import React from 'react';

import { styled } from 'snap-ui/util';

function Disclaimer({ className }: { className?: string }) {
  return (
    <div className={className}>
      By clicking &quot;Launch&quot; you are agreeing to run this attack on your host and that you have permission to do
      so.
    </div>
  );
}

export default styled(Disclaimer)`
  font-size: 90%;
  opacity: 0.6;
`;
