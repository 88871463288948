import React from 'react';

import { FormControlLabel } from 'snap-ui/FormControl';
import Icon from 'snap-ui/Icon';
import Switch from 'snap-ui/Switch';
import Typography from 'snap-ui/Typography';

import { AttackType, Discriminator } from 'module/Tag';

import { Status } from 'storage/Storage.type';

import { useLandingCatalog } from '../../LandingProvider';

export default function AttackSubheader() {
  const { type, data, status, rollup, setRollup } = useLandingCatalog();
  if (type !== Discriminator.Attack) return null;
  return (
    <>
      <div className='Landing-subtype'>
        <Typography component='span' variant='body2'>
          {data.combined.type}
        </Typography>
      </div>
      {data.combined?.type !== AttackType.Subtechnique && (
        <div className='Landing-alias'>
          <FormControlLabel
            control={<Switch color='primary' checked={rollup} onChange={(_e, v) => setRollup(v)} />}
            label={
              data.combined?.type === AttackType.Tactic
                ? 'Include techniques and subtechniques'
                : 'Include subtechniques'
            }
            labelPlacement='end'
          />
          {status === Status.pending && data.combined.id && <Icon.SpinnerProgress color='primary' />}
        </div>
      )}
    </>
  );
}
