import React from 'react';

import { Autocomplete } from 'snap-ui/Autocomplete';

import useOrganizationOptions from 'hooks/useOrganizationOptions';

import { useIDEValue } from 'module/IDE';

import { useAuth } from 'provider';

import { FunctionalPermission } from 'types/auth';

type Props = {
  readonly?: boolean;
};

export default function OrganizationField({ readonly }: Props) {
  const { permission: allOrgs } = useAuth();
  const orgMapping = useOrganizationOptions(FunctionalPermission.CreateAnalytic);
  const { value, setValue } = useIDEValue('organization_id');

  function getDisplayValue() {
    return (
      orgMapping.find(org => org.value === value?.toString())?.label ||
      allOrgs.find(org => org.id === value)?.name ||
      value?.toString() ||
      ''
    );
  }

  return (
    <Autocomplete
      className='Form-field required'
      elevated
      label='Organization'
      name='organization_id'
      disabled={readonly}
      disableClearable
      disableUserAdditions
      options={orgMapping}
      onChange={v => setValue(parseInt(v as string, 10))}
      value={getDisplayValue()}
      testId='organization_id'
    />
  );
}
