import React from 'react';

import { useRequester as useFunctionalRequester } from 'module/May';

import { useIntegrationCatalog } from 'provider';

import { ContentPermission, FunctionalPermission, isContentPermission } from 'types/auth';

import { checkAnalyticPermission, checkAnalyticFunctionalPermission } from '../Analytic.util';
import { useAnalyticCatalog } from './AnalyticProvider';

function useAnalyticRequesters() {
  const { integrations } = useIntegrationCatalog();
  const basicFunctionalRequester = useFunctionalRequester();
  const [{ permissions, supplemental }] = useAnalyticCatalog();

  const contentRequester = React.useCallback(
    (action: ContentPermission) => {
      return checkAnalyticPermission(permissions, supplemental, action, integrations.all);
    },
    [integrations.all, permissions, supplemental]
  );

  const functionalRequester = React.useCallback(
    (action: FunctionalPermission) => {
      return checkAnalyticFunctionalPermission(basicFunctionalRequester, supplemental, action, integrations.all);
    },
    [basicFunctionalRequester, integrations.all, supplemental]
  );

  return { contentRequester, functionalRequester };
}

export default function useAnalyticPermission(action: ContentPermission | FunctionalPermission): [boolean, string?] {
  const { contentRequester, functionalRequester } = useAnalyticRequesters();

  if (isContentPermission(action)) {
    return contentRequester(action);
  } else {
    return functionalRequester(action);
  }
}
