import { DEMO_ORG_GUIDS } from 'constants/auth';

import { useAuth } from 'provider';

import { Catalog, Scheme, useAsyncStorage } from 'storage';

export default function useDemoMode(): { isDemoMode: boolean; setDemoMode(boolean) } {
  const { user } = useAuth();

  const isDemoOrg = DEMO_ORG_GUIDS.includes(user?.preferred_organization?.guid);
  const { data: isDemoMode, setData: setDemoMode } = useAsyncStorage<boolean>(
    Scheme.localStorage,
    Catalog.demo_mode,
    false
  );

  return { isDemoMode: isDemoOrg && isDemoMode, setDemoMode };
}
