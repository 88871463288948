import { Fragment, Match } from './type';

/**
 * This class replaces the simple and elegant boxcar pattern. Unfortunately, That pattern suffers from
 * XSS issues. To solve that, we no longer build up a string containing markup and dangerously set its
 * value through React. Here, we take the array of found indices, Match[], and compile them into a list
 * of Fragment objects.
 */
class Compiler {
  private tagOpen: boolean;
  private startPointer: number;
  private endPointer: number;
  private result: Fragment[];

  /**
   * @param match The detection
   * @param baseline The original string
   */
  constructor(private match: Match[], private baseline: string) {
    this.startPointer = 0;
    this.endPointer = 0;
    this.result = [];
  }

  run(): Fragment[] {
    this.match.forEach((m, index) => {
      this.endPointer = index;
      this.tryMappingStartIndex(m.start);
      this.tryMappingEndIndex(m.end);
    });

    this.endPointer = this.match.length;
    this.flushQueue();
    return this.result;
  }

  tryMappingStartIndex(startTotal: number) {
    if (startTotal > 0) {
      if (this.tagOpen) this.push(this.baseline.substring(this.startPointer, this.endPointer), true);
      else {
        this.flushQueue();
        this.tagOpen = true;
      }

      this.startPointer = this.endPointer;
    }
  }

  tryMappingEndIndex(endTotal: number) {
    if (endTotal > 0) {
      const end = this.endPointer + 1;
      this.push(this.baseline.substring(this.startPointer, end), true);

      this.startPointer = end;
      this.endPointer = end;
      this.tagOpen = false;
    }
  }

  flushQueue() {
    const value = this.baseline.substring(this.startPointer, this.endPointer);
    value && this.push(value, false);
  }

  push(value: string, outline: boolean) {
    this.result.push({
      value,
      outline
    });
  }
}

export default Compiler;
