import React from 'react';

import isEmpty from 'lodash/isEmpty';

import Typography from 'snap-ui/Typography';

import { Discriminator } from 'module/Tag';

import { formatDayTimestamp } from 'utilities/TimeUtils';

import { CategoryContainer } from '../../Landing.style';
import { PlatformIcons } from '../../Landing.widgets';
import { useLandingCatalog } from '../../LandingProvider';

export default function SoftwareDetail() {
  const { source, type } = useLandingCatalog();
  if (type !== Discriminator.Software) return null;

  return (
    <>
      {source.last_seen && (
        <div className='LandingHeaderFooterContent'>
          <Typography variant='h6'>LAST SEEN</Typography>
          <p>{formatDayTimestamp(source.last_seen)}</p>
        </div>
      )}

      {source.role && (
        <div className='LandingHeaderFooterContent'>
          <Typography variant='h6'>ROLE</Typography>
          <p>{source.role}</p>
        </div>
      )}

      {!isEmpty(source.platforms) && (
        <div className='LandingHeaderFooterContent'>
          <Typography variant='h6'>OPERATING SYSTEMS</Typography>
          <CategoryContainer className='row'>
            <PlatformIcons platforms={source?.platforms} />
          </CategoryContainer>
        </div>
      )}
    </>
  );
}
