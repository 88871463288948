import React from 'react';

import { ProtectedSettings } from 'types/auth';
import { StrictReactNode } from 'types/core';

import useProtectedSettingsAsync from './useProtectedSettingsAsync';

const ProtectedSettingsContext = React.createContext<ProtectedSettings>(null);
ProtectedSettingsContext.displayName = 'ProtectedSettingsContext';

function useProtectedSettings(): ProtectedSettings {
  const context = React.useContext(ProtectedSettingsContext);

  if (!context) {
    throw new Error('useProtectedSettings must be used within the ProtectedSettingsContext');
  }

  return context;
}

function ProtectedSettingsProvider({ children }: { children: StrictReactNode }): JSX.Element {
  const settings = useProtectedSettingsAsync();

  return <ProtectedSettingsContext.Provider value={settings}>{children}</ProtectedSettingsContext.Provider>;
}

export { ProtectedSettingsProvider, useProtectedSettings };
