import React from 'react';

import zod from 'zod';

import { Autocomplete, Option } from 'snap-ui/Autocomplete';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { useAuth } from 'provider';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';

import { FilterControl } from '../GlobalFilter.style';
import { FilterConfig } from '../GlobalFilter.type';

type FirstSeenKeys = {
  firstSeen: string;
};

type FirstSeenFilterProps = {
  onChange(values: Partial<FirstSeenKeys>): void;
  values: FirstSeenKeys;
};

const BackwardsCompatibleValues = {
  '-7d': 'P7D',
  '-30d': 'P30D',
  '-90d': 'P90D',
  '-183d': 'P6M',
  '-365dy': 'P1Y',
  '-730y': 'P2Y',
  '-1826d': 'P5Y'
};

const options: Option[] = [
  { content: 'Last 7 Days', label: '7 Days', value: 'P7D' },
  { content: 'Last 30 Days', label: '30 Days', value: 'P30D' },
  { content: 'Last 90 Days', label: '90 Days', value: 'P90D' },
  { content: 'Last 6 Months', label: '6 Months', value: 'P6M' },
  { content: 'Last Year', label: '1 Year', value: 'P1Y' },
  { content: 'Last 2 Years', label: '2 Years', value: 'P2Y' },
  { content: 'Last 5 Years', label: '5 Years', value: 'P5Y' }
];

function FirstSeenFilter({ onChange, values }: FirstSeenFilterProps): React.ReactElement {
  const { isSubscriber } = useAuth();
  const handleValueChange = (option: string) => {
    const payload = { firstSeen: option };
    onChange(payload);
  };

  return (
    <FilterControl className='FirstSeenFilter' disabled={!isSubscriber}>
      <FormLabel id='firstSeenDropdown' className='title-tooltip'>
        By First Seen Date
        {!isSubscriber && (
          <Tooltip title='This filter is only available to subscribers' placement='right' arrow wrap>
            <Icon.Info />
          </Tooltip>
        )}
      </FormLabel>
      <Autocomplete
        name='first_seen_dropdown'
        options={options}
        value={values.firstSeen}
        onChange={handleValueChange}
        disabled={!isSubscriber}
      />
    </FilterControl>
  );
}

function toQuery(values: FirstSeenKeys) {
  if (!values.firstSeen) return;

  return {
    field: 'first_seen',
    op: Ops.greater_than,
    value: values.firstSeen
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('first_seen'),
    op: zod.nativeEnum(Ops),
    value: zod.string()
  })
  .transform(query => ({ firstSeen: BackwardsCompatibleValues[query.value] ?? query.value }));

const FirstSeenFilterConfig: FilterConfig<FirstSeenKeys> = {
  defaults: { default: () => ({ firstSeen: null }) },
  supportedTopics: [ArtifactType.Indicator],
  component: FirstSeenFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default FirstSeenFilterConfig;
