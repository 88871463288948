import React from 'react';

import classNames from 'classnames';

import { styled } from 'snap-ui/util';

import { formatDayTimestamp } from 'utilities/TimeUtils';

type SeenProps = { className?: string; timestamp: string; type: 'Last' | 'First' };

const Container = styled('div')`
  span {
    text-transform: uppercase;
    font-weight: bold;
  }
`;

const Seen = ({ className, timestamp, type }: SeenProps) => {
  if (!timestamp) return null;
  const formatted = formatDayTimestamp(timestamp);

  return (
    <Container className={classNames(`${type}Seen`, className)}>
      <span>{type} Seen: </span>
      {formatted}
    </Container>
  );
};

const FirstSeen = (props: Omit<SeenProps, 'type'>) => <Seen type='First' {...props} />;

const LastSeen = (props: Omit<SeenProps, 'type'>) => <Seen type='Last' {...props} />;

export { FirstSeen, LastSeen };
