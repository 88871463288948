import React from 'react';

import { faWarning } from '@fortawesome/pro-regular-svg-icons';

import Chip from 'snap-ui/Chip';
import Icon from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

import { getTagWeightLabel } from 'module/ThreatProfile';
import { TagWeight } from 'module/ThreatProfile/ThreatProfile.type';

import { ValueMap } from './';

const Label = styled(Chip)`
  height: 1.55rem;
  border-radius: 5px;
  align-items: center;
  text-transform: uppercase;

  .MuiChip-icon {
    margin-left: ${p => p.theme.spacing(3)};
  }
`;

const common: ValueMap['string'] = { icon: <Icon icon={faWarning} />, variant: 'outlined' };

export const WeightMap: ValueMap<TagWeight> = {
  [TagWeight.Highest]: { ...common, color: 'error' },
  [TagWeight.High]: { ...common, color: 'orange' },
  [TagWeight.Medium]: { ...common, color: 'warning' },
  [TagWeight.Low]: { ...common, color: 'success' },
  [TagWeight.Lowest]: { ...common, color: 'info' },
  [TagWeight.Ignored]: { ...common, color: 'greyish' }
};

export function ThreatProfilePriorityLabel({
  className,
  value
}: {
  className?: string;
  value: TagWeight;
}): JSX.Element {
  return <Label className={className} label={getTagWeightLabel(value)} {...WeightMap[value]} />;
}
