import React from 'react';

import { ErrorProps } from 'module/ApiError';
import { AttackType, Discriminator } from 'module/Tag';

import { useAuth } from 'provider';

import { useAsync, Status } from 'storage';

import { Ident } from 'types/common';

import { getCoverage, getCoverageReport } from './api';
import { CoverageReport, TagCoverage, isAttackCoverageReport } from './type';

export type CoverageInterface = {
  getTagCoverage(tagId: Ident): TagCoverage | undefined;
  getTagTypeReport(tagType: Discriminator): CoverageReport | undefined;
  loadCoverage(): void;
  tagCoverageErrorProps: ErrorProps;
  coverageReportErrorProps: ErrorProps;
  tagCoverageIsPending: boolean;
  coverageReportIsPending: boolean;
};

const CoverageContext = React.createContext<CoverageInterface>(null);
CoverageContext.displayName = 'CoverageContext';

function _useCoverage(): CoverageInterface {
  const { defaultOrgId, defaultOrgGuid } = useAuth();
  const [lookupTable, setLookupTable] = React.useState<Record<Ident, TagCoverage>>({});
  const {
    data: tagCoverageData,
    errorProps: tagCoverageErrorProps,
    run: tagCoverageRun,
    status: tagCoverageStatus
  } = useAsync<TagCoverage[]>([]);
  const {
    data: coverageReportData,
    errorProps: coverageReportErrorProps,
    run: coverageReportRun,
    status: coverageReportStatus
  } = useAsync<CoverageReport[]>([]);

  const loadCoverage = React.useCallback(() => {
    // refresh coverage stats if preferred org has changed
    if (!tagCoverageData.length || tagCoverageData[0]?.organization_id !== defaultOrgId) {
      tagCoverageRun(getCoverage(defaultOrgGuid));
      coverageReportRun(getCoverageReport(defaultOrgGuid));
    }
  }, [defaultOrgGuid, defaultOrgId, tagCoverageData, coverageReportRun, tagCoverageRun]);

  const getTagCoverage = React.useCallback(
    (tagId: Ident) => {
      if (tagId in lookupTable) return lookupTable[tagId];
      const coverage = tagCoverageData.find(cov => cov.tag_id === tagId);
      if (coverage) {
        setLookupTable(t => ({ ...t, [tagId]: coverage }));
        return coverage;
      }
    },
    [tagCoverageData, lookupTable]
  );

  const getTagTypeReport = React.useCallback(
    (tagType: Discriminator) =>
      coverageReportData.find(report => {
        if (tagType === Discriminator.Attack) {
          return isAttackCoverageReport(report) && report.type === AttackType.Tactic;
        }
        return report.discriminator === tagType;
      }),
    [coverageReportData]
  );

  return {
    getTagCoverage,
    getTagTypeReport,
    loadCoverage,
    tagCoverageErrorProps,
    coverageReportErrorProps,
    tagCoverageIsPending: tagCoverageStatus === Status.pending || tagCoverageStatus === Status.idle,
    coverageReportIsPending: coverageReportStatus === Status.pending || coverageReportStatus === Status.idle
  };
}

export function CoverageProvider({ children }: React.PropsWithChildren<Record<never, never>>): JSX.Element {
  const value = _useCoverage();
  return <CoverageContext.Provider value={value}>{children}</CoverageContext.Provider>;
}

export function useCoverage(): CoverageInterface {
  const value = React.useContext(CoverageContext);

  if (!value) throw new Error('useCoverage used outside of CoverageProvider');

  return value;
}
