import { postOrgMetadata } from 'apis/resources/organization';

import { ControlElement } from 'module/JsonView';

import { ArtifactType, Guid } from 'types/common';

import { getArtifactMetadata, setArtifactMetadata } from './Metadata.api';
import { ArtifactMetadataService, MetadataSchema } from './Metadata.type';
import { addToSchema, removeFromSchema, reorderSchema, replaceInSchema } from './Metadata.util';

/**
 * NOTE! You need a migration object on every post to updating the schema
 */
function postToOrgSchema(orgGuid: Guid, next: MetadataSchema) {
  return postOrgMetadata(orgGuid, next).then(() => next);
}

export function editOrgMetadataSchema(
  orgGuid: Guid,
  current: MetadataSchema,
  next: MetadataSchema,
  property: string
): Promise<MetadataSchema> {
  const insertIndex = current.uischema.elements.findIndex(
    (i: ControlElement) => i?.scope?.split('/').pop() === property
  );
  const update = replaceInSchema(current, next, property, insertIndex);
  return postToOrgSchema(orgGuid, update);
}

export function addToOrgMetadataSchema(
  orgGuid: Guid,
  current: MetadataSchema,
  addition: MetadataSchema
): Promise<MetadataSchema> {
  const update = addToSchema(current, addition);
  return postToOrgSchema(orgGuid, update);
}

export function reorderOrgMetadataSchema(
  orgGuid: Guid,
  current: MetadataSchema,
  oldIndex: number,
  targetIndex: number
): Promise<MetadataSchema> {
  const update = reorderSchema(current, oldIndex, targetIndex);
  return postToOrgSchema(orgGuid, update);
}

export function removeFromOrgMetadataSchema(
  orgGuid: Guid,
  current: MetadataSchema,
  property: string
): Promise<MetadataSchema> {
  const update = removeFromSchema(current, property);
  return postToOrgSchema(orgGuid, update);
}

export function getMetadata(type: ArtifactType, guid: Guid) {
  switch (type) {
    case ArtifactType.Analytic:
      return getArtifactMetadata('signatures/signature', guid);
    case ArtifactType.Intel:
      return getArtifactMetadata('intel', guid);
    case ArtifactType.Session:
      return getArtifactMetadata('sessions', guid);
    case ArtifactType.Collection:
      return getArtifactMetadata('collections', guid);
    case ArtifactType.AttackScript:
      return getArtifactMetadata('bas/script', guid);
    case ArtifactType.Landing:
      return getArtifactMetadata('tags', `${guid}/landing`);
    default:
      throw new Error('Metadata type not supported. ' + type);
  }
}

export function setMetadata(type: ArtifactType, guid: Guid, record: ArtifactMetadataService['metadata_value']) {
  const payload = { metadata_value: record };
  switch (type) {
    case ArtifactType.Analytic:
      return setArtifactMetadata('signatures/signature', guid, payload);
    case ArtifactType.Intel:
      return setArtifactMetadata('intel', guid, payload);
    case ArtifactType.Session:
      return setArtifactMetadata('sessions', guid, payload);
    case ArtifactType.Collection:
      return setArtifactMetadata('collections', guid, payload);
    case ArtifactType.AttackScript:
      return setArtifactMetadata('bas/script', guid, payload);
    case ArtifactType.Landing:
      return setArtifactMetadata('tags', `${guid}/landing`, payload);
    default:
      throw new Error('Metadata type not supported. ' + type);
  }
}

export function getMetadataBulkEndpoint(type: ArtifactType) {
  switch (type) {
    case ArtifactType.Analytic:
      return 'signatures/signature';
    case ArtifactType.Intel:
      return 'intel';
    case ArtifactType.Session:
      return 'sessions';
    case ArtifactType.Collection:
      return 'collections';
    case ArtifactType.AttackScript:
      return 'bas/script';
    default:
      throw new Error('Metadata type not supported. ' + type);
  }
}
