import React from 'react';

import { CancelToken } from 'apis';

import { AutocompleteResponse, Discriminator, searchTagsByDiscriminators } from 'module/Tag';

import { Status, useAsync } from 'storage';

export default function usePartialTagMatch(
  types: Discriminator[],
  legacy?: boolean
): {
  cancel(): void;
  data: AutocompleteResponse;
  search(query: string): void;
  status: Status;
  reset(): void;
} {
  const { data, run, status, reset } = useAsync<AutocompleteResponse>();
  const cancelTokenSourceRef = React.useRef(CancelToken.source());

  const search = React.useCallback(
    (partial: string) => {
      const source = CancelToken.source();
      cancelTokenSourceRef.current = source;
      run(searchTagsByDiscriminators(50, { partial, types, legacy }));
    },
    [types, legacy, run]
  );

  return {
    cancel: cancelTokenSourceRef.current.cancel,
    data,
    search,
    status,
    reset
  };
}
