import { FEED_TYPES } from 'constants/feed';

import { CollectionDiscriminator } from 'module/Collection/Collection.type';
import { Feed } from 'module/Feed/Feed.type';
import { IndicatorSearchResponse } from 'module/IOC/IOC.type';
import { Discriminator } from 'module/Tag/Tag.type';

import { ArtifactType } from 'types/common';

import { getHighlightsByType } from 'utilities/ArtifactUtils';

import { CategoryResult, CategoryResultItem } from './type';

export default function map(type: ArtifactType, data: Feed): CategoryResult {
  const items = Array.isArray(data.items) ? data.items : [];
  switch (type) {
    case ArtifactType.Analytic:
      return {
        name: 'Detections',
        topic: ArtifactType.Analytic,
        total: data.total,
        results: items.map(i => ({
          to: `${FEED_TYPES[ArtifactType.Analytic].link}/${i.guid}`,
          title: getHighlightsByType(i),
          type: ArtifactType.Analytic,
          preview: i.preview,
          data: i
        }))
      };

    case ArtifactType.Session:
      return {
        name: 'Threats',
        topic: ArtifactType.Session,
        total: data.total,
        results: items.map(i => ({
          to: `${FEED_TYPES[ArtifactType.Session].link}/${i.guid}`,
          title: getHighlightsByType(i),
          type: ArtifactType.Session,
          preview: i.preview,
          data: i
        }))
      };

    case ArtifactType.Intel:
      return {
        name: 'Intelligence',
        topic: ArtifactType.Intel,
        total: data.total,
        results: items.map(i => ({
          to: `${FEED_TYPES[ArtifactType.Intel].link}/${i.guid}`,
          title: getHighlightsByType(i),
          type: ArtifactType.Intel,
          preview: i.preview,
          data: i
        }))
      };
    case ArtifactType.Collection:
      return {
        name: 'Collections',
        topic: ArtifactType.Collection,
        total: data.total,
        results: items.map(
          i =>
            ({
              to: getCollectionLink(i.type as CollectionDiscriminator, i.name, i.guid),
              title: getHighlightsByType(i),
              type: i.type,
              preview: i.preview,
              data: i
            } as CategoryResultItem)
        )
      };
    case ArtifactType.AttackScript:
      return {
        name: 'Attack Scripts',
        topic: ArtifactType.AttackScript,
        total: data.total,
        results: items.map(i => ({
          to: `${FEED_TYPES[ArtifactType.AttackScript].link}/${i.guid}`,
          title: getHighlightsByType(i),
          type: ArtifactType.AttackScript,
          preview: i.preview,
          data: i
        }))
      };

    default:
      throw new Error('map type not supported ' + type);
  }
}

export function mapIndicators(data: IndicatorSearchResponse[], searchTerm: string): CategoryResult {
  return {
    name: 'Indicators',
    query: searchTerm,
    topic: ArtifactType.Indicator,
    total: data.length ?? 0,
    results: Array.isArray(data) ? data.slice() : []
  };
}

function getCollectionLink(type: Discriminator | CollectionDiscriminator, name: string, guid: string): string {
  // Static collections are linked as /collection/<guid>
  // Landing pages are linked as /collection/<type>/<tag_name>
  switch (type) {
    case Discriminator.Attack:
    case Discriminator.Actor:
    case Discriminator.Software:
    case Discriminator.Vulnerability:
      return `${FEED_TYPES[ArtifactType.Collection].link}/${type}/${encodeURIComponent(name)}`;
    default: {
      return `${FEED_TYPES[ArtifactType.Collection].link}/${guid}`;
    }
  }
}
