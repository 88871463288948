import React from 'react';

import { asValidationError } from 'apis/snapattack';

import { basJobFeed } from 'module/BAS/BAS.api';
import { getSearchPage } from 'module/Feed/Feed.service';
import { Page } from 'module/Feed/Feed.type';

import { useAsync, Status } from 'storage';

import { ArtifactType, Guid } from 'types/common';
import { Query } from 'types/filter';

import { downloadFile } from 'utilities/FileUtils';

import { postExport, getExportStatus, getExportResult } from './Export.api';
import {
  BASJobExportRequest,
  DetectionExportRequest,
  ExportEndpointPrefix,
  ExportJobState,
  ExportRequestBase,
  MimeType
} from './Export.type';

const COUNT_CALL = {
  [ExportEndpointPrefix.BASJobs](filter: Query): Promise<Page<any>> {
    return basJobFeed({ page: 0, size: 0 }, filter);
  },
  [ExportEndpointPrefix.Detections](filter: Query): Promise<Page<any>> {
    return getSearchPage(ArtifactType.Analytic, 1, 0, filter); // getSearchPage is 1-indexed
  }
};

export type ExportUtils<ReqType extends ExportRequestBase> = {
  count?: number;
  countStatus: Status;
  error?: string;
  startExport(payload: ReqType): void;
  status: Status;
};

function _pollForFile(endpoint: ExportEndpointPrefix, taskId: Guid, filetype: MimeType, attempt = 0): Promise<void> {
  return getExportStatus(endpoint, taskId).then(result => {
    if (result.status === ExportJobState.success)
      return getExportResult(endpoint, taskId).then(data => {
        downloadFile(new Blob([data]), result.output[filetype]);
      });
    if (attempt > 60) throw { detail: 'This is taking longer than we expected. Something may be wrong.' };
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        _pollForFile(endpoint, taskId, filetype, attempt + 1)
          .then(resolve)
          .catch(reject);
      }, 1000);
    });
  });
}

function useExport(
  open: boolean,
  endpoint: ExportEndpointPrefix.BASJobs,
  filter: Query
): ExportUtils<BASJobExportRequest>;
function useExport(
  open: boolean,
  endpoint: ExportEndpointPrefix.Detections,
  filter: Query
): ExportUtils<DetectionExportRequest>;
function useExport<ReqType extends ExportRequestBase>(
  open: boolean,
  endpoint: ExportEndpointPrefix,
  filter: Query
): ExportUtils<ReqType> {
  const { error: _error, status, task } = useAsync();
  const { data: countData, run: runCount, status: countStatus } = useAsync<Page<any>>();

  React.useEffect(() => {
    if (open) runCount(COUNT_CALL[endpoint](filter));
  }, [endpoint, filter, open, runCount]);

  const startExport = React.useCallback(
    (payload: ReqType) => {
      task(postExport(endpoint, payload)).then(serverTask =>
        task(_pollForFile(endpoint, serverTask.task_id, payload.format[0]))
      );
    },
    [endpoint, task]
  );

  let error;
  if (_error) {
    if (_error.detail) error = _error.detail;
    else error = asValidationError(_error)?.detail?.[0];
  }

  return React.useMemo(
    () => ({
      count: countData?.total,
      countStatus,
      error,
      startExport,
      status
    }),
    [countData?.total, countStatus, error, startExport, status]
  );
}

export default useExport;
