import { FeedRouteGen, IDELogPickerRouteGen, IDERouteGen, RouteGen } from 'types/route';

import { formatQueryString } from './SearchParam';

function getLocation(info: FeedRouteGen): RouteGen;
function getLocation(info: IDERouteGen): RouteGen;
function getLocation(info: IDELogPickerRouteGen): RouteGen;
function getLocation(info: any): RouteGen {
  return {
    pathname: info.pathname,
    search: info.search && formatQueryString(info.search)
  };
}

export { getLocation };
