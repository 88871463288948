import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

function documentIsFullscreen() {
  return (
    !!document.fullscreenElement ||
    !!document.webkitFullscreenElement ||
    !!document.mozFullScreenElement ||
    !!document.msFullscreenElement
  );
}

function useFullscreen(initialState, fullscreenElementRef) {
  const [isFullscreen, setIsFullscreen] = useState(initialState);
  const [newIsFullscreen, setNewIsFullscreen] = useState(initialState);

  const handleFullscreenChange = useCallback(() => {
    if (!documentIsFullscreen() && isFullscreen) {
      setIsFullscreen(false);
      setNewIsFullscreen(false);
    } else if (documentIsFullscreen() && !isFullscreen) {
      setIsFullscreen(true);
      setNewIsFullscreen(true);
    }
  }, [isFullscreen]);

  useLayoutEffect(() => {
    if (newIsFullscreen) {
      try {
        requestFullscreen(fullscreenElementRef.current).then(() => {
          setIsFullscreen(newIsFullscreen);
        });
      } catch (e) {
        console.warn('Full screen video is not supported');
        exitFullscreen();
        setIsFullscreen(false);
        setNewIsFullscreen(false);
      }
    } else if (!newIsFullscreen && documentIsFullscreen()) {
      exitFullscreen().then(() => {
        setIsFullscreen(newIsFullscreen);
      });
    }
  }, [fullscreenElementRef, newIsFullscreen]);

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);
    return function cleanup() {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, [handleFullscreenChange]);

  return [isFullscreen, setNewIsFullscreen];

  function requestFullscreen(container) {
    if (container.requestFullscreen) {
      return container.requestFullscreen();
    } else if (container.webkitRequestFullscreen) {
      return container.webkitRequestFullscreen();
    } else if (container.mozRequestFullscreen) {
      return container.mozRequestFullscreen();
    } else if (container.msRequestFullscreen) {
      return container.msRequestFullscreen();
    }
  }

  function exitFullscreen() {
    if (document.exitFullscreen) {
      return document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      return document.webkitExitFullscreen();
    } else if (document.mozExitFullscreen) {
      return document.mozExitFullscreen();
    } else if (document.msExitFullscreen) {
      return document.msExitFullscreen();
    }
  }
}

export default useFullscreen;
