import { ArtifactScore } from 'types/common';

export const barData = [
  {
    name: 'HIGHEST',
    'Old Confidence': 4000,
    'New Confidence': 2400
  },
  {
    name: 'HIGH',
    'Old Confidence': 3000,
    'New Confidence': 1398
  },
  {
    name: 'MEDIUM',
    'Old Confidence': 2000,
    'New Confidence': 9800
  },
  {
    name: 'LOW',
    'Old Confidence': 2780,
    'New Confidence': 3908
  },
  {
    name: 'LOWEST',
    'Old Confidence': 1890,
    'New Confidence': 4800
  }
];

export const DefaultThreshold = {
  [ArtifactScore.HIGHEST]: 0,
  [ArtifactScore.HIGH]: 50,
  [ArtifactScore.MEDIUM]: 500,
  [ArtifactScore.LOW]: 5000
};

export const jobResultsMockData = [
  {
    name: 'User Privileges Discovery',
    guid: '48e0b7a1-9ad8-4f1b-8e1c-4afba02a8b5f',
    bas_job_id: null,
    job_hit_count: 0,
    count: 4578,
    error: null,
    integration: '2457cf35-b2b1-4ec4-878d-c0c17c1ed924',
    old_rank: ArtifactScore.MEDIUM,
    logsource: 'process_creation'
  },
  {
    name: 'User Privileges Discovery',
    guid: '48e0b7a1-9ad8-4f1b-8e1c-4afba02a8b5f',
    bas_job_id: null,
    job_hit_count: 0,
    count: 50,
    error: 'Expecting value: line 1 column 1 (char 0)',
    integration: '5457cf35-b2b1-4ec4-878d-c0c17c1ed925',
    old_rank: ArtifactScore.MEDIUM,
    logsource: 'process_creation'
  },
  {
    name: 'Btom Dump',
    guid: '48e0b7a1-9ad8-4f1b-8e1c-4afba02a8b5j',
    bas_job_id: null,
    job_hit_count: 0,
    count: 1001,
    error: 'Expecting value: line 1 column 1 (char 0)',
    integration: '2457cf35-b2b1-4ec4-878d-c0c17c1ed924',
    old_rank: ArtifactScore.HIGHEST,
    logsource: 'process_creation'
  },
  {
    name: 'Admin Console Okta',
    guid: '88e0b7a1-9ad8-4y1b-8e1g-4afba02a8b5j',
    bas_job_id: null,
    job_hit_count: 0,
    count: 3132,
    error: 'Expecting value: line 1 column 1 (char 0)',
    integration: '3457cf35-b2b1-4ec4-878d-c0c17c1ed924',
    old_rank: ArtifactScore.LOW,
    logsource: 'process_creation'
  },
  {
    name: 'KrbRelayUp Hack Tool',
    guid: '2e8d79c4-562a-4d9e-9837-593a1f2e7bcc',
    bas_job_id: null,
    job_hit_count: 0,
    count: 25,
    error: 'Expecting value: line 1 column 1 (char 0)',
    integration: '3457cf35-b2b1-4ec4-878d-c0c17c1ed924',
    old_rank: ArtifactScore.LOW,
    logsource: 'process_creation'
  }
];
