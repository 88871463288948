import React from 'react';

import classnames from 'classnames';

import { styled } from 'snap-ui/util';

type InlineHighlightProps = {
  className?: string;
  value: string;
  query: string | string[];
};

const Highlit = styled('span')`
  .match {
    color: ${p => p.theme.palette.primary.main};
  }
`;

export function highlightMatch(value: string, query: string | string[]): string {
  if (!value) return '';
  if (!query) return value;

  if (typeof query === 'string') query = [query];
  const regex = new RegExp(query.join('|'), 'gi');

  return value.replace(new RegExp(regex, 'gi'), '<span class="match">$&</span>');
}

/**
 * Should not be used non-controlled text (i.e. no user input)
 */
export default function InlineHighlight({ className, query, value }: InlineHighlightProps): JSX.Element {
  return (
    <Highlit
      className={classnames('InlineHighlight', className)}
      dangerouslySetInnerHTML={{ __html: highlightMatch(value, query) }}
    />
  );
}
