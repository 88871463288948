import config from 'config/config';

import { useProtectedSettings } from 'provider';

type SplunkLinkMap = {
  sessionDashboard: string;
  hostLogDashboard: string;
};

export default function useSplunkLink(): SplunkLinkMap {
  const { snapattack_splunk_app } = useProtectedSettings();

  return {
    sessionDashboard: `${config.SPLUNK_URL_BASE}/${snapattack_splunk_app}/${config.SPLUNK_SESSION_DASHBOARD_NAME}`,
    hostLogDashboard: `${config.SPLUNK_URL_BASE}/${snapattack_splunk_app}/${config.SPLUNK_HOST_LOG_DASHBOARD_NAME}`
  };
}
