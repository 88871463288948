import { GridApi } from './DataGrid';

export function getRowForSort<T>(api: GridApi, params) {
  const isGroupCell = params.rowNode.type === 'group';
  const row = api.getRow(params.rowNode.id);
  if (!isGroupCell) return row;
  const childrenRowIds = api.getRowGroupChildren({
    groupId: params.id
  });
  const childrenRowData = api.getRow<T>(childrenRowIds[0]);
  return childrenRowData;
}
