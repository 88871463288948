import React from 'react';

import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';

import FormControl from 'snap-ui/FormControl';
import Icon from 'snap-ui/Icon';
import Paper from 'snap-ui/Paper';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { StrictReactNode } from 'types/core';

export type Size = 'lg' | 'md' | 'sm' | 'xs';

function WIDTH(size?: Size): string {
  switch (size) {
    case 'lg':
      return '300px';
    case 'sm':
    default:
      return '200px';
  }
}

function HEIGHT(size?: Size): string {
  switch (size) {
    case 'lg':
      return '200px';
    case 'md':
      return '140px';
    case 'xs':
      return '55px';
    case 'sm':
    default:
      return '80px';
  }
}

const CardFormControl = styled(FormControl, { shouldForwardProp: p => p !== 'size' })<any & { size?: Size }>(p => ({
  display: 'grid',
  gap: p.theme.spacing(4),
  gridTemplateColumns: `repeat(auto-fill, minmax(${WIDTH(p.size)}, 1fr))`,
  gridAutoRows: `minmax(${HEIGHT(p.size)}, 1fr)`,

  '.CheckableCard label':
    p.size === 'xs'
      ? {
          gap: 0,
          padding: p.theme.spacing(1, 3),
          justifyContent: 'center'
        }
      : {}
}));

export type CardProps = { detail?: StrictReactNode; icon?: JSX.Element; label?: StrictReactNode; value: string };

type CheckableCardsCommonProps = {
  className?: string;
  disabled?: boolean;
  name: string;
  options: CardProps[];
  size?: Size;
};

type CheckableCardsCheckboxProps = CheckableCardsCommonProps & {
  multiple: true;
  value: string[];
  onChange(v: string[]): void;
};

type CheckableCardsRadioProps = CheckableCardsCommonProps & {
  multiple?: false | undefined;
  value: string;
  onChange(v: string): void;
};

type Props = CheckableCardsRadioProps | CheckableCardsCheckboxProps;

function isCheckboxProps(props: Props): props is CheckableCardsCheckboxProps {
  return !!props.multiple;
}

export const CheckableCards = React.forwardRef(function CheckableCards(props: Props, ref): JSX.Element {
  const isMultiple = isCheckboxProps(props);
  const handleChange = isMultiple
    ? (v: string) => {
        if (props.value.includes(v)) {
          props.onChange(props.value.filter(val => val !== v));
        } else {
          props.onChange([...props.value, v]);
        }
      }
    : (v: string) => {
        if (v === props.value) props.onChange('');
        else props.onChange(v);
      };

  const { className, disabled, name, options, size = 'md', value } = props;

  return (
    <CardFormControl
      ref={ref}
      disabled={disabled}
      className={classnames('CheckableCards', className)}
      aria-labelledby={`${name}-label`}
      size={size}
    >
      {options.map(option => {
        const checked = isMultiple ? value.includes(option.value) : value === option.value;
        return <CheckableCard key={option.value} checked={checked} onChange={handleChange} {...option} />;
      })}
    </CardFormControl>
  );
});

type CheckableCardProps = CardProps & {
  className?: string;
  checked: boolean;
  onChange(value: string): void;
  showCheckIcon?: boolean;
};

const CheckableCardContainer = styled(Paper)`
  position: relative;
  display: flex;
  padding: 0;

  input {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
  }

  label {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    gap: ${p => p.theme.spacing(4)};
    padding: ${p => p.theme.spacing(3)};
    border: 2px solid transparent;
    overflow: hidden;
  }

  label:hover,
  input:focus ~ label {
    background-color: hsla(282, 73%, 64%, 0.1);
    transition: background-color 0.5s;
  }

  input:checked ~ label {
    border-radius: 4px;
    border: 2px solid ${p => p.theme.palette.primary.main};
    background-color: hsla(282, 73%, 64%, 0.1);

    .check-icon {
      display: inline-block;
    }
  }

  .check-icon {
    position: absolute;
    display: none;
    top: 8px;
    right: 12px;
  }

  .CheckableCard-label,
  .CheckableCard-detail {
    text-align: center;
  }
`;

export function CheckableCard({
  className,
  checked,
  detail,
  icon,
  label,
  value,
  onChange,
  showCheckIcon = true
}: CheckableCardProps): JSX.Element {
  function handleChange() {
    onChange(value);
  }

  return (
    <CheckableCardContainer className={classnames('CheckableCard', className, { checked })} elevation={8}>
      <input type='checkbox' id={value} checked={checked} onChange={handleChange} />
      <label htmlFor={value}>
        {icon && <div className='CheckableCard-icon'>{icon}</div>}
        {label && <div className='CheckableCard-label'>{label}</div>}
        {detail && (
          <Typography className='CheckableCard-detail' variant='h6'>
            {detail}
          </Typography>
        )}
        {showCheckIcon && <Icon icon={faCheck} className='check-icon' color='primary' />}
      </label>
    </CheckableCardContainer>
  );
}
