import { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import Path from 'constants/paths';

import { useAuth } from 'provider';

import { checkTaskPermission } from 'services/authService';

import { FunctionalPermission } from 'types/auth';

export function useCannotRedirect(functionalPermission: FunctionalPermission, redirectPath: string = Path.Home) {
  const { replace } = useHistory();
  const { permission, user } = useAuth();
  const havePermission = checkTaskPermission(permission, functionalPermission);
  useEffect(() => {
    if (!!user.guid && !havePermission) replace(redirectPath);
  }, [havePermission, redirectPath, replace, user.guid]);
}
