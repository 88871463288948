import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { z } from 'zod';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import Chip from 'snap-ui/Chip';
import { ToggleButton, ToggleButtonGroup } from 'snap-ui/ToggleButton';

import { COMMUNITY_ORGANIZATION } from 'constants/auth';
import Path from 'constants/paths';

import useNoPermitCreateRedirect from 'hooks/useNoPermitCreateRedirect';
import useOrganizationOptions from 'hooks/useOrganizationOptions';
import useTitle from 'hooks/useTitle';

import { CommonEvent, Engage, Fingerprint } from 'lib/Engagement';

import { ApiError } from 'module/ApiError';
import { PendingCount } from 'module/Curation/CurationCount';
import { standardFormikBaseProps } from 'module/Form';
import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import Formik from 'module/Form/Formik';
import MarkdownFormik from 'module/Form/MarkdownFormik';
import TextFieldFormik from 'module/Form/TextFieldFormik';
import UploadFieldFormik from 'module/Form/UploadFieldFormik';
import { Form, TagDivider } from 'module/Layout/Artifact.widgets';
import useMachine from 'module/Session/useMachine';
import { Discriminator, TagAutocompleteFormik } from 'module/Tag';
import { RouterMessage } from 'module/Util/RouterPrompt';

import { useAuth, useCommissionedMachine } from 'provider';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { ArtifactType } from 'types/common';
import { CapturePayload } from 'types/harbor';

import { formatQueryString } from 'utilities/SearchParam';

import { ArtifactStyle } from '../Session.style';
import useCapAttackZips from '../useCapAttack';
import CapAttack from './CapAttack';
import CapAttackTable from './CapAttackTable';
import { SessionCreateForm } from './SessionCreate.type';
import SessionCreateHeader from './SessionCreateHeader';
import SessionCreatePublishDialog from './SessionCreatePublishDialog';

const PROMPT_MESSAGE = JSON.stringify({
  title: 'Are you sure?',
  children: 'You have unsaved changes that will be lost if you navigate away',
  submitText: 'Go Back',
  secondaryText: 'Discard Changes'
} as RouterMessage);

const feed = {
  pathname: Path.Feed,
  search: formatQueryString({
    topic: ArtifactType.Session,
    page: 1
  })
};

export default function SessionCreate() {
  useNoPermitCreateRedirect(ArtifactType.Session);
  const { pathname } = useLocation();
  const { replace } = useHistory();
  const { defaultOrgId, user } = useAuth();
  const isUpload = Path.ThreatUpload == pathname;
  useTitle(`${isUpload ? 'Upload' : 'Save'} Captured Threat | SnapAttack`);

  const { capZips, existingIsPending } = useCapAttackZips();
  const newZips = capZips.filter(zip => zip.used === false).length;
  const isCapZips = capZips?.length > 0;
  const [fileSource, setFileSource] = React.useState<'local' | 's3'>('local');

  const orgOptions = useOrganizationOptions(FunctionalPermission.CreateSession);
  const { save, status, error, errorProps } = useMachine();
  const { activeSession, activeSave, setActiveSave, setActiveSession } = useCommissionedMachine();
  const [triedToSave, setTriedToSave] = React.useState(false);
  const [capture, setCapture] = React.useState<CapturePayload | boolean>(false);

  const schema = React.useMemo(() => {
    const schema = z.object({
      organization_id: z.string().nonempty('Organization is required'),
      name: z.string().nonempty('Title is required'),
      description: z.string().nonempty('Description is required')
    });

    if (isEmpty(activeSave)) {
      return schema.extend({
        capture: z.array(z.any()).min(1, 'A CapAttack .zip file is required')
      });
    }
    return schema;
  }, [activeSave]);

  React.useEffect(() => {
    if (!isUpload && !triedToSave) {
      if (activeSession?.length === 0) {
        replace(Path.ThreatUpload);
      } else {
        save()
          .then(s3Uris => {
            setActiveSave(s3Uris);
            setActiveSession([]);
          })
          .catch(error => console.error(error));
        setTriedToSave(true);
      }
    } else if (isUpload) {
      setActiveSave([]);
    }
  }, [activeSession, isUpload, replace, save, setActiveSave, setActiveSession, triedToSave]);

  React.useEffect(() => {
    Engage.track(Fingerprint.load(isUpload ? Path.ThreatUpload : Path.ThreatSave));
  }, [isUpload]);

  const handleSubmit = async (changed: SessionCreateForm) => {
    const payload: CapturePayload = {
      organization_id: changed.organization_id,
      name: changed.name,
      description: changed.description,
      references: [],
      s3_uris: [...activeSave] || [],
      files: activeSave?.length ? undefined : (changed.capture as File[]),
      tags: [...(changed.actor_names || []), ...(changed.software_names || []), ...(changed.vulnerability_names || [])]
    };
    if (changed.references?.length > 0) payload.references = changed.references;

    Engage.track(
      Fingerprint.of(Path.ThreatCreate).withCommon(CommonEvent.Save).withData({
        organization_id: payload.organization_id,
        name: payload.name,
        description: payload.description,
        references: payload.references,
        s3_uris: payload.s3_uris
      })
    );
    setCapture(payload);
  };

  const handleCancel = () => {
    setActiveSession([]);
    replace(isUpload ? feed : Path.ThreatCreate);
  };

  return (
    <Formik<SessionCreateForm>
      {...standardFormikBaseProps}
      initialValues={{
        organization_id: defaultOrgId?.toString() || COMMUNITY_ORGANIZATION.id.toString(),
        owner: user.name || '',
        name: '',
        description: '',
        references: [],
        actor_names: [],
        software_names: [],
        vulnerability_names: [],
        capture: []
      }}
      onSubmit={handleSubmit}
      zodSchema={schema}
    >
      {({ dirty, ...others }): React.ReactElement => {
        return (
          <ArtifactStyle
            meta={
              <SessionCreateHeader
                isPendingMachineState={status === Status.pending}
                onCancel={handleCancel}
                ownerId={user.id}
              />
            }
            type={ArtifactType.Session}
            label={isUpload ? 'Upload Threat' : 'Save Threat'}
          >
            <Prompt when={capture !== true && dirty} message={() => PROMPT_MESSAGE} />
            <Form>
              {isUpload && <CapAttack />}
              {!isUpload && error && error.response.status === 404 && (
                <Alert severity='error'>
                  <AlertTitle>Save capture failed.</AlertTitle>
                  Did you remember to start CapAttack on all hosts?
                </Alert>
              )}
              {!isUpload && error && error.response.status !== 404 && <ApiError {...errorProps} />}
              <TextFieldFormik label='Owner' name='owner' disabled />
              <AutocompleteFormik
                label='Organization'
                name='organization_id'
                options={orgOptions}
                disableUserAdditions
              />
              <TextFieldFormik label='Title' name='name' required />
              {isUpload && isCapZips && newZips > 0 && (
                <ToggleButtonGroup
                  color='primary'
                  value={fileSource}
                  exclusive
                  onChange={() => {
                    fileSource === 's3' ? setFileSource('local') : setFileSource('s3');
                    others.setValues({
                      ...others.values,
                      capture: []
                    });
                  }}
                  aria-label='Upload source'
                >
                  <ToggleButton value='local'>Use Local Upload</ToggleButton>
                  <ToggleButton value='s3'>
                    Use uploaded captures{' '}
                    <PendingCount unavailable={!!capZips} active={existingIsPending}>
                      <Chip size='small' label={newZips} />
                    </PendingCount>
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
              {isUpload && fileSource === 'local' && (
                <div>
                  <label htmlFor='SessionUploadFileDropzone'>Attach a Threat Capture</label>
                  <UploadFieldFormik
                    id='SessionUploadFileDropzone'
                    label='Attach the threat capture'
                    name='capture'
                    accept='application/zip, application/x-zip-compressed'
                    dialogName='Select File'
                    required
                  />
                </div>
              )}

              {isUpload && fileSource === 's3' && isCapZips && <CapAttackTable name='capture' />}
              <MarkdownFormik label='Description' name='description' required />
              <AutocompleteFormik label='References' name='references' options={[]} multiple />
              <TagDivider />
              <TagAutocompleteFormik
                name='attack_names'
                discriminator={Discriminator.Attack}
                multiple
                disabled={true}
                helperText='Labelling an attack on the video or event timeline will add ATT&CK techniques to this threat'
              />
              <TagAutocompleteFormik name='actor_names' discriminator={Discriminator.Actor} multiple />
              <TagAutocompleteFormik name='software_names' discriminator={Discriminator.Software} multiple />
              <TagAutocompleteFormik name='vulnerability_names' discriminator={Discriminator.Vulnerability} multiple />
            </Form>
            {capture && (
              <SessionCreatePublishDialog
                capture={capture as CapturePayload}
                isUpload={isUpload}
                onClose={() => setCapture(false)}
                onFinished={() => setCapture(true)}
              />
            )}
          </ArtifactStyle>
        );
      }}
    </Formik>
  );
}
