import React from 'react';

import Guacamole from 'guacamole-common-js';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import BackdropLoader, { BackdropLoaderContainer } from 'snap-ui/BackdropLoader';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { Engage, Fingerprint } from 'lib/Engagement';

import { NAVIGATION_HEADER_HEIGHT, SESSION_VM_TOOLBAR_WIDTH } from 'module/Scaffold/Scaffold.const';

import { usePushSnack } from 'provider';

import { useMountedRef } from 'storage';

import { formatQueryString } from 'utilities/SearchParam';

import { CommissionedHost } from '../Session.type';
import { GuacClientState } from './SessionGuac.type';
import { getHostName } from './SessionVM.service';
import useClipboard from './useClipboard';
import useInput from './useInput';

const Container = styled(BackdropLoaderContainer)`
  position: relative;
  background-color: ${p => p.theme.palette.common.black};

  .display {
    height: 100%;
    width: 100%;
    cursor: none;
    overflow: hidden;
    position: relative;
    z-index: 10;
  }

  textarea {
    height: 1px;
    width: 1px;
    position: fixed;
    left: -10000px;
  }
`;

type Props = {
  host: CommissionedHost;
  connectionRef: (instanceId: string, client: Guacamole.Client, tunnel: Guacamole.Tunnel) => void;
};

/**
 * Makes use of client state but only idle and connected for the purpose of
 * resizing the client display.
 */
const GuacPanel = (props: Props) => {
  const displayRef = React.useRef<HTMLDivElement>();
  const textAreaRef = React.useRef<HTMLTextAreaElement>();
  const [client, setClient] = React.useState<Guacamole.Client>();
  const [, setTunnel] = React.useState<Guacamole.WebSocketTunnel>();
  const [state, setState] = React.useState<Guacamole.Client.State>(GuacClientState.Idle);
  const timeoutRef = React.useRef<NodeJS.Timeout>();
  const [error, setError] = React.useState<string>();
  const mounted = useMountedRef();
  useClipboard(client, state);
  useInput(displayRef, textAreaRef, client, state);

  const pushSnack = usePushSnack();

  const initializeClient = React.useCallback(() => {
    if (client) client.disconnect();
    setState(GuacClientState.Idle);

    const hostName = getHostName(props.host.guacamole_link);
    const url = `wss://${hostName}/guacamole/websocket-tunnel`;
    const _tunnel = new Guacamole.WebSocketTunnel(url);
    const _client = new Guacamole.Client(_tunnel);
    props.connectionRef(props.host.instance_key, _client, _tunnel);

    _client.onerror = function (status: Guacamole.Status) {
      if (mounted.current) setError(status?.message);
      _client.disconnect();
    };

    _client.onstatechange = (state: Guacamole.Client.State) => {
      if (mounted.current && state === GuacClientState.Connected) setState(GuacClientState.Connected);
    };

    const data = {
      token: props.host.guacamole_token,
      GUAC_DATA_SOURCE: 'json',
      GUAC_ID: props.host.name,
      GUAC_TYPE: 'c',
      GUAC_WIDTH: window.innerWidth - SESSION_VM_TOOLBAR_WIDTH,
      GUAC_HEIGHT: window.innerHeight - NAVIGATION_HEADER_HEIGHT,
      GUAC_DPI: 96,
      GUAC_TIMEZONE: 'America/New_York',
      GUAC_AUDIO: ['audio/L8', 'audio/L16'],
      GUAC_IMAGE: ['image/jpeg', 'image/png', 'image/webp']
    };

    const config = formatQueryString(data).replace('?', '');
    displayRef.current.replaceChildren(_client.getDisplay().getElement());

    if (mounted.current) {
      Engage.track(
        Fingerprint.of(Path.ThreatExecute)
          .withQualifier('guac panel init')
          .withData({
            guac: {
              width: data.GUAC_WIDTH,
              height: data.GUAC_HEIGHT,
              id: data.GUAC_ID,
              token: data.token
            }
          })
      );
      _client.connect(config);
      setClient(_client);
      setTunnel(_tunnel);
    }

    // Ignore mounted ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, props.host.guacamole_link, props.host.guacamole_token, props.host.name]);

  React.useEffect(() => {
    if (state == GuacClientState.Connected) {
      pushSnack(
        <>
          Double click the &quot;<strong>Start Capture</strong>&quot; desktop shortcut when you are ready to begin
          recording.
        </>,
        'info',
        'center',
        'bottom',
        null
      );
    }
  }, [state, pushSnack]);

  React.useEffect(() => {
    initializeClient();
    // Re-initialize when host VM is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.host]);

  React.useEffect(() => {
    return () => {
      client?.disconnect();
    };
  }, [client]);

  /**
   * I exist to keep guac vm display in sync with the window size.
   *
   * See https://github.com/apache/guacamole-client/blob/22fe53fde50fd139cb86091912e1ae50d348add8/guacamole/src/main/frontend/src/app/client/directives/guacClient.js#L444-L472
   */
  React.useEffect(() => {
    clearTimeout(timeoutRef.current);

    const resizeMe = () => {
      client.sendSize(window.innerWidth - NAVIGATION_HEADER_HEIGHT, window.innerHeight - NAVIGATION_HEADER_HEIGHT);
    };

    const handleEventTimeout = () => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(resizeMe, 750);
    };

    window.addEventListener('resize', handleEventTimeout);

    return () => {
      clearTimeout(timeoutRef.current);
      window.removeEventListener('resize', handleEventTimeout);
    };
  }, [client]);

  return (
    <Container className='GuacPanel'>
      <div className='display' ref={displayRef} onClick={handleContainerClick}></div>
      {state !== GuacClientState.Connected && <BackdropLoader fixed zIndexOption='backdropLow' />}
      <textarea id={props.host.instance_key} ref={textAreaRef} />
      {error && (
        <Alert severity='error' variant='outlined'>
          <AlertTitle>Client error</AlertTitle>
          {error}
        </Alert>
      )}
    </Container>
  );

  function handleContainerClick() {
    textAreaRef.current.focus();
  }
};

export default GuacPanel;
