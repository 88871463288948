import React from 'react';

import classnames from 'classnames';

import Card, { CardContent } from 'snap-ui/Card';
import { styled } from 'snap-ui/util';

type MetadataCardProps = {
  children: JSX.Element | JSX.Element[];
  className?: string;
};

const MetadataCard = styled(Card)`
  font-size: 0.9rem;

  .Section {
    padding: ${p => p.theme.spacing(2, 0)};
    margin: ${p => p.theme.spacing(6, 4)};

    dl {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: ${p => p.theme.spacing(3)};

      .success {
        color: ${p => p.theme.palette.success.main};
      }

      .error {
        color: ${p => p.theme.palette.error.main};
      }

      .versionLineage {
        color: ${p => p.theme.palette.warning.main};
      }

      & > div {
        display: grid;
        grid-template-columns: 40% 60%;
        grid-gap: ${p => p.theme.spacing(1)};
        line-height: 1;
        margin-bottom: ${p => p.theme.spacing(3)};
      }

      dt {
        display: grid;
        grid-template-columns: ${p => p.theme.spacing(5)} 1fr;
        align-items: start;

        .text {
          grid-column: 2;
          color: ${p => p.theme.palette.common.white};
        }

        .icon {
          grid-column: 1;
        }
      }

      dd {
        margin: 0;
        display: grid;
        grid-template-columns: 1fr ${p => p.theme.spacing(5)};
        align-items: start;
        grid-column-gap: ${p => p.theme.spacing(2)};

        .deploy-options {
          display: flex;
          justify-content: flex-end;

          .undeploy {
            margin-right: 5px;
          }

          .fa-rotate-exclamation {
            height: 1.3rem;
            width: ${p => p.theme.spacing(6)};
          }

          .versioning {
            color: ${p => p.theme.palette.warning.main};
            margin-left: 2px;
          }
        }

        .text {
          grid-column: 1;
        }

        .icon {
          grid-column: 2;
          padding: ${p => p.theme.spacing(0, 1)};
          color: ${p => p.theme.palette.common.white};
        }
      }
    }
  }

  .SectionTitle {
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;

    button {
      padding: ${p => p.theme.spacing(1, 3)};
    }
  }
`;

export default function MetadataPanel({ children, className }: MetadataCardProps): JSX.Element {
  return (
    <MetadataCard className={classnames('MetadataPanel', className)}>
      <CardContent>{children}</CardContent>
    </MetadataCard>
  );
}
