import Paper from 'snap-ui/Paper';
import { styled } from 'snap-ui/util';

import { IOCTypeCategory } from '../IOC.type';

export const IOCParserContainer = styled('div', { name: 'IOCParserContainer' })`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: calc(100vh - 208px); // 100px page padding, 64px snap header, 44px page header
  align-items: stretch;
  gap: ${p => p.theme.spacing(8)};

  @media (max-width: 1510px) {
    grid-template-columns: 1fr;
  }
`;

export const IOCExtractContainer = styled(Paper, { name: 'IOCExtractContainer' })`
  height: 100%;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(3)};

  padding: ${p => p.theme.spacing(5)};
  min-height: 300px;
  max-width: 50vw;

  @media (max-width: 1599px) {
    max-width: unset;
  }

  .scrape-row {
    display: flex;
    gap: ${p => p.theme.spacing(5)};

    .Unfurl-textfield {
      margin: auto 0;
    }

    & > div {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
      gap: ${p => p.theme.spacing(5)};
    }
  }

  .SyntaxEditor {
    min-height: 300px;

    span.token.${IOCTypeCategory.Hash} {
      color: ${p => p.theme.palette.mint.main};
    }

    span.token.${IOCTypeCategory.IP_Address} {
      color: ${p => p.theme.palette.error.main};
    }

    span.token.${IOCTypeCategory.Domain} {
      color: ${p => p.theme.palette.blue.main};
    }

    span.token.${IOCTypeCategory.URL} {
      color: ${p => p.theme.palette.yellow.main};
      span {
        color: ${p => p.theme.palette.yellow.main};
      }
    }
  }
`;

export const IOCExtractHeaderContainer = styled('div', { name: 'IOCExtractHeaderContainer' })`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 54px;

  .Alert {
    flex-basis: 25%;
  }
`;

export const IOCListContainer = styled('div')`
  display: contents;

  .TablePlaceholder {
    margin-top: -8px;
  }

  .IOCList-table {
    height: 100%;

    &.placeholder {
      height: unset;
      min-height: 56px;
    }
    thead.MuiTableHead-root {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .Table-row-cell .disabled {
      color: ${p => p.theme.palette.secondary.light};
    }
  }

  .warnings {
    text-align: center;
  }

  .MuiTableBody-root {
    overflow-y: scroll;
  }

  .icon-link {
    color: ${p => p.theme.palette.common.white};
  }
`;

export const IOCTranslatorContainer = styled(Paper, { name: 'IOCTranslatorContainer' })`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(4)};
  padding: ${p => p.theme.spacing(3, 5, 5, 5)};
  min-height: 300px;
  overflow-y: scroll;

  .IOCTranslator-toolbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${p => p.theme.spacing(3)};

    .IOCTranslator-search {
      min-width: 90px;
    }
  }

  .IOCTranslator-pagination ul {
    justify-content: center;
  }

  .IOCTranslator-integration {
    display: flex;
    gap: ${p => p.theme.spacing(2)};
    align-items: center;
  }

  .IOCTranslator-inputs {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .IOCTranslator-actions {
    display: flex;
    gap: ${p => p.theme.spacing(4)};
  }

  .IOCTranslator-checks {
    display: flex;
    gap: ${p => p.theme.spacing(6)};
    width: 100%;
  }

  .IOCTranslator-checkbox-label {
    display: flex;
    align-items: center;
    gap: ${p => p.theme.spacing(2)};

    &.${IOCTypeCategory.Hash} {
      color: ${p => p.theme.palette.mint.main};
    }
    &.${IOCTypeCategory.IP_Address} {
      color: ${p => p.theme.palette.error.main};
    }
    &.${IOCTypeCategory.Domain} {
      color: ${p => p.theme.palette.blue.main};
    }
    &.${IOCTypeCategory.URL} {
      color: ${p => p.theme.palette.yellow.main};
    }

    &.${IOCTypeCategory.Hash}.disabled,
      &.${IOCTypeCategory.IP_Address}.disabled,
      &.${IOCTypeCategory.Domain}.disabled,
      &.${IOCTypeCategory.URL}.disabled {
      color: ${p => p.theme.palette.secondary.light};
    }
  }

  .IOCTranslator-selector {
    width: 350px;
  }

  .IOCTranslator-query {
    position: relative;

    .SyntaxEditor {
      max-height: 200px;
    }

    span.token.${IOCTypeCategory.Hash} {
      color: ${p => p.theme.palette.mint.main};
    }

    span.token.${IOCTypeCategory.IP_Address} {
      color: ${p => p.theme.palette.error.main};
    }

    span.token.${IOCTypeCategory.Domain} {
      color: ${p => p.theme.palette.blue.main};
    }

    span.token.${IOCTypeCategory.URL} {
      color: ${p => p.theme.palette.yellow.main};
    }
  }

  @media (max-width: 1599px) {
    .IOCTranslator-query {
      max-width: 90vw;
    }
  }
`;
