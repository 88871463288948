import React from 'react';

import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import { useTheme } from 'snap-ui/util';

import { WINDOWS_DC_OS } from '../Session.const';
import { VictimHostButtonContainer } from './HostButtonContainer.style';

type Props = {
  disabled: boolean;
  onChange(values: string[]): void;
  values: string[];
};

export default function WindowsDCButton({ disabled, onChange, values }: Props) {
  const { palette } = useTheme();
  const isSelected = values.includes(WINDOWS_DC_OS);

  const handleChange = () => {
    const newValue = isSelected ? values.filter(v => v !== WINDOWS_DC_OS) : [...values, WINDOWS_DC_OS];
    onChange(newValue);
  };

  return (
    <Tooltip title={disabled ? 'There are no Windows DC machines available' : ''} wrap arrow placement='top'>
      <VictimHostButtonContainer
        selected={isSelected}
        onChange={handleChange}
        value={WINDOWS_DC_OS}
        disabled={disabled}
      >
        <Icon.Windows color={palette.common.white} />
        Windows DC
      </VictimHostButtonContainer>
    </Tooltip>
  );
}
