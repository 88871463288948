import React from 'react';

import { styled } from 'snap-ui/util';

import { percent as calcPercent } from 'utilities/NumberUtil';

import { LinearProgressStyled } from './Progress';

type ScrubberProps = {
  onClick(x: number): void;
};

const Container = styled('div')`
  .MuiLinearProgress-root {
    padding-top: 3px;
    background-color: transparent;
    opacity: 0.35;
    z-index: 1;
  }
  .MuiLinearProgress-bar {
    transition: none;
  }
`;

export default function Scrubber({ onClick }: ScrubberProps) {
  const [percent, setPercent] = React.useState(0);
  return (
    <Container
      onMouseEnter={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      onClick={handleClick}
    >
      <LinearProgressStyled variant='determinate' value={percent} color='error' />
    </Container>
  );

  function handleClick(event) {
    event.stopPropagation();
    onClick(getPositionPercent(event));
  }

  function handleMouseLeave() {
    setPercent(0);
  }

  function handleMouseMove(event) {
    setPercent(getPositionPercent(event));
  }

  function getPositionPercent(event) {
    return calcPercent(event.nativeEvent.layerX, event.currentTarget.offsetWidth);
  }
}
