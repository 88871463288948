import React from 'react';

import Card, { CardContent, CardHeader } from 'snap-ui/Card';
import Checkbox from 'snap-ui/Checkbox';
import Icon from 'snap-ui/Icon';
import { TooltipConstrained, TooltipProps } from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { ArtifactIcon } from 'module/Layout/Artifact.widgets';
import { MarkdownRead } from 'module/Widgets/Markdown';

import { useAuth, useUserCatalog } from 'provider';

import { ArtifactType } from 'types/common';

import { timeAgo } from 'utilities/TimeUtils';

import { DuckedArtifact } from '../Curation.type';

const TOOLTIP_DELAY = 550;

const CardContainer = styled(Card)`
  .MuiCardHeader-title {
    display: flex;
    gap: ${p => p.theme.spacing(3)};
    align-items: center;
  }

  .MarkdownRead {
    overflow-y: auto;
  }
`;

const NewItem = styled('span')`
  color: ${p => p.theme.palette.success.light};
  font-size: small;
  font-style: italic;
`;

const Subheader = styled('div')`
  margin-top: ${p => p.theme.spacing(3)};
  font-size: small;
`;

export function mapDuckKind(
  checked: boolean,
  item: DuckedArtifact,
  placement: TooltipProps['placement'],
  showExtraColumns: boolean,
  handler: (event: React.SyntheticEvent<Element, Event>, checked: boolean) => void
) {
  const row = [
    <div key={item.guid}>
      <Checkbox id={item.guid} onChange={handler} value={item.guid} checked={checked} />
      <ArtifactIcon type={item.duck} />
      {item.new && <NewItem>new</NewItem>}
    </div>,
    <PreviewSubset key={`${item.guid}-preview`} item={item} placement={placement} />
  ];
  if (showExtraColumns) row.push(<OrgName key={`${item.guid}-org`} item={item} />);

  return row;
}

export function mapColumnNames() {
  return [<Icon.Collection key='collection' className='checkbox-header-spacing' />, 'Title'];
}

function getToLink(item: DuckedArtifact) {
  if (item.duck === ArtifactType.Intel) return `${Path.Intelligence}/${item.guid}`;
  if (item.duck === ArtifactType.Session) return `${Path.Threat}/${item.guid}`;
  if (item.duck === ArtifactType.Analytic) return `${Path.Detection}/${item.guid}`;
  if (item.duck === ArtifactType.AttackScript) return `${Path.AttackScript}/${item.guid}`;
}

function PreviewSubset({ item, placement }: { item: DuckedArtifact; placement: TooltipProps['placement'] }) {
  return (
    <TooltipConstrained
      enterDelay={TOOLTIP_DELAY}
      enterNextDelay={TOOLTIP_DELAY}
      arrow
      placement={placement}
      title={
        <CardContainer>
          <CardHeader
            title={
              <>
                <ArtifactIcon type={item.duck} />
                {item.name}
              </>
            }
            subheader={<SubData item={item} />}
          />
          <CardContent>
            {item.description ? (
              <MarkdownRead value={item.description} restricted to={getToLink(item)} />
            ) : (
              'No description'
            )}
          </CardContent>
        </CardContainer>
      }
    >
      <div>{item.name}</div>
    </TooltipConstrained>
  );
}

function OrgName({ item }: { item: DuckedArtifact }) {
  const { permission: organizations } = useAuth();
  const organization = organizations?.find(o => o.id === item.organization_id)?.name;
  return <div>{organization}</div>;
}

function SubData({ item }: { item: DuckedArtifact }): JSX.Element {
  const { permission: organizations } = useAuth();
  const { users } = useUserCatalog();
  const author = users.find(u => u.id === item?.modified_by_id)?.name || item?.original_author || item?.author;
  const organization = organizations?.find(o => o.id === item.organization_id)?.name;
  const modified = timeAgo(item.modified);

  return (
    <Subheader>
      {author && <div>Author: {author}</div>}
      {organization && <div>Organization: {organization}</div>}
      {modified && <div>Last Modified: {modified}</div>}
    </Subheader>
  );
}
