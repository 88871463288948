import { Guid, Ident, Tracked, UserPublic } from 'types/common';

export enum ApiKeyScope {
  AnalyticDeployment = 'AnalyticDeployment',
  BASTestResults = 'BASTestResults',
  LocalSessionCapture = 'LocalSessionCapture',
  REST = 'REST'
}

export type ApiKey = {
  guid: Guid;
  creation: string;
  name: string;
  organization_id: Ident;
  scopes: ApiKeyScope[];
  integrations?: Tracked[];
  user?: UserPublic;
  app_user_id: Ident;
  last_used: string;
};

export type CreatedApiKey = ApiKey & {
  secret_key: string;
};

export type CreateApiKeyPayload = Pick<ApiKey, 'name' | 'scopes' | 'organization_id' | 'integrations'>;

export type OnCreateApiKey = {
  (payload: CreateApiKeyPayload): Promise<CreatedApiKey | void>;
};

export type OnDeleteApiKey = {
  (guid: Guid): Promise<void>;
};
