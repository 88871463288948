import { Alert as MUIAlert, AlertTitle as MUIAlertTitle } from 'snap-ui/Alert';
import { styled } from 'snap-ui/util';

export const Alert = styled(MUIAlert)`
  .MuiAlert-message {
    flex: 1;
  }
`;

export const AlertTitle = styled(MUIAlertTitle)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: ${p => p.theme.spacing(2)};

  button {
    grid-column: -1;
  }
`;
