import React from 'react';

import { Link } from 'react-router-dom';

import { GridColDef } from 'snap-ui/DataGrid';
import Placeholder from 'snap-ui/Placeholder';

import Path from 'constants/paths';

import { Discriminator, isDiscriminator } from 'module/Tag';
import { ThreatProfileTag, TagWeight } from 'module/ThreatProfile';

import { Guid, Ident } from 'types/common';

import { formatQueryString } from 'utilities/SearchParam';

import { ORDERED_PRIORITY } from '../const';
import { TablePriorityLabel } from '../style';
import { TagCoverage } from '../type';
import { convertToPercent } from '../util';

export const nameCol: GridColDef<{ name: string; type: Discriminator }> = {
  field: 'name',
  headerName: 'Name',
  flex: 1,
  renderCell: params => (
    <Link to={`${Path.Collection}/${params.row.type}/${encodeURIComponent(params.value)}`}>{params.value}</Link>
  )
};

export const mitreNameCol: GridColDef<{ name: string }> = {
  field: 'name',
  headerName: 'Name',
  valueGetter: params => params.row.name.split(': ').slice(1).join(': '),
  flex: 1,
  renderCell: params => (
    <Link to={`${Path.Collection}/${Discriminator.Attack}/${encodeURIComponent(params.row.name)}`}>{params.value}</Link>
  )
};

export const mitreIdCol: GridColDef<{ name: string }> = {
  field: 'mitre_id',
  headerName: 'ID',
  valueGetter: params => params.row.name.split(': ')[0],
  renderCell: params => (
    <Link to={`${Path.Collection}/${Discriminator.Attack}/${encodeURIComponent(params.row.name)}`}>{params.value}</Link>
  )
};

export const priorityCol = (
  isProfilePending: boolean,
  getProfile: (id: Ident | Guid) => ThreatProfileTag
): GridColDef => ({
  field: 'priority',
  headerName: 'Priority',
  minWidth: 200,
  renderCell: params =>
    isProfilePending ? (
      <Placeholder variant='text' width={30} />
    ) : (
      <TablePriorityLabel value={getProfile(params.id)?.score_label ?? ('Unprioritized' as TagWeight)} />
    ),
  valueGetter: params =>
    isProfilePending
      ? Object.values(TagWeight).length
      : ORDERED_PRIORITY.findIndex(v => v === getProfile(params.id)?.score_label)
});

type GetStatsFn = (id: Ident | Guid) => TagCoverage | undefined;

// SnapScore not currently supported by backend
// export const snapscoreCol = (getStats: GetStatsFn): GridColDef => ({
//   field: 'snapscore',
//   headerName: 'SnapScore',
//   minWidth: 125,
//   valueGetter: params => getStats(params.id)?.snapscore ?? 0
// });

export const deployedCol = (getStats: GetStatsFn): GridColDef<{ name: string; type: Discriminator }> => ({
  field: 'deployed',
  headerName: 'Deployed',
  valueGetter: params => getStats(params.id)?.deployed ?? 0,
  renderCell: params => (
    <Link
      to={{
        pathname: `${Path.Collection}/${
          isDiscriminator(params.row.type) ? params.row.type : Discriminator.Attack
        }/${encodeURIComponent(params.row.name)}`,
        search: formatQueryString({ deployedStatus: 'success' })
      }}
    >
      {params.value}
    </Link>
  )
});

export const recommendedCol = (getStats: GetStatsFn): GridColDef<{ name: string; type: Discriminator }> => ({
  field: 'recommended',
  headerName: 'Recommended',
  valueGetter: params => getStats(params.id)?.recommended ?? 0,
  renderCell: params => (
    <Link
      to={{
        pathname: `${Path.Collection}/${
          isDiscriminator(params.row.type) ? params.row.type : Discriminator.Attack
        }/${encodeURIComponent(params.row.name)}`,
        search: formatQueryString({ recommended: 'deployment' })
      }}
    >
      {params.value}
    </Link>
  )
});

export const coverageCol = (getStats: GetStatsFn): GridColDef => ({
  field: 'coverage',
  headerName: 'Coverage',
  renderCell: params => convertToPercent(params.value),
  valueGetter: params => getStats(params.id)?.score_coverage ?? 0
});

export const breadthCol = (getStats: GetStatsFn): GridColDef => ({
  field: 'breadth',
  headerName: 'Breadth',
  renderCell: params => convertToPercent(params.value),
  valueGetter: params => getStats(params.id)?.score_breadth ?? 0
});

export const depthCol = (getStats: GetStatsFn): GridColDef => ({
  field: 'depth',
  headerName: 'Depth',
  renderCell: params => convertToPercent(params.value),
  valueGetter: params => getStats(params.id)?.score_depth ?? 0
});
