import React from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import Icon from './Icon';
import { Action } from './type';
import { styled } from './util';

export { MuiLink as BaseLink };

const Container = styled(MuiLink, {
  shouldForwardProp: p => p !== 'primary'
})<any & { primary?: boolean }>`
  color: ${p => (p.primary ? p.theme.palette.primary.main : p.theme.palette.common.white)};
  :hover {
    color: ${p => (p.primary ? p.theme.palette.primary.dark : p.theme.palette.common.white)};
  }
`;

export type LinkProps = Action & {
  children: React.ReactNode;
  to: string | { pathname: string; search?: string; state?: any };
  ariaLabel?: string;
  target?: MuiLinkProps['target'];
  rel?: string;
  underline?: MuiLinkProps['underline'];

  /**
   * render the link with primary color other default to common white
   */
  primary?: boolean;
};

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  { children, ariaLabel, primary, ...others },
  ref
) {
  return (
    <Container aria-label={ariaLabel} component={RouterLink} underline='none' primary={primary} {...others} ref={ref}>
      {children}
    </Container>
  );
});

export default Link;

export type ExternalLinkProps = Omit<LinkProps, 'to'> & {
  href: string;
  icon?: boolean;
};

const ExternalLinkContainer = styled(Container)`
  text-decoration: unset;

  svg.link-icon {
    font-size: 0.56em; /* magic */
    padding-left: ${p => p.theme.spacing(1)};
    vertical-align: baseline;
  }
`;

export function ExternalLink({ children, icon, ...others }: ExternalLinkProps) {
  return (
    <ExternalLinkContainer target='_blank' rel='noopener noreferrer' {...others}>
      {children}
      {icon && <Icon className='link-icon' icon={faArrowUpRightFromSquare} />}
    </ExternalLinkContainer>
  );
}

const IconLinkContainer = styled(Link)`
  display: flex;
  gap: ${p => p.theme.spacing(2)};
  align-items: center;
`;

export function IconLink({ children, icon, ...props }: LinkProps & { icon: IconDefinition }) {
  return (
    <IconLinkContainer {...props}>
      <Icon icon={icon} size='xs' /> {children}
    </IconLinkContainer>
  );
}
