import { RJSFSchema } from '@rjsf/utils';
import { z } from 'zod';

import { Option } from 'snap-ui/Autocomplete';

import { JobType } from 'module/Job/Job.type';

import { AnalyticDeploymentDetail } from 'types/analytic';
import { Permitted } from 'types/auth';
import { Guid, Ident, Timestamp, Tracked } from 'types/common';

export interface Integration extends Permitted, Tracked {
  id: Ident;
  name: string;
  organization_id: Ident;
  url_template: string;
  type: string;
  type_label?: IntegrationTypeLabel;
  deployment_prefix_template?: string;
  deployment_targets?: IntegrationTarget[];
  search_targets?: IntegrationTarget[];
  hunt_targets?: IntegrationTarget[];
  extra_information: ExtraInformation;
  is_registered: boolean;
  is_alive: boolean;
  is_healthy: boolean;
  ioc_huntable: boolean;
  last_checkin: string;
  last_error: string;
  version?: string;
  compatible: boolean;
  deployment?: AnalyticDeploymentDetail;
  parameters?: Record<string, unknown>;
}

export type HuntableIntegration = Integration & {
  huntableDetections: number;
  incompatible: number;
};

export const IntegrationAction = {
  Hunt: 'hunt',
  Deploy: 'deploy',
  Search: 'search'
} as const;

export type IntegrationAction = (typeof IntegrationAction)[keyof typeof IntegrationAction];

export const CreateJobSchedulePayloadSchema = z.object({
  name: z.string(),
  criteria: z.string(),
  cron_trigger: z.string(),
  type: z.nativeEnum(JobType),
  analytic_filter: z.unknown(),
  parameters: z //parameters is dynamic, but will always have these two properties
    .object({
      auto_accept: z.boolean().optional(),
      max_results_per_query: z.number(),
      max_search_window_seconds: z.union([z.string(), z.number()]),
      raw_logs: z.string() //boolean string
    })
    .passthrough()
    .optional()
});

export type CreateJobSchedulePayload = z.infer<typeof CreateJobSchedulePayloadSchema>;

const CreateJobSchedulePayloadExtraSchema = CreateJobSchedulePayloadSchema.extend({
  extra_parameters: z.record(z.unknown())
});
export type CreateJobSchedulePayloadExtra = z.infer<typeof CreateJobSchedulePayloadExtraSchema>;

export const JobScheduleSchema = CreateJobSchedulePayloadSchema.extend({
  id: z.number(),
  analytic_filter: z.unknown(),
  enabled: z.boolean(),
  guid: z.string(),
  integration_guid: z.string(),
  integration_name: z.string(),
  last_dispatch: z.string(),
  next_dispatch: z.string()
});

export type BulkJobScheduleStatusPayload = {
  job_definition_guids: Guid[];
  enabled: boolean;
};

export type JobSchedule = z.infer<typeof JobScheduleSchema>;

export type IntegrationCount = {
  available: number;
  success: number;
  failed: number;
  pending: number;
  outdated: number;
};

export type IntegrationTarget = {
  /** combines the backend_key and output_format to identify the language syntax */
  native_key: string;
  /** consider if you need native_key instead */
  backend_key: string;
  config_updated: string;
  currently_recompiling: boolean;
  hidden: boolean;
  id: number;
  last_recompile: Timestamp;
  name: string;
  needs_recompile: boolean;
  supported: boolean;
};

export type IntegrationFormData = {
  name: string;
  organization_id: string;
  url_template?: string;
  schema_type: string;
  extra_information: ExtraInformation;
  type?: string;
  deployment_prefix_template?: string;
  deployment_targets?: IntegrationTarget[];
  search_targets?: IntegrationTarget[];
  hunt_targets?: IntegrationTarget[];
};

export type CreateIntegrationRequest = {
  name: string;
  url_template?: string;
  organization_id: string | number;
  deployment_prefix_template?: string;
  deployment_targets?: IntegrationTarget[];
  search_targets?: IntegrationTarget[];
  hunt_targets?: IntegrationTarget[];
  type?: 'CustomerManaged' | string;
  extra_information?: ExtraInformation;
  api_key_id?: Guid;
};

/**
 * An unknown number of key / values
 */
export type ExtraInformation = {
  [index: string]: string;
};

/**
 * The flattened SchemaCatalogResponse. A dictionary with key / value.
 * The key, i.e. index, will be a number as a string.
 * The key will match the language id from /api/signatures/language/
 */
export type SchemaCatalog = {
  type_map?: {
    [index: Ident]: string;
  };

  catalog: Record<string, IntegrationRJSFSchema>;
};
export type DeploymentSchemaCatalog = Record<string, RJSFSchema>;

export type IntegrationRJSFSchema = Omit<RJSFSchema, 'properties'> & {
  properties: Record<string, RJSFSchema['properties'][string] & { tab: string }>;
};

export type IntegrationSchemaPropertiesMap = Record<INTEGRATION_SCHEMA_TABS, IntegrationRJSFSchema['properties']>;

type TypeLabel = {
  display_name: string;
  short_name: string;
};

export type SchemaSupportedLanguageDetails = {
  search: number[];
  deploy: number[];
  hunt: number[];
  type_label: TypeLabel;
};

export type SchemaSupported = Record<string, SchemaSupportedLanguageDetails>;

export type JobIntegrationCatalogSchema = Record<string, RJSFSchema>;

type IntegrationTypeLabel = {
  display_name: string;
  short_name: string;
};

export type PlatformDetails = {
  languageId: Ident;
  languageName?: string;
  nativeKey?: string;
  platforms?: {
    typeLabel: TypeLabel;
    integrationType: string;
    hasVisibleIntegration: boolean;
    integration?: Integration;
  }[];
};

export type LanguageOption = {
  deploy: Option[];
  search: Option[];
  hunt: Option[];
};

export enum INTEGRATION_SCHEMA_TABS {
  Configuration = 'configuration',
  Deployment = 'deployment',
  Indicators = 'indicators'
}

export type IntegrationLanguageOption = Option & { warning?: boolean };

export type IntegrationOption = Omit<IntegrationLanguageOption, 'content'> & { type: string };
