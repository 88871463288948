import React from 'react';

import { Guid } from 'types/common';

import { AttackScriptRouterState, AttackScriptState } from './AttackScript.type';
import useAttackScript from './useAttackScript';

const AttackScriptContext = React.createContext<AttackScriptState>(null);

export function useAttackScriptCatalog() {
  const context = React.useContext(AttackScriptContext);

  if (!context) {
    throw new Error('useAttackScript must be used within a AttackScriptProvider');
  }

  return context;
}

export function AttackScriptProvider({
  guid,
  children,
  item,
  supplementalItem
}: { guid: Guid; children: React.ReactNode } & AttackScriptRouterState) {
  const context = useAttackScript(guid, item, supplementalItem);
  return <AttackScriptContext.Provider value={context}>{children}</AttackScriptContext.Provider>;
}
