import { Group } from 'types/auth';
import { Guid } from 'types/common';

import { snapattack } from '../snapattack';

const prefix = '/group';

/**
 * add a user to a group
 */
export function addToGroup(groupGuid: Guid, userGuid: Guid): Promise<void> {
  return snapattack.post(`${prefix}/${groupGuid}/members/`, { guids: [userGuid] }).then(r => r.data);
}

/**
 * remove a user from a group
 */
export function removeFromGroup(groupGuid: Guid, userGuid: Guid): Promise<void> {
  return snapattack.delete(`${prefix}/${groupGuid}/members/${userGuid}/`).then(r => r.data);
}

/**
 * Get groups the logged-in user has access to
 */
export function getGroups(): Promise<Group[]> {
  return snapattack.get(`${prefix}/`).then(r => r.data);
}
