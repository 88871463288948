import React from 'react';

import { FormControlLabel } from 'snap-ui/FormControl';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Radio from 'snap-ui/Radio';
import RadioGroup from 'snap-ui/RadioGroup';
import Tooltip from 'snap-ui/Tooltip';

import { SortOrder } from 'module/Feed/Feed.type';
import { FilterControl } from 'module/GlobalFilter';

import { useSortOrder } from 'provider';

const LABELS = {
  relevance: 'Relevance',
  modified: 'Modified Date',
  creation: 'Created Date'
};

export default function SortBy(): JSX.Element {
  const { sortOrder: order, setSortOrder } = useSortOrder();
  const [field] = (order || SortOrder.modified).split(':');

  const handleChange = (_e, value: SortOrder) => setSortOrder(value);

  return (
    <FilterControl>
      <FormLabel id='sortBy' className='title-tooltip'>
        Order By
        <Tooltip title={`Sorting by ${LABELS[field]} in descending order`} placement='right' arrow wrap>
          <Icon.Info />
        </Tooltip>
      </FormLabel>
      <RadioGroup name='sortByRadioGroup' aria-labelledby='sortBy' onChange={handleChange} value={order}>
        <FormControlLabel control={<Radio />} label={LABELS.relevance} value={SortOrder.relevance} />
        <FormControlLabel control={<Radio />} label={LABELS.modified} value={SortOrder.modified} />
        <FormControlLabel control={<Radio />} label={LABELS.creation} value={SortOrder.creation} />
      </RadioGroup>
    </FilterControl>
  );
}
