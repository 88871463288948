import React from 'react';

import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { Autocomplete, AutocompleteProps } from 'snap-ui/Autocomplete';

import { Discriminator, getLabel, useTagOptions } from '.';
import { TagOption } from './useTagOptions';

export type Props = Omit<AutocompleteProps, 'options' | 'label' | 'placeholder' | 'disableUserAdditions'> & {
  discriminator: Discriminator;
  enableUserAdditions?: boolean;
  includeSigmaNames?: boolean;
  includeTagNames?: boolean;
  onChange(value: TagOption[]): void;
  value: TagOption[];
};

export default function TagSigmaAutocomplete({
  disabled,
  discriminator,
  enableUserAdditions,
  includeSigmaNames,
  includeTagNames,
  onChange,
  value,
  ...others
}: Props) {
  const { options, searching, searchTerm, setSearchTerm } = useTagOptions(discriminator, {
    includeTagNames,
    includeSigma: includeSigmaNames
  });

  function handleChange(value: string | TagOption[]): void {
    if (!value) onChange(others.multiple ? [] : '');
    if (typeof value === 'string') {
      onChange(value);
    } else {
      const values = value.flatMap(val => {
        if (isEmpty(val.tags)) {
          return [
            {
              value: val.value,
              content: val.content,
              discriminator: val.discriminator,
              sigma_names: val.sigma_names
            }
          ];
        } else {
          return val.tags.map(tag => ({
            value: tag.sigma_name,
            content: tag.name,
            discriminator: val.discriminator,
            sigma_names: [tag.sigma_name]
          }));
        }
      });
      const unique = [...new Map(values.map(v => [v.value, v])).values()];

      onChange(unique);

      setSearchTerm('');
    }
  }

  function getDisplayValue(value: string[] | TagOption[]): AutocompleteProps['value'] {
    if (value === undefined || value === null) {
      return [] as TagOption[];
    }

    let mappedStringValues: TagOption[] = [];
    if (typeof value[0] === 'string') {
      mappedStringValues = value.map(v => {
        const option = options.find(o => o.sigma_names.includes(v));
        if (!option) {
          // display values as provided
          // such as in the initial load case
          return {
            content: v,
            label: v,
            value: v,
            discriminator,
            sigma_names: null
          };
        } else {
          const tag = option.tags.find(t => t.sigma_name === v);
          return {
            content: tag.name,
            label: v,
            value: v,
            discriminator,
            sigma_names: [v]
          };
        }
      });
    }

    const uniqueValues = [
      ...new Map(
        (isEmpty(mappedStringValues) ? (value as TagOption[]) : mappedStringValues).map(v => [v.content, v])
      ).values()
    ];
    return uniqueValues;
  }

  return (
    <Autocomplete
      className={classNames('Form-field', others.className)}
      multiple
      filterByValue
      onChange={handleChange}
      onInputChange={setSearchTerm}
      inputValue={searchTerm}
      searching={searching}
      value={getDisplayValue(value)}
      elevated
      options={options}
      label={getLabel(discriminator)}
      disableUserAdditions={!enableUserAdditions}
      disabled={disabled}
      testId={discriminator}
      {...others}
    />
  );
}
