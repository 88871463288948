import React from 'react';

import { useIDEValue } from 'module/IDE';
import { Discriminator, TagGroup } from 'module/Tag';
import TagSigmaAutocomplete from 'module/Tag/TagSigmaAutocomplete';

interface MitreTagsFieldProps {
  discriminator: Discriminator;
  readonly?: boolean;
  includeTagNames?: boolean;
}

export default function DynamicTagsField({
  discriminator,
  readonly,
  includeTagNames
}: MitreTagsFieldProps): React.ReactElement {
  const name: keyof TagGroup =
    discriminator === Discriminator.Attack
      ? 'attack_names'
      : discriminator === Discriminator.Actor
      ? 'actor_names'
      : discriminator === Discriminator.Software
      ? 'software_names'
      : discriminator === Discriminator.Vulnerability
      ? 'vulnerability_names'
      : undefined;
  const { value, setValue } = useIDEValue(name);

  return (
    <TagSigmaAutocomplete
      disabled={readonly}
      name={name}
      discriminator={discriminator}
      onChange={setValue}
      value={value || []}
      includeTagNames={includeTagNames}
    />
  );
}
