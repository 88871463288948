import React from 'react';

import { Link } from 'react-router-dom';

import Card, { CardActionArea, CardContent } from 'snap-ui/Card';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { Engage, Fingerprint } from 'lib/Engagement';

import { MarkdownRead } from 'module/Widgets/Markdown';

import { ContentPermission } from 'types/auth';
import { ArtifactType } from 'types/common';

import { useLandingCatalog } from './LandingProvider';

const Container = styled('div')`
  padding: ${p => p.theme.spacing(5)};
  border: 1px solid ${p => p.theme.palette.grey[700]}; // match Collection-content
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(3)};

  .LandingDisambiguate-title {
    margin-bottom: ${p => p.theme.spacing(4)};
  }

  .LandingDisambiguate-itemTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Content = styled(CardContent)`
  background: ${p => p.theme.palette.secondary.main}; // match feed Card
`;

export default function Disambiguate() {
  const { name, type, data } = useLandingCatalog();

  React.useEffect(() => {
    if (name && type) {
      Engage.track(
        Fingerprint.load(Path.Collection)
          .withData({
            content: ContentPermission.Read,
            artifact: ArtifactType.Landing,
            name,
            type,
            id: data.combined.id
          })
          .withQualifier('disambiguation')
      );
    }
  }, [data.combined.id, name, type]);

  return (
    <Container className='LandingDisambiguate'>
      <div className='LandingDisambiguate-title'>
        <Typography variant='h2' gutterBottom={false}>
          {name} (disambiguation)
        </Typography>
        <Typography variant='subtitle1'>This {type} alias refers to multiple items</Typography>
      </div>
      {data.items
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(d => (
          <Card key={d.name}>
            <CardActionArea component={Link} to={`${Path.Collection}/${type}/${d.name}`}>
              <Content>
                <Typography className='LandingDisambiguate-itemTitle' component='div' variant='h3'>
                  {d.name}
                </Typography>
                <MarkdownRead value={d.description} raw />
              </Content>
            </CardActionArea>
          </Card>
        ))}
    </Container>
  );
}
