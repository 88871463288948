import React from 'react';

import { useField } from 'formik';
import isEmpty from 'lodash/isEmpty';
import sum from 'lodash/sum';

import Fade from 'snap-ui/Fade';
import Paper from 'snap-ui/Paper';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import TextFieldFormik from 'module/Form/TextFieldFormik';
import { Discriminator } from 'module/Tag';
import { getTagWeightLabel, useThreatProfile } from 'module/ThreatProfile';

import { toPrecisionDecimal } from 'utilities/NumberUtil';

import { TAG_WEIGHTS } from '../../ThreatProfile.util';

const Container = styled(Paper, { name: 'TagBucketSizing' })`
  display: flex;
  flex-direction: column;
  padding: ${p => p.theme.spacing(5)};

  .tag-weight-buckets {
    display: flex;
    flex-direction: column-reverse;
    gap: ${p => p.theme.spacing(3)};
    padding: ${p => p.theme.spacing(3)};

    .tag-bucket-row {
      display: flex;
      gap: ${p => p.theme.spacing(3)};

      label,
      span {
        align-self: center;
      }

      label {
        width: 90px;
        font-weight: bold;
      }

      .tag-input {
        width: 80px;
      }
    }
  }

  .validation-error {
    min-height: 24px;
    font-size: 12px;
    color: ${p => p.theme.palette.error.main};
  }
`;

type ThreatProfileTagBucketSizingProps = {
  countVerbiageTemplate: string;
  name: string;
  title: string;
  type: Discriminator;
};

export default function ThreatProfileTagBucketSizing({
  countVerbiageTemplate,
  name,
  title,
  type
}: ThreatProfileTagBucketSizingProps) {
  const [field] = useField(name);
  const { groupedTags, tags } = useThreatProfile();
  const hasProfile = !isEmpty(tags);
  const tagCount = groupedTags[type]?.length || 0;
  const countVerbiage = countVerbiageTemplate.replace('{{COUNT}}', tagCount.toLocaleString());

  const difference = sum(Object.values(field.value)) - 100;
  const validationError = generateValidationError(difference);

  return (
    <Container>
      <Typography variant='h4'>{title}</Typography>
      {hasProfile && <p>{countVerbiage}</p>}
      <div className='tag-weight-buckets'>
        {TAG_WEIGHTS.map(tagWeight => {
          return (
            <div className='tag-bucket-row' key={`${name}_chunk_${tagWeight}`}>
              <label htmlFor={`${name}.${tagWeight}`}>{getTagWeightLabel(tagWeight, false)}</label>
              <TextFieldFormik
                className='tag-input'
                disabled={!hasProfile}
                label=''
                type='number'
                name={`${name}.${tagWeight}`}
                InputProps={{ inputProps: { min: 0 }, endAdornment: '%' }}
              />
              {hasProfile && <TagWeightCount name={`${name}.${tagWeight}`} total={tagCount} />}
            </div>
          );
        })}
      </div>
      <div className='validation-error'>
        <Fade in={!!validationError} timeout={500}>
          {validationError}
        </Fade>
      </div>
    </Container>
  );
}

function generateValidationError(difference: number) {
  if (!difference) return null;

  const absoluteDifference = Math.abs(difference);
  const overUnder = difference > 0 ? 'Over' : 'Under';
  const removeAdd = difference > 0 ? 'Remove' : 'Add';
  const fromTo = difference > 0 ? 'from' : 'to';
  const prioritySuffix = absoluteDifference > 1 ? 'one or more priorities' : 'a priority';

  return `${overUnder}-allocated by ${absoluteDifference}%.  ${removeAdd} ${absoluteDifference}% ${fromTo} ${prioritySuffix}.`;
}

function TagWeightCount({ name, total }: { name: string; total: number }) {
  const [field] = useField(name);
  const decimal = toPrecisionDecimal(field.value / 100.0, 2);

  return <span>about {toPrecisionDecimal(decimal * total, 0)} items</span>;
}
