// Review https://github.com/snapattack/snapattack/blob/develop/snapattack_be/snapattack/core/alchemy/filtering/filter.py#L172
export enum Ops {
  in = 'in', // same as any
  and = 'and',
  or = 'or',
  any = 'any', // same as in
  all = 'all',
  not = 'not_in',
  none = 'none',
  match = 'match',
  contains = 'contains',
  not_contains = 'not_contains',
  equals = 'equals',
  not_equals = 'not_equals',
  sort = 'sort',
  starts_with = 'startswith',
  ends_with = 'endswith',
  greater_than = 'greaterthan',
  greater_than_equal = 'greaterthanequal',
  less_than = 'lessthan',
  less_than_equal = 'lessthanequal',
  for_each = 'for_each',
  array_contains = 'array_contains',
  json_equals = 'json_equals',
  json_contains = 'json_contains',
  json_array_contains_any = 'json_array_contains_any',
  json_path_exists = 'json_path_exists'
}

export enum SortDirection {
  asc = 'asc',
  desc = 'desc'
}

// Sort fields are based on the object being queried.
// This enum can probably go away
export enum SortField {
  date = 'creation',
  score = 'score'
}

export type ItemOp = Ops.and | Ops.or | Ops.for_each;
export type FieldOp =
  | Ops.in
  | Ops.contains
  | Ops.not_contains
  | Ops.ends_with
  | Ops.starts_with
  | Ops.equals
  | Ops.not_equals
  | Ops.greater_than
  | Ops.less_than;

export type Order = {
  field: string;
  direction: SortDirection;
};

export type Filter = {
  op: Ops;
  field: string;
  value: string | number | (string | number)[];
  case_sensitive?: boolean;
};

export type CompositeFilter = {
  op: Ops;
  items: (Filter | CompositeFilter)[];
};

export type Query = Filter | CompositeFilter;

export function isCompositeFilter(query: Query): query is CompositeFilter {
  return 'items' in query;
}
