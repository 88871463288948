import React from 'react';

import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';

import { ActionIconButton } from 'snap-ui/Button';
import Paper from 'snap-ui/Paper';
import Placeholder from 'snap-ui/Placeholder';
import { styled } from 'snap-ui/util';

import { NOOP } from 'utilities/FunctionUtils';

import CopyButton from '../CopyButton';
import { BasicEditor } from './BasicEditor';

const PaperEditor = styled(Paper)`
  height: 100%;
  overflow-y: auto;

  &:hover:not(.disabled) {
    border: 1px solid ${p => p.theme.palette.common.white} !important;
  }

  &:focus-within {
    border: 2px solid ${p => p.theme.palette.primary.main} !important;
  }

  /* copied here from prism-diff-highlight plugin to modify selectors */
  & pre .token.deleted:not(.prefix) {
    background-color: rgba(255, 0, 0, 0.2);
    color: inherit;
  }

  & pre .token.inserted:not(.prefix) {
    background-color: rgba(0, 255, 128, 0.2);
    color: inherit;
  }
  /* end prism-diff-highlight */
`;

const SyntaxCopyContainer = styled('div')`
  position: relative;

  .SyntaxViewer-copy {
    position: absolute;
    top: ${p => p.theme.spacing(1)};
    right: ${p => p.theme.spacing(1)};
  }
`;

type SyntaxEditor = BasicEditor & {
  className?: string;
  elevation?: number;
  onCopy?(): void;
};

export default function SyntaxEditor({ className, elevation = 2, ...others }: SyntaxEditor): JSX.Element {
  return (
    <PaperEditor className={classnames('SyntaxEditor', className, { disabled: others.disabled })} elevation={elevation}>
      <BasicEditor {...others} />
    </PaperEditor>
  );
}

type SyntaxViewerProps = Omit<SyntaxEditor, 'disabled' | 'onChange'>;

export function SyntaxViewer({ disableCopyButton, value = '', ...props }: SyntaxViewerProps): JSX.Element {
  return (
    <SyntaxCopyContainer>
      <SyntaxEditor {...props} value={value} onChange={NOOP} disabled />
      {!disableCopyButton && (
        <CopyButton tooltip='Copy to clipboard' placement='top' value={value}>
          <ActionIconButton className='SyntaxViewer-copy' aria-label='Copy to clipboard' icon={faCopy} />
        </CopyButton>
      )}
    </SyntaxCopyContainer>
  );
}

type SyntaxCopyWrapperProps = React.PropsWithChildren<{ disableCopyButton?: boolean; value: string }>;
export function SyntaxCopyWrapper({ children, disableCopyButton, value = '' }: SyntaxCopyWrapperProps): JSX.Element {
  return (
    <SyntaxCopyContainer>
      {children}
      {!disableCopyButton && (
        <CopyButton tooltip='Copy to clipboard' placement='top' value={value}>
          <ActionIconButton className='SyntaxViewer-copy' aria-label='Copy to clipboard' icon={faCopy} />
        </CopyButton>
      )}
    </SyntaxCopyContainer>
  );
}

export const SyntaxPlaceholderContainer = styled(Paper)`
  padding: ${p => p.theme.spacing(4)};
`;
export function SyntaxViewerPlaceholder({ rows, animate = true }: { rows: number; animate?: boolean }): JSX.Element {
  const widths = ['80%', '90%', '80%', '70%', '90%', '80%', '80%', '70%', '70%', '80%', '90%', '80%'];
  return (
    <SyntaxPlaceholderContainer elevation={2}>
      {new Array(rows).fill(0).map((_item, index) => (
        <Placeholder
          key={`placeholder_${index}`}
          variant='text'
          width={widths[index % widths.length]}
          animation={animate ? 'pulse' : false}
        />
      ))}
    </SyntaxPlaceholderContainer>
  );
}
