import React from 'react';

import { theme } from 'snap-ui/theme';
import { CssBaseline, ThemeProvider } from 'snap-ui/util';

function MaterialProvider({ children }: { children: React.ReactNode }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      {children}
    </ThemeProvider>
  );
}

export { MaterialProvider };
