import React from 'react';

import { faGear } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import Checkbox from 'snap-ui/Checkbox';
import Icon from 'snap-ui/Icon';
import Table, { TablePlaceholder } from 'snap-ui/Table';
import TableSortLabel from 'snap-ui/TableSortLabel';
import Tooltip from 'snap-ui/Tooltip';

import Path from 'constants/paths';

import useSort from 'hooks/useSort';

import { convertTagsToTagNames } from 'module/Tag';
import TagList from 'module/Tag/TagList';

import { SortDirection } from 'types/filter';

import { IOC, SupportedIOC } from '../IOC.type';
import { IOCListContainer } from './IOCParser.style';

type IOCListProps = {
  className?: string;
  displayDefanged?: boolean;
  iocs: SupportedIOC[];
  selectedIOCs: IOC[];
  setSelectedIOCs(newSelectedIOCs: IOC[]): void;
  isLoading?: boolean;
};

export default function IOCList({
  className,
  displayDefanged = true,
  iocs,
  selectedIOCs,
  setSelectedIOCs,
  isLoading
}: IOCListProps): JSX.Element {
  const iocsWithTemplate = iocs.filter(i => i.hasTemplate);

  const { createSortHandler, order, sortedData } = useSort<SupportedIOC>(iocs, {
    direction: SortDirection.asc,
    field: 'type'
  });

  const handleCheckboxChange = React.useCallback(
    (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
      const ioc = iocs.find(i => i.name === event.currentTarget.id);
      const newSelectedIOCs = checked ? [...selectedIOCs, ioc] : selectedIOCs.filter(s => s.name !== ioc.name);
      setSelectedIOCs(newSelectedIOCs);
    },
    [iocs, selectedIOCs, setSelectedIOCs]
  );

  function handleToggleAllCheckbox(_event: React.SyntheticEvent<Element, Event>, checked: boolean) {
    setSelectedIOCs(checked ? [...iocsWithTemplate] : []);
  }

  function renderWarnings(ioc: SupportedIOC): JSX.Element {
    if (!ioc.hasTemplate) return renderUnsupportedWarning(ioc);
    else renderMispWarnings(ioc.misp_warning_lists);
  }

  function renderUnsupportedWarning(ioc: SupportedIOC): JSX.Element {
    return (
      <div className='warnings'>
        <Tooltip
          arrow
          title={
            <>
              This integration does not have a template configured for {ioc.type} indicators. Configure this in{' '}
              <Link to={Path.Integrations}>Integration Settings</Link>.
            </>
          }
          wrap
        >
          <Link className='icon-link' to={Path.Integrations}>
            <Icon icon={faGear} />
          </Link>
        </Tooltip>
      </div>
    );
  }

  function renderTagList(tags: IOC['tags']): JSX.Element {
    const tagNames = convertTagsToTagNames(tags);
    return (
      <TagList
        inline
        actor={tagNames.actor_names}
        attack={tagNames.attack_names}
        datasource={tagNames.datasource_names}
        software={tagNames.software_names}
        vulnerability={tagNames.vulnerability_names}
      />
    );
  }

  function renderMispWarnings(mispWarnings: string[]): JSX.Element {
    if (!mispWarnings || mispWarnings.length === 0) return null;

    const title =
      mispWarnings.length === 1 ? (
        <span>{mispWarnings[0]}</span>
      ) : (
        <ul>
          {mispWarnings.map(warning => (
            <li key={warning}>{warning}</li>
          ))}
        </ul>
      );

    return (
      <div className='warnings'>
        <Tooltip arrow title={title} wrap>
          <Icon.Warning />
        </Tooltip>
      </div>
    );
  }

  return (
    <IOCListContainer className={classnames('IOCList', className)}>
      <Table
        aria-label='IOCs'
        className={classnames('IOCList-table', { placeholder: isEmpty(iocs) })}
        component='div'
        columns={[
          <Checkbox
            key='check-all'
            disabled={isEmpty(iocs)}
            onChange={handleToggleAllCheckbox}
            checked={iocsWithTemplate.every(ioc => selectedIOCs.some(selectedIOC => selectedIOC.name === ioc.name))}
            intermediate={selectedIOCs.length > 0 && selectedIOCs.length !== iocsWithTemplate.length}
          />,
          <TableSortLabel
            disabled={isEmpty(iocs)}
            key='type'
            field='type'
            label='Type'
            sortHandler={createSortHandler('type')}
            order={order}
          />,
          'Value',
          'Tags',
          <>
            <div className='warnings'>Warnings</div>
          </>
        ]}
        rows={sortedData.map(ioc => [
          <Checkbox
            key={`${ioc.name}_check`}
            id={ioc.name}
            onChange={handleCheckboxChange}
            value={ioc.name}
            disabled={!ioc.hasTemplate}
            checked={selectedIOCs.some(selected => selected.name === ioc.name)}
          />,
          <DisabledTextContainer key={`${ioc.name}_type`} text={ioc.type} disabled={!ioc.hasTemplate} />,
          <DisabledTextContainer
            key={`${ioc.name}_value`}
            text={displayDefanged ? ioc.value : ioc.name}
            disabled={!ioc.hasTemplate}
          />,
          renderTagList(ioc.tags),
          renderWarnings(ioc)
        ])}
      />
      {isEmpty(iocs) && <TablePlaceholder count={6} animate={isLoading} />}
    </IOCListContainer>
  );
}

function DisabledTextContainer({ disabled, text }: { disabled: boolean; text: string }) {
  return <span className={classnames({ disabled })}>{text}</span>;
}
