import React from 'react';

import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import Accordion, { AccordionDetails, AccordionSummary } from 'snap-ui/Accordion';
import Chip from 'snap-ui/Chip';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';

import Path from 'constants/paths';

import { getArtifactIcon } from 'module/Layout/Artifact.helper';
import TruncateContainer from 'module/Layout/TruncateContainer';
import { Discriminator, SoftwareType } from 'module/Tag';
import TagLandingPreview from 'module/Widgets/TagLandingPreview';

import { LandingHyperTags } from '../Landing.type';
import { useLandingCatalog } from '../LandingProvider';

export default function SoftwareTags() {
  const { source, handleReset } = useLandingCatalog();

  if (isEmpty(source.software)) return null;

  const softTypes = source.software
    .sort((a, b) => a.type.localeCompare(b.type))
    .reduce((prev, curr) => {
      if (prev[curr.type])
        return {
          ...prev,
          [curr.type]: [...prev[curr.type], curr]
        };
      else
        return {
          ...prev,
          [curr.type]: [curr]
        };
    }, {} as LandingHyperTags);

  return (
    <Accordion className='LandingSection-accordion' defaultExpanded={source.software.length > 0} elevation={0}>
      <AccordionSummary className='LandingSection-accordionSummary' expandIcon={<Icon icon={faCaretUp} />}>
        <Typography variant='h4'>Software</Typography>
      </AccordionSummary>
      <AccordionDetails className='LandingSection-accordionDetails'>
        {Object.entries(softTypes).map(([key, value]) => (
          <div key={key}>
            <div>
              <Typography variant='subtitle1' className='section-title' color='GrayText'>
                {key}
              </Typography>
              <TruncateContainer>
                <div className={classNames('LandingSection-accordionDetails-section', key)}>
                  {value
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(v => (
                      <TagLandingPreview key={`${key}-${v.id}`} tag={v}>
                        <Chip
                          className={`LandingSection-chip software ${key.toLowerCase()}`}
                          label={v.name}
                          component={Link}
                          to={`${Path.Collection}/${Discriminator.Software}/${encodeURIComponent(v.name)}`}
                          clickable
                          onClick={handleReset}
                          icon={getArtifactIcon(v.type as SoftwareType, true)}
                        />
                      </TagLandingPreview>
                    ))}
                </div>
              </TruncateContainer>
            </div>
            <div className='flex-break'></div>
          </div>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
