import React from 'react';

import Typography from 'snap-ui/Typography';

import useTitle from 'hooks/useTitle';

import { DashboardPage } from 'module/Layout/Styled';
import May from 'module/May';
import withFunctionalPermission from 'module/Util/withFunctionalPermission';

import { FunctionalPermission } from 'types/auth';

import { useDeployedFeeds } from './';
import { Stats } from './DeployedHealth.widget';
import DeploymentErrorsTable from './Tables/DeploymentErrors';
import NeedTuningTable from './Tables/NeedTuning';
import OutdatedDeploymentsTable from './Tables/OutdatedDeployments';

const TITLE = 'Detection Health';

const DeployedHealth = withFunctionalPermission(function DeployedHealth(): JSX.Element {
  useTitle(TITLE);
  const { healthy, deploymentErrors, needTuning, outdatedDeployments, ...others } = useDeployedFeeds();

  return (
    <DashboardPage>
      <Typography variant='h1'>{TITLE}</Typography>
      <Stats healthy={healthy} outdated={outdatedDeployments} tuning={needTuning} errors={deploymentErrors} />
      <div>
        <Typography variant='h2' id='errors'>
          Deployment Errors
        </Typography>
        <DeploymentErrorsTable {...deploymentErrors} {...others} />
      </div>
      <May I={FunctionalPermission.Tuning}>
        <div>
          <Typography variant='h2' id='tuning'>
            Needs Tuning
          </Typography>
          <NeedTuningTable {...needTuning} {...others} />
        </div>
      </May>
      <div>
        <Typography variant='h2' id='outdated'>
          New Versions Available
        </Typography>
        <OutdatedDeploymentsTable {...outdatedDeployments} {...others} />
      </div>
    </DashboardPage>
  );
}, FunctionalPermission.DetectionHealthDashboard);

export default DeployedHealth;
