import React from 'react';

import { faFilter, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Box from 'snap-ui/Box';
import Button from 'snap-ui/Button';
import Tooltip from 'snap-ui/Tooltip';

import { Collection } from 'module/Collection/Collection.type';
import { useFilterRegistry } from 'module/GlobalFilter';

import { ArtifactType } from 'types/common';

import { renderValueWithLabel } from 'utilities/TextUtils';

import FilterModeCollectionEdit from './FilterModeCollectionEdit';
import FilterModeCollectionSelect from './FilterModeCollectionSelect';

type Props = {
  appliedFilterCount: number;
  onClear(): void;
  enableCollection: boolean;
};

export default function FilterMode({ appliedFilterCount: count, onClear, enableCollection }: Props) {
  const { fromQuery } = useFilterRegistry(undefined, undefined, false);
  const [isCollection, setIsCollection] = React.useState(false);
  const [collection, setCollection] = React.useState<Collection>();

  const handleFilterClick = () => {
    setIsCollection(prev => {
      if (prev) {
        onClear();
        setCollection(null);
      }
      return !prev;
    });
  };

  const handleChange = (collection: Collection) => {
    onClear();
    setCollection(collection);

    if (collection.analytic_filter) {
      fromQuery(ArtifactType.Analytic, collection.analytic_filter);
    } else if (collection.session_filter) {
      fromQuery(ArtifactType.Session, collection.session_filter);
    } else if (collection.threat_intelligence_filter) {
      fromQuery(ArtifactType.Intel, collection.threat_intelligence_filter);
    } else if (collection.bas_script_filter) {
      fromQuery(ArtifactType.AttackScript, collection.bas_script_filter);
    } else if (collection.collection_filter) {
      fromQuery(ArtifactType.Collection, collection.collection_filter);
    }
  };

  const handleEditChange = (collection: Collection) => {
    if (collection === null) {
      setCollection(null);
      onClear();
    } else {
      setIsCollection(true);
      handleChange(collection);
    }
  };

  function renderClearButton() {
    return (
      <Button
        variant='outlined'
        disabled={count === 0}
        id='FilterNamed'
        ariaLabel='Filter named'
        onClick={onClear}
        fullWidth
      >
        {`${renderValueWithLabel(count, 'Filter')} Applied`}
      </Button>
    );
  }

  return enableCollection ? (
    <Box
      sx={{
        display: 'flex',
        gap: 1
      }}
    >
      {isCollection ? (
        <FilterModeCollectionSelect onChange={handleChange} value={collection?.guid} />
      ) : (
        <Tooltip arrow placement='top' title={count === 0 ? '' : 'Clear filters'}>
          {renderClearButton()}
        </Tooltip>
      )}
      <Tooltip arrow placement='bottom' title={isCollection ? 'Clear saved filter' : 'Apply a saved filter'}>
        <Button
          sx={{
            minWidth: '35px'
          }}
          id='FilterCollectionButton'
          ariaLabel={isCollection ? 'Clear filter collection' : 'Apply a saved filter'}
          onClick={handleFilterClick}
        >
          <FontAwesomeIcon icon={isCollection ? faTimes : faFilter} />
        </Button>
      </Tooltip>
      <FilterModeCollectionEdit collection={collection} onChange={handleEditChange} count={count} />
    </Box>
  ) : (
    <Tooltip arrow placement='top' title={count === 0 ? '' : 'Clear filters'}>
      {renderClearButton()}
    </Tooltip>
  );
}
