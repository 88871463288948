import React from 'react';

import { GridColDef } from 'snap-ui/DataGrid';
import Placeholder from 'snap-ui/Placeholder';

import { SupplementalArtifact } from 'module/Search';
import { IOCBadge } from 'module/Widgets/ReferenceCounts';

import { Artifact, ArtifactType } from 'types/common';

import { NameContainer } from './DisplayGrid.helper';

export const getGridColumnsIntel = (
  supplementalPending: boolean,
  getSupplemental: (value: string) => SupplementalArtifact
) => {
  const GridColumnsIntel: (GridColDef<Artifact> & { hideToStart?: boolean })[] = [
    {
      field: 'name',
      headerName: 'Name',
      hideable: false,
      width: 500,
      renderCell: cell => <NameContainer artifact={cell.row} topic={ArtifactType.Intel} value={cell.value} />
    },
    {
      field: 'iocs',
      headerName: 'IOCs',
      sortable: true,
      filterable: false,
      hideable: true,
      width: 100,
      hideToStart: true,
      valueGetter: p => {
        if (supplementalPending) return null;
        return getSupplemental(p.row.guid)?.indicator_count || 0;
      },
      renderCell: cell => {
        if (supplementalPending) return <Placeholder variant='text' width={40} />;

        return <IOCBadge iocs={cell.value} />;
      }
    }
  ];

  return GridColumnsIntel;
};
