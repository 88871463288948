import React from 'react';

import { Alert, AlertTitle } from 'snap-ui/Alert';

import Path from 'constants/paths';

import useTitle from 'hooks/useTitle';

import { Engage, Fingerprint } from 'lib/Engagement';

import May from 'module/May';
import withSubscriberOnly from 'module/Setting/withSubscriberOnly';

import { useIntegrationCatalog } from 'provider';

import { FunctionalPermission } from 'types/auth';
import { Guid } from 'types/common';

import { CreateApiKeyPayload, CreatedApiKey } from './ApiKeys.type';
import ApiKeysList from './ApiKeysList';
import useApiKeys from './useApiKeys';

function ApiKeys(): React.ReactElement {
  useTitle('API Keys | SnapAttack');
  const { apiKeys: keys, create, remove, status, error, createdApiKey, setCreatedApiKey } = useApiKeys();
  const { refresh: refreshIntegrations } = useIntegrationCatalog();

  React.useEffect(() => {
    Engage.track(Fingerprint.load(Path.APIKeys));
  }, []);

  return (
    <>
      {!!error && <Alert severity='error'>{error}</Alert>}
      <May I={FunctionalPermission.CreateAPIKey}>
        <May.Yes>
          <ApiKeysList
            status={status}
            keys={keys}
            onCreate={handleCreateKey}
            onDelete={handleDeleteKey}
            createdApiKey={createdApiKey}
            setCreatedApiKey={setCreatedApiKey}
          />
        </May.Yes>
        <May.No>
          <Alert severity='info' width='max-content'>
            <AlertTitle>Not authorized</AlertTitle>
            You don&apos;t have permission to create API Keys. Please contact your organization administrator to request
            access.
          </Alert>
        </May.No>
      </May>
    </>
  );

  function handleCreateKey(key: CreateApiKeyPayload): Promise<CreatedApiKey | void> {
    if (key.integrations?.length) refreshIntegrations();
    return create(key);
  }

  function handleDeleteKey(keyGuid: Guid): Promise<void> {
    return remove(keyGuid);
  }
}

export default withSubscriberOnly(ApiKeys);
