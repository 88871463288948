export type NodeDataType = {
  id: string;
  process_name: string;
  selected: boolean;
  selected_red: boolean;
  row_id: string;
  class?: string;
  filtered?: boolean;
  filterCollapse?: boolean;
  found?: boolean;
  found_red?: boolean;
  children: NodeDataType[];
  _id: string;
  depth: number;
  _children: NodeDataType[];
  _collapsed: boolean;
  x: number;
  y: number;
  parent: NodeDataType;
  maliciousness: number;
  prevalence: number;
  start_time_epoch: number;
  red_markers: object[];
  blue_markers: object[];
  condensed: boolean;
  condenseNode: boolean;
  message: string;
  UtcTime: string;
  forceOpen: boolean;
  rootNode: boolean;
};

export type ZoomOptions = {
  zoomDirection: Zoom;
  zoom: number;
  maxHeight: number;
  minHeight: number;
  maxWidth: number;
  minWidth: number;
  translate: {
    x: number;
    y: number;
  };
};

export enum Zoom {
  FIT = 'fit',
  FOCUS = 'focus',
  SINGLE_FIT = 'single_fit'
}

export type MinMax = {
  yMin: number;
  yMax: number;
  xMin: number;
  xMax: number;
};

export type Field = {
  label: string;
  name: string;
  multiline?: boolean;
  link?: string;
};

export enum BadgeType {
  PREVALENCE,
  MALICIOUS
}
