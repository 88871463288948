import React from 'react';

import { getSchemaSupported } from 'module/Integration/Integration.api';
import { SchemaSupported } from 'module/Integration/Integration.type';

import { useIntegrationCatalog, useLanguageContext } from 'provider';

import { Status, useAsync } from 'storage';

import { Ident } from 'types/common';

import { PlatformDetails } from './Integration.type';
import { getPlatformDetailsByLanguage } from './Integration.util';

export type LanguagePlatformCatalog = {
  supportedPlatforms: SchemaSupported;
  getPlatformDetails(languageId: Ident): PlatformDetails | undefined;
  status: Status;
};

const LanguagePlatformContext = React.createContext<LanguagePlatformCatalog>(null);
LanguagePlatformContext.displayName = 'IntegrationContext';

function _useLanguagePlatforms(): LanguagePlatformCatalog {
  const { allData: languages, status: languageStatus } = useLanguageContext();
  const { integrations, status: integrationStatus } = useIntegrationCatalog();
  const { data: supportedPlatforms, status, run } = useAsync<SchemaSupported>({});

  const getPlatformDetails = React.useCallback(
    (languageId: Ident): PlatformDetails => {
      if (!languageId) return;
      if (![languageStatus, integrationStatus, status].every(s => s === Status.resolved)) return;
      return getPlatformDetailsByLanguage(languageId, languages, supportedPlatforms, integrations.all);
    },
    [supportedPlatforms, integrations.all, languages, integrationStatus, languageStatus, status]
  );

  React.useEffect(() => {
    run(getSchemaSupported());
  }, [run]);

  return {
    supportedPlatforms,
    getPlatformDetails,
    status
  };
}

export function LanguagePlatformProvider({ children }: { children: React.ReactNode }): React.ReactElement {
  const catalog: LanguagePlatformCatalog = _useLanguagePlatforms();

  return <LanguagePlatformContext.Provider value={catalog}>{children}</LanguagePlatformContext.Provider>;
}

export function useLanguagePlatforms(): LanguagePlatformCatalog {
  const context = React.useContext<LanguagePlatformCatalog>(LanguagePlatformContext);

  if (!context) {
    throw new Error('useLanguagePlatforms must be used within the LanguagePlatformContext');
  }

  return context;
}
