import React from 'react';

import PieChart from 'snap-ui/Charts/PieChart';

import { BASCampaign } from 'module/BAS/BAS.type';

import { ArtifactScore } from 'types/common';

import { getFlatObjectCount } from '../../BAS.util';
import { ConfidenceSeverityChartColors, PieChartDefaultProperties } from '../CampaignReport.style';
import ReportPanel from './ReportPanel';

type ConfidencePanelProps = {
  campaign: BASCampaign;
};

const DATA_EMPTY_MESSAGE = 'Assigning detections when adding/editing Outcomes below will populate this chart.';

function ConfidencePanel({ campaign }: ConfidencePanelProps) {
  const confidenceEmpty = getFlatObjectCount(campaign.ranks) === 0;

  const confidenceChartData = React.useMemo(() => {
    if (!campaign.ranks) return [];
    return [
      { name: ArtifactScore.HIGHEST, value: campaign.ranks[ArtifactScore.HIGHEST] },
      { name: ArtifactScore.HIGH, value: campaign.ranks[ArtifactScore.HIGH] },
      { name: ArtifactScore.MEDIUM, value: campaign.ranks[ArtifactScore.MEDIUM] },
      { name: ArtifactScore.LOW, value: campaign.ranks[ArtifactScore.LOW] },
      { name: ArtifactScore.LOWEST, value: campaign.ranks[ArtifactScore.LOWEST] },
      { name: ArtifactScore.UNKNOWN, value: campaign.ranks[ArtifactScore.UNKNOWN] }
    ];
  }, [campaign.ranks]);

  return (
    <ReportPanel title='Confidence' hasError={confidenceEmpty} error={DATA_EMPTY_MESSAGE}>
      <PieChart colors={ConfidenceSeverityChartColors} data={confidenceChartData} {...PieChartDefaultProperties} />
    </ReportPanel>
  );
}

export default ConfidencePanel;
