import React from 'react';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import zod from 'zod';

import { Autocomplete, getDisplayValue, Option } from 'snap-ui/Autocomplete';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormLabel from 'snap-ui/FormLabel';
import Radio from 'snap-ui/Radio';
import RadioGroup from 'snap-ui/RadioGroup';

import { COMMUNITY_ORGANIZATION, SUBSCRIBER_ORGANIZATION_ID } from 'constants/auth';

import { FilterControl } from 'module/GlobalFilter';
import OrganizationAutocompleteOptions from 'module/Widgets/OrganizationAutocompleteOptions';

import { useAuth } from 'provider';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';

import { FilterConfig } from '../GlobalFilter.type';

type OrganizationsKeys = {
  organizations: string[];
  organizationOp: Ops;
};

type OrganizationsFilterProps = {
  disabled?: boolean;
  onChange(values: Partial<OrganizationsKeys>): void;
  values: OrganizationsKeys;
};

function OrganizationsFilter({ onChange, values }: OrganizationsFilterProps): React.ReactElement {
  const { organizations, organizationOp } = values;
  const { isSubscriber, defaultOrgId, permission: authorizedOrgs } = useAuth();
  const defaultOrg = authorizedOrgs.find(org => org.id === defaultOrgId);
  const showDefaultOrg =
    defaultOrgId && defaultOrgId !== COMMUNITY_ORGANIZATION.id && defaultOrgId !== SUBSCRIBER_ORGANIZATION_ID;

  const [selection, setSelection] = React.useState('all');
  const optionMap = authorizedOrgs
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(org => ({
      label: org.name,
      content: <OrganizationAutocompleteOptions guid={org.guid} text={org.name} />,
      value: org.id.toString()
    }));

  const handleRadioChange = (_event: React.ChangeEvent<HTMLInputElement>, newValue: string): void => {
    setSelection(newValue);

    switch (newValue) {
      case 'all':
        onChange({ organizations: [], organizationOp: undefined });
        break;
      case 'community':
        onChange({ organizations: [COMMUNITY_ORGANIZATION.id.toString()], organizationOp: organizationOp || Ops.in });
        break;
      case 'subscribers':
        onChange({ organizations: [SUBSCRIBER_ORGANIZATION_ID.toString()], organizationOp: organizationOp || Ops.in });
        break;
      case 'default':
        onChange({ organizations: [defaultOrgId.toString()], organizationOp: undefined });
        break;
      default:
        break;
    }
  };

  const handleListChange = (option: Option[]) => {
    onChange({ organizations: option.map(o => o.value), organizationOp });
  };

  const handleClick = () => {
    if (selection === 'all') {
      onChange({ organizations: [], organizationOp: undefined });
      setSelection('organization');
    }
  };

  const handleOperatorChange = (_event: React.ChangeEvent<HTMLInputElement>, organizationOp: Ops) => {
    onChange({ organizations, organizationOp });
  };

  React.useEffect(() => {
    if (isEmpty(organizations)) {
      setSelection('all');
    } else if (isEqual(organizations, [COMMUNITY_ORGANIZATION.id.toString()])) {
      setSelection('community');
    } else if (isEqual(organizations, [SUBSCRIBER_ORGANIZATION_ID.toString()])) {
      setSelection('subscribers');
    } else if (showDefaultOrg && isEqual(organizations, [defaultOrgId?.toString()])) {
      setSelection('default');
    } else {
      setSelection('organization');
    }
  }, [defaultOrgId, showDefaultOrg, organizations]);

  return (
    <FilterControl className='OrganizationsFilter'>
      <FormLabel id='organizationGroup'>By Organization</FormLabel>
      <RadioGroup
        aria-labelledby='organizationGroup'
        name='organizationRadioGroup'
        value={selection}
        onChange={handleRadioChange}
      >
        <FormControlLabel value='all' control={<Radio />} label='All My Organizations' />
        <FormControlLabel value='community' control={<Radio />} label='SnapAttack Community' />
        {(isSubscriber || authorizedOrgs.find(org => org.id === SUBSCRIBER_ORGANIZATION_ID)) && (
          <FormControlLabel value='subscribers' control={<Radio />} label='SnapAttack Subscribers' />
        )}
        {showDefaultOrg && <FormControlLabel value='default' control={<Radio />} label={defaultOrg.name} />}
        <FormControlLabel value='organization' control={<Radio />} label='Following Organizations' />
      </RadioGroup>
      <Autocomplete
        name='Organizations select filter'
        options={optionMap}
        value={getDisplayValue(optionMap, organizations)}
        multiple
        onClick={handleClick}
        onChange={handleListChange}
        disableUserAdditions
      />
      {values.organizations?.length > 0 && (
        <RadioGroup
          className='radio-group'
          aria-labelledby='organization-op-group'
          defaultValue={organizationOp}
          name='organization-op-group'
          value={organizationOp}
          onChange={handleOperatorChange}
        >
          <FormControlLabel value={Ops.in} control={<Radio />} label='OR' />
          <FormControlLabel value={Ops.not} control={<Radio />} label='NOT' />
        </RadioGroup>
      )}
    </FilterControl>
  );
}

function toQuery(values: OrganizationsKeys) {
  if (!values.organizations?.length) return;
  return {
    field: 'organization_id',
    op: values.organizationOp,
    value: values.organizations.map(Number)
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('organization_id'),
    op: zod.literal(Ops.in),
    value: zod.array(zod.number())
  })
  .transform(query => ({
    organizations: query.value.map(String),
    organizationOp: query.op
  }));

const OrganizationsFilterConfig: FilterConfig<OrganizationsKeys> = {
  defaults: { default: () => ({ organizations: [], organizationOp: Ops.in }) },
  supportedTopics: [
    ArtifactType.Session,
    ArtifactType.Analytic,
    ArtifactType.Intel,
    ArtifactType.Collection,
    ArtifactType.AttackScript,
    ArtifactType.Marker
  ],
  component: OrganizationsFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default OrganizationsFilterConfig;
