import React from 'react';

import { useIDEValue } from 'module/IDE';
import DelayedTextField from 'module/Widgets/DelayedTextField';

interface DescriptionFieldProps {
  readonly?: boolean;
}

function DescriptionField({ readonly }: DescriptionFieldProps): React.ReactElement {
  const { value, setValue } = useIDEValue('description');
  const description = (value ?? '').replace(/^\n$/, '');

  return (
    <DelayedTextField
      className='Form-field required'
      elevated
      label='Description'
      multiline
      minRows={6}
      name='description'
      placeholder='A short description of the rule and the malicious activity that can be detected'
      disabled={readonly}
      value={description}
      onChange={v => setValue(v)}
      data-testid='description'
    />
  );
}

export default DescriptionField;
