import { AxiosError } from 'axios';

import { snapattack } from 'apis/snapattack';

import { Guid } from 'types/common';

import { sleep } from 'utilities/FunctionUtils';

const prefix = '/cyberai/graph';

export type Graph = any; // No schema available. Endpoint returns a file.

export async function getProcessGraph(guid: Guid, previousResponse = null): Promise<Graph> {
  let response;
  try {
    response = await snapattack.get(`${prefix}/${guid}/`);
  } catch (error) {
    response = (error as AxiosError).response;
  }

  // this function is executed recursively, with a reference to
  // the response of the previous execution. The initial call to create
  // a process graph responds with status 202; subsequent calls respond with
  // status 409 until a 200 or 202 is received. If the status codes
  // flip from 409 to 202, the back end has an issue and is stuck in a loop.
  if (previousResponse?.status === 409 && response.status === 202) {
    throw new Error('Process graph stuck in processing loop', { cause: previousResponse });
  } else {
    // recursive retry occurring every 5 seconds
    if ([202, 409].includes(response.status)) {
      await sleep(5000);
      return getProcessGraph(guid, response);
    }
  }

  return response.data;
}
