import React from 'react';

import { Link } from 'react-router-dom';

import Typography from 'snap-ui/Typography';

import Path from 'constants/paths';

import { User } from 'types/auth';

import { RegisterLink } from './Register';

export function LoginRegisterLinks() {
  return (
    <div className='login-link'>
      <Link to={Path.Login}>Log in</Link>
      <RegisterLink>
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
      </RegisterLink>
    </div>
  );
}

export function LoginRegisterPanel() {
  return (
    <div className='pp-login-bg'>
      <div className='pp-container-overlay'>
        <img className='pp-img-overlay' src={process.env.PUBLIC_URL + '/images/snapattack.png'} alt='SnapAttack' />
        <Typography variant='h3'>
          SnapAttack blends attack simulation, detection engineering, and integrations with the leading SIEM, EDR and
          XDR tools to quickly improve coverage and visibility across large diverse environments
        </Typography>
      </div>
    </div>
  );
}

export function superOnly(user: User) {
  return user?.superuser || user?.impersonator?.superuser;
}
