import React from 'react';

import { migrateSession, getMigrationStatus } from 'module/Session/Session.api';

import { useAsync, Status, useMountedRef } from 'storage';

import { CeleryState, Guid, Ident, CeleryTaskResponse } from 'types/common';

const POLL_INTERVAL = 5000;

type MigrateSessionState = {
  error?: string;
  isMigrating: boolean;
  message?: string;
  migrate(orgId: Ident): void;
  status: Status;
  taskStatus: CeleryState;
};

export default function useMigrateSession(sessionGuid: Guid): MigrateSessionState {
  const mounted = useMountedRef();
  const [isMigrating, setIsMigrating] = React.useState(false);
  const [message, setMessage] = React.useState<string>();
  const [taskStatus, setTaskStatus] = React.useState<CeleryState>();
  const { data, run, status, task, error } = useAsync({} as CeleryTaskResponse);

  const migrate = React.useCallback(
    (orgId: Ident): void => {
      setIsMigrating(true);
      setMessage('Preparing to move threat data...');
      run(migrateSession(sessionGuid, orgId));
    },
    [run, sessionGuid, setIsMigrating]
  );

  const pollMigrationStatus = React.useCallback(
    (taskGuid: Guid): void => {
      if (mounted.current) {
        task(
          getMigrationStatus(sessionGuid, taskGuid).then(migrationStatus => {
            setTaskStatus(migrationStatus.status);
            if (migrationStatus.status === CeleryState.pending) {
              setMessage('Preparing to move threat data...');
              setTimeout(() => {
                pollMigrationStatus(taskGuid);
              }, POLL_INTERVAL);
            } else if (migrationStatus.status === CeleryState.in_progress) {
              setMessage('Moving threat data...');
              setTimeout(() => {
                pollMigrationStatus(taskGuid);
              }, POLL_INTERVAL);
            } else if (migrationStatus.status === CeleryState.retrying) {
              setMessage('Preparing to move threat data...');
              setTimeout(() => {
                pollMigrationStatus(taskGuid);
              }, POLL_INTERVAL);
            } else if (migrationStatus.status === CeleryState.success) {
              setMessage('Success!');
              setIsMigrating(false);
            } else if (migrationStatus.status === CeleryState.failed) {
              setMessage(migrationStatus.output?.message || 'Oops. Something went wrong.');
              setIsMigrating(false);
            }
          })
        );
      }
    },
    [sessionGuid, task] // eslint-disable-line react-hooks/exhaustive-deps
  );

  React.useEffect(() => {
    if (data.task_id) {
      pollMigrationStatus(data.task_id);
    }
  }, [data, pollMigrationStatus]);

  return {
    error: error?.respose?.data?.message || '',
    isMigrating,
    message,
    migrate,
    status,
    taskStatus
  };
}
