import React from 'react';

import classNames from 'classnames';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import { styled } from 'snap-ui/util';

import { ValidationError } from 'apis';

import { StrictReactNode } from 'types/core';

const Container = styled(Alert)`
  margin: 48px auto;
  min-height: 2rem;
  width: 50%;
`;

type Props = {
  className?: string;
  title?: StrictReactNode;
  error: ValidationError;
  severity?: 'error' | 'warning' | 'success' | 'info';
};

function ValidationAlert({ error, severity = 'error', ...props }: Props) {
  if (!error) return null;

  const detail = Array.isArray(error.detail) ? error.detail : [error.detail || `Hmm... We need a closer look at this`];

  return (
    <Container severity={severity} className={classNames('ValidationAlert', props.className)}>
      {props.title && <AlertTitle>{props.title}</AlertTitle>}
      {detail.length === 1 ? (
        detail[0]
      ) : (
        <>
          <div>{detail.length} errors</div>
          <ul>
            {detail.map((e, index) => (
              <li key={index}>{typeof e === 'object' ? JSON.stringify(e) : e}</li>
            ))}
          </ul>
        </>
      )}
    </Container>
  );
}

export default ValidationAlert;
