import React from 'react';

import { ComposableMap, Geographies, Geography } from 'react-simple-maps';

import Tooltip from 'snap-ui/Tooltip';
import { useTheme } from 'snap-ui/util';

import { Country } from 'module/Landing/Landing.type';

import countries from './worldmap.json';

export default function ActorRegionMap({ regions }: { regions: Country[] }) {
  const { palette } = useTheme();

  return (
    <ComposableMap
      height={450}
      width={900}
      style={{ width: '100%', height: 'auto' }}
      projectionConfig={{
        center: [14, 10]
      }}
    >
      <Geographies geography={countries}>
        {({ geographies }) =>
          geographies.map(geo => {
            const targetedRegion = regions?.find(r => r.alpha2 === geo.properties[`Alpha-2`]);
            return (
              <Tooltip arrow placement='right' title={geo.properties.name} key={geo.rsmKey}>
                <Geography
                  geography={geo}
                  fill={targetedRegion ? palette.primary.dark : palette.grey[700]}
                  stroke={palette.grey[900]}
                  style={{
                    default: { outline: 'none' },
                    hover: { outline: 'none', fill: palette.primary.main },
                    pressed: { outline: 'none' }
                  }}
                />
              </Tooltip>
            );
          })
        }
      </Geographies>
    </ComposableMap>
  );
}
