import React, { ReactElement } from 'react';

import { Link } from 'react-router-dom';

import Box from 'snap-ui/Box';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { useFilterRegistry } from 'module/GlobalFilter';
import { MatrixPaletteOrdinal } from 'module/Scaffold/Scaffold.type';
import { Discriminator } from 'module/Tag';

import { ArtifactType } from 'types/common';

import MatrixCount from '../Matrix.count';
import Tooltip from '../Matrix.tooltip';
import { StatisticNode } from '../Matrix.type';
import { getFilterValuesOnlyQueryString, getNodeSpecificName, nodeTotal } from '../Matrix.util';

export type Props = {
  node: StatisticNode;
  showMitreId: boolean;
  showCount: boolean;
  showEmpty: boolean;
  topic: ArtifactType;
  palette: MatrixPaletteOrdinal;
  maxRange: number;
  perBucket: number;
};

const Container = styled('div')`
  top: 0;
  position: sticky;
  display: flex;
  z-index: 1;
  background-color: ${p => p.theme.palette.grey[900]};
`;

export const Hover = styled('div')`
  background-color: rgba(208, 141, 237, 0.2);
  height: 100%;
  width: 100%;
  margin: 16px 8px 8px 8px;
  padding: 12px;
  border-radius: 3px;

  &:hover {
    background-color: rgba(208, 141, 237, 0.3);
  }
`;

export const TacticName = styled(Typography)`
  white-space: nowrap;
  margin-bottom: unset;
`;

export const Id = styled('div', { shouldForwardProp: p => p !== 'tier' })<{ tier?: string }>`
  font-size: 12px;
  line-height: 12px;
  ${p => (p.tier ? 'color: ' + p.tier : '')}
`;

const Tactic = ({ node, showMitreId, showCount, showEmpty, topic }: Props): ReactElement => {
  const { values } = useFilterRegistry(topic);
  const name = getNodeSpecificName(node);

  // breakfix since backend high level counts are wrong
  // const total = nodeTotal(node);
  const total = node.attack_children.reduce((sum, obj) => sum + nodeTotal(obj), 0);

  const noChildrenHaveHits = node.attack_children?.every(
    tech => nodeTotal(tech) === 0 && tech.attack_children?.every(sub => nodeTotal(sub) === 0)
  );
  if (!showEmpty && total === 0 && noChildrenHaveHits) return null;

  return (
    <Container>
      <Tooltip count={total} mitre_id={name}>
        <Hover>
          <Link
            target='_blank'
            to={{
              pathname: `${Path.Collection}/${Discriminator.Attack}/${encodeURIComponent(
                name
              )}${getFilterValuesOnlyQueryString(values, window.location.search)}`
            }}
          >
            <TacticName variant='body1'>{name}</TacticName>
            {showMitreId && <Id>{node.mitre_id}</Id>}
            {showCount && (
              <Box sx={{ paddingTop: '0.5rem', float: 'right' }}>
                <MatrixCount count={total} topic={topic} />
              </Box>
            )}
          </Link>
        </Hover>
      </Tooltip>
    </Container>
  );
};

export default Tactic;
