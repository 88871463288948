import React from 'react';

import Form, { Templates } from '@rjsf/mui';
import { DescriptionFieldProps, RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { useField } from 'formik';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import FormControl, { FormHelperText } from 'snap-ui/FormControl';
import { styled } from 'snap-ui/util';

const StyledForm = styled(Form)`
  div.MuiPaper-elevation2 {
    background-image: inherit;
    background-color: inherit;
    box-shadow: unset;
    border: 1px solid ${p => p.theme.palette.divider};
  }
`;

type RjsfFormikProps = {
  schema: RJSFSchema;
  label?: string;
  name: string;
  /** If true, spreads new form data with current field value. If false, replaces with new form data */
  preserve?: boolean;
};

/**
 * Renders `description` as Formik HelperText, unless it's title for the input like a radio or checkbox
 */
function DescriptionFieldTemplate(props: DescriptionFieldProps) {
  const { id, description, schema } = props;

  if (!description) return null;

  if (schema?.type === 'boolean') return <Templates.DescriptionFieldTemplate {...props} />;

  return <FormHelperText id={id}>{description}</FormHelperText>;
}

export default function RjsfFormik({ label, name, schema, preserve }: RjsfFormikProps) {
  const [field, meta, helpers] = useField(name);

  const effectiveSchema = React.useMemo(() => {
    if (preserve) return omit(schema, 'additionalProperties');
    return schema;
  }, [preserve, schema]);

  if (isEmpty(effectiveSchema)) return null;

  function handleChange(e) {
    if (preserve) {
      helpers.setValue({ ...field.value, ...e.formData });
    } else {
      helpers.setValue(e.formData);
    }
    // avoid helpers.setTouched(true) here because that would display lots of errors prematurely
  }

  return (
    <FormControl>
      {!!label && <label>{label}</label>}
      <StyledForm
        autoComplete='off'
        focusOnFirstError={false}
        formData={field.value}
        liveValidate={meta.touched}
        onChange={handleChange}
        schema={effectiveSchema}
        showErrorList={false}
        tagName='div'
        validator={validator}
        templates={{ DescriptionFieldTemplate }}
        omitExtraData={true}
        liveOmit={true}
        uiSchema={{
          'ui:title': '',
          'ui:description': ''
        }}
      >
        <React.Fragment />
      </StyledForm>
    </FormControl>
  );
}
