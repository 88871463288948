import React from 'react';

import TextField, { TextFieldProps } from 'snap-ui/TextField';

type DelayedTextFieldProps = {
  onChange(value: string): void;
  value: string;
} & Omit<TextFieldProps, 'onChange' | 'value'>;

/**
 * a text field whose change handler is delayed a bit. this is helpful in performance-sensitive cases where there may
 * be a lag between typing and the value rendering, such as the IDE
 * this doesn't use useDebounce because it doesn't want to fire onChange on initial load, just when the underlying
 * TextField's onChange fires
 */
export default function DelayedTextField({ onChange, value, ...props }: DelayedTextFieldProps): JSX.Element {
  const timer = React.useRef<NodeJS.Timeout>();
  const [_value, _setValue] = React.useState<string>(value as string);

  React.useEffect(() => {
    if (value !== _value && !timer.current) _setValue(value);
  }, [value, _value]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    _setValue(e.target.value);
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      onChange(e.target.value);
      timer.current = undefined;
    }, 250);
  }

  return <TextField id={props?.name} {...(props as TextFieldProps)} onChange={handleChange} value={_value} />;
}
