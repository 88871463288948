import React from 'react';

import { faCircle, faStar } from '@fortawesome/pro-regular-svg-icons';
import { faCircle as faCircleSolid, faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';

import Icon from 'snap-ui/Icon';

import { StackedIcon } from 'module/Widgets/MarkerIcons/StackedIcon';

import { Domino } from './Icon.style';

type Props = {
  inheritBackground?: boolean;
};

function AnalyticGapIcon({ inheritBackground }: Props): React.ReactElement {
  return (
    <Domino>
      <StackedIcon className='hollow-icon'>
        <Icon icon={faStarSolid} color={inheritBackground ? 'inherit' : 'secondary'} />
        <Icon icon={faStar} color='error' />
      </StackedIcon>
      <StackedIcon className='hollow-icon'>
        <Icon icon={faCircleSolid} color={inheritBackground ? 'inherit' : 'secondary'} />
        <Icon icon={faCircle} color='blue' />
      </StackedIcon>
    </Domino>
  );
}

export default AnalyticGapIcon;
