import React from 'react';

import { useFilterRegistry } from 'module/GlobalFilter';
import { TagWeight } from 'module/ThreatProfile/ThreatProfile.type';
import useThreatProfile from 'module/ThreatProfile/useThreatProfile';

import { StatisticNode } from './Matrix.type';

export default function useFilterTechnique(matrix: StatisticNode[]): StatisticNode[] {
  const { values: filter } = useFilterRegistry();
  const { tagInProfile } = useThreatProfile();

  const threatPriority = filter?.priority as string[];
  const hasThreatPriorityFilter = !Object.values(TagWeight).every(weight => threatPriority?.includes(weight));

  const shouldIncludeTechnique = React.useCallback(
    (technique: StatisticNode) => {
      if (hasThreatPriorityFilter) {
        const scoreLabel = tagInProfile(technique.id)?.score_label;
        return filter?.priority.includes(scoreLabel);
      }
      return true;
    },
    [filter?.priority, hasThreatPriorityFilter, tagInProfile]
  );

  const filterMatrix = React.useMemo(() => {
    return matrix.map(node => {
      return {
        ...node,
        attack_children: node.attack_children.filter(technique => shouldIncludeTechnique(technique))
      };
    });
  }, [matrix, shouldIncludeTechnique]);

  return filterMatrix;
}
