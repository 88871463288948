import React from 'react';

import { DRAWER_WIDTH } from './Scaffold.const';
import { LOGO_WIDTH } from './core/ToolbarItemLogo';

/**
 * Will return current window inner width taking into account
 * the drawer width if opened, the logo width if drawer is
 * closed, minus scrollbar if provided.
 *
 * Scrollbar isn't always visible and forcing it to show app wide
 * is not a good solution. You can opt for the fuzzy responsive
 * approach and not provide a scrollbar value. Or provide your best guess.
 *
 * The current AppBar width needed for the full spread of menus, search
 * and profile is about 1080px.
 *
 * @param open - The scaffold drawer is opened state
 * @param scrollbar - your best guess
 * @returns - number
 */
export default function useWidthListener(
  open: boolean,
  scrollbar = 0
): { width: number; small: boolean; medium: boolean; large: boolean } {
  const [width, setWidth] = React.useState(() => calculateCurrentWidth(open, scrollbar));

  const additionalWidth = DRAWER_WIDTH - LOGO_WIDTH;

  React.useEffect(() => {
    const cb = () => setWidth(calculateCurrentWidth(open, scrollbar));

    window.addEventListener('resize', cb);
    return () => window.removeEventListener('resize', cb);
  }, [open, scrollbar]);

  return {
    width,
    ...(!open && {
      small: width <= 870 - additionalWidth,
      medium: width > 870 - additionalWidth && width < 1070 - additionalWidth,
      large: width >= 1070 - additionalWidth
    }),
    ...(open && {
      small: width <= 870,
      medium: width > 870 && width < 1070,
      large: width >= 1070
    })
  };
}

function calculateCurrentWidth(open: boolean, scrollbar: number) {
  return window?.innerWidth - (open ? DRAWER_WIDTH : LOGO_WIDTH) - scrollbar;
}
