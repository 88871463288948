import React from 'react';

import { faCircleMinus, faCirclePlus } from '@fortawesome/pro-solid-svg-icons';

import { ActionIconButton } from 'snap-ui/Button';
import Tooltip from 'snap-ui/Tooltip';

import DelayedTextField from 'module/Widgets/DelayedTextField';

import { SectionForm, SectionKind } from 'types/analytic';

type StringsSectionFormProps = {
  disabled: boolean;
  onChange(values: string[]): void;
  section: SectionForm;
  sectionIndex: number;
};

const StringsSectionForm = ({
  disabled,
  onChange,
  section,
  sectionIndex
}: StringsSectionFormProps): React.ReactElement => {
  if (section.kind !== SectionKind.Strings) return null;

  function handleAddValue() {
    onChange([...section.values, '']);
  }

  function handleRemoveValue(valueIndex: number) {
    onChange(section.values.filter((v, idx) => idx !== valueIndex));
  }

  function handleChangeValue(valueIndex: number, newValue: string) {
    if (!section.values.length) onChange([newValue]);
    else onChange(section.values.map((value, idx) => (idx === valueIndex ? newValue : value)));
  }

  return (
    <div className='ab-section-condition strings'>
      {(section.values.length ? section.values : ['']).map((value, valueIndex) => (
        <React.Fragment key={valueIndex}>
          <DelayedTextField
            label={''}
            className='value'
            disabled={disabled}
            name={`detection.sections[${sectionIndex}].values[${valueIndex}]`}
            onChange={v => handleChangeValue(valueIndex, v)}
            placeholder='Expected Value'
            value={value}
          />
          <Tooltip arrow placement='left' title='Delete value'>
            <ActionIconButton
              aria-label='Delete value'
              disabled={disabled}
              icon={faCircleMinus}
              className='link'
              onClick={(): void => {
                handleRemoveValue(valueIndex);
              }}
            />
          </Tooltip>
        </React.Fragment>
      ))}
      <Tooltip title='Add a value' placement='left'>
        <ActionIconButton
          aria-label='Add a value'
          disabled={disabled}
          className='link add-field-rule'
          onClick={handleAddValue}
          icon={faCirclePlus}
        />
      </Tooltip>
    </div>
  );
};

export default StringsSectionForm;
