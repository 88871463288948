import React from 'react';

import { CanContext } from './Can';

export function useCanContext() {
  const context = React.useContext(CanContext);
  if (!context) {
    throw new Error(`Can compound components cannot be rendered outside the Can component`);
  }
  return context._can;
}
