import { styled } from 'snap-ui/util';

import { CardContainer } from 'module/Card/Card.style';

export const IOCCardContainer = styled(CardContainer, { label: 'IOCCard' })`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(3)};

  .CardTitle {
    font-family: monospace;
  }

  .row {
    display: flex;
    gap: ${p => p.theme.spacing(5)};

    .FirstSeen,
    .LastSeen {
      flex-grow: 1;
    }
  }
`;

export const IOCScoreContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 68px;

  font-weight: bold;
  background-color: ${p => p.theme.palette.error.dark};
  padding: ${p => p.theme.spacing(1, 5)};
  border-radius: 4px;
`;
