import React from 'react';

import Guacamole from 'guacamole-common-js';

import { GuacClientState } from './SessionGuac.type';
import { syncLocalClipboardToRemote, syncRemoteClipboardToLocal } from './SessionVM.service';

export default function useClipboard(client: Guacamole.Client, state: GuacClientState) {
  const handleSyncClipboard = React.useCallback(() => {
    if (state !== GuacClientState.Connected) return;
    if (!client) return;
    syncLocalClipboardToRemote(client);
  }, [client, state]);

  React.useEffect(() => {
    if (!client) return;
    client.onclipboard = syncRemoteClipboardToLocal;
  }, [client]);

  React.useEffect(() => {
    window.addEventListener('load', handleSyncClipboard);
    window.addEventListener('copy', handleSyncClipboard);
    window.addEventListener('cut', handleSyncClipboard);
    window.addEventListener('focus', handleSyncClipboard);

    return () => {
      window.removeEventListener('load', handleSyncClipboard);
      window.removeEventListener('copy', handleSyncClipboard);
      window.removeEventListener('cut', handleSyncClipboard);
      window.removeEventListener('focus', handleSyncClipboard);
    };
  }, [handleSyncClipboard]);
}
