import React from 'react';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import zod from 'zod';

import { Autocomplete, getDisplayValue, Option } from 'snap-ui/Autocomplete';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormLabel from 'snap-ui/FormLabel';
import Radio from 'snap-ui/Radio';
import RadioGroup from 'snap-ui/RadioGroup';

import { FilterControl } from 'module/GlobalFilter';

import { useAuth, useUserCatalog } from 'provider';

import { ArtifactType } from 'types/common';
import { Ops, Query } from 'types/filter';

import { FilterConfig } from '../GlobalFilter.type';

type ContributorsKeys = {
  contributors: string[];
  contributorOp: Ops;
};

type ContributorsFilterProps = {
  onChange(values: ContributorsKeys): void;
  values: ContributorsKeys;
};

const radioOptions = [
  { label: 'All Contributors', value: 'all' },
  { label: 'Only Mine', value: 'mine' },
  { label: 'Following Contributors', value: 'contributor' }
];

function ContributorsFilter({ onChange, values }: ContributorsFilterProps): JSX.Element {
  const { contributors, contributorOp } = values;
  const [selection, setSelection] = React.useState('all');
  const { users: userCatalog } = useUserCatalog();
  const { user } = useAuth();
  const currentUserIdent = user.id?.toString();

  const options = React.useMemo(
    () =>
      userCatalog.map(user => ({
        content: user.name,
        value: user.id.toString()
      })),
    [userCatalog]
  );

  const handleRadioChange = (_event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
    if (newValue === 'all') {
      onChange({ contributors: [], contributorOp: undefined });
    } else if (newValue === 'mine') {
      onChange({ contributors: [currentUserIdent], contributorOp: Ops.in });
    }
    setSelection(newValue);
  };

  const handleListChange = (option: Option[]) => {
    onChange({ contributors: option.map(o => o.value), contributorOp });
  };

  const handleClick = () => {
    if (selection === 'all') {
      onChange({ contributors: [], contributorOp: undefined });
      setSelection('contributor');
    }
  };

  const handleOperatorChange = (_event: React.ChangeEvent<HTMLInputElement>, contributorOp: Ops) => {
    onChange({ contributors, contributorOp });
  };

  React.useEffect(() => {
    if (isEqual(contributors, [currentUserIdent])) {
      setSelection('mine');
    } else if (isEmpty(contributors)) {
      setSelection('all');
    } else {
      setSelection('contributor');
    }
  }, [currentUserIdent, contributors]);

  return (
    <FilterControl>
      <FormLabel id='contributors'>By Contributor</FormLabel>
      <RadioGroup
        aria-labelledby='contributors'
        name='contributorsGroup'
        value={selection}
        onChange={handleRadioChange}
      >
        {radioOptions.map(option => (
          <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
        ))}
      </RadioGroup>
      <Autocomplete
        name='Contributors select filter'
        onChange={handleListChange}
        onClick={handleClick}
        options={options}
        value={getDisplayValue(options, contributors)}
        multiple
        disableUserAdditions
      />
      {contributors?.length > 0 && (
        <RadioGroup
          className='radio-group'
          aria-labelledby='contributor-op-group'
          defaultValue={contributorOp}
          name='contributor-op-group'
          value={contributorOp}
          onChange={handleOperatorChange}
        >
          <FormControlLabel value={Ops.in} control={<Radio />} label='OR' />
          <FormControlLabel value={Ops.not} control={<Radio />} label='NOT' />
        </RadioGroup>
      )}
    </FilterControl>
  );
}

function toQuery(values: ContributorsKeys): Query {
  if (!values.contributors?.length) return;
  return {
    field: 'created_by_id',
    op: values.contributorOp,
    value: values.contributors.map(Number)
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('created_by_id'),
    op: zod.literal(Ops.in),
    value: zod.array(zod.number())
  })
  .transform(query => ({
    contributors: query.value.map(String),
    contributorOp: query.op
  }));

const ContributorsFilterConfig: FilterConfig<ContributorsKeys> = {
  defaults: { default: () => ({ contributors: [], contributorOp: Ops.in }) },
  supportedTopics: [
    ArtifactType.Analytic,
    ArtifactType.Collection,
    ArtifactType.Intel,
    ArtifactType.Session,
    ArtifactType.AttackScript
  ],
  component: ContributorsFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default ContributorsFilterConfig;
