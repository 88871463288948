import React from 'react';

import Detection from 'module/Analytic/core/Detection/Detection';
import { useIDEState } from 'module/IDE';

import Choice from './Choice';
import { DetectionFormRoot, RuleBuilder } from './DetectionForm.style';
import LogsourceField from './LogsourceField';
import NativeAnalyticForm from './NativeAnalyticForm';

type DetectionFormProps = {
  readonly?: boolean;
};

function DetectionForm({ readonly }: DetectionFormProps): React.ReactElement {
  const { ideState } = useIDEState();
  return (
    <DetectionFormRoot>
      {!readonly && <Choice />}
      <RuleBuilder>
        {ideState.isNative ? (
          <NativeAnalyticForm readonly={readonly} />
        ) : (
          <>
            <LogsourceField readonly={readonly} />
            <Detection name='analyticForm' readonly={readonly} />
          </>
        )}
      </RuleBuilder>
    </DetectionFormRoot>
  );
}

export default DetectionForm;
