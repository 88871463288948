import React from 'react';

import classnames from 'classnames';

import Table, { TablePlaceholder } from 'snap-ui/Table';
import { TooltipProps } from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { ArtifactType } from 'types/common';
import { StrictReactNode } from 'types/core';

import { DuckedArtifact } from '../Curation.type';
import { mapColumnNames, mapDuckKind } from './CurationGrid.helper';

const Container = styled('div')`
  min-height: 500px;

  .checkbox-header-spacing {
    padding-left: ${p => p.theme.spacing(3)};
  }

  .Table-body-row {
    --cell-height: 55px;
    height: var(--cell-height);
  }

  .Table-row-cell,
  .Table-column-cell {
    padding-left: ${p => p.theme.spacing(2)};
    padding-right: ${p => p.theme.spacing(2)};
  }

  .Table-row-cell > div {
    display: flex;
    align-items: center;
    min-height: 100%;
  }

  .Table-row-cell:not(:first-of-type) {
    padding-top: 0;
    padding-bottom: 0;

    > div {
      height: var(--cell-height);
      width: 100%;
    }

    > div::selection {
      color: ${p => p.theme.palette.common.black};
    }
  }
`;

type Props = {
  className?: string;
  topic?: ArtifactType;
  /** The base set of items to display */
  items: DuckedArtifact[];
  /** Items selected from our base set of items */
  selected: DuckedArtifact[];
  onSelectChange(isSelected: boolean, item: DuckedArtifact): void;
  isPending: boolean;
  checkboxIcon?: StrictReactNode;
  columnHeaders?: StrictReactNode[];
  placement?: TooltipProps['placement'];
  showExtraColumns?: boolean;
};

function CurationGrid({
  onSelectChange,
  selected,
  items,
  isPending,
  className,
  columnHeaders,
  placement = 'left',
  showExtraColumns = false
}: Props) {
  const handleChange = React.useCallback(
    (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
      const item = items.find(i => i.guid === event.currentTarget.id);
      onSelectChange(checked, item);
    },
    [onSelectChange, items]
  );

  const data = items.map(i =>
    mapDuckKind(
      selected.some(s => s.guid === i.guid),
      i,
      placement,
      showExtraColumns,
      handleChange
    )
  );

  return (
    <Container className={classnames('CurationGrid', className)}>
      <Table
        className='CurationGrid-table'
        size='small'
        columns={columnHeaders ? columnHeaders : mapColumnNames()}
        rows={data}
      />
      {isPending && <TablePlaceholder count={10} />}
    </Container>
  );
}

export default CurationGrid;
