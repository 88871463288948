import React, { ReactElement } from 'react';

import { faCircleChevronDown, faCircleChevronUp, faCog, faDisplayCode } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import get from 'lodash/get';
import includes from 'lodash/includes';
import size from 'lodash/size';

import { StandardChip } from 'snap-ui/Chip';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { Detection } from 'module/Detection/Detection.type';

import { getMarkers, isBlueMarkerLonely, isRedMarkerLonely } from 'services/processGraphService';

import { BlueMarker, CombinedCompositeMarker, RedMarker } from 'types/marker';
import { NodeDataType } from 'types/progressGraph';

import AnalyticIcon from '../../Icons/AnalyticIcon';
import AttackIcon from '../../Icons/AttackIcon';
import UndetectedThreatIcon from '../../Icons/UndetectedThreatIcon';
import UnvalidatedAnalyticIcon from '../../Icons/UnvalidatedAnalyticIcon';

const NodeLabelContainer = styled('div', { name: 'NodeLabel' })`
  display: flex;
  align-items: center;

  .graph-node-content-wrapper {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    padding-left: 5px;
    padding-right: 15px;
    background-color: ${p => p.theme.palette.secondary.main};
    opacity: 1;
    border: 8px solid transparent;

    &.filtered {
      opacity: 0.3;
    }

    &.selected {
      border-color: ${p => p.theme.palette.info.main};

      &.analytic {
        border-color: ${p => p.theme.palette.error.main};
      }
    }

    &.found {
      border-style: dashed;
      border-width: 8px;
    }
  }

  .graph-node-content {
    align-items: center;
    display: flex;
    height: 100%;
    gap: ${p => p.theme.spacing(4)};
    padding: ${p => p.theme.spacing(0, 5)};
  }

  .graph-node-container {
    border-width: 2px;
    border-radius: 10px;
    cursor: pointer;
    border-color: ${p => p.theme.palette.info.main};
    border-style: solid;
    height: 140px;
    width: fit-content;
    background-color: ${p => p.theme.palette.secondary.main};
    display: flex;
    min-width: 400px;
    z-index: 0;
    &.analytic {
      border-color: ${p => p.theme.palette.error.main};
    }
  }

  .graph-node-icon {
    font-size: 60px;
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
  }

  .graph-node-label-badge {
    padding: 0.5833em 0.833em;
    font-size: 40px;
    line-height: 40px;
    border-radius: 100%;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: -30px;
    min-width: fit-content;
    cursor: pointer;
    z-index: 4;
  }

  .graph-node-content-icons {
    display: flex;
    & > span:not(:last-child) {
      margin-right: 1.5rem;
    }
  }

  .graph-node-data {
    font-size: 50px;
    line-height: 60px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .graph-process-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
    z-index: 3;
  }

  .graph-node-fade {
    position: absolute;
    z-index: 2;
    right: 0;
    left: 0;
    height: 190px;
    width: 500px;
    cursor: pointer;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, ${p => p.theme.palette.background.default} 75%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, rgba(255, 255, 255, 0)),
      color-stop(75%, ${p => p.theme.palette.background.default})
    );
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, ${p => p.theme.palette.background.default} 75%);
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, ${p => p.theme.palette.background.default} 75%);
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, ${p => p.theme.palette.background.default} 75%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, ${p => p.theme.palette.background.default} 75%);
  }
`;

type NodeLabelProp = {
  nodeData?: NodeDataType;
  onNodeClick: (node) => void;
  onExpandClick: (node) => void;
  composite: CombinedCompositeMarker;
  detection: Detection;
};

const NodeLabel = ({ nodeData, onNodeClick, onExpandClick, composite, detection }: NodeLabelProp): ReactElement => {
  if (!nodeData.process_name) return null;
  const blue = getMarkers<BlueMarker>(nodeData, composite.blue);
  const red = getMarkers<RedMarker>(nodeData, composite.red);

  function renderIcons(): ReactElement {
    const icons = [];

    if (blue.length > 0)
      icons.push(
        <Tooltip
          arrow
          key={`${blue[0].analytic_compilation_id}-${blue[0].event.id}`}
          placement='top'
          title={get(blue, [0, 'analytics', 0, 'name'])}
          wrap
        >
          {isBlueMarkerLonely(blue[0].row_id, detection) ? <UnvalidatedAnalyticIcon /> : <AnalyticIcon />}
        </Tooltip>
      );

    if (red.length > 0)
      icons.push(
        <Tooltip arrow key={red[0].id} title={red[0].description} placement='top' wrap>
          {isRedMarkerLonely(red[0].id, detection) ? <UndetectedThreatIcon /> : <AttackIcon />}
        </Tooltip>
      );

    return <div className='graph-node-content-icons'>{icons.map(icon => icon)}</div>;
  }
  return (
    <NodeLabelContainer>
      <div
        className={classNames('graph-node-container', { analytic: red.length > 0 })}
        onClick={(): void => onNodeClick(nodeData)}
      >
        <div
          className={classNames('graph-node-content-wrapper', {
            filtered: nodeData.filtered,
            selected: nodeData.selected || nodeData.selected_red,
            found: nodeData.found || nodeData.found_red,
            analytic: red.length > 0
          })}
        >
          <div className='graph-node-content'>
            {!nodeData.condenseNode && (
              <Icon icon={nodeData.rootNode ? faDisplayCode : faCog} className='graph-node-icon' />
            )}
            {nodeData.condenseNode && (
              <Icon
                icon={includes(nodeData.process_name, 'Condense') ? faCircleChevronUp : faCircleChevronDown}
                className='graph-node-icon'
              />
            )}
            <span className='graph-node-data'>
              <div className='graph-process-name'>{nodeData.process_name || 'root'}</div>
              {renderIcons()}
            </span>
          </div>
        </div>
      </div>
      {nodeData.condenseNode && includes(nodeData.process_name, 'Expand') ? (
        <div className='graph-node-fade' onClick={(): void => onNodeClick(nodeData)} />
      ) : null}
      {size(nodeData._children) > 0 && (
        <StandardChip
          color='info'
          className='graph-node-label-badge'
          onClick={(): void => onExpandClick(nodeData)}
          label={`+${size(nodeData._children)}`}
        />
      )}
    </NodeLabelContainer>
  );
};

export default NodeLabel;
