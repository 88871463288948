import { styled } from 'snap-ui/util';

export const ColumnContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(7)};

  .UserPreference-row,
  .UserOption > form {
    display: flex;
    gap: ${p => p.theme.spacing(2)};
  }

  .UserPreference-name {
    button {
      width: 200px;
    }

    .ApiError {
      margin-bottom: ${p => p.theme.spacing(4)};
    }
  }

  .UserOption > form > .field {
    width: unset;
  }

  .UserPreference-filter {
    flex-direction: column;
  }

  .UserPreference-message {
    padding-right: ${p => p.theme.spacing(2)};
  }

  .MuiAutocomplete-root,
  .UserPreference-filter > button,
  .MuiTextField-root {
    width: 385px;
  }
`;
