import React, { ReactElement } from 'react';

import isEmpty from 'lodash/isEmpty';

import Button from 'snap-ui/Button';
import { styled } from 'snap-ui/util';

import { useIDEDetectionValue, useIDEValue } from 'module/IDE';

import DragDropCondition from './DragDropCondition';
import SectionForm from './SectionForm';
import useFieldOptions from './useFieldOptions';

type DetectionProps = {
  alwaysShowCondition?: boolean;
  name: 'analyticForm' | 'customization';
  readonly?: boolean;
  ignoreValidation?: boolean;
  conditionDisabled?: boolean;
};

const AddSectionButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

export default function Detection({
  alwaysShowCondition,
  name,
  readonly,
  ignoreValidation,
  conditionDisabled
}: DetectionProps): ReactElement {
  const [fields, eventIds] = useFieldOptions();
  const { addSection, updateCondition, value: detectionValue } = useIDEDetectionValue(name);
  const { value: logsourceValue } = useIDEValue('logsource');

  const disabled = isEmpty(logsourceValue);

  const sections = detectionValue?.sections ?? [];

  return (
    <>
      {sections.map((section, index) => {
        return (
          <SectionForm
            key={index}
            detectionFieldName={name}
            disabled={readonly || disabled}
            disabledTitle={readonly ? '' : undefined}
            eventIdOptions={eventIds.map(eid => ({
              content: eid.toString(),
              label: eid.toString(),
              value: eid.toString()
            }))}
            fields={fields}
            isDupeName={sections.some((s, i) => i < index && s.name === section.name)}
            sectionIndex={index}
            ignoreValidation={ignoreValidation}
          />
        );
      })}
      {!(disabled || readonly) && (
        <AddSectionButtonContainer>
          <Button onClick={() => addSection()}>Add Section</Button>
        </AddSectionButtonContainer>
      )}
      {(Object.values(sections).length > 1 || alwaysShowCondition) && (
        <DragDropCondition
          condition={detectionValue?.condition}
          sections={detectionValue?.sections?.map(s => s.name) ?? []}
          onChange={updateCondition}
          disabled={readonly || disabled || conditionDisabled}
        />
      )}
    </>
  );
}
