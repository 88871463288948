import { styled } from 'snap-ui/util';

export const ProcessGraphRoot = styled('div', { label: 'ProcessGraphRoot' })`
  --sidebar-width: 510px;

  flex: 1;
  position: relative;

  .graph {
    width: 100%;
    height: calc(100vh - 211px);
    padding-top: 5px;
    cursor: move;
    cursor: grab;
  }

  .graph:active {
    cursor: grabbing;
  }

  .sidebar-title {
    font-size: 20px;
    line-height: 28px;
    margin-top: 10px;
    color: ${p => p.theme.palette.common.white};
    &.no-wrap {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 400px;
    }
  }

  .sidebar-accordion-title {
    font-size: 18px;
    line-height: 28px;
    color: ${p => p.theme.palette.grey[400]};

    .detection {
      margin-right: 30px;
      width: 52%;
    }

    .no-padding {
      padding-left: 0px;
      padding-right: 10px;
      width: auto;
    }

    .severity-title {
      padding-right: 34px;
    }

    .no-margin {
      margin: 0px;
    }

    .angle-icon {
      padding: 0px;
      width: 13px;

      .icon {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
    }
  }

  .clickable {
    /* Move to the Styled.tsx Clickable component */
    cursor: pointer;
    padding: 0 !important;
    font-size: 14px;
  }

  button.clickable {
    background: none !important;
    border: none;
    text-decoration: none !important;
    color: ${p => p.theme.palette.primary.main};
    line-height: 24px;
  }

  button.clickable:hover {
    color: ${p => p.theme.palette.primary.dark};
    border: none;
  }

  .sidebar-tags {
    text-align: left;
  }

  .graph-session-name {
    width: fit-content;
    margin-top: 5px;
    margin-bottom: 0;
    padding-left: 30px;
    display: block;
    color: ${p => p.theme.palette.common.white};

    &:hover {
      color: ${p => p.theme.palette.primary.main};
    }
  }
`;
