import React from 'react';

import ButtonGroup from 'snap-ui/ButtonGroup';
import { styled } from 'snap-ui/util';

import WidthContainer from 'module/Layout/WidthContainer';

import ReportButton from './ReportButton';

type Props = {
  className?: string;
  message: React.ReactNode;
  action?: React.ReactNode;
  children?: React.ReactElement;
};

const Container = styled(WidthContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5em;

  .message {
    margin-top: 10em;
    display: flex;
    align-items: center;

    .label {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .title {
        font-size: calc(var(--fontSize) * 1.5);
        font-weight: bolder;
      }

      .sub-title {
        font-size: var(--fontSize) + 5px;
      }
    }
  }

  .children {
    margin: 2em auto;
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  .broken {
    width: 120px;
  }

  .ors::before {
    background-color: var(--subtleBackground);
    color: ${p => p.theme.palette.common.white};
  }

  .item-icon {
    height: 18px;
    top: 12.375px;
  }

  .action-group {
    justify-content: center;
  }
`;

function Fallback({ className, message, action, children }: Props) {
  return (
    <Container className={className}>
      <div className='message'>
        <img className='broken' src={process.env.PUBLIC_URL + '/images/404.png'} alt='SnapAttack Error' />
        <div className='label'>
          <div className='title'>Oh Snap!</div>
          <div className='sub-title'>{message}</div>
        </div>
      </div>
      <div className='children'>
        {children}
        <ButtonGroup className='sa-generic-spacer action-group'>
          {action}
          <ReportButton />
        </ButtonGroup>
      </div>
    </Container>
  );
}

export default Fallback;
