import React from 'react';

import { useAsync } from 'storage';

import { JobGroup, getAsyncTaskGroupJobs } from './';

export default function useJobGroupTask() {
  const { data: _data, errorProps, run, status } = useAsync<JobGroup[]>([]);

  React.useEffect(() => run(getAsyncTaskGroupJobs()), [run]);

  const data = React.useMemo(() => {
    return _data.reverse();
  }, [_data]);

  return {
    data,
    errorProps,
    status
  };
}
