import React from 'react';

import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';

import { Integration } from 'module/Integration/Integration.type';
import { JobType, postAsyncJobs, PostJobsInput } from 'module/Job';

import { Catalog, Scheme, useAsync, useAsyncStorage } from 'storage';

import { Language } from 'types/analytic';
import { Organization } from 'types/auth';

import { AdHocQueryHistoryAsyncBag, AdHocQueryJobHistory, AdHocQueryStorage } from './AdHocQuery.type';
import { updateAdHocQueryJobHistory, updateAdHocQueryJobHits } from './AdHocQuery.util';

export function useAdHocQueryHistory(
  orgId: Organization['id'],
  integrationGuid: Integration['guid']
): AdHocQueryHistoryAsyncBag {
  const { data, setData } = useAsyncStorage<AdHocQueryStorage>(Scheme.localStorage, Catalog.ad_hoc_queries, {});
  const { task, status } = useAsync();

  const getQueryHistory = React.useCallback((): AdHocQueryJobHistory[] => {
    return get(data, `${orgId}.${integrationGuid}`, []);
  }, [data, integrationGuid, orgId]);

  const pushQueryHistory = React.useCallback(
    (newQuery: AdHocQueryJobHistory) => {
      setData(data => {
        const _data = cloneDeep(data);
        const queryHistory = getQueryHistory();
        set(_data, `${orgId}.${integrationGuid}`, [newQuery, ...queryHistory]);
        return _data;
      });
    },
    [getQueryHistory, integrationGuid, orgId, setData]
  );

  const updateQueryHistoryResponse = React.useCallback(
    (jobGuid: AdHocQueryJobHistory['jobGuid'], newResponse: AdHocQueryJobHistory['response']) => {
      setData(data => updateAdHocQueryJobHistory(data, orgId, integrationGuid, jobGuid, newResponse));
    },
    [integrationGuid, orgId, setData]
  );

  const updateQueryHistoryHits = React.useCallback(
    (jobGroupGuid: AdHocQueryJobHistory['response']['guid'], hits: AdHocQueryJobHistory['hits']) => {
      setData(data => updateAdHocQueryJobHits(data, orgId, integrationGuid, jobGroupGuid, hits));
    },
    [integrationGuid, orgId, setData]
  );

  const executeQuery = React.useCallback(
    (language: Language['backend_key'], query: string, parameters?: PostJobsInput['parameters']) => {
      const payload: PostJobsInput = {
        organization_id: orgId,
        type: JobType.Test,
        integrations: [integrationGuid],
        adhoc_queries: [
          {
            language,
            search: query
          }
        ]
      };

      if (parameters) {
        payload.parameters = parameters;
      }

      task(postAsyncJobs(payload)).then(response => {
        pushQueryHistory({ payload, jobGuid: response.jobs[0].guid, response });
      });
    },
    [integrationGuid, orgId, pushQueryHistory, task]
  );

  return {
    getQueryHistory,
    updateQueryHistoryResponse,
    updateQueryHistoryHits,
    executeQuery,
    status
  };
}
