import React from 'react';

import { faAdd } from '@fortawesome/pro-regular-svg-icons';
import { useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import Button from 'snap-ui/Button';
import { GridFooterContainer, GridRowModes, GridRowModesModel, useGridApiContext } from 'snap-ui/DataGrid';
import { FormHelperText } from 'snap-ui/FormControl';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { MetadataFieldInput } from './Metadata.type';

type MetadataEditorFooter = {
  onAddNewOption(id: string): void;
  isEditing: boolean;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
};

export function MetadataEditorFooter(props: MetadataEditorFooter) {
  const { isEditing, onAddNewOption, setRowModesModel } = props;
  const apiRef = useGridApiContext();
  const rowCount = apiRef.current.getRowsCount();
  const [element, setElement] = React.useState<{ rowId: string; rowCount: number }>();
  const { touched, errors } = useFormikContext<MetadataFieldInput>();

  const handleAddNewOption = () => {
    const id = uuidv4();
    setElement({ rowId: id, rowCount });
    onAddNewOption(id);
  };

  React.useLayoutEffect(() => {
    if (apiRef.current && element && element.rowCount !== rowCount) {
      setRowModesModel(m => ({ ...m, [element.rowId]: { mode: GridRowModes.Edit } }));
      apiRef.current.scroll({ top: rowCount * 52 });
      setElement(undefined);
    }
  }, [rowCount, element]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <GridFooterContainer>
      <FormHelperText error={!!(touched.options && errors.options)}>
        {errors.options ? (
          errors.options?.[0]
        ) : isEditing ? (
          <Tooltip arrow placement='top' title='Press escape to cancel or enter to commit'>
            <span>
              Finish editing to save <Icon.Info color='info' />
            </span>
          </Tooltip>
        ) : (
          ''
        )}
      </FormHelperText>
      <Button color='inherit' variant='text' endIcon={<Icon icon={faAdd} />} onClick={handleAddNewOption}>
        ADD OPTION
      </Button>
    </GridFooterContainer>
  );
}
