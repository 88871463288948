import React from 'react';

import { InlineAlert } from 'snap-ui/Alert';

import ChartPanel from 'module/Dashboard/core/ChartPanel';

type ReportPanelProps = {
  title: React.ReactNode;
  hasError?: boolean;
  error?: React.ReactNode;
  children?: React.ReactNode;
};

export default function ReportPanel(props: ReportPanelProps): JSX.Element {
  return (
    <ChartPanel
      title={props.title}
      bottomText={props.hasError && <InlineAlert severity='info'>{props.error}</InlineAlert>}
    >
      {!props.hasError && <>{props.children}</>}
    </ChartPanel>
  );
}
