import React from 'react';

import z from 'zod';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import Button from 'snap-ui/Button';
import CircularProgress from 'snap-ui/CircularProgress';
import { FormDialog } from 'snap-ui/Dialog';
import ConfirmDialog from 'snap-ui/Dialog/ConfirmDialog';
import Icon from 'snap-ui/Icon';
import { FieldsLayout } from 'snap-ui/Layout';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { DEFAULT_ROLES, GROUP_TYPE, sortGroupByRole } from 'constants/organization';

import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import MultivalueTextfieldFormik from 'module/Form/MultivalueTextfieldFormik';
import TextFieldFormik from 'module/Form/TextFieldFormik';

import { useAuth, useManagedOrganizations } from 'provider';

import { checkContentPermission } from 'services/authService/authService';

import { ContentPermission, Organization, User } from 'types/auth';
import { Guid } from 'types/common';

import { isValidEmail } from 'utilities/StringUtils';

import GroupPermissionsFormGroup from './GroupPermissionsFormGroup';
import ResetMFA from './ResetMFA';
import UserFormActions from './UserFormActions';
import { InviteUserPayload } from './UserManagement';
import { userGroupMappingForOrganization } from './UserManagement.util';

export type UserFormDialogPayload = {
  email?: string[];
  editorUser?: User;
  organizationGuid: Guid;
  [DEFAULT_ROLES.Admin]?: boolean;
  [DEFAULT_ROLES.AnalyticContributor]?: boolean;
  [DEFAULT_ROLES.BASContributor]?: boolean;
  [DEFAULT_ROLES.CollectionContributor]?: boolean;
  [DEFAULT_ROLES.ContentAdmin]?: boolean;
  [DEFAULT_ROLES.IntegrationContributor]?: boolean;
  [DEFAULT_ROLES.IntelContributor]?: boolean;
  [DEFAULT_ROLES.SessionContributor]?: boolean;
  [DEFAULT_ROLES.User]?: boolean;
};

type UserFormDialogProps = {
  isLoading: boolean;
  apiError?: string;
  className?: string;
  onClose(): void;
  onSubmit(values: UserFormDialogPayload): void;
  onRemoveFromOrg(organization: Organization): void;
  open: boolean;
  organizationGuid: Guid;
  editorUser: User;
  inviteUser(target: InviteUserPayload[]): Promise<void>;
};

const OrganizationOption = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${p => p.theme.spacing(2)};
`;

const StyledConfirmDialog = styled(ConfirmDialog)`
  & .MuiDialog-container > div {
    width: fit-content;
  }
`;

const ConfirmDialogBody = styled('div')`
  margin-bottom: ${p => p.theme.spacing(2)};
`;

function UserFormDialogComponent(props: UserFormDialogProps): JSX.Element {
  const { user } = useAuth();

  const { organizations } = useManagedOrganizations();
  const [selectedOrg, setSelectedOrg] = React.useState<Organization>(null);
  const editorUser = props?.editorUser;

  const mfa_configured = editorUser?.mfa_configured;
  const canEdit = editorUser && checkContentPermission({ permission: editorUser.permission }, ContentPermission.Edit);

  const [currentlyOpenedDeleteConfirm, setCurrentlyOpenedDeleteConfirm] = React.useState<Guid>();

  const handleChangeOrg = React.useCallback(
    (orgGuid: Guid) => {
      setSelectedOrg(organizations.find(org => org.guid === orgGuid));
    },
    [organizations]
  );

  React.useEffect(() => {
    handleChangeOrg(props.organizationGuid);
  }, [props.organizationGuid, handleChangeOrg]);

  const userGroupMapping = React.useMemo(
    () => userGroupMappingForOrganization(selectedOrg, editorUser) ?? {},
    [editorUser, selectedOrg]
  );
  const sortedGroups = selectedOrg?.groups.filter(group => group.type !== GROUP_TYPE.Permission).sort(sortGroupByRole);

  function openDeleteConfirm(userGuid: Guid) {
    setCurrentlyOpenedDeleteConfirm(userGuid);
  }

  function closeDeleteConfirm(): void {
    setCurrentlyOpenedDeleteConfirm(null);
  }

  return (
    <FormDialog
      className={props.className}
      DialogProps={{ open: props.open, onClose: props.onClose }}
      FormikConfig={{
        enableReinitialize: true,
        initialValues: {
          email: editorUser?.email ? [editorUser?.email] : [],
          organizationGuid: selectedOrg?.guid,
          ...userGroupMapping
        },
        onSubmit: props.onSubmit,
        zodSchema: z.object({
          email: z.array(z.string().email()).nonempty('Email is required.'),
          organizationGuid: z.string().nonempty('Organization is required')
        })
      }}
      SubmitProps={{
        children: props.isLoading ? <CircularProgress size={25} /> : editorUser ? 'Save' : 'Create',
        disabled: props.isLoading
      }}
      title={editorUser ? 'Edit User' : 'Add User'}
    >
      {!!props.apiError && (
        <Alert severity='error'>
          <AlertTitle>Oops! Something went wrong.</AlertTitle>
          {props.apiError}
        </Alert>
      )}
      <FieldsLayout>
        {editorUser ? (
          <TextFieldFormik label={`User's Email Address`} name='email[0]' disabled={!user.superuser} />
        ) : (
          <MultivalueTextfieldFormik
            label='One or more user email addresses'
            name='email'
            inputValidator={isValidEmail}
            helperText='Enter multiple email addresses to invite users in bulk.'
          />
        )}
        {user.superuser && (
          <>
            <AutocompleteFormik
              label='Organization'
              name='organizationGuid'
              options={organizations?.map(org => {
                const userIsMember = Object.keys(editorUser?.memberships || []).some(key => key == org.guid);
                return {
                  value: org.guid,
                  label: org.name,
                  content: (
                    <OrganizationOption>
                      {org.name}
                      {userIsMember && (
                        <Tooltip arrow title={`User is a member of ${org.name}`} wrap>
                          <Icon.Success />
                        </Tooltip>
                      )}
                    </OrganizationOption>
                  )
                };
              })}
              onChange={handleChangeOrg}
              disableClearable
              disableUserAdditions
            />
            {editorUser && !Object.values(userGroupMapping).some(group => group === true) && selectedOrg && (
              <Alert severity='info' variant='outlined'>
                User is not a member of <strong>{selectedOrg?.name}</strong>, but can be added by assigning permissions
                below and clicking Save.
              </Alert>
            )}
          </>
        )}
        {editorUser && selectedOrg && (
          <>
            <GroupPermissionsFormGroup
              groups={sortedGroups}
              userGroupMapping={userGroupMapping}
              organization={selectedOrg}
            />
            {Object.values(userGroupMapping).some(group => group === true) && (
              <div>
                <Button
                  variant='outlined'
                  disabled={props.isLoading}
                  onClick={() => {
                    openDeleteConfirm(editorUser?.guid);
                  }}
                >
                  {props.isLoading ? <CircularProgress size={25} /> : `Remove from ${selectedOrg?.name}`}
                </Button>
                <StyledConfirmDialog
                  className={props.className}
                  DialogProps={{
                    open: currentlyOpenedDeleteConfirm === editorUser?.guid,
                    onClose: closeDeleteConfirm
                  }}
                  ConfirmProps={{
                    children: 'Confirm',
                    onClick: () => {
                      props.onRemoveFromOrg(selectedOrg);
                      closeDeleteConfirm();
                    }
                  }}
                  title='Confirm Action'
                >
                  <ConfirmDialogBody>
                    Are you sure you want to remove this user from <strong>{selectedOrg?.name}</strong>?
                  </ConfirmDialogBody>
                </StyledConfirmDialog>
              </div>
            )}
          </>
        )}
        {editorUser && canEdit && mfa_configured && (
          <ResetMFA organization_id={props.organizationGuid} editorUser={editorUser} />
        )}
        {editorUser && (
          <UserFormActions
            inviteUser={props.inviteUser}
            editorUser={editorUser}
            selectedOrg={selectedOrg}
            isLoading={props.isLoading}
            onSubmit={props.onClose}
          />
        )}
      </FieldsLayout>
    </FormDialog>
  );
}

const UserFormDialog = styled(UserFormDialogComponent)<UserFormDialogProps>`
  h3 {
    font-size: 1.25rem;
  }
`;

export default UserFormDialog;
