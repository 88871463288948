import React from 'react';

import { deleteCapAttackZips, getCapAttackZips } from 'apis/resources/harbor';

import { Status, useAsync } from 'storage';

import { CapAttackZip } from 'types/harbor';

type CapAttackZipState = {
  capZips: CapAttackZip[];
  existingIsPending: boolean;
  refresh: () => void;
  remove: (capAttackZip: CapAttackZip) => Promise<void>;
};

export default function useCapAttackZips(): CapAttackZipState {
  const { data: capZips, run, task, status } = useAsync<CapAttackZip[]>([]);

  const refresh = React.useCallback(() => {
    run(getCapAttackZips());
  }, [run]);

  React.useEffect(() => {
    run(getCapAttackZips());
  }, [run]);

  const remove = React.useCallback(
    (capAttackZip: CapAttackZip) => {
      return task(deleteCapAttackZips(capAttackZip)).then(refresh);
    },
    [refresh, task]
  );

  return { capZips, refresh, remove, existingIsPending: status === Status.pending };
}
