import { Option } from 'snap-ui/Autocomplete';

import { Artifact } from 'types/common';

import { equalizedAllocation } from 'utilities/ArrayUtils';

import { AutocompleteResponse, BaseTag, Discriminator, isDiscriminator, SigmaTag, TagGroup } from '.';

const TECHNIQUE_INDICATOR = ':';

function caseInsensitiveCompare(a: string, b: string) {
  return a.localeCompare(b, 'en', { sensitivity: 'accent' });
}

export function sortTag(a: BaseTag, b: BaseTag) {
  // We want Tactics to bubble to the start and be sorted amongst themselves.
  // We want Techniques and Sub Techniques to follow those and be sorted amongst themselves.
  // We want Actors i.e. groups to follow those and be sorted amongst themselves.
  // Finally, Custom tags bring up the rear, sorted amongst themselves.
  const aOrder = Object.values(Discriminator).indexOf(a.discriminator);
  const bOrder = Object.values(Discriminator).indexOf(b.discriminator);
  const equality = aOrder - bOrder;
  if (equality !== 0) return equality;

  if (a.discriminator === Discriminator.Attack) {
    const isATactic = isTactic(a);
    const isBTactic = isTactic(b);

    if (isATactic && !isBTactic) return -1;
    if (!isATactic && isBTactic) return 1;
  }

  return caseInsensitiveCompare(a.name, b.name);
}
/**
 * Reduce the group to a single list of baseTags. Optionally allocates when a total is provided.
 * @param group
 * @param total - Will evenly distribute across group items to satisfy the total
 * @returns
 */
export function reduceGroupAsBaseTagsWithTotalAllocationDistribution(
  group: { actor: string[]; attack: string[]; software: string[]; vulnerability: string[] },
  total = -1
) {
  if (total < 0) {
    return [
      ...reduceToBaseTags(group.actor, Discriminator.Actor),
      ...reduceToBaseTags(group.attack, Discriminator.Attack),
      ...reduceToBaseTags(group.software, Discriminator.Software),
      ...reduceToBaseTags(group.vulnerability, Discriminator.Vulnerability)
    ];
  }

  const results = equalizedAllocation(
    [
      { data: group.actor || [] },
      { data: group.attack || [] },
      { data: group.software || [] },
      { data: group.vulnerability || [] }
    ],
    total
  );
  return [
    ...reduceToBaseTags(group.actor, Discriminator.Actor).slice(0, results[0]),
    ...reduceToBaseTags(group.attack, Discriminator.Attack).slice(0, results[1]),
    ...reduceToBaseTags(group.software, Discriminator.Software).slice(0, results[3]),
    ...reduceToBaseTags(group.vulnerability, Discriminator.Vulnerability).slice(0, results[4])
  ];
}

export function reduceToBaseTags(list: string[], discriminator: Discriminator): BaseTag[] {
  return (list || ([] as string[])).map(name => ({
    name,
    discriminator
  }));
}

export function reduceToSigmaTags(d: Discriminator, data: AutocompleteResponse): SigmaTag[] {
  return Object.entries(data[d] || {}).map(([key, value]) => ({
    name: key,
    discriminator: d,
    sigma_names: value.map(t => t.sigma_name),
    tags: value
  }));
}

export function isTactic<T extends BaseTag>(tag: T): boolean {
  if (tag.discriminator !== Discriminator.Attack) return false;
  return !(tag.name || '').includes(TECHNIQUE_INDICATOR);
}

export function isTacticOption<T extends Option>(tag: T): boolean {
  return !tag.value.includes(TECHNIQUE_INDICATOR);
}

export function isTacticArtifact(tag: Artifact): boolean {
  if (!isDiscriminator(tag.type)) return false;
  return isTactic({ name: tag.name, discriminator: tag.type });
}

export function getLabel(discriminator: Discriminator) {
  switch (discriminator) {
    case Discriminator.Actor:
      return 'Threat Actors';
    case Discriminator.Attack:
      return 'MITRE ATT&CK';
    case Discriminator.Software:
      return 'Software';
    case Discriminator.Vulnerability:
      return 'Vulnerability';
    case Discriminator.DataSource:
      return 'Data Sources and Components';
    default:
      return 'Other Tags';
  }
}

export function getTagListDiffs(curr: Partial<Artifact>, prev: Partial<Artifact>): [string[], string[], string] {
  const key = Object.keys(curr)?.[0];
  const c: string[] = curr[key] || [];
  const p: string[] = prev[key] || [];
  const deletion = p.filter(v => !c.includes(v));
  const addition = c.filter(v => !p.includes(v));
  return [addition, deletion, key];
}

export function convertTagsToTagNames(tags: BaseTag[]): TagGroup {
  const reduced = (tags || []).reduce(
    (tagGroup, tag) => ({
      ...tagGroup,
      [tag.discriminator]: [...tagGroup[tag.discriminator], tag]
    }),
    {
      [Discriminator.Attack]: [],
      [Discriminator.Actor]: [],
      [Discriminator.DataSource]: [],
      [Discriminator.Software]: [],
      [Discriminator.Vulnerability]: [],
      [Discriminator.Action]: []
    }
  );

  const attack_names = reduced[Discriminator.Attack].map(t => t.name);
  const actor_names = reduced[Discriminator.Actor].map(t => t.name);
  const datasource_names = reduced[Discriminator.DataSource].map(t => t.name);
  const software_names = reduced[Discriminator.Software].map(t => t.name);
  const vulnerability_names = reduced[Discriminator.Vulnerability].map(t => t.name);

  return {
    attack_names: [...new Set(attack_names)],
    actor_names: [...new Set(actor_names)],
    datasource_names: [...new Set(datasource_names)],
    software_names: [...new Set(software_names)],
    vulnerability_names: [...new Set(vulnerability_names)]
  };
}
