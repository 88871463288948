import React from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import classNames from 'classnames';

import Icon from 'snap-ui/Icon';
import {
  ListItem as MuiListItem,
  ListItemButton as MuiListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText
} from 'snap-ui/List';
import Tooltip, { TooltipProps } from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

type Props = {
  className?: string;
  disabled?: boolean;
  icon?: IconDefinition;
  id?: string;
  onClick?: ListItemButtonProps['onClick'];
  title?: string;
  tooltip?: TooltipProps['title'];
};

const ListItem = styled(MuiListItem)`
  padding-left: 0;
  padding-right: 0;
`;

export default function ListItemButton({ className, icon, title, tooltip, ...others }: Props) {
  return (
    <Tooltip title={tooltip ? tooltip : ''} arrow placement='right' wrap={others.disabled}>
      <ListItem>
        <MuiListItemButton className={classNames('ScaffoldItem ListItemButton', className)} {...others}>
          {icon && (
            <ListItemIcon>
              <Icon icon={icon} />
            </ListItemIcon>
          )}
          {title && <ListItemText>{title}</ListItemText>}
        </MuiListItemButton>
      </ListItem>
    </Tooltip>
  );
}
