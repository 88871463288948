import React from 'react';

import isEmpty from 'lodash/isEmpty';

import Placeholder from 'snap-ui/Placeholder';

import useSeverityRank from 'aso/useSeverityRank';

import { useMayI } from 'module/May';
import { SupplementalArtifact } from 'module/Search';
import { LevelMenuBadge } from 'module/Widgets/LevelMenuBadge/LevelMenuBadge';

import { useAuth } from 'provider';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { Artifact, ArtifactScore, ArtifactScoreDetail } from 'types/common';

import { getPreferredOrgScore } from 'utilities/ArtifactUtils';

interface AnalyticSeverityProps {
  analytic: Artifact;
  supplemental: SupplementalArtifact;
}

export default function Severity({ analytic, supplemental }: AnalyticSeverityProps): JSX.Element {
  const { defaultOrgId } = useAuth();
  const { setSeverity, status } = useSeverityRank(analytic.guid);
  const [severities, setSeverities] = React.useState<ArtifactScoreDetail[]>([]);
  const isPending = Status.pending === status;

  const permitted = useMayI(FunctionalPermission.SetAnalyticMeta);

  React.useEffect(() => {
    setSeverities(supplemental?.severities);
  }, [supplemental?.severities]);

  const severity = getPreferredOrgScore(defaultOrgId, severities, analytic.severity);

  return (
    <>
      {isEmpty(analytic) || isEmpty(supplemental) ? (
        <Placeholder height={48} width={132} variant='rectangular' />
      ) : (
        <LevelMenuBadge
          value={severity}
          variant='Severity'
          permitted={permitted}
          loading={isPending}
          handleLevelChange={handleSeverityChange}
        />
      )}
    </>
  );

  async function handleSeverityChange(severity: ArtifactScore): Promise<void> {
    setSeverity(severity).then(() => {
      const index = severities.findIndex(severity => severity.organization_id === defaultOrgId);
      const isFound = severities.find(severity => severity.organization_id === defaultOrgId);
      if (isFound) {
        severities[index] = { organization_id: defaultOrgId, severity };
        setSeverities([...severities]);
      } else {
        setSeverities([...severities, { organization_id: defaultOrgId, severity }]);
      }
    });
  }
}
