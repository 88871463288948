import React from 'react';

import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

const Section = styled(Typography)`
  display: flex;
  align-items: center;
  height: ${p => p.theme.spacing(8)};
  font-size: 1rem;
  background-color: ${p => p.theme.palette.grey[900]};
  color: ${p => p.theme.palette.primary.main};
  margin: ${p => p.theme.spacing(3, 0)};
  margin-top: 15px;
  margin-bottom: 10px;
  padding-left: 18px;
  border-left: ${p => p.theme.spacing(1)} solid ${p => p.theme.palette.primary.main};
` as unknown as typeof Typography;

export default function SectionDivider({ content }: { content: string }) {
  return <Section component='div'>{content}</Section>;
}
