import React from 'react';

import isEmpty from 'lodash/isEmpty';

import Button from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import TextField from 'snap-ui/TextField';
import Tooltip from 'snap-ui/Tooltip';

import Path from 'constants/paths';

import { Engage, Fingerprint } from 'lib/Engagement';

import { ApiError } from 'module/ApiError';

import { updateCurrentUser, useAuth, useAuthInterface } from 'provider';

import { Status, useAsync } from 'storage';

export function DisplayName() {
  const [name, setDisplay] = React.useState('');
  const { user } = useAuth();
  const { fetch } = useAuthInterface();
  const { status, task, errorProps } = useAsync();
  const isSSO = user.sso;

  React.useEffect(() => {
    setDisplay(user.name || '');
  }, [user.name]);

  const updateDisplayName = () => {
    task(
      updateCurrentUser({ name }).then(
        () => {
          Engage.track(
            Fingerprint.of(Path.Preferences)
              .withData({ nameFrom: user.name, nameTo: name })
              .withQualifier('update display name')
          );
          fetch();
        },
        error => {
          Engage.track(
            Fingerprint.error(Path.Preferences)
              .withData({ nameFrom: user.name, nameTo: name, error })
              .withQualifier('update display name')
          );
        }
      )
    );
  };

  return (
    <>
      {!isEmpty(errorProps) && <ApiError {...errorProps} />}
      <Tooltip
        arrow
        wrap
        title={isSSO ? "This value is managed by your organization's SSO provider and cannot be changed." : null}
        placement='top'
      >
        <TextField
          disabled={isSSO}
          value={name}
          label='Display Name'
          onChange={a => setDisplay(a.target.value)}
          elevated
        />
      </Tooltip>
      <Button
        variant='outlined'
        disabled={status === Status.pending || !name || name === user.name}
        onClick={updateDisplayName}
        endIcon={status === Status.pending && <Icon.SpinnerProgress />}
      >
        Change Display Name
      </Button>
    </>
  );
}
