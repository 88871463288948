import { DateRange, PRESET_WINDOW } from './DateRangeSelector.type';

export function getInitialDatesByPreset(presetWindow: PRESET_WINDOW): DateRange {
  const stop = new Date();
  const start = new Date();
  start.setDate(stop.getDate() - presetWindow);

  return {
    start,
    stop
  };
}
