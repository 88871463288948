import React from 'react';

import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';

import CircularProgress from 'snap-ui/CircularProgress';
import { MenuButton, MenuTrigger } from 'snap-ui/Menu';
import { styled } from 'snap-ui/util';

import { AgentInterface } from 'aso/useAgents';

import { Status } from 'storage';

import { BASJobState } from 'types/bas';

import { BASCampaignFeed } from '../BAS.type';
import {
  CampaignActionInterface,
  CancelDialog,
  CancelMenuItem,
  DeleteDialog,
  DeleteMenuItem,
  RerunDialog,
  RerunMenuItem
} from '../CampaignReport/useCampaignActionInterface';
import useCampaignUtils from '../CampaignReport/useCampaignUtils';

const CampaignActionsContainer = styled('div')`
  text-align: center;
`;

type CampaignActionProps = {
  agentInterface: AgentInterface;
  campaign: BASCampaignFeed;
  refresh(): void;
};

export default function CampaignActions({ agentInterface, campaign, refresh }: CampaignActionProps): JSX.Element {
  const campaignInterface = useCampaignUtils(campaign.guid);

  const handleRefresh = () => refresh();

  return campaignInterface.status === Status.pending ? (
    <CircularProgress size={20} />
  ) : (
    <CampaignActionsContainer>
      <CampaignActionInterface
        agentInterface={agentInterface}
        campaign={campaign}
        campaignInterface={campaignInterface}
      >
        <MenuTrigger
          key={campaign.guid}
          trigger={<MenuButton aria-label={`${campaign.name} Menu`} icon={faEllipsisVertical} />}
        >
          <RerunMenuItem key='rerun' />
          <CancelMenuItem key='cancel' disabled={campaign.state === BASJobState.Canceled} />
          <DeleteMenuItem key='delete' />
        </MenuTrigger>
        <RerunDialog onSuccess={handleRefresh} />
        <CancelDialog onSuccess={handleRefresh} />
        <DeleteDialog onSuccess={handleRefresh} />
      </CampaignActionInterface>
    </CampaignActionsContainer>
  );
}
