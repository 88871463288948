import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';
import startsWith from 'lodash/startsWith';

import { Ident } from 'types/common';

export function stripHtmlTags(str: string): string {
  const temp = document.createElement('div');
  temp.innerHTML = str;
  return temp.textContent || temp.innerText || '';
}

export function getSessionNameFromPreview(preview: string, id: Ident): [string, string] {
  return preview.split(` (${id}) - `) as [string, string];
}

export function setToExternalLink(url: string): string {
  if (startsWith('http')) {
    return url;
  }
  return 'http://' + url;
}

export function isHydrated(value: void | string): boolean {
  return typeof value === 'string' && value.length > 0;
}

export function caseInsensitiveStringEqual(a: string, b: string): boolean {
  if (!a || !b) return false;
  return isEqual(a.toUpperCase(), b.toUpperCase());
}

export function isValidEmail(email: string): boolean {
  if (!email) return false;
  const emailRE =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRE.test(email.toLowerCase());
}

/**
 * unescape quotes and line breaks so they render
 */
export function unescapeJson(value: string): string {
  return value.replaceAll('\\n', '\n').replaceAll(/\\"/g, '"');
}

export type TruncateMiddle = [value: string, truncated: boolean, omission: string];
export function truncateMiddle(str: string, sectionLength, omission = ' ..... '): TruncateMiddle {
  if (!isString(str)) return [str, false, omission];
  const resultLength = sectionLength * 2 + omission.length;

  if (resultLength >= str.length || sectionLength <= 0) return [str, false, omission];
  return [
    `${str.substring(0, sectionLength)}${omission}${str.substring(str.length, str.length - sectionLength)}`,
    true,
    omission
  ];
}

export function highlightSearchTerm(searchTerm: string, truncatedMiddle: TruncateMiddle): string {
  const [value, isTruncated, omission] = truncatedMiddle || [];

  if (!searchTerm) return value;

  const match = value.match(searchTerm);
  if (match) {
    // match is in the left side of truncation, or not truncated
    // use standard regex replace
    return value.replace(new RegExp(`(${searchTerm})`, 'gi'), `<span>$1</span>`);
  } else if (isTruncated) {
    // split into left and right side, handle independently
    const parts = value.split(omission).map(p => {
      // find intersection between the searchTerm and the portion we're inspecting
      const intersection = stringIntersection(searchTerm, p);

      const match = p.match(intersection);

      if (match && intersection) {
        const split = p.split(intersection); // returns ['', 'remaining'];
        return `<span>${intersection}</span>${split.join('')}`;
      } else return p;
    });

    return parts.join(omission);
  }
}

export function stringIntersection(a: string, b: string): string {
  if (a === b) return a;
  if (a.includes(b)) return b;
  if (b.includes(a)) return a;

  let bestResult = '';

  const longerString = a.length > b.length ? a : b;
  const shorterString = a.length > b.length ? b : a;

  for (let i = 2; i < shorterString.length; i++) {
    for (let j = 0; i < longerString.length; i++) {
      const chunk = shorterString.slice(j, i);

      if (longerString.includes(chunk) && chunk.length > bestResult.length) {
        bestResult = chunk;
      }
    }
  }

  return bestResult;
}
