import React from 'react';

import { faPen } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';

import { AccordionSummaryActionsWrapper } from 'snap-ui/Accordion';
import { AutocompleteV2, Option } from 'snap-ui/Autocomplete';
import { ActionIconButton } from 'snap-ui/Button';
import Checkbox from 'snap-ui/Checkbox';
import Chip from 'snap-ui/Chip';
import FormControl, { FormHelperText } from 'snap-ui/FormControl';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import TextField from 'snap-ui/TextField';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import { IconFormControlLabel } from 'module/GlobalFilter';
import { FilterControl as _FilterControl } from 'module/GlobalFilter/GlobalFilter.style';
import { ArtifactIcon } from 'module/Layout/Artifact.widgets';

import { ArtifactType } from 'types/common';

import { Control, ControlFriendlyName, TypeOption } from './Control';
import { APPLIED_TO_DEFAULT } from './Metadata.const';
import { MetadataFieldInput } from './Metadata.type';
import { sortControlOptions } from './Metadata.util';

export const AppliedTo = styled((props: { className?: string; data: string[] }) => {
  return (
    <div className={props.className}>
      {APPLIED_TO_DEFAULT.map(a => {
        const applied = props.data?.includes(a);
        return (
          <Tooltip key={a} title={applied ? `Applied to ${a}` : `Not applied to ${a}`} arrow wrap placement='top-end'>
            <ArtifactIcon type={a} color={applied ? undefined : 'greyish'} />
          </Tooltip>
        );
      })}
    </div>
  );
})`
  display: flex;
  gap: ${p => p.theme.spacing(2)};
`;

export const AppliedToFormik = styled(
  ({ className, name, onChange }: { className?: string; name: string; onChange?: (value: string[]) => void }) => {
    const [field, meta, helpers] = useField<string[]>(name);
    const hasError = !!(meta.touched && meta.error);

    function handleChange(e: React.SyntheticEvent<HTMLInputElement, Event>, checked: boolean) {
      const next = checked
        ? [...field.value, e.currentTarget.value]
        : field.value.filter(f => f !== e.currentTarget.value);
      helpers.setValue(next);
      helpers.setTouched(true, false);
      if (onChange) onChange(next);
    }

    function handleToggle(_e: React.SyntheticEvent<HTMLInputElement, Event>, checked: boolean) {
      if (checked) helpers.setValue([...APPLIED_TO_DEFAULT]);
      else helpers.setValue([]);
      helpers.setTouched(true, false);
    }

    return (
      <div className={classNames('Content-applied Form-field required', className)}>
        <FormControl className={classNames({ error: hasError })} component='fieldset' variant='standard'>
          <FormLabel component='legend'>Applied to Content Types</FormLabel>
          <FormGroup>
            <IconFormControlLabel
              name='applied'
              control={<Checkbox onChange={handleToggle} checked={field.value.length === APPLIED_TO_DEFAULT.length} />}
              label='All'
            />
            <IconFormControlLabel
              name='applied'
              control={<Checkbox onChange={handleChange} checked={field.value.includes(ArtifactType.Intel)} />}
              value={ArtifactType.Intel}
              label={
                <>
                  <Icon.Intel width={16} /> Intelligence
                </>
              }
            />
            <IconFormControlLabel
              name='applied'
              control={<Checkbox onChange={handleChange} checked={field.value.includes(ArtifactType.Session)} />}
              value={ArtifactType.Session}
              label={
                <>
                  <Icon.Session width={16} /> Threat
                </>
              }
            />
            <IconFormControlLabel
              name='applied'
              control={<Checkbox onChange={handleChange} checked={field.value.includes(ArtifactType.Analytic)} />}
              value={ArtifactType.Analytic}
              label={
                <>
                  <Icon.Analytic width={16} /> Detection
                </>
              }
            />
            <IconFormControlLabel
              name='applied'
              control={<Checkbox onChange={handleChange} checked={field.value.includes(ArtifactType.AttackScript)} />}
              value={ArtifactType.AttackScript}
              label={
                <>
                  <Icon.AttackScript width={16} /> Validation
                </>
              }
            />
            <IconFormControlLabel
              name='applied'
              control={<Checkbox onChange={handleChange} checked={field.value.includes(ArtifactType.Collection)} />}
              value={ArtifactType.Collection}
              label={
                <>
                  <Icon.Collection width={16} /> Collection
                </>
              }
            />
          </FormGroup>
        </FormControl>
        <FormHelperText error>{hasError && meta.error}</FormHelperText>
      </div>
    );
  }
)`
  fieldset {
    padding: ${p => p.theme.spacing(3)};
  }

  fieldset.error {
    border: 1px solid ${p => p.theme.palette.error.main};

    legend {
      color: ${p => p.theme.palette.error.main};
    }
  }

  .MuiFormHelperText-root {
    text-align: center;
  }
`;

export function OptionsFormik() {
  const [field] = useField('type');
  const [, , helpers] = useField('options');
  if (field.value !== Control.Select && field.value !== Control.MultiSelect) return null;
  return (
    <AutocompleteFormik
      required
      label='Options'
      name='options'
      options={[]}
      multiple
      onChange={(options: Option[]) => helpers.setValue(options.map(o => o.value).sort(sortControlOptions))}
    />
  );
}

export function TypeFormik(props: { disabled?: boolean; onChange?: (type: string) => void }) {
  const ctx = useFormikContext<MetadataFieldInput>();
  const [options] = React.useState<TypeOption[]>(() => [
    ...Object.entries(Control).map(([key, value]) => ({
      key,
      label: ControlFriendlyName[value] || '',
      value
    }))
  ]);

  const handleChange = (_e: React.SyntheticEvent, option: TypeOption) => props.onChange(Control[option?.key] || '');

  const value = ctx.values.type;
  const optionValue: TypeOption = value
    ? {
        value,
        key: '',
        label: ControlFriendlyName[value] || ''
      }
    : null;
  const error = !!(ctx.touched.type && ctx.errors.type);
  const helperText =
    value === ''
      ? 'Select a control type'
      : value === Control.Flag
      ? 'For a true or false boolean value'
      : value === Control.MultiSelect
      ? 'For selecting multiple values from a predefined list'
      : value === Control.Number
      ? 'For numbers and floating point values'
      : value === Control.OpenSelect
      ? 'For adding any values to an open list'
      : value === Control.Select
      ? 'For selecting a single value from a predefined list'
      : value === Control.Text
      ? 'For short text input'
      : value === Control.TextArea
      ? 'For long text input over multiple lines. Markdown enabled input.'
      : 'Hmm...';

  return (
    <AutocompleteV2<TypeOption>
      disabled={props.disabled}
      className={classNames('Form-field required', { valid: !error })}
      onChange={handleChange}
      value={optionValue}
      isOptionEqualToValue={(o: TypeOption, v: TypeOption) => o.value === v.value}
      renderInput={params => (
        <TextField {...params} label='Type' helperText={helperText} elevated error={error ? true : undefined} />
      )}
      options={options}
    />
  );
}

function EmptyValue() {
  return <Chip label={<i>empty</i>} color='warning' size='small' variant='outlined' />;
}

export function EditMeLabel(props: {
  className?: string;
  disabled?: boolean;
  htmlFor: string;
  onClick?: () => void;
  title: string;
}) {
  const handleClick = () => {
    if (!props.disabled && props.onClick) props.onClick();
  };
  return (
    <AccordionSummaryActionsWrapper>
      <Typography
        className={classNames('EditMeLabel', props.className, { disabled: props.disabled })}
        variant='h5'
        component='label'
        htmlFor={props.htmlFor}
        onClick={handleClick}
      >
        {props.title}
      </Typography>
      <Tooltip title={`Edit ${props.title}`} arrow placement='right'>
        <ActionIconButton
          icon={faPen}
          color='stealth'
          aria-label={`Edit ${props.title}`}
          className={classNames('EditMeLabel-pencil', { disabled: props.disabled })}
          onClick={handleClick}
        />
      </Tooltip>
    </AccordionSummaryActionsWrapper>
  );
}

type ReadonlyControl<T> = {
  className?: string;
  data: T;
  disabled?: boolean;
  title: string;
  onClick?(): void;
  pre?: boolean;
  truncate?: boolean;
};

export const ReadonlyControl = styled(function <T>(props: ReadonlyControl<T>) {
  return (
    <div
      className={classNames('Control Control-readonly', props.className, { disabled: props.disabled })}
      onClick={props.onClick}
    >
      {props.onClick && (
        <EditMeLabel disabled={props.disabled} htmlFor={`readonly-${props.title}`} title={props.title} />
      )}
      <Typography
        id={`readonly-${props.title}`}
        component={props.pre ? 'pre' : 'div'}
        className={classNames({
          ['Control-readonly-items']: Array.isArray(props.data)
        })}
      >
        {Array.isArray(props.data) ? (
          props.data.length > 0 ? (
            <>
              {props.data.map(d => (
                <Chip key={d} label={d} />
              ))}
            </>
          ) : (
            <EmptyValue />
          )
        ) : props.data !== null && props.data !== undefined ? (
          <TruncateText text={String(props.data)} truncate={props.truncate} />
        ) : (
          <EmptyValue />
        )}
      </Typography>
    </div>
  );
})`
  .Control-readonly-items {
    display: flex;
    gap: ${p => p.theme.spacing(2)};
  }
`;

function TruncateText({
  text,
  truncate,
  truncateLength = 20
}: {
  text: string;
  truncate: boolean;
  truncateLength?: number;
}): JSX.Element {
  const ellipsis = '...';
  if (!truncate || text.length < truncateLength + ellipsis.length) return <>{text}</>;
  const truncated = text.substring(0, truncateLength) + ellipsis;
  return (
    <Tooltip arrow title={<pre>{text}</pre>} wrap>
      <>{truncated}</>
    </Tooltip>
  );
}

export const FilterControl = styled(_FilterControl)`
  .group-layout {
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(3)};
    margin: ${p => p.theme.spacing(3, 0)};
  }

  .group-layout-item {
    flex: 1;
  }

  .Control {
    width: 100%;
  }
`;
