import React from 'react';

import useIDEReducer, { IDEState } from 'module/IDE/reducer';
import useTagCatalog, { DiscriminatorOrdinal } from 'module/Tag/useTagCatalog';

import { AnalyticVersion } from '../Analytic.type';
import { useAnalyticCatalog } from '../core/AnalyticProvider';

export default function useIDEStateFromVersion(version: AnalyticVersion): IDEState {
  const [ideState, dispatch] = useIDEReducer();
  const { data: tagOptions } = useTagCatalog(DiscriminatorOrdinal.All, true);
  const [{ analytic, permissions, references, supplemental }] = useAnalyticCatalog();

  React.useEffect(() => {
    dispatch({
      type: 'LoadAnalyticUpdate',
      analytic: { ...analytic, ...permissions, ...references, ...supplemental, ...(version ?? {}) },
      tagOptions
    });
  }, [analytic, dispatch, permissions, references, supplemental, tagOptions, version]);

  return ideState;
}
