import React from 'react';

import { Skeleton, styled } from '@mui/material';

export type PlaceholderProps = {
  variant: 'text' | 'circular' | 'rectangular';
  width?: string | number;
  height?: string | number;
  className?: string;
  animation?: false | 'pulse' | 'wave';
};

type PlaceholdersProps = {
  count?: number;
  variant?: 'text' | 'circular' | 'rectangular';
  width?: string | number;
  height?: string | number;
  className?: string;
  animation?: false | 'pulse' | 'wave';
};

type TextPlaceholdersProps = {
  count?: number;
  className?: string;
};

type ImagePlaceholderProps = {
  width: string | number;
  height: string | number;
  className?: string;
  text?: string;
};

const Container = styled(Skeleton, { label: 'Placeholder' })`
  &:not(.MuiSkeleton-circular) {
    border-radius: 4px;
  }
`;

const PlaceholdersContainer = styled('div')`
  margin-top: ${p => p.theme.spacing(2)};
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(2)};
`;

const TextPlaceholdersContainer = styled('div')`
  padding: ${p => p.theme.spacing(6)};
`;

const ImagePlaceholderContainer = styled('div', {
  shouldForwardProp: () => false
})<{ width: string | number; height: string | number }>`
  width: ${p => (typeof p.width === 'number' ? `${p.width}px` : p.width)};
  height: ${p => (typeof p.height === 'number' ? `${p.height}px` : p.height)};
  border: 1px solid ${p => p.theme.palette.common.black};
  background-color: ${p => p.theme.palette.background.default};
  color: ${p => p.theme.palette.greyish.main};
  font-size: 0.75em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export default function Placeholder({ className, variant, animation = 'pulse', ...others }: PlaceholderProps) {
  return <Container className={className} variant={variant} animation={animation} {...others} />;
}

export function Placeholders({
  count = 4,
  variant = 'rectangular',
  width = '100%',
  height = 30,
  animation = 'pulse',
  className
}: PlaceholdersProps): JSX.Element {
  return (
    <PlaceholdersContainer className={className}>
      {new Array(count).fill(0).map((_, index) => (
        <Placeholder
          className='placeholder'
          key={index}
          variant={variant}
          width={width}
          height={height}
          animation={animation}
        />
      ))}
    </PlaceholdersContainer>
  );
}

export function TextPlaceholders({ count = 2, className }: TextPlaceholdersProps): JSX.Element {
  return (
    <TextPlaceholdersContainer className={className}>
      {new Array(count).fill(0).map((_, index) => (
        <div key={index}>
          <Placeholder variant='text' />
          <Placeholder variant='text' width='50%' />
          <Placeholder variant='text' width='80%' />
          <Placeholder variant='text' width='60%' />
          {count !== index ? <br /> : ''}
        </div>
      ))}
    </TextPlaceholdersContainer>
  );
}

export function ImagePlaceholder({ text, className, width, height }: ImagePlaceholderProps): JSX.Element {
  return (
    <ImagePlaceholderContainer className={className} width={width} height={height}>
      {text}
    </ImagePlaceholderContainer>
  );
}
