import React from 'react';

import { useAsync } from 'storage';

import { getSchemaCatalog } from './Integration.api';
import { SchemaCatalog } from './Integration.type';

export default function useIntegrationSchema() {
  const { data: schema, run, status } = useAsync<SchemaCatalog>();

  React.useEffect(() => {
    run(getSchemaCatalog());
  }, [run]);

  return {
    schema,
    status
  };
}
