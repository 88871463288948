import React from 'react';

import { postSeverityRank } from 'apis/resources/analytic';

import { useAuth } from 'provider';

import { orgIdIsNotVoid } from 'services/organizationService';

import { useAsync, Status } from 'storage';

import { Guid, ArtifactScore } from 'types/common';

interface SeverityRankState {
  setSeverity(severity: ArtifactScore): Promise<void>;
  status: Status;
}

export default function useSeverityRank(analyticGuid: Guid): SeverityRankState {
  const { task, status } = useAsync();
  const { defaultOrgId } = useAuth();

  const setSeverity = React.useCallback(
    (severity: ArtifactScore) => {
      if (orgIdIsNotVoid(defaultOrgId)) {
        return task(postSeverityRank(analyticGuid, severity, defaultOrgId));
      }
    },
    [analyticGuid, defaultOrgId, task]
  );

  return {
    setSeverity,
    status
  };
}
