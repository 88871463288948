import React from 'react';

import { Form, Formik } from 'formik';
import { array, object, string } from 'yup';

import Button from 'snap-ui/Button';
import CircularProgress from 'snap-ui/CircularProgress';
import { FieldsLayout } from 'snap-ui/Layout';
import { styled } from 'snap-ui/util';

import { ARTIFACT_SCORE_OPTIONS } from 'constants/common';

import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import { Discriminator, TagAutocompleteFormik } from 'module/Tag';

import { Ident } from 'types/common';

import { convertFormattedTimeToSeconds, convertSecondsToMMss } from 'utilities/TimeUtils';

import TextFieldFormik from '../../Form/TextFieldFormik';

const FormContainer = styled(FieldsLayout)`
  padding-top: ${p => p.theme.spacing(8)};
  gap: ${p => p.theme.spacing(4)};
`;

const ActionContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  & .submit {
    margin-right: 0px;
  }
`;

/*
 * needs to handle use cases of UpdateRedMarkerForm and NewMarkerForm
 * as used in Toolbar.js, NodeSidebar.tsx, HalHits.js, and RedMarkerPopup.tsx
 */

export type MarkerPayload = {
  id?: Ident;
  offset?: number;
  text?: string;
  techniqueIds?: string[];
  severity?: string;
  attack_names?: string[];
  description?: string;
};

export interface InitialValues extends Partial<MarkerPayload> {
  timeReadOnly?: boolean;
  overlayTextTag?: string[];
}

export type MarkerFormFields = {
  id?: Ident;
  attack_names?: string[];
  text?: string;
  time?: string;
  severity?: string;
};

type Props = {
  className?: string;
  isLoading: boolean;
  initialValues: InitialValues;
  onDelete?: (id: Ident) => void;
  onSubmit: (payload: MarkerPayload) => void;
};

export default function MarkerForm(props: Props): React.ReactElement {
  const handleDelete = (): void => {
    if (typeof props.onDelete === 'function') {
      props.onDelete(props.initialValues.id);
    }
  };

  const handleSubmit = (data: MarkerFormFields): void => {
    const payload: MarkerPayload = {
      id: data.id,
      offset: convertFormattedTimeToSeconds(data.time),
      text: data.text,
      attack_names: data.attack_names,
      severity: data.severity
    };
    props.onSubmit(payload);
  };

  const initialValues: MarkerFormFields = {
    time: convertSecondsToMMss(props.initialValues.offset),
    text: '',
    severity: props.initialValues.severity || '',
    ...props.initialValues
  };
  if (props.initialValues.attack_names) {
    initialValues.attack_names = props.initialValues.attack_names;
  }
  if (props.initialValues.severity) {
    initialValues.severity = props.initialValues.severity;
  }

  return (
    <Formik<MarkerFormFields>
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={true}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={object().shape({
        attack_names: array().min(1, 'At least one technique is required'),
        text: string().required('Description is required'),
        severity: string().required('Severity is required')
      })}
    >
      <Form>
        <FormContainer className={props.className}>
          <TextFieldFormik
            data-testid='time'
            label='Time'
            name='time'
            disabled={props.initialValues.timeReadOnly || !!initialValues.id}
          />
          <TagAutocompleteFormik name='attack_names' multiple discriminator={Discriminator.Attack} required />
          <TextFieldFormik label='Description' name='text' required data-testid='description' />
          <AutocompleteFormik
            label='Severity'
            name='severity'
            options={ARTIFACT_SCORE_OPTIONS}
            disableUserAdditions
            required
            testId='severity'
          />
          <ActionContainer>
            {props.isLoading ? (
              <CircularProgress color='primary' size={25} />
            ) : (
              <Button type='submit' disabled={props.isLoading} className='submit'>
                {initialValues.id ? 'Update' : 'Add'}
              </Button>
            )}
            {!!initialValues.id && (
              <Button
                variant='outlined'
                color='primary'
                className='link'
                onClick={handleDelete}
                disabled={props.isLoading}
              >
                Delete
              </Button>
            )}
          </ActionContainer>
        </FormContainer>
      </Form>
    </Formik>
  );
}
