import React from 'react';

import classnames from 'classnames';

import Paper from 'snap-ui/Paper';
import { styled } from 'snap-ui/util';

import Multifactor from './Multifactor';

type Props = {
  className?: string;
};

const Container = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-image: url(${process.env.PUBLIC_URL + '/images/darkpurple.jpg'});
  background-position: center;
  background-size: cover;

  .Multifactor {
    padding: ${p => p.theme.spacing(6)};
    width: 600px;
  }
`;

export default function MultifactorWrapper(props: Props) {
  return (
    <Container className={classnames('MultifactorWrapper', props.className)}>
      <Paper>
        <Multifactor />
      </Paper>
    </Container>
  );
}
