import React from 'react';

import { Area, AreaChart, ResponsiveContainer } from 'recharts';

import { useTheme } from 'snap-ui/util';

import { SimpleLineChartData } from 'module/Dashboard/SocDashboard/SocDashboard.type';

type Props = {
  data: SimpleLineChartData;
  color?: string;
  dataKey?: string;
};

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function SimpleLineChart({ data, color, dataKey = 'value' }: Props) {
  const { palette } = useTheme();
  const graphColor = color ? color : palette.purple.main;
  const num = randomIntFromInterval(1, 1000);

  return (
    <ResponsiveContainer width='100%' height={100}>
      <AreaChart width={350} height={75} data={data}>
        <defs>
          <linearGradient id={`area-gradient-${num}`} x1='0' y1='0' x2='0' y2='1'>
            <stop offset='5%' stopColor={graphColor} stopOpacity={0.8} />
            <stop offset='95%' stopColor={graphColor} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          type='monotone'
          dataKey={dataKey}
          stroke={graphColor}
          fillOpacity={0.6}
          fill={`url(#area-gradient-${num})`}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default SimpleLineChart;
