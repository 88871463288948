import React from 'react';

import { Alert } from 'snap-ui/Alert';
import { Autocomplete } from 'snap-ui/Autocomplete';
import BackdropLoader from 'snap-ui/BackdropLoader';
import Paper from 'snap-ui/Paper';
import { styled } from 'snap-ui/util';

import { asValidationError, ValidationError } from 'apis';

import { PreferredOrgIcon } from 'module/Widgets/RequestPreferredOrg/PreferredOrg.helper';

import { updateCurrentUser, useAuth, useAuthInterface } from 'provider';

const fieldName = 'Preferred Organization';

const Container = styled('div')`
  .Alert {
    margin-bottom: ${p => p.theme.spacing(4)};
  }
`;

function UserOptionPreferredOrg() {
  const [active, setActive] = React.useState(false);
  const { fetch } = useAuthInterface();
  const { user, preferable: orgs } = useAuth();
  const preferred = user.preferred_organization?.id.toString() || '';
  const [error, setError] = React.useState<ValidationError>();

  const options = React.useMemo(() => {
    return orgs.map(o => ({
      preferred: o.id.toString() === preferred,
      isolated: o.isolated,
      value: o.id.toString(),
      label: o.name,
      content: o.id.toString() === preferred ? <PreferredOrgIcon>{o.name}</PreferredOrgIcon> : o.name
    }));
  }, [orgs, preferred]);

  const selectedOrgName =
    options.find(o => {
      if (user) {
        return o.value === user.preferred_organization.id.toString();
      }
    }) || '';

  async function onSubmit(preferred) {
    setActive(true);
    setError(null);

    try {
      const result = await updateCurrentUser({ preferred_organization_id: preferred });
      if (result) fetch();
    } catch (error) {
      setError(asValidationError(error));
      setActive(false);
    }
  }

  return (
    <Container>
      <Paper>
        {error && <Alert severity='error'>{error?.detail || 'Oops! This was unexpected'}</Alert>}
        <BackdropLoader open={active} contained fixed />
        {options.length > 0 && user && (
          <Autocomplete
            className='preferred-org'
            label={`Select ${fieldName}`}
            name='preferred'
            options={options}
            disabled={options.length <= 0}
            disableClearable
            disableUserAdditions
            onChange={value => onSubmit(value)}
            value={selectedOrgName}
          />
        )}
      </Paper>
    </Container>
  );
}

export default UserOptionPreferredOrg;
