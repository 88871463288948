import React from 'react';

import { faCircle } from '@fortawesome/pro-solid-svg-icons';

import Icon from 'snap-ui/Icon';

function AnalyticIcon(): React.ReactElement {
  return <Icon icon={faCircle} color='blue' />;
}

export default AnalyticIcon;
