import React from 'react';

import { useAsync } from 'storage';

import { Artifact, Guid } from 'types/common';

import { getCampaignRecommend } from '../BAS.api';

export function useCampaignRecommend(guid: Guid) {
  const { data, run, status } = useAsync<Artifact[]>([]);

  const getRecommendation = React.useCallback(() => {
    if (guid) {
      run(getCampaignRecommend(guid));
    }
  }, [guid, run]);

  return {
    getRecommendation,
    data,
    status
  };
}
