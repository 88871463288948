import React from 'react';

import { faClose, faPlay } from '@fortawesome/pro-solid-svg-icons';

import Button, { RouterButton } from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';

import Path from 'constants/paths';

import { useCommissionedMachine } from 'provider';

import { CeleryState } from 'types/common';

import Banner from './Banner';

export default function SessionTaskBanner() {
  const { activeTask, setActiveTask } = useCommissionedMachine();
  const message = [CeleryState.in_progress, CeleryState.pending, CeleryState.retrying].includes(activeTask?.status)
    ? 'Saving captured threat...'
    : activeTask?.status === CeleryState.failed
    ? 'Oh.. yeah that broke ' + activeTask?.task_id
    : 'View your new threat here';
  if (!activeTask?.task_id) return null;

  const disabled = activeTask?.status !== CeleryState.success;

  return (
    <Banner>
      <Icon.Save className='banner-icon' />
      {message}
      <RouterButton
        disabled={disabled}
        startIcon={<Icon icon={faPlay} />}
        to={`${Path.Threat}/${activeTask?.output?.session_id}`}
        onClick={handleClick}
        color='secondary'
      >
        View Threat
      </RouterButton>
      <Button startIcon={<Icon icon={faClose} />} variant='text' color='secondary' onClick={handleClick}>
        Dismiss
      </Button>
    </Banner>
  );

  function handleClick() {
    setActiveTask(null);
  }
}
