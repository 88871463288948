import React from 'react';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { StrictReactNode } from 'types/core';

import { FilterValues } from './GlobalFilter.type';
import useFilterRegistry from './useFilterRegistry';

type SettingsRegistryInterface = {
  appliedCount: number;
  render(): StrictReactNode;
  reset(): void;
  update(values: FilterValues): void;
  trimValues(values: FilterValues): FilterValues;
  values: FilterValues;
};

export default function useSettingsRegistry(initialValues?: FilterValues): SettingsRegistryInterface {
  const { _registry } = useFilterRegistry();
  const [isLoaded, setLoaded] = React.useState(false);
  const registryDefaults = React.useMemo(() => (_registry ? _registry.defaults('*') : {}), [_registry]);
  const [values, setValues] = React.useState<FilterValues>(registryDefaults);

  const update = React.useCallback(
    (partial: FilterValues) => {
      setValues(vals => ({ ...vals, ...partial }));
    },
    [setValues]
  );

  const render = React.useCallback(() => {
    if (!_registry || isEmpty(values)) return null;
    return _registry.render('*', update, values);
  }, [_registry, update, values]);

  const reset = React.useCallback(() => {
    if (isEmpty(registryDefaults)) return;
    update(registryDefaults);
    setLoaded(true);
  }, [registryDefaults, update]);

  const trimValues = React.useCallback(
    (vals: FilterValues) => {
      return Object.entries(vals).reduce((acc, [key, val]) => {
        if (isEqual(val, registryDefaults[key])) return acc;
        return {
          ...acc,
          [key]: val
        };
      }, {});
    },
    [registryDefaults]
  );

  React.useEffect(() => {
    reset();
  }, [reset]);

  React.useEffect(() => {
    // wait till registry has loaded to set initial values
    // to avoid being overwritten by registryDefaults
    if (isLoaded && initialValues) {
      update(initialValues);
    }
  }, [initialValues, isLoaded, update]);

  return {
    appliedCount: _registry ? _registry.appliedCount('*', values) : 0,
    render,
    reset,
    trimValues,
    update,
    values
  };
}
