import { AutocompleteProps, Option } from 'snap-ui/Autocomplete/Autocomplete';

export function getDisplayValue(
  options: Option[],
  value: undefined | null | string | string[]
): AutocompleteProps['value'] {
  if (value === undefined || value == null) {
    return [] as Option[];
  }
  if (typeof value === 'string') {
    const selectedOption = options.find(option => option.value === value);
    if (!selectedOption || selectedOption.userAdded) return value;
    return selectedOption;
  }

  return value.map(
    (val: string) => options.find(option => option.value === val) || { content: val, userAdded: true, value: val }
  );
}

export function getDisplayValueString(options: Option[], value: undefined | null | string | string[]): string {
  const opt = getDisplayValue(options, value);
  if (Array.isArray(opt)) {
    return opt.map(o => (o as Option)?.content ?? o ?? '').join(', ');
  }
  return (opt as Option)?.content?.toLocaleString?.() ?? opt?.toString() ?? '';
}
