import React from 'react';

import groupBy from 'lodash/groupBy';
import isArray from 'lodash/isArray';

import { Discriminator } from 'module/Tag';

import { useAuth } from 'provider';

import { Status, useAsync } from 'storage';

import { Guid, Ident } from 'types/common';

import { getProfileTags, putProfileTags } from './ThreatProfile.api';
import { ThreatProfileTag, ThreatProfileTagPayload, TagWeight } from './ThreatProfile.type';

export type ThreatProfileTagInterface = {
  tags: ThreatProfileTag[];
  tagsStatus: Status;
  taskStatus: Status;
  refresh(): void;
  addTag(tagId: ThreatProfileTagPayload['tag']): void;
  removeTag(tagId: ThreatProfileTagPayload['tag']): void;
  updateTagWeight(tag: ThreatProfileTagPayload): void;
  isTagInProfile(tagIDs: Ident | Ident[]): boolean;
  tagInProfile(tagIDs: Ident | Ident[]): ThreatProfileTag;
  groupedTags: Record<Discriminator, ThreatProfileTag[]>;
};

function _useThreatProfile(orgGuid: Guid): ThreatProfileTagInterface {
  const { data, run, status: tagsStatus } = useAsync<ThreatProfileTag[]>([]);
  const { task, status: taskStatus } = useAsync<ThreatProfileTag[]>([]);

  const refresh = React.useCallback(() => {
    if (orgGuid) run(getProfileTags(orgGuid), true);
  }, [orgGuid, run]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  const groupedTags = React.useMemo(() => groupBy(data, 'type') as Record<Discriminator, ThreatProfileTag[]>, [data]);

  const addTag = React.useCallback(
    (tagId: ThreatProfileTagPayload['tag']) => {
      task(putProfileTags(orgGuid, { tag: tagId, score: TagWeight.Medium }).then(refresh));
    },
    [orgGuid, refresh, task]
  );

  const removeTag = React.useCallback(
    (tagId: ThreatProfileTagPayload['tag']) => {
      task(
        putProfileTags(orgGuid, {
          tag: tagId,
          score: TagWeight.Ignored
        }).then(refresh)
      );
    },
    [orgGuid, refresh, task]
  );

  const updateTagWeight = React.useCallback(
    (tag: ThreatProfileTagPayload) => {
      task(putProfileTags(orgGuid, tag).then(refresh));
    },
    [orgGuid, refresh, task]
  );

  const tagInProfile = React.useCallback(
    (tagIDs: Ident | Ident[]): ThreatProfileTag => {
      const ids = isArray(tagIDs) ? tagIDs : [tagIDs];
      return data.find(t => ids.some(id => id === t.tag));
    },
    [data]
  );

  const isTagInProfile = React.useCallback(
    (tagIDs: Ident | Ident[]): boolean => {
      const ids = isArray(tagIDs) ? tagIDs : [tagIDs];

      return data.some(t => ids.some(id => id === t.tag));
    },
    [data]
  );

  return {
    tags: data,
    tagsStatus,
    taskStatus,
    groupedTags,
    refresh,
    addTag,
    removeTag,
    updateTagWeight,
    isTagInProfile,
    tagInProfile
  };
}

const ThreatProfileProviderContext = React.createContext<ThreatProfileTagInterface>(null);

export function ThreatProfileProvider({ children }: React.PropsWithChildren<Record<never, never>>) {
  const { user } = useAuth();
  const organization = user.preferred_organization;

  const threatProfileTags = _useThreatProfile(organization?.guid);

  return (
    <ThreatProfileProviderContext.Provider value={threatProfileTags}>{children}</ThreatProfileProviderContext.Provider>
  );
}

export default function useThreatProfile() {
  const context = React.useContext(ThreatProfileProviderContext);
  if (!context) {
    throw new Error('useThreatProfile must be used within a ThreatProfileProviderContext');
  }
  return context;
}
