import { InviteUserPayload } from 'module/Setting/core/UserManagement/UserManagement';

import {
  ChangeRequest,
  CheckSSORequest,
  CheckSSOResponse,
  ForgotRequest,
  LoginRequest,
  Maintenance,
  MFARequiredResponse,
  Monitoring,
  ProtectedSettings,
  ResetRequest
} from 'types/auth';

import { snapattack, ValidationResponse } from '../snapattack';

const prefix = '/auth';

export function getEmailFromInviteToken(token: string): Promise<string> {
  return snapattack.get(`${prefix}/get_token_email/${token}/`).then(resp => resp.data.email);
}

export function checkSSO(data: CheckSSORequest): Promise<CheckSSOResponse> {
  return snapattack.post(`${prefix}/check_sso/`, data).then(res => res.data);
}

export function logout(): Promise<void> {
  return snapattack.post(`${prefix}/logout/`);
}

export function requestPasswordChange(data: ForgotRequest): Promise<ValidationResponse> {
  return snapattack
    .post(`${prefix}/forgot_password/`, data)
    .then(res => res.data)
    .catch(err => err.response?.data);
}

export function resetPassword(data: ResetRequest): Promise<void> {
  return snapattack.post(`${prefix}/reset_password/`, data);
}

export async function changePassword(data: ChangeRequest): Promise<void> {
  return snapattack.post(`${prefix}/change_password/`, data);
}

export function authenticatedLogin(data: LoginRequest): Promise<MFARequiredResponse> {
  return snapattack.post(`${prefix}/login/`, data);
}

export function getOpenSettings(): Promise<Monitoring> {
  return snapattack.get(`/settings/open/`, { timeout: 6000 }).then(r => r.data);
}

export function getProtectedSettings(): Promise<ProtectedSettings> {
  return snapattack.get('/settings/protected/').then(r => r.data);
}

export function getMaintenance(): Promise<Maintenance> {
  return snapattack.get(`/config/notification/`).then(r => r.data);
}

export function inviteUsers(payload: InviteUserPayload[]): Promise<void> {
  return snapattack.post(`${prefix}/send_invites/`, payload);
}
