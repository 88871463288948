import React from 'react';

import { faFileAlt } from '@fortawesome/pro-solid-svg-icons';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Link from 'snap-ui/Link';
import Typography from 'snap-ui/Typography';

import Path from 'constants/paths';

import DateRangeSelector from 'module/DateRangeSelector';
import { IntegrationAutocomplete } from 'module/Integration';
import { IntegrationAction, IntegrationOption } from 'module/Integration/Integration.type';
import { BurgerCancel, BurgerLink, Menu } from 'module/Layout/Artifact.widgets';
import OverwatchOrganizationsAutocomplete from 'module/Widgets/OverwatchOrganizationsAutocomplete';

import { useIntegrationCatalog } from 'provider';

import { Guid } from 'types/common';

import { useAnalyticCatalog } from '../../core/AnalyticProvider';
import { TuningPreviewButton, TuningPreviewDialog, TuningPreviewInterface } from '../AnalyticTuningPreview';
import useTuningContext, { INITIAL_PRESET } from '../AnalyticTuningProvider';
import { HeaderContainer } from './AnalyticTuningHeader.style';
import HitsChart, { HitsChartProps } from './HitsChart';

type AnalyticTuningHeaderProps = {
  onIntegrationChange: (value: Guid) => void;
  tuningDisabled: boolean;
  HitsChartProps: HitsChartProps;
};

const ACTIONS = [IntegrationAction.Deploy, IntegrationAction.Hunt];
function AnalyticTuningHeader({
  onIntegrationChange,
  tuningDisabled,
  HitsChartProps: hitsChartProps
}: AnalyticTuningHeaderProps) {
  const [{ analytic, supplemental }] = useAnalyticCatalog();
  const { tunableIntegrations, integrationGuid, setIntegrationGuid, dates, setDates } = useTuningContext();
  const { overwatch } = useIntegrationCatalog();
  const { organizationIDs } = overwatch;

  const integrationOptions: IntegrationOption[] = React.useMemo(
    () =>
      tunableIntegrations.map<IntegrationOption>(integration => ({
        label: integration.name,
        value: integration.guid,
        type: integration.type
      })),
    [tunableIntegrations]
  );

  React.useEffect(() => {
    const firstDeployment = get(supplemental, 'deployments.0.integrations.0.guid');

    if (!integrationGuid && !isEmpty(integrationOptions)) {
      setIntegrationGuid(firstDeployment ?? integrationOptions[0].value);
    }
  }, [integrationGuid, integrationOptions, setIntegrationGuid, supplemental]);

  return (
    <TuningPreviewInterface>
      <HeaderContainer>
        <div>
          <Typography variant='h1'>Detection Tuning</Typography>
          {analytic.name && (
            <Link
              to={{
                pathname: `${Path.Detection}/${analytic.guid}/`
              }}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Typography variant='h2'>{analytic.name}</Typography>
            </Link>
          )}
          <OverwatchOrganizationsAutocomplete
            actions={ACTIONS}
            helperText='Select an organization to view hunt hits.'
          />
          <div className='input-controls'>
            {!isEmpty(organizationIDs) && (
              <>
                <IntegrationAutocomplete
                  className='integration-selection'
                  label='Integration'
                  name='integration'
                  value={integrationGuid}
                  onChange={onIntegrationChange}
                  options={integrationOptions}
                  disabled={isEmpty(integrationOptions) || tuningDisabled}
                  disableClearable
                  disableUserAdditions
                />
                <DateRangeSelector
                  disabled={tuningDisabled}
                  dates={dates}
                  onDateRangeChange={setDates}
                  initialPreset={INITIAL_PRESET}
                />
              </>
            )}
          </div>
        </div>
        <div className='actions'>
          <div className='buttons'>
            <TuningPreviewButton disabled={tuningDisabled} />

            <Menu>
              <BurgerCancel
                to={{
                  pathname: `${Path.Detection}/${analytic.guid}/`
                }}
              />
              <BurgerLink
                icon={faFileAlt}
                title='View Event Logs'
                to={{
                  pathname: `${Path.Detection}/${analytic.guid}/tuning_raw`,
                  state: { dates }
                }}
                target='_blank'
                rel='noopener noreferrer'
              />
            </Menu>
          </div>

          <HitsChart {...hitsChartProps} />
        </div>
      </HeaderContainer>
      <TuningPreviewDialog />
    </TuningPreviewInterface>
  );
}

export default AnalyticTuningHeader;
