import React from 'react';

import useAnalyticLanguages, { AnalyticLanguageCatalog } from 'aso/useAnalyticLanguages';

const AnalyticLanguagesContext = React.createContext<AnalyticLanguageCatalog>(null);
AnalyticLanguagesContext.displayName = 'AnalyticLanguagesContext';

function useAnalyticLanguageCatalog(): AnalyticLanguageCatalog {
  const context = React.useContext<AnalyticLanguageCatalog>(AnalyticLanguagesContext);

  if (!context) {
    throw new Error('useAnalyticLanguageCatalog must be used within the AnalyticLanguagesContext');
  }

  return context;
}

function AnalyticLanguagesProvider({
  children,
  guid
}: {
  children: React.ReactNode;
  guid: string;
}): React.ReactElement {
  const catalog: AnalyticLanguageCatalog = useAnalyticLanguages(guid);
  return <AnalyticLanguagesContext.Provider value={catalog}>{children}</AnalyticLanguagesContext.Provider>;
}

export { AnalyticLanguagesProvider, useAnalyticLanguageCatalog };
