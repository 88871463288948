import { ArtifactScore, Guid } from 'types/common';

export type JobTaskResult = {
  id?: number;
  name: string;
  guid: Guid;
  bas_job_id?: string;
  job_hit_count: number;
  count: number;
  error: string;
  integration: Guid;
  integration_name: string;
  old_rank: ArtifactScore;
  newRank: ArtifactScore;
  logsource: string;
  diff?: number;
};

export enum ArtifactScorePoint {
  'Highest' = 5,
  'High' = 4,
  'Medium' = 3,
  'Low' = 2,
  'Lowest' = 1,
  'Unknown' = 0
}

export const CountChangeDifference = [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5] as const;

export type RangeScore = {
  min: number;
  max: number;
  grade: string;
};

export type BulkConfidencePayload = {
  organization_id: number;
  identifier: Guid;
  rank: ArtifactScore;
};
