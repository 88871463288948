import React from 'react';

import { faTag } from '@fortawesome/pro-solid-svg-icons';

import { BurgerClicker } from 'module/Layout/Artifact.widgets';

import { useAuth } from 'provider';

import { BulkTag } from './BulkTag';

type Provider = { children: React.ReactNode };
type Button = Partial<Omit<BurgerClicker, 'onClick'>>;
type Dialog = Omit<BulkTag, 'isOpen' | 'onClose' | 'children'>;

type BulkTagging = { isOpen: boolean; setIsOpen: (open: boolean) => void };

export function bulkTaggingFactory(
  displayName: string
): [React.ComponentType<Provider>, React.ComponentType<Button>, React.ComponentType<Dialog>] {
  const BulkTaggingContext = React.createContext<{ isOpen: boolean; setIsOpen: (open: boolean) => void }>(null);
  BulkTaggingContext.displayName = displayName;

  function BulkTaggingInterface({ children }: Provider): JSX.Element {
    const [isOpen, setIsOpen] = React.useState(false);

    return <BulkTaggingContext.Provider value={{ isOpen, setIsOpen }}>{children}</BulkTaggingContext.Provider>;
  }

  function useBulkTagging(): BulkTagging {
    const context = React.useContext(BulkTaggingContext);
    if (!context) {
      throw new Error(`BulkTagging components must be used within ${displayName}`);
    }
    return context;
  }

  function Button(props: Button) {
    const { setIsOpen } = useBulkTagging();
    const { user } = useAuth();
    if (!user.superuser) return null;
    return (
      <BurgerClicker
        title='Manage Tags'
        aria-label='Manage Tags'
        icon={faTag}
        onClick={() => setIsOpen(true)}
        {...props}
      />
    );
  }

  function Dialog(props: Dialog) {
    const { isOpen, setIsOpen } = useBulkTagging();
    return <BulkTag isOpen={isOpen} onClose={() => setIsOpen(false)} {...props} />;
  }

  return [BulkTaggingInterface, Button, Dialog];
}
