import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { asValidationError } from 'apis';

import useDebounce from 'hooks/useDebounce';

import { translateRaw, translateRawNative } from 'module/Analytic/Analytic.api';
import { FPRTranslationResult, TranslationResult } from 'module/Analytic/Analytic.type';

import { getFprPayloadValue } from 'services/analyticService';

import { Status, useAsync, useMountedRef } from 'storage';

export interface RawTranslationContext {
  translation: string;
  fprTranslation: string;
  error?: string;
  status: Status;
}

export default function useRawTranslation(
  shouldTranslate: boolean,
  raw: string,
  fprRaw?: string,
  languageId?: number,
  native?: boolean
): RawTranslationContext {
  const {
    data,
    error: requestError,
    run,
    task,
    setData,
    status
  } = useAsync<FPRTranslationResult>({} as FPRTranslationResult);
  const debouncedRaw = useDebounce(raw, 1000);
  const debouncedFpr = useDebounce(fprRaw, 1000);
  // since `shouldTranslate` is based on live, not debounced values, we need to wait for the debounced values to catch
  // up before requesting a translation
  const reallyShouldTranslate = shouldTranslate && debouncedRaw === raw && debouncedFpr === fprRaw;

  React.useEffect(() => {
    if (reallyShouldTranslate && debouncedRaw && languageId) {
      if (native) {
        task(translateRawNative(debouncedRaw, languageId, getFprPayloadValue(debouncedFpr))).then(fprRaw => {
          setData({
            raw: debouncedRaw,
            fpr: { raw: fprRaw } as unknown as TranslationResult
          } as FPRTranslationResult);
        });
      } else {
        run(translateRaw(debouncedRaw, languageId, getFprPayloadValue(debouncedFpr)));
      }
    }
  }, [debouncedFpr, debouncedRaw, languageId, run, reallyShouldTranslate, native, task, setData]);

  let error = data?.error;
  if (requestError) error = asValidationError(requestError).detail?.[0] as string;

  return {
    translation: data.raw,
    fprTranslation: data.fpr?.raw,
    error,
    status
  };
}

export function useRawTranslations(
  shouldTranslate: boolean,
  raw: string,
  languageIds: number[],
  fprRaw?: string,
  native?: boolean
): {
  translations: FPRTranslationResult[];
  status: Status;
  errors: Record<string, string>;
} {
  const mounted = useMountedRef();
  const [data, setData] = React.useState<FPRTranslationResult[]>([]);
  const [status, setStatus] = React.useState<Status>(Status.idle);
  const [errors, setErrors] = React.useState({});

  const debouncedRaw = useDebounce(raw, 1000);
  const debouncedFpr = useDebounce(fprRaw, 1000);
  // since `shouldTranslate` is based on live, not debounced values, we need to wait for the debounced values to catch
  // up before requesting a translation
  const reallyShouldTranslate = shouldTranslate && debouncedRaw === raw && debouncedFpr === fprRaw;

  React.useEffect(() => {
    if (mounted.current) {
      if (reallyShouldTranslate && debouncedRaw && !isEmpty(languageIds)) {
        setStatus(Status.pending);
        const newData: FPRTranslationResult[] = [];
        const promises: Promise<void>[] = [];
        languageIds.forEach(languageId => {
          if (native) {
            promises.push(
              translateRawNative(debouncedRaw, languageId, getFprPayloadValue(debouncedFpr))
                .then(fprRaw => {
                  newData.push({
                    raw: debouncedRaw,
                    fpr: { raw: fprRaw } as unknown as TranslationResult,
                    id: languageId,
                    name: null,
                    error: null
                  });
                })
                .catch(e => {
                  setErrors(errors => ({
                    ...errors,
                    [languageId.toString()]: e
                  }));
                })
            );
          } else {
            promises.push(
              translateRaw(debouncedRaw, languageId, getFprPayloadValue(debouncedFpr))
                .then(raw => {
                  newData.push(raw);
                })
                .catch(e => {
                  setErrors(errors => ({
                    ...errors,
                    [languageId.toString()]: e
                  }));
                })
            );
          }
        });
        Promise.all(promises).then(() => {
          setData(newData);
          setStatus(Status.resolved);
        });
      }
    }
  }, [debouncedFpr, debouncedRaw, languageIds, mounted, native, reallyShouldTranslate]);

  return {
    translations: data,
    errors,
    status
  };
}
