import React from 'react';

import orderBy from 'lodash/orderBy';

import { getAgentList } from 'module/BAS/BAS.api';

import { useAuth } from 'provider';

import { Status, useAsync } from 'storage';

import { BASAgent } from 'types/bas';

export type AgentInterface = {
  agents: BASAgent[];
  filteredAgents: BASAgent[];
  refreshAgents(): void;
  status: Status;
};

type AgentInterfaceProps = {
  children: React.ReactNode;
};

const AgentContext = React.createContext<AgentInterface>(null);

export function AgentProvider(props: AgentInterfaceProps) {
  const { children } = props;

  const { data, run, status } = useAsync<BASAgent[]>([]);
  const { user, defaultOrgId } = useAuth();

  const refreshAgents = React.useCallback(() => {
    if (user.id) run(getAgentList(), true);
  }, [run, user.id]);

  React.useEffect(() => {
    refreshAgents();
  }, [refreshAgents]);

  const agents = React.useMemo(
    () => orderBy(data || [], agent => new Date(agent.last_checkin_time).getTime(), ['desc']),
    [data]
  );
  const filteredAgents = agents.filter(agent => agent.organization_id === defaultOrgId);

  return (
    <AgentContext.Provider value={{ agents, filteredAgents, refreshAgents, status }}>{children}</AgentContext.Provider>
  );
}

export default function useAgents(): AgentInterface {
  const context = React.useContext(AgentContext);

  if (!context) {
    throw new Error('useAgents must be used within an AgentContext');
  }
  return context;
}
