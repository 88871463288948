import React from 'react';

import { PDFExportProps as KendoPDFExportProps, savePDF } from '@progress/kendo-react-pdf';

const DefaultPDFExportProps = {
  paperSize: 'Letter',
  margin: '0.75in',
  scale: 0.325,
  forcePageBreak: '.page-break'
};

export type PDFExportProps = KendoPDFExportProps & {
  ref: React.MutableRefObject<HTMLDivElement>;
};

export function exportToPDF({ ref, ...overrides }: PDFExportProps) {
  return new Promise<void>(resolve => {
    savePDF(
      ref.current,
      {
        ...DefaultPDFExportProps,
        ...overrides
      },
      () => resolve()
    );
  });
}
