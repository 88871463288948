import React from 'react';

import axios from 'axios';
import { Link } from 'react-router-dom';

import Alert from 'snap-ui/Alert';
import { RouterButton } from 'snap-ui/Button';
import { DialogProgressContainer, DisplayDialog } from 'snap-ui/Dialog';
import LinearProgress from 'snap-ui/LinearProgress';
import Placeholder from 'snap-ui/Placeholder';
import { styled } from 'snap-ui/util';

import { pingCaptureStatus, postCapture } from 'apis/resources/harbor';

import Path from 'constants/paths';

import { Engage, Fingerprint } from 'lib/Engagement';

import { ApiError } from 'module/ApiError';

import { ErrorProps, useAsync } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { ArtifactType, CeleryState, Guid } from 'types/common';
import { CapturePayload, CaptureStatus } from 'types/harbor';

import { NOOP } from 'utilities/FunctionUtils';
import { percent } from 'utilities/NumberUtil';
import { formatQueryString } from 'utilities/SearchParam';

type Props = {
  capture: CapturePayload;
  isUpload: boolean;
  onClose: () => void;
  onFinished?: () => void;
};

const ViewThreatContainer = styled('div')`
  text-align: center;
`;

const StyledRouterButton = styled(RouterButton)`
  margin: ${p => p.theme.spacing(4, 0)};
  padding-left: ${p => p.theme.spacing(6)};
  padding-right: ${p => p.theme.spacing(6)};
  background-color: ${p => p.theme.palette.primary.main};
  color: ${p => p.theme.palette.primary.contrastText};
`;

function calculatePercent(progressEvent: any) {
  return Math.min(Math.round(percent(progressEvent.loaded, progressEvent.total)), 99);
}

export default function SessionCreatePublishDialog({ ...props }: Props) {
  const [percentage, setPercentage] = React.useState(0);
  const [taskId, setTaskId] = React.useState<Guid>();
  const [sessionId, setSessionId] = React.useState<Guid>();
  const [message, setMessage] = React.useState('We are creating your session. Please keep modal open until finished.');
  const { task, error, setError, errorProps } = useAsync<CaptureStatus>();

  React.useEffect(() => {
    let isSubscribed = true;
    const cancelTokenSource = axios.CancelToken.source();

    postCapture(props.capture, event => isSubscribed && setPercentage(calculatePercent(event)), cancelTokenSource)
      .then(res => {
        const { tags, references, ...others } = props.capture;
        Engage.track(
          Fingerprint.of(Path.ThreatUpload)
            .withFunctional(FunctionalPermission.CreateSession)
            .withData({
              ...others,
              tags: JSON.stringify(tags),
              references: JSON.stringify(references)
            })
        );
        Engage.trackPersonIncrement(FunctionalPermission.CreateSession, 1);
        setTaskId(res.task_id);
        setPercentage(100);
        setMessage(
          "Your uploaded threat was received and we are processing it now. Please keep this modal open until it's finished."
        );
      })
      .catch(e => {
        Engage.track(
          Fingerprint.error(Path.ThreatUpload).withData({
            action: FunctionalPermission.CreateSession,
            message: e?.detail || 'unknown error'
          })
        );
      })
      .finally(() => {
        if (props.onFinished) props.onFinished();
      });

    return () => {
      isSubscribed = false;
      if (percentage < 100) cancelTokenSource.cancel();
    };
  }, []); // eslint-disable-line

  React.useEffect(() => {
    let timeout;

    if (taskId) {
      const cycle = (time: number) => {
        timeout = setTimeout(async () => {
          var response = await task(pingCaptureStatus(taskId));
          switch (response.status) {
            case CeleryState.success:
              setSessionId(response.output['session_id']);
              break;
            case CeleryState.failed:
              setError(response);
              break;
            default:
              cycle(3500);
          }
        }, time);
      };

      cycle(1000);
    }

    return () => timeout && clearTimeout(timeout);
  }, [setError, task, taskId]);

  const makeSessionLink = (uuid = sessionId) => {
    return {
      pathname: `${Path.Threat}/${uuid}`,
      search: formatQueryString({
        topic: ArtifactType.Marker
      })
    };
  };

  return (
    <DisplayDialog
      disableClickaway
      DialogProps={{
        open: !!props.capture,
        onClose: props.onClose
      }}
      title={`${props.isUpload ? 'Upload' : 'Save'} Captured Threat - Publish to SnapAttack`}
    >
      <>
        {sessionId === undefined && !error && (
          <>
            <Alert severity='info'>{message}</Alert>
            <DialogProgressContainer>
              {percentage >= 100 ? (
                <Placeholder variant='rectangular' height={10} animation='wave' />
              ) : (
                <LinearProgress variant='determinate' value={percentage} />
              )}
            </DialogProgressContainer>
          </>
        )}
        {sessionId && sessionId.length > 0 && (
          <>
            <Alert severity='success'>Your captured threat is ready to view.</Alert>
            <ViewThreatContainer>
              <StyledRouterButton
                aria-label='View uploaded session capture button'
                to={makeSessionLink()}
                onClick={props.onClose || NOOP}
              >
                View Threat
              </StyledRouterButton>
            </ViewThreatContainer>
          </>
        )}
        {error && <ErrorMessage taskError={error} errorProps={errorProps} />}
      </>
    </DisplayDialog>
  );
}

function ErrorMessage({ taskError, errorProps }: { taskError: CaptureStatus; errorProps: ErrorProps }): JSX.Element {
  if (taskError && taskError.output.message?.includes('already exists')) {
    return (
      <Alert severity='warning'>
        This session was previously uploaded and can be found at:{' '}
        <Link to={`${Path.Threat}/${taskError?.output?.session_id}?topic=${ArtifactType.Marker}`}>
          {taskError?.output?.session_name}
        </Link>
      </Alert>
    );
  } else if (taskError && taskError.output.message) return <Alert severity='error'>{taskError?.output?.message}</Alert>;
  else return <ApiError {...errorProps} />;
}
