import React from 'react';

import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import Path from 'constants/paths';

import Can from 'module/Can';
import {
  AddArtifactToCollectionButton,
  AddArtifactToCollectionDialog,
  AddArtifactToCollectionInterface
} from 'module/Curation';
import {
  ArtifactWidgetFooter,
  Author,
  BurgerCancel,
  BurgerDelete,
  BurgerLink,
  BurgerPublish,
  Count,
  Menu,
  Title,
  Visibility
} from 'module/Layout/Artifact.widgets';
import { MoveOrgDialog, MoveOrgModalButton, MoveOrgModalInterface } from 'module/Organization';

import { useAuth } from 'provider';

import { ContentPermission } from 'types/auth';
import { ArtifactType, Visibility as VisibilityType } from 'types/common';

import { decomposeIntel } from '../Intel.helper';
import { Intel } from '../Intel.type';

export default function IntelHeader({
  intel,
  isPending,
  preview,
  onDelete,
  onPublish
}: {
  intel: Intel;
  isPending: boolean;
  preview: boolean;
  onDelete: () => void;
  onPublish: (values: Intel) => void;
}) {
  const { goBack } = useHistory();
  const { user } = useAuth();

  return (
    <>
      <Visibility visibility={intel.visibility} />
      <Title headerText={intel.name} contentType={ArtifactType.Intel} />
      <Author resource={intel} type='intel' preview={preview} />
      {!preview && (
        <ArtifactWidgetFooter>
          <AddArtifactToCollectionInterface>
            <MoveOrgModalInterface type={ArtifactType.Intel}>
              <Count
                isPending={isPending}
                references={intel.reference_counts?.reference_count}
                iocs={intel.indicator_count}
              />
              <Menu>
                {!intel.guid && <BurgerCancel onClick={goBack} />}
                <Can I={ContentPermission.Edit} this={intel}>
                  <BurgerLink
                    to={{
                      pathname: `${Path.IntelligenceEdit}/${intel.guid}`,
                      state: decomposeIntel(intel)
                    }}
                    title='Edit'
                    icon={faEdit}
                  />
                </Can>
                {user?.superuser && intel.visibility !== VisibilityType.Published && (
                  <BurgerPublish onClick={() => onPublish(intel)} />
                )}
                <MoveOrgModalButton />
                <AddArtifactToCollectionButton />
                <Can I={ContentPermission.Edit} this={intel}>
                  <BurgerDelete onClick={onDelete} />
                </Can>
              </Menu>
              <MoveOrgDialog artifact={intel} />
              <AddArtifactToCollectionDialog type={ArtifactType.Intel} guid={intel.guid} />
            </MoveOrgModalInterface>
          </AddArtifactToCollectionInterface>
        </ArtifactWidgetFooter>
      )}
    </>
  );
}
