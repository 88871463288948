import React from 'react';

import { CancelToken } from 'apis';

import { Discriminator, SigmaTag, getSigmaTagsByAnyDiscriminators } from 'module/Tag';

import { Status, useAsync } from 'storage';

const ALL_TYPES = [Discriminator.Actor, Discriminator.Attack, Discriminator.Software, Discriminator.Vulnerability];
const INCLUDE_LEGACY = true;
const LIMIT = 25;

export function useSearchAllTags(onlyNames = false) {
  const { data, run, status, reset } = useAsync<SigmaTag[]>([]);
  const cancelTokenSourceRef = React.useRef(CancelToken.source());

  const search = React.useCallback(
    (partial: string) => {
      cancelTokenSourceRef.current.cancel();
      const source = CancelToken.source();
      cancelTokenSourceRef.current = source;
      run(
        getSigmaTagsByAnyDiscriminators(
          LIMIT,
          { partial, types: ALL_TYPES, legacy: INCLUDE_LEGACY, only_names: onlyNames },
          { cancelToken: source.token }
        )
      );
    },
    [onlyNames, run]
  );

  return {
    data,
    searching: status === Status.pending,
    search,
    reset
  };
}
