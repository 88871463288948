import React from 'react';

import classnames from 'classnames';
import { Link } from 'react-router-dom';

import Grid from 'snap-ui/Grid';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import useTitle from 'hooks/useTitle';

import { Engage, Fingerprint } from 'lib/Engagement';

import { DetectionDeploymentStatus } from 'module/GlobalFilter/Filters/DeployedEnvironment';

import { useAuth, useLanguageContext, usePushSnack } from 'provider';

import { ArtifactType } from 'types/common';

import { formatQueryString } from 'utilities/SearchParam';

import { DEMO_SNACK_MESSAGE } from '../core/Dashboard.widgets';
import { useDashboardStats } from './DetectionDashboardContext';
import DetectionDashboardHeader from './DetectionDashboardHeader';
import { AttackPanel, AvailablePanel, DeployedPanel, OverallPanel, TechniquesPanel } from './Panels';

type Props = {
  className?: string;
};

function DetectionDashboardComponent({ className }: Props) {
  useTitle('Detection Dashboard | SnapAttack');

  const { isSubscriber } = useAuth();
  const { deployed, isResolved } = useDashboardStats();
  const pushSnack = usePushSnack();
  const { data: languageTargets } = useLanguageContext();
  const languages = React.useMemo(() => languageTargets.map(lang => lang.id), [languageTargets]);

  React.useEffect(() => {
    Engage.track(Fingerprint.load(Path.DetectionDashboard));
  }, []);

  React.useEffect(() => {
    if (!isSubscriber) {
      pushSnack(DEMO_SNACK_MESSAGE, 'info', 'center', 'bottom', 30000);
    }
  }, [isSubscriber, pushSnack]);

  React.useEffect(() => {
    if (isSubscriber && isResolved && deployed?.stopCount === 0) {
      pushSnack(
        <>
          It looks like you haven&apos;t deployed any detections yet. This dashboard measures how deployed detections
          are improving your security posture. You should{' '}
          <Link
            to={{
              pathname: Path.Integrations
            }}
          >
            configure an integration
          </Link>{' '}
          and{' '}
          <Link
            to={{
              pathname: Path.Feed,
              search: formatQueryString({
                topic: ArtifactType.Analytic,
                languages: languages
              })
            }}
          >
            find some detections to deploy
          </Link>
          , then check back to see your progress!
        </>,
        'info',
        'center',
        'bottom',
        30000
      );
    }
  }, [deployed?.stopCount, isResolved, languages, pushSnack, isSubscriber]);

  return (
    <div className={classnames('DetectionDashboard', className)}>
      <DetectionDashboardHeader title='Detection Dashboard' />
      <Grid container spacing={3} className='dashboard-contents'>
        <Grid item xs={12} md={6} lg={4}>
          <OverallPanel
            title='Threat Preparedness Score'
            tooltip={
              <>
                A measurement of your organization&apos;s capacity to detect the most severe and prevalent adversary
                tactics, techniques, and procedures based on deployed detection coverage
              </>
            }
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <DeployedPanel
            title='Deployed Detections'
            tooltip={<>The number of SnapAttack detections currently deployed to one or more configured integrations</>}
            linkTo={{
              pathname: Path.Feed,
              search: formatQueryString({
                topic: ArtifactType.Analytic,
                deployedStatus: DetectionDeploymentStatus.success
              })
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <AvailablePanel
            title='Available Detections'
            tooltip={
              <>
                The number of SnapAttack detections that are compatible with one or more of your configured integrations
                and are not currently deployed. Consider reviewing and deploying them to improve your overall posture
                score.
              </>
            }
            linkTo={{
              // /feed?topic=detection&workflowStates=Development&workflowStates=Testing&workflowStates=Verified&workflowStates=Rejected&languages=2&languages=6&languages=56
              pathname: Path.Feed,
              search: formatQueryString({
                topic: ArtifactType.Analytic,
                deployedStatus: DetectionDeploymentStatus.false,
                languages: languages
              })
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <AttackPanel
            title='ATT&CK Coverage Percentage'
            tooltip={
              <>
                Overall coverage of the MITRE ATT&CK matrix based on one or more deployed detections for each technique.
              </>
            }
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <TechniquesPanel
            title='ATT&CK Technique Coverage'
            tooltip={
              <>
                Coverage of the MITRE ATT&CK matrix based on one or more deployed detections for each technique, sorted
                by tactic
              </>
            }
          />
        </Grid>
      </Grid>
    </div>
  );
}

const DetectionDashboard = styled(DetectionDashboardComponent)<Props>`
  flex-grow: 1;
  width: 100vw;
  max-width: 1400px;
  margin: 0 auto;
  padding: ${p => p.theme.spacing(7)};

  .dashboard-contents {
    margin: ${p => p.theme.spacing(2)};
    width: auto;
  }

  .MuiGrid-item > div {
    height: 100%;
    width: auto;
  }

  svg.recharts-surface {
    height: 90%;
    width: auto;
    overflow: visible;
  }

  .recharts-legend-item {
    font-size: 0.8em;
    margin-bottom: ${p => p.theme.spacing(5)};
  }

  .recharts-legend-item-text {
    color: ${p => p.theme.palette.common.white};
    text-transform: uppercase;
    text-align: left;
    vertical-align: middle;
  }

  .recharts-bar-rectangle {
    box-shadow: 1px 1px 2px ${p => p.theme.palette.common.black};
  }

  g.recharts-layer text {
    overflow-wrap: normal;
    overflow: visible;
  }

  g.recharts-scatter-symbol {
    text-shadow: 1px 1px ${p => p.theme.palette.common.black};
    box-shadow: 1px 1px ${p => p.theme.palette.common.black};
  }

  .recharts-layer.recharts-pie {
    width: 150%;
    top: 0px;
    position: absolute;
    margin-top: -100px;
  }
`;

export default DetectionDashboard;
