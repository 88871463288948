import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { AlphaFeature, useAlphaSetting } from 'module/AlphaFeatures';
import { DateRange } from 'module/DateRangeSelector';
import { getDefaultTuningFields } from 'module/Integration/Integration.api';
import { Integration } from 'module/Integration/Integration.type';

import { useUserConfig } from 'provider';

import { ErrorProps, Status, useAsync } from 'storage';

import { DetectionForm } from 'types/analytic';
import { Guid } from 'types/common';

import { postTuningFields, postTuningValues } from './AnalyticTuning.api';
import { buildTuningPayload } from './AnalyticTuning.service';
import { FieldType, TuningFieldsResponse, TuningValuesResponse } from './AnalyticTuning.types';
import useTuningContext from './AnalyticTuningProvider';

type TuningHits = {
  fields: TuningFieldsResponse;
  values: TuningValuesResponse;
  getFields(dates: DateRange, integration: Guid, fieldType?: FieldType, detectionForm?: DetectionForm): void;
  getValues(
    dates: DateRange,
    integration: Guid,
    fields: string[],
    fieldType?: FieldType,
    detectionForm?: DetectionForm,
    hitsSize?: number
  ): void;
  reset(): void;
  fieldsStatus: Status;
  valuesStatus: Status;
  errorProps: {
    fields: ErrorProps;
    values: ErrorProps;
  };
};

/**
 * fields are captured per integration guid, which can encompass
 * multiple log sources.
 */
export function useTuningConfig() {
  const { integrationGuid, integrationType } = useTuningContext();
  const { data: defaultTuningFields, run } = useAsync<Record<Integration['type'], string[]>>();
  const { tuningFields, setTuningFields } = useUserConfig();

  const fetchDefaults = React.useCallback(() => {
    if (integrationGuid && isEmpty(tuningFields[integrationGuid])) run(getDefaultTuningFields());
  }, [integrationGuid, run, tuningFields]);

  React.useEffect(() => {
    fetchDefaults();
  }, [fetchDefaults]);

  const fields = React.useMemo(() => {
    if (!integrationGuid) return [];
    if (isEmpty(tuningFields[integrationGuid])) {
      return isEmpty(defaultTuningFields) ? [] : defaultTuningFields[integrationType] ?? [];
    } else {
      return tuningFields[integrationGuid];
    }
  }, [defaultTuningFields, integrationGuid, integrationType, tuningFields]);

  const setConfig = React.useCallback(
    (updates: string[]) => {
      if (integrationGuid) setTuningFields({ ...tuningFields, [integrationGuid]: updates });
    },
    [integrationGuid, setTuningFields, tuningFields]
  );

  return {
    config: fields,
    setConfig
  };
}

export const HITS_MAX = 500;
export default function useTuningHits(analyticGuid: Guid): TuningHits {
  const { enableAlpha } = useAlphaSetting(AlphaFeature.TuningDataGrid);
  const {
    data: fields,
    run: fieldsRun,
    status: fieldsStatus,
    errorProps: fieldsErrorProps,
    reset: fieldsReset
  } = useAsync<TuningFieldsResponse>();

  const {
    data: values,
    run: valuesRun,
    status: valuesStatus,
    errorProps: valuesErrorProps,
    reset: valuesReset
  } = useAsync<TuningValuesResponse>();

  const getFields = React.useCallback(
    (dates: DateRange, integration: Guid, fieldType?: FieldType, detectionForm?: DetectionForm) => {
      if (analyticGuid) {
        fieldsRun(
          postTuningFields(analyticGuid, buildTuningPayload(dates, integration, null, fieldType, detectionForm))
        );
      }
    },
    [analyticGuid, fieldsRun]
  );

  const getValues = React.useCallback(
    (
      dates: DateRange,
      integration: Guid,
      fieldsNames: string[],
      fieldType?: FieldType,
      detectionForm?: DetectionForm,
      hitsSize?: number
    ) => {
      if (analyticGuid) {
        valuesRun(
          postTuningValues(
            analyticGuid,
            buildTuningPayload(dates, integration, fieldsNames, fieldType, detectionForm),
            enableAlpha ? Math.min(hitsSize, HITS_MAX) : 0
          )
        );
      }
    },
    [analyticGuid, enableAlpha, valuesRun]
  );

  const reset = React.useCallback(() => {
    fieldsReset();
    valuesReset();
  }, [fieldsReset, valuesReset]);

  return {
    fields,
    values,
    getFields,
    getValues,
    reset,
    fieldsStatus,
    valuesStatus,
    errorProps: {
      fields: fieldsErrorProps,
      values: valuesErrorProps
    }
  };
}
