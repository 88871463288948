import React from 'react';

import { z } from 'zod';

import { Option } from 'snap-ui/Autocomplete';
import { FormDialog } from 'snap-ui/Dialog';
import Icon from 'snap-ui/Icon';
import { FieldsLayout } from 'snap-ui/Layout';

import { standardFormikBaseProps } from 'module/Form';
import AutocompleteFormik from 'module/Form/AutocompleteFormik';

import { useAuth } from 'provider';

import { StrictReactNode } from 'types/core';

type SelectOrgForm = {
  orgId: string;
};

export type SelectOrgModal = {
  busy?: boolean;
  children?: StrictReactNode;
  disabled?: boolean;
  isOpen: boolean;
  onCancel(): void;
  onSubmit(value: string): void;
  orgOptions: Option[];
  /** Selects the default org id or the first option, otherwise, blank */
  autoSelect?: boolean;
};

export function SelectOrgModal(props: SelectOrgModal): JSX.Element {
  const { busy, autoSelect, disabled, isOpen, onCancel, onSubmit, orgOptions = [] as Option[] } = props;
  const { defaultOrgId } = useAuth();
  const initialOrg = React.useMemo(
    () =>
      autoSelect
        ? orgOptions.some(org => org.value === defaultOrgId.toString())
          ? defaultOrgId.toString()
          : orgOptions[0]?.value
        : '',
    [autoSelect, defaultOrgId, orgOptions]
  );

  return (
    <FormDialog<SelectOrgForm>
      DialogProps={{ open: isOpen, onClose: onCancel }}
      FormikConfig={{
        ...standardFormikBaseProps,
        initialValues: { orgId: initialOrg },
        onSubmit: v => onSubmit(v.orgId),
        zodSchema: z.object({
          orgId: z.string().nonempty('Organization is required')
        })
      }}
      SubmitProps={{
        children: 'Submit',
        disabled
      }}
      title={<div>Select Organization {busy && <Icon.SpinnerProgress />}</div>}
    >
      <FieldsLayout>
        <AutocompleteFormik
          name='orgId'
          label='Organization'
          options={orgOptions}
          disableClearable
          disableUserAdditions
        />
      </FieldsLayout>
      {props.children}
    </FormDialog>
  );
}
