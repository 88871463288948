import React, { ReactElement } from 'react';

import classNames from 'classnames';
import { useDrag } from 'react-dnd';

type DragBoxProps = {
  name: string;
  type: string;
  disabled?: boolean;
};

function DragBox({ name, type, disabled }: DragBoxProps): ReactElement {
  const [{ opacity }, drag] = useDrag({
    item: { name, type },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });
  return (
    <div
      ref={!disabled ? drag : null}
      className={classNames('dragbox', {
        'operand-box': type === 'OP',
        'section-box': type !== 'OP'
      })}
      style={{ opacity }}
    >
      {name}
    </div>
  );
}

export default DragBox;
