import React from 'react';

import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import Box from 'snap-ui/Box';
import Icon from 'snap-ui/Icon';
import { useTheme } from 'snap-ui/util';

import { StrictReactNode } from 'types/core';

type Props = {
  children?: StrictReactNode;
  className?: string;
};

export function PreferredOrgIcon({ children, className }: Props) {
  const { palette } = useTheme();
  return (
    <Box
      className={className}
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        '& svg': {
          backgroundColor: palette.grey[600],
          borderRadius: '50%',
          padding: '3px 4px',
          minWidth: 'content-fit'
        }
      }}
    >
      <Icon icon={faCheck} color='success' /> {children}
    </Box>
  );
}
