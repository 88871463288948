import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { URLState } from 'types/common';

import { formatQueryString, getQueryParam, getQueryParams, parseAnyQueryString } from 'utilities/SearchParam';

type QueryStringInterface = {
  getByKey(key: string): string;
  getAllByKey(key: string): string[];
  search: string;
  update(values: URLState, onlyIncludedValues?: boolean): void;
};

export default function useQueryString(replace = false): QueryStringInterface {
  const { push: pushLocation, replace: replaceLocation } = useHistory();
  const { pathname, search } = useLocation();

  const update = React.useCallback(
    (update: URLState, onlyIncludedValues = false) => {
      const search = window?.location?.search ?? '';
      const existingValues = onlyIncludedValues ? {} : parseAnyQueryString(search);
      const newLocation = {
        pathname,
        search: formatQueryString({
          ...existingValues,
          ...update
        })
      };
      if (replace) replaceLocation(newLocation);
      else pushLocation(newLocation);
    },
    [pathname, pushLocation, replace, replaceLocation]
  );

  const getByKey = React.useCallback((key: string) => getQueryParam(search, key), [search]);
  const getAllByKey = React.useCallback((key: string) => getQueryParams(search, key), [search]);

  return { getByKey, getAllByKey, search, update };
}

export function usePage(zeroIndexPage = true): { page: number; resetPage(): void; setPage(page: number): void } {
  const DEFAULT_PAGE = zeroIndexPage ? 0 : 1;
  const { getByKey, update } = useQueryString();

  const page = parseInt(getByKey('page') || DEFAULT_PAGE.toString(), 10);

  const setPage = React.useCallback(
    (newPage: number) => {
      update({ page: newPage.toString() });
    },
    [update]
  );

  const resetPage = React.useCallback(() => {
    setPage(DEFAULT_PAGE);
  }, [DEFAULT_PAGE, setPage]);

  return { page, resetPage, setPage };
}
