import React from 'react';

import { SigmaTag } from 'module/Tag';

import { IDEAction, IDEState } from './reducer';

type IDEStateContextValue = {
  ideState: IDEState;
  dispatch(action: IDEAction): void;
  tagOptions: SigmaTag[];
};

const IDEStateContext = React.createContext<IDEStateContextValue>(null);
IDEStateContext.displayName = 'IDEStateContext';

export const IDEStateProvider = IDEStateContext.Provider;

export function useIDEState(): IDEStateContextValue {
  const ideState = React.useContext(IDEStateContext);

  if (!ideState) {
    throw new Error('useIDEState must be used within IDEStateProvider');
  }

  return ideState;
}
