import React from 'react';

import { BurgerClicker } from 'module/Layout/Artifact.widgets';

import { useAuth } from 'provider';

import { checkTaskPermission } from 'services/authService';

import { FunctionalPermission } from 'types/auth';

import { BASJobExporter, icon } from '.';

type Button = Partial<Omit<BurgerClicker, 'onClick'>>;
type Dialog = Omit<BASJobExporter, 'isOpen' | 'onClose'>;

export function useBasExportInterface(): [(props: Button) => JSX.Element, (props: Dialog) => JSX.Element] {
  const { permission } = useAuth();
  const [isOpen, setIsOpen] = React.useState(false);

  if (!checkTaskPermission(permission, FunctionalPermission.BASStableFeatures)) return [() => null, () => null];

  const button = (props: Button) => (
    <BurgerClicker
      title='Export Test Cases'
      aria-label='Export Test Cases'
      icon={icon}
      onClick={() => setIsOpen(true)}
      {...props}
    />
  );
  const dialog = (props: Dialog) => <BASJobExporter isOpen={isOpen} onClose={() => setIsOpen(false)} {...props} />;

  return [button, dialog];
}
