import React from 'react';

import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { Autocomplete, AutocompleteProps, Option } from 'snap-ui/Autocomplete';

import { IntegrationAction } from 'module/Integration/Integration.type';

import { useIntegrationCatalog } from 'provider';

type Props = Pick<AutocompleteProps, 'elevated' | 'multiple' | 'helperText'> & {
  actions: IntegrationAction[];
  required?: boolean;
};

export default function OverwatchOrganizationsAutocomplete({
  actions,
  elevated,
  multiple,
  required,
  helperText
}: Props) {
  const { overwatch } = useIntegrationCatalog();
  const { canOverwatch, organizationIDs, setOrganizationIDs, getOverwatchOrganizations } = overwatch;

  const organizationOptions = React.useMemo(
    () =>
      getOverwatchOrganizations(actions).map(org => ({
        label: org.name,
        content: org.name,
        value: org.id.toString()
      })),
    [actions, getOverwatchOrganizations]
  );

  function handleChange(value: string | Option[]): void {
    if (typeof value === 'string') {
      setOrganizationIDs([Number(value)]);
    } else {
      setOrganizationIDs(value.map(v => Number(v.value)));
    }
  }

  return (
    <>
      {canOverwatch && (
        <Autocomplete
          className={classnames('Form-field organization-select', { required })}
          elevated={elevated}
          label='Organizations'
          name='overwatch-organizations'
          options={organizationOptions}
          onChange={handleChange}
          value={multiple ? organizationIDs.map(o => o.toString()) : organizationIDs[0]?.toString() ?? ''}
          multiple={multiple}
          disableClearable
          disableUserAdditions
          error={isEmpty(organizationIDs)}
          helperText={helperText}
        />
      )}
    </>
  );
}
