import React from 'react';

import CircularProgress from 'snap-ui/CircularProgress';
import Menu from 'snap-ui/Menu';
import MenuItem from 'snap-ui/MenuItem';
import Paper from 'snap-ui/Paper';
import Placeholder from 'snap-ui/Placeholder';
import { styled } from 'snap-ui/util';

import useWorkflow from 'aso/useWorkflow';

import { getCurrentOrgState } from 'module/Card/Card.util';
import { useMayI } from 'module/May';
import { SupplementalArtifact } from 'module/Search';
import DisabledButton from 'module/Widgets/DisabledButton';
import { TimeAgoTimestamp } from 'module/Widgets/TimeAgoTimestamp';

import { useAuth } from 'provider';

import { Status } from 'storage';

import { WorkflowState } from 'types/analytic';
import { FunctionalPermission } from 'types/auth';
import { Artifact } from 'types/common';

import { Section, SectionTitle } from './util';

const StateText = styled('span')`
  &.${WorkflowState.Development} {
    color: ${p => p.theme.palette.orange.main};
  }

  &.${WorkflowState.Rejected} {
    color: ${p => p.theme.palette.error.main};
  }

  &.${WorkflowState.Testing} {
    color: ${p => p.theme.palette.info.main};
  }

  &.${WorkflowState.Verified} {
    color: ${p => p.theme.palette.success.main};
  }
`;

type StateProps = {
  analytic: Artifact;
  supplemental: SupplementalArtifact;
  supplementalStatus: Status;
  supplementalRefresh(): void;
};

export default function State(props: StateProps): JSX.Element {
  const { defaultOrgId, isSubscriber } = useAuth();
  const buttonRef = React.useRef();
  const [showMenu, setShowMenu] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const isLoading = props.supplementalStatus === Status.pending;
  const state = getCurrentOrgState(defaultOrgId, props.supplemental?.states);
  const canEdit = useMayI(FunctionalPermission.SetAnalyticMeta);

  const { update } = useWorkflow(props.analytic.guid);

  let popupContent = '';
  if (!isSubscriber) {
    popupContent = 'Setting detection states is only available to subscribers';
  } else if (!canEdit) {
    popupContent = 'You do not have permission to set detection states';
  }

  React.useEffect(() => {
    // reset
    setShowMenu(false);
    setIsUpdating(false);
  }, [state?.state]);

  function toggleMenu() {
    setShowMenu(!showMenu);
  }

  function handleSetState(state: WorkflowState) {
    return function () {
      update(state).then(props.supplementalRefresh);
      setIsUpdating(true);
      toggleMenu();
    };
  }

  return (
    <Section>
      <SectionTitle>
        State
        {!isLoading && (
          <>
            <DisabledButton
              ButtonProps={{ onClick: toggleMenu, ref: buttonRef, variant: 'text' }}
              disabled={!canEdit || isUpdating}
              disabledTitle={popupContent}
              title=''
            >
              {isUpdating ? <CircularProgress size={25} /> : 'Change'}
            </DisabledButton>
            <Menu anchorEl={buttonRef.current} onClose={toggleMenu} open={showMenu}>
              <Paper>
                {Object.values(WorkflowState).map(state => (
                  <MenuItem key={state} onClick={handleSetState(state)}>
                    <StateText className={state}>{state}</StateText>
                  </MenuItem>
                ))}
              </Paper>
            </Menu>
          </>
        )}
      </SectionTitle>
      {isLoading ? (
        <Placeholder variant='rectangular' height={75} width={'100%'} />
      ) : state ? (
        <dl>
          <div>
            <dt>
              <StateText className={state.state + ' icon'}>{state.state}</StateText>
            </dt>
            <dd>
              <span>
                Updated <TimeAgoTimestamp timestamp={state.modified} />
              </span>
            </dd>
          </div>
        </dl>
      ) : (
        <em>Not Tracked</em>
      )}
    </Section>
  );
}
