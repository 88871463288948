import zod from 'zod';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';

import { FilterConfig } from '../GlobalFilter.type';

type IdsKeys = {
  ids: string[];
};

function toQuery({ ids }: IdsKeys) {
  if (!ids?.length) return;
  return {
    field: 'guid',
    op: Ops.in,
    value: ids
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('guid'),
    op: zod.literal(Ops.in),
    value: zod.array(zod.string())
  })
  .transform(query => ({
    ids: query.value
  }));

const IdsConfig: FilterConfig<IdsKeys> = {
  defaults: { default: () => ({ ids: [] }) },
  component: false,
  supportedTopics: Object.values(ArtifactType),
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default IdsConfig;
