import get from 'lodash/get';

import { GridCsvGetRowsToExportParams, gridFilteredSortedRowIdsSelector } from 'snap-ui/DataGrid';

import { Integration } from 'module/Integration/Integration.type';
import { JobGroupResult, JobOverview } from 'module/Job';

import { Preference } from 'types/auth';
import { ArtifactScore, Guid } from 'types/common';

import { DefaultThreshold } from './BulkConfidence.const';
import { ArtifactScorePoint, JobTaskResult } from './BulkConfidence.type';

export function countConfidenceOccurrences(words: string[] | number[], targetWord: ArtifactScore | number): number {
  let count = 0;

  for (const word of words) {
    if (word === targetWord) {
      count++;
    }
  }

  return count;
}

export function getNewConfidence(mandatory_preference: Preference, count: number): ArtifactScore {
  const highest = get(mandatory_preference, 'rank_threshold.Highest.max') || DefaultThreshold.Highest;
  const high = get(mandatory_preference, 'rank_threshold.High.max') || DefaultThreshold.High;
  const medium = get(mandatory_preference, 'rank_threshold.Medium.max') || DefaultThreshold.Medium;
  const low = get(mandatory_preference, 'rank_threshold.Low.max') || DefaultThreshold.Low;

  if (count <= highest) {
    return ArtifactScore.HIGHEST;
  } else if (count > highest && count <= high) {
    return ArtifactScore.HIGH;
  } else if (count > high && count <= medium) {
    return ArtifactScore.MEDIUM;
  } else if (count > medium && count <= low) {
    return ArtifactScore.LOW;
  } else {
    return ArtifactScore.LOWEST;
  }
}

export function getConfidenceDifference(oldConfidence: ArtifactScore, newConfidence: ArtifactScore) {
  return ArtifactScorePoint[newConfidence] - ArtifactScorePoint[oldConfidence];
}

export function addNewRankToDuplicateGuid(jobGroupTask: JobGroupResult, mandatory_preference: Preference, guid: Guid) {
  const duplicateDetectionCount = jobGroupTask
    .filter(detection => detection.guid === guid)
    .map(detection => detection.count);
  const highestCount = duplicateDetectionCount.reduce((totalCount, currentValue) => totalCount + currentValue, 0);
  return getNewConfidence(mandatory_preference, highestCount);
}

export function preparedBulkConfidenceForTable(jobGroupTask: JobGroupResult) {
  const detectionWithDuplicateGuid = jobGroupTask.map(detection => {
    return {
      ...detection,
      groupGuid:
        jobGroupTask.filter(d => d.guid === detection.guid).length > 1
          ? [detection.guid, detection.integration_name]
          : [detection.guid]
    };
  });
  const muiDataDetectionMap = createAdditionalDetectionBasedOnDuplicateGuid(detectionWithDuplicateGuid);
  return muiDataDetectionMap;
}
export function uniqueBulkConfidence(detection: JobGroupResult) {
  const uniqueConfidence = detection.reduce((allDetection, eachDetection) => {
    if (!allDetection[eachDetection.guid]) {
      allDetection[eachDetection.guid] = eachDetection;
    }
    return allDetection;
  }, {});
  return Object.values(uniqueConfidence);
}

export function createAdditionalDetectionBasedOnDuplicateGuid(allDetection: JobGroupResult): JobGroupResult {
  const guidCounts: Record<string, number> = {};
  const newBulkConfidence: JobGroupResult = [...allDetection];

  allDetection.forEach(detection => {
    guidCounts[detection.guid] = guidCounts[detection.guid] ? guidCounts[detection.guid] + 1 : 1;
  });
  for (const guid in guidCounts) {
    if (guidCounts[guid] > 1) {
      for (let i = 1; i <= guidCounts[guid]; i++) {
        let totalCount = 0;
        const detection = allDetection.find(detection => detection.guid === guid);
        const groupIntegrationName = allDetection
          .filter(b => b.guid === guid)
          .map(b => b.integration_name)
          .join();
        allDetection
          .filter(detection => detection.guid === guid)
          .forEach(detection => {
            totalCount += detection.count;
          });
        if (i === guidCounts[guid]) {
          newBulkConfidence.push({
            ...detection,
            count: totalCount,
            integration_name: groupIntegrationName,
            integration: '',
            groupGuid: [detection.guid]
          });
        }
      }
    }
  }

  return newBulkConfidence;
}
export function getIntegrationNameFromJob(
  jobs: { guid?: string }[],
  allJobData: JobOverview[],
  integrations: Integration[]
) {
  const integrationName = [];
  jobs.forEach(job =>
    allJobData.forEach(eachJobData => {
      if (eachJobData.analytic_job_guid === job.guid)
        integrations.forEach(integration => {
          if (integration.guid === eachJobData.integration_guid) integrationName.push(integration.name);
        });
    })
  );
  return integrationName;
}

export const getRowsWithoutGroups = ({ apiRef }: GridCsvGetRowsToExportParams) => {
  const rows = gridFilteredSortedRowIdsSelector(apiRef);
  return rows.filter(row => apiRef.current.getRow(row).groupGuid.length === 1);
};

export const formatConfidenceDetectionsForPayload = (detections: JobTaskResult[], defaultOrgId: number) => {
  const payload = detections.map(detection => {
    return {
      organization_id: defaultOrgId,
      identifier: detection.guid,
      rank: detection.newRank
    };
  });
  return payload;
};
