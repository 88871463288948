import React from 'react';

import { ErrorProps } from 'module/ApiError';

import { Status, useAsync } from 'storage';

import { Guid } from 'types/common';

import { getAnalyticVersionsByGuid } from '../Analytic.api';
import { AnalyticVersion } from '../Analytic.type';

type AnalyticVersionCatalog = {
  errorProps?: ErrorProps;
  latestVersion: AnalyticVersion;
  refresh(): void;
  status: Status;
  versions: AnalyticVersion[];
};

const AnalyticVersionContext = React.createContext<AnalyticVersionCatalog>(null);
AnalyticVersionContext.displayName = 'AnalyticVersionContext';

function useAnalyticVersions(analyticGuid: Guid): AnalyticVersionCatalog {
  const { data: versions, run, status, errorProps } = useAsync<AnalyticVersion[]>([]);

  const refresh = React.useCallback(() => {
    if (analyticGuid) run(getAnalyticVersionsByGuid(analyticGuid));
  }, [analyticGuid, run]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    errorProps,
    latestVersion: versions?.filter(v => v.guid === analyticGuid)?.[0] ?? null,
    refresh,
    status,
    versions
  };
}

function useAnalyticVersionCatalog(): AnalyticVersionCatalog {
  const context = React.useContext<AnalyticVersionCatalog>(AnalyticVersionContext);

  if (!context) {
    throw new Error('useAnalyticVersionCatalog must be used within the AnalyticVersionContext');
  }

  return context;
}

function AnalyticVersionProvider({
  analyticGuid,
  children
}: {
  analyticGuid: Guid;
  children: React.ReactNode;
}): JSX.Element {
  const catalog = useAnalyticVersions(analyticGuid);

  return <AnalyticVersionContext.Provider value={catalog}>{children}</AnalyticVersionContext.Provider>;
}

export { AnalyticVersionProvider, useAnalyticVersionCatalog };
