import React from 'react';

import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';

import Accordion, { AccordionSummary } from 'snap-ui/Accordion';
import { DataGrid, GridColDef, GridToolbarFilterButton, GridValidRowModel } from 'snap-ui/DataGrid';
import Icon from 'snap-ui/Icon';
import Link from 'snap-ui/Link';
import Typography from 'snap-ui/Typography';

import { Discriminator } from 'module/Tag';

import { useLandingCatalog } from '../../LandingProvider';

export default function VendorRemediationGuide() {
  const { source, type } = useLandingCatalog();

  if (type !== Discriminator.Vulnerability || isEmpty(source.vendor_fix_references)) return null;
  const columns: GridColDef<GridValidRowModel>[] = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 300,
      flex: 0.3
    },
    {
      field: 'url',
      headerName: 'URL',
      flex: 1,
      disableColumnMenu: true,
      renderCell: cell => (
        <div>
          <Typography variant='body2'>
            <Link primary target='_blank' to={cell.value}>
              {cell.value}
            </Link>
          </Typography>
        </div>
      )
    }
  ];
  return (
    <Accordion className='LandingSection-accordion' defaultExpanded elevation={0}>
      <AccordionSummary className='LandingSection-accordionSummary' expandIcon={<Icon icon={faCaretUp} />}>
        <Typography variant='h4'>Vendor Remediation Guidance</Typography>
      </AccordionSummary>
      <DataGrid
        className='data-grid table-height'
        columns={columns}
        disableColumnResize
        rows={source.vendor_fix_references}
        disableRowSelectionOnClick
        getRowId={row => row.url}
        getRowClassName={p => (p.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        getCellClassName={() => 'dataGrid-row'}
        slots={{ toolbar: () => <GridToolbarFilterButton /> }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }]
          }
        }}
      />
    </Accordion>
  );
}
