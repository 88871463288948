import React from 'react';

import Slider, { SliderProps } from '@mui/material/Slider';

import { styled } from './util';

export type { SliderProps } from '@mui/material';

const SliderContainer = styled('div')`
  margin-top: ${p => p.theme.spacing(3)};
  padding: ${p => p.theme.spacing(0, 9)};
`;

const StyledSlider = styled(Slider)`
  .MuiSlider-mark {
    background-color: ${p => p.theme.palette.greyish.main};
    height: 16px;
    width: 16px;
    margin-left: -8px;
    border-radius: 50%;

    &.MuiSlider-markActive {
      opacity: 1;
      background-color: ${p => p.theme.palette.primary.main};
    }
  }
`;

export default StyledSlider;

export function WrappedSlider(props: SliderProps) {
  return (
    <SliderContainer>
      <StyledSlider {...props} />
    </SliderContainer>
  );
}
