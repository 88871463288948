/**
 * Join with space, strip beginning forward slashes, replace inner forward slashes, and lowercase
 */
export function standardize(value: string): string {
  return (value || '').replace(/\s\//g, ' ').replace(/\//g, ' ').replace(/_/g, ' ').trim().toLowerCase();
}

export function standardizeFilter(filter: Record<string, any>): object {
  return Object.entries(filter).reduce(
    (previousValue: any, [key, value]: any) => ({ ...previousValue, [`filter_${key}`]: value }),
    {}
  );
}
