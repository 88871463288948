import React from 'react';

import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import Button from 'snap-ui/Button';
import { ConfirmDialog } from 'snap-ui/Dialog';

import { asValidationError, ValidationError } from 'apis';

import Path from 'constants/paths';

import { deleteCollection } from 'module/Collection/Collection.api';

import { ArtifactType, Guid, IconButtonRenderProps } from 'types/common';

type Props = IconButtonRenderProps & {
  guid: Guid;
  onCancel?(): void;
  onDelete?(): void;
  onError?(error: ValidationError): void;
};

export default function CurationConfirmDelete({ children, guid, onCancel, onDelete, onError }: Props) {
  const { replace } = useHistory();
  const [isConfirming, setIsConfirming] = React.useState(false);

  const handleDelete = async () => {
    if (onDelete) onDelete();
    setIsConfirming(false);
    deleteCollection(guid)
      .then(() => replace(`${Path.Feed}?topic=${ArtifactType.Collection}`))
      .catch(error => onError(asValidationError(error)));
  };

  return (
    <>
      {children && children({ onClick: () => setIsConfirming(true), icon: faTrash })}
      {!children && (
        <Button
          className='CurationConfirmDelete-button'
          variant='outlined'
          onClick={() => setIsConfirming(true)}
          aria-label='Delete collection'
        >
          Delete
        </Button>
      )}
      <ConfirmDialog
        DialogProps={{ open: isConfirming, onClose: () => setIsConfirming(false) }}
        ConfirmProps={{ onClick: handleDelete, children: 'Delete' }}
        title='Delete forever'
        SecondaryProps={{ children: 'Cancel', onClick: onCancel }}
      >
        Are you sure you want to delete this collection?
      </ConfirmDialog>
    </>
  );
}
