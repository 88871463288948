import React from 'react';

import Icon from 'snap-ui/Icon';

import { BurgerClicker } from 'module/Layout/Artifact.widgets';
import { useMayI } from 'module/May';

import { FunctionalPermission } from 'types/auth';

import AddAttackScriptToSession, { AddAttackScriptToSessionProps } from './AddAttackScriptToSession';

type Button = Partial<BurgerClicker>;
type Dialog = Omit<AddAttackScriptToSessionProps, 'isOpen' | 'onClose'>;

interface AddAttackScriptToSessionInterface {
  isOpen: boolean;
  setIsOpen(open: boolean): void;
  isBasUser: boolean;
}

const AddAttackScriptToSessionContext = React.createContext<AddAttackScriptToSessionInterface>(null);
AddAttackScriptToSessionContext.displayName = 'AddAttackScriptToSessionContext';

export function AddAttackScriptToSessionInterface({ children }: { children: React.ReactNode }) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const isBasUser = useMayI(FunctionalPermission.BASStableFeatures);

  return (
    <AddAttackScriptToSessionContext.Provider value={{ isOpen, setIsOpen, isBasUser }}>
      {children}
    </AddAttackScriptToSessionContext.Provider>
  );
}

function useAddAttackScriptToSession(): AddAttackScriptToSessionInterface {
  const context = React.useContext(AddAttackScriptToSessionContext);
  if (!context) {
    throw new Error('useAddAttackScriptToSession must be used within an AddAttackScriptToSessionInterface');
  }
  return context;
}

export function AddAttackScriptToSessionButton({ onClick, ...others }: Button) {
  const { setIsOpen, isBasUser } = useAddAttackScriptToSession();
  if (!isBasUser) return null;
  return (
    <BurgerClicker
      title='Link an Attack Script'
      icon={<Icon.AttackScript color='inherit' />}
      onClick={() => {
        setIsOpen(true);
        if (onClick) onClick();
      }}
      {...others}
    />
  );
}

export function AddAttackScriptToSessionDialog(props: Dialog) {
  const { isOpen, setIsOpen, isBasUser } = useAddAttackScriptToSession();
  if (!isBasUser) return null;
  return <AddAttackScriptToSession key='value' isOpen={isOpen} onClose={() => setIsOpen(false)} {...props} />;
}
