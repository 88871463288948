import React from 'react';

import { faLinkSimple, faLinkSlash } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

import { ActionIconButton } from 'snap-ui/Button';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { FeedLink } from 'module/Feed/Feed.type';

import { Guid } from 'types/common';

import { NOOP } from 'utilities/FunctionUtils';

const Container = styled(Tooltip)`
  z-index: 1;
`;

type Props = {
  name: string;
  guid: Guid;
  type: FeedLink;
  readonly: boolean;
  onDelete(id: Guid): void;
};

function CardDeleteButton({ name, guid, type, onDelete, readonly }: Props) {
  const handleDelete = (): void => onDelete(guid);

  return (
    <Container
      arrow
      placement='top'
      title={
        readonly
          ? `This ${type.text.toLowerCase()} is linked automatically and cannot be removed.`
          : `Remove ${type.text}: ${name}`
      }
      wrap
    >
      <ActionIconButton
        aria-label={
          readonly
            ? `This ${type.text.toLowerCase()} is linked automatically and cannot be removed.`
            : `Remove ${type.text}: ${name}`
        }
        className={classNames('remove-button', { readonly })}
        icon={readonly ? faLinkSimple : faLinkSlash}
        onClick={readonly ? NOOP : handleDelete}
        disabled={readonly}
      />
    </Container>
  );
}

export default CardDeleteButton;
