import React from 'react';

import { CancelToken } from 'apis/snapattack';

import { ErrorProps } from 'module/ApiError';
import { isLandingType } from 'module/Collection/Collection.util';

import { Status, useAsync } from 'storage';

import { ArtifactType, Tracked } from 'types/common';

import { getSupplementalItemsCatalog } from './Search.service';
import { SupplementalCatalog } from './Search.type';

export type SupplementalInterface = {
  errorProps: ErrorProps;
  supplemental: SupplementalCatalog;
  status: Status;
};

export default function useSupplementalItems<T extends Tracked & { type?: string }>(
  topic: ArtifactType,
  items: T[]
): SupplementalInterface {
  const { data: supplemental, errorProps, status, run } = useAsync<SupplementalCatalog>({});
  const itemsRunTrigger = JSON.stringify(items);

  React.useEffect(() => {
    const cancelSource = CancelToken.source();
    if (items.length > 0) {
      const nonLandingItems = items.filter(i => !isLandingType(i));
      run(getSupplementalItemsCatalog(topic, nonLandingItems, cancelSource));
    }
    return () => cancelSource?.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsRunTrigger, run]);

  return {
    errorProps,
    supplemental,
    status
  };
}
