import React from 'react';

import classNames from 'classnames';

import Chip from 'snap-ui/Chip';
import Icon from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

const MarkerContentContainer = styled('div')`
  .hollow-icon {
    color: ${p => p.theme.palette.secondary.main};
  }
`;

type Props = {
  showError: boolean;
  children: React.ReactChild;
  className?: string;
};

const ErrorContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(1)};
`;

export default function Wrapper({ className, showError, children }: Props) {
  return (
    <MarkerContentContainer className={classNames('meta', className)}>
      {showError && (
        <ErrorContainer>
          <strong>Detection Hits</strong>
          <Chip
            label={
              <>
                <Icon.Lock /> Restricted
              </>
            }
          />
        </ErrorContainer>
      )}
      {!showError && children}
    </MarkerContentContainer>
  );
}
