import { Auth, Organization } from 'types/auth';

import Path from './paths';

export const COMMUNITY_ORGANIZATION: Partial<Organization> = {
  id: 2147483627,
  guid: '00000000-0000-0000-0000-000000000000',
  name: 'SnapAttack Community',
  isolated: false
};

export const SUBSCRIBER_ORGANIZATION_ID = 1;
export const SUBSCRIBER_ORGANIZATION_GUID = '00000000-0000-0000-0000-000000000001';

export const DEMO_ORG_GUIDS = [
  '3e1a9df8-2898-4f48-b127-66d21feb2373', // Test
  'e2a096c4-2cbc-4120-8fad-a9c46bb38afe' // Prod
];

export const LOGIN_METHODS = {
  SSO: 'sso',
  EMAIL_PASSWORD: 'email-password'
};

export const AUTH_SHELL: Auth = {
  user: {
    guid: null,
    id: null,
    name: null,
    first_name: null,
    last_name: null,
    email: null,
    superuser: false,
    verified: false,
    is_subscriber: false,
    is_isolated: false,
    status: null,
    sso: false,
    mfa: false,
    mfa_configured: false,
    mfa_required_org: false
  },
  permission: [],
  preferable: [],
  isSubscriber: false,
  isTraining: false,
  defaultOrgId: null,
  defaultOrgGuid: null,
  defaultOrgName: null,
  tenantOrgId: null
};

export const UNAUTHENTICATED_ROUTES: string[] = [
  Path.Login,
  Path.Register,
  Path.ForgotPassword,
  Path.ResetPassword,
  Path.Multifactor,
  Path.MultifactorConfiguration,
  Path.Logout
];
