import Accordion from 'snap-ui/Accordion';
import Button from 'snap-ui/Button';
import { styled } from 'snap-ui/util';

export const LanguageConfigPage = styled('div')`
  .MuiTypography-root {
    margin-bottom: ${p => p.theme.spacing(4)};
  }

  .MuiTabs-scroller {
    padding-top: ${p => p.theme.spacing(2)}; // make room for drodown floating label
  }

  .organization-dropdown {
    float: right;
    width: 300px;
  }

  .other-languages {
    margin: ${p => p.theme.spacing(8, 0)};
  }
`;

export const SigmaConfigButton = styled(Button)`
  padding: 0;
  justify-content: flex-start;
  min-width: 0;
  text-align: left;

  &.editable {
    text-decoration: underline;
  }
`;

export const OtherLanguagesAccordion = styled(Accordion)`
  .MuiAccordionSummary-root {
    padding: ${p => p.theme.spacing(0, 3)};
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }
`;
