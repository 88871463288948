import React from 'react';

import { styled } from './util';

const StyledPre = styled('pre')`
  padding: ${p => p.theme.spacing(4)};
  border-left: 2px solid ${p => p.theme.palette.primary.main};
  background: ${p => p.theme.palette.surface.pre};
`;

export default function CodeBlock(props: React.HTMLAttributes<HTMLPreElement>): JSX.Element {
  return <StyledPre {...props} />;
}
