import React from 'react';

import zod from 'zod';

import { Option } from 'snap-ui/Autocomplete';

import { Discriminator } from 'module/Tag';
import useTagOptions from 'module/Tag/useTagOptions';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';

import { FilterConfig } from '../GlobalFilter.type';
import InclusiveAutocomplete from './InclusiveAutocomplete';

type VulnerabilityKeys = {
  vulnerability: string[];
  vulnerabilityOp: Ops;
};

type VulnerabilityFilterProps = {
  onChange(values: Partial<VulnerabilityKeys>): void;
  values: VulnerabilityKeys;
};

function VulnerabilityFilter({ onChange, values }: VulnerabilityFilterProps): React.ReactElement {
  const { options, search, searching } = useTagOptions(Discriminator.Vulnerability);

  const handleValueChange = (option: Option[]) => {
    const payload = { vulnerability: option.map(o => o.value) };
    if (option.length === 0) payload['vulnerabilityOp'] = undefined;
    onChange(payload);
  };

  const handleOperatorChange = (vulnerabilityOp: Ops) => {
    onChange({ vulnerabilityOp });
  };

  return (
    <InclusiveAutocomplete
      title='By Vulnerability'
      name='vulnerability_tags_dropdown'
      option={options}
      value={values.vulnerability}
      onValueChange={handleValueChange}
      onOperatorChange={handleOperatorChange}
      operator={values.vulnerabilityOp}
      onSearch={search}
      searching={searching}
    />
  );
}

function toQuery(values: VulnerabilityKeys) {
  if (!values.vulnerability?.length) return;
  return {
    field: 'vulnerability_aliases',
    op: values.vulnerabilityOp,
    value: values.vulnerability
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('vulnerability_aliases'),
    op: zod.nativeEnum(Ops),
    value: zod.array(zod.string())
  })
  .transform(query => ({
    vulnerability: query.value,
    vulnerabilityOp: query.op
  }));

const VulnerabilityFilterConfig: FilterConfig<VulnerabilityKeys> = {
  defaults: { default: () => ({ vulnerability: [], vulnerabilityOp: Ops.any }) },
  supportedTopics: [
    ArtifactType.Analytic,
    ArtifactType.Collection,
    ArtifactType.Intel,
    ArtifactType.Session,
    ArtifactType.AttackScript
  ],
  component: VulnerabilityFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default VulnerabilityFilterConfig;
