import React from 'react';

import { useLocation } from 'react-router-dom';

export default function useIsLocationTransition(): boolean {
  const { pathname } = useLocation();
  const [referencePath, setReferencePath] = React.useState<string>();

  React.useEffect(() => {
    if (!referencePath) setReferencePath(pathname);
  }, [pathname, referencePath]);

  return !!referencePath && referencePath !== pathname;
}
