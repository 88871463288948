import { FormDialog } from 'snap-ui/Dialog';
import { styled } from 'snap-ui/util';

const INNNER_HEIGHT = '50vh';

export const CardField = styled('div', { shouldForwardProp: p => p !== 'active' })<{ active?: boolean }>`
  --gap: ${p => p.theme.spacing(4)};

  display: grid;
  grid-template-columns: ${p => (p.active ? '25% calc(75% - var(--gap))' : '100% 0')};
  grid-auto-rows: ${INNNER_HEIGHT};
  gap: var(--gap);
  overflow: hidden;

  .MuiDivider-root {
    &:not(:first-of-type) {
      margin-top: ${p => p.theme.spacing(3)};
    }
    margin-bottom: ${p => p.theme.spacing(1)};
  }

  .FieldList {
    overflow: hidden auto;
  }

  .CheckableCards {
    grid-auto-rows: auto;
  }

  .CheckableCard-label,
  .CheckableCard-detail {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .SelectedField {
    padding: ${p => p.theme.spacing(1, 4)};
    overflow-y: auto;

    .MuiAlert-root {
      padding: ${p => p.theme.spacing(0, 2)};
    }
  }
`;

export const FieldPickerDialog = styled(FormDialog)`
  .MuiDialogContent-root,
  .MuiDialogContent-root > div {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .NoFields {
    height: ${INNNER_HEIGHT};
  }
`;
