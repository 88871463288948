import { highlight, languages, Grammar } from 'prismjs';
import 'prismjs/components/prism-diff.min';
import 'prismjs/components/prism-javascript.min';
import 'prismjs/components/prism-json.min';
import 'prismjs/components/prism-kusto.min';
import 'prismjs/components/prism-markup.min';
import 'prismjs/components/prism-python.min';
import 'prismjs/components/prism-splunk-spl.min';
import 'prismjs/components/prism-sql.min';
import 'prismjs/components/prism-yaml.min';
import 'prismjs/plugins/diff-highlight/prism-diff-highlight.min';
import 'prismjs/themes/prism-tomorrow.min.css';

import './diff-highlight.css';

export type PrismGrammar = Grammar;

/*
 * List of supported integrations/languages:
 *   Carbon Black, Chronicle, CrowdStrike, Elastic, Microsoft Defender, Microsoft Sentinel,
 *   SentinelOne, Splunk, USMAnywhere, Falcon LogScale, SumoLogic
 *
 * Each one of these will need their own grammar file evntually. Since PrismJS seems to be more for coding languages,
 * suggestion is to use GenAI to convert existing syntax highlighters into the Prism format. The source materials that
 * were used to generate each grammer are noted (not all of them have published highlighters, but reference materials
 * are a good stop-gap measure.)
 *
 * Other grammars should we need them, but Prism has support for Splunk SPL and Kusto KQL
 * - https://github.com/splunk/vscode-extension-splunk
 * - https://github.com/Azure/monaco-kusto/blob/master/package/src/languageService/kustoMonarchLanguageDefinition.ts
 */

// https://developer.carbonblack.com/resources/query_overview.pdf
languages.carbonblack = {
  comment: {
    pattern: /#.*$/,
    greedy: true
  },
  string: {
    pattern: /"(?:\\.|[^\\"])*"/,
    greedy: true
  },
  keyword: {
    pattern:
      /\b(?:process_name|cmdline|parent_name|childproc_name|filewrite_md5|modload|regmod|domain|ipaddr|ipv6addr|md5|sha256|hostname|username|path|process_md5|start|last_update|blocked_md5|blocked_status|childproc_count|childproc_md5|childproc_sha256|company_name|crossproc_count|crossproc_md5|crossproc_sha256|crossproc_name|crossproc_type|digsig_issuer|digsig_prog_name|digsig_publisher|digsig_result|digsig_sign_time|digsig_subject|file_desc|file_version|filemod|filemod_count|filewrite_sha256|group|has_emet_config|has_emet_event|host_count|host_type|internal_name|ipport|is_64bit|is_executable_image|ja3|ja3s|last_server_update|legal_copyright|legal_trademark|modload_count|netconn_count|observed_filename|orig_mod_len|original_filename|os_type|parent_id|parent_md5|parent_sha256|product_desc|product_name|product_version|regmod_count|sensor_id|server_added_timestamp|special_build|tampered|username|watchlist_id|process_username|device_name|process_product_name|netconn_domain|netconn_ipv4|netconn_port|modload_name|parent_cmdline|netconn_local_ipv4|regmod_name|filemod_name|process_company_name|process_product_version|hash|event_id|process_original_filename|process_integrity_level|process_service_name|childproc_publisher_state|filemod_publisher_state|modload_publisher_state|parent_publisher_state|process_publisher_state|scriptload_publisher_state|netconn_ipv6|netconn_local_ipv6|netconn_local_port|childproc_cmdline|crossproc_cmdline|childproc_username|process_publisher|process_file_description|netconn_proxy_domain|childproc_publisher|filemod_publisher|parent_publisher)\b/,
    greedy: true
  },
  operator: /\b(?:and|AND|or|OR|not|NOT)\b|-/,
  boolean: /\b(?:true|false)\b/,
  number: /\b\d+\b/,
  punctuation: /[(){}[\];,]/
};

// https://github.com/7RedViolin/pySigma-backend-sentinelone/tree/master/sigma
// https://assets.sentinelone.com/dv/sentinel-one-dv-chea-1?xs=105462#page=1
languages.sentinelone = {
  comment: /#.*/,
  keyword: /(?<=\b(AND|OR|NOT)\b\s*)\b\w+\b|\b\w+\b(?=\s*=)/i,
  operator: /\b(?:or|and|not|is|empty|exists|in|contains|anycase|startswithcis|endswithcis|containscis|=|<|<=|>|>=)\b/i,
  punctuation: /[{}[\];(),.:]/,
  number: /\b\d+\b/,
  boolean: /\b(?:true|false)\b/
};

// https://cloud.google.com/chronicle/docs/investigation/udm-search-best-practices
languages.udm = {
  comment: /#.*/,
  keyword: {
    pattern: /\b\w+(?:\.\w+)*(?:\["[^"]*"\])?\s*(?==)/,
    greedy: true
  },
  nocase: {
    pattern: /\b(?:nocase)\b/,
    greedy: true,
    alias: 'keyword'
  },
  operator: /\b(?:AND|OR|NOT)\b/,
  punctuation: /[{}[\];(),.:]/,
  number: /\b\d+\b/,
  boolean: /\b(?:true|false)\b/
};

// https://github.com/chronicle/yara-l-extension
// https://cloud.google.com/chronicle/docs/detection/yara-l-2-0-syntax
languages['yara-l'] = {
  comment: [
    {
      pattern: /\/\/.*/,
      greedy: true
    },
    {
      pattern: /\/\*[\s\S]*?\*\//,
      greedy: true
    }
  ],
  string: {
    pattern: /"(?:[^"\\]|\\.)*"/,
    greedy: true
  },
  regex: {
    pattern: /\/(?:[^/\\]|\\.)*\//,
    greedy: true,
    alias: 'string'
  },
  keyword: {
    pattern:
      /\b(?:rule|meta|match|over|events|condition|outcome|options|and|or|not|nocase|in|regex|cidr|before|after|all|any|if|max|min|sum|array|array_distinct|count|count_distinct|is|null)\b/,
    greedy: true
  },
  function: {
    pattern:
      /\b(?:arrays\.length|math\.abs|net\.ip_in_range_cidr|re\.regex|re\.capture|re\.replace|strings\.base64_decode|strings\.coalesce|strings\.concat|strings\.to_lower|strings\.to_upper|timestamp\.current_seconds|timestamp\.get_date|timestamp\.get_minute|timestamp\.get_hour|timestamp\.get_day_of_week|timestamp\.get_week)\b/,
    greedy: true
  },
  url: {
    pattern: /(?<!['"`\w])\$(?:[a-zA-Z_][\w]*)(?:\.[a-zA-Z_][\w]*)*(?=(?:[^"'`]*['"`][^"'`]*['"`])*[^"'`]*$)/,
    greedy: true
  },
  number: /\b\d+\b/,
  operator: /!=|<=?|>=?|==?|\+|-|\*|\/|%|\||&|^|~|<|>/,
  punctuation: /[{}[\];(),.:]/
};

// https://library.humio.com/data-analysis/syntax.html
languages.humio = {
  comment: [
    {
      pattern: /\/\/.*/,
      greedy: true
    },
    {
      pattern: /\/\*[\s\S]*?\*\//,
      greedy: true
    }
  ],
  string: {
    pattern: /"(?:\\.|[^\\"])*"/,
    greedy: true
  },
  keyword:
    /\b(?:if|else|for|while|return|function|var|let|const|case|match|default|time|math|array|event|eval|drop|callFunction|counterAsRate|filter|not|and|or|cidr|in|regex|wildcard)\b/,
  boolean: /\b(?:true|false)\b/,
  number: /\b\d+(?:\.\d+)?(?:e[+-]?\d+)?\b/i,
  operator: /[=!<>]=?|[+\-*/%]/,
  punctuation: /[{}[\];(),.:]/,
  field: {
    pattern: /\b[_a-zA-Z][_a-zA-Z0-9]*\b(?=\s*:)/,
    alias: 'variable'
  },
  function: {
    pattern: /\b[a-zA-Z_]\w*(?=\()/,
    alias: 'function'
  },
  regex: {
    pattern: /\/(?:\\.|[^\\/])+\//,
    greedy: true
  },
  directive: {
    pattern: /#(?:include|define|undef|ifdef|ifndef|if|else|elif|endif|error|pragma)\b/,
    alias: 'important'
  },
  'time-function': {
    pattern:
      /\btime:(?:dayOfMonth|dayOfWeek|dayOfWeekName|dayOfYear|hour|millisecond|minute|month|monthName|second|weekOfYear|year)\b/,
    alias: 'builtin'
  },
  'math-function': {
    pattern:
      /\bmath:(?:abs|arccos|arcsin|arctan|arctan2|ceil|cos|cosh|deg2rad|exp|expm1|floor|log|log10|log1p|log2|mod|pow|rad2deg|sin|sinh|spherical2cartesian|sqrt|tan|tanh)\b/,
    alias: 'builtin'
  },
  'stat-function': {
    pattern: /\b(?:avg|count|max|min|percentile|sum|stdDev)\b/,
    alias: 'builtin'
  },
  'array-function': {
    pattern: /\barray:(?:contains|eval|filter|intersection|length|reduceAll|reduceRow|reduceColumn|regex|union)\b/,
    alias: 'builtin'
  },
  'event-manipulation': {
    pattern:
      /\b(?:drop|dropEvent|copyEvent|createEvents|eventFieldCount|eventInternals|eventSize|fieldset|fieldstats|findTimestamp|getField)\b/,
    alias: 'builtin'
  },
  'field-manipulation': {
    pattern: /\b(?:setField|rename|replace|stripAnsiCodes|concat|concatArray|split|splitString|join|collect)\b/,
    alias: 'builtin'
  },
  'network-function': {
    pattern: /\b(?:asn|cidr|communityId|ipLocation|rdns)\b/,
    alias: 'builtin'
  },
  'geolocation-function': {
    pattern: /\b(?:geography:distance|geohash)\b/,
    alias: 'builtin'
  },
  'hash-function': {
    pattern: /\b(?:base64Decode|crypto:md5|hash|hashMatch|hashRewrite|tokenHash)\b/,
    alias: 'builtin'
  },
  'time-manipulation': {
    pattern: /\b(?:duration|formatDuration|formatTime|now|parseTimestamp|timeChart)\b/,
    alias: 'builtin'
  },
  'parsing-function': {
    pattern:
      /\b(?:parseCEF|parseCsv|parseFixedWidth|parseHexString|parseInt|parseJson|parseLEEF|parseUri|parseUrl|parseXml|kvParse)\b/,
    alias: 'builtin'
  },
  'case-expression': {
    pattern: /\bcase\b/,
    alias: 'keyword'
  },
  'match-expression': {
    pattern: /\bmatch\b/,
    alias: 'keyword'
  }
};

export { highlight, languages };
