import React from 'react';

import { faEdit, faTrash } from '@fortawesome/pro-solid-svg-icons';

import { Autocomplete } from 'snap-ui/Autocomplete';
import { ActionIconButton } from 'snap-ui/Button';
import { ConfirmDialog } from 'snap-ui/Dialog';
import Paper from 'snap-ui/Paper';
import Table, { TablePlaceholder } from 'snap-ui/Table';
import TableSortLabel from 'snap-ui/TableSortLabel';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import useAgents from 'aso/useAgents';

import Path from 'constants/paths';

import useOrganizationOptions from 'hooks/useOrganizationOptions';
import useSort from 'hooks/useSort';
import useTitle from 'hooks/useTitle';

import { Engage, Fingerprint } from 'lib/Engagement';

import Can from 'module/Can';
import { TableSearchInformationalMessage } from 'module/Setting/core/Core.style';
import withSubscriberOnly from 'module/Setting/withSubscriberOnly';

import { useAuth } from 'provider';

import { Status } from 'storage';

import { ContentPermission, FunctionalPermission } from 'types/auth';
import { BASAgent, BASAgentEditableFields } from 'types/bas';
import { SortDirection } from 'types/filter';

import { formatShortTimestamp } from 'utilities/TimeUtils';

import AgentLabel from '../../../BAS/AgentLabel';
import Download from '../../../BAS/Download';
import EditAgentModal from './EditAgentModal';
import useUpdateAgent from './useUpdateAgent';

const Container = styled('div')`
  margin-bottom: ${p => p.theme.spacing(9)};

  & .MuiTypography-h1 {
    margin-bottom: ${p => p.theme.spacing(4)};
  }

  .contents {
    min-width: 800px;
    max-width: 1400px;
    margin-top: ${p => p.theme.spacing(4)};

    .organization-dropdown {
      width: 300px;
    }

    .table-container {
      .table-toolbar {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: ${p => p.theme.spacing(2)};
      }

      td {
        // height works like min-height for a td
        height: 50px;
      }

      .actions-column {
        display: flex;
        justify-content: center;
      }
    }
  }
`;

function AgentList(): JSX.Element {
  useTitle('Attack Simulation Agents | SnapAttack');
  const { user, defaultOrgId, permission: organizations } = useAuth();
  const { agents, refreshAgents, status: listStatus } = useAgents();
  const { error: updateError, status: updateStatus, updateAgent, removeAgent } = useUpdateAgent();

  const [selectedAgent, setSelectedAgent] = React.useState<BASAgent>(null);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);

  const _orgOptions = useOrganizationOptions(FunctionalPermission.TaskBASAgent, true);
  const orgOptions = React.useMemo(() => {
    return [{ content: 'All Organizations', label: 'All Organizations', value: '' }, ..._orgOptions];
  }, [_orgOptions]);
  const [selectedOrgId, setSelectedOrgId] = React.useState(defaultOrgId.toString());

  const filteredAgents = React.useMemo(() => {
    if (selectedOrgId) {
      return agents.filter(agent => agent.organization_id === +selectedOrgId);
    } else {
      return agents;
    }
  }, [selectedOrgId, agents]);

  const { createSortHandler, order, sortedData } = useSort<BASAgent>(filteredAgents, {
    direction: SortDirection.desc,
    field: 'last_checkin_time'
  });

  React.useEffect(() => {
    // close the modal and refresh agents on successful update
    if (updateStatus === Status.resolved) {
      setSelectedAgent(null);
      setShowEdit(false);
      refreshAgents();
    }
  }, [updateStatus, refreshAgents]);

  React.useEffect(() => {
    Engage.track(Fingerprint.load(Path.Agents));
  }, []);

  function getOrgName(orgId: number): string {
    const org = organizations.find(org => org.id === orgId);
    return org?.name || 'Unknown';
  }

  return (
    <Container className='AgentList'>
      <Typography variant='h1'>Attack Simulation Agents</Typography>
      <div className='contents'>
        <Download />
        <Paper className='table-container'>
          {user.superuser && (
            <div className='table-toolbar'>
              <Autocomplete
                className='organization-dropdown'
                disableClearable
                disableUserAdditions
                elevated
                label='Organization'
                name='organization_id'
                options={orgOptions}
                onChange={v => setSelectedOrgId(v as string)}
                value={selectedOrgId}
              />
            </div>
          )}
          <Table
            aria-label='Attack Simulation Agents'
            component='div'
            columns={[
              'Agent',
              <TableSortLabel
                key='organization_id'
                field='organization_id'
                label='Organization'
                sortHandler={createSortHandler('organization_id')}
                order={order}
              />,
              <TableSortLabel
                key='last_checkin_time'
                field='last_checkin_time'
                label='Last Check-in Time'
                sortHandler={createSortHandler('last_checkin_time')}
                order={order}
              />,
              ''
            ]}
            rows={sortedData.map(agent => [
              <AgentLabel key='agent' agent={agent} />,
              getOrgName(agent.organization_id),
              formatShortTimestamp(agent.last_checkin_time),
              <div key='actions' className='actions-column'>
                <Can I={ContentPermission.Edit} this={agent}>
                  <Tooltip title='Edit agent name' placement='top-start' arrow wrap>
                    <ActionIconButton aria-label='Edit agent name' icon={faEdit} onClick={handleEditAgent(agent)} />
                  </Tooltip>
                </Can>
                <Can I={ContentPermission.Delete} this={agent}>
                  <Tooltip title='Delete agent' placement='top-start' arrow wrap>
                    <ActionIconButton aria-label='delete agent' icon={faTrash} onClick={handleDeleteAgent(agent)} />
                  </Tooltip>
                </Can>
              </div>
            ])}
          />
          {listStatus === Status.pending && <TablePlaceholder count={10} height={35} />}
          {listStatus === Status.resolved && sortedData.length === 0 && (
            <TableSearchInformationalMessage>
              You don&apos;t have any attack simulation agents.
            </TableSearchInformationalMessage>
          )}
        </Paper>
      </div>
      <EditAgentModal
        open={showEdit}
        agent={selectedAgent}
        error={updateError}
        onClose={handleCloseEditAgent}
        onSubmit={handleSubmitEditAgent}
        status={updateStatus}
      />
      <ConfirmDialog
        DialogProps={{
          open: showDeleteConfirmation,
          onClose: handleDeleteClose
        }}
        ConfirmProps={{ onClick: () => handleSubmitDeleteAgent(), children: 'Delete Agent' }}
        title='Delete Agent'
        SecondaryProps={{ children: 'Cancel', onClick: handleDeleteClose }}
      >
        {`Are you sure you want to delete ${
          selectedAgent?.name || selectedAgent?.host_metadata?.host_name || 'this agent'
        }?`}
      </ConfirmDialog>
    </Container>
  );

  function handleEditAgent(agent: BASAgent) {
    return function (): void {
      setSelectedAgent(agent);
      setShowEdit(true);
    };
  }

  function handleSubmitEditAgent(values: BASAgentEditableFields): void {
    updateAgent(selectedAgent.guid, values);
  }

  function handleCloseEditAgent(): void {
    setShowEdit(false);
    setSelectedAgent(null);
  }

  function handleDeleteAgent(agent: BASAgent) {
    return function (): void {
      setSelectedAgent(agent);
      setShowDeleteConfirmation(true);
    };
  }

  function handleSubmitDeleteAgent(): void {
    handleDeleteClose();
    removeAgent(selectedAgent.guid);
  }

  function handleDeleteClose() {
    setSelectedAgent(null);
    setShowDeleteConfirmation(false);
  }
}

export default withSubscriberOnly(AgentList);
