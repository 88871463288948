import React from 'react';

import { gridFilteredSortedRowEntriesSelector, useGridApiContext } from '../DataGrid';

/**
 * Auto scrolling with DataGrid is hard. Don't go alone. Take this friendly hook on your journey. ⚔️
 *
 * Gotcha.. If a row is hidden from a filter, it won't be shown.
 */
export function useScrollRowIntoViewById(uuid: string, setUuid: (v: string) => void) {
  const apiRef = useGridApiContext();

  // I am needed to trigger the setCoordinates effect
  const rowCount = apiRef.current.getRowsCount();

  React.useLayoutEffect(() => {
    if (uuid) {
      const rowIndex = gridFilteredSortedRowEntriesSelector(apiRef).findIndex(model => model.id === uuid);
      if (rowIndex >= 0) {
        setUuid(undefined);
        setTimeout(() => {
          // Delay the imperative grid scroll by leveraging the event loop
          // A second useEffect could be used, and works with chrome, but
          // does not work with firefox as firefox tries to optimize scrolling
          apiRef.current.scrollToIndexes({ rowIndex, colIndex: 0 });
        }, 0);
      }
    }
  }, [uuid, rowCount, apiRef, setUuid]);
}
