import React from 'react';

import styled from '@emotion/styled';
import classNames from 'classnames';

import { ArtifactScoreDetail } from 'types/common';

import { ScoreBadge } from './Badge';
import ConfidenceScoreDetails from './ConfidenceScoreDetails';

const ContainerBadge = styled(ScoreBadge)`
  z-index: 1;

  .border-none {
    border: none;
  }

  &.width-auto {
    min-width: auto;
  }
`;

export default function ConfidenceScoreBadge({
  className,
  confidenceScoreDetail
}: {
  className?: string;
  confidenceScoreDetail: ArtifactScoreDetail;
}) {
  return (
    <ContainerBadge
      className={classNames(className, 'ConfidenceScoreBadge')}
      name='CONFIDENCE'
      score={confidenceScoreDetail?.rank}
      TooltipProps={{
        title: <ConfidenceScoreDetails confidenceScoreDetail={confidenceScoreDetail} />,
        arrow: true,
        placement: 'top'
      }}
    />
  );
}
