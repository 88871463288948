import { Form as FormikForm } from 'formik';

import Button from 'snap-ui/Button';
import MuiDivider from 'snap-ui/Divider';
import { theme } from 'snap-ui/theme';
import { styled } from 'snap-ui/util';

export const AuthenticationRoot = styled('div')`
  display: grid;
  grid-template-columns: 3fr 8fr;
  flex: 1;

  .pp-login {
    margin-top: 0;
    margin-bottom: 0;
    padding: 60px;
    min-width: 600px;
    max-width: 700px;
    height: 100vh;
    overflow-y: auto;

    & hr {
      margin: 20px 0;
    }
  }

  .login-title {
    text-align: left;
    margin-bottom: 16px;
  }

  .login-field {
    padding-bottom: 24px;
  }

  .login-link {
    padding-top: 8px;
    text-align: left;
  }

  .pp-login-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 2px solid ${p => p.theme.palette.primary.main};
    border-image-source: linear-gradient(
      ${p => p.theme.palette.grey[900]},
      ${p => p.theme.palette.primary.main},
      ${p => p.theme.palette.grey[900]}
    );
    border-image-slice: 2;
    background-image: url(${process.env.PUBLIC_URL + '/images/darkpurple.jpg'});
    background-position: center;
    background-size: cover;
  }

  .pp-container-overlay {
    margin: ${theme.spacing(0, 4)};
    width: 600px;
    margin-top: -140px;

    & .pp-img-overlay {
      width: 100%;
      margin-bottom: 30px;
    }

    & h3 {
      width: 600px;

      & span {
        color: ${p => p.theme.palette.primary.main};
      }
    }
  }

  .ValidationAlert {
    width: 100%;
  }

  .MuiAlert-root:not(.ValidationAlert) {
    margin: ${theme.spacing(6, 0)};
  }

  @media (max-width: 1250px) {
    & .pp-container-overlay {
      left: 65%;
    }
  }

  @media (max-width: 1000px) {
    & .pp-container-overlay {
      left: 80%;
    }
  }

  @media (max-width: 800px) {
    & .pp-container-overlay {
      left: 100%;
    }
  }
`;

export const ForgotResetContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url(${process.env.PUBLIC_URL + '/images/darkpurple.jpg'});
  background-position: center;
  background-size: cover;

  & .pp-forgot {
    background-color: ${p => p.theme.palette.background.default};
    border: 1px solid ${p => p.theme.palette.grey[800]};
    margin-top: 0;
    padding: 60px;
    width: 100%;
  }

  & .pp-width-container {
    width: 600px !important;
  }

  .ApiError {
    margin: ${p => p.theme.spacing(7, 0)};
  }
`;

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(8)};
  align-items: flex-start;
`;

export const LoginButton = styled(Button)`
  display: flex;
  gap: ${p => p.theme.spacing(2)};
`;

export const Divider = styled(MuiDivider)`
  margin: ${p => p.theme.spacing(4, 0, 8, 0)};
`;
