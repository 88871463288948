import React from 'react';

import zod from 'zod';

import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';

import { addOrRemoveValue } from 'module/Filter/Filter.util';
import { FilterControl } from 'module/GlobalFilter';

import { useAuth } from 'provider';

import { ArtifactType, Visibility } from 'types/common';
import { Ops } from 'types/filter';

import { FilterConfig } from '../GlobalFilter.type';

type VisibilityKeys = {
  visibility: Visibility[];
};

type VisibilityFilterProps = {
  disabled?: boolean;
  onChange(values: Partial<VisibilityKeys>): void;
  values: VisibilityKeys;
};

function VisibilityFilter({ onChange, values }: VisibilityFilterProps): React.ReactElement {
  const { user } = useAuth();
  return (
    <FilterControl className='VisibilityFilter'>
      <FormLabel id='visible-toggle-button-group-label'>By Visibility</FormLabel>
      <FormGroup aria-labelledby='visible-toggle-button-group-label'>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleChange}
              value={Visibility.Published}
              checked={values.visibility.includes(Visibility.Published)}
            />
          }
          label='Published'
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleChange}
              value={Visibility.Draft}
              checked={values.visibility.includes(Visibility.Draft)}
            />
          }
          label='Draft'
        />
        {user.superuser && (
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleChange}
                value={Visibility.Deleted}
                checked={values.visibility.includes(Visibility.Deleted)}
              />
            }
            label='Deleted'
          />
        )}
      </FormGroup>
    </FilterControl>
  );

  function handleChange(e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    const v = e.currentTarget.value as Visibility;
    const visibility = addOrRemoveValue(checked, values.visibility, v) as Visibility[];
    onChange({ visibility });
  }
}

function toQuery(values: VisibilityKeys) {
  if (!values.visibility?.length) return;
  return {
    field: 'visibility',
    op: Ops.in,
    value: values.visibility
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('visibility'),
    op: zod.nativeEnum(Ops),
    value: zod.array(zod.nativeEnum(Visibility))
  })
  .transform(query => {
    return {
      visibility: query.value
    };
  });

const VisibilityFilterConfig: FilterConfig<VisibilityKeys> = {
  defaults: { default: () => ({ visibility: [Visibility.Published] }) },
  supportedTopics: [ArtifactType.Session, ArtifactType.Analytic, ArtifactType.Intel],
  component: VisibilityFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default VisibilityFilterConfig;
