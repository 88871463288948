import React from 'react';

import moment from 'moment';

import Button from 'snap-ui/Button';
import { useGridApiRef } from 'snap-ui/DataGrid';
import { ConfirmDialog } from 'snap-ui/Dialog';

import { DeployedFeeds, useDeploymentErrorActions } from '../';
import { Table } from '../DeployedHealth.style';
import { RenderTable } from '../DeployedHealth.widget';
import {
  COMMON_GRID_CONFIG,
  detectionName,
  detectionUrl,
  integrationName,
  errorMessage,
  errorActions
} from './Columns';
import { CommonTableProps } from './Tables.type';

type DeploymentErrorsTableProps = DeployedFeeds['deploymentErrors'] & CommonTableProps;

export default function DeploymentErrorsTable(props: DeploymentErrorsTableProps): JSX.Element {
  const gridApiRef = useGridApiRef();
  const { items, undeployOne, undeployAll, isPending } = useDeploymentErrorActions(props.items);
  const [showUndeployConfirm, setShowUndeployConfirm] = React.useState<number | 'all'>(null);
  const undeployItem =
    typeof showUndeployConfirm === 'number' ? items.find(i => i.deploymentId === showUndeployConfirm) : null;

  function handleUndeploy() {
    (undeployItem
      ? undeployOne(undeployItem.detection.guid, undeployItem.deploymentId, undeployItem.integration.guid)
      : undeployAll()
    ).then(() => setShowUndeployConfirm(null));
  }

  function handleExport() {
    gridApiRef.current.exportDataAsCsv({
      fields: [detectionName.field, detectionUrl.field, integrationName.field, errorMessage.field],
      fileName: `deployment_errors_${moment().format('YYYY_MM_DD')}`
    });
  }

  return (
    <>
      <RenderTable
        {...props}
        items={items}
        emptyMessage='All detections deployed successfully'
        toolbar={({ disableButtons }) => (
          <>
            <div />
            <div className='buttons'>
              <Button variant='outlined' disabled={disableButtons} onClick={() => setShowUndeployConfirm('all')}>
                Undeploy All
              </Button>
              <Button variant='outlined' disabled={disableButtons} onClick={handleExport}>
                Export CSV
              </Button>
            </div>
          </>
        )}
        table={
          <Table
            {...COMMON_GRID_CONFIG}
            apiRef={gridApiRef}
            columns={[detectionName, detectionUrl, integrationName, errorMessage, errorActions(setShowUndeployConfirm)]}
            rows={items}
          />
        }
      />
      <ConfirmDialog
        isPending={isPending}
        ConfirmProps={{
          children: 'Undeploy',
          onClick: handleUndeploy
        }}
        DialogProps={{ open: showUndeployConfirm !== null, onClose: () => setShowUndeployConfirm(null) }}
        title={undeployItem ? `Undeploy ${undeployItem.detection.name}` : 'Undeploy Detections'}
      >
        Are you sure you want to undeploy {undeployItem ? 'this detection' : 'these detections'}?
      </ConfirmDialog>
    </>
  );
}
