import React from 'react';

import classnames from 'classnames';

import CircularProgress from 'snap-ui/CircularProgress';

import { asValidationError, ValidationError } from 'apis';

import { IntegrationAutocomplete, useLanguagePlatforms } from 'module/Integration';

import { updateCurrentUser, useAuth, useAuthInterface, useLanguageContext } from 'provider';

import UserOption from './UserOption';

type Props = {
  className?: string;
};

const fieldName = 'Default Language';

function UserOptionDefaultLanguage({ className }: Props) {
  const [active, setActive] = React.useState(false);
  const { fetch } = useAuthInterface();
  const { defaultLanguageId } = useAuth();
  const defaultLanguage = defaultLanguageId?.toString() || '';

  const [error, setError] = React.useState<ValidationError>();
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>(defaultLanguage);
  const { data: languages } = useLanguageContext();
  const { getPlatformDetails } = useLanguagePlatforms();

  const languageOptionList = languages.map(language => ({
    label: language.name,
    type: getPlatformDetails(language.id)?.platforms?.[0]?.integrationType,
    content: language.name,
    value: language.id.toString()
  }));

  async function onSubmit() {
    setActive(true);
    setError(null);

    const default_analytic_compilation_target = selectedLanguage ? Number(selectedLanguage) : null;

    try {
      const result = await updateCurrentUser({
        preference: { default_analytic_compilation_target }
      });
      if (result) fetch();
    } catch (error) {
      setError(asValidationError(error));
      setActive(false);
    }
  }

  return (
    <UserOption
      className={classnames('UserOptionDefaultLanguage', className)}
      error={error}
      fieldName={fieldName}
      fieldComponent={
        <IntegrationAutocomplete
          label={`Select ${fieldName}`}
          onChange={setSelectedLanguage}
          name='defaultLanguage'
          value={selectedLanguage}
          disableClearable
          disableUserAdditions
          options={languageOptionList}
        />
      }
      FormikConfig={{
        initialValues: { defaultLanguage },
        onSubmit: onSubmit
      }}
      SubmitProps={{
        children: active ? <CircularProgress size={20} /> : `Set ${fieldName}`,
        disabled: active
      }}
    />
  );
}

export default UserOptionDefaultLanguage;
