import { snapattack, CancelTokenType } from 'apis/snapattack';

import { SigmaLogsource } from 'types/analytic';
import { Ident } from 'types/common';

import { ReverseFieldMap } from './';

export function getReverseMap(
  languageId: Ident,
  logsource: SigmaLogsource,
  cancelToken?: CancelTokenType
): Promise<ReverseFieldMap> {
  return snapattack.post(`/signatures/language/${languageId}/mappings/`, logsource, { cancelToken }).then(r => r.data);
}
