import React from 'react';

import { getRawLog } from 'module/Detection';

import { Status, useAsync } from 'storage';

import { LogRecord } from 'types/ide';

interface RawLogState {
  data: LogRecord;
  status: Status;
  error: string;
}

const INITIAL_LOG = {} as LogRecord;

export default function useRawLog(rowId: string): RawLogState {
  const { data, run, status, error } = useAsync(INITIAL_LOG);

  React.useEffect(() => {
    if (rowId) {
      run(getRawLog(rowId));
    }
  }, [rowId, run]);

  return {
    data,
    status,
    error: error ? JSON.stringify(error?.response?.data || 'Target log could not be loaded') : error
  };
}
