import React from 'react';

import MuiLinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';

import Tooltip from 'snap-ui/Tooltip';
import { Base } from 'snap-ui/type';
import { styled } from 'snap-ui/util';

type Props = Base &
  LinearProgressProps & {
    color?: 'primary' | 'secondary' | 'error';
  };

export default function PrimaryLinearProgress({ ...others }: Props) {
  return <MuiLinearProgress {...others} />;
}

export type MultiProgressBarProps = {
  color: 'primary' | 'secondary' | 'info' | 'warning' | 'error' | 'success' | string;
  label: string;
  percent: number;
  children?: JSX.Element;
  className?: string;
};

type MultiProgressProps = {
  bars: MultiProgressBarProps[];
  className?: string;
};

const MultiProgressContainer = styled('div')`
  border-radius: 5px;
  height: 16px;
  display: flex;
  flex-flow: row nowrap;
  background-color: ${p => p.theme.palette.secondary.dark};
  overflow: hidden;
  box-shadow: ${p => p.theme.shadows[2]};
`;

const MultiProgressBar = styled('div')<MultiProgressBarProps>`
  flex: 0 0 ${p => p.percent}%;
  color: ${p => p.theme.palette.getContrastText(p.theme.palette[p.color]?.main ?? p.color)};
  background-color: ${p => p.theme.palette[p.color]?.main ?? p.color};
  padding: ${p => (p.children ? p.theme.spacing(0, 3) : 0)};
`;

export function MultiProgress({ bars, className }: MultiProgressProps): JSX.Element {
  return (
    <MultiProgressContainer className={className}>
      {bars.map((bar, i) => (
        <Tooltip key={i} arrow placement='top' title={bar.label}>
          <MultiProgressBar {...bar} />
        </Tooltip>
      ))}
    </MultiProgressContainer>
  );
}
