import React from 'react';

import { getAnalyticBackfill } from 'apis/resources/analytic';

import { Backfill } from 'module/Session/Session.type';

import { usePushSnack } from 'provider';

import { useAsync } from 'storage';

import { CeleryState, Guid } from 'types/common';

const BACKFILL_STATE: string[] = [CeleryState.in_progress, CeleryState.pending, CeleryState.retrying];

export default function useAnalyticBackfill(guid: Guid): Backfill {
  const pushSnack = usePushSnack();
  const { data, run, setData } = useAsync<Backfill>();
  const isBackfilling = BACKFILL_STATE.includes(data?.status);

  React.useEffect(() => {
    run(getAnalyticBackfill(guid));
  }, [guid, run]);

  React.useEffect(() => {
    let timer: NodeJS.Timeout = null;
    if (timer === null && guid && isBackfilling) {
      const cycle = (time: number) => {
        timer = setTimeout(async () => {
          try {
            var response = await getAnalyticBackfill(guid);
          } catch (e) {
            pushSnack('Gah! Maybe we dug too deep: ' + e?.message, 'error');
            return;
          }
          setData(response);
          if (response?.status === 'IDLE') {
            pushSnack('Detection run complete.', 'success');
            return;
          }
          cycle(5000);
        }, time);
      };

      cycle(5000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isBackfilling, guid, pushSnack, setData]);

  return data;
}
