import React from 'react';

import classNames from 'classnames';
import { useField } from 'formik';

import { Autocomplete, AutocompleteProps, Option } from 'snap-ui/Autocomplete';

export type AutocompleteFormikProps = Partial<AutocompleteProps> & {
  className?: string;
  label?: string;
  loading?: boolean;
  multiple?: boolean;
  name: string;
  options: Option[];
  placeholder?: string;
  warningText?: string;
  disabled?: boolean;
  disableClearable?: boolean;
  disableUserAdditions?: boolean;
  onChange?(value: string | Option[]): void;
  required?: boolean;
  helperText?: React.ReactNode | string;
  onInputChange?(value: string): void;
  inputValue?: string;
  searching?: boolean;
  testId?: string;
};

export default function AutocompleteFormik({
  className,
  warningText,
  onChange,
  required,
  helperText,
  ...props
}: AutocompleteFormikProps): JSX.Element {
  const [field, meta, helpers] = useField(props.name);
  const hasError = !!(meta.touched && meta.error);
  const hasWarning = !!(meta.touched && warningText);

  function handleBlur() {
    helpers.setTouched(true);
  }

  function handleChange(value: string | Option[]): void {
    if (!value) helpers.setValue(props.multiple ? [] : '');
    if (typeof value === 'string') {
      helpers.setValue(value);
      if (props.onInputChange) props.onInputChange(value);
    } else {
      helpers.setValue(value.map(val => val.value));
      if (props.onInputChange) props.onInputChange('');
    }
    helpers.setTouched(true, false);

    if (onChange) onChange(value);
  }

  function getDisplayValue(value: undefined | null | string | number | string[]): AutocompleteProps['value'] {
    if (value === undefined || value === null) {
      return props.multiple ? ([] as Option[]) : null;
    }

    if (typeof value === 'number') {
      value = value.toString();
    }

    if (typeof value === 'string') {
      const selectedOption = props.options.find(option => option.value === value);
      if (!selectedOption) return props.disableUserAdditions ? null : value;
      if (selectedOption.userAdded) return value;
      return selectedOption;
    }

    return value.map(
      (val: string) =>
        props.options.find(option => option.value === val) || { content: val, userAdded: true, value: val }
    );
  }

  return (
    <Autocomplete
      className={classNames('Form-field', className, { required: required, valid: meta.touched && !meta.error })}
      error={hasError}
      helperText={
        <>
          {helperText && <span className='AutocompleteFormik-helperText'>{helperText}</span>}
          {hasError ? meta.error : hasWarning ? warningText : undefined}
        </>
      }
      onBlur={handleBlur}
      onChange={handleChange}
      value={getDisplayValue(field.value)}
      elevated
      {...props}
    />
  );
}
