import React from 'react';

import { faFilterSlash } from '@fortawesome/pro-solid-svg-icons';

import Badge from 'snap-ui/Badge';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import { styled, useTheme } from 'snap-ui/util';

import { ArtifactType } from 'types/common';
import { StrictReactNode } from 'types/core';

import { TabType } from './Curation.type';

const Container = styled('div')`
  > span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${p => p.theme.spacing(2)};
  }

  .PendingCount {
    padding: 0;
  }
`;

function getType(mode: TabType): string {
  switch (mode) {
    case ArtifactType.Intel:
      return 'Intelligence';
    case ArtifactType.Analytic:
      return 'Detection';
    case ArtifactType.Session:
      return 'Threat';
    case ArtifactType.AttackScript:
      return 'Attack Script';
    case ArtifactType.Indicator:
      return 'IOC';
  }
}

export default function CurationTabLabel({
  children,
  isEnabled = true,
  mode,
  selectedTab
}: {
  children: StrictReactNode;
  isEnabled?: boolean;
  mode: TabType;
  selectedTab: TabType;
}) {
  const { palette } = useTheme();
  const type = getType(mode);
  const title = isEnabled
    ? `The feed is currently based on ${type} filter options`
    : 'The filter is disabled. You can enable it via the floating action button';

  return (
    <Container>
      <Badge
        badgeContent={
          (selectedTab === mode || (selectedTab === 'overview' && mode === ArtifactType.Session)) && (
            <Tooltip title={title} wrap placement='top' arrow>
              {isEnabled ? <Icon.Filter /> : <Icon icon={faFilterSlash} color={palette.secondary.light} />}
            </Tooltip>
          )
        }
      >
        {children}
      </Badge>
    </Container>
  );
}
