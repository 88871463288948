import React from 'react';

import classNames from 'classnames';
import { useField } from 'formik';
import { PeriodType } from 'react-js-cron';

import CronForm from 'snap-ui/CronForm';
import { TextFieldProps } from 'snap-ui/TextField';

import { DEFAULT_PERIOD } from 'module/Job/Job.const';

export type CronJobFormikProps = TextFieldProps & {
  helperText?: React.ReactNode | string;
  name: string;
};

/*
 * The react-js-cron library uses both day (month) and day (week). If a day of week is configured
 * on another period, when we change to month that value is silently carried over since we hide the
 * day of week field. This hack resets that.
 */
function unsetDayOfWeek(cron: string): string {
  const parts = cron.split(' ');
  if (parts[4] !== '*') {
    parts[4] = '*'; // unset the day-of-week
  }
  return parts.join(' ');
}

export default function CronJobFormik({ className, name }: CronJobFormikProps): JSX.Element {
  const [field, , helpers] = useField(name);
  const [selectedPeriod, setSelectedPeriod] = React.useState<PeriodType>(DEFAULT_PERIOD);

  function handleChange(value: string) {
    /*
     * NOTE: this will not unset the day of the week if the user does not change any other inputs
     * (e.g., day of month, time). That should be _rare_ but, could technically result in a bug if
     * the form is submitted without changing fields. There are other, worse quirks if you try to
     * unsetDayOfWeek when selectedPeriod changes, which causes the period to jump from month to day.
     */
    if (selectedPeriod === 'month') {
      value = unsetDayOfWeek(value);
    }
    helpers.setValue(value);
  }

  return (
    <CronForm
      className={classNames('cron-form', { className })}
      value={field.value}
      setValue={(value, { selectedPeriod }) => {
        setSelectedPeriod(selectedPeriod);
        handleChange(value);
      }}
      selectedPeriod={selectedPeriod}
    />
  );
}
