import { useIDEValue } from 'module/IDE';

import { isWinEvent } from 'services/analyticService';

/**
 * determines if the logsource from the current IDEState context is a Windows Event logsource
 */
export default function useIsWinEvent() {
  const { value: logsource } = useIDEValue('logsource');
  return isWinEvent(logsource);
}
