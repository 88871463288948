import React from 'react';

import { withJsonFormsLayoutProps } from '@jsonforms/react';
import { GroupLayout } from '@jsonforms/vanilla-renderers';

// TODO replace any
const MetadataLayout = (props: any) => {
  const { uischema, schema, path, visible, renderers } = props;

  const layoutProps = {
    elements: uischema.elements,
    schema: schema,
    path: path,
    visible: visible,
    uischema: uischema,
    renderers: renderers
  };
  return <GroupLayout {...layoutProps} />;
};

export default withJsonFormsLayoutProps(MetadataLayout);
