import React from 'react';

import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';

import { StrictReactNode } from 'types/core';

import { Base } from './type';

type Checkbox = Base & {
  onChange(event: React.SyntheticEvent<Element, Event>, checked: boolean): void;
  children?: StrictReactNode;
  color?: CheckboxProps['color'];
  icon?: StrictReactNode;
  checkedIcon?: StrictReactNode;
  defaultChecked?: boolean;
  value?: string;
  checked?: boolean;
  id?: string;
  intermediate?: boolean;
  disabled?: boolean;
};

function Checkbox({ intermediate = false, ...props }: Checkbox) {
  return <MuiCheckbox indeterminate={intermediate} {...props} />;
}

export default Checkbox;
