import React from 'react';

import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { styled, useTheme } from '../util';
import { TooltipDefaults } from './components/TooltipDefaults';

type Data = { key: string; color: string; label: string; value: number }[];

export type BarChartProps = {
  data: Data;
  height: number;
  onBarClick?(data: Data[number], index: number): void;
  margin?: Partial<Record<'left' | 'right' | 'top' | 'bottom', number>>;
  useTooltip?: boolean;
};

const TooltipContainer = styled('div')(TooltipDefaults.contentStyle);

function TooltipContent({ active, label, payload }: any): JSX.Element {
  if (!(active && payload?.length)) return null;

  return (
    <TooltipContainer>
      <b>{label}:</b> {payload[0]?.value}
    </TooltipContainer>
  );
}

export default function SABarChart({ data, height, margin, onBarClick, useTooltip }: BarChartProps): JSX.Element {
  const { palette } = useTheme();

  return (
    <ResponsiveContainer height={height}>
      <BarChart data={data} margin={margin}>
        <XAxis stroke={palette.common.white} dataKey='label' />
        <YAxis stroke={palette.common.white} />
        {useTooltip && <Tooltip {...TooltipDefaults} content={TooltipContent} cursor={false} />}
        <Bar dataKey='value' onClick={onBarClick}>
          {data.map((entry, idx) => (
            <Cell key={`cell-${idx}`} fill={entry.color} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
