import React from 'react';

import { faFilter } from '@fortawesome/pro-solid-svg-icons';

import ListItemButtonIcon from 'module/Scaffold/core/ListItemButtonIcon';

import { StrictReactNode } from 'types/core';

import { Drawer, DrawerHeader } from './Sidebar.style';
import { SidebarOptions, SidebarState } from './Sidebar.type';

const SidebarContext = React.createContext<SidebarState>(null);
SidebarContext.displayName = 'SidebarContext';

function useSidebarState(): SidebarState {
  const sidebarState = React.useContext(SidebarContext);

  if (!sidebarState) {
    throw new Error('useSidebar must be used within the SidebarContext');
  }

  return sidebarState;
}

function useSidebar(defaultOpen: boolean, options: Partial<Omit<SidebarOptions, 'open'>>): SidebarState {
  const sidebarState = useSidebarState();
  const { setOpen } = sidebarState;

  React.useEffect(() => {
    setOpen && setOpen(defaultOpen);
  }, [defaultOpen, setOpen]); // react-hooks

  React.useEffect(() => {
    sidebarState?.setOptions({ enabled: true });

    return () => sidebarState.setOptions({ enabled: false });
  }, [sidebarState]);

  React.useEffect(() => {
    sidebarState?.setOptions(options);
  }, [options, sidebarState.setOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  return sidebarState;
}

function _useSidebarState(): SidebarState {
  const NOOP = React.useCallback(() => null, []);

  const [open, setOpen] = React.useState(false);
  const [_options, setOptions] = React.useReducer<
    (state: SidebarOptions, action: Partial<SidebarOptions>) => SidebarOptions
  >((state, action) => ({ ...state, ...action }), {
    content: NOOP,
    collapsible: true,
    enabled: false,
    icon: faFilter
  });

  const options = React.useMemo(
    () => ({
      content: _options.content,
      collapsible: _options.collapsible,
      enabled: _options.enabled,
      icon: _options.icon
    }),
    [_options.content, _options.collapsible, _options.enabled, _options.icon]
  );

  return React.useMemo(() => {
    return { open, options, setOpen, setOptions };
  }, [open, options, setOpen, setOptions]);
}

function SidebarProvider({ children }: { children: StrictReactNode }) {
  const sidebarState = _useSidebarState();

  return (
    <>
      {sidebarState.options.enabled && (
        <Drawer disablePortal open={sidebarState.open} variant='permanent'>
          {!sidebarState.open ? (
            <>
              <DrawerHeader />
              <ListItemButtonIcon
                data-testid='open-sidebar-button'
                icon={sidebarState.options.icon}
                onClick={() => sidebarState.setOpen(true)}
              />
            </>
          ) : (
            sidebarState.options.content(sidebarState)
          )}
        </Drawer>
      )}
      <SidebarContext.Provider value={sidebarState}>{children}</SidebarContext.Provider>
    </>
  );
}

export { SidebarProvider, useSidebar, useSidebarState };
