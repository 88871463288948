import { AxiosError } from 'axios';
import z from 'zod';

import { ApiErrorResponse } from 'apis';

import { ErrorProps } from './ApiError.type';

const FieldError = z.object({
  loc: z.array(z.string()),
  msg: z.string(),
  type: z.string()
});

const Unprocessable = z.union([
  z.string().transform(v => [v]),
  z.array(FieldError).transform(v =>
    v.map(
      // expected format is ['body', 'field', 'nested_field?', etc...]; we want to remove 'body'
      fieldErr => `${fieldErr.loc.length > 1 ? fieldErr.loc.slice(1).join('.') : fieldErr.loc.at(-1)}: ${fieldErr.msg}`
    )
  ),
  z.any().transform(() => [])
]);

export function convertAxiosError(error: AxiosError<ApiErrorResponse>): ErrorProps {
  const code = error?.response?.status;
  const detail = error?.response?.data?.detail;
  if (!code || code >= 500) {
    return {
      title: 'Oops! That was unexpected',
      messages: []
    };
  }

  if (code === 400) {
    return {
      title: 'There was a problem with your inputs',
      messages: [detail]
    };
  }

  if (code === 404) {
    return {
      title: "We couldn't find that one",
      messages: [detail]
    };
  }

  if (code === 409) {
    return {
      title: "Oops, that's not allowed",
      messages: [detail]
    };
  }

  if (code === 422) {
    return {
      title: 'There was a problem with your inputs',
      messages: Unprocessable.parse(detail)
    };
  }

  if (code === 418) {
    return {
      title: 'Oops, that went up in smoke',
      messages: [detail]
    };
  }

  return {
    title: 'Oops! That was unexpected',
    messages: detail ? [detail] : []
  };
}
