import React from 'react';

import { Option } from 'snap-ui/Autocomplete';

import { PreferredOrgIcon } from 'module/Widgets/RequestPreferredOrg/PreferredOrg.helper';

import { useAuth } from 'provider';

import { filterUserOrganizationsByTask } from 'services/userService';

import { FunctionalPermission } from 'types/auth';

export default function useOrganizationAutocompleteOptions(
  task?: FunctionalPermission,
  highlightPreferred = false
): Option[] {
  const { defaultOrgId, permission } = useAuth();

  const options = React.useMemo(
    () =>
      filterUserOrganizationsByTask(permission, task).map(org => ({
        content:
          highlightPreferred && org.id === defaultOrgId ? <PreferredOrgIcon>{org.name}</PreferredOrgIcon> : org.name,
        label: org.name,
        value: org.id.toString()
      })),
    [defaultOrgId, highlightPreferred, permission, task]
  );

  return options;
}
