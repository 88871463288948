import React from 'react';

import Icon from 'snap-ui/Icon';

import { BurgerClicker } from 'module/Layout/Artifact.widgets';

import AddSessionsToAttackScript, { AddSessionsToAttackScriptProps } from './AddSessionsToAttackScript';

type Button = Partial<BurgerClicker>;
type Dialog = Omit<AddSessionsToAttackScriptProps, 'isOpen' | 'onClose'>;

interface AddSessionsToAttackScriptInterface {
  isOpen: boolean;
  setIsOpen(open: boolean): void;
}

const AddSessionsToAttackScriptContext = React.createContext<AddSessionsToAttackScriptInterface>(null);
AddSessionsToAttackScriptContext.displayName = 'AddSessionsToAttackScriptContext';

export function AddSessionsToAttackScriptInterface({ children }: { children: React.ReactNode }) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  return (
    <AddSessionsToAttackScriptContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </AddSessionsToAttackScriptContext.Provider>
  );
}

function useAddSessionsToAttackScript(): AddSessionsToAttackScriptInterface {
  const context = React.useContext(AddSessionsToAttackScriptContext);
  if (!context) {
    throw new Error('useAddSessionsToAttackScript must be used within an AddSessionsToAttackScriptInterface');
  }
  return context;
}

export function AddSessionsToAttackScriptButton({ onClick, ...others }: Button) {
  const { setIsOpen } = useAddSessionsToAttackScript();
  return (
    <BurgerClicker
      title='Link to Threat'
      icon={<Icon.Session color='inherit' />}
      onClick={() => {
        setIsOpen(true);
        if (onClick) onClick();
      }}
      {...others}
    />
  );
}

export function AddSessionsToAttackScriptDialog(props: Dialog) {
  const { isOpen, setIsOpen } = useAddSessionsToAttackScript();
  return <AddSessionsToAttackScript key='value' isOpen={isOpen} onClose={() => setIsOpen(false)} {...props} />;
}
