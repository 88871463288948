import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { getSupplementalItemsCatalog } from 'module/Search';

import { useAuth } from 'provider';

import { Status, useAsync } from 'storage';

import { ArtifactScore, ArtifactType, Guid } from 'types/common';
import { Permitted, Version } from 'types/common.zod';

import {
  deleteAttackScript,
  getAttackScript,
  getAttackScriptPermissions,
  getAttackScriptVersions
} from './AttackScript.api';
import { AttackScript, AttackScriptState, AttackScriptSupplemental } from './AttackScript.type';

export default function useAttackScript(
  guid: Guid,
  attackScriptState?: AttackScript,
  supplementalState?: AttackScriptSupplemental
): AttackScriptState {
  const { defaultOrgId } = useAuth();

  const {
    data: attackScript,
    run: attackScriptRun,
    status: attackScriptStatus,
    setData: setAttackScript,
    error: attackScriptError
  } = useAsync<AttackScript>();

  const {
    data: supplemental,
    run: supplementalRun,
    status: supplementalStatus,
    setData: setSupplemental,
    error: supplementalError
  } = useAsync<AttackScriptSupplemental>();

  const {
    data: permissions,
    run: permissionsRun,
    status: permissionsStatus,
    setData: setPermissions,
    error: permissionsError
  } = useAsync<Permitted>([]);

  const {
    data: versions,
    run: versionsRun,
    status: versionsStatus,
    setData: setVersions,
    error: versionsError
  } = useAsync<Version[]>([]);

  const { status: taskStatus, task } = useAsync();

  React.useEffect(() => {
    if (!guid) {
      setAttackScript(null);
    } else if (attackScriptStatus === Status.pending) {
      // do nothing
    } else if (isEmpty(attackScriptState) || !attackScriptState.script_yaml) {
      // TODO - Revisit when getting a script directly is available
      attackScriptRun(getAttackScript(guid));
    } else {
      setAttackScript(attackScriptState);
    }
    // eslint-disable-next-line
  }, [guid]);

  React.useEffect(() => {
    if (!guid) {
      setSupplemental(null);
    } else if (supplementalStatus === Status.pending) {
      //do nothing
    } else if (isEmpty(supplementalState)) {
      supplementalRun(getSupplementalItemsCatalog(ArtifactType.AttackScript, [{ guid }]).then(data => data[guid]));
    } else {
      setSupplemental(supplementalState);
    }
    // eslint-disable-next-line
  }, [guid]);

  React.useEffect(() => {
    if (!guid) {
      setPermissions([]);
    } else if (permissionsStatus === Status.pending) {
      //do nothing
    } else if (isEmpty(attackScriptState?.permission)) {
      permissionsRun(getAttackScriptPermissions(guid));
    } else {
      setPermissions(attackScriptState?.permission);
    }
    // eslint-disable-next-line
  }, [guid]);

  React.useEffect(() => {
    if (!guid) {
      setVersions([]);
    } else if (versionsStatus === Status.pending) {
      //do nothing
    } else {
      versionsRun(getAttackScriptVersions(guid));
    }
    // eslint-disable-next-line
  }, [guid]);

  const setSeverity = React.useCallback(
    (severity: ArtifactScore) => {
      setAttackScript({ ...attackScript, severity });
      const { severities } = supplemental;
      const index = severities.findIndex(severity => severity.organization_id === defaultOrgId);
      const isFound = severities.find(severity => severity.organization_id === defaultOrgId);
      if (isFound) {
        severities[index] = { organization_id: defaultOrgId, severity };
        setSupplemental({ ...supplemental, severities });
      } else {
        setSupplemental({ ...supplemental, severities: [...severities, { organization_id: defaultOrgId, severity }] });
      }
    },
    [attackScript, defaultOrgId, setAttackScript, setSupplemental, supplemental]
  );

  const deleteValidation = React.useCallback(() => task(deleteAttackScript(guid)), [guid, task]);

  return {
    attackScript: {
      ...attackScript,
      permission: permissions,
      versions
    },
    supplemental,
    isPending: [attackScriptStatus, supplementalStatus, permissionsStatus, taskStatus].includes(Status.pending),
    statuses: [attackScriptStatus, supplementalStatus, permissionsStatus],
    error: {
      attackScript: attackScriptError,
      supplemental: supplementalError,
      permissions: permissionsError,
      versions: versionsError
    },
    setSeverity,
    setAttackScript,
    deleteValidation
  };
}
