import React from 'react';

import { FunctionalPermission } from 'types/auth';
import { StrictReactNode } from 'types/core';

import useMayI from './useMayI';

type MayProps = {
  I: FunctionalPermission;
  children: StrictReactNode | StrictReactNode[];
};

const MayContext = React.createContext(false);
MayContext.displayName = 'MayContext';

type ChildProps = { children: StrictReactNode };

/**
 * grant component for use within the May component; wraps UI that should be shown if the user has the given permission
 */
function Yes({ children }: ChildProps): JSX.Element {
  const iMay = React.useContext(MayContext);

  if (iMay) return <>{children}</>;
  return null;
}

/**
 * block component for user within the May component; wraps UI that should be shown if the user does not have the given permission
 */
function No({ children }: ChildProps): JSX.Element {
  const iMayNot = !React.useContext(MayContext);

  if (iMayNot) return <>{children}</>;
  return null;
}

Yes._isGrantBlock = true;
No._isGrantBlock = true;

function renderChild(child: StrictReactNode): JSX.Element {
  if ((child as JSX.Element).type?._isGrantBlock) return <>{child}</>;
  else return <Yes>{child}</Yes>;
}

/**
 * component to guard access to a pieces of UI based on the user's permission to do a thing within the preferred organization
 *
 * any children that are not <Yes/> or <No/> components will be wrapped by a <Yes/> and only shown in permission is granted
 */
function May({ I: action, children }: MayProps): JSX.Element {
  const mayI = useMayI(action);

  return (
    <MayContext.Provider value={mayI}>
      {Array.isArray(children)
        ? children.map((child, i) => <React.Fragment key={i}>{renderChild(child)}</React.Fragment>)
        : renderChild(children)}
    </MayContext.Provider>
  );
}

May.Yes = Yes;
May.No = No;

export default May;
