import React from 'react';

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown, faMinusCircle, faTablePivot } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';

import Button, { ButtonProps } from 'snap-ui/Button';
import {
  gridFilteredDescendantCountLookupSelector,
  GridRenderCellParams,
  gridRowTreeSelector,
  GridSlotsComponentsProps,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiContext,
  useGridSelector
} from 'snap-ui/DataGrid';
import Popper from 'snap-ui/Popper';
import { ToolbarProps } from 'snap-ui/Toolbar';
import Tooltip from 'snap-ui/Tooltip';
import VerticalTransferList from 'snap-ui/VerticalTransferList';
import { ClickAwayListener } from 'snap-ui/util';

import { NOOP } from 'utilities/FunctionUtils';

import { TransferListItem } from '../AnalyticTuning.style';
import { HITS_MAX } from '../useTuningHits';
import {
  CustomFooterContainer,
  GroupingColumnContainer,
  GroupingIcon,
  GroupingListContainer,
  StyledActionIconButton
} from './AnalyticTuningGrid.style';

export function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
  const { id, field, rowNode, colDef } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;
  const percentage = ((filteredDescendantCount / apiRef.current.getRowsCount()) * 100).toFixed(2);

  const treeLookup = useGridSelector(apiRef, gridRowTreeSelector);

  const handleClick: ButtonProps['onClick'] = () => {
    if (rowNode.type !== 'group') return;

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
  };

  if (rowNode.type !== 'group') return null; // required for es-lint to recognize groupingKey/groupingField
  const emptyValue = rowNode.groupingKey === '(empty)';
  if (filteredDescendantCount > 0 && field.includes(rowNode.groupingField)) {
    return (
      <GroupingColumnContainer>
        <StyledActionIconButton
          aria-label='expand'
          onClick={handleClick}
          disabled={emptyValue}
          icon={rowNode.childrenExpanded ? faChevronDown : faChevronRight}
        />

        <Tooltip arrow wrap placement='top' title={rowNode.groupingKey}>
          <>
            <>{rowNode.groupingKey}</>
          </>
        </Tooltip>
        <>({percentage}%)</>

        <StyledActionIconButton
          aria-label='exclude hierarchy'
          icon={faMinusCircle}
          disabled={emptyValue}
          onClick={() => {
            let node = treeLookup[rowNode.id];
            const pairs = [];
            while (node.depth >= 0) {
              if (node.type === 'group') {
                pairs.push({ field: node.groupingField, value: node.groupingKey });
                node = treeLookup[node.parent];
              }
            }
            colDef['onExclude'](pairs);
          }}
        />
      </GroupingColumnContainer>
    );
  }

  return null;
}

type OverriddenToolbarProps = ToolbarProps & {
  fields: string[];
};

export function AnalyticTuningGridToolbar(props: OverriddenToolbarProps) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <CustomGridToolbarGrouping {...props} />
    </GridToolbarContainer>
  );
}

function CustomGridToolbarGrouping(props: OverriddenToolbarProps) {
  const buttonRef = React.useRef();
  const apiRef = useGridApiContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initial = React.useMemo(() => apiRef.current.state.rowGrouping.model, []);

  const [fields, setFields] = React.useState<string[]>([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const [chosen, setChosen] = React.useState<string[]>(initial);
  const [choices, setChoices] = React.useState<string[]>([]);

  // Keep chosen/choices (grouped by, available to group by) fields in sync
  React.useEffect(() => {
    const fieldsAdded = props.fields.filter(field => !fields.includes(field)).filter(field => !initial.includes(field));
    const fieldsRemoved = fields.filter(field => !props.fields.includes(field));

    if (!isEmpty(fieldsAdded)) {
      setChoices(choices => [...choices, ...fieldsAdded]);
    }
    if (!isEmpty(fieldsRemoved)) {
      setChoices(choices => choices.filter(choice => !fieldsRemoved.includes(choice)));
      setChosen(chosen => chosen.filter(c => !fieldsRemoved.includes(c)));
    }

    setFields(props.fields);
  }, [initial, fields, props.fields]);

  React.useEffect(() => {
    apiRef.current.setRowGroupingModel(chosen);
  }, [apiRef, chosen]);

  return (
    <>
      <Button ariaLabel='Grouping' ref={buttonRef} onClick={() => setOpen(open => !open)} variant='text' size='small'>
        <GroupingIcon icon={faTablePivot} />
        Grouping
      </Button>
      <Popper open={open} anchorEl={buttonRef.current}>
        <ClickAwayListener onClickAway={open ? () => setOpen(false) : NOOP}>
          <GroupingListContainer>
            <VerticalTransferList
              top={chosen}
              setTop={setChosen}
              topTitle='Grouped By'
              bottom={choices}
              setBottom={setChoices}
              bottomTitle='Grouping Fields'
              renderItem={(item: string) => {
                return (
                  <TransferListItem>
                    <span>{item}</span>
                  </TransferListItem>
                );
              }}
              addAriaLabel='add field to grouping'
              removeAriaLabel='remove field from grouping'
            />
          </GroupingListContainer>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

export type AnalyticTuningGridFooterProps = GridSlotsComponentsProps['footer'] & {
  totalHits: number;
};

export function AnalyticTuningGridFooter({ totalHits }: AnalyticTuningGridFooterProps) {
  const apiRef = useGridApiContext();
  const rowCount = apiRef.current.getRowsCount();

  return totalHits > HITS_MAX ? (
    <CustomFooterContainer>
      Showing {rowCount} of {totalHits} hits.
    </CustomFooterContainer>
  ) : (
    <CustomFooterContainer>Showing {rowCount} hits.</CustomFooterContainer>
  );
}
