import { CancelTokenType, snapattack } from 'apis/snapattack';

import { Guid } from 'types/common';

import {
  AutocompleteResponse,
  SearchCompatibleArtifactType,
  SupplementalArtifact,
  SupplementalSessionDetection
} from './Search.type';

export function getSupplementalDetectionItems(
  items: Guid[],
  config?: { cancelToken?: CancelTokenType; params?: { org_id: string[] } }
): Promise<SupplementalArtifact[]> {
  return snapattack.post('/search/signatures/query/cached/v2/supplemental/', items, config).then(r => r.data);
}

export function getSupplementalIntelItems(
  items: Guid[],
  config?: { cancelToken?: CancelTokenType }
): Promise<SupplementalArtifact[]> {
  return snapattack.post('/search/intel/query/cached/v2/supplemental/', items, config).then(r => r.data);
}

export function getSupplementalAttackScriptItems(
  items: Guid[],
  config?: { cancelToken?: CancelTokenType }
): Promise<SupplementalArtifact[]> {
  return snapattack.post('/search/bas/script/supplemental/', items, config).then(r => r.data);
}

export function getSupplementalSessionDetectionItems(items: Guid[]): Promise<SupplementalSessionDetection[]> {
  return snapattack.post('/search/sessions/query/cached/v2/supplemental/', items).then(r => r.data);
}

export function searchArtifactByType(
  partial: string,
  types: SearchCompatibleArtifactType[],
  config?: { cancelToken?: CancelTokenType }
): Promise<AutocompleteResponse> {
  return snapattack.post(`/search/autocomplete/`, { partial, types }, config).then(r => r.data);
}

export function searchMetadataTagField(field: string, partial: string) {
  return snapattack.post(`/search/autocomplete/metadata/${field}/`, { partial }).then(r => r.data);
}
