import React from 'react';

import { Glossary, MenuOrdinal } from '../Scaffold.type';
import ListItemsDetect from './ListItemsDetect';
import ToolbarItem from './ToolbarItem';

type Props = {
  onClick(id: string, element: HTMLElement): void;
  onClose(): void;
  glossary: Glossary;
};

export default function ToolbarItemDetect({ glossary, onClick, onClose }: Props) {
  return (
    <ToolbarItem
      anchorEl={glossary[MenuOrdinal.Detect]}
      onClick={onClick}
      onClose={onClose}
      open={Boolean(glossary[MenuOrdinal.Detect])}
      ordinal={MenuOrdinal.Detect}
      title='Detect'
      testId='menu-detect'
    >
      <ListItemsDetect onClose={onClose} />
    </ToolbarItem>
  );
}
