import MuiDrawer from 'snap-ui/Drawer';
import { ListItem } from 'snap-ui/List';
import { CSSObject, styled, Theme } from 'snap-ui/util';

import { DRAWER_WIDTH } from 'module/Scaffold/Scaffold.const';

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

export const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' && prop !== 'disablePortal' })(
  ({ theme, open }) => ({
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': {
        ...openedMixin(theme),
        backgroundColor: theme.palette.common.black,
        padding: theme.spacing(0, 5)
      },
      '.Copyright': {
        alignSelf: 'center'
      }
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': {
        ...closedMixin(theme),
        backgroundColor: theme.palette.common.black,
        overflowY: 'hidden'
      },

      '.ScaffoldItem': {
        width: '100%'
      }
    }),

    '.sticky': {
      position: 'sticky',
      top: 0,
      /* stashed items may break this if defining their own z */
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      backgroundColor: theme.palette.common.black
    }
  })
);

export const DrawerHeader = styled('div')<any>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 1),

  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

export const ListSelector = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  padding-right: 0;
  align-items: center;
`;
