import React, { ReactElement } from 'react';

import AnalyticGapIcon from 'module/Icons/AnalyticGapIcon';
import UndetectedThreatIcon from 'module/Icons/UndetectedThreatIcon';
import UnvalidatedAnalyticIcon from 'module/Icons/UnvalidatedAnalyticIcon';
import ValidatedAnalyticIcon from 'module/Icons/ValidatedAnalyticIcon';
import HalCount from 'module/Widgets/HalCount';
import MarkerBadge from 'module/Widgets/MarkerBadge';

import { ArtifactType } from 'types/common';

type Props = {
  topic: ArtifactType;
  count: number;
  unique: number;
  validated: number;
  gap: number;
  undetected?: number;
  unvalidated: number;
  iconsInheritBackgroundColor?: boolean;
  headless?: boolean;
};

const MarkerContent = (props: Props): ReactElement => {
  const undetectedThreats = props.undetected || 0;

  return (
    <>
      <HalCount count={props.count} unique={props.unique} type={props.topic} headless={props.headless} />
      <MarkerBadge
        count={props.validated}
        icon={<ValidatedAnalyticIcon />}
        label={`${props.validated} Validated Detections`}
      />
      <MarkerBadge
        count={props.gap}
        icon={<AnalyticGapIcon inheritBackground={props.iconsInheritBackgroundColor} />}
        label={`${props.gap} Detection Gaps`}
      />
      {ArtifactType.Session === props.topic && (
        <MarkerBadge
          count={undetectedThreats}
          icon={<UndetectedThreatIcon inheritBackground={props.iconsInheritBackgroundColor} />}
          label={`${undetectedThreats} Undetected Threats`}
        />
      )}
      <MarkerBadge
        count={props.unvalidated}
        icon={<UnvalidatedAnalyticIcon inheritBackground={props.iconsInheritBackgroundColor} />}
        label={`${props.unvalidated} Unvalidated Detections`}
      />
    </>
  );
};

export default MarkerContent;
