import React from 'react';

import omit from 'lodash/omit';
import uniqBy from 'lodash/uniqBy';

import { useAnalyticLanguageCatalog } from 'module/Analytic/AnalyticLanguages';
import {
  Integration,
  IntegrationLanguageOption,
  IntegrationOption,
  SchemaSupported
} from 'module/Integration/Integration.type';
import { hasSupportedMatch, mapIntegrationToOption } from 'module/Integration/Integration.util';
import { getTargets } from 'module/TranslateAnalytic';
import { AutoCompleteOptionContent } from 'module/TranslateAnalytic/TranslateAnalytic.helper';

import { useIntegrationCatalog } from 'provider';

import { CompilationTargetId, Language } from 'types/analytic';
import { Ident } from 'types/common';

import { useLanguagePlatforms } from './';

const SNAP_ATTACK_SPLUNK_COMPILATION = Object.values(omit(CompilationTargetId, 'Sigma'));
const SYNTHETIC_SNAP_INTEGRATION_ID = -1;
export function getSyntheticSnapattackIntegration(languages: Language[]): Integration {
  return {
    id: SYNTHETIC_SNAP_INTEGRATION_ID,
    search_targets: languages
      .filter(language => SNAP_ATTACK_SPLUNK_COMPILATION.includes(language.id) && !language.hidden)
      .map(language => ({ ...language, supported: true })) as Integration['search_targets']
  } as Integration;
}

/**
 * generate options for IntegrationAutocomplete that include synthetic options for
 *  - platforms that SnapAttack supports that the user doesn't have integrations for
 *  - languages that don't have platform support in SnapAttack
 * in addition to their preferred org's integrations
 */
export function useIntegrationOptions(
  _includeSigma = true,
  supportedLanguageIds?: Ident[]
): {
  getIntegrationOptionsFromPlatformEntries: (
    entries: [string, SchemaSupported[string]][],
    ignoreLanguageVisibility?: boolean
  ) => IntegrationOption[];
  integrationOptions: IntegrationOption[];
} {
  const { integrations } = useIntegrationCatalog();
  const { supportedPlatforms: snapattackSupportedPlatforms } = useLanguagePlatforms();
  const { data: languages } = useAnalyticLanguageCatalog();
  const isSigmaVisible = languages.some(l => l.id === CompilationTargetId.Sigma && !l.hidden);
  const includeSigma = _includeSigma && isSigmaVisible;

  const getIntegrationOptionsFromPlatformEntries = React.useCallback(
    (entries: [string, SchemaSupported[string]][], ignoreLanguageVisibility = false): IntegrationOption[] => {
      return entries
        .filter(([, platform]) => {
          const visibleLanguages = [...platform.deploy, ...platform.hunt, ...platform.search].filter(
            languageId => (ignoreLanguageVisibility ? true : languages.find(l => l.id === languageId)?.hidden === false) // `undefined` and `true` are both bad here
          );
          return !!visibleLanguages.length;
        })
        .map(([platformType, platform]) => ({
          label: platform.type_label.short_name,
          group: 'Integrations',
          type: platformType,
          value: platformType,
          warning: supportedLanguageIds
            ? !hasSupportedMatch([...platform.deploy, ...platform.hunt, ...platform.search], supportedLanguageIds)
            : false
        }));
    },
    [languages, supportedLanguageIds]
  );

  const integrationOptionsFromPlatforms = React.useMemo(
    () =>
      getIntegrationOptionsFromPlatformEntries(
        Object.entries(snapattackSupportedPlatforms).filter(
          ([platformType]) => !integrations.preferred_org_aware.some(i => i.type === platformType)
        )
      ),
    [getIntegrationOptionsFromPlatformEntries, integrations.preferred_org_aware, snapattackSupportedPlatforms]
  );

  const integrationOptionsFromLanguages = React.useMemo(
    () =>
      languages
        .filter(
          lang =>
            // filter out hidden languages
            !lang.hidden &&
            // filter out snapAttack languages, we don't want separate synthetic snapAttack Integration for each
            !SNAP_ATTACK_SPLUNK_COMPILATION.includes(lang.id) &&
            // filter out if associated with the user's integrations
            // these will be selectable under those integrations
            integrations.preferred_org_aware.every(
              integration =>
                !integration.deployment_targets.some(t => t.id === lang.id) &&
                !integration.hunt_targets.some(t => t.id === lang.id) &&
                !integration.search_targets.some(t => t.id === lang.id)
            ) &&
            // filter out if associated with a platform that's NOT represented in the user's integrations
            // these will be selectable under those platform options
            integrationOptionsFromPlatforms.every(platformOption => {
              const platform = snapattackSupportedPlatforms[platformOption.type];
              return (
                !platform.deploy.includes(lang.id) &&
                !platform.hunt.includes(lang.id) &&
                !platform.search.includes(lang.id)
              );
            })
        )
        .map(language => ({
          label: language.name,
          group: 'Languages',
          type: 'CustomerManaged',
          value: `language-${language.id}`,
          warning: supportedLanguageIds ? !supportedLanguageIds?.includes(language.id) : false
        })),
    [
      integrationOptionsFromPlatforms,
      integrations.preferred_org_aware,
      languages,
      snapattackSupportedPlatforms,
      supportedLanguageIds
    ]
  );

  const integrationOptions = React.useMemo(
    () =>
      [
        includeSigma
          ? {
              label: 'Sigma',
              group: 'Languages',
              type: 'Sigma',
              warning: supportedLanguageIds ? !supportedLanguageIds?.includes(CompilationTargetId.Sigma) : false,
              value: `language-${CompilationTargetId.Sigma}`
            }
          : undefined,
        // create synthetic integration that will consolidate snapAttack splunk languages options under
        {
          label: 'SnapAttack',
          group: 'Integrations',
          type: 'SnapAttackIcon',
          value: SYNTHETIC_SNAP_INTEGRATION_ID.toString()
        },
        ...integrations.preferred_org_aware.map(integration =>
          mapIntegrationToOption(integration, supportedLanguageIds)
        ),
        // create synthetic integrations for platforms we don't have integrations for
        ...integrationOptionsFromPlatforms,
        // create synthetic integrations for languages we don't have platforms for
        ...integrationOptionsFromLanguages
        // create synthetic integrations for snapAttack languages, even if they are hidden
      ].filter(Boolean),
    [
      includeSigma,
      integrationOptionsFromLanguages,
      integrationOptionsFromPlatforms,
      integrations.preferred_org_aware,
      supportedLanguageIds
    ]
  );

  return {
    getIntegrationOptionsFromPlatformEntries,
    integrationOptions
  };
}

export function useLanguageOptions(
  integrationOptionValue: string,
  supportedLanguageIds?: number[],
  recompileInProgress?: boolean
): IntegrationLanguageOption[] {
  const { integrations } = useIntegrationCatalog();
  const { supportedPlatforms: snapattackSupportedPlatforms } = useLanguagePlatforms();
  const { data: languageList, allLanguages } = useAnalyticLanguageCatalog();

  // create synthetic integration for snapAttack splunk languages options
  const snapAttackIntegration = React.useMemo(() => getSyntheticSnapattackIntegration(allLanguages), [allLanguages]);

  const languagesOptionsFromSnapAttack = React.useMemo(() => {
    return allLanguages.filter(language => SNAP_ATTACK_SPLUNK_COMPILATION.includes(language.id));
  }, [allLanguages]);

  const mapLanguageToOption = React.useCallback(
    (language: Pick<Language, 'id' | 'name'>): IntegrationLanguageOption => {
      return {
        value: language.id.toString(),
        label: language.name,
        warning: supportedLanguageIds ? !supportedLanguageIds?.includes(language.id) : false,
        content: (
          <AutoCompleteOptionContent
            supported={supportedLanguageIds ? supportedLanguageIds.includes(language.id) : true}
            text={language.name}
            recompile={recompileInProgress}
          />
        )
      };
    },
    [recompileInProgress, supportedLanguageIds]
  );

  const selectedIntegration = React.useMemo(
    () => [...integrations.all, snapAttackIntegration].find(i => i.id.toString() === integrationOptionValue),
    [integrations.all, snapAttackIntegration, integrationOptionValue]
  );

  const selectedPlatform = React.useMemo(() => {
    if (selectedIntegration) return;
    return snapattackSupportedPlatforms[integrationOptionValue];
  }, [selectedIntegration, integrationOptionValue, snapattackSupportedPlatforms]);

  const selectedLanguageAsIntegration = React.useMemo(() => {
    if (selectedIntegration || selectedPlatform || !integrationOptionValue) return;
    const languageId = integrationOptionValue.replace('language-', '');
    return [...languagesOptionsFromSnapAttack, ...languageList].find(l => l.id.toString() === languageId);
  }, [selectedIntegration, selectedPlatform, integrationOptionValue, languagesOptionsFromSnapAttack, languageList]);

  return React.useMemo(
    () =>
      selectedIntegration
        ? uniqBy(
            getTargets(selectedIntegration)
              .map(target => mapLanguageToOption(target))
              .filter(target => languageList.some(languge => languge.id.toString() === target.value)),
            'value'
          )
        : selectedPlatform
        ? Array.from(new Set([...selectedPlatform.deploy, ...selectedPlatform.hunt, ...selectedPlatform.search]))
            .map(languageId => {
              const language = [...languagesOptionsFromSnapAttack, ...languageList].find(l => l.id === languageId);
              if (!language || language.hidden) return;
              return mapLanguageToOption(language);
            })
            .filter(Boolean)
        : selectedLanguageAsIntegration
        ? [mapLanguageToOption(selectedLanguageAsIntegration)]
        : [],
    [
      languageList,
      languagesOptionsFromSnapAttack,
      mapLanguageToOption,
      selectedIntegration,
      selectedLanguageAsIntegration,
      selectedPlatform
    ]
  );
}
