import React from 'react';

import zod from 'zod';

import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { ArtifactType } from 'types/common';
import { Ops, Query } from 'types/filter';

import { FilterControl } from '../GlobalFilter.style';
import { FilterConfig } from '../GlobalFilter.type';

type IsSimulatedKeys = {
  isSimulated: string;
};

type IsSimulatedFilterProps = {
  onChange(values: IsSimulatedKeys): void;
  values: IsSimulatedKeys;
};

function IsSimulatedFilter({ onChange, values }: IsSimulatedFilterProps): React.ReactElement {
  const booleanValue = values.isSimulated?.toLowerCase() === 'true';

  return (
    <FilterControl>
      <FormLabel id='is-simulated-toggle-button-group-label' className='title-tooltip'>
        Is Simulated
        <Tooltip
          title='Simulated attacks can validate detections without running malicious code.'
          placement='right'
          arrow
          wrap
        >
          <Icon.Info />
        </Tooltip>
      </FormLabel>
      <FormGroup aria-labelledby='is-simulated-toggle-button-group-label'>
        <FormControlLabel
          key='isSimulated'
          control={<Checkbox onChange={handleChange} value='isSimulated' checked={booleanValue} />}
          label='Show Simulated Attack Scripts'
        />
      </FormGroup>
    </FilterControl>
  );

  function handleChange(_e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    onChange({ isSimulated: checked ? String(checked) : undefined });
  }
}

function toQuery(values: IsSimulatedKeys): Query {
  if (values.isSimulated?.toLowerCase() !== 'true') return;

  return {
    field: 'simulated',
    op: Ops.equals,
    value: 'true'
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('simulated'),
    op: zod.literal(Ops.equals),
    value: zod.string()
  })
  .transform(query => ({ isSimulated: query.value.toLowerCase() === 'true' ? 'true' : undefined }));

const IsSimulatedFilterConfig: FilterConfig<IsSimulatedKeys> = {
  defaults: { default: () => ({ isSimulated: 'false' }) },
  supportedTopics: [ArtifactType.AttackScript],
  component: IsSimulatedFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};

export default IsSimulatedFilterConfig;
