import React from 'react';

import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

type MarkerBadgeProps = {
  count: number;
  icon: React.ReactNode;
  label: string;
  'data-testid'?: string;
};

const Container = styled('div', { name: 'MarkerBadge' })`
  align-items: center;
  display: flex;
  gap: ${p => p.theme.spacing(1)};
  word-break: keep-all;

  label {
    font-size: 0.75rem;
    font-weight: bold;
    padding: ${p => p.theme.spacing(0, 2)};
    line-height: 1.5rem;
  }
`;

function MarkerBadge({ count, icon, label, ...props }: MarkerBadgeProps): React.ReactElement {
  return (
    <Tooltip title={label} placement='top' arrow>
      <Container>
        {icon}
        <label data-testid={props['data-testid']}>{count}</label>
      </Container>
    </Tooltip>
  );
}

export default MarkerBadge;
