import { HyperTag } from 'module/Landing/Landing.type';

export type NistControl = {
  organization_id: number;
  time: string;
  tag_id: number;
  tag_name: string;
  detections: number;
  detections_available: number;
  detections_deployed: number;
  threats: number;
  intel: number;
};

export type NistArtifact = NistControl & {
  tags: HyperTag[];
  detail: HyperTag;
  external_source_id: string;
  family: string;
};

export enum NistCoverage {
  Covered = 'Covered',
  Improving = 'Improving',
  Risk = 'Risk'
}

export type NistFamilyScore = {
  name: string;
  deployed: number;
  covered: number;
  improving: number;
  risk: number;
  familyTotal: number;
};
