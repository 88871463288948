import { FunctionalPermission } from 'types/auth';

import useRequester from './useRequester';

/**
 * Determine if the user is allowed to do a thing within their `preferred_organization`
 */
export default function useMayI(action: FunctionalPermission): boolean {
  const requester = useRequester();

  return requester(action);
}
