import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { getOrgMetadata } from 'apis/resources/organization';

import { MetadataSchema } from 'module/Metadata/Metadata.type';

import { Status, useAsync } from 'storage';

import { Guid, Ident } from 'types/common';

import { useAuth } from './Account';

type MetadataSchemaInterface = {
  invalid: boolean;
  orgId: Ident;
  orgGuid: Guid;
  schema: MetadataSchema;
  schemaStatus: Status;
  setSchema: (metadata: MetadataSchema) => void;
};

const MetadataSchemaContext = React.createContext<MetadataSchemaInterface>(null);
MetadataSchemaContext.displayName = 'MetadataSchemaContext';

function useMetadataSchema(): MetadataSchemaInterface {
  const context = React.useContext(MetadataSchemaContext);
  if (!context) {
    throw new Error('useMetadataSchema must be used within the MetadataSchemaContext');
  }
  return context;
}

function MetadataSchemaProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const { defaultOrgId, permission } = useAuth();
  const { data, run, status, setData } = useAsync<MetadataSchema>();

  const org = React.useMemo(() => ({ ...permission.find(org => org.id === defaultOrgId) }), [defaultOrgId, permission]);
  const invalid = !data || isEmpty(data) || isEmpty(data?.properties);

  const setSchema = React.useCallback((schema: MetadataSchema) => setData(schema, Status.resolved), [setData]);

  const value = React.useMemo(
    () => ({
      invalid,
      orgId: org.id,
      orgGuid: org.guid,
      schema: data,
      schemaStatus: status,
      setSchema
    }),
    [org.id, org.guid, invalid, data, status, setSchema]
  );

  React.useEffect(() => {
    if (org.guid) run(getOrgMetadata(org.guid));
  }, [org.guid, run]);

  return <MetadataSchemaContext.Provider value={value}>{children}</MetadataSchemaContext.Provider>;
}

export { MetadataSchemaProvider, useMetadataSchema };
