import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import Checkbox from 'snap-ui/Checkbox';
import FormControl from 'snap-ui/FormControl';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Radio from 'snap-ui/Radio';
import RadioGroup from 'snap-ui/RadioGroup';
import TextField from 'snap-ui/TextField';
import { useTheme } from 'snap-ui/util';

import { EIGHTS_TRUTH, FOURS_TRUTH, ONES_TRUTH, SIXTEENS_TRUTH, TWOS_TRUTH } from 'constants/common';
import Path from 'constants/paths';

import { HeatMapOptions, catalog } from 'module/Matrix/Matrix.const';
import { HeatMap, Palette } from 'module/Matrix/Matrix.type';

import { parseAnyQueryString } from 'utilities/SearchParam';

import { getNewMatrixParams, hydratedMatrixDisplayOptions } from './Scaffold.service';
import { MatrixPaletteOrdinal, Stashed } from './Scaffold.type';
import MatrixLegendChip from './core/MatrixLegendChip';

export default function useMatrixDisplayStash(): Stashed[] {
  const { palette: mainPalette } = useTheme();
  const { push } = useHistory();
  const { search, pathname } = useLocation();
  const _options = hydratedMatrixDisplayOptions(search);
  const options = React.useMemo(
    () => ({
      display: _options.display,
      palette: _options.palette,
      heatMap: _options.heatMap,
      bounds: _options.bounds
    }),
    [_options.bounds, _options.display, _options.heatMap, _options.palette]
  );

  const handleDisplayChange = React.useCallback(
    (event: React.SyntheticEvent<HTMLInputElement, Event>, checked: boolean) => {
      const value = Number(event.currentTarget.value);
      const replacements = { display: Math.max(0, (options.display ?? 0) + (checked ? value : -value)) };
      const existingSearch = parseAnyQueryString(window.location.search);
      push(getNewMatrixParams(pathname, existingSearch, options, replacements));
    },
    [pathname, push, options]
  );

  const handlePaletteChange = React.useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, value: MatrixPaletteOrdinal) => {
      const replacements = {
        palette: Object.values(MatrixPaletteOrdinal).includes(value) ? value : MatrixPaletteOrdinal.Traffic
      };
      const existingSearch = parseAnyQueryString(window.location.search);
      push(getNewMatrixParams(pathname, existingSearch, options, replacements));
    },
    [options, pathname, push]
  );

  const handleHeatMapChange = React.useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, value: HeatMap) => {
      const replacements = { heatMap: value };
      const existingSearch = parseAnyQueryString(window.location.search);
      push(getNewMatrixParams(pathname, existingSearch, options, replacements));
    },
    [options, pathname, push]
  );

  const handleBoundsChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const replacements = { bounds: Math.max(0, Number(event.currentTarget.value)) };
      const existingSearch = parseAnyQueryString(window.location.search);
      push(getNewMatrixParams(pathname, existingSearch, options, replacements));
    },
    [options, pathname, push]
  );

  return React.useMemo(() => {
    if (Path.Matrix !== pathname) return [];
    const palette: Palette | undefined = catalog[options.palette];

    const stash: Stashed[] = [
      {
        id: 'display',
        openedContent: (
          <div className='filter-widget'>
            <FormControl>
              <FormLabel id='display-toggle-button-group-label'>Display</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox onChange={handleDisplayChange} value='1' checked={ONES_TRUTH.includes(options.display)} />
                  }
                  label={`Show ATT&CK ID`}
                />
                <FormControlLabel
                  control={
                    <Checkbox onChange={handleDisplayChange} value='2' checked={TWOS_TRUTH.includes(options.display)} />
                  }
                  label={`Show detection count`}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleDisplayChange}
                      value='4'
                      checked={FOURS_TRUTH.includes(options.display)}
                    />
                  }
                  label={`Show empty cells`}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleDisplayChange}
                      value='8'
                      checked={EIGHTS_TRUTH.includes(options.display)}
                    />
                  }
                  label={`Show sub-techniques`}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleDisplayChange}
                      value='16'
                      checked={SIXTEENS_TRUTH.includes(options.display)}
                    />
                  }
                  label={`Show PRE ATT&CK`}
                />
              </FormGroup>
            </FormControl>
          </div>
        )
      },

      {
        id: 'palette',
        openedContent: (
          <div className='filter-widget'>
            <FormControl sx={{ width: '100%', display: 'flex', flexDirection: 'col' }}>
              <FormLabel id='palette-radio-buttons-group-label'>Color Palette</FormLabel>
              <RadioGroup
                aria-labelledby='palette-radio-buttons-group-label'
                name='palette-radio-buttons-group'
                value={options.palette}
                onChange={handlePaletteChange}
              >
                <FormControlLabel value={MatrixPaletteOrdinal.Snap} control={<Radio />} label='Snap' />
                <FormControlLabel
                  value={MatrixPaletteOrdinal.Grayscale}
                  control={
                    <Radio
                      sx={{
                        color: mainPalette.grey[300],
                        '&.Mui-checked': {
                          color: mainPalette.grey[300]
                        }
                      }}
                    />
                  }
                  label='Grayscale'
                />
                <FormControlLabel
                  value={MatrixPaletteOrdinal.Traffic}
                  control={
                    <Radio
                      sx={{
                        color: mainPalette.orange.main,
                        '&.Mui-checked': {
                          color: mainPalette.orange.main
                        }
                      }}
                    />
                  }
                  label='Traffic'
                />
                <FormControlLabel
                  value={MatrixPaletteOrdinal.Red}
                  control={
                    <Radio
                      sx={{
                        color: mainPalette.error.main,
                        '&.Mui-checked': {
                          color: mainPalette.error.main
                        }
                      }}
                    />
                  }
                  label='Red'
                />
                <FormControlLabel
                  value={MatrixPaletteOrdinal.Blue}
                  control={
                    <Radio
                      sx={{
                        color: mainPalette.info.main,
                        '&.Mui-checked': {
                          color: mainPalette.info.main
                        }
                      }}
                    />
                  }
                  label='Blue'
                />
              </RadioGroup>
            </FormControl>
          </div>
        )
      },
      {
        id: 'heatmap',
        openedContent: (
          <div className='filter-widget'>
            <FormControl sx={{ width: '100%', display: 'flex', flexDirection: 'col' }}>
              <FormLabel id='heatmap'>Heatmap Criteria</FormLabel>
              <RadioGroup
                aria-labelledby='heatmap'
                name='heatmapGroup'
                value={options.heatMap}
                onChange={handleHeatMapChange}
              >
                {HeatMapOptions.map(option => (
                  <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        )
      },

      {
        id: 'bounds',
        openedContent: (
          <div className='filter-widget'>
            <TextField
              label='Color Upper Bounds'
              type='number'
              value={options.bounds}
              onChange={handleBoundsChange}
              disabled={options.palette === MatrixPaletteOrdinal.Snap}
            />
          </div>
        )
      },

      {
        id: 'legend',
        openedContent: (
          <div className='filter-widget'>
            <FormControl
              sx={{ width: '100%', display: 'flex', flexDirection: 'col' }}
              disabled={options.palette === MatrixPaletteOrdinal.Snap}
            >
              <FormLabel id='legend-badges-label'>Legend</FormLabel>
              <FormGroup
                aria-labelledby='legend-badges-label'
                sx={{ marginLeft: '0.5rem', marginTop: '0.5rem', display: 'flex', gap: 1 }}
              >
                <MatrixLegendChip palette={palette} tier={5} bucket={1} />
                <MatrixLegendChip palette={palette} tier={4} bucket={1} />
                <MatrixLegendChip palette={palette} tier={3} bucket={1} />
                <MatrixLegendChip palette={palette} tier={2} bucket={1} />
                <MatrixLegendChip palette={palette} tier={1} bucket={1} />
                <MatrixLegendChip palette={palette} tier={0} bucket={1} />
              </FormGroup>
            </FormControl>
          </div>
        )
      }
    ];

    return stash;
  }, [
    handleBoundsChange,
    handleDisplayChange,
    handleHeatMapChange,
    handlePaletteChange,
    mainPalette.error.main,
    mainPalette.grey,
    mainPalette.info.main,
    mainPalette.orange.main,
    options.bounds,
    options.display,
    options.heatMap,
    options.palette,
    pathname
  ]);
}
