import { snapattack } from 'apis/snapattack';

import { MULTIPART_REQUEST_CONFIG } from 'constants/common';

import { AssociatedCollections } from 'module/Collection/Collection.type';
import { TagGroup, convertTagsToTagNames } from 'module/Tag';

import { Permitted } from 'types/auth';
import { Artifact, Guid } from 'types/common';

import { Intel, IntelPayload, IntelReferences, LinkSummary } from './Intel.type';

const prefix = '/intel';

function buildMultipartPayload(intel: Partial<IntelPayload> | Partial<Intel>, files?: File[]): FormData {
  const fd = new FormData();
  fd.append('payload', JSON.stringify(intel));
  files?.forEach(file => {
    fd.append('intel_files', file);
  });
  return fd;
}

export function putIntel(guid: Guid, intel: Partial<IntelPayload> | Partial<Intel>, files?: File[]): Promise<Intel> {
  const body = buildMultipartPayload(intel, files);
  return snapattack.put(`${prefix}/${guid}/`, body, MULTIPART_REQUEST_CONFIG).then(r => r.data);
}

export function postIntel(intel: Partial<IntelPayload>, files?: File[]): Promise<Intel> {
  const body = buildMultipartPayload(intel, files);
  return snapattack.post(`${prefix}/`, body, MULTIPART_REQUEST_CONFIG).then(r => r.data);
}

export function getIntel(intelId: Guid): Promise<Artifact> {
  const url = `${prefix}/${intelId}/`;
  return snapattack.get(url).then(r => r.data);
}

export function getIntelReferences(intelId: Guid): Promise<IntelReferences> {
  const url = `${prefix}/${intelId}/references/`;
  return snapattack.get(url).then(r => r.data);
}

export function getIntelPermissions(intelId: Guid): Promise<Permitted> {
  const url = `${prefix}/${intelId}/permissions/`;
  return snapattack.get(url).then(r => ({
    permission: r.data
  }));
}

export function deleteIntel(guid: Guid): Promise<void> {
  return snapattack.delete(`${prefix}/${guid}/`).then(r => r.data);
}

export function downloadAttachment(guid: Guid): Promise<any> {
  return snapattack.get(`${prefix}/files/${guid}/`, { responseType: 'blob' }).then(r => r.data);
}
export function deleteAttachment(guid: Guid): Promise<any> {
  return snapattack.delete(`${prefix}/files/${guid}/`).then(r => r.data);
}

export function summarize(source: string): Promise<LinkSummary> {
  return snapattack.post(`${prefix}/summarize/`, { source }).then(r => r.data);
}

export function summarizeFile(file: File): Promise<LinkSummary> {
  const fd = new FormData();

  fd.append('file', file);

  return snapattack
    .post(`${prefix}/summarizer/file/`, fd, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(r => r.data);
}

export function getIntelCollectionAssociations(guid: Guid): Promise<AssociatedCollections[]> {
  return snapattack.get(`${prefix}/${guid}/collections/`).then(r => r.data);
}

export function quickAddTags(guid: Guid, additions: string[]) {
  return snapattack.put(`${prefix}/${guid}/tags/`, additions);
}

export function quickDeleteTags(guid: Guid, deletions: string[]) {
  return snapattack.delete(`${prefix}/${guid}/tags/`, { data: deletions });
}

export function tagRefresh(guid: Guid): Promise<TagGroup> {
  return snapattack.get(`${prefix}/${guid}/tags/`).then(r => convertTagsToTagNames(r.data));
}
