import React from 'react';

import { styled } from 'snap-ui/util';

import { ReactComponent as AdobeSquare } from '../assets/adobe-square.svg';
import { ReactComponent as Adobe } from '../assets/adobe.svg';
import { ReactComponent as ApacheSquare } from '../assets/apache-square.svg';
import { ReactComponent as Apache } from '../assets/apache.svg';
import { ReactComponent as Atlassian } from '../assets/atlassian.svg';
import { ReactComponent as AWS } from '../assets/aws.svg';
import { ReactComponent as CarbonBlackCloud } from '../assets/carbon-black-cloud.svg';
import { ReactComponent as CarbonBlackEndpoint } from '../assets/carbon-black-endpoint.svg';
import { ReactComponent as MitreAttack } from '../assets/circle-ampersand-duotone.svg';
import { ReactComponent as Cisco } from '../assets/cisco.svg';
import { ReactComponent as Citrix } from '../assets/citrix.svg';
import { ReactComponent as Cortex } from '../assets/cortex.svg';
import { ReactComponent as CrowdStrike } from '../assets/crowdstrike.svg';
import { ReactComponent as CustomerManaged } from '../assets/customer-managed.svg';
import { ReactComponent as Devo } from '../assets/devo.svg';
import { ReactComponent as Elastic } from '../assets/elastic.svg';
import { ReactComponent as F5 } from '../assets/f5.svg';
import { ReactComponent as Fortinet } from '../assets/fortinet.svg';
import { ReactComponent as Git } from '../assets/git.svg';
import { ReactComponent as GoogleChronicle } from '../assets/google-chronicle.svg';
import { ReactComponent as GoogleCloud } from '../assets/google-cloud.svg';
import { ReactComponent as GoogleSecOps } from '../assets/google-secops.svg';
import { ReactComponent as GoogleWorkspace } from '../assets/google-workspace.svg';
import { ReactComponent as Ivanti } from '../assets/ivanti.svg';
import { ReactComponent as KaliLinux } from '../assets/kalilinux.svg';
import { ReactComponent as LinuxTux } from '../assets/linux-tux.svg';
import { ReactComponent as Mandiant } from '../assets/mandiant.svg';
import { ReactComponent as MicrosoftAzure } from '../assets/microsoft-azure.svg';
import { ReactComponent as MicrosoftDefender } from '../assets/microsoft-defender.svg';
import { ReactComponent as MicrosoftOffice365 } from '../assets/microsoft-office-365.svg';
import { ReactComponent as MicrosoftSentinel } from '../assets/microsoft-sentinel.svg';
import { ReactComponent as Windows } from '../assets/microsoft-windows.svg';
import { ReactComponent as Microsoft } from '../assets/microsoft.svg';
import { ReactComponent as Oracle } from '../assets/oracle.svg';
import { ReactComponent as PaloAlto } from '../assets/paloalto.svg';
import { ReactComponent as QRadar } from '../assets/qradar.svg';
import { ReactComponent as RedCanary } from '../assets/redcanary.svg';
import { ReactComponent as SentinelOne } from '../assets/sentinel-one.svg';
import { ReactComponent as SigmaLight } from '../assets/sigma-light.svg';
import { ReactComponent as Sigma } from '../assets/sigma.svg';
import { ReactComponent as SnapAttackIcon } from '../assets/snapattack-icon.svg';
import { ReactComponent as SnapAttackLogo } from '../assets/snapattack-logo.svg';
import { ReactComponent as SplunkName } from '../assets/splunk-name.svg';
import { ReactComponent as Splunk } from '../assets/splunk.svg';
import { ReactComponent as SumoLogic } from '../assets/sumologic.svg';
import { ReactComponent as Trellix } from '../assets/trellix.svg';
import { ReactComponent as USMAnywhere } from '../assets/usm-anywhere.svg';
import { ReactComponent as Vmware } from '../assets/vmware.svg';
import { ReactComponent as Zoho } from '../assets/zoho.svg';

// Badass logo
export const saSnapAttackIcon = <SnapAttackIcon title='SnapAttack icon' />;
export const saSnapAttackLogo = <SnapAttackLogo title='SnapAttack logo' />;

// Misc
export const saMitreAttack = <MitreAttack title='mitre attack logo' />;

// Platforms / Operating Systems
export const saAWS = <AWS title='amazon web services logo' />;
export const saGoogleCloud = <GoogleCloud title='google cloud logo' />;
export const saKaliLinux = <KaliLinux title='kali linux logo' />;
export const saLinuxTux = <LinuxTux title='linux tux logo' />;
export const saMicrosoftAzure = <MicrosoftAzure title='MicrosoftAzure logo' />;
export const saWindows = <Windows title='windows logo' />;

// Commando VM
// Other Windows

// Integrations
export const saCarbonBlackCloud = <CarbonBlackCloud title='carbon-black-cloud logo' />;
export const saCarbonBlackEndpoint = <CarbonBlackEndpoint title='carbon-black-endpoint logo' />;
export const saCortex = <Cortex title='cortex logo' />;
export const saCrowdStrike = <CrowdStrike title='crowdstrike logo' />; // for use with Falcon EDR and Falcon Logscale
export const saLogScale = <CrowdStrike title='crowdstrike logo' />;
export const saDevo = <Devo title='devo logo' />; // TODO: make white -> grey if on light background
export const saElastic = <Elastic title='elastic logo' />; // for use with Elastic Search, ElastAlert
export const saElastAlert = saElastic;
export const saChronicle = <GoogleChronicle title='google chronicle logo' />;
export const saGoogleSecOps = <GoogleSecOps title='google security operations logo' />;
export const saGit = <Git title='git logo' />;
export const saMicrosoftDefender = <MicrosoftDefender title='microsoft defender logo' />;
export const saMicrosoftSentinel = <MicrosoftSentinel title='microsoft sentinel logo' />;
export const saPaloAlto = <PaloAlto title='palo alto logo' />;
export const saQRadar = <QRadar title='qradar logo' />;
export const saSigma = <Sigma title='sigma logo' />;
export const saSigmaLight = <SigmaLight title='sigma light logo' />;
export const saSentinelOne = <SentinelOne title='sentinel one logo' />;
export const saSplunk = <Splunk title='splunk logo' />;
export const saSplunkName = <SplunkName title='splunk named logo' />;
export const saSumoLogic = <SumoLogic title='sumo logic logo' />;
export const saTrellix = <Trellix title='trellix logo' />; // TODO: make white -> grey if on light background
export const saUSMAnywhere = <USMAnywhere title='usm anywhere logo' />;
const CustomerManagedIcon = styled(CustomerManaged)`
  opacity: 0.5;
`;
export const saCustomerManaged = <CustomerManagedIcon title='custom integration logo' />;

export const IntegrationIcons = {
  saCarbonBlackCloud,
  saCarbonBlackEndpoint,
  saCortex,
  saCrowdStrike,
  saCustomerManaged,
  saDevo,
  saElastic,
  saElastAlert,
  saChronicle,
  saGoogleSecOps,
  saGit,
  saLogScale,
  saMicrosoftDefender,
  saMicrosoftSentinel,
  saPaloAlto,
  saQRadar,
  saSigma,
  saSigmaLight,
  saSentinelOne,
  saSplunk,
  saSumoLogic,
  saTrellix,
  saUSMAnywhere,
  saSnapAttackIcon
};

export function IntegrationIcon({ type }: { type: string }): JSX.Element {
  return IntegrationIcons[`sa${type}`] ?? saCustomerManaged;
}

// Mandiant
export const saMandiant = <Mandiant title='mandiant logo' />;

// Red Canary
export const saRedCanary = <RedCanary title='red canary logo' />;

// Applications
export const saAtlassian = <Atlassian title='atlassian logo' />;
export const saAdobe = <Adobe title='adobe logo' />;
export const saAdobeSquare = <AdobeSquare title='adobe logo' />;
export const saApache = <Apache title='apache logo' />;
export const saApacheSquare = <ApacheSquare title='apache logo' />;
export const saCisco = <Cisco title='cisco logo' />;
export const saCitrix = <Citrix title='citrix logo' />;
export const saF5 = <F5 title='f5 logo' />;
export const saFortinet = <Fortinet title='fortinet logo' />;
export const saGoogleWorkspace = <GoogleWorkspace title='google workspace logo' />;
export const saIvanti = <Ivanti title='ivanti logo' />;
export const saMicrosoftOffice365 = <MicrosoftOffice365 title='microsoft-office-365 logo' />;
export const saOracle = <Oracle title='oracle logo' />;
export const saMicrosoft = <Microsoft title='microsoft logo' />;
export const saVmware = <Vmware title='vmware logo' />;
export const saZoho = <Zoho title='zoho logo' />;
