import React from 'react';

import { PieChartProps } from 'snap-ui/Charts/PieChart';
import Grid from 'snap-ui/Grid';
import { MultiProgress } from 'snap-ui/LinearProgress';
import Placeholder from 'snap-ui/Placeholder';
import { theme } from 'snap-ui/theme';
import { styled } from 'snap-ui/util';

import DashboardGrid from '../core/DashboardGrid';
import { NistCoverage } from './NistDashboard.type';

export const NistDashboardContainer = styled('div')`
  .Dashboard-header {
    align-items: end;
  }

  .place-holder {
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(4)};
  }
  .k-pdf-export {
    * {
      color: ${p => p.theme.palette.common.black} !important;
      text-shadow: none !important;
      box-shadow: none;
      text-overflow: initial !important;
      overflow: visible !important;
    }

    .MuiPaper-root {
      border: 2px solid ${p => p.theme.palette.common.black};
      background-color: ${p => p.theme.palette.common.white};
    }

    thead,
    tr {
      background-color: ${p => p.theme.palette.common.white};
    }

    .MuiDataGrid-root .MuiDataGrid-columnHeaders {
      background: none;
    }

    .no-print {
      display: none !important;
    }

    .dataGrid-row:last-child {
      border-right: 1px solid ${p => p.theme.palette.common.black};
    }
  }
  .dataGrid-row:last-child {
    border-right: none;
  }
`;

export const NistTableContainer = styled(DashboardGrid)`
  .MuiDataGrid-main {
    div:nth-of-type(2) {
      overflow: visible !important;
      .MuiDataGrid-virtualScrollerRenderZone {
        position: relative !important;
      }
    }
  }
  h2 {
    padding: ${p => p.theme.spacing(0, 3, 3, 3)};
  }
  .data-grid {
    min-height: 65vh;
  }
  .dataGrid-row {
    border-right: 1px solid rgba(81, 81, 81, 1);
  }

  .control-name {
    height: 100%;
    width: 100%;
    cursor: pointer;
    display: flex;
    margin: auto;
    align-items: center;
  }

  .deployed {
    a {
      color: ${p => p.theme.palette.common.black};
    }
    &.Risk {
      background-color: ${p => p.theme.palette.error.main};
    }
    &.Improving {
      background-color: ${p => p.theme.palette.warning.main};
    }
    &.Covered {
      background-color: ${p => p.theme.palette.lime.dark};
    }
  }

  .nistToolbar {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: ${p => p.theme.spacing(2, 3, 2, 3)};
  }

  .tag-description {
    grid-column: 1 / span 2;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const PieChartDefaultProperties: Partial<PieChartProps> = {
  useLegend: true,
  showValueInLegend: true,
  useTooltip: true,
  legendProps: {
    layout: 'horizontal',
    verticalAlign: 'bottom',
    align: 'center'
  }
};

export const Progress = styled(MultiProgress)`
  min-width: 100px;
  height: 24px;

  .coverage-value-text {
    color: ${p => p.theme.palette.common.black};
  }
`;

export const NistCoverageColors = {
  [NistCoverage.Covered]: theme.palette.lime.dark,
  [NistCoverage.Improving]: theme.palette.warning.main,
  [NistCoverage.Risk]: theme.palette.error.main
};

export const SubTitle = styled('div')`
  font-weight: bold;
`;

export const Section = styled('div')`
  display: flex;
  flex-direction: column;
  padding: ${p => p.theme.spacing(4, 6, 4, 6)};

  .summary {
    padding-bottom: ${p => p.theme.spacing(4)};
  }
`;
export function NistDashboardPlaceHolder() {
  return (
    <>
      <DashboardGrid>
        <Grid className='place-holder' item xs={12} md={12} lg={6}>
          <Placeholder variant='rectangular' width={625} height={500} />
        </Grid>
        <Grid className='place-holder' item xs={12} md={12} lg={6}>
          <Placeholder variant='rectangular' width={625} height={500} />
        </Grid>
      </DashboardGrid>
      <DashboardGrid>
        <Grid className='place-holder' item xs={12} md={12} lg={12}>
          <Placeholder variant='rectangular' width={1300} height={100} />
          <Placeholder variant='rectangular' width={1300} height={50} />
          <Placeholder variant='rectangular' width={1300} height={50} />
          <Placeholder variant='rectangular' width={1300} height={50} />
          <Placeholder variant='rectangular' width={1300} height={50} />
          <Placeholder variant='rectangular' width={1300} height={50} />
          <Placeholder variant='rectangular' width={1300} height={50} />
        </Grid>
      </DashboardGrid>
    </>
  );
}
