import React from 'react';

import { faSquareUp } from '@fortawesome/pro-regular-svg-icons';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';

import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import {
  ArtifactWidgetFooter,
  Author,
  BurgerCancel,
  BurgerPublish,
  BurgerReset,
  Menu,
  Publish,
  Title
} from 'module/Layout/Artifact.widgets';

import { Session } from '../Session.type';
import { SessionCreateForm } from './SessionCreate.type';

type Props = {
  isPendingMachineState: boolean;
  ownerId: number;
  onCancel(): void;
};

export default function SessionHeader(props: Props) {
  const { dirty, values, resetForm, ...others } = useFormikContext<SessionCreateForm>();
  const isMachinePending = props.isPendingMachineState;
  return (
    <>
      <Title headerText={values.name || 'Captured Threat'} />
      <Author
        resource={{ created_by_id: props.ownerId, organization_id: +values.organization_id } as unknown as Session}
        type='session'
      />
      <ArtifactWidgetFooter>
        <div />
        <div className='ArtifactWidget-menuWrapper'>
          <Tooltip
            arrow
            wrap
            placement='left'
            title={isMachinePending ? 'Processing threat capture - please wait...' : null}
          >
            <Publish
              onClick={async () => {
                others.setTouched(
                  {
                    organization_id: true,
                    name: true,
                    description: true,
                    capture: true
                  },
                  false
                );
                const errors = await others.validateForm();
                if (isEmpty(errors)) others.submitForm();
                else errors;
              }}
              disabled={isMachinePending}
              startIcon={isMachinePending ? <Icon.SpinnerProgress /> : <Icon icon={faSquareUp} />}
            >
              Publish
            </Publish>
          </Tooltip>
          <Menu dirty={dirty}>
            <BurgerCancel onClick={props.onCancel} />
            <BurgerPublish
              onClick={async () => {
                others.setTouched(
                  {
                    organization_id: true,
                    name: true,
                    description: true,
                    capture: true
                  },
                  false
                );
                const errors = await others.validateForm();
                if (isEmpty(errors)) others.submitForm();
                else errors;
              }}
              disabled={props.isPendingMachineState}
            />
            <BurgerReset onClick={resetForm} disabled={!dirty} />
          </Menu>
        </div>
      </ArtifactWidgetFooter>
    </>
  );
}
