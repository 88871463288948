import { CeleryTaskResponse, Guid, Ident, TaskStatus } from 'types/common';

import { MimeType } from '../Export';

export type ImportForm = {
  file: Blob[];
  format: MimeType;
  organization_id: Ident;
  integration_id: Guid;
  reimport: boolean;
  deployable: boolean;
};

export type ImportPayload = {
  large_image?: string;
  small_image?: string;
  organization_id: Ident;
  integration_id: Guid;
  analytic_compilation_target_id?: number;
  import_source?: string;
  deployable?: boolean;
  reimport: boolean;
  license_url?: string;
};

export enum ImportEndpointPrefix {
  AttackScript = '/harbor/bas/script/import',
  Analytic = '/harbor/signatures/import'
}

export enum ImportJobState {
  idle = 'IDLE', // Non celery state
  pending = 'PENDING',
  success = 'SUCCESS',
  failed = 'FAILED',
  retrying = 'RETRYING',
  cancelled = 'CANCELED' // Non celery state
}

export type ImportJobStateOutputResponse = {
  output: {
    total: number;
    new: number;
    updated: number;
    deleted: number;
    failed: number;
    errors: any[];
  };
};
export type ImportResponse = CeleryTaskResponse;

export type ImportStatus = TaskStatus<ImportJobStateOutputResponse, ImportJobState>;

export type DetectionImporterModal = {
  isOpen: boolean;
  integration_id: string;
  organization_id: Ident;
  onClose(): void;
};
