import React, { ReactElement } from 'react';

import { faExpandArrows, faArrowsToCircle } from '@fortawesome/pro-regular-svg-icons';
import { faSearch, faSliders } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import size from 'lodash/size';

import { Autocomplete, getDisplayValue } from 'snap-ui/Autocomplete';
import { ActionIconButton } from 'snap-ui/Button';
import { DisplayDialog } from 'snap-ui/Dialog';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { SEARCH_FIELDS } from 'constants/processGraph';

import { Marker } from 'types/marker';
import { Zoom } from 'types/progressGraph';

import { renderValueWithLabel } from 'utilities/TextUtils';

import GraphFilters from './GraphFilters';

type GraphHeaderProps = {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  searchField: string;
  setSearchField: (value: string) => void;
  searchData: { label: string; value: string }[];
  setZoom: (zoomDirection: Zoom, data) => void;
  maxTime: number;
  minTime: number;
  setTime: (min: number, max: number) => void;
  sessionStart: number;
  sessionEnd: number;
  sidebarOpened: boolean;
  collapseNodes: boolean;
  setCollapseNodes: (value: boolean) => void;
  totalNodes: number;
  unfilteredNodes: number;
  marker: Marker[];
  searchCount: number;
  isLoading: boolean;
};

const ActionButton = styled(ActionIconButton)`
  font-size: 1.5rem;
  color: ${p => p.theme.palette.grey[400]};
`;

const GraphHeaderContainer = styled('div', { name: 'GraphHeader' })`
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 40px;
  display: flex;
  &.opened {
    margin-right: var(--sidebar-width);
  }

  .graph-search-results {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 40px;
  }

  .graph-search-field {
    width: 280px;
    div.text {
      width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .menu {
      max-height: 19.35rem;
    }
  }
`;

const SearchControlsContainer = styled('div', { name: 'GraphHeader-search' })`
  display: flex;
  gap: ${p => p.theme.spacing(3)};
`;

const ZoomControlsContainer = styled('div', { name: 'GraphHeader-zoom' })`
  display: flex;
  margin-left: auto;
  padding-right: 10px;
  align-items: center;
`;

const searchFieldOptions = Object.values(SEARCH_FIELDS);

const GraphHeader = ({
  searchQuery,
  setSearchQuery,
  searchField,
  setSearchField,
  searchData,
  setZoom,
  maxTime,
  minTime,
  setTime,
  sessionStart,
  sessionEnd,
  sidebarOpened,
  marker,
  collapseNodes,
  setCollapseNodes,
  totalNodes,
  unfilteredNodes,
  searchCount,
  isLoading
}: GraphHeaderProps): ReactElement => {
  const [filterOpen, setFilterOpen] = React.useState(false);

  const searchQueryOptions = React.useMemo(
    () =>
      searchData
        .filter(d => d.value !== undefined)
        .map(item => ({
          key: item.value,
          value: item.value,
          content: item.label
        })),
    [searchData]
  );

  function handleSearchSelection(value): void {
    setSearchField(value);
  }

  function handleSearchQuerySelection(value): void {
    setSearchQuery(value);
  }

  function renderSearchField(): ReactElement {
    const dropdown = (
      <Autocomplete
        name='search-query'
        onChange={handleSearchQuerySelection}
        className='graph-search-field'
        options={searchQueryOptions}
        value={getDisplayValue(searchQueryOptions, searchQuery)}
        loading={isLoading}
        disableUserAdditions
        startAdornment={<Icon icon={faSearch} />}
        placeholder='Search'
      />
    );
    if (size(searchQuery) > 25) {
      return (
        <Tooltip arrow title={searchQuery}>
          {dropdown}
        </Tooltip>
      );
    }
    return dropdown;
  }

  function renderSearchCount(): ReactElement {
    if (searchField && searchQuery) {
      return (
        <div className='graph-search-results'>
          <Typography variant='h5'>{renderValueWithLabel(searchCount, 'Result')}</Typography>
        </div>
      );
    }
    return null;
  }

  return (
    <GraphHeaderContainer
      className={classNames({
        opened: sidebarOpened
      })}
    >
      <SearchControlsContainer>
        <Autocomplete
          name='search-field'
          value={getDisplayValue(searchFieldOptions, searchField)}
          onChange={handleSearchSelection}
          className='graph-search-field'
          options={searchFieldOptions}
          disableClearable
          disableUserAdditions
        />
        {renderSearchField()}
        <Tooltip arrow title='Filter nodes' placement='bottom'>
          <ActionButton
            onClick={(): void => setFilterOpen(!filterOpen)}
            icon={faSliders}
            aria-label='graph filter button'
          />
        </Tooltip>
      </SearchControlsContainer>
      {renderSearchCount()}
      <ZoomControlsContainer>
        <Tooltip arrow title='Fit to screen' placement='top'>
          <ActionButton
            icon={faExpandArrows}
            aria-label='Fit to screen'
            onClick={(): void => setZoom(Zoom.FIT, null)}
          />
        </Tooltip>
        <Tooltip arrow title='Focus on selected node' placement='top'>
          <ActionButton
            icon={faArrowsToCircle}
            aria-label='Fit to screen'
            onClick={(): void => setZoom(Zoom.FOCUS, null)}
          />
        </Tooltip>
      </ZoomControlsContainer>
      <DisplayDialog
        DialogProps={{ open: filterOpen, onClose: () => setFilterOpen(false) }}
        title='Adjust Graph Filters'
      >
        <GraphFilters
          minTime={minTime}
          maxTime={maxTime}
          setTime={setTime}
          sessionStart={sessionStart}
          sessionEnd={sessionEnd}
          collapseNodes={collapseNodes}
          setCollapseNodes={setCollapseNodes}
          totalNodes={totalNodes}
          unfilteredNodes={unfilteredNodes}
          marker={marker}
        />
      </DisplayDialog>
    </GraphHeaderContainer>
  );
};

export default GraphHeader;
