import { snapattack } from 'apis/snapattack';

import { User } from 'types/auth';
import { Guid, Identity } from 'types/common';

const prefix = '/auth';

export function impersonate(user: Guid): Promise<void> {
  return snapattack.put(`${prefix}/impersonate/user/${user}/`);
}

export function impersonateOrg(org: Guid): Promise<void> {
  return snapattack.put(`${prefix}/impersonate/org/${org}/`);
}

export function unpersonate(): Promise<void> {
  return snapattack.put(`${prefix}/unpersonate/`);
}

export function getAvailableOrgs(): Promise<Identity[]> {
  return snapattack.get('/auth/impersonate/org/available/').then(r => r.data);
}

export function getAvailableUsers(): Promise<User[]> {
  return snapattack.get('/auth/impersonate/user/available/').then(r => r.data);
}
