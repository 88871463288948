import React from 'react';

import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';

import Accordion, { AccordionDetails, AccordionSummary } from 'snap-ui/Accordion';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';

import { MarkdownRead } from 'module/Widgets/Markdown';

import { useLandingCatalog } from '../../LandingProvider';

export default function VulnerabilityAnalysis() {
  const { source } = useLandingCatalog();

  if (!source.analysis) return null;
  return (
    <Accordion className='LandingSection-accordion' defaultExpanded={!!source.analysis} elevation={0}>
      <AccordionSummary className='LandingSection-accordionSummary' expandIcon={<Icon icon={faCaretUp} />}>
        <Typography variant='h4'>Analysis</Typography>
      </AccordionSummary>
      <AccordionDetails className='LandingSection-accordionDetails'>
        <MarkdownRead className='Landing-markDownRead' value={source.analysis} />
      </AccordionDetails>
    </Accordion>
  );
}
