import React from 'react';

import pick from 'lodash/pick';

import { BuffAlert } from 'snap-ui/Alert';
import CircularProgress from 'snap-ui/CircularProgress';
import { FormDialog } from 'snap-ui/Dialog';
import { styled } from 'snap-ui/util';

import { Status } from 'storage';

import { BASAgent, BASAgentEditableFields } from 'types/bas';

import AgentMetadata from '../../../BAS/AgentMetadata';
import EditAgentForm from './EditAgentForm';

type EditAgentModalProps = {
  open: boolean;
  agent?: BASAgent;
  error?: string;
  onClose: () => void;
  onSubmit: (values: BASAgentEditableFields) => void;
  status: Status;
};

const AgentMetadataContainer = styled('div')`
  padding: ${p => p.theme.spacing(0, 6)};
`;

export default function EditAgentModal(props: EditAgentModalProps): JSX.Element {
  return (
    <FormDialog
      DialogProps={{ open: !!props.agent && props.open, onClose: props.onClose }}
      FormikConfig={{
        enableReinitialize: true,
        initialValues: pick(props.agent, ['name', 'description']),
        onSubmit: props.onSubmit
      }}
      SubmitProps={{
        children: props.status === Status.pending ? <CircularProgress size={25} /> : 'Save',
        disabled: props.status === Status.pending
      }}
      title='Edit Attack Simulation Agent'
    >
      {props.agent && (
        <AgentMetadataContainer>
          <AgentMetadata agent={props.agent} />
        </AgentMetadataContainer>
      )}
      {props.status === Status.rejected && <BuffAlert severity='error'>{props.error}</BuffAlert>}
      <EditAgentForm />
    </FormDialog>
  );
}
