import React from 'react';

import { useLocation } from 'react-router-dom';

import { EIGHTS_TRUTH, FOURS_TRUTH, ONES_TRUTH, SIXTEENS_TRUTH, TWOS_TRUTH } from 'constants/common';

import { DEFAULT_MATRIX_DISPLAY } from 'module/Scaffold/Scaffold.const';
import { hydratedMatrixDisplayOptions } from 'module/Scaffold/Scaffold.service';
import { MatrixDisplay, MatrixPaletteOrdinal } from 'module/Scaffold/Scaffold.type';

import { HeatMap } from './Matrix.type';

export default function useMatrixOptions() {
  const { search } = useLocation();
  const [params, setParams] = React.useState<MatrixDisplay>({
    display: DEFAULT_MATRIX_DISPLAY,
    palette: MatrixPaletteOrdinal.Traffic,
    heatMap: HeatMap.Detection,
    bounds: 5
  });

  React.useEffect(() => {
    const h = hydratedMatrixDisplayOptions(search);
    if (
      params.bounds !== h.bounds ||
      params.display !== h.display ||
      params.palette !== h.palette ||
      params.heatMap !== h.heatMap
    )
      setParams(h);
  }, [params, search]);

  return React.useMemo(
    () => ({
      showMitreId: ONES_TRUTH.includes(params.display),
      showCount: TWOS_TRUTH.includes(params.display),
      showEmpty: FOURS_TRUTH.includes(params.display),
      showSubtechniques: EIGHTS_TRUTH.includes(params.display),
      showPreAttack: SIXTEENS_TRUTH.includes(params.display),
      palette: params.palette,
      heatMap: params.heatMap,
      maxRange: params.bounds,
      perBucket: 1
    }),
    [params]
  );
}
