import React from 'react';

import pick from 'lodash/pick';

import { FormDialog } from 'snap-ui/Dialog';
import FormControl from 'snap-ui/FormControl';
import { FieldsLayout } from 'snap-ui/Layout';
import { styled } from 'snap-ui/util';

import { COMMUNITY_ORGANIZATION } from 'constants/auth';

import FormikFieldError from 'module/Form/FormikFieldError';
import { useIDEState } from 'module/IDE';
import LogsourceField from 'module/IDE/core/DetectionForm/LogsourceField';
import NativeAnalyticForm from 'module/IDE/core/DetectionForm/NativeAnalyticForm';
import DescriptionField from 'module/IDE/core/MetadataForm/DescriptionField';
import OrganizationField from 'module/IDE/core/MetadataForm/OrganizationField';
import TitleField from 'module/IDE/core/MetadataForm/TitleField';
import { getRequiredFields, validate } from 'module/IDE/reducer';

import { AnalyticForm } from 'types/analytic';

type AnalyticFormFragment = Partial<Pick<AnalyticForm, 'title' | 'description' | 'logsource' | 'languageId'>> & {
  raw: string;
};

interface RequiredFieldsModalProps {
  handleCancel(): void;
  handleSubmit(): void;
  isOpen: boolean;
}

const emptyForm = {
  title: '',
  description: '',
  logsource: {} as AnalyticForm['logsource'],
  raw: '',
  languageId: 0,
  organization_id: COMMUNITY_ORGANIZATION.id
};

const StyledFieldsLayout = styled(FieldsLayout)`
  gap: ${p => p.theme.spacing(5)};
  padding-top: 0;

  .error {
    color: ${p => p.theme.palette.error.main};
    margin-top: 0;
  }
`;

export default function RequiredFieldsModal({
  handleCancel,
  handleSubmit,
  isOpen
}: RequiredFieldsModalProps): JSX.Element {
  const { ideState } = useIDEState();
  const requiredFields = getRequiredFields(ideState.analyticForm.languageId);
  const initialValues: AnalyticFormFragment = {
    ...emptyForm,
    ...pick(ideState.analyticForm, Object.keys(emptyForm)),
    raw: ideState.raw
  };

  return (
    <FormDialog
      className='RequiredFieldsModal'
      DialogProps={{ open: isOpen, onClose: handleCancel }}
      FormikConfig={{
        initialValues: initialValues,
        onSubmit: handleSubmit,
        validate: () => validate({ ...ideState.analyticForm, raw: ideState.raw }),
        validateOnBlur: false
      }}
      SubmitProps={{
        children: 'Save Detection'
      }}
      title='Please complete these required fields before saving'
    >
      <StyledFieldsLayout>
        {requiredFields.includes('raw') && (
          <FormControl>
            <NativeAnalyticForm />
            <FormikFieldError name='raw' />
          </FormControl>
        )}
        {requiredFields.includes('logsource') && (
          <FormControl>
            <LogsourceField />
            <FormikFieldError name='logsource' />
          </FormControl>
        )}
        {requiredFields.includes('organization_id') && (
          <FormControl>
            <OrganizationField />
            <FormikFieldError name='organization_id' />
          </FormControl>
        )}
        {requiredFields.includes('title') && (
          <FormControl>
            <TitleField />
            <FormikFieldError name='title' />
          </FormControl>
        )}
        {requiredFields.includes('description') && (
          <FormControl>
            <DescriptionField />
            <FormikFieldError name='description' />
          </FormControl>
        )}
      </StyledFieldsLayout>
    </FormDialog>
  );
}
