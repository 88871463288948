import React from 'react';

import PieChart from 'snap-ui/Charts/PieChart';
import Grid from 'snap-ui/Grid';
import { styled, useTheme } from 'snap-ui/util';

import ChartPanel from '../core/ChartPanel';
import DashboardGrid from '../core/DashboardGrid';
import { nistIs } from './NistDashboard.helper';
import { PieChartDefaultProperties } from './NistDashboard.style';
import { NistArtifact, NistCoverage } from './NistDashboard.type';
import NistFamilyCoverage from './NistFamilyCoverage';

const Container = styled(DashboardGrid)`
  .GridItem {
    padding-left: 0;
  }
`;

type NistActivityProp = {
  nist: NistArtifact[];
};
function NistActivity({ nist }: NistActivityProp) {
  const { palette } = useTheme();

  const nistControlIdCoverage = [
    {
      name: NistCoverage.Covered,
      value: nist.filter(id => Number(nistIs(id.detections_deployed, NistCoverage.Covered))).length
    },
    {
      name: NistCoverage.Improving,
      value: nist.filter(id => Number(nistIs(id.detections_deployed, NistCoverage.Improving))).length
    },
    {
      name: NistCoverage.Risk,
      value: nist.filter(id => Number(nistIs(id.detections_deployed, NistCoverage.Risk))).length
    }
  ];
  return (
    <Container>
      <Grid item xs={12} md={12} lg={6} className='GridItem'>
        <ChartPanel title='Overall NIST Controls Coverage'>
          <PieChart
            {...PieChartDefaultProperties}
            height={670} // magic number based on height of Controls chart to right
            outerRadius={'75%'}
            data={nistControlIdCoverage}
            colors={[palette.success, palette.warning, palette.red]}
          />
        </ChartPanel>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <ChartPanel title='NIST Controls Coverage by Family'>
          <NistFamilyCoverage nist={nist} />
        </ChartPanel>
      </Grid>
    </Container>
  );
}

export default NistActivity;
