import React from 'react';

import { faExternalLinkAlt, faPaperclip } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';

import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { downloadAttachment } from 'module/Intel/Intel.api';

import { File, Named, Tracked } from 'types/common';

import { downloadFile } from 'utilities/FileUtils';

type ReferenceProps = {
  reference: (string | (Tracked & Named))[];
  file?: File[];
  className?: string;
  title?: string;
  children?: JSX.Element;
};

const ReferenceContainer = styled('div')`
  margin: ${p => p.theme.spacing(6, 0)};

  & h4 {
    margin-top: 0;
    margin-bottom: ${p => p.theme.spacing(2)};
  }

  & ul {
    margin: 0;
    padding-inline-start: 0;
  }

  & li {
    list-style-type: none;
    word-break: break-word;

    &:not(:last-child) {
      margin-bottom: 1em;
    }

    & span {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
`;

export default function Reference({ title = 'References', ...others }: ReferenceProps) {
  const reference = others.reference || [];
  const file: File[] = others.file || [];

  if (!others.children && reference.length + file.length === 0) return null;

  return (
    <ReferenceContainer className={classnames('Reference', others.className)}>
      <Typography variant='h4'>{title}</Typography>
      <ul>
        {reference.map((r, index) => {
          return typeof r === 'string' ? (
            <ExternalRefLink key={index} url={r} />
          ) : (
            <CTILink key={index} reference={r} />
          );
        })}
        {file.map(f => (
          <FileLink key={f.guid} file={f} />
        ))}
        {others.children}
      </ul>
    </ReferenceContainer>
  );
}

function ExternalRefLink({ url }: { url: string }) {
  const safe: string = sanitizeHtml(url);
  return (
    <li>
      <span>
        <Icon icon={faExternalLinkAlt} />
        <a href={safe} target='_blank' rel='noopener noreferrer'>
          {safe}
        </a>
      </span>
    </li>
  );
}

function CTILink({ reference }: { reference: Tracked & Named }) {
  return (
    <li>
      <span>
        <Icon.Intel />
        <Link to={`${Path.Intelligence}/${reference.guid}`} target='_blank' rel='noopener noreferrer'>
          {reference.name ? reference.name : reference.guid}
        </Link>
      </span>
    </li>
  );
}

function FileLink({ file }: { file: File }) {
  return (
    <li>
      <span>
        <Icon icon={faPaperclip} />
        <Link to='#' className='link' onClick={() => downloadReference(file)}>
          {file.file_name}
        </Link>
      </span>
    </li>
  );
}

function downloadReference(file: File): void {
  downloadAttachment(file.guid).then(r => {
    downloadFile(new Blob([r]), file.file_name);
  });
}
