import React from 'react';

import { Alert, AlertTitle } from 'snap-ui/Alert';

import { RegisterResponse, RegisterStatus } from 'types/auth';

const message = {
  [RegisterStatus.Invite]:
    'Thanks for signing up, we will let you know when the SnapAttack Community Edition becomes available.',
  [RegisterStatus.Approval]: 'Your account registration is still pending admin approval.',
  [RegisterStatus.Verification]: 'Your E-mail address is still pending verification.'
};

type Props = {
  response: RegisterResponse;
};

export default function RegisterMessage(props: Props) {
  return props.response ? (
    <Alert severity='success'>
      <AlertTitle>{header()}</AlertTitle>
      {message[props.response.status]}
    </Alert>
  ) : null;

  function header() {
    switch (props.response.status) {
      case RegisterStatus.Invite:
        return 'Awaiting Invite';
      case RegisterStatus.Approval:
        return 'Awaiting Approval';
      case RegisterStatus.Verification:
        return 'Awaiting Verification';
      case RegisterStatus.Active:
        return '';
    }
  }
}
