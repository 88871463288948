import React from 'react';

import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';

import Accordion, { AccordionSummary } from 'snap-ui/Accordion';
import { DataGrid, GridColDef, GridToolbarFilterButton, GridValidRowModel } from 'snap-ui/DataGrid';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';

import { Discriminator } from 'module/Tag';

import { useLandingCatalog } from '../../LandingProvider';

export default function VulnerabilitySoftwareVersion() {
  const { source, type } = useLandingCatalog();

  if (type !== Discriminator.Vulnerability || isEmpty(source.vulnerable_cpes)) return null;
  const columns: GridColDef<GridValidRowModel>[] = [
    {
      field: 'vendor_name',
      headerName: 'Vendor',
      flex: 1
    },
    {
      field: 'cpe_title',
      headerName: 'Version',
      flex: 1.5
    }
  ];
  return (
    <Accordion className='LandingSection-accordion' defaultExpanded elevation={0}>
      <AccordionSummary className='LandingSection-accordionSummary' expandIcon={<Icon icon={faCaretUp} />}>
        <Typography variant='h4'>Vulnerable Software Version</Typography>
      </AccordionSummary>
      <DataGrid
        className='data-grid table-height'
        columns={columns}
        rows={source.vulnerable_cpes}
        disableRowSelectionOnClick
        getRowId={row => row.cpe}
        getRowClassName={p => (p.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        getCellClassName={() => 'dataGrid-row'}
        slots={{ toolbar: () => <GridToolbarFilterButton /> }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'vendor_name', sort: 'asc' }]
          }
        }}
      />
    </Accordion>
  );
}
