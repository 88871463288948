import React from 'react';

import { styled } from 'snap-ui/util';

const Container = styled('div')`
  .overlay-container {
    position: relative;
    isolation: isolate;
    user-select: none;
    pointer-events: none;
    max-height: 50vh;
    overflow: hidden;
  }
  .overlay:after {
    content: '';
    height: 70%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(40, 40, 40, 1));
  }
`;

type Props = {
  preview: boolean;
  children?: React.ReactNode;
};

export default function PayWallTextBlur({ preview: preview, ...props }: Props) {
  return preview ? (
    <Container>
      <div className='overlay-container'>
        <div className='overlay'></div>
        {props.children}
      </div>
    </Container>
  ) : (
    <>{props.children}</>
  );
}
