import React from 'react';

import {
  IconDefinition,
  faAngleDown,
  faAngleUp,
  faAnglesDown,
  faAnglesUp,
  faMinus
} from '@fortawesome/pro-regular-svg-icons';

import Icon from 'snap-ui/Icon/Icon';

export default function WeightedScoreIcon({ score, maxScore }: { score: number; maxScore: number }): JSX.Element {
  const normalizedScore = Math.round((score / maxScore) * 5) / 5;

  let icon: IconDefinition;
  let color: string;

  if (normalizedScore > 0.8) {
    icon = faAnglesUp;
    color = 'red';
  } else if (normalizedScore > 0.6) {
    icon = faAngleUp;
    color = 'red';
  } else if (normalizedScore < 0.2) {
    icon = faAnglesDown;
    color = 'green';
  } else if (normalizedScore < 0.4) {
    icon = faAngleDown;
    color = 'green';
  } else {
    icon = faMinus;
    color = 'grey';
  }

  return <Icon icon={icon} color={color} />;
}
