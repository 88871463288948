import React from 'react';

import { AnalyticReferences } from 'types/analytic';
import { Named, Tracked } from 'types/common';

import Reference from '.';

type Props = {
  data?: Partial<AnalyticReferences>;
};

export default function ReferenceIntel({ data }: Props) {
  const references: string[] = data?.references || [];
  const linked_threat_intelligence: (Named & Tracked)[] = data?.linked_threat_intelligence || [];
  const allReferences = [...references, ...linked_threat_intelligence];

  return <Reference className='ReferenceIntel' reference={allReferences} />;
}
