import React from 'react';

import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';

import Alert from 'snap-ui/Alert';
import Icon from 'snap-ui/Icon';
import PanelFrame, { Props as PanelProps } from 'snap-ui/PanelFrame';
import { styled } from 'snap-ui/util';

import { useDashboardStats } from 'module/Dashboard/DetectionDashboard';

import ChangePercentage from '../ChangePercentage';

export type Props = Partial<PanelProps> & {
  className?: string;
};

function AvailablePanel({ className, title, ...extraProps }: Props) {
  const { available, isPending, isResolved } = useDashboardStats();

  return (
    <PanelFrame
      {...extraProps}
      className={className}
      loading={isPending}
      title={title}
      content={
        isResolved && (
          <div className='available-analytics'>
            <ChangePercentage changePercent={available?.increasePercent} />
            <div className='available-analytics-count'>
              {!!available?.increasePercent && (
                <>
                  <div>{available?.startCount?.toLocaleString() || 0}</div>
                  <Icon className='arrow' icon={faArrowRight} />
                </>
              )}
              {available.stopCount ? (
                <div>{available?.stopCount?.toLocaleString()}</div>
              ) : (
                <Alert severity='info'>No data available for this period.</Alert>
              )}
            </div>
          </div>
        )
      }
    />
  );
}

const StyledAvailablePanel = styled(AvailablePanel)<Props>`
  min-height: 200px;

  .panel-contents {
    padding-top: ${p => p.theme.spacing(2)};
  }

  .available-analytics {
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(4)};
  }

  .available-analytics-count {
    font-size: 3.5rem;
    line-height: 1;
    background-color: ${p => p.theme.palette.common.black};
    color: ${p => p.theme.palette.common.white};
    background-clip: text;
    text-shadow: 2px 2px 4px ${p => p.theme.palette.common.black};

    display: flex;
    justify-content: center;
    gap: ${p => p.theme.spacing(4)};
    align-items: center;

    .arrow {
      font-size: 2.75rem;
    }
  }
`;

export default StyledAvailablePanel;
