import React from 'react';

import { Message } from 'module/Widgets/AssociateArtifactsModal';

export const IDLE_MESSAGE: Message = {
  data: <div key='info'>Add to, remove from, or create a new collection for the given items</div>,
  severity: 'info'
};

export const BUSY_MESSAGE: Message = {
  data: <div key='warning'>Handling your request...</div>,
  severity: 'warning'
};

export const NO_COLLECTIONS_MESSAGE: Message = {
  data: <div key='warning'>There are no collections that you can link to</div>,
  severity: 'warning'
};

export const NO_SELECTION_MESSAGE: Message = {
  data: <div key='warning'>There are no items available to link</div>,
  severity: 'warning'
};
