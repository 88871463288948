import { styled } from 'snap-ui/util';

export const FieldContainer = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(2)};
  align-items: center;

  .input {
    flex: 1;
  }
`;

export const AgentStatusMessage = styled('div')`
  margin-top: 0 !important;
  padding: ${p => p.theme.spacing(0, 2)};
  font-size: 90%;

  [data-icon] {
    margin-right: ${p => p.theme.spacing(1)};
  }
`;
export const PositiveAgentMessage = styled(AgentStatusMessage)`
  color: ${p => p.theme.palette.success.main};
`;

export const NegativeAgentMessage = styled(AgentStatusMessage)`
  color: ${p => p.theme.palette.error.main};
`;
