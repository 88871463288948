import React from 'react';

import { styled, Tooltip as MuiTooltip, tooltipClasses, TooltipProps as MuiTooltipProps } from '@mui/material';
import { PopperProps } from '@mui/material/Popper';

import { StrictReactNode } from 'types/core';

import { Base } from './type';

export type TooltipProps = Base & {
  title: StrictReactNode;
  children: React.ReactElement;
  componentsProps?: object;
  placement?: MuiTooltipProps['placement'];
  followCursor?: boolean;
  enterDelay?: number;
  enterNextDelay?: number;
  arrow?: boolean;
  open?: boolean;
  onOpen?(): void;
  onClose?(): void;
  wrap?: boolean;
  PopperProps?: Partial<PopperProps>;
};

/**
 * Requires forward-able ref
 * https://mui.com/components/tooltips/#custom-child-element
 *
 * if attempting to customize style with `sx`, you must use componentsProps instead. Ex:
 *    componentsProps={{ tooltip: { sx: { padding '42px' } } }}
 * see https://github.com/mui/snap-ui/issues/28679
 *
 * @param title - Zero-length titles string are never displayed.
 * @param wrap - For items that don't accept refs or need to display the tip when children disabled. Wraps children in a span tag
 */
function Tooltip({ children, wrap, title, ...others }: TooltipProps) {
  if (!title) {
    return children;
  }
  const content = wrap ? <span className='Tooltip-wrapper'>{children}</span> : children;
  return (
    <MuiTooltip title={title} {...others}>
      {content}
    </MuiTooltip>
  );
}

export default Tooltip;

export const ErrorTooltip = (props: TooltipProps) => (
  <Tooltip
    PopperProps={{
      sx: ({ palette }) => ({
        '& .MuiTooltip-tooltip': {
          borderColor: `${palette.error.main}`
        },
        '& .MuiTooltip-arrow': {
          color: `${palette.error.main} !important`
        }
      })
    }}
    {...props}
  />
);

export type TooltipConstrainedProps = Omit<TooltipProps, 'wrap'>;
/**
 * With max controlling max-width. Accepts none | number. Defaults to 400
 */
export const TooltipConstrained = styled(
  ({ className, ...props }: TooltipConstrainedProps) => <MuiTooltip {...props} classes={{ popper: className }} />,
  { shouldForwardProp: p => p !== 'max' }
)<TooltipConstrainedProps & { max?: 'none' | number }>(p => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: p.max || 400
  }
}));
