import React from 'react';

import { faBowArrow } from '@fortawesome/pro-solid-svg-icons';

import { Engage, Fingerprint, Widget } from 'lib/Engagement';

import { BurgerClicker } from 'module/Layout/Artifact.widgets';

import { useAuth } from 'provider';

import { checkTaskPermission } from 'services/authService';

import { FunctionalPermission } from 'types/auth';
import { StrictReactNode } from 'types/core';

import { JobType } from '../Job.type';
import { AllowedJobTypes, Hunt } from './Hunt';

type Provider = {
  jobType: AllowedJobTypes;
  children: React.ReactNode;
};
type Button = Partial<Omit<BurgerClicker, 'onClick'>> & {
  children?: (params: { disabled: boolean; onClick(): void }) => StrictReactNode;
};

type Dialog = Omit<Hunt, 'isOpen' | 'onClose' | 'jobType'> & {
  onHunted?: () => void;
};

interface HuntInterface {
  jobType: AllowedJobTypes;
  isOpen: boolean;
  setIsOpen(open: boolean): void;
}

export function huntInterfaceFactory(
  displayName: string
): [React.ComponentType<Provider>, React.ComponentType<Button>, React.ComponentType<Dialog>] {
  const HuntContext = React.createContext<HuntInterface>(null);
  HuntContext.displayName = displayName;

  function HuntInterface({ jobType, children }: { jobType: AllowedJobTypes; children: Provider }): JSX.Element {
    const [isOpen, setIsOpen] = React.useState(false);

    return <HuntContext.Provider value={{ jobType, isOpen, setIsOpen }}>{children}</HuntContext.Provider>;
  }

  function useHunt(): HuntInterface {
    const context = React.useContext(HuntContext);
    if (!context) {
      throw new Error('useHunt must be used within an HuntContext');
    }
    return context;
  }

  function HuntButton({ children, ...props }: Button): JSX.Element {
    const { jobType, setIsOpen } = useHunt();
    const { permission } = useAuth();

    // Fall back to JobType.Hunt; title can be optionally passed in to override
    const title =
      jobType === JobType.Rank ? 'Confidence Tailoring' : jobType === JobType.IOC ? 'Hunt IOCs' : 'Hunt Detections';

    const canExecuteJob = checkTaskPermission(
      permission,
      jobType === JobType.Rank ? FunctionalPermission.CreateAnalyticJob : FunctionalPermission.DetectionHuntFeatures
    );
    const canIOC = checkTaskPermission(permission, FunctionalPermission.IocHunt);

    const notAllowed = !(jobType === JobType.IOC ? canIOC && canExecuteJob : canExecuteJob);

    return (
      <>
        {children ? (
          children({
            disabled: notAllowed,
            onClick: () => setIsOpen(true)
          })
        ) : (
          <BurgerClicker
            title={title}
            aria-label={title}
            icon={faBowArrow}
            onClick={() => {
              Engage.track(
                Fingerprint.of(Widget.Modal)
                  .open()
                  .withQualifier(
                    jobType === JobType.Rank
                      ? 'modify detection confidence'
                      : jobType === JobType.IOC
                      ? 'hunt iocs'
                      : 'hunt detections'
                  )
              );
              setIsOpen(true);
            }}
            disabled={notAllowed}
            TooltipProps={
              notAllowed
                ? {
                    title:
                      'You do not have permission to ' +
                      (jobType === JobType.Rank
                        ? 'modify detection confidence'
                        : jobType === JobType.IOC
                        ? 'hunt IOCs'
                        : 'hunt detections')
                  }
                : undefined
            }
            {...props}
          />
        )}
      </>
    );
  }

  function HuntDialog(props: Dialog) {
    const { jobType, isOpen, setIsOpen } = useHunt();
    return (
      <Hunt
        jobType={jobType}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          props.onHunted && props.onHunted();
        }}
        {...props}
      />
    );
  }

  return [HuntInterface, HuntButton, HuntDialog];
}
