import React from 'react';

import rison from 'rison';
import zod from 'zod';

import { Option } from 'snap-ui/Autocomplete';

import { useLogsourceOptions } from 'provider';

import { ArtifactType } from 'types/common';
import { Ops, Query } from 'types/filter';

import { FilterConfig } from '../GlobalFilter.type';
import InclusiveAutocomplete from './InclusiveAutocomplete';

type LogsourceKeys = {
  logsource: string[];
  logsourceOp: Ops;
};

type LogsourceFilterProps = {
  onChange(args: Partial<LogsourceKeys>): void;
  values: LogsourceKeys;
};

function toQuery(values: LogsourceKeys): Query {
  if (values.logsource?.length) {
    return {
      op: values.logsourceOp,
      items: values.logsource.map(value => {
        let decodedValue;
        try {
          decodedValue = rison.decode(value);
        } catch (e) {
          decodedValue = value;
        }
        return {
          op: Ops.and,
          items:
            typeof decodedValue === 'string'
              ? value.split(' ').map(part => ({
                  op: Ops.contains,
                  field: 'logsource',
                  value: part
                }))
              : Object.entries(decodedValue).map(([field, value]) => ({
                  field,
                  op: Ops.equals,
                  value: value as string
                }))
        };
      })
    };
  }
}

const fromQuery = zod
  .object({
    op: zod.nativeEnum(Ops),
    items: zod.array(
      zod.union([
        zod
          .object({
            op: zod.literal(Ops.and),
            items: zod
              .array(
                zod.object({
                  op: zod.literal(Ops.contains),
                  field: zod.literal('logsource'),
                  value: zod.string()
                })
              )
              .min(1)
              .max(2)
          })
          .transform(query => query.items.map(subItem => subItem.value).join(' ')),
        zod
          .object({
            op: zod.literal(Ops.and),
            items: zod.array(
              zod.object({
                field: zod.enum(['product', 'category', 'service']),
                op: zod.literal(Ops.equals),
                value: zod.string()
              })
            )
          })
          .transform(query =>
            rison.encode(Object.fromEntries(query.items.map(subItem => [subItem.field, subItem.value])))
          )
      ])
    )
  })
  .transform(query => ({
    logsourceOp: query.op,
    logsource: query.items.flat()
  }));

function LogsourceFilter({ onChange, values }: LogsourceFilterProps): JSX.Element {
  const { logsource, logsourceOp } = values;
  const options = useLogsourceOptions();

  function handleValueChange(option: Option[]) {
    const payload: Partial<LogsourceKeys> = { logsource: option.map(o => o.value) };
    if (option.length === 0) payload.logsourceOp = undefined;
    onChange(payload);
  }

  function handleOperatorChange(logsourceOp: Ops) {
    onChange({ logsourceOp });
  }

  return (
    <InclusiveAutocomplete
      allowUserAdditions
      title='By Log Source'
      name='logsource_tags_dropdown'
      option={options}
      value={logsource}
      onValueChange={handleValueChange}
      onOperatorChange={handleOperatorChange}
      operator={logsourceOp}
      disableInclusionGroup
    />
  );
}

const LogsourceFilterConfig: FilterConfig<LogsourceKeys> = {
  defaults: { default: () => ({ logsource: [], logsourceOp: Ops.or }) },
  supportedTopics: [ArtifactType.Analytic],
  component: LogsourceFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default LogsourceFilterConfig;
