import React from 'react';

import { useFormikContext } from 'formik';

import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';

import { ImportEndpointPrefix, ImportForm } from 'module/Import';
import {
  DetectionImporterButton,
  DetectionImporterDialog,
  ImporterInterface
} from 'module/Import/useDetectionImportInterface';

import { IntegrationFormData } from './Integration.type';

function IntegrationManualImport({ integration_id }: Pick<ImportForm, 'integration_id'>) {
  const { values } = useFormikContext<IntegrationFormData>();

  if (!integration_id) return null;

  return (
    <div>
      <Typography variant='h4'>Manual Import</Typography>
      <ImporterInterface>
        <p>Use the .csv template below to import existing native detections for this integration into SnapAttack.</p>
        <div className='actions'>
          <Tooltip arrow title='Download Detection Import Template'>
            <a href={`/api${ImportEndpointPrefix.Analytic}/csv/`} download='snapattack_detection_import_template.csv'>
              Detection Template
            </a>
          </Tooltip>
          <DetectionImporterButton className='DetectionImportControl' variant='outlined' />
        </div>
        <DetectionImporterDialog integration_id={integration_id} organization_id={parseInt(values.organization_id)} />
      </ImporterInterface>
    </div>
  );
}

export default IntegrationManualImport;
