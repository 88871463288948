import React from 'react';

import { faSparkles } from '@fortawesome/pro-regular-svg-icons';

import { FormControlLabel } from 'snap-ui/FormControl';
import Icon from 'snap-ui/Icon';
import Switch from 'snap-ui/Switch';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';

import { useAiFeatures } from 'hooks/useAiFeatures';

import { EditAiDescription } from 'module/Analytic/core/AiDescription/EditAiDescription';
import { MarkdownRead, draftJSToMarkdownRawParser } from 'module/Widgets/Markdown';
import { DescriptionContainer } from 'module/Widgets/OverviewTab/OverviewTab.styled';

import { useUserConfig } from 'provider/UserConfig';

export default function OverviewAIDescription({
  aiDescription,
  description
}: {
  aiDescription?: string;
  description: string;
}) {
  const { showAiDescriptions, setShowAiDescriptions } = useUserConfig();
  const { canUseAiFeatures } = useAiFeatures();
  const hasAiDescription = !!aiDescription;
  const displayedDescription = showAiDescriptions && hasAiDescription ? aiDescription : description;
  const iconColor = showAiDescriptions ? 'primary' : 'stealth';

  return (
    <div>
      <DescriptionContainer>
        <Typography variant='h4' gutterBottom>
          Overview {canUseAiFeatures && showAiDescriptions && <EditAiDescription aiDescription={aiDescription} />}
        </Typography>
        {hasAiDescription && (
          <div className='aiDescription'>
            <FormControlLabel
              control={
                <Switch size='small' checked={showAiDescriptions} onChange={(_, v) => setShowAiDescriptions(v)} />
              }
              label='Show AI Enhanced Description'
            />
            <Tooltip
              arrow
              placement='bottom'
              title={
                showAiDescriptions
                  ? 'This description was created by an AI model and may contain mistakes or inaccuracies. Please verify the information independently before use.'
                  : null
              }
              wrap
            >
              <Icon color={iconColor} icon={faSparkles} />
            </Tooltip>
          </div>
        )}
      </DescriptionContainer>

      <MarkdownRead value={draftJSToMarkdownRawParser(displayedDescription)} />
    </div>
  );
}
