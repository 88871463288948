import React from 'react';

import ShouldLink from 'snap-ui/Charts/components/ShouldLink';
import Paper, { PaperProps } from 'snap-ui/Paper';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

type Props = {
  tooltip?: string;
  children: React.ReactNode;
  to?: string | { pathname: string; search?: string; state?: any };
  className?: string;
};

const CustomPaper = styled(Paper)<PaperProps>`
  display: flex;
  flex-direction: column;
  padding: ${p => p.theme.spacing(4)};
  height: 100%;
  overflow: visible;
`;

function PanelPaper({ tooltip, children, to, className }: Props) {
  return (
    <ShouldLink to={to}>
      <Tooltip arrow title={tooltip}>
        <CustomPaper square elevation={5} className={className}>
          {children}
        </CustomPaper>
      </Tooltip>
    </ShouldLink>
  );
}

export default PanelPaper;
