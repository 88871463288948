import React from 'react';

import classNames from 'classnames';

import { styled } from 'snap-ui/util';

import { LevelContainer } from 'module/Layout/Styled';

import { ArtifactScore, ArtifactScoreDetail } from 'types/common';

const Container = styled('div')`
  display: grid;
  grid-template-columns: auto auto;
  gap: ${p => p.theme.spacing(2)};

  .border-none {
    border: none;
  }
`;

export default function ConfidenceScoreDetails({
  confidenceScoreDetail
}: {
  confidenceScoreDetail: ArtifactScoreDetail;
}) {
  const { is_fallback, rank } = confidenceScoreDetail;
  const organizationConfidence = is_fallback ? ArtifactScore.UNKNOWN : rank;
  const globalConfidence = confidenceScoreDetail?.global_rank || ArtifactScore.UNKNOWN;

  return (
    <Container>
      <div>Organization Confidence:</div>
      <LevelContainer>
        <div className={classNames('level', 'border-none', organizationConfidence)}>{organizationConfidence}</div>
      </LevelContainer>
      <div>SnapAttack Confidence:</div>
      <LevelContainer>
        <div className={classNames('level', 'border-none', globalConfidence)}>{globalConfidence}</div>
      </LevelContainer>
    </Container>
  );
}
