import React from 'react';

import { Link, useHistory } from 'react-router-dom';

import MenuItem from 'snap-ui/MenuItem';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { useAuth } from 'provider';

import { formatQueryString, getQueryParam } from 'utilities/SearchParam';

export const LOGO_WIDTH = 260;

const StyledLink = styled(Link)`
  outline: none;

  &:focus {
    outline: 1px solid ${p => p.theme.palette.primary.main};
  }
`;

const Container = styled(MenuItem)`
  height: 50px;
  max-width: ${LOGO_WIDTH}px;
  min-width: ${LOGO_WIDTH}px;
  flex-shrink: 0;

  outline: none;

  img {
    width: 100%;
    height: 100%;
  }
`;

export default function ToolbarItemLogo() {
  const { location } = useHistory();
  const { isSubscriber, isTraining } = useAuth();
  const query = getQueryParam(location.search, 'query');
  const path = `${Path.Home}${
    query
      ? formatQueryString({
          query
        })
      : ''
  }`;

  let imgSrc = '/images/community.png';
  let imgAltText = 'SnapAttack Community Edition';
  if (isTraining) {
    imgSrc = '/images/training.png';
    imgAltText = 'SnapAttack Training Mode';
  } else if (isSubscriber) {
    imgSrc = '/images/enterprise.png';
    imgAltText = 'SnapAttack Enterprise Edition';
  }

  return (
    <StyledLink to={path} id='ToolbarItemHome' className='LogoToolbarItem' aria-label='SnapAttack Home' tabIndex={0}>
      <Container>
        <img src={process.env.PUBLIC_URL + imgSrc} alt={imgAltText} />
      </Container>
    </StyledLink>
  );
}
