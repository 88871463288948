import React from 'react';

import { faCopy, faEdit, faEllipsisVertical, faTrash } from '@fortawesome/pro-solid-svg-icons';

import Button, { ActionIconButton } from 'snap-ui/Button';
import { MenuTrigger } from 'snap-ui/Menu';
import Paper from 'snap-ui/Paper';
import Table, { TablePlaceholder } from 'snap-ui/Table';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import useDebounce from 'hooks/useDebounce';

import Can from 'module/Can';
import May from 'module/May';
import TableToolbar from 'module/Widgets/TableToolbar';

import { useAuth } from 'provider';

import { SigmaConfigOrder, simpleConfigParse } from 'services/sigmaService';

import { Status } from 'storage';

import { ConfigType, SigmaConfig } from 'types/analytic';
import { ContentPermission, FunctionalPermission } from 'types/auth';
import { Ident } from 'types/common';
import { StrictReactNode } from 'types/core';

import { useConfigControls } from './ConfigProvider';
import { configName, isConfigNotEditable } from './LanguageConfig.util';
import LanguageListMenuItem from './LanguageListMenuItem';

type SigmaConfigListProps = {
  discriminator: ConfigType;
};

function orderLabel(order: string): string {
  switch (order) {
    case SigmaConfigOrder.Generic:
      return 'Generic Log Sources';
    case SigmaConfigOrder.Specific:
      return 'Backend-Specific Configuration';
    default:
      return 'Custom Order';
  }
}

function sortByOrg(defaultOrgId: Ident, a: SigmaConfig, b: SigmaConfig): 1 | -1 | 0 {
  if (a.organization_id === b.organization_id) return 0;
  else if (a.organization_id === defaultOrgId) return -1;
  else if (b.organization_id === defaultOrgId) return 1;
  return 0;
}

function sortByRepoStatus(a: SigmaConfig, b: SigmaConfig): 1 | -1 | 0 {
  const aIsInRepo = a.name === a.sigma_identifier;
  const bIsInRepo = b.name === b.sigma_identifier;
  if (aIsInRepo === bIsInRepo) return 0;
  else if (bIsInRepo) return -1;
  return 1;
}

const NameButton = styled(Button)`
  text-align: left;
  justify-content: left;
`;

const COLUMNS = {
  [ConfigType.Legacy]: ['Name', 'Organization', 'Title', 'Compatible Compilers', 'Order', ''],
  [ConfigType.Pipeline]: ['Name', 'Organization', 'Priority', ''],
  [ConfigType.Validator]: ['Name', 'Organization', '']
};

function includeColumn(configType: ConfigType, column: string, content: StrictReactNode): StrictReactNode {
  if (COLUMNS[configType].includes(column)) return content;
  return undefined;
}

function isPresent(item?: any): boolean {
  return item !== undefined;
}

export default function SigmaConfigList({ discriminator }: SigmaConfigListProps): JSX.Element {
  const { defaultOrgId } = useAuth();
  const { getControls, openDisplay, openEditor, openEditorToClone, openDeleteConfirm } = useConfigControls();
  const configs = React.useMemo(() => getControls(discriminator)?.configs || [], [discriminator, getControls]);
  const status = React.useMemo(() => getControls(discriminator)?.status || [], [discriminator, getControls]);
  const [filterValue, setFilterValue] = React.useState<string>(null);
  const debouncedFilter = useDebounce(filterValue);

  const configsWithYaml = React.useMemo(
    () =>
      configs
        .sort((a, b) => {
          // preferred org's configs float up
          const orgOrder = sortByOrg(defaultOrgId, a, b);
          if (orgOrder) return orgOrder;

          // configs from the sigma repo float down
          const repoOrder = sortByRepoStatus(a, b);
          if (repoOrder) return repoOrder;

          // if all else is equal, sort alphabetically by name
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        })
        .map(config => ({
          ...config,
          parsed: simpleConfigParse(config.raw)[0]
        })),
    [configs, defaultOrgId]
  );

  const parsedConfigs = React.useMemo(
    () =>
      configsWithYaml
        .filter(
          config =>
            config.name !== 'sigma' &&
            (!debouncedFilter ||
              config.name.toLowerCase().includes(debouncedFilter.toLowerCase()) ||
              (config.parsed?.title || '').toLowerCase().includes(debouncedFilter.toLowerCase()))
        )
        .map(config => {
          const isNotEditable = isConfigNotEditable(config);
          return [
            includeColumn(
              discriminator,
              'Name',
              config.raw ? (
                <Tooltip key={config.sigma_identifier} placement='top' title={`View ${config.name}`}>
                  <NameButton onClick={() => openDisplay(discriminator, config.id)} variant='text'>
                    {config.name}
                  </NameButton>
                </Tooltip>
              ) : (
                config.name
              )
            ),
            includeColumn(discriminator, 'Organization', config.organization.name),
            includeColumn(discriminator, 'Title', config.parsed?.title ? String(config.parsed.title) : null),
            includeColumn(
              discriminator,
              'Compatible Compilers',
              Array.isArray(config.parsed?.backends)
                ? config.parsed.backends?.join(', ')
                : config.parsed?.backends
                ? String(config.parsed?.backends)
                : null
            ),
            includeColumn(
              discriminator,
              'Order',
              config.parsed?.order ? `${config.parsed.order} (${orderLabel(config.parsed.order)})` : null
            ),
            includeColumn(discriminator, 'Priority', config.parsed?.priority ?? null),
            includeColumn(
              discriminator,
              '',
              <MenuTrigger
                key={config.id}
                trigger={<ActionIconButton aria-label={`${config.name} Menu`} icon={faEllipsisVertical} />}
              >
                {({ toggle }) => [
                  <Can key='edit' I={ContentPermission.Edit} this={config}>
                    <LanguageListMenuItem
                      disabled={isNotEditable}
                      tooltip={isNotEditable ? "Python configs can't be edited in SnapAttack" : ''}
                      onClick={() => openEditor(discriminator, config.id)}
                      closeMenu={toggle}
                      icon={faEdit}
                      text='Edit'
                    />
                  </Can>,
                  <May key='clone' I={FunctionalPermission.CreateCompilationTarget}>
                    <LanguageListMenuItem
                      disabled={isNotEditable}
                      tooltip={isNotEditable ? "Python configs can't be edited in SnapAttack" : ''}
                      onClick={() => openEditorToClone(discriminator, config.id)}
                      closeMenu={toggle}
                      icon={faCopy}
                      text='Clone'
                    />
                  </May>,
                  <Can key='delete' I={ContentPermission.Delete} this={config}>
                    <LanguageListMenuItem
                      disabled={isNotEditable}
                      tooltip={isNotEditable ? "Python configs can't be deleted in SnapAttack" : ''}
                      onClick={() => openDeleteConfirm(discriminator, config.id)}
                      closeMenu={toggle}
                      icon={faTrash}
                      text='Delete'
                    />
                  </Can>
                ]}
              </MenuTrigger>
            )
          ].filter(isPresent);
        }),
    [configsWithYaml, debouncedFilter, discriminator, openDisplay, openEditor, openEditorToClone, openDeleteConfirm]
  );

  return (
    <>
      <Paper>
        <TableToolbar
          onSearchChange={setFilterValue}
          placeholder={`Filter ${configName(discriminator, { plural: true })} by name`}
        >
          <May I={FunctionalPermission.CreateCompilationTarget}>
            <Button variant='outlined' onClick={() => openEditor(discriminator)}>
              New {configName(discriminator, { capital: true })}
            </Button>
          </May>
        </TableToolbar>
        <Table columns={COLUMNS[discriminator]} rows={parsedConfigs} />
        {status === Status.pending && <TablePlaceholder />}
      </Paper>
    </>
  );
}
