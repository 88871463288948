import React from 'react';

import zod from 'zod';

import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { ArtifactType } from 'types/common';
import { Ops, Query } from 'types/filter';

import { FilterControl } from '../GlobalFilter.style';
import { FilterConfig } from '../GlobalFilter.type';

type HasThreatKeys = {
  hasThreat: string;
};

type HasThreatFilterProps = {
  onChange(values: HasThreatKeys): void;
  values: HasThreatKeys;
};

function HasThreatFilter({ onChange, values }: HasThreatFilterProps): React.ReactElement {
  const booleanValue = values.hasThreat?.toLowerCase() === 'true';

  return (
    <FilterControl>
      <FormLabel id='has-threat-toggle-button-group-label' className='title-tooltip'>
        Has Threat
        <Tooltip title='Filters out Attack Scripts without associated Threat(s).' placement='right' arrow wrap>
          <Icon.Info />
        </Tooltip>
      </FormLabel>
      <FormGroup aria-labelledby='has-threat-toggle-button-group-label'>
        <FormControlLabel
          key='hasThreat'
          control={<Checkbox onChange={handleChange} value='hasThreat' checked={booleanValue} />}
          label='Show Attack Scripts with Threat(s)'
        />
      </FormGroup>
    </FilterControl>
  );

  function handleChange(_e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    onChange({ hasThreat: checked ? String(checked) : undefined });
  }
}

function toQuery(values: HasThreatKeys): Query {
  if (values.hasThreat?.toLowerCase() !== 'true') return;

  return {
    field: 'has_threat_capture',
    op: Ops.equals,
    value: 'true'
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('has_threat_capture'),
    op: zod.literal(Ops.equals),
    value: zod.string()
  })
  .transform(query => ({ hasThreat: query.value.toLowerCase() === 'true' ? 'true' : undefined }));

const HasThreatFilterConfig: FilterConfig<HasThreatKeys> = {
  defaults: { default: () => ({ hasThreat: 'false' }) },
  supportedTopics: [ArtifactType.AttackScript],
  component: HasThreatFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default HasThreatFilterConfig;
