import React from 'react';

import classNames from 'classnames';

import Icon from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

type FormErrorProps = {
  className?: string;
  error: string;
  isVisible: boolean;
  indicator?: boolean;
};

const Container = styled('span')`
  display: inline-flex;
  gap: ${p => p.theme.spacing(1)};
  align-items: center;

  .FormError-message {
    font-size: 0.75rem;
    color: ${p => p.theme.palette.error.main};
    margin-left: ${p => p.theme.spacing(4)};
  }
`;

function FormError({ className, indicator, isVisible, error }: FormErrorProps): React.ReactElement {
  if (!isVisible) return null;
  return (
    <Container className={classNames('FormError', className)}>
      {indicator && <Icon.Error />}
      <span className='FormError-message'>{error}</span>
    </Container>
  );
}

export default FormError;
