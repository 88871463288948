import React from 'react';

import z from 'zod';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import Button from 'snap-ui/Button';
import CircularProgress from 'snap-ui/CircularProgress';
import { FormDialog } from 'snap-ui/Dialog';
import Divider from 'snap-ui/Divider';
import Image from 'snap-ui/Image';
import { FieldsLayout } from 'snap-ui/Layout';
import { ImagePlaceholder } from 'snap-ui/Placeholder';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import {
  DEFAULT_ROLES,
  DEFAULT_ROLE_OPTIONS,
  REGISTRATION_MODES,
  REGISTRATION_MODE_OPTIONS
} from 'constants/organization';

import { standardFormikBaseProps } from 'module/Form';
import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import CheckboxFormik from 'module/Form/CheckboxFormik';
import SelectFormik from 'module/Form/SelectFormik';
import TextFieldFormik from 'module/Form/TextFieldFormik';
import WithTooltip from 'module/Form/WithTooltip';

import { useAuth, useLanguageContext } from 'provider';

import { Status } from 'storage';

import { OrganizationTargetPayload } from 'types/auth';

import { caseInsensitiveStringEqual } from 'utilities/StringUtils';

import { BASE_PACKAGES } from '../Entitlements/Entitlements.const';
import { useEntitlements } from '../Entitlements/Entitlements.service';

type OrgFormDialogProps = {
  isLoading: boolean;
  apiError?: string;
  className?: string;
  initialTarget?: OrganizationTargetPayload;
  onClose(): void;
  onSubmit(values: OrganizationTargetPayload): void;
  open: boolean;
  openFilterDialog(): void;
};

const SHELL: OrganizationTargetPayload = {
  small_image: '',
  large_image: '',
  subscriber: false,
  isolated: false,
  content_provider: false,
  default_roles: [DEFAULT_ROLES.Admin],
  domains: [],
  entitlements: [],
  registration_mode: REGISTRATION_MODES.Invite,
  name: '',
  mandatory_preference: {
    mfa_required: false,
    disable_ai: false
  },
  bulk_rank_active: false,
  detection_author_override: '',
  allow_impersonation: true
};

function OrgFormDialog(props: OrgFormDialogProps): JSX.Element {
  const { user } = useAuth();
  const { entitlements } = useEntitlements();
  const { data: languages, status: languageStatus } = useLanguageContext();
  const [registrationMode, setRegistrationMode] = React.useState(props?.initialTarget?.registration_mode);
  const [smallImage, setSmallImage] = React.useState(props?.initialTarget?.small_image);
  const [largeImage, setLargeImage] = React.useState(props?.initialTarget?.large_image);

  React.useEffect(() => {
    setRegistrationMode(props?.initialTarget?.registration_mode);
    setSmallImage(props?.initialTarget?.small_image);
    setLargeImage(props?.initialTarget?.large_image);
  }, [props.initialTarget]);

  const handleSmallImageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSmallImage(event.target.value);
  };

  const handleLargeImageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLargeImage(event.target.value);
  };

  const entitlementOptions = React.useMemo(() => {
    const filtered = entitlements.filter(entitlement => !BASE_PACKAGES.includes(entitlement.guid));
    return filtered.map(entitlement => ({
      label: entitlement.name,
      value: entitlement.guid,
      content: entitlement.name
    }));
  }, [entitlements]);

  const languageOptionList = languages.map(language => ({
    label: language.name,
    content: language.name,
    value: language.id.toString()
  }));

  const domainOptions = (props?.initialTarget?.domains || []).map(domain => ({
    label: domain,
    content: domain,
    value: domain
  }));

  return (
    <FormDialog
      className={props.className}
      DialogProps={{ open: props.open, onClose: props.onClose }}
      FormikConfig={{
        ...standardFormikBaseProps,
        initialValues: props.initialTarget || SHELL,
        onSubmit: props.onSubmit,
        zodSchema: z.object({
          name: z.string().nonempty('Organization Name is required.'),
          registration_mode: z.string().nonempty('Registration Mode is required.'),
          default_roles: z.array(z.string()).nonempty('Default Roles is required.'),
          small_image: z.string().url().or(z.literal('')),
          large_image: z.string().url().or(z.literal(''))
        })
      }}
      SubmitProps={{
        children: props.isLoading ? <CircularProgress size={25} /> : props.initialTarget ? 'Update' : 'Create',
        disabled: props.isLoading
      }}
      title={props.initialTarget ? `Update ${props.initialTarget.name}` : 'Create New Organization'}
    >
      {!!props.apiError && (
        <Alert severity='error'>
          <AlertTitle>Something went wrong</AlertTitle>
          {props.apiError}
        </Alert>
      )}
      <FieldsLayout>
        <TextFieldFormik label='Name' name='name' disabled={!user.superuser} />

        <Divider />
        <Typography variant='h4'>Security</Typography>
        <WithTooltip
          title={
            <>
              <strong>Invite</strong> - Users must be manually invited by an organization administrator
              <br />
              <strong>Auto</strong> - Users will be automatically added to the organization if they register with one of
              the whitelisted domains below
            </>
          }
        >
          <SelectFormik
            label='Registration Mode'
            name='registration_mode'
            onChange={setRegistrationMode}
            options={REGISTRATION_MODE_OPTIONS}
          />
        </WithTooltip>
        <WithTooltip
          title={
            <>
              The e-mail domains (e.g., <code>gmail.com</code>) that will allow users to automatically be added to your
              organization. Leave blank to disable. Only affects registrations if the registration mode is set to{' '}
              <code>auto</code>.
            </>
          }
        >
          <AutocompleteFormik
            label='Domain Whitelist'
            name='domains'
            multiple
            options={domainOptions}
            disabled={!caseInsensitiveStringEqual(REGISTRATION_MODES.Auto, registrationMode)}
          />
        </WithTooltip>
        <WithTooltip
          title={
            <>
              <strong>User</strong> - Grants read-only access to content
              <br />
              <strong>Contributor</strong> - Grants create and edit permissions to specific content
              <br />
              <strong>Content Admin</strong> - Grants create, edit, and delete permissions to all content
              <br />
              <strong>Admin</strong> - Grants full access to content and user management
            </>
          }
        >
          <AutocompleteFormik
            label='Default Roles'
            name='default_roles'
            multiple
            options={DEFAULT_ROLE_OPTIONS}
            disableUserAdditions
          />
        </WithTooltip>
        <WithTooltip
          title={
            <>
              Requires enrollment with an TOTP provider (e.g., Google Authenticator, Authy) and token authentication for
              every login. This setting is ignored if an organization has SSO configured.
            </>
          }
        >
          <CheckboxFormik label='Require MFA' name='mandatory_preference.mfa_required' />
        </WithTooltip>
        <WithTooltip
          title={
            <>
              SnapAttack uses generative AI for various features, such as summarizing threat intelligence, enhancing
              detection descriptions, and inserting tuning logic. If you want to ensure that none of your
              organization&apos;s data is shared with a third-party LLM, you may disable these features. User experience
              may be degraded.
            </>
          }
        >
          <CheckboxFormik label='Disable LLM/Generative AI Features' name='mandatory_preference.disable_ai' />
        </WithTooltip>

        <Divider />
        <Typography variant='h4'>Branding & Images</Typography>
        <WithTooltip
          title={
            <>
              This is the organization&apos;s logo. It should be a square, 1000 by 1000px image, with either a
              transparent or solid background. It will be used on content to identify it as belonging to this
              organization.
            </>
          }
        >
          <TextFieldFormik fullWidth={true} label='Image URL' name='small_image' onChange={handleSmallImageChange} />
        </WithTooltip>
        <WithTooltip
          title={
            <>
              This is currently unused for organizations, but it would replace the currently purple hexagon hero image
              on most content pages. It is intended as additional branding for partner and community content that are
              considered content providers.
            </>
          }
        >
          <TextFieldFormik
            fullWidth={true}
            label='Banner Image URL'
            name='large_image'
            onChange={handleLargeImageChange}
          />
        </WithTooltip>
        <div className='placeholder-images'>
          {smallImage ? (
            <Image src={smallImage} width={100} height={100} />
          ) : (
            <ImagePlaceholder width={100} height={100} text='Image' />
          )}
          {largeImage ? (
            <Image src={largeImage} width={400} height={100} asHero />
          ) : (
            <ImagePlaceholder width={400} height={100} text='Banner Image' />
          )}
        </div>

        <Divider />
        <Typography variant='h4'>Preferences</Typography>
        <WithTooltip title='The default language shown for detection translations. Users may override.'>
          <AutocompleteFormik
            label='Default Language'
            name='optional_preference.default_analytic_compilation_target'
            options={languageOptionList}
            disabled={languageStatus === Status.pending}
            disableUserAdditions
          />
        </WithTooltip>
        <WithTooltip
          title={
            <>
              The default author for detections. For example, set this if you want detections created by your
              organization to be attributed to your organization, and not individual users.
            </>
          }
        >
          <TextFieldFormik fullWidth={true} label='Detection Author Display Name' name='detection_author_override' />
        </WithTooltip>
        <WithTooltip
          title={
            <>
              Shows detection confidence with values calculated from confidence tailoring, or sets to unknown if the
              confidence has not been calculated. If disabled, this will fall back to the global confidence values
              provided by SnapAttack, which may perform differently in your environment.
            </>
          }
        >
          <CheckboxFormik label='Display only tailored confidence values for detections' name='bulk_rank_active' />
        </WithTooltip>
        <WithTooltip
          title={
            <>
              Configure the default filters for your organization for intelligence, detections, threats, and attack
              scripts. Users may override.
            </>
          }
        >
          <Button className='defaultFilterButton' variant='outlined' fullWidth onClick={() => props.openFilterDialog()}>
            Edit Default Filters
          </Button>
        </WithTooltip>

        {user.superuser && (
          <>
            <Divider />
            <Typography variant='h4'>Superuser Settings</Typography>
            <WithTooltip
              title={
                <>
                  Entitlements configure the additional features/functions this organization can access. Review the
                  entitlements settings page if you want to know more about specific entitlements.
                </>
              }
            >
              <AutocompleteFormik
                label='Entitlements'
                name='entitlements'
                multiple
                options={entitlementOptions}
                disableUserAdditions
                helperText='Base packages will be applied automatically'
              />
            </WithTooltip>
            <div className='checkbox-options'>
              <WithTooltip
                title={
                  <>
                    Configure this organization as a subscriber, granting them access to subscription content and
                    features.
                  </>
                }
              >
                <CheckboxFormik label='Subscriber' name='subscriber' />
              </WithTooltip>
              <WithTooltip
                title={
                  <>
                    Allow this organization and its users to be impersonated. Enable this setting to allow SnapAttack
                    staff to monitor and maintain this organization.
                  </>
                }
              >
                <CheckboxFormik label='Allow Impersonation' name='allow_impersonation' />
              </WithTooltip>
              <WithTooltip
                title={
                  <>
                    Configure this organization for training mode, which restricts visibility to only content created by
                    the individual user.
                  </>
                }
              >
                <CheckboxFormik label='Training' name='isolated' />
              </WithTooltip>
              <WithTooltip
                title={
                  <>
                    Configure this organization as a content provider, which publishes their content to ALL USERS of the
                    platform, including community users. Example content providers are the Sigma Community and Atomic
                    Red Team organizations.
                  </>
                }
              >
                <CheckboxFormik label='Content Provider' name='content_provider' />
              </WithTooltip>
            </div>
          </>
        )}
      </FieldsLayout>
    </FormDialog>
  );
}

const StyledOrgFormDialog = styled(OrgFormDialog)<OrgFormDialogProps>`
  h4.MuiTypography-h4 {
    margin: 0;
  }

  & label {
    width: fit-content;
  }

  .checkbox-options {
    display: flex;
    flex-direction: column;
  }

  .placeholder-images {
    display: flex;
    flex-direction: row;
    gap: ${p => p.theme.spacing(5)};
  }

  .Divider {
    margin: ${p => p.theme.spacing(7, 0)};
  }
`;

export default StyledOrgFormDialog;
