import { styled } from 'snap-ui/util';

export const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${p => p.theme.spacing(4)};

  & > div {
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(3)};

    .buttons {
      display: flex;
      gap: ${p => p.theme.spacing(3)};
      justify-content: flex-end;
      align-items: center;
    }
  }

  .input-controls {
    display: flex;
    gap: ${p => p.theme.spacing(3)};

    .integration-selection.MuiAutocomplete-root {
      min-width: 300px;
    }

    .DateRangeSelector {
      .DatePicker-start {
        order: 1;
      }
      .DatePicker-stop {
        order: 2;
      }
      .DateRange {
        order: -1;
      }
    }
  }
`;

export const HitsChartContainer = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(5)};
  justify-content: flex-end;
  align-items: center;
  font-size: 1.5rem;

  div.chart-reduction-text {
    display: flex;
    gap: ${p => p.theme.spacing(3)};
    justify-content: flex-start;
    align-items: center;
    & > div {
      white-space: nowrap;
    }
  }

  div.chart-text {
    min-width: 175px;
  }
`;
