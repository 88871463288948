import React, { ReactElement } from 'react';

import Box from 'snap-ui/Box';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';

import { ArtifactType } from 'types/common';

import { Tier } from './Matrix.type';

type Props = {
  count: number;
  topic: string;
  tier?: Tier;
};

function formatter(num: number) {
  if (!num) return 0;
  return num > 999 ? Math.round(num / 100) / 10 + 'k' : num;
}

function icon(topic: string) {
  switch (topic) {
    case ArtifactType.Analytic:
      return Icon.Analytic;
    case ArtifactType.Intel:
      return Icon.Intel;
    case ArtifactType.Session:
      return Icon.Session;
    default:
      return Icon.Analytic;
  }
}

const MitreCount = ({ count, topic, tier }: Props): ReactElement => {
  const Icon = icon(topic);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Icon size='sm' color={tier?.text} />
      <Typography sx={{ marginLeft: '0.25rem', color: tier?.text }} variant='caption'>
        {formatter(count)}
      </Typography>
    </Box>
  );
};

export default MitreCount;
