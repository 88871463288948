import React from 'react';

import { checkSSO } from 'apis/resources/auth';

import { Status, useAsync } from 'storage';

type CheckSSOResponse = {
  url: string;
};

export interface SSOLoadState {
  status: Status;
  error: any;
  checkEmail(email: string): void;
  isNotSSO: boolean;
}

export default function useSSO(): SSOLoadState {
  const { data: sso, run, status, error } = useAsync<CheckSSOResponse>();

  const checkEmail = React.useCallback(
    (email: string) => {
      if (email) run(checkSSO({ email }));
    },
    [run]
  );

  React.useEffect(() => {
    if (sso?.url) window.location.replace(sso.url);
  }, [sso]);

  return {
    status,
    error,
    checkEmail,
    isNotSSO: status === Status.resolved && !sso?.url
  };
}
