import React from 'react';

import { InputAdornment, InputProps } from '@mui/material';
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';

export type TextFieldProps = MuiTextFieldProps & {
  startAdornment?: InputProps['startAdornment'];
  readOnly?: InputProps['readOnly'];
  autoComplete?: InputProps['autoComplete'];
  /** synthetic paper effect */
  elevated?: boolean | number;
};

const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>(function TextField(
  { startAdornment, readOnly, autoComplete = 'off', elevated, ...others },
  ref
) {
  const _props = { ...others };

  if (startAdornment) {
    _props.InputProps = {
      ..._props.InputProps,
      startAdornment: <InputAdornment position='start'>{startAdornment}</InputAdornment>
    };
  }
  if (autoComplete) _props.InputProps = { ..._props.InputProps, autoComplete };
  if (readOnly) _props.InputProps = { ..._props.InputProps, readOnly };
  if (elevated)
    _props.InputProps = {
      ..._props.InputProps,
      sx: {
        boxShadow: typeof elevated === 'number' ? elevated : 6,
        backgroundColor: 'hsl(0, 0%, 7.1%);',
        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))'
      }
    };

  return <MuiTextField {..._props} ref={ref} />;
});

export default TextField;
