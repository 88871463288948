import { CancelTokenType, snapattack } from 'apis';

import { Guid } from 'types/common';

import { ArtifactMetadataService, BulkMetadataSet } from './Metadata.type';

export function getArtifactMetadata(prefix: string, guid: Guid): Promise<ArtifactMetadataService> {
  return snapattack.get(`/${prefix}/${guid}/metadata/`).then(r => r.data);
}

export function setArtifactMetadata(
  prefix: string,
  guid: Guid,
  payload: Pick<ArtifactMetadataService, 'metadata_value'>
) {
  return snapattack.post(`/${prefix}/${guid}/metadata/`, payload).then(r => r.data);
}

export function getMetadataBulk(
  prefix: string,
  items: Guid[],
  config?: { cancelToken?: CancelTokenType }
): Promise<Record<Guid, ArtifactMetadataService>> {
  return snapattack.post(`/${prefix}/metadata/bulk/`, items, config).then(r => r.data);
}

export function postMetadataBulk(prefix: string, payload: BulkMetadataSet): Promise<void> {
  return snapattack.post(`/${prefix}/metadata/bulk/set/`, payload).then(r => r.data);
}
