import React from 'react';

import { IntegrationIcon } from 'snap-ui/Icon';
import Placeholder from 'snap-ui/Placeholder';
import Tooltip from 'snap-ui/Tooltip';

import { useLanguagePlatforms } from 'module/Integration';
import { SimpleBadge } from 'module/Widgets/Badge';

import { useIntegrationCatalog } from 'provider';

import { CompilationTargetId } from 'types/analytic';
import { Ident } from 'types/common';

type CompatibilityBadgeProps = {
  sourceLanguageId: Ident;
  compatibleLanguages: Ident[];
};

export default function CompatibilityBadge({
  sourceLanguageId,
  compatibleLanguages
}: CompatibilityBadgeProps): JSX.Element {
  const { getPlatformDetails } = useLanguagePlatforms();
  const { integrations } = useIntegrationCatalog();

  if (!sourceLanguageId) return <Placeholder variant='rectangular' height={24} width={80} />;

  if (sourceLanguageId !== CompilationTargetId.Sigma) {
    const platformDetails = getPlatformDetails(sourceLanguageId);
    if (!platformDetails) return <Placeholder variant='rectangular' height={24} width={80} />;

    const displayName = platformDetails?.platforms?.[0]?.typeLabel.display_name || platformDetails?.nativeKey;
    const integrationType = platformDetails?.platforms?.[0]?.integrationType;

    return <SimpleBadge icon={<IntegrationIcon type={integrationType} />} value={displayName} />;
  }

  const compatibleIntegrations = integrations.preferred_org_aware.filter(i =>
    compatibleLanguages?.some(
      targetId =>
        i.deployment_targets.some(dt => dt.id === targetId) ||
        i.hunt_targets.some(ht => ht.id === targetId) ||
        i.search_targets.some(st => st.id === targetId)
    )
  );

  if (!compatibleIntegrations.length) {
    return (
      <Tooltip arrow wrap placement='top' title='Not compatible with your integrations'>
        <>None</>
      </Tooltip>
    );
  }

  if (compatibleIntegrations.length === 1) {
    return <>Compatible with {compatibleIntegrations[0].name}</>;
  }

  return (
    <Tooltip
      arrow
      wrap
      placement='top'
      title={
        <ul>
          {compatibleIntegrations.map(i => (
            <li key={i.id}>{i.name}</li>
          ))}
        </ul>
      }
    >
      <SimpleBadge value={`${compatibleIntegrations.length} integrations`} />
    </Tooltip>
  );
}
