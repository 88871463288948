import React from 'react';

import { DetectionSummary } from 'module/Detection';

import { ArtifactType } from 'types/common';

import MarkerContent from './core/MarkerContent';
import Wrapper from './core/Wrapper';

type Props = {
  className?: string;
  count: DetectionSummary;
  error?: any;
  headless?: boolean;
};

export default function SessionDefensivePosture({ className, error, count, headless }: Props) {
  const validated = count.validated || 0;
  const gap = count.validated_gaps || 0;
  const unvalidated = count.unvalidated || 0;
  const total = validated + gap + unvalidated;
  const undetected = count.undetected || 0;
  if (total === 0) return null;
  return (
    <Wrapper className={className} showError={error || !count}>
      <MarkerContent
        topic={ArtifactType.Session}
        count={total}
        unique={count.unique_analytics}
        validated={validated}
        gap={gap}
        unvalidated={unvalidated}
        undetected={undetected}
        headless={headless}
      />
    </Wrapper>
  );
}
