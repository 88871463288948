import React from 'react';

export const DEMO_SNACK_MESSAGE = (
  <>
    This is a preview of an enterprise dashboard. To see what this would look like for your organization, contact{' '}
    <a target='_blank' rel='noreferrer' href='mailto:sales@snapattack.com?subject=SnapAttack+Dashboard+Demo'>
      sales@snapattack.com
    </a>{' '}
    for a demo.
  </>
);
