import React from 'react';

import AgentLabel from '../AgentLabel';
import { BASCampaignFeed } from '../BAS.type';

type CampaignHostProps = {
  agents: BASCampaignFeed['agents'];
};

export default function CampaignHost({ agents }: CampaignHostProps): JSX.Element {
  return (
    <>
      {agents?.map((agent, idx) => (
        <AgentLabel key={idx} agent={agent} />
      ))}
    </>
  );
}
