import React from 'react';

import { useAuth } from 'provider/Account';

import { debounce } from 'utilities/FunctionUtils';

import { JOB_PAGE_SIZE } from '../Job.const';
import { TaskAction, TaskState } from '../Job.type';

const createInitialState = (typeFilter: TaskState['typeFilter'], orgId: TaskState['orgFilter']): TaskState => ({
  typeFilter,
  orgFilter: orgId,
  nameFilter: '',
  name: '',
  sortModel: [{ field: 'modified', sort: 'desc' }],
  pageParams: { page: 0, size: JOB_PAGE_SIZE }
});

function taskReducer(state: TaskState, action: TaskAction): TaskState {
  switch (action.type) {
    case 'SET_TYPE':
      return {
        ...state,
        pageParams: { ...state.pageParams, page: 0 },
        typeFilter: action.payload
      };

    case 'SET_PAGE':
      return {
        ...state,
        pageParams: { ...state.pageParams, page: action.payload }
      };

    case 'SET_ORG_FILTER':
      return {
        ...state,
        pageParams: { ...state.pageParams, page: 0 },
        orgFilter: action.payload
      };

    case 'SET_NAME_FILTER':
      return {
        ...state,
        pageParams: { ...state.pageParams, page: 0 },
        nameFilter: action.payload
      };

    case 'SET_SORT_MODEL':
      return {
        ...state,
        sortModel: action.payload
      };

    case 'RESET_FILTER':
      return createInitialState(action.payload, state.orgFilter);

    default:
      return state;
  }
}

export default function useTaskReducer(type: TaskState['typeFilter']): [TaskState, React.Dispatch<TaskAction>] {
  const { user, defaultOrgId } = useAuth();
  const orgId = user.superuser ? null : defaultOrgId;

  const [state, _dispatch] = React.useReducer(taskReducer, createInitialState(type, orgId));

  const dispatch = React.useCallback((...args) => debounce(_dispatch)(...args), [_dispatch]);

  return [state, dispatch];
}
