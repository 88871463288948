import React from 'react';

import { Fade as MuiFade, FadeProps as MuiFadeProps } from '@mui/material';
import classnames from 'classnames';

import { StrictReactNode } from 'types/core';

export type FadeProps = Omit<MuiFadeProps, 'children'> & {
  children: StrictReactNode;
};

export default function Fade({ children, ...props }: FadeProps): JSX.Element {
  return (
    <MuiFade className={classnames('Fade')} {...props} unmountOnExit>
      <div>{children}</div>
    </MuiFade>
  );
}
