import React from 'react';

import { StandardChip } from 'snap-ui/Chip';

import { WorkflowState } from 'types/analytic';

import { WORKFLOW_STATE_COLORS } from './util';

export type Props = {
  state: WorkflowState;
};

export default function WorkflowBadge({ state }: Props) {
  const color = WORKFLOW_STATE_COLORS[state];

  return state ? <StandardChip label={state.toUpperCase()} color={color} /> : null;
}
