import replace from 'lodash/replace';

import { SigmaModifier, SpecialSigmaModifier } from 'types/analytic';

import { Model as Token } from '../Token';
import { Modifier } from '../const';
import Compiler from './Compiler';
import { Fragment, Match } from './type';

export default class Detection {
  private baseline: string;
  private guideline: string;
  private matched: Match[];
  private matchedRule = 0;

  constructor(log: string, private token: Token) {
    if (typeof log === 'string') this.baseline = log;
    else {
      this.baseline = '';
      if (process.env.NODE_ENV === 'development') {
        console.groupCollapsed('Detection warning');
        console.info(`Detection expects the first argument to be a string but received ${typeof log}`);
        console.info('log', log);
        console.info('token', token);
        console.groupEnd();
      }
    }
    this.guideline = this.baseline.toUpperCase();
    this.matched = this.baseline.split('').map<Match>(() => ({ end: 0, start: 0 }));
  }

  run(): {
    compiled: Fragment[];
    satisfied: boolean;
  } {
    try {
      this.token.criterion.forEach(rule => this.load(rule));
    } catch (e) {
      if (process.env.NODE_ENV !== 'test') {
        console.groupCollapsed('Token error');
        console.info(this.token);
        console.info(this.baseline);
        console.info(e);
        console.groupEnd();
      }
      return {
        compiled: [{ value: this.baseline, outline: false }],
        satisfied: false
      };
    }

    return {
      compiled: new Compiler(this.matched, this.baseline).run(),
      satisfied: this.getSatisfiedFlag()
    };
  }

  getSatisfiedFlag() {
    switch (this.token.modifier) {
      case Modifier.all:
        return this.matchedRule === this.token.criterion.length;
      case Modifier.any:
        return this.matchedRule > 0;
      case Modifier.exact:
        return this.matchedRule === 1;
      case Modifier.full:
        return this.matchedRule > 0;
    }
  }

  load(rule: string): void {
    switch (this.token.comparison) {
      case SigmaModifier.Contains:
        this.handleContains(rule);
        break;
      case SigmaModifier.StartsWith:
        this.handleStartsWith(rule);
        break;
      case SigmaModifier.EndsWith:
        this.handleEndsWith(rule);
        break;
      case SpecialSigmaModifier.Equal:
        this.loadWithTransformation(rule);
        break;
      default:
        throw new Error('Unexpected Token comparison type');
    }
  }

  loadWithTransformation(rule: string): void {
    let transformation = 0;
    if (rule.charAt(0) === '*') transformation += 1;
    if (rule.charAt(rule.length - 1) === '*') transformation += 2;

    switch (transformation) {
      case 0:
        this.handleEquals(rule);
        break;
      case 1:
        this.handleEndsWith(rule.substring(1));
        break;
      case 2:
        this.handleStartsWith(rule.substring(0, rule.length - 1));
        break;
      case 3:
        this.handleContains(rule.substring(1, rule.length - 1));
        break;
    }
  }

  handleEquals(rule: string): void {
    if (rule && this.guideline === rule.toUpperCase()) {
      this.matchedRule++;
      this.recordMatch(0, this.matched.length - 1);
    }
  }

  handleEndsWith(rule: string): void {
    if (rule) {
      const r = rule.toUpperCase();

      if (this.guideline.endsWith(r)) {
        this.matchedRule++;
        const lastIndexOf = this.guideline.lastIndexOf(r);
        this.recordMatch(lastIndexOf, this.matched.length - 1);
      }
    }
  }

  handleStartsWith(rule: string): void {
    if (rule) {
      const r = rule.toUpperCase();

      if (this.guideline.startsWith(r)) {
        this.matchedRule++;
        this.recordMatch(0, rule.length - 1);
      }
    }
  }

  handleContains(rule: string): void {
    if (rule) {
      let escaped = replace(rule, new RegExp(/\\/g), '\\\\');
      escaped = replace(escaped, new RegExp(/\./g), '\\.');
      escaped = replace(escaped, new RegExp(/\(/g), '\\(');
      escaped = replace(escaped, new RegExp(/\)/g), '\\)');
      escaped = replace(escaped, new RegExp(/\*/g), '\\*');
      escaped = replace(escaped, new RegExp(/\[/g), '\\[');
      escaped = replace(escaped, new RegExp(/\]/g), '\\]');

      // matchAll returns an iterable which contains information about each match
      const normal = this.baseline.matchAll(RegExp(escaped, 'ig'));
      let matched = false;

      for (const found of normal) {
        matched = true;
        const end = found.index + found[0].length - 1;
        this.recordMatch(found.index, end);
      }

      if (matched) this.matchedRule++;
    }
  }

  recordMatch(start: number, end: number): void {
    this.matched[start].start++;
    this.matched[end].end++;
  }
}
