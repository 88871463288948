import React from 'react';

import { faVideo } from '@fortawesome/pro-solid-svg-icons';

import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { SimpleBadge } from './Badge';

const Badge = styled(SimpleBadge)`
  min-width: 120px;
`;

export default function HasThreatBadge({ value }: { value?: boolean }) {
  if (!value) return null;
  return (
    <Tooltip title='Has recorded threat session(s)' arrow placement='top' wrap>
      <Badge icon={<Icon icon={faVideo} />} value='Has Threat' />
    </Tooltip>
  );
}
