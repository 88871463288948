import React from 'react';

import yaml from 'js-yaml';

import Alert from 'snap-ui/Alert';
import { styled } from 'snap-ui/util';

import ErrorPopper from 'module/Widgets/ErrorPopper';
import { Highlight } from 'module/Widgets/SyntaxEditor';

import { YamlParseError } from 'services/analyticService';

import AdvancedEditor from './AdvancedEditor';

type AdvancedFormProps = {
  readonly?: boolean;
  syntaxError?: YamlParseError | yaml.YAMLException;
};

const StyledAlert = styled(Alert)`
  align-items: center;

  & > .MuiAlert-message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }
`;

export default function AdvancedForm({ readonly, syntaxError }: AdvancedFormProps): JSX.Element {
  let errorSeverity: Alert['severity'] = 'warning';

  let errorMessage = 'This detection can only be edited in the Advanced editor';
  if (syntaxError instanceof yaml.YAMLException) {
    errorSeverity = 'error';
    errorMessage = 'Syntax error';
  }

  return (
    <>
      {!!syntaxError && (
        <>
          <StyledAlert severity={errorSeverity}>
            {errorMessage}
            <ErrorPopper id='advanced-tab-syntax-error-tooltip'>
              <pre>{syntaxError?.message}</pre>
            </ErrorPopper>
          </StyledAlert>
        </>
      )}
      <AdvancedEditor protocol={Highlight.yaml} readonly={readonly} />
    </>
  );
}
