import { MatrixPaletteOrdinal } from 'module/Scaffold/Scaffold.type';
import { Attack } from 'module/Tag/Tag.type';

import { JobOutcome } from 'types/bas';
import { ArtifactScore, Ident } from 'types/common';

export type Summary = Record<number, Statistic>;
export interface AttackNode extends Attack {
  attack_children: AttackNode[];
  outcome?: JobOutcome;
  severity?: ArtifactScore;
}

export type Forest = AttackNode[];

export type Statistic = {
  id?: Ident;
  intelligence?: number;
  sessions?: number;
  analytics?: number;
};

export type StatisticNode = Attack &
  Statistic & {
    attack_children: StatisticNode[];
    severity?: ArtifactScore;
  };

export type Matrix = StatisticNode[];

export type Display = {
  showMitreId: boolean;
  showCount: boolean;
  showEmpty: boolean;
  showDrawer: boolean;
  showSubtechniques: boolean;
  palette: MatrixPaletteOrdinal;
  maxRange: number;
  perBucket: number;
};

export type Action = {
  type: 'showMitreId' | 'showCount' | 'showEmpty' | 'showDrawer' | 'showSubtechniques' | 'palette' | 'maxRange';
  value?: string | number;
};

/**
 * https://github.com/mitre-attack/attack-navigator
 * https://mitre-attack.github.io/attack-navigator/
 */
export type MitreNavigator = {
  name: string;

  versions: {
    attack: string;
    navigator: string;
    layer: string;
  };

  domain: 'enterprise-attack';
  description?: string;
  filters?: {
    platforms: string[];
  };
  sorting?: 0;
  layout?: {
    layout: 'side' | 'flat' | 'mini';
    aggregateFunction: 'average';
    showID?: boolean;
    showName?: boolean;
    showAggregateScores?: boolean;
    countUnscored?: boolean;
    expandedSubtechniques?: 'all' | 'none' | 'annotated';
  };
  hideDisabled?: boolean;
  techniques: {
    techniqueID: string;
    tactic?: string;
    score?: number;
    enabled: true;
    color?: string;
    comment?: string;
    metadata?: string[];

    // Must be false for sub-techniques
    showSubtechniques: boolean;
    links: { label: string; url: string }[];
  }[];
  gradient: {
    colors: string[];
    minValue: 0;
    maxValue: number;
  };
  legendItems?: [];
  metadata?: { name: string; value: string }[];
  links?: { label: string; url: string }[];
  showTacticRowBackground?: false;
  tacticRowBackground?: string; // hex color (#dddddd)
  selectTechniquesAcrossTactics?: boolean;
  selectSubtechniquesWithParent?: boolean;
  selectVisibleTechniques?: boolean;
};

export type Tier = {
  background: string;
  text?: string;
  contrast?: string;
};

export type Palette = [Tier, Tier, Tier, Tier, Tier, Tier];

export enum HeatMap {
  Detection = 'detection',
  ThreatProfile = 'threatProfile'
}

export type HeatMapKeys = {
  heatMap: HeatMap;
};
