import React from 'react';

import { faTrash } from '@fortawesome/pro-solid-svg-icons';

import { ActionIconButton } from 'snap-ui/Button';
import CircularProgress from 'snap-ui/CircularProgress';
import { ConfirmDialog } from 'snap-ui/Dialog';
import Tooltip from 'snap-ui/Tooltip';

import { ApiError } from 'module/ApiError';

import { Status } from 'storage';

import { Guid } from 'types/common';

import { useScheduledJobsContext } from '../useScheduledJobs';

type DeleteTaskButtonProps = {
  name: string;
  schedule_guid: Guid;
  integration_guid: Guid;
};

function DeleteTaskButton({ name, integration_guid, schedule_guid }: DeleteTaskButtonProps) {
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const { deleteJobSchedule, taskStatus, taskErrorProps } = useScheduledJobsContext();

  function handleSubmit(): void {
    deleteJobSchedule(integration_guid, schedule_guid).then(() => {
      setShowDeleteConfirm(false);
    });
  }

  return (
    <>
      <Tooltip arrow title={`Delete ${name}`}>
        <ActionIconButton
          key='Delete'
          aria-label='Delete Task'
          icon={faTrash}
          onClick={() => setShowDeleteConfirm(true)}
        />
      </Tooltip>
      <ConfirmDialog
        ConfirmProps={{
          children:
            taskStatus === Status.pending ? (
              <>
                Deleting... <CircularProgress size={16} />
              </>
            ) : (
              'Delete'
            ),
          disabled: taskStatus === Status.pending,
          onClick: handleSubmit
        }}
        DialogProps={{ open: showDeleteConfirm, onClose: () => setShowDeleteConfirm(false) }}
        title={`Delete ${name}`}
      >
        Are you sure you want to delete this task?
        <ApiError {...taskErrorProps} />
      </ConfirmDialog>
    </>
  );
}

export default DeleteTaskButton;
