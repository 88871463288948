import React from 'react';

import useOrganization, { OrganizationCatalog } from './useOrganization';

const ManagedOrganizationContext = React.createContext<OrganizationCatalog>(null);
ManagedOrganizationContext.displayName = 'ManagedOrganizationContext';

const useManagedOrganizations = (): OrganizationCatalog => {
  const context = React.useContext(ManagedOrganizationContext);

  if (!context) {
    throw new Error('useManagedOrganizations must be used within the ManagedOrganizationContext');
  }

  return context;
};

function ManagedOrganizationProvider({ children }: { children: React.ReactNode }): React.ReactElement {
  const catalog: OrganizationCatalog = useOrganization();

  return <ManagedOrganizationContext.Provider value={catalog}>{children}</ManagedOrganizationContext.Provider>;
}

export { ManagedOrganizationProvider, useManagedOrganizations };
