import React from 'react';

import Placeholder from 'snap-ui/Placeholder';
import Typography from 'snap-ui/Typography';

import { StrictReactNode } from 'types/core';

import { VersionNumberTypography } from '../Analytic.style';

export function AnalyticDiffSelector(props: {
  busy?: boolean;
  children?: StrictReactNode;
  title: StrictReactNode;
  subtitle: StrictReactNode;
}) {
  const { busy, children, subtitle, title } = props;

  return (
    <div className='VersionDiff-selector--title'>
      <div className='VersionDiff-selector--content'>
        {busy ? (
          <>
            <Placeholder variant='text' animation='wave' height={20} width={50} />
            <Placeholder variant='text' animation='wave' height={20} width={150} />
          </>
        ) : (
          <>
            <VersionNumberTypography variant='body2' component='span'>
              {title}
            </VersionNumberTypography>
            <Typography variant='subtitle2' component='div'>
              {subtitle}
            </Typography>
          </>
        )}
      </div>
      {children}
    </div>
  );
}
