import React from 'react';

import { Autocomplete } from 'snap-ui/Autocomplete';

import { useIDEState } from 'module/IDE';
import {
  useLanguagePlatforms,
  useIntegrationOptions,
  useLanguageOptions,
  IntegrationAutocomplete
} from 'module/Integration';
import { SyntaxCheckerButton, useSyntaxCheckerContext } from 'module/SyntaxChecker';
import { getIntegrationValueFromLanguageId } from 'module/TranslateAnalytic';

import { useIntegrationCatalog } from 'provider';

import { IntegrationLanguageContainer } from './DetectionForm.style';

type LanguageField = {
  disabled?: boolean;
  onChange?(languageId: number): void;
  value: number;
};

export default function LanguageField({ disabled }: LanguageField): JSX.Element {
  const { ideState, dispatch } = useIDEState();
  const value = ideState.analyticForm.languageId;

  const { supportedPlatforms } = useLanguagePlatforms();
  const { integrationOptions } = useIntegrationOptions(false);
  const [selectedIntegrationOptionValue, setSelectedIntegrationOptionValue] = React.useState('');
  const languageOptions = useLanguageOptions(selectedIntegrationOptionValue);
  const { setSyntaxCheckerParams } = useSyntaxCheckerContext();

  const { integrations } = useIntegrationCatalog();

  React.useEffect(() => {
    setSyntaxCheckerParams({
      detectionCode: ideState.raw,
      integrationId: selectedIntegrationOptionValue ? Number(selectedIntegrationOptionValue) : undefined,
      languageId: ideState.analyticForm.languageId
    });
  }, [ideState.analyticForm.languageId, ideState.raw, selectedIntegrationOptionValue, setSyntaxCheckerParams]);

  React.useEffect(() => {
    // set selected integration on load
    // use the first integration that supports the detection's source language
    if (value && !selectedIntegrationOptionValue) {
      const integrationValue = getIntegrationValueFromLanguageId(
        value,
        integrationOptions,
        integrations.all,
        supportedPlatforms
      );
      if (integrationValue) setSelectedIntegrationOptionValue(integrationValue);
    }
  }, [integrationOptions, integrations.all, selectedIntegrationOptionValue, supportedPlatforms, value]);

  function dispatchChange(languageId: number) {
    dispatch({ type: 'FormUpdateAction', analyticForm: { languageId } });
  }

  function handleIntegrationChange(value: string): void {
    setSelectedIntegrationOptionValue(value);

    let languageId: number;

    const integration = integrations.all.find(i => i.id.toString() === value);
    if (integration) {
      // selected option is a real integration
      // set language to the first target supported by the integration
      languageId =
        integration.deployment_targets[0]?.id ?? integration.hunt_targets[0]?.id ?? integration.search_targets[0]?.id;
    }

    const platform = supportedPlatforms[value];
    if (platform) {
      // selected option is a platform
      languageId = platform.deploy[0] ?? platform.hunt[0] ?? platform.search[0];
    }

    if (value.includes('language-')) {
      // selected option is a language with no platform support
      languageId = parseInt(value.replace('language-', ''));
    }

    if (languageId) dispatchChange(languageId);
    else dispatchChange(undefined);
  }

  function handleLanguageChange(languageId: string) {
    dispatchChange(Number(languageId));
  }

  return (
    <IntegrationLanguageContainer>
      <IntegrationAutocomplete
        className='IntegrationSelection'
        disabled={disabled}
        disableClearable
        disableUserAdditions
        name='IntegrationSelection'
        onChange={handleIntegrationChange}
        options={integrationOptions}
        value={selectedIntegrationOptionValue}
      />
      {languageOptions?.length > 1 && (
        <Autocomplete
          disabled={disabled}
          name='languageId'
          onChange={handleLanguageChange}
          options={languageOptions}
          value={value?.toString() ?? ''}
        />
      )}
      <SyntaxCheckerButton />
    </IntegrationLanguageContainer>
  );
}
