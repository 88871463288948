import React from 'react';

import axios from 'axios';

import { getSearchPage } from 'module/Feed/Feed.service';
import { Feed } from 'module/Feed/Feed.type';
import { useFilterRegistry } from 'module/GlobalFilter';
import { searchIndicator } from 'module/IOC/IOC.api';
import { IndicatorSearchResponse } from 'module/IOC/IOC.type';
import { useMayI } from 'module/May';

import { Status, useAsync } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { ArtifactType, Visibility } from 'types/common';

import mapper, { mapIndicators } from './mapper';
import redistributeResult from './redistributeResult';
import { CategoryResult, PER_PAGE } from './type';

const empty = (name: string, topic: CategoryResult['topic']): CategoryResult => ({
  name,
  topic,
  results: [],
  total: 0
});

const SORT = '';

export const INDICATOR_SEARCH_MIN_LENGTH = 6;

const CancelToken = axios.CancelToken;

export default function useSearchBar() {
  const [query, setQuery] = React.useState('');
  const { generateQuery } = useFilterRegistry();
  const { data: intel, status: intelStatus, run: intelRun, reset: intelReset } = useAsync<Feed>();
  const { data: session, status: sessionStatus, run: sessionRun, reset: sessionReset } = useAsync<Feed>();
  const { data: signature, status: signatureStatus, run: signatureRun, reset: signatureReset } = useAsync<Feed>();
  const { data: collection, status: collectionStatus, run: collectionRun, reset: collectionReset } = useAsync<Feed>();
  const {
    data: attackScript,
    status: attackScriptStatus,
    run: attackScriptRun,
    reset: attackScriptReset
  } = useAsync<Feed>();
  const {
    data: indicator,
    status: indicatorStatus,
    run: indicatorRun,
    reset: indicatorReset
  } = useAsync<IndicatorSearchResponse[]>();

  const isBasUser = useMayI(FunctionalPermission.BASStableFeatures);

  const cancelTokenSourceRef = React.useRef(CancelToken.source());

  const cancelQuery = React.useCallback(() => {
    cancelTokenSourceRef.current.cancel();
    indicatorReset();
    intelReset();
    sessionReset();
    signatureReset();
    collectionReset();
    attackScriptReset();
  }, [collectionReset, indicatorReset, intelReset, sessionReset, signatureReset, attackScriptReset]);

  const submitQuery = React.useCallback(
    (query: string) => {
      cancelQuery();
      const source = CancelToken.source();
      setQuery(query);
      cancelTokenSourceRef.current = source;

      if (query.length >= INDICATOR_SEARCH_MIN_LENGTH) indicatorRun(searchIndicator(query));

      intelRun(
        getSearchPage(
          ArtifactType.Intel,
          1,
          PER_PAGE,
          generateQuery(ArtifactType.Intel, { query, visibility: [Visibility.Published] }),
          SORT,
          source
        )
      );

      sessionRun(
        getSearchPage(
          ArtifactType.Session,
          1,
          PER_PAGE,
          generateQuery(ArtifactType.Session, { query, visibility: [Visibility.Published] }),
          SORT,
          source
        )
      );

      signatureRun(
        getSearchPage(
          ArtifactType.Analytic,
          1,
          PER_PAGE,
          generateQuery(ArtifactType.Analytic, {
            query,
            visibility: [Visibility.Published]
          }),
          SORT,
          source
        )
      );

      collectionRun(
        getSearchPage(
          ArtifactType.Collection,
          1,
          PER_PAGE,
          generateQuery(ArtifactType.Collection, { query }),
          SORT,
          source
        )
      );

      attackScriptRun(
        getSearchPage(
          ArtifactType.AttackScript,
          1,
          PER_PAGE,
          generateQuery(ArtifactType.AttackScript, { query }),
          SORT,
          source
        )
      );
    },
    [cancelQuery, indicatorRun, intelRun, generateQuery, sessionRun, signatureRun, collectionRun, attackScriptRun]
  );

  const result = redistributeResult(
    [
      collection ? mapper(ArtifactType.Collection, collection) : empty('Collections', ArtifactType.Collection),
      intel ? mapper(ArtifactType.Intel, intel) : empty('Intelligence', ArtifactType.Intel),
      session ? mapper(ArtifactType.Session, session) : empty('Threats', ArtifactType.Session),
      signature ? mapper(ArtifactType.Analytic, signature) : empty('Detections', ArtifactType.Analytic),
      attackScript
        ? isBasUser // rather than try to undo everything else, ignore results if they aren't a BAS user
          ? mapper(ArtifactType.AttackScript, attackScript)
          : empty('Attack Scripts', ArtifactType.AttackScript)
        : empty('Attack Scripts', ArtifactType.AttackScript),
      indicator ? mapIndicators(indicator, query) : empty('Indicators', ArtifactType.Indicator)
    ],
    query
  );

  const isLoading = [
    collectionStatus,
    indicatorStatus,
    intelStatus,
    sessionStatus,
    signatureStatus,
    attackScriptStatus
  ].includes(Status.pending);

  return {
    query,
    result,
    isLoading,
    submitQuery,
    cancelQuery
  };
}
