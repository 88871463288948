import React from 'react';

import { IOCCard } from 'module/IOC';
import { IOCArtifact, isIOCType } from 'module/IOC/IOC.type';
import { LandingArtifact } from 'module/Landing/Landing.type';

import { Artifact, ArtifactType, Guid } from 'types/common';

import { isLandingType } from '../Collection/Collection.util';
import LandingCard from './LandingCard';
import LegacyCard from './LegacyCard';

export type CardProps = {
  className?: string;
  item: Artifact;
  topic: ArtifactType;
  onDelete?(id: Guid): void;
};

export type LandingCardProps = Omit<CardProps, 'item'> & {
  item: LandingArtifact;
};

function Card(props: CardProps) {
  // Collection (Static + Hybrid), + Landing types
  if (isLandingType(props.item)) return <LandingCard {...props} />;
  if (isIOCType(props.item)) return <IOCCard ioc={props.item as unknown as IOCArtifact} />;

  return <LegacyCard {...props} />;
}

export default Card;
