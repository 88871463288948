import React from 'react';

import { Status, useAsync } from 'storage';

import { extractIndicatorsFromText, extractIndicatorsFromURL } from '../IOC.api';
import { ExtractIOCResponse, IOC } from '../IOC.type';

type ExtractIOCInterface = {
  extractText(text: string): void;
  extractUrl(url: string): void;
  iocs: IOC[];
  status: Status;
  text: string;
};

const ExtractIOCShell: ExtractIOCResponse = {
  text: '',
  indicators: []
};

export default function useExtractIOC(): ExtractIOCInterface {
  const { data, run, status } = useAsync<ExtractIOCResponse>(ExtractIOCShell);

  const extractText = React.useCallback(
    (text: string) => {
      if (text) run(extractIndicatorsFromText(text));
    },
    [run]
  );

  const extractUrl = React.useCallback(
    (url: string) => {
      if (url) run(extractIndicatorsFromURL(url));
    },
    [run]
  );

  return {
    extractText,
    extractUrl,
    text: data.text,
    iocs: data.indicators,
    status
  };
}
