import { useAuth } from 'provider';

export function useAiFeatures(): {
  canUseAiFeatures: boolean;
} {
  const { permission: orgs, tenantOrgId } = useAuth();
  const canUseAiFeatures = !orgs.find(org => org.id === tenantOrgId)?.mandatory_preference?.disable_ai;

  return { canUseAiFeatures };
}
