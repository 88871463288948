import React from 'react';

import { RJSFSchema } from '@rjsf/utils';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';

import Tabs, { TabItem, TabLabel } from 'snap-ui/Tabs';

import { Guid } from 'types/common';

import {
  INTEGRATION_SCHEMA_TABS,
  IntegrationFormData,
  IntegrationRJSFSchema,
  LanguageOption
} from '../Integration.type';
import { getTabIntegrationSchema } from '../Integration.util';
import IntegrationConfidenceTailoring from '../IntegrationConfidenceTailoring/IntegrationConfidenceTailoring';
import ConfigurationTab from './ConfigurationTab';
import DeploymentTab from './DeploymentTab';
import { IOCTab } from './IOCTab';
import SearchTab from './SearchTab';

export type IntegrationFormFieldProps = {
  getLanguageOptions: (type: string) => LanguageOption;
  integrationGuid?: Guid;
  integrationSchema: IntegrationRJSFSchema;
  jobSchema: RJSFSchema;

  setType(type: string): void;
};

const TabFormFields = ({
  integrationGuid,
  integrationSchema,
  jobSchema,
  getLanguageOptions,
  setType
}: IntegrationFormFieldProps) => {
  const { values } = useFormikContext<IntegrationFormData>();

  const languageOptionLists = getLanguageOptions(values.type);

  const tabIntegrationSchema = getTabIntegrationSchema(integrationSchema);

  const tabs: TabItem[] = [
    {
      value: 'Configuration',
      label: <TabLabel>Configuration</TabLabel>,
      content: (
        <ConfigurationTab
          integrationSchema={tabIntegrationSchema(INTEGRATION_SCHEMA_TABS.Configuration)}
          setType={setType}
        />
      )
    }
  ];

  tabs.push({
    value: 'Search',
    label: <TabLabel>Search</TabLabel>,
    content: <SearchTab languageOptionLists={languageOptionLists} />
  });

  tabs.push({
    value: 'Deployment',
    label: <TabLabel>Deployment</TabLabel>,
    content: (
      <DeploymentTab
        languageOptionLists={languageOptionLists}
        integrationGuid={integrationGuid}
        integrationSchema={tabIntegrationSchema(INTEGRATION_SCHEMA_TABS.Deployment)}
      />
    )
  });

  if (integrationGuid) {
    tabs.push({
      value: 'Confidence',
      label: <TabLabel>Confidence</TabLabel>,
      content: <IntegrationConfidenceTailoring integration_guid={integrationGuid} jobSchema={jobSchema} />
    });
  }

  if (!isEmpty(integrationSchema)) {
    tabs.push({
      value: 'Indicators',
      label: <TabLabel>Indicators</TabLabel>,
      content: (
        <IOCTab
          integration_guid={integrationGuid}
          integrationSchema={tabIntegrationSchema(INTEGRATION_SCHEMA_TABS.Indicators)}
        />
      )
    });
  }

  return (
    <div>
      <Tabs tabs={tabs} variant='scrollable' />
    </div>
  );
};

export default TabFormFields;
