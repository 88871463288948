import React from 'react';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import { Alert } from 'snap-ui/Alert';
import { DisplayDialog } from 'snap-ui/Dialog';
import Icon from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

import { ApiError } from 'module/ApiError';

import { useMetadataSchema } from 'provider';

import { Status } from 'storage';

import { ArtifactType, Guid } from 'types/common';

import { Metadata, MetadataValue } from './Metadata.type';
import { transformMetadataValueWithSchema } from './Metadata.util';
import { MetadataForm } from './MetadataForm';
import { useMetadata } from './useMetadata';

const Container = styled('div')`
  margin: ${p => p.theme.spacing(5, 3)};

  .Alert,
  .ApiError {
    margin-bottom: ${p => p.theme.spacing(3)};
  }
`;

type MetadataFormModal = {
  guid: Guid;
  isOpen: boolean;
  onClose(): void;
  type: ArtifactType;
  onUpdated(): void;
};

function _MetadataFormModal(props: MetadataFormModal) {
  const { guid, isOpen, onClose, onUpdated, type } = props;
  const { schema } = useMetadataSchema();
  const { data, error, errorProps, formUpdate, setError, status } = useMetadata(type, guid);
  const [queue, setQueue] = React.useState<Metadata['value']>(data?.value);
  const disabled =
    status === Status.pending || !queue || isEqual(data.value, transformMetadataValueWithSchema(queue, schema));

  const handleSubmit = () => {
    formUpdate(transformMetadataValueWithSchema(queue, schema)).then(() => {
      onUpdated();
      onClose();
    });
  };

  const handleChange: typeof setQueue = d => {
    // spread new values to overwrite existing
    let value: MetadataValue = { ...data.value, ...d };

    // omit empty/nullish
    value = omitBy(value, v => (typeof v === 'object' ? isEmpty(v) : isNil(v)));

    setError(undefined);
    setQueue(value);
  };

  return (
    <DisplayDialog
      DialogProps={{ open: isOpen, onClose, disablePortal: true }}
      PrimaryActionProps={{
        children: <>Submit {status === Status.pending && <Icon.SpinnerProgress />}</>,
        onClick: handleSubmit,
        disabled
      }}
      title='Metadata Edit'
    >
      <Container>
        {error && typeof error === 'string' && <Alert severity='error'>{error}</Alert>}
        {typeof error !== 'string' && errorProps && <ApiError {...errorProps} />}
        <MetadataForm data={data} onChange={handleChange} type={type} />
      </Container>
    </DisplayDialog>
  );
}

export function MetadataFormModal(props: MetadataFormModal) {
  if (!props.isOpen) return null;
  return <_MetadataFormModal {...props} />;
}
