import React from 'react';

import { useLocation } from 'react-router-dom';

import Link from 'snap-ui/Link';

import Path from 'constants/paths';

import { useAuth } from 'provider';

import Banner from './Banner';

function IsolatedBanner() {
  const { pathname } = useLocation();
  const { user } = useAuth();
  if (!user.is_isolated) return null;

  return (
    <Banner>
      You are currently in training mode and will see limited platform content.
      {!pathname.includes(Path.Preferences) && (
        <Link id='ChangePreferredOrganization' ariaLabel='Change Preferred Organization' to={Path.Preferences}>
          Change
        </Link>
      )}
    </Banner>
  );
}

export default IsolatedBanner;
