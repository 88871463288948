import React from 'react';

import { postOrg, putOrg } from 'apis/resources/organization';

import { useAuth } from 'provider';

import { Status, useAsync } from 'storage';

import { Organization, OrganizationTargetPayload } from 'types/auth';
import { Guid } from 'types/common';

import { getSortedManagedOrgs } from './useOrganization.service';

export type OrganizationCatalog = {
  create(target: OrganizationTargetPayload): Promise<void>;
  error?: string;
  organizations: Organization[];
  resetError(): void;
  status: Status;
  update(targetGuid: Guid, target: Partial<OrganizationTargetPayload>): Promise<void>;
  refresh(): void;
};

export default function useOrganization(): OrganizationCatalog {
  const { user } = useAuth();
  const { data: organizations, error, status, run, task } = useAsync<Organization[]>([]);

  const refresh = React.useCallback(
    (preserve = false) => {
      if (user?.guid) {
        run(getSortedManagedOrgs(), preserve);
      }
    },
    [run, user?.guid]
  );

  const create = React.useCallback(
    (target: OrganizationTargetPayload) => {
      return task(postOrg(target)).then(refresh);
    },
    [refresh, task]
  );

  const update = React.useCallback(
    (targetGuid: Guid, target: Partial<OrganizationTargetPayload>) => {
      return task(putOrg(targetGuid, target)).then(() => {
        refresh(true);
      });
    },
    [refresh, task]
  );

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  const resetError = React.useCallback(() => {
    if (status === Status.rejected) task(Promise.resolve());
  }, [task, status]);

  return {
    create,
    error: error ? error.message || JSON.stringify(error.detail) || 'The server encountered an error' : undefined,
    organizations: organizations,
    resetError,
    status,
    update,
    refresh
  };
}
