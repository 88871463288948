import React from 'react';

import zod from 'zod';

import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { FilterControl } from 'module/GlobalFilter';

import { useAuth } from 'provider';

import { ArtifactType } from 'types/common';
import { Ops, Query } from 'types/filter';
import { RiskRatings } from 'types/mati';

import { FilterConfig } from '../GlobalFilter.type';
import { addOrRemoveValue } from '../GlobalFilter.util';

type RiskRatingKeys = {
  riskRating: RiskRatings[];
};

type RiskRatingFilterProps = {
  onChange(values: RiskRatingKeys): void;
  values: RiskRatingKeys;
};

function VulnerabilityRiskRatingFilter({ onChange, values }: RiskRatingFilterProps): React.ReactElement {
  const { isSubscriber } = useAuth();
  return (
    <FilterControl disabled={!isSubscriber}>
      <FormLabel id='risk-rating-checkbox-group-label' className='title-tooltip'>
        By Vulnerability Risk Rating
        <Tooltip
          title={
            !isSubscriber
              ? 'This filter is only available to subscribers'
              : 'Measure of vulnerability risk as tracked by Mandiant'
          }
          placement='right'
          arrow
          wrap
        >
          <Icon.Info />
        </Tooltip>
      </FormLabel>
      <FormGroup aria-labelledby='risk-rating-checkbox-group-label'>
        {Object.values(RiskRatings).map(rr => (
          <FormControlLabel
            key={rr}
            control={
              <Checkbox onChange={handleChange} value={rr} checked={values.riskRating.includes(rr as RiskRatings)} />
            }
            label={rr}
          />
        ))}
      </FormGroup>
    </FilterControl>
  );

  function handleChange(e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    const v = e.currentTarget.value as RiskRatings;
    const riskRating = addOrRemoveValue(checked, values.riskRating, v);
    onChange({ riskRating });
  }
}

function checkForCompleteSet(values: RiskRatings[]): boolean {
  return !values?.length || Object.values(RiskRatings).every(rating => values?.includes(rating));
}

function toQuery(values: RiskRatingKeys): Query {
  if (checkForCompleteSet(values.riskRating)) return;

  return {
    field: 'risk_rating',
    op: Ops.in,
    value: values.riskRating
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('risk_rating'),
    op: zod.literal(Ops.in),
    value: zod.array(zod.union([zod.nativeEnum(RiskRatings), zod.literal(null)]))
  })
  .transform(query => ({
    riskRating: query.value.map(v => v || null)
  }));

const VulnerabilityRiskRatingFilterConfig: FilterConfig<RiskRatingKeys> = {
  defaults: { default: () => ({ riskRating: Object.values(RiskRatings) }) },
  supportedTopics: [ArtifactType.Collection],
  component: VulnerabilityRiskRatingFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default VulnerabilityRiskRatingFilterConfig;
