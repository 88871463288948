import React, { ReactElement, SVGProps, isValidElement } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faApple, faDocker, faLinux, faWindows } from '@fortawesome/free-brands-svg-icons';
import { faClipboardListCheck } from '@fortawesome/pro-light-svg-icons';
import { faAt, faBug, faBurst, faGearComplexCode } from '@fortawesome/pro-regular-svg-icons';
import {
  faBars,
  faBinary,
  faBowArrow,
  faBrain,
  faCheckCircle,
  faCircle,
  faCircleAmpersand,
  faCloud,
  faCode,
  faCrosshairs,
  faDatabase,
  faDisplayCode,
  faEdit,
  faExclamationCircle,
  faExclamationTriangle,
  faFilter,
  faFingerprint,
  faHelicopterSymbol,
  faInfoCircle,
  faLayerGroup,
  faLock,
  faNetworkWired,
  faSave,
  faScrewdriverWrench,
  faSpinnerThird,
  faUserSecret,
  faVirus
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { Palette, styled, useTheme } from '../util';
import { saMitreAttack } from './assets';

type SVGIconProps = {
  className?: string;
  color?: string;
  size?: FontAwesomeIconProps['size'];
  svg: ReactElement<SVGProps<SVGSVGElement>>;
};

function parseColor(color: string, palette: Palette) {
  if (!color) return;
  if (color === 'grey') return palette.grey[500];
  if (color in palette) return palette[color]?.main;
  if (color in palette.surface) return palette.surface[color]?.main || palette.surface[color];
  return color;
}

type Props = Omit<FontAwesomeIconProps, 'icon'> & {
  icon: IconProp | JSX.Element;
};

function SVGIcon({ className, color, size, svg }: SVGIconProps) {
  const { palette } = useTheme();

  const sizeClass = size ? `fa-${size}` : '';
  return (
    <>
      {React.cloneElement(svg, {
        className: classnames(className, 'svg-inline--fa', sizeClass),
        color: parseColor(color, palette)
      })}
    </>
  );
}
function Icon({ icon, color, ...others }: Props) {
  const { palette } = useTheme();

  const isSVG = isValidElement(icon);
  if (isSVG) return <SVGIcon svg={icon} size={others.size} color={color} className={others.className} />;

  const iconProp = icon as IconProp;
  return <FontAwesomeIcon color={parseColor(color, palette)} icon={iconProp} {...others} />;
}

export type CannedIconProps = Omit<FontAwesomeIconProps, 'icon'>;

function Brain({ color = 'warning', ...others }: CannedIconProps): ReactElement {
  return <Icon icon={faBrain} color={color} {...others} />;
}

function Crosshair({ color = 'info', ...others }: CannedIconProps): ReactElement {
  return <Icon icon={faCrosshairs} color={color} {...others} />;
}

function Binary(props: CannedIconProps): ReactElement {
  return <Icon icon={faBinary} {...props} />;
}

function AttackScript({ color = 'primary', ...others }: CannedIconProps): ReactElement {
  return <Icon icon={faGearComplexCode} color={color} {...others} />;
}

function Burst({ color = 'error', ...others }: CannedIconProps): ReactElement {
  return <Icon icon={faBurst} color={color} {...others} />;
}

function Collection(props: CannedIconProps): ReactElement {
  return <Icon icon={faLayerGroup} {...props} />;
}

function Save(props: CannedIconProps) {
  return <Icon icon={faSave} {...props} />;
}

function Edit(props: CannedIconProps) {
  return <Icon icon={faEdit} {...props} />;
}

function Error({ color = 'error', ...others }: CannedIconProps) {
  return <Icon icon={faExclamationCircle} color={color} {...others} />;
}

function Warning({ color = 'orange', ...others }: CannedIconProps) {
  return <Icon icon={faExclamationTriangle} color={color} {...others} />;
}

function Info({ color = 'primary', ...others }: CannedIconProps) {
  return <Icon icon={faInfoCircle} color={color} {...others} />;
}

function Success({ color = 'success', ...others }: CannedIconProps) {
  return <Icon icon={faCheckCircle} color={color} {...others} />;
}

function Filter(props: CannedIconProps) {
  return <Icon icon={faFilter} {...props} />;
}

function Fingerprint(props: CannedIconProps) {
  return <Icon icon={faFingerprint} {...props} />;
}

function SpinnerProgress(props: CannedIconProps) {
  return <Icon icon={faSpinnerThird} className={classnames(props.className, 'sa-spin-faster')} spin {...props} />;
}

function Landing(props: CannedIconProps) {
  return <Icon icon={faHelicopterSymbol} {...props} />;
}

function Lock(props: CannedIconProps) {
  return <Icon icon={faLock} {...props} />;
}

function Alias(props: CannedIconProps) {
  return <Icon icon={faAt} {...props} />;
}

function Actor(props: CannedIconProps) {
  return <Icon icon={faUserSecret} {...props} />;
}

function Attack(props: CannedIconProps) {
  return <Icon icon={faCircleAmpersand} {...props} />;
}

function AttackColored(props: CannedIconProps) {
  // This is just the circleAmpersand duotone logo as an SVG, so we don't have to bring along the whole library
  // https://fontawesome.com/icons/circle-ampersand?f=classic&s=duotone
  return <Icon icon={saMitreAttack} {...props} />;
}

function DataSource(props: CannedIconProps) {
  return <Icon icon={faDatabase} {...props} />;
}

function Software(props: CannedIconProps) {
  return <Icon icon={faCode} {...props} />;
}

function Malware(props: CannedIconProps) {
  return <Icon icon={faVirus} {...props} />;
}

function Tool(props: CannedIconProps) {
  return <Icon icon={faScrewdriverWrench} {...props} />;
}

function Vulnerability(props: CannedIconProps) {
  return <Icon icon={faBug} {...props} />;
}

function Nist(props: CannedIconProps) {
  return <Icon icon={faClipboardListCheck} {...props} />;
}

function Windows(props: CannedIconProps) {
  return <Icon icon={faWindows} {...props} />;
}

function Linux(props: CannedIconProps) {
  return <Icon icon={faLinux} {...props} />;
}

function MacOS(props: CannedIconProps) {
  return <Icon icon={faApple} {...props} />;
}

function Cloud(props: CannedIconProps) {
  return <Icon icon={faCloud} {...props} />;
}

function Network(props: CannedIconProps) {
  return <Icon icon={faNetworkWired} {...props} />;
}

function Containers(props: CannedIconProps) {
  return <Icon icon={faDocker} {...props} />;
}

function Platform(props: CannedIconProps) {
  return <Icon icon={faDisplayCode} {...props} />;
}

function Menu() {
  return <Icon.Stacked shrink bottom={<Icon icon={faCircle} color='gray' />} top={<Icon icon={faBars} />} />;
}

function Hunt(props: CannedIconProps) {
  return <Icon icon={faBowArrow} {...props} />;
}

type StackedIconProps = {
  className?: string;
  top: JSX.Element;
  bottom: JSX.Element;
  shrink?: boolean;
  offset?: boolean;
};

const StackedContainer = styled('span')`
  min-width: 16px;

  .shrink svg {
    height: 0.6rem !important;
  }

  .offset svg {
    margin-left: -${p => p.theme.spacing(2)};
  }
`;

function StackedIcon({ className, top, bottom, shrink, offset }: StackedIconProps) {
  return (
    <StackedContainer className={classnames('StackedIcon fa-layers fa-fw', className)}>
      {bottom}
      <span className={classnames({ shrink, offset })}>{top}</span>
    </StackedContainer>
  );
}

Icon.Intel = Brain;
Icon.Session = Burst;
Icon.Analytic = Crosshair;
Icon.Collection = Collection;
Icon.IOC = Fingerprint;
Icon.AttackScript = AttackScript;
Icon.AttackPlan = Binary;
Icon.Save = Save;
Icon.Edit = Edit;
Icon.Error = Error;
Icon.Warning = Warning;
Icon.Info = Info;
Icon.Success = Success;
Icon.Filter = Filter;
Icon.SpinnerProgress = SpinnerProgress;
Icon.Landing = Landing;
Icon.Lock = Lock;
Icon.Alias = Alias;
Icon.Actor = Actor;
Icon.Attack = Attack;
Icon.AttackColored = AttackColored;
Icon.DataSource = DataSource;
Icon.Software = Software;
Icon.Malware = Malware;
Icon.Tool = Tool;
Icon.Vulnerability = Vulnerability;
Icon.Nist = Nist;
Icon.Menu = Menu;
Icon.Hunt = Hunt;

Icon.Stacked = StackedIcon;

// Platforms
Icon.Windows = Windows;
Icon.Linux = Linux;
Icon.Mac = MacOS;
Icon.Cloud = Cloud;
Icon.Network = Network;
Icon.Containers = Containers;
Icon.Platform = Platform;

export default Icon;
