import { Tracked } from 'types/common';
import { Filter, Query } from 'types/filter';

import { CollectionDiscriminator } from './Collection.type';

export function isLandingType<T extends Tracked & { type?: string }>(item: T) {
  return (
    item.type &&
    (item.type === CollectionDiscriminator.Actor ||
      item.type === CollectionDiscriminator.Attack ||
      item.type === CollectionDiscriminator.Software ||
      item.type === CollectionDiscriminator.Vulnerability)
  );
}

export function guidsFromCompositeFilterFor(obj: Query): Filter | undefined {
  if (typeof obj !== 'object' || obj === null) return undefined;

  if ('field' in obj) {
    if (obj.field === 'guid') return obj;
    return undefined;
  } else if (Array.isArray(obj?.items)) {
    for (let i = 0; i < obj.items.length; i++) {
      return guidsFromCompositeFilterFor(obj.items[i]);
    }
  }

  for (const prop in obj) {
    if ('field' === prop) {
      const value = guidsFromCompositeFilterFor(obj);
      if (value !== undefined) {
        return value;
      }
    }
  }

  return undefined;
}
