import { ErrorProps } from 'module/ApiError';
import { AttackType } from 'module/Tag/Tag.type';

export const Catalog = {
  attempted_url: 'accumsan',
  bas_tool: 'bictum',
  language: 'laoreet',
  [AttackType.Tactic]: 'Tristique',
  action: 'alwquam',
  allAttackTags: 'alwquflam',
  soc_title: 'stultus',
  soc_capacity: 'scelerisque',
  landing: 'lacinia',
  demo_mode: 'dominanst',
  clipboard: 'praedo',
  maintenance: 'constructio',
  ad_hoc_queries: 'bacc-dorrio'
} as const;

export type Catalog = (typeof Catalog)[keyof typeof Catalog];

export enum Scheme {
  localStorage = 'localStorage',
  sessionStorage = 'sessionStorage'
}

export type Cancelable = {
  canceled?: boolean;
};

export enum Status {
  pending = 'pending',
  resolved = 'resolved',
  rejected = 'rejected',
  idle = 'idle'
}

export enum Cause {
  reset = 'reset'
}

export type Meta<Signature> = {
  status: Status;
  data: Signature;
  error: any;
  errorProps?: ErrorProps;
};

export interface Helper<Signature> {
  setData: (data: React.SetStateAction<Signature>, status?: Status) => void;
  setError: (value: any) => void;
  run: (promise: Promise<Signature>, preserve?: boolean) => void;
  task?: <T>(promise: Promise<T>) => Promise<T>;
  reset: () => void;
}

export type Action<Signature> =
  | { type: Status.resolved; data: Signature }
  | { type: Status.rejected; error: any; data: Signature }
  | { type: Status.pending; preserve: boolean; data: Signature }
  | { type: Cause.reset; data: Signature };

export type Initializer<Signature> = {
  status: Status.idle;
  data: Signature;
  error: any;
  _suffix: string;
  _key: string;
  _type: Scheme;
};
