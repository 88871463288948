import React from 'react';

import { faSave, faTrash, faX } from '@fortawesome/pro-solid-svg-icons';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import { OutlinedAccordion } from 'snap-ui/Accordion';
import { ActionIconButton } from 'snap-ui/Button';
import {
  DataGrid,
  FooterPropsOverrides,
  GridEventListener,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowEditStopReasons,
  GridRowModel,
  GridRowModes,
  GridToolbarQuickFilter
} from 'snap-ui/DataGrid';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import useTitle from 'hooks/useTitle';

import { Status, useAsync } from 'storage';

import {
  addBlacklistValue,
  addBlacklistValueAndAlias,
  deleteBlacklistValue,
  deleteBlacklistValueWithAliases
} from './Blacklist.api';
import { transformBlacklist } from './Blacklist.service';
import { Restricted, ValidityModel } from './Blacklist.type';
import { BlacklistFooter } from './BlacklistFooter';
import { SearchAliasesEditCell } from './SearchAliasesEditCell';
import { SearchTagEditCell } from './SearchTagEditCell';

const Container = styled('div', { label: 'Blacklist' })`
  .MuiDataGrid-root {
    margin-top: ${p => p.theme.spacing(5)};
    margin-bottom: ${p => p.theme.spacing(8)};
    height: calc(100vh - 16rem);
  }

  .MuiDataGrid-footerContainer {
    padding: ${p => p.theme.spacing(1, 3, 1, 1)};
    justify-items: space-between;
  }

  .QuickFilter {
    width: 300px;
    padding: ${p => p.theme.spacing(3)};
  }
`;

/**
 * Step 1. A new row is added
 * Step 2. A named tag is selected. This component leverages autocomplete endpoint with "only names" true
 * Step 3. A secondary request is sent out to populate aliases. This component leverages autocomplete endpoint with "only names" false.
 * Step 4. Alias data is purged of duplicates and matched against the selected named tag's sigma tags
 * Step 5. An name or alias can then be selected
 */
export function Blacklist() {
  useTitle('Blacklist | SnapAttack');
  const { data, run, setData, task, status } = useAsync<Awaited<ReturnType<typeof transformBlacklist>>>([]);
  const [trashModel, setTrashModel] = React.useState<{ [index: string]: boolean }>({});
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModel>({});
  const [validityModel, setValidityModel] = React.useState<ValidityModel>({});

  const handleRowModesModelChange = (newRowModesModel: GridRowModel) => setRowModesModel(newRowModesModel);

  const handleRowUpdate = async (next: GridRowModel & Restricted) => {
    if (!validityModel[next.id]) return next;

    const updatedRow = { ...next, isNew: false };
    if (next.blacklistedAliases) {
      await task(addBlacklistValueAndAlias(next.value, next.blacklistedAliases));
    } else {
      await task(addBlacklistValue(next.value));
    }

    setData(data.map(d => (d.id === next.id ? updatedRow : d)));
    return updatedRow;
  };

  const handleSaveValue = (row: GridRowModel) =>
    setRowModesModel({
      ...rowModesModel,
      [row.id]: { mode: GridRowModes.View, blacklistedAliases: row.blacklistedAliases }
    });

  const handleCancelValue = (row: GridRowModel) => {
    setRowModesModel({
      ...rowModesModel,
      [row.id]: { mode: GridRowModes.View, ignoreModifications: true }
    });
    setValidityModel(model => {
      const m = { ...model };
      delete m[row.id];
      return m;
    });

    const editedRow = data.find(r => r.id === row.id);
    if (editedRow?.isNew) setData(data.filter(d => d.id !== row.id));
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (
      (params.id in validityModel && !validityModel[params.id]) ||
      params.reason === GridRowEditStopReasons.rowFocusOut
    )
      event.defaultMuiPrevented = true;
  };

  const handleDeleteValue = (row: GridRowModel) => {
    setTrashModel(model => ({ ...model, [row.id]: true }));

    let taskPromise;
    if (isEmpty(row.blacklistedAliases)) {
      taskPromise = task(deleteBlacklistValue(row.id));
    } else {
      taskPromise = task(deleteBlacklistValueWithAliases(row.id, row.blacklistedAliases));
    }

    taskPromise.then(() => setData(data.filter(d => d.id !== row.id)));
  };

  React.useEffect(() => {
    run(transformBlacklist());
  }, [run]);

  return (
    <Container>
      <Typography variant='h1' gutterBottom>
        Tag Blacklist
      </Typography>
      <OutlinedAccordion
        summary='How does blacklisting work?'
        details={
          <>
            <Typography gutterBottom>
              Blacklisting a tag prevents it from being automatically applied to content if there is a match.
              Blacklisting is required for common, overly generic words. Update the blacklist, then manually untag
              content in bulk via the feed page.
            </Typography>
            <br />
            <Typography>
              Blacklisting an alias prevents unlinks the association between a tag and an alias. For example, Mandiant
              has aliases of &apos;AdFind&apos; and &apos;Metasploit&apos; on Cobalt Strike&apos;s &apos;BEACON&apos;,
              which causes us to show AdFind and Metasploit content on the BEACON page because of the associated
              aliases. Blacklisting those aliases will remove the unwanted content from the associations. As landing
              page content is generated dynamically, no additional actions are needed other than updating the alias
              blacklist.
            </Typography>
          </>
        }
      />
      <DataGrid
        loading={status === Status.pending}
        rows={data}
        editMode='row'
        processRowUpdate={handleRowUpdate}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        isCellEditable={p =>
          (p.id in rowModesModel && rowModesModel[p.id].mode === GridRowModes.Edit) ||
          validityModel[p.id]?.validity === false
        }
        columns={[
          {
            headerName: 'Name',
            field: 'value',
            flex: 1,
            editable: true,
            renderEditCell: (params: GridRenderEditCellParams) => (
              <SearchTagEditCell
                {...params}
                onChange={(validity, sigmaTags) => {
                  setValidityModel(model => ({
                    ...model,
                    [params.id]: { validity, sigmaTags, aliasSelected: false }
                  }));
                }}
              />
            )
          },
          {
            headerName: 'Alias',
            field: 'blacklistedAliases',
            flex: 1,
            editable: true,
            renderCell: (params: GridRenderCellParams) =>
              isArray(params.value) ? params.value?.join(', ') : params.value,
            renderEditCell: (params: GridRenderEditCellParams) => (
              <SearchAliasesEditCell
                {...params}
                key={params.row.value}
                isValidValueField={validityModel[params.row.id]?.validity}
                sigmaTags={validityModel[params.row.id]?.sigmaTags}
                onChange={(aliasSelected: boolean) =>
                  setValidityModel(model => ({
                    ...model,
                    [params.id]: { ...model[params.id], aliasSelected }
                  }))
                }
              />
            )
          },
          {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            getActions: ({ row }) => {
              const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;
              return isInEditMode
                ? [
                    <Tooltip
                      key='save'
                      title={
                        !validityModel[row.id]?.validity
                          ? ''
                          : validityModel[row.id]?.validity === true
                          ? 'Save item'
                          : 'Select an item'
                      }
                      arrow
                      placement='top'
                      wrap
                    >
                      <ActionIconButton
                        aria-label={`save ${row.value}`}
                        icon={faSave}
                        onClick={() => handleSaveValue(row)}
                        disabled={!validityModel[row.id]?.validity}
                        color={!validityModel[row.id]?.validity ? 'secondary' : 'default'}
                      />
                    </Tooltip>,
                    <Tooltip key='cancel' title='Cancel add item' arrow placement='top'>
                      <ActionIconButton
                        aria-label={`cancel ${row.value}`}
                        icon={faX}
                        data-id={row.id}
                        onClick={() => handleCancelValue(row)}
                        color={validityModel[row.id] ? 'default' : 'error'}
                      />
                    </Tooltip>
                  ]
                : [
                    row.id in trashModel ? (
                      <Icon.SpinnerProgress key='delete-progress' />
                    ) : row.id in validityModel && !validityModel[row.id]?.validity ? (
                      <Tooltip key='cancel' title='Item is not a tag' arrow placement='top'>
                        <ActionIconButton
                          aria-label={`cancel ${row.value}`}
                          icon={faX}
                          data-id={row.id}
                          onClick={() => handleCancelValue(row)}
                          color={validityModel[row.id]?.validity ? 'default' : 'error'}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip key='delete' title='Delete item' arrow placement='top'>
                        <ActionIconButton
                          aria-label={`delete ${row.value} control`}
                          icon={faTrash}
                          data-id={row.id}
                          onClick={() => handleDeleteValue(row)}
                        />
                      </Tooltip>
                    )
                  ];
            }
          }
        ]}
        slots={{
          footer: BlacklistFooter,
          toolbar: () => <GridToolbarQuickFilter variant='outlined' className='QuickFilter' />
        }}
        slotProps={{
          footer: { setRows: setData, setRowModesModel, isActing: status === Status.pending } as FooterPropsOverrides,
          toolbar: { showQuickFilter: true }
        }}
      />
    </Container>
  );
}
