import React from 'react';

import { useAuth } from 'provider';

import { FunctionalPermission } from 'types/auth';

export type Requester = (action: FunctionalPermission) => boolean;

/**
 * create a function that can be used to check the preferred organization for permission to do a given FunctionPermission
 */
export default function useRequester(): Requester {
  const { defaultOrgId, permission, user } = useAuth();

  const requester = React.useCallback(
    (action: FunctionalPermission): boolean => {
      return (
        user.superuser ||
        permission.some(
          org =>
            org.id === defaultOrgId &&
            ((org.permission as FunctionalPermission[]).includes(action) ||
              org.permission.includes(FunctionalPermission.All))
        )
      );
    },
    [defaultOrgId, permission, user.superuser]
  );

  return requester;
}
