import React from 'react';

import classNames from 'classnames';

import { DataGridProps, GridRowSelectionModel } from 'snap-ui/DataGrid';
import { styled } from 'snap-ui/util';

import Card from 'module/Card';
import Grid from 'module/DisplayGrid';
import TablePagination from 'module/Widgets/TablePagination';

import { Artifact, ArtifactType, Guid } from 'types/common';

import { DisplayKind } from './Feed.type';

type Props = {
  gridApiRef?: DataGridProps['apiRef'];
  page: number;
  total: number;
  onChangePage(newPage: number): void;
  displayKind: DisplayKind;
  hasActivity?: boolean;
  items: Artifact[];
  isPending?: boolean;
  onDelete?(id: Guid): void;
  topic: ArtifactType;
  hideCheckboxes?: boolean;
  selectionModel?: GridRowSelectionModel;
  onSelectionModelChange?(model: Guid[]): void;
  zeroIndex?: boolean;
};

const Container = styled('div')`
  .Card.item:not(:last-of-type) {
    margin: ${p => p.theme.spacing(4, 0)};
  }

  .Card.item:first-of-type {
    margin-top: 0;
  }
`;

export default function FeedItems({ displayKind, ...others }: Props) {
  switch (displayKind) {
    case DisplayKind.Grid:
      return <DisplayAsGrid {...others} />;
    case DisplayKind.Card:
    default:
      return <DisplayAsCard {...others} />;
  }
}

function DisplayAsCard({
  items,
  onDelete,
  topic,
  ...others
}: Omit<Props, 'displayKind' | 'hasActivity' | 'gridApiRef'>) {
  return (
    <Container className={classNames(`FeedItems-${DisplayKind.Card}`)}>
      {items.map(item => (
        <Card className='item' key={item.short_id || item.guid} item={item} onDelete={onDelete} topic={topic} />
      ))}
      <TablePagination
        changePage={others.onChangePage}
        numPages={others.total}
        page={others.page}
        zeroIndex={others.zeroIndex}
      />
    </Container>
  );
}

function DisplayAsGrid(props: Omit<Props, 'displayKind' | 'hasActivity'>) {
  return (
    <Grid
      gridApiRef={props.gridApiRef}
      items={props.items}
      isPending={props.isPending}
      className={`FeedItems-${DisplayKind.Grid}`}
      hideCheckboxes={props.hideCheckboxes}
      selectionModel={props.selectionModel}
      onSelectionModelChange={props.onSelectionModelChange}
      topic={props.topic}
    >
      <TablePagination
        className='with-grid'
        changePage={props.onChangePage}
        numPages={props.total}
        page={props.page}
        zeroIndex={props.zeroIndex}
      />
    </Grid>
  );
}
