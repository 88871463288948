import React from 'react';

import { useIDEValue } from 'module/IDE';
import DelayedTextField from 'module/Widgets/DelayedTextField';

interface TitleFieldProps {
  readonly?: boolean;
}

function TitleField({ readonly }: TitleFieldProps): React.ReactElement {
  const { value, setValue } = useIDEValue('title');

  return (
    <DelayedTextField
      className='Form-field required'
      elevated
      label='Title'
      name='title'
      placeholder='A brief title for the rule that describes what it is supposed to detect'
      aria-label='detection title'
      disabled={readonly}
      onChange={v => setValue(v)}
      value={value}
      data-testid='title'
    />
  );
}

export default TitleField;
