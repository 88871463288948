import React from 'react';

import Typography from 'snap-ui/Typography';

import { CategoryContainer, CategorySection, SubTitle } from '../../Landing.style';
import { useLandingCatalog } from '../../LandingProvider';

export default function Exploitation() {
  const { source } = useLandingCatalog();

  if (!source.exploitation_consequences && !source.exploitation_vectors) return null;
  return (
    <CategorySection>
      <Typography variant='h4'>Exploitation</Typography>
      <CategoryContainer className='row'>
        <div className='category-detail-item'>
          <SubTitle variant='subtitle2'>Exploitation Consequence</SubTitle>
          {source.exploitation_consequences?.map(consequence => (
            <div key={consequence} className='category-detail-item'>
              <Typography variant='h5' className='sa-cap'>
                {consequence}
              </Typography>
            </div>
          ))}
        </div>
        <div className='category-detail-item'>
          <SubTitle variant='subtitle2'>Exploitation Vectors</SubTitle>
          {source.exploitation_vectors?.map(vectors => (
            <div key={vectors} className='category-detail-item'>
              <Typography variant='h5' className='sa-cap'>
                {vectors}
              </Typography>
            </div>
          ))}
        </div>
      </CategoryContainer>
    </CategorySection>
  );
}
