import React from 'react';

import { faAdd } from '@fortawesome/pro-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';

import Button from 'snap-ui/Button';
import {
  GridFooterContainer,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  useGridApiContext
} from 'snap-ui/DataGrid';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';

import { useScrollRowIntoViewById } from '../../snap-ui/DataGrid/useScrollRowIntoViewById';

type BlacklistFooter = {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  isActing: boolean;
};

export function BlacklistFooter(props: BlacklistFooter) {
  const apiRef = useGridApiContext();
  const [uuid, setUuid] = React.useState<string>();
  const { setRows, setRowModesModel } = props;
  useScrollRowIntoViewById(uuid, setUuid);

  const handleNewRow = () => {
    const id = uuidv4();
    setRows(oldRows => [...oldRows, { id, value: '', isNew: true, blacklistedAliases: null }]);
    setRowModesModel(oldModel => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'value' }
    }));
    setUuid(id);
  };

  return (
    <GridFooterContainer>
      <Button
        color='inherit'
        variant='text'
        endIcon={<Icon icon={faAdd} />}
        onClick={handleNewRow}
        disabled={props.isActing}
      >
        ADD ENTRY
      </Button>
      <Typography>Total rows: {apiRef.current.getRowsCount()}</Typography>
    </GridFooterContainer>
  );
}
