import React from 'react';

import { faCircleX } from '@fortawesome/pro-light-svg-icons';
import { faFileImport, faPaste } from '@fortawesome/pro-solid-svg-icons';

import { ActionIconButton } from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import TextField from 'snap-ui/TextField';

export function UnfurlTextField({
  disabled,
  handleUnfurl,
  source,
  setSource,
  error
}: {
  disabled?: boolean;
  handleUnfurl(source: string): void;
  source: string;
  setSource(source: string);
  error?: string;
}): JSX.Element {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSource(event.target.value);
  };

  const handlePress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleUnfurl(source);
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) =>
    handleUnfurl(event.clipboardData.getData('text'));

  return (
    <TextField
      id='unfurl-input'
      className='Unfurl-textfield'
      aria-label='Unfurl link summary into form'
      placeholder='mylink.com'
      onChange={handleChange}
      onPaste={handlePaste}
      value={source}
      disabled={disabled}
      startAdornment={<Icon icon={error ? faCircleX : faPaste} color={error ? 'warning' : null} />}
      helperText={error ? "We couldn't make sense of that" : ''}
      error={!!error}
      onKeyPress={handlePress}
      InputProps={{
        endAdornment: (
          <ActionIconButton
            onClick={() => handleUnfurl(source)}
            aria-label='Quick add to unfurl link'
            icon={faFileImport}
            disabled={!source}
          />
        )
      }}
    />
  );
}
