import React from 'react';

import startCase from 'lodash/startCase';

import { List, ListItem } from 'snap-ui/List';

import { BASAgent } from 'types/bas';

import { ManualAgent } from './BAS.type';

type AgentMetadataProps = {
  agent: BASAgent;
  isManual?: boolean;
  manualAgent?: ManualAgent;
};

export default function AgentMetadata({ agent, isManual = false, manualAgent }: AgentMetadataProps): JSX.Element {
  const hostMetaData = agent?.host_metadata || {};
  const osName = isManual ? manualAgent?.victim_operating_system : startCase(agent?.os_name);

  const manualMetaData = () => (
    <>
      <ListItem disablePadding>Victim Host Name: {manualAgent?.victim_hostname}</ListItem>
      <ListItem disablePadding>Victim IP: {manualAgent?.victim_ip}</ListItem>
      <ListItem disablePadding>Attacker Host Name: {manualAgent?.attacker_hostname}</ListItem>
      <ListItem disablePadding>Attacker IP: {manualAgent?.attacker_ip}</ListItem>
    </>
  );

  const agentMetaData = Object.entries(hostMetaData).map(([key, value]) => (
    <ListItem key={key} disablePadding>
      {startCase(key)}: {value}
    </ListItem>
  ));

  return (
    <List disablePadding>
      {!isManual && <ListItem disablePadding>Current Status: {agent?.is_online ? 'Online' : 'Offline'}</ListItem>}
      <ListItem disablePadding>Operating System: {osName}</ListItem>
      {isManual ? manualMetaData() : agentMetaData}
    </List>
  );
}
