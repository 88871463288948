import React from 'react';

import classnames from 'classnames';
import { Form, FormikFormProps } from 'formik';

type FormFormikProps = FormikFormProps & {
  className?: string;
};

function FormFormik({ className, ...props }: FormFormikProps): React.ReactElement {
  // noValidate: disable built-in browser validation because it's generally not very good
  // and interferes with our in-JS validation
  return <Form className={classnames('ui form', className)} noValidate {...props} />;
}

export default FormFormik;
