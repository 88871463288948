export const URL_PARAMS_SCHEMA = {
  detection: String,
  row_id: String,
  hostname: String,
  threat: String
};

export const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';

export const LOG_UNIQUE_ID_FIELD = 'row_id';
