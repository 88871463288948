import React from 'react';

import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { RJSFSchema } from '@rjsf/utils';
import toNumber from 'lodash/toNumber';

import { ActionIconButton } from 'snap-ui/Button';
import Tooltip from 'snap-ui/Tooltip';

import { JobType } from 'module/Job';

import { Guid } from 'types/common';
import { Query } from 'types/filter';

import { CreateJobSchedulePayload } from '../Integration.type';
import { useScheduledJobsContext } from '../useScheduledJobs';
import ConfigureTaskModal from './ConfigureTaskModal';

type EditTaskButtonProps = {
  name: string;
  type: JobType;
  title?: string;
  integration_guid: Guid;
  criteria: string;
  analytic_filter: Query;
  cron_trigger: string;
  schedule_guid: Guid;
  jobSchema: RJSFSchema;
  extra_parameters: Record<string, unknown>;
};

function EditTaskButton({
  name,
  type,
  title = 'Edit Confidence Tailoring Task',
  integration_guid,
  criteria,
  analytic_filter,
  cron_trigger,
  schedule_guid,
  jobSchema,
  extra_parameters
}: EditTaskButtonProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const { updateJobSchedule, taskStatus } = useScheduledJobsContext();

  function handleSubmit(payload: CreateJobSchedulePayload): void {
    updateJobSchedule(integration_guid, schedule_guid, payload);
  }

  return (
    <>
      <Tooltip arrow title={`Edit ${name}`}>
        <ActionIconButton key='Edit' aria-label='Edit Job' icon={faEdit} onClick={() => setIsOpen(true)} />
      </Tooltip>
      <ConfigureTaskModal
        title={title}
        type={type}
        jobSchema={jobSchema}
        onClose={() => setIsOpen(false)}
        initialValues={{
          name,
          criteria,
          cron_trigger,
          extra_parameters,
          parameters: {
            auto_accept: type === JobType.Rank ? Boolean(extra_parameters.auto_accept) : undefined,
            max_results_per_query: toNumber(extra_parameters.max_results_per_query),
            max_search_window_seconds: toNumber(extra_parameters.max_search_window_seconds),
            raw_logs: Boolean(extra_parameters.raw_logs).toString()
          }
        }}
        status={taskStatus}
        onSubmit={handleSubmit}
        isOpen={isOpen}
        integration_guid={integration_guid}
        analytic_filter={analytic_filter}
      />
    </>
  );
}

export default EditTaskButton;
