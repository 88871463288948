import { snapattack } from 'apis';

import { Guid } from 'types/common';

import { NistControl } from '../NistDashboard/NistDashboard.type';
import { CoverageData, StatsPayload, DetectionData } from './DetectionDashboard.type';

const prefix = '/stats';

export function getCoverageStats(stats: StatsPayload): Promise<CoverageData[]> {
  return snapattack.post(`${prefix}/coverage/historical/`, stats).then(r => r.data);
}

export function getNistStats(organizationGuid: Guid): Promise<NistControl[]> {
  return snapattack.post(`${prefix}/coverage/nist/${organizationGuid}/`).then(r => r.data);
}

export function getDetectionStats(stats: StatsPayload): Promise<DetectionData[]> {
  return snapattack.post(`${prefix}/detections/`, stats).then(r => r.data);
}
