import React from 'react';

import { Formik } from 'formik';

import { AlertTitle, BuffAlert } from 'snap-ui/Alert';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import useTitle from 'hooks/useTitle';

import { style } from 'module/Setting/core/Core.style';

import { useAuth } from 'provider';

import { Guid } from 'types/common';

import { useImpersonate } from './';
import ImpersonateForm from './ImpersonateForm';

const Core = styled('div')(() => style);
const Container = styled(Core)`
  max-width: max(400px, 80%);
`;

export type FormType = {
  user: Guid;
};

type Props = {
  className?: string;
};

export default function Impersonate(props: Props) {
  useTitle('Impersonation | SnapAttack');
  const { availableUsers, impersonate, unpersonate, isPending } = useImpersonate();
  const { user } = useAuth();
  const [error, setError] = React.useState<string>();

  const store = React.useMemo(() => {
    let filtered = availableUsers.map(d => ({ id: d.id, content: `${d.name} (${d.email})`, value: d.guid }));

    if (filtered.length === 0 && !!user.impersonator) {
      filtered = [{ id: user.id, content: `${user.name} (${user.email})`, value: user.guid }];
    }
    return filtered;
  }, [availableUsers, user.guid, user.name, user.email, user.id, user.impersonator]);

  const handleImpersonate = async (values: any) =>
    impersonate(values.user, {
      catchCb: e => {
        setError(e?.response?.data?.detail || 'Oops! That was unexpected');
      }
    });
  const handleUnpersonate = async () => unpersonate();

  return (
    <Container className={props.className}>
      <Typography variant='h1'>Impersonate User</Typography>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={false}
        initialValues={{ user: user.impersonator ? user.guid : '' }}
        onSubmit={handleImpersonate}
        validate={values => {
          if (!(values.user?.length > 0)) return { user: 'A user name is required' };
          else return null;
        }}
      >
        {({ values }) => {
          return (
            <ImpersonateForm
              isActive={isPending}
              impersonatedGuid={values.user}
              user={user}
              options={store}
              onCancel={handleUnpersonate}
            />
          );
        }}
      </Formik>
      {error && (
        <BuffAlert severity='error'>
          <AlertTitle>Error</AlertTitle>
          {error}
        </BuffAlert>
      )}
    </Container>
  );
}
