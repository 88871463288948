import React from 'react';

function useConfirmBeforeUnload(condition: boolean, deps: Array<any>): void {
  React.useEffect(() => {
    if (!condition) return;

    const handler = (event): string => {
      const message = 'Are you sure you want to leave?';
      event.returnValue = message;
      event.preventDefault();
      return message;
    };
    window.addEventListener('beforeunload', handler);

    return (): void => {
      window.removeEventListener('beforeunload', handler);
    };
  }, [condition, deps]);
}

export default useConfirmBeforeUnload;
