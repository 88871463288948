import React from 'react';

import { useLocation } from 'react-router-dom';

import { Guid } from 'types/common';

import { searchParamFactory } from 'utilities/SearchParam';

export type GraphParam = {
  hostId: Guid;
  hostname: string;
  searchField: string;
  searchQuery: string;
  threatId: string;
  minTime: number;
  maxTime: number;
  nodeId: string;
  rowId: string;
  processGuid: string;
};

export default function useGraphParam(): GraphParam {
  const { search } = useLocation();

  const param = React.useMemo(() => {
    const factory = searchParamFactory(search);
    return {
      threatId: factory.byName('threatId'),
      hostId: factory.byName('hostId'),
      hostname: factory.byName('hostname'),
      searchQuery: factory.byName('searchQuery'),
      searchField: factory.byName('searchField', 'process_name'),
      minTime: Number(factory.byName('minTime')),
      maxTime: Number(factory.byName('maxTime')),
      nodeId: factory.byName('nodeId'),
      rowId: factory.byName('rowId'),
      processGuid: factory.byName('processGuid')
    };
  }, [search]);

  return param;
}
