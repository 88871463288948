import { useEffect } from 'react';

import { useAsync } from 'storage';

async function fetchText(url: string) {
  if (!url) return '';
  const res = await fetch(url);
  if (!res.ok) throw Error(`${res.status}: ${res.statusText}`);
  return res.text();
}

export default function useKeyStrokes(url: string) {
  const { data, error, run, status } = useAsync<string>('');

  useEffect(() => {
    run(fetchText(url));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return {
    data,
    status,
    error
  };
}
