import React from 'react';

import { faX } from '@fortawesome/pro-solid-svg-icons';
import zod from 'zod';

import { ActionIconButton } from 'snap-ui/Button';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import TextField from 'snap-ui/TextField';
import Tooltip from 'snap-ui/Tooltip';

import { useAuth } from 'provider';

import { ArtifactType } from 'types/common';
import { Ops, Query } from 'types/filter';

import { FilterControl } from '../GlobalFilter.style';
import { FilterConfig } from '../GlobalFilter.type';

type CvssKeys = {
  cvss: string;
};

type CvssFilterProps = {
  onChange(values: CvssKeys): void;
  values: CvssKeys;
};

function VulnerabilityCVSSFilter({ onChange, values }: CvssFilterProps): React.ReactElement {
  const { isSubscriber } = useAuth();
  return (
    <FilterControl disabled={!isSubscriber}>
      <FormLabel id='vulnerability-cvss-group-label' className='title-tooltip'>
        By Vulnerability CVSS Score
        {!isSubscriber && (
          <Tooltip title='This filter is only available to subscribers' placement='right' arrow wrap>
            <Icon.Info />
          </Tooltip>
        )}
      </FormLabel>
      <FormGroup aria-labelledby='vulnerability-cvss-group-label'>
        <TextField
          type='number'
          value={values.cvss}
          helperText={'CVSS Minimum Score'}
          onChange={handleChange}
          disabled={!isSubscriber}
          InputProps={{
            inputMode: 'numeric',
            inputProps: { min: 0, max: 10 },
            endAdornment: (
              <ActionIconButton aria-label={`Clear CVSS`} icon={faX} onClick={clearForm} disabled={!isSubscriber} />
            )
          }}
        />
      </FormGroup>
    </FilterControl>
  );

  function clearForm() {
    onChange({ cvss: '' });
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    onChange({ cvss: e.target.value });
  }
}

function toQuery(values: CvssKeys): Query {
  if (!values.cvss) return;
  return {
    field: 'cvss_3_base_score',
    op: Ops.greater_than_equal,
    value: values.cvss
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('cvss_3_base_score'),
    op: zod.literal(Ops.greater_than_equal),
    value: zod.string()
  })
  .transform(query => ({ cvss: query.value }));

const VulnerabilityCVSSFilterConfig: FilterConfig<CvssKeys> = {
  defaults: { default: () => ({ cvss: '' }) },
  supportedTopics: [ArtifactType.Collection],
  component: VulnerabilityCVSSFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default VulnerabilityCVSSFilterConfig;
