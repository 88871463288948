import React from 'react';

import Paper from 'snap-ui/Paper';
import { styled } from 'snap-ui/util';

import SliderFormik from 'module/Form/SliderFormik';

import ThreatProfileStep from '../ThreatProfileStep';

const Container = styled(Paper, { name: 'SliderContainer' })`
  padding: 60px;
`;

function valuetext(value: number) {
  return `${value}%`;
}

const marks = new Array(11).fill(0).map((val, index) => ({ value: index * 10, label: `${index * 10}%` }));

export default function ExploitLikelihood(): JSX.Element {
  return (
    <ThreatProfileStep
      title='What is your exploit likelihood tolerance?'
      description="The Exploit Prediction Scoring System (EPSS) is a proactive cybersecurity approach designed to assess the likelihood of vulnerabilities being exploited in the future. EPSS combines various factors, such as the vulnerability's characteristics, historical data, and threat intelligence, to generate predictive scores. This system enables organizations to proactively allocate resources and prioritize patching efforts. By focusing on vulnerabilities with higher EPSS scores, organizations can effectively reduce their attack surface and bolster their overall cybersecurity posture, ensuring that limited resources are channeled towards mitigating the most imminent and impactful security risks. Ultimately, EPSS empowers organizations to stay one step ahead of cyber threats, reducing the window of opportunity for adversaries and enhancing their overall resilience."
      content={
        <Container>
          <SliderFormik
            name='exploit_likelihood'
            fixedBoundary='right'
            className='ExploitLikelihood'
            step={10}
            marks={marks}
            valueLabelDisplay='auto'
            valueLabelFormat={valuetext}
          />
        </Container>
      }
    />
  );
}
