import React, { ReactElement } from 'react';

import Path from 'constants/paths';

import { Engage, Fingerprint, standardizeFilter } from 'lib/Engagement';

import { useMatrixSidebar } from 'module/Scaffold/useStashSidebar';

import { useFilter, usePushSnack } from 'provider';

import { ArtifactType } from 'types/common';

import { catalog } from './Matrix.const';
import Content from './Matrix.content';
import { exportMatrixToAttackNavigation } from './Matrix.util';
import useFilterTechnique from './useFilterTechnique';
import useMatrix from './useMatrix';
import useMatrixOptions from './useMatrixOptions';

export default function Matrix(): ReactElement {
  useMatrixSidebar();
  const pushSnack = usePushSnack();
  const topic = ArtifactType.Analytic;
  const filter = useFilter()[topic];
  const meta = useMatrixOptions();
  const { matrix, empty, loading: matrixLoading } = useMatrix();
  const filterMatrix = useFilterTechnique(matrix);
  const loading = matrixLoading || !filterMatrix;

  React.useEffect(() => {
    document.title = 'ATT&CK Matrix | SnapAttack';
    addClassToBody();
    Engage.trackPersonIncrement(`view ${Path.Matrix}`, 1);
    return () => removeClassFromBody();
  }, []);

  const act = `${JSON.stringify(filter)}${JSON.stringify(meta)}`;

  React.useEffect(() => {
    Engage.track(Fingerprint.load(Path.Matrix).withData(standardizeFilter({ ...filter, ...(meta as any) })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [act]);

  React.useEffect(() => {
    if (!loading && empty) pushSnack('This filter selection returned no results', 'warning', 'center');
  }, [loading, empty, pushSnack]);

  return <Content matrix={filterMatrix} loading={loading} onExport={handleExport} />;

  function handleExport() {
    exportMatrixToAttackNavigation(filterMatrix, meta, topic, catalog[meta.palette]);
  }

  function addClassToBody(): void {
    document.body.classList.add('sa-no-navigation');
  }

  function removeClassFromBody(): void {
    document.body.classList.remove('sa-no-navigation');
  }
}
