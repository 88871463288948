import React from 'react';

import { Autocomplete } from 'snap-ui/Autocomplete';
import Button from 'snap-ui/Button';
import CircularProgress from 'snap-ui/CircularProgress';
import Divider from 'snap-ui/Divider';
import { styled } from 'snap-ui/util';

import { useImpersonate } from 'module/Impersonate';
import withFunctionalPermission from 'module/Util/withFunctionalPermission';
import OrganizationAutocompleteOptions from 'module/Widgets/OrganizationAutocompleteOptions';

import { useAuth } from 'provider';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { Guid } from 'types/common';

const Container = styled('div')`
  padding: ${p => p.theme.spacing(2)};
  text-align: center;

  button {
    width: 100%;
  }
`;

const ImpersonateOrganization = withFunctionalPermission(
  function ImpersonateOrganization() {
    const { user } = useAuth();
    const { availableOrgs, orgsStatus, impersonateOrg, unpersonate, isPending } = useImpersonate();

    const [value, setValue] = React.useState<Guid>(() =>
      user?.impersonator ? user?.preferred_organization?.guid : ''
    );

    if (!user.superuser) return null;

    const orgOptions = availableOrgs
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(org => ({
        label: org.name,
        content: <OrganizationAutocompleteOptions guid={org.guid} text={org.name} />,
        value: org.guid
      }));

    const handleImorganzate = async (guid: Guid) => impersonateOrg(guid);
    const handleUnpersonate = async () => unpersonate();

    function handleChange(guid: Guid) {
      setValue(guid);
      if (guid) {
        handleImorganzate(guid);
      } else {
        handleUnpersonate();
      }
    }

    return (
      <>
        <Divider />
        <Container>
          {isPending ? (
            <Button disabled variant='outlined'>
              <CircularProgress size={20} />
            </Button>
          ) : user?.impersonator ? (
            <Button
              id='ClearImpersonation'
              ariaLabel='Clear impersonate for organization'
              variant='outlined'
              onClick={handleUnpersonate}
            >
              Clear Impersonation
            </Button>
          ) : (
            <Autocomplete
              testId='impersonate-organization'
              disableClearable
              disableUserAdditions
              label='Service Account'
              loading={orgsStatus === Status.pending}
              name='impersonate-organization'
              onChange={handleChange}
              options={orgOptions}
              value={value}
            />
          )}
        </Container>
      </>
    );
  },
  FunctionalPermission.ImpersonateUser,
  true
);
export default ImpersonateOrganization;
