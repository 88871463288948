import React from 'react';

import { useHistory } from 'react-router-dom';

import Icon from 'snap-ui/Icon';

import Path from 'constants/paths';

import { Discriminator } from 'module/Tag';
import OrButton from 'module/Widgets/OrButton';

import { ArtifactType } from 'types/common';

type Props = {
  artifact: ArtifactType | Discriminator;
};

export default function Suggest(props: Props) {
  const { push } = useHistory();
  let IconAlias = null;
  let label = '';
  let path = '';

  switch (props.artifact) {
    case ArtifactType.Analytic:
      IconAlias = <Icon.Analytic size='lg' />;
      label = 'Create New Detection';
      path = Path.IDE;
      break;
    case ArtifactType.Session:
      IconAlias = <Icon.Session size='lg' />;
      label = 'Create New Threat';
      path = Path.ThreatUpload;
      break;
    case ArtifactType.Intel:
      IconAlias = <Icon.Intel size='lg' />;
      label = 'Create New Intelligence';
      path = Path.IntelligenceCreate;
      break;
    case ArtifactType.Collection:
      IconAlias = <Icon.Collection size='lg' />;
      label = 'Create New Collection';
      path = Path.CollectionCreate;
      break;
    case ArtifactType.AttackPlan:
      IconAlias = <Icon.AttackPlan size='lg' />;
      label = 'Find Attack Simulations';
      path = Path.BASJob;
      break;
    case ArtifactType.AttackScript:
      IconAlias = <Icon.AttackScript size='lg' />;
      label = 'Create New Attack Script';
      path = Path.AttackScriptCreate;
      break;
    default:
      return null;
  }

  if (IconAlias === null) return null;
  const handleClick = () => push(path);

  return (
    <OrButton onClick={handleClick} color='secondary'>
      {IconAlias}
      {label}
    </OrButton>
  );
}
