import config from 'config/config';

import { CollectionDiscriminator } from 'module/Collection/Collection.type';
import { IndicatorSearchResponse } from 'module/IOC/IOC.type';
import { getArtifactIcon } from 'module/Layout/Artifact.helper';
import { Discriminator, SoftwareType } from 'module/Tag';

import { Artifact, ArtifactType } from 'types/common';

export type CategoryResultItem = {
  to: string;
  title: string[];
  type: Discriminator | CollectionDiscriminator | ArtifactType | SoftwareType;
  preview: boolean;
  data: Artifact;
};

export type CategoryResult = {
  name: string;
  query?: string;
  topic: Parameters<typeof getArtifactIcon>[0];
  results: CategoryResultItem[] | IndicatorSearchResponse[];
  total: number;
};

export const SHOW_TOTAL = config.SEARCH_BAR_SHOW;
export const PER_PAGE = config.SEARCH_BAR_QUERY;
