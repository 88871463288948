import React from 'react';

import Typography from 'snap-ui/Typography';

import useTitle from 'hooks/useTitle';

import withFunctionalPermission from 'module/Util/withFunctionalPermission';

import { FunctionalPermission } from 'types/auth';

import { MitreChartRow, MitreDashboardPage } from '../style';
import BarChart from './BarChart';
import Stats from './Stats';
import Table from './Table';
import useMitreCoverage from './useMitreCoverage';

export const MitreCoverageDashboard = withFunctionalPermission(function MitreCoverageDashboard(): JSX.Element {
  useTitle('MITRE ATT&CK Coverage Report');
  const coverageInterface = useMitreCoverage();

  return (
    <MitreDashboardPage>
      <Typography variant='h1'>MITRE ATT&CK Coverage</Typography>
      <MitreChartRow>
        <Stats {...coverageInterface} />
        <BarChart {...coverageInterface} />
      </MitreChartRow>
      <Table {...coverageInterface} />
    </MitreDashboardPage>
  );
}, FunctionalPermission.MitreCoverageDashboard);

export default MitreCoverageDashboard;
