import React from 'react';

import IFrame from 'snap-ui/IFrame';

import useTitle from 'hooks/useTitle';

import { buildAuditLogsLink } from 'module/Hunt';

const AUDIT_LOGS_DAYS_BACK = 30;
function AuditLogs() {
  useTitle('Audit Logs | SnapAttack');

  const url = buildAuditLogsLink(AUDIT_LOGS_DAYS_BACK);

  return <IFrame src={url} />;
}

export default AuditLogs;
