import React, { ReactElement } from 'react';

import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import { Autocomplete } from 'snap-ui/Autocomplete';
import BackdropLoader from 'snap-ui/BackdropLoader';
import Divider from 'snap-ui/Divider';
import Icon, { IntegrationIcon } from 'snap-ui/Icon';
import Tabs, { TabProps } from 'snap-ui/Tabs';
import Typography from 'snap-ui/Typography';

import useOrganizationOptions from 'hooks/useOrganizationOptions';
import useTitle from 'hooks/useTitle';

import { ApiError } from 'module/ApiError';
import withSubscriberOnly from 'module/Setting/withSubscriberOnly';

import { useAuth, useIntegrationCatalog } from 'provider';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';

import { IntegrationContainer } from './Integration.style';
import IntegrationsForm from './IntegrationForm';
import { IntegrationHealthBadge } from './IntegrationHealth';
import useIntegrationContext, { IntegrationProvider } from './IntegrationProvider';

const Integrations = (): ReactElement => {
  useTitle('Integrations | SnapAttack');

  const { backdropMessage, integrationSchemaCatalogStatus, handleUpdate, handleSave } = useIntegrationContext();
  const ic = useIntegrationCatalog();

  const { defaultOrgId } = useAuth();
  const [selectedOrg, setSelectedOrg] = React.useState(defaultOrgId.toString());
  const orgOptions = useOrganizationOptions(FunctionalPermission.CreateIntegration, true);
  const canAdd = !!orgOptions.length;

  const handleOrgSelect = newOrg => {
    setSelectedOrg(newOrg);
  };

  const tabs = React.useMemo(() => {
    const integrationTabs: TabProps['tabs'] = ic.integrations.editable
      .filter(integration => {
        if (selectedOrg !== null) {
          return integration.organization_id === Number(selectedOrg);
        }
      })
      .map((integration, index) => {
        const { extra_information, name, type, guid } = integration;

        return {
          value: index,
          label: (
            <div className='left-tabs'>
              <IntegrationIcon type={integration?.type} />
              <span className='integration-name'>{integration?.name}</span>
              <IntegrationHealthBadge integration={integration} />
            </div>
          ),
          content: (
            <div className='tab-content'>
              <IntegrationsForm
                integration={integration}
                initialValuesProps={{
                  name,
                  url_template: integration.url_template,
                  organization_id: integration.organization_id?.toString(),
                  deployment_prefix_template: integration.deployment_prefix_template,
                  deployment_targets: integration.deployment_targets?.map(d => d.id.toString()),
                  hunt_targets: integration.hunt_targets?.map(d => d.id.toString()),
                  search_targets: integration.search_targets?.map(d => d.id.toString()),
                  extra_information,
                  type
                }}
                submitForm={handleUpdate(guid)}
              />
            </div>
          )
        };
      });

    if (canAdd) {
      integrationTabs.push({
        value: integrationTabs.length,
        label: <Icon icon={faPlus} />,
        disabled: integrationSchemaCatalogStatus !== Status.resolved,
        content: (
          <div className='tab-content'>
            <IntegrationsForm
              initialValuesProps={{
                name: '',
                url_template: '',
                organization_id: selectedOrg || defaultOrgId?.toString(),
                deployment_prefix_template: '',
                deployment_targets: null,
                hunt_targets: null,
                search_targets: null,
                type: 'CustomerManaged'
              }}
              submitForm={handleSave}
            />
          </div>
        )
      });
    }

    return integrationTabs;
  }, [
    canAdd,
    defaultOrgId,
    handleSave,
    handleUpdate,
    ic.integrations.editable,
    selectedOrg,
    integrationSchemaCatalogStatus
  ]);

  return (
    <IntegrationContainer>
      <Typography variant='h1'>Integrations</Typography>
      <Typography variant='subtitle2' className='sub-text'>
        Configure integrations with your security tools to quickly launch queries and export detections from SnapAttack.
      </Typography>
      <Divider />
      {orgOptions.length > 1 && (
        <Autocomplete
          elevated
          value={selectedOrg}
          label='Organization'
          name='organization_id'
          options={orgOptions}
          disableUserAdditions
          disableClearable
          onChange={handleOrgSelect}
          className='organization-dropdown'
        />
      )}
      <BackdropLoader
        title={backdropMessage}
        open={ic.status === Status.pending || ic.taskStatus === Status.pending}
        fixed
        contained
        zIndexOption='backdropLow'
      />
      <ApiError {...ic.errorProps} />
      {ic.status === Status.resolved && tabs.length === 0 && (
        <Alert className='alert' severity='warning'>
          <AlertTitle>You do not have permission to create integrations</AlertTitle>
        </Alert>
      )}
      <div className='integration-tab'>
        <Tabs nowrap tabs={tabs} orientation='vertical' />
      </div>
    </IntegrationContainer>
  );
};

function IntegrationWrapper() {
  return (
    <IntegrationProvider>
      <Integrations />
    </IntegrationProvider>
  );
}
export default withSubscriberOnly(IntegrationWrapper);
