import React from 'react';

import IFrame from 'snap-ui/IFrame';
import { ExternalLink } from 'snap-ui/Link';
import { styled } from 'snap-ui/util';

import { TUNING_DASHBOARD_PANEL, buildRuleHitsLink } from 'module/Hunt';

import { useAuth } from 'provider/Account';

import { checkTaskPermission } from 'services/authService';

import useAsync from 'storage/useAsync';

import { FunctionalPermission } from 'types/auth';
import { Guid } from 'types/common';

import { getHitCount } from '../Analytic.api';

const Container = styled('div')`
  padding: ${p => p.theme.spacing(3)};

  .dashboard-link {
    display: block;
    margin-bottom: ${p => p.theme.spacing(3)};
    text-align: end;
  }

  & > iframe {
    min-height: 600px;

    &:first-of-type {
      min-height: 250px;
    }
  }
`;

export default function CustomerHits({
  analyticGuid,
  logsource = ''
}: {
  analyticGuid: Guid;
  logsource: string;
}): JSX.Element {
  const dashboardURL = buildRuleHitsLink(analyticGuid, logsource, false);
  const hitsPerDayURL = buildRuleHitsLink(analyticGuid, logsource, true, TUNING_DASHBOARD_PANEL.HITS_PER_DETECTION);
  const rawHitsURL = buildRuleHitsLink(analyticGuid, logsource, true);

  return (
    <Container>
      <ExternalLink className='dashboard-link' icon href={dashboardURL}>
        Go To Dashboard
      </ExternalLink>
      <IFrame src={hitsPerDayURL} />
      <IFrame src={rawHitsURL} />
    </Container>
  );
}

export const HIT_COUNT_DAYS_BACK = 60;
export function useOSHitCount(guid: Guid) {
  const { permission } = useAuth();
  const { data, run } = useAsync<number>();
  const canOS = checkTaskPermission(permission, FunctionalPermission.OpenSearchUIAccess);

  const refresh = React.useCallback(() => {
    run(getHitCount(guid, HIT_COUNT_DAYS_BACK));
  }, [guid, run]);

  React.useEffect(() => {
    if (canOS) refresh();
  }, [canOS, refresh]);

  return { hitCount: data };
}
