export enum APP_MODES {
  COMMUNITY = 'community',
  ENTERPRISE = 'enterprise'
}

export enum FEATURES {
  VOTES,
  BULK_EXPORT,
  INTEL,
  SESSION_UPLOAD
}

export enum APP_DEPLOY {
  LOCAL = 'local',
  DEV = 'dev',
  TEST = 'test',
  DEMO = 'demo',
  PROD = 'production'
}

export const FEATURE_SETS: { [mode: string]: FEATURES[] } = {
  [APP_MODES.COMMUNITY]: [FEATURES.VOTES],
  [APP_MODES.ENTERPRISE]: [],
  [APP_DEPLOY.LOCAL]: [
    FEATURES.BULK_EXPORT,
    FEATURES.INTEL,
    FEATURES.SESSION_UPLOAD
    /* do not turn on engagement for local */
  ],
  [APP_DEPLOY.DEV]: [FEATURES.BULK_EXPORT, FEATURES.INTEL, FEATURES.SESSION_UPLOAD],
  [APP_DEPLOY.TEST]: [FEATURES.BULK_EXPORT, FEATURES.SESSION_UPLOAD, FEATURES.INTEL],
  [APP_DEPLOY.DEMO]: [FEATURES.BULK_EXPORT],
  [APP_DEPLOY.PROD]: [FEATURES.BULK_EXPORT]
};
