import React from 'react';

import { CancelTokenSourceType } from 'apis';

import Path from 'constants/paths';

import { FEED_SKELETON } from 'module/Feed/Feed.const';
import { getSearchPage } from 'module/Feed/Feed.service';
import { Feed } from 'module/Feed/Feed.type';
import { useFilterRegistry } from 'module/GlobalFilter';
import { Associator } from 'module/Widgets/AssociateArtifactsModal';

import { useAsync } from 'storage';

import { Artifact, ArtifactType } from 'types/common';
import { Identity } from 'types/common.zod';
import { Ops } from 'types/filter';

import { postAttackScriptSessions } from '../AttackScript.api';

export type AddSessionsToAttackScriptProps = {
  attackScript: Identity;
  onClose: () => void;
  isOpen: boolean;
};

const ITEMS_PER_PAGE = 50;

export default function AddSessionsToAttackScript({ attackScript, onClose, isOpen }: AddSessionsToAttackScriptProps) {
  const { generateQuery } = useFilterRegistry();
  const {
    data: associatedSessions,
    status: associatedSessionsStatus,
    run: associatedSessionsRun
  } = useAsync<Feed>(FEED_SKELETON);

  const fetchAssociatedSessions = React.useCallback(() => {
    if (!attackScript.id) return;
    associatedSessionsRun(
      getSearchPage(
        ArtifactType.Session,
        1,
        ITEMS_PER_PAGE,
        { field: 'session.bas_scripts.id', op: Ops.equals, value: attackScript.id },
        ''
      )
    );
  }, [associatedSessionsRun, attackScript.id]);

  React.useEffect(() => {
    fetchAssociatedSessions();
  }, [fetchAssociatedSessions]);

  const fetchAllSessions = React.useCallback(
    (searchTerm: string, cancelToken: CancelTokenSourceType) => {
      return getSearchPage(
        ArtifactType.Session,
        1,
        ITEMS_PER_PAGE,
        generateQuery(ArtifactType.Session, { query: searchTerm }),
        '',
        cancelToken
      ).then(data => data.items);
    },
    [generateQuery]
  );

  const associate = React.useCallback(
    (session: Artifact) => {
      return postAttackScriptSessions(attackScript.guid, 'add', [session.guid]).then(fetchAssociatedSessions);
    },
    [attackScript.guid, fetchAssociatedSessions]
  );

  const disassociate = React.useCallback(
    (session: Artifact) => {
      return postAttackScriptSessions(attackScript.guid, 'delete', [session.guid]).then(fetchAssociatedSessions);
    },
    [attackScript.guid, fetchAssociatedSessions]
  );

  return (
    <Associator
      associate={associate}
      associatedRecords={associatedSessions.items}
      associatedRecordsStatus={associatedSessionsStatus}
      disassociate={disassociate}
      fetchAllOptions={fetchAllSessions}
      isOpen={isOpen}
      onClose={onClose}
      pathRoot={Path.Threat}
      types={{ associated: ArtifactType.AttackScript, current: ArtifactType.Session }}
    />
  );
}
