import React from 'react';

import Path from 'constants/paths';

import { DetectionSummary } from 'module/Detection';

import { ArtifactType, DefensivePosture } from 'types/common';

import { getLocation } from 'utilities/RouteGenUtils';

import Board from '.';
import MarkerPanel, { MarkerType } from '../MarkerPanel';

type Props = DetectionSummary & {
  loading: boolean;
};

export default function PostureBoard({ loading, ...others }: Props) {
  return (
    <Board>
      <MarkerPanel
        linkTo={getLocation({
          pathname: Path.Feed,
          search: {
            posture: [DefensivePosture.UNDETECTED],
            topic: ArtifactType.Session
          }
        })}
        loading={loading}
        markerType={MarkerType.Undetected}
        value={others.undetected}
        data-testid='undetected-panel'
      />
      <MarkerPanel
        linkTo={getLocation({
          pathname: Path.Feed,
          search: {
            posture: [DefensivePosture.VALIDATED],
            topic: ArtifactType.Analytic
          }
        })}
        loading={loading}
        markerType={MarkerType.Validated}
        value={others.validated}
        data-testid='validated-panel'
      />
      <MarkerPanel
        linkTo={getLocation({
          pathname: Path.Feed,
          search: {
            posture: [DefensivePosture.UNTESTED],
            topic: ArtifactType.Analytic
          }
        })}
        loading={loading}
        markerType={MarkerType.Untested}
        value={others.untested}
        data-testid='untested-panel'
      />
    </Board>
  );
}
