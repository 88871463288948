import React from 'react';

import Button, { ButtonProps } from 'snap-ui/Button';
import CircularProgress from 'snap-ui/CircularProgress';

import { StrictReactNode } from 'types/core';

import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from './_MuiDialog';

type ConfirmDialogProps = {
  className?: string;
  children: StrictReactNode;
  DialogProps: DialogProps;
  ConfirmProps: Omit<ButtonProps, 'type'>;
  SecondaryProps?: Omit<ButtonProps, 'color' | 'variant'>;
  isPending?: boolean;
  title: string | StrictReactNode;
  subtitle?: string;
  disableOnClose?: boolean;
};

export default function ConfirmDialog({
  className,
  children,
  DialogProps,
  ConfirmProps,
  SecondaryProps = { children: 'Close' },
  isPending,
  title,
  subtitle,
  disableOnClose = false
}: ConfirmDialogProps): JSX.Element {
  const { onClick, ...otherSecondary } = SecondaryProps;
  if (isPending) {
    ConfirmProps.children = <CircularProgress size={25} />;
    ConfirmProps.disabled = true;
  }
  return (
    <Dialog {...DialogProps} className={className}>
      <DialogTitle onClose={DialogProps.onClose} subtitle={subtitle}>
        <>{title}</>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          color='primary'
          variant='outlined'
          onClick={() => {
            disableOnClose ? null : DialogProps.onClose(null, null);
            if (onClick) onClick();
          }}
          {...otherSecondary}
        />
        <Button color='primary' type='submit' {...ConfirmProps} />
      </DialogActions>
    </Dialog>
  );
}
