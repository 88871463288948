import { Entitlement } from 'module/Setting/core/Entitlements/Entitlements.type';

import { ContentPermission, FunctionalPermission, Organization, Permitted } from 'types/auth';

export function checkContentPermission(permitted: Permitted, content: ContentPermission) {
  return permitted?.permission?.some(p => p === ContentPermission.All || p === content) || false;
}

export function checkTaskPermission(organizations: Organization[], task: FunctionalPermission) {
  return (
    organizations?.some(
      org =>
        (org?.permission as FunctionalPermission[])?.includes(task) ||
        (org?.permission as FunctionalPermission[])?.includes(FunctionalPermission.All)
    ) || false
  );
}

export function checkEntitlement(entitlements: Entitlement[], task: FunctionalPermission) {
  return entitlements?.some(entitlement => entitlement.rights.some(right => right.includes(task))) || false;
}
