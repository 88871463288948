import ToggleButton from 'snap-ui/ToggleButton';
import { styled } from 'snap-ui/util';

const HostButtonContainer = styled(ToggleButton)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${p => p.theme.palette.common.white};
  text-transform: uppercase;
  font-size: x-small;
  padding-left: ${p => p.theme.spacing(5)};
  padding-right: ${p => p.theme.spacing(5)};

  .vm-logo {
    width: 48px;
    height: 48px;

    & img {
      width: 100%;
    }
  }

  svg {
    height: 48px !important;
  }
`;

export const VictimHostButtonContainer = styled(HostButtonContainer)`
  &.Mui-selected {
    background-color: ${p => p.theme.palette.blue.main};
  }

  &.Mui-selected:hover {
    background-color: ${p => p.theme.palette.blue.dark};
  }
`;

export const AttackerHostButtonContainer = styled(HostButtonContainer)`
  &.Mui-selected {
    background-color: ${p => p.theme.palette.error.main};
  }

  &.Mui-selected:hover {
    background-color: ${p => p.theme.palette.error.dark};
  }
`;
