export const LOG_COLUMNS = [
  'Image',
  'CommandLine',
  'ParentImage',
  'TaskCategory',
  'TargetObject',
  'Details',
  'SourceIp',
  'DestinationIp',
  'QueryName',
  'TargetFilename',
  'process_name',
  'process_path',
  'command_line',
  'parent_process_path',
  'action',
  'reg_key',
  'reg_data',
  'source_ip',
  'dest_ip',
  'domain',
  'file_path',
  'file_name',
  'message'
];
