import React from 'react';

import { convertAxiosError, ErrorProps } from 'module/ApiError';

import { Cancelable, Helper, Meta, Status, useMountedRef } from '.';

/**
 * Used for async api calls. Unmount safe.
 *
 * @param initialData - initial and fallback data when things go awry. Please provide a stable unmutable / unchanging object.
 * @returns Meta & Helper
 */

function useAsync<Signature>(initialData: Signature = null): Meta<Signature> & Helper<Signature> {
  const mounted = useMountedRef();
  const [data, _setData] = React.useState(initialData);
  const [status, _setStatus] = React.useState<Status>(Status.idle);
  const [error, _setError] = React.useState();
  const [errorProps, _setErrorProps] = React.useState<ErrorProps>(null);
  const oldtimestampRef = React.useRef<number>();

  // Ignore changes to initialData for setData and reset since initialData should never change.
  // We don't want initialData causing setters to be unstable so we don't include it in our array.

  const setData = React.useCallback(
    (data: React.SetStateAction<Signature>, status = Status.idle) => {
      if (mounted.current) {
        _setData(data ?? initialData);
        _setStatus(status);
        _setError(null);
        _setErrorProps(null);
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const setError = React.useCallback(error => {
    if (mounted.current) {
      if (error) _setStatus(Status.rejected);
      _setError(error);
      _setErrorProps(error ? convertAxiosError(error) : null);

      if (error?.response?.status === 402) _setData(error.response.data);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const reset = React.useCallback(() => {
    if (mounted.current) {
      _setStatus(Status.idle);
      _setData(initialData);
      _setError(null);
      _setErrorProps(null);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Runnable that changes our status, data, and maybe error. returns void.
   *
   * **NOTE** On canceling requests:
   * > If you need to support cancelable functionality, your apis function
   * should catch and return canceled: true. See `apis > feed.ts > getPage`</p>
   */
  const run = React.useCallback(
    (promise: Promise<Signature & Cancelable>, preserve = false) => {
      const newtimstamp = Date.now();
      oldtimestampRef.current = newtimstamp;
      if (!preserve) setData(null, Status.pending);
      else _setStatus(Status.pending);

      promise.then(
        data => {
          if (mounted.current) {
            if (data?.canceled) {
              if (newtimstamp === oldtimestampRef.current) _setStatus(Status.idle);
            } else setData(data, Status.resolved);
          }
        },
        (error: any) => {
          if (mounted.current) setError(error);
        }
      );
    },
    [setData, setError] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * Runnable task that changes our status, and maybe error. returns Promise<T>
   */
  const task = React.useCallback(
    async <T>(promise: PromiseLike<T>) => {
      _setStatus(Status.pending);
      return await promise.then(
        (response: T) => {
          if (mounted.current) {
            _setStatus(Status.resolved);
            setError(null);
          }
          return Promise.resolve(response);
        },
        (error: any) => {
          if (mounted.current) setError(error);
          return Promise.reject(error?.response?.data || error);
        }
      );
    },
    [setError] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    setData,
    setError,
    error,
    errorProps,
    status,
    data,
    run,
    task,
    reset
  };
}

export default useAsync;
