import React from 'react';

import MuiRadio, { RadioProps } from '@mui/material/Radio';

import { StrictReactNode } from 'types/core';

import { Base } from './type';

type Radio = Base & {
  onChange?(event: React.SyntheticEvent<Element, Event>, checked: boolean): void;
  children?: StrictReactNode;
  color?: RadioProps['color'];
  icon?: StrictReactNode;
  disabled?: boolean;
  checked?: boolean;
};

function Radio(props: Radio) {
  return <MuiRadio {...props} />;
}

export default Radio;
