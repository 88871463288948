import React from 'react';

import classnames from 'classnames';

import ToggleButton, { ToggleButtonGroup } from 'snap-ui/ToggleButton';
import { styled } from 'snap-ui/util';

const Container = styled(ToggleButtonGroup)`
  .MuiToggleButton-root {
    height: 40px;

    &.Mui-selected {
      background-color: transparent;
      border-bottom: 1px solid ${p => p.theme.palette.primary.main};
    }
  }
`;

type TuningToggleProps = {
  className?: string;
  value: boolean;
  onChange: (value: boolean) => void;
};
export default function TuningToggle({ className, value, onChange }: TuningToggleProps) {
  return (
    <Container
      className={classnames('TuningToggle', className)}
      exclusive
      orientation='horizontal'
      value={value}
      onChange={(_event, value: boolean) => onChange(value)}
    >
      <ToggleButton value={false}>Original</ToggleButton>
      <ToggleButton value={true}>Tuned</ToggleButton>
    </Container>
  );
}
