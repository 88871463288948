import React from 'react';

import { Link } from 'react-router-dom';

import Placeholder from 'snap-ui/Placeholder';
import { styled } from 'snap-ui/util';

import { buildLinkWithState } from 'module/Card/Card.util';
import { AnalyticDefensivePosture, SessionDefensivePosture } from 'module/DefensivePosture';
import { filterOutSelf } from 'module/Landing/Landing.util';
import { PosturePlaceholder } from 'module/Layout/Placeholders';
import { Control } from 'module/Metadata/Control';
import { FILTER_PREFIX } from 'module/Metadata/Metadata.const';
import { isMetaEmpty } from 'module/Metadata/Metadata.util';
import { ReadonlyControl } from 'module/Metadata/Metadata.widgets';
import TagList, { TagListProps } from 'module/Tag/TagList';
import { ScoreBadge } from 'module/Widgets/Badge';

import { useAuth, useFeedExtra } from 'provider';

import { Artifact, ArtifactScore, ArtifactType, Guid } from 'types/common';

import { getPreferredOrgScore } from 'utilities/ArtifactUtils';

const StyledLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1rem;

  .value {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    color: ${p => p.theme.palette.common.white};
  }
`;

export function NameContainer({ artifact, topic, value }: { artifact: Artifact; topic: ArtifactType; value: string }) {
  return (
    <StyledLink to={buildLinkWithState(topic, artifact)}>
      <div className='value'>{value}</div>
    </StyledLink>
  );
}

function Hits({
  className,
  guid,
  topic
}: {
  className?: string;
  guid: Guid;
  topic: ArtifactType.Analytic | ArtifactType.Session;
}) {
  const count = useFeedExtra().getDetection(guid);
  if (!count) return <PosturePlaceholder />;
  switch (topic) {
    case ArtifactType.Session:
      return <SessionDefensivePosture className={className} count={count} headless />;
    case ArtifactType.Analytic:
      return <AnalyticDefensivePosture className={className} count={count} headless />;
  }
}

export const AnalyticDefensePostureContainer = styled(AnalyticDefensivePosture)`
  display: flex;
  gap: ${p => p.theme.spacing(2)};
  align-items: center;
`;

export const HitsContainer = styled(Hits)`
  display: flex;
  gap: ${p => p.theme.spacing(2)};
  align-items: center;
`;

export const PlatformBadgeContainer = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(2)};
  align-items: center;
  text-transform: uppercase;
`;

const StyledTagContainer = styled('div')`
  height: 24px;
  display: flex;
  gap: ${p => p.theme.spacing(1)};
  align-items: center;
  flex-flow: wrap;
  overflow: hidden;
`;

type TagContainerProps = Omit<TagListProps, 'inline' | 'className' | 'showPlaceholder'> & {
  name?: Artifact['name'];
  preview?: number;
};
export function TagContainer(props: TagContainerProps) {
  const filterFn = props?.name ? filterOutSelf(props?.name) : () => true;
  const preview = props.preview ?? 2;

  const attack = props.attack?.filter(filterFn);
  const action = props.action;
  const actor = props.actor?.filter(filterFn);
  const software = props.software?.filter(filterFn);
  const vulnerability = props.vulnerability?.filter(filterFn);
  const datasource = props.datasource?.filter(filterFn);

  return (
    <StyledTagContainer>
      <TagList
        inline
        preview={preview}
        {...props}
        attack={attack}
        actor={actor}
        action={action}
        software={software}
        vulnerability={vulnerability}
        datasource={datasource}
      />
    </StyledTagContainer>
  );
}

export const CreatorContainer = styled(Creator)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

function Creator({ className, name }: { className?: string; name: string }) {
  return <div className={className}>{name}</div>;
}

export function SeverityContainer({ guid, severity }: { guid: Guid; severity: ArtifactScore }) {
  const { defaultOrgId } = useAuth();
  const { supplementalCatalog, supplementalPending } = useFeedExtra();

  if (supplementalPending) return <Placeholder variant='text' width={140} />;
  const supplemental = supplementalCatalog[guid];
  if (!supplemental) return <ScoreBadge name='SEVERITY' score={severity || ArtifactScore.UNKNOWN} />;
  const score = getPreferredOrgScore(defaultOrgId, supplemental.severities, severity);
  return <ScoreBadge name='SEVERITY' score={score} />;
}

export function ConfidenceContainer({
  guid,
  rank,
  itemIncludeRank
}: {
  guid: Guid;
  rank: ArtifactScore;
  itemIncludeRank?: boolean;
}) {
  const { defaultOrgId } = useAuth();
  const { supplementalCatalog, supplementalPending } = useFeedExtra();

  if (supplementalPending) return <Placeholder variant='text' width={140} />;
  if (itemIncludeRank && !!rank) return <ScoreBadge name='CONFIDENCE' score={rank} />;

  const supplemental = supplementalCatalog[guid];
  if (!supplemental) return null;
  const score = itemIncludeRank ? rank : getPreferredOrgScore(defaultOrgId, supplemental.ranks, rank);
  return score ? <ScoreBadge name='CONFIDENCE' score={score || rank} /> : null;
}

const TRUNCATE_TYPES: Control[] = [Control.Text, Control.TextArea];
export function MetadataCell({ artifact, field }: { artifact: Artifact; field: string }) {
  const { getMetadata } = useFeedExtra();
  if (!artifact?.guid) return null;

  const metadata = getMetadata(artifact.guid);
  const property = field.replace(FILTER_PREFIX, '');
  if (isMetaEmpty(metadata?.metadata_value?.[property])) return null;
  const type = metadata?.metadata_schema?.properties?.[property]?.properties?.control?.title as Control;

  return <ReadonlyControl title='' truncate={TRUNCATE_TYPES.includes(type)} data={metadata.metadata_value[property]} />;
}
