import React from 'react';

import { useAuth, useIntegrationCatalog } from 'provider';

import { useMountedRef } from 'storage';

import { Language } from 'types/analytic';

import { postIntegrationUrl } from './Integration.api';
import { Integration } from './Integration.type';

export type IntegrationURL = Integration & {
  url: string;
};

export default function useIntegrationURL(language: Language, code: string, native: boolean): IntegrationURL[] {
  const { current } = useMountedRef();
  const { permission } = useAuth();
  const { integrations } = useIntegrationCatalog();
  const [integrationUrls, setIntegrationUrls] = React.useState([]);

  const targets = React.useMemo(
    () =>
      integrations.searchable.filter(integration =>
        integration.search_targets?.some(
          target => target.id === language?.id || (native && target.native_key === language?.native_key)
        )
      ),
    [integrations.searchable, language, native]
  );

  React.useEffect(() => {
    function load() {
      setIntegrationUrls([]);
      const promises = targets.map(t =>
        postIntegrationUrl(t.guid, code).then(computed => ({
          ...t,
          url: computed.url
        }))
      );
      Promise.all(promises).then(values => {
        if (current) setIntegrationUrls(values);
      });
    }

    if (code) load();
  }, [code, permission, targets, current]);

  return integrationUrls;
}
