import React from 'react';

import isEmpty from 'lodash/isEmpty';

import useAgents, { AgentInterface } from 'aso/useAgents';

import { AttackScript } from 'module/AttackScript/AttackScript.type';
import { BurgerClicker } from 'module/Layout/Artifact.widgets';

import { BASAgent } from 'types/bas';

import BASButton from './BASButton';
import Launcher from './Launcher';

type Button = Partial<BurgerClicker>;

type LauncherInterfaceProps = {
  children: React.ReactNode;
};
export interface LauncherInterface {
  agentInterface: AgentInterface;
  isOpen: boolean;
  setIsOpen(open: boolean): void;
}

const LauncherContext = React.createContext<LauncherInterface>(null);

export function LauncherInterface(props: LauncherInterfaceProps) {
  const { children } = props;
  const agentInterface = useAgents();
  const [isOpen, setIsOpen] = React.useState(false);

  return <LauncherContext.Provider value={{ agentInterface, isOpen, setIsOpen }}>{children}</LauncherContext.Provider>;
}

function useLauncher() {
  const context = React.useContext(LauncherContext);
  if (!context) {
    throw new Error('useLauncher must be used within an LauncherInterface');
  }
  return context;
}

export function CustomLauncherButton({ component, onClick }: Button & { component: React.ElementType }) {
  const { setIsOpen } = useLauncher();

  return React.createElement(component, {
    onClick: () => {
      setIsOpen(true);
      if (onClick) onClick();
    }
  });
}

type ScriptSubset = Pick<AttackScript, 'guid' | 'name' | 'product_id' | 'product_name' | 'platforms'>;
export function LauncherButton({ TooltipProps, onClick, scripts }: Button & { scripts: ScriptSubset[] }) {
  const { agentInterface, setIsOpen } = useLauncher();
  const { filteredAgents: agents, status: agentStatus } = agentInterface;

  return (
    <BASButton
      agentStatus={agentStatus}
      hasAgents={agents?.length > 0}
      hasScripts={scripts?.length > 0}
      TooltipProps={TooltipProps}
      onClick={() => {
        setIsOpen(true);
        if (onClick) onClick();
      }}
    />
  );
}

export function LauncherDialog({ scripts, agent }: { scripts: ScriptSubset[]; agent?: BASAgent }) {
  const { isOpen, setIsOpen, agentInterface } = useLauncher();

  if (isEmpty(scripts)) return null;

  return (
    <Launcher
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      agent={agent}
      agentInterface={agentInterface}
      scripts={scripts}
    />
  );
}
