import Paper from 'snap-ui/Paper';
import { styled } from 'snap-ui/util';

export const Container = styled(Paper)`
  display: flex;
  flex-direction: column;

  .toolbar {
    padding: ${p => p.theme.spacing(2)};
    display: flex;
    justify-content: space-between;
  }
  .MuiFab-root {
    height: 40px;
    width: 40px;
  }

  .search-field {
    width: 350px;
  }

  .MuiCheckbox-root {
    padding: ${p => p.theme.spacing(1)};
  }

  .recommenderTable {
    tr {
      th:nth-of-type(2) {
        width: 50%;
      }
    }
  }

  .BackdropLoaderContainer {
    height: 300px;
  }

  .SimpleBadge {
    width: ${p => p.theme.spacing(7)};
  }
`;
