import React from 'react';

import { DisplayDialog } from 'snap-ui/Dialog';

import { canI } from 'module/Can';
import { Highlight } from 'module/Widgets/SyntaxEditor';
import { SyntaxViewer } from 'module/Widgets/SyntaxEditor/SyntaxEditor';

import { SigmaConfig } from 'types/analytic';
import { ConfigType } from 'types/analytic';
import { ContentPermission } from 'types/auth';
import { Ident } from 'types/common';

import { isConfigNotEditable } from '../LanguageConfig.util';

export default function ConfigDisplayDialog({
  config,
  onClose,
  onEdit,
  onClone
}: {
  config: SigmaConfig;
  onClose: () => void;
  onEdit: (configType: ConfigType, configId: Ident) => void;
  onClone: (configType: ConfigType, configId: Ident) => void;
}): JSX.Element {
  return (
    <DisplayDialog
      DialogProps={{
        open: !!config,
        onClose,
        maxWidth: 'md'
      }}
      SecondaryActionProps={
        config && !isConfigNotEditable(config) && canI(ContentPermission.Edit, config)
          ? {
              children: 'Edit',
              onClick: () => onEdit(config.discriminator, config.id)
            }
          : {
              children: 'Clone & Edit',
              onClick: () => onClone(config.discriminator, config.id)
            }
      }
      title={config?.name}
    >
      <SyntaxViewer value={config?.raw ?? ''} highlight={Highlight.yaml} disableCopyButton />
    </DisplayDialog>
  );
}
