import React from 'react';

import { useIntegrationCatalog } from 'provider';

import { useAsync, Status } from 'storage';

import { syntaxCheckDetectionCode } from './api';
import { SyntaxCheckerInterface, SyntaxCheckerParameters, SyntaxCheckerResponse } from './type';

const SyntaxCheckerContext = React.createContext<SyntaxCheckerInterface>(null);
SyntaxCheckerContext.displayName = 'SyntaxCheckerContext';

const INITIAL: Partial<SyntaxCheckerResponse> = { valid: undefined, error: undefined };

function useSyntaxChecker(): SyntaxCheckerInterface {
  const { integrations } = useIntegrationCatalog();
  const { data, errorProps, run, setData, status } = useAsync(INITIAL);
  const [syntaxCheckerParams, _setSyntaxCheckerParams] = React.useState<SyntaxCheckerParameters>(null);

  const payloadValues = React.useMemo(() => {
    // no params set
    if (!syntaxCheckerParams) return;
    if (!syntaxCheckerParams.detectionCode) return;
    if (!syntaxCheckerParams.integrationId) return;
    if (!syntaxCheckerParams.languageId) return;

    // integration guid is not valid
    const integration = integrations.all.find(i => i.id === syntaxCheckerParams.integrationId);
    if (!integration) return;

    // selected language is not huntable
    const language = integration.hunt_targets.find(l => l.id === syntaxCheckerParams.languageId);
    if (!language) return;

    return {
      integrationGuid: integration.guid,
      search: syntaxCheckerParams.detectionCode,
      backend_key: language.backend_key
    };
  }, [integrations.all, syntaxCheckerParams]);

  const checkSyntax = React.useCallback(() => {
    if (!payloadValues) return;

    run(
      syntaxCheckDetectionCode(payloadValues.integrationGuid, {
        search: payloadValues.search,
        backend_key: payloadValues.backend_key
      })
    );
  }, [payloadValues, run]);

  const setSyntaxCheckerParams = React.useCallback(
    (params: SyntaxCheckerParameters) => {
      setData(INITIAL);
      _setSyntaxCheckerParams(params);
    },
    [_setSyntaxCheckerParams, setData]
  );

  return {
    canCheckSyntax: !!payloadValues,
    checkSyntax,
    errorProps,
    isPending: status === Status.pending,
    isValid: data.valid,
    setSyntaxCheckerParams,
    syntaxError: data.error,
    syntaxCheckerParams
  };
}

export function SyntaxCheckerProvider({ children }: React.PropsWithChildren<Record<never, never>>): JSX.Element {
  const context = useSyntaxChecker();
  return <SyntaxCheckerContext.Provider value={context}>{children}</SyntaxCheckerContext.Provider>;
}

export function useSyntaxCheckerContext(): SyntaxCheckerInterface {
  const context = React.useContext(SyntaxCheckerContext);
  if (!context) {
    throw new Error('useSyntaxCheckerContext used outside SyntaxCheckerProvider');
  }
  return context;
}
