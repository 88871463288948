import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import BackdropLoader from 'snap-ui/BackdropLoader';
import Typography from 'snap-ui/Typography';

import { Path } from 'constants/paths';

import { CommonEvent, Engage, Fingerprint } from 'lib/Engagement';

import { ApiError } from 'module/ApiError';

import { Status } from 'storage';

import { RegisterRequest, RegisterStatus, RegistrationMethod } from 'types/auth';

import { getQueryParam } from 'utilities/SearchParam';

import { LoginRegisterPanel } from '../Authentication.helper';
import { AuthenticationRoot } from '../Authentication.style';
import RegisterForm from './Register.form';
import RegisterMessage from './Register.message';
import useRegistration from './useRegistration';

type Props = {
  className?: string;
};

export default function Register(props: Props): React.ReactElement {
  const { data, registrationMethod, register, tokenToEmail, status, errorProps } = useRegistration();
  const { replace } = useHistory();
  const { search } = useLocation();
  const token = getQueryParam(search, 'token');
  const [email, setEmail] = React.useState<string>(undefined);
  const [tokenError, setTokenError] = React.useState<string[]>();

  React.useEffect(() => {
    if (token) {
      tokenToEmail(token)
        .then(setEmail)
        .catch(() => setTokenError(['Invalid invite token']));
    }
  }, [token, tokenToEmail]);

  if (
    (!token && registrationMethod && registrationMethod !== RegistrationMethod.Public) ||
    (status === Status.resolved && data.status === RegisterStatus.Active)
  ) {
    replace(Path.Login);
  }

  const handleRegister = React.useCallback(
    (data: RegisterRequest) => {
      const payload: RegisterRequest = {
        name: data.name,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        password: data.password
      };

      if (token) Object.assign(payload, { token });
      if (data.interest) Object.assign(payload, { interest: data.interest });
      if (data.company) Object.assign(payload, { company: data.company });
      if (data.job_title) Object.assign(payload, { job_title: data.job_title });

      // Sending a unique object for tracking to avoid later mutation
      // Also changing the token name sent as token is leveraged by engagement
      const trackPayload = { ...payload, registerToken: token };
      delete trackPayload.password;
      delete trackPayload.token;

      Engage.track(Fingerprint.of(Path.Register).withCommon(CommonEvent.Submit).withData(trackPayload));
      register(payload);
    },
    [register, token]
  );

  return (
    <AuthenticationRoot className={props.className}>
      {status === Status.pending && <BackdropLoader data-testid='loading-dimmer' contained />}
      {status !== Status.pending && (
        <div className='pp-login'>
          <Typography variant='h2' className='login-title'>
            Register
          </Typography>
          <ApiError {...errorProps} />
          <ApiError messages={tokenError} />
          <RegisterMessage response={data} />
          <RegisterForm initialValues={{ email, token }} submitForm={handleRegister} tokenError={!!tokenError} />
        </div>
      )}
      <LoginRegisterPanel />
    </AuthenticationRoot>
  );
}
