import React, { useState } from 'react';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import BackdropLoader from 'snap-ui/BackdropLoader';
import Typography from 'snap-ui/Typography';

import WidthContainer from 'module/Layout/WidthContainer';

import { requestPasswordChange } from 'provider/Account';

import { ForgotRequest } from 'types/auth';
import { DisplayState } from 'types/common';

import { Divider, ForgotResetContainer } from '../Authentication.style';
import ForgotPwForm from './ForgotPwForm';

type Props = {
  className?: string;
};

const ForgotPassword = (props: Props): React.ReactElement => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [forgotPwState, setForgotPwState] = React.useState(DisplayState.Ready);

  function handleReset(formValue: ForgotRequest): void {
    setForgotPwState(DisplayState.Loading);
    requestPasswordChange(formValue)
      .then(validationResponse => {
        if (validationResponse) {
          setError(true);
          setSuccess(false);
        } else {
          setSuccess(true);
          setError(false);
        }
      })
      .catch(() => setError(true))
      .finally(() => setForgotPwState(DisplayState.Ready));
  }

  return (
    <ForgotResetContainer className={props.className}>
      {forgotPwState === DisplayState.Loading && <BackdropLoader data-testid='loading-dimmer' contained />}
      <WidthContainer className='pp-center-vert'>
        {error && (
          <Alert severity='error'>
            <AlertTitle>There was a problem sending the email. Please contact the site administrator.</AlertTitle>
          </Alert>
        )}
        {success && (
          <Alert severity='success'>
            <AlertTitle>A password reset email has been sent if the email address was in our system.</AlertTitle>
          </Alert>
        )}
        {forgotPwState === DisplayState.Ready && (
          <div className='pp-forgot'>
            <Typography variant='h1'>Reset Password</Typography>
            <p>Forgot your password? Enter your email address below and we will send a reset link.</p>
            <Divider />
            <ForgotPwForm submitForm={handleReset.bind(this)} />
          </div>
        )}
      </WidthContainer>
    </ForgotResetContainer>
  );
};

export default ForgotPassword;
