import { Catalog, Scheme, Status } from 'storage';

const ONE_MONTH = 2_629_800_000;
const ONE_MINUTE = 60_000;
type Item = { time: number; data: any; variant: number };
const fallback = { time: null, data: null, variant: 1 } as unknown as Item;

export function parseStorageItem(type: Scheme, key: Catalog | string): Item {
  try {
    return JSON.parse(window?.[type]?.getItem(key) || '') || fallback;
  } catch (e) {
    return fallback;
  }
}

export function statusGroupState(...status: Status[]) {
  let count = 0;
  let hasError = false;

  status.forEach(s => {
    if (s === Status.pending) count++;
    if (s === Status.rejected) hasError = true;
  });

  return {
    active: count,
    done: status.length - count,
    total: status.length,
    hasPending: count > 0,
    hasError: hasError
  };
}

export function isDataFresh(scheme: Scheme, time: number) {
  return Date.now() - time < (scheme === Scheme.localStorage ? ONE_MONTH : ONE_MINUTE);
}

export function stowItem(scheme: Scheme, key: Catalog | string, data: unknown, version: string | number = 1) {
  window?.[scheme]?.setItem(key, JSON.stringify({ time: Date.now(), data, variant: version }));
}
