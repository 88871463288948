import React, { ReactElement } from 'react';

import classnames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Button from 'snap-ui/Button';
import { styled } from 'snap-ui/util';

import { CommonEvent, Engage, Fingerprint, Widget } from 'lib/Engagement';

import { Host } from 'module/Session/Session.type';

import { StrictReactNode } from 'types/core';

import { HOST_COLORS, HOST_TYPE } from './Session.const';

export interface HostSelectorHelpers {
  onSelectorClick(event: React.MouseEvent<HTMLInputElement, MouseEvent>): void;
  getButtonColor(host: Host): string;
  isHostActive(host: Host): boolean;
  hosts: Host[];
}

const Container = styled('div')`
  display: flex;
  min-height: 46px;
  border-bottom: 2px solid ${p => p.theme.palette.grey[700]};

  .item {
    padding: 14px 18px;
    color: ${p => p.theme.palette.common.white};
    border-bottom: 3px solid;
    margin-bottom: -2px;
    border-radius: 4px 4px 0px 0px;

    &.blue {
      border-color: ${p => p.theme.palette.blue.dark};
    }

    &.red {
      border-color: ${p => p.theme.palette.error.dark};
    }

    &.active {
      font-weight: 700;
      border-color: ${p => p.theme.palette.grey[700]};
    }

    &.active.blue {
      background-color: ${p => p.theme.palette.blue.dark};
    }

    &.active.red {
      background-color: ${p => p.theme.palette.error.dark};
    }
  }
`;

type HostSelectorProps = {
  children?(props: HostSelectorHelpers): StrictReactNode;
  activeHostName: string;
  hosts: Host[];
  getCurrentTime?(): number;
};

function HostSelector({
  activeHostName = '',
  hosts = [],
  getCurrentTime,
  history,
  location,
  children
}: HostSelectorProps & RouteComponentProps): ReactElement {
  const handleSelectorClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLInputElement, MouseEvent>) => {
    if (event.currentTarget.getAttribute('data-active') === 'false') {
      Engage.track(
        Fingerprint.of(Widget.HostSelector).withCommon(CommonEvent.Change).withData({ host: event.currentTarget.id })
      );

      const params = new URLSearchParams(location.search);
      params.set('host', event.currentTarget.id);
      params.set('t', getCurrentTime().toString());

      history.replace({
        search: params.toString(),
        state: { noScroll: true }
      });
    }
  };

  return (
    <Container className='HostSelector'>
      {children
        ? (children as (helpers: HostSelectorHelpers) => React.ReactNode)({
            onSelectorClick: handleSelectorClick,
            getButtonColor,
            isHostActive,
            hosts
          })
        : hosts.map(host => (
            <Button
              component='a'
              key={host.machine.id}
              id={host.machine.name}
              data-active={isHostActive(host)}
              color='inherit'
              variant='text'
              className={classnames('item', getButtonColor(host), {
                active: isHostActive(host)
              })}
              onClick={handleSelectorClick}
            >
              {host.machine.display_name}
            </Button>
          ))}
    </Container>
  );

  function getButtonColor(host: Host): string {
    if (host.is_attacker) return HOST_COLORS[HOST_TYPE.ATTACKER];
    if (host.is_victim) return HOST_COLORS[HOST_TYPE.VICTIM];
  }

  function isHostActive(host: Host): boolean {
    return host.machine.name === activeHostName;
  }
}

export default withRouter<HostSelectorProps & RouteComponentProps, React.FC<HostSelectorProps>>(HostSelector);
