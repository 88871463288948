import { AxiosRequestConfigType, snapattack } from 'apis/snapattack';

import {
  BulkCollectionField,
  BulkUpdateCollectionField,
  Collection,
  CollectionRequest
} from 'module/Collection/Collection.type';
import {
  CollectionRecommender,
  CollectionRecommenderType,
  RecommendationResponse
} from 'module/Curation/Curation.type';
import { TagGroup, convertTagsToTagNames } from 'module/Tag';

import { ContentPermission } from 'types/auth';
import { Guid, Tracked } from 'types/common';
import { Query } from 'types/filter';

const prefix = '/collections';

/**
 * Used to query the collections via a query
 */
export function postList(data: Query, config: AxiosRequestConfigType): Promise<Collection[]> {
  return snapattack.post(`/search${prefix}/query/cached/v2/`, data, config).then(res => res.data.items);
}

export function saveCollection(collection: Partial<CollectionRequest>): Promise<Tracked> {
  return snapattack.post(`${prefix}/`, collection).then(res => res.data);
}

export function putCollection(guid: Guid, collection: Partial<CollectionRequest>): Promise<Tracked> {
  return snapattack.put(`${prefix}/${guid}/`, collection).then(res => res.data);
}

export function updateCollectionModifyItemByType(
  collectionId: Guid,
  mode: 'add' | 'remove',
  type: BulkCollectionField,
  artifactIds: Guid | Guid[]
) {
  return snapattack
    .post(`${prefix}/${collectionId}/items/${mode === 'remove' ? 'delete' : mode}/`, {
      [type]: Array.isArray(artifactIds) ? artifactIds : [artifactIds]
    })
    .then(res => res.data);
}

export function updateAllCollectionItemsByType(collectionId: Guid, mode: 'add', payload: BulkUpdateCollectionField) {
  return snapattack.post(`${prefix}/${collectionId}/items/${mode}/`, payload).then(res => res.data);
}

export function updateCollectionModifyItems(
  collectionId: Guid,
  mode: 'add' | 'remove',
  intelIds: Guid[],
  threatIds: Guid[],
  detectionIds: Guid[],
  attackScriptIds: Guid[]
) {
  const payload: Partial<BulkUpdateCollectionField> = {};
  if (intelIds.length) payload.threat_intelligences = intelIds;
  if (threatIds.length) payload.sessions = threatIds;
  if (detectionIds.length) payload.analytics = detectionIds;
  if (attackScriptIds.length) payload.bas_scripts = attackScriptIds;

  return snapattack
    .post(`${prefix}/${collectionId}/items/${mode === 'remove' ? 'delete' : mode}/`, payload)
    .then(res => res.data);
}

export function getCollection(guid: Guid): Promise<Collection> {
  return snapattack.get(`${prefix}/${guid}/`).then(res => res.data);
}

export function deleteCollection(guid: Guid): Promise<void> {
  return snapattack.delete(`${prefix}/${guid}/`).then(res => res.data);
}

export function getSavedFilters(): Promise<Collection[]> {
  return snapattack.get(`${prefix}/saved_filters/`).then(res => res.data);
}

// type: CollectionRecommenderType.Collection | CollectionRecommenderType.Threat
export async function postRecommender(
  payload: CollectionRecommender,
  type: CollectionRecommenderType
): Promise<RecommendationResponse> {
  return snapattack.post(`cyberai/recommender/artifact/${type}/`, payload).then(r => r.data);
}

export function quickAddTags(guid: Guid, additions: string[]) {
  return snapattack.put(`${prefix}/${guid}/tags/`, additions);
}

export function quickDeleteTags(guid: Guid, deletions: string[]) {
  return snapattack.delete(`${prefix}/${guid}/tags/`, { data: deletions });
}

export function tagRefresh(guid: Guid): Promise<TagGroup> {
  return snapattack.get(`${prefix}/${guid}/tags/`).then(r => convertTagsToTagNames(r.data));
}

export function postShare(
  collectionGuid: Guid,
  permissions: {
    share_items: boolean;
    permissions: {
      guid: Guid;
      permission: ContentPermission;
    }[];
  }
) {
  return snapattack.post(`${prefix}/${collectionGuid}/share/`, permissions);
}
