import React from 'react';

import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Placeholder from 'snap-ui/Placeholder';
import Tooltip from 'snap-ui/Tooltip';

import { FilterTopic } from 'module/GlobalFilter';
import { JsonView } from 'module/JsonView';
import withFunctionalPermission from 'module/Util/withFunctionalPermission';

import { useMetadataSchema } from 'provider/MetadataSchema';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';

import { FILTER_PREFIX } from './Metadata.const';
import { getJsonViewChangedPayload } from './Metadata.util';
import { FilterControl } from './Metadata.widgets';

function _MetadataFilter(props: { onChange(values: unknown): void; topic: FilterTopic; values: unknown }) {
  const { invalid, schema, schemaStatus } = useMetadataSchema();
  const isPending = schemaStatus === Status.pending;

  const initialData = React.useMemo(
    () =>
      Object.entries(props.values)
        .filter(([key]) => key.startsWith(FILTER_PREFIX))
        .reduce(
          (prev, [key, value]) => ({
            ...prev,
            [key.replace(FILTER_PREFIX, '')]: value
          }),
          {}
        ),
    [props.values]
  );

  if (invalid || (!isPending && !schema)) return null;

  return (
    <FilterControl className='metadataFilter'>
      <Tooltip title='Filters on content unique to this organization.' placement='right' arrow>
        <FormLabel id='metadata-group-label' className='title-tooltip'>
          By Metadata <Icon.Info />
        </FormLabel>
      </Tooltip>
      <FormGroup aria-labelledby='metadata-group-label'>
        {isPending ? (
          <>
            <Placeholder variant='text' height={20} />
            <Placeholder variant='text' height={20} />
            <Placeholder variant='text' height={20} />
          </>
        ) : props.topic !== '*' ? (
          <JsonView
            asFilter
            type={props.topic}
            schema={schema}
            uischema={schema?.uischema}
            data={initialData}
            onChange={({ data }) => {
              /**
               * A callback which is called on each data change, containing the updated data and the validation result.
               * JSON Forms will immediately produce an event with the results of the initial validation,
               * even before the inputs of the form are changed.
               */
              const payload = getJsonViewChangedPayload(initialData, data);
              if (payload) props.onChange(payload);
            }}
          />
        ) : null}
      </FormGroup>
    </FilterControl>
  );
}

export const MetadataFilter = withFunctionalPermission(_MetadataFilter, FunctionalPermission.MetadataFeatures, true);
