import React from 'react';

import Button, { ButtonProps } from 'snap-ui/Button';

import { StrictReactNode } from 'types/core';

import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, DialogTitleProps } from './_MuiDialog';

type DisplayDialogProps = {
  actions?: boolean;
  children: StrictReactNode;
  className?: string;
  auxiliary?: StrictReactNode;
  DialogProps: DialogProps;
  disableClickaway?: boolean;
  dividers?: boolean;
  PrimaryActionProps?: ButtonProps;
  SecondaryActionProps?: ButtonProps;
  AuxiliaryActionProps?: ButtonProps;
  subtitle?: string;
  title: DialogTitleProps['children'];
};

/**
 * A basic dialog for displaying content
 */
export default function DisplayDialog({
  actions,
  children,
  className,
  auxiliary,
  DialogProps,
  disableClickaway = false,
  dividers,
  PrimaryActionProps,
  SecondaryActionProps,
  AuxiliaryActionProps,
  subtitle,
  title
}: DisplayDialogProps): JSX.Element {
  actions = actions ?? true;
  dividers = dividers ?? true;

  return (
    <Dialog {...DialogProps} className={className} onClose={disableClickaway ? undefined : DialogProps.onClose}>
      <DialogTitle onClose={DialogProps.onClose} subtitle={subtitle}>
        {title}
      </DialogTitle>
      {auxiliary && (
        <DialogTitle>
          <>{auxiliary}</>
        </DialogTitle>
      )}
      <DialogContent dividers={dividers}>{children}</DialogContent>
      {actions && (
        <DialogActions>
          {AuxiliaryActionProps && <Button variant='outlined' {...AuxiliaryActionProps} />}
          {SecondaryActionProps && <Button variant='outlined' {...SecondaryActionProps} />}
          <Button variant='outlined' onClick={DialogProps.onClose}>
            Close
          </Button>
          {PrimaryActionProps && <Button {...PrimaryActionProps} />}
        </DialogActions>
      )}
    </Dialog>
  );
}
