import React from 'react';

import { RJSFSchema } from '@rjsf/utils';
import isEmpty from 'lodash/isEmpty';

import Button from 'snap-ui/Button';
import { styled } from 'snap-ui/util';

import { ApiError } from 'module/ApiError';
import ThresholdConfidence from 'module/Dashboard/BulkRank/ThresholdConfidence';
import { JobType } from 'module/Job';

import { useAuth, useManagedOrganizations } from 'provider';

import { Guid } from 'types/common';

import { CreateJobSchedulePayload } from '../Integration.type';
import { ScheduledJobsProvider, useScheduledJobsContext } from '../useScheduledJobs';
import ConfigureTaskModal from './ConfigureTaskModal';
import IntegrationConfidenceJobsTables from './IntegrationConfidenceJobsTable';

const ActionsContainer = styled('div')`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  gap: ${p => p.theme.spacing(2)};
`;

type IntegrationConfidenceTailoringProps = {
  integration_guid: Guid;
  jobSchema: RJSFSchema;
};

export default function IntegrationConfidenceTailoring({
  integration_guid,
  jobSchema
}: IntegrationConfidenceTailoringProps) {
  if (!integration_guid) return null;

  return (
    <ScheduledJobsProvider integrationGuid={integration_guid}>
      <IntegrationConfidenceJobsTables integration_guid={integration_guid} jobSchema={jobSchema} />
      <IntegrationConfidenceActions integration_guid={integration_guid} jobSchema={jobSchema} />
    </ScheduledJobsProvider>
  );
}

function IntegrationConfidenceActions({
  integration_guid,
  jobSchema
}: IntegrationConfidenceTailoringProps): JSX.Element {
  const { defaultOrgId } = useAuth();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isThresholdOpen, setIsThresholdOpen] = React.useState(false);
  const { organizations, refresh: refreshOrg } = useManagedOrganizations();
  const preferredOrganization = organizations.find(org => org?.id === defaultOrgId);

  const { createJobSchedule, taskStatus, taskErrorProps } = useScheduledJobsContext();

  function handleSubmit(payload: CreateJobSchedulePayload) {
    createJobSchedule(integration_guid, payload).then(() => {
      setIsOpen(false);
    });
  }

  return (
    <>
      <ActionsContainer>
        <Button disabled={isEmpty(jobSchema)} onClick={() => setIsOpen(true)}>
          Configure Task
        </Button>
        <Button
          variant='outlined'
          onClick={() => setIsThresholdOpen(true)}
          disabled={!preferredOrganization?.optional_preference}
        >
          Set Confidence Threshold
        </Button>
      </ActionsContainer>

      <ThresholdConfidence
        open={isThresholdOpen}
        onClose={setIsThresholdOpen}
        optional_preference={preferredOrganization?.optional_preference}
        refreshPreferredOrg={refreshOrg}
      />

      <ConfigureTaskModal
        title={'Configure Confidence Tailoring Task'}
        type={JobType.Rank}
        jobSchema={jobSchema}
        onClose={() => setIsOpen(false)}
        onSubmit={handleSubmit}
        status={taskStatus}
        isOpen={isOpen}
        integration_guid={integration_guid}
      />

      <ApiError {...taskErrorProps} />
    </>
  );
}
