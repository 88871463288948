import React from 'react';

import { faRotateExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faCloudArrowUp, faFileCircleInfo, faTrash } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Link, useHistory } from 'react-router-dom';

import { SlimAccordion } from 'snap-ui/Accordion';
import Button, { ActionIconButton } from 'snap-ui/Button';
import CircularProgress from 'snap-ui/CircularProgress';
import { FormDialog } from 'snap-ui/Dialog';
import Icon from 'snap-ui/Icon';
import { MenuButton, MenuTrigger } from 'snap-ui/Menu';
import { Placeholders } from 'snap-ui/Placeholder';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import useWorkflow from 'aso/useWorkflow';

import Path from 'constants/paths';

import { getReadonlyReason } from 'module/Analytic/Analytic.util';
import { standardFormikBaseProps } from 'module/Form';
import RjsfFormik from 'module/Form/RjsfFormik';
import useIntegrationAdvancedConfig from 'module/Integration/useIntegrationAdvancedConfig';
import { BurgerClicker } from 'module/Layout/Artifact.widgets';
import { useMayI } from 'module/May';
import { SupplementalArtifact } from 'module/Search';
import { TimeAgoTimestamp } from 'module/Widgets/TimeAgoTimestamp';

import { useAuth, useIntegrationCatalog, usePushSnack } from 'provider';

import { Status } from 'storage';

import { AnalyticDeploymentDetail, DeploymentIntegration } from 'types/analytic';
import { FunctionalPermission } from 'types/auth';
import { Guid, Ident } from 'types/common';
import { StrictReactNode } from 'types/core';

import { createDeployableIntegration, getDeploymentStatus, VersionMessage } from './Deployment.helper';
import { UndeployButton, UndeployDialog, UndeployInterface } from './UndeploymentInterface';
import {
  addDeployment,
  getDeployDisabledTooltip,
  isDeploymentLatestVersion,
  removeDeploymentOrIntegration,
  Section,
  SectionTitle,
  sortDeployment
} from './util';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(5)};
`;

type DeployIntegrationButtonProps = {
  guid: Guid;
  existingDeployment?: AnalyticDeploymentDetail;
  integration: DeploymentIntegration;
  onDeploy(deployment: AnalyticDeploymentDetail): void;
  showIfDisabled?: boolean;
  children?(props: { disabled: boolean; handleClickDeploy(): void }): StrictReactNode;
};

export function DeployIntegrationButton({
  guid,
  existingDeployment,
  integration,
  onDeploy,
  showIfDisabled,
  children
}: DeployIntegrationButtonProps): JSX.Element {
  const { defaultOrgId } = useAuth();
  const pushSnack = usePushSnack();
  const canDeploy = useMayI(FunctionalPermission.DeployAnalytic);
  const { deploy, status } = useWorkflow(guid);

  const { schemas, initialValues } = useIntegrationAdvancedConfig([integration?.guid]);

  const [showConfirm, setShowConfirm] = React.useState(false);
  const isLoading = status === Status.pending;
  const isDisabled = !integration?.compatible;

  if (!canDeploy) return null;
  // integration hasn't loaded yet, so we don't know where to deploy to
  if (!integration) return null;
  // If you publish a detection and a compilation is still active, compatible will return 'false' from the backend
  if (isDisabled && !showIfDisabled) return null;

  function handleClickDeploy() {
    setShowConfirm(true);
  }

  function handleConfirmDeploy(options) {
    let payload;
    if (existingDeployment) {
      payload = {
        overwrite: true,
        organization_id: existingDeployment.organization_id,
        integrations: [...existingDeployment.integrations.map(i => i.guid), integration.guid]
      };
    } else {
      payload = {
        organization_id: defaultOrgId,
        integrations: [integration.guid]
      };
    }
    if (!isEmpty(options)) {
      payload = { ...payload, options };
    }

    deploy(payload).then(handleDeploySuccess).catch(handleDeployFailure);
  }

  function handleDeploySuccess(newDeployment: AnalyticDeploymentDetail) {
    setShowConfirm(false);
    onDeploy(newDeployment);
  }

  function handleDeployFailure() {
    pushSnack('Something went wrong', 'error');
  }

  function handleCancel() {
    setShowConfirm(false);
  }

  return (
    <>
      <Tooltip arrow wrap title={isDisabled ? getDeployDisabledTooltip(integration) : undefined}>
        <>{children && children({ disabled: isDisabled, handleClickDeploy })}</>
      </Tooltip>

      <FormDialog
        DialogProps={{ open: showConfirm, onClose: handleCancel }}
        SubmitProps={{
          children: isLoading ? <CircularProgress size={25} /> : 'Deploy',
          disabled: isLoading
        }}
        FormikConfig={{
          ...standardFormikBaseProps,
          initialValues,
          onSubmit: handleConfirmDeploy
        }}
        title='Deploy Detection'
      >
        <Container>
          Are you sure you want to deploy this detection to {integration?.name || 'this integration'}?
          {schemas[integration.guid] && (
            <SlimAccordion
              details={<RjsfFormik name={integration.guid} schema={schemas[integration.guid]} />}
              summary='Advanced Configuration'
            />
          )}
        </Container>
      </FormDialog>
    </>
  );
}

type DeploymentProps = {
  status: Status;
  supplemental: SupplementalArtifact;
  versions: { id: Ident }[];
};

export default function Deployment({ status, supplemental, versions = [] }: DeploymentProps): JSX.Element {
  const { push } = useHistory();
  const { isSubscriber, defaultOrgId } = useAuth();

  const { integrations, status: integrationStatus } = useIntegrationCatalog();

  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const canCreateIntegrations = useMayI(FunctionalPermission.CreateIntegration);

  // copy the list of deployments for the preferred org into local state
  // it will need to be modified locally for optimistic updates due to BE caching
  const [orgDeployments, setOrgDeployments] = React.useState<AnalyticDeploymentDetail[]>([]);

  React.useEffect(() => {
    setOrgDeployments((supplemental?.deployments ?? []).filter(d => d.organization_id === defaultOrgId));
  }, [defaultOrgId, supplemental?.deployments]);

  const isIntegrationsLoading = [status, integrationStatus].some(status => status === Status.pending);

  const deployableIntegrations: DeploymentIntegration[] = React.useMemo(() => {
    return integrations.deployable
      .map(integration => createDeployableIntegration(integration, supplemental, orgDeployments))
      .sort(sortDeployment);
  }, [integrations.deployable, orgDeployments, supplemental]);

  const { guid } = supplemental ?? {};
  const reducedFilterIntegration = isCollapsed ? deployableIntegrations.slice(0, 4) : deployableIntegrations;

  function optimisticallyDeleteDeployment(deploymentId: Ident, integrationGuid?: Guid) {
    setOrgDeployments(removeDeploymentOrIntegration(orgDeployments, deploymentId, integrationGuid));
  }

  function optimisticallyAddDeployment(guid: Guid, newDeployment: AnalyticDeploymentDetail) {
    setOrgDeployments(orgDeployments => addDeployment(orgDeployments, newDeployment, guid));
  }

  function toggleCollapsed(): void {
    setIsCollapsed(collapsed => !collapsed);
  }

  return (
    <UndeployInterface>
      <Section>
        <SectionTitle>Deployment</SectionTitle>
        {isIntegrationsLoading ? (
          <Placeholders count={5} />
        ) : supplemental?.read_only ? (
          supplemental?.deployments?.map(deployment => {
            const integration = integrations.all.find(i => i.guid === deployment.integrations?.[0].guid);
            const deploymentStatus = getDeploymentStatus(deployableIntegrations.find(i => i.guid === integration.guid));
            return (
              !!integration && (
                <dl key={integration.guid}>
                  <div className={classnames(deploymentStatus.color)}>
                    <dt>
                      <Icon className='icon' icon={deploymentStatus.icon} color={deploymentStatus.color} />
                      <span className='text'>{integration.name}</span>
                    </dt>
                    <dd>
                      <span className='text'>{deploymentStatus.message}</span>
                    </dd>
                  </div>
                  <em>{getReadonlyReason(integration.name)}</em>
                </dl>
              )
            );
          })
        ) : (
          <>
            <dl>
              {deployableIntegrations.length ? (
                reducedFilterIntegration.map(integration => {
                  const versionsBehindCount = versions.findIndex(
                    v => v.id === integration.deployment?.analytic_version_id
                  );

                  const diffVersionId = versions?.[versionsBehindCount]?.id;
                  const deploymentStatus = getDeploymentStatus(integration);

                  return (
                    <div className={classnames(deploymentStatus.color)} key={integration.guid}>
                      <dt>
                        <Icon className='icon' icon={deploymentStatus.icon} color={deploymentStatus.color} />
                        <span className='text'>{integration.name}</span>
                      </dt>
                      <dd>
                        <span className='text'>
                          <Tooltip wrap arrow placement='bottom' title={deploymentStatus?.tooltip}>
                            <>
                              {deploymentStatus.message}
                              <br />
                              <TimeAgoTimestamp
                                timestamp={integration.deployment?.creation}
                                disabledTooltip={!!deploymentStatus?.tooltip}
                              />
                            </>
                          </Tooltip>
                        </span>
                        <div className='deploy-options'>
                          {deploymentStatus.action === 'deploy' && (
                            <DeployIntegrationButton
                              guid={guid}
                              existingDeployment={orgDeployments[0]}
                              integration={integration}
                              onDeploy={(newDeployment: AnalyticDeploymentDetail) => {
                                optimisticallyAddDeployment(integration.guid, newDeployment);
                              }}
                            >
                              {({ handleClickDeploy }) => (
                                <ActionIconButton
                                  aria-label='Deploy'
                                  className='icon'
                                  icon={faCloudArrowUp}
                                  onClick={handleClickDeploy}
                                />
                              )}
                            </DeployIntegrationButton>
                          )}
                          {deploymentStatus.action === 'undeploy' && (
                            <>
                              {isDeploymentLatestVersion(supplemental?.deployments, integration) ? (
                                <UndeployButton deployment={integration.deployment} integration={integration} />
                              ) : (
                                <Tooltip arrow placement='top' title='Update available. Review and Redeploy'>
                                  <div>
                                    <MenuTrigger
                                      className='versioning'
                                      aria-label='deploy-menu-trigger'
                                      trigger={
                                        <MenuButton aria-label='deploy-menu-options' icon={faRotateExclamation} />
                                      }
                                    >
                                      <BurgerClicker
                                        title='Review and Redeploy'
                                        label='ReviewAndRedeploy'
                                        icon={faFileCircleInfo}
                                        onClick={() =>
                                          push(
                                            `${Path.Detection}/${guid}/version/${diffVersionId}/diff/${versions?.[0]?.id}?deployedEnvironment=${integration.guid}`
                                          )
                                        }
                                        disabled={!integration.compatible}
                                        TooltipProps={
                                          !integration.compatible
                                            ? {
                                                title: getDeployDisabledTooltip(integration)
                                              }
                                            : undefined
                                        }
                                      />
                                      <UndeployButton deployment={integration.deployment} integration={integration}>
                                        {({ handleClickUndeploy }) => (
                                          <BurgerClicker
                                            label='Undeploy'
                                            title='Undeploy'
                                            icon={faTrash}
                                            onClick={() => handleClickUndeploy()}
                                          />
                                        )}
                                      </UndeployButton>
                                    </MenuTrigger>
                                  </div>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </div>
                        {!isDeploymentLatestVersion(supplemental?.deployments, integration) && (
                          <VersionMessage version={versionsBehindCount} />
                        )}
                      </dd>
                    </div>
                  );
                })
              ) : (
                <p>
                  {!isSubscriber ? (
                    'As a subscriber, you can configure integrations'
                  ) : canCreateIntegrations ? (
                    <Link to={Path.Integrations}>Configure integrations</Link>
                  ) : (
                    'Ask your organization admin to configure integrations'
                  )}{' '}
                  to have this detection deployed to your live environment.
                </p>
              )}
            </dl>
            {deployableIntegrations.length > 4 && (
              <Button
                variant='text'
                onClick={toggleCollapsed}
                aria-label={isCollapsed ? 'show all integrations' : 'hide integrations'}
              >
                {isCollapsed ? 'view all integrations' : 'hide integrations'}
              </Button>
            )}
            <UndeployDialog guid={guid} onDelete={optimisticallyDeleteDeployment} />
          </>
        )}
      </Section>
    </UndeployInterface>
  );
}
