import React from 'react';

import isEmpty from 'lodash/isEmpty';

import useDemoMode from 'hooks/useDemoMode';

import { DateRangeSelectorProps } from 'module/DateRangeSelector';
import useTactics from 'module/Matrix/Tactic/useTactics';

import { useAuth } from 'provider';

import { Status, useAsync } from 'storage';

import { getCoverageStats, getDetectionStats } from './DetectionDashboard.api';
import { DEMO_DETECTION_STATS, EMPTY_DASHBOARD_STATS } from './DetectionDashboard.const';
import { CoverageData, DashboardStats, DetectionData } from './DetectionDashboard.type';
import { createStatsPayload, parseDashboardData } from './DetectionDashboard.util';

interface DetectionDashboardStatsInterface extends DashboardStats {
  DateRangeSelectorProps: DateRangeSelectorProps;
  isPending: boolean;
  isResolved: boolean;
}

const DashboardStatsContext = React.createContext<DetectionDashboardStatsInterface>(null);
DashboardStatsContext.displayName = 'DashboardStatsContext';

function useDashboardStats(): DetectionDashboardStatsInterface {
  const context = React.useContext<DetectionDashboardStatsInterface>(DashboardStatsContext);

  if (!context) {
    throw new Error('useDashboardStats must be used within the DashboardStatsContext');
  }
  return context;
}

function DetectionDashboardStatsProvider({
  children,
  DateRangeSelectorProps
}: {
  children: React.ReactNode;
  DateRangeSelectorProps: DateRangeSelectorProps;
}): React.ReactElement {
  const { defaultOrgId, isSubscriber } = useAuth();
  const { isDemoMode } = useDemoMode();
  const { tactics } = useTactics();
  const {
    dates: { start, stop }
  } = DateRangeSelectorProps;

  const { data: coverage, run: coverageRun, status: coverageStatus } = useAsync<CoverageData[]>([]);
  const { data: detection, run: detectionRun, status: detectionStatus } = useAsync<DetectionData[]>([]);

  const isPending = [coverageStatus, detectionStatus].includes(Status.pending);
  const isResolved = coverageStatus === Status.resolved && detectionStatus === Status.resolved;

  const fetchStats = React.useCallback(async () => {
    const payload = createStatsPayload(start, stop, defaultOrgId);
    coverageRun(getCoverageStats(payload));
    detectionRun(getDetectionStats(payload));
  }, [coverageRun, defaultOrgId, detectionRun, start, stop]);

  React.useEffect(() => {
    fetchStats();
  }, [fetchStats]);

  const dashboardStats = React.useMemo<DashboardStats>(() => {
    if (!isSubscriber || isDemoMode) return DEMO_DETECTION_STATS;
    if (isPending || (isEmpty(coverage) && isEmpty(detection))) return EMPTY_DASHBOARD_STATS;
    return parseDashboardData(coverage, detection, tactics);
  }, [coverage, detection, isDemoMode, isPending, tactics, isSubscriber]);

  const payload = {
    DateRangeSelectorProps,
    ...dashboardStats,
    isPending,
    isResolved
  };

  return <DashboardStatsContext.Provider value={payload}>{children}</DashboardStatsContext.Provider>;
}

export { DetectionDashboardStatsProvider, useDashboardStats };
