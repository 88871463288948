import React from 'react';

import Divider from 'snap-ui/Divider';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { Engage, Fingerprint, NotableEvent } from 'lib/Engagement';

import { useAuth } from 'provider';

import ImpersonateOrganization from './ImpersonateOrganization';
import ListItemLink from './ListItemLink';
import ListItemLinkExternal from './ListItemLinkExternal';
import SectionDivider from './SectionDivider';

const Section = styled('div')`
  max-width: 250px;
  margin-top: 15px;
  margin-bottom: 10px;

  .profile-subtitle {
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    padding-left: 23px;

    &.user-detail {
      text-align: end;
      padding-right: 12px;
    }

    &.offset {
      margin-bottom: -2px;
    }
  }
`;

export default function ListItemsProfile({ onClose }: { onClose(): void }) {
  const { user } = useAuth();

  return (
    <>
      <Section>
        <Typography className='profile-subtitle user-detail'>{`${user.first_name} ${user.last_name}`}</Typography>
        <Typography className='profile-subtitle user-detail'>{`(${user.name})`}</Typography>
        <Typography className='profile-subtitle user-detail'>
          {user.preferred_organization && user.preferred_organization?.name}
        </Typography>
      </Section>
      <ImpersonateOrganization />
      <Divider />
      <ListItemLink aria-label='Go to Settings page' onClick={onClose} title='Settings' to={Path.Setting} />
      <ListItemLink aria-label='Go to Logout page' onClick={onClose} title='Logout' to={Path.Logout} />
      <SectionDivider content='Resources' />
      <ListItemLinkExternal
        id='MenuItemHelpDocumentation'
        aria-label='Go to view documentation'
        title='Documentation'
        href='https://docs.snapattack.com'
        compact
        onClick={() =>
          Engage.track(
            Fingerprint.of(NotableEvent.ExternalLink)
              .withQualifier('external documentation')
              .withData({ href: 'https://docs.snapattack.com' })
          )
        }
      />
      <ListItemLinkExternal
        aria-label='Go to End User License Agreement page'
        compact
        href='https://www.snapattack.com/legal/end-user-license-agreement'
        onClick={onClose}
        title='End User License Agreement'
      />
      <ListItemLinkExternal
        aria-label='Go to Acceptable Use Policy page'
        compact
        href='https://www.snapattack.com/legal/acceptable-use-policy'
        onClick={onClose}
        title='Acceptable Use Policy'
      />
      <ListItemLinkExternal
        aria-label='Go to Privacy Policy page'
        compact
        href='https://www.snapattack.com/legal/privacy-policy'
        onClick={onClose}
        title='Privacy Policy'
      />
    </>
  );
}
