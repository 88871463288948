import React from 'react';

import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import {
  Accordion as MuiAccordion,
  AccordionProps as MuiAccordionProps,
  AccordionDetails,
  AccordionSummary
} from '@mui/material';
import classnames from 'classnames';

import Icon from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

import { StrictReactNode } from 'types/core';

type AccordionProps = Omit<MuiAccordionProps, 'children'> & {
  details?: StrictReactNode;
  summary: StrictReactNode;
  simple?: boolean;
};

const StyledAccordion = styled(MuiAccordion)`
  background-color: unset;
  background-image: unset;
  box-shadow: unset;
  &.simple-format {
    .MuiGrid-root.MuiGrid-item {
      font-size: 0.8rem;
    }
    .MuiAccordionSummary-root {
      flex-direction: row;
    }
    .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters.AccordionSummary {
      padding: 0;
      min-height: 0;
    }
    .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded.MuiAccordionSummary-gutters.AccordionSummary {
      padding: 0.2rem 0;
    }
    .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters {
      margin: 0;
      padding-bottom: 0;
      /* justify-content: space-between; */
      align-items: center;
    }

    .MuiAccordionSummary-content.Mui-expanded {
      margin: 0;
      padding-bottom: 0;
      /* justify-content: space-between; */
      align-items: center;
    }

    .MuiCollapse-root.MuiCollapse-vertical .MuiCollapse-wrapper {
      padding: 0;
      min-height: 0;
      flex-direction: row;
    }

    &.Mui-expanded {
      .MuiCollapse-root.MuiCollapse-vertical {
        border: 0;
        transition-duration: 0ms !important;
      }
    }

    .MuiAccordionDetails-root.AccordionDetails {
      padding: 0;
    }
  }

  &:before {
    background-color: unset;
  }

  &.Mui-expanded {
    margin: 0;

    .MuiCollapse-root.MuiCollapse-vertical {
      border: 1px solid ${p => p.theme.palette.grey[600]};
    }
  }

  .MuiCollapse-root.MuiCollapse-vertical .MuiCollapse-wrapper {
    padding: ${p => p.theme.spacing(4)};
  }

  .MuiAccordionSummary-root {
    flex-direction: row-reverse;

    .MuiAccordionSummary-expandIconWrapper {
      padding: 0 ${p => p.theme.spacing(2)};

      &.Mui-expanded {
        transform: rotate(90deg);
      }
    }
  }
`;

export default function OutlinedAccordion({
  className,
  details,
  summary,
  simple,
  ...props
}: AccordionProps): JSX.Element {
  return (
    <StyledAccordion className={classnames('Accordion', { 'simple-format': simple }, className)} {...props}>
      <AccordionSummary className='AccordionSummary' expandIcon={<Icon icon={faCaretRight} />}>
        {summary}
      </AccordionSummary>
      {!!details && <AccordionDetails className='AccordionDetails'>{details}</AccordionDetails>}
    </StyledAccordion>
  );
}

export const SlimAccordion = styled(OutlinedAccordion, { name: 'SlimAccordion' })`
  .MuiAccordionSummary-root {
    padding: ${p => p.theme.spacing(1, 0, 0, 0)};
    min-height: 0;
    gap: ${p => p.theme.spacing(1)};
    align-items: baseline;

    .MuiAccordionSummary-expandIconWrapper {
      padding: 0;
      align-self: center;
    }

    .Mui-expanded {
      margin: 0;
      min-height: 0 !important;
    }
  }

  .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
    min-height: 0;
  }

  .MuiAccordionSummary-content {
    margin: 0;
    justify-content: space-between;
    align-items: baseline;
    padding: ${p => p.theme.spacing(0, 0, 0, 2)};
  }

  .MuiCollapse-root.MuiCollapse-vertical .MuiCollapse-wrapper,
  .MuiAccordionSummary-expandIconWrapper,
  .MuiCollapse-vertical,
  .MuiAccordionDetails-root,
  .MuiCollapse-wrapper {
    padding: 0;
  }

  &.Mui-expanded .MuiCollapse-root.MuiCollapse-vertical {
    border: 0;
    padding-top: ${p => p.theme.spacing(1)};

    .MuiAccordionDetails-root {
      padding-left: ${p => p.theme.spacing(5)};
    }
  }
`;
