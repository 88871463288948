import React from 'react';

import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { Discriminator } from 'module/Tag';

import { formatDayTimestamp } from 'utilities/TimeUtils/TimeUtils';

import { VulnerabilitiesScore } from '../../Landing.type';
import { getSeverityLevel } from '../../Landing.util';
import { useLandingCatalog } from '../../LandingProvider';

const Container = styled('div')`
  .${VulnerabilitiesScore.CRITICAL} {
    color: ${p => p.theme.palette.error.main};
  }
  .${VulnerabilitiesScore.HIGH} {
    color: ${p => p.theme.palette.orange.main};
  }
  .${VulnerabilitiesScore.MEDIUM} {
    color: ${p => p.theme.palette.warning.main};
  }
  .${VulnerabilitiesScore.LOW} {
    color: ${p => p.theme.palette.success.main};
  }
`;

export default function VulnerabilityDetail() {
  const { source, type } = useLandingCatalog();
  if (type !== Discriminator.Vulnerability) return null;

  const cvss2Level = getSeverityLevel(source.cvss_2_base_score);
  const cvss3Level = getSeverityLevel(source.cvss_3_base_score);

  return (
    <>
      {!!source.cvss_3_base_score && (
        <Container className='collection-header-detail-container'>
          <Typography variant='h6'>CVSSv3.1</Typography>
          <p className={cvss3Level}>
            <strong>
              {source.cvss_3_base_score} ({cvss3Level})
            </strong>
          </p>
        </Container>
      )}
      {!!source.cvss_2_base_score && (
        <Container className='collection-header-detail-container'>
          <Typography variant='h6'>CVSSv2.0</Typography>
          <p className={cvss2Level}>
            <strong>
              {source.cvss_2_base_score} ({cvss2Level})
            </strong>
          </p>
        </Container>
      )}
      {source.creation && (
        <Container className='collection-header-detail-container'>
          <Typography variant='h6'>FIRST PUBLISHED</Typography>
          <p>{formatDayTimestamp(source.creation)}</p>
        </Container>
      )}
      {source.modified && (
        <Container className='collection-header-detail-container'>
          <Typography variant='h6'>LAST UPDATED</Typography>
          <p>{formatDayTimestamp(source.modified)}</p>
        </Container>
      )}
    </>
  );
}
