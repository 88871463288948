import React from 'react';

import yaml from 'js-yaml';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import omitBy from 'lodash/omitBy';

import { Processor } from 'module/HighlightDetection';
import { Metadata } from 'module/Metadata/Metadata.type';
import { SigmaTag } from 'module/Tag';
import { TagOption } from 'module/Tag/useTagOptions';

import { useAuth } from 'provider';

import {
  appendValueToRule,
  defaultDetection,
  defaultFprDetection,
  emptySection,
  isWinEvent,
  interpolateYamlTemplate,
  newRuleFromKeyValue,
  parseAnalyticYaml,
  transformDetection,
  YamlParseError
} from 'services/analyticService';
import { orgIdIsNotVoid } from 'services/organizationService';

import {
  Analytic,
  AnalyticForm,
  CompilationTargetId,
  CustomizationForm,
  CustomizationFormUpdate,
  KeyValueRule,
  LanguageId,
  SectionKind
} from 'types/analytic';
import { Guid, Ident } from 'types/common';

import { debounce } from 'utilities/FunctionUtils';

export interface _ExplicitIDEState {
  analyticForm: AnalyticForm;
  customization: CustomizationForm;
  metadata: Metadata;
  guid?: Guid;
  highlightProcessor: Processor;
  isNative: boolean;
  pinned_events?: Guid[];
  raw: string;
  refreshKey: number;
  yamlParseError?: YamlParseError;
  _referenceForm: string;
  _referenceRaw: string;
  _customization_raw: string;
  _referenceMetadata: string;
}

export interface IDEState extends _ExplicitIDEState {
  isModified: boolean;
}

export interface InitWithStateAction {
  type: 'InitWithState';
  state: IDEState;
}

export interface ResetBaseState {
  type: 'ResetBaseState';
  guid?: Guid;
}

export interface FormUpdateAction {
  type: 'FormUpdateAction';
  analyticForm: Partial<IDEState['analyticForm']>;
  refresh?: boolean;
}

export interface RawUpdateAction {
  type: 'RawUpdateAction';
  raw: IDEState['raw'];
  tagOptions: SigmaTag[];
  guid?: Guid;
}

export interface EditorModeUpdate {
  type: 'EditorModeUpdate';
  isNative: boolean;
  languageId?: Ident;
}

export interface LoadAnalyticUpdate {
  type: 'LoadAnalyticUpdate';
  analytic: Analytic;
  tagOptions: SigmaTag[];
}

export interface AddFromLogUpdate {
  type: 'AddFromLogUpdate';
  addLog: {
    key: string;
    value: string;
  };
  detectionField?: 'analyticForm' | 'customization';
}

export interface ModPinnedEventUpdate {
  type: 'ModPinnedEventUpdate';
  pinned_events: Guid[];
}

export interface EditAsNativeUpdate {
  type: 'EditAsNativeUpdate';
  languageId: number;
  raw: string;
}

export interface CustomizationUpdate {
  type: 'CustomizationUpdate';
  customization: CustomizationFormUpdate;
}

export interface MetadataUpdate {
  type: 'MetadataUpdate';
  metadata: Partial<Metadata>;
}

export type IDEAction =
  | InitWithStateAction
  | ResetBaseState
  | FormUpdateAction
  | RawUpdateAction
  | EditorModeUpdate
  | LoadAnalyticUpdate
  | AddFromLogUpdate
  | ModPinnedEventUpdate
  | EditAsNativeUpdate
  | CustomizationUpdate
  | MetadataUpdate;

function initWithState(action: InitWithStateAction): IDEState {
  return action.state;
}

function resetBaseState(state: IDEState, action: ResetBaseState): IDEState {
  return {
    ...state,
    guid: action.guid ?? state.guid,
    refreshKey: 0,
    _referenceRaw: state.raw,
    _referenceForm: JSON.stringify(state.analyticForm),
    _referenceMetadata: JSON.stringify(state.metadata)
  };
}

function rawUpdate(state: IDEState, action: RawUpdateAction): _ExplicitIDEState {
  // don't parse again if nothing changed
  if (isEqual(state.raw, action.raw)) return state;

  const existingForm = state.analyticForm;
  let newForm;
  let yamlParseError = state.yamlParseError;
  if (!state.isNative) {
    [newForm, yamlParseError] = parseAnalyticYaml(action.raw, action.tagOptions);
  }
  const analyticForm = { ...existingForm, ...newForm };

  return {
    ...state,
    analyticForm,
    guid: state.guid || action.guid,
    highlightProcessor: Processor.fromAnalyticForm(analyticForm),
    isNative: state.isNative,
    raw: action.raw,
    yamlParseError
  };
}

function formUpdate(state: IDEState, action: FormUpdateAction): _ExplicitIDEState {
  const logSourceChange =
    !!action?.analyticForm?.logsource &&
    !isEqual(omitBy(action.analyticForm.logsource, isNil), omitBy(state?.analyticForm.logsource, isNil));
  const analyticForm = {
    ...state.analyticForm,
    ...action.analyticForm
  };

  if (logSourceChange) {
    // reset rule fields when a log source changes, since
    // the fields are typically different between sources
    analyticForm?.detection?.sections?.forEach(section =>
      section.rules.forEach((rule, index) => {
        // Windows first field should always be EventID
        rule.field = isWinEvent(analyticForm.logsource) && index === 0 ? 'EventID' : '';
      })
    );
  }

  const refreshKey = action.refresh ? state.refreshKey + 1 : state.refreshKey;
  // don't interpolate again if nothing changed
  if (isEqual(state.analyticForm, analyticForm)) return { ...state, refreshKey };

  let raw = state.raw;
  if (!state.isNative) {
    raw = convertFormToRaw(analyticForm, state);
  }

  return {
    ...state,
    analyticForm,
    guid: state.guid,
    highlightProcessor: Processor.fromAnalyticForm(analyticForm),
    isNative: state.isNative,
    raw,
    refreshKey
  };
}

function editorModeUpdate(state: IDEState, action: EditorModeUpdate): _ExplicitIDEState {
  if (action.isNative) {
    return {
      ...state,
      analyticForm: {
        ...state.analyticForm,
        languageId: action.languageId
      },
      isNative: action.isNative,
      raw: '',
      refreshKey: state.refreshKey + 1
    };
  } else {
    return {
      ...state,
      analyticForm: {
        ...state.analyticForm,
        languageId: LanguageId.Sigma
      },
      isNative: action.isNative,
      raw: convertFormToRaw(state.analyticForm, state),
      refreshKey: state.refreshKey + 1
    };
  }
}

function filterTagOptions(options: SigmaTag[], list: string[]): TagOption[] {
  if (list && list.length > 0)
    return options
      .filter(o => list.some(t => t === o.name || t === o.sigma_names[0]))
      .map(o => ({ ...o, content: o.name, value: o.name, sigma_names: o.sigma_names }));
  return [];
}

function loadAnalyticUpdate(state: IDEState, action: LoadAnalyticUpdate): _ExplicitIDEState {
  const isNative = action.analytic.source_analytic_compilation_target_id !== LanguageId.Sigma;
  let analyticForm: AnalyticForm;
  let highlightProcessor: Processor;
  let yamlParseError: YamlParseError;

  if (isNative) {
    analyticForm = {
      ...state.analyticForm,
      title: action.analytic.name,
      description: action.analytic.description,
      author: action.analytic.author,
      references: action.analytic.references,
      attack_names: filterTagOptions(action.tagOptions, action.analytic.attack_names),
      actor_names: filterTagOptions(action.tagOptions, action.analytic.actor_names),
      software_names: filterTagOptions(action.tagOptions, action.analytic.software_names),
      vulnerability_names: filterTagOptions(action.tagOptions, action.analytic.vulnerability_names),
      languageId: action.analytic.source_analytic_compilation_target_id
    };
  } else {
    [analyticForm, yamlParseError] = parseAnalyticYaml(
      action.analytic.raw,
      action.tagOptions,
      undefined,
      action.analytic
    );
    highlightProcessor = Processor.fromAnalyticForm(analyticForm);
  }

  analyticForm.organization_id = action?.analytic?.organization_id;
  analyticForm.license_url = action?.analytic?.license_url || '';

  return {
    analyticForm,
    customization: state.customization,
    metadata: state.metadata,
    guid: action.analytic.guid,
    highlightProcessor,
    isNative,
    pinned_events: action.analytic.pinned_events,
    raw: action.analytic.raw,
    refreshKey: state.refreshKey + 1,
    yamlParseError,
    _referenceForm: JSON.stringify(analyticForm),
    _referenceRaw: action.analytic.raw,
    _customization_raw: state._customization_raw,
    _referenceMetadata: JSON.stringify(state.metadata)
  };
}

function addFromLogUpdate(state: IDEState, action: AddFromLogUpdate): _ExplicitIDEState {
  const { key: field, value } = action.addLog;
  const detectionField = action.detectionField || 'analyticForm';

  let detection;
  const existingDetection = state[detectionField]?.detection;
  if (!existingDetection) {
    detection = defaultDetection(state.analyticForm.logsource?.type);
  } else {
    detection = cloneDeep(existingDetection);
  }

  if (!detection.sections.length) {
    const section = emptySection(1);
    section.rules = [newRuleFromKeyValue(field, value)];
    detection.sections = [section];
    detection.condition = section.name;
  } else {
    const section = detection.sections[detection.sections.length - 1];
    if (section.kind === SectionKind.KeyValue) {
      const rule = section.rules.find(rule => rule.field === field);
      if (rule) {
        if (!rule.values.includes(value)) {
          appendValueToRule(rule, value);
        }
      } else if (!last<KeyValueRule>(section.rules).field) {
        const rule = last<KeyValueRule>(section.rules);
        rule.field = field;
        appendValueToRule(rule, value);
      } else {
        section.rules.push(newRuleFromKeyValue(field, value));
      }
    } else {
      const section = emptySection(detection.sections.length + 1);
      section.rules = [newRuleFromKeyValue(field, value)];
      detection.sections.push(section);
    }
  }

  if (detectionField === 'analyticForm') {
    return formUpdate(state, {
      type: 'FormUpdateAction',
      analyticForm: { ...state.analyticForm, detection },
      refresh: true
    });
  } else if (detectionField === 'customization') {
    return customizationUpdate(state, {
      type: 'CustomizationUpdate',
      customization: { detection }
    });
  }
}

function modPinnedEventsUpdate(state: IDEState, action: ModPinnedEventUpdate): _ExplicitIDEState {
  return {
    ...state,
    pinned_events: action.pinned_events
  };
}

function editAsNativeUpdate(state: IDEState, action: EditAsNativeUpdate): _ExplicitIDEState {
  return {
    ...state,
    isNative: true,
    raw: action.raw,
    analyticForm: {
      ...state.analyticForm,
      languageId: action.languageId
    }
  };
}

function customizationUpdate(state: IDEState, action: CustomizationUpdate): _ExplicitIDEState {
  return {
    ...state,
    customization: action.customization.detection
      ? // sigma fpr
        { detection: action.customization.detection, raw: undefined }
      : // language isn't supported, so just revert to default
        { detection: defaultFprDetection(), raw: undefined }
  };
}

function metadataUpdate(state: IDEState, action: MetadataUpdate): _ExplicitIDEState {
  const _referenceMetadata = isEmpty(state.metadata) ? JSON.stringify(action.metadata) : state._referenceMetadata;
  return {
    ...state,
    metadata: { ...state.metadata, ...action.metadata },
    _referenceMetadata
  };
}

function ideStateReducer(state: IDEState, action: IDEAction): _ExplicitIDEState {
  switch (action.type) {
    case 'InitWithState':
      return initWithState(action);

    case 'ResetBaseState':
      return resetBaseState(state, action);

    case 'RawUpdateAction':
      return rawUpdate(state, action);

    case 'FormUpdateAction':
      return formUpdate(state, action);

    case 'EditorModeUpdate':
      return editorModeUpdate(state, action);

    case 'LoadAnalyticUpdate':
      return loadAnalyticUpdate(state, action);

    case 'AddFromLogUpdate':
      return addFromLogUpdate(state, action);

    case 'ModPinnedEventUpdate':
      return modPinnedEventsUpdate(state, action);

    case 'EditAsNativeUpdate':
      return editAsNativeUpdate(state, action);

    case 'CustomizationUpdate':
      return customizationUpdate(state, action);

    case 'MetadataUpdate':
      return metadataUpdate(state, action);

    default:
      return state;
  }
}

function otherStateDirty(state: IDEState, newState: _ExplicitIDEState) {
  return difference(newState.pinned_events || [], state.pinned_events || []).length > 0;
}

export function reducer(state: IDEState, action: IDEAction): IDEState {
  const newState = ideStateReducer(state, action);

  const _referenceForm = newState._referenceForm || JSON.stringify(newState.analyticForm);
  const _referenceRaw = newState._referenceRaw || newState.raw;
  const _customization_raw =
    newState.customization.raw ?? yaml.dump({ detection: transformDetection(newState.customization) });
  const _referenceMetadata = JSON.stringify(newState.metadata);

  let isModified =
    newState.raw !== _referenceRaw ||
    otherStateDirty(state, newState) ||
    _customization_raw !== newState._customization_raw ||
    _referenceMetadata !== newState._referenceMetadata;
  if (newState.isNative) {
    isModified = isModified || JSON.stringify(newState.analyticForm) !== _referenceForm;
  }

  return {
    ...newState,
    isModified,
    _referenceForm,
    _referenceRaw,
    _customization_raw,
    _referenceMetadata
  };
}

export const initialState = {
  isNative: false,
  refreshKey: 0,
  pinned_events: [],
  customization: {
    detection: defaultFprDetection()
  },
  metadata: {}
} as IDEState;

export default function useIDEReducer(): [IDEState, React.Dispatch<IDEAction>] {
  const { user, defaultOrgId } = useAuth();
  const initial = React.useMemo(
    () =>
      reducer(initialState, {
        type: 'FormUpdateAction',
        analyticForm: {
          organization_id: defaultOrgId,
          author: user.name,
          detection: defaultDetection(),
          languageId: CompilationTargetId.Sigma
        }
      }),
    [defaultOrgId, user.name]
  );

  const [state, _dispatch] = React.useReducer(reducer, initial);

  const dispatch = React.useCallback((...args) => debounce(_dispatch)(...args), [_dispatch]);

  return [state, dispatch];
}

type ValidationErrors = Partial<{ [key in keyof AnalyticForm]: string }> & { raw?: string };

export function validate(values: Partial<AnalyticForm> & { raw?: string }): ValidationErrors {
  const errors: ValidationErrors = {};
  const requiredFields = getRequiredFields(values.languageId);

  if (requiredFields.includes('organization_id') && !orgIdIsNotVoid(values.organization_id)) {
    errors.organization_id = 'Organization is required';
  }
  if (requiredFields.includes('title') && !values.title) errors.title = 'Title is required';
  if (requiredFields.includes('description') && !values.description) errors.description = 'Description is required';
  if (requiredFields.includes('logsource') && isEmpty(values.logsource)) errors.logsource = 'Log Source is required';
  if (requiredFields.includes('raw') && !values.raw) errors.raw = 'Detection code is required';

  return errors;
}

export function getRequiredFields(languageId?: AnalyticForm['languageId']): string[] {
  const alwaysRequired = ['title', 'description', 'organization_id'];

  if (languageId === undefined || languageId === CompilationTargetId.Sigma) {
    return [...alwaysRequired, 'logsource'];
  }

  return [...alwaysRequired, 'raw'];
}

function convertFormToRaw(analyticForm: AnalyticForm, currentState: IDEState): string {
  // no errors from the pervious parse -- proceed as normal
  if (!currentState.yamlParseError) return interpolateYamlTemplate(analyticForm);

  // if the previous parse resulted in a syntax error, just punt. this case shouldn't really be possible in the UI
  if (currentState.yamlParseError instanceof yaml.YAMLException) return currentState.raw;

  // the complicated case: there's detection logic that's not supported by the builder
  // solution: keep the old detection logic from `raw`, but get the metadata fields from the analyticForm
  try {
    var loadedRaw = yaml.load(currentState.raw) as any;
  } catch {
    // syntax error that somehow didn't get caught in the previous parse? punt
    return currentState.raw;
  }
  const detection = loadedRaw.detection;
  const logsource = loadedRaw.logsource;

  try {
    var interpolated = yaml.load(interpolateYamlTemplate(analyticForm)) as any;
  } catch {
    // this indicates a bug in interpolateYamlTemplate, but if it happens we have to punt
    return currentState.raw;
  }
  return yaml.dump({ ...interpolated, detection, logsource });
}
