import React from 'react';

import { useFeed } from 'aso';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import CircularProgress from 'snap-ui/CircularProgress';
import Typography from 'snap-ui/Typography';

import { useDetectionCount } from 'module/Detection';
import { DisplayKind, SortOrder } from 'module/Feed/Feed.type';
import FeedItems from 'module/Feed/FeedItems';
import { useSupplementalItems } from 'module/Search';

import { FeedExtraProvider } from 'provider';

import { Status } from 'storage';

import { ArtifactType } from 'types/common';
import { Query } from 'types/filter';

import { AttackScriptFeedTabContainer } from '../AttackScript.style';

export default function useAttackScriptFeedTab(
  topic: ArtifactType,
  query: Query,
  className?: string,
  display?: DisplayKind
): { count: number; disabled: boolean; AttackScriptFeedTab: () => JSX.Element } {
  const [page, setPage] = React.useState<number>(1);

  const pageParams = React.useMemo(
    () => ({
      page,
      size: 20,
      sort_by: SortOrder.modified
    }),
    [page]
  );

  const feed = useFeed(topic, query, pageParams);
  const { detection: detectionCount, status: detectStatus } = useDetectionCount(topic, feed?.items);
  const { supplemental, status: supStatus } = useSupplementalItems(topic, feed?.items);

  const isActive = feed?.status === Status.pending;

  const handleChangePage = React.useCallback(
    (newPage: number) => {
      newPage++; // because zeroIndexed
      if (newPage > feed.pageTotal || newPage < 1) {
        return;
      }

      setPage(newPage);
    },
    [feed.pageTotal]
  );

  return {
    count: feed.total,
    disabled: isActive || isEmpty(feed.items),
    AttackScriptFeedTab: () => (
      <FeedExtraProvider
        detection={detectionCount}
        supplemental={supplemental}
        detectionPending={detectStatus === Status.pending}
        supplementalPending={supStatus === Status.pending}
      >
        <AttackScriptFeedTabContainer className={classnames(`AttackScriptFeedTab DisplayKind-${display}`, className)}>
          {isActive ? (
            <Typography className='AttackScriptFeedTab-loading' variant='h3'>
              Fetching page {page}... <CircularProgress size={30} />
            </Typography>
          ) : (
            <FeedItems
              page={feed.page}
              total={feed.pageTotal}
              items={feed.items}
              topic={topic}
              displayKind='Card'
              onChangePage={handleChangePage}
              zeroIndex
            />
          )}
        </AttackScriptFeedTabContainer>
      </FeedExtraProvider>
    )
  };
}
