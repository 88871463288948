import React from 'react';

import { FilterValues, useFilterRegistry } from 'module/GlobalFilter';

import { ArtifactType } from 'types/common';

const DEFAULT_TOPIC = ArtifactType.Session;

interface FilterInterface {
  topic: ArtifactType;
  current: FilterValues;
  [ArtifactType.Analytic]: FilterValues;
  [ArtifactType.AttackTag]: FilterValues;
  [ArtifactType.Collection]: FilterValues;
  [ArtifactType.Intel]: FilterValues;
  [ArtifactType.Marker]: FilterValues;
  [ArtifactType.Session]: FilterValues;
  _clearParamOverride: FilterValues;
  /**
   * When the filter is reset by the user clicking [ {n} Filters Applied ] button,
   * sometimes there is a need for customizing the end result. This may cause the
   * filter to never drop into [ 0 Filters Applied ] state so this should be used
   * sparingly.
   */
  setClearParamOverride(overrides: FilterValues): void;
}

const FilterContext = React.createContext<FilterInterface>(null);
FilterContext.displayName = 'FilterContext';

function useFilter(): FilterInterface {
  const context = React.useContext(FilterContext);

  if (!context) {
    throw new Error('useFilter must be used within the FilterContext');
  }

  return context;
}

/**
 * This provider needs history so it's wrapped within App.js default export
 */
function FilterProvider({ children }: { children: any }) {
  const [clearParamOverride, setClearParamOverride] = React.useState<FilterValues>({});
  const { parseSearch, topic } = useFilterRegistry(DEFAULT_TOPIC);

  const value: FilterInterface = React.useMemo(() => {
    const result = {
      topic: topic || DEFAULT_TOPIC,
      [ArtifactType.Analytic]: parseSearch(ArtifactType.Analytic),
      [ArtifactType.AttackTag]: parseSearch(ArtifactType.AttackTag),
      [ArtifactType.Collection]: parseSearch(ArtifactType.Collection),
      [ArtifactType.Intel]: parseSearch(ArtifactType.Intel),
      [ArtifactType.Marker]: parseSearch(ArtifactType.Marker),
      [ArtifactType.Session]: parseSearch(ArtifactType.Session),
      _clearParamOverride: clearParamOverride,
      setClearParamOverride: override => setClearParamOverride(override || {})
    } as FilterInterface;
    result.current = result[result.topic] || result[DEFAULT_TOPIC];
    return result;
  }, [clearParamOverride, parseSearch, topic]);

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
}

export { FilterProvider, useFilter };
