import { styled } from 'snap-ui/util';

/**
 * use of !important can be removed with removal of semantic
 */
export const Root = styled('div')`
  .pp-dashboard-container {
    display: grid;
    grid-gap: ${p => p.theme.spacing(3)};
    grid-template-columns: repeat(3, 1fr);
    max-width: 1200px;
  }

  // Super basic responsiveness
  @media screen and (max-width: 1210px) {
    .pp-dashboard-container {
      width: 800px;
      grid-template-columns: 1fr !important;
    }

    .pp-dashboard-panel {
      width: 80% !important;
      margin: 5px auto !important;

      .footer {
        padding-top: 30px;
      }
    }

    .pp-attack-panel {
      img {
        display: none !important;
        margin-bottom: -8px;
      }
    }
  }

  .pp-dashboard-panel {
    position: relative;
    height: 220px;
    width: 375px;
    margin: 0px 5px 30px 5px;
    z-index: 0;
    align-items: center;
    background-color: ${p => p.theme.palette.secondary.main};
    padding: ${p => p.theme.spacing(3)};
    text-align: center;

    > a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: ${p => p.theme.spacing(3)};
    }

    &.red {
      border: 2px solid ${p => p.theme.palette.error.main};
    }

    &.blue {
      border: 2px solid ${p => p.theme.palette.info.main};
    }

    &.blue .stat {
      color: ${p => p.theme.palette.info.main};
    }

    &.red .stat {
      color: ${p => p.theme.palette.error.main};
    }

    .title {
      align-items: center;
      color: ${p => p.theme.palette.text.secondary};
      display: flex;
      flex-direction: column;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 8px;
      margin-top: 8px;
    }

    .stat {
      margin-top: 12px;
      color: ${p => p.theme.palette.text.primary};
      font-size: 66px;
      line-height: 100px;
    }

    .footer {
      color: ${p => p.theme.palette.text.primary};
      display: flex;
      flex-flow: row wrap;
      font-size: 16px;
      line-height: 24px;
      font-weight: regular;
      justify-content: center;
      min-height: 40px;

      & > * {
        margin: ${p => p.theme.spacing(0, 1)};
      }
    }

    .features {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }

    .link {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      a {
        color: ${p => p.theme.palette.text.primary} !important;
        text-align: left;

        &:hover {
          color: ${p => p.theme.palette.text.secondary} !important;
        }
      }

      .MuiChip-root {
        font-size: 14px;
        line-height: 20px;
        border-radius: 4px;
        color: ${p => p.theme.palette.background.default} !important;
        font-weight: bold;
      }
      .MuiChip-root.blue {
        background-color: ${p => p.theme.palette.info.light} !important;
        border: 2px solid ${p => p.theme.palette.info.main} !important;
      }
      .MuiChip-root.red {
        background-color: ${p => p.theme.palette.error.light} !important;
        border: 2px solid ${p => p.theme.palette.error.main} !important;
      }
    }
  }

  .pp-marker-count-container {
    margin-top: ${p => p.theme.spacing(3)};
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .pp-marker-icon {
      position: relative;
      margin-right: ${p => p.theme.spacing(2)};

      .icon-container {
        position: relative;
        display: flex;
        justify-content: center;
        left: 0;
        right: 0;

        .icon {
          font-size: 2rem;
        }
      }
    }

    .val {
      color: ${p => p.theme.palette.text.primary};
      font-size: 66px;
      line-height: 100px;
    }
  }

  .pp-attack-panel {
    img {
      width: 50% !important;
    }

    p {
      font-size: 0.85rem;
      font-weight: normal;
      margin-bottom: 0;
    }
  }

  .pp-dashboard-undetected {
    border: 4px solid;
    border-width: 2px;
    border-image-slice: 1;
    border-image-source: linear-gradient(
      to right,
      ${p => p.theme.palette.error.main},
      ${p => p.theme.palette.primary.main}
    );
  }

  .pp-dashboard-validated {
    border: 2px solid ${p => p.theme.palette.primary.main};
  }

  .pp-dashboard-untested {
    border: 4px solid;
    border-width: 2px;
    border-image-slice: 1;
    border-image-source: linear-gradient(
      to right,
      ${p => p.theme.palette.primary.main},
      ${p => p.theme.palette.info.main}
    );
  }
`;
