import React from 'react';

import { faCopy, faDial, faFileMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import sum from 'lodash/sum';
import { Link } from 'react-router-dom';

import { ActionIconButton } from 'snap-ui/Button';
import {
  GridAggregationFunction,
  GridApi,
  GridColDef,
  GridRowParams,
  GridValueFormatterParams,
  TruncateCell
} from 'snap-ui/DataGrid';
import Icon from 'snap-ui/Icon';
import Placeholder from 'snap-ui/Placeholder';
import TableErrorMessage from 'snap-ui/TableErrorMessage';
import Tooltip from 'snap-ui/Tooltip';

import Path from 'constants/paths';

import { DeploymentStatusChip } from 'module/Card/Card.widgets';
import { IOCScoreContainer } from 'module/IOC/IOCCard.style';
import { JobOverviewDetailItem } from 'module/Job';
import { GuidContainer } from 'module/Job/Task/Task.style';
import { SupplementalCatalog } from 'module/Search';
import ConfidenceScoreBadge from 'module/Widgets/ConfidenceScoreBadge';
import CopyButton from 'module/Widgets/CopyButton';

import { Ident } from 'types/common';

import { getPreferredOrgScore, getScoreDetails } from 'utilities/ArtifactUtils';

type Col<T> = GridColDef<T>;
export const iocColumns = [
  {
    field: 'name',
    headerName: 'IOC',
    flex: 1,
    hideable: false
  },
  {
    field: 'hit_count',
    headerName: 'Hits',
    type: 'number',
    width: 150,
    valueFormatter: (p: GridValueFormatterParams) => p.value || 0,
    hideable: false
  },
  {
    field: 'score',
    headerName: 'Score',
    type: 'number',
    width: 150,
    renderCell: p => <IOCScoreContainer>{p.value || 0}</IOCScoreContainer>,
    hideable: false
  }
];

export const detectionColumns = (
  defaultOrgId: Ident,
  supplemental: SupplementalCatalog,
  isSupplementalPending: boolean,
  isSuperUser: boolean
) => {
  const allColumns: Col<JobOverviewDetailItem>[] = [
    {
      field: 'detectionGuidForGrouping',
      headerName: 'Name',
      flex: 1,
      hideable: false
    },
    {
      field: 'guid',
      headerName: 'Detection ID',
      type: 'string',
      minWidth: 125,
      renderCell: p => {
        if (!p?.value) return null;
        return (
          <CopyButton value={p?.value} placement='top' copyTooltip='Copied ID to clipboard!' messageTime={2000} arrow>
            <GuidContainer>
              <Icon className='copy-icon' icon={faCopy} />
              <TruncateCell>{p.value}</TruncateCell>
            </GuidContainer>
          </CopyButton>
        );
      },
      hideable: true
    },
    {
      field: 'integration_name',
      headerName: 'Integration',
      type: 'string',
      minWidth: 100,
      flex: 0.6,
      renderCell: p => <TruncateCell>{p.value}</TruncateCell>,
      hideable: false
    },
    {
      field: 'hit_count',
      headerName: 'Hits',
      type: 'number',
      flex: 0.3,
      minWidth: 75,
      valueFormatter: (p: GridValueFormatterParams) => p.value || 0,
      hideable: false
    },
    {
      field: 'rank',
      headerName: 'Confidence',
      type: 'string',
      hideable: true,
      width: 130,
      valueGetter: p => getPreferredOrgScore(defaultOrgId, supplemental[p.row.guid]?.ranks || []),
      renderCell: cell =>
        isSupplementalPending ? (
          <Placeholder variant='text' width={80} />
        ) : (
          <ConfidenceScoreBadge
            confidenceScoreDetail={getScoreDetails(defaultOrgId, supplemental[cell.row.guid]?.ranks || [])}
          />
        )
    },
    {
      field: 'deployed',
      headerName: 'Deployed',
      type: 'string',
      hideable: true,
      valueGetter: p => supplemental[p.row.guid]?.deployments.some(d => d.organization_id === defaultOrgId),
      width: 130,
      renderCell: cell =>
        isSupplementalPending ? (
          <Placeholder variant='text' width={80} />
        ) : (
          <DeploymentStatusChip supplemental={supplemental[cell.row.guid]} />
        )
    },
    {
      field: 'item_error',
      headerName: 'Error Message',
      renderCell: p => <TableErrorMessage message={p.value} />,
      minWidth: 250,
      flex: 0.3,
      hideable: true
    },
    {
      field: 'name',
      headerName: 'N/A',
      disableColumnMenu: true,
      sortable: false,
      hideable: false
    }
  ];

  return allColumns.filter(col => (col.field === 'guid' ? isSuperUser : true));
};

export const sumUp: GridAggregationFunction<number> = {
  apply: ({ values }) => sum(values),
  label: '',

  columnTypes: ['number']
};
export const constantStringAggregate: GridAggregationFunction<string> = {
  apply: ({ values }) => values.filter(value => value !== null)[0],
  label: '',

  columnTypes: ['string']
};
export const errorAggregate: GridAggregationFunction<string> = {
  apply: ({ values }) => {
    const numberOfErrors = values.filter(value => value !== null).length;
    return numberOfErrors === 0 ? '' : `This detection has ${numberOfErrors} error ${numberOfErrors !== 1 ? 's' : ''}`;
  },
  label: '',

  columnTypes: ['string']
};

export const taskActions = ({
  jobGroupGuid,
  apiRef,
  includeTuningLink
}: {
  jobGroupGuid: string;
  apiRef: React.MutableRefObject<GridApi>;
  includeTuningLink: boolean;
}): Col<JobOverviewDetailItem> => ({
  field: 'Actions',
  type: 'actions',
  minWidth: 40,
  hideable: false,
  getActions: (p: GridRowParams<JobOverviewDetailItem>) => {
    const singleRow = p.row?.guid;
    const childRowId = apiRef?.current.getRowGroupChildren({
      groupId: p.id
    });
    const childrenRowsOfGroup = childRowId?.map(rowId => apiRef.current.getRow<JobOverviewDetailItem>(rowId));
    const isGroupRow = !singleRow && childrenRowsOfGroup;
    const workBenchPath = isGroupRow
      ? `${Path.HuntWorkbench.replace(':guid', jobGroupGuid)}?detection=${childrenRowsOfGroup?.[0]?.guid}`
      : `${Path.HuntWorkbench.replace(':guid', jobGroupGuid)}?detection=${p.row?.guid}&integrationGuid=${
          p.row?.integration_guid
        }`;
    const tuningPath = isGroupRow
      ? `${Path.Detection}/${childrenRowsOfGroup?.[0]?.guid}/tuning`
      : `${Path.Detection}/${p.row.guid}/tuning`;

    const actions = [
      <Tooltip key='workbench' arrow placement='top' title='View in Workbench'>
        <ActionIconButton
          aria-label='View in Workbench'
          component={Link}
          icon={faFileMagnifyingGlass}
          to={workBenchPath}
        />
      </Tooltip>
    ];
    if (includeTuningLink) {
      actions.push(
        <Tooltip key='detection' arrow placement='top' title='Tune Detection'>
          <ActionIconButton
            aria-label='Tune Detection'
            component={Link}
            icon={faDial}
            to={{
              pathname: tuningPath
            }}
          />
        </Tooltip>
      );
    }
    return actions;
  }
});
