import React from 'react';

import Chip from 'snap-ui/Chip';
import FormControlLabel from 'snap-ui/FormControlLabel';

import { Palette } from 'module/Matrix/Matrix.type';

type Props = {
  tier: number;
  palette?: Palette;
  bucket: number;
};

function getLabel(tier: number) {
  switch (tier) {
    case 5:
      return 'Highest';
    case 4:
      return 'Higher';
    case 3:
      return 'High';
    case 2:
      return 'Medium';
    case 1:
      return 'Low';
    case 0:
      return 'Lowest';
  }
}

export default function MatrixLegendChip({ palette, tier }: Props) {
  const style = {
    backgroundColor: palette?.[tier]?.background,
    color: palette?.[tier]?.text,
    border: palette?.[tier]?.text ? `1px solid ${palette?.[tier]?.text}` : ''
  };

  return (
    <FormControlLabel
      value='snap'
      control={
        <Chip
          label={getLabel(tier)}
          sx={{
            ...style,
            '&.Mui-checked': {
              ...style
            },
            marginRight: '0.5rem'
          }}
        />
      }
      label=''
    />
  );
}
