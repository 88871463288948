import React from 'react';

import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import { ActionIconButton } from 'snap-ui/Button';
import TextField from 'snap-ui/TextField';
import { styled } from 'snap-ui/util';

type SectionNameInputProps = {
  'data-testid'?: string;
  initialValue: string;
  onSave: (value: string) => void;
  textHandler?: (text: string) => string;
};

const Container = styled('div')`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(4)};
  margin-bottom: ${p => p.theme.spacing(3)};
`;

const SectionNameInput = (props: SectionNameInputProps): React.ReactElement => {
  const [value, setValue] = React.useState<string>(props.initialValue);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const text = props?.textHandler ? props.textHandler(e.target.value) : e.target.value;
    setValue(text);
  };

  const handleSave = (): void => {
    props.onSave(value);
  };

  return (
    <Container>
      <TextField
        className='name-input'
        id='section_name'
        type='text'
        name='section_name'
        onChange={handleInputChange}
        required
        value={value}
        data-testid={props['data-testid']}
      />
      <ActionIconButton aria-label='Save section name' color='primary' icon={faCheck} onClick={handleSave} />
    </Container>
  );
};

export default SectionNameInput;
