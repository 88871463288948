import React from 'react';

import { getBrowser, isSupported } from 'utilities/BrowserUtils';

import Banner from './Banner';

export default function UnsupportedBrowserBanner() {
  const [showUnsupported, setShowUnsupported] = React.useState(false);

  React.useEffect(() => {
    const browser = getBrowser();
    const supported = isSupported(browser);
    if (!supported) setShowUnsupported(true);
  }, []);

  if (!showUnsupported) return null;

  return <Banner>You are using an unsupported browser. Please use the latest Chrome, Firefox, or Safari.</Banner>;
}
