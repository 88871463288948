import React from 'react';

import { useFormikContext } from 'formik';

import { IntegrationIcon } from 'snap-ui/Icon';

import useOrganizationOptions from 'hooks/useOrganizationOptions';

import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import TextFieldFormik from 'module/Form/TextFieldFormik';

import { FunctionalPermission } from 'types/auth';

import { AutocompleteSelectedIconContainer } from '../Integration.style';
import { IntegrationFormData } from '../Integration.type';
import { AutoCompleteOptionContent } from '../IntegrationAutocomplete';
import { useLanguagePlatforms } from '../LanguagePlatformProvider';

function MetadataFields() {
  const { values } = useFormikContext<IntegrationFormData>();
  const orgOptions = useOrganizationOptions(FunctionalPermission.CreateIntegration);
  const { supportedPlatforms } = useLanguagePlatforms();

  const platformOptions = React.useMemo(() => {
    const platformOptions = Object.keys(supportedPlatforms).map(key => ({
      content: <AutoCompleteOptionContent type={key} text={key} />,
      label: key,
      value: key
    }));
    platformOptions.push({
      content: <AutoCompleteOptionContent type='CustomerManaged' text='Custom' />,
      label: 'Custom',
      value: 'CustomerManaged'
    });
    return platformOptions;
  }, [supportedPlatforms]);

  return (
    <>
      <AutocompleteFormik
        label='Platform'
        name='type'
        options={platformOptions}
        disabled={values.type !== 'CustomerManaged'}
        disableUserAdditions
        disableClearable
        startAdornment={
          values.type ? (
            <AutocompleteSelectedIconContainer>
              <IntegrationIcon type={values?.type} />
            </AutocompleteSelectedIconContainer>
          ) : null
        }
      />
      <TextFieldFormik
        helperText='A unique name to identify this environment'
        label='Integration Name'
        name='name'
        required
      />
      <AutocompleteFormik
        label='Organization'
        name='organization_id'
        options={orgOptions}
        disableUserAdditions
        disableClearable
      />
    </>
  );
}

export default MetadataFields;
