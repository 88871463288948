import React from 'react';

import zod from 'zod';

import { Option } from 'snap-ui/Autocomplete';
import Icon from 'snap-ui/Icon';

import { useAuth } from 'provider';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';
import { ActorMotivationDetails, ActorMotivations } from 'types/mati';

import { AutocompleteOptionContent } from '../GlobalFilter.style';
import { FilterConfig } from '../GlobalFilter.type';
import InclusiveAutocomplete from './InclusiveAutocomplete';

type ActorMotivationsKeys = {
  motivation: string[];
  motivationOp: Ops;
};

type ActorMotivationFilterProps = {
  onChange(values: Partial<ActorMotivationsKeys>): void;
  values: ActorMotivationsKeys;
};

const options = ActorMotivations.sort((a, b) => a.localeCompare(b)).map(motivation => ({
  label: motivation,
  value: motivation,
  content: (
    <AutocompleteOptionContent>
      <Icon icon={ActorMotivationDetails[motivation]?.icon} /> {motivation}
    </AutocompleteOptionContent>
  )
}));

function ActorMotivationFilter({ onChange, values }: ActorMotivationFilterProps): React.ReactElement {
  const { isSubscriber } = useAuth();
  const handleValueChange = (option: Option[]) => {
    const payload = { motivation: option.map(o => o.value) };
    if (option.length === 0) payload['motivationOp'] = undefined;
    onChange(payload);
  };

  const handleOperatorChange = (motivationOp: Ops) => {
    onChange({ motivationOp });
  };

  return (
    <InclusiveAutocomplete
      title='By Actor Motivation'
      name='actor_motivation_dropdown'
      option={options}
      value={values.motivation}
      onValueChange={handleValueChange}
      onOperatorChange={handleOperatorChange}
      operator={values.motivationOp}
      disableInclusionGroup
      disabled={!isSubscriber}
      disabledTooltip='This filter is only available to subscribers'
      tooltip='Filter by threat actor motivations as observed by Mandiant'
    />
  );
}
function toQuery(value: ActorMotivationsKeys) {
  if (!value.motivation?.length) return;
  return {
    field: 'motivations',
    op: Ops.any,
    value: value.motivation
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('motivations'),
    op: zod.literal(Ops.any),
    value: zod.array(zod.string())
  })
  .transform(query => ({
    motivation: query.value,
    motivationOp: query.op
  }));

const ActorMotivationFilterConfig: FilterConfig<ActorMotivationsKeys> = {
  defaults: { default: () => ({ motivation: [], motivationOp: Ops.any }) },
  supportedTopics: [ArtifactType.Collection],
  component: ActorMotivationFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default ActorMotivationFilterConfig;
