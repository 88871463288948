import React from 'react';

import { GridColDef } from 'snap-ui/DataGrid';

import { IOCScoreContainer } from 'module/IOC/IOCCard.style';

import { Artifact } from 'types/common';

import { formatDayTimestamp } from 'utilities/TimeUtils';

export const GridColumnsIOC: (GridColDef<Artifact> & { hideToStart?: boolean })[] = [
  {
    field: 'score',
    headerName: 'Score',
    hideable: false,
    width: 150,
    renderCell: cell => <IOCScoreContainer>{cell.value}</IOCScoreContainer>
  },
  {
    field: 'type',
    headerName: 'Type',
    hideable: false,
    width: 150,
    renderCell: cell => cell.value
  },
  {
    field: 'name',
    headerName: 'Name',
    hideable: false,
    width: 500,
    renderCell: cell => cell.value
  },
  {
    field: 'first_seen',
    headerName: 'First Seen Date',
    sortable: true,
    filterable: true,
    hideable: true,
    width: 175,
    renderCell: cell => formatDayTimestamp(cell.value)
  },
  {
    field: 'last_seen',
    headerName: 'Last Seen Date',
    sortable: true,
    filterable: true,
    hideable: true,
    width: 175,
    renderCell: cell => formatDayTimestamp(cell.value)
  }
];
