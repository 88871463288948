import React from 'react';

import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import isEmpty from 'lodash/isEmpty';

import Chip from 'snap-ui/Chip';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { countries as allCountries, FEATURED_COUNTRIES } from 'constants/countries';

import { LandingArtifact } from 'module/Landing/Landing.type';

import { sortByPrioritizedListOn } from 'utilities/ArrayUtils';

export const Container = styled('div', { label: 'TargetedCountries' })`
  display: flex;
  gap: ${p => p.theme.spacing(2)};
  align-items: center;
  font-size: 1em;

  .MuiChip-label {
    display: flex;
    gap: ${p => p.theme.spacing(2)};
    align-items: center;
  }
  .remaining {
    line-height: 1;
  }
`;

export default function TargetedCountries({
  countries
}: {
  /** alpha 3 country codes */
  countries: LandingArtifact['target_regions'];
}) {
  const populated = countries ? allCountries.filter(c => countries.includes(c.alpha3)) : [];
  const sorted = populated.sort(sortByPrioritizedListOn('alpha3', FEATURED_COUNTRIES));

  const display = sorted.slice(0, 5);
  const remaining = sorted.length - display.length;

  return (
    <Container>
      {isEmpty(display) && <Chip size='small' label='No Targeted Countries Listed' />}
      {display.map(country => {
        return (
          <Tooltip key={country.alpha3} arrow title={country.name} wrap>
            <>{country.flag}</>
          </Tooltip>
        );
      })}
      {remaining > 0 && (
        <Chip
          className='remaining'
          size='small'
          label={
            <>
              <Icon icon={faCirclePlus} /> <span>{remaining}</span>
            </>
          }
        />
      )}
    </Container>
  );
}
