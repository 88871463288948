import React from 'react';

import PieChart from 'snap-ui/Charts/PieChart';

import { BASCampaign, DetectionValidated } from 'module/BAS/BAS.type';

import { getFlatObjectCount } from '../../BAS.util';
import { PieChartDefaultProperties, ValidationsChartColors } from '../CampaignReport.style';
import ReportPanel from './ReportPanel';

type ValidationsPanelProps = {
  campaign: BASCampaign;
};

const DATA_EMPTY_MESSAGE = 'Assigning detections when adding/editing Outcomes below will populate this chart.';

function ValidationsPanel({ campaign }: ValidationsPanelProps) {
  const validationsEmpty = getFlatObjectCount(campaign.validations, DetectionValidated.NotApplicable) === 0;

  const validationsChartData = React.useMemo(() => {
    if (!campaign.validations) return [];
    return [
      { name: DetectionValidated.Validated, value: campaign.validations[DetectionValidated.Validated] },
      {
        name: DetectionValidated.NotValidated,
        value: campaign.validations[DetectionValidated.NotValidated]
      },
      {
        name: DetectionValidated.NotApplicable,
        value: campaign.validations[DetectionValidated.NotApplicable]
      }
    ];
  }, [campaign.validations]);

  return (
    <ReportPanel title='Validations' hasError={validationsEmpty} error={DATA_EMPTY_MESSAGE}>
      <PieChart colors={ValidationsChartColors} data={validationsChartData} {...PieChartDefaultProperties} />
    </ReportPanel>
  );
}

export default ValidationsPanel;
