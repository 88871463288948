import React from 'react';

import classNames from 'classnames';
import { useFormikContext } from 'formik';
import Dropzone, { DropzoneRef } from 'react-dropzone';

import Button from 'snap-ui/Button';
import ButtonGroup from 'snap-ui/ButtonGroup';
import { FormHelperText } from 'snap-ui/FormControl';
import { styled } from 'snap-ui/util';

import { OrStyles } from 'module/Widgets/OrButton';

import { EditIntelForm } from '../IntelEdit/IntelEdit';
import { UnfurlTextField } from './UnfurlTextField';

const Container = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(1)};
  flex-direction: column;

  .ButtonGroup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${p => p.theme.spacing(1)};
  }

  #select-file {
    height: 40px;
    border-radius: 4px;
    width: 100%;
    color: ${p => p.theme.palette.grey[400]};
    border-color: ${p => p.theme.palette.surface.fieldOutline};

    &:hover {
      border-color: ${p => p.theme.palette.common.white};
      transition: border-color 0.01s;
    }
  }

  .Unfurl-textfield {
    min-width: 250px;
    .MuiInputBase-formControl {
      ${OrStyles}
    }
  }
`;

type Props = {
  className?: string;
  disabled?: boolean;
  onUnfurl(s: string | File): void;
};

/** e.g. https://swimlane.com/blog/automating-mitre-attack-testing */
export default function Unfurl({ className, disabled, onUnfurl }: Props) {
  const { values, setValues } = useFormikContext<EditIntelForm>();
  const [file, setFile] = React.useState<File>(null);
  const [source, setSource] = React.useState('');
  const dropzoneRef = React.useRef<DropzoneRef>();

  const openFileDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const handleUnfurl = async (source: string | File) => {
    if (typeof source === 'string') {
      setFile(null);
      setValues({ ...values, quick_add_attachment: [] });
    } else setSource('');
    if (source) onUnfurl(source);
  };

  const handleDrop = (uploadedFile: File[]) => {
    setValues({ ...values, quick_add_attachment: [uploadedFile[0]] });
    setFile(uploadedFile[0]);
  };

  React.useEffect(() => {
    if (file) handleUnfurl(file);
    // using callbacks creates circular dependencies here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <Container className={classNames('Unfurl', className)}>
      <ButtonGroup>
        <UnfurlTextField handleUnfurl={handleUnfurl} disabled={disabled} source={source} setSource={setSource} />
        <Dropzone ref={dropzoneRef} noClick noKeyboard onDrop={handleDrop} accept='.pdf' multiple={false}>
          {({ getInputProps, getRootProps }): React.ReactElement => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button id='select-file' variant='outlined' color='secondary' onClick={openFileDialog}>
                Select&nbsp;File
              </Button>
              {file && <small>{file?.name}</small>}
            </div>
          )}
        </Dropzone>
      </ButtonGroup>
      <FormHelperText>QUICK ADD: Use a link or a PDF to pre-populate this form</FormHelperText>
    </Container>
  );
}
