import React from 'react';

import isEmpty from 'lodash/isEmpty';

import Paper from 'snap-ui/Paper';
import Stepper from 'snap-ui/Stepper';
import { styled } from 'snap-ui/util';

import useTitle from 'hooks/useTitle';

import { standardFormikBaseProps } from 'module/Form';
import Formik from 'module/Form/Formik';

import { useAuth } from 'provider';

import { Status } from 'storage';

import { NOOP } from 'utilities/FunctionUtils';
import { durationToMonths } from 'utilities/TimeUtils';

import {
  Applications,
  ConfidenceThresholds,
  ExploitLikelihood,
  Industries,
  Motivations,
  OfficeSoftware,
  OperatingSystems,
  PriorityThresholdManager,
  Regions,
  Scale,
  SeverityThresholds,
  ThreatLastObserved,
  ThreatPriorities,
  ThreatReview,
  VulnerabilityCharacteristics,
  VulnerabilityRisk
} from './Steps';
import { convertFitVectorsToBucketSizes, expandScale, requiresUpdate, transformValues } from './ThreatProfile.util';
import {
  ExploitationStateOrderedList,
  FIT_VECTOR_DEFAULT,
  ThreatProfile,
  ThreatProfileSchema,
  ThreatProfileStepDetail
} from './ThreatProfileWizard.type';
import useThreatProfileWizard, { ThreatProfileWizardProvider } from './useThreatProfileWizard';

const Container = styled(Paper, { label: 'ThreatProfileWizardContainer' })`
  display: grid;
  grid-template-columns: 240px 1fr;

  .Stepper,
  .StepContent {
    padding: ${p => p.theme.spacing(3)};
    overflow: auto;
  }

  .Stepper {
    height: min-content;
  }

  .StepContent {
    min-height: min-content;
  }

  & .MuiStepConnector-line {
    min-height: ${p => p.theme.spacing(3)}; // default is 24px
  }
`;

const steps: ThreatProfileStepDetail[] = [
  {
    label: 'Industries',
    content: <Industries />,
    keys: ['industries']
  },
  {
    label: 'Regions',
    content: <Regions />,
    keys: ['regions']
  },
  {
    label: 'Scale',
    content: <Scale />,
    keys: ['scale']
  },
  {
    label: 'Adversary Motivations',
    content: <Motivations />,
    keys: ['motivations']
  },
  {
    label: 'Operating Systems',
    content: <OperatingSystems />,
    keys: ['platforms']
  },
  {
    label: 'Office Software',
    content: <OfficeSoftware />,
    keys: ['platforms']
  },
  {
    label: 'Applications',
    content: <Applications />,
    keys: ['applications']
  },
  {
    label: 'Severity Thresholds',
    content: <SeverityThresholds />,
    keys: ['severity_thresholds']
  },
  {
    label: 'Confidence Thresholds',
    content: <ConfidenceThresholds />,
    keys: ['confidence_thresholds']
  },
  {
    label: 'Vulnerability Risk',
    content: <VulnerabilityRisk />,
    keys: ['risk_tolerance']
  },
  {
    label: 'Vulnerability Characteristics',
    content: <VulnerabilityCharacteristics />,
    keys: ['exploitation_state', 'exploited_zero_day', 'exploited_in_wild']
  },
  {
    label: 'Exploit Likelihood',
    content: <ExploitLikelihood />,
    keys: ['exploit_likelihood']
  },
  {
    label: 'Threat Priorities',
    content: <ThreatPriorities />,
    keys: ['threat_recency_threshold']
  },
  {
    label: 'Last Observed Threshold',
    content: <ThreatLastObserved />,
    keys: ['last_observed_threshold']
  },
  {
    label: 'Threat Review',
    content: <ThreatReview />
  },
  {
    label: 'Priority Threshold Manager',
    content: <PriorityThresholdManager />,
    keys: ['fit']
  }
];

function ThreatProfileWizardInner() {
  useTitle('Threat Profile | SnapAttack');
  const { user } = useAuth();
  const organization = user.preferred_organization;
  const { data: threatProfile, noProfileFound, status, incrementalUpdate } = useThreatProfileWizard();
  const [clickableSteps, setClickableSteps] = React.useState<Record<string, boolean>>(() =>
    steps.reduce(
      (steps, step) => ({
        ...steps,
        [step.label]: true
      }),
      {}
    )
  );

  React.useEffect(() => {
    if (noProfileFound) {
      setClickableSteps({});
    }
  }, [noProfileFound]);

  const threatProfileFormInitial: ThreatProfile = {
    organization_id: threatProfile?.organization_id || organization?.id,
    industries: threatProfile?.industries || [],
    regions: threatProfile?.regions || [],
    scale: threatProfile?.scale ? expandScale(threatProfile.scale) : [],
    motivations: threatProfile?.motivations || [],
    applications: threatProfile?.applications || [],
    platforms: threatProfile?.platforms || [],

    risk_tolerance: [threatProfile?.vulnerability_cvss_min || 0, 10],

    confidence_thresholds: [threatProfile?.confidence_min || 0, 1],
    severity_thresholds: [threatProfile?.severity_min || 0, 1],

    exploitation_state: [
      ExploitationStateOrderedList.findIndex(state => state === threatProfile?.vulnerability_exploit_min),
      4
    ],
    exploited_zero_day: threatProfile?.vulnerability_exploit_zero_day ? 'yes' : 'no',
    exploited_in_wild: threatProfile?.vulnerability_exploit_wild ? 'yes' : 'no',
    exploit_likelihood: [threatProfile?.vulnerability_epss_min * 100, 100],

    threat_recency_threshold: threatProfile?.threat_maturity * 100 || 50,
    last_observed_threshold: threatProfile?.observation_age_max
      ? durationToMonths(threatProfile.observation_age_max)
      : 7 * 12,
    fit: threatProfile?.fit
      ? convertFitVectorsToBucketSizes(threatProfile.fit)
      : convertFitVectorsToBucketSizes(FIT_VECTOR_DEFAULT)
  };

  return (
    <Formik<ThreatProfile>
      {...standardFormikBaseProps}
      initialValues={threatProfileFormInitial}
      onSubmit={NOOP}
      zodSchema={ThreatProfileSchema}
    >
      {({ values, touched }) => {
        function handleClick(step: number) {
          const { label, keys } = steps[step];
          setClickableSteps(clickableSteps => ({ ...clickableSteps, [label]: true }));

          if (!isEmpty(keys) && requiresUpdate(keys, values, touched, threatProfileFormInitial)) {
            const transformedValues = transformValues(keys, values);
            if (!isEmpty(transformedValues)) {
              incrementalUpdate(transformedValues);
            }
          }
        }

        return (
          <Container>
            <Stepper
              steps={steps}
              savingStep={status === Status.pending}
              clickableSteps={clickableSteps}
              onClick={handleClick}
              buttons
              vertical
              nonLinear
            />
          </Container>
        );
      }}
    </Formik>
  );
}

export default function ThreatProfileWizard() {
  return (
    <ThreatProfileWizardProvider>
      <ThreatProfileWizardInner />
    </ThreatProfileWizardProvider>
  );
}
