import React from 'react';

import { faClose, faMegaphone } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import Button from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';

import { getMaintenance } from 'apis/resources/auth';

import { Catalog, Scheme, useAsync, useAsyncStorage } from 'storage';

import { Maintenance } from 'types/auth';

import Banner from './Banner';

export default function MaintenanceBanner() {
  const { data, run } = useAsync<Maintenance>();
  const { data: dismissedEndtime, setData: setDismissedEndtime } = useAsyncStorage<string>(
    Scheme.localStorage,
    Catalog.maintenance,
    null
  );

  React.useEffect(() => {
    run(getMaintenance());
  }, [run]);

  const show = React.useMemo(() => {
    if (isEmpty(data)) return false;
    const start = new Date(data.start_time);
    const end = new Date(data.end_time);
    const now = Date.now();
    if (dismissedEndtime && moment(now).isBefore(moment(dismissedEndtime))) return false;

    return data.active && moment(now).isBetween(start, end);
  }, [data, dismissedEndtime]);

  if (!show) return null;

  return (
    <Banner>
      <Icon icon={faMegaphone} className='banner-icon' />
      <strong dangerouslySetInnerHTML={{ __html: data.message }}></strong>
      <Button
        startIcon={<Icon icon={faClose} />}
        variant='text'
        color='secondary'
        onClick={() => setDismissedEndtime(data.end_time)}
      >
        Dismiss
      </Button>
    </Banner>
  );
}
