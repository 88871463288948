import React from 'react';

import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import Path from 'constants/paths';

import Landing from './Landing';
import { getLandingPaths, pathToDiscriminator } from './Landing.util';
import { LandingEdit } from './LandingEdit';
import { LandingProvider } from './LandingProvider';

export type Props = RouteComponentProps<{ name: string }>;

export default function LandingRouter({ match }: Props) {
  const name = decodeURIComponent(match.params.name);
  const type = pathToDiscriminator(match.path);

  return (
    <LandingProvider name={name} type={type}>
      <Switch>
        <Route path={getLandingPaths(Path.Collection)} component={Landing} />
        <Route path={getLandingPaths(Path.CollectionEdit)} component={LandingEdit} />
      </Switch>
    </LandingProvider>
  );
}
