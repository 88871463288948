import React from 'react';

import Guacamole from 'guacamole-common-js';
import { useHistory } from 'react-router-dom';

import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import useNoPermitCreateRedirect from 'hooks/useNoPermitCreateRedirect';
import useTitle from 'hooks/useTitle';

import { CommonEvent, Engage, Fingerprint, NotableEvent } from 'lib/Engagement';

import { CommissionedHost } from 'module/Session/Session.type';

import { useCommissionedMachine } from 'provider';

import { ArtifactType, UploadResult } from 'types/common';

import GuacPanel from './GuacPanel';
import { setRemoteClipboard, uploadFiles } from './SessionVM.service';
import VmToolbar from './VmToolbar';

const Container = styled('div')`
  flex-grow: 1;
  display: flex;
  gap: 0px;
  --toolbar-width: 60px;

  .VMToolbar {
    background-color: ${p => p.theme.palette.common.black};
    width: var(--toolbar-width);
  }

  .GuacPanel {
    height: 100%;
    width: calc(100% - var(--toolbar-width));
  }

  .no-session {
    margin: 20px 100px;
    width: fit-content;

    .Alert {
      min-width: 400px;
    }
  }
`;

type Connection = {
  client: Guacamole.Client;
  tunnel: Guacamole.Tunnel;
};

export default function SessionVM() {
  useNoPermitCreateRedirect(ArtifactType.Session);
  useTitle('Capture Threat | SnapAttack');
  const { replace } = useHistory();
  const { activeSession } = useCommissionedMachine();
  const [activeHost, setActiveHost] = React.useState<CommissionedHost>(activeSession?.[0]);
  const hasActiveSession = !!activeHost;

  const connectionRefs = React.useRef<Record<string, Connection>>({});

  function setConnectionRef(instanceId: string, client: Guacamole.Client, tunnel: Guacamole.Tunnel): void {
    connectionRefs.current[instanceId] = { client, tunnel };
  }

  function handleSendKeys(instanceId: string, keys: number[]) {
    const connection = connectionRefs.current[instanceId];
    const { client } = connection;

    for (let i = 0; i < keys.length; i++) {
      client.sendKeyEvent(1, keys[i]);
    }
    for (let j = 0; j < keys.length; j++) {
      client.sendKeyEvent(0, keys[j]);
    }
  }

  function handleClipboard(instanceId: string, clipboard: string) {
    const connection = connectionRefs.current[instanceId];
    const { client } = connection;

    setRemoteClipboard(client, clipboard);
  }

  function handleUpload(
    instanceId: string,
    files: File[],
    onUploadProgress: (file: File, progress: ProgressEvent) => void
  ): Promise<UploadResult[]> {
    const connection = connectionRefs.current[instanceId];
    if (!connection) return Promise.reject(); // something weird happened
    const { client, tunnel } = connection;

    Engage.track(
      Fingerprint.of(Path.ThreatExecute)
        .withCommon(CommonEvent.Upload)
        .withData({
          count: files.length,
          files: files.map(f => ({ name: f.name, size: f.size, type: f.type }))
        })
    );

    return uploadFiles(activeHost.guacamole_token, activeHost.guacamole_link, files, client, tunnel, onUploadProgress)
      .then(result => {
        Engage.track(
          Fingerprint.of(Path.ThreatExecute).withNotable(NotableEvent.Success).withCommon(CommonEvent.Upload)
        );
        return result;
      })
      .catch(e => {
        Engage.track(Fingerprint.error(Path.ThreatExecute).withCommon(CommonEvent.Upload).withData({ error: e }));
        return e;
      });
  }

  const handleSetActiveHost = (host: CommissionedHost) => {
    Engage.track(
      Fingerprint.of(Path.ThreatExecute).withCommon(CommonEvent.Change).withQualifier('host').withData(host)
    );
    setActiveHost(host);
  };

  React.useLayoutEffect(() => {
    if (!hasActiveSession) replace(Path.ThreatCreate);
  }, [hasActiveSession, replace]);

  return (
    <Container>
      {hasActiveSession && (
        <>
          <GuacPanel host={activeHost} connectionRef={setConnectionRef} />
          <VmToolbar
            host={activeHost}
            setActiveHost={handleSetActiveHost}
            onUpload={handleUpload}
            onSendKeys={handleSendKeys}
            onClipboard={handleClipboard}
          />
        </>
      )}
    </Container>
  );
}
