import React from 'react';

import { useAsync } from 'storage';

import {
  getUserActivityViewedStats,
  getUserActivityHuntedStats,
  getUserActivityCreatedStats
} from '../SocDashboard/SocDashboard.api';
import { ViewedStats, HuntedStats, CreatedStats } from '../SocDashboard/SocDashboard.type';

function useUserActivityViewedStats() {
  const { data, run, status, error } = useAsync<ViewedStats>([]);

  const getViewedStats = React.useCallback(
    payload => {
      run(getUserActivityViewedStats(payload));
    },
    [run]
  );

  return {
    data,
    status,
    error,
    getViewedStats
  };
}

function useUserActivityCreatedStats() {
  const { data, run, status, error } = useAsync<CreatedStats>([]);

  const getCreatedStats = React.useCallback(
    payload => {
      run(getUserActivityCreatedStats(payload));
    },
    [run]
  );

  return {
    data,
    status,
    error,
    getCreatedStats
  };
}

function useUserActivityHuntedStats() {
  const { data, run, status, error } = useAsync<HuntedStats>([]);

  const getHuntedStats = React.useCallback(
    payload => {
      run(getUserActivityHuntedStats(payload));
    },
    [run]
  );

  return {
    data,
    status,
    error,
    getHuntedStats
  };
}

export { useUserActivityViewedStats, useUserActivityHuntedStats, useUserActivityCreatedStats };
