import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import zod from 'zod';

import { FILTER_PREFIX } from 'module/Metadata/Metadata.const';
import { getJsonOperator } from 'module/Metadata/Metadata.util';
import { MetadataFilter } from 'module/Metadata/MetadataFilter';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';

import { FilterAugment, FilterConfig, FilterValues } from '../GlobalFilter.type';

function defaults({ metadataSchema }: FilterAugment): FilterValues {
  return metadataSchema?.properties
    ? Object.entries(metadataSchema.properties).reduce(
        (acc, [key, def]) => ({
          ...acc,
          [`${FILTER_PREFIX}${key}`]: 'enum' in def ? [] : def.default || '',
          [`${FILTER_PREFIX}${key}Op`]: ''
        }),
        {}
      )
    : {};
}

function toQuery(values: FilterValues, augment: FilterAugment) {
  return isEmpty(values) || isEqual(values, defaults(augment))
    ? null
    : Object.entries(values)
        .filter(([k, v]) => !k.endsWith('Op') && v !== '' && v !== 'any' && v?.length > 0)
        .reduce(
          (prev, [key, value]) => ({
            ...prev,
            items: [
              ...prev.items,
              {
                op: getJsonOperator(value),
                field: 'custom_metadata.metadata_value',
                value: [key.replace(FILTER_PREFIX, ''), value],
                case_sensitive: false
              }
            ]
          }),
          {
            op: Ops.and,
            items: []
          }
        );
}

const fromQuery = zod
  .object({
    op: zod.nativeEnum(Ops),
    items: zod.array(
      zod
        .object({
          field: zod.literal('custom_metadata.metadata_value'),
          op: zod.nativeEnum(Ops),
          value: zod.tuple([zod.string(), zod.union([zod.string(), zod.array(zod.string())])])
        })
        .transform(query => {
          // query.value contains a tuple: ['fieldName': 'value']
          const [key, value] = query.value;
          return { ['_m_' + key]: value };
        })
    )
  })
  .transform(query => {
    return query.items.reduce((acc, i) => ({ ...acc, ...i }), {});
  });

const MetadataFilterConfig: FilterConfig = {
  defaults: {
    default: defaults
  },
  supportedTopics: [
    ArtifactType.Analytic,
    ArtifactType.Collection,
    ArtifactType.Intel,
    ArtifactType.Session,
    ArtifactType.AttackScript
  ],
  component: MetadataFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default MetadataFilterConfig;
