import React from 'react';

import { z } from 'zod';

import Button from 'snap-ui/Button';
import { FormDialog } from 'snap-ui/Dialog';
import FormControl from 'snap-ui/FormControl';
import { FieldsLayout } from 'snap-ui/Layout';

import TextFieldFormik from 'module/Form/TextFieldFormik';

function CapacityThreshold({
  socCapacity,
  storeSocCapacity
}: {
  socCapacity: number;
  storeSocCapacity: (value: number) => void;
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  function handleClick(): void {
    setIsOpen(true);
  }
  function handleClose() {
    setIsOpen(false);
  }

  function handleSubmit({ thresholdLimit }: { thresholdLimit: number }): void {
    storeSocCapacity(thresholdLimit);
    setIsOpen(false);
  }
  return (
    <>
      <Button
        className='no-print'
        aria-label='Set Threshold Limit'
        aria-haspopup='true'
        color='primary'
        variant='outlined'
        onClick={handleClick}
      >
        Set Threshold
      </Button>
      <FormDialog
        DialogProps={{
          open: isOpen,
          onClose: handleClose
        }}
        FormikConfig={{
          initialValues: {
            thresholdLimit: socCapacity
          },
          enableReinitialize: true,
          onSubmit: handleSubmit,
          zodSchema: z.object({
            thresholdLimit: z.number().min(0, 'Number is required')
          })
        }}
        SubmitProps={{
          children: 'Set'
        }}
        title={`Set Threshold Limit`}
      >
        <FieldsLayout>
          <FormControl>
            <TextFieldFormik
              label='Threshold Limit'
              name='thresholdLimit'
              required
              type='number'
              inputProps={{ min: 0 }}
            />
          </FormControl>
        </FieldsLayout>
      </FormDialog>
    </>
  );
}

export default CapacityThreshold;
