import Path from 'constants/paths';

import { CollectionDiscriminator } from 'module/Collection/Collection.type';

import { ArtifactType } from 'types/common';

import { formatQueryString } from 'utilities/SearchParam';

import { FEED_ENDPOINT } from './Feed.const';

export function topicEndpoint(type: ArtifactType): string {
  return FEED_ENDPOINT[type];
}

export function getFeedUrl(type: ArtifactType): string {
  let url = topicEndpoint(type);
  if (type !== ArtifactType.AttackScript) url += 'cached/v2/';
  return url;
}

export function stripAttackPrefix(attack: string) {
  const pie = attack?.split(':') || [''];
  const result = pie.length > 1 ? pie.slice(1).join(':') : pie[0];
  return result.trim();
}

export function collectionPath(collectionType: CollectionDiscriminator): string {
  return (
    Path.FeedReset +
    formatQueryString({
      topic: ArtifactType.Collection,
      contentType: collectionType,
      page: 1
    })
  );
}

export function detectionPath(): string {
  return Path.FeedReset + formatQueryString({ topic: ArtifactType.Analytic, page: 1 });
}
