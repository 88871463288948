import React from 'react';

import Divider from 'snap-ui/Divider';
import Typography from 'snap-ui/Typography';

import ReferenceIntel from 'module/Reference/ReferenceIntel';
import ExternalImage from 'module/Widgets/ExternalImage';

import { AnalyticReferences } from 'types/analytic';
import { StrictReactNode } from 'types/core';

import OverviewDescription from './OverviewDescription';
import { Container } from './OverviewTab.styled';

type Props = {
  title: string;
  subtitle?: JSX.Element;
  description: string;
  descriptionComponent?: JSX.Element;
  references?: Partial<AnalyticReferences>;
  orgImage?: string;
  includeBranding?: boolean;
  includeHeading?: boolean;
  children?: StrictReactNode;
};

export const testId = 'overview-tab';

export default function OverviewTab(props: Props) {
  const includeHeading = props.includeHeading ?? true;
  const includeBranding = props.includeBranding ?? true;

  return (
    <Container className='Overview' data-testid={testId}>
      <div className='banner'>
        <Typography variant='h4' aria-label={props.title} className='org'>
          {props.title}
        </Typography>
        <Divider className='divide-banner' />
        {props.subtitle}
        {includeBranding && (
          <div className='branding'>
            <ExternalImage
              src={props.orgImage || process.env.PUBLIC_URL + '/images/logo.png'}
              altSrc={process.env.PUBLIC_URL + '/images/logo.png'}
              alt='collection'
            />
          </div>
        )}
      </div>
      <div className='content'>
        {props?.descriptionComponent ? (
          props.descriptionComponent
        ) : (
          <OverviewDescription includeHeading={includeHeading} description={props?.description} />
        )}
        {props.references && <ReferenceIntel data={props.references} />}
        {props.children}
      </div>
    </Container>
  );
}
