import Card from 'snap-ui/Card';
import { styled } from 'snap-ui/util';

export const RecommendationContainer = styled('div', { name: 'RecommendationContainer' })`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${p => p.theme.spacing(4)};

  @media (min-width: 400px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (min-width: 1800px) {
    gap: ${p => p.theme.spacing(6)};
  }
`;

export const RecommendationPanelContainer = styled(Card, { name: 'RecommendationPanelContainer' })`
  display: flex;
  flex-direction: column;
  padding: ${p => p.theme.spacing(5)};
  gap: ${p => p.theme.spacing(3)};

  &.SnapScore > div {
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(3)};
    flex-grow: 1;
  }

  .title {
    align-items: center;
    color: ${p => p.theme.palette.grey[400]};
    display: flex;
    gap: ${p => p.theme.spacing(3)};

    .MuiTypography-body1 {
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 600;
    }
  }

  .description {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  }
`;

export const SnapScoreEvaluation = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: ${p => p.theme.spacing(2)};
  align-items: center;
  justify-content: center;
`;

export const NumberValue = styled('span')`
  font-weight: bold;
  line-height: 1.75rem;
  font-size: 2.25rem;
`;

export const SnapScoreLabelContainer = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(3)};
  align-items: center;
  justify-content: space-between;
  color: ${p => p.theme.palette.grey[400]};
`;

export const SnapScoreLabel = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(3)};
  align-items: center;
`;

export const ScoreScoreTypeLabel = styled('span')`
  font-weight: bold;
`;

export const SnapScoreNumber = styled('span')`
  font-weight: bold;
  font-size: 7rem;
  line-height: 5rem;
  margin: 0;
`;

export const TrendPercentage = styled('div')`
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  line-height: 1.2rem;
  font-weight: bold;
  color: ${p => p.theme.palette.mint.main};
`;

export const SnapLogo = styled('div')`
  height: 24px;
  img {
    height: 100%;
  }
`;
