import React from 'react';

import FormControlLabel from 'snap-ui/FormControlLabel';
import Switch from 'snap-ui/Switch';

import { DEMO_ORG_GUIDS } from 'constants/auth';

import useDemoMode from 'hooks/useDemoMode';

import { useAuth, usePushSnack } from 'provider';

const fieldName = 'Demo Mode';

function UserOptionDemoMode() {
  const { user } = useAuth();
  const pushSnack = usePushSnack();
  const { isDemoMode, setDemoMode } = useDemoMode();

  const isDemoOrg = DEMO_ORG_GUIDS.includes(user?.preferred_organization?.guid);

  async function onSubmit(demo_mode) {
    pushSnack('Saved', 'info', 'center', 'bottom', 5000);
    await setDemoMode(demo_mode);
  }

  if (!isDemoOrg) return null;
  return (
    <FormControlLabel
      control={<Switch color='primary' name='demo_mode' checked={isDemoMode} onChange={(_e, mode) => onSubmit(mode)} />}
      label={fieldName}
      labelPlacement='end'
      className='demoMode'
    />
  );
}

export default UserOptionDemoMode;
