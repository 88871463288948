import React from 'react';

import { Discriminator } from 'module/Tag';

import { useLandingCatalog } from '../../LandingProvider';
import CVSS from './CVSS';
import CWE from './CWE';
import EPSS from './EPSS';
import Exploitation from './Exploitation';
import KnownExploitedDate from './KnownExploitedDate';
import Mitigation from './Mitigation';
import SeverityDetail from './SeverityDetail';
import VulnerabilityAnalysis from './VulnerabilityAnalysis';

export default function VulnerabilitiesDetails() {
  const { source, type } = useLandingCatalog();
  if (type !== Discriminator.Vulnerability || !source.external_sources?.includes('mandiant')) return null;

  return (
    <>
      <Exploitation />
      <SeverityDetail />
      <VulnerabilityAnalysis />
      <CVSS />
      <KnownExploitedDate />
      <Mitigation />
      <EPSS />
      <CWE />
    </>
  );
}
