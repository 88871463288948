import React from 'react';

import { LogsSummary } from 'module/Detection/Detection.type';

import { ArtifactType } from 'types/common';

import MarkerContent from './core/MarkerContent';
import Wrapper from './core/Wrapper';

type Props = {
  error?: any;
  summary: LogsSummary;
  topic: ArtifactType;
  iconsInheritBackgroundColor?: boolean;
};

export default function DefensivePosture({ error, summary, topic, iconsInheritBackgroundColor }: Props) {
  return (
    <Wrapper className='DefensivePosture' showError={error || !summary}>
      <MarkerContent
        topic={topic}
        count={summary.blue_collisions}
        unique={summary.unique_analytics || 0}
        validated={summary.validated}
        gap={summary.validated_gaps}
        undetected={summary.undetected}
        unvalidated={summary.unvalidated}
        iconsInheritBackgroundColor={iconsInheritBackgroundColor}
      />
    </Wrapper>
  );
}
