import React from 'react';

import MuiPaper, { PaperProps } from '@mui/material/Paper';

const Paper = React.forwardRef<HTMLDivElement, PaperProps>(function Paper(props, ref) {
  return <MuiPaper {...props} ref={ref} />;
});

export default Paper;
export type { PaperProps } from '@mui/material/Paper';
