import React from 'react';

import { faBracketsCurly, faCopy, faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import isNil from 'lodash/isNil';

import { ActionIconButton } from 'snap-ui/Button';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import CopyButton from 'module/Widgets/CopyButton';

import { usePushSnack } from 'provider';

import { useSyntaxCheckerContext } from './SyntaxCheckerProvider';

const Button = styled(ActionIconButton)`
  &.invalid {
    color: ${p => p.theme.palette.warning.main};
  }

  &.valid {
    color: ${p => p.theme.palette.success.main};
  }
`;

const ErrorContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${p => p.theme.spacing(2)};
`;

export function SyntaxCheckerButton({ className }: { className?: string }): JSX.Element {
  const pushSnack = usePushSnack();
  const { checkSyntax, canCheckSyntax, isPending, isValid, syntaxError } = useSyntaxCheckerContext();

  let buttonLabel = 'Check syntax against your environment';
  let disabled = false;
  let extraClassName: string;
  let icon = faBracketsCurly;
  let tooltipTitle: React.ReactNode = buttonLabel;

  if (!canCheckSyntax) {
    buttonLabel = 'Select a huntable language to check syntax against your environment';
    disabled = true;
    tooltipTitle = buttonLabel;
  } else if (isPending) {
    buttonLabel = 'Checking syntax against your environment';
    disabled = true;
    extraClassName = 'fa-spin';
    icon = faSpinnerThird;
    tooltipTitle = buttonLabel;
  } else if (isValid === true) {
    buttonLabel = 'Syntax is correct and compatible with your environment';
    extraClassName = 'valid';
    tooltipTitle = buttonLabel;
  } else if (isValid === false) {
    buttonLabel = `Syntax is incorrect; fix the error to deploy: ${syntaxError}`;
    extraClassName = 'invalid';
    tooltipTitle = (
      <ErrorContainer>
        {buttonLabel}{' '}
        <CopyButton value={syntaxError}>
          <ActionIconButton style={{ float: 'right' }} aria-label='Copy error to clipboard' icon={faCopy} />
        </CopyButton>
      </ErrorContainer>
    );
  }

  React.useEffect(() => {
    if (!isNil(isValid) && !isPending) {
      const color = isValid ? 'success' : 'warning';
      pushSnack(tooltipTitle, color, 'center');
    }
    // tooltipTitle is derived from isValid and isPending, and we don't want this to fire just for tooltip changes
  }, [isPending, isValid, pushSnack]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Tooltip arrow placement='top' title={tooltipTitle} wrap>
      <Button
        aria-label={buttonLabel}
        className={classnames('SyntaxCheckerButton', extraClassName, className)}
        disabled={disabled}
        icon={icon}
        onClick={checkSyntax}
      />
    </Tooltip>
  );
}
