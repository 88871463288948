import React from 'react';

import { faCancel, faSquareUp } from '@fortawesome/pro-solid-svg-icons';
import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router-dom';

import Alert from 'snap-ui/Alert';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';

import Path from 'constants/paths';

import { ApiError } from 'module/ApiError';
import { standardFormikBaseProps } from 'module/Form';
import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import Formik from 'module/Form/Formik';
import MarkdownFormik from 'module/Form/MarkdownFormik';
import TextFieldFormik from 'module/Form/TextFieldFormik';
import {
  ArtifactWidgetFooter,
  ArtifactWidgetMenuWrapper,
  BurgerClicker,
  Form,
  Menu,
  Publish,
  TagDivider
} from 'module/Layout/Artifact.widgets';
import { Discriminator, SoftwareType, TagAutocompleteFormik } from 'module/Tag';
import NotFound from 'module/Util/Fallback/NotFound';

import { useAuth } from 'provider';

import { Status } from 'storage';

import { createNewLanding, updateLanding } from '../Landing.service';
import { LandingForm, LandingFormSchema } from '../Landing.type';
import {
  isMutable,
  mapAliasValues,
  mapInitialSnapattackAliases,
  mapInitialSnapattackName,
  mapInitialSnapattackOverview,
  mapInitialSnapattackTagValuesByKey
} from '../Landing.util';
import { LandingTitle } from '../Landing.widgets';
import { AliasOf } from '../Landing.widgets';
import { useLandingCatalog } from '../LandingProvider';
import { StyledArtifact } from './LandingEdit.style';

function _LandingEdit() {
  const { name, type, data, task, errorProps, status, reset, stow } = useLandingCatalog();

  const { replace } = useHistory();

  const [dirtyWarning, setDirtyWarning] = React.useState<string>();
  const [error, setError] = React.useState<string>();

  const initialSelectionValues = React.useMemo(
    () => ({
      alias_names: mapInitialSnapattackAliases(data),
      actor_names: mapInitialSnapattackTagValuesByKey('actors', data),
      attack_names: mapInitialSnapattackTagValuesByKey('attacks', data),
      name: mapInitialSnapattackName(data),
      overview: mapInitialSnapattackOverview(data),
      software_names: mapInitialSnapattackTagValuesByKey('software', data),
      vulnerabilities_names: mapInitialSnapattackTagValuesByKey('vulnerabilities', data)
    }),
    [data]
  );

  const handleCancel = () => {
    stow();
    replace(`${Path.Collection}/${type}/${encodeURIComponent(name)}`);
  };

  const handleSubmit = async (values: LandingForm) => {
    setDirtyWarning(null);
    if (!isMutable(type)) return;

    const isNameEqual = isEqual(name, values.name);
    const pageName = isNameEqual ? name : values.name;
    const runner = async () => {
      const tag = data.items.find(i => i.external_source === 'snapattack');
      if (tag) await updateLanding(tag.id, type, values);
      else await createNewLanding(type, values);
    };

    task(runner()).then(() => {
      reset();
      replace(`${Path.Collection}/${type}/${encodeURIComponent(pageName)}`);
    });
  };

  const handlePreSubmit = (dirty: boolean, submitter: () => void) => {
    if (!dirty) setDirtyWarning('There are no changes. Change some content first and then try publishing.');
    else submitter();
  };

  React.useEffect(() => {
    if (!isMutable(type)) setError('This type of landing is not creatable or editable');
  }, [type]);

  return (
    <Formik
      {...standardFormikBaseProps}
      initialValues={{
        actor_names: initialSelectionValues.actor_names,
        alias_names: initialSelectionValues.alias_names,
        attack_names: initialSelectionValues.attack_names,
        external_source: 'snapattack',
        name: initialSelectionValues.name,
        overview: initialSelectionValues.overview,
        software_names: initialSelectionValues.software_names,
        type: type === Discriminator.Software ? data.combined.type || SoftwareType.Malware : undefined,
        vulnerabilities_names: initialSelectionValues.vulnerabilities_names
      }}
      zodSchema={LandingFormSchema}
      onSubmit={handleSubmit}
    >
      {({ dirty, submitForm }) => (
        <StyledArtifact
          isPending={status === Status.pending}
          type={type}
          meta={
            <>
              <LandingTitle />
              <AliasOf className='Landing-alias' />
              <ArtifactWidgetFooter separation>
                <Typography className='LandingEdit-meta' component='div' variant='body2'>
                  <div>{data.combined.external_source}</div>
                  <div>{data.combined.mitre_id}</div>
                  <div>{data.combined.sigma_name}</div>
                </Typography>
                <ArtifactWidgetMenuWrapper>
                  <Publish
                    onClick={() => handlePreSubmit(dirty, submitForm)}
                    startIcon={<Icon icon={faSquareUp} />}
                    disabled={!isMutable(type)}
                  >
                    Publish
                  </Publish>
                  <Menu>
                    <BurgerClicker icon={faCancel} title='Cancel' onClick={handleCancel} />
                  </Menu>
                </ArtifactWidgetMenuWrapper>
              </ArtifactWidgetFooter>
            </>
          }
        >
          <Form>
            <ApiError {...errorProps} />
            {dirtyWarning && <Alert severity='warning'>{dirtyWarning}</Alert>}
            {error && <Alert severity='error'>{error}</Alert>}
            <TextFieldFormik label='External Source' name='external_source' readOnly disabled />
            <TextFieldFormik label='Name' name='name' />
            {type === Discriminator.Software && (
              <AutocompleteFormik
                label='Type'
                name='type'
                options={[
                  { value: SoftwareType.Malware, content: SoftwareType.Malware },
                  { value: SoftwareType.Tool, content: SoftwareType.Tool }
                ]}
                disableClearable
              />
            )}
            <MarkdownFormik label='Overview' name='overview' required />
            <AutocompleteFormik label='Alias' name='alias_names' options={mapAliasValues(data.combined)} multiple />
            <TagDivider />
            <TagAutocompleteFormik name='attack_names' discriminator={Discriminator.Attack} multiple />
            {type !== Discriminator.Actor && (
              <TagAutocompleteFormik name='actor_names' discriminator={Discriminator.Actor} multiple />
            )}
            {type !== Discriminator.Software && (
              <TagAutocompleteFormik name='software_names' discriminator={Discriminator.Software} multiple />
            )}
            <TagAutocompleteFormik name='vulnerabilities_names' discriminator={Discriminator.Vulnerability} multiple />
          </Form>
        </StyledArtifact>
      )}
    </Formik>
  );
}

export function LandingEdit() {
  const { user } = useAuth();
  if (!user.superuser) return <NotFound />;
  return <_LandingEdit />;
}
