import React from 'react';

import { faEdit, faLayerPlus } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import { ValidationError } from 'apis';

import Path from 'constants/paths';

import { Head } from 'module/Analytic/Analytic.style';
import { Collection } from 'module/Collection/Collection.type';
import UseForTrainingButton from 'module/Collection/UseForTrainingButton';
import { ArtifactWidgetFooter, BurgerClicker, Menu, Title } from 'module/Layout/Artifact.widgets';

import { checkContentPermission } from 'services/authService';

import { ContentPermission } from 'types/auth';
import { ArtifactType } from 'types/common';

import CurationClone from './CurationClone';
import CurationConfirmDelete from './CurationConfirmDelete';

type CurationHeader = {
  collection: Collection;
  onDelete(): void;
  onClone?(): void;
  onError(error: ValidationError): void;
};

export default function CurationHeader({ collection, onDelete, onClone, onError }: CurationHeader) {
  const { push } = useHistory();
  const isMutable = checkContentPermission(collection, ContentPermission.Edit);
  const isSharable = checkContentPermission(collection, ContentPermission.Share);
  return (
    <Head>
      <Title headerText={collection.name} contentType={ArtifactType.Collection} />
      <ArtifactWidgetFooter>
        <Menu>
          {({ toggle }) =>
            !isMutable
              ? [
                  <CurationClone
                    key='clone'
                    onClone={() => {
                      toggle();
                      onClone();
                    }}
                    onCancel={toggle}
                    collection={collection}
                  >
                    {({ icon, onClick }) => <BurgerClicker icon={icon} title='Edit Collection' onClick={onClick} />}
                  </CurationClone>,
                  <UseForTrainingButton
                    key='share'
                    guid={collection.guid}
                    orgId={collection?.organization?.id}
                    onCancel={toggle}
                    disabled={!isSharable}
                    TooltipProps={{
                      title: isSharable ? '' : 'You are not authorized to share this item.'
                    }}
                  />
                ]
              : [
                  <BurgerClicker
                    key='content'
                    disabled={!isMutable}
                    onClick={() => push(`${Path.CollectionAttach}/${collection.guid}`, collection)}
                    icon={faLayerPlus}
                    title='Edit Linked Content'
                    TooltipProps={{
                      title: isMutable ? '' : 'You are not authorized to edit this item.'
                    }}
                  />,
                  <BurgerClicker
                    key='overview'
                    disabled={!isMutable}
                    onClick={() => push(`${Path.CollectionEdit}/${collection.guid}`, collection)}
                    icon={faEdit}
                    title='Edit Collection Overview'
                    TooltipProps={{
                      title: isMutable ? '' : 'You are not authorized to edit this item.'
                    }}
                  />,
                  <UseForTrainingButton
                    key='share'
                    guid={collection.guid}
                    orgId={collection?.organization?.id}
                    onCancel={toggle}
                    disabled={!isSharable}
                    TooltipProps={{
                      title: isSharable ? '' : 'You are not authorized to share this item.'
                    }}
                  />,
                  <CurationConfirmDelete
                    key='delete'
                    guid={collection.guid}
                    onDelete={() => {
                      toggle();
                      onDelete();
                    }}
                    onError={onError}
                    onCancel={toggle}
                  >
                    {({ icon, onClick }) => (
                      <BurgerClicker
                        disabled={!isMutable}
                        onClick={onClick}
                        icon={icon}
                        title='Delete Collection'
                        TooltipProps={{
                          title: isMutable ? '' : 'You are not authorized to delete this item.'
                        }}
                      />
                    )}
                  </CurationConfirmDelete>
                ]
          }
        </Menu>
      </ArtifactWidgetFooter>
    </Head>
  );
}
