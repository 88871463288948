import React from 'react';

import { styled } from 'snap-ui/util';

import { ArtifactScore } from 'types/common';

import { ScoreBadge } from '../Widgets/Badge';
import SliderFormik from './SliderFormik';

const artifactScores = Object.values(ArtifactScore).reverse();

const SmallScoreBadge = styled(ScoreBadge)`
  margin-top: ${p => p.theme.spacing(5)};
  min-width: 55px;
`;

function getMarks(type: 'SEVERITY' | 'CONFIDENCE') {
  return artifactScores.map((score, index) => ({
    // BE expects a float between 0 and 1
    // There are 6 artifact scores (thus 5 steps).
    // 1 / 5 = 0.2
    value: Number((index * 0.2).toFixed(2)),
    label: <SmallScoreBadge name={type} score={score} />
  }));
}

function valuetext(value: number) {
  // Convert decimal value into artifactScore index
  // Inverse of `getMarks`
  const index = Math.round(value * 5);
  return artifactScores[index];
}

export default function ArtifactScoreSliderFormik({
  type,
  name
}: {
  type: 'SEVERITY' | 'CONFIDENCE';
  name: string;
}): JSX.Element {
  const marks = getMarks(type);

  return (
    <SliderFormik
      name={name}
      className='ArtifactScoreSlider'
      fixedBoundary='right'
      marks={marks}
      step={null}
      min={0}
      max={1}
      valueLabelDisplay='auto'
      getAriaValueText={valuetext}
      valueLabelFormat={valuetext}
    />
  );
}
