import { Order, SortDirection } from 'types/filter';

import usePaginate from './usePaginate';
import useSearch, { FilterFn } from './useSearch';
import useSort, { SortControls } from './useSort';

export type TableControls<T> = {
  createSortHandler: SortControls<T>['createSortHandler'];
  data: T[];
  order: Order;
  setPage(p: number): void;
  setQuery(q: string): void;
  page: number;
  pageTotal: number;
};

export default function useTableControls<T>(
  data: T[],
  filterFn: FilterFn<T>,
  initialSort: Pick<Order, 'field'> & Partial<Order>,
  pageSize = 10
): TableControls<T> {
  const { filteredData, setQuery } = useSearch(data, filterFn);
  const { createSortHandler, order, sortedData } = useSort(filteredData, {
    direction: SortDirection.asc,
    ...initialSort
  });
  const { page, pageData, pageTotal, handleChangePage: setPage } = usePaginate(sortedData, pageSize);

  return {
    createSortHandler,
    data: pageData,
    order,
    setPage,
    setQuery,
    page,
    pageTotal
  };
}
