import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import Alert from 'snap-ui/Alert';
import { Autocomplete } from 'snap-ui/Autocomplete';
import Tabs from 'snap-ui/Tabs';
import Typography from 'snap-ui/Typography';

import Path from 'constants/paths';

import useOrganizationOptions from 'hooks/useOrganizationOptions';
import useTitle from 'hooks/useTitle';

import May from 'module/May';
import withSubscriberOnly from 'module/Setting/withSubscriberOnly';
import NotFound from 'module/Util/Fallback/NotFound';

import { useAuth } from 'provider';

import { FunctionalPermission } from 'types/auth';

import { LanguageConfigPage } from './LanguageConfig.style';
import LanguageList from './LanguageList';
import { useLanguageControls } from './LanguageProvider';
import SigmaConfigList from './SigmaConfigList';

const Subpage = {
  Languages: 'languages',
  Pipelines: 'pipelines',
  Validators: 'validators',
  Sigma: 'sigma'
} as const;

type Subpage = (typeof Subpage)[keyof typeof Subpage];

const isPresent = e => e !== undefined;

function LanguageConfig({ subpage }: { subpage: Subpage }): JSX.Element {
  useTitle('Configure Detection Translations | SnapAttack');
  const { user } = useAuth();
  const { replace } = useHistory();
  const { pathname } = useLocation();
  React.useEffect(() => {
    if (!subpage || !Object.values(Subpage).includes(subpage)) replace(`${Path.LanguageConfig}/${Subpage.Languages}`);
  }, [replace, pathname, subpage]);

  const _orgOptions = useOrganizationOptions(FunctionalPermission.CreateCompilationTarget, true);
  const orgOptions = React.useMemo(() => {
    return [{ content: 'All Organizations', label: 'All Organizations', value: '0' }, ..._orgOptions];
  }, [_orgOptions]);
  const { selectedOrgId, setSelectedOrgId } = useLanguageControls();

  function handleTabChange(e, value: Subpage) {
    replace(`${Path.LanguageConfig}/${value}`);
  }

  if (!Object.values(Subpage).includes(subpage)) {
    return <NotFound />;
  }

  return (
    <LanguageConfigPage>
      <May I={FunctionalPermission.CreateCompilationTarget}>
        <May.No>
          <Alert severity='warning'>Contact your organization administrator to make changes to these settings.</Alert>
        </May.No>
      </May>
      <Typography variant='h1'>Language Configuration</Typography>
      <Tabs
        key={subpage}
        auxiliary={
          subpage === Subpage.Languages &&
          orgOptions.length > 1 &&
          user.superuser && (
            <Autocomplete
              className='organization-dropdown'
              disableClearable
              disableUserAdditions
              elevated
              label='Organization'
              name='organization_id'
              options={orgOptions}
              onChange={v => setSelectedOrgId(Number(v))}
              value={selectedOrgId.toString()}
            />
          )
        }
        initialValue={subpage}
        nowrap
        onChange={handleTabChange}
        tabs={[
          {
            value: Subpage.Languages,
            label: 'Languages',
            content: <LanguageList />
          },
          {
            value: Subpage.Pipelines,
            label: 'Pipelines',
            content: <SigmaConfigList discriminator='pipeline' />
          },
          {
            value: Subpage.Validators,
            label: 'Validators',
            content: <SigmaConfigList discriminator='validator' />
          },
          {
            value: Subpage.Sigma,
            label: 'Configurations',
            content: <SigmaConfigList discriminator='field_mapping' />
          }
        ].filter(isPresent)}
      />
    </LanguageConfigPage>
  );
}

export default withSubscriberOnly(LanguageConfig);
