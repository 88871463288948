import React from 'react';

import { Link, LinkProps } from 'react-router-dom';

import Path from 'constants/paths';

import { RegistrationMethod } from 'types/auth';

import useRegistration from './useRegistration';

/**
 * provides a link to the registration page, if that's allowed. Null if not
 * props are passed to the react-router-dom <Link /> component
 * the children prop is included before the <Link /> so you can include any markup whose visibility is conditional
 * on the link being visible
 */
export default function RegisterLink({ children, ...props }: Partial<LinkProps>): JSX.Element {
  const { registrationMethod } = useRegistration();
  return registrationMethod === RegistrationMethod.Public ? (
    <>
      {children}
      <Link to={Path.Register} {...props}>
        Register
      </Link>
    </>
  ) : null;
}
