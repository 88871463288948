import React from 'react';

import { SupplementalArtifact } from 'module/Search';

import { useAsync } from 'storage';

import { ArtifactAnalyticCatalog } from 'types/analytic';
import { Guid } from 'types/common';

import { getAnalyticRecommendations, getBulkAnalyticRecommendations } from './Session.api';

export default function useAnalyticRecommendations(guid: Guid) {
  const { data, run, status, error } = useAsync<SupplementalArtifact[]>([]);

  const sortedData = React.useMemo(() => {
    return data.sort((a, b) => {
      const aDeployed = a.deployments[0]?.integrations?.length > 0;
      const bDeployed = b.deployments[0]?.integrations?.length > 0;
      if (aDeployed) return -1;
      else if (bDeployed) return 1;

      const aCompat = a.deployable;
      const bCompat = b.deployable;
      if (aCompat) return -1;
      else if (bCompat) return 1;

      return 0;
    });
  }, [data]);

  React.useEffect(() => {
    if (guid) {
      run(getAnalyticRecommendations(guid));
    }
  }, [run, guid]);

  return {
    data: sortedData,
    status,
    error
  };
}

export function useBulkAnalyticsRecommendations(guid: Guid) {
  const { data, run, status, error } = useAsync<ArtifactAnalyticCatalog[]>([]);

  React.useEffect(() => {
    if (guid) {
      run(getBulkAnalyticRecommendations(guid));
    }
  }, [run, guid]);

  return {
    data,
    status,
    error
  };
}
