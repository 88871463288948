import React from 'react';

import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import Button from 'snap-ui/Button';
import Typography from 'snap-ui/Typography';

import { ApiErrorResponse } from 'apis';

import useSSO from 'aso/useSSO';

import config from 'config/config';

import { LOGIN_METHODS } from 'constants/auth';
import { Path } from 'constants/paths';

import useTitle from 'hooks/useTitle';

import { CopyrightBadge } from 'module/Scaffold/core/Copyright';

import { authenticatedLogin, resendVerificationRequest, useAuth, usePushSnack, verify } from 'provider';

import { getQueryParam } from 'utilities/SearchParam';

import { LoginRegisterPanel } from '../Authentication.helper';
import { AuthenticationRoot } from '../Authentication.style';
import Multifactor from '../Multifactor/Multifactor';
import LoginForm from './LoginForm';

enum MessageState {
  InvalidCredential = 'InvalidCredential',
  SSORequired = 'SSORequired',
  VerificationRequired = 'VerificationRequired',
  VerificationRequestSuccess = 'VerificationRequestSuccess',
  VerificationSuccessful = 'VerificationSuccessful',
  AdminApprovalRequired = 'AdminApprovalRequired',
  CommunityInvite = 'CommunityInvite',
  Lockout = 'Lockout',
  UnknownError = 'UnknownError'
}

export default function Login() {
  useTitle('Login | SnapAttack');
  const auth = useAuth();
  const sso = useSSO();
  const pushSnack = usePushSnack();
  const { push, location } = useHistory();

  const [attemptedEmail, setAttemptedEmail] = React.useState<string>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [messageState, setMessageState] = React.useState<MessageState>(null);
  const [ssoUrl, setSsoUrl] = React.useState<string>(null);
  const [mfaLoginIdentifier, setMfaLoginIdentifier] = React.useState<string>(null);

  const verifyToken = getQueryParam(location.search, 'verify');
  React.useEffect(() => {
    if (verifyToken) {
      setLoading(true);
      verify(verifyToken)
        .then(() => setMessageState(MessageState.VerificationSuccessful))
        .catch(() => setMessageState(MessageState.UnknownError))
        .finally(() => setLoading(false));
    }
  }, [verifyToken]);

  React.useEffect(() => {
    if (auth.user.email) push(Path.LoginSuccess);
  }, [auth.user.email, push]);

  const authEmailLogin = async (data: { email: string; password: string }) => {
    setAttemptedEmail(data.email);
    setLoading(true);

    await authenticatedLogin(data).then(
      response => {
        if (response?.data?.login_identifier) {
          setMfaLoginIdentifier(response?.data?.login_identifier);
          setMessageState(null);
        } else {
          push(Path.LoginSuccess);
        }
      },
      (error: AxiosError<ApiErrorResponse>) => {
        if (error.response.status === 428) push(Path.MultifactorConfiguration);

        let messageState: MessageState;
        switch (error.response?.data?.detail) {
          case 'Your E-mail address is still pending verification.':
            messageState = MessageState.VerificationRequired;
            break;
          case 'Unable to authenticate: incorrect email/password combination.':
            messageState = MessageState.InvalidCredential;
            break;
          case 'Thanks for signing up, we will let you know when the SnapAttack Community Edition becomes available.':
            messageState = MessageState.CommunityInvite;
            break;
          case 'Your account registration is still pending admin approval.':
            messageState = MessageState.AdminApprovalRequired;
            break;
          case 'Maximum allowable login attempts exceeded. Your account has been temporarily locked.':
            messageState = MessageState.Lockout;
            break;
          case 'Organization requires SSO authentication.':
            messageState = MessageState.SSORequired;
            setSsoUrl(error.response?.headers?.sso_url);
            break;
          default:
            messageState = MessageState.UnknownError;
        }
        setMessageState(messageState);
        setLoading(false);
      }
    );
  };

  const resendVerification = (): void => {
    setLoading(true);
    resendVerificationRequest(attemptedEmail)
      .then(() => {
        setMessageState(MessageState.VerificationRequestSuccess);
      })
      .catch(() => {
        setMessageState(MessageState.UnknownError);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleMFAError = errorMessage => {
    pushSnack(errorMessage, 'error', 'center', 'bottom', 5000);
    setMfaLoginIdentifier(null);
    setMessageState(null);
    setLoading(false);
  };

  const loginMethods = [
    {
      key: 'email-password-login',
      enabled: config.LOGIN_METHODS.includes(LOGIN_METHODS.EMAIL_PASSWORD),
      content: mfaLoginIdentifier ? (
        <Multifactor loginIdentifier={mfaLoginIdentifier} handleError={handleMFAError} />
      ) : (
        <LoginForm
          initialEmail={getQueryParam(location.search, 'email')}
          submitForm={authEmailLogin}
          sso={sso}
          loading={loading}
        />
      )
    },
    {
      key: 'sso-link',
      enabled: config.LOGIN_METHODS.includes(LOGIN_METHODS.SSO),
      content: (
        <div className='login-link'>
          <hr />
          <Typography variant='h4'>SSO Login</Typography>
          <a className='ui button primary left floated' href={ssoUrl} data-testid='sso-link'>
            Login with SSO
          </a>
          <br />
        </div>
      )
    }
  ];

  return (
    <AuthenticationRoot>
      <div className='pp-login'>
        {messageState === MessageState.InvalidCredential && (
          <Alert severity='error'>
            <AlertTitle>Incorrect email address and/or password.</AlertTitle>
          </Alert>
        )}
        {messageState === MessageState.SSORequired && (
          <Alert severity='error'>
            <AlertTitle>Your organization uses Single Sign-On. Click below to redirect to your login page.</AlertTitle>
            <Button
              disabled={loading}
              onClick={() => {
                window.location.href = ssoUrl;
              }}
            >
              Log in with SSO
            </Button>
          </Alert>
        )}
        {messageState === MessageState.VerificationRequired && (
          <Alert severity='error'>
            <AlertTitle>Verification is required. Check your email for a verification link.</AlertTitle>
            <Button disabled={loading} onClick={resendVerification}>
              Resend Verification Request
            </Button>
          </Alert>
        )}
        {messageState === MessageState.VerificationRequestSuccess && (
          <Alert severity='success'>
            <AlertTitle>Check your inbox for the verification link.</AlertTitle>
          </Alert>
        )}
        {messageState === MessageState.VerificationSuccessful && (
          <Alert severity='success'>
            <AlertTitle>Your email has been verified. You may log in.</AlertTitle>
          </Alert>
        )}
        {messageState === MessageState.CommunityInvite && (
          <Alert severity='success'>
            <AlertTitle>
              Thanks for signing up, we will let you know when the SnapAttack Community Edition becomes available.
            </AlertTitle>
          </Alert>
        )}
        {messageState === MessageState.AdminApprovalRequired && (
          <Alert severity='error'>
            <AlertTitle>Your account registration is still pending admin approval.</AlertTitle>
          </Alert>
        )}
        {messageState === MessageState.Lockout && (
          <Alert severity='error'>
            <AlertTitle>
              Maximum allowable login attempts exceeded. Your account has been temporarily locked.
            </AlertTitle>
          </Alert>
        )}
        {messageState === MessageState.UnknownError && (
          <Alert severity='error'>
            <AlertTitle>Something went wrong. Try again?</AlertTitle>
          </Alert>
        )}
        <Typography variant='h2' className='login-title'>
          {mfaLoginIdentifier ? 'Multi-Factor Authentication' : 'Log in'}
        </Typography>
        {loginMethods.map(method => {
          if (!method.enabled) return null;
          return <React.Fragment key={method.key}>{method.content}</React.Fragment>;
        })}
      </div>
      <LoginRegisterPanel />
      <CopyrightBadge />
    </AuthenticationRoot>
  );
}
