import React from 'react';

import { RouteComponentProps } from 'react-router-dom';

import useTitle from 'hooks/useTitle';

import { EXPORT_FORMAT } from 'module/Dashboard/core/Dashboard.util';
import { CoverPage } from 'module/Dashboard/core/DashboardCoverPage';
import NotFound from 'module/Util/Fallback/NotFound';

import { ArtifactType, Guid } from 'types/common';

import { formatCustomNow } from 'utilities/TimeUtils';

import AssessmentResultsSection from './AssessmentResultsSection';
import CampaignRecommendationSection from './CampaignRecommendationSection';
import { CampaignReportContainer, CampaignReportPrintContainer } from './CampaignReport.style';
import CampaignReportHeader from './CampaignReportHeader';
import DeployedDetectionsSection from './DeployedDetectionsSection';
import DetailedResultsSection from './DetailedResultsSection';
import MitreTacticCoverageSection from './MitreTacticCoverageSection';
import TestSummarySection from './TestSummarySection';
import useCampaign from './useCampaign';

export const CAMPAIGN_REPORT_ID = 'AttackPlanReport';
export enum BASReportType {
  ExecutiveSummary = 'Executive Summary',
  DetailedReport = 'Detailed Report'
}

export default function CampaignReport(props: RouteComponentProps<{ guid: Guid }>): JSX.Element {
  const campaignGuid = props.match.params.guid;

  const { campaign, refresh, isLoading, error: campaignError } = useCampaign(campaignGuid);

  const [exportingReportType, setExportingReportType] = React.useState<BASReportType>(null);
  const title = campaign.name || 'Attack Simulation Result';
  useTitle(`${title} | SnapAttack`);

  const pdfExportRef = React.useRef<HTMLDivElement>(null);
  const todaysDate = formatCustomNow(EXPORT_FORMAT);
  const reportTitle = `${campaign.name} ${exportingReportType}`;

  if (campaignError) return <NotFound artifact={ArtifactType.AttackPlan} error={campaignError} />;

  return (
    <CampaignReportPrintContainer id={CAMPAIGN_REPORT_ID}>
      <div ref={pdfExportRef}>
        <CoverPage title={reportTitle} details={todaysDate} />
        <CampaignReportContainer
          meta={
            <CampaignReportHeader
              campaign={campaign}
              isLoading={isLoading}
              exportingReportType={exportingReportType}
              setExportingReportType={setExportingReportType}
              pdfExportProps={{ ref: pdfExportRef }}
              refresh={refresh}
            />
          }
          type={ArtifactType.AttackPlan}
        >
          <AssessmentResultsSection campaign={campaign} isLoading={isLoading} />
          <DeployedDetectionsSection campaign={campaign} isLoading={isLoading} />
          <MitreTacticCoverageSection campaign={campaign} isLoading={isLoading} />
          <DetailedResultsSection campaignGuid={campaignGuid} isLoading={isLoading} refreshCampaign={refresh} />
          <CampaignRecommendationSection campaignGuid={campaignGuid} isLoading={isLoading} />
          <TestSummarySection campaignGuid={campaignGuid} isLoading={isLoading} reportType={exportingReportType} />
        </CampaignReportContainer>
      </div>
    </CampaignReportPrintContainer>
  );
}
