import React from 'react';

import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { DataGridProps, GridColDef, useGridApiRef, useKeepGroupedColumnsHidden } from 'snap-ui/DataGrid';
import Typography from 'snap-ui/Typography';

import { useIDEDetectionValue } from 'module/IDE';
import withFunctionalPermission from 'module/Util/withFunctionalPermission';

import { isDefaultFPRDetection } from 'services/analyticService';

import { SectionForm, SpecialSigmaModifier } from 'types/analytic';
import { FunctionalPermission } from 'types/auth';
import { BooleanString } from 'types/common';

import { EventLogsTable, TableContainer } from '../AnalyticTuning.style';
import useTuningContext from '../AnalyticTuningProvider';
import {
  AnalyticTuningGridFooter,
  AnalyticTuningGridFooterProps,
  AnalyticTuningGridToolbar,
  CustomGridTreeDataGroupingCell
} from './AnalyticTuningGrid.widgets';

type AnalyticTuningGridProps = {
  fields: string[];
  hits: Record<string, string>[];
  totalHits: number;
};

const AnalyticTuningGrid = withFunctionalPermission(function AnalyticTuningGrid({
  fields,
  hits,
  totalHits
}: AnalyticTuningGridProps) {
  const { fieldType } = useTuningContext();
  const apiRef = useGridApiRef();
  const { addSection, updateSection, value: customizationValue } = useIDEDetectionValue('customization');

  // hit log fields come back with their type prefix; strip this out
  const mappedHits = React.useMemo(
    () =>
      hits.map(hit => {
        return Object.entries(hit).reduce((accum, [key, value]) => {
          return { ...accum, [key.replace(`${fieldType}.`, '')]: value };
        }, {});
      }),
    [fieldType, hits]
  );

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: fields.slice(0, 2)
      }
    }
  });

  const columns: GridColDef[] = fields.map(field => ({
    field,
    headerName: field,
    flex: 1,
    groupingValueGetter: ({ value }) => {
      if (isNil(value)) return '(empty)';
      return value;
    }
  }));

  function excludeGroup(pairs: { field: string; value: string | string[] }[]) {
    if (isEmpty(pairs)) return;

    const update: Partial<SectionForm> = {
      rules: pairs.map(({ field, value }) => ({
        all: BooleanString.False,
        field,
        modifier: SpecialSigmaModifier.Equal,
        values: Array.isArray(value) ? value : [value]
      }))
    };

    if (isDefaultFPRDetection(customizationValue)) {
      updateSection(0, update);
    } else {
      addSection(update);
    }
  }

  const groupingColDef: DataGridProps['groupingColDef'] = params => {
    return {
      headerName: params.fields[0],
      flex: 1,
      minWidth: 300,
      renderCell: renderCellParams => <CustomGridTreeDataGroupingCell {...renderCellParams} />,
      onExclude: excludeGroup
    };
  };

  return (
    <TableContainer className='span-columns'>
      <Typography variant='h3'>Tuning Grid</Typography>
      <EventLogsTable
        apiRef={apiRef}
        density='compact'
        columns={columns}
        rows={mappedHits}
        rowGroupingColumnMode='multiple'
        getRowId={() => Math.ceil(Math.random() * 100_000)}
        slots={{
          toolbar: AnalyticTuningGridToolbar,
          footer: AnalyticTuningGridFooter
        }}
        slotProps={{
          toolbar: { fields },
          footer: { totalHits } as AnalyticTuningGridFooterProps
        }}
        initialState={initialState}
        groupingColDef={groupingColDef}
      />
    </TableContainer>
  );
},
FunctionalPermission.Tuning);

export default AnalyticTuningGrid;
