import React from 'react';

import zod from 'zod';

import { Autocomplete, Option } from 'snap-ui/Autocomplete';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { useAuth } from 'provider';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';
import { TargetIndustries, TargetIndustryDetails } from 'types/mati';

import { AutocompleteOptionContent, FilterControl } from '../GlobalFilter.style';
import { FilterConfig } from '../GlobalFilter.type';

type TargetIndustriesKeys = {
  industry: string;
};

type TargetIndustriesFilterProps = {
  onChange(values: Partial<TargetIndustriesKeys>): void;
  values: TargetIndustriesKeys;
};

const options: Option[] = TargetIndustries.sort((a, b) => a.localeCompare(b)).map(industry => ({
  label: industry,
  value: industry,
  content: (
    <AutocompleteOptionContent>
      <Icon icon={TargetIndustryDetails[industry]?.icon} /> {industry}
    </AutocompleteOptionContent>
  )
}));

function TargetIndustryFilter({ onChange, values }: TargetIndustriesFilterProps): React.ReactElement {
  const { isSubscriber } = useAuth();
  const handleValueChange = (industry: string) => {
    const payload = { industry };
    onChange(payload);
  };

  return (
    <FilterControl className='TargetIndustryFilter' disabled={!isSubscriber}>
      <FormLabel className='title-tooltip'>
        By Target Industry
        <Tooltip
          title={
            !isSubscriber
              ? 'This filter is only available to subscribers'
              : 'Filter by actors or software targeting specific industries as tracked by Mandiant'
          }
          placement='right'
          arrow
          wrap
        >
          <Icon.Info />
        </Tooltip>
      </FormLabel>
      <Autocomplete
        name='actor_target_industry_dropdown'
        options={options}
        value={values.industry}
        onChange={handleValueChange}
        disableUserAdditions
        disabled={!isSubscriber}
      />
    </FilterControl>
  );
}

/*
 * Future TODO - we could possibly handle logic operators
 * OR - "$[*] ? (@.name == \"Legal & Professional Services\" || @.name == \"Education\")"
 * AND, NOT ??
 */
function toQuery(value: TargetIndustriesKeys) {
  if (!value.industry) return;
  return {
    field: 'industries',
    op: Ops.json_path_exists,
    value: '$[*] ? (@.name == "' + value.industry + '")'
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('industries'),
    op: zod.literal(Ops.json_path_exists),
    value: zod.string()
  })
  .transform(query => ({
    // This extracts the industry name inside of quotes
    industry: query.value.match(/(?<=")(.*?)(?=")/)[0] || null
  }));

const TargetIndustryFilterConfig: FilterConfig<TargetIndustriesKeys> = {
  defaults: { default: () => ({ industry: null }) },
  supportedTopics: [ArtifactType.Collection],
  component: TargetIndustryFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default TargetIndustryFilterConfig;
