import React from 'react';

import { PaperProps } from '@mui/material';
import { Form, FormikContextType, useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import Button, { ButtonProps } from 'snap-ui/Button';

import Formik, { FormikProps } from 'module/Form/Formik';

import { StrictReactNode } from 'types/core';

import ConfirmDialog from './ConfirmDialog';
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from './_MuiDialog';

type InnerProps<V> = {
  cancelLabel?: string;
  children: StrictReactNode | ((formik: FormikContextType<V>) => JSX.Element);
  DialogProps: DialogProps;
  SubmitProps: ButtonProps;
  confirmMessage?: StrictReactNode;
  SecondaryActionProps?: Omit<ButtonProps, 'onClick'> & { onClick?(values: V): void; validate?: boolean };
  onChange?(values: V): void;
  title: string | JSX.Element;
  subtitle?: string;
  values: V;
};

type Props<V> = Omit<InnerProps<V>, 'values'> & {
  className?: string;
  FormikConfig: FormikProps<V>;
};

export default function FormDialog<V>({ className, FormikConfig, ...others }: Props<V>): JSX.Element {
  return (
    <Formik {...FormikConfig}>
      {({ values }) => (
        <Dialog PaperProps={{ component: Form } as unknown as PaperProps} className={className} {...others.DialogProps}>
          <Inner {...others} values={values} />
        </Dialog>
      )}
    </Formik>
  );
}

function Inner<V>({
  DialogProps,
  SubmitProps,
  confirmMessage,
  SecondaryActionProps,
  onChange,
  cancelLabel,
  values,
  title,
  subtitle,
  children
}: InnerProps<V>): JSX.Element {
  React.useEffect(() => {
    if (onChange) onChange(values);
  }, [onChange, values]);
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);
  const formik = useFormikContext<V>();

  const handleConfirmOpen = async () => {
    const result = await formik.validateForm();
    if (isEmpty(result)) setIsConfirmOpen(true);
    else formik.submitForm();
  };

  async function handleSecondaryClick() {
    let errors;
    if (SecondaryActionProps.validate) {
      errors = await formik.validateForm();
    }
    if (isEmpty(errors)) SecondaryActionProps.onClick?.(formik.values);
  }

  return (
    <>
      <DialogTitle onClose={DialogProps.onClose} subtitle={subtitle}>
        {title}
      </DialogTitle>
      <DialogContent>{typeof children === 'function' ? children(formik) : children}</DialogContent>
      <DialogActions>
        {DialogProps.onClose && (
          <span>
            <Button variant='outlined' onClick={DialogProps.onClose}>
              {cancelLabel || 'Cancel'}
            </Button>
          </span>
        )}
        {SecondaryActionProps && (
          <span>
            <Button
              variant='outlined'
              onClick={handleSecondaryClick}
              {...omit(SecondaryActionProps, 'onClick', 'validate')}
            />
          </span>
        )}
        <span>
          {confirmMessage ? (
            <Button color='primary' {...SubmitProps} type='button' onClick={handleConfirmOpen} />
          ) : (
            <Button color='primary' type='submit' {...SubmitProps} />
          )}
        </span>
        <ConfirmDialog
          ConfirmProps={{
            onClick: () => {
              setIsConfirmOpen(false);
              formik.handleSubmit();
            },
            children: 'Yes'
          }}
          DialogProps={{ maxWidth: 'sm', open: isConfirmOpen, onClose: () => setIsConfirmOpen(false) }}
          title='Confirmation'
        >
          {confirmMessage}
        </ConfirmDialog>
      </DialogActions>
    </>
  );
}
