import { snapattack } from 'apis/snapattack';

import { Guid } from 'types/common';

import { ThreatProfilePayload, ThreatProfileResponse } from './ThreatProfileWizard.type';

const prefix = '/cyberai/profile';

export async function getProfile(orgGuid: Guid): Promise<ThreatProfileResponse> {
  return snapattack.get(`${prefix}/${orgGuid}/`).then(r => r.data);
}

export async function postProfile(
  orgGuid: Guid,
  payload: Partial<ThreatProfilePayload>
): Promise<ThreatProfileResponse> {
  return snapattack.post(`${prefix}/${orgGuid}/`, payload).then(r => r.data);
}

export async function putProfileBuild(orgGuid: Guid): Promise<void> {
  return snapattack.put(`${prefix}/${orgGuid}/build/`);
}
