import React from 'react';

import Button from 'snap-ui/Button';
import Popper from 'snap-ui/Popper';
import { ClickAwayListener, styled } from 'snap-ui/util';

import { StrictReactNode } from 'types/core';

import { NOOP } from 'utilities/FunctionUtils';

const PopperContent = styled('div')`
  background-color: ${p => p.theme.palette.background.default};
  border: 1px solid ${p => p.theme.palette.error.main};
  padding: ${p => p.theme.spacing(3)};
`;

export default function ErrorPopper({ children, id }: { children: StrictReactNode; id: string }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <ClickAwayListener onClickAway={() => (open ? setAnchorEl(null) : NOOP)}>
      <div>
        <Button variant='outlined' color='error' onClick={handleClick}>
          Show
        </Button>
        <Popper id={id} open={open} anchorEl={anchorEl} placement='bottom-end'>
          <PopperContent>{children}</PopperContent>
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
