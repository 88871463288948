import React from 'react';

import Icon from 'snap-ui/Icon';

import { CollectionDiscriminator } from 'module/Collection/Collection.type';
import { Discriminator, SoftwareType } from 'module/Tag';

import { ArtifactType } from 'types/common';

export function getArtifactIcon(
  type: ArtifactType | CollectionDiscriminator | Discriminator | SoftwareType,
  colored?: boolean
) {
  switch (type) {
    case ArtifactType.Intel:
      return <Icon.Intel />;
    case ArtifactType.Indicator:
      return <Icon.IOC />;
    case ArtifactType.Session:
      return <Icon.Session />;
    case ArtifactType.Analytic:
      return <Icon.Analytic />;
    case ArtifactType.AttackScript:
      return <Icon.AttackScript />;
    case ArtifactType.Collection:
      return <Icon.Collection />;
    case ArtifactType.AttackPlan:
      return <Icon.AttackPlan />;
    case ArtifactType.Hunt:
      return <Icon.Hunt />;
    case ArtifactType.Landing:
      return <Icon.Landing />;
    case Discriminator.Action:
      return colored ? <Icon.Nist color='blue' /> : <Icon.Nist />;
    case Discriminator.Actor:
      return colored ? <Icon.Actor color='yellow' /> : <Icon.Actor />;
    case Discriminator.Attack:
      return colored ? <Icon.AttackColored className='MuiChip-icon' /> : <Icon.Attack />;
    case Discriminator.DataSource:
      return colored ? <Icon.DataSource color='cyan' /> : <Icon.DataSource />;
    case Discriminator.Software:
      return colored ? <Icon.Software color='white' /> : <Icon.Software />;
    case SoftwareType.Malware:
      return colored ? <Icon.Malware color='orange' /> : <Icon.Malware />;
    case SoftwareType.Tool:
      return colored ? <Icon.Tool color='white' /> : <Icon.Tool />;
    case Discriminator.Vulnerability:
      return colored ? <Icon.Vulnerability color='mint' /> : <Icon.Vulnerability />;
    default:
      return null;
  }
}
