import React from 'react';

import { faChevronCircleLeft } from '@fortawesome/pro-regular-svg-icons';
import { faBars, faFilter, faGears } from '@fortawesome/pro-solid-svg-icons';

import { IconButton } from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import ToggleButton, { ToggleButtonGroup } from 'snap-ui/ToggleButton';
import Tooltip from 'snap-ui/Tooltip';

import { usePage } from 'hooks/useQueryString';

import FilterMode from 'module/Filter/FilterMode';
import { FilterControl, FilterLayout, useFilterRegistry } from 'module/GlobalFilter';
import ListItemButtonIcon from 'module/Scaffold/core/ListItemButtonIcon';
import ToolbarItemLogo from 'module/Scaffold/core/ToolbarItemLogo';
import Search from 'module/Widgets/Search';
import SortBy from 'module/Widgets/SortBy';

import { useAuth } from 'provider';
import { useSidebar } from 'provider';
import { Drawer, DrawerHeader, ListSelector } from 'provider/SidebarProvider/Sidebar.style';
import { SidebarOptions, SidebarState } from 'provider/SidebarProvider/Sidebar.type';

import { ArtifactType } from 'types/common';
import { StrictReactNode } from 'types/core';

import { List } from './Scaffold.style';
import { Stash, Stashed, StashListType } from './Scaffold.type';
import useMatrixDisplayStash from './useMatrixDisplayStash';
import useSettingsStash from './useSettingsStash';

function SidebarContent({ stash, state }: { stash: Stash; state: SidebarState }): JSX.Element {
  const [listType, setListType] = React.useState<StashListType>(StashListType.stash);

  React.useEffect(() => {
    if (!stash.options || stash.options.length === 0) setListType(StashListType.stash);
  }, [stash.options]);

  const handleChange = (_event: React.MouseEvent<HTMLElement>, nextView: StashListType) => {
    setListType(nextView);
  };

  return (
    <>
      <div className='sticky'>
        <DrawerHeader>
          <ToolbarItemLogo />
        </DrawerHeader>
        <ListSelector>
          <div>
            {stash.items.length > 0 && stash.options?.length > 0 && (
              <ToggleButtonGroup value={listType} exclusive onChange={handleChange}>
                <Tooltip title='Show filter options' arrow placement='top'>
                  <ToggleButton value={StashListType.stash}>
                    <Icon icon={stash.icon} />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title='Show other options' arrow placement='top'>
                  <ToggleButton value={StashListType.option}>
                    <Icon icon={faGears} />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            )}
          </div>
          <IconButton onClick={() => state.setOpen(false)}>
            <Icon icon={faChevronCircleLeft} />
          </IconButton>
        </ListSelector>
      </div>
      <List>
        {listType === StashListType.stash &&
          stash.items
            .filter(
              ({ closedContent, openedContent }) => (closedContent && !state.open) || (openedContent && state.open)
            )
            .map(({ id, closedContent, openedContent }) => (
              <React.Fragment key={id}>{state.open ? openedContent : closedContent}</React.Fragment>
            ))}
        {stash.options &&
          listType === StashListType.option &&
          stash.options
            .filter(
              ({ closedContent, openedContent }) => (closedContent && !state.open) || (openedContent && state.open)
            )
            .map(({ id, closedContent, openedContent }) => (
              <React.Fragment key={id}>{state.open ? openedContent : closedContent}</React.Fragment>
            ))}
      </List>
    </>
  );
}

type SidebarProps = {
  head?: StrictReactNode;
  body: StrictReactNode;
  state: SidebarState;
};

function Sidebar({ head, body, state }: SidebarProps): JSX.Element {
  return (
    <Drawer disablePortal open={state.open} variant='permanent'>
      {!state.open ? (
        <>
          <DrawerHeader />
          <ListItemButtonIcon icon={faFilter} onClick={() => state.setOpen(true)} />
        </>
      ) : (
        <>
          <div className='sticky'>
            <DrawerHeader>
              <ToolbarItemLogo />
            </DrawerHeader>
            <ListSelector>
              <div>{head}</div>
              <IconButton data-testid='close-sidebar-button' onClick={() => state.setOpen(false)}>
                <Icon icon={faChevronCircleLeft} />
              </IconButton>
            </ListSelector>
          </div>
          {body}
        </>
      )}
    </Drawer>
  );
}

function FilterSidebar({
  defaultTopic,
  disableSort,
  state
}: {
  defaultTopic: ArtifactType;
  disableSort?: boolean;
  state: SidebarState;
}): JSX.Element {
  const { resetPage } = usePage(false);
  const registry = useFilterRegistry(defaultTopic, resetPage, false);
  const { isSubscriber } = useAuth();
  // We do not have a functional permission for filtering collections, and since they are visible to the
  // entire organization we will disable them for community users.  It's also broken :'(
  const canSaveFilters = isSubscriber ? true : false;
  return (
    <Sidebar
      body={
        <FilterLayout>
          <FilterControl>
            <FilterMode
              appliedFilterCount={registry.appliedCount}
              onClear={registry.reset}
              enableCollection={canSaveFilters}
            />
          </FilterControl>
          <Search />
          {!disableSort && <SortBy />}
          {state.open && registry.render()}
        </FilterLayout>
      }
      state={state}
    />
  );
}

function MatrixSidebar({ displayOptions, state }: { displayOptions: Stashed[]; state: SidebarState }): JSX.Element {
  const registry = useFilterRegistry(ArtifactType.Analytic, undefined, false);
  const [displayMode, setDisplayMode] = React.useState<'filter' | 'options'>('filter');
  const handleChangeDisplayMode = (_event: React.MouseEvent<HTMLElement>, mode: 'filter' | 'options') => {
    setDisplayMode(mode);
  };

  return (
    <Sidebar
      head={
        <ToggleButtonGroup value={displayMode} exclusive onChange={handleChangeDisplayMode}>
          <Tooltip title='Show filter options' arrow placement='top'>
            <ToggleButton value={'filter'}>
              <Icon icon={faFilter} />
            </ToggleButton>
          </Tooltip>
          <Tooltip title='Show display options' arrow placement='top'>
            <ToggleButton value={'options'}>
              <Icon icon={faGears} />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
      }
      body={
        <FilterLayout>
          {displayMode === 'options' ? (
            <List>
              {displayOptions.map(({ id, closedContent, openedContent }) => (
                <React.Fragment key={id}>{state.open ? openedContent : closedContent}</React.Fragment>
              ))}
            </List>
          ) : (
            <>
              <FilterControl>
                <FilterMode
                  appliedFilterCount={registry.appliedCount}
                  onClear={registry.reset}
                  enableCollection={false}
                />
              </FilterControl>
              {state.open &&
                registry.renderByContainingSupportedTopics([ArtifactType.Analytic, ArtifactType.AttackTag])}
            </>
          )}
        </FilterLayout>
      }
      state={state}
    />
  );
}

export function useStashSidebar(_stash: Stash, defaultOpen = true): SidebarState {
  const stash = React.useMemo(
    () => ({
      icon: _stash.icon,
      items: _stash.items,
      options: _stash.options
    }),
    [_stash.icon, _stash.items, _stash.options]
  );

  const content = React.useCallback((state: SidebarState) => <SidebarContent stash={stash} state={state} />, [stash]);

  const [sidebarOptions, setSidebarOptions] = React.useState<Partial<SidebarOptions>>({
    content,
    icon: stash.icon
  });

  React.useEffect(() => {
    setSidebarOptions({ content, icon: stash.icon });
  }, [content, stash.icon]);
  const sidebarState = useSidebar(defaultOpen, sidebarOptions);

  return sidebarState;
}

export function useFilterSidebar(defaultTopic, defaultOpen = true, disableSort = false) {
  const { appliedCount } = useFilterRegistry(defaultTopic);
  const content = React.useCallback(
    state => <FilterSidebar defaultTopic={defaultTopic} disableSort={disableSort} state={state} />,
    [defaultTopic, disableSort]
  );
  const options = React.useMemo(
    () => ({
      content,
      icon: faFilter
    }),
    [content]
  );
  // Force open the filters sidebar if we have filters applied
  if (appliedCount > 0) {
    defaultOpen = true;
  }
  useSidebar(defaultOpen, options);
}

export function useSettingsSidebar() {
  const stash = useSettingsStash();
  useStashSidebar({
    icon: faBars,
    items: stash
  });
}

export function useMatrixSidebar() {
  const displayOptions = useMatrixDisplayStash();
  const content = React.useCallback(
    state => <MatrixSidebar displayOptions={displayOptions} state={state} />,
    [displayOptions]
  );
  const options = React.useMemo(
    () => ({
      content,
      icon: faFilter
    }),
    [content]
  );
  useSidebar(false, options);
}
