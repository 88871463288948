import React from 'react';

import Tooltip from 'snap-ui/Tooltip';

import { COMMANDO_OS } from '../Session.const';
import { AttackerHostButtonContainer } from './HostButtonContainer.style';

type Props = {
  disabled: boolean;
  onChange(values: string[]): void;
  values: string[];
};

export default function Commando2Button({ disabled, values, onChange }: Props) {
  const isSelected = values.includes(COMMANDO_OS);

  const handleChange = () => {
    const newValue = isSelected ? [] : [COMMANDO_OS];
    onChange(newValue);
  };

  return (
    <Tooltip title={disabled ? 'There are no Commando machines available' : ''} wrap arrow placement='top'>
      <AttackerHostButtonContainer
        selected={isSelected}
        onChange={handleChange}
        value={COMMANDO_OS}
        disabled={disabled}
      >
        <div className='vm-logo'>
          <img src={process.env.PUBLIC_URL + '/images/commando-logo.png'} alt='Commando' />
        </div>
        Commando
      </AttackerHostButtonContainer>
    </Tooltip>
  );
}
