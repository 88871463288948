import React from 'react';

import { faCheck, faThumbsUp, faX } from '@fortawesome/pro-solid-svg-icons';
import { Form, Formik } from 'formik';

import Button from 'snap-ui/Button';
import Fade from 'snap-ui/Fade';
import Icon from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

import { Discriminator, TagAutocompleteFormik } from 'module/Tag';

import { Status, useAsync, useMountedRef } from 'storage';

const EditContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(2)};
  width: 100%;

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: ${p => p.theme.spacing(3)};
  }
`;

export type InlineTagForm = {
  kind: Discriminator;
  onClose(): void;
  onSubmit(payload: {
    actor_names?: string[];
    attack_names?: string[];
    software_names?: string[];
    vulnerability_names?: string[];
  }): Promise<unknown>;
  values: string[];
};

export function InlineTagForm(props: InlineTagForm) {
  const { kind, onClose, onSubmit, values } = props;
  const mounted = useMountedRef();
  const { task, status } = useAsync();
  const [successFade, setSuccessFade] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const propertyName = `${kind}_names`;
  const isActive = status === Status.pending;

  const handleSubmit = values => {
    setDisabled(true);
    setSuccessFade(false);
    task(onSubmit(values)).then(() => mounted.current && setSuccessFade(true));
  };

  return (
    <Formik
      initialValues={{
        [propertyName]: values || []
      }}
      onSubmit={handleSubmit}
    >
      <EditContainer>
        <TagAutocompleteFormik name={propertyName} multiple discriminator={kind} />
        <div className='actions'>
          <Fade
            in={successFade}
            timeout={1250}
            unmountOnExit
            onEntered={() => setSuccessFade(false)}
            onExited={onClose}
          >
            <div>
              <Icon icon={faThumbsUp} color='success' />
            </div>
          </Fade>
          {isActive && <Icon.SpinnerProgress />}
          <Button className='InlineEditorForm-cancel' onClick={onClose} aria-label='Cancel editing' variant='outlined'>
            <Icon icon={faX} />
          </Button>
          <Button
            type='submit'
            className='InlineEditorForm-submit'
            aria-label='Submit editing'
            variant='outlined'
            disabled={isActive || disabled}
          >
            <Icon icon={faCheck} />
          </Button>
        </div>
      </EditContainer>
    </Formik>
  );
}
