import { Option } from 'snap-ui/Autocomplete';

import { ArtifactType } from 'types/common';

import { BulkOperation } from './Metadata.type';

export const FILTER_PREFIX = '_m_';

export const APPLIED_TO_DEFAULT = [
  ArtifactType.Intel,
  ArtifactType.Session,
  ArtifactType.Analytic,
  ArtifactType.AttackScript,
  ArtifactType.Collection
] as const;

export const MULTI_VALUE_BULK_OPTIONS: Option[] = [
  {
    content: 'Keep as is',
    value: BulkOperation.Keep
  },
  {
    content: 'Append',
    value: BulkOperation.Add
  },
  {
    content: 'Overwrite',
    value: BulkOperation.Set
  },
  {
    content: 'Find and remove',
    value: BulkOperation.Remove
  },
  {
    content: 'Remove all',
    value: BulkOperation.UnSet
  }
];

export const SINGLE_VALUE_BULK_OPTIONS: Option[] = [
  {
    content: 'Keep as is',
    value: BulkOperation.Keep
  },
  {
    content: 'Overwrite',
    value: BulkOperation.Set
  },
  {
    content: 'Remove all',
    value: BulkOperation.UnSet
  }
];

export const MULTI_LINE_TEXT_BULK_OPTIONS: Option[] = [
  {
    content: 'Keep as is',
    value: BulkOperation.Keep
  },
  {
    content: 'Remove all',
    value: BulkOperation.UnSet
  }
];
