import React from 'react';

import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';

import { getCountryBy } from 'constants/countries';

import { Discriminator } from 'module/Tag';

import { ActorMotivationDetails } from 'types/mati';

import { formatDayTimestamp } from 'utilities/TimeUtils';

import { useLandingCatalog } from '../../LandingProvider';

export default function ActorDetail() {
  const { source, type } = useLandingCatalog();

  if (type !== Discriminator.Actor) return null;
  const isCountryPresent = source.source_regions?.length > 0 ? true : false;
  let country;
  if (isCountryPresent) {
    country = getCountryBy('alpha3', source?.source_regions?.[0]);
  }

  return (
    <>
      {source.last_seen && (
        <div className='LandingHeaderFooterContent'>
          <Typography variant='h6'>LAST SEEN</Typography>
          <p>{formatDayTimestamp(source.last_seen)}</p>
        </div>
      )}

      {source.first_seen && (
        <div className='LandingHeaderFooterContent'>
          <Typography variant='h6'>FIRST SEEN</Typography>
          <p>{formatDayTimestamp(source.first_seen)}</p>
        </div>
      )}

      {isCountryPresent && (
        <div className='LandingHeaderFooterContent'>
          <Typography variant='h6'>SOURCE COUNTRY</Typography>
          <div className='group-detail'>
            <div className='country-flag'>
              <span>
                {country?.flag} {country?.name}
              </span>
            </div>
          </div>
        </div>
      )}

      {source.motivations?.length > 0 && (
        <div className='LandingHeaderFooterContent'>
          <Typography variant='h6'>MOTIVATIONS</Typography>
          <div className='group-detail'>
            {source.motivations?.map(motive => {
              const motivationDetail = ActorMotivationDetails[motive];
              return (
                <div className='motivation-icon' key={motive}>
                  <Icon icon={motivationDetail?.icon} />
                  <span>{motive}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
