import React from 'react';

import { faDatabase } from '@fortawesome/pro-regular-svg-icons';

import { JsonSchema4 } from 'module/JsonView';
import { BurgerClicker } from 'module/Layout/Artifact.widgets';

import { useMetadataSchema } from 'provider/MetadataSchema';

import { BulkAction, BulkOperation } from './Metadata.type';
import { MetadataBulkModal } from './MetadataBulkModal';

type Provider = { children: React.ReactNode };
type Button = Partial<Omit<BurgerClicker, 'onClick'>>;
type Dialog = Omit<MetadataBulkModal, 'isOpen' | 'onClose' | 'children' | 'data' | 'resetData'>;

type BulkMetadata = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  data: Record<string, BulkAction>;
  setData: React.Dispatch<React.SetStateAction<Record<string, BulkAction>>>;
  resetData: () => void;
};

const MetadataBulkEditContext = React.createContext<BulkMetadata>(null);
MetadataBulkEditContext.displayName = 'MetadataBulkEditContext';

function generateBulkValue(properties: JsonSchema4['properties']): Record<string, BulkAction> {
  return Object.keys(properties).reduce(
    (accumulated, prop) => ({
      ...accumulated,
      [prop]: { operation: BulkOperation.Keep, value: undefined }
    }),
    {}
  );
}

export function MetadataBulkEditInterface({ children }: Provider): JSX.Element {
  const [isOpen, setIsOpen] = React.useState(false);
  const { schema } = useMetadataSchema();

  const [data, setData] = React.useState<Record<string, BulkAction>>();

  const resetData = React.useCallback(() => {
    setData(generateBulkValue(schema?.properties));
  }, [schema?.properties]);

  React.useEffect(() => {
    if (!data && schema) {
      resetData();
    }
  }, [data, resetData, schema]);

  return (
    <MetadataBulkEditContext.Provider value={{ isOpen, setIsOpen, data, setData, resetData }}>
      {children}
    </MetadataBulkEditContext.Provider>
  );
}

function useMetadataBulkEditContext(): BulkMetadata {
  const context = React.useContext(MetadataBulkEditContext);
  if (!context) {
    throw new Error(`BulkMetadata components must be used within a MetadataBulkEditContext`);
  }
  return context;
}

export function MetadataBulkEditButton(props: Button) {
  const { setIsOpen } = useMetadataBulkEditContext();

  return (
    <BurgerClicker
      title='Edit Metadata'
      aria-label='Edit Metadata'
      icon={faDatabase}
      onClick={() => setIsOpen(true)}
      {...props}
    />
  );
}

export function MetadataBulkEditDialog(props: Dialog) {
  const { isOpen, setIsOpen, data, resetData } = useMetadataBulkEditContext();
  return (
    <MetadataBulkModal isOpen={isOpen} onClose={() => setIsOpen(false)} data={data} resetData={resetData} {...props} />
  );
}

export type Editor = {
  operation: BulkOperation;
  value: unknown;
  set(operation: BulkOperation, value: unknown): void;
};

export function useBulkEditor(path: string): Editor {
  const { data, setData: _setData } = useMetadataBulkEditContext();

  const setData = React.useCallback(
    (operation: BulkOperation, value: unknown) => {
      _setData(data => ({
        ...data,
        [path]: {
          operation,
          value
        }
      }));
    },
    [_setData, path]
  );

  return {
    ...data[path],
    set: setData
  };
}
