import React from 'react';

import { TooltipProps as MuiTooltipProps } from '@mui/material';

import Icon from 'snap-ui/Icon/Icon';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { StrictReactNode } from 'types/core';

const Container = styled('div')`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(5)};
`;

const ChildContainer = styled('div')`
  flex-grow: 1;
`;

type WithTooltipProps = {
  children: React.ReactElement;
  title: StrictReactNode;
  placement?: MuiTooltipProps['placement'];
};

export default function WithTooltip({ children, title, placement }: WithTooltipProps): JSX.Element {
  return (
    <Container>
      <ChildContainer>{children}</ChildContainer>
      <Tooltip title={title} placement={placement || 'bottom'} arrow wrap>
        <Icon.Info />
      </Tooltip>
    </Container>
  );
}
