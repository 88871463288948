import React from 'react';

import useIsAuthenticated from 'hooks/useIsAuthenticated';

import { styled, useTheme } from './util';

type IFrameProps = {
  src: string;
};

const StyledIframe = styled('iframe')`
  height: 100%;
  width: 100%;
  border: none;
  color-scheme: normal; /* https://stackoverflow.com/a/70119968 */
`;

const IFrame = React.forwardRef<HTMLIFrameElement, IFrameProps>(function IFrame(
  { src }: IFrameProps,
  outerRef
): JSX.Element {
  useIsAuthenticated();
  const innerRef = React.useRef<HTMLIFrameElement>(null);
  React.useImperativeHandle(outerRef, () => innerRef.current, []);

  const { palette } = useTheme();

  React.useLayoutEffect(() => {
    if (innerRef.current) {
      const body = innerRef.current.contentDocument.querySelector('body');

      if (body) {
        body.style.backgroundColor = palette.background.default;
      }
    }
  }, [palette]);

  return <StyledIframe ref={innerRef} frameBorder='0' src={src} />;
});

export default IFrame;
