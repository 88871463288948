import React from 'react';

import { faBookmark as faBookmarkOutlined, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faBookmark as faBookmarkSolid } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import startCase from 'lodash/startCase';

import Icon from 'snap-ui/Icon/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { Ident } from 'types/common';

import { ThreatProfileTag, useThreatProfile } from '.';
import TagJustification from './TagJustification';
import { CardBadgeContainer } from './ThreatProfile.style';

type CardBadgeThreatProfileProps = {
  className?: string;
  tagIDs: Ident[];
};

export default function CardBadgeThreatProfile({ tagIDs }: CardBadgeThreatProfileProps) {
  const { isTagInProfile, tagInProfile } = useThreatProfile();

  const isInProfile = isTagInProfile(tagIDs);
  const tag: ThreatProfileTag = tagInProfile(tagIDs);

  return (
    <CardBadgeContainer>
      {isInProfile ? (
        <>
          <TagJustification components={tag.components} scoreLabel={tag.score_label}>
            <Icon icon={faTriangleExclamation} className={classnames('weight-icon', startCase(tag?.score_label))} />
          </TagJustification>
          <Tooltip arrow title='In Threat Profile' wrap>
            <Icon icon={faBookmarkSolid} color='primary' />
          </Tooltip>
        </>
      ) : (
        <Tooltip arrow title='Not in Threat Profile' wrap>
          <Icon icon={faBookmarkOutlined} color='primary' />
        </Tooltip>
      )}
    </CardBadgeContainer>
  );
}
