import React, { Dispatch, SetStateAction } from 'react';

import { faTrash } from '@fortawesome/pro-solid-svg-icons';

import { ActionIconButton } from 'snap-ui/Button';
import CircularProgress from 'snap-ui/CircularProgress';
import { ConfirmDialog } from 'snap-ui/Dialog';
import Tooltip from 'snap-ui/Tooltip';

import useWorkflow from 'aso/useWorkflow';

import { usePushSnack } from 'provider';

import { Status } from 'storage';

import { AnalyticDeploymentDetail, DeploymentIntegration } from 'types/analytic';
import { Guid, Ident } from 'types/common';
import { StrictReactNode } from 'types/core';

import { getIntegrationGuidForRemoval } from './util';

type Button = {
  deployment: AnalyticDeploymentDetail;
  integration?: DeploymentIntegration;
  onDelete?(deploymentId: Ident, integrationGuid: Guid): void;
  children?(props: { handleClickUndeploy(): void }): StrictReactNode;
};
type Dialog = { guid?: Guid; onDelete(deploymentId: Ident, integrationGuid: Guid): void };

interface UndeploymentInterface {
  showConfirm: boolean;
  setShowConfirm(open: boolean): void;
  deployment: AnalyticDeploymentDetail;
  setDeployment: Dispatch<SetStateAction<AnalyticDeploymentDetail>>;
  integration: DeploymentIntegration;
  setIntegration: Dispatch<SetStateAction<DeploymentIntegration>>;
}

const UndeploymentContext = React.createContext<UndeploymentInterface>(null);
UndeploymentContext.displayName = 'UndeploymentInterfaceContext';

export function UndeployInterface({ children }: { children: React.ReactNode }) {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [deployment, setDeployment] = React.useState<AnalyticDeploymentDetail>();
  const [integration, setIntegration] = React.useState<DeploymentIntegration>();

  return (
    <UndeploymentContext.Provider
      value={{ showConfirm, setShowConfirm, deployment, setDeployment, integration, setIntegration }}
    >
      {children}
    </UndeploymentContext.Provider>
  );
}

function useUndeploy(): UndeploymentInterface {
  const context = React.useContext(UndeploymentContext);
  if (!context) {
    throw new Error('useUndeploy components must be used within UndeploymentContext');
  }
  return context;
}

export function UndeployButton({ deployment, integration, children }: Button): JSX.Element {
  const { setShowConfirm, setDeployment, setIntegration } = useUndeploy();

  React.useEffect(() => {
    setDeployment(deployment);
    setIntegration(integration);
  }, [deployment, integration, setDeployment, setIntegration]);

  function handleClickUndeploy() {
    setShowConfirm(true);
  }
  return (
    <>
      {children ? (
        children({ handleClickUndeploy })
      ) : (
        <Tooltip arrow placement='top' title='Undeploy'>
          <ActionIconButton
            aria-label='Undeploy'
            className='undeploy icon'
            icon={faTrash}
            onClick={() => handleClickUndeploy()}
          />
        </Tooltip>
      )}
    </>
  );
}

export function UndeployDialog(props: Dialog) {
  const pushSnack = usePushSnack();
  const { guid } = props;
  const { showConfirm, setShowConfirm, deployment, integration } = useUndeploy();
  const undeployIntegrationGuid = getIntegrationGuidForRemoval(deployment, integration);
  const { removeDeployment, status } = useWorkflow(guid);
  const isLoading = status === Status.pending;

  function handleConfirmUndeploy() {
    handleDeleteSuccess();

    removeDeployment(deployment.id, undeployIntegrationGuid).then(handleDeleteSuccess, handleDeleteFailure);
  }

  function handleDeleteSuccess() {
    setShowConfirm(false);
    props.onDelete(deployment.id, undeployIntegrationGuid);
  }

  function handleDeleteFailure() {
    pushSnack('Something went wrong', 'error');
  }

  function handleCancel() {
    setShowConfirm(false);
  }

  return (
    <ConfirmDialog
      ConfirmProps={{
        children: isLoading ? <CircularProgress size={25} /> : 'Remove',
        disabled: isLoading,
        onClick: handleConfirmUndeploy
      }}
      DialogProps={{ open: showConfirm, onClose: handleCancel }}
      title='Remove Detection Deployment'
    >
      Are you sure you want to remove this deployment?
    </ConfirmDialog>
  );
}
