import { theme } from 'snap-ui/theme';
import { styled } from 'snap-ui/util';

const controlsPaddingTop = '15px';
const controlsPaddingRight = theme.spacing(3);
const controlsPaddingBottom = '10px';
const controlsPaddingLeft = theme.spacing(3);
const progressHeight = '4px';
const markerHeight = '20px';
const markerWidth = '14px';
const toolbarWidth = theme.spacing(8);

const playPauseAfter = {
  fontFamily: "'outline-icons', 'Icons'",
  fontSize: '6rem',
  textShadow: `0px 0px 5px ${theme.palette.common.black}`,
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '96px',
  width: '96px',
  marginLeft: '-48px',
  marginTop: '-48px',
  textAlign: 'center',
  lineHeight: '96px',
  verticalAlign: 'middle',
  opacity: '0'
};

const playPauseKeyframes = {
  '0%': {
    opacity: '0',
    transform: 'scale(0.5)'
  },

  '50%': {
    opacity: '0.5',
    transform: 'scale(2)'
  },

  '75%': {
    opacity: '0.25',
    transform: 'scale(2)'
  },

  '100%:': {
    opacity: '0',
    transform: 'scale(1.5)'
  }
};

export const VideoPlayerContainer = styled('div', { name: 'VideoPlayer' })(() => ({
  isolation: 'isolate',
  width: '100%',
  height: 'auto',
  position: 'relative',
  cursor: 'pointer',
  paddingRight: toolbarWidth,

  '@keyframes play': playPauseKeyframes,
  '@keyframes pause': playPauseKeyframes,

  '&.playing:after': {
    ...playPauseAfter,
    content: "'\\f04b'",
    animation: 'play 0.3s ease'
  },

  '&.paused:after': {
    ...playPauseAfter,
    content: "'\\f04c'",
    animation: 'pause 0.3s ease'
  }
}));

export const StyledVideo = styled('video')`
  max-width: 100%;
  max-height: calc(100vh - 120px);
  background-color: black;
  width: 100%;

  &.fullwidth {
    width: 100%;
  }
`;

export const VideoPlayerControls = styled('div', { name: 'VideoPlayer-controls' })`
  position: relative;
  display: block;
  background-color: ${p => p.theme.palette.secondary.main};
  padding-top: ${controlsPaddingTop};
  padding-right: ${controlsPaddingRight};
  padding-bottom: ${controlsPaddingBottom};
  padding-left: ${controlsPaddingLeft};
`;

export const VideoMarkerContainer = styled('div', { name: 'VideoPlayer-marker-container' })`
  position: absolute;
  top: 0;
  max-width: 100%;
  z-index: 3;
  left: ${p => p.theme.spacing(3)};
  right: ${p => p.theme.spacing(3)};
`;

export const VideoMarker = styled('div', { name: 'VideoPlayer-marker' })`
  height: ${markerHeight};
  width: ${markerWidth};
  margin-top: calc(${markerHeight} / -2);
  margin-left: calc(${markerWidth} / -2);
  position: absolute;
  top: calc(${controlsPaddingTop} + ${progressHeight} / 2);

  .icon {
    color: ${p => p.theme.palette.common.white};
  }

  &.blue .icon {
    color: ${p => p.theme.palette.info.main};
  }
  &.gold .icon {
    color: ${p => p.theme.palette.warning.main};
  }
  &.red .icon {
    color: ${p => p.theme.palette.error.main};
  }
`;
