import React from 'react';

import { faCloudArrowUp, faPlus } from '@fortawesome/pro-solid-svg-icons';

import { ButtonBaseProps, DivButton } from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';

import { Engage, Fingerprint, Widget } from 'lib/Engagement';

import { BurgerClicker } from 'module/Layout/Artifact.widgets';

import { useAuth } from 'provider';

import { checkTaskPermission } from 'services/authService';

import { FunctionalPermission } from 'types/auth';

import { BulkDetectionDeployment } from './DetectionDeployment';

type MenuButton = Partial<Omit<BurgerClicker, 'onClick'>> & { undeploy?: boolean };
type DivButton = Partial<Omit<ButtonBaseProps, 'onClick'>> & { count: number; undeploy?: boolean };
type Dialog = Omit<BulkDetectionDeployment, 'isOpen' | 'onClose' | 'unDeploy'>;

interface DetectionDeploymentInterface {
  permitted: boolean;
  isOpen: boolean;
  setIsOpen(open: boolean): void;
  undeploy: boolean;
  setUndeploy(undeploy: boolean): void;
}

const DetectionDeploymentContext = React.createContext<DetectionDeploymentInterface>(null);
DetectionDeploymentContext.displayName = 'DetectionDeploymentContext';

export function DetectionDeploymentInterface({ children }: { children: React.ReactNode }): JSX.Element {
  const { permission } = useAuth();
  const [isOpen, setIsOpen] = React.useState(false);
  const [undeploy, setUndeploy] = React.useState(false);
  const permitted = checkTaskPermission(permission, FunctionalPermission.DeployAnalytic);

  return (
    <DetectionDeploymentContext.Provider value={{ permitted, isOpen, setIsOpen, undeploy, setUndeploy }}>
      {children}
    </DetectionDeploymentContext.Provider>
  );
}

function useDetectionDeploymentInterface() {
  const context = React.useContext(DetectionDeploymentContext);
  if (!context) {
    throw new Error('useDetectionDeploymentInterface must be used within an DetectionDeploymentContext');
  }
  return context;
}

export function DetectionDeploymentMenuButton({ undeploy, ...props }: MenuButton) {
  const { permitted, setUndeploy, setIsOpen } = useDetectionDeploymentInterface();
  if (!permitted) return null;
  return (
    <BurgerClicker
      title='Deploy Detections'
      aria-label='Deploy Detections'
      icon={faCloudArrowUp}
      onClick={() => {
        Engage.track(Fingerprint.of(Widget.Modal).open().withQualifier('deploy detections'));
        setUndeploy(undeploy);
        setIsOpen(true);
      }}
      {...props}
    />
  );
}

export function DetectionDeploymentDivButton({ count, undeploy, ...props }: DivButton) {
  const { permitted, setUndeploy, setIsOpen } = useDetectionDeploymentInterface();

  return (
    <DivButton
      disabled={count === 0}
      onClick={() => {
        Engage.track(Fingerprint.of(Widget.LandingRecommender).open().withQualifier('deploy detections'));
        setUndeploy(undeploy);
        setIsOpen(true);
      }}
      {...props}
      TooltipProps={{ title: permitted ? undefined : 'You do not have permission to deploy detections' }}
    >
      <Icon icon={faPlus} /> Deploy Detections
    </DivButton>
  );
}

export function DetectionDeploymentDialog(props: Dialog) {
  const { permitted, undeploy, isOpen, setIsOpen } = useDetectionDeploymentInterface();
  if (!permitted) return null;
  return <BulkDetectionDeployment unDeploy={undeploy} isOpen={isOpen} onClose={() => setIsOpen(false)} {...props} />;
}
