import React from 'react';

import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';

import { AccordionDetails, AccordionSummary } from 'snap-ui/Accordion';
import Button from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import Paper from 'snap-ui/Paper';
import Table, { TablePlaceholder } from 'snap-ui/Table';

import useSearch from 'hooks/useSearch';
import useSort from 'hooks/useSort';

import May from 'module/May';
import TableToolbar from 'module/Widgets/TableToolbar';

import { useAuth, useIntegrationCatalog } from 'provider';

import { Status } from 'storage';

import { LanguageExtended } from 'types/analytic';
import { FunctionalPermission } from 'types/auth';
import { SortDirection } from 'types/filter';

import { useConfigControls } from './ConfigProvider';
import { OtherLanguagesAccordion } from './LanguageConfig.style';
import { languageListColumns, languageListRow } from './LanguageListRow';
import { useLanguageControls } from './LanguageProvider';

export default function LanguageList(): JSX.Element {
  const { permission: organizations } = useAuth();
  const {
    integrations: { all: integrations }
  } = useIntegrationCatalog();
  const configControls = useConfigControls();
  const languageControls = useLanguageControls();
  const isPending = status === Status.pending;

  const mappedLanguages = React.useMemo(() => {
    return languageControls.languages
      .filter(l => !isEmpty(l.organization))
      .map(lang => {
        const associated_integrations = integrations.filter(
          integration =>
            integration.deployment_targets.some(target => target.id === lang.id) ||
            integration.hunt_targets.some(target => target.id === lang.id) ||
            integration.search_targets.some(target => target.id === lang.id)
        );
        return {
          name: lang.name,
          // this field is only used for sorting; selected org is modded to come first by alpha
          _organizationName: lang.organization.id === languageControls.selectedOrgId ? ' ' : lang.organization.name,
          visible: !lang.hidden,
          integrations: associated_integrations,
          integration_count: associated_integrations.length,
          ...lang
        };
      });
  }, [integrations, languageControls.languages, languageControls.selectedOrgId]);

  const filterFn = React.useCallback((query: string, item: (typeof mappedLanguages)[number]) => {
    return item.name?.toLowerCase().includes(query?.toLowerCase());
  }, []);

  const { isLanguageHidden } = languageControls; // have to destructure to make eslint happy in the deps array
  const [aboveFold, belowFold] = React.useMemo(() => {
    const visibleToMeFilter = (language: LanguageExtended): boolean => !isLanguageHidden(language);
    return [mappedLanguages.filter(visibleToMeFilter), mappedLanguages.filter(l => !visibleToMeFilter(l))];
  }, [isLanguageHidden, mappedLanguages]);

  const { filteredData, setQuery } = useSearch(aboveFold, filterFn);
  const {
    createSortHandler,
    order,
    sortedData: aboveFoldLanguages
  } = useSort(filteredData, {
    direction: SortDirection.asc,
    field: '_organizationName'
  });
  const { filteredData: filteredBelowFoldData, setQuery: setBelowFoldQuery } = useSearch(belowFold, filterFn);
  const {
    createSortHandler: createBelowFoldSortHandler,
    order: belowFoldOrder,
    sortedData: belowFoldLanguages
  } = useSort(filteredBelowFoldData, {
    direction: SortDirection.asc,
    field: '_organizationName'
  });

  function row(language: (typeof mappedLanguages)[number]): React.ReactNodeArray {
    return languageListRow({
      integrations: language.integrations,
      organizations,
      language,
      languageControls,
      configControls
    });
  }

  return (
    <>
      <Paper>
        <TableToolbar onSearchChange={setQuery} placeholder='Filter languages by name'>
          <May I={FunctionalPermission.CreateCompilationTarget}>
            <Button variant='outlined' onClick={() => languageControls.openEditor()}>
              New Language
            </Button>
          </May>
        </TableToolbar>
        <Table
          columns={languageListColumns({ createSortHandler, order })}
          rows={isPending ? [] : aboveFoldLanguages.map(row)}
        />
        {isPending && <TablePlaceholder />}
      </Paper>
      {!isPending && (
        <OtherLanguagesAccordion className='other-languages'>
          <AccordionSummary expandIcon={<Icon icon={faCaretDown} />}>Other languages</AccordionSummary>
          <AccordionDetails>
            <TableToolbar onSearchChange={setBelowFoldQuery} placeholder='Filter languages by name'></TableToolbar>
            <Table
              columns={languageListColumns({ createSortHandler: createBelowFoldSortHandler, order: belowFoldOrder })}
              rows={belowFoldLanguages.map(row)}
            />
          </AccordionDetails>
        </OtherLanguagesAccordion>
      )}
    </>
  );
}
