import React from 'react';

import zod from 'zod';

import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';

import { addOrRemoveValue } from 'module/Filter/Filter.util';
import { FilterControl } from 'module/GlobalFilter';
import { IOCTypeCategory, iocTypeCategory } from 'module/IOC/IOC.type';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';

import { FilterConfig } from '../GlobalFilter.type';
import { fromQueryNoop } from '../GlobalFilter.util';

type IndicatorTypeKeys = {
  indicatorType: IOCTypeCategory[];
};

type IndicatorTypeFilterProps = {
  disabled?: boolean;
  onChange(values: Partial<IndicatorTypeKeys>): void;
  values: IndicatorTypeKeys;
};

function IndicatorTypeFilter({ onChange, values }: IndicatorTypeFilterProps): React.ReactElement {
  return (
    <FilterControl className='IndicatorTypeFilter'>
      <FormLabel>By Indicator Type</FormLabel>
      <FormGroup>
        {iocTypeCategory.map(category => (
          <FormControlLabel
            key={category}
            control={
              <Checkbox onChange={handleChange} value={category} checked={values.indicatorType.includes(category)} />
            }
            label={category}
          />
        ))}
      </FormGroup>
    </FilterControl>
  );

  function handleChange(e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    const v = e.currentTarget.value as IOCTypeCategory;
    const indicatorType = addOrRemoveValue(checked, values.indicatorType, v) as IOCTypeCategory[];
    onChange({ indicatorType });
  }
}

function toQuery(values: IndicatorTypeKeys) {
  if (!values.indicatorType?.length) return;
  return {
    field: 'ioc_type',
    op: Ops.in,
    value: values.indicatorType
  };
}

const IndicatorTypeFilterConfig: FilterConfig<IndicatorTypeKeys> = {
  defaults: { default: () => ({ indicatorType: [] }) },
  supportedTopics: [ArtifactType.Indicator],
  component: IndicatorTypeFilter,
  toQuery: { default: toQuery },
  fromQuery: {
    default: fromQueryNoop({
      field: zod.literal('ioc_type'),
      op: zod.nativeEnum(Ops),
      value: zod.array(zod.nativeEnum(IOCTypeCategory))
    })
  }
};
export default IndicatorTypeFilterConfig;
