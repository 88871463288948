import get from 'lodash/get';
import includes from 'lodash/includes';

import { APP_MODES, FEATURES, FEATURE_SETS, APP_DEPLOY } from './appModes';

declare let BASE_URL: string;
const SEARCH_BAR_SHOW = 10;
const SEARCH_BAR_QUERY = 10;

type EnvConfig = {
  VERSION: string;

  BASE_HOST_URL: string;
  SNAPATTACK_API_URL: string;
  SNAPATTACK_DATA_URL: string;
  SNAPATTACK_DOC_URL: string;
  SPLUNK_URL_BASE: string;
  SPLUNK_SESSION_DASHBOARD_NAME?: string;
  SPLUNK_HOST_LOG_DASHBOARD_NAME?: string;

  LOGIN_METHODS: string[];
  BACKEND_VERSION: string;

  SEARCH_BAR_SHOW?: number;
  SEARCH_BAR_QUERY?: number;
};

type ModeConfig = {
  FEATURES: FEATURES[];
};

type Config = EnvConfig & ModeConfig;
declare var default_config: EnvConfig;

function getApiConfig() {
  const base = BASE_URL || '';
  const splunkBase = `${base}/splunk/en-US/app`;
  return {
    BASE_HOST_URL: base,
    SNAPATTACK_API_URL: `${base}/api`,
    SNAPATTACK_DATA_URL: `${base}/data`,
    SNAPATTACK_DOC_URL: `${base}`,
    SPLUNK_URL_BASE: splunkBase,
    SPLUNK_SESSION_DASHBOARD_NAME: 'event_drilldown',
    SPLUNK_HOST_LOG_DASHBOARD_NAME: 'review_host_logs'
  };
}

let config: Config = {
  SEARCH_BAR_SHOW,
  SEARCH_BAR_QUERY,
  FEATURES: [],
  ...default_config,
  ...getApiConfig(),
  VERSION: process.env.REACT_APP_VERSION || 'local'
};

function setApplicationModeProperties(): void {
  const appMode = process.env.REACT_APP_MODE || APP_MODES.ENTERPRISE;
  const deploy = getDeploymentLocation();
  config = {
    ...config,
    FEATURES: [...get(FEATURE_SETS, appMode, []), ...get(FEATURE_SETS, deploy, [])]
  };

  if (process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'mock') {
    console.groupCollapsed('Configuration');
    console.info('For debug information, hold down ctrlKey + shiftKey and mouse into a Card or User Profile menu.');
    Object.entries(config).forEach(k => {
      console.info(k[0], k[1]);
    });
    console.groupEnd();
  }
}

function getDeploymentLocation(): string {
  const version = process.env.REACT_APP_VERSION || 'local';
  if (version === 'local') {
    return APP_DEPLOY.LOCAL;
  }
  if (includes(config.BASE_HOST_URL, 'dev')) {
    return APP_DEPLOY.DEV;
  } else if (includes(config.BASE_HOST_URL, 'test')) {
    return APP_DEPLOY.TEST;
  } else if (includes(config.BASE_HOST_URL, 'demo')) {
    return APP_DEPLOY.DEMO;
  }
  return APP_DEPLOY.PROD;
}

setApplicationModeProperties();

export { config as default };
