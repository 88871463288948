import React from 'react';

import classNames from 'classnames';
import { useField } from 'formik';

import { DataGrid, DataGridProps, GridRowSelectionModel } from 'snap-ui/DataGrid';
import FormControl, { FormHelperText } from 'snap-ui/FormControl';
import FormLabel from 'snap-ui/FormLabel';
import { styled } from 'snap-ui/util';

const StyledDataGrid = styled(DataGrid, { shouldForwardProp: p => p !== 'hasError' })<
  DataGridProps & { hasError: boolean }
>`
  box-shadow: 6;
  background-color: hsl(0, 0%, 7.1%);
  background-image: linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12));
  ${p => (p.hasError ? `border-color: hsl(0, 100%, 75%);` : '')}
`;

type Props = Omit<DataGridProps, 'selectionModel' | 'onSelectionModelChange'> & {
  className?: string;
  name: string;
  label?: string;
};

export default function DataGridFormik({ className, label, name, ...others }: Props) {
  const [, field, helpers] = useField<GridRowSelectionModel>(name);
  const hasError = !!(field.touched && field.error);

  const handleSelection = (selectionModel: GridRowSelectionModel) => {
    helpers.setValue(selectionModel, true);
    helpers.setTouched(true, false);
  };

  return (
    <>
      <FormControl component='fieldset' className={classNames('Form-field required', className)}>
        {label && <FormLabel component='legend'>{label}</FormLabel>}
        <StyledDataGrid
          hasError={hasError}
          rowSelectionModel={field.value}
          onRowSelectionModelChange={handleSelection}
          {...others}
        />
      </FormControl>
      {hasError && (
        <FormHelperText error sx={{ marginLeft: '14px' }}>
          {field.error}
        </FormHelperText>
      )}
    </>
  );
}
