import React from 'react';

import { CancelToken } from 'apis';

import { getPreviewLogs } from 'module/Detection';
import { Integration } from 'module/Integration/Integration.type';
import { getClientHitsByGroup, pollForGroupCompletion, postAsyncJobs, JobType } from 'module/Job';

import { usePushSnack } from 'provider';

import { useAsync, Status } from 'storage';

import { Language } from 'types/analytic';
import { Guid } from 'types/common';
import { LogRecord } from 'types/ide';

type TestLogsInterface = {
  cancelTest(): void;
  getLabTest(detectionGuid: Guid): void;
  testLogs: LogRecord[];
  getHuntTest(raw: string, integration: Integration, language: Language): void;
  testLogsStatus: Status;
};

export function useTestLogs(): TestLogsInterface {
  const pushSnack = usePushSnack();
  const pushNoLogsSnack = React.useCallback(
    () => pushSnack('No test logs returned', 'warning', 'center', 'bottom', 10000),
    [pushSnack]
  );

  const [huntGroupGuid, setHuntGroupGuid] = React.useState<Guid>();
  const { data, run, status, task } = useAsync<LogRecord[]>();
  const cancelTokenSourceRef = React.useRef(CancelToken.source());

  const cancelTest = React.useCallback(() => {
    cancelTokenSourceRef.current.cancel();
    const source = CancelToken.source();
    cancelTokenSourceRef.current = source;
  }, []);

  React.useEffect(() => {
    /* isFinished is mutable by design */
    const state = { isFinished: false };

    if (huntGroupGuid) {
      cancelTest();
      // this one fetches logs
      run(getResults());
      // this one tells us when the job has finished
      pollForGroupCompletion(huntGroupGuid, cancelTokenSourceRef.current.token).finally(() => {
        state.isFinished = true;
      });
      return () => cancelTest();
    }

    function getResults() {
      return getClientHitsByGroup(huntGroupGuid, cancelTokenSourceRef.current.token).then(r => {
        if (state.isFinished) {
          if (!r.preview?.length) pushNoLogsSnack();
          return r.preview;
        }
        return new Promise<LogRecord[]>((resolve, reject) => {
          setTimeout(() => {
            getResults().then(resolve).catch(reject);
          }, 5000);
        });
      });
    }
  }, [cancelTest, huntGroupGuid, pushNoLogsSnack, run]);

  const getHuntTest = React.useCallback(
    (raw: string, integration: Integration, language: Language) => {
      if (!integration?.hunt_targets.some(t => t.id === language.id)) alert('bad language selection');
      task(
        postAsyncJobs({
          type: JobType.Test,
          integrations: [integration.guid],
          organization_id: integration.organization_id,
          adhoc_queries: [{ language: language.backend_key, search: raw }]
        }).then(data => setHuntGroupGuid(data.guid))
      );
    },
    [task]
  );

  const getLabTest = React.useCallback(
    (detectionGuid: Guid) => {
      cancelTest();
      run(
        getPreviewLogs(detectionGuid, { cancelToken: cancelTokenSourceRef.current.token }).then(data => {
          if (!data?.preview.length) pushNoLogsSnack();
          return data?.preview;
        })
      );
    },
    [cancelTest, pushNoLogsSnack, run]
  );

  return {
    testLogs: data,
    getHuntTest,
    testLogsStatus: status,
    cancelTest,
    getLabTest
  };
}
