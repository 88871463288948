import React from 'react';

import lowerCase from 'lodash/lowerCase';

import { MultiProgress, MultiProgressBarProps } from 'snap-ui/LinearProgress';
import { styled } from 'snap-ui/util';

import { JobOutcome } from 'types/bas';

import { renderValueWithLabel } from 'utilities/TextUtils';

import { JobOutcomeColors } from '../CampaignReport/CampaignReport.style';

type CampaignOutcomesProps = {
  outcomes: Record<JobOutcome, number>;
};

const Progress = styled(MultiProgress)`
  min-width: 100px;
`;

export default function CampaignOutcomes({ outcomes }: CampaignOutcomesProps): JSX.Element {
  const total = Object.values(outcomes).reduce((sum, count) => sum + count, 0);

  const bars: MultiProgressBarProps[] = [
    {
      color: JobOutcomeColors[JobOutcome.Prevented],
      label: `${renderValueWithLabel(outcomes[JobOutcome.Prevented], 'attack')} ${lowerCase(JobOutcome.Prevented)}`,
      percent: Math.round((outcomes[JobOutcome.Prevented] / total) * 100) || 0
    },
    {
      color: JobOutcomeColors[JobOutcome.Detected],
      label: `${renderValueWithLabel(outcomes[JobOutcome.Detected], 'attack')} ${lowerCase(JobOutcome.Detected)}`,
      percent: Math.round((outcomes[JobOutcome.Detected] / total) * 100) || 0
    },
    {
      color: JobOutcomeColors[JobOutcome.Logged],
      label: `${renderValueWithLabel(outcomes[JobOutcome.Logged], 'attack')} ${lowerCase(JobOutcome.Logged)}`,
      percent: Math.round((outcomes[JobOutcome.Logged] / total) * 100) || 0
    },
    {
      color: JobOutcomeColors[JobOutcome.NoAction],
      label: `${renderValueWithLabel(outcomes[JobOutcome.NoAction], 'attack')} with ${lowerCase(JobOutcome.NoAction)}`,
      percent: Math.round((outcomes[JobOutcome.NoAction] / total) * 100) || 0
    },
    {
      color: JobOutcomeColors[JobOutcome.NotTested],
      label: `${renderValueWithLabel(outcomes[JobOutcome.NotTested], 'attack')} ${lowerCase(JobOutcome.NotTested)}`,
      percent: Math.round((outcomes[JobOutcome.NotTested] / total) * 100) || 0
    }
  ];
  return <Progress bars={bars} />;
}
