import React from 'react';

import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { AttackNode, Forest } from 'module/Matrix/Matrix.type';

import { JOB_OUTCOME_ORDER } from 'types/bas';
import { StrictReactNode } from 'types/core';

import { getNodeSpecificName } from './Matrix.util';

const Node = styled('div')`
  background-color: ${p => p.theme.palette.grey[200]};
  border-radius: var(--matrix-spacing);
  color: ${p => p.theme.palette.common.black};
  font-size: 0.8rem;
  line-height: 1;
  padding: var(--matrix-spacing);

  span {
    display: block;
  }
`;

export const Tactic = styled(Node, { name: 'MiniMatrix-Tactic' })`
  background-color: ${p => p.theme.palette.primary.main};
  color: ${p => p.theme.palette.primary.contrastText};

  font-weight: bold;
  height: 32px;
  text-align: center;

  span {
    margin: auto;
  }
`;

export const Technique = styled(Node, { name: 'MiniMatrix-Technique' })`
  margin-left: var(--matrix-spacing);
`;

export const SubTechnique = styled(Node, { name: 'MiniMatrix-SubTechnique' })`
  margin-left: calc(var(--matrix-spacing) * 2);
`;

const MiniMatrixContainer = styled('div', { name: 'MiniMatrix-Container' })`
  --matrix-spacing: 4px;

  display: flex;
  justify-content: center;
  gap: calc(var(--matrix-spacing) * 2);

  .matrix-column {
    display: flex;
    flex-direction: column;
    gap: calc(var(--matrix-spacing) * 2);
  }
`;

function getNodeWithBestOutcome(technique: AttackNode): AttackNode {
  const outcomes = [...JOB_OUTCOME_ORDER].reverse();
  let bestNode = technique;

  technique.attack_children?.forEach(subtechnique => {
    if (outcomes.indexOf(subtechnique.outcome) > outcomes.indexOf(bestNode.outcome)) {
      bestNode = subtechnique;
    }
  });

  return bestNode;
}

const TOOLTIP_PROP_BASE = {
  arrow: true,
  placement: 'top'
} as const;

export default function MiniMatrix({
  forest,
  getNodeStyle,
  getNodeTooltip,
  className
}: {
  forest: Forest;
  getNodeStyle: (node: AttackNode) =>
    | {
        backgroundColor: string;
        color: string;
      }
    | {
        background: string;
        color: string;
      }
    | {
        display?: string;
      };
  getNodeTooltip?: (node: AttackNode) => StrictReactNode;
  className?: string;
}): JSX.Element {
  const tooltipProps = (node: AttackNode) => ({
    ...TOOLTIP_PROP_BASE,
    title: getNodeTooltip ? getNodeTooltip(node) : null
  });

  return (
    <MiniMatrixContainer className={className}>
      {forest.map(node => {
        return (
          <React.Fragment key={node.id}>
            <div className='matrix-column' key={node.name}>
              <Tooltip {...tooltipProps(node)}>
                <Tactic className='Tactic' style={getNodeStyle(node)}>
                  <span>{node.name}</span>
                </Tactic>
              </Tooltip>
              {node.attack_children?.map(technique => (
                <React.Fragment key={technique.id}>
                  <Tooltip {...tooltipProps(technique)}>
                    <Technique
                      className='Technique'
                      key={technique.id}
                      style={getNodeStyle(getNodeWithBestOutcome(technique))}
                    >
                      <span>{getNodeSpecificName(technique)}</span>
                    </Technique>
                  </Tooltip>

                  {technique.attack_children?.map(subtechnique => (
                    <Tooltip {...tooltipProps(subtechnique)} key={subtechnique.id}>
                      <SubTechnique className='SubTechnique' style={getNodeStyle(subtechnique)}>
                        <span>{getNodeSpecificName(subtechnique)}</span>
                      </SubTechnique>
                    </Tooltip>
                  ))}
                </React.Fragment>
              ))}
            </div>
          </React.Fragment>
        );
      })}
    </MiniMatrixContainer>
  );
}
