import React from 'react';

import Guacamole from 'guacamole-common-js';

import { GuacClientState } from './SessionGuac.type';

export default function useInput(
  displayRef: React.MutableRefObject<HTMLDivElement>,
  textAreaRef: React.MutableRefObject<HTMLTextAreaElement>,
  client: Guacamole.Client,
  state: Guacamole.Client.State
) {
  React.useEffect(() => {
    if (client && state !== GuacClientState.Connected) {
      const mouse = new Guacamole.Mouse(displayRef.current);

      /*
       *  Documentation suggest we migrate to using mouse.on or mouse.onEach, but the
       *  typescript library didn't implement these correctly and throws an error that
       *  the function doesn't exist.
       *
       *  mouse.onEach(['mousedown', 'mouseup', 'mousemove'], (e: Guacamole.Mouse.Event) => {
       *    client.sendMouseState(e.state);
       *  });
       */

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore:next-line
      mouse.onmousedown = (mouseState: Guacamole.Mouse.State) => client.sendMouseState(mouseState);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore:next-line
      mouse.onmouseup = (mouseState: Guacamole.Mouse.State) => client.sendMouseState(mouseState);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore:next-line
      mouse.onmousemove = (mouseState: Guacamole.Mouse.State) => client.sendMouseState(mouseState);

      const keyboard = new Guacamole.Keyboard(textAreaRef.current);
      keyboard.onkeydown = (keysym: number) => client.sendKeyEvent(1, keysym);
      keyboard.onkeyup = (keysym: number) => client.sendKeyEvent(0, keysym);
    }
    // refs are mutable - don't add here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, state]);
}
