import React from 'react';

import { FeaturedQuery } from 'module/Dashboard/ContentDashboard/FeaturedPanel/Feature';
import { DetectionSummary } from 'module/Detection';

import {
  getAnalyticCount,
  getDetectionSummary,
  getFeaturedContent,
  getMitreAttackCoverage,
  getSessionCount
} from 'services/dashboardService';

import { Status, useAsync } from 'storage';

import { ArtifactType } from 'types/common';
import {
  AnalyticCount,
  Coverage,
  CoverageSummary,
  DetectionCoverage,
  MitreAttackCoverage,
  SessionCount
} from 'types/dashboard';

const CoverageShell: Coverage = {
  attacks: 0,
  sessions: 0,
  analytics: 0,
  intelligence: 0
};

const DetectionSummaryShell = {
  detectionSummary: {} as DetectionSummary
};

const SessionCountShell = {
  sessionCount: 0
};

const AnalyticCountShell = {
  analyticCount: 0
};

const MitreAttackCoverageShell = {
  technique: CoverageShell,
  subtechnique: CoverageShell
};

type CoverageInterface = CoverageSummary & {
  loadingDetectionSummary: boolean;
  loadingSessionCount: boolean;
  loadingAnalyticCount: boolean;
  loadingMitreAttackCoverage: boolean;
  loadingFeaturedAnalytics: boolean;
  loadingFeaturedSessions: boolean;
};

export default function useCoverage(): CoverageInterface {
  const {
    data: dataDetectionSummary,
    run: runDetectionSummary,
    status: statusDetectionSummary
  } = useAsync<DetectionCoverage>(DetectionSummaryShell);

  const {
    data: dataSessionCount,
    run: runSessionCount,
    status: statusSessionCount
  } = useAsync<SessionCount>(SessionCountShell);

  const {
    data: dataAnalyticCount,
    run: runAnalyticCount,
    status: statusAnalyticCount
  } = useAsync<AnalyticCount>(AnalyticCountShell);

  const {
    data: dataMitreAttackCoverage,
    run: runMitreAttackCoverage,
    status: statusMitreAttackCoverage
  } = useAsync<MitreAttackCoverage>(MitreAttackCoverageShell);

  const {
    data: dataFeaturedSessions,
    run: runFeaturedSessions,
    status: statusFeaturedSessions
  } = useAsync<FeaturedQuery[]>([]);

  const {
    data: dataFeaturedAnalytics,
    run: runFeaturedAnalytics,
    status: statusFeaturedAnalytics
  } = useAsync<FeaturedQuery[]>([]);

  React.useEffect(() => {
    runDetectionSummary(getDetectionSummary());
  }, [runDetectionSummary]);

  React.useEffect(() => {
    runSessionCount(getSessionCount());
  }, [runSessionCount]);

  React.useEffect(() => {
    runAnalyticCount(getAnalyticCount());
  }, [runAnalyticCount]);

  React.useEffect(() => {
    runMitreAttackCoverage(getMitreAttackCoverage());
  }, [runMitreAttackCoverage]);

  React.useEffect(() => {
    runFeaturedSessions(getFeaturedContent(ArtifactType.Session));
    runFeaturedAnalytics(getFeaturedContent(ArtifactType.Analytic));
  }, [runFeaturedAnalytics, runFeaturedSessions]);

  return {
    ...dataDetectionSummary,
    ...dataSessionCount,
    ...dataAnalyticCount,
    ...dataMitreAttackCoverage,
    sessionFeature: dataFeaturedSessions,
    analyticFeature: dataFeaturedAnalytics,
    loadingDetectionSummary: statusDetectionSummary === Status.pending || statusDetectionSummary === Status.idle,
    loadingSessionCount: statusSessionCount === Status.pending || statusSessionCount === Status.idle,
    loadingAnalyticCount: statusAnalyticCount === Status.pending || statusAnalyticCount === Status.idle,
    loadingMitreAttackCoverage:
      statusMitreAttackCoverage === Status.pending || statusMitreAttackCoverage === Status.idle,
    loadingFeaturedAnalytics: statusFeaturedAnalytics === Status.pending || statusFeaturedAnalytics === Status.idle,
    loadingFeaturedSessions: statusFeaturedSessions === Status.pending || statusFeaturedSessions === Status.idle
  };
}
