import React from 'react';

import { useAsync } from 'storage';

import { Artifact, ArtifactType } from 'types/common';

import { getSupplementalSessionDetectionItems } from './Search.api';
import { SupplementalSessionDetection } from './Search.type';

export default function useSupplementalSessionDetectionItems(topic: ArtifactType, feed: Artifact[]) {
  const { data, run } = useAsync<SupplementalSessionDetection[]>();

  const itemGuid = React.useMemo(() => {
    return feed.map(item => item.guid);
  }, [feed]);

  React.useEffect(() => {
    if (topic === ArtifactType.Session && itemGuid.length > 0) {
      run(getSupplementalSessionDetectionItems(itemGuid));
    }
  }, [itemGuid, itemGuid.length, run, topic]);

  return {
    data
  };
}
