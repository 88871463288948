import React from 'react';

import get from 'lodash/get';

import useDebounce from 'hooks/useDebounce';

import { basJobFeed } from 'module/BAS/BAS.api';
import { BASJob } from 'module/BAS/BAS.type';
import { Page } from 'module/Feed/Feed.type';

import { useAsync, Status } from 'storage';

import { BASJobOutcomePayload } from 'types/bas';
import { Guid } from 'types/common';
import { Ops, Query } from 'types/filter';

export type JobListAsyncData = {
  error: string;
  data: Page<BASJob>;
  refresh(): void;
  status: Status;
  setPage(page: number): void;
  setSearchTerm(searchTerm: string): void;
  updateOutcomes(jobId: Guid, outcomes: BASJobOutcomePayload[]): void;
};

const SHELL = {
  items: [],
  total: 0,
  page: 0,
  size: 20
};

function sessionQuery(session: Guid): Query {
  return {
    field: 'script.sessions.guid',
    op: Ops.equals,
    value: session
  };
}

function analyticQuery(analytic: Guid): Query {
  return {
    field: 'analytics.guid',
    op: Ops.equals,
    value: analytic
  };
}

function campaignQuery(campaign: Guid | null): Query {
  return {
    field: campaign ? 'campaign.guid' : 'bas_campaign_id',
    op: Ops.equals,
    value: campaign
  };
}

function scriptQuery(script: Guid): Query {
  return {
    field: 'script.guid',
    op: Ops.equals,
    value: script
  };
}

function textQuery(text: string): Query {
  return {
    field: 'text',
    op: Ops.contains,
    value: text
  };
}

function compositeQuery(queries: Query[]): Query {
  return {
    op: Ops.and,
    items: queries
  };
}

export default function useJobList(
  filters?: { session?: Guid; analytic?: Guid; campaign?: Guid | null; script?: Guid },
  sort_by?: string
): JobListAsyncData {
  const { session, analytic, campaign, script } = filters || {};
  const [page, setPage] = React.useState(0);
  const [_searchTerm, setSearchTerm] = React.useState('');
  const searchTerm = useDebounce(_searchTerm, 1000);
  const { data, error, run, setData, status } = useAsync<Page<BASJob>>(SHELL);
  const params = React.useMemo(
    () => ({
      page: page ?? 0,
      size: 20,
      sort_by: sort_by || 'creation:desc'
    }),
    [page, sort_by]
  );

  const refresh = React.useCallback(() => {
    let payload: Query;
    if (session) {
      payload = searchTerm ? compositeQuery([sessionQuery(session), textQuery(searchTerm)]) : sessionQuery(session);
    } else if (analytic) {
      payload = searchTerm ? compositeQuery([analyticQuery(analytic), textQuery(searchTerm)]) : analyticQuery(analytic);
    } else if (campaign) {
      payload = searchTerm ? compositeQuery([campaignQuery(campaign), textQuery(searchTerm)]) : campaignQuery(campaign);
    } else if (script) {
      payload = searchTerm ? compositeQuery([scriptQuery(script), textQuery(searchTerm)]) : scriptQuery(script);
    } else if (searchTerm) {
      payload = textQuery(searchTerm);
    }
    if (filters && !payload) return;
    run(basJobFeed(params, payload));
    // Using all the elements but filter itself is not stable or not standardFormikBaseProps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, analytic, campaign, script, searchTerm, run, params]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  const updateOutcomes = React.useCallback(
    (jobId: Guid, outcomes: BASJobOutcomePayload[]) => {
      const newItems = [...data.items];
      const newJob = newItems.find(item => item.guid === jobId);
      newJob.outcomes = outcomes.map(outcome => ({
        ...outcome,
        detections: outcome.detections?.map(detection => ({ guid: detection }))
      }));
      const newData = { ...data, items: newItems };

      setData(newData);
    },
    [data, setData]
  );

  return {
    error: error ? get(error, 'response.data.message') : null,
    data,
    refresh,
    status,
    setPage,
    setSearchTerm,
    updateOutcomes
  };
}
