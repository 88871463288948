import { CancelTokenSource } from 'axios';

import { ArtifactType, Tracked } from 'types/common';

import {
  getSupplementalDetectionItems,
  getSupplementalIntelItems,
  getSupplementalAttackScriptItems
} from './Search.api';
import { SupplementalCatalog } from './Search.type';

function getSupplementalFunction(topic: ArtifactType) {
  switch (topic) {
    case ArtifactType.Analytic:
      return getSupplementalDetectionItems;

    case ArtifactType.Intel:
      return getSupplementalIntelItems;

    case ArtifactType.AttackScript:
      return getSupplementalAttackScriptItems;
  }
}

export async function getSupplementalItemsCatalog<T extends Tracked>(
  topic: ArtifactType,
  items: T[],
  cancelTokenSource?: CancelTokenSource
): Promise<SupplementalCatalog> {
  const operator = getSupplementalFunction(topic);
  if (!operator) return Promise.resolve({});

  const config = {
    cancelToken: cancelTokenSource && cancelTokenSource.token
  };
  const result = await operator(
    items.map(i => i.guid),
    config
  );
  return result.reduce(
    (p, c) => ({
      ...p,
      [c.guid]: c
    }),
    {}
  );
}
