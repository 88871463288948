import React from 'react';

import { NistArtifact } from 'module/Dashboard/NistDashboard/NistDashboard.type';
import { Discriminator, NistTag, reduceToBaseTags } from 'module/Tag';

import { compareAlphaNumericStrings } from 'utilities/SortUtils';

import useNist from '../../module/Dashboard/NistDashboard/useNist';

export interface NistInterface {
  searchNistTags: (tagId: string[]) => NistTag[];
  nistTag: NistArtifact[];
  isReady: boolean;
}

const NistTagContext = React.createContext(null);
NistTagContext.displayName = 'NistTagContext';

function useNistTagCatalog(): NistInterface {
  const context = React.useContext<NistInterface>(NistTagContext);

  if (!context) {
    throw new Error('useNistTagContext must be used within the NistTagContext');
  }

  return context;
}

function NistTagProvider({ children }: { children: React.ReactNode }): React.ReactElement {
  const { nistTags, isReady } = useNist();

  const searchNistTags = React.useCallback(
    (tagId: string[]) => {
      const reduceTag = reduceToBaseTags(tagId, Discriminator.Action);
      return reduceTag
        .map(t => {
          const nistInfo = nistTags.find(nist => nist.tag_id.toString() === t.name);
          return {
            ...t,
            name: nistInfo?.tag_name,
            ...nistInfo
          };
        })
        .filter(tag => tag.name)
        .sort((a, b) => compareAlphaNumericStrings(a.name, b.name));
    },
    [nistTags]
  );

  return <NistTagContext.Provider value={{ searchNistTags, nistTags, isReady }}>{children}</NistTagContext.Provider>;
}

export { NistTagProvider, useNistTagCatalog };
