import { Autocomplete, AutocompleteOptionContainer } from 'snap-ui/Autocomplete';
import Paper from 'snap-ui/Paper';
import { styled } from 'snap-ui/util';

export const IntegrationContainer = styled('div', { name: 'IntegrationContainer' })`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(4)};
  margin-bottom: calc(${p => p.theme.spacing(9)} * 2);

  .organization-dropdown {
    width: 400px;
  }

  .paper-container {
    width: 100%;
    height: 100%;
  }

  .left-tabs {
    display: grid;
    grid-template-columns: 20px 1fr 4px;
    gap: ${p => p.theme.spacing(4)};
    padding-right: ${p => p.theme.spacing(3)};
    width: 100%;
    align-items: center;

    .integration-name {
      text-align: left;
    }

    svg {
      width: 20px;
    }
  }

  .alert {
    width: max-content;
  }

  .integration-tab {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    padding: 0;
    width: 100%;
    gap: ${p => p.theme.spacing(4)};

    > .MuiTabs-root {
      flex: 0 0 250px;
    }

    .tab-content {
      width: 100%;
      height: 100%;
      gap: ${p => p.theme.spacing(3)};
      flex-direction: column;
      display: flex;
    }

    .tab-content > div {
      display: flex;
      flex-direction: column;
      gap: ${p => p.theme.spacing(5)};

      .actions {
        display: flex;
        align-items: center;
        gap: ${p => p.theme.spacing(6)};
      }
    }

    .Divider {
      font-weight: bold;
      ::before,
      ::after {
        border-width: 2px;
        border-color: ${p => p.theme.palette.primary.main};
      }
    }
  }
`;

export const StatusHeaderContainer = styled('div', { name: 'StatusHeaderContainer' })`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .actions {
    display: flex;
    align-items: center;
    gap: ${p => p.theme.spacing(3)};
  }
`;

export const StatusContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: ${p => p.theme.spacing(3)};
`;

export const StatusIconContainer = styled('div')`
  flex: 50%;
  text-align: end;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  svg {
    width: 140px;
  }
`;
export const StatusDetailsContainer = styled('div')`
  flex: 50%;
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(3)};
`;

export const ContentPaper = styled(Paper)`
  padding-top: ${p => p.theme.spacing(8)};
  padding: ${p => p.theme.spacing(6)};
  min-height: 250px;
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(4)};
`;

export const HeightContainer = styled('div')`
  height: 64px;
  display: flex;
  align-items: center;
`;

export const TableContainer = styled('div')`
  height: 300px;
`;

export const IntegrationAutocompleteOptionContainer = styled(AutocompleteOptionContainer)`
  &.warning {
    color: ${p => p.theme.palette.greyish.main};
    svg {
      filter: grayscale(1);
    }
  }
`;

export const StyledAutocomplete = styled(Autocomplete)`
  &.MuiAutocomplete-root .MuiInputBase-root .MuiInputBase-input {
    padding-left: 2.5px; // match top/bottom padding
  }
`;

export const AutocompleteSelectedIconContainer = styled('div')`
  display: flex;
  min-width: unset;
  align-items: center;
  justify-content: flex-end;
  width: 20px;
  height: 28px;
  margin-left: ${p => p.theme.spacing(1)};
`;
