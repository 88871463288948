import React from 'react';

import { useHistory } from 'react-router-dom';

import { AppLoader } from 'snap-ui/BackdropLoader';

import { Path } from 'constants/paths';

import useTitle from 'hooks/useTitle';

import { Engage, Fingerprint } from 'lib/Engagement';

import { useAuth, useAuthInterface } from 'provider';

import { Catalog } from 'storage';

export default function LoginSuccess() {
  useTitle('SnapAttack');
  const { replace } = useHistory();
  const { fetch } = useAuthInterface();
  const { user, permission, defaultOrgId, isSubscriber } = useAuth();

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  React.useEffect(() => {
    if (user.email) {
      const attempted_url = window?.sessionStorage.getItem(Catalog.attempted_url);
      Engage.track(Fingerprint.of(Path.LoginSuccess).withData({ attempted_url }));
      Engage.trackPersonIncrement('login success', 1);

      if (attempted_url) {
        try {
          replace(attempted_url);
        } catch (e) {
          // attempted_url wasn't a valid value; fall back to Home
          replace(Path.Home);
        }
      } else replace(Path.Home);
    }
  }, [user, permission, isSubscriber, defaultOrgId, replace]);

  React.useEffect(() => {
    const deleteAttemptedUrlOnRedirect = () => window?.sessionStorage?.removeItem(Catalog.attempted_url);
    return () => deleteAttemptedUrlOnRedirect();
  }, []);

  return <AppLoader />;
}
