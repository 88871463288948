import React from 'react';

import { faSquareUp } from '@fortawesome/pro-regular-svg-icons';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';

import Icon from 'snap-ui/Icon';

import Path from 'constants/paths';

import {
  ArtifactWidgetFooter,
  ArtifactWidgetMenuWrapper,
  Author,
  BurgerCancel,
  BurgerDraft,
  BurgerPublish,
  BurgerReset,
  Count,
  Menu,
  Publish,
  Title,
  Visibility
} from 'module/Layout/Artifact.widgets';

import { decomposeIntel } from '../Intel.helper';
import { Intel } from '../Intel.type';
import Unfurl from '../IntelCore/Unfurl';
import { EditIntelForm } from './IntelEdit';

type IntelHeaderProps = {
  dirty: boolean;
  intel: Intel;
  onPublish(): void;
  onDraft(): void;
  onReset(): void;
  onUnfurl(v: string | File): void;
};

export default function IntelHeader({ intel, ...props }: IntelHeaderProps) {
  const { goBack, replace } = useHistory();
  const { values } = useFormikContext<EditIntelForm>();

  const handleGoBack = () => {
    if (intel.guid) replace(`${Path.Intelligence}/${intel.guid}`, decomposeIntel(intel));
    else goBack();
  };

  return (
    <>
      <Visibility visibility={intel.visibility} />
      <Title headerText={values.title || intel.name || 'New Intelligence'} />
      <Author resource={intel} type='intel' />
      <ArtifactWidgetFooter>
        {!intel.guid && <Unfurl onUnfurl={props.onUnfurl} />}
        {intel.guid && <Count references={intel.reference_counts.reference_count} iocs={intel.indicator_count} />}
        <ArtifactWidgetMenuWrapper>
          <Publish onClick={props.onPublish} startIcon={<Icon icon={faSquareUp} />}>
            Publish
          </Publish>
          <Menu dirty={props.dirty}>
            <BurgerCancel onClick={handleGoBack} />
            <BurgerPublish onClick={props.onPublish} />
            {intel.guid && <BurgerDraft onClick={props.onDraft} />}
            <BurgerReset onClick={props.onReset} disabled={!props.dirty} />
          </Menu>
        </ArtifactWidgetMenuWrapper>
      </ArtifactWidgetFooter>
    </>
  );
}
