import React from 'react';

import { useField } from 'formik';

import { AutocompleteFormikProps } from 'module/Form/AutocompleteFormik';

import { Discriminator } from '.';
import TagSigmaAutocomplete from './TagSigmaAutocomplete';
import { TagOption } from './useTagOptions';

export type Props = Omit<AutocompleteFormikProps, 'options' | 'label' | 'placeholder' | 'disableUserAdditions'> & {
  discriminator: Discriminator;
  enableUserAdditions?: boolean;
};

export default function TagAutocompleteFormik({ disabled, discriminator, name, ...others }: Props) {
  const [field, , helpers] = useField(name);

  function handleChange(value: TagOption[]) {
    const sigmaValues = value.map(v => v.value);

    helpers.setValue(sigmaValues);
  }

  return (
    <TagSigmaAutocomplete
      includeTagNames
      disabled={disabled}
      discriminator={discriminator}
      testId={discriminator}
      name={name}
      onChange={handleChange}
      value={field.value}
      {...others}
    />
  );
}
