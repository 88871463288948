import React from 'react';

import { ErrorProps, Status, useAsync } from 'storage';

import { summarize, summarizeFile } from '../Intel.api';
import { LinkSummary } from '../Intel.type';

export type SummarizeData = {
  error: unknown;
  errorProps: ErrorProps;
  unfurl(source: string | File): Promise<LinkSummary>;
  status: Status;
  reset: () => void;
};

export default function useSummarize(summarizeCallback?: (linkSummary: LinkSummary) => void): SummarizeData {
  const { error, errorProps, task, status, reset, setError } = useAsync<LinkSummary>();

  const unfurl = React.useCallback(
    (source: string & File) => {
      const action = typeof source === 'string' ? summarize : summarizeFile;
      reset();
      return task(action(source)).then(v => {
        if (v.metadata.error)
          setError({
            // simulate AxiosError
            response: {
              status: 418,
              data: {
                detail: v.metadata.error
              }
            }
          });
        if (summarizeCallback) summarizeCallback(v);
        return v;
      });
    },
    [reset, setError, summarizeCallback, task]
  );

  return {
    error,
    errorProps,
    unfurl,
    status,
    reset
  };
}
