import React from 'react';

import MuiFormLabel from '@mui/material/FormLabel';

import { StrictReactNode } from 'types/core';

type Props = {
  children: StrictReactNode;
  id?: string;
  className?: string;
  component?: string;
};

const FormLabel = React.forwardRef<HTMLLabelElement, Props>(function FormLabel(props: Props, ref) {
  return <MuiFormLabel {...props} ref={ref} />;
});

export default FormLabel;
