import cloneDeep from 'lodash/cloneDeep';
import escapeRegExp from 'lodash/escapeRegExp';
import isEmpty from 'lodash/isEmpty';

import { CategorizedIOCs, HASHES, IOC, IOCType, IOCTypeCategory, IPS } from '../IOC.type';

export function pluralize(category: IOCTypeCategory): string {
  switch (category) {
    case IOCTypeCategory.Hash:
      return 'Hashes';
    case IOCTypeCategory.IP_Address:
      return 'IPs';
    case IOCTypeCategory.Domain:
      return 'Domains';
    case IOCTypeCategory.URL:
      return 'URLs';
  }
}

export const INITIAL_CATEGORIES: CategorizedIOCs = {
  [IOCTypeCategory.Hash]: [],
  [IOCTypeCategory.IP_Address]: [],
  [IOCTypeCategory.Domain]: [],
  [IOCTypeCategory.URL]: []
};

export function mapIocType(type: IOCType): IOCTypeCategory {
  if (HASHES.includes(type)) {
    return IOCTypeCategory.Hash;
  } else if (IPS.includes(type)) {
    return IOCTypeCategory.IP_Address;
  } else {
    return type as IOCTypeCategory;
  }
}

export function categorizeIocs(iocs: IOC[], field = null): CategorizedIOCs {
  if (isEmpty(iocs)) return INITIAL_CATEGORIES;
  return iocs.reduce((categories, ioc) => {
    const cat = mapIocType(ioc.type);
    categories[cat].push(field ? ioc[field] : ioc);

    return categories;
  }, cloneDeep(INITIAL_CATEGORIES));
}

export function highlight(keywords: CategorizedIOCs) {
  return function (rawText: string) {
    let highlightedText = rawText;
    Object.entries(keywords ?? {})
      .sort(([keyA], [keyB]) => keyB.localeCompare(keyA))
      .forEach(([key, values]) => {
        values.forEach(value => {
          highlightedText = highlightedText.replace(
            new RegExp(escapeRegExp(value.toString()), 'gi'),
            `<span class="token ${key}">$&</span>`
          );
        });
      });

    return highlightedText;
  };
}
