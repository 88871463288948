import ToggleButton from 'snap-ui/ToggleButton';
import { styled } from 'snap-ui/util';

// Spinner icon is wider than other icons. Contain all with width
export const ToggleActionButton = styled(ToggleButton)`
  width: 1.8rem;
`;

export const Container = styled('div')`
  height: 450px;
  margin: ${p => p.theme.spacing(2, 0)};
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(1)};

  .query {
    margin-top: ${p => p.theme.spacing(4)};
  }

  .minus.included:not(.disabled) {
    color: ${p => p.theme.palette.error.main};
  }

  .plus:not(.included):not(.disabled) {
    color: ${p => p.theme.palette.success.main};
  }
`;

export const Row = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(2)};

  a {
    flex-grow: 1;

    svg {
      margin-right: ${p => p.theme.spacing(2)};
    }
  }
`;
