import orderBy from 'lodash/orderBy';
import { LinkProps } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';

import { FEED_TYPES } from 'constants/feed';
import Path from 'constants/paths';

import { CollectionDiscriminator } from 'module/Collection/Collection.type';
import { isLandingType } from 'module/Collection/Collection.util';
import { DetectionSummary } from 'module/Detection';
import { SupplementalArtifact } from 'module/Search';

import { AnalyticStateDetail } from 'types/analytic';
import { Organization } from 'types/auth';
import { Artifact, ArtifactType } from 'types/common';

import { formatQueryString } from 'utilities/SearchParam';

export function buildLinkWithState(
  topic: ArtifactType,
  item: Artifact,
  supplementalItem?: SupplementalArtifact,
  count?: DetectionSummary
): LinkProps['to'] {
  const encodedItemName = encodeURIComponent(item.name);
  if (isLandingType(item))
    return {
      pathname: `${Path.Collection}/${item.type}/${encodedItemName}`,
      state: { item, supplementalItem, count }
    };

  const feedType = FEED_TYPES[topic];
  const location: LinkProps['to'] = {
    pathname: `${feedType.link}/${item.guid}`,
    state: { item, supplementalItem, count }
  };
  switch (feedType) {
    case FEED_TYPES[ArtifactType.Session]:
      location.search = formatQueryString({
        topic: ArtifactType.Marker
      });
      return location;
    case FEED_TYPES[ArtifactType.Collection]:
      if (item.type === CollectionDiscriminator.Static) return location;
      else {
        const location: LinkProps['to'] = {
          pathname: `${ArtifactType.Landing}/${item.type}/${encodedItemName}`
        };
        return location;
      }
    default:
      return location;
  }
}

const urlPrefix = process.env.PUBLIC_URL + '/images/cards';
const collectionTypes: string[] = [
  CollectionDiscriminator.Dynamic,
  CollectionDiscriminator.Static,
  CollectionDiscriminator.Hybrid
];

export function getCardImageUrl(item: Artifact, topic: ArtifactType, organizations: Organization[]) {
  let url = '';

  if (topic === ArtifactType.Collection && !collectionTypes.includes(item.type)) {
    switch (item.type as CollectionDiscriminator) {
      case CollectionDiscriminator.Actor:
        url = `${urlPrefix}/actor.png`;
        break;
      case CollectionDiscriminator.Attack:
        url = `${urlPrefix}/attack.png`;
        break;
      case CollectionDiscriminator.Software:
        url = `${urlPrefix}/software.png`;
        break;
      case CollectionDiscriminator.Vulnerability:
        url = `${urlPrefix}/vulnerability.png`;
        break;
    }
  } else {
    url = organizations.find(o => o.id === item.organization_id)?.small_image;
  }

  const alt =
    topic === ArtifactType.Session
      ? `${urlPrefix}/threat.png`
      : topic === ArtifactType.Analytic
      ? `${urlPrefix}/detection.png`
      : topic === ArtifactType.Intel
      ? `${urlPrefix}/intelligence.png`
      : topic === ArtifactType.AttackScript
      ? `${urlPrefix}/attack_script.png`
      : topic === ArtifactType.Collection
      ? `${urlPrefix}/collection.png`
      : `${urlPrefix}/generic.png`;
  const source = item.small_image || url || alt;

  return [source, alt];
}

export function getCurrentOrgState(orgId: number, states: AnalyticStateDetail[] = []): AnalyticStateDetail {
  return orderBy(states, ['creation'], ['desc']).find(state => state.organization_id === orgId);
}

export const cleanHTML = dirty =>
  sanitizeHtml(dirty, {
    allowedTags: ['b', 'i', 'em', 'strong', 'span', 'div']
  });
