import z from 'zod';

import { SigmaEngine } from 'types/analytic';

export const LanguageFormSchema = z.object({
  discriminator: z.nativeEnum(SigmaEngine),
  organization_id: z.number(),
  name: z.string().nonempty('Name is required'),
  backend_key: z.string().nonempty('Compiler is required'),
  output_format: z.string().optional().nullable(),
  config_keys: z.array(z.string()).nonempty('At least one configuration is required'),
  backend_options: z
    .array(z.object({ field: z.string(), value: z.string() }))
    .nullable()
    .optional()
    .refine(
      backend_options =>
        !backend_options?.length ||
        backend_options.every(option => option.field === '' && option.value === '') ||
        backend_options.every(option => option.field !== ''),
      {
        message: 'Key is required',
        path: ['backend_options']
      }
    )
});
