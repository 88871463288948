import React from 'react';

import { useField } from 'formik';

type FormikFieldErrorProps = {
  name: string;
};

function FormikFieldError(props: FormikFieldErrorProps): React.ReactElement {
  // useField returns array, we are only using the meta which is at index 1
  const meta = useField(props.name)[1];
  const error: string | string[] = meta.error;

  if (!error) return null;
  if (Array.isArray(error)) {
    if (!error.length) return null;
    return (
      <>
        {error.map((err, idx) => (
          <div key={idx}>
            <span className='error'>{err}</span>
          </div>
        ))}
      </>
    );
  }
  return <span className='error'>{error}</span>;
}

export default FormikFieldError;
