import React, { ReactElement } from 'react';

import Path from 'constants/paths';

import useTitle from 'hooks/useTitle';

import { Engage, Fingerprint } from 'lib/Engagement';

import NotFound from 'module/Util/Fallback/NotFound';

import { useAuth } from 'provider';

import { ContentPermission } from 'types/auth';
import { ArtifactType } from 'types/common';

import { AttackScriptContainer } from '../AttackScript.style';
import { useAttackScriptCatalog } from '../AttackScriptProvider';
import { useIsLatestVersionSnack } from '../useIsLatestVersionSnack';
import AttackScriptDetail from './AttackScriptDetail';
import AttackScriptViewHeader from './AttackScriptViewHeader';

export default function AttackScriptView(): ReactElement {
  const { permission: organizations } = useAuth();

  const { attackScript, error } = useAttackScriptCatalog();
  useTitle(`${attackScript.name || 'Attack Script'} | SnapAttack`);

  useIsLatestVersionSnack(attackScript.guid, attackScript.versions, ArtifactType.AttackScript);

  React.useEffect(() => {
    if (attackScript.guid && attackScript.name) {
      Engage.track(
        Fingerprint.load(Path.AttackScript).withData({
          content: ContentPermission.Read,
          guid: attackScript.guid,
          name: attackScript.name,
          artifact_organization_id: attackScript.organization_id,
          artifact_organization_name: organizations?.find(o => o.id === attackScript.organization_id)?.name
        })
      );
      Engage.trackPersonIncrement(`view ${Path.AttackScript}`, 1);
    }
  }, [attackScript.guid, attackScript.name, attackScript.organization_id, organizations]);

  if (error.attackScript) return <NotFound artifact={ArtifactType.AttackScript} error={error.attackScript} />;

  return (
    <AttackScriptContainer meta={<AttackScriptViewHeader />} type={ArtifactType.AttackScript}>
      <AttackScriptDetail />
    </AttackScriptContainer>
  );
}
