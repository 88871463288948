import React from 'react';

import { asValidationError } from 'apis/snapattack';

import { usePushSnack } from 'provider';

export default function useSnackOnError(setError: (e: any) => void, error: any): void {
  const pushSnack = usePushSnack();

  const errorMessage = error ? asValidationError(error)?.detail?.[0] : null;
  React.useEffect(() => {
    if (errorMessage) {
      pushSnack(errorMessage, 'error');
      setError(null);
    }
  }, [errorMessage, pushSnack, setError]);
}
