import { Field, Zoom, ZoomOptions } from 'types/progressGraph';

export const SEARCH_FIELDS = {
  PROCESS_NAME: {
    key: 'process_name',
    content: 'Process Name',
    value: 'process_name'
  },
  PROCESS_PATH: {
    key: 'Image',
    content: 'Image',
    value: 'Image'
  },
  COMMAND_LINE: {
    key: 'command_line',
    content: 'Command Line',
    value: 'CommandLine'
  },
  MD5: {
    key: 'md5',
    content: 'MD5',
    value: 'md5'
  },
  ROW_ID: {
    key: 'row_id',
    content: 'Row ID',
    value: 'row_id'
  },
  PROCESS_ID: {
    key: 'ProcessId',
    content: 'Process ID',
    value: 'ProcessId'
  },
  PROCESS_GUID: {
    key: 'ProcessGuid',
    content: 'Process GUID',
    value: 'ProcessGuid'
  },
  ATTACK_NODE: {
    key: 'labeled_attack',
    content: 'Labeled Attack',
    value: 'labeled_attack'
  },
  ANALYTIC_HITS: {
    key: 'analytic_hit',
    content: 'Detection Hit',
    value: 'analytic_hit'
  }
};
export const MAX_PREVALENCE = 10;
export const MAX_MALICIOUS = 100;
export const SESSION_FIELDS: Field[] = [
  { label: 'Description', name: 'description' },
  { label: 'Author', name: 'author' },
  { label: 'Duration', name: 'duration' },
  { label: 'Created On', name: 'created_on' },
  { label: 'CommandLine', name: 'command_line' },
  { label: 'Tags', name: 'tags' }
];

export const DETAIL_FIELDS: Field[] = [
  { label: 'UTC Time', name: 'UtcTime' },
  { label: 'Start Offset', name: 'start_offset' },
  { label: 'Image', name: 'Image', multiline: true },
  {
    label: 'Parent Image',
    name: 'ParentImage',
    multiline: true
  },
  {
    label: 'Command Line',
    name: 'CommandLine',
    multiline: true
  },
  {
    label: 'MD5',
    name: 'md5',
    link: 'https://www.virustotal.com/gui/file/{VALUE}'
  },
  { label: 'Row ID', name: 'row_id' }
];

export const PREVALENCE_FIELDS: Field[] = [
  { label: 'Prevalence', name: 'prevalence' },
  { label: 'Process Name', name: 'process_name_prevalence_score' },
  { label: 'MD5', name: 'md5_prevalence_score' },
  {
    label: 'Command Line',
    name: 'command_line_prevalence_score'
  },
  {
    label: 'Process Name & MD5',
    name: 'process_name_with_md5_prevalence_score'
  },
  {
    label: 'Process Name & Directory',
    name: 'process_name_with_process_dirname_prevalence_score'
  }
];

export const MALICIOUS_FIELDS: Field[] = [{ label: 'Maliciousness', name: 'maliciousness' }];

export const LOG_DETAILS_FIELDS: Field[] = [
  { label: 'Company', name: 'Company' },
  { label: 'Current Directory', name: 'CurrentDirectory' },
  { label: 'Description', name: 'Description' },
  { label: 'File Version', name: 'FileVersion' },
  { label: 'Integrity Level', name: 'IntegrityLevel' },
  { label: 'Original Filename', name: 'OriginalFileName' },
  {
    label: 'Parent Command Line',
    name: 'ParentCommandLine'
  },
  {
    label: 'Parent Process GUID',
    name: 'ParentProcessGuid'
  },
  { label: 'Process GUID', name: 'ProcessGuid' },
  { label: 'Product', name: 'Product' },
  { label: 'Rule Name', name: 'RuleName' },
  { label: 'Sid', name: 'Sid' },
  { label: 'Sid Type', name: 'SidType' },
  { label: 'User', name: 'User' }
];

export const DEFAULT_ZOOM: ZoomOptions = {
  zoomDirection: Zoom.FIT,
  minHeight: 0,
  maxHeight: 0,
  zoom: 0.3,
  minWidth: 0,
  maxWidth: 0,
  translate: {
    x: 600,
    y: 700
  }
};

export const URL_PARAMS_SCHEMA = {
  minTime: Number,
  maxTime: Number,
  hostId: String,
  hostname: String,
  session: String,
  query: String,
  option: String,
  nodeId: String
};

export const CONDENSED_NODES = 5;

export const NODE_SIZE = { x: 800, y: 700 };

export const MULTILINE_SIZE = 35;
