import axios, { AxiosResponse } from 'axios';

import { HubspotUser } from 'types/auth';

export function sendFormDateToHubspot(userInfo: HubspotUser): Promise<void | AxiosResponse<any>> {
  const data = JSON.stringify({
    fields: [
      {
        name: 'email',
        value: userInfo.email
      },
      {
        name: 'firstname',
        value: userInfo.firstName
      },
      {
        name: 'lastname',
        value: userInfo.lastName
      }
    ]
  });
  return axios
    .post(
      `https://api.hsforms.com/submissions/v3/integration/submit/9207696/8cae63cd-13b3-4de8-a006-68f2957069b7`,
      data,
      {
        method: 'POST',
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    .then(res => res)
    .catch(err => console.log('Hub error', err));
}
