import React from 'react';

import { BurgerClicker } from 'module/Layout/Artifact.widgets';

import { useAuth } from 'provider';

import { checkTaskPermission } from 'services/authService';

import { FunctionalPermission } from 'types/auth';

import { DetectionExporter, icon, RecommendedDetectionExporter } from '.';

type Button = Partial<Omit<BurgerClicker, 'onClick'>>;
type Dialog = Omit<DetectionExporter, 'isOpen' | 'onClose'>;

export function useRecommendedExportInterface(): [(props: Button) => JSX.Element, (props: Dialog) => JSX.Element] {
  const { permission } = useAuth();
  const [isOpen, setIsOpen] = React.useState(false);

  if (!checkTaskPermission(permission, FunctionalPermission.BulkExportAnalytic)) return [() => null, () => null];

  const button = (props: Button) => (
    <BurgerClicker
      title='Export Recommended Detections'
      aria-label='Export Recommended Detections'
      icon={icon}
      onClick={() => setIsOpen(true)}
      TooltipProps={{
        title: 'Export recommended detections to CSV or JSON',
        arrow: true,
        placement: 'right'
      }}
      {...props}
    />
  );
  const dialog = (props: Dialog) => (
    <RecommendedDetectionExporter isOpen={isOpen} onClose={() => setIsOpen(false)} {...props} />
  );

  return [button, dialog];
}
