import { styled } from 'snap-ui/util';

import { NAVIGATION_HEADER_HEIGHT } from 'module/Scaffold/Scaffold.const';

export const Container = styled('div')`
  margin-top: -${NAVIGATION_HEADER_HEIGHT}px;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  padding-bottom: ${p => p.theme.spacing(8)};

  justify-content: space-between;
  align-items: center;

  background-image: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)),
    url(${process.env.PUBLIC_URL + '/images/darkpurple.jpg'});
  background-position: center;
  background-size: cover;

  .Omnisearch {
    width: 100%;
    max-width: 800px;
    flex-grow: 1;
    justify-content: center;
  }
`;
