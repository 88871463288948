import React from 'react';

import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';

import { StrictReactNode } from 'types/core';

type Props = {
  onChange(event: React.SyntheticEvent<Element, Event>, checked: boolean): void;
  children?: StrictReactNode;
};

export default function RegisterAgreement(props: Props) {
  return (
    <FormGroup className='field validation-field login-field'>
      <FormControlLabel
        name='legal'
        label={
          <>
            <span>I have read and agree to follow the terms specified in the </span>
            <a
              href='https://www.snapattack.com/legal/end-user-license-agreement'
              target='_blank'
              rel='noopener noreferrer'
            >
              End User License Agreement
            </a>
            <span> and </span>
            <a href='https://www.snapattack.com/legal/acceptable-use-policy' target='_blank' rel='noopener noreferrer'>
              Acceptable Use Policy
            </a>
          </>
        }
        control={<Checkbox onChange={props.onChange} />}
      />
      {props.children}
    </FormGroup>
  );
}
