import React from 'react';

import classnames from 'classnames';

import CircularProgress from 'snap-ui/CircularProgress';
import { DataGridProps, GridRowSelectionModel } from 'snap-ui/DataGrid';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { DisplayKind, Feed } from 'module/Feed/Feed.type';
import FeedItems from 'module/Feed/FeedItems';

import { ArtifactType, Guid } from 'types/common';

type CurationTab = {
  gridApiRef: DataGridProps['apiRef'];
  className?: string;
  data: Feed;
  isActive: boolean;
  topic: ArtifactType;
  onChangePage(page: number): void;
  page: number;
  selectionModel: GridRowSelectionModel;
  onSelectionModelChange(model: Guid[]): void;
  display: DisplayKind;
  hideCheckboxes?: boolean;
};

export const PaywallContainer = styled('div')`
  position: relative;
  border: 1px solid ${p => p.theme.palette.grey[700]};
  padding: ${p => p.theme.spacing(3)};

  .Paywall {
    margin: 0;
  }
`;

const Container = styled('div')`
  position: relative;
  border: 1px solid ${p => p.theme.palette.grey[700]};
  padding: ${p => p.theme.spacing(3)};

  .Paywall {
    margin: 0;
  }

  &.DisplayKind-${DisplayKind.Card} {
    min-width: 900px;
    max-width: 1200px;
  }

  &.DisplayKind-${DisplayKind.Grid} {
    max-width: calc(100vw - var(--Scaffold-drawer) - (var(--Scaffold-spacing) * 2));
  }

  .BackdropLoader {
    position: absolute;
    display: flex;
    align-items: flex-start;
  }

  .CircularProgress {
    margin-top: 50px;
  }

  .CurationTab-loading {
    align-items: flex-start;
    display: flex;
    gap: ${p => p.theme.spacing(2)};
    padding: ${p => p.theme.spacing(5)};
  }
`;

export default function CurationTab(props: CurationTab) {
  const { data, display, onChangePage } = props;

  const handleChangePage = React.useCallback(
    (newPage: number) => {
      if (newPage > data.pageTotal || newPage < 1) {
        return;
      }

      onChangePage(newPage);
    },
    [data.pageTotal, onChangePage]
  );

  return (
    <Container className={classnames(`CurationTab DisplayKind-${display}`, props.className)}>
      {props.isActive ? (
        <Typography className='CurationTab-loading' variant='h3'>
          Fetching page {props.page}... <CircularProgress size={30} />
        </Typography>
      ) : (
        <FeedItems
          gridApiRef={props.gridApiRef}
          page={props.page}
          total={data.pageTotal}
          items={data.items}
          topic={props.topic}
          displayKind={display}
          selectionModel={props.selectionModel}
          onSelectionModelChange={props.onSelectionModelChange}
          onChangePage={handleChangePage}
          hideCheckboxes={props.hideCheckboxes}
        />
      )}
    </Container>
  );
}
