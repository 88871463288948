import React from 'react';

import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ZodSchema } from 'zod';

import Alert from 'snap-ui/Alert';
import CircularProgress from 'snap-ui/CircularProgress';
import { FormDialog } from 'snap-ui/Dialog';
import Icon from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

import { Path } from 'constants/paths';

import { ApiError } from 'module/ApiError';

import { useAsync, Status } from 'storage';

import { BulkLaunchBASFormValues, LaunchBASFormValues } from 'types/bas';

type LauncherModalProps = {
  children: JSX.Element;
  initialValues: LaunchBASFormValues | BulkLaunchBASFormValues;
  open: boolean;
  onClose(): void;
  onSubmit(values: LaunchBASFormValues | BulkLaunchBASFormValues): Promise<void>;
  submitDisabled: boolean;
  msvUrl?: string;
  subtitle?: string;
  zodSchema: ZodSchema;
};

const TopAlert = styled(ApiError)`
  margin-bottom: ${p => p.theme.spacing(6)};
`;

export default function LauncherModal({
  children,
  initialValues,
  open,
  onClose,
  onSubmit,
  submitDisabled,
  msvUrl,
  subtitle,
  zodSchema
}: LauncherModalProps): JSX.Element {
  const { errorProps, reset, status, task } = useAsync();
  const isLoading = status === Status.pending;
  const isSuccess = status === Status.resolved;

  const submitProps = msvUrl
    ? {
        children: 'Launch in Mandiant Security Validation',
        startIcon: <Icon icon={faArrowUpRightFromSquare} />,
        href: msvUrl
      }
    : {
        children: isLoading ? <CircularProgress size={25} /> : 'Launch',
        disabled: isLoading || isSuccess || submitDisabled
      };

  return (
    <FormDialog
      cancelLabel={isSuccess ? 'Close' : 'Cancel'}
      DialogProps={{ onClose: handleCloseModal, open }}
      FormikConfig={{
        enableReinitialize: true,
        initialValues,
        onSubmit: handleSubmit,
        zodSchema
      }}
      SubmitProps={submitProps}
      title='Launch Attack Simulation'
      subtitle={subtitle}
    >
      <TopAlert {...errorProps} />
      {isSuccess ? (
        <Alert severity='success'>
          The attack simulation job has been created. You can view the results on the{' '}
          <Link to={Path.BASJob}>Attack Simulation page</Link> after it is completed.
        </Alert>
      ) : (
        children
      )}
    </FormDialog>
  );

  function clearState(): void {
    reset();
  }

  function handleCloseModal(): void {
    clearState();
    onClose();
  }

  function handleSubmit(values: LaunchBASFormValues): void {
    task(onSubmit(values));
  }
}
