import React from 'react';

import classnames from 'classnames';
import { Form } from 'formik';

import Alert from 'snap-ui/Alert';
import Button, { ButtonProps } from 'snap-ui/Button';
import { styled } from 'snap-ui/util';

import { ValidationError } from 'apis';

import { standardFormikBaseProps } from 'module/Form';
import Formik, { FormikProps } from 'module/Form/Formik';

import { StrictReactNode } from 'types/core';

type Props<V> = {
  className?: string;
  error: ValidationError;
  fieldName: string;
  fieldComponent: StrictReactNode;
  FormikConfig: FormikProps<V>;
  SubmitProps: ButtonProps;
  info?: StrictReactNode;
};

const Container = styled('div')`
  .Alert {
    margin-bottom: ${p => p.theme.spacing(4)};
  }

  form {
    display: flex;
    flex-direction: row;
    gap: ${p => p.theme.spacing(4)};
  }

  .field {
    width: 400px;

    & > * {
      width: 100%;
    }
  }

  .action {
    button {
      min-width: 200px;
      height: 40px;
    }
  }
`;

function UserOption<V>({ className, error, fieldComponent, fieldName, FormikConfig, SubmitProps, info }: Props<V>) {
  return (
    <Container className={classnames('UserOption', className)}>
      <Formik {...standardFormikBaseProps} {...FormikConfig}>
        <>
          {error && <Alert severity='error'>{error?.detail || 'Oops! This was unexpected'}</Alert>}
          {info}
          <Form>
            <div className='field'>{fieldComponent}</div>
            <div className='action'>
              <Button variant='outlined' ariaLabel={`Set your ${fieldName}`} type='submit' {...SubmitProps} />
            </div>
          </Form>
        </>
      </Formik>
    </Container>
  );
}

export default UserOption;
