import React from 'react';

import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { Prompt } from 'react-router-dom';

import {
  DataGrid,
  DataGridProps,
  GridCellParams,
  GridColDef,
  GridComparatorFn,
  GridGroupingColDefOverride,
  GridRenderCellParams,
  GridRowParams,
  GridTreeNodeWithRender,
  GridValidRowModel,
  gridFilteredSortedRowIdsSelector,
  useGridApiRef
} from 'snap-ui/DataGrid';
import { FormDialog } from 'snap-ui/Dialog';
import Icon from 'snap-ui/Icon';
import { FieldsLayout } from 'snap-ui/Layout';
import Tooltip from 'snap-ui/Tooltip';
import { theme } from 'snap-ui/theme';
import { styled } from 'snap-ui/util';

import { standardFormikBaseProps } from 'module/Form';
import CheckboxFormik from 'module/Form/CheckboxFormik';
import { JobGroupResult } from 'module/Job';
import { RouterMessage } from 'module/Util/RouterPrompt';

import { useAuth } from 'provider';

import { ArtifactScore } from 'types/common';

import PanelPaper from '../core/PanelPaper';
import {
  formatConfidenceDetectionsForPayload,
  getConfidenceDifference,
  preparedBulkConfidenceForTable
} from './BulkConfidence.helper';
import { BulkConfidencePayload, JobTaskResult } from './BulkConfidence.type';
import { CustomGridTreeDataGroupingCell, CustomToolbar, SelectEditInputCell } from './BulkConfidence.widgets';

const PROMPT_MESSAGE = JSON.stringify({
  title: 'Are you sure?',
  submitText: 'Go Back',
  children: `You have detection confidence changes that have not been saved. Are you sure you want to navigate away?

      Saving confidence changes will overwrite the confidence levels for these detections across the application. This
      action CANNOT be undone`,
  secondaryText: 'Discard & Continue'
} as RouterMessage);

const StyledCheckbox = styled(CheckboxFormik)`
  .MuiTypography-body1 {
    margin-top: ${p => p.theme.spacing(4)};
  }
`;

const Container = styled(PanelPaper)`
  height: 70vh;
  .data-grid-container {
    min-height: 65vh;
    display: flex;
    flex-direction: column;
    align-items: end;
    .data-grid {
      width: 100%;
    }
  }

  .toolbar-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 8px;
  }
  .data-header-action {
    display: flex;
    gap: ${p => p.theme.spacing(2)};
  }

  .update-button {
    margin-top: ${p => p.theme.spacing(5)};
  }

  .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer {
    flex-direction: row;
  }

  .row-button-group-expand {
    border-radius: 50%;
    min-width: 29px;
    padding: ${p => p.theme.spacing(2)};
    margin-right: ${p => p.theme.spacing(3)};
    span {
      margin: 0;
      svg {
        color: ${p => p.theme.palette.common.white};
      }
    }
  }
  .group-cell-expanded {
    transform: rotate(90deg);
  }

  .dataGrid-row {
    border-right: 1px solid rgba(81, 81, 81, 1);
  }

  .dataGrid-edit-cell {
    padding: 0 ${p => p.theme.spacing(3)};
  }

  .detection-name:hover {
    color: ${p => p.theme.palette.primary.main};
  }
  .non-group-space {
    margin-left: 42px;
  }

  .difference {
    color: ${p => p.theme.palette.common.black};

    &.diff-5 {
      background-color: hsla(0, 100%, 55%, 0.75);
    }
    &.diff-4 {
      background-color: hsla(0, 100%, 55%, 0.75);
    }
    &.diff-3 {
      background-color: hsla(0, 100%, 60%, 0.75);
    }
    &.diff-2 {
      background-color: hsla(0, 100%, 65%, 0.75);
    }
    &.diff-1 {
      background-color: hsla(0, 100%, 75%, 0.75);
    }
    &.diff0 {
      background-color: hsla(0, 0%, 75%, 0.75);
    }
    &.diff1 {
      background-color: hsla(102, 73%, 74%, 0.75);
    }
    &.diff2 {
      background-color: hsla(102, 73%, 64%, 0.75);
    }
    &.diff3 {
      background-color: hsla(102, 73%, 54%, 0.75);
    }
    &.diff4 {
      background-color: hsla(102, 73%, 50%, 0.75);
    }
    &.diff5 {
      background-color: hsla(102, 73%, 45%, 0.75);
    }
  }
  .dropdown-edit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &:hover {
      cursor: pointer;
    }
  }
  .level-intensity {
    &.cell-change {
      border: 1px solid ${p => p.theme.palette.primary.main};
    }
    &.${ArtifactScore.HIGHEST} {
      color: ${p => p.theme.palette.error.main};
    }
    &.${ArtifactScore.HIGH} {
      color: ${p => p.theme.palette.orange.main};
    }
    &.${ArtifactScore.MEDIUM} {
      color: ${p => p.theme.palette.warning.main};
    }
    &.${ArtifactScore.LOW} {
      color: ${p => p.theme.palette.success.main};
    }
    &.${ArtifactScore.LOWEST} {
      color: ${p => p.theme.palette.info.main};
    }
    &.${ArtifactScore.UNKNOWN} {
      color: ${p => p.theme.palette.grey[500]};
    }
  }
`;

type ConfidenceGridProps = {
  className?: string;
  bulkConfidence: JobGroupResult;
  updateDetection: (detection: JobTaskResult) => void;
  isLoading: boolean;
  update?: (payload: BulkConfidencePayload[], overwriteManual?: boolean) => void;
};

function ConfidenceGrid({ className, bulkConfidence, updateDetection, update }: ConfidenceGridProps) {
  const { defaultOrgId } = useAuth();
  const apiRef = useGridApiRef();

  const [changedDetection, setChangedDetection] = React.useState<JobTaskResult[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showSaveConfirmDialog, setShowSaveConfirmDialog] = React.useState(false);

  const tableData = preparedBulkConfidenceForTable(bulkConfidence);

  const getTreeDataPath: DataGridProps['getTreeDataPath'] = row => row.groupGuid;
  const handleEditClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const saveDetections = React.useCallback(
    ({ overwriteManual }: { overwriteManual: boolean }) => {
      const selectedRows = apiRef.current.getSelectedRows();
      const selectedRowValues = Array.from(selectedRows.values());
      if (selectedRowValues.length > 0) {
        const payload = formatConfidenceDetectionsForPayload(selectedRowValues as JobTaskResult[], defaultOrgId);
        update(payload, overwriteManual);
      } else {
        const payload = formatConfidenceDetectionsForPayload(tableData as JobTaskResult[], defaultOrgId);
        update(payload, overwriteManual);
      }
    },
    [apiRef, defaultOrgId, tableData, update]
  );

  const updateRow = React.useCallback(
    (newRow: JobTaskResult) => {
      const newDetection = {
        ...newRow,
        diff: getConfidenceDifference(newRow.old_rank, newRow.newRank)
      };
      updateDetection(newDetection as JobTaskResult);
      return newDetection;
    },
    [updateDetection]
  );

  const handleCellClick = React.useCallback(
    (params: GridCellParams) => {
      if (params.isEditable) {
        setAnchorEl(apiRef.current.getCellElement(params.id, params.field));
        apiRef.current.startCellEditMode({ id: params.id, field: params.field });
      }
    },
    [apiRef]
  );

  function simplifyErrorMessage(error: string) {
    if (!error) {
      return null;
    } else if (error.toLowerCase().includes('timeout')) {
      return 'Timeout';
    } else if (error.toLowerCase().includes('syntax')) {
      return 'Syntax';
    } else {
      return 'Yes';
    }
  }

  const identityColumns: GridColDef<GridValidRowModel>[] = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 300,
      renderCell: params => <CustomGridTreeDataGroupingCell {...params} />
    },
    {
      field: 'integration',
      headerName: 'Integration',
      minWidth: 200,
      renderCell: params => params.row.integration_name,
      valueGetter: p => p.row.integration_name
    },
    {
      field: 'count',
      headerName: 'Hits',
      width: 75
    },
    {
      field: 'old_rank',
      headerName: 'Current Confidence',
      type: 'singleSelect',
      width: 120,
      valueOptions: Object.values(ArtifactScore),
      cellClassName: p => classNames('level-intensity', p.value)
    },
    {
      field: 'newRank',
      headerName: 'Suggested Confidence',
      type: 'singleSelect',
      width: 120,
      editable: true,
      valueOptions: Object.values(ArtifactScore).filter(score => score !== ArtifactScore.UNKNOWN),
      renderEditCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => (
        <SelectEditInputCell params={params} apiRef={apiRef} handleEditClick={handleEditClick} anchorEl={anchorEl} />
      ),
      renderCell: p =>
        p.row.groupGuid?.length < 2 ? (
          <Tooltip arrow placement='top-end' title='Click to edit confidence'>
            <span onClick={() => handleCellClick(p)} className='dropdown-edit'>
              {p.value}
              <Icon icon={faCaretDown} color={theme.palette.common.white} />
            </span>
          </Tooltip>
        ) : null,
      cellClassName: p =>
        classNames('level-intensity editable', p.value, { 'cell-change': p.row.newRankReadOnly !== p.row.newRank }),
      valueParser: (value: any, params: GridCellParams) => {
        setChangedDetection(prev => [
          ...prev.filter(prev => prev.guid !== params.row.guid),
          { ...params.row, newRank: value }
        ]);
        return value;
      }
    },
    {
      field: 'difference',
      headerName: 'Difference',
      type: 'number',
      width: 80,
      align: 'center',
      headerAlign: 'center',
      cellClassName: p => (p.row.groupGuid?.length < 2 ? classNames('difference', `diff${p.row.diff}`) : null),
      renderCell: p => (p.row.groupGuid?.length < 2 ? p.row.diff : null),
      valueGetter: p => p.row.diff
    },
    {
      field: 'logsource',
      headerName: 'Logsource',
      flex: 1,
      renderCell: p => (
        <Tooltip arrow placement='top-end' title={p.value}>
          <span>{p.value}</span>
        </Tooltip>
      )
    },
    {
      field: 'error',
      headerName: 'Error',
      flex: 1,
      renderCell: p => (
        <Tooltip arrow placement='top-end' title={p.value}>
          <span>{simplifyErrorMessage(p.row?.error)}</span>
        </Tooltip>
      )
    }
  ];

  const sortGroupByName: GridComparatorFn<string> = (a, b) => a.localeCompare(b);

  const groupingColDef: GridGroupingColDefOverride<GridValidRowModel> = {
    headerName: 'Name',
    minWidth: 350,
    sortable: true,
    renderCell: params => <CustomGridTreeDataGroupingCell {...params} />,
    valueGetter: p => p.row.name,
    valueFormatter: p => p.value,
    sortComparator: sortGroupByName,
    sortingOrder: ['asc', 'desc']
  };

  return (
    <Container className={className}>
      <>
        <div className='data-grid-container'>
          <DataGrid
            className='data-grid'
            apiRef={apiRef}
            checkboxSelection={true}
            columns={identityColumns}
            rows={tableData || []}
            getRowId={row => row.guid + row.integration}
            processRowUpdate={updateRow}
            disableRowSelectionOnClick
            groupingColDef={groupingColDef}
            treeData
            getTreeDataPath={getTreeDataPath}
            isCellEditable={params => params.row.groupGuid?.length < 2}
            isRowSelectable={(params: GridRowParams) => params.row.groupGuid?.length < 2}
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }]
              },
              columns: {
                columnVisibilityModel: { name: false }
              },
              filter: {
                filterModel: {
                  items: [{ field: 'error', operator: 'isEmpty' }]
                }
              }
            }}
            components={{
              Toolbar: () => (
                <CustomToolbar
                  setShowSaveConfirmDialog={setShowSaveConfirmDialog}
                  selectedDetection={apiRef.current.getSelectedRows().size}
                  totalDetection={
                    gridFilteredSortedRowIdsSelector(apiRef).filter(row => {
                      return apiRef.current.getRow(row).groupGuid?.length === 1;
                    }).length
                  }
                />
              )
            }}
            getCellClassName={() => 'dataGrid-row'}
          />
        </div>
      </>
      <FormDialog
        DialogProps={{
          open: showSaveConfirmDialog,
          onClose: () => setShowSaveConfirmDialog(false)
        }}
        FormikConfig={{
          ...standardFormikBaseProps,
          initialValues: { overwriteManual: false },
          onSubmit: saveDetections
        }}
        SubmitProps={{
          children: 'Save'
        }}
        title='Save Confidence Changes'
      >
        <FieldsLayout>
          <span>
            Are you sure you want to save your detection confidence changes? This will overwrite the confidence levels
            for these detections across the application. This action CANNOT be undone.
          </span>
          <div>
            <StyledCheckbox
              label='Overwrite manually specified confidence values with the new calculated values'
              name='overwriteManual'
            />
          </div>
        </FieldsLayout>
      </FormDialog>
      <Prompt when={changedDetection.length > 0} message={() => PROMPT_MESSAGE} />
    </Container>
  );
}

export default ConfidenceGrid;
