import React from 'react';

import { useHistory } from 'react-router-dom';

import Path from 'constants/paths';

export function CurationEditReset() {
  const { replace } = useHistory();
  React.useLayoutEffect(() => {
    replace(Path.CollectionCreate);
  }, [replace]);
  return null;
}
