import React, { Component } from 'react';

import { select, behavior, event } from 'd3';
import deepEqual from 'deep-equal';
import clone from 'lodash/clone';
import get from 'lodash/get';
import { v4 as uuidv4 } from 'uuid';

import { Detection } from 'module/Detection/Detection.type';

import { CombinedCompositeMarker } from 'types/marker';
import { NodeDataType } from 'types/progressGraph';

import Link from './Link';
import Node from './Node';

type GraphProps = {
  width?: number;
  height?: number;
  data: any;
  children?: any[];
  center?: { x: number; y: number };
  zoom?: number;
  initialDepth?: number;
  useCollapseData?: boolean;
  depthFactor?: number;
  separation?: {
    siblings: number;
    nonSiblings: number;
  };
  nodeSize?: {
    x: number;
    y: number;
  };
  orientation?: string;
  styles?: {
    links: object;
    nodes: object;
  };
  scaleExtent?: { min: number; max: number };
  translate: {
    x: number;
    y: number;
  };
  onUpdate?: (value) => void;
  setContainerRef?: (ref) => void;
  updateZoom: (zoom: number, translateX: number, translateY: number) => void;
  svgClassName: string;
  gClassName: string;
  nodeClick: (selectedNode) => void;
  expandClick: (selectedNode) => void;
  nodes: NodeDataType[];
  links: { source: NodeDataType; target: NodeDataType }[];
  composite: CombinedCompositeMarker;
  detection: Detection;
};

type GraphState = {
  d3: {
    scale?: number;
    translate?: {
      x: number;
      y: number;
    };
  };
  data: object[];
};

class Graph extends Component<GraphProps, GraphState> {
  constructor(props) {
    super(props);

    this.state = {
      d3: Graph.calculateD3Geometry(props),
      data: props.data
    };
  }
  internalState = {
    initialRender: true,
    targetNode: null,
    isTransitioning: false
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let derivedState = null;
    if (nextProps.data !== prevState.dataRef) {
      derivedState = {
        dataRef: nextProps.data,
        data: nextProps.data
      };
    }

    const d3 = Graph.calculateD3Geometry(nextProps, prevState);
    if (!deepEqual(d3, prevState.d3)) {
      derivedState = derivedState || {};
      derivedState.d3 = d3;
    }

    return derivedState;
  }

  static calculateD3Geometry(nextProps, nextState?) {
    const zoom = get(nextProps, 'zoom') || get(nextState, 'scale');
    let scale;

    if (zoom > nextProps.scaleExtent.max) {
      scale = nextProps.scaleExtent.max;
    } else if (zoom < nextProps.scaleExtent.min) {
      scale = nextProps.scaleExtent.min;
    } else {
      scale = zoom;
    }

    return {
      translate: get(nextState, 'translate') || get(nextProps, 'translate'),
      scale
    };
  }

  componentDidMount() {
    this.bindZoomListener(this.props);
    this.internalState.initialRender = false;
  }

  componentDidUpdate(prevProps) {
    if (
      !deepEqual(this.props.translate, prevProps.translate) ||
      !deepEqual(this.props.scaleExtent, prevProps.scaleExtent) ||
      this.props.zoom !== prevProps.zoom
    ) {
      this.bindZoomListener(this.props);
    }
    if (typeof this.props.onUpdate === 'function') {
      this.props.onUpdate({
        node: this.internalState.targetNode ? clone(this.internalState.targetNode) : null,
        zoom: this.state.d3.scale,
        translate: this.state.d3.translate
      });
    }
    this.internalState.targetNode = null;
  }

  setInitialTreeDepth(nodeSet, initialDepth) {
    nodeSet.forEach(n => {
      n._collapsed = n.depth >= initialDepth;
    });
  }

  bindZoomListener(props) {
    const { scaleExtent, onUpdate } = props;
    const { svgClassName, gClassName } = this.props;
    const svg = select(`.${svgClassName}`);
    const g = select(`.${gClassName}`);

    svg.call(
      behavior
        .zoom()
        .scaleExtent([scaleExtent.min, scaleExtent.max])
        .on('zoom', () => {
          if (event.translate[0] !== 0 && event.translate[1] !== 0) {
            g.attr('transform', `translate(${event.translate}) scale(${event.scale})`);
          }
          if (typeof onUpdate === 'function') {
            onUpdate({
              node: null,
              zoom: event.scale,
              translate: { x: event.translate[0], y: event.translate[1] }
            });
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.d3.scale = event.scale;
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.d3.translate = {
              x: event.translate[0],
              y: event.translate[1]
            };
          }
        })
        .scale(this.state.d3.scale)
        .translate([this.state.d3.translate.x, this.state.d3.translate.y])
    );
  }

  render() {
    const { nodes, links, svgClassName, gClassName } = this.props;
    const { nodeSize, styles } = this.props;
    if (!links) {
      return <div />;
    }

    return (
      <div className='graph' ref={treeRef => this.props.setContainerRef(treeRef)}>
        <svg className={svgClassName} width='100%' height='100%'>
          <g className={gClassName}>
            {links.map(link => (
              <Link key={uuidv4()} orientation='horizontal' linkData={link} styles={styles.links} />
            ))}
            {nodes.map(nodeData => (
              <Node
                key={uuidv4()}
                nodeSize={nodeSize}
                nodeData={nodeData}
                onNodeClick={this.props.nodeClick}
                onExpandClick={this.props.expandClick}
                composite={this.props.composite}
                detection={this.props.detection}
              />
            ))}
          </g>
        </svg>
      </div>
    );
  }
}

export default Graph;
