import React from 'react';

import { TooltipConstrained, TooltipConstrainedProps } from 'snap-ui/Tooltip';

type CopyButtonProps = Omit<TooltipConstrainedProps, 'title'> & {
  onError?: () => void;
  onSuccess?: () => void;
  value: string;
  messageTime?: number;
  tooltip?: string;
  copyTooltip?: string;
  children: React.ReactNode;
  tooltipWidth?: 'none' | number;
};

/**
 * Reusable copy to clipboard
 *
 * Children requires forward-able ref `src > > snap-ui > tooltip`
 *
 * TODO Better if we auto wrap children here to forward ref. Please do
 * this when removing the original CopyButton.
 */
const CopyButton = React.forwardRef<HTMLDivElement, CopyButtonProps>(function CopyButton(
  { onError, onSuccess, value, messageTime, tooltip, copyTooltip, children, tooltipWidth = 300, ...otherTooltip },
  ref
) {
  const textAreaRef = React.useRef<HTMLTextAreaElement>();
  const debugTextAreaRef = React.useRef<HTMLTextAreaElement>();
  const [title, setTitle] = React.useState(tooltip);
  const [open, setOpen] = React.useState(false);

  let debugValue = value;
  try {
    debugValue = JSON.stringify(value);
  } catch (e) {
    console.debug('error parsing debug value', e);
  }

  return (
    <>
      <TooltipConstrained
        title={title}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        arrow
        max={tooltipWidth}
        {...otherTooltip}
      >
        {React.cloneElement(children, { onClick: handleCopy, ref })}
      </TooltipConstrained>
      <textarea
        ref={textAreaRef}
        readOnly={true}
        style={{
          height: '1px',
          width: '1px',
          position: 'fixed',
          left: '-10000px'
        }}
        value={value || ''}
        data-testid='copy-button-text-area'
      />
      <textarea
        ref={debugTextAreaRef}
        readOnly={true}
        style={{
          height: '1px',
          width: '1px',
          position: 'fixed',
          left: '-10000px'
        }}
        value={debugValue || ''}
        data-testid='debug-copy-button-text-area'
      />
    </>
  );

  function handleOpen(): void {
    if (tooltip) {
      setTitle(tooltip);
      setOpen(true);
    }
  }

  function handleClose(): void {
    setOpen(false);
  }

  function emitError(): void {
    if (onError) onError();
  }

  function handleCopy(e: React.MouseEvent<HTMLButtonElement>): void {
    const ref = e.shiftKey ? debugTextAreaRef : textAreaRef;
    if (!ref.current) {
      emitError();
      return;
    }
    ref.current.select();
    document.execCommand('copy');
    if (onSuccess) onSuccess();
    setTimeout(() => {
      setTitle(copyTooltip || 'Copied!');
      setOpen(true);
    });
    setTimeout(() => {
      setOpen(false);
    }, messageTime || 1000);
  }
});

export default CopyButton;
