import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { canI } from 'module/Can';
import { LevelMenuBadge } from 'module/Widgets/LevelMenuBadge/LevelMenuBadge';

import { useAuth } from 'provider';
import { usePushSnack } from 'provider/Snack';

import { Status, useAsync } from 'storage';

import { ContentPermission } from 'types/auth';
import { ArtifactScore } from 'types/common';

import { getPreferredOrgScore } from 'utilities/ArtifactUtils';

import { postAttackScriptSeverity } from '../AttackScript.api';
import { useAttackScriptCatalog } from '../AttackScriptProvider';

export default function Severity(): JSX.Element {
  const { defaultOrgId } = useAuth();
  const { attackScript, supplemental, setSeverity } = useAttackScriptCatalog();
  const pushSnack = usePushSnack();

  const { task, status, error } = useAsync();

  const updateSeverity = React.useCallback(
    async (severity: ArtifactScore) => {
      await task(postAttackScriptSeverity(attackScript.guid, { severity, organization_id: defaultOrgId }));
      return setSeverity(severity);
    },
    [task, attackScript.guid, defaultOrgId, setSeverity]
  );

  React.useEffect(() => {
    if (!isEmpty(error)) pushSnack('Unable to update Severity. Try again later.', 'warning', 'center', 'bottom', 5000);
  }, [error, pushSnack]);

  const isPending = Status.pending === status;
  const permitted = canI(ContentPermission.Edit, attackScript);

  const severity = getPreferredOrgScore(defaultOrgId, supplemental?.severities, attackScript?.severity);

  if (!attackScript.guid) return null;
  return (
    <div className='AttackScriptViewHeader-severity'>
      <LevelMenuBadge
        value={severity}
        variant='Severity'
        permitted={permitted}
        loading={isPending}
        handleLevelChange={updateSeverity}
      />
    </div>
  );
}
