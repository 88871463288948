import React from 'react';

import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';

import Chip from 'snap-ui/Chip';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import { useTheme } from 'snap-ui/util';

import Path from 'constants/paths';

import { useAuth } from 'provider';

import { ArtifactType, Visibility } from 'types/common';
import { CompositeFilter } from 'types/filter';
import { FeedRouteGen, RouteGen } from 'types/route';

import { getLocation } from 'utilities/RouteGenUtils';

export type FeaturedQuery = {
  title: string;
  subscription?: boolean;
  disabled?: boolean;
  count: number;
  payload: CompositeFilter;
};

type Props = {
  item: FeaturedQuery;
  type: ArtifactType.Analytic | ArtifactType.Session;
};

const traverse = function (o: CompositeFilter, fn, scope = []) {
  for (const i in o) {
    fn.apply(this, [i, o[i], scope]);
    if (o[i] !== null && typeof o[i] === 'object') {
      traverse(o[i], fn, scope.concat(i));
    }
  }
};

export default function Feature({ type, item }: Props) {
  const { isSubscriber } = useAuth();
  const isDisabled = item?.disabled && !isSubscriber;
  const isSubscription = item?.subscription && !isSubscriber;
  const { palette } = useTheme();
  const style = { color: palette.grey[600], cursor: 'default' };

  return (
    <div className='link' key={item.title}>
      <Link to={isDisabled ? '#' : getRouteGen(item, type)} style={isDisabled ? style : null}>
        {isDisabled || isSubscription ? (
          <Tooltip
            title={
              item?.disabled
                ? 'This content is restricted to subscribers'
                : 'Additional content is available to subscribers'
            }
            placement='left'
            arrow
          >
            <span>
              <Icon icon={faLock} style={{ marginRight: '8px' }} /> {item.title}
            </span>
          </Tooltip>
        ) : (
          item.title
        )}
      </Link>
      <Chip
        size='small'
        className={type === ArtifactType.Analytic ? 'blue' : 'red'}
        label={(item?.count ?? 0).toString()}
        color={type === ArtifactType.Analytic ? 'info' : 'error'}
      />
    </div>
  );
}

function getRouteGen(item: FeaturedQuery, type: ArtifactType): RouteGen {
  if (!item) return null;

  const location: FeedRouteGen = {
    pathname: Path.Feed,
    search: { topic: type, visibility: [Visibility[Visibility.Published]] } as any
  };

  // This is REALLY hacky to get something out the door
  // We should come up with a function to parse a CompoundFilter and generate a URL, and vice versa
  // https://gist.github.com/sphvn/dcdf9d683458f879f593
  // ASSUMES that 'field' always comes before 'value' in the object
  // Does not support all filters, only the ones used on the dashboard

  let currentKey = '';

  traverse(item.payload, (key, value) => {
    if (key === 'field') {
      currentKey = value;
    }
    if (key === 'value') {
      switch (currentKey) {
        case 'text':
          location.search.query = value;
          break;
        case 'organization_id':
          location.search.organizations = value;
          break;
        case 'bas.product_name':
          location.search.basProduct = value;
          break;
      }
    }
  });

  return getLocation(location);
}
