import React from 'react';

import { faEnvelopeOpenText } from '@fortawesome/pro-light-svg-icons';

import Icon from 'snap-ui/Icon';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      ubroutemenu: any;
      ubdiv: any;
      ubspan: any;
    }
  }
}

export type Props = {
  close(): void;
  sendUserInfoToHubspot(): void;
  showDemo: boolean;
};

export default function CustomUserbackWidget({ close, sendUserInfoToHubspot, showDemo }: Props) {
  const [showDemoConfirm, setShowDemoConfirm] = React.useState(false);

  function handleRequestDemo() {
    sendUserInfoToHubspot();
    setShowDemoConfirm(true);
    close();
  }

  return !showDemoConfirm ? (
    <div id='userback-custom-menu-options'>
      {showDemo && (
        <ubroutemenu className='userback-feedback-type-form' onClick={handleRequestDemo}>
          <ubdiv>
            <Icon icon={faEnvelopeOpenText} />
          </ubdiv>
          <ubdiv>Request a Demo</ubdiv>
          <ubdiv>See our enterprise features and integrations in action</ubdiv>
        </ubroutemenu>
      )}
    </div>
  ) : (
    <ubdiv className='userback-controls-send-success'>
      <style
        dangerouslySetInnerHTML={{
          __html: ':root {--widget-outro-icon: #d08ded !important; }'
        }}
      />
      <ubdiv data-icon='1'>
        <svg className='ub-checkmark' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
          <circle className='ub-checkmark__circle' cx='26' cy='26' r='25' fill='none'></circle>
          <path className='ub-checkmark__check' fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8'></path>
        </svg>
        <ubspan>Thank you!</ubspan>
        <p>Thanks for reaching out! A member of our sales team will follow up with you to schedule a demo.</p>
      </ubdiv>
    </ubdiv>
  );
}
