import React from 'react';

import Alert, { AlertTitle } from 'snap-ui/Alert';
import Button from 'snap-ui/Button';
import Divider from 'snap-ui/Divider';
import Stack from 'snap-ui/Stack';
import { styled } from 'snap-ui/util';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(3)};
  .divide-banner {
    border-width: 1px;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
`;
const MESSAGE = {
  userMessage: `Your organization has configured default filters, shown below. You can continue to use them or add
  further customizations.`,
  userAction: 'Use Organization Defaults',
  orgMessage: `This organization is configured to default filters, shown below. You can continue to use them or add
  further customizations.`,
  orgAction: 'Reset to SnapAttack Defaults'
};

function PreferenceMessage({
  editOrg,
  onSave,
  onDelete
}: {
  editOrg: boolean;
  onSave: () => void;
  onDelete: () => void;
}) {
  return (
    <Container>
      Default Filters
      <Divider className='divide-banner' />
      <Alert severity='info'>
        <AlertTitle>{editOrg ? MESSAGE.orgMessage : MESSAGE.userMessage}</AlertTitle>
        <Stack direction='row' spacing={2}>
          <Button variant='outlined' onClick={() => onDelete()}>
            {editOrg ? MESSAGE.orgAction : MESSAGE.userAction}
          </Button>
          {!editOrg && (
            <Button variant='outlined' onClick={() => onSave()}>
              Reset to SnapAttack Defaults
            </Button>
          )}
        </Stack>
      </Alert>
      <Divider className='divide-banner' />
    </Container>
  );
}

export default PreferenceMessage;
