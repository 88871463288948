import React from 'react';

import zod from 'zod';

import { Option } from 'snap-ui/Autocomplete';

import { countries, FEATURED_COUNTRIES } from 'constants/countries';

import { useAuth } from 'provider';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';

import { sortByPrioritizedListOn } from 'utilities/ArrayUtils';

import { AutocompleteOptionContent } from '../GlobalFilter.style';
import { FilterConfig } from '../GlobalFilter.type';
import InclusiveAutocomplete from './InclusiveAutocomplete';

type ActorTargetRegionsKeys = {
  targetRegion: string[];
  targetRegionOp: Ops;
};

type ActorTargetRegionFilterProps = {
  onChange(values: Partial<ActorTargetRegionsKeys>): void;
  values: ActorTargetRegionsKeys;
};

const options = countries
  .slice()
  .sort(sortByPrioritizedListOn('alpha3', FEATURED_COUNTRIES))
  .map(country => ({
    label: country.name,
    value: country?.alpha3,
    content: (
      <AutocompleteOptionContent>
        {country?.flag}
        {country.name}
      </AutocompleteOptionContent>
    )
  }));

function ActorTargetRegionFilter({ onChange, values }: ActorTargetRegionFilterProps): React.ReactElement {
  const { isSubscriber } = useAuth();
  const handleValueChange = (option: Option[]) => {
    const payload = { targetRegion: option.map(o => o.value) };
    if (option.length === 0) payload['targetRegionOp'] = undefined;
    onChange(payload);
  };

  const handleOperatorChange = (targetRegionOp: Ops) => {
    onChange({ targetRegionOp });
  };

  return (
    <InclusiveAutocomplete
      title='By Actor Target Region'
      name='actor_target_region_dropdown'
      option={options}
      value={values.targetRegion}
      onValueChange={handleValueChange}
      onOperatorChange={handleOperatorChange}
      operator={values.targetRegionOp}
      disableInclusionGroup
      disabled={!isSubscriber}
      disabledTooltip='This filter is only available to subscribers'
      tooltip='Filter by threat actor target countries as observed by Mandiant'
    />
  );
}
function toQuery(value: ActorTargetRegionsKeys) {
  if (!value.targetRegion?.length) return;
  return {
    field: 'target_regions',
    op: Ops.any,
    value: value.targetRegion
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('target_regions'),
    op: zod.literal(Ops.any),
    value: zod.array(zod.string())
  })
  .transform(query => ({
    targetRegion: query.value,
    targetRegionOp: query.op
  }));

const ActorTargetRegionFilterConfig: FilterConfig<ActorTargetRegionsKeys> = {
  defaults: { default: () => ({ targetRegion: [], targetRegionOp: Ops.any }) },
  supportedTopics: [ArtifactType.Collection],
  component: ActorTargetRegionFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default ActorTargetRegionFilterConfig;
