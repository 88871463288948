import React from 'react';

import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';

import { Option } from 'snap-ui/Autocomplete';
import Icon from 'snap-ui/Icon';
import { ExternalLink } from 'snap-ui/Link';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { useAiFeatures } from 'hooks/useAiFeatures';

import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import MarkdownFormik from 'module/Form/MarkdownFormik';
import MultivalueTextfieldFormik from 'module/Form/MultivalueTextfieldFormik';
import { ScrapeIOCUploadFieldFormik } from 'module/Form/ScrapeIOCUploadFieldFormik';
import TextFieldFormik from 'module/Form/TextFieldFormik';
import { IOC } from 'module/IOC/IOC.type';
import IOCListFormik from 'module/IOC/IOCParser/IOCListFormik';
import { Form, TagDivider } from 'module/Layout/Artifact.widgets';
import { Discriminator, TagAutocompleteFormik } from 'module/Tag';

import { EditIntelForm } from '../IntelEdit/IntelEdit';

// matching style of helper text
const IntelUploadHelperText = styled('p')`
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.75rem;
  line-height: 1.66;
  margin: ${p => p.theme.spacing(1, 0, 0, '14px')};
`;

const Label = styled('span')`
  margin-right: ${p => p.theme.spacing(2)};
`;

type IntelFormProps = {
  orgOptions: Option[];
  iocPayload: IOC[];
  setIocPayload: React.Dispatch<React.SetStateAction<IOC[]>>;
  setIsActing(acting: boolean);
};

function IntelForm({ orgOptions, iocPayload, setIocPayload, setIsActing }: IntelFormProps): React.ReactElement {
  const { canUseAiFeatures } = useAiFeatures();
  const { values } = useFormikContext<EditIntelForm>();

  return (
    <Form>
      <TextFieldFormik label='Owner' name='owner' disabled />
      <AutocompleteFormik label='Organization' name='organization_id' options={orgOptions} disableUserAdditions />
      <TextFieldFormik label='Title' name='title' required multiline />
      <TextFieldFormik
        label='Original Author'
        name='original_author'
        helperText='The original author of the blog, article, or artifact describing this intelligence'
      />
      <div>
        <label htmlFor='attachment'>Attachments</label>
        <ScrapeIOCUploadFieldFormik scrapeMax={1} setIocPayload={setIocPayload} setIsActing={setIsActing} />
        <IntelUploadHelperText>
          <label>Uploaded PDFs will be scraped for IOCs.</label>
        </IntelUploadHelperText>
      </div>
      <MarkdownFormik
        label={
          <>
            <Label>Description</Label>
            {!canUseAiFeatures && (
              <Tooltip
                title='Threat intelligence summaries are created with generative AI, which has been disabled by your organization administrator.'
                wrap
                placement='top'
                arrow
              >
                <Icon.Info />
              </Tooltip>
            )}
          </>
        }
        name='description'
        required
      />
      {isEmpty(iocPayload) ? (
        <MultivalueTextfieldFormik label='IOCs' name='indicators' />
      ) : (
        <div>
          <label htmlFor='indicators'>IOCs</label>
          <IOCListFormik iocOptions={iocPayload} name='indicators' />
        </div>
      )}
      {values.source && (
        <TextFieldFormik
          label='Source Reference'
          name='source'
          disabled
          readOnly
          InputProps={{
            endAdornment: (
              <ExternalLink href={values.source}>
                <Icon icon={faExternalLink} />
              </ExternalLink>
            )
          }}
        />
      )}
      <AutocompleteFormik label='References' name='reference' multiple options={[]} />
      <TagDivider />
      <TagAutocompleteFormik name='attack_names' discriminator={Discriminator.Attack} multiple />
      <TagAutocompleteFormik name='actor_names' discriminator={Discriminator.Actor} multiple />
      <TagAutocompleteFormik name='software_names' discriminator={Discriminator.Software} multiple />
      <TagAutocompleteFormik name='vulnerability_names' discriminator={Discriminator.Vulnerability} multiple />
      {values.image && <TextFieldFormik label='Image URL' name='image' disabled readOnly />}
      {(values.image || values.large_image) && (
        <TextFieldFormik label='Banner Image URL' name='large_image' disabled readOnly />
      )}
    </Form>
  );
}

export default IntelForm;
