import { SigmaModifier, SpecialSigmaModifier } from 'types/analytic';

export enum Condition {
  _ = 7,
  AND,
  OR,
  NOT,
  LPAREN,
  RPAREN
}

export type Comparison = SigmaModifier | SpecialSigmaModifier.Equal;

export enum Modifier {
  /**
   * Single search field
   */
  exact = 37,

  /**
   * Multiple search field condition
   */
  any,

  /**
   * Multiple search field condition
   */
  all,

  /**
   * Full text search
   */
  full
}
