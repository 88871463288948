import React from 'react';

import { CancelToken } from 'apis';

import { partialNameMatch } from 'module/Feed/Feed.api';
import { NameMatchResponse } from 'module/Feed/Feed.type';

import { Status, useAsync } from 'storage';

import { ArtifactType } from 'types/common';

export default function usePartialNameMatch(): {
  cancel(): void;
  data: NameMatchResponse;
  search(query: string, types?: ArtifactType[]): void;
  status: Status;
  reset(): void;
} {
  const { data, run, status, reset } = useAsync<NameMatchResponse>([]);
  const cancelTokenSourceRef = React.useRef(CancelToken.source());

  const search = React.useCallback(
    (query: string, types?: ArtifactType[]) => {
      const source = CancelToken.source();
      cancelTokenSourceRef.current = source;
      run(partialNameMatch(query, types));
    },
    [run]
  );

  return {
    cancel: cancelTokenSourceRef.current.cancel,
    data,
    search,
    status,
    reset
  };
}
