import { AxiosError } from 'axios';
import { History } from 'history';

import { snapattack } from 'apis/snapattack';

import { UNAUTHENTICATED_ROUTES } from 'constants/auth';
import { Path } from 'constants/paths';

import { Catalog } from 'storage';

import history from '../history';

export default function createInterceptors(fetchUserCallback) {
  snapattack.interceptors.response.use(
    // intercept successful response
    response => {
      // update user (specifically user.has_active_session) after
      // any action that could affect active session
      // see interceptors.spec for expected matching routes
      const url = response.config.url.replace(response.config.baseURL, '');
      if (
        ['post', 'put', 'delete'].includes(response.config.method) &&
        /^\/session\/(active\/|\d+(\/publish)?)?$/.test(url)
      ) {
        fetchUserCallback();
      }
      return response;
    },
    // intercept error response
    (error: AxiosError) => {
      if (error?.response?.status === 401 && !isLoginAttempt(error) && !isUnauthedPage(history)) {
        if (!window?.sessionStorage.getItem(Catalog.attempted_url)) {
          window?.sessionStorage.setItem(
            Catalog.attempted_url,
            `${window?.location.pathname}${window?.location.search}`
          );
        }
        history.push(Path.Login);
      }
      return Promise.reject(error);
    }
  );
}

function isLoginAttempt(error: AxiosError): boolean {
  const url = error?.response?.config?.url;
  return !!url && url.includes('/login/');
}

function isUnauthedPage(history: History): boolean {
  return UNAUTHENTICATED_ROUTES.some(path => history.location.pathname.includes(path));
}
