import React from 'react';

import { faCaretDown, faWarning } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';

import Button from 'snap-ui/Button';
import Icon from 'snap-ui/Icon/Icon';
import { MenuTrigger } from 'snap-ui/Menu';
import Tooltip from 'snap-ui/Tooltip';

import { useAuth } from 'provider/Account';

import { NOOP } from 'utilities/FunctionUtils';

import { ThreatProfileTag, TagWeight, useThreatProfile, getTagWeightLabel } from '.';
import { StyledMenuItem } from './ThreatProfile.style';

export default function TagPriority({ tag, readonly }: { tag: ThreatProfileTag; readonly?: boolean }): JSX.Element {
  const { updateTagWeight } = useThreatProfile();
  const { isSubscriber } = useAuth();

  if (readonly)
    return (
      <Tooltip
        arrow
        title={
          isSubscriber
            ? 'Contact an Organization Administrator to make changes.'
            : 'Threat Profiles are only available to subscribers.'
        }
        wrap
      >
        <Button
          className={classnames('TagPriority ThreatProfile-button disabled', tag.score_label)}
          variant='outlined'
          color='inherit'
          onClick={NOOP}
        >
          <Icon icon={faWarning} /> {getTagWeightLabel(tag.score_label)}
        </Button>
      </Tooltip>
    );

  return (
    <MenuTrigger
      className={classnames('TagPriority', tag.score_label)}
      trigger={
        <Button variant='outlined' color='inherit' endIcon={<Icon icon={faCaretDown} />}>
          <Icon icon={faWarning} /> {getTagWeightLabel(tag.score_label)}
        </Button>
      }
    >
      {Object.values(TagWeight).map(priority => (
        <StyledMenuItem
          key={priority}
          className={priority}
          onClick={() => {
            updateTagWeight({ tag: tag.name, score: priority });
          }}
          disabled={tag.score_label === priority}
          icon={faWarning}
          text={`${getTagWeightLabel(priority)}`}
        />
      ))}
    </MenuTrigger>
  );
}
