import { IconMenuItem } from 'snap-ui/MenuItem';
import { css, styled } from 'snap-ui/util';

import { TagWeight } from 'module/ThreatProfile';
import { SimpleBadge } from 'module/Widgets/Badge';

const tagStyleString = p => css`
  :not(.disabled) {
    &.${TagWeight.Highest}, &.${TagWeight.Highest} svg {
      color: ${p.theme.palette.error.main};
    }
    &.${TagWeight.High}, &.${TagWeight.High} svg {
      color: ${p.theme.palette.orange.main};
    }
    &.${TagWeight.Medium}, &.${TagWeight.Medium} svg {
      color: ${p.theme.palette.warning.main};
    }
    &.${TagWeight.Low}, &.${TagWeight.Low} svg {
      color: ${p.theme.palette.success.main};
    }
    &.${TagWeight.Lowest}, &.${TagWeight.Lowest} svg {
      color: ${p.theme.palette.info.main};
    }
    &.${TagWeight.Ignored}, &.${TagWeight.Ignored} svg {
      color: ${p.theme.palette.grey[500]};
    }
  }
`;

export const AddToProfileContainer = styled('div', { label: 'AddToProfileContainer' })`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(3)};

  .ThreatProfile-button,
  .TagPriority {
    display: flex;
    justify-content: center;
    gap: ${p => p.theme.spacing(3)};
    width: 190px;
  }

  .TagPriority {
    padding-right: ${p => p.theme.spacing(7)}; // accounts for caret
    .MuiButton-endIcon {
      position: absolute;
      right: ${p => p.theme.spacing(3)};
    }

    ${p => tagStyleString(p)}
  }

  .ThreatProfile-button.disabled {
    pointer-events: none;
    color: ${p => p.theme.palette.secondary.main};
  }
`;

export const StyledMenuItem = styled(IconMenuItem, { label: 'WeightMenuItem' })`
  ${p => tagStyleString(p)}
`;

export const CardBadgeContainer = styled('div', { label: 'CardBadgeProfileContainer' })`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(3)};

  .weight-icon {
    ${p => tagStyleString(p)}
  }
`;

export const ThreatPriorityBadge = styled(SimpleBadge)`
  text-transform: uppercase;
  min-width: 144px;

  ${p => tagStyleString(p)}
`;

export const JustificationContainer = styled('ul', { label: 'JustificationContainer' })`
  margin: ${p => p.theme.spacing(1)};
  padding-left: ${p => p.theme.spacing(5)};
`;
