import { DashboardStats } from './DetectionDashboard.type';

export const LETTER_GRADES = {
  0: 'F',
  1: 'F',
  2: 'F',
  3: 'D-',
  4: 'D',
  5: 'D+',
  6: 'C-',
  7: 'C',
  8: 'C+',
  9: 'B-',
  10: 'B',
  11: 'B+',
  12: 'A-',
  13: 'A',
  14: 'A+'
} as const;

export type LETTER_GRADES = (typeof LETTER_GRADES)[keyof typeof LETTER_GRADES];

export const EMPTY_DASHBOARD_STATS: DashboardStats = {
  overallPosture: {
    letterGrade: 'F-',
    percentageGrade: 0
  },
  postures: [{ value: 0 }],
  deployed: {
    startCount: 0,
    stopCount: 0,
    difference: 0,
    increasePercent: 0,
    raw: []
  },
  available: {
    startCount: 0,
    stopCount: 0,
    difference: 0,
    increasePercent: 0,
    raw: []
  },
  attackCoverage: {
    initial: 0,
    change: 0,
    children: 0
  },
  techniques: []
};

export const DEMO_DETECTION_STATS: DashboardStats = {
  overallPosture: {
    letterGrade: 'B+',
    percentageGrade: 86
  },
  postures: [{ value: 79 }, { value: 88 }, { value: 77 }, { value: 85 }, { value: 79 }, { value: 86 }],
  deployed: {
    startCount: 157,
    stopCount: 198,
    difference: 41,
    increasePercent: 26,
    raw: []
  },
  available: {
    startCount: 3478,
    stopCount: 3692,
    difference: 214,
    increasePercent: 6,
    raw: []
  },
  attackCoverage: {
    initial: 47,
    change: 19,
    children: 150
  },
  techniques: [
    {
      name: 'Reconnaissance',
      coverageAvailable: 7,
      coverageStart: 2,
      coverageIncrease: 1,
      coverageDecrease: 0,
      children: 10,
      uncovered: 0
    },
    {
      name: 'Resource Development',
      coverageAvailable: 6,
      coverageStart: 1,
      coverageIncrease: 0,
      coverageDecrease: 0,
      children: 7,
      uncovered: 0
    },
    {
      name: 'Initial Access',
      coverageAvailable: 3,
      coverageStart: 4,
      coverageIncrease: 2,
      coverageDecrease: 0,
      children: 9,
      uncovered: 0
    },
    {
      name: 'Execution',
      coverageAvailable: 5,
      coverageStart: 4,
      coverageIncrease: 3,
      coverageDecrease: 0,
      children: 13,
      uncovered: 1
    },
    {
      name: 'Persistence',
      coverageAvailable: 5,
      coverageStart: 9,
      coverageIncrease: 5,
      coverageDecrease: 0,
      children: 19,
      uncovered: 0
    },
    {
      name: 'Privilege Escalation',
      coverageAvailable: 5,
      coverageStart: 4,
      coverageIncrease: 4,
      coverageDecrease: 0,
      children: 13,
      uncovered: 0
    },
    {
      name: 'Defense Evasion',
      coverageAvailable: 22,
      coverageStart: 13,
      coverageIncrease: 7,
      coverageDecrease: 0,
      children: 42,
      uncovered: 0
    },
    {
      name: 'Credential Access',
      coverageAvailable: 6,
      coverageStart: 8,
      coverageIncrease: 3,
      coverageDecrease: 0,
      children: 17,
      uncovered: 0
    },
    {
      name: 'Discovery',
      coverageAvailable: 19,
      coverageStart: 7,
      coverageIncrease: 4,
      coverageDecrease: 0,
      children: 30,
      uncovered: 0
    },
    {
      name: 'Lateral Movement',
      coverageAvailable: 3,
      coverageStart: 4,
      coverageIncrease: 2,
      coverageDecrease: 0,
      children: 9,
      uncovered: 1
    },
    {
      name: 'Collection',
      coverageAvailable: 9,
      coverageStart: 5,
      coverageIncrease: 3,
      coverageDecrease: 0,
      children: 17,
      uncovered: 0
    },
    {
      name: 'Command and Control',
      coverageAvailable: 6,
      coverageStart: 7,
      coverageIncrease: 3,
      coverageDecrease: 0,
      children: 16,
      uncovered: 0
    },
    {
      name: 'Exfiltration',
      coverageAvailable: 3,
      coverageStart: 5,
      coverageIncrease: 1,
      coverageDecrease: 0,
      children: 9,
      uncovered: 1
    },
    {
      name: 'Impact',
      coverageAvailable: 5,
      coverageStart: 5,
      coverageIncrease: 3,
      coverageDecrease: 0,
      children: 13,
      uncovered: 0
    }
  ]
};
