import React from 'react';

import classNames from 'classnames';

import { ArtifactType } from 'types/common';

import Panel, { Props as PanelProps } from '../Panel';
import Feature, { FeaturedQuery } from './Feature';

export type FeatureLink = {
  count: number;
  title: string;
  to: object;
};

export type Props = Partial<PanelProps> & {
  title: string;
  type: ArtifactType.Analytic | ArtifactType.Session;
  className?: 'red' | 'blue' | string;
  featured: Array<FeaturedQuery>;
};

function FeaturedPanel({ title, type, className, featured, ...extraProps }: Props) {
  return (
    <Panel
      {...extraProps}
      className={classNames('pp-featured-panel', `${className}`)}
      content={
        <>
          {featured.map(i => (
            <Feature key={i.title} item={i} type={type} />
          ))}
        </>
      }
      title={title}
    />
  );
}

export default FeaturedPanel;
