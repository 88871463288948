import React from 'react';

import { Autocomplete } from 'snap-ui/Autocomplete';

import {
  CoverageTable,
  mitreIdCol,
  mitreNameCol,
  priorityCol,
  deployedCol,
  recommendedCol,
  coverageCol,
  breadthCol,
  depthCol
} from '../Table';
import { ATTACK_RECORD_TYPE_FILTER_OPTIONS } from '../const';
import { MitreCoverageInterface } from './useMitreCoverage';
import useRecordType from './useRecordType';

type MitreTableProps = MitreCoverageInterface;

export default function MitreTable(props: MitreTableProps): JSX.Element {
  const { isPending, isProfilePending, getProfile, getStats } = props;
  const [selectedRecordType, setSelectedRecordType] = useRecordType();

  return (
    <CoverageTable
      columns={[
        mitreIdCol,
        mitreNameCol,
        priorityCol(isProfilePending, getProfile),
        deployedCol(getStats),
        recommendedCol(getStats),
        coverageCol(getStats),
        breadthCol(getStats),
        depthCol(getStats)
      ]}
      isPending={isPending}
      isProfilePending={isProfilePending}
      rows={props[selectedRecordType]}
      ToolbarContent={
        <Autocomplete
          className='TagTypeFilter'
          disableClearable
          label='Type'
          name='type_filter'
          options={ATTACK_RECORD_TYPE_FILTER_OPTIONS}
          onChange={setSelectedRecordType}
          value={selectedRecordType}
        />
      }
    />
  );
}
