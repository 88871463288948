import React from 'react';

import { useField } from 'formik';

import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import TextFieldFormik from 'module/Form/TextFieldFormik';
import { ScoreBadge } from 'module/Widgets/Badge';

import { ArtifactScore } from 'types/common';

const Container = styled('div')`
  display: grid;
  grid-template-columns: 15% 15% 65%;
  gap: ${p => p.theme.spacing(4)};
  align-items: center;
  .message {
    margin: 0;
    font-size: 0.8125rem;
  }
`;

function BulkConfidenceThresholdInput({
  level,
  priorThreshold
}: {
  level: ArtifactScore;
  priorThreshold: number;
  isDisable?: boolean;
}) {
  const [field] = useField(level);
  const isDisabled = level === ArtifactScore.LOWEST;
  const suffix = ' detection hits on average per week';
  let message = '';

  if (level === ArtifactScore.HIGHEST) {
    if (field.value === 0) {
      message = '0' + suffix;
    } else {
      message = '0-' + field.value + suffix;
    }
  } else if (level === ArtifactScore.LOWEST) {
    message = priorThreshold + '+' + suffix;
  } else {
    message = `${priorThreshold + 1}-${field.value}${suffix}`;
  }

  return (
    <Container>
      <Typography className='levelName' variant='h4'>
        <ScoreBadge name='CONFIDENCE' score={level} />
      </Typography>
      <TextFieldFormik
        type='number'
        label='Max Number'
        InputProps={{ inputProps: { min: 0 } }}
        name={level}
        value={isDisabled ? priorThreshold : field.value}
        disabled={isDisabled}
        required
      />
      <p className='message'>{message}</p>
    </Container>
  );
}

export default BulkConfidenceThresholdInput;
