import { LogRecord } from 'types/ide';

import { FilteredKeys, Guid } from '../../types/common';
import { BlueMarker, RedMarker } from '../../types/marker';

/**
 * Creates a derived type with just the properties containing arrays.
 * Those keys are then required to have `number` values.
 *
 * @template T the object to create the base type from
 *
 * @example
 * ArrayCount<{ foo: string[], bar: string }> // { foo: number }
 */
type ArrayCount<T> = {
  [K in FilteredKeys<T, unknown[]>]: number;
};

export interface BlueMarkerReference {
  analytic_compilation_id: number;
  row_id: string;
}

export interface RedMarkerReference {
  id: number;
}

export interface Detection {
  analytics: [Guid];
  blue_collisions: BlueCollision[];
  red_collisions: RedCollision[];
  detected: RedMarkerReference[];
  detection_gaps: RedMarkerReference[];
  validated_gaps: BlueMarkerReference[];
  undetected: RedMarkerReference[];
  validated: BlueMarkerReference[];
  unvalidated: BlueMarkerReference[];
  unique_analytics: number;
}

/** The count of every array in {@link Detection} */
export type DetectionCounts = ArrayCount<Detection>;

/** {@link DetectionCounts} with the unique counts */
export interface LogsSummary extends DetectionCounts {
  unique_analytics: number;
}

export interface RedCollision extends RedMarkerReference, Collision<BlueMarker> {}
export interface BlueCollision extends BlueMarkerReference, Collision<RedMarker> {}

interface Collision<T> {
  hits: T[];
  gaps: T[];
}

export enum DetectionStatsResources {
  analytic = 'signature',
  session = 'session',
  // Temporary fix since ArtifactType has been remapped to new names
  detection = 'signature',
  threat = 'session'
}

export type DetectionSummary = {
  unvalidated: number;
  validated: number;
  validated_gaps: number;
  untested?: number; // comes with analytic data
  undetected?: number; // comes with session data
  unique_analytics?: number; // comes with session data
};

/**
 * A dictionary of key/value i.g. guid/DetectionSummary
 */
export type DetectionSummaryCatalog = {
  [index: string]: DetectionSummary;
};

export type LogPreviewResult = { count: number; preview: LogRecord[] };
