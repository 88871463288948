import React from 'react';

import Button from 'snap-ui/Button';

import { useIntegrationCatalog } from 'provider';

import { Status } from 'storage';

function SaveButton() {
  const { taskStatus } = useIntegrationCatalog();
  return (
    <div className='actions'>
      <Button type='submit' disabled={taskStatus === Status.pending}>
        Save
      </Button>
    </div>
  );
}

export default SaveButton;
