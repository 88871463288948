import { CancelTokenSource } from 'axios';

import { snapattack } from 'apis/snapattack';

import { CollectionDiscriminator } from 'module/Collection/Collection.type';
import { artifactToEndpointFragment } from 'module/Landing/Landing.util';

import { Guid, Tracked } from 'types/common';

import {
  ExtractIOCResponse,
  IOC,
  IOCArtifact,
  IOCBase,
  IndicatorSearchResponse,
  TranslateIOCResponse
} from './IOC.type';

const prefix = '/indicators';

export function createIOC(payload: IOC[]): Promise<Tracked[]> {
  return snapattack.post(`${prefix}/`, payload).then(r => r.data);
}

export function extractIndicatorsFromText(data: string): Promise<ExtractIOCResponse> {
  return snapattack.post(`${prefix}/extract/extract/`, { data }).then(r => r.data);
}

export function extractIndicatorsFromURL(url: string): Promise<ExtractIOCResponse> {
  return snapattack
    .post(`${prefix}/extract/extract/url/`, null, {
      params: {
        input: url
      }
    })
    .then(r => r.data);
}

export function extractIndicatorsFromFile(file: File): Promise<ExtractIOCResponse> {
  const fd = new FormData();

  fd.append('input', file);

  return snapattack
    .post(`${prefix}/extract/extract/file/`, fd, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(r => r.data);
}

export async function translateIOC(
  integrationGuid: Guid,
  indicators: IOCBase[],
  cancelTokenSource?: CancelTokenSource
): Promise<TranslateIOCResponse> {
  try {
    const translations = await snapattack
      .post(
        `${prefix}/extract/translate/`,
        { integration: integrationGuid, indicators },
        {
          cancelToken: cancelTokenSource && cancelTokenSource.token
        }
      )
      .then(r => ({
        translations: r.data
      }));
    return translations;
  } catch (e) {
    return {
      translations: [],
      canceled: !!e.__CANCEL__
    };
  }
}

export function getIndicators(name: string, type: CollectionDiscriminator): Promise<IOCArtifact[]> {
  const path = artifactToEndpointFragment(type);
  return snapattack.get(`/tags/${path}/${name}/landing/indicators/`).then(r => r.data);
}

export function searchIndicator(query: string): Promise<IndicatorSearchResponse[]> {
  return snapattack.get(`/search/indicator/${query}/`).then(r => r.data);
}
