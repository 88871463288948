import React from 'react';

import { faLinux, faApple, faWindows } from '@fortawesome/free-brands-svg-icons';
import { faPowerOff } from '@fortawesome/pro-solid-svg-icons';
import get from 'lodash/get';

import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { BASAgent } from 'types/bas';

import { BASCampaignFeedAgent, OSName, isBASCampaignFeedAgent } from './BAS.type';

export type AgentLabelProps = {
  agent: BASAgent | BASCampaignFeedAgent;
};

const LabelContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  .agent-label-content {
    overflow: hidden;
  }

  .agent-label-name {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .agent-label-description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const OS_NAME_ICON_MAP = {
  [OSName.Linux]: faLinux,
  [OSName.macOS]: faApple,
  [OSName.Windows]: faWindows
};

export function getOSDisplayName(osName: OSName): keyof typeof OSName {
  return Object.entries(OSName).find(([, value]) => value === osName)?.[0] as keyof typeof OSName;
}

export default function AgentLabel(props: AgentLabelProps): JSX.Element {
  let name: string, description: string;
  if (isBASCampaignFeedAgent(props.agent)) {
    name = props.agent.host_name;
  } else {
    if (props.agent?.name) name = props.agent.name;
    else name = get(props.agent, 'host_metadata.host_name', 'New Unnamed Agent');

    description = props.agent?.name
      ? props.agent?.description || props.agent?.host_metadata?.host_name
      : props.agent?.description;
  }

  return (
    <LabelContainer className='AgentLabel'>
      <Tooltip arrow placement='top' title={props.agent?.is_online ? 'Online' : 'Offline'} wrap>
        <Icon
          aria-label={props.agent?.is_online ? 'Online' : 'Offline'}
          color={props.agent?.is_online ? 'success' : 'error'}
          icon={faPowerOff}
        />
      </Tooltip>
      <Tooltip arrow placement='top' title={getOSDisplayName(props.agent?.os_name)} wrap>
        <Icon aria-label={props.agent?.os_name} icon={OS_NAME_ICON_MAP[props.agent?.os_name]} />
      </Tooltip>
      <div className='agent-label-content'>
        <div className='agent-label-name'>{name}</div>
        {!!description && <div className='agent-label-description'>{description}</div>}
      </div>
    </LabelContainer>
  );
}
