import React, { useRef } from 'react';

import { default as classnames } from 'classnames';

import { LinkButton } from 'snap-ui/Button';
import { styled } from 'snap-ui/util';

import { StrictReactNode } from 'types/core';

const Wrapper = styled('div')`
  position: relative;

  &.show-buttons {
    padding-bottom: ${p => p.theme.spacing(6)};
  }
`;

const Container = styled('div', { shouldForwardProp: p => p !== 'rows' })<any & { rows: number }>`
  --rows: ${p => p.rows ?? 5};

  display: flex;
  gap: ${p => p.theme.spacing(3)};
  flex-wrap: wrap;
  max-height: calc(var(--rows) * 44px - 12px);
  overflow: hidden;
  margin-bottom: ${p => p.theme.spacing(3)};

  &.show-all {
    max-height: unset !important;
  }

  .toggle-button {
    position: absolute;
    bottom: 0;
    left: ${p => p.theme.spacing(3)};
    justify-content: flex-start;
  }
`;

type Props = {
  children: StrictReactNode;
  className?: string;
  rows?: number;
};

const TruncateContainer = React.forwardRef<any, Props>(function TruncateContainer(
  { children, className = '', rows },
  ref
) {
  const [showAllTags, setShowAllTags] = React.useState<boolean>(false);
  const [showButtons, setShowButtons] = React.useState(false);
  const truncateRef = useRef(null);

  function calcShouldShowButtons() {
    if (truncateRef.current) {
      setShowButtons(truncateRef.current.clientHeight < truncateRef.current.scrollHeight);
    }
  }

  React.useEffect(() => {
    // Run on page load
    if (truncateRef.current) {
      setShowButtons(truncateRef.current.clientHeight < truncateRef.current.scrollHeight);
    }

    // Run on page resize
    window.addEventListener('resize', calcShouldShowButtons, false);
    return () => {
      window.removeEventListener('resize', calcShouldShowButtons);
    };
  }, []);

  return (
    <Wrapper
      className={classnames('TruncateContainer', { 'show-buttons': showAllTags || showButtons }, className)}
      ref={ref}
    >
      <Container className={classnames({ 'show-all': showAllTags })} ref={truncateRef} rows={rows}>
        {children}
        {(showAllTags || showButtons) && (
          <LinkButton className='toggle-button' onClick={() => setShowAllTags(showAllTags => !showAllTags)}>
            {showAllTags ? 'Show less' : 'Show all '}
          </LinkButton>
        )}
      </Container>
    </Wrapper>
  );
});

export default TruncateContainer;
