import React from 'react';

import { updateCurrentUser, useAuth, useAuthInterface } from 'provider';

import { PreferredOrgIcon } from './PreferredOrg.helper';

export type PreferredOrgProps = {
  active: boolean;
  className?: string;
  options: { preferred: boolean; isolated: boolean; value: string; content: string | JSX.Element }[];
  onSubmit(values: { preferred: string }): void;
  preferred: string;
  error: string;
};

type Props = {
  className?: string;
  Component: React.ElementType<PreferredOrgProps>;
};

export default function PreferredOrgController({ className, Component }: Props) {
  const [active, setActive] = React.useState(false);
  const { fetch } = useAuthInterface();
  const { user, preferable: orgs } = useAuth();
  const preferred = user.preferred_organization?.id.toString() || '';
  const [error, setError] = React.useState<string>();

  const preferredListed = orgs.some(org => org.id.toString() === preferred);

  const options = React.useMemo(() => {
    return orgs.map(o => ({
      preferred: o.id.toString() === preferred,
      isolated: o.isolated,
      value: o.id.toString(),
      content: o.id.toString() === preferred ? <PreferredOrgIcon>{o.name}</PreferredOrgIcon> : o.name
    }));
  }, [orgs, preferred]);

  return (
    <Component
      active={active}
      className={className}
      options={options}
      onSubmit={handlePreferred}
      error={error}
      preferred={preferredListed ? preferred : undefined}
    />
  );

  async function handlePreferred(values: { preferred: string }) {
    setActive(true);
    setError(null);
    const result = await updateCurrentUser({ preferred_organization_id: values.preferred });

    if ('detail' in result) {
      setError(result.detail);
      setActive(false);
    } else fetch();
  }
}
