import React from 'react';

import { useLocation } from 'react-router-dom';

import Path from 'constants/paths';

import settingItems from 'module/Setting/Setting.helper';

import { useAuth, useManagedOrganizations } from 'provider';

import { Stashed } from './Scaffold.type';
import ListItemLink from './core/ListItemLink';
import ListItemLinkIcon from './core/ListItemLinkIcon';

export default function useSettingsStash(): Stashed[] {
  const { pathname } = useLocation();
  const { isSubscriber, user, permission: userOrganizations } = useAuth();
  const { organizations: managedOrganizations } = useManagedOrganizations();

  return React.useMemo(() => {
    if (!pathname.includes(Path.Setting)) return [];
    const allowed = settingItems.filter(item =>
      item?.getAllowed ? item.getAllowed(user, userOrganizations, managedOrganizations) : true
    );
    return allowed.map<Stashed>(item => {
      let disabled = false;
      let disabledTooltip;
      if (item.subscriber && !isSubscriber) {
        disabled = true;
        disabledTooltip = `${item.name} is only available to subscribers`;
      }

      return {
        id: item.path,
        closedContent: (
          <ListItemLinkIcon
            disabled={disabled}
            icon={item.icon}
            tooltip={disabledTooltip || item.name}
            to={`${Path.Setting}/${item.path}`}
          />
        ),
        openedContent: (
          <ListItemLink
            disabled={disabled}
            icon={item.icon}
            title={item.name}
            tooltip={disabledTooltip}
            to={`${Path.Setting}/${item.path}`}
          />
        )
      };
    });
  }, [isSubscriber, managedOrganizations, pathname, userOrganizations, user]);
}
