import React from 'react';

import startCase from 'lodash/startCase';

import { FormControl, FormControlLabel, FormHelperText } from 'snap-ui/FormControl';
import FormLabel from 'snap-ui/FormLabel';
import Radio from 'snap-ui/Radio';
import RadioGroup from 'snap-ui/RadioGroup';

import {
  BulkActionForm,
  InlineEditorForm,
  JsonSchema,
  useInlineEditor,
  withJsonFormsControlProps
} from 'module/JsonView';

import { BooleanString } from 'types/common';

import { SINGLE_VALUE_BULK_OPTIONS } from '../Metadata.const';
import { isMetaEmpty, reform } from '../Metadata.util';
import { useBulkEditor } from '../MetadataBulkEditInterface';
import { Control, ControlProps } from './Control.type';
import { isControlApplied } from './Control.util';

const VALUE_ANY = 'any';
const FLAG_OPTIONS = [BooleanString.False, BooleanString.True] as const;
const FILTER_FLAG_OPTIONS = [VALUE_ANY, ...FLAG_OPTIONS] as const;

const _Base = (props: {
  description: string;
  disabled?: boolean;
  label: string;
  options: readonly string[];
  path: string;
  onChange: (value: string) => void;
  value: string;
}) => (
  <FormControl className={`Control Control-${Control.Flag}`} disabled={props.disabled}>
    <FormLabel id={`${props.path}-radio-buttons-group-label`}>{props.label}</FormLabel>
    <RadioGroup
      value={props.value}
      row
      aria-labelledby={`${props.path}-radio-buttons-group-label`}
      name={`${props.path}-radio-buttons-group`}
      onChange={e => props.onChange(e.target.value)}
    >
      <>
        {props.options.map(o => (
          <FormControlLabel key={o} value={o} control={<Radio />} label={startCase(o)} />
        ))}
      </>
    </RadioGroup>
    <FormHelperText>{props.description}</FormHelperText>
  </FormControl>
);

function InlineFlag(props: { data: string; enabled: boolean; path: string; schema: JsonSchema; type: string }) {
  const { data, enabled, path, schema, type } = props;
  const editor = useInlineEditor(data);
  return (
    <InlineEditorForm data={data} editor={editor} enabled={enabled} path={path} title={schema.title} type={type}>
      <_Base
        description={schema.description}
        label={schema.title}
        options={FLAG_OPTIONS}
        path={props.path}
        onChange={value => editor.setQueue(value)}
        value={editor.queue || ''}
      />
    </InlineEditorForm>
  );
}
function BulkActionFlag(props: { data: string; path: string; schema: JsonSchema; type: string }) {
  const { path, schema } = props;
  const editor = useBulkEditor(path);
  return (
    <BulkActionForm actions={SINGLE_VALUE_BULK_OPTIONS} editor={editor} title={schema.title}>
      <_Base
        description={schema.description}
        label='Value'
        options={FLAG_OPTIONS}
        path={props.path}
        onChange={value => editor.set(editor.operation, value)}
        value={(editor.value as string) || ''}
      />
    </BulkActionForm>
  );
}

const _FlagControl = ({ config, data, enabled, handleChange, path, schema }: ControlProps) => {
  const flag = reform(data) as string;
  if (!isControlApplied(schema, config) || (config.truncated && isMetaEmpty(flag))) return null;
  if (config.asBulk) return <BulkActionFlag data={flag} path={path} schema={schema} type={config.type} />;
  if (config.asInline)
    return <InlineFlag data={flag} enabled={enabled} path={path} schema={schema} type={config.type} />;

  return (
    <_Base
      description={schema.description}
      disabled={!enabled}
      label={schema.title}
      options={config.asFilter ? FILTER_FLAG_OPTIONS : FLAG_OPTIONS}
      path={path}
      onChange={value => handleChange(path, value === VALUE_ANY ? null : value)}
      value={flag || VALUE_ANY}
    />
  );
};
const FlagControl = withJsonFormsControlProps(_FlagControl);

export default FlagControl;
