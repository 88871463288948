import React from 'react';

import { getSigmaBackends } from 'apis/resources/analytic';

import { useAuth } from 'provider';

import { Status, useAsync } from 'storage';

import { SigmaBackend, SigmaEngine } from 'types/analytic';

type SigmaBackendState = {
  legacy: readonly SigmaBackend[];
  legacyStatus: Status;
  pysigma: readonly SigmaBackend[];
  pysigmaStatus: Status;
};

const SigmaBackendContext = React.createContext<SigmaBackendState>(null);
SigmaBackendContext.displayName = 'SigmaBackendContext';

function useSigmaBackendCatalog(): SigmaBackendState {
  const context = React.useContext(SigmaBackendContext);

  if (!context) {
    throw new Error('useSigmaBackendCatalog must be used within the SigmaBackendContext');
  }

  return context;
}

function SigmaBackendProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const { user } = useAuth();
  const { data: legacy, run: legacyRun, status: legacyStatus } = useAsync<SigmaBackend[]>([]);
  const { data: pysigma, run: pysigmaRun, status: pysigmaStatus } = useAsync<SigmaBackend[]>([]);

  React.useEffect(() => {
    if (user.id) {
      legacyRun(getSigmaBackends(SigmaEngine.Legacy));
      pysigmaRun(getSigmaBackends(SigmaEngine.PySigma));
    }
  }, [legacyRun, pysigmaRun, user.id]);

  return (
    <SigmaBackendContext.Provider value={{ legacy, legacyStatus, pysigma, pysigmaStatus }}>
      {children}
    </SigmaBackendContext.Provider>
  );
}

export { SigmaBackendProvider, useSigmaBackendCatalog };
