import React from 'react';

import { faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';

import Icon from 'snap-ui/Icon';
import { ExternalLink } from 'snap-ui/Link';
import Tabs from 'snap-ui/Tabs';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { useAuth } from 'provider';

import { checkTaskPermission } from 'services/authService';

import { FunctionalPermission } from 'types/auth';

type SessionTabsProps = {
  exportComponent?: React.ReactElement;
  graphUrl?: string;
  keystrokesComponent?: React.ReactElement;
  keystrokesLabel?: React.ReactNode;
  jobListComponent?: React.ReactElement;
  jobListLabel?: React.ReactNode;
  timelineComponent: React.ReactElement;
  timelineLabel: React.ReactNode;
  splunkUrl?: string;
};

const Container = styled('div')`
  .tabs {
    margin: 0;
    background-color: ${p => p.theme.palette.background.paper};
    border-width: 1px 0;
    border-style: solid;
    border-color: ${p => p.theme.palette.grey[700]};

    .MuiTabs-flexContainer .MuiTab-root {
      text-transform: none;
      color: ${p => p.theme.palette.common.white};
      font-weight: normal;
      line-height: 1;
      font-size: 1rem;
      opacity: 1;

      border-width: 1px;
      border-top: 0;
      border-bottom: 2px solid transparent;

      flex-direction: row;
      gap: ${p => p.theme.spacing(1)};
    }
  }

  .Tab-auxiliary {
    display: flex;
    align-items: center;
    align-self: stretch;
    height: auto;
    border-right: 1px solid ${p => p.theme.palette.grey[700]};

    margin: 0;

    .MuiTab-root:last-of-type {
      border-right-width: 2px;
    }

    svg {
      height: 0.8rem;
    }

    a {
      text-decoration: none;
      padding: ${p => p.theme.spacing(3)};
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ${p => p.theme.spacing(1)};
    }

    a:hover:not([disabled]) {
      background-color: ${p => p.theme.palette.action.hover};
      background-image: ${p =>
        `linear-gradient(${p.theme.palette.action.selected}, ${p.theme.palette.action.selected}) `};
    }

    a[disabled] {
      color: ${p => p.theme.palette.secondary.dark};
    }
  }
`;

function SessionTabs(props: SessionTabsProps): React.ReactElement {
  const { permission } = useAuth();
  const canSplunk = checkTaskPermission(permission, FunctionalPermission.SplunkUIAccess);

  const panes = [];

  panes.push({
    value: 'analytics',
    label: props.timelineLabel,
    content: props.timelineComponent,
    className: 'analytics',
    component: 'a'
  });

  if (props.jobListComponent && props.jobListLabel) {
    panes.push({
      value: 'bas',
      content: props.jobListComponent,
      label: props.jobListLabel
    });
  }

  panes.push({
    value: 'keystrokes',
    content: props.keystrokesComponent,
    label: props.keystrokesLabel
  });

  return (
    <Container className='SessionTabs'>
      <Tabs
        className='tabs'
        tabs={panes}
        auxiliary={
          <>
            {props.exportComponent}
            <Tooltip title={props.graphUrl ? '' : 'No process graph for attacker machine'} placement='top' arrow wrap>
              <ExternalLink href={props.graphUrl} disabled={!props.graphUrl}>
                Process Graph <Icon icon={faUpRightFromSquare} />
              </ExternalLink>
            </Tooltip>
            <Tooltip
              title={props.splunkUrl ? '' : 'Event logs are only accessible to contributors'}
              placement='top'
              arrow
              wrap
            >
              <ExternalLink href={props.splunkUrl} disabled={!props.splunkUrl || !canSplunk}>
                Event Logs <Icon icon={faUpRightFromSquare} />
              </ExternalLink>
            </Tooltip>
          </>
        }
      />
    </Container>
  );
}

export default SessionTabs;
