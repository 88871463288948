import { getCollection, postList, postShare, putCollection, saveCollection } from 'module/Collection/Collection.api';
import { CollectionDiscriminator, CollectionRequest } from 'module/Collection/Collection.type';

import { ContentPermission, Entity } from 'types/auth';
import { ArtifactType, Guid, Ident } from 'types/common';
import { CompositeFilter, Ops, Query } from 'types/filter';

function getQueryKeyValue(topic: ArtifactType, query: Query) {
  switch (topic) {
    case ArtifactType.Analytic:
      return {
        analytic_filter: query
      };
    case ArtifactType.Intel:
      return {
        threat_intelligence_filter: query
      };
    case ArtifactType.Session:
      return {
        session_filter: query
      };
    case ArtifactType.AttackScript:
      return {
        bas_script_filter: query
      };
    case ArtifactType.Collection:
      return {
        collection_filter: query
      };
  }
}

function getCollectionPayload(entity: Entity, description: string, topic: ArtifactType, query: Query) {
  const topicQuery = getQueryKeyValue(topic, query);

  const payload: Partial<CollectionRequest> = {
    organization_id: entity.id,
    name: entity.name,
    description,
    type: CollectionDiscriminator.Dynamic,
    ...topicQuery
  };

  return payload;
}

export function saveNewFilterCollection(entity: Entity, description: string, topic: ArtifactType, query: Query) {
  return saveCollection(getCollectionPayload(entity, description, topic, query));
}

export function updateSavedFilter(entity: Entity, description: string, topic: ArtifactType, query: Query) {
  return putCollection(entity.guid, getCollectionPayload(entity, description, topic, query));
}

export function getFilterCollection(guid: Guid) {
  return getCollection(guid);
}

export function getCollectionList(type?: 'Dynamic' | 'Static', query?: Query, size?: number) {
  let data: Query = {
    op: Ops.equals,
    field: 'type',
    value: type || 'Dynamic'
  };
  if (query) {
    data = {
      op: Ops.and,
      items: [data, query]
    };
  }
  const config = {
    params: {
      page: 0,
      size: size ?? 50
    }
  };
  return postList(data, config);
}

/**
 * Returns a new query adding 'not deployed' filter to items list
 */
export function addDeployedFilterToQuery(defaultOrgId: Ident, query: Query): Query {
  const cf = query as CompositeFilter;
  return {
    ...cf,
    items: [
      ...cf.items,
      {
        op: Ops.or,
        items: [
          {
            field: 'deployment_filter.org_ids',
            op: Ops.not,
            value: [defaultOrgId],
            case_sensitive: true
          },
          {
            field: 'deployment_filter.org_ids',
            op: Ops.equals,
            value: null,
            case_sensitive: true
          }
        ]
      }
    ]
  };
}

/**
 * Warning: this is an overwrite feature for now
 */
export function shareCollection(collection: Guid, group: Guid) {
  return postShare(collection, {
    share_items: true,
    permissions: [
      {
        guid: group,
        permission: ContentPermission.Read
      }
    ]
  });
}
