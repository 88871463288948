import { MetadataSchema } from 'module/Metadata/Metadata.type';

import { Organization, OrganizationTargetPayload, User } from 'types/auth';
import { Guid } from 'types/common';

import { snapattack } from '../snapattack';

const prefix = '/org';

export function getManagedOrgs(): Promise<Organization[]> {
  return snapattack.get(`${prefix}/managed_orgs/`).then(r => r.data);
}

export function postOrg(key: OrganizationTargetPayload): Promise<OrganizationTargetPayload> {
  return snapattack.post(`${prefix}/`, key).then(r => r.data);
}

export function putOrg(organization: Guid, payload: Partial<OrganizationTargetPayload>): Promise<void> {
  return snapattack.put(`${prefix}/${organization}/`, payload);
}

export function getOrgMetadata(oid: Guid): Promise<MetadataSchema> {
  return snapattack.get(`${prefix}/${oid}/preferences/metadata/`).then(r => r.data?.definition);
}

export function postOrgMetadata(oid: Guid, payload: Partial<MetadataSchema>): Promise<void> {
  return snapattack.post(`${prefix}/${oid}/preferences/metadata/`, payload).then(r => r.data);
}

/**
 * SuperAdmin only route
 */
export function getAllOrgs(): Promise<Organization[]> {
  return snapattack.get(`${prefix}/`).then(r => r.data);
}

export function getUsersForOrg(organization: Guid): Promise<User[]> {
  return snapattack.get(`${prefix}/${organization}/users/`).then(r => r.data);
}
