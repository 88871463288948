import React from 'react';

import Paper from 'snap-ui/Paper';
import { styled } from 'snap-ui/util';

import ArtifactScoreSliderFormik from 'module/Form/ArtifactScoreSliderFormik';

import ThreatProfileStep from '../ThreatProfileStep';

const Container = styled(Paper, { name: 'SliderContainer' })`
  padding: 75px;
`;

export default function ConfidenceThresholds(): JSX.Element {
  return (
    <ThreatProfileStep
      title='What are your confidence thresholds?'
      description="Detection confidence levels quantify the certainty of identifying relevant signals within a system's noise, with a focus on minimizing false positives. Striking the right balance between sensitivity and specificity is crucial to ensure accurate threat recognition while minimizing the disruptive impact of erroneous alerts."
      content={
        <Container>
          <ArtifactScoreSliderFormik type='CONFIDENCE' name='confidence_thresholds' />
        </Container>
      }
    />
  );
}
