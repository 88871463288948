import React from 'react';

import classNames from 'classnames';

import { SimpleTabs, Tab, TabProps } from 'snap-ui/Tabs';
import { styled } from 'snap-ui/util';

import { usePage } from 'hooks/useQueryString';

import { useFilterRegistry } from 'module/GlobalFilter';

import { ArtifactType } from 'types/common';
import { StrictReactNode } from 'types/core';

const Container = styled('div', { name: 'Collection' })`
  position: relative;

  .Collection-content {
    margin-bottom: ${p => p.theme.spacing(4)};
  }
`;

type Props = {
  className?: string;
  children?: StrictReactNode;
  onTabChange?(topic: ArtifactType): void;
  tabs: TabProps['tabs'];
  tabsSubtitle?: StrictReactNode;
};

export default function Collection(props: Props) {
  const { resetPage } = usePage(false);
  const { topic, update } = useFilterRegistry(ArtifactType.Session, resetPage, false);

  function changeTopic(newTopic: ArtifactType): void {
    if (newTopic === topic) return;
    update({ topic: newTopic });
  }

  const handleTabChange = (_event: React.SyntheticEvent<Element, Event>, value: any) => {
    props.onTabChange && props.onTabChange(value);
    changeTopic(value);
  };

  return (
    <Container className={classNames('Collection', props.className)}>
      <div className='Collection-wrapper'>
        <SimpleTabs value={topic} className='Collection-tabs' onChange={handleTabChange}>
          {props.tabs.map(tab => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              disabled={tab.disabled}
              component={tab.component}
            />
          ))}
        </SimpleTabs>
        <div className='Collection-content'>
          {props.tabsSubtitle}
          {props.tabs.find(t => t.value === topic)?.content}
          {props.children}
        </div>
      </div>
    </Container>
  );
}
