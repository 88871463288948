import React from 'react';

import SaveButton from './SaveButton';
import SearchFields from './SearchFields';

function SearchTab({ languageOptionLists }: { languageOptionLists }) {
  return (
    <>
      <SearchFields languageOptionLists={languageOptionLists} />
      <SaveButton />
    </>
  );
}

export default SearchTab;
