import React, { ReactElement } from 'react';

import Divider from 'snap-ui/Divider';
import Typography from 'snap-ui/Typography';

import { ScriptsAsyncInterface } from 'module/BAS/Launcher/useScripts';
import { Session } from 'module/Session/Session.type';

import { useAuth } from 'provider/Account';

import { Status } from 'storage';

import { AnalyticRecommendation } from 'types/analytic';
import { ArtifactType } from 'types/common';

import { RecommendedHeader } from '../Session.style';
import AttackScriptList from './AttackScriptList';
import RecommendedDetectionList from './RecommendedDetectionList';
import { SessionRecommendationsContainer } from './SessionRecommendations.style';
import TrainingScore from './TrainingScore';

type Props = {
  session: Session;
  preview: boolean;
  recommendations?: AnalyticRecommendation[];
  scripts: ScriptsAsyncInterface;
  status?: Status;
  isRedMarkerCreated?: boolean;
};

export default function SessionRecommendations(props: Props): ReactElement {
  const {
    session: { guid }
  } = props;
  const { user, isTraining } = useAuth();

  // Training mode does not have recommended detections; instead, we'll show the user if they solved their challenge or not
  if (isTraining) {
    return (
      <SessionRecommendationsContainer>
        <TrainingScore
          guid={user.preferred_organization.guid}
          user_name={user.name}
          session_name={props.session.name}
        />
      </SessionRecommendationsContainer>
    );
  } else {
    return (
      <SessionRecommendationsContainer>
        <RecommendedHeader>
          <Typography variant='h3'>Recommendations</Typography>
        </RecommendedHeader>
        <RecommendedDetectionList {...props} />
        <Divider />
        <AttackScriptList guid={guid} type={ArtifactType.Session} {...props} />
      </SessionRecommendationsContainer>
    );
  }
}
