import React from 'react';

import { AutocompleteRenderInputParams } from 'snap-ui/Autocomplete/AutocompleteV2';
import { GridRenderEditCellParams, useGridApiContext } from 'snap-ui/DataGrid';
import Icon from 'snap-ui/Icon';
import TextField from 'snap-ui/TextField';

import { Discriminator } from 'module/Tag';

import { AutocompleteCell, AutocompleteLi } from './Blacklist.style';
import { SigmaTagOption } from './Blacklist.type';
import { useSearchAllTags } from './useSearchAllTags';

const USE_ONLY_NAMES_NOT_ALIASES = true;
type Option = { label: string; value: string };
function excludeDuplicates(value: Option, index: number, self: Option[]) {
  return index === self.findIndex(t => t.label === value.label);
}
function insensitiveSort(a: Option, b: Option) {
  return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
}

export function SearchTagEditCell(
  props: GridRenderEditCellParams & { onChange(valid: boolean, sigmaTags: string[]): void }
) {
  // eslint-disable-next-line react/prop-types
  const { field, id, onChange, value } = props;
  const apiRef = useGridApiContext();
  const { data, search } = useSearchAllTags(USE_ONLY_NAMES_NOT_ALIASES);

  const options = React.useMemo(
    () =>
      data
        .map<SigmaTagOption>(d => ({
          ...d,
          label: d.name,
          value: d.name
        }))
        .filter(excludeDuplicates)
        .sort(insensitiveSort),
    [data]
  );

  const handleInputChange = (_e: React.SyntheticEvent, value: string) => {
    search(value);
    // First void the alias then the named valued otherwise its buggy
    apiRef.current.setEditCellValue({ id, field: 'alias', value: null });
    apiRef.current.setEditCellValue({ id, field, value });
    // Then we use timeout to sync scroll since the autocomplete and the grid cell don't play nice together.
    // Without this, if you add a row then cancel it. Then add another and make a selection, the row scrolls out of view.
    setTimeout(() => {
      apiRef.current.scrollToIndexes({ rowIndex: apiRef.current.getRowIndexRelativeToVisibleRows(id), colIndex: 0 });
    }, 0);
    const selection = options.find(e => e.value === value);
    onChange(!!selection, selection?.sigma_names);
  };

  const handleChange = (_event: React.SyntheticEvent, value: SigmaTagOption): void =>
    // eslint-disable-next-line react/prop-types
    onChange(!!value, value?.sigma_names);

  React.useEffect(() => {
    search('%');
  }, [search]);

  return (
    <AutocompleteCell
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          autoFocus
          {...params}
          variant='outlined'
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === 'Escape') e.stopPropagation();
          }}
        />
      )}
      renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: SigmaTagOption) => {
        return (
          <AutocompleteLi {...props}>
            <TagIcon discriminator={option.discriminator} />
            {option.label}
          </AutocompleteLi>
        );
      }}
      options={options}
      value={value || null}
      onInputChange={handleInputChange}
      onChange={handleChange}
      isOptionEqualToValue={(o: Option, v: string) => o.value === v}
    />
  );
}

function TagIcon({ discriminator }: { discriminator: Discriminator }) {
  switch (discriminator) {
    case Discriminator.Actor:
      return <Icon.Actor width={16} color='grey' />;
    case Discriminator.Attack:
      return <Icon.Attack width={16} color='grey' />;
    case Discriminator.Software:
      return <Icon.Software width={16} color='grey' />;
    case Discriminator.Vulnerability:
      return <Icon.Vulnerability width={16} color='grey' />;
    default:
      return null;
  }
}
