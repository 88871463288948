import React from 'react';

import classnames from 'classnames';

import BackdropLoader, { BackdropLoaderContainer } from 'snap-ui/BackdropLoader';

import { StyledVideo } from 'module/Session/VideoPlayer/VideoPlayer.style';

type VideoProps = {
  isLoading: boolean;
  onLoadedData: (value: boolean) => void;
};

const Video = React.forwardRef<HTMLVideoElement, VideoProps>(({ onLoadedData, isLoading }: VideoProps, ref) => {
  return (
    <BackdropLoaderContainer>
      <BackdropLoader title='Video Loading' open={isLoading} />
      <StyledVideo
        ref={ref}
        className={classnames({ fullwidth: isLoading })}
        preload='auto'
        controls={false}
        onLoadedData={() => onLoadedData(false)}
      >
        Your browser does not support the video tag.
      </StyledVideo>
    </BackdropLoaderContainer>
  );
});

Video.displayName = 'Video';

export default Video;
