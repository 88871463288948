import React from 'react';

import { useAuth } from 'provider';

import { Status, useAsync } from 'storage';

import { getProfile, postProfile } from './ThreatProfileWizard.api';
import { ThreatProfilePayload, ThreatProfileResponse } from './ThreatProfileWizard.type';

type ThreatProfileWizardInterface = {
  data: ThreatProfileResponse;
  noProfileFound: boolean;
  status: Status;
  refresh(): void;
  incrementalUpdate(update: Partial<ThreatProfilePayload>): Promise<void>;
};

function _useThreatProfileWizard(): ThreatProfileWizardInterface {
  const { user } = useAuth();
  const orgGuid = user.preferred_organization.guid;

  const { data, setData, error, run, task, status } = useAsync<ThreatProfileResponse>();

  const refresh = React.useCallback(() => {
    run(getProfile(orgGuid));
  }, [orgGuid, run]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  const incrementalUpdate = React.useCallback(
    (update: Partial<ThreatProfilePayload>) => {
      return task(postProfile(orgGuid, update).then(updated => setData(updated)));
    },
    [orgGuid, setData, task]
  );

  return {
    data,
    noProfileFound: error?.response?.status === 404,
    status,
    refresh,
    incrementalUpdate
  };
}

const ThreatProfileWizardProviderContext = React.createContext<ThreatProfileWizardInterface>(null);

export function ThreatProfileWizardProvider({ children }: React.PropsWithChildren<Record<never, never>>) {
  const value = _useThreatProfileWizard();

  return (
    <ThreatProfileWizardProviderContext.Provider value={value}>{children}</ThreatProfileWizardProviderContext.Provider>
  );
}

export default function useThreatProfileWizard() {
  const context = React.useContext(ThreatProfileWizardProviderContext);
  if (!context) {
    throw new Error('useThreatProfileWizard must be used within a ThreatProfileWizardProviderContext');
  }
  return context;
}
