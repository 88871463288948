import { useCallback, useEffect, useMemo, useRef } from 'react';

import {
  getStats,
  Detection,
  DetectionCounts,
  LogsSummary,
  DetectionStatsResources as Resources
} from 'module/Detection';
import { DETECTION_SHELL } from 'module/Detection/Detection.const';

import { Status, useAsync } from 'storage';

import { Guid } from 'types/common';

/** Calculates the summary by counting every array  */
const calcLogsSummary = (d: Detection): LogsSummary => ({
  ...(Object.fromEntries(
    Object.entries(d) // get the length of all the array fields from the object, ignore the rest
      .filter(([, v]) => Array.isArray(v))
      .map(([k, v]: [keyof LogsSummary, Detection[keyof DetectionCounts]]) => [k, v.length])
  ) as DetectionCounts),
  unique_analytics: d.unique_analytics
});

export interface DetectionStatsState {
  update(detection: Detection): void;
  detection: Detection;
  summary: LogsSummary;
  status: Status;
  error: any;
  refresh(): void;
}

export default function useDetectionStats(resource: Resources, id: Guid, refreshInterval = 0): DetectionStatsState {
  const intervalRef = useRef<NodeJS.Timeout>();
  const { data: detection, run, status, setData, error } = useAsync<Detection>(DETECTION_SHELL);
  const summary = useMemo(() => calcLogsSummary(detection), [detection]);

  const refresh = useCallback(() => {
    if (id) run(getStats(resource, id));
    else setData(DETECTION_SHELL);
  }, [run, setData, resource, id]);

  useEffect(() => {
    if (status !== Status.pending) {
      refresh();
      // creates an interval if the refresh rate is valid
      intervalRef.current = refreshInterval > 0 ? setInterval(() => refresh(), refreshInterval) : null;
    }

    return () => intervalRef.current && clearInterval(intervalRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshInterval, refresh]);

  return {
    detection,
    summary,
    status,
    update: setData,
    error,
    refresh
  };
}
