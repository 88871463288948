import isPropValid from '@emotion/is-prop-valid';

import { Fab } from 'snap-ui/Button';
import { styled } from 'snap-ui/util';

import { ArtifactScore } from 'types/common';

export type PageProps = {
  matchHero?: boolean;
  maxWidth?: number;
};

export const Page = styled('div', {
  shouldForwardProp: isPropValid
})<PageProps>`
  padding: ${p => (p.matchHero ? 'var(--Scaffold-hero)' : '50px')};
  max-width: ${p => (p.maxWidth ? `${p.maxWidth}px` : 'none')};
`;

export const DashboardPage = styled(Page)`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(8)};

  h2 {
    margin-bottom: ${p => p.theme.spacing(2)};
  }
`;

export const LevelContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(1)};
  z-index: 1;
  .level {
    display: flex;
    height: 1.5rem;
    justify-content: flex-start;
    min-width: 104px;
    gap: ${p => p.theme.spacing(2)};
    align-items: center;
    border: 2px solid;
    padding: 0px 10px;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 5px;

    &.level-graph {
      height: 24px;
      min-width: 91px;
    }

    &.border-none {
      border: none;
    }

    &.no-width {
      min-width: auto;
    }
    &.graph-width {
      min-width: 101px;
      padding: ${p => p.theme.spacing(0)} ${p => p.theme.spacing(1)};
    }
    &.${ArtifactScore.HIGHEST} {
      color: ${p => p.theme.palette.error.main};
    }
    &.${ArtifactScore.HIGH} {
      color: ${p => p.theme.palette.orange.main};
    }
    &.${ArtifactScore.MEDIUM} {
      color: ${p => p.theme.palette.warning.main};
    }
    &.${ArtifactScore.LOW} {
      color: ${p => p.theme.palette.success.main};
    }
    &.${ArtifactScore.LOWEST} {
      color: ${p => p.theme.palette.info.main};
    }
    &.${ArtifactScore.UNKNOWN} {
      color: ${p => p.theme.palette.grey[500]};
    }

    svg {
      height: ${p => p.theme.palette.dimension.svg};
    }
  }

  .text {
    font-size: 1rem;
    text-align: left;
    line-height: 1rem;
  }

  .text-small {
    font-size: 0.6rem;
    text-align: left;
  }
`;

export const HamburgerFab = styled(Fab)`
  font-size: 1.25rem;
  background-color: ${p => p.theme.palette.secondary.light};
  color: ${p => p.theme.palette.secondary.contrastText};
  :hover {
    background-color: ${p => p.theme.palette.secondary.main};
    color: ${p => p.theme.palette.secondary.contrastText};
  }
`;

export const Flex = styled('div')`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(4)};
`;

/**
 * This is a replacement for processGraph.less .clickable class
 * Two other locations still use that but should be updated to use
 * this instead if possible
 */
export const Clickable = styled('button')`
  cursor: pointer;
  padding: 0;
  font-size: 0.875rem;
  background: none;
  border: none;
  text-decoration: none;
  color: ${p => p.theme.palette.primary.main};
  line-height: 24px;

  :hover {
    color: ${p => p.theme.palette.primary.dark};
  }
`;

export const AutocompleteIconOption = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${p => p.theme.spacing(2)};
`;
