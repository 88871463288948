import React from 'react';

import { ErrorProps, Status, useAsync } from 'storage';

import { Ident } from 'types/common';

import { postFpr, putFpr } from './Analytic.api';
import { FPRCreate } from './Analytic.type';

export type TuningBag = {
  saveAndApply(id: Ident, payload: FPRCreate): Promise<void>;
  status: Status;
  errorProps: ErrorProps;
};

export default function useFalsePositiveReduction(): TuningBag {
  const { errorProps, status, task } = useAsync();

  const saveAndApply = React.useCallback(
    (id: Ident, payload: FPRCreate) => {
      if (id) {
        return task(putFpr(id, payload));
      } else {
        return task(postFpr(payload));
      }
    },
    [task]
  );

  return {
    saveAndApply,
    status,
    errorProps
  };
}
