import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { getForest } from 'module/Tag/Tag.api';

import { Status, useAsync } from 'storage';

import { Sorter, sortForest } from './Matrix.service';
import { Forest } from './Matrix.type';
import useTactics from './Tactic/useTactics';

export default function useForest(): { forest: Forest; status: Status } {
  const { mitreIDs } = useTactics();
  const { data: forest, run, status } = useAsync<Forest>([]);

  React.useEffect(() => {
    if (!isEmpty(mitreIDs)) {
      run(getForest().then(r => sortForest(r, Sorter(mitreIDs))));
    }
  }, [run, mitreIDs]);

  return { forest, status };
}
