import React from 'react';

import { useFeed } from 'aso';
import isEmpty from 'lodash/isEmpty';

import { useRecommender } from 'aso/useRecommender';

import { buildCuratedFilter } from 'module/Curation/Curation.service';
import { CollectionRecommenderType } from 'module/Curation/Curation.type';
import { SortOrder } from 'module/Feed/Feed.type';

import { ArtifactType } from 'types/common';
import { CompositeFilter, Query } from 'types/filter';

export default function useAttackScriptRecommendedFilter(filter: Query): { deploymentFilter: CompositeFilter } {
  const pageParams = {
    page: 1,
    size: 100,
    sort_by: SortOrder.modified
  };

  const feed = useFeed(ArtifactType.Session, filter, pageParams);

  const { getRecommendation, data: recommendation } = useRecommender();

  const deploymentGuids = recommendation?.all_deployment_recommendations?.map(d => d.guid);

  React.useEffect(() => {
    const threatGuids = feed.items.map(item => item.guid);
    if (!isEmpty(threatGuids)) {
      getRecommendation(threatGuids, CollectionRecommenderType.Threat);
    }
  }, [getRecommendation, feed.items]);

  const deploymentFilter = deploymentGuids?.length > 0 ? buildCuratedFilter(deploymentGuids) : null;

  return { deploymentFilter };
}
