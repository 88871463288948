import React from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faChevronCircleLeft } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

import { IconButton } from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import { ListItem as MuiListItem } from 'snap-ui/List';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

type Props = {
  className?: string;
  icon: IconDefinition;
  id?: string;
  onClick?(): void;
  tooltip?: string;
};

const ListItem = styled(MuiListItem)`
  padding-left: 0;
  padding-right: 0;

  .MuiButtonBase-root:hover {
    background-color: ${p => p.theme.palette.surface.hover};
  }

  &.close-icon {
    justify-content: flex-end;
  }
`;

/**
 * For use with closed Drawer
 */
export default function ListItemButtonIcon({ className, icon, tooltip, ...others }: Props) {
  return (
    <Tooltip title={tooltip ? tooltip : ''} arrow placement='right'>
      <ListItem className={classNames(className, { 'close-icon': icon === faChevronCircleLeft })}>
        <IconButton className='ScaffoldItem ListItemButtonIcon' {...others}>
          <Icon icon={icon} />
        </IconButton>
      </ListItem>
    </Tooltip>
  );
}
