import React from 'react';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import CircularProgress from 'snap-ui/CircularProgress';
import { FormDialog } from 'snap-ui/Dialog';
import Divider from 'snap-ui/Divider';
import { styled } from 'snap-ui/util';

import SelectFormik from 'module/Form/SelectFormik';
import { style } from 'module/Setting/core/Core.style';

import { useAuth } from 'provider/Account';

import PreferredOrgController, { PreferredOrgProps } from './PreferredOrgController';

type Props = PreferredOrgProps & {
  className?: string;
};

function RequestPreferredOrg({ active, className, error, options, onSubmit, preferred }: Props) {
  const [currentSelection, setCurrentSelection] = React.useState<string>(preferred);

  return (
    <FormDialog
      className={className}
      DialogProps={{ open: true }}
      SubmitProps={{
        children: active ? (
          <span>
            <CircularProgress className='request-preferred' size={'1em'} /> Save
          </span>
        ) : (
          'Save'
        ),
        disabled: !currentSelection || active || options.some(o => o.value === currentSelection && o.preferred)
      }}
      FormikConfig={{
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: { preferred: preferred },
        onSubmit: onSubmit
      }}
      title='Preferred Organization Selection'
    >
      <div className='form-content-wrapper'>
        <Divider />
        <p>
          You belong to multiple organizations. Please select the organization that you would like set as your preferred
          organization. This will impact how the application functions and what content you can see. You can change it
          at any time on the settings page which is found under your profile.
        </p>
        <SelectFormik
          disabled={options.length <= 0}
          options={options}
          name='preferred'
          label='Select preferred organization'
          onChange={setCurrentSelection}
        />
        {options.some(org => org.value === currentSelection && org.isolated) && (
          <Alert severity='info'>
            <AlertTitle>Training Mode</AlertTitle>
            This organization is in training mode. When selected as your default organization, you will only see content
            that you created in this organization, or content that has been explicitly shared with this organization for
            demonstration or training purposes. You must select another organization to view community and subscription
            content.
          </Alert>
        )}
        {error && (
          <Alert severity='error'>
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        )}
      </div>
    </FormDialog>
  );
}

const StyledPreferredOrg = styled(RequestPreferredOrg)<Props>(() => ({
  ...style,
  maxWidth: '100%',
  '& .form-content-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  }
}));

export default function RequestPreferredOrgWrapper({ className }: { className?: string }) {
  const { user } = useAuth();
  if (user.preferred_organization) return null;
  return <PreferredOrgController className={className} Component={StyledPreferredOrg} />;
}
