import React from 'react';

import zod from 'zod';

import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Slider from 'snap-ui/Slider';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { FilterControl } from 'module/GlobalFilter';

import { useAuth } from 'provider';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';

import { FilterConfig } from '../GlobalFilter.type';
import { fromQueryNoop } from '../GlobalFilter.util';

type IndicatorScoreKeys = {
  indicatorScore: string;
};

type IndicatorScoreFilterProps = {
  onChange(values: Partial<IndicatorScoreKeys>): void;
  values: IndicatorScoreKeys;
};

const SliderContainer = styled('div')`
  margin-top: ${p => p.theme.spacing(3)};
  padding-right: ${p => p.theme.spacing(4)};
`;

export const MINIMUM_IOC_SCORE = '70';
function IndicatorScoreFilter({ onChange, values }: IndicatorScoreFilterProps): React.ReactElement {
  const { isSubscriber } = useAuth();
  const timer = React.useRef<NodeJS.Timeout>();
  const [_score, _setScore] = React.useState<number>(Number(values.indicatorScore));

  React.useEffect(() => {
    if (Number(values.indicatorScore) !== _score && !timer.current) _setScore(Number(values.indicatorScore));
  }, [values.indicatorScore, _score]);

  function handleChange(_event: Event, value: number): void {
    _setScore(value);
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      onChange({ indicatorScore: value.toString() });
      timer.current = undefined;
    }, 250);
  }

  const marks = [{ value: Number(MINIMUM_IOC_SCORE) }, { value: 100 }];

  return (
    <FilterControl className='IndicatorScoreFilter'>
      <FormLabel className='title-tooltip'>
        By Minimum Score{Number(_score) > 70 && `: ${_score}`}
        <Tooltip
          title={
            !isSubscriber
              ? 'This filter is only available to subscribers'
              : 'The IC-Score or Mandiant Indicator Confidence Score, is produced by machine learning algorithms to convey a confidence in an Indicator being benign or malicious. 0 is considered "high-confidence benign", and 100 is "high-confidence malicious"'
          }
          placement='right'
          arrow
          wrap
        >
          <Icon.Info />
        </Tooltip>
      </FormLabel>
      <SliderContainer>
        <Slider value={Number(_score)} onChange={handleChange} min={70} track={false} marks={marks} />
      </SliderContainer>
    </FilterControl>
  );
}

function toQuery(values: IndicatorScoreKeys) {
  if (!values.indicatorScore || values.indicatorScore === MINIMUM_IOC_SCORE) return;
  return {
    field: 'ioc_score',
    op: Ops.greater_than_equal,
    value: values.indicatorScore
  };
}

const IndicatorScoreFilterConfig: FilterConfig<IndicatorScoreKeys> = {
  defaults: { default: () => ({ indicatorScore: MINIMUM_IOC_SCORE }) },
  supportedTopics: [ArtifactType.Indicator],
  component: IndicatorScoreFilter,
  toQuery: { default: toQuery },
  fromQuery: {
    default: fromQueryNoop({
      field: zod.literal('ioc_score'),
      op: zod.nativeEnum(Ops),
      value: zod.string()
    })
  }
};
export default IndicatorScoreFilterConfig;
