import { Autocomplete } from 'snap-ui/Autocomplete';
import Chip from 'snap-ui/Chip';
import { DataGrid } from 'snap-ui/DataGrid';
import Paper from 'snap-ui/Paper';
import { styled } from 'snap-ui/util';

export const HeaderContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto;
  justify-items: start;
  gap: ${p => p.theme.spacing(4)};
`;

export const Title = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${p => p.theme.spacing(3)};
`;

export const PageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(8)};
`;

export const DetailPanelsContainer = styled('div')`
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  margin-bottom: ${p => p.theme.spacing(9)};
  gap: ${p => p.theme.spacing(6)};

  .Overview {
    h4,
    .org,
    .Subtitle.CreatorSubtitle {
      padding-left: ${p => p.theme.spacing(3)};
    }

    .content {
      margin: ${p => p.theme.spacing(3)};
    }
  }

  dl {
    align-items: center;
    grid-gap: ${p => p.theme.spacing(4)} ${p => p.theme.spacing(8)};
  }
`;

export const IntegrationSelect = styled(Autocomplete)`
  width: 300px;
`;

export const TaskName = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${p => p.theme.spacing(2)};
`;

export const Head = styled('div')``;

export const TaskDetailType = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${p => p.theme.spacing(2)};

  .title-type {
    font-weight: bold;
  }
`;

export const TaskOverviewPanel = styled(Paper)`
  flex: 3;
  display: flex;
  flex-direction: column;
`;

export const TaskDetails = styled(Paper)`
  flex: 2;
  display: flex;
  flex-direction: column;
`;

export const StyledChip = styled(Chip)`
  span {
    color: ${p => p.theme.palette.common.black};
    font-weight: bold;
  }
`;

export const GridSizer = styled(Paper)`
  width: 100%;
  height: 500px;
`;

export const HitsGrid = styled(DataGrid)`
  .non-group-cell {
    margin-left: ${p => p.theme.spacing(8)};
  }

  .parent-group-cell {
    margin-left: 0;
  }

  .child-group-cell {
    margin-left: ${p => p.theme.spacing(10)};
  }

  .truncate-label {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
