import React from 'react';

import { useAsync } from 'storage';

import { ArtifactType, Guid } from 'types/common';

import { postMetadataBulk } from './Metadata.api';
import { getMetadataBulkEndpoint } from './Metadata.service';
import { BulkAction, BulkMetadataSet } from './Metadata.type';
import { mapActions } from './Metadata.util';

export default function useMetadataBulkEdit(type: ArtifactType) {
  const { task, status, errorProps } = useAsync();

  const prefix = getMetadataBulkEndpoint(type);

  const update = React.useCallback(
    (data: Record<string, BulkAction>, identifiers: Guid[]) => {
      if (!prefix) return Promise.resolve();
      const payload: BulkMetadataSet = {
        identifiers,
        actions: mapActions(data)
      };
      return task(postMetadataBulk(prefix, payload));
    },
    [prefix, task]
  );

  return {
    status,
    update,
    errorProps
  };
}
