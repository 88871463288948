import React from 'react';

import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import { DataGrid, FooterPropsOverrides } from 'snap-ui/DataGrid';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';

import useTitle from 'hooks/useTitle';

import { ApiError } from 'module/ApiError';

import { useAuth } from 'provider';

import { Status, useAsync } from 'storage';

import { BoardFooter } from './BoardFooter';
import { ChallengeHeader } from './ChallengeHeader';
import { NoPlayer } from './NoPlayer';
import { TIMEOUT } from './Training.const';
import { fetchScoreboard } from './Training.service';
import { Container } from './Training.style';
import { Game } from './Training.type';

const INITIAL_GAME: Game = {
  players: [],
  challenges: []
};

export function Scoreboard() {
  const { defaultOrgGuid } = useAuth();
  const interval = React.useRef<NodeJS.Timeout>();
  const { data, setData, task, status, errorProps } = useAsync(INITIAL_GAME);
  useTitle('Scoreboard | SnapAttack');

  React.useEffect(() => {
    // TODO maybe implement cancel in the future
    const poll = () => task(fetchScoreboard(defaultOrgGuid)).then(d => setData(d));

    // Get data now then set up interval
    poll();

    // Set up an interval for fetching data
    // Fetch data every n seconds (adjust as needed)
    interval.current = setInterval(poll, TIMEOUT);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(interval.current);
  }, [defaultOrgGuid, setData, task]);

  return (
    <Container>
      <Typography variant='h1'>Scoreboard {Status.pending === status && <Icon.SpinnerProgress />}</Typography>
      <ApiError {...errorProps} />
      <DataGrid
        experimentalFeatures={{ columnGrouping: true }}
        initialState={{ pinnedColumns: { left: ['user_name', 'score', 'solved'] } }}
        columnGroupingModel={[
          {
            groupId: 'Challenges',
            children: [...data.challenges.map(c => ({ field: c }))]
          }
        ]}
        columns={[
          { headerName: 'Name', field: 'user_name', flex: 1, minWidth: 150 },
          { headerName: 'Score', field: 'score', minWidth: 75, maxWidth: 100 },
          {
            headerName: '# Solved',
            field: 'solved',
            minWidth: 75,
            maxWidth: 100,
            valueGetter: p => p.row.challenges?.length || 0
          },
          ...data.challenges.map(c => ({
            headerName: c,
            field: c,
            flex: 3,
            minWidth: 100,
            renderCell: p => (p.row.challenges.includes(c) ? <Icon icon={faCheck} color='mint' /> : null),
            renderHeader: p => <ChallengeHeader p={p} />
          }))
        ]}
        rows={data.players}
        slots={{
          footer: BoardFooter,
          noRowsOverlay: NoPlayer
        }}
        slotProps={{
          footer: {
            onRefresh: () => task(fetchScoreboard(defaultOrgGuid)).then(d => setData(d)),
            isActing: status === Status.pending
          } as FooterPropsOverrides
        }}
      />
    </Container>
  );
}
