import React from 'react';

import { postIntegrationScheduledJobStatus } from 'module/Integration/Integration.api';
import { JobSchedule } from 'module/Integration/Integration.type';

import { usePushSnack } from 'provider/Snack/Snack';

import { ErrorProps, Status, useAsync } from 'storage';

import { Guid } from 'types/common';

export default function useScheduleEnableBulk(jobs: JobSchedule[]): {
  task: <T>(promise: Promise<T>) => Promise<T>;
  status: Status;
  errorProps: ErrorProps;
  optimisticJobs: JobSchedule[];
  pendingGuids: Guid[];
  setScheduledHuntStatus: (guids: Guid[], enabled: boolean) => Promise<void>;
} {
  const pushSnack = usePushSnack();
  const [optimisticJobs, setOptimisticJobs] = React.useState(jobs);
  const [pendingGuids, setPendingGuids] = React.useState<Guid[]>([]);
  const { task, status, errorProps } = useAsync();

  React.useEffect(() => {
    setOptimisticJobs(jobs);
  }, [jobs, setOptimisticJobs]);

  const setScheduledHuntStatus = async (guids: Guid[], enabled: boolean) => {
    setPendingGuids(prev => [...prev, ...guids]);
    setOptimisticJobs(prevJobs => prevJobs.map(job => (guids.includes(job.guid) ? { ...job, enabled } : job)));

    try {
      await task(
        postIntegrationScheduledJobStatus({ job_definition_guids: guids as Guid[], enabled: enabled }).then(() => {
          setPendingGuids(prev => prev.filter(guid => !guids.includes(guid)));
          pushSnack('Scheduled hunt updated', 'info', 'center', 'bottom', 5000);
        })
      );
    } catch (error) {
      setOptimisticJobs(jobs);
    }
  };

  return {
    task,
    status,
    errorProps,
    optimisticJobs,
    pendingGuids,
    setScheduledHuntStatus
  };
}
