import { CancelTokenSource } from 'axios';

import { FilterType } from 'constants/feed';

import { addValidationProductFilter } from 'module/AttackScript/AttackScript.const';

import { ArtifactType, Guid } from 'types/common';
import { Query } from 'types/filter';

import { search, supplemental } from './Feed.api';
import { SortOrder } from './Feed.type';
import { getFeedUrl, topicEndpoint } from './Feed.util';

export function getSearchPage(
  topic: ArtifactType,
  page: number,
  perPage: number,
  query: Query,
  sort = '',
  cancelTokenSource?: CancelTokenSource
) {
  const url = getFeedUrl(topic);

  const config = {
    params: {
      page: page - 1,
      size: perPage,
      sort_by: sort === SortOrder.relevance ? '' : sort
    },
    cancelToken: cancelTokenSource && cancelTokenSource.token
  };

  return search(url, topic === ArtifactType.AttackScript ? addValidationProductFilter(query) : query, config);
}

export function getArtifactCounts(query: Query, topic: FilterType, cancelTokenSource?: CancelTokenSource) {
  const config = {
    params: {
      page: 0,
      size: 0
    },
    cancelToken: cancelTokenSource && cancelTokenSource.token
  };

  return search(
    getFeedUrl(topic),
    topic === ArtifactType.AttackScript ? addValidationProductFilter(query) : query,
    config
  );
}

export function getSupplementalSearch(topic: ArtifactType, guid: Guid, cancelTokenSource?: CancelTokenSource) {
  const config = {
    cancelToken: cancelTokenSource && cancelTokenSource.token
  };
  const prefix = topicEndpoint(topic);
  return supplemental(prefix, guid, config);
}
