import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { DisplayDialog } from 'snap-ui/Dialog';
import Icon from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

import { ApiError } from 'module/ApiError';
import { JsonView } from 'module/JsonView';

import { useMetadataSchema } from 'provider';

import { Status } from 'storage';

import { ArtifactType, Guid } from 'types/common';

import { renderValueWithLabel } from 'utilities/TextUtils';

import { BulkAction } from './Metadata.type';
import { transformMetadataBulkActionWithSchema } from './Metadata.util';
import useMetadataBulkEdit from './useMetadataBulkEdit';

const Container = styled('div')`
  margin: ${p => p.theme.spacing(5, 3)};

  fieldset {
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(4)};
  }

  .Alert,
  .ApiError {
    margin-bottom: ${p => p.theme.spacing(3)};
  }
`;

export type MetadataBulkModal = {
  data: Record<string, BulkAction>;
  resetData: () => void;
  selections: Guid[];
  topic: ArtifactType;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

function _MetadataBulkModal(props: MetadataBulkModal) {
  const { data, resetData, selections, isOpen, onClose, onSuccess, topic } = props;
  const { schema } = useMetadataSchema();
  const { update, errorProps, status } = useMetadataBulkEdit(topic);

  const displayTitle = `Edit Metadata for ${renderValueWithLabel(selections?.length, 'item')}`;
  const disabled = status === Status.pending || isEmpty(selections);

  function handleSubmit() {
    update(transformMetadataBulkActionWithSchema(data, schema), selections).then(() => {
      resetData();
      onSuccess && onSuccess();
      onClose();
    });
  }

  return (
    <DisplayDialog
      DialogProps={{ open: isOpen, onClose, disablePortal: true }}
      PrimaryActionProps={{
        children: <>Submit {status === Status.pending && <Icon.SpinnerProgress />}</>,
        onClick: handleSubmit,
        disabled
      }}
      title={displayTitle}
    >
      <Container>
        <ApiError {...errorProps} />

        <JsonView asBulk schema={schema} uischema={schema?.uischema} data={data} type={topic} />
      </Container>
    </DisplayDialog>
  );
}

export function MetadataBulkModal(props: MetadataBulkModal) {
  if (!props.isOpen) return null;
  return <_MetadataBulkModal {...props} />;
}
