import React from 'react';

import { useHistory } from 'react-router-dom';

import Path from 'constants/paths';

export default function Reset() {
  const { replace } = useHistory();
  React.useLayoutEffect(() => {
    replace(Path.ThreatUpload);
  }, [replace]);
  return null;
}
