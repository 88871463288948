import React from 'react';

import { useHistory } from 'react-router-dom';

import { Path } from 'constants/paths';

import { Engage, Fingerprint, NotableEvent } from 'lib/Engagement';

import { logout } from 'provider';

import { useMountedRef } from 'storage';

function Logout() {
  const mounted = useMountedRef();
  const { replace } = useHistory();
  const [error, setError] = React.useState<{ cause?: any }>();

  React.useEffect(() => {
    logout()
      .then(() => {
        Engage.track(Fingerprint.of(Path.Logout).withNotable(NotableEvent.Success));
        Engage.unidentify();
        replace(Path.Login);
      })
      .catch(err => {
        Engage.track(Fingerprint.error(Path.Logout).withData(err));
        if (err?.response?.status === 401) replace(Path.Login);
        else mounted.current && setError(err);
      });
  }, [replace]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) throw error;
  return null;
}

export default Logout;
