import React from 'react';

import { faCancel, faSquareUp } from '@fortawesome/pro-solid-svg-icons';
import { useFormikContext } from 'formik';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import Icon from 'snap-ui/Icon';

import { Path } from 'constants/paths';

import { ApiError } from 'module/ApiError';
import { standardFormikBaseProps } from 'module/Form';
import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import Formik from 'module/Form/Formik';
import MarkdownFormik from 'module/Form/MarkdownFormik';
import TextFieldFormik from 'module/Form/TextFieldFormik';
import {
  ArtifactWidgetFooter,
  ArtifactWidgetMenuWrapper,
  BurgerClicker,
  Form,
  Menu,
  Publish,
  TagDivider,
  Title
} from 'module/Layout/Artifact.widgets';
import { Discriminator, SoftwareType, TagAutocompleteFormik } from 'module/Tag';
import NotFound from 'module/Util/Fallback/NotFound';

import { useAuth } from 'provider';

import { Status, useAsync, useMountedRef } from 'storage';

import { createNewLanding } from '../Landing.service';
import { LandingForm, LandingFormSchema } from '../Landing.type';
import { StyledArtifact } from './LandingEdit.style';

export type LandingCreate = RouteComponentProps<{ type: string }>;

function _LandingCreate({ match }: LandingCreate) {
  const { goBack } = useHistory();
  const type = match.params.type as typeof Discriminator.Actor | typeof Discriminator.Software;
  const { data, run, status, errorProps } = useAsync<{ id: number }>();

  const handleSubmit = async (values: LandingForm) => {
    run(createNewLanding(type, values));
  };

  return (
    <Formik
      {...standardFormikBaseProps}
      initialValues={{
        actor_names: [],
        alias_names: [],
        attack_names: [],
        external_source: 'snapattack',
        name: '',
        overview: '',
        software_names: [],
        type: type === Discriminator.Software ? SoftwareType.Malware : undefined,
        vulnerabilities_names: []
      }}
      zodSchema={LandingFormSchema}
      onSubmit={handleSubmit}
    >
      {({ submitForm, values }) => (
        <StyledArtifact
          isPending={status === Status.pending}
          type={type}
          meta={
            <>
              <Title headerText={values.name || `New ${type}`} />
              <ArtifactWidgetFooter separation>
                <div />
                <ArtifactWidgetMenuWrapper>
                  <Publish onClick={submitForm} startIcon={<Icon icon={faSquareUp} />}>
                    Publish
                  </Publish>
                  <Menu>
                    <BurgerClicker icon={faCancel} title='Cancel' onClick={() => goBack()} />
                  </Menu>
                </ArtifactWidgetMenuWrapper>
              </ArtifactWidgetFooter>
            </>
          }
        >
          <Form>
            <ResetListener type={type} />
            <NavigationListener id={data?.id} success={status === Status.resolved} type={type} />
            <ApiError {...errorProps} />
            <TextFieldFormik label='External Source' name='external_source' disabled readOnly />
            <TextFieldFormik label='Name' name='name' required />
            {type === Discriminator.Software && (
              <AutocompleteFormik
                label='Type'
                name='type'
                options={[
                  { value: SoftwareType.Malware, content: SoftwareType.Malware },
                  { value: SoftwareType.Tool, content: SoftwareType.Tool }
                ]}
                disableClearable
              />
            )}
            <MarkdownFormik label='Overview' name='overview' required />
            <AutocompleteFormik label='Alias' name='alias_names' multiple options={[]} />
            <TagDivider />
            <TagAutocompleteFormik name='attack_names' discriminator={Discriminator.Attack} multiple />
            {type !== Discriminator.Actor && (
              <TagAutocompleteFormik name='actor_names' discriminator={Discriminator.Actor} multiple />
            )}
            {type !== Discriminator.Software && (
              <TagAutocompleteFormik name='software_names' discriminator={Discriminator.Software} multiple />
            )}
            <TagAutocompleteFormik name='vulnerabilities_names' discriminator={Discriminator.Vulnerability} multiple />
          </Form>
        </StyledArtifact>
      )}
    </Formik>
  );
}

export function LandingCreate(props: LandingCreate) {
  const { user } = useAuth();
  if (
    !user.superuser ||
    (props.match.params.type !== Discriminator.Actor && props.match.params.type !== Discriminator.Software)
  )
    return <NotFound />;
  return <_LandingCreate {...props} />;
}

function ResetListener({ type }: { type: Discriminator }) {
  const mounted = useMountedRef();
  const { resetForm } = useFormikContext<LandingForm>();

  React.useEffect(() => {
    if (mounted.current) resetForm();
  }, [resetForm, type]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}

function NavigationListener(props: { id: number; success: boolean; type: string }) {
  const { replace } = useHistory();
  const {
    values: { name }
  } = useFormikContext<LandingForm>();
  const { id, success, type } = props;

  React.useEffect(() => {
    if (id && success && name && type) {
      replace(`${Path.Collection}/${type}/${encodeURIComponent(name)}`);
    }
  }, [id, name, replace, success, type]);

  return null;
}
