import { z } from 'zod';

import { Guid } from 'types/common';
import { safelyParse } from 'types/common.zod';
import { Ops, Query } from 'types/filter';

import { getAttackScript, postAttackScripts, postSearchScripts, putAttackScripts } from './AttackScript.api';
import { ValidationProductFilter, addValidationProductFilter } from './AttackScript.const';
import { AttackScript, AttackScriptFormInput, AttackScriptSchema } from './AttackScript.type';

export async function postAsyncAttackScripts(body: AttackScriptFormInput) {
  const data = await postAttackScripts(body);
  return safelyParse<AttackScript>(data, AttackScriptSchema);
}

export async function putAsyncAttackScripts(guid: Guid, payload: Partial<AttackScriptFormInput>) {
  const data = await putAttackScripts(guid, payload);
  return safelyParse<AttackScript>(data, AttackScriptSchema);
}

export async function getAsyncAttackScript(guid: Guid) {
  const data = await getAttackScript(guid);
  return safelyParse<z.infer<typeof AttackScriptSchema>>(data, AttackScriptSchema);
}

export function getSessionAttackScripts(guid: Guid) {
  const query: Query = {
    field: 'sessions.guid',
    op: Ops.in,
    value: [guid]
  };
  return postSearchScripts(query);
}

const config = {
  params: {
    page: 0,
    size: 1000
  }
};

export function getAnalyticAttackScripts(payload: Guid | Guid[]) {
  const query: Query = {
    field: 'sessions.analytics.guid',
    op: Ops.in,
    value: Array.isArray(payload) ? payload : [payload]
  };
  return postSearchScripts(query, config);
}

export function getAttackScriptList(query?: Query) {
  const payload = query ? addValidationProductFilter(query) : ValidationProductFilter;
  return postSearchScripts(payload, config);
}
