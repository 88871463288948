import React from 'react';

import May from 'module/May';
import NotFound from 'module/Util/Fallback/NotFound';

import { FunctionalPermission } from 'types/auth';

import CampaignReport from './CampaignReport';

export default function CampaignReportWrapper(props): JSX.Element {
  return (
    <May I={FunctionalPermission.BASStableFeatures}>
      <May.Yes>
        <CampaignReport {...props} />
      </May.Yes>
      <May.No>
        <NotFound />;
      </May.No>
    </May>
  );
}
