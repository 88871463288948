import React from 'react';

import classnames from 'classnames';

import Omnisearch from 'module/Omnisearch';

import { Container } from './Home.style';

type Props = {
  className?: string;
};

export default function Home({ className }: Props) {
  return (
    <Container className={classnames('Home', className)}>
      <Omnisearch />
    </Container>
  );
}
