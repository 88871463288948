import React, { createContext, useContext } from 'react';

import { basJobManualTest } from 'module/BAS/BAS.api';

import { useAuth, usePushSnack } from 'provider';

import { Status, useAsync } from 'storage';

import { Guid } from 'types/common';

import { BASManualTest } from '../BAS.type';
import AddTestFormDialog from './AddTestFormDialog';

type AddTestCaseInterface = {
  openModal: boolean;
  setOpenModal(v: boolean): void;
};

const AddTestCaseContext = createContext<AddTestCaseInterface>(null);

const useAddTestCase = () => {
  const context = useContext(AddTestCaseContext);

  if (!context) {
    throw new Error('useAddTestCase must be used within an AddTestCaseProvider');
  }

  return context;
};

type AddTestCaseProviderProps = {
  children: React.ReactNode;
  refreshJobs: () => void;
  campaignGuid: Guid;
};

function AddTestCaseProvider({ children, refreshJobs, campaignGuid }: AddTestCaseProviderProps) {
  const [openModal, setOpenModal] = React.useState(false);
  const pushSnack = usePushSnack();
  const { run, status, error } = useAsync();
  const { defaultOrgId } = useAuth();
  const errorMessage = error?.message;

  React.useEffect(() => {
    if (status === Status.resolved) {
      pushSnack(`Manual Test Created`, 'info', 'center', 'bottom', 5000);
      refreshJobs();
      setOpenModal(false);
    }
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  const addManualTestCase = React.useCallback(
    (values: BASManualTest) => {
      run(basJobManualTest(values));
    },
    [run]
  );

  const catalog: AddTestCaseInterface = {
    openModal,
    setOpenModal
  };

  return (
    <AddTestCaseContext.Provider value={catalog}>
      <>
        {children}
        <AddTestFormDialog
          isLoading={status === Status.pending}
          apiError={errorMessage}
          open={openModal}
          onClose={() => setOpenModal(false)}
          handleSubmit={handleSubmit}
        />
      </>
    </AddTestCaseContext.Provider>
  );

  function handleSubmit(values: BASManualTest) {
    const body = { ...values, bas_campaign_id: campaignGuid, organization_id: defaultOrgId };
    addManualTestCase(body);
  }
}

export { AddTestCaseProvider, useAddTestCase };
