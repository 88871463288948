import React from 'react';

import { RouteComponentProps } from 'react-router-dom';

import BackdropLoader from 'snap-ui/BackdropLoader';
import Paper from 'snap-ui/Paper';

import { asValidationError, ValidationError } from 'apis';

import Path from 'constants/paths';

import useTitle from 'hooks/useTitle';

import { Engage, Fingerprint } from 'lib/Engagement';

import { canI } from 'module/Can/util';
import { quickAddTags, quickDeleteTags, tagRefresh } from 'module/Collection/Collection.api';
import useCollection from 'module/Collection/useCollection';
import Recommender from 'module/Landing/RecommendationPanel/Recommender';
import ArtifactLayout from 'module/Layout/Artifact';
import { MetadataView } from 'module/Metadata';
import PayWall from 'module/PayWall';
import { useFilterSidebar } from 'module/Scaffold/useStashSidebar';
import { getTagListDiffs } from 'module/Tag';
import TagList, { TagListProps } from 'module/Tag/TagList';
import NotFound from 'module/Util/Fallback/NotFound';
import OverviewTab, { CreatorSubtitle } from 'module/Widgets/OverviewTab';

import { useAuth } from 'provider';
import { RecommenderProvider } from 'provider/Recommender/RecommenderProvider';

import { Status } from 'storage';

import { ContentPermission } from 'types/auth';
import { Artifact, ArtifactType } from 'types/common';

import { CollectionRecommenderType } from './Curation.type';
import { ErrorAlert } from './Curation.widgets';
import CurationFeed from './CurationFeed';
import CurationHeader from './CurationHeader';

type Curation = RouteComponentProps<{ guid: string }>;

function Curation({ match }: Curation) {
  useFilterSidebar(ArtifactType.Analytic, false);
  const guid = match.params.guid;
  const { user, isSubscriber } = useAuth();
  const { collection: ct, isPreview, status, setData } = useCollection(guid);
  const [isActing, setIsActing] = React.useState(false);
  const [action, setAction] = React.useState(null);
  const [error, setError] = React.useState<ValidationError>();
  useTitle(`${ct.name || 'Collections'} | SnapAttack`);
  const isEditable = user.superuser || canI(ContentPermission.Edit, ct);

  React.useEffect(() => {
    if (ct.guid && ct.name) {
      Engage.track(
        Fingerprint.load(Path.Collection).withData({
          content: ContentPermission.Read,
          artifact: ArtifactType.Collection,
          guid: ct.guid,
          name: ct.name,
          artifact_organization_id: ct.organization?.id,
          artifact_organization_name: ct.organization?.name
        })
      );
      Engage.trackPersonIncrement(`view ${Path.Collection}`, 1);
    }
  }, [ct.guid, ct.name, ct.organization?.id, ct.organization?.name]);

  const handleQuickAddTag: TagListProps['onSubmit'] = React.useCallback(
    async values => {
      const [addition, deletion] = getTagListDiffs(values, ct as unknown as Artifact);
      const promised: Promise<unknown>[] = [];
      if (addition?.length > 0 || deletion?.length > 0) {
        if (addition?.length > 0) promised.push(quickAddTags(ct.guid, addition));
        if (deletion?.length > 0) promised.push(quickDeleteTags(ct.guid, deletion));
        await Promise.all(promised);
        const refreshedTags = await tagRefresh(ct.guid);

        setData({
          ...ct,
          ...refreshedTags
        });
      }
    },
    [ct, setData]
  );

  if (status === Status.rejected && !isPreview) {
    return <NotFound artifact={ArtifactType.Collection} error={{ response: 'collection not found', tag: guid }} />;
  }

  return (
    <RecommenderProvider guid={ct.guid} type={CollectionRecommenderType.Collection}>
      {/* This is displayed only for clone (edit w/o edit permissions) and delete from hamburger menu, not page load */}
      <BackdropLoader open={isActing} title={action} fixed contained />
      <ArtifactLayout
        isPending={status === Status.pending}
        meta={
          <CurationHeader
            collection={ct}
            onDelete={() => {
              setError(undefined);
              setAction('Deleting...');
              setIsActing(true);
            }}
            onClone={() => {
              setError(undefined);
              setAction('Cloning...');
              setIsActing(true);
            }}
            onError={error => {
              setError(error);
              setIsActing(false);
            }}
          />
        }
        type={ArtifactType.Collection}
      >
        {isPreview ? (
          <PayWall fixed />
        ) : (
          <>
            {!!ct.guid && isSubscriber && <Recommender name={ct.guid} type={CollectionRecommenderType.Collection} />}
            {error && <ErrorAlert messages={asValidationError(error)?.detail as string[]} />}
            <CurationFeed
              guid={ct.guid}
              collection={ct}
              infoLabel='Overview'
              infoContent={
                <Paper>
                  <OverviewTab
                    description={ct.description}
                    title={ct.organization?.name}
                    references={{ references: ct.references }}
                    orgImage={ct.organization?.small_image || ct.small_image}
                    subtitle={<CreatorSubtitle creator={ct.created_by?.name} date={ct.modified} />}
                  >
                    <MetadataView type={ArtifactType.Collection} guid={ct.guid} />
                    <TagList
                      actor={ct.actor_names}
                      attack={ct.attack_names}
                      software={ct.software_names}
                      vulnerability={ct.vulnerability_names}
                      onSubmit={isEditable ? handleQuickAddTag : undefined}
                    />
                  </OverviewTab>
                </Paper>
              }
            />
          </>
        )}
      </ArtifactLayout>
    </RecommenderProvider>
  );
}

export default React.memo(Curation, (p, n) => p.match.params.guid === n.match.params.guid);
