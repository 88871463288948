import { CompositeMarkerLoadState } from 'aso';

import { BlueMarkerExtended } from 'types/marker';

export function compositeSearchFilter(
  compositeOptions: CompositeMarkerLoadState,
  searchQuery: string
): CompositeMarkerLoadState {
  if (!searchQuery) return compositeOptions;
  return {
    ...compositeOptions,
    markers: {
      ...compositeOptions.markers,
      all: compositeOptions.markers.all.filter(compositeMarker => {
        const descriptionSearchBased = composite =>
          composite?.description?.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()) || false;

        const attackNamesSearchBased =
          compositeMarker?.attack_names?.some(names =>
            names?.toLocaleLowerCase()?.includes(searchQuery.toLocaleLowerCase())
          ) || false;

        const analyticAttackSearchBased = (composite: BlueMarkerExtended) =>
          composite?.analytics?.some(analytic =>
            analytic?.name?.toLocaleLowerCase()?.includes(searchQuery.toLocaleLowerCase())
          ) || false;

        return (
          descriptionSearchBased(compositeMarker) ||
          attackNamesSearchBased ||
          analyticAttackSearchBased(compositeMarker as unknown as BlueMarkerExtended)
        );
      })
    }
  };
}
