import React from 'react';

import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import { ActionIconButton } from 'snap-ui/Button';
import CircularProgress from 'snap-ui/CircularProgress';
import { FormDialog } from 'snap-ui/Dialog';
import { FieldsLayout } from 'snap-ui/Layout';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { updateAiDescription } from 'module/Analytic/Analytic.api';
import { standardFormikBaseProps } from 'module/Form';
import MarkdownFormik from 'module/Form/MarkdownFormik';

import { checkContentPermission } from 'services/authService';

import { Status, useAsync } from 'storage';

import { ContentPermission } from 'types/auth';

import { useAnalyticCatalog } from '../AnalyticProvider';

// This has to undo another required styling element
// The default `display: table` causes the whole form, and not just the contents, to scroll
const MarkdownContainer = styled('div')`
  .markdown-preview {
    display: block;
  }
`;

export function EditAiDescription({ aiDescription }: { aiDescription: string }): JSX.Element {
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
  const { run, status } = useAsync();
  const { go } = useHistory();
  const [{ permissions, analytic }] = useAnalyticCatalog();
  const canEdit = checkContentPermission(permissions, ContentPermission.Edit);

  if (!aiDescription || !canEdit) return null;

  function handleAiChange(value: { aiDescription: string }) {
    run(
      updateAiDescription(analytic.guid, value.aiDescription).then(() => {
        setIsDialogOpen(false);
        go(0);
      })
    );
  }
  return (
    <>
      <Tooltip arrow placement='right' title='Edit AI Description'>
        <ActionIconButton
          color='stealth'
          aria-label='Edit AI Description'
          icon={faPen}
          onClick={() => setIsDialogOpen(true)}
        />
      </Tooltip>
      <FormDialog
        title='Edit AI Description'
        DialogProps={{ open: isDialogOpen, onClose: () => setIsDialogOpen(false) }}
        SubmitProps={{
          children: status === Status.pending ? <CircularProgress size={25} /> : 'Update',
          disabled: status === Status.pending
        }}
        FormikConfig={{
          ...standardFormikBaseProps,
          initialValues: {
            aiDescription: aiDescription
          },
          onSubmit: handleAiChange
        }}
      >
        <FieldsLayout>
          <MarkdownContainer>
            <MarkdownFormik label='' name='aiDescription' required />
          </MarkdownContainer>
        </FieldsLayout>
      </FormDialog>
    </>
  );
}
