import React from 'react';

import { useField } from 'formik';

import AutocompleteFormik, { AutocompleteFormikProps } from 'module/Form/AutocompleteFormik';

import { Discriminator, getLabel, isTacticOption, useTagOptions } from '.';

export type Props = Omit<AutocompleteFormikProps, 'options' | 'label' | 'placeholder' | 'disableUserAdditions'> & {
  enableUserAdditions?: boolean;
  tactics?: boolean;
  techniques?: boolean;
};

export default function AttackTagAutocompleteFormik({
  disabled,
  enableUserAdditions,
  tactics,
  techniques,
  ...others
}: Props) {
  const [field] = useField(others.name);
  const { options: tagOptions, searching, searchTerm, setSearchTerm } = useTagOptions(Discriminator.Attack);

  const options = React.useMemo(() => {
    if (tactics) return tagOptions.filter(option => tactics && isTacticOption(option));
    if (techniques) return tagOptions.filter(option => !isTacticOption(option));
    return tagOptions;
  }, [tactics, tagOptions, techniques]);

  return (
    <AutocompleteFormik
      options={options}
      label={`Primary ${getLabel(Discriminator.Attack)}`}
      disableUserAdditions={!enableUserAdditions}
      disabled={disabled}
      onInputChange={setSearchTerm}
      inputValue={searchTerm || field.value}
      searching={searching}
      {...others}
    />
  );
}
