import React from 'react';

import Chip from 'snap-ui/Chip';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { ArtifactType } from 'types/common';

const LeftPointingChip = styled(Chip)`
  background-color: ${p => p.theme.palette.secondary.dark};
  position: relative;
  border-radius: 3px;
  margin-left: 10px;

  :after {
    border-right: ${p => p.theme.spacing(2)} solid;
    border-top: ${p => p.theme.spacing(2)} solid transparent;
    border-bottom: ${p => p.theme.spacing(2)} solid transparent;
    display: inline-block;
    content: '';
    position: absolute;
    left: -${p => p.theme.spacing(2)};
    color: ${p => p.theme.palette.secondary.dark};
  }
`;

function HalCount({
  count,
  unique,
  type,
  headless
}: {
  count: number;
  unique: number;
  type: ArtifactType;
  headless?: boolean;
}) {
  return (
    <>
      {type === ArtifactType.Analytic && (
        <Tooltip placement='top' title={`${count} detection hits across all attacks`} arrow>
          <span>
            {!headless && <strong>Detection Hits</strong>}{' '}
            <LeftPointingChip label={count} className='HalCount-chip' size='small' />
          </span>
        </Tooltip>
      )}
      {type === ArtifactType.Session && (
        <Tooltip placement='top' title={`${count} detection hits / ${unique} unique`} arrow>
          <span>
            {!headless && <strong>Detection Hits</strong>}{' '}
            <LeftPointingChip label={`${count} / ${unique}`} size='small' />
          </span>
        </Tooltip>
      )}
    </>
  );
}

export default HalCount;
