import { useHistory } from 'react-router-dom';

import { Path } from 'constants/paths';

/*
 * This allows for a redirect to an external site. We need to validate or whitelist those sites to prevent open redirect attacks. If you don't know what that is, Google it!
 * Example usage:
 * GOOD: /redirect?url=https://docs.snapattack.com -- safe domain, will complete redirect
 * BAD: /redirect?url=https://malicious.site -- bad domain, will not redirect
 * BAD: /redirect?url=notavalidurl -- not a valid url, will not redirect
 * BAD: /redirect  -- no url parameter present, will not redirect
 */
export default function Redirect() {
  const { location, replace } = useHistory();
  const domain_whitelist = ['docs.snapattack.com'];
  const params = new URLSearchParams(location.search);
  const url = decodeURI(params.get('url'));
  let domain = null;
  try {
    domain = new URL(url)?.hostname;
  } catch (e) {
    // ignore
  }

  if (url) {
    if (domain_whitelist.includes(domain)) {
      window.location.replace(url);
      return null;
    }
  }

  replace(Path.Home);
  return null;
}
