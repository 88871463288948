import React from 'react';

import Paper, { PaperProps } from '@mui/material/Paper';

import { styled } from 'snap-ui/util';

export type FormSectionProps = PaperProps;

const StyledPaper = styled(({ className, ...props }: PaperProps) => (
  <Paper className={className} {...props} />
))<PaperProps>(() => ({
  padding: '8px 16px'
}));

export default function FormSection(props: PaperProps): JSX.Element {
  return <StyledPaper {...props} />;
}
