import React from 'react';

import Button from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { useIntegrationCatalog, usePushSnack } from 'provider';

import { Status } from 'storage';

import { Guid } from 'types/common';

import { Integration } from './Integration.type';

export function IntegrationSync({ integration }: { integration: Integration }) {
  const pushSnack = usePushSnack();

  const { sync, syncStatus } = useIntegrationCatalog();
  const isSyncing = syncStatus === Status.pending;
  const autoSync = ['CustomerManaged', 'Splunk', 'ElastAlert'].includes(integration.type);

  const handleSyncIntegration = (guid: Guid) => {
    sync([guid]).then(() =>
      setTimeout(() => {
        // This endpoint is instantaneous. Slow down the visuals a bit.
        pushSnack('Sync request received and will complete as a background task. ', 'info', 'center', 'bottom');
      }, 1000)
    );
  };

  if (!integration.is_registered) return null;
  return (
    <div className='sync-container'>
      <Tooltip
        wrap
        arrow
        placement='top'
        title={
          autoSync
            ? 'This integration can only poll SnapAttack for updates. Sync actions are automatically performed every 15 minutes.'
            : null
        }
      >
        <Button
          className='sync-button'
          endIcon={isSyncing && <Icon.SpinnerProgress />}
          onClick={() => handleSyncIntegration(integration.guid)}
          color='primary'
          disabled={autoSync}
          variant='outlined'
        >
          Sync Now
        </Button>
      </Tooltip>
    </div>
  );
}
