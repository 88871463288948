import React from 'react';

import { faCalendarDay, faClock } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

import Icon from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

import useExtractDetails from 'hooks/useExtractDetails';

import { Collection } from 'module/Collection/Collection.type';
import { Intel } from 'module/Intel/Intel.type';
import { Session } from 'module/Session/Session.type';
import ExternalImage from 'module/Widgets/ExternalImage';

import { Artifact } from 'types/common';

import { formatShortTimestamp, getDiff, TimeInSeconds } from 'utilities/TimeUtils';

export type AuthorMetaProps = { className?: string; duration?: string; preview?: boolean } & (
  | { resource: Session; type: 'session' }
  | { resource: Artifact; type: 'analytic' }
  | { resource: Intel; type: 'intel' }
  | { resource: Collection; type: 'collection' }
);

function MetaExternalImage({
  organization_image_url,
  type,
  content_image_url
}: {
  organization_image_url: string;
  type: 'intel' | 'session' | 'analytic';
  content_image_url: string;
}) {
  let alternate = '';
  let alt = '';
  switch (type) {
    case 'intel':
      alternate = `${process.env.PUBLIC_URL}/images/cards/intelligence.png`;
      alt = 'Intelligence';
      break;
    case 'session':
      alternate = `${process.env.PUBLIC_URL}/images/cards/threat.png`;
      alt = 'Threat';
      break;
    case 'analytic':
      alternate = `${process.env.PUBLIC_URL}/images/cards/detection.png`;
      alt = 'Detection';
      break;
  }

  return <ExternalImage src={organization_image_url || content_image_url || alternate} altSrc={alternate} alt={alt} />;
}

// Don't display last modified date if the content item has not really been modified
const displayLastModified = (details): boolean => {
  return (
    details.created_by !== details.updated_by && getDiff(details.modified, details.creation) > TimeInSeconds.minute
  );
};

const Container = styled('div')`
  display: flex;
  align-items: center;

  img {
    height: 75px;
    margin-right: ${p => p.theme.spacing(4)};
  }

  span {
    font-weight: bold;
  }

  .AuthorMeta-details {
    display: 'flex';
    flex-direction: 'column';
  }

  .AuthorMeta-grid {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: ${p => p.theme.spacing(3)};
  }

  .AuthorMeta-session-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: ${p => p.theme.spacing(9)};
  }

  svg {
    margin-right: ${p => p.theme.spacing(1)};
  }
`;

const EmptyColumn = () => <div>&nbsp;</div>;

export default function AuthorMeta(props: AuthorMetaProps) {
  const details = useExtractDetails(props.resource);
  const author =
    props.type === 'analytic' ? props.resource.author : props.type === 'intel' ? props.resource.original_author : null;

  return (
    <Container className={classNames('AuthorMeta', props.className)}>
      {props.type !== 'collection' && (
        <MetaExternalImage
          organization_image_url={details?.organization?.small_image}
          type={props.type}
          content_image_url={props.resource.small_image}
        />
      )}
      <div className='AuthorMeta-details'>
        {props.type === 'session' ? (
          <div className='AuthorMeta-session-grid'>
            <div>
              Organization: <span>{details?.organization?.name || 'Private Organization'}</span>
            </div>
            <EmptyColumn />
            <EmptyColumn />
            <div>
              Created By: <span>{details.created_by}</span>
            </div>
            {props.resource.creation && (
              <div>
                <Icon icon={faCalendarDay} />
                {formatShortTimestamp(props.resource.creation)}
              </div>
            )}
            {props.duration === '-' || !props.duration ? (
              <EmptyColumn />
            ) : (
              <div>
                <Icon icon={faClock} />
                {props.duration}
              </div>
            )}
            {displayLastModified(details) && (
              <>
                <div>
                  Last Updated By: <span>{details.updated_by}</span>
                </div>
                <div>
                  <Icon icon={faCalendarDay} />
                  {formatShortTimestamp(props.resource.modified)}
                </div>
                <EmptyColumn />
              </>
            )}
          </div>
        ) : (
          <div className='AuthorMeta-grid'>
            <div>
              Organization: <span>{details?.organization?.name || 'Private Organization'}</span>
            </div>
            <EmptyColumn />

            {author && (
              <>
                <div>
                  Original Author: <span>{author}</span>
                </div>
                <EmptyColumn />
              </>
            )}

            <div>
              Contributed By: <span>{details.created_by}</span>
            </div>
            <div>
              {props.resource.creation && (
                <>
                  <Icon icon={faCalendarDay} />
                  {formatShortTimestamp(props.resource.creation)}
                </>
              )}
            </div>

            {displayLastModified(details) && (
              <>
                <div>
                  Last Updated By: <span>{details.updated_by}</span>
                </div>
                <div>
                  <Icon icon={faCalendarDay} />
                  {formatShortTimestamp(props.resource.modified)}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Container>
  );
}
