import React from 'react';

import { CancelTokenSource } from 'axios';
import isEmpty from 'lodash/isEmpty';

import { CancelToken } from 'apis';

import { CollectionDiscriminator, FilterableCollections } from 'module/Collection/Collection.type';
import { isLandingType } from 'module/Collection/Collection.util';
import { getCollectionContentCounts } from 'module/Curation/Curation.service';
import { getBulkLandingStatsCount } from 'module/Landing/Landing.service';
import { LandingStats, LandingStatsCatalog } from 'module/Landing/Landing.type';

import { Status, useAsync } from 'storage';

import { Artifact, Named, Tracked } from 'types/common';

import { CollectionCountCatalog, CollectionCount } from './Curation.type';

export type CollectionCounts = {
  [CollectionDiscriminator.Static]: CollectionCountCatalog;
  [CollectionDiscriminator.Actor]: LandingStatsCatalog;
  [CollectionDiscriminator.Attack]: LandingStatsCatalog;
  [CollectionDiscriminator.Software]: LandingStatsCatalog;
  [CollectionDiscriminator.Vulnerability]: LandingStatsCatalog;
};

type ArtifactCollectionCountsInterface = {
  counts: CollectionCounts;
  getCounts(item: Artifact): LandingStats | CollectionCount;
  getCountsStatus(item: Artifact): Status;
};

export default function useArtifactCollectionCounts<T extends Tracked & Named & { type?: string }>(
  items: T[]
): ArtifactCollectionCountsInterface {
  const { run: collectionRun, ...collection } = useAsync<CollectionCountCatalog>({});
  const { run: actorRun, ...actor } = useAsync<LandingStatsCatalog>({});
  const { run: attackRun, ...attack } = useAsync<LandingStatsCatalog>({});
  const { run: softwareRun, ...software } = useAsync<LandingStatsCatalog>({});
  const { run: vulnerabilityRun, ...vulnerability } = useAsync<LandingStatsCatalog>({});

  const groupedItems: Record<FilterableCollections, string[]> = React.useMemo(
    () =>
      items
        .filter(item => item.type === CollectionDiscriminator.Static || isLandingType(item))
        .reduce(
          (grouped, item) => ({
            ...grouped,
            [item.type]: [...grouped[item.type], isLandingType(item) ? item.name : item.guid]
          }),
          {
            [CollectionDiscriminator.Actor]: [],
            [CollectionDiscriminator.Attack]: [],
            [CollectionDiscriminator.Software]: [],
            [CollectionDiscriminator.Static]: [],
            [CollectionDiscriminator.Vulnerability]: []
          }
        ),
    [items]
  );

  const counts = React.useMemo(
    () => ({
      [CollectionDiscriminator.Static]: collection.data,
      [CollectionDiscriminator.Actor]: actor.data,
      [CollectionDiscriminator.Attack]: attack.data,
      [CollectionDiscriminator.Software]: software.data,
      [CollectionDiscriminator.Vulnerability]: vulnerability.data
    }),
    [actor.data, attack.data, collection.data, software.data, vulnerability.data]
  );

  const countsStatuses = React.useMemo(
    () => ({
      [CollectionDiscriminator.Static]: collection.status,
      [CollectionDiscriminator.Actor]: actor.status,
      [CollectionDiscriminator.Attack]: attack.status,
      [CollectionDiscriminator.Software]: software.status,
      [CollectionDiscriminator.Vulnerability]: vulnerability.status
    }),
    [actor.status, attack.status, collection.status, software.status, vulnerability.status]
  );

  const getCounts = React.useCallback(
    (item: Artifact) => {
      return counts[item.type][isLandingType(item) ? item.name : item.guid];
    },
    [counts]
  );

  const getCountsStatus = React.useCallback(
    (item: Artifact) => {
      return countsStatuses[item.type];
    },
    [countsStatuses]
  );

  const fetchCounts = React.useCallback(
    (
      type: Artifact['type'],
      runnable: (promise: Promise<CollectionCountCatalog>, preserve?: boolean) => void
    ): CancelTokenSource => {
      const cancelSource = CancelToken.source();
      const config = {
        cancelToken: cancelSource && cancelSource.token
      };
      const items = groupedItems[type];
      const action =
        type === CollectionDiscriminator.Static ? getCollectionContentCounts : getBulkLandingStatsCount(type);

      if (!isEmpty(items)) {
        runnable(action(items, config));
      }

      return cancelSource;
    },
    [groupedItems]
  );

  React.useEffect(() => {
    const cancelSource = fetchCounts(CollectionDiscriminator.Static, collectionRun);
    return () => cancelSource?.cancel();
  }, [collectionRun, fetchCounts]);

  React.useEffect(() => {
    const cancelSource = fetchCounts(CollectionDiscriminator.Actor, actorRun);
    return () => cancelSource?.cancel();
  }, [actorRun, fetchCounts]);

  React.useEffect(() => {
    const cancelSource = fetchCounts(CollectionDiscriminator.Attack, attackRun);
    return () => cancelSource?.cancel();
  }, [attackRun, fetchCounts]);

  React.useEffect(() => {
    const cancelSource = fetchCounts(CollectionDiscriminator.Software, softwareRun);
    return () => cancelSource?.cancel();
  }, [softwareRun, fetchCounts]);

  React.useEffect(() => {
    const cancelSource = fetchCounts(CollectionDiscriminator.Vulnerability, vulnerabilityRun);
    return () => cancelSource?.cancel();
  }, [vulnerabilityRun, fetchCounts]);

  return {
    counts,
    getCounts,
    getCountsStatus
  };
}
