import React from 'react';

import Tooltip from 'snap-ui/Tooltip';

type Props = {
  count: number;
  mitre_id: string;
  children: React.ReactElement;
};

export default function MatrixTooltip(props: Props) {
  return (
    <Tooltip
      arrow
      title={<>{`${props.count} artifact${props.count === 1 ? '' : 's'} for ${props.mitre_id}`}</>}
      followCursor
      enterDelay={1000}
      enterNextDelay={500}
      placement='right'
    >
      {props.children}
    </Tooltip>
  );
}
