import React, { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';

import { Status, useAsync } from 'storage';

import { Permitted } from 'types/auth';
import { Artifact, Guid } from 'types/common';

import { getIntel, getIntelPermissions, getIntelReferences } from './Intel.api';
import { IntelReferences, IntelState } from './Intel.type';

export default function useIntel(
  guid: Guid,
  artifact?: Artifact,
  refs?: IntelReferences,
  perms?: Permitted
): IntelState {
  const {
    data: intel,
    run: intelRun,
    status: intelStatus,
    setData: setIntel,
    error: intelError
  } = useAsync<Artifact>();

  const {
    data: references,
    run: referencesRun,
    status: referencesStatus,
    setData: setReferences,
    error: referencesError
  } = useAsync<IntelReferences>();

  const {
    data: permissions,
    run: permissionsRun,
    status: permissionsStatus,
    setData: setPermissions,
    error: permissionsError
  } = useAsync<Permitted>();

  useEffect(() => {
    if (!guid) {
      setIntel(null);
    } else if (intelStatus === Status.pending) {
      // do nothing
    } else if (isEmpty(artifact)) {
      intelRun(getIntel(guid));
    } else {
      setIntel(artifact);
    }
    // eslint-disable-next-line
  }, [guid]);

  useEffect(() => {
    if (!guid) {
      setReferences(null);
    } else if (referencesStatus === Status.pending) {
      // do nothing
    } else if (isEmpty(refs)) {
      referencesRun(getIntelReferences(guid));
    } else {
      setReferences(refs);
    }
    // eslint-disable-next-line
  }, [guid]);

  useEffect(() => {
    if (!guid) {
      setPermissions(null);
    } else if (permissionsStatus === Status.pending) {
      //do nothing
    } else if (isEmpty(perms) || !perms.permission) {
      permissionsRun(getIntelPermissions(guid));
    } else {
      setPermissions(perms);
    }
    // eslint-disable-next-line
  }, [guid]);

  const refresh = React.useCallback(() => {
    intelRun(getIntel(guid), true);
    referencesRun(getIntelReferences(guid), true);
    permissionsRun(getIntelPermissions(guid), true);
  }, [intelRun, guid, referencesRun, permissionsRun]);

  const refreshReferences = React.useCallback(() => {
    referencesRun(getIntelReferences(guid), true);
  }, [guid, referencesRun]);

  return {
    intel:
      intelError?.response?.status === 402
        ? { ...intelError.response.data }
        : {
            ...intel,
            ...references,
            ...permissions
          },
    isPending: [intelStatus, referencesStatus, permissionsStatus].includes(Status.pending),
    status: {
      intel: intelStatus,
      references: referencesStatus,
      permissions: permissionsStatus
    },
    error: {
      intel: intelError,
      references: referencesError,
      permissions: permissionsError
    },
    setIntel,
    refresh,
    refreshReferences,
    preview: intelError?.response?.status === 402
  };
}
