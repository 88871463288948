import Button, { ButtonProps } from 'snap-ui/Button';
import { theme } from 'snap-ui/theme';
import { styled } from 'snap-ui/util';

export const OrStyles = `

display: flex;
gap: 6px;
position: relative;
&::after {
  content: 'Or';
  color: ${theme.palette.common.white};
  position: absolute;
  right: -14px; /* Magic */
  background-color: ${theme.palette.background.default};
  border-radius: 50%;
  z-index: 10;
  padding: ${theme.spacing(0, 1)};
}

`;

const OrButton = styled(Button)<ButtonProps>`
  ${OrStyles}
`;

export default OrButton;
