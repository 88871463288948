import { Detection } from 'module/Detection';
import { FilterValues } from 'module/GlobalFilter';

import { AnalyticRecommendation } from 'types/analytic';
import { DefensivePosture, Ident } from 'types/common';
import { Ops } from 'types/filter';
import { BlueMarker, BlueMarkerExtended, CombinedCompositeMarker, Marker, MarkerExtended } from 'types/marker';

import { getPreferredOrgScore } from 'utilities/ArtifactUtils';
import { getDiff } from 'utilities/TimeUtils';

// handles both extended non-extended variants
export function isBlueMarker(marker: MarkerExtended): marker is BlueMarkerExtended;
export function isBlueMarker(marker: Marker): marker is BlueMarker;
export function isBlueMarker(marker: Marker): marker is BlueMarker {
  // Let's make assumptions since the BE no longer gives us a simple boolean flag
  if (!marker) return false;
  return 'analytic_compilation_id' in marker;
}

export function sortMarker<M extends Marker>(startTime: string, marker: M[]): M[] {
  function compare(a: Marker, b: Marker): number {
    const at = isBlueMarker(a) ? a.event?.timestamp : a?.timestamp;
    const bt = isBlueMarker(b) ? b.event?.timestamp : b?.timestamp;

    const offsetA = getDiff(at, startTime);
    const offsetB = getDiff(bt, startTime);
    return offsetA - offsetB;
  }

  return marker.sort(compare);
}

function filterScore<M extends Marker>(
  defaultOrgId: Ident,
  severity: string[],
  marker: M,
  accessor: 'ranks' | 'severities',
  globalAccessor: 'rank' | 'severity'
) {
  if (!isBlueMarker(marker)) return true;
  // TODO: Should this look at every analytic or continue to short cut with zero index?
  const detail = marker?.analytics?.[0];
  return (
    detail && (severity || []).includes(getPreferredOrgScore(defaultOrgId, detail[accessor], detail[globalAccessor]))
  );
}

export function filterPosture(posture: string[], detection: Detection, marker: BlueMarker) {
  return posture.some(p => {
    switch (p) {
      case DefensivePosture.UNVALIDATED:
        return detection.unvalidated.some(
          d => d.row_id === marker?.row_id && d.analytic_compilation_id === marker?.analytic_compilation_id
        );
      case DefensivePosture.VALIDATED:
        return detection.validated.some(
          d => d.row_id === marker?.row_id && d.analytic_compilation_id === marker?.analytic_compilation_id
        );
      case DefensivePosture.ANALYTIC_GAP:
        return detection.validated_gaps.some(
          d => d.row_id === marker?.row_id && d.analytic_compilation_id === marker?.analytic_compilation_id
        );
      default:
        return false;
    }
  });
}

export function filterOrganization<M extends BlueMarker>(organizations: string[], op: Ops, marker: M) {
  if (organizations?.length === 0) return true;

  switch (op) {
    case Ops.in:
      return organizations?.some(org => marker?.analytics[0]?.organization_id.toString() === org);
    case Ops.not:
      return organizations?.every(org => marker?.analytics[0]?.organization_id.toString() !== org);
  }
  return false;
}
export function filterAttack<M extends Marker>(attack: string[], marker: M) {
  if (attack.length === 0) return true;
  return marker?.attack_names?.some(t => attack.includes(t));
}

export function filterRecommendedDetection<M extends Marker>(
  recommendedDetectionChecked: boolean,
  analyticRecommendations: AnalyticRecommendation[],
  marker: M
) {
  if (!recommendedDetectionChecked) return true;
  return isBlueMarker(marker) && !!recommendedDetectionChecked
    ? analyticRecommendations?.some(recommendation => recommendation.guid == marker.analytics[0].guid)
    : false;
}

export function filterByHostMachineName<M extends Marker>(name: string, marker: M) {
  return name.length === 0 ? true : marker?.event?.machine_name === name;
}

type filter = <M extends Marker>(m: M) => void;

export function applySessionFilterToMarkers(
  defaultOrgId: Ident,
  filter: FilterValues,
  markers: CombinedCompositeMarker,
  start: string,
  detection: Detection,
  machineName: string,
  analyticRecommendations: AnalyticRecommendation[]
): CombinedCompositeMarker {
  const confidence = filter.confidence as string[];
  const severity = filter.severity as string[];
  const recommendedDetectionChecked = filter.recommended === 'true' || false;
  const posture = filter.posture as string[];
  const attack = filter.attack as string[];
  const organizations = filter.organizations as string[];
  const organizationsOp = filter.organizationOp as Ops;

  const blueMarkersByHost = markers.blue.filter(m => filterByHostMachineName(machineName, m));

  const blueFilters: filter[] = [
    m => filterRecommendedDetection(recommendedDetectionChecked, analyticRecommendations, m),
    m => filterScore(defaultOrgId, confidence, m, 'ranks', 'rank'),
    m => filterScore(defaultOrgId, severity, m, 'severities', 'severity'),
    m => filterPosture(posture, detection, m as BlueMarker),
    m => filterAttack(attack, m),
    m => filterOrganization(organizations, organizationsOp, m as BlueMarker)
  ];

  const blue = blueMarkersByHost.filter(m => blueFilters.every(f => f(m)));
  const all = sortMarker(start, [...markers.red, ...blue]);

  return {
    blue,
    red: markers.red,
    all,
    unfilteredTotalByHost: blueMarkersByHost.length + markers.red.length,
    unfilteredTotal: markers.blue.length + markers.red.length
  };
}

export function getAllArtifactParams() {}
