import { styled } from 'snap-ui/util';

export const WithPrintStyle = styled('div')<{ id: string; className?: string }>`
  .CoverPage {
    display: none !important;
  }

  .k-pdf-export {
    .CoverPage {
      display: flex !important;
    }

    * {
      color: ${p => p.theme.palette.common.black} !important;
      text-shadow: none !important;
      box-shadow: none;
    }

    .break-before {
      page-break-inside: avoid;
      page-break-before: always;
    }

    .MuiPaper-root {
      border: 2px solid ${p => p.theme.palette.common.black};
      background-color: ${p => p.theme.palette.common.white};
    }

    thead,
    tr {
      background-color: ${p => p.theme.palette.common.white};
    }

    div.HeroImage {
      background-image: unset;
      background-color: unset;
      border: 2px solid ${p => p.theme.palette.common.black};

      .HeroImage-window {
        background-color: unset;
        border: unset;
      }
    }

    .no-print,
    button {
      display: none;
    }
  }
`;

export const Header = styled('div', { name: 'Dashboard-header' })`
  display: flex;
  justify-content: space-between;

  .Header-action {
    display: flex;
    align-items: center;
    gap: ${p => p.theme.spacing(3)};
  }

  .title-container {
    .pencil-button {
      display: none;
    }
  }

  .title-container:hover {
    .pencil-button {
      display: inline-block;
    }
  }
`;
