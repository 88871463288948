import React from 'react';

import Tooltip from 'snap-ui/Tooltip';

import CheckboxFormik from 'module/Form/CheckboxFormik';

import { SigmaConfig } from 'types/analytic';

export function LanguageFormAdvancedCheckbox({
  configs,
  visible
}: {
  configs: SigmaConfig[];
  visible: boolean;
}): JSX.Element {
  if (!visible) return null;
  const enableClone = configs.every(config => !!config.raw);

  return (
    <Tooltip arrow wrap title={enableClone ? undefined : 'The associated configuration files cannot be cloned'}>
      <CheckboxFormik disabled={!enableClone} name='_clone_configs' label='Clone Configurations' />
    </Tooltip>
  );
}
