import { styledBox } from 'snap-ui/util';

export const Domino = styledBox(() => ({
  display: 'inline-flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginLeft: '-8px',

  '& > :first-of-type': {
    zIndex: 1
  },

  '& > :not(:first-of-type)': {
    /* margin is calc'd as a % of parent width
         2 markers means parent width = 2 * marker width
         => 50% marker width = 25% parent width
      */
    marginLeft: '-25%'
  }
}));
