import React from 'react';

import { faList } from '@fortawesome/pro-solid-svg-icons';
import truncate from 'lodash/truncate';
import sanitizeHtml from 'sanitize-html';

import { ActionIconButton } from 'snap-ui/Button';
import Placeholder from 'snap-ui/Placeholder';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';

import useExtractDetails from 'hooks/useExtractDetails';

import { canI } from 'module/Can/util';
import { MetadataView } from 'module/Metadata';
import Reference from 'module/Reference';
import { SupplementalArtifact } from 'module/Search';
import TagList, { TagListProps } from 'module/Tag/TagList';
import OverviewTab, { Subtitle } from 'module/Widgets/OverviewTab';

import { Status } from 'storage';

import { AnalyticReferences } from 'types/analytic';
import { ContentPermission, Permitted } from 'types/auth';
import { Artifact, ArtifactType } from 'types/common';

import { formatShortTimestamp } from 'utilities/TimeUtils';

import OverviewAIDescription from './AiDescription/OverviewAIDescription';

type AnalyticOverviewProps = {
  analytic: Artifact;
  analyticStatus: Status;
  onQuickAddTag: TagListProps['onSubmit'];
  onShowVersionList(): void;
  permissions: Permitted;
  references: AnalyticReferences;
  supplemental: SupplementalArtifact;
};

export default function AnalyticOverview(props: AnalyticOverviewProps): JSX.Element {
  const { analytic, analyticStatus, onQuickAddTag, onShowVersionList, permissions, references, supplemental } = props;
  const isEditable = canI(ContentPermission.Edit, permissions);
  const authorMetadata = useExtractDetails(analytic);
  const showPlaceholder = analyticStatus === Status.pending;
  const author = authorMetadata.created_by === analytic?.author ? authorMetadata.created_by : analytic?.author;
  const license = supplemental?.license_url ? sanitizeHtml(supplemental?.license_url) : null;
  const action = analytic.actions?.map(nist => nist.toString());
  // Adds a link back to the sigma github repository for sigma detections
  if (
    license?.toLowerCase()?.includes('https://github.com/sigmahq/') &&
    !references?.references?.includes('https://github.com/SigmaHQ/sigma')
  ) {
    references?.references?.push('https://github.com/SigmaHQ/sigma');
  }

  return showPlaceholder ? (
    <Placeholder variant='rectangular' height='100%' />
  ) : (
    <OverviewTab
      title={authorMetadata.organization?.name}
      description={analytic.description}
      descriptionComponent={
        <OverviewAIDescription description={analytic.description} aiDescription={analytic.ai_description} />
      }
      references={references}
      subtitle={
        <Subtitle className='CreatorSubtitle'>
          <Typography>
            CREATOR:{' '}
            {truncate(author, {
              omission: '...',
              length: 30
            })}
          </Typography>
          <Typography>
            UPDATED: {formatShortTimestamp(authorMetadata.modified)}{' '}
            <Tooltip arrow placement='top' title='Previous versions'>
              <ActionIconButton
                aria-label='Previous versions'
                color='primary'
                icon={faList}
                onClick={onShowVersionList}
              />
            </Tooltip>
          </Typography>
        </Subtitle>
      }
      orgImage={authorMetadata.organization?.small_image}
    >
      {license && <Reference title='License' reference={[license]} />}
      <MetadataView type={ArtifactType.Analytic} guid={analytic.guid} />
      <TagList
        includeDataSources
        actor={analytic.actor_names}
        action={action}
        attack={analytic.attack_names}
        software={analytic.software_names}
        vulnerability={analytic.vulnerability_names}
        datasource={analytic.datasource_names}
        onSubmit={isEditable ? onQuickAddTag : undefined}
      />
    </OverviewTab>
  );
}
