import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import Icon from 'snap-ui/Icon/Icon';
import Paper from 'snap-ui/Paper';
import { TablePlaceholder } from 'snap-ui/Table';
import Typography from 'snap-ui/Typography';

import { useCannotRedirect } from 'aso/useCannotRedirect';

import useTitle from 'hooks/useTitle';

import { CollectionDiscriminator } from 'module/Collection/Collection.type';
import { collectionPath, detectionPath } from 'module/Feed/Feed.util';
import { ScheduledJobsProvider, useScheduledJobsContext } from 'module/Integration/useScheduledJobs';
import { JobType } from 'module/Job';
import { Page } from 'module/Layout/Styled';
import EmptyState from 'module/Widgets/EmptyState';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';

import HuntTaskTable from './HuntTaskTable';

export default function HuntTasks(): JSX.Element {
  useTitle('Scheduled Hunts | SnapAttack');
  useCannotRedirect(FunctionalPermission.OpenSearchUIAccess);

  return (
    <ScheduledJobsProvider integrationGuid='ALL'>
      <Page>
        <Typography variant='h1'>Scheduled Hunts</Typography>
        <p>
          Scheduled hunts automatically search detections across your integrations at a defined interval. Hunt for
          threats based on <Link to={collectionPath(CollectionDiscriminator.Actor)}>threat actors</Link>,{' '}
          <Link to={collectionPath(CollectionDiscriminator.Software)}>software</Link>,{' '}
          <Link to={collectionPath(CollectionDiscriminator.Vulnerability)}>vulnerabilities</Link>, or{' '}
          <Link to={collectionPath(CollectionDiscriminator.Attack)}>MITRE ATT&CK techniques</Link>; from a specific{' '}
          <Link to={collectionPath(CollectionDiscriminator.Static)}>collection</Link>; or from the{' '}
          <Link to={detectionPath()}>detection library</Link>. To configure a scheduled hunt, click the <Icon.Menu />{' '}
          button, select &quot;Hunt Detections,&quot; and set a frequency.
        </p>
        <Paper>
          <HuntTasksInner />
        </Paper>
      </Page>
    </ScheduledJobsProvider>
  );
}

function HuntTasksInner(): JSX.Element {
  const { jobs, fetchStatus } = useScheduledJobsContext();
  const huntJobs = (jobs || []).filter(j => j.type === JobType.Hunt);

  const isPending = fetchStatus === Status.pending;

  if (isPending) return <TablePlaceholder count={10} height={40} />;
  if (isEmpty(huntJobs)) return <EmptyState title='No Results'>There are no scheduled hunts configured</EmptyState>;

  return <HuntTaskTable jobs={huntJobs} />;
}
