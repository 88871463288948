import React from 'react';

import Stack from '@mui/material/Stack';
import classnames from 'classnames';

import { StrictReactNode } from 'types/core';

export default function Fields({
  children,
  className,
  maxWidth = 'sm',
  spacing = 5
}: {
  children: StrictReactNode;
  className?: string;
  maxWidth?: string;
  spacing?: string | number;
}): JSX.Element {
  return (
    <Stack className={classnames('FieldsLayout', className)} maxWidth={maxWidth} py='1em' px='1.5em' spacing={spacing}>
      {children}
    </Stack>
  );
}
