import React from 'react';

import zod from 'zod';

import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { addOrRemoveValue } from 'module/Filter/Filter.util';
import { FilterAugment, FilterControl } from 'module/GlobalFilter';

import { useAuth } from 'provider';

import { WorkflowState } from 'types/analytic';
import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';

import { FilterConfig } from '../GlobalFilter.type';

type WorkflowStateKeys = {
  workflowStates: WorkflowState[];
};

type WorkflowStateFilterProps = {
  disabled?: boolean;
  onChange(values: Partial<WorkflowStateKeys>): void;
  values: WorkflowStateKeys;
};

const options = Object.values(WorkflowState);

function WorkflowStateFilter({ disabled, onChange, values }: WorkflowStateFilterProps): JSX.Element {
  const { isSubscriber } = useAuth();

  return (
    <FilterControl disabled={disabled || !isSubscriber} className='WorkflowStateFilter'>
      <FormLabel id='workflow-toggle-button-group-label' className='title-tooltip'>
        By Workflow State{' '}
        {!isSubscriber && (
          <Tooltip title='Workflow states are only available to subscribers' placement='right' wrap arrow>
            <Icon.Info />
          </Tooltip>
        )}
      </FormLabel>
      <FormGroup aria-labelledby='workflow-toggle-button-group-label'>
        {options.map(state => (
          <FormControlLabel
            key={state}
            control={<Checkbox onChange={handleChange} value={state} checked={values.workflowStates.includes(state)} />}
            label={state}
          />
        ))}
      </FormGroup>
    </FilterControl>
  );

  function handleChange(e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    const v = e.currentTarget.value as WorkflowState;
    const workflowStates = addOrRemoveValue(checked, values.workflowStates, v) as WorkflowState[];
    onChange({ workflowStates });
  }
}

function toQuery(values: WorkflowStateKeys, { orgId }: FilterAugment) {
  if (!values.workflowStates?.length) return;
  if (values.workflowStates?.length === Object.keys(WorkflowState).length) return;
  return {
    op: Ops.for_each,
    items: [
      {
        case_sensitive: false,
        field: 'states.state',
        op: Ops.in,
        value: values.workflowStates
      },
      {
        field: 'states.organization_id',
        op: Ops.equals,
        value: orgId
      }
    ]
  };
}

const fromQuery = zod
  .object({
    op: zod.nativeEnum(Ops),
    items: zod
      .array(
        zod.union([
          zod
            .object({
              case_sensitive: zod.literal(false),
              field: zod.union([zod.literal('states.state'), zod.literal('state.state')]),
              op: zod.nativeEnum(Ops),
              value: zod.array(zod.nativeEnum(WorkflowState))
            })
            .transform(query => query.value),
          zod
            .object({
              field: zod.literal('states.organization_id')
            })
            .transform(() => undefined)
        ])
      )
      .max(2)
      .min(2)
  })
  .transform(query => ({
    workflowStates: query.items.filter(Boolean).flat()
  }));

const WorkflowStateFilterConfig: FilterConfig<WorkflowStateKeys> = {
  defaults: { default: () => ({ workflowStates: [...Object.values(WorkflowState)] }) },
  supportedTopics: [ArtifactType.Analytic],
  component: WorkflowStateFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default WorkflowStateFilterConfig;
