import React from 'react';

import { faCaretUp, faCheck, faPen, faX } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import Accordion, { AccordionDetails, AccordionSummary } from 'snap-ui/Accordion';
import { Option } from 'snap-ui/Autocomplete';
import Autosearch from 'snap-ui/Autosearch';
import Button, { ActionIconButton } from 'snap-ui/Button';
import Chip from 'snap-ui/Chip';
import Icon from 'snap-ui/Icon';
import Link from 'snap-ui/Link';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { ApiError } from 'module/ApiError';
import { getArtifactIcon } from 'module/Layout/Artifact.helper';
import TruncateContainer from 'module/Layout/TruncateContainer';
import { AutocompleteResponse, Discriminator, postVulnerabilityAlias } from 'module/Tag';
import usePartialTagMatch from 'module/Tag/usePartialTagMatch';

import { useAuth } from 'provider';

import { Status, useAsync } from 'storage';

import { Ident } from 'types/common';

import { useLandingCatalog } from '../LandingProvider';

const EditContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(2)};
  width: 100%;

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: ${p => p.theme.spacing(3)};
  }
`;

export default function LandingAliases() {
  const { name, type, data, handleReset } = useLandingCatalog();
  const { data: tagMatchData, status, cancel, search } = usePartialTagMatch([Discriminator.Vulnerability]);
  const { user } = useAuth();

  const itemIDs = data.items?.map(item => item.id);
  const [aliases, setAliases] = React.useState<string[]>([]);
  const [isEditing, setIsEditing] = React.useState(false);

  const [selection, setSelection] = React.useState<Option[]>([]);
  const mappedOption = React.useMemo(
    () => mapSelectionAndData(selection, tagMatchData?.vulnerability),
    [tagMatchData?.vulnerability, selection]
  );
  const selectionValues = selection.map(s => s.value);

  React.useEffect(() => setSelection(aliases?.map(alias => ({ content: alias, value: alias }))), [aliases]);

  const { task, status: taskStatus, errorProps } = useAsync();
  const isLoading = taskStatus === Status.pending;

  const addAlias = React.useCallback(
    (id: Ident, aliases: string[]) => {
      return task(postVulnerabilityAlias(id, aliases));
    },
    [task]
  );

  React.useEffect(() => setAliases(data.combined.aliases || []), [data.combined.aliases]);

  if (type === Discriminator.Attack) return null;
  if (!user.superuser && isEmpty(aliases)) return null;

  function handleListChange(value: Option[]) {
    setSelection(value);
  }

  function handleSearchChange(value: string) {
    cancel();
    if (value.length >= 2) search(value);
  }

  function handleSubmit(): void {
    itemIDs.forEach(async id => await addAlias(id, selectionValues));
    setAliases(selectionValues);
    setIsEditing(false);
  }

  return (
    <Accordion className='LandingSection-accordion' defaultExpanded={true} elevation={0}>
      <AccordionSummary className='LandingSection-accordionSummary' expandIcon={<Icon icon={faCaretUp} />}>
        <Typography variant='h4'>Aliases</Typography>
      </AccordionSummary>
      <AccordionDetails className='LandingSection-accordionDetails'>
        {isEditing ? (
          <EditContainer>
            <ApiError {...errorProps} />
            <Autosearch
              className='autosearch'
              onChange={handleListChange}
              onSearchChange={handleSearchChange}
              value={selection}
              option={mappedOption}
              isSearching={status === Status.pending}
              noOptionText='Search for an existing alias, or add a new one (min 2 characters)'
            />
            <div className='actions'>
              <Button
                disabled={isLoading}
                className='InlineEditorForm-cancel'
                onClick={() => setIsEditing(false)}
                aria-label='Cancel editing'
                variant='outlined'
              >
                <Icon icon={faX} />
              </Button>
              <Button
                type='button'
                className='InlineEditorForm-submit'
                aria-label='Submit editing'
                variant='outlined'
                disabled={isLoading}
                onClick={handleSubmit}
              >
                <Icon icon={faCheck} />
              </Button>
            </div>
          </EditContainer>
        ) : (
          <TruncateContainer>
            {aliases
              .filter(alias => name?.toLowerCase() !== alias?.toLowerCase())
              .sort((a, b) => a.localeCompare(b))
              .map(a => (
                <Chip
                  key={a}
                  label={a}
                  className={classNames('LandingSection-chip', type)}
                  component={Link}
                  to={`${Path.Collection}/${type}/${encodeURIComponent(a)}`}
                  clickable
                  icon={getArtifactIcon(type, true)}
                  onClick={handleReset}
                />
              ))}
            {Discriminator.Vulnerability === type && user.superuser && (
              <Tooltip arrow placement='top' title='Add vulnerability alias'>
                <ActionIconButton
                  icon={faPen}
                  size='small'
                  aria-label='Add vulnerability alias'
                  onClick={() => {
                    setIsEditing(true);
                  }}
                />
              </Tooltip>
            )}
          </TruncateContainer>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

function mapSelectionAndData(selection: Option[], data: AutocompleteResponse[typeof Discriminator.Vulnerability]) {
  return [
    ...selection,
    ...Object.keys(data ?? {})
      .filter(vulnerability => !selection.some(s => s.value === vulnerability))
      .map(vulnerability => ({
        content: vulnerability,
        value: vulnerability
      }))
  ];
}
