import React from 'react';

import useSnackOnError from 'hooks/useSnackOnError';

import { useAsync, Status } from 'storage';

import { Guid } from 'types/common';

import { deleteBASJob } from '../BAS.api';

type CancelJobBag = {
  cancelJob(): Promise<void>;
  status: Status;
};

export default function useCancelJob(jobGuid: Guid): CancelJobBag {
  const { error, setError, status, task } = useAsync();
  useSnackOnError(setError, error);

  const cancelJob = React.useCallback(() => {
    return task(deleteBASJob(jobGuid));
  }, [jobGuid, task]);

  return {
    cancelJob,
    status
  };
}
