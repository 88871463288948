import React from 'react';

import upperCase from 'lodash/upperCase';

import Chip from 'snap-ui/Chip';
import { styled } from 'snap-ui/util';

import { JobOutcome } from 'types/bas';

import { getJobOutcomeColors } from '../CampaignReport/CampaignReport.style';

const StyledChip = styled(Chip)`
  height: 1.55rem;
  border-radius: 3px;
  width: 94px;
`;

type JobOutcomeLabelProps = {
  outcome: JobOutcome;
};

export default function JobStateLabel({ outcome }: JobOutcomeLabelProps): JSX.Element {
  return <StyledChip sx={getJobOutcomeColors(outcome)} label={upperCase(outcome)} />;
}
