import React from 'react';

import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import { useField } from 'formik';

import BackdropLoader from 'snap-ui/BackdropLoader';
import Checkbox from 'snap-ui/Checkbox';
import { DataGrid, GridColDef, GridRowSelectionModel } from 'snap-ui/DataGrid';
import FormControlLabel from 'snap-ui/FormControlLabel';
import Icon from 'snap-ui/Icon';
import MenuItem from 'snap-ui/MenuItem';
import Paper from 'snap-ui/Paper';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import FormError from 'module/Form/FormError';

import { useCommissionedMachine } from 'provider';

import { CapAttackZip } from 'types/harbor';

import { handleBytes } from 'utilities/NumberUtil';
import { formatShortTimestamp } from 'utilities/TimeUtils';

import useCapAttackZips from '../useCapAttack';
import { extractFilename } from './CapAttackTable.helper';
import { SessionCreateForm } from './SessionCreate.type';

const StyledCapAttackTable = styled('div')`
  .table-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .table-view {
    height: 340px;
  }
`;

type Props = {
  className?: string;
  name: keyof SessionCreateForm;
};

function CapAttackTable({ className, name }: Props) {
  const { capZips, existingIsPending, remove } = useCapAttackZips();
  const { setActiveSave } = useCommissionedMachine();
  const [isZipsOverOneHourApart, setIsZipsOverOneHourApart] = React.useState<boolean>(false);
  const [, meta, helpers] = useField(name);

  const hasError =
    (meta.touched && !!meta.error && meta.value.length === 0) ||
    (meta.touched && !!meta.error && meta.value.length > 0 && isZipsOverOneHourApart);

  const hasUsedZips = capZips.some(zip => zip.used === true);
  const [filterData, setFilterData] = React.useState<CapAttackZip[]>([]);

  React.useEffect(() => {
    setActiveSave(meta.value.map(filename => filename.filename));
    formatZipsInMinutes(meta.value);
  }, [meta.value, setActiveSave]);

  React.useEffect(() => {
    setFilterData(capZips.filter(zip => zip.used === false) || capZips);
  }, [capZips]);

  const handleChange = React.useCallback(
    (zipSelection: GridRowSelectionModel) => {
      const zips = filterData.filter(zipFile => zipSelection.find(filterFile => filterFile === zipFile.filename));
      helpers.setValue(zips);
      helpers.setTouched(true);
    },
    [filterData, helpers]
  );

  const capAttackColumn: GridColDef[] = [
    {
      field: 'filename',
      headerName: 'File Name',
      renderCell: p => (
        <div onChange={() => handleChange} key={'filename'}>
          {extractFilename(p.row.item.filename)}
        </div>
      ),
      flex: 0.3,
      minWidth: 200,
      sortable: true
    },
    {
      field: 'last_modified',
      headerName: 'Last Modified',
      renderCell: p => (
        <>
          <div key={'last_modified'}>{formatShortTimestamp(p.row.item.last_modified)}</div>
        </>
      ),
      minWidth: 170,
      sortable: true
    },
    {
      field: 'used',
      headerName: 'Previously Used',
      renderCell: p => hasUsedZips && <div key={'used'}>{p.row.item.used ? 'Yes' : 'No'}</div>,
      minWidth: 120,
      sortable: true
    },
    {
      field: 'size',
      headerName: 'Size',
      renderCell: p => <div key={'size'}>{handleBytes(Number(p.row.item.size))}</div>,
      minWidth: 70,
      sortable: true
    },
    {
      field: 'actions',
      headerName: 'Delete',
      renderCell: p => (
        <Tooltip arrow placement='top-end' title={'Delete upload'}>
          <MenuItem onClick={() => remove(p.row.item)}>
            <Icon icon={faTrash} />
          </MenuItem>
        </Tooltip>
      ),
      minWidth: 70,
      sortable: true
    }
  ];
  return (
    <StyledCapAttackTable className={classnames('CapAttackTable', className)}>
      <div className='table-meta'>
        <label htmlFor='SessionUploadFileDropzone'>Uploaded Threat Captures</label>
        {hasUsedZips && (
          <FormControlLabel
            key='used-zip'
            labelPlacement='start'
            control={
              <Checkbox
                onChange={handleToggleUsedZips}
                checked={filterData.some(zip => zip.used === true)}
                className='checkbox'
              />
            }
            label={'Show used threat threat captures'}
          />
        )}
      </div>

      <Paper className='table-view'>
        <DataGrid
          checkboxSelection={true}
          onRowSelectionModelChange={handleChange}
          columns={capAttackColumn}
          rows={[
            ...filterData.map(_zipFile => {
              return {
                item: _zipFile,
                filename: _zipFile.filename,
                last_modified: _zipFile.last_modified,
                size: _zipFile.size,
                used: _zipFile.used
              };
            })
          ]}
          rowHeight={48}
          getRowId={row => row.item.filename}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableColumnReorder
          disableChildrenSorting
          disableMultipleRowSelection
          disableRowSelectionOnClick
        />
        {!existingIsPending && filterData?.length < 1 && (
          <Typography className='align-center'>No Files Found</Typography>
        )}
        {existingIsPending && <BackdropLoader contained />}
      </Paper>
      <FormError error={errorMessageBasedOnUser()} isVisible={hasError} indicator={false} />
    </StyledCapAttackTable>
  );
  function handleToggleUsedZips(_event: React.SyntheticEvent<Element, Event>, checked: boolean) {
    if (checked) {
      setFilterData(capZips);
    } else {
      setFilterData(capZips.filter(zip => zip.used === false));
      helpers.setValue(meta.value.filter(zip => zip.used === false));
    }
  }

  function formatZipsInMinutes(zips: CapAttackZip[]) {
    if (zips.length >= 2) {
      const zipTimestampMinutes = zips.map(zip => Date.parse(zip.last_modified) / 1000 / 60); // 1000 milliseconds in a second / 60 seconds in a minute
      const duration = Math.max(...zipTimestampMinutes) - Math.min(...zipTimestampMinutes);
      setIsZipsOverOneHourApart(duration > 60);
    } else if (zips.length <= 1) setIsZipsOverOneHourApart(false);
  }

  function errorMessageBasedOnUser() {
    if (isZipsOverOneHourApart && hasError) {
      return 'The time between captured threats is too far apart';
    }
    if (!isZipsOverOneHourApart && hasError) {
      return 'A CapAttack .zip file is required';
    }
  }
}

export default CapAttackTable;
