import { styled } from 'snap-ui/util';

/**
 * Putting buttons inside AccordionSummary is bad form. Keep them separate and wrap with this
 */
export const AccordionSummaryActionsWrapper = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(3)};
  align-items: center;

  .MuiAccordionSummary-root {
    flex: 1;
  }

  .MuiIconButton-root {
    max-height: 28px; // magic number based on icon and padding. otherwise, the hover effect is distorted with flex
  }
`;
