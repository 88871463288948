import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';

import Badge from 'snap-ui/Badge';
import Icon from 'snap-ui/Icon';
import { ListItemIcon, ListItemText } from 'snap-ui/List';
import MenuItem from 'snap-ui/MenuItem';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

const RecompileDot = styled(Badge)`
  .MuiBadge-dot {
    right: -16px;
    top: 13px;
  }
`;

export default function LanguageListMenuItem({
  disabled,
  onClick,
  closeMenu,
  icon,
  text,
  tooltip,
  dotInvisible = true
}: {
  disabled?: boolean;
  onClick(): void;
  closeMenu(): void;
  icon: IconProp;
  text: string;
  tooltip?: string;
  dotInvisible?: boolean;
}) {
  return (
    <Tooltip arrow placement='left' title={tooltip}>
      <MenuItem
        disabled={disabled}
        onClick={() => {
          onClick();
          closeMenu();
        }}
      >
        <ListItemIcon>
          <Icon icon={icon} />
        </ListItemIcon>
        <RecompileDot invisible={dotInvisible} color='primary' variant='dot'>
          <ListItemText>{text}</ListItemText>
        </RecompileDot>
      </MenuItem>
    </Tooltip>
  );
}
