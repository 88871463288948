import { topicEndpoint } from 'module/Feed/Feed.util';

import { ArtifactType } from 'types/common';
import { CompositeFilter, Ops } from 'types/filter';

import { snapattack } from '../snapattack';

const config = {
  params: {
    size: 0
  }
};

export function filterPublishedAnalyticCount(): Promise<number> {
  const payload: CompositeFilter = {
    op: Ops.or,
    items: [
      {
        field: 'visibility',
        op: Ops.equals,
        value: 'Published'
      }
    ]
  };
  return snapattack
    .post(topicEndpoint(ArtifactType.Analytic) + 'cached/v2/', payload, config)
    .then(r => r.data.total)
    .catch(() => 0);
}

export function filterPublishedSessionCount(): Promise<number> {
  const payload: CompositeFilter = {
    op: Ops.or,
    items: [
      {
        field: 'visibility',
        op: Ops.equals,
        value: 'Published'
      }
    ]
  };
  return snapattack
    .post(topicEndpoint(ArtifactType.Session) + 'cached/v2/', payload, config)
    .then(r => r.data.total)
    .catch(() => 0);
}

export function getFeaturedCount(
  topic: ArtifactType.Analytic | ArtifactType.Session,
  payload: CompositeFilter
): Promise<number> {
  return snapattack.post(topicEndpoint(topic) + 'cached/v2/', payload, config).then(r => r.data.total);
}
