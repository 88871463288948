import React from 'react';

import classnames from 'classnames';
import { useField } from 'formik';
import omit from 'lodash/omit';

import { IOC, SupportedIOC } from '../IOC.type';
import IOCList from './IOCList';

type IOCListFormikProps = {
  className?: string;
  name: string;
  iocOptions: IOC[];
};

export default function IOCListFormik({ className, name, iocOptions }: IOCListFormikProps): JSX.Element {
  const [field, , { setValue }] = useField(name);

  function handleChange(newSelectedIOCs: SupportedIOC[]) {
    setValue(newSelectedIOCs.map(i => omit(i, 'hasTemplate')));
  }

  React.useEffect(() => {
    setValue(iocOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(iocOptions)]);

  return (
    <IOCList
      className={classnames('IOCListFormik', className)}
      iocs={iocOptions.map(i => ({ ...i, hasTemplate: true }))}
      selectedIOCs={field.value}
      setSelectedIOCs={handleChange}
    />
  );
}
