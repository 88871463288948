import React from 'react';

import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import Icon from 'snap-ui/Icon';
import { ExternalLink } from 'snap-ui/Link';
import Menu from 'snap-ui/Menu';
import MenuItem from 'snap-ui/MenuItem';
import { styled } from 'snap-ui/util';

import { Path } from 'constants/paths';

import { CommonEvent, Engage, Fingerprint, Widget } from 'lib/Engagement';

import DisabledButton from 'module/Widgets/DisabledButton';

import { useAuth } from 'provider';

import { Language } from 'types/analytic';
import { Guid } from 'types/common';

import useIntegrationURL, { IntegrationURL } from './useIntegrationURL';

type IntegrationsLinkProps = {
  analyticGuid: Guid;
  language: Language;
  translatedCode: string;
  native: boolean;
};

const StyledDisabledButton = styled(DisabledButton)`
  padding: ${p => p.theme.spacing(3)};
  min-width: 20px;

  svg {
    font-size: ${p => p.theme.palette.dimension.svg};
  }

  :hover {
    border-radius: 50%;
  }
`;

const StyledMenu = styled(Menu)`
  max-height: 600px;
`;

const StyledMenuItem = styled(MenuItem)`
  margin-left: ${p => p.theme.spacing(4)};
  align-items: flex-end;
  justify-content: space-between;
  gap: ${p => p.theme.spacing(5)};
`;

export default function IntegrationsLink({
  analyticGuid,
  language,
  translatedCode,
  native
}: IntegrationsLinkProps): React.ReactElement {
  const integrationUrls = useIntegrationURL(language, translatedCode, native);
  const { permission, isSubscriber } = useAuth();

  const buttonRef = React.useRef();
  const [showMenu, setShowMenu] = React.useState(false);

  function toggleMenu() {
    setShowMenu(!showMenu);
  }

  const organizations = permission.sort((a, b) => a.name.localeCompare(b.name));

  function handleExternalLinkClick(url: IntegrationURL) {
    const orgName = permission.find(org => org.id === url.organization_id)?.name || 'Unknown Organization';
    Engage.track(
      Fingerprint.of(Widget.IntegrationLink).withCommon(CommonEvent.Click).withData({
        integration_guid: url.guid,
        integration_url: url.url,
        integration_org: orgName,
        detection_guid: analyticGuid
      })
    );
  }

  const noIntegrations = !integrationUrls.length;
  const isDisabled = noIntegrations || !translatedCode;
  const isMany = integrationUrls.length > 1;

  function TooltipContent(): JSX.Element {
    if (noIntegrations) {
      return (
        <>
          You don&apos;t have any integrations configured for this language.
          {isSubscriber && (
            <>
              {' '}
              You can configure them on the <Link to={Path.Integrations}>Settings page</Link>.
            </>
          )}
        </>
      );
    }

    if (!translatedCode) {
      return <>A valid detection query is required to search</>;
    }

    if (!language) {
      return <>Search in a live environment</>;
    }

    if (isMany) {
      return <>Search in a live {language.name} environment</>;
    }

    const orgName =
      permission.find(org => org.id === integrationUrls[0].organization_id)?.name || 'Unknown Organization';
    return (
      <>
        Search in {orgName}&apos;s {language.name} environment
      </>
    );
  }

  return (
    <>
      <StyledDisabledButton
        aria-label='search'
        title={<TooltipContent />}
        disabledTitle={<TooltipContent />}
        disabled={isDisabled}
        ButtonProps={{ ref: buttonRef, onClick: toggleMenu, variant: 'text', color: 'inherit' }}
      >
        <Icon icon={faSearch} />
      </StyledDisabledButton>
      <StyledMenu
        anchorEl={buttonRef.current}
        onClose={toggleMenu}
        open={showMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {organizations.map(org => {
          const integrations = integrationUrls.filter(i => i.organization_id === org.id);

          if (isEmpty(integrations)) return null;

          return [
            <MenuItem key={org.name} dense disabled>
              {org.name}
            </MenuItem>,
            ...integrations
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(url => (
                <ExternalLink key={url.guid} href={url.url} onClick={() => handleExternalLinkClick(url)}>
                  <StyledMenuItem dense>{url.name}</StyledMenuItem>
                </ExternalLink>
              ))
          ];
        })}
      </StyledMenu>
    </>
  );
}
