import React from 'react';

import { useRecommender } from 'aso/useRecommender';

import { CollectionRecommenderType, RecommendationResponse } from 'module/Curation/Curation.type';
import { useGlobalFilterContextValue } from 'module/GlobalFilter';

import { Status } from 'storage';

import { Guid } from 'types/common';

export type RecommenderInterface = {
  getRecommendation: (guid: Guid[], type: CollectionRecommenderType) => void;
  data: RecommendationResponse;
  status: Status;
  recommendationReset: () => void;
};

const RecommenderContext = React.createContext<RecommenderInterface>(null);

export function useRecommenderCatalog() {
  const context = React.useContext(RecommenderContext);

  if (!context) {
    throw new Error('useRecommenderCatalog must be used within a RecommenderProvider');
  }
  return context;
}

export function RecommenderProvider({
  guid,
  type,
  children
}: {
  guid: Guid;
  type: CollectionRecommenderType;
  children: React.ReactNode;
}) {
  const context = useRecommender();

  const [, setRecommendationContext] = useGlobalFilterContextValue('recommendations');

  React.useEffect(() => {
    setRecommendationContext(context.data);
  }, [context.data, setRecommendationContext]);

  React.useEffect(() => {
    if (guid) context.getRecommendation([guid], type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guid]);

  return <RecommenderContext.Provider value={context}>{children}</RecommenderContext.Provider>;
}
