import React from 'react';

import { faArrowLeft, faBars, faClockRotateLeft } from '@fortawesome/pro-solid-svg-icons';

import CircularProgress from 'snap-ui/CircularProgress';
import { ConfirmDialog } from 'snap-ui/Dialog';
import Menu from 'snap-ui/Menu';

import Path from 'constants/paths';

import { BurgerClicker, BurgerLink } from 'module/Layout/Artifact.widgets';
import { HamburgerFab } from 'module/Layout/Styled';

import { checkContentPermission } from 'services/authService';

import { Status } from 'storage';

import { ContentPermission } from 'types/auth';

import history from '../../../history';
import { AnalyticVersion } from '../Analytic.type';
import { useAnalyticCatalog } from '../core/AnalyticProvider';
import useRevert from './useRevert';

type AnalyticVersionMenuProps = {
  version: AnalyticVersion;
};

export default function AnalyticVersionMenu({ version }: AnalyticVersionMenuProps): JSX.Element {
  const [{ analytic, permissions, supplemental }] = useAnalyticCatalog();
  const { revert, status: revertStatus } = useRevert(version);
  const buttonRef = React.useRef();
  const [showRevertConfirm, setShowRevertConfirm] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);
  const canEdit = checkContentPermission(permissions, ContentPermission.Edit);
  const isReverting = revertStatus === Status.pending;

  function closeRevertConfirm() {
    setShowRevertConfirm(false);
  }

  function handleRevert() {
    revert().then(() => {
      history.push(`${Path.Detection}/${analytic.guid}`);
      closeRevertConfirm();
    });
  }

  function toggleMenu() {
    setShowMenu(!showMenu);
  }

  return (
    <>
      <HamburgerFab aria-label='Detection actions' icon={faBars} onClick={toggleMenu} ref={buttonRef} />
      <Menu
        anchorEl={buttonRef.current}
        onClose={toggleMenu}
        open={showMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <BurgerLink
          to={{
            pathname: `${Path.Detection}/${analytic.guid}`,
            state: { item: analytic, permissions, supplementalItem: supplemental }
          }}
          title='Back to Current Version'
          icon={faArrowLeft}
        />
        {canEdit && (
          <BurgerClicker
            onClick={() => setShowRevertConfirm(true)}
            icon={faClockRotateLeft}
            title='Revert to this Version'
          />
        )}
      </Menu>
      <ConfirmDialog
        ConfirmProps={{
          children: isReverting ? (
            <>
              Reverting... <CircularProgress size={16} />
            </>
          ) : (
            'Revert'
          ),
          disabled: isReverting,
          onClick: handleRevert
        }}
        DialogProps={{ open: showRevertConfirm, onClose: closeRevertConfirm }}
        title='Revert detection'
      >
        Are you sure you want to revert the detection to this version?
      </ConfirmDialog>
    </>
  );
}
