import React from 'react';

import { getAnalyticAttackScripts } from 'module/AttackScript/AttackScript.service';
import { AttackScript } from 'module/AttackScript/AttackScript.type';

import { useAsync } from 'storage';

import { Guid } from 'types/common';

export default function useRecommendedAttackScripts() {
  const { data, run, status, reset } = useAsync<AttackScript[]>([]);

  const getRelated = React.useCallback(
    (guids: Guid[]) => {
      if (guids.length > 0) {
        run(getAnalyticAttackScripts(guids));
      }
    },
    [run]
  );

  return {
    getRelated,
    data,
    status,
    relatedReset: reset
  };
}
