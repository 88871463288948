import { styled } from 'snap-ui/util';

export const LogContentRoot = styled('dl')`
  margin-left: ${p => p.theme.spacing(8)};

  .LogContentRow.clickable dt {
    cursor: pointer;
  }

  dt {
    display: inline-block;
    color: ${p => p.theme.palette.primary.main};
    font-weight: 600;

    .button {
      padding: 0;

      &:after {
        content: ':';
      }

      &:hover {
        box-shadow: none !important;
        color: ${p => p.theme.palette.text.secondary} !important; /** TODO confirm */
      }
    }

    &.highlight {
      position: relative;
      color: ${p => p.theme.palette.success.main} !important;

      .property-name {
        color: ${p => p.theme.palette.success.main} !important;
      }

      &.parent button {
        // resetting the color for sub detections
        color: ${p => p.theme.palette.primary.main} !important;

        &:after {
          content: ':';
        }
      }

      .highlight-caret {
        position: absolute;
        left: -25px;
        top: 5px;
        color: ${p => p.theme.palette.success.main};
      }

      .highlight-caret.excluded {
        color: ${p => p.theme.palette.error.main};
      }

      .highlight-caret.unsatisfied {
        color: ${p => p.theme.palette.warning.main};
      }

      &.mod-40 {
        // Modifier.full
        .button {
          font-style: italic;
        }
      }
    }
  }

  dd {
    display: inline-block;
    margin-left: ${p => p.theme.spacing(2)};
    word-break: break-word;

    span {
      &.outline {
        border: 1px solid ${p => p.theme.palette.success.main};
      }

      &.outline.unsatisfied {
        border: 1px solid ${p => p.theme.palette.warning.main};
      }
      &.outline.excluded {
        border: 1px solid ${p => p.theme.palette.error.main};
      }
    }
  }

  .log-collapse {
    display: flex;

    & > dt {
      flex: 1;
      display: flex;
      align-items: center;

      &:after {
        content: '';
        height: 1px;
        background: ${p => p.theme.palette.text.secondary};
        flex: 1;
        margin-left: ${p => p.theme.spacing(2)};
      }
    }

    & > .button {
      color: ${p => p.theme.palette.text.secondary} !important;
      margin-right: ${p => p.theme.spacing(9)};
      padding: ${p => p.theme.spacing(1)};
      margin-left: ${p => p.theme.spacing(1)};
    }
  }
`;
