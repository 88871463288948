import React from 'react';

import Alert from 'snap-ui/Alert';
import { Option } from 'snap-ui/Autocomplete';
import { DialogProgressContainer, DisplayDialog } from 'snap-ui/Dialog';
import Placeholder from 'snap-ui/Placeholder';

import { SelectOrgModal } from 'module/Organization';
import useMigrateSession from 'module/Session/useMigrateSession';

import { CeleryState, Guid } from 'types/common';

type MigrateModalProps = {
  isOpen: boolean;
  handleCancel(): void;
  handleSuccess(): void;
  orgOptions: Option[];
  sessionGuid: Guid;
};

export default function MigrateModal(props: MigrateModalProps): JSX.Element {
  const { error, isMigrating, message, migrate, taskStatus } = useMigrateSession(props.sessionGuid);
  const isComplete = [CeleryState.success, CeleryState.failed].includes(taskStatus);

  return (
    <>
      <SelectOrgModal
        isOpen={props.isOpen && !isMigrating && !isComplete}
        onCancel={props.handleCancel}
        onSubmit={orgId => migrate(Number(orgId))}
        disabled={isMigrating || taskStatus === CeleryState.failed}
        orgOptions={props.orgOptions}
        autoSelect
      />
      <DisplayDialog
        DialogProps={{ open: props.isOpen && isMigrating, onClose: props.handleCancel }}
        title='Migrating Data...'
      >
        <DialogProgressContainer>
          <Placeholder variant='rectangular' height={10} animation='wave' />
        </DialogProgressContainer>
      </DisplayDialog>
      <DisplayDialog
        DialogProps={{ open: props.isOpen && isComplete, onClose: props.handleCancel }}
        title='Migration Complete'
      >
        {error ? (
          <Alert severity='error'>{error}</Alert>
        ) : message ? (
          <Alert severity={taskStatus === CeleryState.success ? 'success' : 'info'}>{message}</Alert>
        ) : null}
      </DisplayDialog>
    </>
  );
}
