import React from 'react';

import { faListAlt } from '@fortawesome/pro-solid-svg-icons';

import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { StrictReactNode } from 'types/core';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${p => p.theme.spacing(8)};
  gap: ${p => p.theme.spacing(3)};

  svg {
    height: 3.75rem;
  }
`;

type EmptyStateProps = {
  icon?: StrictReactNode;
  title: string;
  children?: React.ReactChild;
  className?: string;
};

const EmptyState = React.forwardRef<HTMLDivElement, EmptyStateProps>(function EmptyState(
  { icon, title, children, className },
  ref
) {
  icon = icon || <Icon icon={faListAlt} />;
  return (
    <Container className={className} ref={ref}>
      {icon}
      <Typography variant='h4'>{title}</Typography>
      {children}
    </Container>
  );
});

export default EmptyState;
