import React from 'react';

import useDemoMode from 'hooks/useDemoMode';

import { HyperTag } from 'module/Landing/Landing.type';
import { getAllAttackTags, getAllActionTags } from 'module/Tag';

import { useAuth } from 'provider';

import { Catalog, Scheme, Status, useAsync, useAsyncStorage } from 'storage';

import { getNistStats } from '../DetectionDashboard/DetectionDashboard.api';
import { DEMO_DEPLOY_NUMBERS } from './NistDashboard.const';
import { NistArtifact, NistControl } from './NistDashboard.type';

export default function useNist() {
  const { data, run, status } = useAsyncStorage<HyperTag[]>(Scheme.sessionStorage, Catalog.action, [] as HyperTag[]);
  const { isDemoMode } = useDemoMode();
  const {
    isSubscriber,
    user: { preferred_organization }
  } = useAuth();
  const defaultOrgId = preferred_organization?.guid;
  const displayDemoData = isDemoMode || !isSubscriber;
  const { data: nistStatData, run: nistStatRun, status: nistStatStatus } = useAsync<NistControl[]>([]);
  const {
    data: allTagData,
    run: allTagRun,
    status: allTagStatus
  } = useAsyncStorage<HyperTag[]>(Scheme.sessionStorage, Catalog.allAttackTags, [] as HyperTag[]);

  React.useEffect(() => {
    if (defaultOrgId) {
      run(() => getAllActionTags());
    }
  }, [defaultOrgId, run]);

  React.useEffect(() => {
    if (defaultOrgId) {
      allTagRun(() => getAllAttackTags());
    }
  }, [defaultOrgId, allTagRun]);

  React.useEffect(() => {
    if (defaultOrgId) {
      nistStatRun(getNistStats(defaultOrgId));
    }
  }, [defaultOrgId, nistStatRun]);

  const fetched = React.useMemo(() => {
    if (
      [status, nistStatStatus, allTagStatus].every(status => status === Status.resolved) &&
      allTagData.length > 0 &&
      data.length > 0
    ) {
      return true;
    }
  }, [allTagData, allTagStatus, data, nistStatStatus, status]);

  const nistTags = React.useMemo(() => {
    if (fetched) {
      return nistStatData.map((nistStat, index) => {
        const nistDetail = data.find(nistDetail => nistDetail.id === nistStat.tag_id);
        return {
          ...nistStat,
          detections_available: displayDemoData ? DEMO_DEPLOY_NUMBERS[index][0] : nistStat.detections_available,
          detections_deployed: displayDemoData ? DEMO_DEPLOY_NUMBERS[index][1] : nistStat.detections_deployed,
          tags: (nistDetail?.attacks || []).map(tag =>
            allTagData.find(allTag => {
              return allTag.id === tag;
            })
          ),
          detail: nistDetail,
          external_source_id: nistDetail?.external_source_id,
          family: nistDetail?.external_source_id?.match(/[a-zA-Z]+(?=-)/)[0]
        };
      });
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetched]);

  return {
    nistTags: nistTags as NistArtifact[],
    isReady: fetched,
    status: allTagStatus
  };
}
