import React from 'react';

import { Option } from 'snap-ui/Autocomplete';

import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import MarkdownFormik from 'module/Form/MarkdownFormik';
import TextFieldFormik from 'module/Form/TextFieldFormik';
import { Form, TagDivider } from 'module/Layout/Artifact.widgets';
import { Discriminator, TagAutocompleteFormik } from 'module/Tag';

type Props = {
  organization: Option[];
};

export default function CurationForm(props: Props) {
  return (
    <Form>
      <TextFieldFormik label='Owner' name='owner' disabled />
      <AutocompleteFormik label='Organization' name='organization' options={props.organization} disableUserAdditions />
      <TextFieldFormik label='Title' name='name' required />
      <MarkdownFormik label='Description' name='description' />
      <TagDivider />
      <TagAutocompleteFormik name='attack_names' multiple discriminator={Discriminator.Attack} />
      <TagAutocompleteFormik name='actor_names' multiple discriminator={Discriminator.Actor} />
      <TagAutocompleteFormik name='software_names' discriminator={Discriminator.Software} multiple />
      <TagAutocompleteFormik name='vulnerability_names' discriminator={Discriminator.Vulnerability} multiple />
    </Form>
  );
}
