import React from 'react';

import { faShare } from '@fortawesome/pro-solid-svg-icons';

import { Option } from 'snap-ui/Autocomplete';

import { getGroups } from 'apis/resources/group';

import { DEFAULT_ROLES } from 'constants/organization';

import { ApiError } from 'module/ApiError';
import { BurgerClicker } from 'module/Layout/Artifact.widgets';
import { SelectOrgModal } from 'module/Organization';
import { PreferredOrgIcon } from 'module/Widgets/RequestPreferredOrg/PreferredOrg.helper';

import { useAuth } from 'provider';

import { Status, useAsync } from 'storage';

import { Group } from 'types/auth';
import { Guid, Ident } from 'types/common';

import { shareCollection } from './Collection.service';

/**
 * Not a general share. But specific to training orgs
 */
export default function UseForTrainingButton(
  props: {
    guid: Guid;
    orgId: Ident;
    disabled?: boolean;
    onCancel(): void;
  } & Pick<BurgerClicker, 'TooltipProps'>
) {
  const { guid, disabled, onCancel, TooltipProps } = props;
  const [isOpen, setOpen] = React.useState(false);

  const handleCancel = () => {
    setOpen(false);
    if (onCancel) onCancel();
  };

  return (
    <>
      <BurgerClicker
        disabled={disabled}
        onClick={() => setOpen(true)}
        icon={faShare}
        title='Use for Training'
        TooltipProps={TooltipProps}
      />
      {isOpen && <UseForTraining guid={guid} onCancel={handleCancel} />}
    </>
  );
}

function UseForTraining(props: { guid: Guid; onCancel(): void }) {
  const { guid, onCancel } = props;
  const { defaultOrgId, permission } = useAuth();
  const { data: groups, run, status, task, errorProps } = useAsync<Group[]>([]);
  const busy = status === Status.pending;

  const handleSubmit = async (groupGuid: string) => {
    task(shareCollection(guid, groupGuid)).then(() => onCancel());
  };

  const options: Option[] = React.useMemo(() => {
    if (busy) return [];
    const trainingOrgs = permission.filter(p => p.isolated);
    const analyticGroups = groups.filter(
      g => g.role === DEFAULT_ROLES.AnalyticContributor && trainingOrgs.some(o => o.guid === g.organization.guid)
    );

    // Show the organization's name but use the AnalyticContributor group's guid
    return analyticGroups.map(({ guid, organization: o }) => ({
      content: o.id === defaultOrgId ? <PreferredOrgIcon>{o.name}</PreferredOrgIcon> : o.name,
      value: guid,
      label: o.name
    }));
  }, [busy, defaultOrgId, groups, permission]);

  React.useEffect(() => {
    run(getGroups());
  }, [run]);

  return (
    <SelectOrgModal
      busy={busy}
      disabled={busy}
      isOpen={true}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      orgOptions={options}
    >
      <ApiError {...errorProps} />
    </SelectOrgModal>
  );
}
