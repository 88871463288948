import React from 'react';

import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { default as classnames } from 'classnames';
import type { Identifier, XYCoord } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';

import Icon from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

import { StrictReactNode } from 'types/core';

type DragDropBoxProps = {
  className?: string;
  children: StrictReactNode;
  index: number;
  type?: string;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
};

type DragItem = {
  index: number;
  type: string;
};

const DragDropBoxContainer = styled('div')`
  cursor: grab;
  opacity: 1;

  &.dragging {
    opacity: 0.1;
  }

  .grip-icon {
    visibility: hidden;
    align-self: center;
  }

  &:hover .grip-icon {
    visibility: visible;
  }
`;

// https://react-dnd.github.io/react-dnd/examples/sortable/simple
export default function DragDropBox({ children, className, index, type = 'DEFAULT', moveItem }: DragDropBoxProps) {
  const ref = React.useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverTopThirdY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 3;
      const hoverBottomThirdY = ((hoverBoundingRect.bottom - hoverBoundingRect.top) * 2) / 3;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverTopThirdY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverBottomThirdY) {
        return;
      }

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    item: { index, type },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  drag(drop(ref));
  return (
    <DragDropBoxContainer
      ref={ref}
      className={classnames(className, {
        dragging: isDragging
      })}
      data-handler-id={handlerId}
    >
      <Icon className='grip-icon' icon={faGripDotsVertical} />
      {children}
    </DragDropBoxContainer>
  );
}
