import React from 'react';

import classNames from 'classnames';
import { useField } from 'formik';
import uniqBy from 'lodash/uniqBy';

import { Autocomplete, AutocompleteProps, Option } from 'snap-ui/Autocomplete';
import Chip from 'snap-ui/Chip';
import Tooltip from 'snap-ui/Tooltip';

type MultivalueTextfieldFormikProps = {
  className?: string;
  label?: string;
  required?: boolean;
  name: string;
  helperText?: string;
  warningText?: string;
  disabled?: boolean;
  disableClearable?: boolean;
  inputValidator?(input: string): boolean;
};

const splitRegex = new RegExp(/[;\s|,]/g);

export default function MultivalueTextfieldFormik({
  helperText,
  warningText,
  ...props
}: MultivalueTextfieldFormikProps): JSX.Element {
  const [field, meta, helpers] = useField(props.name);
  const hasError = !!(meta.touched && meta.error);
  const hasWarning = !!(meta.touched && warningText);

  function handleChange(value: Option[]): void {
    helpers.setValue(value ? value.map(val => val.value) : []);
    helpers.setTouched(true, false);
  }

  function handleInputChange(value: string): void {
    if (value && value.match(splitRegex)) {
      const newValue = [...field.value, value];
      handleChange(transformValue(newValue));
    }
  }

  function rerunValidation() {
    helpers.setTouched(true);
  }

  function getDisplayValue(value: undefined | null | string[]): AutocompleteProps['value'] {
    if (value === undefined || value === null) {
      return [] as Option[];
    }

    return value.map((val: string) => {
      return {
        content: val,
        value: val,
        valid: props.inputValidator ? props.inputValidator(val) : true
      };
    });
  }

  function transformValue(newValues: (Option | string)[]): Option[] {
    const transformedValues = newValues
      .flatMap(newValue => {
        const value = typeof newValue === 'string' ? newValue : newValue.value;
        return value.split(splitRegex).filter(Boolean);
      })
      .map(val => {
        const trimmedVal = val.trim();
        if (trimmedVal) return { content: trimmedVal, value: trimmedVal };
      });

    return uniqBy(transformedValues, 'value');
  }

  function renderTags(value, getTagProps): JSX.Element {
    return value.map((option, index) => {
      return option.valid ? (
        <Chip key={index} label={option.value} {...getTagProps({ index })} />
      ) : (
        <Tooltip key={index} arrow title={`${option.value} is not a valid email.`} wrap>
          <Chip label={option.value} {...getTagProps({ index })} color={'error'} />
        </Tooltip>
      );
    });
  }

  return (
    <Autocomplete
      className={classNames('Form-field', props.className, { required: props.required })}
      elevated
      error={hasError}
      helperText={hasError ? meta.error[0] : hasWarning ? warningText : helperText}
      onChange={handleChange}
      onClick={rerunValidation}
      onInputChange={handleInputChange}
      onBlur={rerunValidation}
      value={getDisplayValue(field.value)}
      options={[]}
      omitPopupIcon
      multiple
      transformValue={transformValue}
      renderTags={renderTags}
      {...props}
    />
  );
}
