import React from 'react';

import Alert from 'snap-ui/Alert';
import { DialogProgressContainer, DisplayDialog } from 'snap-ui/Dialog';
import Placeholder from 'snap-ui/Placeholder';
import { Tab } from 'snap-ui/Tabs';
import Tooltip from 'snap-ui/Tooltip';

import { Engage, Fingerprint, Widget } from 'lib/Engagement';

import { ApiError } from 'module/ApiError';
import May from 'module/May';

import { useAsync, Status } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { Guid } from 'types/common';

import { downloadFile } from 'utilities/FileUtils';

import { exportSessionFiles } from './Session.api';

export default function SessionExport({
  sessionGuid,
  sessionName
}: {
  sessionGuid: Guid;
  sessionName: string;
}): JSX.Element {
  const { errorProps, status, task } = useAsync<Blob>();
  const [isOpen, setIsOpen] = React.useState(false);

  function handleExport() {
    setIsOpen(true);
    Engage.track(Fingerprint.of(Widget.SessionExport).withData({ sessionGuid, sessionName }));
    task(exportSessionFiles(sessionGuid)).then(file => {
      downloadFile(file, `${sessionName || sessionGuid}.zip`);
    });
  }

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <May I={FunctionalPermission.ExportThreat}>
      <>
        <Tooltip arrow placement='top' title='Download threat capture files'>
          <Tab label='Export' onClick={handleExport} />
        </Tooltip>
        <DisplayDialog
          DialogProps={{ open: isOpen && status === Status.pending, onClose: handleClose }}
          title='Export in progress'
        >
          <Alert severity='info'>We are exporting your data.</Alert>
          <DialogProgressContainer>
            <Placeholder variant='rectangular' height={10} animation='wave' />
          </DialogProgressContainer>
        </DisplayDialog>
        <DisplayDialog
          DialogProps={{ open: isOpen && status === Status.resolved, onClose: handleClose }}
          title='Export complete'
        >
          <Alert severity='success'>The data export is complete.</Alert>
        </DisplayDialog>
        <DisplayDialog
          DialogProps={{ open: isOpen && status !== Status.pending && !!errorProps, onClose: handleClose }}
          title='Something went wrong'
        >
          <ApiError {...errorProps} />
        </DisplayDialog>
      </>
    </May>
  );
}
