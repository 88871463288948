import React from 'react';

import { faCheck, faThumbsUp, faX } from '@fortawesome/pro-solid-svg-icons';

import Button from 'snap-ui/Button';
import Fade from 'snap-ui/Fade';
import Icon from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

import { Engage, Fingerprint, Widget } from 'lib/Engagement';

import { ControlFriendlyName } from 'module/Metadata/Control';

import { StrictReactNode } from 'types/core';

import { ReadonlyControl } from '../Metadata/Metadata.widgets';
import { useInlineEditor } from './useInlineEditor';

const Container = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${p => p.theme.spacing(2)};

  .Control {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .InlineEditorForm-actions {
    grid-column-start: 4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: ${p => p.theme.spacing(3)};
  }
`;

type Editor = ReturnType<typeof useInlineEditor>;

type InlineEditorForm<T> = {
  children: StrictReactNode;
  controlled?: boolean;
  data: T;
  editor: Editor;
  enabled: boolean;
  path: string;
  title: string;
  type: string;
};

export function InlineEditorForm<T>(props: InlineEditorForm<T>) {
  const { children, controlled, data, editor, enabled, path, title, type } = props;
  const [isActive, setIsActive] = React.useState(false);
  const [successFade, setSuccessFade] = React.useState(false);
  if (controlled && data && (!enabled || !editor.isEditing)) return <>{children}</>;
  if (!enabled) return <ReadonlyControl title={title} data={data} onClick={editor.toggleEditing} disabled />;
  if (!editor.isEditing) return <ReadonlyControl title={title} data={data} onClick={editor.toggleEditing} pre />;
  return (
    <Container>
      {children}
      <div className='InlineEditorForm-actions'>
        <Fade
          in={successFade}
          timeout={1250}
          unmountOnExit
          onEntered={() => setSuccessFade(false)}
          onExited={() => editor.toggleEditing()}
        >
          <div>
            <Icon icon={faThumbsUp} color='success' />
          </div>
        </Fade>
        {isActive && <Icon.SpinnerProgress />}
        <Button
          disabled={isActive}
          className='InlineEditorForm-cancel'
          onClick={() => {
            editor.setError(null);
            editor.setQueue(data);
            editor.toggleEditing();
          }}
          aria-label='Cancel editing'
          variant='outlined'
        >
          <Icon icon={faX} />
        </Button>
        <Button
          type='button'
          className='InlineEditorForm-submit'
          aria-label='Submit editing'
          variant='outlined'
          disabled={isActive || editor.queue === data}
          onClick={async () => {
            if (editor.update) {
              setIsActive(true);
              setSuccessFade(false);
              Engage.track(
                Fingerprint.of(Widget.MetadataInline).withData({
                  data,
                  path,
                  title,
                  control: ControlFriendlyName[type]
                })
              );
              await editor
                .update(path, editor.queue || undefined) // undefined is only way to erase. Null fails validation. Empty string breaks FE logic
                .then(() => {
                  setSuccessFade(true);
                })
                .catch(error => {
                  editor.setError({
                    ...error,
                    title: `${error.title} ${editor.queue}`
                  });
                  editor.setQueue(null);
                });
              setIsActive(false);
            }
          }}
        >
          <Icon icon={faCheck} />
        </Button>
      </div>
    </Container>
  );
}
