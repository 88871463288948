import React from 'react';

import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';

import { Option } from 'snap-ui/Autocomplete';
import { ActionIconButton } from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import TextField from 'snap-ui/TextField';
import Tooltip from 'snap-ui/Tooltip';

import useFeed from 'aso/useFeed';

import { BASJob } from 'module/BAS/BAS.type';
import { useFilterRegistry } from 'module/GlobalFilter';
import { DetectionDeploymentStatus } from 'module/GlobalFilter/Filters/DeployedEnvironment';
import { useArtifactOptions } from 'module/Search/useAutocomplete';

import { usePushSnack } from 'provider';
import { useBASTools } from 'provider/BASTools';

import { Status, useAsync } from 'storage';

import { BASJobOutcomePayload } from 'types/bas';
import { ArtifactType, Guid, Visibility } from 'types/common';

import { putBASJob } from '../BAS.api';
import JobOutcomeFormDialog, { JobOutcomeFormDialogPayload } from '../JobOutcomes/JobOutcomeFormDialog';
import { DetectionOption, PaginateModalContainer } from '../JobOutcomes/JobOutcomes.style';

export type PaginateModalCatalog = {
  openId: Guid;
  setOpenId(openId: Guid): void;
  items: BASJob[];
  detectionOptions: Option[];
};

const PaginateModalContext = React.createContext<PaginateModalCatalog>(null);
PaginateModalContext.displayName = 'PaginateModalContext';

const usePaginateModal = (): PaginateModalCatalog => {
  const context = React.useContext(PaginateModalContext);

  if (!context) {
    throw new Error('usePaginateModal must be used within the PaginateModalContext');
  }

  return context;
};

type PaginateModalProviderProps = {
  children: React.ReactNode;
  items: BASJob[];
  refreshJobs(): void;
  updateOutcomes(jobId: Guid, outcomes: BASJobOutcomePayload[]): void;
};

function PaginateModalProvider({
  children,
  items = [],
  refreshJobs,
  updateOutcomes
}: PaginateModalProviderProps): React.ReactElement {
  const [openId, setOpenId] = React.useState<Guid>(null);

  const { tools, addTools } = useBASTools();
  const pushSnack = usePushSnack();
  const { error, status: basStatus, task } = useAsync<BASJob>();
  const { generateQuery } = useFilterRegistry();

  const job = items.find(item => item.guid === openId);
  const deployedQuery = React.useMemo(
    () =>
      generateQuery(ArtifactType.Analytic, {
        deployedStatus: [DetectionDeploymentStatus.success],
        visibility: [Visibility.Published]
      }),
    [generateQuery]
  );

  const { items: deployedDetections } = useFeed(ArtifactType.Analytic, deployedQuery, {
    page: 1,
    size: 5000
  });

  const { options } = useArtifactOptions(ArtifactType.Analytic);

  const detectionOptions = React.useMemo(() => {
    return options.map(accessible => {
      const deployed = deployedDetections.find(deployed => deployed.guid === accessible.value);
      return {
        content: (
          <DetectionOption>
            {accessible.label}
            {deployed && (
              <Tooltip arrow title='Detection is deployed' wrap>
                <Icon.Success />
              </Tooltip>
            )}
          </DetectionOption>
        ),
        label: accessible.label,
        value: accessible.value
      };
    });
  }, [options, deployedDetections]);

  const catalog: PaginateModalCatalog = {
    openId,
    setOpenId,
    items,
    detectionOptions
  };

  return (
    <PaginateModalContext.Provider value={catalog}>
      <>
        {children}
        <JobOutcomeFormDialog
          isLoading={basStatus === Status.pending}
          apiError={error}
          onClose={handleCloseEditor}
          onSubmit={handleSubmitEditor}
          open={!!openId}
          job={job}
        />
      </>
    </PaginateModalContext.Provider>
  );

  function handleCloseEditor(dirty?: boolean) {
    setOpenId(null);
    if (dirty) refreshJobs();
  }

  function handleSubmitEditor(values: JobOutcomeFormDialogPayload, closeWindow: boolean) {
    const newTools = values.outcomes.map(outcome => outcome.tool).filter(toolUsed => !tools.includes(toolUsed));
    if (!isEmpty(newTools)) addTools(newTools);

    updateOutcomes(job.guid, values.outcomes);
    task(putBASJob(job.guid, values)).then(() => {
      pushSnack(`Outcomes updated.`, 'info', 'center', 'bottom', 5000);
      if (closeWindow) handleCloseEditor(closeWindow);
    });
  }
}

type PaginateModalProps = {
  onSubmit(values, refresh);
};

function PaginateModal(props: PaginateModalProps): JSX.Element {
  const { openId, setOpenId, items } = usePaginateModal();
  const currentIndex = items.findIndex(item => item.guid === openId);
  const { values, dirty, validateForm } = useFormikContext<JobOutcomeFormDialogPayload>();

  const [isDirty, setIsDirty] = React.useState<boolean>(dirty);

  React.useEffect(() => {
    if (dirty) setIsDirty(dirty);
  }, [dirty]);

  const changeToItemAtIndex = React.useCallback(
    (index: number) => {
      let newIndex = Number(index);
      if (isNaN(newIndex) || newIndex <= 0) newIndex = 0;
      if (newIndex > items.length) newIndex = items.length;

      setOpenId(items[newIndex].guid);
    },
    [setOpenId, items]
  );

  const onNextPage = () => {
    if (isDirty) {
      validateForm().then(errors => {
        if (isEmpty(errors)) {
          props.onSubmit(values, false);
          changeToItemAtIndex(currentIndex + 1);
          setIsDirty(false);
        }
      });
    } else {
      changeToItemAtIndex(currentIndex + 1);
    }
  };

  const onPrevPage = () => {
    if (isDirty) {
      validateForm().then(errors => {
        if (isEmpty(errors)) {
          props.onSubmit(values, false);
          changeToItemAtIndex(currentIndex - 1);
          setIsDirty(false);
        }
      });
    } else {
      changeToItemAtIndex(currentIndex - 1);
    }
  };

  const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeToItemAtIndex(Number(e.target.value) - 1);
  };

  return (
    <PaginateModalContainer className='PaginateModal'>
      <Tooltip arrow placement='top' title='Previous - save and move back' wrap>
        <ActionIconButton
          aria-label='Previous Test Case'
          icon={faChevronLeft}
          onClick={onPrevPage}
          disabled={currentIndex <= 0}
        />
      </Tooltip>
      <TextField value={currentIndex + 1} onChange={onTextFieldChange} />
      {'/'}
      <span>{items.length}</span>
      <Tooltip arrow placement='top' title='Next - save and move forward' wrap>
        <ActionIconButton
          aria-label='Next Test Case'
          icon={faChevronRight}
          onClick={onNextPage}
          disabled={currentIndex >= items.length}
        />
      </Tooltip>
    </PaginateModalContainer>
  );
}

export { PaginateModal, PaginateModalProvider, usePaginateModal };
