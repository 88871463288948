import React from 'react';

import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import Accordion, { AccordionDetails, AccordionSummary } from 'snap-ui/Accordion';
import Chip from 'snap-ui/Chip';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';

import Path from 'constants/paths';

import { getArtifactIcon } from 'module/Layout/Artifact.helper';
import TruncateContainer from 'module/Layout/TruncateContainer';
import { Discriminator } from 'module/Tag';
import TagLandingPreview from 'module/Widgets/TagLandingPreview';

import { useLandingCatalog } from '../LandingProvider';

export default function VulnerabilityTags() {
  const { source, handleReset } = useLandingCatalog();

  if (isEmpty(source.vulnerabilities)) return null;
  return (
    <Accordion className='LandingSection-accordion' defaultExpanded={source.vulnerabilities.length > 0} elevation={0}>
      <AccordionSummary className='LandingSection-accordionSummary' expandIcon={<Icon icon={faCaretUp} />}>
        <Typography variant='h4'>Vulnerabilities</Typography>
      </AccordionSummary>
      <AccordionDetails className='LandingSection-accordionDetails'>
        <TruncateContainer>
          {Object.values(source.vulnerabilities)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(value => (
              <div key={value.id}>
                <div className='LandingSection-accordionDetails-section'>
                  <TagLandingPreview tag={value}>
                    <Chip
                      className='LandingSection-chip vulnerability'
                      label={value.name}
                      component={Link}
                      to={`${Path.Collection}/${value.discriminator}/${encodeURIComponent(value.name)}`}
                      clickable
                      onClick={handleReset}
                      icon={getArtifactIcon(Discriminator.Vulnerability, true)}
                    />
                  </TagLandingPreview>
                </div>
              </div>
            ))}
        </TruncateContainer>
      </AccordionDetails>
    </Accordion>
  );
}
