import React from 'react';

import { pingCaptureStatus } from 'apis/resources/harbor';

import { ErrorProps, Status, useAsync } from 'storage';

import { Guid, Url } from 'types/common';
import { CaptureStatus } from 'types/harbor';

import { getActiveSession, getAvailableHosts, killActiveSession, saveActiveSession, startSession } from './Session.api';
import { CommissionedHost, StartSessionRequest } from './Session.type';

export interface MachineInterface {
  data: CommissionedHost[];
  setData(d: CommissionedHost[]): void;
  status: Status;
  available(): void;
  active(): Promise<CommissionedHost[]>;
  commission(payload: StartSessionRequest): Promise<CommissionedHost[]>;
  kill(): Promise<string>;
  save(): Promise<Url[]>;
  ping(taskGuid: Guid): Promise<CaptureStatus>;
  error: any;
  errorProps: ErrorProps;
}

/**
 * Returns all available VM's with callbacks to refresh and commission
 */
export default function useMachine(hosts = []): MachineInterface {
  const { data, run, task, status, error, errorProps, setData } = useAsync<CommissionedHost[]>(hosts);

  const available = React.useCallback(() => {
    run(getAvailableHosts());
  }, [run]);

  const commission = React.useCallback(
    (payload: StartSessionRequest): Promise<CommissionedHost[]> => {
      return task(startSession(payload));
    },
    [task]
  );

  const kill = React.useCallback((): Promise<string> => {
    return task(killActiveSession());
  }, [task]);

  const active = React.useCallback((): Promise<CommissionedHost[]> => {
    return task(getActiveSession());
  }, [task]);

  const save = React.useCallback((): Promise<Url[]> => {
    return task(saveActiveSession());
  }, [task]);

  const ping = React.useCallback(
    (taskGuid: Guid): Promise<CaptureStatus> => {
      return task(pingCaptureStatus(taskGuid));
    },
    [task]
  );

  const result = React.useMemo(() => {
    return {
      data,
      active,
      available,
      commission,
      kill,
      save,
      ping,
      status,
      error,
      errorProps,
      setData
    };
  }, [active, available, commission, data, error, errorProps, kill, save, ping, setData, status]);

  return result;
}
