import { IntelReferences } from './Intel.type';

export const IntelReferencesShell: IntelReferences = {
  comments: [],
  files: [],
  references: [],
  reference_counts: null,
  indicator_count: 0,
  indicators: []
};
