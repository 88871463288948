import React from 'react';

import Button from 'snap-ui/Button';
import Divider from 'snap-ui/Divider';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';

import useTitle from 'hooks/useTitle';

import { useAlphaSetting, AlphaFeature, AlphaToggle } from 'module/AlphaFeatures';
import PreferenceFilterDialog from 'module/Filter/PreferenceFilterDialog';

import { updateCurrentUser, useAuth, useAuthInterface } from 'provider';

import { Status, useAsync } from 'storage';

import { DisplayName } from './DisplayName';
import UserOptionDefaultLanguage from './UserOptionDefaultLanguage';
import UserOptionDemoMode from './UserOptionDemoMode';
import UserOptionPreferredOrg from './UserOptionPreferredOrg';
import { ColumnContainer } from './UserPreferences.style';

type Props = {
  className?: string;
};

function UserPreferences({ className }: Props) {
  useTitle('User Preferences');
  const { user } = useAuth();
  const { fetch } = useAuthInterface();
  const [showFilterDialog, setFilterDialog] = React.useState<boolean>(false);
  const { status, task } = useAsync();
  const { canEnableAlpha } = useAlphaSetting(AlphaFeature.AlphaSetting);

  const updateUser = (default_feed_filter: Record<string, string | string[]>) => {
    task(updateCurrentUser({ preference: { default_feed_filter: default_feed_filter } }).then(() => fetch()));
  };

  return (
    <ColumnContainer className={className}>
      <Typography variant='h1'>User Preferences</Typography>
      <div className='UserPreference-row UserPreference-name'>
        <DisplayName />
      </div>
      <Divider />
      <div className='UserPreference-row'>
        <UserOptionPreferredOrg />
        <UserOptionDemoMode />
      </div>
      <Divider />
      <UserOptionDefaultLanguage />
      {canEnableAlpha && (
        <>
          <Divider />
          <AlphaToggle />
        </>
      )}
      <Divider />
      <div className='UserPreference-row UserPreference-filter'>
        <Tooltip
          arrow
          title="Default filters set a base search filter across the application.
You can use default filters to restrict the content to what's relevant to your organization."
          placement='top-start'
        >
          <div>
            <span className='UserPreference-message'>Set Default Filter</span>
            <Icon.Info />
          </div>
        </Tooltip>
        <Button
          variant='outlined'
          id='FilterNamed'
          ariaLabel='Filter named'
          onClick={() => setFilterDialog(true)}
          fullWidth
        >
          Set Default Filters
        </Button>
      </div>
      {showFilterDialog && (
        <PreferenceFilterDialog
          initialValues={user.preference?.default_feed_filter || user.effective_preference?.default_feed_filter}
          isOpen={showFilterDialog}
          isPending={status === Status.pending}
          onClose={() => setFilterDialog(false)}
          onSave={updateUser}
        />
      )}
    </ColumnContainer>
  );
}

export default UserPreferences;
