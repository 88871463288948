import React from 'react';

import { getFileType } from 'services/analyticService';

import { Language } from 'types/analytic';

type DownloadLinkProps = {
  children: React.ReactElement;
  filename: string;
  format: Language;
  value: string;
};

function DownloadLink(props: DownloadLinkProps): React.ReactElement {
  const [linkProps, setLinkProps] = React.useState<{
    href: string;
    download: string;
  }>();

  React.useEffect(() => {
    const blob = new Blob([props.value], { type: 'text/plain' });
    setLinkProps({
      href: URL.createObjectURL(blob),
      download: `${props.filename}.${getFileType(props.format)}`
    });
  }, [props.filename, props.format, props.value]);

  return React.cloneElement(props.children, linkProps);
}

export default DownloadLink;
