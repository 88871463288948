import React from 'react';

import {
  faCopy,
  faEdit,
  faEllipsisVertical,
  faEye,
  faEyeSlash,
  faSplit,
  faTrash
} from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import pick from 'lodash/pick';

import Badge from 'snap-ui/Badge';
import { MenuButton, MenuTrigger } from 'snap-ui/Menu';
import Placeholder from 'snap-ui/Placeholder';
import TableSortLabel from 'snap-ui/TableSortLabel';
import Tooltip from 'snap-ui/Tooltip';

import { SortControls } from 'hooks/useSort';

import Can from 'module/Can';
import { Integration } from 'module/Integration/Integration.type';
import May from 'module/May';

import { checkContentPermission } from 'services/authService';

import { Status } from 'storage';

import { LanguageExtended } from 'types/analytic';
import { ContentPermission, FunctionalPermission, Organization } from 'types/auth';

import { ConfigControls } from './ConfigProvider';
import { SigmaConfigButton } from './LanguageConfig.style';
import { isConfigNotEditable } from './LanguageConfig.util';
import LanguageListMenuItem from './LanguageListMenuItem';
import { LanguageControls } from './LanguageProvider';

type LanguageListRowProps = {
  integrations: Integration[];
  language: LanguageExtended;
  organizations: Organization[];
  languageControls: LanguageControls;
  configControls: ConfigControls;
};

export function languageListRow({
  integrations,
  organizations,
  language,
  languageControls,
  configControls
}: LanguageListRowProps): React.ReactNodeArray {
  const isHidden = languageControls.isLanguageHidden(language);
  const { name: selectedOrgName, guid: selectedOrgGuid } = pick(
    organizations.find(org => org.id === languageControls.selectedOrgId) || {
      name: 'Unknown Organization',
      guid: undefined
    },
    ['name', 'guid']
  );
  const visibilityTooltip = isHidden
    ? `${language.name} is hidden for ${selectedOrgName}`
    : `${language.name} is visible for ${selectedOrgName}`;
  const toggleTooltip = isHidden ? `Show for ${selectedOrgName}` : `Hide for ${selectedOrgName}`;
  return [
    language.name,
    language.organization.name,
    language.backend_key,
    <>
      {language.translate_configs.map((config, idx) => {
        const isNotEditable = isConfigNotEditable(config);
        return (
          <React.Fragment key={config.name}>
            {configControls.getControls(config.discriminator)?.status === Status.resolved ? (
              <>
                <SigmaConfigButton
                  className={classnames({
                    editable: !isNotEditable && checkContentPermission(config, ContentPermission.Edit)
                  })}
                  onClick={() =>
                    checkContentPermission(config, ContentPermission.Edit)
                      ? configControls.openEditor(config.discriminator, config.id)
                      : configControls.openDisplay(config.discriminator, config.id)
                  }
                  variant='text'
                >
                  {config.name}
                </SigmaConfigButton>
                {idx === language.translate_configs.length - 1 ? '' : ', '}
              </>
            ) : (
              <Placeholder variant='text' width={300} height={15} />
            )}
          </React.Fragment>
        );
      })}
    </>,
    <Tooltip arrow key='integration_count' placement='top' title={integrations.map(i => i.name).join(', ')}>
      <span>{integrations.length}</span>
    </Tooltip>,
    <Tooltip arrow key={language.id} placement='top' title={visibilityTooltip}>
      <span>{isHidden ? 'No' : 'Yes'}</span>
    </Tooltip>,
    <MenuTrigger
      key={language.id}
      trigger={
        <Badge
          className={`${language.name}Menu`}
          invisible={!language.needs_recompile}
          color={language.currently_recompiling ? 'success' : 'primary'}
          variant='dot'
        >
          <MenuButton aria-label={`${language.name} Menu`} icon={faEllipsisVertical} />
        </Badge>
      }
    >
      {({ toggle }) => [
        <Can key='edit' I={ContentPermission.Edit} this={language}>
          <LanguageListMenuItem
            onClick={() => languageControls.openEditor(language.id)}
            closeMenu={toggle}
            icon={faEdit}
            text='Edit'
          />
        </Can>,
        <Can key='recompile' I={ContentPermission.Edit} this={language}>
          <LanguageListMenuItem
            onClick={() => languageControls.openRecompileConfirm(language.id)}
            closeMenu={toggle}
            icon={faSplit}
            text='Recompile'
            dotInvisible={!language.needs_recompile}
            tooltip={
              language.currently_recompiling
                ? 'Recompile in progress'
                : language.needs_recompile
                ? 'Recompile required'
                : ''
            }
          />
        </Can>,
        <May key='clone' I={FunctionalPermission.CreateCompilationTarget}>
          <LanguageListMenuItem
            onClick={() => languageControls.openEditorToClone(language.id)}
            closeMenu={toggle}
            icon={faCopy}
            text='Clone'
          />
        </May>,
        <May key='hide' I={FunctionalPermission.CreateCompilationTarget}>
          <LanguageListMenuItem
            disabled={!languageControls.selectedOrgId}
            onClick={() => languageControls.toggleLanguage(language.id, selectedOrgGuid)}
            closeMenu={toggle}
            icon={isHidden ? faEye : faEyeSlash}
            text={toggleTooltip}
          />
        </May>,
        <Can key='delete' I={ContentPermission.Delete} this={language}>
          <LanguageListMenuItem
            onClick={() => languageControls.openDeleteConfirm(language.id)}
            closeMenu={toggle}
            icon={faTrash}
            text='Delete'
          />
        </Can>
      ]}
    </MenuTrigger>
  ];
}

export function languageListColumns({
  createSortHandler,
  order
}: Pick<
  SortControls<LanguageExtended & { _organizationName: string; integration_count: number; visible: boolean }>,
  'createSortHandler' | 'order'
>): React.ReactNodeArray {
  return [
    <TableSortLabel key='name' field='name' label='Name' sortHandler={createSortHandler('name')} order={order} />,
    <TableSortLabel
      key='_organizationName'
      field='organizationName'
      label='Organization'
      sortHandler={createSortHandler('_organizationName')}
      order={order}
    />,
    <TableSortLabel
      key='backend_key'
      field='backend_key'
      label='Compiler'
      sortHandler={createSortHandler('backend_key')}
      order={order}
    />,
    'Configuration',
    <TableSortLabel
      key='integration_count'
      field='integration_count'
      label='Integrations'
      sortHandler={createSortHandler('integration_count')}
      order={order}
    />,
    <TableSortLabel
      key='visible'
      field='visible'
      label='Visible'
      sortHandler={createSortHandler('visible')}
      order={order}
    />,
    ''
  ];
}
