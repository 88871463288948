import React from 'react';

import { inviteUsers } from 'apis/resources/auth';
import { addToGroup, removeFromGroup } from 'apis/resources/group';
import { getUsersForOrg } from 'apis/resources/organization';
import { updateUser } from 'apis/resources/user';

import { InviteUserPayload } from 'module/Setting/core/UserManagement/UserManagement';

import { useAuth } from 'provider';

import { Status, useAsync } from 'storage';

import { User, UserRequest } from 'types/auth';
import { Guid } from 'types/common';

import useAllUser from './useAllUser';

export type GroupMembership = { [index: Guid]: boolean };

type UserManagementState = {
  inviteUser(target: InviteUserPayload[]): Promise<void>;
  error?: string;
  taskError?: string;
  users: User[];
  resetError(): void;
  refresh(): void;
  refreshAllUsers(): void;
  status: Status;
  taskStatus: Status;
  update(userGuid: Guid, user: UserRequest): Promise<void>;
  updateGroups(user: Guid, groups: GroupMembership): Promise<void>;
};

export default function useUserManagement(organization: Guid): UserManagementState {
  const { user } = useAuth();

  const { data: users, error, status, run } = useAsync<User[]>([]);
  // separate useAsync to perform tasks on a user and have insight into the status of those tasks
  const { error: taskError, status: taskStatus, task } = useAsync<User[]>([]);
  const { data: allUsers, refresh: refreshAllUsers, status: allUsersStatus } = useAllUser();

  const refresh = React.useCallback(() => {
    if (organization && organization !== 'ALL') run(getUsersForOrg(organization));
  }, [run, organization]);

  const inviteUser = React.useCallback(
    async (target: InviteUserPayload[]) => {
      await task(inviteUsers(target));
      if (user.superuser) await refreshAllUsers();
      return refresh();
    },
    [user.superuser, task, refresh, refreshAllUsers]
  );

  const update = React.useCallback(
    async (userGuid: Guid, userRequest: UserRequest) => {
      await task(updateUser(userGuid, userRequest));
      if (user.superuser) await refreshAllUsers();
      return refresh();
    },
    [user.superuser, task, refresh, refreshAllUsers]
  );

  const updateGroups = React.useCallback(
    async (userGuid: Guid, groups: GroupMembership) => {
      const promises = [];
      for (const [groupGuid, member] of Object.entries(groups)) {
        if (member) {
          promises.push(addToGroup(groupGuid, userGuid));
        } else {
          promises.push(removeFromGroup(groupGuid, userGuid));
        }
        if (error) return Promise.resolve();
      }

      await task(Promise.all(promises));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [task, refresh, refreshAllUsers, organization]
  );

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  const resetError = React.useCallback(() => {
    task(Promise.resolve());
  }, [task]);

  return {
    inviteUser,
    error: error ? error.message || JSON.stringify(error.detail) || 'The server encountered an error' : undefined,
    taskError: taskError
      ? taskError.message || JSON.stringify(taskError.detail) || 'The server encountered an error'
      : undefined,
    users: organization == 'ALL' ? allUsers : users,
    resetError,
    refresh,
    refreshAllUsers,
    status: organization == 'ALL' ? allUsersStatus : status,
    taskStatus,
    update,
    updateGroups
  };
}
