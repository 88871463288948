import escapeRegExp from 'lodash/escapeRegExp';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import split from 'lodash/split';

import { EMPTY_KEY_VALUE_RULE } from 'constants/analytic';

import { FieldOption, KeyValueRule, SectionForm } from 'types/analytic';

export function getFilteredFields(
  isWinEvent: boolean,
  section: SectionForm,
  ruleIndex: number,
  fields: FieldOption[]
): FieldOption[] {
  if (isWinEvent)
    return fields.filter(field =>
      section.rules.every((rule, index) => {
        return ruleIndex === index || rule.field !== field.field;
      })
    );
  const eventIds = section.rules?.find(r => r.field === 'EventId')?.values || [];
  const eventFieldOptions = fields.filter(field => eventIds.some(eid => field.event_ids.includes(parseInt(eid, 10))));
  return eventFieldOptions.length ? eventFieldOptions : fields;
}

export function isOutOfEventOptionList(isWinEvent: boolean, rule: KeyValueRule, fields: FieldOption[]): boolean {
  if (isWinEvent || rule.field === 'EventID' || rule.field === '') return false;

  return !fields.some(e => e.field === rule.field);
}

export function fieldOptionError(isWinEvent: boolean, rule: KeyValueRule, fields: FieldOption[]): string | undefined {
  // special case - validate early to guide the user.
  if (rule.field === '' && isWinEvent && fields.length === 0) {
    return 'Select at least one Event ID before continuing.';
  }

  // don't validate if no input
  // don't validate EventID, it's a special case
  if (rule.field === '' || rule.field === 'EventID') return;

  if (isWinEvent && isOutOfEventOptionList(isWinEvent, rule, fields)) {
    return 'Field unknown with this set of event ids - testing may fail to find matching logs with unknown fields';
  }

  if (!fields.some(field => field.field === rule.field)) {
    return 'Field unkown for this log source - testing may fail to find matching logs with unknown fields';
  }
}

function removeTrailingOperator(condition: string): string {
  const trailingOperator = /(\s+(and|or|not)(\s+)?)$/;
  const fixed = condition.replace(trailingOperator, '');
  if (fixed === condition) return condition;
  return removeTrailingOperator(fixed);
}

export function fixupCondition(condition: string, nameToRemove: string): string {
  const operandRegex = '(\\s+(and|or)\\s+)';
  const escaped = escapeRegExp(nameToRemove);

  const nameWithLeadingOperand = `${operandRegex}?(${escaped})`;
  const nameWithTrailingButNotLeadingOperand = `(?<!${operandRegex})(${escaped})${operandRegex}`;

  const target = new RegExp(`(${nameWithTrailingButNotLeadingOperand})|(${nameWithLeadingOperand})`, 'g');

  let finalCondition = condition.replace(target, '').trim();

  const emptyParens = /\((\s+)?\)/g;
  finalCondition = finalCondition.replace(emptyParens, '');

  const parensAroundOneWord = /\((?:\s+)?(\w+)(?:\s+)?\)/g;
  finalCondition = finalCondition.replace(parensAroundOneWord, '$1');

  return removeTrailingOperator(finalCondition);
}

export function changeRule(
  section: SectionForm,
  ruleIndex: number,
  update: Partial<KeyValueRule>
): SectionForm['rules'] {
  return section.rules.map((rule, idx) => (idx === ruleIndex ? { ...rule, ...update } : rule));
}

export function changeRuleValue(
  section: SectionForm,
  ruleIndex: number,
  valueIndex: number,
  newValue: string
): SectionForm['rules'] {
  const values = section.rules[ruleIndex]?.values || [];
  return changeRule(section, ruleIndex, {
    values: values.length ? values.map((value, idx) => (idx === valueIndex ? newValue : value)) : [newValue]
  });
}

export function removeValueFromRule(section: SectionForm, ruleIndex: number, valueIndex: number): SectionForm['rules'] {
  const values = section.rules[ruleIndex]?.values || [];
  if (values.length <= 1) {
    if (ruleIndex === 0 && section.rules?.length === 1) {
      // short circtuit; if we would blank out the only rule, just return the default
      return [EMPTY_KEY_VALUE_RULE];
    }

    // delete the rule that would be empty
    return section.rules.filter((rule, idx) => idx !== ruleIndex);
  }

  const newValues = values.filter((value, idx) => idx !== valueIndex);
  return changeRule(section, ruleIndex, { values: newValues, all: newValues.length === 1 ? 'false' : undefined });
}

export function addValueToRule(section: SectionForm, ruleIndex: number): SectionForm['rules'] {
  return section.rules.map((rule, idx) => (idx === ruleIndex ? { ...rule, values: [...rule.values, ''] } : rule));
}

export function addRule(section: SectionForm): SectionForm['rules'] {
  return [...section.rules, EMPTY_KEY_VALUE_RULE];
}

export function splitCondition(condition: string): string[] {
  let modCondition = split(condition, ')').join(' )');
  modCondition = split(modCondition, '(').join('( ');

  return filter(split(modCondition, ' '), value => !isEmpty(value));
}

const operatorRegex = new RegExp(/(\band\b|\bor\b)/);
const notRegex = new RegExp(/(\bnot\b)/);

function determineNextExpectedType(term: string): [type: string, expectedNextType: string[], errorMessage: string] {
  if (term.match(operatorRegex)) {
    return ['OPERATOR', ['not', 'SECTION', 'OPEN_PAREN'], 'a "not", a section name, or a "("'];
  } else if (term.match(notRegex)) {
    return ['not', ['SECTION', 'OPEN_PAREN'], 'a section name or a "("'];
  } else if (term === '(') {
    return ['OPEN_PAREN', ['SECTION', 'OPEN_PAREN'], ' a section name or a "("'];
  } else if (term === ')') {
    return ['CLOSE_PAREN', ['CLOSE_PAREN', 'OPERATOR'], 'an operator or a ")"'];
  } else {
    return ['SECTION', ['CLOSE_PAREN', 'OPERATOR'], 'an operator or a ")"'];
  }
}

/**
 * @returns error message or "undefined" if expression is valid.
 */
export function validateExpression(expression: string): string {
  if (!expression) return 'Expression is invalid'; // empty
  const expressionParts = splitCondition(expression);
  const [first, ...rest] = expressionParts.slice();
  const [last] = expressionParts.slice(-1);

  let [firstType, nextTypeExpected, errorMessage] = determineNextExpectedType(first); // eslint-disable-line prefer-const
  const [lastType] = determineNextExpectedType(last);

  if (['OPERATOR', 'CLOSE_PAREN'].includes(firstType)) {
    return 'Expression should start with a section name, "not" or "("'; // Invalid start to expression, can only start with a section name, `not` or `(`
  }

  if (['OPERATOR', 'not', 'OPEN_PAREN'].includes(lastType)) {
    return 'Expression should end with a section name or ")"'; // Invalid END to expression, can only end with a section name or `)`
  }

  const openParenCount = expressionParts.filter(r => r === '(').length;
  const closeParenCount = expressionParts.filter(r => r === ')').length;

  if (openParenCount !== closeParenCount) return 'Expression has mismatched parentheses'; // Mismatched Parentheses

  for (const term of rest) {
    const [type, nextType, nextErrorMessage] = determineNextExpectedType(term);

    if (nextTypeExpected.includes(type)) {
      nextTypeExpected = nextType;
      errorMessage = nextErrorMessage;
    } else {
      return `Expression is invalid: received "${term}" but expected ${errorMessage}`;
    }
  }

  return;
}
