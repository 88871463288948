import React from 'react';

import { faSquareUp } from '@fortawesome/pro-regular-svg-icons';
import { useFormikContext } from 'formik';

import Button from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';

import {
  ArtifactWidgetFooter,
  ArtifactWidgetMenuWrapper,
  Author,
  BurgerCancel,
  BurgerDraft,
  BurgerPublish,
  BurgerReset,
  Menu,
  Publish,
  Title
} from 'module/Layout/Artifact.widgets';

import { Session } from '../Session.type';
import { SessionEditForm } from './SessionEdit';

type Props = {
  isDraft?: boolean;
  onCancel(): void;
  onDraft?(): void;
  onPublish(): void;
  permissionBlocked?: boolean;
  session: Session;
};

export default function SessionHeader(props: Props) {
  const { isDraft, onCancel, onDraft, onPublish, permissionBlocked, session } = props;
  const { dirty, isValid, resetForm, values } = useFormikContext<SessionEditForm>();

  return (
    <>
      <Title headerText={values.name} />
      <Author resource={session} type='session' />
      <ArtifactWidgetFooter>
        <div />
        <ArtifactWidgetMenuWrapper>
          {permissionBlocked ? (
            <>
              <Typography variant='body2'>You do not have permission to edit this threat</Typography>
              <Button color='error' onClick={onCancel}>
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Publish onClick={onPublish} disabled={!dirty || !isValid} startIcon={<Icon icon={faSquareUp} />}>
                Publish
              </Publish>
              <Menu dirty={dirty}>
                <BurgerCancel onClick={onCancel} />
                <BurgerPublish onClick={onPublish} disabled={!dirty || !isValid} />
                <BurgerDraft onClick={onDraft} disabled={!dirty || isDraft} />
                <BurgerReset onClick={resetForm} disabled={!dirty} />
              </Menu>
            </>
          )}
        </ArtifactWidgetMenuWrapper>
      </ArtifactWidgetFooter>
    </>
  );
}
