import React from 'react';

import { faPen } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';

import { Autocomplete, Option } from 'snap-ui/Autocomplete';
import { ActionIconButton } from 'snap-ui/Button';
import TextField from 'snap-ui/TextField';
import Tooltip from 'snap-ui/Tooltip';

import FieldPicker from 'module/IDE/core/FieldPicker';
import DelayedTextField from 'module/Widgets/DelayedTextField';

import { FieldOption, SectionForm } from 'types/analytic';

import { getFilteredFields, fieldOptionError } from './Detection.util';
import useIsWinEvent from './useIsWinEvent';

type FieldFieldProps = {
  disabled: boolean;
  fields: FieldOption[];
  name: string;
  onChange(value: string): void;
  rule: SectionForm['rules'][number];
  ruleIndex: number;
  section: SectionForm;
  ignoreValidation: boolean;
};

export function FieldField({
  disabled,
  fields,
  name,
  onChange,
  rule,
  ruleIndex,
  section,
  ignoreValidation
}: FieldFieldProps): JSX.Element {
  const [isPickerOpen, setIsPickerOpen] = React.useState(false);
  const isWinEvent = useIsWinEvent();

  function handleClosePicker(field?: string) {
    setIsPickerOpen(false);
    if (field) onChange(field);
  }

  if (ignoreValidation) {
    return (
      <DelayedTextField
        data-testid={`logField-${ruleIndex}`}
        className='field'
        label='Log Field'
        disabled={disabled}
        name={name}
        value={rule.field}
        onChange={onChange}
      />
    );
  }

  if (rule.field === 'EventID' && isWinEvent) {
    return (
      <TextField
        data-testid={`logField-${ruleIndex}`}
        className='field'
        label='Log Field'
        disabled={disabled}
        name={name}
        readOnly
        value={rule.field}
      />
    );
  }
  const fieldOptions = getFilteredFields(isWinEvent, section, ruleIndex, fields);
  const fieldOptionErrorMessage = fieldOptionError(isWinEvent, rule, fields);
  const isFieldDisabled = disabled || (isWinEvent && fieldOptions.length === 0);

  return (
    <>
      <Tooltip arrow className='field' placement='top' title={fieldOptionErrorMessage || ''} wrap>
        <Autocomplete
          testId={`logField-${ruleIndex}`}
          label='Log Field'
          className={classnames('field', !!fieldOptionErrorMessage && 'custom-field')}
          disabled={isFieldDisabled}
          name={name}
          onChange={onChange}
          options={fieldOptions.map(field => ({
            content: field.field,
            label: field.field,
            value: field.field
          }))}
          value={rule.field}
          placeholder='Log Field'
          endAdornment={
            <ActionIconButton
              aria-label='Open field selector dialog'
              className='ButtonEndAdornment'
              disabled={isFieldDisabled}
              icon={faPen}
              onClick={() => setIsPickerOpen(true)}
            />
          }
        />
      </Tooltip>
      <FieldPicker isOpen={isPickerOpen} isWinEvent={isWinEvent} onClose={handleClosePicker} fields={fields} />
    </>
  );
}

type ValueFieldProps = {
  disabled: boolean;
  name: string;
  onChange(value: string): void;
  value: string;
  ruleIndex: number;
};

export function ValueField({ disabled, name, onChange, value, ruleIndex }: ValueFieldProps): JSX.Element {
  return (
    <DelayedTextField
      data-testid={`expectedValue-${ruleIndex}`}
      label='Expected Value'
      className='value'
      disabled={disabled}
      name={name}
      onChange={onChange}
      value={value}
      placeholder='Expected Value'
    />
  );
}

type EventIDValueFieldProps = {
  disabled: boolean;
  eventIdOptions: Option[];
  name: string;
  onChange(value: string): void;
  showWarning: boolean;
  value: string;
};

export function EventIDValueField({
  disabled,
  eventIdOptions,
  name,
  onChange,
  showWarning,
  value
}: EventIDValueFieldProps): JSX.Element {
  return (
    <Tooltip
      arrow
      className='value'
      placement='left'
      title={
        showWarning
          ? 'Event ID unknown with this logsource and event type - testing may fail to find matching logs with unknown IDs'
          : ''
      }
      wrap
    >
      <Autocomplete
        label='Expected Value'
        className={classnames('value', showWarning && 'custom-event-id')}
        disabled={disabled}
        name={name}
        placeholder='Expected Value'
        onChange={onChange}
        options={eventIdOptions}
        value={value}
      />
    </Tooltip>
  );
}
