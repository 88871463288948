import React from 'react';

import MuiFormControlLabel from '@mui/material/FormControlLabel';

export type FormControlLabelProps = {
  className?: string;
  control: React.ReactElement<any, any>;
  label: string | React.ReactElement;
  name?: string;
  value?: string;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
};

export default function FormControlLabel(props: FormControlLabelProps) {
  return <MuiFormControlLabel {...props} />;
}
