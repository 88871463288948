import { ZodError } from 'zod';

const mapIssue = ({ path, message }, errors) => {
  const [name, index, field] = path;
  if (!field) return { [name]: [message] };

  errors[index] = {
    ...errors[index],
    [field]: [message]
  };
  return {
    [name]: errors
  };
};

export const getSchemaViolations = (errors: ZodError) =>
  errors.issues.reduce(
    (accumulated, issue) => ({ ...accumulated, ...mapIssue(issue, accumulated[issue.path[0]] || []) }),
    {}
  );
