import { snapattack } from 'apis';

import { Guid } from 'types/common';

import { SyntaxCheckerResponse } from './type';

export function syntaxCheckDetectionCode(
  integrationGuid: Guid,
  payload: { search: string; backend_key: string }
): Promise<SyntaxCheckerResponse> {
  return snapattack.post(`/integrations/${integrationGuid}/syntax/`, payload).then(r => r.data);
}
