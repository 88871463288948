import React from 'react';

import { Link } from 'react-router-dom';
import z from 'zod';

import CircularProgress from 'snap-ui/CircularProgress';

import { SSOLoadState } from 'aso/useSSO';

import Path from 'constants/paths';

import Formik from 'module/Form/Formik';
import TextFieldFormik from 'module/Form/TextFieldFormik';

import { Status } from 'storage';

import { Form, LoginButton } from '../Authentication.style';
import { RegisterLink } from '../Register';
import LoginNextForm from './LoginNext';

type LoginFormProps = {
  initialEmail: string;
  loading: boolean;
  sso: SSOLoadState;
  submitForm: (data: { email: string; password: string }) => void;
};

const LoginForm = ({ initialEmail, loading, sso, submitForm }: LoginFormProps): React.ReactElement => {
  const [email, setEmail] = React.useState<string>(initialEmail || '');
  const passwordRef = React.useRef();

  React.useEffect(() => {
    const password = passwordRef?.current as any;
    if (sso.status === Status.resolved && password) {
      password.focus();
    }
  }, [sso.status, passwordRef]);

  return (
    <>
      {(sso.status !== Status.resolved || !sso.isNotSSO) && (
        <LoginNextForm
          initialValues={{
            email,
            token: ''
          }}
          sso={sso}
          setEmail={setEmail}
        />
      )}
      <Formik
        enableReinitialize
        initialValues={{ email, password: '' }}
        onSubmit={submitForm}
        zodSchema={z.object({
          email: z.string().email('Please enter a valid email'),
          password: z.string().min(1, 'Password is required')
        })}
      >
        <Form>
          {sso.isNotSSO && (
            <>
              <TextFieldFormik label='Email Address' name='email' autoComplete='on' disabled required />
              <TextFieldFormik
                label='Password'
                name='password'
                type='password'
                autoComplete='on'
                inputRef={passwordRef}
                required
              />

              <LoginButton id='PrimaryLoginButton' ariaLabel='Primary login button' type='submit' disabled={loading}>
                {loading ? (
                  <>
                    Logging in... <CircularProgress size={16} />
                  </>
                ) : (
                  'Log in'
                )}
              </LoginButton>
            </>
          )}

          <div>
            {sso.isNotSSO && (
              <div className='login-link'>
                <Link to={Path.ForgotPassword}>Forgot Password</Link>
              </div>
            )}
            <div className='login-link'>
              <RegisterLink>Don&apos;t have an account?&nbsp;&nbsp;</RegisterLink>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default LoginForm;
