import React from 'react';

import zod from 'zod';

import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { FilterControl } from 'module/GlobalFilter';

import { useAuth } from 'provider';

import { ArtifactType } from 'types/common';
import { Ops, Query } from 'types/filter';
import { ExploitationStates } from 'types/mati';

import { FilterConfig } from '../GlobalFilter.type';
import { addOrRemoveValue } from '../GlobalFilter.util';

type ExploitationStatesKeys = {
  exploitationState: ExploitationStates[];
};

type ExploitationStatesFilterProps = {
  onChange(values: ExploitationStatesKeys): void;
  values: ExploitationStatesKeys;
};

function VulnerabilityExploitationStatesFilter({
  onChange,
  values
}: ExploitationStatesFilterProps): React.ReactElement {
  const { isSubscriber } = useAuth();
  return (
    <FilterControl disabled={!isSubscriber}>
      <FormLabel id='exploitation-state-checkbox-group-label' className='title-tooltip'>
        By Vulnerability Exploitation State
        <Tooltip
          title={
            !isSubscriber
              ? 'This filter is only available to subscribers'
              : 'Measure of exploitation activity as tracked by Mandiant'
          }
          placement='right'
          arrow
          wrap
        >
          <Icon.Info />
        </Tooltip>
      </FormLabel>
      <FormGroup aria-labelledby='exploitation-state-checkbox-group-label'>
        {Object.values(ExploitationStates).map(es => (
          <FormControlLabel
            key={es}
            control={
              <Checkbox
                onChange={handleChange}
                value={es}
                checked={values.exploitationState.includes(es as ExploitationStates)}
              />
            }
            label={es}
          />
        ))}
      </FormGroup>
    </FilterControl>
  );

  function handleChange(e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    const v = e.currentTarget.value as ExploitationStates;
    const exploitationState = addOrRemoveValue(checked, values.exploitationState, v);
    onChange({ exploitationState });
  }
}

function checkForCompleteSet(values: ExploitationStates[]): boolean {
  return !values?.length || Object.values(ExploitationStates).every(state => values?.includes(state));
}

function toQuery(values: ExploitationStatesKeys): Query {
  if (checkForCompleteSet(values.exploitationState)) return;

  return {
    field: 'exploitation_state',
    op: Ops.in,
    value: values.exploitationState
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('exploitation_state'),
    op: zod.literal(Ops.in),
    value: zod.array(zod.union([zod.nativeEnum(ExploitationStates), zod.literal(null)]))
  })
  .transform(query => ({
    exploitationState: query.value.map(v => v || null)
  }));

const VulnerabilityExploitationStatesFilterConfig: FilterConfig<ExploitationStatesKeys> = {
  defaults: { default: () => ({ exploitationState: Object.values(ExploitationStates) }) },
  supportedTopics: [ArtifactType.Collection],
  component: VulnerabilityExploitationStatesFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default VulnerabilityExploitationStatesFilterConfig;
