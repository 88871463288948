import React from 'react';

import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';

import Divider from 'snap-ui/Divider';

import RjsfFormik from 'module/Form/RjsfFormik';

import { IntegrationFormData } from '../Integration.type';
import { IntegrationFormFieldProps } from './TabFormFields';

export default function ApiFields({
  integrationSchema,
  setType
}: Pick<IntegrationFormFieldProps, 'integrationSchema' | 'setType'>) {
  const { values } = useFormikContext<IntegrationFormData>();

  React.useEffect(() => {
    setType(values.type);
  }, [setType, values.type]);

  if (isEmpty(integrationSchema)) return null;

  return (
    <>
      <Divider textAlign='left'>API Settings</Divider>
      <RjsfFormik name='extra_information' schema={integrationSchema} preserve />
    </>
  );
}
