import React from 'react';

import { faCopy, faEllipsisVertical, faTrash } from '@fortawesome/pro-solid-svg-icons';

import CircularProgress from 'snap-ui/CircularProgress';
import { ConfirmDialog } from 'snap-ui/Dialog';
import Icon from 'snap-ui/Icon';
import { ListItemIcon, ListItemText } from 'snap-ui/List';
import { MenuButton, MenuTrigger } from 'snap-ui/Menu';
import MenuItem from 'snap-ui/MenuItem';
import Typography from 'snap-ui/Typography';

import { useIntegrationCatalog } from 'provider/Integration';

import { Status } from 'storage';

import { StatusContainer, StatusHeaderContainer } from './Integration.style';
import { Integration } from './Integration.type';
import IntegrationsForm from './IntegrationForm';
import useIntegrationContext from './IntegrationProvider';

export function StatusHeader({ integration }: { integration: Integration }) {
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = React.useState(false);
  const [showCloneDialog, setShowCloneDialog] = React.useState(false);
  const [selectedIntegration, setSelectedIntegration] = React.useState(null);

  const { deploymentCount, handleClone, handleDelete } = useIntegrationContext();

  function handleCloseDialog() {
    setSelectedIntegration(null);
    setShowCloneDialog(false);
    setShowDeleteConfirmDialog(false);
  }

  const { taskStatus } = useIntegrationCatalog();

  return (
    <StatusHeaderContainer>
      <Typography variant='h3'>Status</Typography>
      <StatusContainer>
        <MenuTrigger
          key={integration.guid}
          trigger={
            <StatusContainer>
              <Typography variant='h3'>{integration.name}</Typography>
              <MenuButton aria-label={`${integration.name} menu`} icon={faEllipsisVertical} />
            </StatusContainer>
          }
        >
          <MenuItem
            key='copy'
            onClick={() => {
              setShowCloneDialog(true);
              setSelectedIntegration(integration);
            }}
          >
            <ListItemIcon>
              <Icon icon={faCopy} />
            </ListItemIcon>
            <ListItemText>Copy</ListItemText>
          </MenuItem>
          <MenuItem
            key='delete'
            onClick={() => {
              setShowDeleteConfirmDialog(true);
              setSelectedIntegration(integration);
            }}
          >
            <ListItemIcon>
              <Icon icon={faTrash} />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </MenuTrigger>
      </StatusContainer>

      {/* Clone Dialog */}
      {selectedIntegration && showCloneDialog && (
        <IntegrationsForm
          initialValuesProps={{
            name: selectedIntegration?.name + ' - Copy',
            url_template: selectedIntegration?.url_template,
            organization_id: selectedIntegration?.organization_id?.toString(),
            deployment_prefix_template: selectedIntegration?.deployment_prefix_template,
            deployment_targets: selectedIntegration?.deployment_targets?.map(d => d.id.toString()),
            hunt_targets: selectedIntegration?.hunt_targets?.map(d => d.id.toString()),
            search_targets: selectedIntegration?.search_targets?.map(d => d.id.toString()),
            extra_information: selectedIntegration?.extra_information,
            type: selectedIntegration?.type
          }}
          submitForm={handleClone(selectedIntegration?.guid)}
          DialogProps={{
            open: showCloneDialog,
            onClose: () => handleCloseDialog()
          }}
          status={taskStatus}
        />
      )}

      {/* Delete Dialog */}
      {selectedIntegration && showDeleteConfirmDialog && (
        <ConfirmDialog
          ConfirmProps={{
            children:
              taskStatus === Status.pending ? (
                <>
                  Deleting... <CircularProgress size={16} />
                </>
              ) : (
                'Delete'
              ),
            disabled: taskStatus === Status.pending,
            onClick: () => {
              handleDelete(selectedIntegration.guid);
              handleCloseDialog();
            }
          }}
          DialogProps={{
            open: showDeleteConfirmDialog,
            onClose: () => handleCloseDialog()
          }}
          title='Confirm Delete'
        >
          <p>Are you sure you want to delete {selectedIntegration?.name}?</p>
          {!!deploymentCount && <p>{deploymentCount} detections will be undeployed prior to deletion.</p>}
        </ConfirmDialog>
      )}
    </StatusHeaderContainer>
  );
}
