import React from 'react';

import Icon, { saSnapAttackLogo } from 'snap-ui/Icon';

import { StrictReactNode } from 'types/core';

const padding = 20;
const gap = 8;

/** This special case component is exempt from our normalized standards with styling */
export default function CoverPage({ title, details }: { title: string; details: StrictReactNode }) {
  return (
    <section
      className='CoverPage'
      style={{
        color: 'black',
        display: 'flex',
        gap: `${gap}px`,
        flexDirection: 'column',
        justifyContent: 'start',
        padding: `${padding * 4}px ${padding}px ${padding}px ${padding}px`,
        fontFamily: 'sans-serif',
        fontSize: '2rem'
      }}
    >
      <div
        style={{
          marginLeft: -padding,
          marginRight: -padding,
          backgroundColor: 'hsl(0, 0%, 18.4%)',
          height: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Icon icon={saSnapAttackLogo} size='3x' />
      </div>
      <h1 style={{ fontSize: '4rem', fontStyle: 'bold', marginLeft: '43px' }}>{title}</h1>
      <div style={{ marginLeft: '43px' }}>{details}</div>
      <div className='page-break' />
    </section>
  );
}
