import React from 'react';

import useOrganizationOptions from 'hooks/useOrganizationOptions';

import { Session } from 'module/Session/Session.type';

import { FunctionalPermission } from 'types/auth';

import MigrateModal from './Modal';

export type MigrateSession = {
  isOpen: boolean;
  onClose(): void;
  refreshSession(): void;
  session: Session;
};

export function MigrateSession({ isOpen, onClose, refreshSession, session }: MigrateSession): JSX.Element {
  // to migrate a session you must have permission to:
  //    1. delete the session
  //    2. create a session on some other org
  const options = useOrganizationOptions(FunctionalPermission.CreateSession);
  if (!isOpen) return null;

  const moveOptions = options.filter(orgOption => orgOption.value !== session?.organization_id?.toString());
  const handleMigrationSuccess = (): void => {
    refreshSession();
    onClose();
  };

  if (!moveOptions.length) return null;

  return (
    <MigrateModal
      isOpen={isOpen}
      handleCancel={onClose}
      handleSuccess={handleMigrationSuccess}
      orgOptions={moveOptions}
      sessionGuid={session.guid}
    />
  );
}
