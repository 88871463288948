import React from 'react';

import { faChevronsDown, faChevronsUp } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';

import Icon from 'snap-ui/Icon';
import { styled, useTheme } from 'snap-ui/util';

type Props = {
  changePercent: number;
  className?: string;
};

function ChangePercentageComponent({ className, changePercent }: Props) {
  const { palette } = useTheme();

  return (
    <>
      {!!changePercent && isFinite(changePercent) && (
        <div className={classnames(className, 'change-percentage')}>
          <Icon
            icon={changePercent > 0 ? faChevronsUp : faChevronsDown}
            color={changePercent > 0 ? palette.lime.main : palette.error.main}
          />
          <span>
            {changePercent > 0 ? 'UP' : 'DOWN'} {Math.abs(Number(changePercent.toFixed(0))).toLocaleString()}%
          </span>
        </div>
      )}
    </>
  );
}

const ChangePercentage = styled(ChangePercentageComponent)<Props>`
  height: ${p => p.theme.spacing(6)};

  > span {
    margin-left: ${p => p.theme.spacing(4)};
  }
`;

export default ChangePercentage;
