import { COMMUNITY_ORGANIZATION } from 'constants/auth';

import { FunctionalPermission, Organization } from 'types/auth';

/**
 * Filter organization options based on a functional task.
 * If a task is not passed, then all organizations are returned.
 *
 * @param organization
 */
export function filterUserOrganizationsByTask(
  organization: Organization[],
  task: FunctionalPermission
): Organization[] {
  const options = task ? filterOrganizationsByTask(organization, task) : organization;
  return options?.length > 0 ? options : [COMMUNITY_ORGANIZATION as Organization];
}

/**
 * filter an organization list by a FunctionalPermission a user has on the org
 */
export function filterOrganizationsByTask(organization: Organization[], task: FunctionalPermission): Organization[] {
  return organization?.filter(
    org => org.permission[0] === '*' || (org.permission as FunctionalPermission[]).includes(task)
  );
}

/**
 * Returns true if any of the user's organizations are subscribers, granting access to subscription content
 * @param organization
 * @returns
 */
export function isMemberOfSubscribingOrganization(organization: Organization[]): boolean {
  return organization?.some(org => org.subscriber === true);
}
