import React from 'react';

import classNames from 'classnames';
import { useField } from 'formik';

import MarkdownEdit from 'module/Widgets/Markdown/MarkdownEdit';

import FormError from './FormError';

interface Props {
  className?: string;
  name: string;
  label: string | React.ReactElement;
  required?: boolean;
  disabled?: boolean;
}

function MarkdownFormik({ className, disabled, label, name, required }: Props): React.ReactElement {
  const [field, meta, helpers] = useField(name);
  const hasError = meta.touched && !!meta.error;

  return (
    <div className={className}>
      {label && <label htmlFor={name}>{label}</label>}
      <MarkdownEdit
        id={name}
        value={field.value}
        onChange={helpers.setValue}
        onBlur={handleBlur}
        disabled={disabled}
        className={classNames(
          'Form-field',
          { error: hasError },
          { required: required, valid: meta.touched && !meta.error }
        )}
        textareaClassName={classNames({ error: hasError })}
      />
      <FormError error={meta.error} isVisible={hasError} indicator={false} />
    </div>
  );

  function handleBlur(): void {
    helpers.setTouched(true);
  }
}

export default MarkdownFormik;
