import React from 'react';

import { useHistory } from 'react-router-dom';

import { ConfirmDialog } from 'snap-ui/Dialog';
import FormControl from 'snap-ui/FormControl';
import FormControlLabel from 'snap-ui/FormControlLabel';
import Radio from 'snap-ui/Radio';
import RadioGroup from 'snap-ui/RadioGroup';

import Path from 'constants/paths';

import { CommonEvent, Engage, Fingerprint, Widget } from 'lib/Engagement';

import { getNativeCloneState } from 'module/Analytic/core/EditorStateProvider';
import { useIDEState } from 'module/IDE';
import EditAsNativeDialog from 'module/IDE/core/EditAsNativeDialog';
import { DetectionFormType } from 'module/IDE/type';
import { hasLogsource } from 'module/TranslateAnalytic';

import { useAuth } from 'provider';

import { Ident } from 'types/common';

export default function Choice() {
  const { push } = useHistory();
  const { defaultLanguageId } = useAuth();
  const { ideState, dispatch } = useIDEState();
  const canTranslate = hasLogsource(ideState);
  const [showWidget, setShowWidget] = React.useState(false);
  const [showConfirmSwitchToSigma, setShowConfirmSwitchToSigma] = React.useState(false);
  const [showConfirmClone, setShowConfirmClone] = React.useState(false);
  const hasSourceObj = !!ideState.guid;
  const willClone = hasSourceObj && !ideState.isNative;

  const disableChange = ideState.isNative && hasSourceObj; // can't convert a saved native to sigma

  const handleChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
    Engage.track(
      Fingerprint.of(Widget.DetectionBuilder).withCommon(CommonEvent.Click).withQualifier('builder type').withData({
        builderType: value
      })
    );
    if (value === DetectionFormType.Native && canTranslate && !willClone) {
      setShowWidget(true);
    } else if (value === DetectionFormType.Native && canTranslate && willClone) {
      setShowConfirmClone(true);
    } else if (value === DetectionFormType.Sigma && ideState.raw) {
      setShowConfirmSwitchToSigma(true);
    } else {
      const isNative = value === DetectionFormType.Native;
      dispatch({ type: 'EditorModeUpdate', isNative, languageId: isNative ? defaultLanguageId : undefined });
    }
  };

  function handleEditAsSigma() {
    setShowConfirmSwitchToSigma(false);
    dispatch({ type: 'EditorModeUpdate', isNative: false });
  }

  function handleEmitSwitchToNative(raw: string, languageId: Ident) {
    if (!willClone) dispatch({ type: 'EditAsNativeUpdate', languageId, raw });
    else {
      Engage.track(
        Fingerprint.of(Widget.DetectionBuilder).withQualifier('convert to native').withData({
          guid: ideState.guid,
          languageId
        })
      );
      push({
        pathname: Path.IDEReset,
        state: {
          editor: getNativeCloneState(ideState, ideState.analyticForm.organization_id, ideState.guid, languageId, raw)
        }
      });
    }
  }

  return (
    <>
      <FormControl disabled={disableChange}>
        <RadioGroup
          row
          name='detectionChoiceRadioGroup'
          onChange={handleChange}
          value={ideState.isNative ? DetectionFormType.Native : DetectionFormType.Sigma}
        >
          <FormControlLabel control={<Radio />} label='Sigma' value={DetectionFormType.Sigma} />
          <FormControlLabel control={<Radio />} label='Native Detection' value={DetectionFormType.Native} />
        </RadioGroup>
      </FormControl>
      <EditAsNativeDialog
        isOpen={showWidget}
        onSubmit={handleEmitSwitchToNative}
        onClose={() => setShowWidget(false)}
      />
      <ConfirmDialog
        ConfirmProps={{ children: 'Edit Sigma Detection', onClick: handleEditAsSigma }}
        DialogProps={{
          open: showConfirmSwitchToSigma,
          onClose: () => setShowConfirmSwitchToSigma(false)
        }}
        SecondaryProps={{ children: 'Cancel' }}
        title='Are you sure you want to switch to Sigma mode?'
      >
        Any edits you&apos;ve made to the native detection will be lost.
      </ConfirmDialog>
      <ConfirmDialog
        ConfirmProps={{ children: 'Convert to Native Detection', onClick: () => setShowWidget(true) }}
        DialogProps={{ open: showConfirmClone, onClose: () => setShowConfirmClone(false) }}
        SecondaryProps={{ children: 'Cancel' }}
        title='Are you sure you want to convert to native code?'
      >
        This will create a new detection based on a translation of this one.
      </ConfirmDialog>
    </>
  );
}
