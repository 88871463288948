import React from 'react';

import { postAnalyticRank } from 'apis/resources/analytic';

import { useAuth } from 'provider';

import { orgIdIsNotVoid } from 'services/organizationService';

import { Status, useAsync } from 'storage';

import { ArtifactScore, Guid } from 'types/common';

interface AnalyticRankState {
  setRank(rank: ArtifactScore): Promise<void>;
  status: Status;
}

export default function useAnalyticRank(analyticGuid: Guid): AnalyticRankState {
  const { task, status } = useAsync();
  const { defaultOrgId } = useAuth();

  const setRank = React.useCallback(
    (rank: ArtifactScore) => {
      if (orgIdIsNotVoid(defaultOrgId)) {
        return task(postAnalyticRank(analyticGuid, rank, defaultOrgId));
      }
    },
    [analyticGuid, defaultOrgId, task]
  );

  return {
    setRank,
    status
  };
}
