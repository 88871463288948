import * as React from 'react';

import MenuItem from '@mui/material/MenuItem';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';

type Props = Omit<MuiSelectProps, 'children' | 'onChange'> & {
  onChange(value: string): void;
};

export type SelectProps =
  | (Props & {
      children?: never;
      options: {
        value: string;
        content: React.ReactNode;
      }[];
    })
  | (Props & {
      children: MuiSelectProps['children'];
      options?: never;
    });

export function Select(props: SelectProps) {
  const { children, options, onChange, ...others } = props;

  return (
    <MuiSelect displayEmpty onChange={e => onChange(e.target.value as string)} {...others}>
      {others.placeholder && (
        <MenuItem disabled value=''>
          <em>{others.placeholder}</em>
        </MenuItem>
      )}
      {options
        ? options.map(o => (
            <MenuItem key={o.value} value={o.value}>
              {o.content}
            </MenuItem>
          ))
        : children}
    </MuiSelect>
  );
}
