import React from 'react';

import Tooltip from 'snap-ui/Tooltip';

import { AlphaFeature, useAlphaSetting } from 'module/AlphaFeatures';

import { JustificationContainer } from './ThreatProfile.style';
import { ThreatProfileTag } from './ThreatProfile.type';
import { getTagWeightLabel } from './ThreatProfile.util';
import WeightedScoreIcon from './WeightedScoreIcon';

export default function TagJustification({
  components,
  scoreLabel,
  children
}: {
  components: ThreatProfileTag['components'];
  scoreLabel: ThreatProfileTag['score_label'];
  children?: JSX.Element;
}): JSX.Element {
  const { enableAlpha } = useAlphaSetting(AlphaFeature.TagJustification);
  const filteredComponents = Object.values(components ?? {}).filter(component => component.description !== null);
  const maxWeight = Math.max(...filteredComponents.map(c => c.weight));

  return (
    <Tooltip
      arrow
      placement='left'
      wrap
      title={
        <>
          {getTagWeightLabel(scoreLabel)}
          {enableAlpha && (
            <JustificationContainer>
              {filteredComponents
                .sort((a, b) => b.weight - a.weight)
                .map(component => (
                  <li key={component.description}>
                    {component.description} <WeightedScoreIcon score={component.weight} maxScore={maxWeight} />
                  </li>
                ))}
            </JustificationContainer>
          )}
        </>
      }
    >
      {children}
    </Tooltip>
  );
}
