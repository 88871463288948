import React from 'react';

import { useCanContext } from './useCanContext';

type Props = {
  children: React.ReactNode;
};

export default function Block({ children }: Props) {
  const can = useCanContext();
  return can ? null : <>{children}</>;
}
