import React from 'react';

import { getAnalytic } from 'apis/resources/analytic';

import { Status, useMountedRef } from 'storage';

import { Artifact, Guid } from 'types/common';

type AnalyticPreview = {
  analytic: Artifact;
  status: Status;
};

type AnalyticPreviewCatalog = {
  getter(guid: Guid): Promise<Artifact>;
};

const AnalyticPreviewContext = React.createContext<AnalyticPreviewCatalog>(null);
AnalyticPreviewContext.displayName = 'AnalyticPreviewContext';

function useAnalyticPreviewCatalog(): AnalyticPreviewCatalog {
  const context = React.useContext<AnalyticPreviewCatalog>(AnalyticPreviewContext);

  if (!context) {
    throw new Error('useAnalyticPreviewCatalog must be used within the AnalyticPreviewContext');
  }

  return context;
}

function AnalyticPreviewProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [cache, setCache] = React.useState<Record<Guid, Promise<Artifact>>>({});

  const getter = async (guid: Guid) => {
    if (guid) {
      if (guid in cache) {
        return cache[guid];
      } else {
        const promise = getAnalytic(guid);

        setCache(cache => {
          cache[guid] = promise;
          return cache;
        });

        return promise;
      }
    }
  };

  return <AnalyticPreviewContext.Provider value={{ getter }}>{children}</AnalyticPreviewContext.Provider>;
}

export function useAnalyticPreview(analyticGuid: Guid): AnalyticPreview {
  const mounted = useMountedRef();
  const { getter } = useAnalyticPreviewCatalog();
  const [analytic, setAnalytic] = React.useState<Artifact>();
  const [status, setStatus] = React.useState<Status>();

  React.useEffect(() => {
    setStatus(Status.pending);
    getter(analyticGuid).then(artifact => {
      if (mounted.current) {
        setAnalytic(artifact);
        setStatus(Status.resolved);
      }
    });
  }, [analyticGuid]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    analytic,
    status
  };
}

export { AnalyticPreviewProvider, useAnalyticPreviewCatalog };
