import React, { ReactElement } from 'react';

import classnames from 'classnames';

import UndetectedThreatIcon from 'module/Icons/UndetectedThreatIcon';
import UntestedAnalyticIcon from 'module/Icons/UntestedAnalyticIcon';
import ValidatedAnalyticIcon from 'module/Icons/ValidatedAnalyticIcon';
import MarkerIcon from 'module/Widgets/MarkerIcons/MarkerIcon';

import Panel from './Panel';
import { Props as PanelProps } from './Panel';

export enum MarkerType {
  Validated = 'validated',
  Undetected = 'undetected',
  Untested = 'untested'
}

const PANEL_PROPS = {
  [MarkerType.Undetected]: {
    title: 'Undetected Threats',
    icon: <MarkerIcon icon={<UndetectedThreatIcon className='icon' />} />,
    tooltip: (
      <p>
        <strong>Undetected threats</strong> are the blue team&apos;s backlog. The labeled attack (red star) does not
        have a matching detection hit (blue circle). These can be improved by reviewing the log data from the threat and
        creating a new detection.
      </p>
    )
  },
  [MarkerType.Validated]: {
    title: 'Validated Detections',
    icon: <MarkerIcon icon={<ValidatedAnalyticIcon className='icon' />} />,
    tooltip: (
      <p>
        <strong>Validated detections</strong> are true positive detection results. When an detection hit (blue circle)
        matches a labeled attack (red star), the icons are filled in to denote that the gaps are closed.
      </p>
    )
  },
  [MarkerType.Untested]: {
    title: 'Untested Detections',
    icon: <MarkerIcon icon={<UntestedAnalyticIcon className='icon' />} />,
    tooltip: (
      <p>
        <strong>Untested detections</strong> are the red team&apos;s backlog. These are detections that have never hit
        on a threat. These can be improved by capturing or emulating a threat that will trigger the detection.
      </p>
    )
  }
};

export type Props = Partial<PanelProps> & {
  markerType: MarkerType.Undetected | MarkerType.Untested | MarkerType.Validated;
  value: number;
};

function MarkerPanel({ markerType, value, ...others }: Props): ReactElement {
  return (
    <Panel
      {...others}
      className={classnames('pp-marker-panel', `pp-dashboard-${markerType}`, {
        loading: others.loading
      })}
      content={
        <>
          <div className='pp-marker-count-container'>
            {PANEL_PROPS[markerType].icon}
            <span className='val'>{value ? value.toLocaleString() : 0}</span>
          </div>
        </>
      }
      {...PANEL_PROPS[markerType]}
    />
  );
}

export default MarkerPanel;
