import React from 'react';

import { faBullhorn } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';

import Button from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';

export default function ReportButton(props: { className?: string }) {
  return (
    <Button
      onClick={() => window['Userback'].open()}
      color='secondary'
      className={classnames('sa-generic-spacer', props.className)}
    >
      Report this as an Issue
      <Icon icon={faBullhorn} color='primary' size='lg'></Icon>
    </Button>
  );
}
