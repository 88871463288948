import React from 'react';

import { faCloudArrowDown } from '@fortawesome/pro-regular-svg-icons';
import classnames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import moment from 'moment';

import Button, { ActionIconButton } from 'snap-ui/Button';
import PrimaryCircularProgress from 'snap-ui/CircularProgress';
import Table from 'snap-ui/Table';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { HuntStatusLabel } from 'module/Hunt/Hunt.widget';
import { JobStatus, postClientHitsExport } from 'module/Job';
import { getJobStatusMessage } from 'module/Job/Job.util';

import { Status, useAsync } from 'storage';

import { downloadFile } from 'utilities/FileUtils';

import { AdHocQueryJobHistory } from './AdHocQuery.type';
import { useAdHocQueryContext } from './AdHocQueryProvider';
import usePollAdHocQueries from './usePollAdHocQueries';

const Container = styled('div')`
  max-height: 640px;

  button {
    display: inline-block;
    max-width: 280px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: start;
  }

  .Table-row-cell.active {
    background-color: ${p => p.theme.palette.surface.hover};
  }
`;

export default function AdHocQueryHistory({ className }: { className?: string }) {
  const { getQueryHistory, jobGuid } = useAdHocQueryContext();

  const queryHistory = getQueryHistory();

  if (isEmpty(queryHistory)) return null;

  return (
    <Container className={classnames('AdHocQueryHistory', className)}>
      <Typography variant='h2'>Query History</Typography>
      <Table
        aria-label='query history'
        columns={['Query', 'Status', 'Results', '']}
        rows={queryHistory.slice(0, 10).map((h: AdHocQueryJobHistory) => [
          {
            className: jobGuid === h.jobGuid ? 'active' : '',
            component: <ClickableQueryHistoryItem key={h.jobGuid} queryHistory={h} />
          },
          {
            className: jobGuid === h.jobGuid ? 'active' : '',
            component: (
              <HuntStatusLabel
                status={h.response.status}
                statusDetail={
                  h.response.status === JobStatus.CompletedWithErrors
                    ? getJobStatusMessage(JobStatus.CompletedWithErrors, null)
                    : null
                }
              />
            )
          },
          { className: jobGuid === h.jobGuid ? 'active' : '', component: <>{h.hits}</> },
          {
            className: jobGuid === h.jobGuid ? 'active' : '',
            component: <DownloadHits queryHistory={h} />
          }
        ])}
      />
    </Container>
  );
}

function ClickableQueryHistoryItem({ queryHistory }: { queryHistory: AdHocQueryJobHistory }) {
  const { handleHistoryClick } = useAdHocQueryContext();

  const query = get(queryHistory, 'payload.adhoc_queries[0].search');
  const status = get(queryHistory, 'response.status');
  const isJobRunning = [JobStatus.Started, JobStatus.Pending].includes(status);
  const hitsFetched = !isNil(queryHistory?.hits);

  usePollAdHocQueries(queryHistory.response.guid, isJobRunning, hitsFetched);

  return (
    <Button variant='text' onClick={() => handleHistoryClick(queryHistory)}>
      {query.slice(0, 60)}
    </Button>
  );
}

function DownloadHits({ queryHistory }: { queryHistory: AdHocQueryJobHistory }) {
  const status = get(queryHistory, 'response.status');
  const isJobRunning = [JobStatus.Started, JobStatus.Pending].includes(status);
  const groupGuid = queryHistory.response.guid;

  const { task: exportTask, status: exportStatus } = useAsync<string>();

  const exportDataAsCsv = React.useCallback(() => {
    const startTime = moment().subtract(6, 'months').toISOString();
    const endTime = moment().toISOString();
    return exportTask(postClientHitsExport(groupGuid, { start: startTime, end: endTime })).then(result =>
      downloadFile(new Blob([result]), `${groupGuid}.csv`)
    );
  }, [exportTask, groupGuid]);

  if (exportStatus === Status.pending) return <PrimaryCircularProgress size={16} />;
  return (
    <ActionIconButton
      aria-label='download hits'
      disabled={isJobRunning}
      icon={faCloudArrowDown}
      onClick={exportDataAsCsv}
    />
  );
}
