import FormControl from 'snap-ui/FormControl';
import FormControlLabel from 'snap-ui/FormControlLabel';
import { styled } from 'snap-ui/util';

export const FilterControl = styled(FormControl)`
  display: flex;
  flex-direction: column;

  .radio-group {
    display: flex;
    flex-direction: row;
  }

  .MuiFormControlLabel-root {
    height: ${p => p.theme.spacing(7)};
    min-height: 1rem;
  }

  & .title-tooltip {
    align-items: center;
    display: flex;
    gap: ${p => p.theme.spacing(2)};
  }
`;

export const FilterLayout = styled('div')`
  display: flex;
  flex-direction: column;
  padding-bottom: ${p => p.theme.spacing(6)}; // keeps the last filter off the bottom of the browser window

  & > *:not(:last-child)::after {
    content: '';
    margin: ${p => p.theme.spacing(6, 0)};
    background: linear-gradient(0.25turn, hsla(0, 0%, 20%, 0.1), hsla(0, 0%, 40%, 0.3), hsla(0, 0%, 20%, 0.1));
    width: 100%;
    height: 2px;
  }
`;

export const AutosearchControl = styled(FormControl)`
  width: 100%;

  & .Autosearch {
    max-width: 100%;
  }
`;

export const CheckboxControl = styled(FormControl)`
  min-width: 100%;

  .MuiFormControlLabel-root {
    height: ${p => p.theme.spacing(7)};
    min-height: 1rem;
  }

  & .title-tooltip {
    align-items: center;
    display: flex;
    gap: ${p => p.theme.spacing(2)};
  }
`;

export const IconFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    display: flex;
    align-items: center;
    gap: ${p => p.theme.spacing(2)};
  }

  .non-domino-icon {
    margin-left: 6px; // magic numbers based on the extra width of the domino icon
    margin-right: 2px;
  }
`;

export const AutocompleteOptionContent = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(3)};
  align-items: center;
`;
