import React from 'react';

import { faBarsSort, faBookmark } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';

import { DivButton } from 'snap-ui/Button';
import Fade from 'snap-ui/Fade';
import Icon from 'snap-ui/Icon';
import LinearProgress from 'snap-ui/LinearProgress';
import Paper from 'snap-ui/Paper';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { ApiError } from 'module/ApiError';
import { Discriminator } from 'module/Tag';
import { TagWeight, useThreatProfile } from 'module/ThreatProfile';

import { Status, useMountedRef } from 'storage';

import { ArtifactType } from 'types/common';

import { formatQueryString } from 'utilities/SearchParam';

import ThreatProfileStep from '../ThreatProfileStep';
import useBuildThreatProfile from '../useBuildThreatProfile';

const ThreatPrioritiesContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${p => p.theme.spacing(6, 5)};

  .Card {
    display: flex;
    flex-direction: column;
    padding: ${p => p.theme.spacing(5)};
    gap: ${p => p.theme.spacing(3)};
  }

  .row {
    display: flex;
    gap: ${p => p.theme.spacing(3)};
    align-items: center;
  }

  .description {
    display: flex;
    flex-grow: 1;
  }

  .span-columns {
    grid-column: 1/-1;
  }

  a {
    cursor: pointer;
  }
`;

export default function ThreatReview(): JSX.Element {
  const { push } = useHistory();
  const { tags, refresh, tagsStatus } = useThreatProfile();
  const { buildProfile: _buildProfile, buildStatus, buildErrorProps } = useBuildThreatProfile();
  const [requestRunningLong, setRequestRunningLong] = React.useState<boolean>(false);
  const mounted = useMountedRef();
  const rebuildClicked = React.useRef(false);
  const profileExists = !isEmpty(tags) && tagsStatus === Status.resolved;

  const allResolved = React.useMemo(
    () =>
      (profileExists && !rebuildClicked.current ? [tagsStatus] : [buildStatus, tagsStatus]).every(
        status => status === Status.resolved
      ),
    [buildStatus, profileExists, tagsStatus]
  );

  React.useEffect(() => {
    if (mounted.current) {
      setTimeout(() => {
        if (!allResolved) {
          setRequestRunningLong(true);
        }
      }, 3000);
    }
  }, [allResolved, mounted]);

  const buildProfile = React.useCallback(() => {
    if (rebuildClicked.current === false) {
      _buildProfile().then(() => refresh());
      rebuildClicked.current = true;
    }
  }, [_buildProfile, refresh]);

  React.useEffect(() => {
    if (!profileExists) buildProfile();
  }, [buildProfile, profileExists]);

  const actorCount = tags.filter(
    tag => tag.type === Discriminator.Actor && tag.score_label === TagWeight.Highest
  )?.length;
  const attackCount = tags.filter(
    tag => tag.type === Discriminator.Attack && tag.score_label === TagWeight.Highest
  )?.length;
  const softwareCount = tags.filter(
    tag => tag.type === Discriminator.Software && tag.score_label === TagWeight.Highest
  )?.length;
  const vulnerabilityCount = tags.filter(
    tag => tag.type === Discriminator.Vulnerability && tag.score_label === TagWeight.Highest
  )?.length;

  function handleClick(contentType: Discriminator) {
    return function () {
      const path =
        Path.FeedReset +
        formatQueryString({
          topic: ArtifactType.Collection,
          contentType,
          threatProfile: true,
          priority: TagWeight.Highest
        });
      push(path);
    };
  }

  return (
    <ThreatProfileStep
      title='Threat Priorities'
      description='To prioritize cyber threats for your threat profile, we meticulously considered metrics around new and emerging threats, historical threat data, and potential business impact, enabling us to prioritize resources efficiently and focus on addressing the most imminent and impactful risks to your systems and sensitive information. This strategic approach ensures that your defensive measures align with the evolving threat landscape and safeguards your digital assets effectively.'
      content={
        <>
          {isEmpty(buildErrorProps) ? (
            <>
              {allResolved ? (
                <Fade in timeout={1000}>
                  <ThreatPrioritiesContainer>
                    <div className='row span-columns'>
                      <Typography>
                        Your organization&apos;s threat profile is updated periodically throughout the day, but you can{' '}
                        <a onClick={buildProfile}>rebuild it now</a> if you wish.
                      </Typography>
                    </div>
                    <Paper elevation={8} className='Card'>
                      <div className='row'>
                        <Icon.Tool />
                        <Typography variant='h4'>Malware & Tools</Typography>
                      </div>
                      <div className='description'>
                        Malware and tools encompass a wide range of malicious software and applications that threat
                        actors use to compromise systems, steal data, and execute cyber attacks.
                      </div>
                      <DivButton onClick={handleClick(Discriminator.Software)}>
                        <Icon icon={faBookmark} /> {softwareCount} Highest Priorities
                      </DivButton>
                    </Paper>
                    <Paper elevation={8} className='Card'>
                      <div className='row'>
                        <Icon.Actor />
                        <Typography variant='h4'>Threat Actors</Typography>
                      </div>
                      <div className='description'>
                        Threat actors are individuals, groups, or organizations that engage in malicious activities such
                        as hacking, exploiting vulnerabilities, and conducting cyber attacks with various motivations,
                        from financial gain to espionage.
                      </div>
                      <DivButton onClick={handleClick(Discriminator.Actor)}>
                        <Icon icon={faBookmark} /> {actorCount} Highest Priorities
                      </DivButton>
                    </Paper>
                    <Paper elevation={8} className='Card'>
                      <div className='row'>
                        <Icon icon={faBarsSort} />
                        <Typography variant='h4'>ATT&CK TTPs</Typography>
                      </div>
                      <div className='description'>
                        Tactics, Techniques, and Procedures (TTPs) refer to the methods and strategies used by threat
                        actors to achieve their objectives in cyber attacks, providing insights into their operational
                        patterns and behavior.
                      </div>
                      <DivButton onClick={handleClick(Discriminator.Attack)}>
                        <Icon icon={faBookmark} /> {attackCount} Highest Priorities
                      </DivButton>
                    </Paper>
                    <Paper elevation={8} className='Card'>
                      <div className='row'>
                        <Icon.Vulnerability />
                        <Typography variant='h4'>Vulnerabilities</Typography>
                      </div>
                      <div className='description'>
                        Vulnerabilities are weaknesses or flaws in software, hardware, or systems that, if exploited,
                        can lead to security breaches, unauthorized access, and potential compromise of sensitive
                        information.
                      </div>
                      <DivButton onClick={handleClick(Discriminator.Vulnerability)}>
                        <Icon icon={faBookmark} /> {vulnerabilityCount} Highest Priorities
                      </DivButton>
                    </Paper>
                  </ThreatPrioritiesContainer>
                </Fade>
              ) : (
                <>
                  <Typography variant='h4'>Crunching the numbers...</Typography>
                  <LinearProgress />
                  <Fade in={requestRunningLong}>
                    <p>
                      Your threat profile is being calculated. This can take a few minutes minutes. Come back to review
                      the results.
                    </p>
                  </Fade>
                </>
              )}
            </>
          ) : (
            <ApiError {...buildErrorProps} />
          )}
        </>
      }
    />
  );
}
