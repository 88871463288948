import React from 'react';

import MuiChip, { ChipProps } from '@mui/material/Chip';
import classNames from 'classnames';

import { styled } from 'snap-ui/util';

const Chip = styled(MuiChip)`
  /** When we have filled or outlined icons looks smooshed */
  padding-left: ${props => (props.icon ? props.theme.spacing(1) : '0px')};

  border: revert;
  background-color: revert;
  color: ${props => props.theme.palette[props.color]?.main || 'revert'};
  padding-left: 0px;
`;

const RawChip = React.forwardRef<HTMLDivElement, ChipProps & { className?: string }>(function RawChip(
  { className, ...others },
  ref
) {
  return <Chip className={classNames('Chip', className)} {...others} ref={ref} />;
});

export default RawChip;
