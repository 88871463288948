import React from 'react';

import Icon from 'snap-ui/Icon';

import { OutcomesStatus } from 'module/BAS/BAS.type';

import { StateLabelBase, ValueMap } from './';

const VALUE_MAP: ValueMap<OutcomesStatus> = {
  [OutcomesStatus.Complete]: { color: 'success' },
  [OutcomesStatus.Failed]: { color: 'error', label: 'Failed' },
  [OutcomesStatus.InProgress]: {
    color: 'green',
    variant: 'outlined',
    label: 'In Progress',
    icon: <Icon.SpinnerProgress />
  },
  [OutcomesStatus.Pending]: { color: 'greyish', variant: 'outlined', label: 'Queued' }
};

export function DetermineOutcomeLabel({
  className,
  value
}: {
  className?: string;
  value: OutcomesStatus;
}): JSX.Element {
  return <StateLabelBase className={className} value={value} valueMap={VALUE_MAP} />;
}
