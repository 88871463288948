import { postIntel, putIntel } from 'module/Intel/Intel.api';
import { Intel, IntelPayload } from 'module/Intel/Intel.type';
import { EditIntelForm } from 'module/Intel/IntelEdit/IntelEdit';

import { Guid, Tracked, Visibility } from 'types/common';

export function createNewIntel(intel: EditIntelForm): Promise<Intel> {
  return postIntel(payloadFromForm(intel), intel.attachment);
}

export function updateIntel(guid: Guid, intel: EditIntelForm): Promise<Intel> {
  return putIntel(guid, payloadFromForm(intel), intel.attachment);
}

export function updateIntelDirect(guid: Guid, intel: Partial<IntelPayload>): Promise<Tracked> {
  return putIntel(guid, intel);
}

export function payloadFromForm(intel: EditIntelForm): Partial<IntelPayload> {
  const payload = {
    organization_id: parseInt(intel.organization_id),
    small_image: intel.image,
    indicators: intel.indicators,
    large_image: intel.large_image,
    name: intel.title,
    description: intel.description,
    original_author: intel.original_author,
    visibility: Visibility[intel.visibility],
    references: intel.reference,
    url: intel.url ?? intel.reference[0],
    actor_names: intel.actor_names,
    attack_names: intel.attack_names,
    software_names: intel.software_names,
    vulnerability_names: intel.vulnerability_names
  };
  return payload;
}
