import React from 'react';

import { useAsync } from 'storage';

import { Payload, getSocDashboardStats } from './SocDashboard.api';
import { Stats } from './SocDashboard.type';

function useSocDashboardStats() {
  const { data, run, status, error } = useAsync<Stats>();

  const getStats = React.useCallback(
    (payload: Payload) => {
      run(getSocDashboardStats(payload));
    },
    [run]
  );

  return {
    data,
    status,
    error,
    getStats
  };
}

export default useSocDashboardStats;
