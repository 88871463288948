import React from 'react';

import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons';

import { Backdrop } from 'snap-ui/BackdropLoader';
import Icon from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

function Unavailable({ className }: { className?: string }) {
  return (
    <div className={className}>
      <Backdrop>
        <Icon icon={faEyeSlash} />
        Video Unavailable
      </Backdrop>
    </div>
  );
}

const StyledUnavailable = styled(Unavailable)`
  padding-top: 25%;
  padding-bottom: 25%;

  & .Backdrop {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-transform: uppercase;

    & svg {
      height: 80px;
    }
  }
`;

export default StyledUnavailable;
