export function extractYouTubeVideoID(url) {
  // From https://www.labnol.org/code/19797-regex-youtube-id
  // eslint-disable-next-line no-useless-escape
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  var match = url.match(regExp);
  if (match && match[7].length == 11) {
    return match[7];
  } else {
    throw new Error('Could not extract YouTube Video ID');
  }
}
