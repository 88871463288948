import startCase from 'lodash/startCase';

export enum DEFAULT_ROLES {
  Admin = 'Admin',
  Auditor = 'Auditor',
  ContentAdmin = 'ContentAdmin',
  BASContributor = 'BASContributor',
  AnalyticContributor = 'AnalyticContributor',
  CollectionContributor = 'CollectionContributor',
  IntegrationContributor = 'IntegrationContributor',
  IntelContributor = 'IntelContributor',
  SessionContributor = 'SessionContributor',
  User = 'User',
  Impersonator = 'Impersonator'
}

export const SORTED_DEFAULT_ROLES = {
  [DEFAULT_ROLES.Admin]: 1,
  [DEFAULT_ROLES.Auditor]: 2,
  [DEFAULT_ROLES.ContentAdmin]: 3,
  [DEFAULT_ROLES.AnalyticContributor]: 4,
  [DEFAULT_ROLES.CollectionContributor]: 5,
  [DEFAULT_ROLES.BASContributor]: 6,
  [DEFAULT_ROLES.IntelContributor]: 7,
  [DEFAULT_ROLES.IntegrationContributor]: 8,
  [DEFAULT_ROLES.SessionContributor]: 9,
  [DEFAULT_ROLES.User]: 10
};

export const ROLE_LABEL_MAP = {
  [DEFAULT_ROLES.BASContributor]: 'Attack Script Contributor',
  [DEFAULT_ROLES.CollectionContributor]: 'Collection Contributor',
  [DEFAULT_ROLES.AnalyticContributor]: 'Detection Contributor',
  [DEFAULT_ROLES.IntegrationContributor]: 'Integration Contributor',
  [DEFAULT_ROLES.IntelContributor]: 'Intelligence Contributor',
  [DEFAULT_ROLES.SessionContributor]: 'Threat Contributor'
};

export const DEFAULT_ROLE_OPTIONS = Object.entries(DEFAULT_ROLES).map(([value, content]) => ({
  content: ROLE_LABEL_MAP[content] || startCase(content),
  value: value
}));

export enum REGISTRATION_MODES {
  Invite = 'Invite',
  // Approval = 'Approval',
  Auto = 'Auto'
}

export const REGISTRATION_MODE_OPTIONS = Object.entries(REGISTRATION_MODES).map(([value, content]) => ({
  content: content,
  value: value
}));

export enum GROUP_TYPE {
  Permission = 'Permission',
  RoleAssignment = 'RoleAssignment',
  Special = 'Special'
}

export const ORG_ADMIN_FIELDS = [
  'small_image',
  'large_image',
  'domains',
  'default_roles',
  'registration_mode',
  'mandatory_preference',
  'bulk_rank_active',
  'optional_preference'
];
