import isEmpty from 'lodash/isEmpty';

import { COMMUNITY_ORGANIZATION } from 'constants/auth';

import { Complexities } from 'module/Search';
import { Discriminator } from 'module/Tag';

import { Artifact, ArtifactScore, ArtifactScoreDetail, ArtifactType } from 'types/common';

export function getDeprecatedArtifactType(type: ArtifactType) {
  switch (type) {
    case ArtifactType.Analytic:
      return ArtifactType.DeprecatedAnalytic;
    case ArtifactType.Session:
      return ArtifactType.DeprecatedSession;
    case ArtifactType.Intel:
      return ArtifactType.DeprecatedIntel;
    case ArtifactType.AttackScript:
      return ArtifactType.DeprecatedAttackScript;
    default:
      return type;
  }
}

/**
 *
 * @param defaultOrgId
 * @param detail
 * @param withDefault - If provided, this will return if no match with organization_id. Otherwise, UNKNOWN is returned
 * @returns ArtifactScore
 */
export function getPreferredOrgScore(
  defaultOrgId: number,
  detail: ArtifactScoreDetail[],
  withDefault?: ArtifactScore
): ArtifactScore {
  // If performance is an issue, we can use a raw looper and track the community index as we go
  const result = getScoreDetails(defaultOrgId, detail);

  return result ? result.rank || result.severity : withDefault || ArtifactScore.UNKNOWN;
}

export function getScoreDetails(defaultOrgId: number, detail: ArtifactScoreDetail[]) {
  return (
    detail?.find(r => r.organization_id === defaultOrgId) ||
    detail?.find(r => r.organization_id === COMMUNITY_ORGANIZATION.id)
  );
}

export function getPreferredOrgRuntime(defaultOrgId: number, complexities: Complexities[]): number {
  // If performance is an issue, we can use a raw looper and track the community index as we go
  const result =
    complexities?.find(r => r.organization_id === defaultOrgId) ||
    complexities?.find(r => r.organization_id === COMMUNITY_ORGANIZATION.id);

  return result ? result.complexity : null;
}

export function getHighlightsByType(item: Partial<Artifact>): string[] {
  let aliases: string[];
  switch (item?.type) {
    case Discriminator.Actor:
      aliases = isEmpty(item.actor_aliases_idx_highlights)
        ? item.actor_aliases
        : extractHighlights(item.actor_aliases_idx_highlights);
      break;
    case Discriminator.Software:
      aliases = isEmpty(item.software_aliases_idx_highlights)
        ? item.software_aliases
        : extractHighlights(item.software_aliases_idx_highlights);
      break;
    case Discriminator.Vulnerability:
      aliases = isEmpty(item.vulnerability_aliases_idx_highlights)
        ? item.vulnerability_aliases
        : extractHighlights(item.vulnerability_aliases_idx_highlights);
      break;
    default:
      // these don't have aliases; just use name highlights instead
      aliases = isEmpty(item.name_highlights) ? [item.name] : [item.name_highlights.slice().shift()];
  }

  return aliases || [];
}

function extractHighlights(idx_highlights: string[]): string[] {
  return isEmpty(idx_highlights) ? [] : idx_highlights?.slice().shift().split(' || ');
}
