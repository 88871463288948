import isEqual from 'lodash/isEqual';

import { HyperTag } from 'module/Landing/Landing.type';
import { Forest, Statistic } from 'module/Matrix/Matrix.type';

import { Ident } from 'types/common';
import { Coverage } from 'types/dashboard';

import { AttackType, Attack, Discriminator, AutocompleteResponse, MassTagPayload, AutocompleteModifiers } from '.';
import { CancelTokenType, snapattack } from '../../apis/snapattack';

const prefix = '/tags';

export async function getForest(): Promise<Forest> {
  return snapattack.get(`${prefix}/attacks/forest/`).then(r => r.data);
}

export async function getAttackByType(type: AttackType): Promise<Attack[]> {
  return snapattack.get(`${prefix}/attacks/?type=${type}`).then(r => r.data);
}

export async function filterStatisticBy(data: any, config: any): Promise<Statistic[]> {
  return snapattack.post(`${prefix}/attacks/statistics/`, data, config).then(r => r.data);
}

export function filterCoverage(type: AttackType): Promise<Coverage> {
  return snapattack.get(`${prefix}/attacks/coverage/?type=${type}`).then(r => r.data);
}

export function searchTagsByDiscriminators(
  limit: number,
  modifiers: AutocompleteModifiers,
  config?: { cancelToken?: CancelTokenType }
): Promise<AutocompleteResponse> {
  const mods = { ...modifiers };
  // HACK: while the backend figures out a slow query issue. if just Attack is supplied, this takes a minute
  if (isEqual(mods.types, [Discriminator.Attack]))
    mods.types = [
      'tag' as Discriminator,
      Discriminator.Action,
      Discriminator.Actor,
      Discriminator.Attack,
      Discriminator.DataSource,
      Discriminator.Software,
      Discriminator.Vulnerability
    ];
  return snapattack.post(`/search/autocomplete/tags/?limit=${limit}`, mods, config).then(r => r.data);
}

export async function getAllActionTags(): Promise<HyperTag[]> {
  return snapattack.get(`${prefix}/actions/`).then(r => r.data);
}

export async function getAllAttackTags(): Promise<HyperTag[]> {
  return snapattack.get(`${prefix}/attacks/`).then(r => r.data);
}

export async function postVulnerabilityAlias(id: Ident, aliases: string[]): Promise<void> {
  return snapattack.post(`${prefix}/vulnerabilities/${id}/aliases/`, aliases);
}

export function massAddTag(payload: MassTagPayload) {
  return snapattack.put(`${prefix}/mass/`, payload).then(r => r.data);
}

export function massRemTag(payload: MassTagPayload) {
  return snapattack.delete(`${prefix}/mass/`, { data: payload }).then(r => r.data);
}
export async function getAliasesByDiscriminator(id: Ident, discriminator: string): Promise<string[]> {
  return snapattack.get(`${prefix}/${discriminator}/${id}/aliases/`).then(r => r.data);
}
