import React from 'react';

import classNames from 'classnames';

import { RawChip } from 'snap-ui/Chip';
import Icon from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

import { Discriminator } from 'module/Tag';

import { ArtifactType } from 'types/common';
import { StrictReactNode } from 'types/core';

import { getArtifactIcon } from '../Layout/Artifact.helper';

const Container = styled('div')<{ url: string; placeholder: string }>`
  min-height: 250px;
  background-image: url('${p => p.url}'), url('${p => p.placeholder}');
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: ${p => p.theme.palette.background.default};
`;

const Window = styled('div')`
  padding: ${p => p.theme.spacing(5)};
  background-color: rgba(0, 0, 0, 0.65);
  border: 1px solid rgba(100, 100, 100, 0.65);
  border-radius: 5px;

  .Chip {
    text-transform: uppercase;
  }
`;

type Props = {
  children?: StrictReactNode;
  url?: string;
  className?: string;
  type?: ArtifactType | Discriminator;
  chipLabel?: string;
};

export default function HeroImage({ children, className, url, type, chipLabel }: Props) {
  const icon = getArtifactIcon(type) || <Icon.Info color='info' />;
  const darkpurple = process.env.PUBLIC_URL + '/images/darkpurple.jpg';

  return (
    <Container className={classNames('HeroImage', className)} url={url} placeholder={darkpurple}>
      {children && (
        <Window className='HeroImage-window'>
          {chipLabel && <RawChip label={chipLabel} icon={icon} color='primary' />}
          {children}
        </Window>
      )}
    </Container>
  );
}

export const HeroContainer = styled(HeroImage)`
  padding: var(--Scaffold-hero);
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    margin: ${p => p.theme.spacing(2)};
  }
`;
