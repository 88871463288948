import { styled } from 'snap-ui/util';

import MetadataPanel from 'module/Analytic/core/MetadataPanel';

export const SessionRecommendationsContainer = styled(MetadataPanel, { name: 'SessionRecommendationsContainer' })`
  flex: 1 0 400px;
  margin-top: ${p => p.theme.spacing(8)};

  .action-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: ${p => p.theme.spacing(3)};
    margin-bottom: ${p => p.theme.spacing(5)};
  }

  .Section {
    padding: ${p => p.theme.spacing(2, 0)};
    margin: ${p => p.theme.spacing(6, 4)};

    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 580px;
    overflow: auto;

    &.overflow-hidden {
      overflow: hidden;
    }

    dl {
      overflow-x: clip;
    }
  }

  .Alert {
    align-items: center;
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${p => p.theme.spacing(2)};
  }
`;

export const RecommendedContainer = styled('div', { name: 'RecommendedContainer' })`
  display: flex;
  padding: ${p => p.theme.spacing(1, 0, 0, 0)};
  align-items: baseline;
  justify-content: space-between;

  .Section {
    margin: 0;
  }

  .summary-text {
    display: inline;
    padding-right: ${p => p.theme.spacing(2)};
  }

  .summary-icons {
    margin-right: ${p => p.theme.spacing(2)};
    .negative {
      color: ${p => p.theme.palette.error.main};
    }

    .positive {
      color: ${p => p.theme.palette.success.main};
    }
  }

  .deploy-text {
    display: inline;
  }

  .MuiPaper-root.MuiAccordion-root {
    flex-grow: 1;
  }
`;
