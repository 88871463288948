import React from 'react';

import { Discriminator } from 'module/Tag';

import { Catalog, Scheme, stowItem, useAsync } from 'storage';

import { LANDING_SHELL } from './Landing.const';
import { getLanding } from './Landing.service';
import { Landing } from './Landing.type';

export default function useLanding(discriminator: Discriminator, name: string) {
  const version = `${discriminator}-${name}`;
  const { data, errorProps, reset, setData, setError, status, task } = useAsync<Landing>(LANDING_SHELL);

  const getData = React.useCallback(
    (rollup: boolean) => {
      return getLanding(discriminator, name, rollup);
    },
    [discriminator, name]
  );

  const stow = React.useCallback(() => {
    stowItem(Scheme.sessionStorage, Catalog.landing, data, version);
  }, [data, version]);

  return {
    data,
    errorProps,
    getData,
    reset,
    setData,
    setError,
    status,
    stow,
    task
  };
}
