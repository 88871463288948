import React from 'react';

import { CancelTokenSource } from 'axios';

import { CancelToken } from 'apis/snapattack';

import { ErrorProps } from 'module/ApiError';
import { isLandingType } from 'module/Collection/Collection.util';
import { DisplayKind } from 'module/Feed/Feed.type';

import { useUserConfig } from 'provider';

import { Status, useAsync } from 'storage';

import { ArtifactType, Tracked } from 'types/common';

import { getMetadataBulk } from './Metadata.api';
import { getMetadataBulkEndpoint } from './Metadata.service';
import { MetadataCatalog } from './Metadata.type';

export type MetadataInterface = {
  errorProps: ErrorProps;
  metadata: MetadataCatalog;
  refresh: () => void;
  status: Status;
};

export default function useMetadataItems<T extends Tracked & { type?: string }>(
  topic: ArtifactType,
  items: T[]
): MetadataInterface {
  const { display } = useUserConfig();
  const { data: metadata, errorProps, status, run } = useAsync<MetadataCatalog>({});
  const itemsRunTrigger = JSON.stringify(items);

  const refresh = React.useCallback(() => {
    const cancelSource = CancelToken.source();
    if (items.length > 0 && display === DisplayKind.Grid) {
      const nonLandingItems = items.filter(i => !isLandingType(i));
      run(getMetadataItemsCatalog(topic, nonLandingItems, cancelSource));
    }
    return () => cancelSource?.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsRunTrigger, run]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    errorProps,
    metadata,
    refresh,
    status
  };
}

export async function getMetadataItemsCatalog<T extends Tracked>(
  topic: ArtifactType,
  items: T[],
  cancelTokenSource?: CancelTokenSource
): Promise<MetadataCatalog> {
  const prefix = getMetadataBulkEndpoint(topic);
  if (!prefix) return Promise.resolve({});

  const config = {
    cancelToken: cancelTokenSource && cancelTokenSource.token
  };
  return getMetadataBulk(
    prefix,
    items.map(i => i.guid),
    config
  );
}
