import React from 'react';

import classNames from 'classnames';

import {
  DataGrid,
  GridColDef,
  GridComparatorFn,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValidRowModel,
  gridExpandedSortedRowIdsSelector,
  useGridApiRef
} from 'snap-ui/DataGrid';
import { DisplayDialog } from 'snap-ui/Dialog';
import Grid from 'snap-ui/Grid';
import Link from 'snap-ui/Link';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';

import Path from 'constants/paths';

import { HyperTag } from 'module/Landing/Landing.type';
import TruncateContainer from 'module/Layout/TruncateContainer';
import AttackChip from 'module/Widgets/AttackChip';
import TagLandingPreview from 'module/Widgets/TagLandingPreview';

import { ArtifactType } from 'types/common';

import { compareAlphaNumericStrings } from 'utilities/SortUtils';

import PanelPaper from '../core/PanelPaper';
import { determineNistCoverage } from './NistDashboard.helper';
import { NistTableContainer, Section, SubTitle } from './NistDashboard.style';
import { NistArtifact } from './NistDashboard.type';

const TAG_TRUNCATE_LIMIT = 5;

type NistTableProp = {
  nist: NistArtifact[];
  updateNist?: (filterNist: NistArtifact[]) => void;
};
function NistTable({ nist, updateNist }: NistTableProp) {
  const apiRef = useGridApiRef();
  const [selected, setSelected] = React.useState<NistArtifact>();

  React.useEffect(() => {
    return apiRef.current.subscribeEvent('filteredRowsSet', () => {
      const rowIds = gridExpandedSortedRowIdsSelector(apiRef);
      const rows = rowIds.map(rowId => apiRef.current.getRow(rowId));
      updateNist(rows);
    });
  }, [apiRef, updateNist]);

  const naturalSort: GridComparatorFn<any> = (a, b) => {
    return compareAlphaNumericStrings(a, b);
  };

  const columns: GridColDef<GridValidRowModel & NistArtifact>[] = [
    {
      field: 'external_source_id',
      headerName: 'Control ID',
      width: 100,
      sortComparator: naturalSort
    },
    {
      field: 'tag_name',
      headerName: 'Control Name',
      minWidth: 450,
      renderCell: cell => (
        <span className='control-name' onClick={() => setSelected(cell.row as NistArtifact)}>
          {cell.row.detail.name}
        </span>
      )
    },
    {
      field: 'tags',
      headerName: 'MITRE ATT&CK',
      minWidth: 300,
      flex: 1,
      renderCell: cell => {
        return (
          <Tooltip
            arrow
            title={cell.row.tags
              .sort((a, b) => a.mitre_id.localeCompare(b.mitre_id))
              .map((tag, i) => (
                <span key={tag.mitre_id}>
                  {i !== 0 ? ', ' : ''}
                  {tag.mitre_id}
                </span>
              ))}
          >
            <div className='tag-description'>
              {cell.row.tags
                .sort((a, b) => a.mitre_id.localeCompare(b.mitre_id))
                .slice(0, TAG_TRUNCATE_LIMIT)
                .map((tag, i) => (
                  <Link
                    to={`${Path.Collection}/${tag.discriminator}/${encodeURIComponent(tag.name)}`}
                    target='_blank'
                    key={tag.id + cell.id.toString()}
                  >
                    {i !== 0 ? ' , ' : ''}
                    <span>{tag.mitre_id}</span>
                  </Link>
                ))}
              {cell.row.tags.length > TAG_TRUNCATE_LIMIT ? '...' : ''}
            </div>
          </Tooltip>
        );
      }
    },
    {
      field: 'detections_deployed',
      headerName: 'Deployed',
      width: 100,
      align: 'center',
      valueGetter: p => p.row.detections_deployed,
      cellClassName: p => classNames('deployed', determineNistCoverage(p.row.detections_deployed)),
      renderCell: cell => {
        return (
          <Link
            to={`${Path.Feed}?topic=${ArtifactType.Analytic}&sort_by=relevance&deployedStatus=success&actions=${cell.row.detail.name}`}
            target='_blank'
          >
            {cell.row?.detections_deployed.toString()}
          </Link>
        );
      }
    },
    {
      field: 'detections_available',
      headerName: 'Available',
      width: 100,
      align: 'center',
      valueGetter: p => p.row.detections_available,
      renderCell: cell => {
        return (
          <Link
            to={`${Path.Feed}?topic=${ArtifactType.Analytic}&sort_by=relevance&deployedStatus=false&deployedStatus=failed&deployedStatus=pending&deployedStatus=outdated&actions=${cell.row.detail.name}`}
            target='_blank'
          >
            {cell.row?.detections_available.toString()}
          </Link>
        );
      }
    }
  ];

  return (
    <NistTableContainer>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <PanelPaper>
            <Typography variant='h2'>NIST Coverage by Control</Typography>
            <DataGrid
              apiRef={apiRef}
              className='data-grid'
              columns={columns}
              rows={nist}
              disableRowSelectionOnClick
              checkboxSelection={true}
              getRowId={row => row.tag_id}
              getCellClassName={() => 'dataGrid-row'}
              slots={{
                toolbar: () => <CustomToolbar />
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'external_source_id', sort: 'asc' }]
                }
              }}
            />
          </PanelPaper>
        </Grid>
      </Grid>
      <DisplayDialog DialogProps={{ open: !!selected, onClose: () => setSelected(undefined), maxWidth: 'md' }} title=''>
        {selected && (
          <>
            <Section>
              <Typography component='label' variant='body1'>
                {selected.external_source_id}
              </Typography>
              <Typography component='label' variant='h1'>
                {selected.detail.name}
              </Typography>
            </Section>
            <Section>
              <SubTitle>Description</SubTitle>
              {selected.detail.description}
            </Section>
            <Section>
              <SubTitle>Related ATT&CK Techniques</SubTitle>
              <TruncateContainer>
                {selected.tags
                  .sort((a, b) => a.mitre_id.localeCompare(b.mitre_id))
                  .map(v => (
                    <TagLandingPreview tag={v as HyperTag} key={v.id}>
                      <AttackChip onClick={() => setSelected(undefined)} tag={v as HyperTag} />
                    </TagLandingPreview>
                  ))}
              </TruncateContainer>
            </Section>
          </>
        )}
      </DisplayDialog>
    </NistTableContainer>
  );
}

function CustomToolbar() {
  return (
    <div className='nistToolbar'>
      <GridToolbarFilterButton />
      <div className='data-header-action'>
        <GridToolbarExport
          printOptions={{
            disableToolbarButton: true
          }}
        />
      </div>
    </div>
  );
}

export default NistTable;
