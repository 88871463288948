import React from 'react';

import MuiFade, { FadeProps } from '@mui/material/Fade';
import MuiSnackbar from '@mui/material/Snackbar';

type Props = {
  open: boolean;
  children?: React.ReactElement;
  onClose(): void;
  autoHideDuration?: number;
  message?: string;
  anchorOrigin?: { horizontal: 'center' | 'left' | 'right'; vertical: 'top' | 'bottom' };
};

export default function Snackbar({ children, onClose, ...others }: Props) {
  const handleClose = (_e: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    onClose();
  };
  return (
    <MuiSnackbar {...others} onClose={handleClose} TransitionComponent={(props: FadeProps) => <MuiFade {...props} />}>
      {children}
    </MuiSnackbar>
  );
}
