import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { Page } from 'module/Feed/Feed.type';

import { Status, useAsync } from 'storage';

import { PageParams } from 'types/common';
import { Query } from 'types/filter';

import { basCampaignFeed, postCampaignSupplemental } from './BAS.api';
import { BASCampaignFeed, BASCampaignSupplemental } from './BAS.type';
import { composeCampaignWithSupplement } from './BAS.util';

const DEFAULT_PAGE_SIZE = 10;

type CampaignsBag = {
  data: Page<BASCampaignFeed>;
  getPage(params: Partial<PageParams>, payload?: Query): void;
  status: Status;
};

const SHELL: CampaignsBag['data'] = {
  items: [],
  page: 0,
  size: DEFAULT_PAGE_SIZE,
  total: 0
};

export default function useCampaigns(): CampaignsBag {
  const { data: campaigns, run: runCampaigns, status: campaignStatus } = useAsync(SHELL);
  const { data: supplementals, run: supplementalRun } = useAsync<BASCampaignSupplemental[]>([]);

  const getPage = React.useCallback(
    (params: Partial<PageParams> = {}, payload?: Query) => {
      const pageParams = { page: 0, size: DEFAULT_PAGE_SIZE, ...params };
      return runCampaigns(basCampaignFeed(pageParams, payload));
    },
    [runCampaigns]
  );

  React.useEffect(() => {
    const guids = campaigns.items.map(campaign => campaign.guid);
    if (isEmpty(guids)) return;

    supplementalRun(postCampaignSupplemental(guids));
  }, [campaigns.items, supplementalRun]);

  const data = React.useMemo(() => {
    const newItems = campaigns.items.map(campaign => {
      return composeCampaignWithSupplement(campaign, supplementals);
    });

    return { ...campaigns, items: newItems };
  }, [campaigns, supplementals]);

  return {
    data,
    getPage,
    status: campaignStatus
  };
}
