import React from 'react';

import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { useField } from 'formik';

import Icon from 'snap-ui/Icon';
import Link from 'snap-ui/Link';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { AttackScript } from 'module/AttackScript/AttackScript.type';
import AutocompleteFormik from 'module/Form/AutocompleteFormik';

import { FieldContainer } from './layout';

type ScriptFieldProps = {
  scripts: AttackScript[];
};

const ScriptOptionWrapper = styled('div')`
  display: block;
`;

export default function ScriptField({ scripts }: ScriptFieldProps): JSX.Element {
  const [field] = useField('script_id');
  const options = scripts.map(script => ({
    content: (
      <ScriptOptionWrapper key={script.guid}>
        <em>{script.product_name}</em>
        <br />
        {script.name}
      </ScriptOptionWrapper>
    ),
    label: script.name,
    value: script.guid
  }));

  return (
    <FieldContainer>
      <AutocompleteFormik
        className='input'
        disableClearable
        disableUserAdditions
        label='Attack'
        name='script_id'
        options={options}
      />
      <Tooltip arrow title='View attack script' wrap>
        <Link
          aria-label='View attack script'
          to={`${Path.AttackScript}/${field.value}`}
          rel='noopener noreferrer'
          target='_blank'
        >
          <Icon icon={faArrowUpRightFromSquare} />
        </Link>
      </Tooltip>
    </FieldContainer>
  );
}
