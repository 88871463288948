import React from 'react';

import { getBackfill } from 'module/Session/Session.api';
import { BACKFILL_SHELL } from 'module/Session/Session.const';
import { Backfill } from 'module/Session/Session.type';

import { usePushSnack } from 'provider/Snack';

import { useAsync } from 'storage';

import { CeleryState, Guid } from 'types/common';

const BACKFILL_STATE: (CeleryState | 'IDLE')[] = [CeleryState.in_progress, CeleryState.pending, CeleryState.retrying];

export default function useBackfill(session: Guid): Backfill {
  const pushSnack = usePushSnack();
  const { data, run, setData } = useAsync<Backfill>(BACKFILL_SHELL);
  const isBackfilling = BACKFILL_STATE.includes(data.status);

  React.useEffect(() => {
    run(getBackfill(session).then(b => b || BACKFILL_SHELL));
  }, [run, session]);

  React.useEffect(() => {
    let timer: NodeJS.Timeout = null;
    if (timer === null && session && isBackfilling) {
      // This snack will get overwritten by the no marker snack.
      // Better solution is to not show this. Rely on the backfill button for information
      // pushSnack('Detections are currently being run against this threat.', 'info');

      const cycle = (time: number) => {
        timer = setTimeout(async () => {
          try {
            var response = await getBackfill(session);
          } catch (e) {
            pushSnack('Gah! Maybe we dug too deep: ' + e?.message, 'error');
            return;
          }
          setData(response);
          if (response?.status === 'IDLE') {
            pushSnack('Detection run complete.', 'success');
            return;
          }
          cycle(5000);
        }, time);
      };

      cycle(5000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isBackfilling, session, pushSnack, setData]);

  return data;
}
