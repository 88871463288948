import React from 'react';

import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';

import Accordion, { AccordionDetails, AccordionSummary } from 'snap-ui/Accordion';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { Cvss2Card, Cvss3Card } from '../../Landing.widgets';
import { useLandingCatalog } from '../../LandingProvider';

const Container = styled(Accordion)`
  .cvssContainer {
    display: flex;
    flex-wrap: wrap;
    gap: ${p => p.theme.spacing(6)};
  }
`;

export default function CommonVulnerabilityScoringSystem() {
  const { source } = useLandingCatalog();

  if (!source.cvss_3_base_score && !source.cvss_2_base_score) return null;
  return (
    <Container className='LandingSection-accordion' defaultExpanded={true} elevation={0}>
      <AccordionSummary className='LandingSection-accordionSummary' expandIcon={<Icon icon={faCaretUp} />}>
        <Typography variant='h4' className='category'>
          Common Vulnerability Scoring System (CVSS)
        </Typography>
      </AccordionSummary>
      <AccordionDetails className='Landing-accordion-detail'>
        <div className='cvssContainer'>
          <Cvss3Card />
          <Cvss2Card />
        </div>
      </AccordionDetails>
    </Container>
  );
}
