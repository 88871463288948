import React from 'react';

import Tooltip from 'snap-ui/Tooltip';

import { KALI_OS } from '../Session.const';
import { AttackerHostButtonContainer } from './HostButtonContainer.style';

type Props = {
  disabled: boolean;
  onChange(values: string[]): void;
  values: string[];
};

export default function KaliButton({ disabled, values, onChange }: Props) {
  const isSelected = values.includes(KALI_OS);

  const handleChange = () => {
    const newValue = isSelected ? [] : [KALI_OS];
    onChange(newValue);
  };

  return (
    <Tooltip title={disabled ? 'There are no Kali machines available' : ''} wrap arrow placement='top'>
      <AttackerHostButtonContainer selected={isSelected} onChange={handleChange} value={KALI_OS} disabled={disabled}>
        <div className='vm-logo'>
          <img src={process.env.PUBLIC_URL + '/images/kali-logo.png'} alt='Kali Linux' />
        </div>
        Kali Linux
      </AttackerHostButtonContainer>
    </Tooltip>
  );
}
