import React from 'react';

import isEmpty from 'lodash/isEmpty';

import Divider from 'snap-ui/Divider';
import { styled } from 'snap-ui/util';

import { useIDEState } from 'module/IDE';
import { Discriminator } from 'module/Tag';

import CustomMetadata from './CustomMetadata';
import DescriptionField from './DescriptionField';
import DynamicTagsField from './DynamicTagsField';
import LicenseField from './LicenseField';
import OrganizationField from './OrganizationField';
import ReferencesField from './ReferencesField';
import TitleField from './TitleField';

type MetadataFormProps = {
  readonly?: boolean;
};

const StyledForm = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(6)};
  padding: ${p => p.theme.spacing(6)};
`;

function MetadataForm({ readonly }: MetadataFormProps): React.ReactElement {
  const { ideState } = useIDEState();

  return (
    <StyledForm>
      <OrganizationField readonly={readonly} />
      <TitleField readonly={readonly} />
      <DescriptionField readonly={readonly} />
      <DynamicTagsField discriminator={Discriminator.Attack} readonly={readonly} />
      <DynamicTagsField discriminator={Discriminator.Actor} includeTagNames readonly={readonly} />
      <DynamicTagsField discriminator={Discriminator.Software} includeTagNames readonly={readonly} />
      <DynamicTagsField discriminator={Discriminator.Vulnerability} includeTagNames readonly={readonly} />
      <ReferencesField readonly={readonly} />
      <LicenseField readonly={readonly} />
      {!isEmpty(ideState.metadata.meta) && (
        <>
          <Divider />
          <CustomMetadata />
        </>
      )}
    </StyledForm>
  );
}

export default MetadataForm;
