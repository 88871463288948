import React from 'react';

import { faCloudArrowDown } from '@fortawesome/pro-regular-svg-icons';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import { InlineAlert } from 'snap-ui/Alert';
import { ActionIconButton } from 'snap-ui/Button';
import { MultiProgressBarProps } from 'snap-ui/LinearProgress';
import Placeholder from 'snap-ui/Placeholder';
import Tabs from 'snap-ui/Tabs';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';

import { catalog } from 'module/Matrix/Matrix.const';
import { filterForestByMitreID } from 'module/Matrix/Matrix.service';
import { AttackNode, Forest } from 'module/Matrix/Matrix.type';
import { exportMatrixToAttackNavigation, getMitreID } from 'module/Matrix/Matrix.util';
import MiniMatrix from 'module/Matrix/MiniMatrix';
import useTactics from 'module/Matrix/Tactic/useTactics';
import useForest from 'module/Matrix/useForest';
import useMatrixOptions from 'module/Matrix/useMatrixOptions';

import { JOB_OUTCOME_ORDER, JobOutcome } from 'types/bas';
import { ArtifactType } from 'types/common';

import { BASCampaign } from '../BAS.type';
import { getFlatObjectCount } from '../BAS.util';
import {
  getJobOutcomeColors,
  JobOutcomeColors,
  MitreLegendContainer,
  MitreTacticCoverageChartContainer,
  MitreTacticCoverageMatrixContainer,
  Progress
} from './CampaignReport.style';

type MitreTacticCoverageSectionProps = {
  campaign: BASCampaign;
  isLoading: boolean;
};

const DATA_EMPTY_MESSAGE = 'Please add outcomes below.';
export default function MitreTacticCoverageSection({
  campaign,
  isLoading
}: MitreTacticCoverageSectionProps): JSX.Element {
  const { forest } = useForest();

  if (isLoading)
    return (
      <div className='CampaignReport-section'>
        <Placeholder variant='text' height={66} width={250} />
        <Placeholder variant='rectangular' height={308} width='100%' />
      </div>
    );

  if (isEmpty(campaign.tactic_coverage)) return null;

  const tabs = [
    {
      value: 'chart',
      content: <MitreTacticCoverageChart campaign={campaign} />,
      label: 'Chart'
    },
    {
      value: 'matrix',
      content: <MitreTacticCoverageMatrix campaign={campaign} forest={forest} />,
      label: 'Matrix'
    }
  ];

  return (
    <div className='CampaignReport-section'>
      <Typography variant='h2'>MITRE ATT&CK Coverage</Typography>
      <div>
        <Tabs className='MitreTacticCoverage-tabs' tabs={tabs} />
      </div>
    </div>
  );
}

function getOutcome(outcome: Record<JobOutcome, number>): JobOutcome {
  if (outcome[JobOutcome.Prevented]) return JobOutcome.Prevented;
  if (outcome[JobOutcome.Detected]) return JobOutcome.Detected;
  if (outcome[JobOutcome.Logged]) return JobOutcome.Logged;
  if (outcome[JobOutcome.NoAction]) return JobOutcome.NoAction;
  if (outcome[JobOutcome.NotTested]) return JobOutcome.NotTested;
  return JobOutcome.NeedsReview;
}

function MitreTacticCoverageMatrix({ campaign, forest }: { campaign: BASCampaign; forest: Forest }): JSX.Element {
  const meta = useMatrixOptions();

  let filteredForest = [];
  if (campaign.attack_coverage) {
    const mitreOutcomes = Object.entries(campaign.attack_coverage).reduce(
      (outcomes, [key, value]) => ({
        ...outcomes,
        [getMitreID(key)]: getOutcome(value)
      }),
      {}
    );

    filteredForest = filterForestByMitreID(forest, mitreOutcomes);
  }

  return (
    <MitreTacticCoverageMatrixContainer>
      <Legend />
      <Tooltip arrow className='export-button' title='Export ATT&CK Navigator Json'>
        <ActionIconButton
          aria-label='Export ATT&CK Navigator Json'
          icon={faCloudArrowDown}
          onClick={() =>
            exportMatrixToAttackNavigation(filteredForest, meta, ArtifactType.Analytic, catalog[meta.palette])
          }
        />
      </Tooltip>

      <MiniMatrix forest={filteredForest} getNodeStyle={(node: AttackNode) => getJobOutcomeColors(node.outcome)} />
    </MitreTacticCoverageMatrixContainer>
  );
}

function Legend(): JSX.Element {
  const outcomes = [...JOB_OUTCOME_ORDER].reverse();
  return (
    <MitreLegendContainer className='Legend'>
      {outcomes.map(outcome => (
        <div className='Legend-item' key={outcome}>
          <div className='Legend-colorSample' style={getJobOutcomeColors(outcome)}></div>
          <div>{outcome}</div>
        </div>
      ))}
    </MitreLegendContainer>
  );
}

type TacticCoverageProps = {
  coverage: {
    [JobOutcome.NotTested]: number;
    [JobOutcome.NoAction]: number;
    [JobOutcome.Logged]: number;
    [JobOutcome.Detected]: number;
    [JobOutcome.Prevented]: number;
  };
  total: number;
};

function TacticCoverage({ coverage, total }: TacticCoverageProps): JSX.Element {
  const bars: MultiProgressBarProps[] = [
    {
      color: JobOutcomeColors[JobOutcome.Prevented],
      children: <>{coverage[JobOutcome.Prevented]}</>,
      label: `${coverage[JobOutcome.Prevented]} ${JobOutcome.Prevented} `,
      percent: Number(((coverage[JobOutcome.Prevented] / total) * 100).toFixed(2))
    },
    {
      color: JobOutcomeColors[JobOutcome.Detected],
      children: <>{coverage[JobOutcome.Detected]}</>,
      label: `${coverage[JobOutcome.Detected]} ${JobOutcome.Detected} `,
      percent: Number(((coverage[JobOutcome.Detected] / total) * 100).toFixed(2))
    },
    {
      color: JobOutcomeColors[JobOutcome.Logged],
      children: <>{coverage[JobOutcome.Logged]}</>,
      label: `${coverage[JobOutcome.Logged]} ${JobOutcome.Logged} `,
      percent: Number(((coverage[JobOutcome.Logged] / total) * 100).toFixed(2))
    },
    {
      color: JobOutcomeColors[JobOutcome.NoAction],
      children: <>{coverage[JobOutcome.NoAction]}</>,
      label: `${coverage[JobOutcome.NoAction]} ${JobOutcome.NoAction} `,
      percent: Number(((coverage[JobOutcome.NoAction] / total) * 100).toFixed(2))
    },
    {
      color: JobOutcomeColors[JobOutcome.NotTested],
      children: <>{coverage[JobOutcome.NotTested]}</>,
      label: `${coverage[JobOutcome.NotTested]} ${JobOutcome.NotTested} `,
      percent: Number(((coverage[JobOutcome.NotTested] / total) * 100).toFixed(2))
    }
  ].filter(bar => !!bar.percent);
  return <Progress bars={bars} />;
}

function MitreTacticCoverageChart({ campaign }: { campaign: BASCampaign }): JSX.Element {
  const { tactics } = useTactics();
  const overallTotal = Object.values(campaign.tactic_coverage).reduce(
    (overallTotal, outcomes) => overallTotal + getFlatObjectCount(outcomes, JobOutcome.NeedsReview),
    0
  );

  return (
    <MitreTacticCoverageChartContainer>
      {overallTotal ? (
        <>
          <Legend />
          <div className='MitreTacticCoverageChart-bars'>
            {tactics.map(tactic => {
              const coverage = omit(campaign.tactic_coverage[tactic.name], JobOutcome.NeedsReview);
              const total = getFlatObjectCount(coverage);
              return total ? (
                <React.Fragment key={tactic.mitre_id}>
                  <p className='tactic-name'>
                    {tactic.name} ({total})
                  </p>
                  <TacticCoverage coverage={coverage} total={total} />
                </React.Fragment>
              ) : null;
            })}
          </div>
        </>
      ) : (
        <InlineAlert severity='info'>{DATA_EMPTY_MESSAGE}</InlineAlert>
      )}
    </MitreTacticCoverageChartContainer>
  );
}
