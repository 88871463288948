import React from 'react';

import Tabs, { TabItem } from 'snap-ui/Tabs';
import Typography from 'snap-ui/Typography';

import { JobListPage } from 'module/BAS/JobList';
import { Page } from 'module/Layout/Styled';
import May from 'module/May';

import { FunctionalPermission } from 'types/auth';

import { CampaignList } from '../CampaignList';
import Download from '../Download';
import CampaignJobListPage from './CampaignJobListPage';

export default function AttackSimulations(): JSX.Element {
  const tabs: TabItem[] = [
    {
      value: 'attack_simulations',
      content: <CampaignList />,
      label: 'Attack Simulations'
    },
    {
      value: 'ad_hoc_attacks',
      content: <CampaignJobListPage />,
      label: 'Ad Hoc Attacks'
    }
  ];

  return (
    <Page>
      <Typography variant='h1'>Attack Simulation Results</Typography>
      <Download />
      <May I={FunctionalPermission.BASStableFeatures}>
        <May.Yes>
          <Tabs className='AttackSimulations-tabs' tabs={tabs} />
        </May.Yes>
        <May.No>
          <JobListPage />
        </May.No>
      </May>
    </Page>
  );
}
