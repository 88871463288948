import { Detection } from 'module/Detection/Detection.type';

export const DETECTION_SHELL: Detection = {
  analytics: [''],
  blue_collisions: [],
  detected: [],
  red_collisions: [],
  undetected: [],
  unvalidated: [],
  validated: [],
  detection_gaps: [],
  validated_gaps: [],
  unique_analytics: 0
};
