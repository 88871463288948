import React from 'react';

import zod from 'zod';

import { Option } from 'snap-ui/Autocomplete';

import { Discriminator } from 'module/Tag';
import useTagOptions from 'module/Tag/useTagOptions';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';

import { FilterConfig } from '../GlobalFilter.type';
import InclusiveAutocomplete from './InclusiveAutocomplete';

type ThreatActorKeys = {
  actor: string[];
  actorOp: Ops;
};

type ThreatActorFilterProps = {
  onChange(values: Partial<ThreatActorKeys>): void;
  values: ThreatActorKeys;
};

function ThreatActorFilter({ onChange, values }: ThreatActorFilterProps): React.ReactElement {
  const { options, search, searching } = useTagOptions(Discriminator.Actor);

  const handleValueChange = (option: Option[]) => {
    const payload = { actor: option.map(o => o.value) };
    if (option.length === 0) payload['actorOp'] = undefined;
    onChange(payload);
  };

  const handleOperatorChange = (actorOp: Ops) => {
    onChange({ actorOp });
  };

  return (
    <InclusiveAutocomplete
      title='By Threat Actor'
      name='actor_tags_dropdown'
      option={options}
      value={values.actor}
      onValueChange={handleValueChange}
      onOperatorChange={handleOperatorChange}
      operator={values.actorOp}
      onSearch={search}
      searching={searching}
    />
  );
}

function toQuery(values: ThreatActorKeys) {
  if (!values.actor?.length) return;
  return {
    field: 'actor_aliases',
    op: values.actorOp,
    value: values.actor
  };
}

function toAttackTagQuery(values: ThreatActorKeys) {
  if (!values.actor?.length) return;
  return {
    field: 'actors.name',
    op: values.actorOp,
    value: values.actor
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('actor_aliases'),
    op: zod.nativeEnum(Ops),
    value: zod.array(zod.string())
  })
  .transform(query => ({
    actor: query.value,
    actorOp: query.op
  }));

const fromAttackTagQuery = zod
  .object({
    field: zod.literal('actors.name'),
    op: zod.nativeEnum(Ops),
    value: zod.array(zod.string())
  })
  .transform(query => ({
    actor: query.value,
    actorOp: query.op
  }));

const ThreatActorFilterConfig: FilterConfig<ThreatActorKeys> = {
  defaults: { default: () => ({ actor: [], actorOp: Ops.any }) },
  supportedTopics: [
    ArtifactType.Analytic,
    ArtifactType.AttackTag,
    ArtifactType.Collection,
    ArtifactType.Intel,
    ArtifactType.Session,
    ArtifactType.AttackScript
  ],
  component: ThreatActorFilter,
  toQuery: { default: toQuery, [ArtifactType.AttackTag]: toAttackTagQuery },
  fromQuery: { default: fromQuery, [ArtifactType.AttackTag]: fromAttackTagQuery }
};
export default ThreatActorFilterConfig;
