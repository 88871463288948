import React from 'react';

import startCase from 'lodash/startCase';

import PieChart from 'snap-ui/Charts/PieChart';

import { BASCampaign, BASJobState } from 'module/BAS/BAS.type';

import { getFlatObjectCount } from '../../BAS.util';
import { AttackStatusChartColors, PieChartDefaultProperties } from '../CampaignReport.style';
import ReportPanel from './ReportPanel';

type TestsExecutedPanelProps = {
  campaign: BASCampaign;
};

function TestsExecutedPanel({ campaign }: TestsExecutedPanelProps) {
  const testsExecuted = getFlatObjectCount(campaign.result_status);

  const attackStatusChartData = React.useMemo(() => {
    if (!campaign.result_status) return [];
    return [
      { name: startCase(BASJobState.Completed), value: campaign.result_status[BASJobState.Completed] },
      { name: startCase(BASJobState.MissingPrereq), value: campaign.result_status[BASJobState.MissingPrereq] },
      { name: startCase(BASJobState.Failed), value: campaign.result_status[BASJobState.Failed] },
      { name: 'Incomplete', value: campaign.result_status.incomplete }
    ];
  }, [campaign.result_status]);

  return (
    <ReportPanel title={`Tests Executed (${testsExecuted})`}>
      <PieChart colors={AttackStatusChartColors} data={attackStatusChartData} {...PieChartDefaultProperties} />
    </ReportPanel>
  );
}

export default TestsExecutedPanel;
