import React from 'react';

import Path from 'constants/paths';

import { CollectionDiscriminator } from 'module/Collection/Collection.type';
import { collectionPath } from 'module/Feed/Feed.util';

import { useAuth } from 'provider';

import { ArtifactType } from 'types/common';

import { formatQueryString } from 'utilities/SearchParam';

import ListItemLink from './ListItemLink';
import SectionDivider from './SectionDivider';

export default function ListItemsResearch({ onClose }: { onClose(): void }) {
  const { user } = useAuth();
  const viewPath =
    Path.FeedReset +
    formatQueryString({
      topic: ArtifactType.Intel,
      page: 1
    });

  return (
    <>
      <ListItemLink onClick={onClose} title='ATT&CK Techniques' to={collectionPath(CollectionDiscriminator.Attack)} />
      <ListItemLink onClick={onClose} title='Threat Actors' to={collectionPath(CollectionDiscriminator.Actor)} />
      <ListItemLink onClick={onClose} title='Software' to={collectionPath(CollectionDiscriminator.Software)} />
      <ListItemLink
        onClick={onClose}
        title='Vulnerabilities'
        to={collectionPath(CollectionDiscriminator.Vulnerability)}
      />
      <ListItemLink onClick={onClose} title='Collections' to={collectionPath(CollectionDiscriminator.Static)} />
      <ListItemLink onClick={onClose} title='Intelligence' to={viewPath} />
      {user.superuser && (
        <>
          <SectionDivider content='Research Team' />
          <ListItemLink
            aria-label='Go to create actor'
            id='MenuItemActorCreate'
            onClick={onClose}
            title='Create a New Actor'
            to={`${Path.CollectionCreate}/actor`}
          />
          <ListItemLink
            aria-label='Go to create software'
            id='MenuItemSoftwareCreate'
            onClick={onClose}
            title='Create a New Software'
            to={`${Path.CollectionCreate}/software`}
          />
        </>
      )}
    </>
  );
}
