import React from 'react';

import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { styled } from './util';

export { default as AlertTitle } from '@mui/material/AlertTitle';

export type Alert = {
  children?: React.ReactNode;
  className?: string;
  color?: AlertProps['color'];
  icon?: AlertProps['icon'];
  onClose?(): void;
  severity: AlertProps['severity'];
  variant?: AlertProps['variant'];
  width?: string;
};

export const Alert = React.forwardRef<HTMLDivElement, Alert>(function Alert(
  { children, variant = 'outlined', width = '100%', ...others },
  ref
) {
  return (
    <MuiAlert className='Alert' variant={variant} sx={{ width }} {...others} ref={ref}>
      {children}
    </MuiAlert>
  );
});

export default Alert;

export const BuffAlert = styled(Alert)`
  margin: ${p => p.theme.spacing(3, 0)};
`;

export function InlineAlert(props: Alert) {
  return <MuiAlert className='Alert' sx={{ border: 0 }} width='fit-content' variant='outlined' {...props} />;
}
