import React from 'react';

import { DataGrid, FooterPropsOverrides } from 'snap-ui/DataGrid';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';

import useTitle from 'hooks/useTitle';

import { ApiError } from 'module/ApiError';

import { useAuth } from 'provider';

import { Status, useAsync } from 'storage';

import { timeAgo } from 'utilities/TimeUtils';

import { BoardFooter } from './BoardFooter';
import { NoPlayer } from './NoPlayer';
import { TIMEOUT } from './Training.const';
import { fetchLeaderboard } from './Training.service';
import { Container } from './Training.style';
import { Player } from './Training.type';

export function Leaderboard() {
  const { defaultOrgGuid } = useAuth();
  const interval = React.useRef<NodeJS.Timeout>();
  const { data, setData, task, status, errorProps } = useAsync<Player[]>([]);
  useTitle('Leaderboard | SnapAttack');

  React.useEffect(() => {
    // TODO maybe implement cancel in the future
    const poll = () => task(fetchLeaderboard(defaultOrgGuid)).then(d => setData(d));

    // Get data now then set up interval
    poll();

    // Set up an interval for fetching data
    // Fetch data every n seconds (adjust as needed)
    interval.current = setInterval(poll, TIMEOUT);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(interval.current);
  }, [defaultOrgGuid, setData, task]);

  return (
    <Container>
      <Typography variant='h1'>Leaderboard {Status.pending === status && <Icon.SpinnerProgress />}</Typography>
      <ApiError {...errorProps} />
      <DataGrid
        rowSelection={false}
        columns={[
          { headerName: 'Place', field: 'place', flex: 1 },
          { headerName: 'Name', field: 'user_name', flex: 2 },
          { headerName: 'Score', field: 'score', flex: 1 },
          { headerName: 'Challenges Solved', field: 'challenges', flex: 2, valueGetter: row => row.value?.length || 0 },
          {
            headerName: 'Last Solved',
            field: 'last_attempt',
            flex: 1,
            valueGetter: row => timeAgo(row.value)
          }
        ]}
        rows={data}
        slots={{
          footer: BoardFooter,
          noRowsOverlay: NoPlayer
        }}
        slotProps={{
          footer: {
            onRefresh: () => task(fetchLeaderboard(defaultOrgGuid)).then(d => setData(d)),
            isActing: status === Status.pending
          } as FooterPropsOverrides
        }}
      />
    </Container>
  );
}
