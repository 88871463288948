import React from 'react';

import { RouteComponentProps, useHistory } from 'react-router-dom';

import { Alert } from 'snap-ui/Alert';
import Button from 'snap-ui/Button';
import Card, { CardContent, CardHeader } from 'snap-ui/Card';
import CircularProgress from 'snap-ui/CircularProgress';
import { ConfirmDialog } from 'snap-ui/Dialog';
import Link from 'snap-ui/Link';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';

import Path from 'constants/paths';

import useDebounce from 'hooks/useDebounce';
import useTitle from 'hooks/useTitle';

import { ApiError } from 'module/ApiError';
import { DiffEditor, DiffViewer } from 'module/DiffEditor';
import { IDETranslationStateProvider } from 'module/IDE';
import NotFound from 'module/Util/Fallback/NotFound';
import withFunctionalPermission from 'module/Util/withFunctionalPermission';

import { useIntegrationCatalog } from 'provider';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { ArtifactType } from 'types/common';

import { useAnalyticCatalog } from '../core/AnalyticProvider';
import Detection from '../core/Detection/Detection';
import { validateExpression } from '../core/Detection/Detection.util';
import useEditorStateContext from '../core/EditorStateProvider';
import useAnalyticPermission from '../core/useAnalyticPermission';
import { FineTuningContainer } from './AnalyticTuning.style';
import { HeaderContainer } from './AnalyticTuningHeader';
import { TuningPreviewPlaceholder } from './AnalyticTuningPreview';
import useTuningContext from './AnalyticTuningProvider';

type Props = RouteComponentProps<{ analyticGuid: string }>;

const AnalyticFineTuning = withFunctionalPermission(function AnalyticTuning(props: Props) {
  useTitle('SnapAttack | Detection Tuning');
  const { goBack, push, replace } = useHistory();
  const analyticGuid = props.match.params.analyticGuid;

  const [{ analytic, analyticError }] = useAnalyticCatalog();
  const [canSave, noSaveReason] = useAnalyticPermission(FunctionalPermission.Tuning);
  const { integrations, overwatch } = useIntegrationCatalog();
  const { isOverwatching } = overwatch;
  const [asNative, setAsNative] = React.useState<boolean>(analytic.is_native);
  const [asNativeDialogOpen, setAsNativeDialogOpen] = React.useState<boolean>(false);
  const { dispatch, tagOptions, state } = useEditorStateContext();

  const { applyTuning, isSaving, translationBag, setShouldTranslate, selectedLanguage, integrationGuid } =
    useTuningContext();
  const integration = integrations.all.find(i => i.guid === integrationGuid);

  const { translation, fprTranslation, status: translationStatus, error: translationError } = translationBag;
  const [editedFPR, setEditedFPR] = React.useState<string>(fprTranslation);
  const debouncedEdits = useDebounce(editedFPR, 1000);
  const [expressionError, setExpressionError] = React.useState<string>(null);

  React.useEffect(() => {
    setExpressionError(validateExpression(state.customization.detection.condition));
  }, [state.customization.detection.condition]);

  React.useEffect(() => {
    setShouldTranslate(!expressionError);
  }, [setShouldTranslate, expressionError]);

  React.useLayoutEffect(() => {
    if (!selectedLanguage) {
      replace(`${Path.Detection}/${analyticGuid}/tuning`);
    }
  }, [analyticGuid, replace, selectedLanguage]);

  React.useEffect(() => {
    dispatch({ type: 'RawUpdateAction', raw: debouncedEdits, tagOptions });
  }, [debouncedEdits, dispatch, tagOptions]);

  function handleApplyTuning() {
    applyTuning().then(guid => {
      push(`${Path.Detection}/${guid}`);
    });
  }

  if (analyticError) return <NotFound artifact={ArtifactType.Analytic} error={analyticError} />;

  return (
    <IDETranslationStateProvider>
      <FineTuningContainer>
        <HeaderContainer>
          <div>
            <Typography variant='h1'>Detection Tuning</Typography>
            {analytic.name && (
              <Link
                to={{
                  pathname: `${Path.Detection}/${analytic.guid}`
                }}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Typography variant='h2'>{analytic.name}</Typography>
              </Link>
            )}
          </div>
          <div className='actions'>
            <div className='buttons'>
              <Button disabled={isSaving} onClick={goBack} variant='outlined'>
                Cancel
              </Button>
              <Tooltip
                arrow
                placement='top'
                title={isOverwatching ? 'Saving is disabled for overwatch' : noSaveReason}
                wrap
              >
                <Button onClick={handleApplyTuning} disabled={isSaving || !canSave || isOverwatching}>
                  {isSaving ? <CircularProgress size={25} /> : <>Apply Tuning</>}
                </Button>
              </Tooltip>
            </div>
          </div>
        </HeaderContainer>
        <div className='contents'>
          {asNative ? (
            <Card className='TunedDetection native'>
              <CardHeader
                title={
                  <div className='title-bar'>
                    <Typography variant='h3'>Tuned Detection</Typography>
                  </div>
                }
              />
              <CardContent className='column'>
                {!canSave && <Alert severity='warning'>{noSaveReason}</Alert>}
                <DiffEditor
                  disabled={isSaving}
                  onChange={setEditedFPR}
                  value={editedFPR}
                  grammar={selectedLanguage?.native_key}
                  original={translation}
                />
              </CardContent>
            </Card>
          ) : (
            <>
              <Card className='TuningLogic'>
                <CardHeader title={<Typography variant='h3'>Tuning Logic</Typography>} />
                <CardContent>
                  <Detection alwaysShowCondition name='customization' ignoreValidation conditionDisabled />
                </CardContent>
              </Card>
              <Card className='TunedDetection'>
                <CardHeader
                  title={
                    <div className='title-bar'>
                      <Typography variant='h3'>Tuned Detection</Typography>
                      <Button variant='outlined' onClick={() => setAsNativeDialogOpen(true)}>
                        Edit as Native
                      </Button>
                    </div>
                  }
                />
                <CardContent className='column'>
                  {translationStatus === Status.pending ? (
                    <TuningPreviewPlaceholder />
                  ) : (
                    <>
                      <ApiError messages={translationError ? [translationError] : []} />
                      <DiffViewer
                        value={fprTranslation || translation}
                        grammar={selectedLanguage?.native_key}
                        original={translation}
                      />
                    </>
                  )}
                </CardContent>
              </Card>
            </>
          )}
        </div>
      </FineTuningContainer>
      <ConfirmDialog
        key='preview'
        title='Edit as Native'
        DialogProps={{
          open: asNativeDialogOpen,
          onClose: () => setAsNativeDialogOpen(false),
          maxWidth: 'md'
        }}
        ConfirmProps={{
          children: 'Confirm',
          onClick: () => {
            dispatch({
              type: 'EditAsNativeUpdate',
              languageId: selectedLanguage?.id,
              raw: fprTranslation
            });
            setAsNative(true);
            setAsNativeDialogOpen(false);
          }
        }}
      >
        <Typography variant='body1'>
          This detection will become a new, native {integration?.name} detection. The true positive rule and tuning
          logic will be merged into a single detection. Existing versions will be undeployed once tuning is complete.
        </Typography>
      </ConfirmDialog>
    </IDETranslationStateProvider>
  );
}, FunctionalPermission.Tuning);

export default AnalyticFineTuning;
