import React from 'react';

import classnames from 'classnames';

import { Token } from 'module/HighlightDetection';

export type Props = {
  token: Token.Model;
};

export default function DetectionResultField({ token }: Props): React.ReactElement {
  return (
    <>
      {token.compiled.map((c, index) => (
        <span
          key={`${token.property}-${index}--${c.value}`}
          className={classnames(
            c.outline ? 'outline' : '',
            token.satisfied ? '' : 'unsatisfied',
            token.inclusion ? '' : 'excluded'
          )}
        >
          {c.value}
        </span>
      ))}
    </>
  );
}
