import React from 'react';

import { useField } from 'formik';
import isEmpty from 'lodash/isEmpty';

import Alert from 'snap-ui/Alert';
import { ConfirmDialog } from 'snap-ui/Dialog';
import { AutoflowGrid } from 'snap-ui/Grid';
import { styled, useTheme } from 'snap-ui/util';

import { Discriminator } from 'module/Tag';
import { useThreatProfile } from 'module/ThreatProfile';

import { Status } from 'storage/Storage.type';

import { convertFitVectorsToBucketSizes } from '../../ThreatProfile.util';
import ThreatProfileStep from '../../ThreatProfileStep';
import { FIT_VECTOR_DEFAULT } from '../../ThreatProfileWizard.type';
import useThreatProfileWizard from '../../useThreatProfileWizard';
import TagBucketSizing from './TagBucketSizing';

const StyledAlert = styled(Alert)`
  margin-top: ${p => p.theme.spacing(5)};
`;
export default function PriorityThresholdManager(): JSX.Element {
  const { spacing } = useTheme();
  const { tags } = useThreatProfile();
  const noProfile = isEmpty(tags);

  return (
    <ThreatProfileStep
      title='Priority Threshold Manager'
      description={
        <>
          This page enables organizations to customize priority thresholds for vulnerabilities, ATT&CK techniques,
          malware/tools, and threat actors, aligning them with their capacity. Set custom thresholds for priority levels
          from highest to lowest and view the estimated number of items at each level. Individual threat priorities will
          continue to dynamically adjust as new threats emerge, but the threshold percentages will remain constant.
          Organizations can override system&apos;s default priority thresholds or{' '}
          <ResetAction>reset to default scoring</ResetAction> if needed.
          {noProfile && (
            <StyledAlert severity='info'>
              Your organization&apos;s threat profile is currently building. Check back later to adjust the priority
              thresholds.
            </StyledAlert>
          )}
        </>
      }
      content={
        <AutoflowGrid className='ThresholdManager-container' maxColumns={2} minWidth='365px' gap={spacing(5)}>
          <AutoflowGrid maxColumns={2} minWidth='365px' gap={spacing(5)}>
            <TagBucketSizing
              countVerbiageTemplate='{{COUNT}} total actors'
              name='fit.actor'
              title='Threat Actors'
              type={Discriminator.Actor}
            />
            <TagBucketSizing
              countVerbiageTemplate='{{COUNT}} total TTPs'
              name='fit.attack'
              title='ATT&CK TTPs'
              type={Discriminator.Attack}
            />
          </AutoflowGrid>
          <AutoflowGrid maxColumns={2} minWidth='365px' gap={spacing(5)}>
            <TagBucketSizing
              countVerbiageTemplate='{{COUNT}} total malware/tools'
              name='fit.software'
              title='Malware & Tools'
              type={Discriminator.Software}
            />
            <TagBucketSizing
              countVerbiageTemplate='{{COUNT}} total vulnerabilities'
              name='fit.vulnerability'
              title='Vulnerabilities'
              type={Discriminator.Vulnerability}
            />
          </AutoflowGrid>
        </AutoflowGrid>
      }
    />
  );
}

const StyledAnchor = styled('a')`
  cursor: pointer;
`;
function ResetAction({ children }: React.PropsWithChildren<Record<never, never>>) {
  const [, , helpers] = useField('fit');
  const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);

  const { incrementalUpdate, status } = useThreatProfileWizard();

  function handleClick() {
    setConfirmOpen(true);
  }

  function handleClose() {
    setConfirmOpen(false);
  }

  function handleConfirm() {
    helpers.setValue(convertFitVectorsToBucketSizes(FIT_VECTOR_DEFAULT));
    helpers.setTouched(false);
    incrementalUpdate({ fit: null }).then(handleClose);
  }
  return (
    <>
      <StyledAnchor onClick={handleClick}>{children}</StyledAnchor>
      <ConfirmDialog
        title='Reset Priority Thresholds'
        isPending={status === Status.pending}
        DialogProps={{
          open: confirmOpen,
          onClose: handleClose
        }}
        ConfirmProps={{
          children: 'Reset',
          onClick: handleConfirm
        }}
      >
        Are you sure you want to reset priority thresholds?
      </ConfirmDialog>
    </>
  );
}
