import React from 'react';

import { ConfirmDialog } from 'snap-ui/Dialog';

import { ErrorProps } from 'module/ApiError';

import { useAuth, usePushSnack } from 'provider';

import { Status } from 'storage';

import { EditorLanguage, LanguageExtended } from 'types/analytic';
import { Guid, Ident } from 'types/common';

import { useConfigControls } from './ConfigProvider';
import LanguageTargetFormDialog from './LanguageTargetFormDialog';
import useLanguageSettings from './useLanguageSettings';
import { pickEditorValues, transformLanguagePayload } from './util';

export type LanguageControls = {
  languages: LanguageExtended[];
  errorProps?: ErrorProps;
  selectedOrgId: Ident;
  setSelectedOrgId(id: Ident): void;
  status: Status;
  openEditor(languageId?: Ident): void;
  openEditorToClone(languageId: Ident): void;
  openDeleteConfirm(languageId: Ident): void;
  openRecompileConfirm(languageId: Ident): void;
  toggleLanguage(languageId: Ident, orgGuid: Guid): void;
  isLanguageHidden(language: LanguageExtended, orgId?: Ident): boolean;
};

const LanguageControlContext = React.createContext<LanguageControls>(undefined);
LanguageControlContext.displayName = 'LanguageControlContext';

function useLanguageControls(): LanguageControls {
  const controls = React.useContext(LanguageControlContext);

  if (!controls) throw new Error('useLanguageControls used outside LanguageControlContext');

  return controls;
}

function LanguageControlProvider({ children }: React.PropsWithChildren<Record<never, never>>): JSX.Element {
  const pushSnack = usePushSnack();
  const { defaultOrgId } = useAuth();
  const { languages, errorProps, hiddenLanguages, selectedOrgId, setSelectedOrgId, status, ...languageControls } =
    useLanguageSettings();
  const configControls = useConfigControls();

  const { setRefreshLanguagesRef } = configControls;
  React.useEffect(() => {
    setRefreshLanguagesRef(languageControls.refresh);
  }, [languageControls.refresh, setRefreshLanguagesRef]);

  const getLanguageById = React.useCallback(
    (languageId: Ident) => {
      const language = languages.find(language => language.id === languageId);
      if (!language) console.error(`language with id [${languageId}] not found`);
      return language;
    },
    [languages]
  );

  const isLanguageHidden = React.useCallback(
    (language: LanguageExtended) => {
      return hiddenLanguages.includes(language?.id);
    },
    [hiddenLanguages]
  );

  const [editorLanguage, setEditorLanguage] = React.useState<Partial<EditorLanguage>>(null);
  const openEditor = React.useCallback(
    (languageId?: Ident) => {
      if (!languageId) setEditorLanguage({});
      else setEditorLanguage(pickEditorValues(getLanguageById(languageId)));
    },
    [getLanguageById]
  );

  const openEditorToClone = React.useCallback(
    (languageId: Ident) => {
      setEditorLanguage(pickEditorValues(getLanguageById(languageId), true));
    },
    [getLanguageById]
  );

  function handleEditorClose() {
    setEditorLanguage(null);
    languageControls.resetError();
  }

  async function handleEditorSave(values: EditorLanguage) {
    const payload = transformLanguagePayload(values);
    if (values.id) {
      await languageControls.update(values.id, payload);
    } else {
      if (values._source_language_id && values._clone_configs) {
        // clone configs before creating
        const source = getLanguageById(values._source_language_id);
        await Promise.all(
          source.config_keys.map(configKey => {
            const config = configControls.getConfigByKey(configKey);
            if (!config) return Promise.reject(new Error(`No config found for key ${configKey}`));
            if (!config.raw) return Promise.reject(new Error(`Cannot clone config ${configKey}`));
            const controls = configControls.getControls(config.discriminator);
            return controls.create(config.raw, defaultOrgId);
          })
        ).then(newConfigs => {
          payload.config_keys = newConfigs.map(config => config.sigma_identifier);
        });
      }
      await languageControls.create(payload);
    }
    handleEditorClose();
  }

  const [deleteLanguage, setDeleteLanguage] = React.useState<LanguageExtended>(null);
  const openDeleteConfirm = React.useCallback(
    (languageId: Ident) => {
      setDeleteLanguage(getLanguageById(languageId));
    },
    [getLanguageById]
  );

  function handleDeleteClose() {
    setDeleteLanguage(null);
  }

  function handleDeleteConfirm() {
    languageControls.remove(deleteLanguage.id).then(handleDeleteClose);
  }

  const [recompileLanguage, setRecompileLanguage] = React.useState<LanguageExtended>(null);
  const openRecompileConfirm = React.useCallback(
    (languageId: Ident) => {
      setRecompileLanguage(getLanguageById(languageId));
    },
    [getLanguageById]
  );

  function handleRecompileClose() {
    setRecompileLanguage(null);
  }

  function handleRecompileConfirm() {
    languageControls.recompile(recompileLanguage.id).then(() => {
      pushSnack(`Recompile of ${recompileLanguage.name} started`, 'info', 'center', 'bottom', 5000);
      handleRecompileClose();
    });
  }

  const isPending = status === Status.pending;
  return (
    <LanguageControlContext.Provider
      value={{
        languages,
        errorProps,
        selectedOrgId,
        setSelectedOrgId,
        status,
        openEditor,
        openEditorToClone,
        openDeleteConfirm,
        openRecompileConfirm,
        toggleLanguage: languageControls.toggle,
        isLanguageHidden
      }}
    >
      {children}
      <LanguageTargetFormDialog
        initialTarget={editorLanguage || undefined}
        onClose={handleEditorClose}
        onSave={handleEditorSave}
        open={!!editorLanguage}
        active={isPending}
      />
      <ConfirmDialog
        title='Delete Language'
        isPending={isPending}
        DialogProps={{
          open: !!deleteLanguage,
          onClose: handleDeleteClose
        }}
        ConfirmProps={{
          children: 'Delete',
          onClick: handleDeleteConfirm
        }}
      >
        {`Are you sure you want to delete ${deleteLanguage?.name}?`}
      </ConfirmDialog>
      <ConfirmDialog
        isPending={isPending}
        title='Recompile Language'
        DialogProps={{
          open: !!recompileLanguage,
          onClose: handleRecompileClose
        }}
        ConfirmProps={{
          children: 'Recompile',
          onClick: handleRecompileConfirm
        }}
      >
        {`Are you sure you want to recompile ${recompileLanguage?.name}?`}
      </ConfirmDialog>
    </LanguageControlContext.Provider>
  );
}

export { LanguageControlProvider, useLanguageControls };
