import React from 'react';

import Path from 'constants/paths';

import { postAttackScriptSessions } from 'module/AttackScript/AttackScript.api';
import { getAttackScriptList } from 'module/AttackScript/AttackScript.service';
import { AttackScript } from 'module/AttackScript/AttackScript.type';
import { ScriptsAsyncInterface } from 'module/BAS/Launcher/useScripts';
import { useFilterRegistry } from 'module/GlobalFilter';
import { Associator } from 'module/Widgets/AssociateArtifactsModal';

import { ArtifactType, Guid, IconButtonRenderProps } from 'types/common';

export type AddAttackScriptToSessionProps = IconButtonRenderProps & {
  guid?: Guid;
  onClose: () => void;
  isOpen: boolean;
  scripts: ScriptsAsyncInterface;
};

export default function AddAttackScriptToSession({ guid, onClose, isOpen, scripts }: AddAttackScriptToSessionProps) {
  const { generateQuery } = useFilterRegistry();

  const fetchAllScripts = React.useCallback(
    (searchTerm?: string) => {
      const query = searchTerm ? generateQuery(ArtifactType.AttackScript, { query: searchTerm }) : undefined;
      return getAttackScriptList(query);
    },
    [generateQuery]
  );

  const associate = React.useCallback(
    (script: AttackScript) => {
      return postAttackScriptSessions(script.guid, 'add', [guid]).then(() =>
        scripts.setScripts([...scripts.data, script])
      );
    },
    [guid, scripts]
  );

  const disassociate = React.useCallback(
    (script: AttackScript) => {
      return postAttackScriptSessions(script.guid, 'delete', [guid]).then(() =>
        scripts.setScripts(scripts.data.filter(s => s.guid !== script.guid))
      );
    },
    [guid, scripts]
  );

  return (
    <Associator
      associate={associate}
      associatedRecords={scripts.data}
      associatedRecordsStatus={scripts.status}
      disassociate={disassociate}
      fetchAllOptions={fetchAllScripts}
      isOpen={isOpen}
      onClose={onClose}
      pathRoot={Path.AttackScript}
      types={{ associated: ArtifactType.AttackScript, current: ArtifactType.Session }}
    />
  );
}
