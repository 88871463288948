import React from 'react';

import useQueryString from 'hooks/useQueryString';

import { AttackRecordType, isAttackRecordType } from '../type';

export default function useRecordType(): [AttackRecordType, (value: AttackRecordType) => void] {
  const { getByKey, update } = useQueryString(true);

  const recordTypeValue = getByKey('type');
  const recordType: AttackRecordType = isAttackRecordType(recordTypeValue) ? recordTypeValue : 'techniques';

  const setRecordType = React.useCallback(
    (value: string) => {
      update({ type: isAttackRecordType(value) ? value : undefined });
    },
    [update]
  );

  return [recordType, setRecordType];
}
