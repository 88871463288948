import React from 'react';

import { faChevronCircleLeft } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';

import Box from 'snap-ui/Box';
import { IconButton } from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import { List, ListItem, ListItemText } from 'snap-ui/List';
import Placeholder from 'snap-ui/Placeholder';
import Tooltip from 'snap-ui/Tooltip';

import Path from 'constants/paths';

import { Status } from 'storage';

import { Guid } from 'types/common';

import { VersionList } from '../Analytic.style';
import { AnalyticVersion } from '../Analytic.type';
import { useAnalyticCatalog } from '../core/AnalyticProvider';
import { AnalyticVersionListItems } from './AnalyticVersionListItems';

type AnalyticVersionListProps = {
  open: boolean;
  onClose?(): void;
  guid: Guid;
  parentGuid?: Guid;
  versions: AnalyticVersion[];
  versionStatus: Status;
};

export default function AnalyticVersionList(props: AnalyticVersionListProps): JSX.Element {
  const [{ analytic }] = useAnalyticCatalog();
  const { open, onClose, guid, parentGuid = null, versions, versionStatus } = props;
  const showPlaceholder = versionStatus === Status.pending;

  return (
    <VersionList
      anchor='left'
      className='VersionList'
      open={open}
      onClose={onClose}
      PaperProps={{ className: 'VersionList-surface' }}
      variant='persistent'
    >
      <Box className='VersionList-close'>
        {onClose ? (
          <Tooltip arrow title='Close version list'>
            <IconButton aria-label='Close version list' onClick={onClose}>
              <Icon icon={faChevronCircleLeft} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip arrow title='Back to current version'>
            <IconButton aria-label='Back to current version' component={Link} to={`${Path.Detection}/${analytic.guid}`}>
              <Icon icon={faChevronCircleLeft} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <List dense className='VersionList-content'>
        {showPlaceholder ? (
          [1, 2, 3, 4, 5].map(i => (
            <ListItem key={i}>
              <ListItemText primary={<Placeholder variant='text' />} />
            </ListItem>
          ))
        ) : (
          <>
            <AnalyticVersionListItems
              id='previous-list-subheader'
              guid={analytic.guid}
              title='PREVIOUS VERSIONS'
              versions={versions.filter(v => v.guid === guid)}
            />
            <AnalyticVersionListItems
              id='parent-list-subheader'
              guid={analytic.guid}
              title='PARENT VERSIONS'
              versions={versions.filter(v => v.guid === parentGuid)}
            />
          </>
        )}
      </List>
    </VersionList>
  );
}
