import React from 'react';

import Path from 'constants/paths';

import { ArtifactType, Visibility } from 'types/common';
import { Coverage } from 'types/dashboard';

import { getLocation } from 'utilities/RouteGenUtils';

import Board from '.';
import FeaturedPanel from '../FeaturedPanel';
import { FeaturedQuery } from '../FeaturedPanel/Feature';
import MatrixPanel from '../MatrixPanel';
import NumberPanel from '../NumberPanel';

type Props = {
  total: number;
  technique: Coverage;
  subtechnique: Coverage;
  featured: FeaturedQuery[];
  loadingStats: boolean;
  loadingFeatured: boolean;
};

export default function SessionBoard({
  featured,
  loadingStats,
  loadingFeatured,
  subtechnique,
  technique,
  total
}: Props) {
  return (
    <Board>
      <NumberPanel
        className='red'
        linkTo={getLocation({
          pathname: Path.Feed,
          search: {
            topic: ArtifactType.Session,
            visibility: [Visibility[Visibility.Published]]
          } as any
        })}
        loading={loadingStats}
        value={total}
        title='Threats'
      />
      <MatrixPanel
        className='red'
        technique={technique}
        subtechnique={subtechnique}
        linkTo={`${Path.Matrix}?topic=${ArtifactType.Analytic}`}
        loading={loadingStats}
        type={ArtifactType.Session}
      />
      <FeaturedPanel
        className='red'
        title='Featured Threats'
        type={ArtifactType.Session}
        featured={featured}
        loading={loadingFeatured}
      />
    </Board>
  );
}
