import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import MuiFab, { FabProps as MuiFabProps } from '@mui/material/Fab';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { Action, Base } from 'snap-ui/type';

import { StrictReactNode } from 'types/core';

import Icon, { CannedIconProps } from './Icon';
import Tooltip, { TooltipProps } from './Tooltip';
import { styled } from './util';

export { ButtonBase } from '@mui/material';
export type { FabProps, ButtonBaseProps } from '@mui/material';
export { ActionIconButton, Fab, IconButton, RouterButton };

export type ButtonProps = Base &
  Action & {
    children?: React.ReactNode;
    variant?: 'contained' | 'outlined' | 'text';
    type?: 'submit' | 'button';
    color?: MuiButtonProps['color'];
    size?: MuiButtonProps['size'];
    fullWidth?: boolean;
    ariaLabel?: string;
    startIcon?: StrictReactNode;
    endIcon?: StrictReactNode;
    href?: string;
    target?: string;
    rel?: string;
    component?: any;
    /** for icons with styles that aren't compatible with `startIcon`, you can place the icon in the button
     * content and use `hasOwnIcon`, which will adjust the spacing between text and icon */
    hasOwnIcon?: boolean;
  };

const Button = styled(
  React.forwardRef<HTMLButtonElement, ButtonProps>(function SUIButton(
    { children, ariaLabel, variant = 'contained', ...others },
    ref
  ) {
    return (
      <MuiButton variant={variant} aria-label={ariaLabel} {...others} ref={ref}>
        {children}
      </MuiButton>
    );
  }),
  { shouldForwardProp: p => p !== 'hasOwnIcon' }
)`
  ${p => (p.hasOwnIcon ? 'gap: 8px;' : '')}
`;

export default Button;

export type ActionIconButtonProps = IconButtonProps & {
  'aria-label': string;
  disabled?: boolean;
  icon: IconProp;
  IconProps?: CannedIconProps;
  loading?: boolean;
  onClick?: IconButtonProps['onClick'];
  role?: string;
  size?: 'small' | 'medium' | 'large';
  to?: string | object;
  component?: any;
  href?: string;
  rel?: string;
  target?: string;
};

const ActionIconButton = React.forwardRef<HTMLButtonElement, ActionIconButtonProps>(function ActionIconButton(
  { disabled, icon, loading, size = 'small', IconProps, ...props },
  ref
) {
  return (
    <IconButton sx={{ ':hover': { color: 'unset' } }} size={size} ref={ref} disabled={disabled || loading} {...props}>
      {loading ? <Icon.SpinnerProgress /> : <Icon icon={icon} {...IconProps} />}
    </IconButton>
  );
});

type RouterButtonProps = Omit<ButtonProps, 'href' | 'type'> & {
  to: string | object;
  className?: string;
};

const RouterButton = React.forwardRef<HTMLAnchorElement, RouterButtonProps>(function RouterButton(props, ref) {
  return <MuiButton component={Link} {...props} ref={ref}></MuiButton>;
});

type FabProps = MuiFabProps & {
  'aria-label': string;
  icon: IconProp;
};

const Fab = React.forwardRef<HTMLButtonElement, FabProps>(function Fab({ icon, ...props }, ref) {
  return (
    <MuiFab {...props} ref={ref}>
      <Icon icon={icon} />
    </MuiFab>
  );
});

export const LinkButton = styled(
  React.forwardRef<HTMLButtonElement, ButtonProps>(function LinkButton(props, ref) {
    return <Button ref={ref} {...props} variant='text' />;
  })
)`
  padding: 0;
  vertical-align: inherit;

  &:hover {
    background: none;
  }
`;

const DivButtonContainer = styled('div')`
  // Expected to be on a .Card, which has 20px of padding
  .Card & {
    margin: 10px -20px -20px -20px;
  }

  .DivButton {
    flex-grow: 1;
    padding: ${p => p.theme.spacing(5)};
    gap: ${p => p.theme.spacing(2)};
  }

  .Tooltip-wrapper {
    display: flex;
    flex-grow: 1;
  }

  display: flex;
  border: 2px solid ${p => p.theme.palette.primary.main};
  border-radius: 4px;
  background: ${p => p.theme.palette.darkPurple.main};

  &.disabled {
    color: ${p => p.theme.palette.text.disabled};
    border: 2px solid ${p => p.theme.palette.secondary.dark};
    background: ${p => p.theme.palette.secondary.main};
  }

  &:not(.disabled):hover {
    background: ${p => p.theme.palette.darkPurple.light};
  }
`;

type DivButtonProps = ButtonBaseProps & {
  className?: string;
  disabled?: boolean;
  TooltipProps?: Omit<TooltipProps, 'arrow' | 'children' | 'wrap'>;
};

export function DivButton({ className, TooltipProps, ...ButtonProps }: DivButtonProps) {
  return (
    <DivButtonContainer className={classnames('DivButtonContainer', className, { disabled: ButtonProps.disabled })}>
      <Tooltip arrow {...TooltipProps} wrap>
        <ButtonBase {...ButtonProps} className='DivButton' />
      </Tooltip>
    </DivButtonContainer>
  );
}
