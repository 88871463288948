import React from 'react';

import Path from 'constants/paths';

import May from 'module/May';

import { useAuth } from 'provider';

import { FunctionalPermission } from 'types/auth';
import { ArtifactType } from 'types/common';

import { formatQueryString } from 'utilities/SearchParam';

import ListItemLink from './ListItemLink';

export default function ListItemsReport({ onClose }: { onClose(): void }) {
  const { isTraining } = useAuth();

  const viewPath =
    Path.Matrix +
    formatQueryString({
      topic: ArtifactType.Analytic,
      page: undefined
    });

  return (
    <>
      {!isTraining && (
        <>
          <ListItemLink
            aria-label='Go to view attack matrix page'
            id='MenuItemMatrixView'
            onClick={onClose}
            title='ATT&CK Matrix'
            to={viewPath}
            testId='menu-matrix'
          />
          <May I={FunctionalPermission.MitreCoverageDashboard}>
            <ListItemLink
              aria-label='MITRE ATT&CK Coverage Dashboard'
              id='MenuItemMitreCoverageDashboardView'
              onClick={onClose}
              title='ATT&CK Coverage Dashboard'
              to={Path.MitreCoverageDashboard}
              testId='menu-mitre-coverage-dashboard'
            />
          </May>
          <May I={FunctionalPermission.SecurityProfileDashboard}>
            <ListItemLink
              aria-label='Threat Profile Coverage Dashboard'
              id='MenuItemProfileCoverageDashboardView'
              onClick={onClose}
              title='Threat Profile Coverage Dashboard'
              to={Path.ProfileCoverageDashboard}
              testId='menu-profile-coverage-dashboard'
            />
          </May>
          <ListItemLink
            aria-label='Go to view detection dashboard'
            id='MenuItemDetectionDashboardView'
            onClick={onClose}
            title='Detection Dashboard'
            to={Path.DetectionDashboard}
            testId='menu-detection-dashboard'
          />
          <May I={FunctionalPermission.DetectionHealthDashboard}>
            <ListItemLink
              aria-label='Go to view detection health dashboard'
              id='MenuItemDetectionHealthView'
              onClick={onClose}
              title='Detection Health'
              to={Path.DetectionHealth}
              testId='menu-detection-health'
            />
          </May>
          <ListItemLink
            aria-label='Go to view SOC dashboard'
            id='MenuItemSOCDashboardView'
            onClick={onClose}
            title='SOC Manager Dashboard'
            to={Path.SocDashboard}
            testId='menu-soc-dashboard'
          />
          <ListItemLink
            aria-label='Go to view NIST dashboard'
            id='MenuItemNistDashboardView'
            onClick={onClose}
            title='NIST 800-53 Dashboard'
            to={Path.NistDashboard}
            testId='menu-nist-dashboard'
          />
          <ListItemLink
            aria-label='Go to view User Activity dashboard'
            id='MenuItemUserActivityDashboardView'
            onClick={onClose}
            title='User Activity Dashboard'
            to={Path.UserActivityDashboard}
            testId='menu-user-activity-dashboard'
          />
          <ListItemLink
            aria-label='Go to view home dashboard'
            id='MenuItemDashboardView'
            onClick={onClose}
            title='Content Dashboard'
            to={Path.ContentDashboard}
            testId='menu-home-dashboard'
          />
        </>
      )}
      {isTraining && (
        <>
          <ListItemLink
            aria-label='Go to view leaderboard'
            id='MenuItemLeaderboardView'
            onClick={onClose}
            title='Leaderboard'
            to={Path.Leaderboard}
            testId='menu-home-leaderboard'
          />
          <ListItemLink
            aria-label='Go to view scoreboard'
            id='MenuItemScoreboardView'
            onClick={onClose}
            title='Scoreboard'
            to={Path.Scoreboard}
            testId='menu-home-scoreboard'
          />
        </>
      )}
    </>
  );
}
