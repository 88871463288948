import React from 'react';

import { MultiProgressBarProps } from 'snap-ui/LinearProgress';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { NIST_FAMILY_MAPPINGS } from './NistDashboard.const';
import { nistIs } from './NistDashboard.helper';
import { NistCoverageColors, Progress } from './NistDashboard.style';
import { NistArtifact, NistCoverage, NistFamilyScore } from './NistDashboard.type';

const Container = styled('div')`
  margin: ${p => p.theme.spacing(7, 0)};

  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: ${p => p.theme.spacing(7)};
  align-items: stretch;
`;

type NistFamilyCoverageProp = {
  nist: NistArtifact[];
};

function NistFamilyCoverage({ nist }: NistFamilyCoverageProp) {
  const nistFamilyScore = React.useMemo(
    () =>
      nist.reduce((allNistFamily, nistControlId) => {
        if (!allNistFamily[nistControlId.family]) {
          allNistFamily[nistControlId.family] = {
            name: nistControlId.family,
            deployed: nistControlId.detections_deployed,
            covered: Number(nistIs(nistControlId.detections_deployed, NistCoverage.Covered)),
            improving: Number(nistIs(nistControlId.detections_deployed, NistCoverage.Improving)),
            risk: Number(nistIs(nistControlId.detections_deployed, NistCoverage.Risk)),
            familyTotal: 1
          };
        } else {
          allNistFamily[nistControlId.family] = {
            ...allNistFamily[nistControlId.family],
            deployed: allNistFamily[nistControlId.family].deployed + nistControlId.detections_deployed,
            covered: nistIs(nistControlId.detections_deployed, NistCoverage.Covered)
              ? allNistFamily[nistControlId.family].covered + 1
              : allNistFamily[nistControlId.family].covered,
            improving: nistIs(nistControlId.detections_deployed, NistCoverage.Improving)
              ? allNistFamily[nistControlId.family].improving + 1
              : allNistFamily[nistControlId.family].improving,
            risk: nistIs(nistControlId.detections_deployed, NistCoverage.Risk)
              ? allNistFamily[nistControlId.family].risk + 1
              : allNistFamily[nistControlId.family].risk,
            familyTotal: allNistFamily[nistControlId.family].familyTotal + 1
          };
        }
        return allNistFamily;
      }, {}),
    [nist]
  );

  const nistFamilyCollection = Object.values(nistFamilyScore) as NistFamilyScore[];
  return (
    <Container>
      {nistFamilyCollection
        .map(family => {
          return (
            <React.Fragment key={family.name}>
              <Tooltip title={NIST_FAMILY_MAPPINGS[family.name]} arrow placement='top'>
                <span>{family.name}</span>
              </Tooltip>
              <NistFamilyCollectionCoverage coverage={family} total={family.familyTotal} />
            </React.Fragment>
          );
        })
        .sort((a, b) => a.key.toLocaleString().localeCompare(b.key.toLocaleString()))}
    </Container>
  );
}

function NistFamilyCollectionCoverage({ coverage, total }: { coverage: NistFamilyScore; total: number }): JSX.Element {
  const bars: MultiProgressBarProps[] = [
    {
      color: NistCoverageColors.Covered,
      children: <>{coverage.covered}</>,
      label: `${coverage.covered} ${NistCoverage.Covered} `,
      percent: Number(((coverage.covered / total) * 100).toFixed(2)),
      className: 'coverage-value-text'
    },
    {
      color: NistCoverageColors.Improving,
      children: <>{coverage.improving}</>,
      label: `${coverage.improving} ${NistCoverage.Improving} `,
      percent: Number(((coverage.improving / total) * 100).toFixed(2))
    },
    {
      color: NistCoverageColors.Risk,
      children: <>{coverage.risk}</>,
      label: `${coverage.risk} ${NistCoverage.Risk} `,
      percent: Number(((coverage.risk / total) * 100).toFixed(2))
    }
  ].filter(bar => !!bar.percent);
  return <Progress bars={bars} />;
}

export default NistFamilyCoverage;
