import React from 'react';

import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import PanelPaper from './PanelPaper';

type Props = {
  title?: React.ReactNode;
  centerText?: React.ReactNode;
  bottomText?: React.ReactNode;
  children?: React.ReactNode;
  panelTooltip?: string;
  titleTooltip?: string;
  to?: string | { pathname: string; search?: string; state?: any };
};

const CenterText = styled('div')`
  font-size: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  line-height: 1;
`;

const BottomText = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  line-height: 1;
`;

function ChartPanel({ title, centerText, children, bottomText, to, panelTooltip, titleTooltip }: Props) {
  return (
    <PanelPaper to={to} tooltip={panelTooltip}>
      {title && (
        <Typography variant='h2'>
          {title}
          {titleTooltip && (
            <Tooltip title={titleTooltip} placement='bottom' arrow wrap>
              <Icon.Info size='xs' width={48} />
            </Tooltip>
          )}
        </Typography>
      )}
      {centerText && <CenterText>{centerText}</CenterText>}
      {children}
      {bottomText && <BottomText>{bottomText}</BottomText>}
    </PanelPaper>
  );
}

export default ChartPanel;
