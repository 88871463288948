import { theme } from 'snap-ui/theme';

const TooltipStyles = {
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: `${theme.palette.background.default}`,
  padding: theme.spacing(2),
  fontSize: '1rem'
};

const ItemStyles = {
  color: theme.palette.common.white
};

export const TooltipDefaults = {
  separator: ': ',
  contentStyle: TooltipStyles,
  itemStyle: ItemStyles
};
