import React, { useRef, useState } from 'react';

import { usePushSnack } from 'provider';

import { useAsync } from 'storage';

import { getDetermineOutcomesStatus } from '../BAS.api';
import { DetermineOutcomesStatus, OutcomesStatus } from '../BAS.type';

function useDetermineOutcomesStatus({ campaign_id, refreshPage }: { campaign_id: string; refreshPage(): void }) {
  const { data, status, task, setData } = useAsync<DetermineOutcomesStatus>();
  const pushSnack = usePushSnack();
  const [wasInProgress, setWasInProgress] = useState(false);
  const timer = useRef(null);
  const wasInProgressRef = useRef(wasInProgress);
  wasInProgressRef.current = wasInProgress;

  const cycle = (time: number) => {
    timer.current = setTimeout(async () => {
      try {
        var response = await task(getDetermineOutcomesStatus(campaign_id));
      } catch (e) {
        pushSnack('Gah! Maybe we dug too deep: ' + e?.message, 'error', 'center', 'bottom');
        return;
      }
      setData(response);

      if (!response) {
        return;
      }

      switch (response?.status) {
        case OutcomesStatus.InProgress:
        case OutcomesStatus.Pending:
          setWasInProgress(true);
          cycle(5000);
          break;
        case OutcomesStatus.Complete:
          if (wasInProgressRef.current) {
            pushSnack('Determine Outcomes complete.', 'success', 'center', 'bottom');
            refreshPage();
          }
          setWasInProgress(false);
          break;
        default:
          cycle(5000);
          break;
      }
    }, time);
  };

  React.useEffect(() => {
    if (timer.current === null && campaign_id) {
      cycle(0);
    }

    return () => {
      clearTimeout(timer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  return {
    data,
    status,
    setData,
    cycle
  };
}

export default useDetermineOutcomesStatus;
