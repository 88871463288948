import React from 'react';

import Button, { ButtonProps } from 'snap-ui/Button';

import { ImportForm } from './Import.type';
import { DetectionImporter as DetectionImporterModal } from './Importer';

type Button = Omit<ButtonProps, 'onClick'>;

type ImporterInterfaceProps = {
  children: React.ReactNode;
};

export interface ImporterInterface {
  isOpen: boolean;
  setIsOpen(open: boolean): void;
}

const ImporterContext = React.createContext<ImporterInterface>(null);

export function ImporterInterface(props: ImporterInterfaceProps) {
  const { children } = props;
  const [isOpen, setIsOpen] = React.useState(false);

  return <ImporterContext.Provider value={{ isOpen, setIsOpen }}>{children}</ImporterContext.Provider>;
}

export function useDetectionImporter() {
  const context = React.useContext(ImporterContext);
  if (!context) {
    throw new Error('useDetectionImporter must be used within an ImporterInterface');
  }
  return context;
}

export function DetectionImporterButton(props: Button): JSX.Element {
  const { setIsOpen } = useDetectionImporter();

  return (
    <Button onClick={() => setIsOpen(true)} {...props}>
      Import Detections (CSV)
    </Button>
  );
}

export function DetectionImporterDialog({
  integration_id,
  organization_id
}: Pick<ImportForm, 'organization_id' | 'integration_id'>): JSX.Element {
  const { isOpen, setIsOpen } = useDetectionImporter();

  return isOpen ? (
    <DetectionImporterModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      organization_id={organization_id}
      integration_id={integration_id}
    />
  ) : null;
}
