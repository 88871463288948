import React from 'react';

import Typography from 'snap-ui/Typography';

import { BASCampaign } from 'module/BAS/BAS.type';

import { CampaignReportSectionPlaceholder } from './CampaignReport.style';
import { ConfidencePanel, SeverityPanel, ValidationsPanel } from './Panels';

type DeployedDetectionsChartsProps = {
  campaign: BASCampaign;
  isLoading: boolean;
};

function DeployedDetectionsCharts({ campaign, isLoading }: DeployedDetectionsChartsProps) {
  return isLoading ? (
    <CampaignReportSectionPlaceholder />
  ) : (
    <div className='CampaignReport-section'>
      <Typography variant='h2'>Deployed Detections</Typography>
      <div className='CampaignReport-charts'>
        <ValidationsPanel campaign={campaign} />
        <ConfidencePanel campaign={campaign} />
        <SeverityPanel campaign={campaign} />
      </div>
    </div>
  );
}

export default DeployedDetectionsCharts;
