import Drawer from 'snap-ui/Drawer';
import { styled } from 'snap-ui/util';

import { NAVIGATION_HEADER_HEIGHT } from 'module/Scaffold/Scaffold.const';

const CLOSED_WIDTH = '64px';
const OPEN_WIDTH = '600px';

export const Sidebar = styled(Drawer)`
  min-height: 100%;

  .Sidebar-surface {
    border: unset;
    background-color: ${p => p.theme.palette.background.default};
    position: static;
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(4)};
    padding: ${p => p.theme.spacing(0, 4)};
    max-width: 1200px;
    margin: 0 auto;
  }

  .sidebar-controls-container {
    display: flex;
    justify-content: flex-end;
  }

  .Sidebar-close,
  .Sidebar-expand,
  .Sidebar-open {
    text-align: right;
    padding-top: ${p => p.theme.spacing(4)};
  }

  .input-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${p => p.theme.spacing(3)};
  }

  .query-container {
    .SyntaxEditor {
      height: 300px;
    }
  }

  .query-actions-container {
    display: flex;
    justify-content: space-between;

    .buttons {
      display: flex;
      gap: ${p => p.theme.spacing(3)};
    }
  }

  .query-history-container {
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(3)};
  }
`;

export const Container = styled('div')`
  padding: ${p => p.theme.spacing(7)};
  height: calc(100% - ${NAVIGATION_HEADER_HEIGHT}px);
  grid-template-columns: 1fr 0px;
  display: grid;
  flex: 1;

  &.has-sidebar {
    padding: unset;
    grid-template-columns: ${OPEN_WIDTH} 1fr;
    transition: 300ms;

    .input-container {
      grid-template-columns: 1fr;
      grid-gap: unset;
      & > :not(.grid-placeholder) {
        margin-top: ${p => p.theme.spacing(3)};
      }
    }
  }

  &.sidebar-closed {
    grid-template-columns: ${CLOSED_WIDTH} 1fr;
    transition: 300ms;
  }

  &:not(.sidebar-closed),
  &:not(.drawer) {
    transition: grid-template-columns 300ms;
  }
`;
