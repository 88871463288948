import Fingerprint from './Fingerprint';

export enum Widget {
  CurationFeed = 'collection feed widget',
  TranslateAnalytic = 'translate analytic widget',
  NativeAnalyticCode = 'native analytic code widget',
  HostSelector = 'host selector widget',
  SearchBar = 'search bar widget',
  FeedExport = 'feed export widget',
  Omnisearch = 'omnisearch widget',
  IntegrationLink = 'integration link',
  DetectionBuilder = 'detection builder',
  Modal = 'modal',
  MetadataInline = 'metadata inline edit widget',
  LandingRecommender = 'landing recommender widget',
  SessionExport = 'threat file export'
}

export enum NotableEvent {
  Error = 'error',
  Failure = 'failure',
  Feedback = 'user feedback',
  ExternalLink = 'link clicked',
  NotFound = 'not found',
  PageLoad = 'page loaded',
  Success = 'success'
}

export enum CommonEvent {
  Click = 'click',
  Change = 'change',
  Copy = 'copy',
  Export = 'export',
  Open = 'open',
  Search = 'search',
  Save = 'save',
  Select = 'select',
  Submit = 'submit',
  Upload = 'upload'
}

export type PropertyBatch = { [index: string]: string | number | boolean };
export type IncrementBatch = { [index: string]: number };

export interface EngagementInterface {
  /**
   * For identifying a user via a unique string. Helps track cross devices
   */
  identify(value: string): void;

  /**
   * For resetting super properties when a user logs out.
   */
  unidentify(): void;

  track(event: Fingerprint): void;

  /**
   * Track persistent property that describe a user - things like name, email address, artifacts created, etc...
   */
  trackPerson(key: string, value: string | number): void;

  /**
   * Track persistent sets of properties that describe a user - things like name, email address, artifacts created, etc...
   */
  trackPersonBatch(batch: PropertyBatch): void;

  /**
   * Set property on a user record, only if they do not yet exist. This will not overwrite previous people property values
   */
  trackPersonOnce(key: string, value: string | number): void;

  /**
   * Set properties on a user record, only if they do not yet exist. This will not overwrite previous people property values
   */
  trackPersonOnceBatch(batch: PropertyBatch): void;

  /**
   * Increment/decrement numeric person's property.
   */
  trackPersonIncrement(key: string, value: number): void;

  /**
   * Increment/decrement numeric person's properties.
   */
  trackPersonIncrement(key: string, value: number): void;

  /**
   * Assign a preferred organization to a user
   */
  assignPreferredOrganization(value: string): void;

  /**
   * Assign one or more organizations to a user
   */
  assignOrganizations(value: string | string[]): void;

  /**
   * Register a set of super properties, which are included with all events. This will overwrite previous super property values.
   */
  register(batch: PropertyBatch): void;

  _projectToken: string;

  _init(token: string): void;
}
