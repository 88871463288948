import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { useLocation } from 'react-router-dom';

import {
  DataGrid,
  GRID_AGGREGATION_FUNCTIONS,
  GridColDef,
  GridFilterModel,
  GridSortModel,
  GridValueGetterParams,
  gridStringOrNumberComparator,
  useGridApiRef,
  useKeepGroupedColumnsHidden
} from 'snap-ui/DataGrid';
import { getRowForSort } from 'snap-ui/DataGrid/DatGrid.helper';
import Paper from 'snap-ui/Paper';

import Path from 'constants/paths';

import { JobOverview, JobType } from 'module/Job';
import { convertSortModelToQueryString, getGridColumnsTask, getRowId } from 'module/Job/Job.util';
import { JobGridTreeDataGroupingCell, TaskTableToolbar } from 'module/Job/Job.widgets';
import { useTaskReducer } from 'module/Job/Task';
import {
  aggregateCreated,
  aggregateCreatedBy,
  aggregateIntegration,
  aggregateOrg,
  aggregateProgress,
  aggregateStatus,
  aggregateTimeRemaining,
  aggregateType,
  created_by,
  creation,
  detections,
  hits,
  integrationNames,
  jobGuid,
  jobName,
  jobsByGroup,
  modified,
  organization,
  progress,
  sumUp,
  taskActions,
  taskStatus,
  timeRemaining
} from 'module/Job/Task/Column';
import useJobOverview from 'module/Job/useJobOverview';
import { useMayI } from 'module/May';
import EmptyState from 'module/Widgets/EmptyState';
import TablePagination from 'module/Widgets/TablePagination';

import { useAuth, useManagedOrganizations, useUserConfig } from 'provider';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';

import { getQueryParam } from 'utilities/SearchParam';

import { HuntTableContainer } from '../Hunt.style';

const COLUMN_MODEL_HUNT_LIST_TABLE = 'huntJobOverview';
const DEFAULT_JOB_TYPE = [JobType.Hunt, JobType.IOC];

export default function HuntTable(): JSX.Element {
  const { search } = useLocation();
  const scheduledHuntId = getQueryParam(search, 'scheduledHuntId');
  const { columnModel, setColumnModel } = useUserConfig();
  const { user } = useAuth();
  const isDetectionFeaturesUser = useMayI(FunctionalPermission.DetectionHuntFeatures);

  const { organizations } = useManagedOrganizations();

  const [state, dispatch] = useTaskReducer(JobType.Hunt);
  const { orgFilter, nameFilter, sortModel, pageParams } = state;

  const filters = React.useMemo(
    () => ({
      org_id: orgFilter,
      name: nameFilter,
      sort: convertSortModelToQueryString(sortModel)
    }),
    [nameFilter, orgFilter, sortModel]
  );

  const {
    items: jobGroupsOverview,
    total,
    status: jobsGroupStatus,
    deleteJobs,
    pause,
    resume,
    cancel,
    taskStatus: actionTaskStatus
  } = useJobOverview(DEFAULT_JOB_TYPE, pageParams, filters);

  const pageTotal = Math.ceil(total / pageParams?.size) || 0;

  const hunts = scheduledHuntId
    ? jobGroupsOverview?.filter(hunt => hunt?.schedule_id?.toString() === scheduledHuntId) || []
    : jobGroupsOverview || [];

  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
    quickFilterValues: []
  });
  const apiRef = useGridApiRef();

  const handleSetColumn = (value: Record<string, boolean>) =>
    setColumnModel({ ...columnModel, [COLUMN_MODEL_HUNT_LIST_TABLE]: value });

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['jobsByGroup']
      },
      filter: {
        filterModel: {
          items: []
        }
      },
      sorting: {
        sortModel
      },
      aggregation: {
        model: {
          created_by: 'aggregateCreatedBy',
          type: 'aggregateType',
          integration_name: 'aggregateIntegration',
          job_status: 'aggregateStatus',
          progress_percent: 'aggregateProgress',
          detections_dispatched: 'sumUp',
          total_hits: 'sumUp',
          estimated_remaining_duration: 'aggregateTimeRemaining',
          creation: 'aggregateCreated',
          modified: 'aggregateCreated',
          organization_id: 'aggregateOrg'
        }
      }
    }
  });

  function handleChangePage(newPage: number): void {
    dispatch({ type: 'SET_PAGE', payload: newPage });
  }

  function handleSortModelChange(newModel: GridSortModel) {
    dispatch({ type: 'SET_SORT_MODEL', payload: newModel });
  }

  function onOrgChange(org: number) {
    dispatch({ type: 'SET_ORG_FILTER', payload: org });
  }

  function onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch({ type: 'SET_NAME_FILTER', payload: event.target.value });
  }

  return (
    <Paper>
      <HuntTableContainer>
        <DataGrid
          apiRef={apiRef}
          loading={
            jobsGroupStatus === Status.pending ||
            jobsGroupStatus === Status.rejected ||
            actionTaskStatus === Status.pending
          }
          columns={[
            jobsByGroup,
            jobGuid(user.superuser),
            created_by,
            integrationNames,
            taskStatus(apiRef),
            progress,
            detections,
            hits,
            timeRemaining,
            creation,
            modified,
            organization(organizations),
            taskActions({
              isDetectionFeaturesUser,
              deleteJobs,
              pause,
              resume,
              cancel,
              apiRef
            }),
            jobName
          ]}
          rows={hunts}
          groupingColDef={{
            headerName: 'Name',
            flex: 1,
            minWidth: 300,
            sortingOrder: ['asc', 'desc'],
            sortComparator: (v1, v2, param1, param2) => gridStringOrNumberComparator(v1, v2, param1, param2),
            valueGetter(params: GridValueGetterParams) {
              const row = getRowForSort<JobOverview>(apiRef.current, params);
              return row?.name;
            },
            renderCell: params => (
              <JobGridTreeDataGroupingCell {...params} idPath='analytic_job_group_guid' linkPath={Path.Hunt} />
            )
          }}
          initialState={initialState}
          getAggregationPosition={groupNode => (groupNode == null ? null : 'inline')}
          getRowId={getRowId}
          columnVisibilityModel={{
            creation: false,
            estimated_remaining_duration: false,
            progress_percent: false,
            analytic_job_guid: user.superuser ? true : false,
            organization_id: user.superuser ? true : false,
            ...(isEmpty(columnModel[COLUMN_MODEL_HUNT_LIST_TABLE])
              ? undefined
              : columnModel[COLUMN_MODEL_HUNT_LIST_TABLE]),
            jobsByGroup: false,
            name: false
          }}
          onColumnVisibilityModelChange={handleSetColumn}
          filterModel={filterModel}
          aggregationFunctions={{
            ...GRID_AGGREGATION_FUNCTIONS,
            aggregateType,
            aggregateIntegration,
            aggregateCreatedBy,
            aggregateStatus,
            aggregateProgress,
            aggregateTimeRemaining,
            aggregateCreated,
            sumUp,
            aggregateOrg
          }}
          onFilterModelChange={newModel => setFilterModel(newModel)}
          slots={{
            toolbar: TaskTableToolbar,
            footer: () => (
              <TablePagination changePage={handleChangePage} numPages={pageTotal} page={pageParams.page} zeroIndex />
            ),
            noRowsOverlay: EmptyState
          }}
          slotProps={{
            toolbar: { orgFilter, onSearchChange, onOrgChange, includeExport: false },
            noRowsOverlay: {
              title: 'No Results',
              children: 'There are no hunt results available'
            },
            columnsPanel: {
              getTogglableColumns: (columns: GridColDef[]) => getGridColumnsTask(columns, user.superuser)
            }
          }}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
        />
      </HuntTableContainer>
    </Paper>
  );
}
