import React, { ReactElement } from 'react';

import {
  faArrowsCross,
  faBan,
  faClipboard,
  faCloudArrowUp,
  faKeyboard,
  faSave
} from '@fortawesome/pro-regular-svg-icons';
import { IconButton } from '@mui/material';
import classNames from 'classnames';
import startCase from 'lodash/startCase';
import { useHistory } from 'react-router-dom';

import { DisplayDialog as ClipboardDialog } from 'snap-ui/Dialog';
import Icon from 'snap-ui/Icon';
import Menu from 'snap-ui/Menu';
import MenuItem from 'snap-ui/MenuItem';
import TextField from 'snap-ui/TextField';
import Tooltip from 'snap-ui/Tooltip';
import { styled, useTheme } from 'snap-ui/util';

import Path from 'constants/paths';

import { Engage, Fingerprint } from 'lib/Engagement';

import UploadDialog from 'module/Widgets/UploadDialog';

import { useCommissionedMachine } from 'provider';

import { Catalog, Scheme, parseStorageItem, stowItem } from 'storage';

import { UploadResult } from 'types/common';

import { NOOP } from 'utilities/FunctionUtils';

import { CommissionedHost } from '../Session.type';
import useMachine from '../useMachine';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 50px;

  button {
    height: 40px;
    width: 40px;
  }
`;

type Props = {
  className?: string;
  host: CommissionedHost;
  setActiveHost(host: CommissionedHost): void;
  onUpload: (
    instanceId: string,
    files: File[],
    onUploadProgress?: (file: File, progress: ProgressEvent) => void
  ) => Promise<UploadResult[]>;
  onSendKeys: (instanceId: string, keys: number[]) => void;
  onClipboard: (instanceId: string, clipboard: string) => void;
};

function VmToolbar(props: Props): ReactElement {
  const { push, replace } = useHistory();
  const { kill, data } = useMachine();
  const { activeSession, setActiveSession } = useCommissionedMachine();
  const [switchVmAnchor, setSwitchVmAnchor] = React.useState<null | HTMLElement>(null);
  const [sendKeysAnchor, setSendKeysAnchor] = React.useState<null | HTMLElement>(null);
  const [uploadIsOpen, setUploadIsOpen] = React.useState(false);
  const clipboardTextRef = React.useRef<HTMLInputElement>();
  const [clipboardIsOpen, setClipboardIsOpen] = React.useState(false);
  const [clipboardContents, setClipboardContents] = React.useState(null);

  const { palette } = useTheme();

  const handleSave = () => {
    push(Path.ThreatSave);
  };

  const handleKill = () => {
    Engage.track(
      Fingerprint.of(Path.ThreatExecute).withQualifier('terminate').withData({
        hosts: data
      })
    );
    kill();
    setActiveSession([]);
    replace(Path.ThreatCreate);
  };

  const handleOpenUpload = () => {
    setUploadIsOpen(true);
  };

  function handleUpload(files: File[], onUploadProgress): Promise<UploadResult[]> {
    return props.onUpload(props.host.instance_key, files, onUploadProgress);
  }

  function handleSendKeys(keys) {
    return props.onSendKeys(props.host.instance_key, keys);
  }

  const handleSendKeysClick = (event: React.MouseEvent<HTMLElement>) => {
    setSendKeysAnchor(event.currentTarget);
  };

  const handleCloseSendKeysMenu = () => {
    setSendKeysAnchor(null);
  };

  const handleSwitchVmClick = (event: React.MouseEvent<HTMLElement>) => {
    setSwitchVmAnchor(event.currentTarget);
  };

  const handleCloseVmMenu = () => {
    setSwitchVmAnchor(null);
  };

  const handleClipboardOpen = () => {
    const item = parseStorageItem(Scheme.sessionStorage, Catalog.clipboard);
    if (item.data) {
      setClipboardContents(item.data);
    }
    setClipboardIsOpen(true);
  };

  const handleClipboardClose = () => {
    const text = clipboardTextRef.current?.value;
    if (text) {
      stowItem(Scheme.sessionStorage, Catalog.clipboard, text);
      props.onClipboard(props.host.instance_key, text);
    }
    setClipboardIsOpen(false);
  };

  return (
    <Container className={classNames('VMToolbar', props.className)}>
      <Tooltip arrow title='Save Capture' placement='left'>
        <IconButton aria-label='Save Capture' onClick={handleSave}>
          <Icon icon={faSave} color='success' />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title='Kill VMs' placement='left'>
        <IconButton aria-label='Kill VMs' onClick={handleKill}>
          <Icon icon={faBan} color='error' />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title='Upload to VM' placement='left'>
        <IconButton aria-label='Upload to VM' onClick={handleOpenUpload}>
          <Icon icon={faCloudArrowUp} color={palette.common.white} />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title='Send Keys' placement='left'>
        <IconButton aria-label='Send Keys' onClick={handleSendKeysClick}>
          <Icon icon={faKeyboard} color={palette.common.white} />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title='Copy/Paste Clipboard' placement='left'>
        <IconButton aria-label='Copy/Paste Clipboard' onClick={handleClipboardOpen}>
          <Icon icon={faClipboard} color={palette.common.white} />
        </IconButton>
      </Tooltip>
      {activeSession?.length > 1 && (
        <Tooltip arrow title='Switch VMs' placement='left'>
          <IconButton aria-label='Switch VMs' onClick={handleSwitchVmClick}>
            <Icon icon={faArrowsCross} color={palette.common.white} />
          </IconButton>
        </Tooltip>
      )}
      <Menu
        anchorEl={sendKeysAnchor}
        id='vm-sendkeys-menu'
        open={Boolean(sendKeysAnchor)}
        onClose={handleCloseSendKeysMenu}
        onClick={NOOP}
      >
        <MenuItem
          key='ctrlAltDel'
          onClick={() => {
            handleSendKeys(['65507', '65513', '65535']);
            handleCloseSendKeysMenu();
          }}
        >
          Ctrl + Alt + Delete
        </MenuItem>
        <MenuItem
          key='ctrlAltBackspace'
          onClick={() => {
            handleSendKeys(['65507', '65513', '65288']);
            handleCloseSendKeysMenu();
          }}
        >
          Ctrl + Alt + Backspace
        </MenuItem>
        <MenuItem
          key='windowsR'
          onClick={() => {
            handleSendKeys(['65515', '114']);
            handleCloseSendKeysMenu();
          }}
        >
          Windows + R
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={switchVmAnchor}
        id='vm-switch-menu'
        open={Boolean(switchVmAnchor)}
        onClose={handleCloseVmMenu}
        onClick={NOOP}
      >
        {activeSession.map(session => (
          <MenuItem
            key={session.instance_key}
            disabled={session.id == props.host.id}
            onClick={() => {
              handleCloseVmMenu();
              props.setActiveHost(session);
            }}
          >
            {session.platform == 'Linux' ? `${startCase(session.image_key)} ` : session.display_name}
          </MenuItem>
        ))}
      </Menu>
      <UploadDialog
        header='Upload Files to Virtual Machine'
        onComplete={(): void => setUploadIsOpen(false)}
        onUpload={handleUpload}
        open={uploadIsOpen}
      />
      <ClipboardDialog DialogProps={{ open: clipboardIsOpen, onClose: handleClipboardClose }} title='Clipboard'>
        <p>
          Text copied/cut within the virtual machine will appear here. Changes to the text below will affect the remote
          clipboard.
        </p>
        <TextField
          inputRef={clipboardTextRef}
          defaultValue={clipboardContents}
          elevated
          multiline
          fullWidth
          rows={12}
        />
      </ClipboardDialog>
    </Container>
  );
}

export default VmToolbar;
