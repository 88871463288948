import React from 'react';

import { faBuilding } from '@fortawesome/pro-light-svg-icons';

import { AutocompleteOptionContainer } from 'snap-ui/Autocomplete';
import Icon from 'snap-ui/Icon';
import Image from 'snap-ui/Image';
import { ListItemIcon, ListItemText } from 'snap-ui/List';

import { useAuth } from 'provider/Account';

export default function OrganizationAutocompleteOptions({ guid, text }: { guid: string; text: string }) {
  const { permission: authorizedOrgs } = useAuth();
  const smallImage = authorizedOrgs.find(org => org.guid === guid)?.small_image;
  const icon = smallImage ? <Image src={smallImage} width={16} height={16} /> : <Icon icon={faBuilding} />;

  return (
    <AutocompleteOptionContainer>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </AutocompleteOptionContainer>
  );
}
