import React from 'react';

import classnames from 'classnames';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';

import Alert from 'snap-ui/Alert';
import FormGroup from 'snap-ui/FormGroup';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { DEFAULT_ROLES, GROUP_TYPE, ROLE_LABEL_MAP } from 'constants/organization';

import CheckboxFormik from 'module/Form/CheckboxFormik';

import { Group, Organization } from 'types/auth';

import { UserFormDialogPayload } from './UserFormDialog';
import { specialGroupPermissionFilter } from './UserManagement.util';

type GroupPermissionsFormGroupProps = {
  className?: string;
  groups: Group[];
  userGroupMapping: { [key: string]: boolean };
  organization: Organization;
};

const ADMIN_GROUP = [
  DEFAULT_ROLES.Auditor,
  DEFAULT_ROLES.ContentAdmin,
  DEFAULT_ROLES.AnalyticContributor,
  DEFAULT_ROLES.BASContributor,
  DEFAULT_ROLES.CollectionContributor,
  DEFAULT_ROLES.IntegrationContributor,
  DEFAULT_ROLES.IntelContributor,
  DEFAULT_ROLES.SessionContributor
];

const CONTENT_GROUP = [
  DEFAULT_ROLES.AnalyticContributor,
  DEFAULT_ROLES.BASContributor,
  DEFAULT_ROLES.CollectionContributor,
  DEFAULT_ROLES.IntegrationContributor,
  DEFAULT_ROLES.IntelContributor,
  DEFAULT_ROLES.SessionContributor
];

const GROUPS = {
  ADMIN_GROUP,
  CONTENT_GROUP
};

function makeGroupValues(key: string, checked: boolean) {
  return GROUPS[key].reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: checked
    }),
    {}
  );
}

function GroupPermissionsFormGroupComponent({
  groups,
  userGroupMapping,
  organization,
  className
}: GroupPermissionsFormGroupProps): JSX.Element {
  const isolatedOrg = organization?.isolated;
  const { values, setValues, touched, setTouched } = useFormikContext<UserFormDialogPayload>();
  const [isDirty, setIsDirty] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (touched.organizationGuid) {
      setIsDirty(false);
      setTouched({});
    } else if (!isEmpty(touched)) {
      setIsDirty(true);
    }
  }, [touched, setTouched]);

  React.useEffect(() => {
    const newValues = {
      email: values.email,
      organizationGuid: values.organizationGuid,
      ...userGroupMapping
    };

    const newGroup = newValues[DEFAULT_ROLES.Admin]
      ? makeGroupValues('ADMIN_GROUP', true)
      : newValues[DEFAULT_ROLES.ContentAdmin]
      ? makeGroupValues('CONTENT_GROUP', true)
      : {};

    setValues({ ...newValues, ...newGroup });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroupMapping, setValues]);

  function handleCheckChange(event) {
    const { name, checked } = event.target;
    switch (name) {
      case DEFAULT_ROLES.Admin:
        setValues({
          ...values,
          [name]: checked,
          ...makeGroupValues('ADMIN_GROUP', checked),
          [DEFAULT_ROLES.User]: true
        });
        break;
      case DEFAULT_ROLES.ContentAdmin:
        setValues({
          ...values,
          [name]: checked,
          ...makeGroupValues('CONTENT_GROUP', checked),
          [DEFAULT_ROLES.Admin]: false,
          [DEFAULT_ROLES.User]: true
        });
        break;
      default:
        groups.find(g => g.name === name)?.type === GROUP_TYPE.RoleAssignment
          ? setValues({
              ...values,
              [DEFAULT_ROLES.User]: true,
              [name]: checked,
              [DEFAULT_ROLES.Admin]: false,
              [DEFAULT_ROLES.ContentAdmin]: false
            })
          : setValues({
              ...values,
              [DEFAULT_ROLES.User]: true,
              [name]: checked
            });
        break;
    }
  }

  function permissionFilter(group: Group) {
    return specialGroupPermissionFilter(organization, group);
  }

  return (
    <div className={className}>
      <Typography variant='h3' className='organization'>
        <strong>Group Permissions</strong>
      </Typography>
      {isDirty && <Alert severity='info'>You have unsaved changes.</Alert>}
      <FormGroup className='group-permissions'>
        {groups
          //do not display 'User' checkbox for isolated organizations
          ?.filter(group => (group.role === DEFAULT_ROLES.User ? !isolatedOrg : true))
          .filter(permissionFilter)
          .map(group => (
            <CheckboxFormik
              key={group.id}
              label={ROLE_LABEL_MAP[group.role] || startCase(group.role)}
              name={group.role}
              className={classnames({
                contributor: group.role.includes('Contributor'),
                auditor: group.role.includes('Auditor')
              })}
              disabled={group.role === DEFAULT_ROLES.User && userGroupMapping.User}
              onChange={handleCheckChange}
            />
          ))}
      </FormGroup>
    </div>
  );
}

const GroupPermissionsFormGroup = styled(GroupPermissionsFormGroupComponent)<GroupPermissionsFormGroupProps>`
  .group-permissions label {
    margin-top: 0;
    margin-left: ${p => p.theme.spacing(4)};

    &.contributor,
    &.auditor {
      margin-left: ${p => p.theme.spacing(7)};
    }
  }
`;

export default GroupPermissionsFormGroup;
