import { styled } from 'snap-ui/util';

export const ListContainer = styled('div')`
  margin-bottom: ${p => p.theme.spacing(9)};

  & .MuiTypography-h1 {
    margin-bottom: ${p => p.theme.spacing(4)};
  }

  .contents {
    min-width: 800px;
    max-width: 1400px;
    margin-top: ${p => p.theme.spacing(4)};

    .organization-dropdown {
      width: 300px;
    }

    .add-apikey-button {
      margin-left: auto;
    }

    .create-api-key {
      margin-left: ${p => p.theme.spacing(3)};
    }

    .table-container {
      .table-toolbar {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: ${p => p.theme.spacing(2)};
      }

      .actions-column {
        display: flex;
        justify-content: center;
      }
    }

    .no-keys-placeholder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: ${p => p.theme.spacing(5)};

      font-size: 20px;

      svg {
        height: 54px;
      }

      margin-bottom: ${p => p.theme.spacing(5)};
    }
  }
`;
