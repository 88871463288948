import React from 'react';

import { convertAxiosError } from 'module/ApiError';

import { useMetadata } from '../Metadata/useMetadata';

export const InlineUpdateContext = React.createContext<ReturnType<typeof useMetadata>['inlineUpdate']>(null);

export function useInlineEditor<T>(initialValue: T) {
  const [isEditing, setEdit] = React.useState(false);
  const [error, setError] = React.useState<ReturnType<typeof convertAxiosError>>(null);
  const [queue, setQueue] = React.useState(() => initialValue);
  const update = React.useContext(InlineUpdateContext);

  React.useEffect(() => {
    setQueue(initialValue);
  }, [initialValue]);

  return {
    isEditing,
    queue,
    setQueue,
    toggleEditing: () => setEdit(e => !e),
    update,
    error,
    setError
  };
}
