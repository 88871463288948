import React from 'react';

import { getUserList } from 'apis/resources/user';

import { useAuth } from 'provider';

import { Status, useAsync } from 'storage';

import { Ident, UserPublic } from 'types/common';

export type UserCatalog = {
  users: UserPublic[];
  status: Status;
  refresh(): void;
  getUserById(userId: Ident): UserPublic;
};

export default function useUsers(): UserCatalog {
  const { user } = useAuth();
  const { data: users, status, run } = useAsync<UserPublic[]>([]);

  const refresh = React.useCallback(() => {
    if (user.guid) {
      run(getUserList());
    }
  }, [run, user.guid]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  const getUserById = React.useCallback(
    (userId: Ident) => {
      return users.find(u => u.id === userId);
    },
    [users]
  );

  return { users, status, refresh, getUserById };
}
