import { styled } from 'snap-ui/util';

export const DescriptionContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: ${p => p.theme.spacing(8)};
  align-items: center;
  justify-content: space-between;

  .aiDescription {
    display: flex;
    align-items: center;

    .MuiFormControlLabel-label {
      font-size: 0.75em;
    }
  }
`;

export const Container = styled('div')`
  --branding-spacing: ${p => p.theme.spacing(4)};
  --branding-dimension: 70px;
  --branding-gap: calc(2 * var(--branding-spacing) + var(--branding-dimension));

  height: 100%;

  & .content {
    padding: 28px;
    padding-top: 16px;
    height: 100%;
  }

  & .banner {
    position: relative;
  }

  & .divide-banner {
    border-color: ${p => p.theme.palette.primary.dark};
  }

  & .divide-content {
    margin: 16px 0;
  }

  & .org {
    text-transform: uppercase;
    background-color: ${p => p.theme.palette.primary.dark};
    background: ${p => p.theme.palette.surface.gradient};
    padding-left: var(--branding-gap);
    height: 37.82px;
    display: flex;
    align-items: center;
  }

  .Subtitle {
    background-color: ${p => p.theme.palette.secondary.main};
    padding-left: var(--branding-gap);
    padding-right: var(--branding-spacing);
    height: 37.82px;
    display: flex;
    align-items: center;

    justify-content: space-between;
    flex-flow: row wrap;

    @media (max-width: 1361px) {
      height: unset;
      min-height: 37.82px;
    }

    > * {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
    }
  }

  .branding {
    position: absolute;
    top: 4px;
    left: var(--branding-spacing);
    height: var(--branding-dimension);
    width: var(--branding-dimension);
    background-color: ${p => p.theme.palette.background.default};
    border: 3px solid ${p => p.theme.palette.background.paper};
    text-align: center;
  }

  .branding img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;
