import React from 'react';

import MuiFormGroup from '@mui/material/FormGroup';

import { StrictReactNode } from 'types/core';

import { Base } from './type';

type Props = Base & {
  children: StrictReactNode;
  row?: boolean;
};

export default function FormGroup({ children, ...others }: Props) {
  return <MuiFormGroup {...others}>{children}</MuiFormGroup>;
}
