import React from 'react';

import Typography from 'snap-ui/Typography';

import { MarkdownRead, draftJSToMarkdownRawParser } from '../Markdown';
import { DescriptionContainer } from './OverviewTab.styled';

export default function OverviewDescription({
  includeHeading,
  description
}: {
  includeHeading: boolean;
  description: string;
}) {
  return (
    <div>
      {includeHeading && (
        <DescriptionContainer>
          <Typography variant='h4' gutterBottom>
            Overview
          </Typography>
        </DescriptionContainer>
      )}
      <MarkdownRead value={draftJSToMarkdownRawParser(description)} />
    </div>
  );
}
