import React from 'react';

import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import QRCode from 'react-qr-code';
import { useHistory } from 'react-router-dom';
import z from 'zod';

import Button from 'snap-ui/Button';
import PrimaryCircularProgress from 'snap-ui/CircularProgress';
import { BuffDivider } from 'snap-ui/Divider';
import Icon from 'snap-ui/Icon';
import { ExternalLink } from 'snap-ui/Link';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import useTitle from 'hooks/useTitle';

import { Engage, Fingerprint } from 'lib/Engagement';

import { ApiError } from 'module/ApiError';
import FormFormik from 'module/Form/FormFormik';
import Formik from 'module/Form/Formik';
import TextFieldFormik from 'module/Form/TextFieldFormik';

import { useAuth, useAuthInterface } from 'provider';

import { Status } from 'storage';

import { useMultifactor } from './Multifactor.service';

const FieldsLayout = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(3)};

  & > div {
    flex: 1;
  }

  input,
  button,
  fieldset {
    border-radius: 4px !important;
  }
`;

const Container = styled('div')`
  .header {
    text-align: center;
    font-size: 2rem;
  }

  .configuration {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: ${p => p.theme.spacing(3)};
    padding: ${p => p.theme.spacing(6)};

    ol {
      padding: 0;
      margin: 0;
    }

    ul.auth-apps {
      list-style: none;

      li {
        display: flex;
        align-items: center;
        gap: ${p => p.theme.spacing(3)};
      }
    }

    .qr-code-background {
      background: ${p => p.theme.palette.common.white};
      padding: ${p => p.theme.spacing(2)};
      padding-bottom: 3px; // funkiness from the QR Code lib
      margin: ${p => p.theme.spacing(2)};
    }
  }
`;

type Props = {
  className?: string;
  loginIdentifier?: string;
  handleError?: (errorMessage: string) => void;
};

export default function Multifactor(props: Props) {
  const { authStatus, fetch } = useAuthInterface();
  const { user } = useAuth();

  const { location, replace } = useHistory();
  useTitle(
    `Multifactor ${
      location.pathname === Path.MultifactorConfiguration ? 'Configuration' : 'Authentication'
    } | SnapAttack`
  );

  const { errorProps, setup, confirmSetup, verify } = useMultifactor();

  React.useEffect(() => {
    Engage.track(Fingerprint.load(Path.MultifactorConfiguration).withData({ user: user.email, guid: user.guid }));
  }, [user.email, user.guid]);

  const mfaConfigured = !!props.loginIdentifier;

  const [provisioningURI, setProvisioningURI] = React.useState<string>();
  const [setupKey, setSetupKey] = React.useState<string>();

  React.useEffect(() => {
    if (mfaConfigured === false && [Status.resolved, Status.rejected].includes(authStatus)) {
      setup().then(response => {
        setProvisioningURI(response.provisioning_uri);
        setSetupKey(response.otp_secret);
      });
    }
  }, [mfaConfigured, setup, authStatus]);

  return (
    <Container className={classnames('Multifactor', props.className)}>
      {mfaConfigured ? (
        <p>Open the authentication app on your mobile device and get your authentication code.</p>
      ) : (
        <>
          <Typography variant='h1' className='header'>
            Configure Multi-Factor Authentication
          </Typography>
          <div className='configuration'>
            <ol>
              <li>
                Install an authenticator application to your phone, such as:
                <ul className='auth-apps'>
                  <li>
                    Authy
                    <ExternalLink primary href={'https://apps.apple.com/us/app/twilio-authy/id494168017'}>
                      <Icon icon={faApple} />
                    </ExternalLink>
                    <ExternalLink primary href={'https://play.google.com/store/apps/details?id=com.authy.authy'}>
                      <Icon icon={faAndroid} />
                    </ExternalLink>
                  </li>
                  <li>
                    Google Authenticator
                    <ExternalLink primary href={'https://apps.apple.com/us/app/google-authenticator/id388497605'}>
                      <Icon icon={faApple} />
                    </ExternalLink>
                    <ExternalLink
                      primary
                      href={'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'}
                    >
                      <Icon icon={faAndroid} />
                    </ExternalLink>
                  </li>
                  <li>
                    Microsoft Authenticator
                    <ExternalLink primary href={'https://apps.apple.com/us/app/microsoft-authenticator/id983156458'}>
                      <Icon icon={faApple} />
                    </ExternalLink>
                    <ExternalLink
                      primary
                      href={'https://play.google.com/store/apps/details?id=com.azure.authenticator'}
                    >
                      <Icon icon={faAndroid} />
                    </ExternalLink>
                  </li>
                </ul>
              </li>
              <li>
                Scan the QR code below with the authenticator application, or enter the following setup key:{' '}
                <strong>{setupKey}</strong>
              </li>
              <li>Enter the current 6-digit code shown in the authenticator application to complete setup</li>
            </ol>
            {provisioningURI ? (
              <div className='qr-code-background'>
                <QRCode size={200} value={provisioningURI} />
              </div>
            ) : (
              <PrimaryCircularProgress size={120} />
            )}
          </div>
        </>
      )}
      <BuffDivider />
      {!isEmpty(errorProps) && (
        <>
          <ApiError {...errorProps} />
          <BuffDivider />
        </>
      )}
      <Formik
        initialValues={{
          code: ''
        }}
        onSubmit={handleSubmit}
        zodSchema={z.object({
          code: z.string().min(6, 'Authentication code is required.')
        })}
      >
        <FormFormik>
          <FieldsLayout>
            <TextFieldFormik label='Authentication Code' name='code' placeholder='Enter code...' />
            <Button type='submit'>{mfaConfigured ? 'Verify' : 'Enable'}</Button>
          </FieldsLayout>
        </FormFormik>
      </Formik>
    </Container>
  );

  async function handleSubmit({ code }) {
    if (!code) return;

    if (mfaConfigured) {
      await verify(props.loginIdentifier, { two_factor: code })
        .then(() => {
          replace(Path.LoginSuccess);
        })
        .catch(e => {
          const { detail: ErrorMessage } = e;

          props.handleError(ErrorMessage);
        });
    } else {
      await confirmSetup({ two_factor: code });
      await fetch();
      replace(Path.Home);
    }
  }
}
