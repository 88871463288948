import React from 'react';

import { JobStatus } from 'module/Job/Job.type';

import { StateLabelBase, ValueMap } from './';

export const VALUE_MAP: ValueMap<JobStatus> = {
  [JobStatus.Canceled]: { color: 'greyish', label: 'Canceled' },
  [JobStatus.CompletedWithErrors]: { color: 'warning', label: 'Completed' },
  [JobStatus.Failed]: { color: 'error', label: 'Failed' },
  [JobStatus.Pending]: { color: 'greyish', variant: 'outlined', label: 'Queued' },
  [JobStatus.Started]: { color: 'success', variant: 'outlined', label: 'In Progress' },
  [JobStatus.Success]: { color: 'success', label: 'Completed' },
  [JobStatus.Paused]: { color: 'greyish', label: 'Paused' }
};

export function DetectionJobStateLabel({ className, value }: { className?: string; value: JobStatus }): JSX.Element {
  return <StateLabelBase className={className} value={value} valueMap={VALUE_MAP} />;
}
