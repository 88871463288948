import { MIN_PERCENTAGE } from 'snap-ui/Charts/SpecifiedDomainRadarChart';

import { Integration } from 'module/Integration/Integration.type';

import { RadarData, SocDashboardStats } from './SocDashboard.type';

export const getColorKey = (data: SocDashboardStats, integrations: Integration[]) => {
  return Object.keys(data ?? {})
    .map(integrationId => {
      const integration = integrations?.find(integration => integration.id.toString() === integrationId);

      if (integration) {
        return integration.name;
      }
    })
    .filter(name => {
      return !!name;
    })
    .reduce((colorKey, name, index) => {
      return {
        ...colorKey,
        [name]: index
      };
    }, {});
};

export const getAttackCoverageData = (data: SocDashboardStats, integrations: Integration[]): RadarData[] => {
  const attackCoverageData = {};
  Object.entries(data ?? {}).forEach(([integrationId, integrationData]) => {
    const integration = integrations.find(integration => integration?.id?.toString() === integrationId);
    if (integration?.name) {
      // get last time slice of coverage
      const coverage = integrationData[integrationData.length - 1]?.coverage;
      coverage.forEach(item => {
        const { tag_name, coverage, children } = item;
        let percentage = Math.floor((coverage / children) * 100);
        percentage = percentage < 1 ? MIN_PERCENTAGE : percentage;

        if (!attackCoverageData[tag_name]) {
          attackCoverageData[tag_name] = {
            name: tag_name,
            values: {
              [integration.name]: percentage
            }
          };
        } else {
          attackCoverageData[tag_name].values[integration.name] = percentage;
        }
      });
    }
  });

  return Object.values(attackCoverageData);
};
