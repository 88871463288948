import { BulkLaunchBASFormValues } from 'types/bas';
import { ArtifactType, Guid } from 'types/common';
import { Query } from 'types/filter';

import { postCampaign } from './BAS.api';
import { BASCampaignPost } from './BAS.type';

function filterPayload(topic: ArtifactType, criteria: Query, guid: Guid, tagName?: string) {
  const payload: Omit<BASCampaignPost, 'agents'> = { collection: guid, tag: tagName };
  switch (topic) {
    case ArtifactType.Analytic:
      payload.analytic_filter = criteria;
      break;
    case ArtifactType.Session:
      payload.session_filter = criteria;
      break;
    case ArtifactType.AttackScript:
      payload.bas_script_filter = criteria;
      break;
    default:
      console.error(`Preview payload not implemented for ${topic}`);
  }
  return payload;
}

export async function sendPostCampaign(
  topic: ArtifactType,
  criteria: Query,
  guid: Guid,
  values: BulkLaunchBASFormValues,
  tagName?: string
) {
  const payload: BASCampaignPost = { ...filterPayload(topic, criteria, guid, tagName), ...values };
  await postCampaign(payload);
}
