import React from 'react';

import { faThumbTack } from '@fortawesome/pro-regular-svg-icons';
import { faThumbTack as faThumbTackPinned } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

import { ActionIconButton as _ActionIconButton } from 'snap-ui/Button';
import Tooltip, { TooltipProps } from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { Guid } from 'types/common';

const ActionIconButton = styled(_ActionIconButton)`
  &.TargetPinner-pinned {
    color: ${p => p.theme.palette.success.main};
  }

  &.Mui-disabled {
    color: ${p => p.theme.palette.greyish.dark};
  }
`;

export type ToggleLogPinButton = {
  autopinned?: boolean;
  className?: string;
  disabled?: boolean;
  guid?: string;
  onClick(guid: Guid): void;
  pinned?: boolean;
  TooltipProps?: Omit<TooltipProps, 'children' | 'title'>;
};

export function ToggleLogPinButton(props: ToggleLogPinButton) {
  const { autopinned, className, guid, TooltipProps, onClick, pinned, ...others } = props;
  const title = autopinned
    ? 'This log is automatically attach to this detection.'
    : pinned
    ? 'This log is pinned. Press to unpin.'
    : 'This log is not pinned. Press to pin.';
  return (
    <Tooltip placement='top-start' arrow wrap title={title} {...TooltipProps}>
      <ActionIconButton
        className={classNames('ToggleLogPin', { 'TargetPinner-pinned': pinned }, className)}
        aria-label={title}
        icon={pinned ? faThumbTackPinned : faThumbTack}
        onClick={() => onClick(guid)}
        IconProps={{ size: 'lg' }}
        {...others}
      />
    </Tooltip>
  );
}
