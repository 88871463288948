import React from 'react';

import { CancelTokenSourceType } from 'apis';

import { Status, useAsync } from 'storage';

import { Guid } from 'types/common';

import { translateIOC } from '../IOC.api';
import { IOCBase, IOCTranslation, TranslateIOCResponse } from '../IOC.type';

type ExtractIOCInterface = {
  data: IOCTranslation[];
  status: Status;
  translate(integrationGuid: Guid, iocs: IOCBase[], cancelSource: CancelTokenSourceType): void;
  reset(): void;
};

export default function useTranslateIOC(): ExtractIOCInterface {
  const { data, run, setData, status, reset } = useAsync<TranslateIOCResponse>();

  const translate = React.useCallback(
    (integrationGuid: Guid, iocs: IOCBase[], cancelSource: CancelTokenSourceType) => {
      if (iocs && iocs.length) run(translateIOC(integrationGuid, iocs, cancelSource));
      else {
        setData({ translations: [] });
      }
    },
    [run, setData]
  );

  return {
    data: data?.translations || [],
    status,
    translate,
    reset
  };
}
