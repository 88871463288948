import React from 'react';

import isEmpty from 'lodash/isEmpty';

import Tooltip from 'snap-ui/Tooltip';

import { BASJob } from 'module/BAS/BAS.type';

import { JobOutcome as JobOutcomeEnum } from 'types/bas';

import JobOutcomeLabel from './JobOutcomeLabel';

type JobOutcomeProps = {
  job: BASJob;
  print?: boolean;
};

export default function JobOutcome({ job, print }: JobOutcomeProps): JSX.Element {
  const outcomesList = (
    <div>
      {job.outcomes.map(outcome => (
        <div key={outcome.tool}>
          {outcome.outcome} by {outcome.tool}
        </div>
      ))}
    </div>
  );
  return print ? (
    outcomesList
  ) : (
    <Tooltip arrow placement='top' title={isEmpty(job.outcomes) ? JobOutcomeEnum.NeedsReview : outcomesList} wrap>
      <JobOutcomeLabel outcome={job.outcome} />
    </Tooltip>
  );
}
