export const divide = (numerator: number, denominator: number): number => {
  if (denominator && denominator > 0) {
    return numerator / denominator;
  }

  return 0;
};

export const percent = (numerator: number, denominator: number): number => {
  return divide(numerator, denominator) * 100.0;
};

export const handleBytes = (bytes: number): string => {
  const units = ['bytes', 'KB', 'MB', 'GB'];

  let l = 0;

  while (bytes >= 1024 && ++l) {
    bytes = bytes / 1024;
  }

  return bytes.toFixed(bytes < 10 && l > 0 ? 1 : 1) + ' ' + units[l];
};

export function pageTotal(totalItems: number, pageSize: number): number {
  return Math.ceil(totalItems / pageSize);
}

export function toPrecisionDecimal(value: number | string, precision: number): number {
  return Number(Number(value).toFixed(precision));
}
