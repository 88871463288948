import { AnalyticForm } from 'types/analytic';

export default class Lexer {
  constructor(private structure: AnalyticForm) {}

  run(): Lexer {
    // Here we will build an abstract syntax tree of this.structure.detection.condition
    // accounting for any parens and nested parens
    // accounting for OR AND, & NOT
    return this;
  }

  get result(): Record<string, unknown> {
    return {};
  }
}
