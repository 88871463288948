import React from 'react';

import { getAssociatedAttackScripts } from 'module/Analytic/Analytic.api';
import { getAnalyticAttackScripts, getSessionAttackScripts } from 'module/AttackScript/AttackScript.service';
import { AttackScript } from 'module/AttackScript/AttackScript.type';

import { Status, useAsync } from 'storage';

import { BASScript } from 'types/bas';
import { ArtifactType, Guid } from 'types/common';

type Script = AttackScript | BASScript;
export type ScriptsAsyncInterface = {
  data: Script[];
  status: Status;
  setScripts: React.Dispatch<React.SetStateAction<Script[]>>;
};

function useScripts(guid: Guid, type: ArtifactType.Session | ArtifactType.Analytic): ScriptsAsyncInterface {
  const { data, setData, run, status } = useAsync<Script[]>([]);

  const refresh = React.useCallback(() => {
    if (guid) {
      if (type === ArtifactType.Session) {
        run(getSessionAttackScripts(guid));
      } else if (type === ArtifactType.Analytic) {
        run(
          Promise.all([
            getAnalyticAttackScripts(guid),
            getAssociatedAttackScripts(guid).then(response => response.scripts)
          ]).then(results => results.flat())
        );
      }
    }
  }, [guid, run, type]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    data,
    status,
    setScripts: setData
  };
}

export default useScripts;
