import React from 'react';

import { PieChartProps } from 'snap-ui/Charts/PieChart';
import { MultiProgress } from 'snap-ui/LinearProgress';
import Placeholder from 'snap-ui/Placeholder';
import { theme } from 'snap-ui/theme';
import { styled } from 'snap-ui/util';

import { WithPrintStyle } from 'module/Dashboard/core/Dashboard.style';
import Artifact from 'module/Layout/Artifact';

import { JobOutcome } from 'types/bas';

export const CampaignReportContainer = styled(Artifact)`
  .HeroImage-window {
    display: grid;
    align-items: center;
    gap: ${p => p.theme.spacing(3)};

    grid-template-columns: 1fr 20%;
    grid-template-areas:
      'type status'
      'title artifact'
      'description description'
      'details menu';

    .Artifact-type {
      grid-area: type;
      justify-self: start;
      height: 1.25rem;
    }

    .CampaignReportHeader-status {
      grid-area: status;

      justify-self: end;
    }

    .CampaignReportHeader-action {
      grid-area: action;
      justify-self: end;
      display: flex;
      gap: ${p => p.theme.spacing(1)};
      align-items: center;
    }

    .CampaignReportHeader-title {
      grid-area: title;
    }

    .CampaignReportHeader-artifact {
      grid-area: artifact;

      & > * {
        display: flex;
        justify-content: flex-end;
        gap: ${p => p.theme.spacing(2)};
        align-items: center;
      }
    }

    .CampaignReportHeader-description {
      grid-area: description;
    }

    .CampaignReportHeader-details {
      grid-area: details;
      display: flex;
      flex-direction: column;
      gap: ${p => p.theme.spacing(2)};

      .AgentLabels {
        display: flex;
        flex-direction: column;

        .agent-label-content {
          display: flex;
          flex-direction: row;
          gap: ${p => p.theme.spacing(2)};
        }
      }

      .execution-times {
        display: grid;
        grid-template-columns: 80px 1fr;
        gap: ${p => p.theme.spacing(2)};
        align-items: center;

        & > div {
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .CampaignReportHeader-menu {
      grid-area: menu;
      justify-self: end;
      align-self: end;
    }
  }

  .Artifact-content,
  .CampaignReport-section {
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(5)};
  }

  .CampaignReport-charts {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: ${p => p.theme.spacing(4)};
    align-items: stretch;
  }

  .HeroImage-window,
  .CampaignReport-section {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;

    .MuiTableHead-root {
      height: ${p => p.theme.spacing(7)};
    }
  }

  .Alert {
    justify-content: center;
    align-items: center;
  }
`;

export const CampaignReportPrintContainer = styled(WithPrintStyle)`
  .print-only {
    display: none !important;
  }

  .k-pdf-export {
    .print-only {
      display: flex !important;
    }
    .no-print {
      display: none !important;
    }

    p,
    span {
      font-size: 20px !important;
    }

    .Artifact,
    .Artifact-content,
    .HeroImage {
      padding: 0;
      margin-top: 0;
    }

    div.HeroImage,
    .CampaignReport-section {
      margin-top: ${p => p.theme.spacing(8)};
    }

    .DetailedTestResults .MuiPaper-root {
      border-color: transparent;
    }
  }
`;

export function ChartPanelPlaceholder(): JSX.Element {
  return <Placeholder variant='rectangular' height={308} width='100%' />;
}

export function CampaignReportSectionPlaceholder(): JSX.Element {
  return (
    <div className='CampaignReport-section'>
      <Placeholder variant='text' height={66} width={250} />
      <div className='CampaignReport-charts'>
        <ChartPanelPlaceholder />
        <ChartPanelPlaceholder />
        <ChartPanelPlaceholder />
      </div>
    </div>
  );
}

export const MitreTacticCoverageMatrixContainer = styled('div')`
  padding: ${p => p.theme.spacing(5)};
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(5)};
  position: relative;

  .export-button {
    position: absolute;
    right: ${p => p.theme.spacing(5)};
  }
`;

export const MitreTacticCoverageChartContainer = styled('div')`
  padding: ${p => p.theme.spacing(5)};

  .MitreTacticCoverageChart-bars {
    margin: ${p => p.theme.spacing(7, 0)};

    display: grid;
    grid-template-columns: 180px 1fr;
    grid-gap: ${p => p.theme.spacing(4)};
    align-items: stretch;

    p {
      text-align: right;
    }

    @media (max-width: 1000px) {
      grid-gap: 0;
      grid-template-columns: 1fr;

      p {
        text-align: left;
        margin-bottom: 0;
        margin-top: ${p => p.theme.spacing(4)};
      }
    }
  }
`;

export const Progress = styled(MultiProgress)`
  min-width: 100px;
  height: 24px;
`;

export const MitreLegendContainer = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(5)};
  align-items: center;
  justify-content: center;

  .Legend-item {
    display: flex;
    align-items: center;
    gap: ${p => p.theme.spacing(1)};
  }

  .Legend-colorSample {
    background-color: ${p => p.theme.palette.grey[200]};
    border-radius: 5px;
    color: ${p => p.theme.palette.common.black};
    height: 20px;
    width: 20px;
  }
`;

export const PieChartDefaultProperties: Partial<PieChartProps> = {
  useLegend: true,
  showValueInLegend: true,
  useTooltip: true
};

export const JobOutcomeColors = {
  [JobOutcome.Prevented]: theme.palette.lime.dark,
  [JobOutcome.Detected]: theme.palette.lime.main,
  [JobOutcome.Logged]: theme.palette.warning.main,
  [JobOutcome.NoAction]: theme.palette.error.main,
  [JobOutcome.NeedsReview]: theme.palette.common.white,
  [JobOutcome.NotTested]: theme.palette.grey[500],
  true: theme.palette.grey[500]
};

export const JobOutcomeColorGradient = [
  { main: theme.palette.lime.dark, light: theme.palette.lime.main },
  {
    main: theme.palette.lime.main,
    light: theme.palette.lime.light
  },
  {
    main: theme.palette.warning.main,
    light: theme.palette.warning.light
  },
  {
    main: theme.palette.error.main,
    light: theme.palette.error.light
  },
  {
    main: theme.palette.greyish.main,
    light: theme.palette.greyish.light
  }
];

export function getJobOutcomeColors(outcome: JobOutcome) {
  if (!outcome) return;
  return {
    backgroundColor: JobOutcomeColors[outcome],
    color: theme.palette.getContrastText(JobOutcomeColors[outcome])
  };
}

/*
  Chart Colors
*/

export const AttackStatusChartColors = [
  theme.palette.success,
  theme.palette.warning,
  theme.palette.error,
  theme.palette.greyish
];

export const ValidationsChartColors = [theme.palette.success, theme.palette.error, theme.palette.greyish];

export const ConfidenceSeverityChartColors = [
  theme.palette.error,
  theme.palette.orange,
  theme.palette.warning,
  theme.palette.success,
  theme.palette.info,
  theme.palette.greyish
];
