import React from 'react';

import { Link } from 'react-router-dom';

import Path from 'constants/paths';

import { Discriminator } from '../BAS.type';
import { getTestCaseName } from '../BAS.util';
import { ColumnComponentProps } from './common';

export default function TestCaseColumn({ job }: ColumnComponentProps): JSX.Element {
  const isManual = job?.discriminator === Discriminator.manual;
  const testCaseName = getTestCaseName(job);

  return isManual ? <>{testCaseName}</> : <Link to={`${Path.AttackScript}/${job?.script?.guid}`}>{testCaseName}</Link>;
}
