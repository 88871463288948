import React from 'react';

import { OutlinedAccordion as Accordion } from 'snap-ui/Accordion';

function CapAttack() {
  return (
    <Accordion
      summary='How do I capture a threat locally?'
      details={
        <div>
          <p>
            Need more attacker/victim machine options than what we currently provide in SnapAttack? With CapAttack, you
            can capture threats from a local workstation, server, or virtual machine and import them into SnapAttack!
          </p>

          <p>Open Powershell as an administrator/root and run:</p>

          <div className='MarkdownRead'>
            <pre>
              <code>
                Invoke-WebRequest -useb https://cdn.snapattack.com/resources/capattack/Install.ps1 -OutFile
                Install.ps1;./Install.ps1
              </code>
            </pre>
          </div>

          <p>
            Alternatively, you can{' '}
            <a
              href='https://cdn.snapattack.com/resources/capattack/capattack.zip'
              rel='noopener noreferrer'
              target='_blank'
            >
              download CapAttack
            </a>{' '}
            and install manually.
          </p>

          <p>See the README in the capattack.zip for more details and usage information.</p>
        </div>
      }
    />
  );
}

export default CapAttack;
