import React from 'react';

import { faMinusSquare, faPlusSquare } from '@fortawesome/pro-regular-svg-icons';
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import get from 'lodash/get';

import { ActionIconButton } from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { useTokenizeKeyValues, Processor } from 'module/HighlightDetection';

import { LogRecord } from 'types/ide';

import LogContentRow from './LogContentRow';
import { LogContentRoot } from './style';

const EXCLUDE_FIELD = ['host', 'Message'];

const FIELD_ORDER = [
  'EventID',
  'UtcTime',
  'ProcessName',
  'Image',
  'CommandLine',
  'ParentProcessName',
  'ParentImage',
  'ParentCommandLine',
  'User',
  'IntegrityLevel',
  'Hashes',
  'process_name',
  'process_path',
  'parent_process_path',
  'command_line',
  'start_time',
  'hashes'
];

function getFieldRank(field: string, order: string[]): number {
  // sort by the order above, then alphabetically if the field
  // is not one of those specified above for priority sorting
  const rank = order.indexOf(field);
  return rank === -1 ? order.length + field.toLowerCase().charCodeAt(0) : rank;
}

export type LogContentProps = {
  getTooltip(key: string): string;
  highlightProcessor?: Processor;
  log: LogRecord | string;
  onLogClick?(key: string, value: string): void;
};

function LogContent(props: LogContentProps): React.ReactElement {
  const { getTooltip, highlightProcessor, log, onLogClick } = props;
  const logObject = React.useMemo(() => {
    if (typeof log === 'string') {
      try {
        return JSON.parse(log) as LogRecord;
      } catch (e) {
        console.error('Trying to parse LogRecord from string', e);
        return {} as LogRecord;
      }
    } else {
      return log || ({} as LogRecord);
    }
  }, [log]);

  const [logCollapse, toggleLogCollapse] = React.useReducer((state, key) => ({ ...state, [key]: !state[key] }), {});

  const logEntries = React.useMemo(() => {
    return Object.entries(logObject)
      .filter(([key]) => key !== '_raw' && key !== 'splunk_raw' && !key.startsWith('metadata.'))
      .map(([key, value]) => [
        key.startsWith('raw.')
          ? key.replace('raw.', '')
          : key.startsWith('normalized.')
          ? key.replace('normalized.', '')
          : key,
        value
      ]);
  }, [logObject]);

  const tokenizedKeyValues = useTokenizeKeyValues(logEntries, highlightProcessor);

  // combine our highlighted fields and default field ordering into a unique list
  const order = Array.from(new Set([...tokenizedKeyValues.field, ...FIELD_ORDER]));
  return (
    <LogContentRoot className='LogContent'>
      {logEntries
        .filter(([key]) => !key.startsWith('_') && !EXCLUDE_FIELD.includes(key))
        .sort((a, b) => {
          const aRank = getFieldRank(a[0], order);
          const bRank = getFieldRank(b[0], order);
          return aRank - bRank;
        })
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            const subDetection = {
              field: tokenizedKeyValues.field.filter(f => f === key),
              token: tokenizedKeyValues.token.filter(t => t.property === key)
            };

            const inclusion = get(subDetection, ['token', 0, 'inclusion']);

            return (
              <div key={key}>
                <div style={{ display: 'flex' }}>
                  <dt className={subDetection.field.includes(key) ? 'highlight' : ''}>
                    {inclusion === false && (
                      <>
                        <Tooltip arrow placement='top-start' title='Missing from condition expression' wrap>
                          <Icon icon={faMinusCircle} />
                        </Tooltip>
                        &nbsp; &nbsp;
                      </>
                    )}
                    {key}
                  </dt>
                  <div className='log-collapse'>
                    <ActionIconButton
                      aria-label='collapse section'
                      icon={logCollapse[key] ? faPlusSquare : faMinusSquare}
                      size='small'
                      onClick={(): void => toggleLogCollapse(key)}
                    />
                  </div>
                </div>
                {!logCollapse[key] &&
                  value.map((v, sub) =>
                    typeof v === 'object' ? (
                      <LogContent {...props} log={v} />
                    ) : (
                      <LogContentRow
                        getTooltip={getTooltip}
                        key={`${key}-${v}`}
                        ident={`${sub}`}
                        field={subDetection.field[sub]}
                        parent={key}
                        token={subDetection.token[sub]}
                        value={v}
                        style={{ marginLeft: '2em' }}
                        onLogClick={onLogClick}
                      />
                    )
                  )}
              </div>
            );
          } else {
            const subject = tokenizedKeyValues.field.indexOf(key);
            return (
              <LogContentRow
                getTooltip={getTooltip}
                key={key}
                ident={key}
                field={tokenizedKeyValues.field[subject]}
                token={tokenizedKeyValues.token[subject]}
                value={value}
                onLogClick={onLogClick}
              />
            );
          }
        })}
    </LogContentRoot>
  );
}

export default LogContent;
