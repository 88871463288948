import React from 'react';

import classnames from 'classnames';
import { useField } from 'formik';

import { styled } from 'snap-ui/util';

import { Guid } from 'types/common';

import FormError from './FormError';
import UploadField, { DropZoneProps } from './UploadField';

const UploadRoot = styled('div', { label: 'UploadRoot' })`
  .pp-highlight-zone {
    background-color: ${p => p.theme.palette.surface.fade};
    transition: background-color 0.3;
  }
`;

type UploadFieldFormikProps = DropZoneProps & {
  className?: string;
  existingFilesName?: string;
  name: string;
  required?: boolean;
  maxFiles?: number;
};

function UploadFieldFormik({
  className,
  existingFilesName,
  name,
  required,
  ...others
}: UploadFieldFormikProps): React.ReactElement {
  const [, meta, helpers] = useField(name);
  // We can't conditionally call a hook, so if existingFilesName is undefined we'll create a dummy field
  const [{ value: existingFiles }, , { setValue: setExistingFilesValue }] = useField(existingFilesName || '__unused');
  const hasError = meta.touched && !!meta.error;
  const [highlight, setHighlight] = React.useState(false);

  const handleDragEnter = () => setHighlight(true);
  const handleDragLeave = () => setHighlight(false);

  React.useEffect(() => {
    document.addEventListener('dragover', handleDragEnter);
    document.addEventListener('dragleave', handleDragLeave);
    document.addEventListener('drop', handleDragLeave);

    return () => {
      document.removeEventListener('dragover', handleDragEnter);
      document.removeEventListener('dragleave', handleDragLeave);
      document.removeEventListener('drop', handleDragLeave);
    };
  }, []);

  function handleRemoveExisting(guidToRemove: Guid) {
    if (existingFilesName) {
      setExistingFilesValue(existingFiles.filter(file => file.guid !== guidToRemove));
    }
  }

  return (
    <UploadRoot
      className={classnames('Form-field', className, {
        'pp-highlight-zone': highlight,
        required,
        valid: meta.touched && !meta.error
      })}
    >
      <UploadField
        existingFiles={existingFiles}
        onChange={handleChange}
        onRemoveExisting={handleRemoveExisting}
        {...others}
      />
      <FormError error={meta.error} isVisible={hasError} />
    </UploadRoot>
  );

  function handleChange(files: File[]): void {
    helpers.setValue(files, true);
  }
}

export default UploadFieldFormik;
