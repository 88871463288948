import React from 'react';

import { RJSFSchema } from '@rjsf/utils';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';

import { useIntegrationCatalog } from 'provider';

import { Guid } from 'types/common';

export type AdvancedConfig = {
  schemas: Record<Guid, RJSFSchema>;
  initialValues: Record<Guid, unknown>;
};

export default function useIntegrationAdvancedConfig(integrationGuids: Guid[]): AdvancedConfig {
  const { integrations, deploymentSchema } = useIntegrationCatalog();

  // map schemas and initial values to selected integration guid
  const advancedConfigurations = React.useMemo(() => {
    if (isEmpty(integrationGuids) || isEmpty(deploymentSchema) || isEmpty(integrations.deployable)) {
      return {
        schemas: {},
        initialValues: {}
      };
    }

    const selectedIntegrations = integrations.deployable.filter(i => integrationGuids.includes(i.guid));
    return selectedIntegrations.reduce(
      (accumulated, integration) => {
        if (integration.type in deploymentSchema) {
          const schema = deploymentSchema[integration.type];
          return {
            schemas: {
              ...accumulated.schemas,
              [integration.guid]: schema
            },
            initialValues: {
              ...accumulated.initialValues,
              [integration.guid]: pick(integration?.extra_information, Object.keys(schema.properties))
            }
          };
        }
        return accumulated;
      },
      {
        schemas: {},
        initialValues: {}
      }
    );
  }, [deploymentSchema, integrations.deployable, integrationGuids]);

  return advancedConfigurations;
}
