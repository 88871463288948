import React from 'react';

import Avatar from 'snap-ui/Avatar';
import Paper from 'snap-ui/Paper';
import Popper from 'snap-ui/Popper';
import { ClickAwayListener, styled } from 'snap-ui/util';

import { useAuth } from 'provider';

import { handleDebug, NOOP } from 'utilities/FunctionUtils';

import { Glossary, MenuOrdinal } from '../Scaffold.type';
import Copyright from './Copyright';
import ListItemsProfile from './ListItemsProfile';
import { List } from './ToolbarItem';

export const Profile = styled(Avatar)`
  margin: ${p => p.theme.spacing(0, 2)};
  background-color: transparent;
  color: ${p => p.theme.palette.text.primary};
  height: 40px;
  width: 40px;
  border-radius: 40px;
  border: 1px solid ${p => p.theme.palette.primary.main};
  padding: 0;
  justify-content: center;
  align-items: center;
  outline: none;

  &:focus {
    outline: 1px solid ${p => p.theme.palette.primary.main};
  }

  &:hover {
    background-color: ${p => p.theme.palette.surface.hover};
    cursor: pointer;
  }
`;

type Props = {
  onClick(id: string, element: HTMLElement | HTMLDivElement): void;
  onClose(): void;
  glossary: Glossary;
};

export default function ToolbarItemProfile({ glossary, onClick, onClose }: Props) {
  const { user, permission, defaultOrgId } = useAuth();
  const initials = ((user.first_name ?? '').charAt(0) + (user.last_name ?? '')?.charAt(0)).toUpperCase();

  const handleOnClick = (event: React.MouseEvent<HTMLLIElement | HTMLDivElement>) => {
    const id = event.currentTarget.getAttribute('data-id');
    onClick(id, event.currentTarget);
  };

  const handleMouseEvent = (e: React.MouseEvent<HTMLDivElement>) =>
    handleDebug(e, () => {
      console.groupCollapsed(user.guid);
      console.info('defaultOrgId', defaultOrgId);
      console.info('permission', permission);
      console.info('user', user);
      console.groupEnd();
    });

  return (
    <>
      <Profile
        aria-owns={`${MenuOrdinal.Profile}-menu-item`}
        data-id={MenuOrdinal.Profile}
        data-testid='menu-profile'
        tabIndex={0}
        role='menuitem'
        onClick={handleOnClick}
        onMouseEnter={handleMouseEvent}
      >
        {initials}
      </Profile>
      <Popper
        disablePortal
        anchorEl={glossary[MenuOrdinal.Profile]}
        open={Boolean(glossary[MenuOrdinal.Profile])}
        className='ToolbarItem-popper'
      >
        <ClickAwayListener onClickAway={open ? onClose : NOOP}>
          <List id={`${MenuOrdinal.Profile}-menu-item`} component={Paper} elevation={0} square>
            <ListItemsProfile onClose={onClose} />
            <Copyright withStatus />
          </List>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
