import React from 'react';

import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';

import Accordion, { AccordionDetails, AccordionSummary } from 'snap-ui/Accordion';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';

import { getCountriesByAlpha3 } from 'constants/countries';

import { Discriminator } from 'module/Tag';

import { CategoryContainer } from '../../Landing.style';
import { useLandingCatalog } from '../../LandingProvider';
import { ActorRegionMap } from './ActorRegionMap';

export default function TargetRegions() {
  const { source, type } = useLandingCatalog();

  if (isEmpty(source.target_regions) || type !== Discriminator.Actor) return null;
  const regions = getCountriesByAlpha3(source?.target_regions);
  return (
    <Accordion className='LandingSection-accordion' defaultExpanded={true} elevation={0}>
      <AccordionSummary className='LandingSection-accordionSummary' expandIcon={<Icon icon={faCaretUp} />}>
        <Typography variant='h4'>Target Regions</Typography>
      </AccordionSummary>
      <AccordionDetails className='category-title'>
        <CategoryContainer>
          <div className='category-detail'>
            {regions
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((region, index) => (
                <div className='icon-title' key={index}>
                  <span>{region.flag}</span>
                  {region.name}
                </div>
              ))}
          </div>
          <ActorRegionMap regions={regions} />
        </CategoryContainer>
      </AccordionDetails>
    </Accordion>
  );
}
