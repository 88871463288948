import FormDialog from 'snap-ui/Dialog/FormDialog';
import { styled } from 'snap-ui/util';

export const LabelContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${p => p.theme.spacing(2)};
  cursor: pointer;

  div:first-of-type {
    min-width: 100px;
    width: fit-content;
    white-space: nowrap;

    span:first-of-type {
      overflow: unset;
    }
  }
`;

export const StyledFormDialog = styled(FormDialog)`
  .auxiliary-actions {
    display: flex;
    justify-content: space-between;
  }
`;

export const OutcomeContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(3)};

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-self: stretch;
    gap: ${p => p.theme.spacing(2)};
  }

  .outcome-header {
    grid-template-columns: 1fr 40px;
    margin-bottom: ${p => p.theme.spacing(3)};
  }
`;

export const PaginateModalContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(3)};

  input {
    width: 24px;
  }
`;

export const DetectionOption = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${p => p.theme.spacing(2)};
`;
