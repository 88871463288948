import React from 'react';

function useHover() {
  const [isHovering, setIsHovering] = React.useState(false);

  const handleMouseEnter = React.useCallback(() => setIsHovering(true), []);
  const handleMouseLeave = React.useCallback(() => setIsHovering(false), []);

  const nodeRef = React.useRef<HTMLDivElement | null>(null);

  const callbackRef = React.useCallback(
    node => {
      if (nodeRef.current) {
        nodeRef.current.removeEventListener('mouseenter', handleMouseEnter);
        nodeRef.current.removeEventListener('mouseleave', handleMouseLeave);
      }

      nodeRef.current = node;

      if (nodeRef.current) {
        nodeRef.current.addEventListener('mouseenter', handleMouseEnter);
        nodeRef.current.addEventListener('mouseleave', handleMouseLeave);
      }
    },
    [handleMouseEnter, handleMouseLeave]
  );

  return [callbackRef, isHovering];
}

export default useHover;
