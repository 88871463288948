import React from 'react';

import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { Redirect } from 'react-router-dom';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import Fade from 'snap-ui/Fade';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import useDebounce from 'hooks/useDebounce';
import useTitle from 'hooks/useTitle';

import Can from 'module/Can';
import { standardFormikBaseProps } from 'module/Form';
import { ScrapeIOCUploadFieldFormik } from 'module/Form/ScrapeIOCUploadFieldFormik';
import { UnfurlTextField } from 'module/Intel/IntelCore/UnfurlTextField';
import { Page } from 'module/Layout/Styled';
import { SyntaxEditor } from 'module/Widgets/SyntaxEditor';

import { Status } from 'storage/Storage.type';

import { FunctionalPermission } from 'types/auth';
import { ArtifactType } from 'types/common';

import { NOOP } from 'utilities/FunctionUtils';

import { CategorizedIOCs, IOC } from '../IOC.type';
import { IOCExtractContainer, IOCExtractHeaderContainer, IOCParserContainer } from './IOCParser.style';
import { categorizeIocs, highlight } from './IOCParser.util';
import IOCTranslator from './IOCTranslator';
import useExtractIOC from './useExtractIOC';

const StyledScrapeIOCUploadFieldFormik = styled(ScrapeIOCUploadFieldFormik)`
  .UploadContainer {
    padding: ${p => p.theme.spacing(3)};

    p {
      margin-bottom: 0;
    }

    ul {
      margin-top: ${p => p.theme.spacing(3)};
    }
  }
`;

export default function IOCParser() {
  useTitle('IOC Hunter');

  const { text, iocs, extractText, extractUrl, status: extractStatus } = useExtractIOC();
  const [rawText, setRawText] = React.useState<string>('');
  const [linkSource, setLinkSource] = React.useState('');
  const [keywords, setKeywords] = React.useState<CategorizedIOCs>();
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [iocPayload, setIocPayload] = React.useState<IOC[]>([]);
  const [changedByAPI, setChangedByAPI] = React.useState<boolean>(false);
  const debouncedRaw = useDebounce(rawText, 1000);
  const isLoading = isUploading || extractStatus === Status.pending;

  React.useEffect(() => {
    setIocPayload(iocs);
  }, [iocs]);

  React.useEffect(() => {
    const keywords = categorizeIocs(iocPayload, 'name');
    setKeywords(keywords);
  }, [iocPayload]);

  React.useEffect(() => {
    if (text) setRawText(text);
  }, [text]);

  React.useEffect(() => {
    if (debouncedRaw && !changedByAPI) {
      extractText(debouncedRaw);
      setChangedByAPI(true);
    }

    // prevent changes to changedByAPI kicking off an extract
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedRaw, extractText]);

  const doHighlight = highlight(keywords);

  const handleTextChange = (text: string): void => {
    setChangedByAPI(false);
    setRawText(text);
  };

  const handleUnfurl = (source: string): void => {
    setRawText('');
    setChangedByAPI(true);
    extractUrl(source);
  };

  const setTextFromFile = React.useCallback((text: string): void => {
    setLinkSource('');
    setChangedByAPI(true);
    setRawText(text);
  }, []);

  return (
    <Can I={FunctionalPermission.IocHunt}>
      <Can.Grant>
        <Page className='IOCParserPage'>
          <Typography variant='h1'>IOC Hunter</Typography>
          <IOCParserContainer>
            <IOCExtractContainer>
              <IOCExtractHeaderContainer>
                <Typography variant='subtitle1'>
                  Extract IOCs and hunt in your environment by using one of the mechanisms below.
                </Typography>
                <Fade in={isLoading} timeout={500}>
                  <Alert severity='info'>Extracting IOCs... </Alert>
                </Fade>
              </IOCExtractHeaderContainer>
              {linkSource && isEmpty(iocPayload) && (
                <>
                  {extractStatus === Status.resolved && (
                    <Alert severity='info'>
                      <AlertTitle>We were unable to scrape IOCs from this address.</AlertTitle>
                      <p>
                        Some web pages cannot be scraped through this mechanism - please copy and paste the IOCs into
                        the text editor directly.
                      </p>
                      <p>If this is a link to a PDF, use the Extract from PDF option.</p>
                    </Alert>
                  )}
                  {extractStatus === Status.rejected && (
                    <Alert severity='error'>We were unable to scrape IOCs with this input.</Alert>
                  )}
                </>
              )}
              <div className='scrape-row'>
                <div>
                  <Typography variant='subtitle1'>Paste in a link:</Typography>
                  <UnfurlTextField handleUnfurl={handleUnfurl} source={linkSource} setSource={setLinkSource} />
                </div>
                <div>
                  <Typography variant='subtitle1'>Upload PDF(s):</Typography>
                  <Formik {...standardFormikBaseProps} initialValues={{ attachment: [] }} onSubmit={NOOP}>
                    <StyledScrapeIOCUploadFieldFormik
                      setIocPayload={setIocPayload}
                      setText={setTextFromFile}
                      setIsActing={setIsUploading}
                    />
                  </Formik>
                </div>
              </div>
              <Typography variant='subtitle1'>Enter text directly:</Typography>
              <SyntaxEditor highlight={doHighlight} value={rawText} onChange={handleTextChange} />
            </IOCExtractContainer>
            <IOCTranslator
              iocs={iocPayload}
              isLoading={isUploading}
              title='Hunt for extracted IOCs'
              displayDefanged={false}
            />
          </IOCParserContainer>
        </Page>
      </Can.Grant>
      <Can.Block>
        <Redirect to={{ pathname: Path.Feed, search: `topic=${ArtifactType.Intel}` }} />
      </Can.Block>
    </Can>
  );
}
