import React from 'react';

import { faBan, faList, faPlay } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import { Link } from 'react-router-dom';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import Button, { ActionIconButton } from 'snap-ui/Button';
import Chip from 'snap-ui/Chip';
import CircularProgress from 'snap-ui/CircularProgress';
import { ConfirmDialog, DisplayDialog } from 'snap-ui/Dialog';
import Divider from 'snap-ui/Divider';
import Icon from 'snap-ui/Icon';
import KeyValueList from 'snap-ui/KeyValueList';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import DisabledButton from 'module/Widgets/DisabledButton';
import { AttackJobStateLabel } from 'module/Widgets/StateLabel';

import { Status } from 'storage';

import { BASJobResultStatus, BASJobState, ExecutionErrors } from 'types/bas';

import AgentMetadata from '../AgentMetadata';
import { Discriminator, ManualAgent } from '../BAS.type';
import { getTestCaseName } from '../BAS.util';
import { CustomLauncherButton, LauncherDialog, LauncherInterface } from '../Launcher/useLauncherInterface';
import { ColumnComponentProps } from './common';
import useCancelJob from './useCancelJob';

const DoneStates = [BASJobState.Completed, BASJobState.Failed, BASJobState.MissingPrereq] as Partial<BASJobState>[];

function LauncherButton({ disabled, onClick }: { disabled?: boolean; onClick?(): void }) {
  const button = (
    <ActionIconButton aria-label='Replay attack simulation' disabled={disabled} icon={faPlay} onClick={onClick} />
  );
  if (disabled) return button;
  return (
    <Tooltip arrow placement='top' title='Replay attack simulation'>
      {button}
    </Tooltip>
  );
}

export const ResultStatus = styled(Chip)`
  height: 1.55em;
  border-radius: 3px;

  &.${BASJobResultStatus.Success} {
    background-color: ${p => p.theme.palette.success.main};
    color: ${p => p.theme.palette.success.contrastText};
  }

  &.${BASJobResultStatus.Warning} {
    background-color: ${p => p.theme.palette.warning.main};
    color: ${p => p.theme.palette.warning.contrastText};
  }

  &.${BASJobResultStatus.Failure} {
    background-color: ${p => p.theme.palette.error.main};
    color: ${p => p.theme.palette.error.contrastText};
  }
`;

const ErrorWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: ${p => p.theme.spacing(2)};
`;

export function ValidationStatus({ executionErrors }: { executionErrors: ExecutionErrors }): JSX.Element {
  const [showDetails, setShowDetails] = React.useState(false);

  if (!executionErrors || !Object.keys(executionErrors).length) {
    return <ResultStatus className={BASJobResultStatus.Success} label='Execution Succeeded' />;
  }

  return (
    <ErrorWrapper>
      <ResultStatus className={BASJobResultStatus.Failure} label='Execution Failed' />
      <Button variant='text' color='error' onClick={() => setShowDetails(!showDetails)}>
        {showDetails ? 'Hide' : 'Show'} Details
      </Button>
      {showDetails && (
        <Alert severity='error'>
          <AlertTitle>Unexpected command output</AlertTitle>
          <KeyValueList data={executionErrors} />
        </Alert>
      )}
    </ErrorWrapper>
  );
}

// TODO - remove this when the ActionsColumn is replaced with a kebab menu
const ActionsColumnContainer = styled('div', { name: 'ActionsColumn' })`
  white-space: nowrap;
`;

function ActionsColumn({ disableReplay, refreshJobs, ...props }: ColumnComponentProps): JSX.Element {
  const discriminator = props?.job?.discriminator;
  const isManual = discriminator === Discriminator.manual;
  const isDone = DoneStates.includes(props.job.state) || isManual;
  const [showResultsModal, setShowResultsModal] = React.useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = React.useState(false);
  const { cancelJob, status: cancelStatus } = useCancelJob(props?.job?.guid);

  const { scripts } = React.useMemo(() => {
    return {
      scripts: props.job.script ? [props.job.script] : []
    };
  }, [props.job.script]);

  React.useEffect(() => {
    if (cancelStatus === Status.resolved) {
      setShowCancelConfirm(false);
      refreshJobs();
    }
  }, [cancelStatus, refreshJobs, setShowCancelConfirm]);

  const canCancel = props.job.state === BASJobState.Pending;

  const title = getTestCaseName(props?.job);
  const description = isManual ? props?.job.description : props?.job.script?.description;
  const command = isManual ? props?.job?.command_input : props?.job?.result?.command;
  const commandOutput = isManual ? props?.job?.command_output : props?.job?.result?.output;
  const manualAgent: ManualAgent = isManual
    ? {
        victim_hostname: props?.job?.victim_hostname,
        victim_ip: props?.job?.victim_ip,
        victim_operating_system: props?.job?.victim_operating_system,
        attacker_hostname: props?.job?.attacker_hostname,
        attacker_ip: props?.job?.attacker_ip
      }
    : null;

  return (
    <LauncherInterface>
      <ActionsColumnContainer>
        <Tooltip arrow placement='top' title='View details'>
          <ActionIconButton aria-label='View details' icon={faList} onClick={handleOpen} />
        </Tooltip>

        {!disableReplay && !isManual && <CustomLauncherButton component={LauncherButton} />}
        {!isManual && (
          <DisabledButton
            buttonComponent={ActionIconButton}
            ButtonProps={{
              onClick: () => setShowCancelConfirm(true),
              icon: faBan,
              variant: 'text'
            }}
            disabled={!canCancel}
            disabledTitle={`${startCase(props.job.state)} simulations cannot be canceled`}
            title='Cancel attack simulation'
          >
            <Icon icon={faBan} />
          </DisabledButton>
        )}
      </ActionsColumnContainer>
      <ConfirmDialog
        ConfirmProps={{
          children:
            cancelStatus === Status.pending ? <CircularProgress color='primary' size={25} /> : 'Cancel Simulation',
          disabled: cancelStatus === Status.pending,
          onClick: handleCancel
        }}
        DialogProps={{ open: showCancelConfirm, onClose: () => setShowCancelConfirm(false) }}
        title='Cancel attack simulation'
      >
        Are you sure you want to cancel the attack simulation?
      </ConfirmDialog>
      <DisplayDialog
        DialogProps={{ maxWidth: 'md', open: showResultsModal, onClose: handleClose }}
        title='Attack Simulation Result'
      >
        <div className={props.className}>
          <Typography variant='h2'>{title}</Typography>
          <p>{description}</p>
          {!isManual && (
            <p>
              Source: <Link to={`${Path.AttackScript}/${props?.job?.script?.guid}`}>{props?.job?.script?.name}</Link>
            </p>
          )}
          {isDone && (
            <>
              <Divider />
              <div className='detail-section'>
                <div className='job-meta'>
                  <Typography variant='h2'>Test Details</Typography>
                  <AgentMetadata agent={props.job.agent} isManual={isManual} manualAgent={manualAgent} />
                </div>
                {!isEmpty(props?.job?.execution_errors) ? (
                  <ValidationStatus executionErrors={props.job?.execution_errors} />
                ) : (
                  <AttackJobStateLabel value={props.job?.state} />
                )}
              </div>
              <Typography variant='h3'>Commands</Typography>
              <div className='output'>
                <pre>{command}</pre>
              </div>
              {!!props?.job?.result?.error && (
                <>
                  <Typography variant='h3'>Error</Typography>
                  <div className='output'>
                    <pre>{props?.job?.result?.error}</pre>
                  </div>
                </>
              )}
              {!!commandOutput && (
                <>
                  <Typography variant='h3'>Output</Typography>
                  <div className='output'>
                    <pre>{commandOutput}</pre>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </DisplayDialog>
      <LauncherDialog agent={props.job.agent} scripts={scripts} />
    </LauncherInterface>
  );

  function handleClose(): void {
    setShowResultsModal(false);
  }

  function handleOpen(): void {
    setShowResultsModal(true);
  }

  function handleCancel(): void {
    cancelJob();
  }
}

const StyledActionsColumn = styled(ActionsColumn)`
  hr {
    margin: ${p => p.theme.spacing(2, 0)};
  }

  .detail-section {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: ${p => p.theme.spacing(2)};
  }

  .job-meta {
    flex: 0 0 auto;
  }

  .output {
    margin: ${p => p.theme.spacing(4, 0)};
    padding: ${p => p.theme.spacing(4)};
    border-left: 2px solid ${p => p.theme.palette.primary.main};
    background: ${p => p.theme.palette.surface.pre};
    overflow-x: auto;
  }
`;

export default StyledActionsColumn;
