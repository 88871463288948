import React from 'react';

import { faCircle, faStar } from '@fortawesome/pro-solid-svg-icons';

import Icon from 'snap-ui/Icon';

import { Domino } from './Icon.style';

type Props = {
  className?: string;
};

function ValidatedAnalyticIcon({ className }: Props): React.ReactElement {
  return (
    <Domino className={className}>
      <Icon icon={faStar} color='error' />
      <Icon icon={faCircle} color='blue' />
    </Domino>
  );
}

export default ValidatedAnalyticIcon;
