import React from 'react';

import { faCircle, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

import Badge from 'snap-ui/Badge';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { ApiError } from 'module/ApiError';
import { StackedIcon } from 'module/Widgets/MarkerIcons/StackedIcon';

import { Meta } from 'storage';

import { SelectProps } from '.';
import { Select as MuiSelect } from './Select';

const Select = styled(MuiSelect)`
  .MuiSelect-select {
    padding: 0;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .Mui-disabled {
    color: grey;
  }
`;

const Error = styled(ApiError)`
  border: 0;
  padding: 0;
`;

export type SelectMutator = SelectProps & {
  busy: boolean;
  errorProps: Meta<unknown>['errorProps'];
};

/**p
 * Provides the styled things for inline mutating. BYOD
 */
export function SelectMutator(props: SelectMutator) {
  const { busy, className, errorProps, ...others } = props;

  return (
    <Badge
      className={className}
      invisible={!errorProps}
      badgeContent={
        <Tooltip title={<Error {...errorProps} />} placement='left' wrap arrow>
          <StackedIcon>
            <Icon icon={faCircle} size='xl' color='black' />
            <Icon icon={faExclamationCircle} size='xl' color='red' />
          </StackedIcon>
        </Tooltip>
      }
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <Select IconComponent={busy ? Icon.SpinnerProgress : undefined} {...others} />
    </Badge>
  );
}
