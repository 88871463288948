import { Guid } from 'types/common';

import { getLeaderboard } from './Training.api';
import { Game } from './Training.type';

export async function fetchLeaderboard(guid: Guid) {
  const data = await getLeaderboard(guid);
  // Sort by highest score, then earliest solve (if the same score)
  data
    .sort((a, b) => b.score - a.score || Date.parse(a.last_attempt) - Date.parse(b.last_attempt))
    .forEach((d, index) => {
      d.place = index + 1;
      d.id = d.user_name;
    });

  return data;
}

export async function fetchScoreboard(guid: Guid): Promise<Game> {
  const players = await getLeaderboard(guid);
  const challenges: string[] = [];
  // Sort by highest score, then earliest solve (if the same score)
  players
    .sort((a, b) => b.score - a.score || Date.parse(a.last_attempt) - Date.parse(b.last_attempt))
    .forEach(d => {
      d.id = d.user_name;
      d.challenges.forEach(c => !challenges.includes(c) && challenges.push(c));
    });

  // Otherwise our Challenge group column doesn't show at all
  if (challenges.length === 0) challenges.push(' ');

  return {
    players,
    challenges
  };
}

export async function fetchMyChallenges(guid: Guid, user_display_name: string) {
  const data = await getLeaderboard(guid);
  const filtered = data.filter(d => d.user_name === user_display_name);

  // This is janky; we don't have a user guid/ID in the API response and technically user display name is not guaranteed unique. Return nothing if we can't find a single user record.
  if (filtered.length !== 1) {
    return null;
  } else {
    return filtered[0];
  }
}
