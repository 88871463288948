import React from 'react';

import { Link } from 'react-router-dom';

import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';

import { useCannotRedirect } from 'aso/useCannotRedirect';

import useTitle from 'hooks/useTitle';

import { CollectionDiscriminator } from 'module/Collection/Collection.type';
import { collectionPath, detectionPath } from 'module/Feed/Feed.util';
import { Page } from 'module/Layout/Styled';

import { FunctionalPermission } from 'types/auth';

import HuntTable from './HuntTable';

export default function Hunt(): JSX.Element {
  useTitle('Hunt Results | SnapAttack');
  useCannotRedirect(FunctionalPermission.OpenSearchUIAccess);

  return (
    <Page>
      <Typography variant='h1'>Hunt Results</Typography>
      <p>
        Hunt for threats based on <Link to={collectionPath(CollectionDiscriminator.Actor)}>threat actors</Link>,{' '}
        <Link to={collectionPath(CollectionDiscriminator.Software)}>software</Link>,{' '}
        <Link to={collectionPath(CollectionDiscriminator.Vulnerability)}>vulnerabilities</Link>, or{' '}
        <Link to={collectionPath(CollectionDiscriminator.Attack)}>MITRE ATT&CK techniques</Link>; from a specific{' '}
        <Link to={collectionPath(CollectionDiscriminator.Static)}>collection</Link>; or from the{' '}
        <Link to={detectionPath()}>detection library</Link>. To start a hunt, click the <Icon.Menu /> and select
        &quot;Hunt Detections&quot;.
      </p>
      <HuntTable />
    </Page>
  );
}
