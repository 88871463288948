import React from 'react';

import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

import Icon from 'snap-ui/Icon';

import { StackedIcon } from 'module/Widgets/MarkerIcons/StackedIcon';

type Props = {
  className?: string;
  inheritBackground?: boolean;
};

function UnvalidatedAnalyticIcon({ className, inheritBackground }: Props): React.ReactElement {
  return (
    <StackedIcon className={classNames('non-domino-icon hollow-icon', className)}>
      <Icon icon={faCircleSolid} color={inheritBackground ? 'inherit' : 'secondary'} />
      <Icon icon={faCircle} color='blue' />
    </StackedIcon>
  );
}

export default UnvalidatedAnalyticIcon;
