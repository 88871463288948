import Toolbar from 'snap-ui/Toolbar';
import { styled } from 'snap-ui/util';

export const IDERoot = styled('div', { label: 'IDERoot' })`
  flex: 1 1 100%;
  overflow-y: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  margin: var(--Scaffold-spacing);

  .IDE-panelWrapper {
    display: grid;
    grid-template-columns: minmax(750px, 1fr) minmax(600px, 1000px);
    grid-template-rows: 100%;
    grid-gap: ${p => p.theme.spacing(4)};
    overflow-y: hidden;
  }

  @media (max-width: 1407px) {
    overflow: visible;

    .IDE-panelWrapper {
      grid-template-columns: 1fr;
      grid-template-rows: revert;
      margin-bottom: ${p => p.theme.spacing(9)};
    }

    .IDE-panel {
      width: 94vw;
    }
  }

  .ide-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${p => p.theme.spacing(4)};

    h1 {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      * + * {
        margin-left: ${p => p.theme.spacing(6)};
      }
    }

    .right-side {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: ${p => p.theme.spacing(6)};
    }
  }

  .ide-status {
    .status-alert {
      height: 40px;
      display: flex;
      gap: ${p => p.theme.spacing(3)};
      align-items: center;
    }
    .message {
      .button {
        display: inline-block;
        color: ${p => p.theme.palette.text.primary};
        text-decoration: underline;
      }
    }
  }
`;

export const IDEPanel = styled('div', { label: 'IDEPanel' })`
  border: 1px solid ${p => p.theme.palette.grey[600]};
  display: flex;
  flex-direction: column;

  &.stacked {
    border: 0;

    > div {
      border: 1px solid ${p => p.theme.palette.grey[600]};
    }
  }

  .MuiTabs-root {
    background-color: ${p => p.theme.palette.secondary.main};
  }

  .MuiTabs-flexContainer > button {
    min-width: 150px;
    font-size: 1.125rem;
    background-color: ${p => p.theme.palette.secondary.main};
    color: ${p => p.theme.palette.common.white};
    border: none;
  }

  .MuiTabs-flexContainer > button.Mui-selected {
    background-image: unset;
  }

  .IDE-panelContent {
    flex: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    border-radius: 0;
  }

  .IDE-translate {
    margin-top: ${p => p.theme.spacing(4)};
  }

  .TranslateAnalytic {
    border: none;
    overflow: hidden;
  }

  .TranslateAnalytic-codeContainer {
    min-height: 100px;
    max-height: 400px;
  }

  .TranslateAnalytic-languageToolbar {
    justify-content: flex-start;
  }
`;

export const IDEToolbar = styled(Toolbar, { shouldForwardProp: p => p === 'children' })``;
