import React from 'react';

import { faAndroid, faAppStoreIos, faApple, faLinux, faWindows } from '@fortawesome/free-brands-svg-icons';
import { faClone } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightLong, faCircleNodes, faCloud, faEdit, faSquareTerminal } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import Chip from 'snap-ui/Chip';
import { BuffDivider } from 'snap-ui/Divider';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { getArtifactIcon } from 'module/Layout/Artifact.helper';
import { ArtifactWidgetMenuWrapper, BurgerClicker, Menu, Title } from 'module/Layout/Artifact.widgets';
import { Discriminator } from 'module/Tag';

import { useAuth } from 'provider';

import { Artifact, ArtifactType } from 'types/common';

import { sortByPrioritizedList } from 'utilities/ArrayUtils';

import { CVSSContainer, CategoryContainer, CategorySection, SubTitle } from './Landing.style';
import { HyperTag, LandingCategoryDetail } from './Landing.type';
import { getLandingName, getSeverityLevel, isMutable } from './Landing.util';
import { useLandingCatalog } from './LandingProvider';

export function LandingTitle() {
  const { expandedName } = useLandingCatalog();
  return <Title headerText={expandedName} contentType={ArtifactType.Collection} className='ArtifactWidget-title' />;
}

export function LandingMenu() {
  const { user } = useAuth();
  const { type, expandedName, handleExtends } = useLandingCatalog();
  if (!isMutable(type) || !user.superuser) return null;
  return (
    <ArtifactWidgetMenuWrapper>
      <Menu>
        <BurgerClicker icon={faEdit} title={`Edit ${expandedName}`} onClick={handleExtends} />
      </Menu>
    </ArtifactWidgetMenuWrapper>
  );
}

export function PlatformIcon({ platform }: { platform: string }): JSX.Element {
  const icon =
    platform === 'Linux'
      ? faLinux
      : platform === 'macOS'
      ? faApple
      : platform === 'Windows'
      ? faWindows
      : platform === 'Unix'
      ? faSquareTerminal
      : platform === 'Android'
      ? faAndroid
      : platform === 'iOS'
      ? faAppStoreIos
      : platform === 'VMware'
      ? faClone
      : platform === 'Office 365'
      ? faCloud
      : platform === 'Azure AD'
      ? faCircleNodes
      : null;
  return icon ? (
    <Tooltip key={platform} title={platform} arrow placement='top' wrap>
      <Icon icon={icon} />
    </Tooltip>
  ) : (
    <>{platform}</>
  );
}

const PlatformIconsContainer = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(2)};
`;
const PlatformPriorityOrder = ['windows', 'linux', 'macos', 'android', 'ios'];

export const PlatformIcons = React.forwardRef<HTMLDivElement, { className?: string; platforms: HyperTag['platforms'] }>(
  function PlatformIcons({ className, platforms, ...others }, ref) {
    return (
      <PlatformIconsContainer className={classNames('PlatformIcons', className)} ref={ref} {...others}>
        {platforms
          ?.slice()
          .sort(sortByPrioritizedList(PlatformPriorityOrder))
          ?.map(p => (
            <PlatformIcon key={p} platform={p} />
          ))}
      </PlatformIconsContainer>
    );
  }
);

export const AliasOf = styled((props: { className?: string; linkable?: boolean }) => {
  const { className, linkable } = props;
  const { aliases: names, type, handleReset } = useLandingCatalog();
  if (isEmpty(names)) return null;
  return (
    <div className={classNames('AliasOf', className)}>
      <Typography component='span' variant='body2' className='AliasOf-text sa-no-wrap'>
        Alias of
      </Typography>{' '}
      <Icon icon={faArrowRightLong} />
      <div className='AliasOf-aliases'>
        {!linkable &&
          names.map(n => (
            <Chip key={n} label={n} className='LandingSection-chip aliases' icon={<Icon.Alias color='blue' />} />
          ))}
        {linkable &&
          names.map(n => (
            <Chip
              key={n}
              label={n}
              className={classNames('LandingSection-chip', type)}
              component={Link}
              clickable
              to={`${Path.Collection}/${type}/${encodeURIComponent(n)}`}
              onClick={handleReset}
              icon={getArtifactIcon(type as Discriminator, true)}
            />
          ))}
      </div>
    </div>
  );
})`
  .AliasOf-text {
    line-height: 1.5;
  }

  .AliasOf-aliases {
    display: flex;
    flex-wrap: wrap;
    gap: ${p => p.theme.spacing(2)};
  }
`;

export function FriendlyLandingType({ item }: { item: Artifact }): JSX.Element {
  switch (item.type) {
    case Discriminator.Attack:
      return <>ATT&CK</>;
    default:
      return <>{item.type}</>;
  }
}

export function LandingLink({
  asId,
  data,
  onClick
}: {
  asId?: boolean;
  data: Partial<HyperTag>;
  onClick(e: React.MouseEvent): void;
}) {
  return (
    <Link to={`${Path.Collection}/${data.discriminator}/${encodeURIComponent(data.name)}`} onClick={onClick}>
      {getLandingName(asId, data)}
    </Link>
  );
}

export function LandingTarget({ asId, data }: { asId?: boolean; data: Partial<HyperTag> }) {
  return <>{getLandingName(asId, data)}</>;
}

export function LandingRootName({
  sourceId,
  ...props
}: {
  sourceId: string;
  asId?: boolean;
  data: Partial<HyperTag>;
  onClick(e: React.MouseEvent): void;
}) {
  return props.data.external_source_id === sourceId ? <LandingTarget {...props} /> : <LandingLink {...props} />;
}

export function CategoryDetail({ title, detail }: { title: string | JSX.Element; detail: LandingCategoryDetail[] }) {
  return (
    <CategorySection>
      <Typography variant='h4'>{title}</Typography>
      <CategoryContainer className='row'>
        {detail.map(content => (
          <div key={content.name} className='category-detail-item'>
            <SubTitle variant='subtitle2'>{content.name}</SubTitle>
            <Typography variant='h5' className='sa-cap'>
              {content.value}
            </Typography>
          </div>
        ))}
      </CategoryContainer>
    </CategorySection>
  );
}

export function Cvss3Card() {
  const { source } = useLandingCatalog();
  if (!source.cvss_3_base_score) return null;

  const cvss3Score = getSeverityLevel(source.cvss_3_base_score);
  return (
    <CVSSContainer>
      <Typography variant='h4'>CVSS v3.1 Base</Typography>
      <BuffDivider />
      <div className='cvssCard-detail'>
        <div>
          <Typography variant='subtitle2'>SCORE</Typography>
          <Typography variant='h3' className={cvss3Score}>
            {source.cvss_3_base_score} ({cvss3Score})
          </Typography>
          <Typography variant='subtitle2'>
            {source.cvss_3_vector}
            {source.cvss_3_vector}
          </Typography>
        </div>
        <div className='score-detail-container'>
          <div>
            <SubTitle variant='subtitle2'>Attack Vector</SubTitle>
            <Typography variant='h4'>{source.cvss_3_vector_details.attack_vector}</Typography>
          </div>
          <div>
            <SubTitle variant='subtitle2'>Scope</SubTitle>
            <Typography variant='h4'>{source.cvss_3_vector_details.scope}</Typography>
          </div>
          <div>
            <SubTitle variant='subtitle2'>Attack Complexity</SubTitle>
            <Typography variant='h4'>{source.cvss_3_vector_details.attack_complexity}</Typography>
          </div>
          <div>
            <SubTitle variant='subtitle2'>Confidentiality Impact</SubTitle>
            <Typography variant='h4'>{source.cvss_3_vector_details.confidentiality_impact}</Typography>
          </div>
          <div>
            <SubTitle variant='subtitle2'>Privileges Required</SubTitle>
            <Typography variant='h4'>{source.cvss_3_vector_details.privileges_required}</Typography>
          </div>
          <div>
            <SubTitle variant='subtitle2'>Integrity Impact</SubTitle>
            <Typography variant='h4'>{source.cvss_3_vector_details.integrity_impact}</Typography>
          </div>
          <div>
            <SubTitle variant='subtitle2'>User Interaction</SubTitle>
            <Typography variant='h4'>{source.cvss_3_vector_details.user_interaction}</Typography>
          </div>
          <div>
            <SubTitle variant='subtitle2'>Availability Impact</SubTitle>
            <Typography variant='h4'>{source.cvss_3_vector_details.availability_impact}</Typography>
          </div>
        </div>
      </div>
    </CVSSContainer>
  );
}

export function Cvss2Card() {
  const { source } = useLandingCatalog();
  if (!source.cvss_2_base_score) return null;

  const cvss2Score = getSeverityLevel(source.cvss_2_base_score);
  return (
    <CVSSContainer>
      <Typography variant='h4'>CVSS v2.0 Base</Typography>
      <BuffDivider />
      <div className='cvssCard-detail'>
        <div>
          <SubTitle variant='subtitle2'>SCORE</SubTitle>
          <Typography variant='h3' className={cvss2Score}>
            {source.cvss_2_base_score} ({cvss2Score})
          </Typography>
          <Typography variant='subtitle2'>{source.cvss_2_vector}</Typography>
        </div>
        <div className='score-detail-container'>
          <div>
            <SubTitle variant='subtitle2'>Access Vector</SubTitle>
            <Typography variant='h4'>{source.cvss_2_vector_details.access_vector}</Typography>
          </div>
          <div>
            <SubTitle variant='subtitle2'>Confidentiality Impact</SubTitle>
            <Typography variant='h4'>{source.cvss_2_vector_details.confidentiality_impact}</Typography>
          </div>
          <div>
            <SubTitle variant='subtitle2'>Access Complexity</SubTitle>
            <Typography variant='h4'>{source.cvss_2_vector_details.access_complexity}</Typography>
          </div>
          <div>
            <SubTitle variant='subtitle2'>Integrity Impact</SubTitle>
            <Typography variant='h4'>{source.cvss_2_vector_details.integrity_impact}</Typography>
          </div>
          <div>
            <SubTitle variant='subtitle2'>Authentication</SubTitle>
            <Typography variant='h4'>{source.cvss_2_vector_details.authentication}</Typography>
          </div>
          <div>
            <SubTitle variant='subtitle2'>Availability Impact</SubTitle>
            <Typography variant='h4'>{source.cvss_2_vector_details.availability_impact}</Typography>
          </div>
        </div>
      </div>
    </CVSSContainer>
  );
}
