import React from 'react';

import { faFlaskGear } from '@fortawesome/pro-regular-svg-icons';

import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { SimpleBadge } from '../Badge';

const Badge = styled(SimpleBadge)`
  min-width: 120px;
`;

const SIMULATED_LABEL = 'SIMULATED';

export function SimulatedBadge({ simulated }: { simulated: boolean }): React.ReactElement {
  if (!simulated) return null;
  return (
    <Tooltip
      title='Simulated attacks can validate detections without running malicious code'
      arrow
      placement='top'
      wrap
    >
      <Badge icon={<Icon icon={faFlaskGear} />} value={SIMULATED_LABEL} />
    </Tooltip>
  );
}
