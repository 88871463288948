import React from 'react';

import { useField } from 'formik';

import FormControl, { FormControlProps, FormHelperText } from 'snap-ui/FormControl';
import { InputLabel } from 'snap-ui/InputLabel';
import { Select, SelectProps } from 'snap-ui/Select';

type SelectFormikProps = Omit<SelectProps, 'children' | 'onChange' | 'options' | 'value'> & {
  name: string;
  onChange?(value: string): void;
  elevated?: boolean | number;
  options: SelectProps['options'];
};

export default function SelectFormik(props: SelectFormikProps): JSX.Element {
  const { elevated, onChange, sx, ...others } = props;
  const [field, meta, helpers] = useField(others.name);
  const hasError = !!(meta.touched && meta.error);

  const handleChange = (value: string) => {
    helpers.setValue(value);
    helpers.setTouched(true, false);
    onChange && onChange(value);
  };

  const handleBlur = () => helpers.setTouched(true);

  let formControl: FormControlProps;
  let helperText: string;
  if (hasError) {
    formControl = { error: true };
    helperText = meta.error;
  }

  const uix: SelectProps['sx'] = {
    ...sx,
    boxShadow: typeof elevated === 'number' ? elevated : 6,
    backgroundColor: 'hsl(0, 0%, 7.1%);',
    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))'
  };

  return (
    <FormControl fullWidth variant='outlined' {...formControl}>
      {!!others.label && <InputLabel>{others.label}</InputLabel>}
      <Select value={field.value} onBlur={handleBlur} onChange={handleChange} sx={uix} {...others} />
      {!!helperText && <FormHelperText className='SelectFormik-helperText'>{helperText}</FormHelperText>}
    </FormControl>
  );
}
