import React from 'react';

import { faFilePdf } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';

import BackdropLoader from 'snap-ui/BackdropLoader';
import Divider from 'snap-ui/Divider';
import Link from 'snap-ui/Link';
import Placeholder from 'snap-ui/Placeholder';

import useAgents from 'aso/useAgents';

import Path from 'constants/paths';

import AgentLabel from 'module/BAS/AgentLabel';
import { BASCampaign } from 'module/BAS/BAS.type';
import { exportToPDF, PDFExportProps } from 'module/Dashboard/core/Dashboard.helper';
import { EXPORT_FORMAT } from 'module/Dashboard/core/Dashboard.util';
import { useBasExportInterface } from 'module/Export';
import { BurgerClicker, Menu } from 'module/Layout/Artifact.widgets';
import ContentHeader from 'module/Widgets/ContentHeader';
import { MarkdownRead } from 'module/Widgets/Markdown';
import { AttackJobStateLabel } from 'module/Widgets/StateLabel';

import { Status } from 'storage';

import { BASJobState } from 'types/bas';
import { Ops } from 'types/filter';

import { formatCustomNow, formatShortTimestamp } from 'utilities/TimeUtils/TimeUtils';

import { getLinkedArtifactIcon, getLinkPath } from '../BAS.util';
import {
  CampaignActionInterface,
  CancelDialog,
  CancelMenuItem,
  DeleteDialog,
  DeleteMenuItem,
  RerunDialog,
  RerunMenuItem
} from '../CampaignReport/useCampaignActionInterface';
import { BASReportType } from './CampaignReport';
import useCampaignUtils from './useCampaignUtils';

type CampaignReportHeaderProps = {
  campaign: BASCampaign;
  isLoading: boolean;
  exportingReportType: BASReportType;
  setExportingReportType?: (exportingReportType: BASReportType) => void;
  pdfExportProps?: PDFExportProps;
  refresh(): void;
};

export default function CampaignReportHeader({
  setExportingReportType,
  pdfExportProps,
  ...props
}: CampaignReportHeaderProps): JSX.Element {
  return (
    <>
      <CampaignContentArtifact {...props} />
      <CampaignContentStatus {...props} />
      <CampaignContentHeader {...props} />
      <CampaignReportHeaderDescription {...props} />
      <CampaignReportHeaderDetails {...props} />
      <CampaignReportHeaderMenu
        {...props}
        setExportingReportType={setExportingReportType}
        pdfExportProps={pdfExportProps}
      />
    </>
  );
}

function CampaignContentStatus({ campaign, isLoading }: CampaignReportHeaderProps): JSX.Element {
  return (
    <div className='CampaignReportHeader-status no-print'>
      {isLoading && !campaign.state ? (
        <Placeholder variant='rectangular' height={20} width={100} />
      ) : (
        <AttackJobStateLabel value={campaign.state} />
      )}
    </div>
  );
}

function CampaignContentHeader({ campaign, isLoading }: CampaignReportHeaderProps): JSX.Element {
  const title = campaign.name || 'Attack Simulation Result';

  return (
    <ContentHeader
      className='CampaignReportHeader-title'
      headerText={title}
      showPlaceholder={isLoading && !campaign.name}
    />
  );
}

function CampaignContentArtifact({ campaign, isLoading }: CampaignReportHeaderProps): JSX.Element {
  const linkPath = getLinkPath(campaign);
  const iconComponent = getLinkedArtifactIcon(campaign);

  return (
    <div className='CampaignReportHeader-artifact'>
      {isLoading ? (
        <div>
          <Placeholder variant='text' height={20} width={80} />
        </div>
      ) : (
        <>
          {linkPath && (
            <Link to={linkPath}>
              {iconComponent}
              {campaign.linked_artifact.name}
            </Link>
          )}
        </>
      )}
    </div>
  );
}

function CampaignReportHeaderDescription({ campaign, isLoading }: CampaignReportHeaderProps): JSX.Element {
  return isLoading ? (
    <div className='CampaignReportHeader-description'>
      <Placeholder variant='rectangular' height={40} width='100%' />
    </div>
  ) : campaign.linked_artifact.description ? (
    <div className='CampaignReportHeader-description'>
      <MarkdownRead value={campaign.linked_artifact.description} />
    </div>
  ) : null;
}

function CampaignReportHeaderDetails({ campaign, isLoading }: CampaignReportHeaderProps): JSX.Element {
  const isPreloaded = !isEmpty(campaign.agents) && campaign.last_execution;
  return isLoading && !isPreloaded ? (
    <div className='CampaignReportHeader-details'>
      <div className='AgentLabels'>
        <Placeholder variant='text' height={20} width={80} />
      </div>
      <div className='execution-times'>
        <div>
          <Placeholder variant='text' height={20} width={80} />
        </div>
        <Placeholder variant='text' height={20} width={80} />
        <div>
          <Placeholder variant='text' height={20} width={80} />
        </div>
        <Placeholder variant='text' height={20} width={80} />
      </div>
    </div>
  ) : (
    <div className='CampaignReportHeader-details'>
      <div className='AgentLabels no-print'>
        {campaign.agents?.map((agent: BASCampaign['agents'][number], idx) => (
          <AgentLabel key={agent.os_name + idx} agent={agent} />
        ))}
      </div>
      <div className='execution-times'>
        <div>Start Time:</div>
        <span>{formatShortTimestamp(campaign?.creation)}</span>
        {campaign.last_execution && (
          <>
            <div>End Time: </div>
            <span>{formatShortTimestamp(campaign.last_execution)}</span>
          </>
        )}
      </div>
    </div>
  );
}

function CampaignReportHeaderMenu({
  campaign,
  isLoading,
  exportingReportType,
  setExportingReportType,
  pdfExportProps,
  refresh
}: CampaignReportHeaderProps): JSX.Element {
  const { push } = useHistory();
  const todaysDate = formatCustomNow(EXPORT_FORMAT);
  const campaignInterface = useCampaignUtils(campaign.guid);
  const agentInterface = useAgents();
  const [BasExportButton, BasExportDialog] = useBasExportInterface();

  const rerunSuccess = newCampaign => push(`${Path.BASJob}/${newCampaign.guid}`);
  const cancelSuccess = refresh;
  const deleteSuccess = () => push(Path.BASJob);

  const exportPDF = React.useCallback(
    async (reportType: BASReportType) => {
      await exportToPDF({
        ...pdfExportProps,
        fileName: `${campaign.name} ${reportType}_${todaysDate}.pdf`
      });
    },
    [campaign.name, pdfExportProps, todaysDate]
  );

  function handleExport(reportType: BASReportType) {
    setExportingReportType(reportType);
  }

  React.useEffect(() => {
    if (exportingReportType) {
      exportPDF(exportingReportType).then(() => setExportingReportType(null));
    }
  }, [exportPDF, exportingReportType, setExportingReportType]);

  return isLoading ? (
    <div className='CampaignReportHeader-menu'>
      <Placeholder variant='circular' height={40} width={40} />
    </div>
  ) : (
    <>
      <CampaignActionInterface
        agentInterface={agentInterface}
        campaign={campaign}
        campaignInterface={campaignInterface}
      >
        <div className='CampaignReportHeader-menu no-print'>
          <Menu>
            <BurgerClicker
              icon={faFilePdf}
              onClick={() => handleExport(BASReportType.DetailedReport)}
              title={`Export ${BASReportType.DetailedReport}`}
            />
            <BurgerClicker
              icon={faFilePdf}
              onClick={() => handleExport(BASReportType.ExecutiveSummary)}
              title={`Export ${BASReportType.ExecutiveSummary}`}
            />
            <BasExportButton />
            <Divider />
            <RerunMenuItem />
            <CancelMenuItem disabled={campaign.state === BASJobState.Canceled} />
            <DeleteMenuItem />
          </Menu>
        </div>
        <RerunDialog onSuccess={rerunSuccess} />
        <CancelDialog onSuccess={cancelSuccess} />
        <DeleteDialog onSuccess={deleteSuccess} />
        <BackdropLoader open={campaignInterface.status === Status.pending} title='Loading...' fixed />
        <BasExportDialog filter={{ field: 'bas_campaign_id', op: Ops.equals, value: campaign.id }} />
      </CampaignActionInterface>
    </>
  );
}
