import React from 'react';

import { ExternalLink } from 'snap-ui/Link';

import { Discriminator } from 'module/Tag';

import { useLandingCatalog } from '../LandingProvider';
import LandingMandiantLogo from './MandiantLogo';

function getMandiantLink(type: Discriminator) {
  switch (type) {
    case Discriminator.Actor:
      return 'actors/';
    case Discriminator.Vulnerability:
      return 'vulnerabilities/';
    case Discriminator.Software:
      return 'malware/';
  }
}

const VALID_DISCRIMINATORS = [Discriminator.Actor, Discriminator.Software, Discriminator.Vulnerability];

export default function MandiantLogoLink() {
  const { data, type } = useLandingCatalog();
  const externalSourceId = data?.items?.find(item => item.external_source === 'mandiant')?.external_source_id;

  if (!externalSourceId) return null;

  return (
    <div className='collection-header-detail-container'>
      {VALID_DISCRIMINATORS.some(discriminator => discriminator === type) ? (
        <ExternalLink
          className='mandiant-link'
          href={`https://advantage.mandiant.com/${getMandiantLink(type)}${externalSourceId}`}
        >
          <LandingMandiantLogo />
        </ExternalLink>
      ) : (
        <LandingMandiantLogo />
      )}
    </div>
  );
}
