import React from 'react';

import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';

import { CategoryContainer, SubTitle } from '../../Landing.style';
import { useLandingCatalog } from '../../LandingProvider';

export default function EPSS() {
  const { source } = useLandingCatalog();
  if (!source.epss_score) return null;

  return (
    <CategoryContainer>
      <Typography variant='h4'>Exploit Prediction Scoring System (EPSS)</Typography>
      <CategoryContainer className='row'>
        <div className='category-detail-item'>
          <Tooltip
            arrow
            placement='top'
            title='EPSS Score provides a probability [0-1] of observing exploitation activity in the next 30 days.'
          >
            <SubTitle className='epss-title' variant='subtitle2'>
              EPSS Score <Icon.Info />
            </SubTitle>
          </Tooltip>
          <Typography variant='h5' className='sa-cap'>
            {source.epss_score}
          </Typography>
        </div>
        <div className='category-detail-item'>
          <Tooltip
            arrow
            placement='top'
            title='EPSS Percentile is a rank ordering of probabilities from highest to lowest based on every published CVE. A vulnerability within the 92% EPSS Percentile means that 92% of all CVEs are scored lower; in other words, this vulnerability is in the top 8%.'
          >
            <SubTitle className='epss-title' variant='subtitle2'>
              EPSS Percentile <Icon.Info />
            </SubTitle>
          </Tooltip>
          <Typography variant='h5' className='sa-cap'>
            {(100 * source.epss_percentile).toFixed(3) + '%'}
          </Typography>
        </div>
      </CategoryContainer>
    </CategoryContainer>
  );
}
