import React from 'react';

import { Alert, AlertTitle } from 'snap-ui/Alert';

import { useAuth } from 'provider';

/**
 * @deprecated - use withFunctionalPermission
 */
// eslint-disable-next-line react/display-name
const withSubscriberOnly = WrappedComponent => props => {
  const { isSubscriber } = useAuth();
  return isSubscriber ? (
    <WrappedComponent {...props} />
  ) : (
    <Alert severity='info' width='max-content'>
      <AlertTitle>This item is only available to subscribers</AlertTitle>
      Please contact{' '}
      <a href='mailto:sales@snapattack.com' target='_blank' rel='noreferrer' className='mailto'>
        sales@snapattack.com
      </a>{' '}
      to discuss subscription plans.
    </Alert>
  );
};

export default withSubscriberOnly;
