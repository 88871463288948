import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { getFields } from 'apis/resources/analytic';

import { useAuth } from 'provider/Account';

import { Status, useAsync } from 'storage';

import { LogsourceSource } from 'types/analytic';

type Context = {
  data: LogsourceSource[];
  refresh: () => void;
  status: Status;
};

const LogsourceContext = React.createContext<Context>(null);
LogsourceContext.displayName = 'LogsourceContext';

/**
 * This hook will return a very large set of data. Rendering a subset of the data
 * returned here is highly recommended.
 *
 * @returns { data: LogsourceSource[]; refresh: () => void; }
 */
function useLogsourceContext(): Context {
  const context = React.useContext(LogsourceContext);

  if (!context) {
    throw new Error('useLogsource must be used within the LogsourceContext');
  }

  return context;
}

function LogsourceProvider({
  children,
  value = []
}: {
  children: React.ReactNode;
  value?: LogsourceSource[];
}): React.ReactElement {
  const timeoutRef = React.useRef<NodeJS.Timeout>();
  const { user } = useAuth();
  const { data, run, status } = useAsync<LogsourceSource[]>(value);
  const refresh = React.useCallback(() => run(getFields()), [run]);
  const dataString = JSON.stringify(data);

  const payload = React.useMemo(() => {
    return { data, refresh };
    // eslint-disable-next-line
  }, [refresh, dataString]);

  React.useEffect(() => {
    if (user.id && isEmpty(data)) {
      timeoutRef.current = setTimeout(() => refresh(), 1500);
    }

    return (): void => clearTimeout(timeoutRef.current);
    // eslint-disable-next-line
  }, [refresh, user]);

  return <LogsourceContext.Provider value={{ ...payload, status }}>{children}</LogsourceContext.Provider>;
}

export { LogsourceProvider, useLogsourceContext };
