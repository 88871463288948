import { snapattack } from 'apis/snapattack';

import { Page } from 'module/Feed/Feed.type';

import {
  BASAgent,
  BASAgentEditableFields,
  BASJobPayload,
  BASTool,
  BulkLaunchBASFormValues,
  LaunchBASFormValues
} from 'types/bas';
import { Artifact, Guid, PageParams } from 'types/common';
import { Query } from 'types/filter';

import {
  BASCampaign,
  BASCampaignFeed,
  BASCampaignPost,
  BASCampaignPreviewPayload,
  BASCampaignPreviewResponse,
  BASCampaignSupplemental,
  BASJob,
  BASManualTest,
  DetermineOutcomesStatus
} from './BAS.type';

export function basCampaignFeed(params: PageParams, payload: Query): Promise<Page<BASCampaignFeed>> {
  return snapattack.post('/search/bas/campaign/cache/v2/', payload, { params }).then(r => r.data);
}

export function basJobFeed(params: PageParams, payload: Query): Promise<Page<BASJob>> {
  return snapattack.post('/search/bas/job/', payload, { params }).then(r => r.data);
}

export function basJobManualTest(payload: BASManualTest): Promise<BASCampaign> {
  return snapattack.post('/bas/job/manual/', payload).then(r => r.data);
}

export function putBASJob(guid: Guid, payload: Partial<BASJobPayload>): Promise<BASJob> {
  return snapattack.put(`/bas/job/${guid}/`, payload).then(r => r.data);
}

export function getCampaign(guid: Guid): Promise<BASCampaign> {
  return snapattack.get(`/bas/campaign/${guid}/`).then(r => r.data);
}

export function postCampaignSupplemental(payload: Guid[]): Promise<BASCampaignSupplemental[]> {
  return snapattack.post(`/search/bas/campaign/supplemental/`, payload).then(r => r.data);
}

export function postCampaignPreview(payload: BASCampaignPreviewPayload): Promise<BASCampaignPreviewResponse> {
  return snapattack.post('/bas/campaign/preview/', payload).then(r => r.data);
}

export function postCampaign(payload: BASCampaignPost): Promise<BASCampaign> {
  return snapattack.post('/bas/campaign/', payload).then(r => r.data);
}

export function cancelCampaign(guid: Guid): Promise<boolean> {
  return snapattack.delete(`/bas/campaign/${guid}/cancel/`).then(r => r.data);
}

export function cloneCampaign(guid: Guid, payload: BulkLaunchBASFormValues): Promise<BASCampaign> {
  return snapattack.post(`/bas/campaign/${guid}/clone/`, payload).then(r => r.data);
}

export function deleteCampaign(guid: Guid): Promise<boolean> {
  return snapattack.delete(`/bas/campaign/${guid}/`).then(r => r.data);
}

export function postDetermineOutcomes(campaign_id: Guid, payload: { integrations: string[] }): Promise<void> {
  return snapattack.post(`/bas/campaign/${campaign_id}/determine_outcomes/`, payload).then(r => r.data);
}

export function getDetermineOutcomesStatus(campaign_id: Guid): Promise<DetermineOutcomesStatus> {
  return snapattack.get(`/bas/campaign/${campaign_id}/determine_outcomes/status/`).then(r => r.data);
}

export function getCampaignRecommend(campaign_id: Guid): Promise<Artifact[]> {
  return snapattack.get(`/bas/campaign/${campaign_id}/recommend/`).then(r => r.data);
}

/*
 * You probably want to use the useAgents hook instead
 */
export function getAgentList(): Promise<BASAgent[]> {
  return snapattack.get('/bas/agent/').then(r => r.data);
}

/*
 * You probably want the useBASTools provider instead
 */
export function getBASTools(): Promise<BASTool[]> {
  return snapattack.get('/bas/tools/').then(r => r.data);
}

export function postBASJob(payload: LaunchBASFormValues): Promise<void> {
  return snapattack.post('/bas/job/', payload);
}

export function deleteBASJob(jobGuid: Guid): Promise<void> {
  return snapattack.delete(`/bas/job/${jobGuid}/`);
}

export function putAgent(agentId: string, values: BASAgentEditableFields): Promise<void> {
  return snapattack.put(`/bas/agent/${agentId}/`, values);
}

export function deleteAgent(agentId: string): Promise<void> {
  return snapattack.delete(`/bas/agent/${agentId}/`);
}
