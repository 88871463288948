import yaml from 'js-yaml';
import { z } from 'zod';

import { ConfigType } from 'types/analytic';

import { simpleParse } from 'utilities/YamlUtils';

export { YAMLException } from 'js-yaml';
export type ParseError = yaml.YAMLException | SigmaConfigParseError;

export enum SigmaConfigOrder {
  Generic = '10',
  Specific = '20'
}

const intToString = z
  .number()
  .int()
  .transform(v => v.toString())
  .nullable();

const LogsourceSchema = z.object({
  category: z.string().optional(),
  product: z.string().optional(),
  service: z.string().optional()
});

const SigmaConfigSchema = z
  .object({
    title: z.string().nullable(),
    order: intToString,
    defaultindex: z.string().nullable(),
    backends: z.array(z.string()).nullable(),
    logsources: z.record(LogsourceSchema).optional(),
    fieldmappings: z.record(z.any()).optional(),
    unsupported_fields: z.record(z.string()).optional(),
    optional_fields: z.array(z.string()).optional(),
    detection_hit_extractions: z.record(z.any()).optional(),
    included_logsources: z.array(z.record(z.any())).optional(),
    excluded_logsources: z.array(z.record(z.any())).optional(),
    excluded_values: z.array(z.record(z.any())).optional()
  })
  .partial();

export class SigmaConfigParseError extends Error {}

export function simpleConfigParse(configYaml: string): [any, yaml.YAMLException?] {
  return simpleParse<any>(configYaml);
}

const SigmaTransformationItem = z.any();

const SigmaPipelineSchema = z.object({
  name: z.string(),
  priority: intToString,
  allowed_backends: z.array(z.string()).optional(),
  transformations: z.array(SigmaTransformationItem).optional(),
  postprocessing: z.array(SigmaTransformationItem).optional(),
  finalizers: z.array(SigmaTransformationItem).optional()
});

const SigmaValidatorSchema = z.object({
  name: z.string(),
  validators: z.array(z.string()),
  exclusions: z.record(z.union([z.array(z.string()), z.string()])).optional()
});

function getSchema(discriminator: ConfigType): z.ZodSchema {
  switch (discriminator) {
    case ConfigType.Pipeline:
      return SigmaPipelineSchema;
    case ConfigType.Validator:
      return SigmaValidatorSchema;
    case ConfigType.Legacy:
    default:
      return SigmaConfigSchema;
  }
}

export const SigmaConfigYamlSchema = z
  .object({
    organization_id: z.number().int(),
    discriminator: z.nativeEnum(ConfigType),
    raw: z.string()
  })
  .superRefine(({ discriminator, raw }, ctx) => {
    try {
      var loaded = yaml.load(raw);
    } catch (syntaxError) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, message: syntaxError.message, path: ['raw'] });
    }

    if (loaded) {
      const schema = getSchema(discriminator);
      try {
        schema.parse(loaded);
      } catch (schemaError) {
        (schemaError as z.ZodError).issues.forEach(issue => {
          ctx.addIssue({ ...issue, path: ['raw', ...issue.path] });
        });
      }
    }
  });

/**
 * interpolate a config builder form into a sigma config yaml
 * actually it just dumps values to yaml since we don't have a config builder form
 */
export function interpolateSigmaConfig(configForm: any): string {
  return yaml.dump(configForm);
}
