import React from 'react';

import PieChart from 'snap-ui/Charts/PieChart';

import { BASCampaign } from 'module/BAS/BAS.type';

import { getFlatObjectCount } from '../../BAS.util';
import { PieChartDefaultProperties } from '../CampaignReport.style';
import ReportPanel from './ReportPanel';

type ControlsPanelProps = {
  campaign: BASCampaign;
};

const DATA_EMPTY_MESSAGE = 'Please add outcomes below.';

function ControlsPanel({ campaign }: ControlsPanelProps) {
  const controlsEmpty = getFlatObjectCount(campaign.tool_outcomes) === 0;

  const controlsChartData = React.useMemo(() => {
    if (!campaign.tool_outcomes) return [];
    return Object.entries(campaign.tool_outcomes).map(([tool, value]) => ({
      name: tool,
      value
    }));
  }, [campaign.tool_outcomes]);

  return (
    <ReportPanel title='Controls' hasError={controlsEmpty} error={DATA_EMPTY_MESSAGE}>
      <PieChart
        data={controlsChartData}
        {...PieChartDefaultProperties}
        legendProps={{
          layout: 'horizontal',
          verticalAlign: 'bottom',
          align: 'center'
        }}
      />
    </ReportPanel>
  );
}

export default ControlsPanel;
