import { ArtifactType, Guid, Ident } from '../../types/common';
import { NistControl } from '../Dashboard/NistDashboard/NistDashboard.type';
import { HyperTag } from '../Landing/Landing.type';

/** Ordering matters when sorting. e.g. TagList */
export const Discriminator = {
  Attack: 'attack',
  Actor: 'actor',
  DataSource: 'datasource',
  Software: 'software',
  Vulnerability: 'vulnerability',
  Action: 'action'
} as const;

export type Discriminator = (typeof Discriminator)[keyof typeof Discriminator];

export function isDiscriminator(value: string): value is Discriminator {
  return Object.values(Discriminator).includes(value as Discriminator);
}

export type TagGroup = {
  actor_names: string[];
  attack_names: string[];
  datasource_names: string[];
  software_names: string[];
  vulnerability_names: string[];
};

export type MiniTag = {
  id: Ident;
  sigma_name: string;
  name: string;
};

type SigmaTagCatalog = { [index: string]: MiniTag[] };
export type AutocompleteResponse = {
  actor: SigmaTagCatalog;
  attack: SigmaTagCatalog;
  datasource: SigmaTagCatalog;
  software: SigmaTagCatalog;
  tag: SigmaTagCatalog;
  vulnerability: SigmaTagCatalog;
  action: SigmaTagCatalog;
};

export enum AttackType {
  Tactic = 'Tactic',
  Technique = 'Technique',
  Subtechnique = 'Subtechnique'
}

export const SoftwareType = {
  Tool: 'Tool',
  Malware: 'Malware'
} as const;

export type SoftwareType = (typeof SoftwareType)[keyof typeof SoftwareType];

export type BaseTag = {
  name: string;
  discriminator: Discriminator;
};

export type NistTag = BaseTag &
  NistControl & {
    detail: HyperTag;
  };

export type LandingCollectionTag = {
  name: string;
  guid?: string;
  discriminator: Discriminator | ArtifactType;
};

export interface SigmaTag extends BaseTag {
  sigma_names: string[];
  tags: MiniTag[];
}

export interface Attack extends FullTag {
  type: AttackType;
  discriminator: typeof Discriminator.Attack;
  deprecated: boolean;
  revoked: boolean;
}

export type MassTagPayload = (
  | { actor_names: string[] }
  | { attack_names: string[] }
  | { software_names: string[] }
  | { vulnerability_names: string[] }
) &
  ({ intelligence: Guid[] } | { threats: Guid[] } | { detections: Guid[] } | { collections: Guid[] });

export type AutocompleteModifiers = {
  legacy: boolean;
  only_names?: boolean;
  partial: string;
  types: Discriminator[];
};

export interface FullTag extends BaseTag {
  id: Ident;
  sigma_name: string;
  mitre_id: string;

  description?: string;
  external_source: string;
  external_source_id: string;
}

export enum AttackPlatform {
  PRE = 'PRE',
  Network = 'Network',
  Containers = 'Containers',
  // OS Platforms,
  Android = 'Android',
  iOS = 'iOS',
  Linux = 'Linux',
  macOS = 'macOS',
  Windows = 'Windows',
  Unix = 'Unix',
  VMware = 'VMware',
  // 'Cloud' Platforms,
  AzureAD = 'Azure AD',
  Office365 = 'Office 365',
  GoogleWorkspace = 'Google Workspace',
  IaaS = 'IaaS',
  SaaS = 'SaaS',
  // Now it's just starting to get weird.,
  EngineeringWorkstation = 'Engineering Workstation',
  FieldController = 'Field Controller/RTU/PLC/IED'
}
