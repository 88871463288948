import React from 'react';

import isEqual from 'lodash/isEqual';

import { Autocomplete } from 'snap-ui/Autocomplete';
import { GridColDef, GridSortModel, useGridApiRef } from 'snap-ui/DataGrid';
import { TablePlaceholder } from 'snap-ui/Table';
import { ToggleButton, ToggleButtonGroup } from 'snap-ui/ToggleButton';

import useQueryString from 'hooks/useQueryString';

import { Toolbar } from 'module/Widgets/TableToolbar';

import { StrictReactNode } from 'types/core';

import { PRIORITY_FILTER_OPTIONS } from '../const';
import { Table, TablePanel } from '../style';
import TableMenu from './TableMenu';

enum QuickSortOption {
  Gaps = 'gaps',
  Covered = 'covered'
}

const SORT_MODEL_MAP: Record<QuickSortOption, GridSortModel> = {
  [QuickSortOption.Gaps]: [
    { field: 'priority', sort: 'desc' },
    { field: 'coverage', sort: 'asc' }
  ],
  [QuickSortOption.Covered]: [{ field: 'coverage', sort: 'desc' }]
};

function getQuickSortValue(sortModel: GridSortModel): QuickSortOption | null {
  return (Object.entries(SORT_MODEL_MAP).find(([, sm]) => isEqual(sm, sortModel))?.[0] as QuickSortOption) ?? null;
}

type CoverageTableProps<T> = {
  dataGridClassName?: string;
  columns: GridColDef<Partial<T>>[];
  isPending: boolean;
  isProfilePending;
  rows: T[];
  ToolbarContent?: StrictReactNode;
};

export default function CoverageTable<T>({
  dataGridClassName,
  columns,
  isPending,
  isProfilePending,
  rows,
  ToolbarContent
}: CoverageTableProps<T>): JSX.Element {
  const gridApiRef = useGridApiRef();
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [_quickSort, setQuickSort] = React.useState<QuickSortOption>(QuickSortOption.Gaps);

  const { getByKey, update: updateQueryString } = useQueryString(true);
  const selectedPriorityTextValue = getByKey('priority');
  const selectedPriority = PRIORITY_FILTER_OPTIONS.find(opt => opt.label === selectedPriorityTextValue)?.value;

  function setSelectedPriority(value: string) {
    const textValue = PRIORITY_FILTER_OPTIONS.find(opt => opt.value === value)?.label;
    updateQueryString({ priority: textValue });
  }

  React.useEffect(() => {
    // we need the profile to be loaded for the quick sort 'Gaps' option (which is default) to work
    if (!isProfilePending) setSortModel(SORT_MODEL_MAP[_quickSort]);
  }, [_quickSort, isProfilePending]);

  return (
    <TablePanel>
      <Toolbar>
        <Toolbar>
          {ToolbarContent}
          <Autocomplete
            className='PriorityFilter'
            label='Priority'
            name='priority_filter'
            options={PRIORITY_FILTER_OPTIONS}
            onChange={setSelectedPriority}
            value={selectedPriority || ''}
          />
        </Toolbar>
        <div className='buttons'>
          <div className='quicksort'>
            <label>Quick Sort:</label>
            <ToggleButtonGroup
              exclusive
              onChange={(_, v) => setQuickSort(v as QuickSortOption)}
              orientation='horizontal'
              value={getQuickSortValue(sortModel)}
            >
              <ToggleButton color='primary' size='small' value={QuickSortOption.Gaps}>
                Gaps
              </ToggleButton>
              <ToggleButton color='primary' size='small' value={QuickSortOption.Covered}>
                Covered
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <TableMenu isPending={isPending} gridApiRef={gridApiRef.current} />
        </div>
      </Toolbar>
      {isPending ? (
        <TablePlaceholder />
      ) : (
        <Table
          apiRef={gridApiRef}
          className={dataGridClassName}
          columns={columns}
          disableColumnFilter
          disableColumnSelector
          filterModel={{
            items: selectedPriority ? [{ field: 'priority', operator: 'equals', value: selectedPriority }] : []
          }}
          getRowId={row => row.id || row.guid}
          hideFooter
          rows={rows}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
        />
      )}
    </TablePanel>
  );
}
