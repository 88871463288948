import React from 'react';

import Typography from 'snap-ui/Typography';

import Detection from '../core/Detection/Detection';
import { ExclusionsContainer } from './AnalyticTuning.style';

function ExclusionsTable() {
  return (
    <ExclusionsContainer className='span-columns'>
      <Typography variant='h3'>Tuning Logic</Typography>
      <Detection alwaysShowCondition name='customization' ignoreValidation readonly conditionDisabled />
    </ExclusionsContainer>
  );
}

export default ExclusionsTable;
