import { getBlacklist } from './Blacklist.api';
import { Restricted } from './Blacklist.type';

export function transformBlacklist(): Promise<Restricted[]> {
  return getBlacklist().then(data => {
    // filter out duplicates and sort alphabetically
    const tags = Array.from(new Set(data.map(d => d.name))).sort((a, b) =>
      a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())
    );

    // compose tag name with the rest of the blacklist alias data
    return tags.map(tagName => {
      const tagDetail = data.find(d => d.name === tagName);
      return {
        ...tagDetail,
        id: tagDetail.name,
        value: tagDetail.name,
        blacklistedAliases: tagDetail.aliases?.filter(a => a.blacklisted).map(a => a.name) || []
      };
    });
  });
}
