import React from 'react';

import Icon from 'snap-ui/Icon';

import { CheckableCardCheckboxFormik } from 'module/Form/CheckableCardFormik';

import { ActorMotivationDetails } from 'types/mati';

import ThreatProfileStep from '../ThreatProfileStep';

export default function Motivations(): JSX.Element {
  const motivations = Object.entries(ActorMotivationDetails);

  const motivationOptions = motivations.map(([motivation, detail]) => {
    return {
      value: motivation,
      label: motivation,
      icon: detail.icon ? <Icon icon={detail.icon} size='lg' /> : null
    };
  });

  return (
    <ThreatProfileStep
      title='What adversary motivations are you concerned about?'
      description="Prioritizing threat actor motivations involves understanding their objectives, whether it's financial gain, espionage, or disruption, and assessing how closely these align with an organization's assets and industry, enabling a focused security strategy that addresses the most pertinent and high-risk threat actor motivations. This approach helps tailor defenses to safeguard against the most relevant threats effectively."
      content={
        <>
          <CheckableCardCheckboxFormik name='motivations' options={motivationOptions} size='sm' />
        </>
      }
    />
  );
}
