import React from 'react';

import pick from 'lodash/pick';

import { ConfirmDialog } from 'snap-ui/Dialog';

import { ErrorProps } from 'module/ApiError';

import { Status } from 'storage';

import { ConfigType, EditorConfig, SigmaConfig } from 'types/analytic';
import { Ident } from 'types/common';

import { ConfigDisplayDialog, ConfigFormDialog, ConfigFormValues } from './ConfigFormDialog';
import { useLegacySigmaConfigs, useSigmaPipelines, useSigmaValidators, SigmaConfigsState } from './useSigmaConfigs';

type ConfigTypeControls = {
  configs: SigmaConfig[];
  errorProps?: ErrorProps;
  status: Status;
  create: SigmaConfigsState['create'];
};

type RefreshLanguages = () => void;

export type ConfigControls = {
  legacy: ConfigTypeControls;
  pipeline: ConfigTypeControls;
  validator: ConfigTypeControls;
  getControls(configType: ConfigType): ConfigTypeControls;
  getConfigByKey(configKey: string): SigmaConfig;
  getConfigsByKeys(configKeys: string[]): SigmaConfig[];
  openDisplay(configType: ConfigType, configId: Ident): void;
  openEditor(configType: ConfigType, configId?: Ident): void;
  openEditorToClone(configType: ConfigType, configId: Ident): void;
  openDeleteConfirm(configType: ConfigType, configId: Ident): void;
  setRefreshLanguagesRef(refreshLangueages: RefreshLanguages): void;
};

function pickControls(controls: SigmaConfigsState) {
  return pick(controls, ['configs', 'errorProps', 'status', 'create']);
}

function controlGetter(
  legacyControls: SigmaConfigsState,
  pipelineControls: SigmaConfigsState,
  validatorControls: SigmaConfigsState
) {
  return function (discriminator: ConfigType): SigmaConfigsState {
    if (!discriminator) return;

    switch (discriminator) {
      case 'field_mapping':
        return legacyControls;
      case 'pipeline':
        return pipelineControls;
      case 'validator':
        return validatorControls;
      default:
        console.error(`invalid config type ${discriminator}`);
        return;
    }
  };
}

const ConfigContext = React.createContext<ConfigControls>(undefined);
ConfigContext.displayName = 'ConfigContext';

function useConfigControls(): ConfigControls {
  const controls = React.useContext(ConfigContext);

  if (!controls) throw new Error('useConfigControls used outside ConfigContext');

  return controls;
}

function ConfigProvider({ children }: React.PropsWithChildren<Record<never, never>>): JSX.Element {
  const refreshLanguagesRef = React.useRef<RefreshLanguages>(null);
  const setRefreshLanguagesRef = React.useCallback((fn: RefreshLanguages) => {
    refreshLanguagesRef.current = fn;
  }, []);

  const legacyControls = useLegacySigmaConfigs();
  const pipelineControls = useSigmaPipelines();
  const validatorControls = useSigmaValidators();
  const getControls = React.useMemo(
    () => controlGetter(legacyControls, pipelineControls, validatorControls),
    [legacyControls, pipelineControls, validatorControls]
  );
  const getConfigById = React.useCallback(
    (configType: ConfigType, configId: Ident) => {
      const configList = getControls(configType)?.configs;
      const config = configList.find(c => c.id === configId);
      if (!config) console.error(`config with id [${configId}] not found in [${configType}]s`);
      return config;
    },
    [getControls]
  );

  const getConfigByKey = React.useCallback(
    (configKey: string): SigmaConfig => {
      for (const configType of Object.values(ConfigType)) {
        const config = getControls(configType).configs?.find(config => config.sigma_identifier === configKey);
        if (config) return config;
      }
    },
    [getControls]
  );

  const getConfigsByKeys = React.useCallback(
    (configKeys: string[]) => configKeys.map(getConfigByKey).filter(Boolean),
    [getConfigByKey]
  );

  const [displayConfig, setDisplayConfig] = React.useState<SigmaConfig>(null);
  const openDisplay = React.useCallback(
    (configType: ConfigType, configId: Ident) => {
      handleEditorClose();
      setDisplayConfig(getConfigById(configType, configId));
    },
    [getConfigById]
  );

  function handleDisplayClose() {
    setDisplayConfig(null);
  }

  const [editorConfig, setEditorConfig] = React.useState<EditorConfig>(null);
  const openEditor = React.useCallback(
    (configType: ConfigType, configId?: Ident) => {
      handleDisplayClose();
      getControls(configType)?.resetError();
      if (configId) {
        setEditorConfig(getConfigById(configType, configId));
      } else {
        setEditorConfig({
          discriminator: configType
        });
      }
    },
    [getControls, getConfigById]
  );
  const openEditorToClone = React.useCallback(
    (configType: ConfigType, configId: Ident) => {
      handleDisplayClose();
      getControls(configType)?.resetError();
      const sourceConfig = getConfigById(configType, configId);
      setEditorConfig({
        discriminator: configType,
        raw: sourceConfig.raw
      });
    },
    [getControls, getConfigById]
  );

  function handleEditorClose() {
    setEditorConfig(null);
  }

  function handleEditorSave(values: ConfigFormValues) {
    const controls = getControls(editorConfig.discriminator);
    if (!controls) return Promise.reject();
    return (
      editorConfig.id
        ? controls.update(editorConfig.sigma_identifier, values.raw, values.organization_id)
        : controls.create(values.raw, values.organization_id)
    ).then(() => {
      if (refreshLanguagesRef.current) refreshLanguagesRef.current();
    });
  }

  const [deleteConfig, setDeleteConfig] = React.useState<SigmaConfig>(null);
  const openDeleteConfirm = React.useCallback(
    (configType: ConfigType, configId: Ident) => {
      const config = getConfigById(configType, configId);
      setDeleteConfig(config);
    },
    [getConfigById]
  );

  function handleDeleteClose() {
    setDeleteConfig(null);
  }

  function handleDeleteConfirm() {
    const controls = getControls(deleteConfig.discriminator);
    if (!controls) return Promise.reject();
    controls.remove(deleteConfig.sigma_identifier);
  }

  return (
    <ConfigContext.Provider
      value={{
        legacy: pickControls(legacyControls),
        pipeline: pickControls(pipelineControls),
        validator: pickControls(validatorControls),
        getConfigByKey,
        getConfigsByKeys,
        getControls,
        openDisplay,
        openEditor,
        openEditorToClone,
        openDeleteConfirm,
        setRefreshLanguagesRef
      }}
    >
      {children}
      <ConfigDisplayDialog
        config={displayConfig}
        onClose={handleDisplayClose}
        onEdit={openEditor}
        onClone={openEditorToClone}
      />
      <ConfigFormDialog
        errorProps={getControls(editorConfig?.discriminator)?.errorProps}
        initialConfig={editorConfig}
        onClose={handleEditorClose}
        onSave={handleEditorSave}
        status={getControls(editorConfig?.discriminator)?.status}
      />
      <ConfirmDialog
        title='Delete Configuration'
        DialogProps={{
          open: !!deleteConfig,
          onClose: handleDeleteClose
        }}
        ConfirmProps={{
          children: 'Delete',
          onClick: handleDeleteConfirm
        }}
      >
        {`Are you sure you want to delete ${deleteConfig?.name}?`}
      </ConfirmDialog>
    </ConfigContext.Provider>
  );
}

export { ConfigProvider, useConfigControls };
