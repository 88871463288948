import React, { MutableRefObject } from 'react';

import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

import { CompositeMarkerLoadState } from 'aso/useCompositeMarker';

import useFullscreen from 'hooks/useFullscreen';

import { MarkerColor } from 'module/Session/Session.const';
import { addMarker } from 'module/Session/Session.service';
import { Host, RedMarkerCreationPayload, Session } from 'module/Session/Session.type';
import Controls from 'module/Video/Controls';
import Marker from 'module/Video/Marker';
import Progress from 'module/Video/Progress';
import Scrubber from 'module/Video/Scrubber';
import Toolbar from 'module/Video/Toolbar';
import Unavailable from 'module/Video/Unavailable';
import Video from 'module/Video/Video';

import { useMountedRef } from 'storage';

import { getQueryParam } from 'utilities/SearchParam';

import { VideoMarkerContainer, VideoPlayerContainer, VideoPlayerControls } from './VideoPlayer.style';

type Props = {
  startTime: string;
  hasHostGraph: boolean;
  activeHost: Host;
  composite: CompositeMarkerLoadState;
  session: Session;
  onDurationChange(duration: number): void;
  videoRef?: MutableRefObject<HTMLVideoElement>;
  hostSelectorRef: MutableRefObject<HTMLElement>;
  onRefreshMarker(): void;
};

function VideoPlayer({
  startTime,
  onDurationChange,
  videoRef: onVideoRef,
  hostSelectorRef,
  hasHostGraph,
  session,
  activeHost,
  composite,
  onRefreshMarker
}: Props): React.ReactElement {
  const mounted = useMountedRef();
  const location = useLocation();
  const [currentTime, setCurrentTime] = React.useState(0);
  const [duration, setDuration] = React.useState(0);
  const [isVideoLoading, setIsVideoLoading] = React.useState(true);
  const [shouldPlay, setShouldPlay] = React.useState(null);
  const [videoRef, setVideoRefState] = React.useState<HTMLVideoElement>();
  const [videoHasError, setVideoHasError] = React.useState(false);
  const containerRef = React.useRef(); // The video player top level div
  const [isFullscreen, setIsFullscreen] = useFullscreen(false, hostSelectorRef);
  const pausedOrEnded = videoRef && (videoRef.paused || videoRef.ended);

  const videoOffset = Number(getQueryParam(location.search, 't'));

  const setVideoRef = React.useCallback(
    (video: HTMLVideoElement) => {
      if (onVideoRef) onVideoRef.current = video;
      setVideoRefState(video);
    },
    [onVideoRef, setVideoRefState]
  );

  React.useEffect(() => {
    if (!videoRef || !activeHost.video) return;

    function onLoadedMetadata() {
      mounted.current && setDuration(videoRef.duration);
      mounted.current && onDurationChange(videoRef.duration);
      mounted.current && setVideoHasError(false);
    }

    function onVideoError() {
      mounted.current && setIsVideoLoading(false);
      mounted.current && setShouldPlay(false);
      mounted.current && setVideoHasError(true);
    }

    function onTimeUpdate() {
      if (mounted.current) {
        setCurrentTime(videoRef.currentTime);
        if (videoRef.currentTime === videoRef.duration) {
          setShouldPlay(false);
        }
      }
    }

    videoRef.addEventListener('error', onVideoError);
    videoRef.addEventListener('loadedmetadata', onLoadedMetadata);
    videoRef.addEventListener('timeupdate', onTimeUpdate);

    videoRef.preload = 'metadata';
    videoRef.src = activeHost.video;

    return function cleanup() {
      videoRef.removeEventListener('error', onVideoError);
      videoRef.removeEventListener('loadedmetadata', onLoadedMetadata);
      videoRef.removeEventListener('timeupdate', onTimeUpdate);
    };
  }, [onDurationChange, videoRef, activeHost.video, mounted]);

  React.useEffect(() => {
    mounted.current && setIsVideoLoading(true);
    mounted.current && setShouldPlay(false);
    mounted.current && setVideoHasError(false);
  }, [activeHost.guid, mounted]);

  React.useEffect(() => {
    if (!videoRef) return;
    if (shouldPlay) videoRef.play();
    else videoRef.pause();
  }, [videoRef, shouldPlay, activeHost.video]);

  React.useEffect(() => {
    if (!videoRef) return;
    if (videoRef.currentTime !== videoOffset) {
      videoRef.currentTime = videoOffset;
      if (videoRef.scrollIntoView) {
        videoRef.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [videoRef, activeHost.video, videoOffset]);

  return (
    <VideoPlayerContainer
      ref={containerRef}
      className={classnames({
        playing: shouldPlay === true,
        paused: shouldPlay === false
      })}
    >
      <div onClick={handlePlayPauseClick}>
        {videoHasError ? (
          <Unavailable />
        ) : (
          <>
            <Video ref={setVideoRef} isLoading={isVideoLoading} onLoadedData={setIsVideoLoading} />
            {renderControls()}
          </>
        )}
      </div>
      <Toolbar
        addMarker={handleAddMarker}
        containerRef={containerRef.current}
        currentTime={currentTime}
        url={buildCurrentUrl()}
        activeHost={activeHost}
        session={session}
        hasHostGraph={hasHostGraph}
      />
    </VideoPlayerContainer>
  );

  function renderControls(): React.ReactElement {
    if (!videoRef) return null;
    return (
      <VideoPlayerControls>
        <Progress currentTime={currentTime} duration={duration} />
        <Scrubber onClick={handleScrubberClick} />
        <VideoMarkerContainer>
          {composite.markers.red.map(marker => (
            <Marker
              key={marker.id}
              id={marker.id}
              hostId={marker.host_id}
              timestamp={marker.timestamp}
              color={MarkerColor.Red}
              containerRef={containerRef.current}
              duration={duration}
              marker={marker}
              onClick={handleMarkerClick}
              startTime={startTime}
            />
          ))}
          {composite.markers.blue.map((marker, index) => (
            <Marker
              key={`${index}-${marker.row_id}` /** We don't have a unique identifier here */}
              id={marker.event.id}
              hostId={marker.event.host_id}
              timestamp={marker.event.timestamp}
              color={MarkerColor.Blue}
              containerRef={containerRef.current}
              duration={duration}
              marker={marker}
              onClick={handleMarkerClick}
              startTime={startTime}
            />
          ))}
        </VideoMarkerContainer>
        <Controls
          containerRef={containerRef.current}
          currentTime={currentTime}
          duration={duration}
          isFullscreen={isFullscreen}
          onFullscreenClick={handleFullscreenClick}
          onPlayPauseClick={handlePlayPauseClick}
          playing={!pausedOrEnded}
        />
      </VideoPlayerControls>
    );
  }

  async function handleAddMarker(payload: RedMarkerCreationPayload) {
    await addMarker(session.guid, activeHost.guid, payload, composite);
    onRefreshMarker();
  }

  function handleFullscreenClick(event: React.MouseEvent): void {
    event.stopPropagation();
    setIsFullscreen(!isFullscreen);
  }

  function handleMarkerClick(time: number): void {
    if (time) {
      videoRef.currentTime = time;
    }
  }

  function handlePlayPauseClick(event: React.MouseEvent): void {
    event.stopPropagation();

    if (pausedOrEnded) {
      if (videoRef.currentTime === duration) {
        videoRef.currentTime = 0;
      }
      setShouldPlay(true);
    } else setShouldPlay(false);
  }

  function handleScrubberClick(percent: number): void {
    videoRef.currentTime = (duration * percent) / 100;
  }

  function buildCurrentUrl(): string {
    const urlObject = new URL(window.location.href);
    if (videoRef) {
      urlObject.searchParams.set('t', String(videoRef.currentTime | 0));
    }
    return urlObject.toString();
  }
}

export default VideoPlayer;
