import React from 'react';

import zod from 'zod';

import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { FilterableCollections } from 'module/Collection/Collection.type';

import { ArtifactType } from 'types/common';
import { Ops, Query } from 'types/filter';

import { FilterControl } from '../GlobalFilter.style';
import { FilterAugment, FilterConfig } from '../GlobalFilter.type';

type AccessibleContentKeys = {
  accessibleContent: string;
};

type AccessibleContentFilterProps = {
  onChange(values: AccessibleContentKeys): void;
  values: AccessibleContentKeys;
};

function AccessibleContentFilter({ onChange, values }: AccessibleContentFilterProps): React.ReactElement {
  const booleanValue = values.accessibleContent?.toLowerCase() === 'true';

  return (
    <FilterControl>
      <FormLabel id='accessible-content-toggle-button-group-label' className='title-tooltip'>
        By Access Level
        <Tooltip title='Filters out content requiring additional subscription.' placement='right' arrow wrap>
          <Icon.Info />
        </Tooltip>
      </FormLabel>
      <FormGroup aria-labelledby='accessible-content-toggle-button-group-label'>
        <FormControlLabel
          key='accessibleContent'
          control={<Checkbox onChange={handleChange} value={'accessibleContent'} checked={booleanValue} />}
          label='Show only content I can access'
        />
      </FormGroup>
    </FilterControl>
  );

  function handleChange(_e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    onChange({ accessibleContent: checked ? String(checked) : undefined });
  }
}

function toQuery(values: AccessibleContentKeys, { userId }: FilterAugment): Query {
  if (values.accessibleContent?.toLowerCase() !== 'true') return;
  return {
    field: 'users.user_id',
    op: Ops.equals,
    value: userId
  };
}

function toCollectionQuery(values: AccessibleContentKeys, { userId }: FilterAugment): Query {
  if (values.accessibleContent?.toLowerCase() !== 'true') return;
  return {
    op: Ops.or,
    items: [
      {
        field: 'users.user_id',
        op: Ops.equals,
        value: userId
      },
      {
        field: 'type',
        op: Ops.in,
        value: [
          FilterableCollections.Actor,
          FilterableCollections.Attack,
          FilterableCollections.Software,
          FilterableCollections.Vulnerability
        ],
        case_sensitive: false
      }
    ]
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('users.user_id'),
    op: zod.literal(Ops.equals),
    value: zod.number()
  })
  .transform(() => ({ accessibleContent: 'true' }));

const fromCollectionQuery = zod
  .object({
    op: zod.literal(Ops.or),
    items: zod.array(
      zod.union([
        zod
          .object({
            field: zod.literal('users.user_id'),
            op: zod.literal(Ops.equals),
            value: zod.number()
          })
          .transform(() => undefined),
        zod
          .object({
            field: zod.literal('type'),
            op: zod.literal(Ops.in),
            value: zod.array(zod.nativeEnum(FilterableCollections)),
            case_sensitive: zod.boolean()
          })
          .transform(() => undefined)
      ])
    )
  })
  .transform(() => ({ accessibleContent: 'true' }));

const AccessibleContentFilterConfig: FilterConfig<AccessibleContentKeys> = {
  defaults: { default: () => ({ accessibleContent: 'false' }) },
  supportedTopics: [
    ArtifactType.Intel,
    ArtifactType.Session,
    ArtifactType.Analytic,
    ArtifactType.AttackScript,
    ArtifactType.Collection
  ],
  component: AccessibleContentFilter,
  toQuery: {
    [ArtifactType.Intel]: toQuery,
    [ArtifactType.Session]: toQuery,
    [ArtifactType.Analytic]: toQuery,
    [ArtifactType.AttackScript]: toQuery,
    [ArtifactType.Collection]: toCollectionQuery
  },
  fromQuery: {
    [ArtifactType.Intel]: fromQuery,
    [ArtifactType.Session]: fromQuery,
    [ArtifactType.Analytic]: fromQuery,
    [ArtifactType.AttackScript]: fromQuery,
    [ArtifactType.Collection]: fromCollectionQuery
  }
};
export default AccessibleContentFilterConfig;
