import React from 'react';

import isEmpty from 'lodash/isEmpty';

import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { SimpleBadge } from './Badge';

const Badge = styled(SimpleBadge)`
  min-width: 100px;
`;

const Platforms: Record<string, Platform> = {
  windows: { name: 'Windows', icon: <Icon.Windows /> },
  linux: { name: 'Linux', icon: <Icon.Linux /> },
  macos: { name: 'macOS', icon: <Icon.Mac /> }
} as const;

type Platform = {
  name: string;
  icon: JSX.Element;
};

export default function PlatformBadge({ platforms }: { platforms: string[] }) {
  if (isEmpty(platforms)) return null;
  return (
    <>
      {platforms.map(key => {
        const platform = Platforms[key] || {
          name: key,
          icon: <Icon.Platform />
        };
        return (
          <Tooltip key={key} title={`This Validation is compatible with ${platform.name}`} arrow placement='top' wrap>
            <Badge icon={platform.icon} value={platform.name} />
          </Tooltip>
        );
      })}
    </>
  );
}
