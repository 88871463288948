import React from 'react';

import { deleteCollection, getSavedFilters } from 'module/Collection/Collection.api';
import { getFilterCollection, saveNewFilterCollection, updateSavedFilter } from 'module/Collection/Collection.service';
import { Collection } from 'module/Collection/Collection.type';
import { useFilterRegistry } from 'module/GlobalFilter';

import { useAuth } from 'provider';

import { Status, useAsync } from 'storage';

import { Entity } from 'types/auth';
import { Guid } from 'types/common';

export type CollectionInterface = {
  data: Collection[];
  refresh(): void;
  add(entity: Entity, description: string): Promise<Collection>;
  update(entity: Entity, description: string): Promise<Collection>;
  erase(guid: Guid): Promise<void>;
  isActive: boolean;
};

const FilterCollectionContext = React.createContext<CollectionInterface>(null);
FilterCollectionContext.displayName = 'FilterCollectionContext';

function useFilterCollection() {
  const context = React.useContext(FilterCollectionContext);

  if (!context) {
    throw new Error('useFilterCollection must be used within the CollectionContext');
  }

  return context;
}

function FilterCollectionProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();
  const { data, run, task, status } = useAsync<Collection[]>();
  const { topic, toQuery } = useFilterRegistry();

  const refresh = React.useCallback(() => {
    run(getSavedFilters(), true);
  }, [run]);

  const add = React.useCallback(
    (entity: Entity, description: string): Promise<Collection> => {
      return task(
        saveNewFilterCollection(entity, description, topic, toQuery()).then(tracked =>
          getFilterCollection(tracked.guid)
        )
      );
    },
    [task, topic, toQuery]
  );

  const update = React.useCallback(
    (entity: Entity, description: string): Promise<Collection> => {
      return task(
        updateSavedFilter(entity, description, topic, toQuery()).then(tracked => getFilterCollection(tracked.guid))
      );
    },
    [task, topic, toQuery]
  );

  const erase = React.useCallback(
    (guid: Guid): Promise<void> => {
      return task(deleteCollection(guid));
    },
    [task]
  );

  React.useEffect(() => {
    if (user.guid) refresh();
  }, [refresh, user.guid]);

  const payload = React.useMemo(() => {
    return {
      data,
      refresh,
      add,
      erase,
      update,
      isActive: status === Status.pending
    };
  }, [data, refresh, add, erase, update, status]);

  return <FilterCollectionContext.Provider value={payload}>{children}</FilterCollectionContext.Provider>;
}

export { FilterCollectionProvider, useFilterCollection };
