function transformToStatsObject(data: Record<string, number[]>) {
  return Object.entries(data).reduce((accumulated, [key, values]) => {
    if (accumulated.length === 0) accumulated = Array(values.length).fill({});
    values.forEach((value, index) => {
      accumulated[index] = { ...accumulated[index], [key]: value };
    });

    return [...accumulated];
  }, []);
}

// transformToStatsObject makes the assumption that each array is of the same length, keying
// on the length of the first array in an object (such as 'created').
// template: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
const USER_ACTIVITY_DEMO_DATA = {
  created: {
    intel: [0, 0, 1, 3, 2, 4, 1, 0, 0, 0, 1, 2, 1, 0, 0, 0, 2, 6, 3, 4, 1, 0, 0, 1, 0, 3, 2, 0, 0, 1, 0],
    threats: [0, 0, 1, 2, 0, 2, 1, 0, 0, 0, 3, 2, 0, 1, 0, 0, 0, 1, 1, 2, 0, 0, 0, 0, 4, 2, 0, 0, 1, 0, 0],
    detections: [0, 0, 3, 2, 1, 0, 1, 0, 0, 1, 3, 2, 0, 1, 0, 0, 5, 2, 1, 0, 0, 0, 1, 3, 2, 0, 0, 0, 0, 3, 2],
    simulations: [3, 0, 3, 1, 4, 0, 0, 6, 3, 2, 1, 1, 0, 0, 2, 3, 0, 0, 2, 1, 2, 0, 2, 3, 4, 4, 2, 0, 2, 2, 2],
    simulations_failed: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    deployments: [0, 0, 2, 3, 2, 4, 1, 0, 0, 1, 1, 5, 6, 2, 0, 0, 0, 3, 2, 3, 4, 0, 0, 1, 7, 5, 2, 0, 0, 3, 2]
  },
  viewed: {
    intel: [3, 3, 0, 1, 3, 0, 9, 6, 7, 7, 3, 4, 4, 8, 0, 0, 0, 1, 5, 4, 4, 8, 2, 3, 7, 6, 5, 9, 4, 9, 3],
    threats: [0, 3, 6, 3, 5, 0, 0, 6, 0, 4, 1, 0, 0, 1, 1, 0, 5, 6, 0, 3, 0, 6, 3, 3, 3, 7, 3, 2, 4, 0, 4],
    detections: [12, 12, 9, 12, 4, 8, 5, 0, 9, 2, 2, 5, 7, 9, 3, 7, 8, 5, 9, 0, 5, 7, 0, 9, 9, 10, 0, 6, 3, 9, 10]
  },
  hunted: {
    hunts: [0, 0, 3, 2, 1, 0, 1, 0, 0, 2, 4, 2, 1, 0, 0, 0, 3, 7, 2, 1, 1, 0, 0, 2, 3, 2, 0, 0, 0, 2, 2],
    hunts_ioc: [1, 1, 1, 5, 1, 3, 0, 0, 1, 1, 4, 5, 2, 3, 3, 5, 0, 0, 1, 0, 4, 3, 2, 5, 3, 4, 1, 0, 2, 1, 1]
  }
};

export const CREATED_DEMO_DATA = transformToStatsObject(USER_ACTIVITY_DEMO_DATA.created);
export const HUNTED_DEMO_DATA = transformToStatsObject(USER_ACTIVITY_DEMO_DATA.hunted);
export const VIEWED_DEMO_DATA = transformToStatsObject(USER_ACTIVITY_DEMO_DATA.viewed);
