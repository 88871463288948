import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';

import Icon from 'snap-ui/Icon';
import { ListItemIcon, ListItemText } from 'snap-ui/List';
import MenuItem from 'snap-ui/MenuItem';
import Tooltip, { TooltipProps } from 'snap-ui/Tooltip';

export { default } from '@mui/material/MenuItem';
export type { MenuItemProps } from '@mui/material/MenuItem';

export type IconMenuItemProps = {
  className?: string;
  disabled?: boolean;
  onClick(): void;
  icon: IconProp;
  text: string;
  tooltip?: string;
  placement?: TooltipProps['placement'];
};

export function IconMenuItem({ className, disabled, onClick, icon, text, tooltip, placement }: IconMenuItemProps) {
  return (
    <Tooltip arrow placement={placement} title={tooltip}>
      <MenuItem className={className} aria-label={text} disabled={disabled} onClick={onClick}>
        <ListItemIcon>
          <Icon icon={icon} />
        </ListItemIcon>
        <ListItemText>{text}</ListItemText>
      </MenuItem>
    </Tooltip>
  );
}
