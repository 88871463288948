import React from 'react';

import { faArrowCircleUp } from '@fortawesome/pro-solid-svg-icons';
import { FormikErrors } from 'formik';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import CircularProgress from 'snap-ui/CircularProgress';
import FormDialog from 'snap-ui/Dialog/FormDialog';
import Icon from 'snap-ui/Icon';
import { FieldsLayout } from 'snap-ui/Layout';
import LinearProgress from 'snap-ui/LinearProgress';
import { styled } from 'snap-ui/util';

import { UploadResult } from 'types/common';

import UploadFieldFormik from '../Form/UploadFieldFormik';

type UploadDialogProps = {
  className?: string;
  header?: string;
  onComplete: () => void;
  onUpload: (
    values: File[],
    onUploadProgress?: (file: File, progress: ProgressEvent) => void
  ) => Promise<UploadResult[]>;
  open: boolean;
};

type FileProgress = Map<File, { percent_complete: number; success: boolean }>;

enum ContentState {
  Form,
  BeginUpload,
  Uploading,
  Complete
}

export type UploadForm = {
  files: File[];
};

type UploadFormErrors = FormikErrors<UploadForm>;

function UploadDialog(props: UploadDialogProps): JSX.Element {
  const [contentState, setContentState] = React.useState(ContentState.Form);
  const [uploadProgress, setUploadProgress] = React.useState<FileProgress>(new Map());
  const [uploadResults, setUploadResults] = React.useState([]);

  const handleCloseOnComplete = (): void => {
    setContentState(ContentState.Form);
    setUploadProgress(new Map());
    setUploadResults([]);
    props.onComplete();
  };

  const onUploadProgress = (file: File, progress: ProgressEvent): void => {
    const existingRecord = uploadProgress.get(file);
    const updatedUploadProgress = new Map(uploadProgress);
    updatedUploadProgress.set(file, {
      percent_complete: progress.loaded === progress.total ? 100 : (progress.loaded / progress.total) * 100,
      success: existingRecord ? existingRecord.success : null
    });
    setUploadProgress(updatedUploadProgress);
  };

  const handleSubmit = (values: UploadForm): void => {
    setUploadProgress(
      values?.files.reduce((progress, file) => {
        progress?.set(file, {
          percent_complete: file.size ? 0 : 100,
          success: null
        });
        return progress;
      }, new Map() as FileProgress)
    );
    setContentState(ContentState.BeginUpload);
  };

  React.useEffect(() => {
    if (ContentState.BeginUpload === contentState) {
      props
        .onUpload(Array.from(uploadProgress.keys()), onUploadProgress)
        .then(uploadResults => {
          setUploadResults(uploadResults);
        })
        .finally(() => {
          setContentState(ContentState.Complete);
        });
      setContentState(ContentState.Uploading);
    }
    // kicking off an upload with a specific contentState
    // This block previously existed within handleSubmit, but
    // the uploadProgress reference used in onUploadProgress did not
    // honor the setUploadProgress call in handleSubmit
    // yay timing/state problems!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentState]);

  return (
    <FormDialog
      className={props?.className}
      DialogProps={{ open: props.open, onClose: handleCloseOnComplete }}
      FormikConfig={{
        initialValues: { files: [] },
        onSubmit: handleSubmit,
        validate: (values: UploadForm): UploadFormErrors => {
          if (!values.files.length) return { files: 'Please select a file to upload' };
        }
      }}
      SubmitProps={{
        children: contentState == ContentState.Uploading ? <CircularProgress size={25} /> : 'Upload Files',
        disabled: contentState !== ContentState.Form
      }}
      cancelLabel='Close'
      title={props.header || 'Upload Files'}
    >
      {contentState === ContentState.Form && (
        <FieldsLayout>
          <UploadFieldFormik name='files' />
        </FieldsLayout>
      )}
      {contentState !== ContentState.Form && (
        <>
          {uploadResults.some(result => !result.success) &&
            uploadResults
              .filter(result => !result.success)
              .slice(0, 1)
              .map(error => (
                <Alert key={error?.message} className='alert' severity='error'>
                  <AlertTitle>{error?.message}</AlertTitle>
                  One or more files have failed to upload.
                </Alert>
              ))}
          <ul className='file-progress'>
            {Array.from(uploadProgress.entries()).map(([file, progress], index) => {
              const uploadResult = uploadResults.find(result => result.filename === file.name);
              const showCheck = uploadResult?.success || progress?.percent_complete === 100;
              const showPending = !showCheck && progress?.percent_complete >= 0 && progress?.percent_complete < 100;
              const showError = !showCheck && !showPending;
              return (
                <li key={index}>
                  <span>
                    {showCheck && <Icon.Success />}
                    {showPending && <Icon icon={faArrowCircleUp} />}
                    {showError && <Icon.Error />}
                  </span>
                  <span className='filename'>{file.name}</span>

                  {showPending && <LinearProgress variant='determinate' value={progress?.percent_complete} />}
                </li>
              );
            })}
          </ul>
        </>
      )}
    </FormDialog>
  );
}

const StyledUploadDialog = styled(UploadDialog)<UploadDialogProps>`
  flex-grow: 1;

  .alert {
    margin-bottom: ${p => p.theme.spacing(3)};
  }

  .file-progress {
    list-style: none;
    margin: 0;
    padding: ${p => p.theme.spacing(3)};
    color: ${p => p.theme.palette.grey[400]};
    background: ${p => p.theme.palette.grey[900]};
    border-radius: ${p => p.theme.spacing(1)};

    li {
      display: grid;
      grid-template: auto auto / ${p => p.theme.spacing(5)} 1fr;
      grid-gap: ${p => p.theme.spacing(1, 3)};

      & + li {
        margin-top: ${p => p.theme.spacing(3)};
      }

      .MuiLinearProgress-root {
        grid-column-start: 1;
        grid-column-end: -1;
      }
    }

    .filename {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .upload-error {
      color: ${p => p.theme.palette.error.light};
    }
  }
`;

export default StyledUploadDialog;
