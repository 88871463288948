import React from 'react';

import classNames from 'classnames';
import truncate from 'lodash/truncate';

import Typography from 'snap-ui/Typography';

import { StrictReactNode } from 'types/core';

import { formatShortTimestamp } from 'utilities/TimeUtils';

export function CreatorSubtitle(props: { creator: string; date: string }) {
  return (
    <Subtitle className='CreatorSubtitle'>
      <Typography>
        CREATOR:{' '}
        {truncate(props.creator, {
          omission: '...',
          length: 30
        })}
      </Typography>
      <Typography>UPDATED: {formatShortTimestamp(props.date)}</Typography>
    </Subtitle>
  );
}

export function Subtitle(props: { children: StrictReactNode; className?: string }) {
  return <div className={classNames('Subtitle', props.className)}>{props.children}</div>;
}
