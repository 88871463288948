import React from 'react';

import Icon from 'snap-ui/Icon';

import { BurgerClicker } from 'module/Layout/Artifact.widgets';
import { useMayI } from 'module/May';

import { FunctionalPermission } from 'types/auth';

import { SimulateAttackScriptsModal } from './SimulateAttackScriptsModal';

type Provider = { children: React.ReactNode };
type Button = Partial<Omit<BurgerClicker, 'onClick'>>;
type Dialog = Omit<SimulateAttackScriptsModal, 'onClose' | 'isOpen'>;

type SimulateAttackScripts = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  isSimulateUser: boolean;
};

const SimulateAttackScriptsContext = React.createContext<SimulateAttackScripts>(null);
SimulateAttackScriptsContext.displayName = 'SimulateAttackScriptsContext';

export function SimulateAttackScriptsInterface({ children }: Provider): JSX.Element {
  const [isOpen, setIsOpen] = React.useState(false);
  const isSimulateUser = useMayI(FunctionalPermission.SimulationFeatures);

  return (
    <SimulateAttackScriptsContext.Provider value={{ isOpen, setIsOpen, isSimulateUser }}>
      {children}
    </SimulateAttackScriptsContext.Provider>
  );
}

function useSimulateAttackScriptsContext(): SimulateAttackScripts {
  const context = React.useContext(SimulateAttackScriptsContext);
  if (!context) {
    throw new Error(`SimulateAttackScripts components must be used within a SimulateAttackScriptsContext`);
  }
  return context;
}

export function SimulateAttackScriptsButton(props: Button) {
  const { setIsOpen, isSimulateUser } = useSimulateAttackScriptsContext();

  if (!isSimulateUser) return null;

  return (
    <BurgerClicker
      title='Generate Simulated Attack Scripts'
      aria-label='Generate Simulated Attack Scripts'
      icon={<Icon.AttackScript color='inherit' />}
      onClick={() => setIsOpen(true)}
      {...props}
    />
  );
}

export function SimulateAttackScriptsDialog(props: Dialog) {
  const { isOpen, setIsOpen, isSimulateUser } = useSimulateAttackScriptsContext();

  if (!isSimulateUser) return null;

  return <SimulateAttackScriptsModal isOpen={isOpen} onClose={() => setIsOpen(false)} {...props} />;
}
