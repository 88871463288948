import React from 'react';

import { faUbuntu } from '@fortawesome/free-brands-svg-icons';

import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import { useTheme } from 'snap-ui/util';

import { UBUNTU_OS } from '../Session.const';
import { VictimHostButtonContainer } from './HostButtonContainer.style';

type Props = {
  disabled: boolean;
  onChange(values: string[]): void;
  values: string[];
};

export default function UbuntuButton({ disabled, values, onChange }: Props) {
  const { palette } = useTheme();
  const isSelected = values.includes(UBUNTU_OS);

  const handleChange = () => {
    const newValue = isSelected ? values.filter(v => v !== UBUNTU_OS) : [...values, UBUNTU_OS];
    onChange(newValue);
  };

  return (
    <Tooltip title={disabled ? 'There are no Ubuntu machines available' : ''} wrap arrow placement='top'>
      <VictimHostButtonContainer selected={isSelected} onChange={handleChange} value={UBUNTU_OS} disabled={disabled}>
        <Icon icon={faUbuntu} color={palette.common.white} />
        Ubuntu
      </VictimHostButtonContainer>
    </Tooltip>
  );
}
