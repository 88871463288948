import React from 'react';

import { Engage, Fingerprint, NotableEvent } from 'lib/Engagement';

import { Discriminator } from 'module/Tag';

import { ArtifactType } from 'types/common';

import Fallback from '../Fallback';
import Suggest from './Suggest';

type Props = {
  error?: { response: any; tag: string };
  artifact?: ArtifactType | Discriminator;
};

export default function NotFound(props: Props) {
  let suffix = 'page';

  React.useEffect(() => {
    Engage.track(
      Fingerprint.of(NotableEvent.NotFound).pageLoad().withData({
        artifact: props.artifact,
        pathname: window?.location.pathname
      })
    );
  }, [props.artifact]);

  switch (props.artifact) {
    case ArtifactType.Analytic:
      suffix = 'detection';
      break;
    case ArtifactType.Session:
      suffix = 'threat';
      break;
    case ArtifactType.Intel:
      suffix = 'intelligence';
      break;
    case ArtifactType.Collection:
      suffix = 'collection';
      break;
    case ArtifactType.AttackPlan:
      suffix = 'attack simulation';
      break;
    case ArtifactType.AttackScript:
      suffix = 'attack script';
      break;
    case Discriminator.Attack:
      suffix = 'MITRE ATT&CK technique';
      break;
    case Discriminator.Actor:
      suffix = 'actor';
      break;
    case Discriminator.Software:
      suffix = 'software';
      break;
    case Discriminator.Vulnerability:
      suffix = 'vulnerability';
      break;
  }

  const action = props.artifact ? <Suggest artifact={props.artifact} /> : null;

  return <Fallback message={`We couldn't find that ${suffix}...`} action={action} />;
}
