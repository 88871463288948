import React from 'react';

import Alert from 'snap-ui/Alert';

import useOrganizationOptions from 'hooks/useOrganizationOptions';

import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import MarkdownFormik from 'module/Form/MarkdownFormik';
import TextFieldFormik from 'module/Form/TextFieldFormik';
import { Form, TagDivider } from 'module/Layout/Artifact.widgets';
import { Discriminator, TagAutocompleteFormik } from 'module/Tag';

import { FunctionalPermission } from 'types/auth';

type Props = {
  permissionBlocked?: boolean;
};

function ReviewSessionForm({ permissionBlocked: blocked }: Props): React.ReactElement {
  const orgOptions = useOrganizationOptions(FunctionalPermission.CreateSession);

  return (
    <Form>
      <TextFieldFormik label='Owner' name='owner' disabled />
      <AutocompleteFormik label='Organization' name='organization_id' options={orgOptions} disabled />
      <TextFieldFormik label='Title' name='name' required disabled={blocked} />
      <MarkdownFormik label='Description' name='description' required disabled={blocked} />
      <AutocompleteFormik label='References' name='references' options={[]} multiple disabled={blocked} />
      <TagDivider />
      <Alert severity='info'>Labelling an attack on the video or event timeline will add tags to this threat</Alert>
      <TagAutocompleteFormik name='attack_names' discriminator={Discriminator.Attack} multiple disabled />
      <TagAutocompleteFormik name='actor_names' discriminator={Discriminator.Actor} multiple disabled />
      <TagAutocompleteFormik name='software_names' discriminator={Discriminator.Software} multiple disabled />
      <TagAutocompleteFormik name='vulnerability_names' discriminator={Discriminator.Vulnerability} multiple disabled />
    </Form>
  );
}

export default ReviewSessionForm;
