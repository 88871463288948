import { useLocation } from 'react-router-dom';

import { HostShell } from 'module/Session/Session.const';

import { getQueryParam } from 'utilities/SearchParam';

import { Host } from './Session.type';

/** Pulls the current host from the location */
export default function useSessionHost(hosts: Host[]): Host {
  const { search } = useLocation();
  const hostname = getQueryParam(search, 'host');
  if (hosts === undefined || hosts.length === 0) return HostShell;
  return hosts.find(h => h.machine.name === hostname) || hosts[0];
}
