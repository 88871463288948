import isNil from 'lodash/isNil';

import { snapattack } from 'apis/snapattack';

import { Guid } from 'types/common';

import { TuningFieldsResponse, TuningPayload, TuningValuesPayload, TuningValuesResponse } from './AnalyticTuning.types';

export function postTuningFields(guid: Guid, payload: TuningPayload): Promise<TuningFieldsResponse> {
  return snapattack.post(`/signatures/tuning/${guid}/fields/`, payload).then(r => r.data);
}

export function postTuningValues(guid: Guid, _payload: TuningPayload, size?: number): Promise<TuningValuesResponse> {
  const payload: TuningValuesPayload = isNil(size) ? _payload : { ..._payload, size };
  return snapattack.post(`/signatures/tuning/${guid}/values/`, payload).then(r => r.data);
}
