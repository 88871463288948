import { Status } from 'storage';

import { Artifact, ArtifactType, Guid, PageParams, Visibility } from 'types/common';
import { Query } from 'types/filter';

export type Item = {
  id: number;

  creation_time: string;
  end_time: string;

  owner: {
    first_name: string;
    last_name: string;
  };
};

export type IntelItem = Item & {
  title: string;
  description: string;
  visibility: Visibility;
};

export type FeedLink = {
  text: string;
  link: string;
};

export type NameMatch = {
  type: ArtifactType;
  guid: Guid;
  name: string;
};

export type NameMatchResponse = NameMatch[];

export type Page<T> = PageParams & {
  items: T[];
  total: number;
};

export type FeedResponse = Page<Artifact> & {
  message?: string;
};

/**
 * Additional fields added to the SearchResult provided by the FE
 */
export interface Feed extends FeedResponse {
  lastUsedQuery?: Query;
  canceled?: boolean;
  pageTotal?: number;
}

export type FeedAsync = Feed & {
  status: Status;
  error: any;
  reset(): void;
};

export type FeedCounts = {
  [ArtifactType.Intel]: FeedAsync;
  [ArtifactType.Session]: FeedAsync;
  [ArtifactType.Analytic]: FeedAsync;
  [ArtifactType.AttackScript]: FeedAsync;
  [ArtifactType.Collection]: FeedAsync;
  fetched: boolean;
};

export const DisplayKind = {
  Card: 'Card',
  Mini: 'Mini',
  Grid: 'Grid'
} as const;

export type DisplayKind = (typeof DisplayKind)[keyof typeof DisplayKind];

export const SortOrder = {
  relevance: 'relevance',
  modified: 'modified:desc',
  creation: 'creation:desc'
} as const;

export type SortOrder = (typeof SortOrder)[keyof typeof SortOrder];

export function isValidSortOrder(value: string): value is SortOrder {
  return Object.values(SortOrder).includes(value as SortOrder);
}
