import React from 'react';

import { faPlus } from '@fortawesome/pro-regular-svg-icons';

import { ButtonBaseProps, DivButton as ContainerButton } from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';

import { Engage, Fingerprint, Widget } from 'lib/Engagement';

import { useAuth } from 'provider';

import { checkTaskPermission } from 'services/authService';

import { FunctionalPermission } from 'types/auth';

type HuntButton = Partial<Omit<ButtonBaseProps, 'onClick'>> & { count: number; onClick: () => void };
export default function HuntButton({ count, onClick, disabled, ...props }: HuntButton): JSX.Element {
  const { permission } = useAuth();

  const notAllowed = !checkTaskPermission(permission, FunctionalPermission.DetectionHuntFeatures);

  return (
    <ContainerButton
      disabled={count === 0 || notAllowed || disabled}
      onClick={() => {
        Engage.track(Fingerprint.of(Widget.LandingRecommender).open().withQualifier('start hunt'));
        onClick();
      }}
      TooltipProps={{ title: notAllowed ? 'You do not have permission to hunt detections' : undefined }}
      {...props}
    >
      <Icon icon={faPlus} /> Start Hunt
    </ContainerButton>
  );
}
