import React from 'react';

import isEqual from 'lodash/isEqual';
import rison from 'rison';

import { Autocomplete } from 'snap-ui/Autocomplete';
import TextField from 'snap-ui/TextField';

import { useIDEState } from 'module/IDE/IDEStateProvider';

import { useLogsourceOptions } from 'provider';

export type LogsourceFieldProps = { readonly?: boolean };

function parseStringValue(value: string): { product: string; service?: string } {
  const [product, service] = value.split(' ');
  return { product, service };
}

export default function LogsourceField({ readonly }: LogsourceFieldProps): JSX.Element {
  const { ideState, dispatch } = useIDEState();
  const value = ideState.analyticForm?.logsource;
  const options = useLogsourceOptions();

  function handleChange(encodedValue: string) {
    let newValue;
    if (!encodedValue) newValue = {};
    else {
      try {
        newValue = rison.decode(encodedValue);
      } catch (e) {
        newValue = parseStringValue(encodedValue);
      }
      if (typeof newValue === 'string') newValue = parseStringValue(newValue);
    }

    dispatch({
      type: 'FormUpdateAction',
      analyticForm: { logsource: newValue }
    });
  }

  const displayValue =
    options.find(o => isEqual(rison.decode(o.value), value))?.content.toString() || value
      ? `${value.product || ''}${value.service ? ` ${value.service}` : ''}${value.category ? ` ${value.category}` : ''}`
      : '';

  return readonly ? (
    <TextField data-testid='logSource' label='Log Source' elevated fullWidth disabled value={displayValue} readOnly />
  ) : (
    <Autocomplete
      elevated
      label='Log Source'
      name='logsource'
      onChange={handleChange}
      options={options}
      value={displayValue}
      disableUserAdditions
    />
  );
}
