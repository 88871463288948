import React from 'react';

import startCase from 'lodash/startCase';

import PieChart from 'snap-ui/Charts/PieChart';

import { BASCampaign } from 'module/BAS/BAS.type';

import { JobOutcome } from 'types/bas';

import { getFlatObjectCount } from '../../BAS.util';
import { JobOutcomeColorGradient, PieChartDefaultProperties } from '../CampaignReport.style';
import ReportPanel from './ReportPanel';

type OutcomesPanelProps = {
  campaign: BASCampaign;
};

const DATA_EMPTY_MESSAGE = 'Please add outcomes below.';

function OutcomesPanel({ campaign }: OutcomesPanelProps) {
  const outcomesEmpty = getFlatObjectCount(campaign.outcomes, JobOutcome.NeedsReview) === 0;

  const outcomeChartData = React.useMemo(() => {
    if (!campaign.outcomes) return [];
    return [
      { name: startCase(JobOutcome.Prevented), value: campaign.outcomes[JobOutcome.Prevented] },
      { name: startCase(JobOutcome.Detected), value: campaign.outcomes[JobOutcome.Detected] },
      { name: startCase(JobOutcome.Logged), value: campaign.outcomes[JobOutcome.Logged] },
      { name: startCase(JobOutcome.NoAction), value: campaign.outcomes[JobOutcome.NoAction] },
      { name: startCase(JobOutcome.NotTested), value: campaign.outcomes[JobOutcome.NotTested] }
    ];
  }, [campaign.outcomes]);

  return (
    <ReportPanel title='Outcomes' hasError={outcomesEmpty} error={DATA_EMPTY_MESSAGE}>
      <PieChart colors={JobOutcomeColorGradient} data={outcomeChartData} {...PieChartDefaultProperties} />
    </ReportPanel>
  );
}

export default OutcomesPanel;
