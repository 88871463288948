import React from 'react';

import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';

import Badge from 'snap-ui/Badge';
import { IntegrationIcon } from 'snap-ui/Icon';
import { IconLink } from 'snap-ui/Link';
import Placeholder from 'snap-ui/Placeholder';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { DetectionDeploymentStatus } from 'module/GlobalFilter/Filters/DeployedEnvironment';
import { TimeAgoTimestamp } from 'module/Widgets/TimeAgoTimestamp';

import { Status, useAsync } from 'storage';

import { ArtifactType } from 'types/common';

import { formatQueryString } from 'utilities/SearchParam';

import { getIntegrationDeployments } from './Integration.api';
import { ContentPaper, StatusDetailsContainer, StatusIconContainer } from './Integration.style';
import { Integration, IntegrationCount } from './Integration.type';
import useIntegrationContext from './IntegrationProvider';
import { IntegrationSync } from './IntegrationSync';
import { StatusHeader } from './StatusHeader';

const HealthStatusContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: ${p => p.theme.spacing(3)};

  .status {
    align-items: center;
    display: flex;
    gap: ${p => p.theme.spacing(3)};
  }

  .row {
    display: flex;
    flex-direction: row;
    gap: ${p => p.theme.spacing(3)};
  }

  .detection-count-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .sync-button {
    width: 160px;
    justify-self: center;
  }
`;

export function IntegrationHealthBadge({
  integration,
  showTooltip = true
}: {
  integration: Integration;
  showTooltip?: boolean;
}) {
  if (isEmpty(integration) || !integration.is_registered) return <div></div>;

  const { status, color, timestamp, error } = getHealthStatus(integration);

  return (
    <Tooltip
      arrow
      title={
        showTooltip ? (
          <div>
            <div>Status: {status}</div>
            <div>Last Check-in: {timestamp}</div>
            {error && <div>Last Error: {error}</div>}
          </div>
        ) : null
      }
    >
      <Badge variant='dot' color={color} />
    </Tooltip>
  );
}

export function IntegrationHealthStatus({ integration }: { integration: Integration }) {
  if (isEmpty(integration)) return <Typography variant='h3'>Add a New Integration</Typography>;

  if (!integration.is_registered)
    return (
      <ContentPaper>
        <StatusHeader integration={integration} />
        <HealthStatusContainer>
          <StatusDetailsContainer>
            <IntegrationDeploymentCount integration={integration} />
          </StatusDetailsContainer>
          <StatusIconContainer>
            <IntegrationIcon type={integration.type} />
          </StatusIconContainer>
        </HealthStatusContainer>
      </ContentPaper>
    );

  const { status, timestamp, error } = getHealthStatus(integration);

  return (
    <ContentPaper>
      <StatusHeader integration={integration} />
      <HealthStatusContainer>
        <StatusDetailsContainer>
          <div className='status'>
            {status}
            <IntegrationHealthBadge integration={integration} showTooltip={false} />
          </div>
          <div>
            {integration?.version && <div>Version: {integration.version}</div>}
            Last Check-in: {timestamp}
            {!error && status === 'Needs Help' && (
              <p>
                <small>Has not checked in within the past 30 minutes.</small>
              </p>
            )}
          </div>
          {error && <div>Last Error: {error}</div>}
          <div className='row'>
            <IntegrationDeploymentCount integration={integration} />
          </div>
          <IntegrationSync integration={integration} />
        </StatusDetailsContainer>
        <StatusIconContainer>
          <IntegrationIcon type={integration.type} />
        </StatusIconContainer>
      </HealthStatusContainer>
    </ContentPaper>
  );
}

function IntegrationDeploymentCount({ integration }: { integration: Integration }) {
  const { setDeploymentCount } = useIntegrationContext();
  const { data: deploymentCount, run, status } = useAsync<IntegrationCount>();

  const refresh = React.useCallback(() => {
    if (integration?.guid) run(getIntegrationDeployments(integration.guid));
  }, [integration.guid, run]);

  const isPending = status === Status.idle || status === Status.pending;

  React.useEffect(() => refresh(), [refresh]);

  React.useEffect(() => {
    setDeploymentCount(deploymentCount?.success);
  }, [deploymentCount, setDeploymentCount]);

  if (isEmpty(deploymentCount) && !isPending) return null;

  const { success, failed, pending } = deploymentCount ?? {};

  return (
    <div className='detection-count-container'>
      <b>Detections</b>
      {isPending ? (
        <>
          <Placeholder variant='text' width={150} />
          <Placeholder variant='text' width={150} />
          <Placeholder variant='text' width={150} />
        </>
      ) : (
        <>
          <IconLink
            to={{
              pathname: Path.Feed,
              search: formatQueryString({
                topic: ArtifactType.Analytic,
                deployedStatus: DetectionDeploymentStatus.success,
                deployedEnvironment: integration.guid
              })
            }}
            icon={faArrowUpRightFromSquare}
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            {success} Deployed
          </IconLink>
          <IconLink
            to={{
              pathname: Path.Feed,
              search: formatQueryString({
                topic: ArtifactType.Analytic,
                deployedStatus: DetectionDeploymentStatus.pending,
                deployedEnvironment: integration.guid
              })
            }}
            icon={faArrowUpRightFromSquare}
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            {pending} Queued
          </IconLink>
          <IconLink
            to={{
              pathname: Path.Feed,
              search: formatQueryString({
                topic: ArtifactType.Analytic,
                deployedStatus: DetectionDeploymentStatus.failed,
                deployedEnvironment: integration.guid
              })
            }}
            icon={faArrowUpRightFromSquare}
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            {failed} Error
          </IconLink>
        </>
      )}
    </div>
  );
}

function getHealthStatus(integration: Integration): {
  status: string;
  color: 'success' | 'error' | 'warning' | 'default';
  timestamp: string | JSX.Element;
  error: unknown;
} {
  const { is_alive, is_healthy, last_checkin, last_error } = integration;

  const isHealthy = is_alive && is_healthy;
  const isError = !is_alive && !is_healthy;
  const isWarn = !isHealthy && !isError;

  const status = isHealthy ? 'Healthy' : isError ? 'Not Healthy' : isWarn ? 'Needs Help' : 'Unknown';
  const color = isHealthy ? 'success' : isError ? 'error' : isWarn ? 'warning' : 'default';
  const lastCheckin = last_checkin ? <TimeAgoTimestamp timestamp={last_checkin} /> : 'Never';

  return {
    status,
    color,
    timestamp: lastCheckin,
    error: last_error
  };
}
