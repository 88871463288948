import React from 'react';

import Tooltip from 'snap-ui/Tooltip';

import { formatShortTimestamp, timeAgo } from 'utilities/TimeUtils';

export function TimeAgoTimestamp({
  timestamp,
  disabledTooltip
}: {
  timestamp: string;
  disabledTooltip?: boolean;
}): JSX.Element {
  if (!timestamp) return null;
  return (
    <Tooltip arrow placement='bottom' title={disabledTooltip ? null : formatShortTimestamp(timestamp)} wrap>
      <>{timeAgo(timestamp)}</>
    </Tooltip>
  );
}
