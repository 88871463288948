import React from 'react';

import { faCertificate } from '@fortawesome/pro-light-svg-icons';

import Icon from './Icon';
import { styled } from './util';

export const Certified = styled(Cert, { shouldForwardProp: p => p !== 'size' })`
  position: relative;

  height: ${p => p.theme.spacing(p.size)};
  width: ${p => p.theme.spacing(p.size)};
  margin-right: ${p => p.theme.spacing(1)};

  svg,
  .logo-container {
    vertical-align: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 38%;
      height: ${Math.floor(38 * (25 / 17))}%;
    }
  }
`;

function Cert({ className }: { className?: string; size: number }) {
  return (
    <div className={className}>
      <Icon icon={faCertificate} color='cert' />
      <div className='logo-container'>
        <img src={'/images/logo.png'} decoding='async' />
      </div>
    </div>
  );
}
