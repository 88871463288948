import React from 'react';

import zod from 'zod';

import FormControlLabel from 'snap-ui/FormControlLabel';
import FormLabel from 'snap-ui/FormLabel';
import Radio from 'snap-ui/Radio';
import RadioGroup from 'snap-ui/RadioGroup';

import { FilterControl } from 'module/GlobalFilter';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';

import { FilterConfig } from '../GlobalFilter.type';

type TimeRestrictionKeys = {
  createdAt: string;
};

type TimeRestrictionFilterProps = {
  disabled?: boolean;
  onChange(values: Partial<TimeRestrictionKeys>): void;
  values: TimeRestrictionKeys;
};

const BackwardsCompatibleValues = {
  '-1d': 'P1D',
  '-1w': 'P1W',
  '-30d': 'P30D',
  '-365d': 'P1Y'
};

const ALL = 'ALL';
const options = [
  { label: 'Past Day', value: 'P1D' },
  { label: 'Past Week', value: 'P1W' },
  { label: 'Past Month', value: 'P30D' },
  { label: 'Past Year', value: 'P1Y' },
  { label: 'All Time', value: ALL }
];

function TimeRestrictionFilter({ onChange, values }: TimeRestrictionFilterProps): React.ReactElement {
  return (
    <FilterControl className='TimeRestrictionFilter'>
      <FormLabel id='timeRestriction'>By Time</FormLabel>
      <RadioGroup
        aria-labelledby='timeRestriction'
        name='timeRestrictionGroup'
        value={values.createdAt}
        onChange={handleChange}
      >
        {options.map(option => (
          <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
        ))}
      </RadioGroup>
    </FilterControl>
  );

  function handleChange(_event: React.ChangeEvent<HTMLInputElement>, value: string): void {
    onChange({ createdAt: value === ALL ? undefined : value });
  }
}

function toQuery(values: TimeRestrictionKeys) {
  if (!values?.createdAt || values?.createdAt === ALL) return;
  return {
    op: Ops.greater_than,
    field: 'creation',
    value: values.createdAt
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('creation'),
    op: zod.nativeEnum(Ops),
    value: zod.string()
  })
  .transform(query => ({ createdAt: BackwardsCompatibleValues[query.value] ?? query.value }));

/**
 * Outstanding issue in fromQuery
 */
const TimeRestrictionFilterConfig: FilterConfig<TimeRestrictionKeys> = {
  defaults: { default: () => ({ createdAt: ALL }) },
  supportedTopics: [
    ArtifactType.Session,
    ArtifactType.Analytic,
    ArtifactType.Intel,
    ArtifactType.Collection,
    ArtifactType.AttackScript
  ],
  component: TimeRestrictionFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default TimeRestrictionFilterConfig;
