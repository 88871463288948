import React from 'react';

import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

import { ActionIconButton, ActionIconButtonProps } from 'snap-ui/Button';
import Paper, { PaperProps } from 'snap-ui/Paper';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

export const LogRoot = styled('div', { label: 'LogRoot' })`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  height: 100%;
  gap: ${p => p.theme.spacing(3)};
  padding: ${p => p.theme.spacing(4)};

  .MuiAlert-root {
    width: 75%;
  }

  .RootLog-icon {
    height: 3.75rem;
  }

  .RootLog-example {
    margin: ${p => p.theme.spacing(0, 3)};
  }
`;

export const LogHeader = styled('div')`
  padding: ${p => p.theme.spacing(4)};
  margin-bottom: ${p => p.theme.spacing(4)};
`;

export const LogListContainer = styled('div', { name: 'LogListContainer' })`
  display: flex;
  flex-direction: column;
`;

export const LogContainer = styled('div', { name: 'LogContainer' })`
  padding: ${p => p.theme.spacing(3)};
`;

export const LogToolbar = styled(({ className, ...others }: PaperProps) => (
  <Paper className={classNames('LogToolbar', className)} {...others} />
))`
  height: 48px;
  background-color: ${p => p.theme.palette.secondary.main};
  background-image: unset;
  display: flex;
  gap: ${p => p.theme.spacing(4)};

  .tabs .MuiTab-root {
    font-size: 1.25rem;
    color: ${p => p.theme.palette.common.white};
  }

  .MuiTabs-flexContainer > button {
    background-color: ${p => p.theme.palette.secondary.main};
    color: ${p => p.theme.palette.common.white};
    border: none;
  }

  .MuiTabs-flexContainer > button.Mui-selected {
    background-image: unset;
  }

  .pager {
    align-items: center;
    display: flex;
    gap: ${p => p.theme.spacing(3)};
    justify-content: center;
  }

  .content-menu {
    align-items: center;
    display: flex;
    gap: ${p => p.theme.spacing(3)};
    justify-content: flex-end;
    flex-grow: 1;
    margin-right: ${p => p.theme.spacing(5)};

    button {
      font-size: 1rem;
    }
  }
`;

export function Pager(props: { current: number; count: number; onChange(item: number): void }) {
  const hasPrevious = props.current > 0;
  const hasNext = props.current < props.count - 1;
  return (
    <div className='pager'>
      <Tooltip arrow placement='top' title='View First Log' wrap>
        <ActionIconButton
          aria-label='view first log'
          disabled={!hasPrevious}
          icon={faAnglesLeft}
          onClick={() => props.onChange(0)}
        />
      </Tooltip>
      <Tooltip arrow placement='top' title='View Previous Log' wrap>
        <ActionIconButton
          aria-label='view previous log'
          disabled={!hasPrevious}
          icon={faAngleLeft}
          onClick={() => props.onChange(props.current - 1)}
        />
      </Tooltip>
      <Typography variant='h6'>{props.count ? `${props.current + 1} of ${props.count} Logs` : '0 Logs'}</Typography>
      <Tooltip arrow placement='top' title='View Next Log' wrap>
        <ActionIconButton
          aria-label='view next log'
          disabled={!hasNext}
          icon={faAngleRight}
          onClick={() => props.onChange(props.current + 1)}
        />
      </Tooltip>
      <Tooltip arrow placement='top' title='View Last Log' wrap>
        <ActionIconButton
          aria-label='view last log'
          disabled={!hasNext}
          icon={faAnglesRight}
          onClick={() => props.onChange(props.count - 1)}
        />
      </Tooltip>
    </div>
  );
}

export function LinkActionButtonComponent({
  navigate, // eslint-disable-line @typescript-eslint/no-unused-vars
  ...others
}: Record<string, any> & ActionIconButtonProps) {
  return <ActionIconButton {...others} />;
}
