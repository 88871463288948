import React from 'react';

import classnames from 'classnames';
import { useFormikContext } from 'formik';

import useOrganizationOptions from 'hooks/useOrganizationOptions';

import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import CheckboxFormik from 'module/Form/CheckboxFormik';
import TextFieldFormik from 'module/Form/TextFieldFormik';
import { TagDivider } from 'module/Layout/Artifact.widgets';
import AttackTagAutocompleteFormik from 'module/Tag/AttackTagAutocompleteFormik';
import { Discriminator } from 'module/Tag/Tag.type';
import TagAutocompleteFormik from 'module/Tag/TagAutocompleteFormik';
import { Highlight } from 'module/Widgets/SyntaxEditor';
import SyntaxEditorFormik from 'module/Widgets/SyntaxEditor/SyntaxEditorFormik';

import { FunctionalPermission } from 'types/auth';

import { simpleParse } from 'utilities/YamlUtils';

import { AttackScriptFormContainer } from '../AttackScript.style';
import { AtomicTestAttackScript, AtomicTestScript, AttackScriptFormInput } from '../AttackScript.type';

type AttackScriptFormProps = {
  className?: string;
  disableCoreFields?: boolean;
};

function AttackScriptForm({ className, disableCoreFields }: AttackScriptFormProps) {
  const orgOptions = useOrganizationOptions(FunctionalPermission.BASStableFeatures);

  const { values } = useFormikContext<AttackScriptFormInput>();

  const [, scriptParseError] = React.useMemo(() => {
    return simpleParse<AtomicTestScript>(values.script);
  }, [values.script]);

  const scriptError = scriptParseError?.message.split('\n\n')[0];

  const [, AttackScriptParseError] = React.useMemo(() => {
    return simpleParse<AtomicTestAttackScript>(values.validation);
  }, [values.validation]);

  const AttackScriptError = AttackScriptParseError?.message.split('\n\n')[0];

  return (
    <AttackScriptFormContainer className={classnames('AttackScriptForm ArtifactWidget-form', className)}>
      <TextFieldFormik label='Owner' name='owner' disabled />
      <AutocompleteFormik
        label='Organization'
        name='organization_id'
        options={orgOptions}
        disableUserAdditions
        required
        disabled={disableCoreFields}
      />
      <AttackTagAutocompleteFormik techniques name='attack_name' required disabled={disableCoreFields} />
      <AutocompleteFormik label='References' multiple name='references' options={[]} />
      <SyntaxEditorFormik
        hasError={!!scriptParseError}
        helperText={scriptError}
        label='Attack Script'
        name='script'
        highlight={Highlight.yaml}
        disabled={disableCoreFields}
      />
      <SyntaxEditorFormik
        hasError={!!AttackScriptParseError}
        helperText={AttackScriptError}
        label='Success Criteria'
        name='validation'
        highlight={Highlight.yaml}
      />
      <CheckboxFormik name='simulated' label='Simulated' disabled={disableCoreFields} />
      <TagDivider />
      <TagAutocompleteFormik name='attack_names' discriminator={Discriminator.Attack} multiple />
      <TagAutocompleteFormik name='actor_names' discriminator={Discriminator.Actor} multiple />
      <TagAutocompleteFormik name='software_names' discriminator={Discriminator.Software} multiple />
      <TagAutocompleteFormik name='vulnerability_names' discriminator={Discriminator.Vulnerability} multiple />
    </AttackScriptFormContainer>
  );
}

export default AttackScriptForm;
