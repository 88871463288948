import React from 'react';

import { faBuildingCircleArrowRight } from '@fortawesome/pro-solid-svg-icons';

import { styled } from 'snap-ui/util';

import { BurgerClicker } from 'module/Layout/Artifact.widgets';
import { SupplementalArtifact } from 'module/Search';

import { useAuth } from 'provider';

import { Artifact, ArtifactType } from 'types/common';

import MoveOrgModal from './MoveOrgModal';

export const ButtonTitles = {
  [ArtifactType.Analytic]: 'Move Detection',
  [ArtifactType.AttackScript]: 'Move Attack Script',
  [ArtifactType.Intel]: 'Move Intel'
};

type MoveOrgDialogProps = {
  artifact: Artifact & { raw?: string };
  supplemental?: SupplementalArtifact;
};

type MoveOrgModalInterfaceProps = {
  children: React.ReactNode;
  type: ArtifactType.Analytic | ArtifactType.Intel | ArtifactType.AttackScript;
};

export interface MoveOrgModalInterface {
  isOpen: boolean;
  setIsOpen(open: boolean): void;
  type: ArtifactType.Analytic | ArtifactType.Intel | ArtifactType.AttackScript;
}

const StyledBurgerClicker = styled(BurgerClicker)`
  svg {
    padding-left: 2px;
  }
`;

const MoveOrgModalContext = React.createContext<MoveOrgModalInterface>(null);

export function MoveOrgModalInterface({ children, type }: MoveOrgModalInterfaceProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  return <MoveOrgModalContext.Provider value={{ isOpen, setIsOpen, type }}>{children}</MoveOrgModalContext.Provider>;
}

function useMoveOrgModal() {
  const context = React.useContext(MoveOrgModalContext);
  if (!context) {
    throw new Error('useMoveOrgModal must be used within an MoveOrgModalContext');
  }
  return context;
}

export function MoveOrgModalButton() {
  const { setIsOpen, type } = useMoveOrgModal();
  const { user } = useAuth();

  if (!user.superuser) return null;

  return (
    <StyledBurgerClicker
      title={ButtonTitles[type]}
      aria-label={ButtonTitles[type]}
      icon={faBuildingCircleArrowRight}
      onClick={() => setIsOpen(true)}
    />
  );
}

export function MoveOrgDialog({ artifact, supplemental }: MoveOrgDialogProps) {
  const { isOpen, setIsOpen, type } = useMoveOrgModal();
  const { user } = useAuth();

  if (!user.superuser) return null;

  return (
    <MoveOrgModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      type={type}
      artifact={artifact}
      supplemental={supplemental}
    />
  );
}
