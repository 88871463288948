import { NistCoverage } from './NistDashboard.type';

/**
 * Returns the coverage of a Nist based on deployed. Coverage can be "Covered, Improving or Risk"
 */
export function determineNistCoverage(deployed: number) {
  if (deployed >= 5) {
    return NistCoverage.Covered;
  } else if (deployed >= 1 && deployed <= 4) {
    return NistCoverage.Improving;
  } else {
    return NistCoverage.Risk;
  }
}

export function getRandomNumbers() {
  let maxNumber;

  // Generating a random number between 0 and 1 to decide if maxNumber should be less than 4
  const shouldMaxNumberBeLow = Math.random() <= 0.35;

  if (shouldMaxNumberBeLow) {
    // 15% of the time, maxNumber should be between 1 and 3
    maxNumber = Math.floor(Math.random() * 3) + 1;
  } else {
    // 85% of the time, maxNumber should be between 4 and 100
    maxNumber = Math.floor(Math.random() * 97) + 4;
  }

  // Generating a random number between 0 and 1 to decide if randomNumber should be close to maxNumber
  const shouldStayClose = Math.random() <= 0.85;

  let randomNumber;
  if (shouldStayClose) {
    // 85% of the time, randomNumber should be no more than 4 apart from maxNumber
    const lowerBound = Math.max(0, maxNumber - 4);
    randomNumber = Math.floor(Math.random() * (maxNumber - lowerBound + 1)) + lowerBound;
  } else {
    // 15% of the time, randomNumber can be anywhere between 0 and maxNumber
    randomNumber = Math.floor(Math.random() * (maxNumber + 1));
  }

  // Returning maxNumber and randomNumber in an array
  return [maxNumber, randomNumber];
}

export function nistIs(deployed: number, coverage: NistCoverage): boolean {
  return determineNistCoverage(deployed) === coverage;
}
