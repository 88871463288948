/* eslint-disable react/prop-types */
import React from 'react';

import { faCircle as faCircleSolid, faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';

import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import { useTheme } from 'snap-ui/util';

import { MarkerColor } from 'module/Session/Session.const';
import { VideoMarker } from 'module/Session/VideoPlayer/VideoPlayer.style';

import { Ident } from 'types/common';
import { BlueMarker, MarkerExtended, RedMarker } from 'types/marker';

import { divide } from 'utilities/NumberUtil';
import { getDiff } from 'utilities/TimeUtils';

type Props = {
  containerRef: HTMLElement;
  duration: number;
  id: Ident;
  color: MarkerColor;
  hostId: Ident;
  timestamp: string;
  marker: MarkerExtended;
  onClick: (offset: number) => void;
  startTime: string;
};

function IconBase({ color, marker }: { color: string; marker: MarkerExtended }): React.ReactElement {
  const { palette } = useTheme();
  const icon =
    color === MarkerColor.Blue && marker.lonely ? (
      <Icon
        color='secondary'
        icon={faCircleSolid}
        style={{
          stroke: palette.blue.main,
          strokeWidth: 40
        }}
      />
    ) : color === MarkerColor.Blue ? (
      <Icon color='blue' icon={faCircleSolid} />
    ) : color === MarkerColor.Red && marker.lonely ? (
      <Icon
        color='secondary'
        icon={faStarSolid}
        style={{
          stroke: palette.error.main,
          strokeWidth: 40
        }}
      />
    ) : (
      <Icon color='error' icon={faStarSolid} />
    );
  return icon;
}

function Marker(props: Props): React.ReactElement {
  // The first param to use in getDiff may differ based on color 🥴
  const offset = getDiff(props.timestamp || props.timestamp, props.startTime);

  if (offset < 0 || offset > props.duration) {
    return null;
  }

  return (
    <Tooltip arrow placement='bottom' PopperProps={{ container: props.containerRef }} title={getContent()}>
      <VideoMarker
        className={classnames(props.color)}
        onClick={e => {
          e.stopPropagation();
          props.onClick(offset);
        }}
        style={getStyle()}
        data-testid={`marker-${props.id}`}
      >
        <IconBase color={props.color} marker={props.marker} />
      </VideoMarker>
    </Tooltip>
  );

  function getContent(): React.ReactNode {
    if (props.color === MarkerColor.Red) {
      const rm = props.marker as RedMarker;
      return <>{rm.description || rm.attack_names?.[0]}</>;
    }
    return (props.marker as BlueMarker).analytics?.[0]?.name;
  }

  function getStyle(): { left: string; zIndex: number } {
    let zIndex = 10;
    if (props.color === MarkerColor.Red) zIndex += 2;
    //if (marker.lonely) zIndex += 1;

    return { left: `${divide(offset, props.duration) * 100}%`, zIndex };
  }
}

export default Marker;
