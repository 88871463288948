import React from 'react';

import classNames from 'classnames';
import { useField } from 'formik';

import TextField, { TextFieldProps } from 'snap-ui/TextField';
import { styled } from 'snap-ui/util';

const formatErrors = errors => {
  errors = typeof errors === 'string' ? [errors] : errors;
  return (
    <>
      {errors.map((error, index) => (
        <span key={index} data-testid={`errors-${index}`}>
          {error}
        </span>
      ))}
    </>
  );
};

const StyledTextField = styled(TextField)`
  .MuiFormHelperText-root {
    display: flex;
    flex-direction: column;
  }

  .MuiFormHelperText-root > * {
    display: block;
  }

  .Mui-error > .TextFieldFormik-helperText {
    color: hsl(0, 0%, 44%);
  }
`;

export type TextFieldFormikProps = TextFieldProps & {
  helperText?: React.ReactNode | string;
  label: React.ReactNode;
  name: string;
  disabled?: boolean;
  placeholder?: string;
  multiline?: boolean;
};

export default function TextFieldFormik({
  className,
  helperText,
  name,
  required,
  elevated = true,
  type = 'text',
  onChange,
  ...others
}: TextFieldFormikProps): JSX.Element {
  const [field, meta, helpers] = useField(name);
  const hasError = !!(meta.touched && meta.error);

  function handleBlur() {
    helpers.setTouched(true);
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newValue = type === 'number' ? e.target.valueAsNumber || 0 : e.target.value;
    helpers.setValue(newValue);
    if (onChange) onChange(e);
  }

  return (
    <StyledTextField
      className={classNames('Form-field', className, { required: required, valid: meta.touched && !meta.error })}
      error={hasError}
      helperText={
        <>
          {helperText && <span className='TextFieldFormik-helperText'>{helperText}</span>}
          {hasError && formatErrors(meta.error)}
        </>
      }
      id={name}
      onBlur={handleBlur}
      onChange={handleChange}
      value={field.value}
      elevated={elevated}
      type={type}
      {...others}
    />
  );
}
