import React from 'react';

import MuiRadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import classnames from 'classnames';

import { StrictReactNode } from 'types/core';

type Props = {
  children: StrictReactNode;
  className?: string;
  defaultValue?: RadioGroupProps['defaultValue'];
  name?: string;
  onChange?: RadioGroupProps['onChange'];
  row?: boolean;
  value?: RadioGroupProps['value'];
};

export default function RadioGroup({ children, className, ...others }: Props) {
  return (
    <MuiRadioGroup className={classnames('RadioGroup', className)} {...others}>
      {children}
    </MuiRadioGroup>
  );
}
