import React from 'react';

import classnames from 'classnames';
import classNames from 'classnames';
import { useField } from 'formik';

import { FormControl, FormHelperText } from 'snap-ui/FormControl';
import FormLabel from 'snap-ui/FormLabel';
import { styled } from 'snap-ui/util';

import SyntaxEditor from './SyntaxEditor';

type SyntaxEditorFormikProps = {
  className?: string;
  disabled?: boolean;
  hasError?: boolean;
  helperText?: string;
  highlight?(value: string): React.ReactNode;
  label: string;
  name: string;
};

const StyledSyntaxEditor = styled(SyntaxEditor)`
  border: 1px solid ${p => p.theme.palette.surface.fieldOutline};

  &.error {
    border: 1px solid ${p => p.theme.palette.error.main};
  }
`;

export default function SyntaxEditorFormik({
  className,
  disabled,
  hasError,
  helperText,
  highlight,
  label,
  name
}: SyntaxEditorFormikProps): JSX.Element {
  const [field, , { setValue }] = useField(name);

  return (
    <FormControl
      error={hasError}
      component='fieldset'
      className={classnames('SyntaxEditorFormik Form-field required', className)}
    >
      {label && <FormLabel component='legend'>{label}</FormLabel>}

      <StyledSyntaxEditor
        className={classNames({ error: hasError })}
        highlight={highlight}
        onChange={setValue}
        value={field.value}
        elevation={8}
        disabled={disabled}
      />
      {helperText && <FormHelperText className='SyntaxEditorFormik-helperText'>{helperText}</FormHelperText>}
    </FormControl>
  );
}
