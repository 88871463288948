import React from 'react';

import useAnalyticWithMeta, { AnalyticCatalog } from 'aso/useAnalyticWithMeta';

import useLocationState from 'hooks/useLocationState';

import { Guid } from 'types/common';

import { AnalyticRouterState } from '../Analytic.type';

const AnalyticContext = React.createContext<AnalyticCatalog>(null);
AnalyticContext.displayName = 'AnalyticContext';

function useAnalyticCatalog(): AnalyticCatalog {
  const context = React.useContext<AnalyticCatalog>(AnalyticContext);

  if (!context) {
    throw new Error('useAnalyticCatalog must be used within the AnalyticContext');
  }

  return context;
}

function AnalyticProvider({ analyticGuid, children }: { analyticGuid: Guid; children: React.ReactNode }): JSX.Element {
  const { item, supplementalItem } = useLocationState<AnalyticRouterState>() || {};
  const catalog = useAnalyticWithMeta(analyticGuid, item, supplementalItem);

  return <AnalyticContext.Provider value={catalog}>{children}</AnalyticContext.Provider>;
}

export { AnalyticProvider, useAnalyticCatalog };
