import React from 'react';

import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { z } from 'zod';

import Alert, { AlertTitle } from 'snap-ui/Alert';
import { Option } from 'snap-ui/Autocomplete';
import CircularProgress from 'snap-ui/CircularProgress';
import { FormDialog } from 'snap-ui/Dialog';
import { FieldsLayout } from 'snap-ui/Layout';

import { ApiError } from 'module/ApiError';
import { standardFormikBaseProps } from 'module/Form';
import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import TextFieldFormik from 'module/Form/TextFieldFormik';

import { ErrorProps } from 'storage';

import { FunctionalPermission } from 'types/auth';

import { useEntitlementGroups } from './Entitlements.service';
import { EntitlementPayload } from './Entitlements.type';

type EntitlementFormDialogProps = {
  isLoading: boolean;
  isBasePackage: boolean;
  apiErrorProps?: ErrorProps;
  className?: string;
  initialTarget?: EntitlementPayload;
  onClose(): void;
  onSubmit(values: EntitlementPayload): void;
  open: boolean;
};

const RIGHTS = Object.values(omit(FunctionalPermission, 'All'));
const RIGHT_OPTIONS: Option[] = RIGHTS.map(right => ({
  value: right.toString(),
  content: right.toString()
}));

function EntitlementFormDialog(props: EntitlementFormDialogProps): JSX.Element {
  const { groups, errorProps: groupsErrorProps } = useEntitlementGroups();

  const groupOptions: Option[] = React.useMemo(() => {
    if (groups.length === 0) return [];
    return groups.map(group => ({
      value: group.guid,
      content: group.name
    }));
  }, [groups]);

  return (
    <FormDialog
      className={props.className}
      DialogProps={{ open: props.open, onClose: props.onClose }}
      FormikConfig={{
        ...standardFormikBaseProps,
        initialValues: props.initialTarget,
        onSubmit: props.onSubmit,
        zodSchema: z
          .object({
            name: z.string().nonempty('A name is required'),
            description: z.string().nonempty('A description is required'),
            rights: z.array(z.string()).optional(),
            groups: z.array(z.string()).optional()
          })
          .superRefine(({ rights, groups }, ctx) => {
            if (isEmpty(rights) && isEmpty(groups)) {
              ['rights', 'groups'].forEach(field =>
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Either rights or groups must be specified',
                  path: [field]
                })
              );
            }
          })
      }}
      SubmitProps={{
        children: props.isLoading ? <CircularProgress size={25} /> : props.initialTarget.name ? 'Update' : 'Create',
        disabled: props.isLoading
      }}
      title={props.initialTarget.name ? `Update ${props.initialTarget.name}` : 'Create New Entitlement'}
    >
      <FieldsLayout>
        {props.isBasePackage && (
          <Alert severity='warning'>
            <AlertTitle>Editing Base Package</AlertTitle>
            You are editing a base package that changes the permissions across multiple organizations and subscription
            tiers. Exercise extreme caution when making changes.
          </Alert>
        )}
        <ApiError {...props.apiErrorProps} />
        <ApiError {...groupsErrorProps} />
        <TextFieldFormik label='Name' name='name' required />
        <TextFieldFormik label='Description' name='description' multiline minRows={2} required />
        <AutocompleteFormik label='Rights' name='rights' multiple options={RIGHT_OPTIONS} disableUserAdditions />
        <AutocompleteFormik label='Groups' name='groups' multiple options={groupOptions} disableUserAdditions />
      </FieldsLayout>
    </FormDialog>
  );
}

export default EntitlementFormDialog;
