import React from 'react';

import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';

import { PieChart } from 'snap-ui/Charts';
import Icon from 'snap-ui/Icon/Icon';
import { useTheme } from 'snap-ui/util';

import { renderValueWithLabel } from 'utilities/TextUtils';

import { HitsChartContainer } from './AnalyticTuningHeader.style';

export type HitsChartProps = {
  hits: number;
  reduction: number;
  percentReduction: number;
};

export default function HitsChart(props: HitsChartProps) {
  const { palette } = useTheme();
  const { hits = 0, reduction = 0, percentReduction = 0 } = props;

  return (
    <HitsChartContainer>
      <div className='chart-text'>
        <div className='chart-reduction-text'>
          <div>{renderValueWithLabel(hits + reduction, 'Hit')}</div>
          <div>
            <Icon icon={faArrowRight} />
          </div>
          <div>{renderValueWithLabel(hits, 'Hit')}</div>
        </div>
        <div>{percentReduction.toFixed(0)}% Reduction</div>
      </div>
      <PieChart
        height={80}
        width={80}
        colors={[palette.stealth, palette.success]}
        data={[
          {
            name: 'Hits',
            value: hits || 1 // forces recharts to render, otherwise looks horrible
          },
          {
            name: 'Reduction',
            value: reduction
          }
        ]}
        innerRadius={16}
        outerRadius={40}
        useTooltip={!!hits} // works in conjunction with value: hits || 1 - we dont want a tooltip showing an incorrect value
      />
    </HitsChartContainer>
  );
}
