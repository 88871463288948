import React, { ReactElement } from 'react';

import Chip from 'snap-ui/Chip';
import Icon from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

import { CollectionDiscriminator } from 'module/Collection/Collection.type';
import { isIOCCompatibleCollection } from 'module/IOC/IOC.type';
import { useMayI } from 'module/May';
import MarkerBadge from 'module/Widgets/MarkerBadge';

import { useAuth } from 'provider/Account';

import { FunctionalPermission } from 'types/auth';
import { Artifact } from 'types/common';

type ReferenceCountsProps = {
  artifactType: Artifact['type'];
  indicators: number;
  intels: number;
  sessions: number;
  analytics: number;
  attackScripts?: number;
  showTotal?: boolean;
  indicatorLabel?: string;
  intelLabel?: string;
  threatLabel?: string;
  detectionLabel?: string;
  attackScriptLabel?: string;
};

const Container = styled('div', { label: 'ReferenceCounts' })`
  display: flex;
  gap: ${p => p.theme.spacing(1)};
  align-items: center;
  word-break: keep-all;
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
`;

export const IOCBadge = ({
  iocs = 0,
  iocLabel = 'Included IOCs'
}: Pick<IntelReferenceCountsProps, 'iocs' | 'iocLabel'>): ReactElement => {
  return <MarkerBadge count={iocs} icon={<Icon.IOC />} label={`${iocs} ${iocLabel}`} />;
};

const ReferenceCounts = ({
  artifactType,
  indicators = 0,
  intels = 0,
  sessions = 0,
  analytics = 0,
  attackScripts = 0,
  indicatorLabel = 'Related Indicators',
  intelLabel = 'Related Intelligence',
  threatLabel = 'Related Threats',
  detectionLabel = 'Related Detections',
  attackScriptLabel = 'Related Attack Simulations'
}: ReferenceCountsProps): ReactElement => {
  const isBasUser = useMayI(FunctionalPermission.BASStableFeatures);
  const { isSubscriber } = useAuth();

  return (
    <>
      <MarkerBadge count={intels} icon={<Icon.Intel />} label={`${intels} ${intelLabel}`} />
      <MarkerBadge count={sessions} icon={<Icon.Session />} label={`${sessions} ${threatLabel}`} />
      <MarkerBadge count={analytics} icon={<Icon.Analytic />} label={`${analytics} ${detectionLabel}`} />

      {isBasUser && (
        <MarkerBadge
          count={attackScripts}
          icon={<Icon.AttackScript />}
          label={`${attackScripts} ${attackScriptLabel}`}
        />
      )}

      {isSubscriber && isIOCCompatibleCollection(artifactType as CollectionDiscriminator) && (
        <IOCBadge iocs={indicators} iocLabel={indicatorLabel} />
      )}
    </>
  );
};

export default ReferenceCounts;

type IntelReferenceCountsProps = {
  className?: string;
  references: number;
  iocs: number;
  iocLabel?: string;
};

export const IntelReferenceCounts = ({
  className,
  references,
  iocs = 0,
  iocLabel
}: IntelReferenceCountsProps): ReactElement => {
  return (
    <>
      <Container className={className}>
        <strong>References</strong>
        <Chip label={references} />
      </Container>
      <IOCBadge iocs={iocs} iocLabel={iocLabel} />
    </>
  );
};
