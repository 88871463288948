import React from 'react';

import { default as classNames } from 'classnames';
import { Link } from 'react-router-dom';

import Chip from 'snap-ui/Chip';

import Path from 'constants/paths';

import { HyperTag } from 'module/Landing/Landing.type';
import { getArtifactIcon } from 'module/Layout/Artifact.helper';
import { Discriminator } from 'module/Tag';

const AttackChip = React.forwardRef<
  HTMLAnchorElement,
  {
    onClick(e: React.MouseEvent): void;
    tag: Partial<HyperTag>;
    className?: string;
  }
>(function AttackChip({ onClick, tag, className }, ref) {
  if (!tag) return null;
  return (
    <Chip
      className={classNames('LandingSection-chip attack', className, tag.type.toLowerCase())}
      label={tag.name}
      component={Link}
      to={`${Path.Collection}/${tag.discriminator}/${encodeURIComponent(tag.name)}`}
      onClick={onClick}
      clickable
      ref={ref}
      icon={getArtifactIcon(Discriminator.Attack, true)}
    />
  );
});

export default AttackChip;
