import React from 'react';

import { getJobGroupResult } from 'module/Detection';
import { JobGroupResult } from 'module/Job';

import { ErrorProps, Status, useAsync } from 'storage';

import { Guid } from 'types/common';

type JobGroupResultInterface = {
  refresh(): void;
  result: JobGroupResult;
  status: Status;
  errorProps: ErrorProps;
};

export default function useJobGroupResult(guid: Guid): JobGroupResultInterface {
  const { data: result, run, status, errorProps } = useAsync<JobGroupResult>([]);

  const refresh = React.useCallback(() => {
    if (guid) run(getJobGroupResult(guid));
  }, [guid, run]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    refresh,
    result,
    status,
    errorProps
  };
}
