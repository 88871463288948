import React from 'react';

import { faCircleMinus, faCirclePlus } from '@fortawesome/pro-solid-svg-icons';

import { Autocomplete, Option } from 'snap-ui/Autocomplete';
import Button, { ActionIconButton } from 'snap-ui/Button';
import Tooltip from 'snap-ui/Tooltip';

import {
  FieldOption,
  KeyValueRule,
  SectionForm,
  SectionKind,
  SigmaModifier,
  SpecialSigmaModifier
} from 'types/analytic';
import { BooleanString } from 'types/common';

import * as util from './Detection.util';
import { EventIDValueField, FieldField, ValueField } from './KeyValueSectionFields';
import useIsWinEvent from './useIsWinEvent';

const modifierOptions: Option[] = [
  { content: 'Equals', label: 'Equals', value: SpecialSigmaModifier.Equal },
  {
    content: 'Starts With',
    label: 'Starts With',
    value: SigmaModifier.StartsWith
  },
  {
    content: 'Ends With',
    label: 'Ends With',
    value: SigmaModifier.EndsWith
  },
  {
    content: 'Contains',
    label: 'Contains',
    value: SigmaModifier.Contains
  }
];

const anyAllOptions: Option[] = [
  { content: 'Any of', label: 'Any of', value: BooleanString.False },
  { content: 'All of', label: 'All of', value: BooleanString.True }
];

type KeyValueSectionFormProps = {
  className?: string;
  disabled: boolean;

  // Based on user selected category. ie the logsource subtype (Event Type)
  eventIdOptions: Option[];

  // Based on user selected source. ie the logsource type (windows|sysmon|zeek)
  fields: FieldOption[];

  onChange(rules: SectionForm['rules']): void;
  section: SectionForm;
  sectionIndex: number;
  ignoreValidation: boolean;
};

function KeyValueSectionForm({
  className,
  disabled,
  eventIdOptions,
  fields,
  onChange,
  section,
  sectionIndex,
  ignoreValidation
}: KeyValueSectionFormProps): JSX.Element {
  const isWinEvent = useIsWinEvent();

  if (section.kind !== SectionKind.KeyValue) return null;

  function handleChangeRule(ruleIndex: number, update: Partial<KeyValueRule>) {
    onChange(util.changeRule(section, ruleIndex, update));
  }

  function handleAddRule() {
    onChange(util.addRule(section));
  }

  function handleChangeValue(ruleIndex: number, valueIndex: number, newValue: string) {
    onChange(util.changeRuleValue(section, ruleIndex, valueIndex, newValue));
  }

  function handleRemoveValue(ruleIndex: number, valueIndex: number) {
    onChange(util.removeValueFromRule(section, ruleIndex, valueIndex));
  }

  function handleAddvalue(ruleIndex: number) {
    onChange(util.addValueToRule(section, ruleIndex));
  }

  return (
    <div className={className}>
      {section.rules.map((rule, ruleIndex) => (
        <div className='ab-section-condition' key={ruleIndex}>
          <FieldField
            disabled={disabled}
            fields={fields}
            name={`detection.sections[${sectionIndex}].rules[${ruleIndex}].field`}
            onChange={field => handleChangeRule(ruleIndex, { field })}
            rule={rule}
            ruleIndex={ruleIndex}
            section={section}
            ignoreValidation={ignoreValidation}
          />
          <Autocomplete
            testId={`comparison-${ruleIndex}`}
            label='Comparison'
            className='modifier'
            disabled={disabled}
            name={`detection.sections[${sectionIndex}].rules[${ruleIndex}].modifier`}
            options={modifierOptions}
            onChange={modifier => handleChangeRule(ruleIndex, { modifier: modifier as SigmaModifier })}
            value={modifierOptions.find(o => o.value === rule.modifier)?.label || rule.modifier}
            placeholder='Comparison'
            disableClearable
            disableUserAdditions
          />
          <React.Fragment key={rule.values.length}>
            {(rule.values.length ? rule.values : ['']).map((value, valueIndex) => (
              <React.Fragment key={valueIndex}>
                {rule.field === 'EventID' && isWinEvent ? (
                  <EventIDValueField
                    disabled={disabled}
                    eventIdOptions={eventIdOptions}
                    name={`detection.sections[${sectionIndex}].rules[${ruleIndex}].values[${valueIndex}]`}
                    onChange={value => handleChangeValue(ruleIndex, valueIndex, value)}
                    showWarning={!eventIdOptions.some(opt => opt.value === value)}
                    value={value}
                  />
                ) : (
                  <ValueField
                    disabled={disabled}
                    name={`detection.sections[${sectionIndex}].rules[${ruleIndex}].values[${valueIndex}]`}
                    onChange={value => handleChangeValue(ruleIndex, valueIndex, value)}
                    value={value}
                    ruleIndex={ruleIndex}
                  />
                )}
                {!disabled && (
                  <Tooltip arrow placement='left' title='Delete Rule' wrap>
                    <ActionIconButton
                      aria-label={`Delete rule`}
                      className='link'
                      disabled={disabled}
                      onClick={() => handleRemoveValue(ruleIndex, valueIndex)}
                      icon={faCircleMinus}
                    />
                  </Tooltip>
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
          {rule.values.length > 1 && (
            <Autocomplete
              testId={`all-${ruleIndex}`}
              label='Match With'
              className='ab-all'
              disabled={disabled}
              name={`detection.sections[${sectionIndex}].rules[${ruleIndex}].all`}
              options={anyAllOptions}
              onChange={all => handleChangeRule(ruleIndex, { all: all as BooleanString })}
              value={anyAllOptions.find(o => o.value === rule.all)?.label || rule.all}
              disableClearable
              disableUserAdditions
            />
          )}
          {!disabled && (
            <Tooltip
              arrow
              className='add-field-rule'
              placement='left'
              title={rule.field ? `Add rule for ${rule.field}` : ''}
              wrap
            >
              <ActionIconButton
                aria-label={`Add rule for ${rule.field}`}
                className='add-field-rule link'
                disabled={disabled || !rule.field}
                onClick={() => handleAddvalue(ruleIndex)}
                icon={faCirclePlus}
              />
            </Tooltip>
          )}
        </div>
      ))}
      {!disabled && (
        <div className='ab-section-actions'>
          <Button disabled={disabled} onClick={handleAddRule}>
            Add Rule
          </Button>
        </div>
      )}
    </div>
  );
}

export default KeyValueSectionForm;
