import React from 'react';

import { styled } from 'snap-ui/util';

import { useIDEState } from 'module/IDE';
import AdvancedEditor from 'module/IDE/core/AdvancedEditor';
import { Highlight } from 'module/Widgets/SyntaxEditor';

import { useLanguageContext } from 'provider';

import LanguageField from './LanguageField';

export const BOUNCE = 1300;

export const EditorWrapper = styled('div', { label: 'Import-formField' })`
  padding: ${p => p.theme.spacing(5)};
  border: 1px solid ${p => p.theme.palette.grey[700]};
  background-color: ${p => p.theme.palette.grey[900]};
`;

export default function NativeAnalyticForm({ readonly }: { readonly?: boolean }): React.ReactElement {
  const { ideState, dispatch } = useIDEState();
  const { data: languageList } = useLanguageContext();
  const selectedLanguage = languageList.find(language => language.id === ideState.analyticForm.languageId);

  function handleLanguageChange(languageId: number) {
    dispatch({ type: 'FormUpdateAction', analyticForm: { languageId } });
  }

  return (
    <>
      <LanguageField
        disabled={!!ideState.guid || readonly}
        value={ideState.analyticForm.languageId}
        onChange={handleLanguageChange}
      />
      <div>
        <label htmlFor='raw'>Detection Code</label>
        <EditorWrapper>
          <AdvancedEditor protocol={Highlight[selectedLanguage?.native_key]} readonly={readonly} />
        </EditorWrapper>
      </div>
    </>
  );
}
