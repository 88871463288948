import React from 'react';

import { Link } from 'react-router-dom';

import Paper from 'snap-ui/Paper';
import Table, { TablePlaceholder } from 'snap-ui/Table';
import { styled } from 'snap-ui/util';

import useAgents from 'aso/useAgents';

import Path from 'constants/paths';

import useDebounce from 'hooks/useDebounce';
import useTitle from 'hooks/useTitle';

import { AttackJobStateLabel } from 'module/Widgets/StateLabel';
import TablePagination from 'module/Widgets/TablePagination';
import TableToolbar from 'module/Widgets/TableToolbar';

import { Status } from 'storage';

import { CompositeFilter, Ops } from 'types/filter';

import { pageTotal } from 'utilities/NumberUtil';
import { formatShortTimestamp } from 'utilities/TimeUtils';

import useCampaigns from '../useCampaigns';
import CampaignActions from './CampaignActions';
import CampaignAttackCount from './CampaignAttackCount';
import CampaignHosts from './CampaignHosts';
import CampaignOutcomes from './CampaignOutcomes';
import CampaignStatus from './CampaignStatus';

const Container = styled(Paper)`
  .loader-container {
    height: 300px;
    position: relative;
  }
`;

const StateLabel = styled(AttackJobStateLabel)`
  width: 100%;
`;

function getQueryPayload(query: string): CompositeFilter {
  if (!query) return undefined;
  return {
    op: Ops.and,
    items: [
      {
        field: 'text',
        op: Ops.contains,
        value: query
      }
    ]
  };
}

export default function CampaignList(): JSX.Element {
  useTitle('Attack Simulation Executions | SnapAttack');
  const { data, getPage, status } = useCampaigns();
  const agentInterface = useAgents();
  const [searchTerm, setSearchTerm] = React.useState<string>();
  const query = useDebounce(searchTerm, 1000);

  const refresh = React.useCallback(
    (page = 0) => {
      if (query) {
        getPage({ page }, getQueryPayload(query));
      } else {
        getPage({ page });
      }
    },
    [getPage, query]
  );

  React.useEffect(() => {
    refresh(0);
  }, [refresh]);

  return (
    <Container>
      <TableToolbar onSearchChange={setSearchTerm} placeholder='Search by attack simulation name' />
      <Table
        // Using a non-breaking space unicode character to prevent splitting 'Attack Count' label to two lines
        columns={['Name', 'Status', 'End Time', 'Hosts', 'Attack Count', 'Attacks', 'Outcomes', '']}
        component='div'
        rows={data.items.map(c => [
          <Link key='name' to={{ pathname: `${Path.BASJob}/${c.guid}`, state: c }}>
            {c.name || 'link'}
          </Link>,
          <StateLabel key='state' value={c.state} />,
          formatShortTimestamp(c.last_execution),
          <CampaignHosts key='hosts' agents={c.agents} />,
          <CampaignAttackCount key='result_status' result_status={c.result_status} />,
          <CampaignStatus key='result_status' result_status={c.result_status} />,
          <CampaignOutcomes key='outcomes' outcomes={c.outcomes} />,
          <CampaignActions key='actions' campaign={c} refresh={refresh} agentInterface={agentInterface} />
        ])}
      />
      {status === Status.pending && <TablePlaceholder count={10} height={30} />}
      <TablePagination
        changePage={refresh}
        numPages={pageTotal(data.total, data.size)}
        page={data.page}
        zeroIndex={true}
      />
    </Container>
  );
}
