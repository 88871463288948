import Detection from '../Detection';
import Token from '../Token';

/**
 * This is duplicated within Markup.ts. Leave alone for now.
 * @param rule
 */
function escapeRule(rule: string): string {
  return rule ? rule.replace(/\\/g, '\\\\').replace(/\*/g, '') : '';
}

/**
 * Tokens with Modifier.full don't have a property name associated at creation time. It
 * all depends on the particular log being viewed. That log must be searched. This function
 * does that for us.
 *
 * The function assumes you are only sending in tokens with modifier, Modifier.full.
 *
 * When a property's content matches the token criteria, a new token is created with the matched
 * property name.
 *
 * @param property
 * @param used
 * @param token
 */
export default function fullTextSearch(property: string[][], used: string[], token: Token): Token {
  for (const [name, value] of property) {
    if (used.includes(name)) continue;

    let result: Token = null;

    if (token.criterion) {
      token.criterion.forEach(c => {
        if (c && RegExp(escapeRule(c), 'ig').test(value)) {
          if (!result) {
            result = {
              ...token,
              property: name
            };
          }

          const log = Array.isArray(value) ? value : [value];
          log.forEach(l => {
            const c = new Detection(l, result).run();
            result.compiled = c.compiled;
            result.satisfied = c.satisfied;
          });
        }
      });

      if (result !== null) return result;
    }
  }

  return null;
}
