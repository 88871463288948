import React from 'react';

import { CancelToken } from 'apis';

import { ErrorProps } from 'module/ApiError';

import { createSimpleProvider, useIntegrationCatalog } from 'provider';

import { useAsync, Status } from 'storage';

import { SigmaLogsource } from 'types/analytic';
import { Guid, Ident } from 'types/common';

import { ReverseFieldMap } from './';
import { getReverseMap } from './api';

function getKey(languageId: Ident, logsource: SigmaLogsource): string {
  return `${languageId}_${logsource.product}_${logsource.category}_${logsource.service}`;
}

type ReverseMapCatalog = {
  getMapping(languageId: Ident, logsource?: SigmaLogsource): Promise<ReverseFieldMap>;
};

function _useReverseMapCatalog(): ReverseMapCatalog {
  const reverseMaps = React.useRef<Map<string, Promise<ReverseFieldMap>>>(new Map());

  const getMapping = React.useCallback((languageId: Ident, logsource: SigmaLogsource = {}) => {
    const key = getKey(languageId, logsource);
    if (!reverseMaps.current.has(key)) reverseMaps.current.set(key, getReverseMap(languageId, logsource));
    return reverseMaps.current.get(key);
  }, []);

  return { getMapping };
}

const [ReverseMapProvider, useReverseMapCatalog] = createSimpleProvider('ReverseMapProvider', _useReverseMapCatalog);

export { ReverseMapProvider, useReverseMapCatalog };

type ReverseMapInterface = {
  errorProps?: ErrorProps;
  isPending: boolean;
  map: ReverseFieldMap;
};

export function useReverseMap(languageId: Ident, logsource: SigmaLogsource = {}): ReverseMapInterface {
  const { getMapping } = useReverseMapCatalog();
  const { data, errorProps, run, status } = useAsync<ReverseFieldMap>({});
  const { product, category, service } = logsource;

  React.useEffect(() => {
    if (languageId) {
      run(getMapping(languageId, { product, category, service }));
    }
  }, [getMapping, languageId, product, category, service, run]);

  return {
    errorProps,
    isPending: status === Status.pending,
    map: data
  };
}

export function useReverseMapsForIntegration(integrationGuid?: Guid): { isPending: boolean; maps: ReverseFieldMap[] } {
  const [isPending, setIsPending] = React.useState(false);
  const [maps, setMaps] = React.useState<ReverseFieldMap[]>([]);
  const { integrations } = useIntegrationCatalog();

  React.useEffect(() => {
    const cancelToken = CancelToken.source();
    if (integrationGuid) {
      const targets = integrations.all
        .find(integration => integration.guid === integrationGuid)
        .hunt_targets.map(t => t.id);
      if (targets.length) {
        setIsPending(true);
        Promise.all(targets.map(target => getReverseMap(target, {}, cancelToken.token)))
          .then(setMaps)
          .finally(() => setIsPending(false));
      }
    } else {
      setIsPending(false);
    }
    return () => cancelToken.cancel();
  }, [integrationGuid, integrations.all]);

  return { isPending, maps };
}
