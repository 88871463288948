import React from 'react';

import { faX } from '@fortawesome/pro-solid-svg-icons';

import { ActionIconButton } from 'snap-ui/Button';
import { default as _TextField } from 'snap-ui/TextField';

import useDebounce from 'hooks/useDebounce';

import {
  BulkActionForm,
  InlineEditorForm,
  JsonSchema,
  useInlineEditor,
  withJsonFormsControlProps
} from 'module/JsonView';

import { SINGLE_VALUE_BULK_OPTIONS } from '../Metadata.const';
import { isMetaEmpty } from '../Metadata.util';
import { useBulkEditor } from '../MetadataBulkEditInterface';
import { Control, ControlProps } from './Control.type';
import { isControlApplied } from './Control.util';

function InlineNumber(props: { data: number; enabled: boolean; path: string; schema: JsonSchema; type: string }) {
  const { data, enabled, path, schema, type } = props;
  const editor = useInlineEditor(data);
  return (
    <InlineEditorForm data={data} editor={editor} enabled={enabled} path={path} title={schema.title} type={type}>
      <_TextField
        type='number'
        autoFocus
        value={editor.queue || ''}
        label={schema.title}
        helperText={editor?.error?.title ? editor.error.title : schema.description}
        error={!!editor?.error}
        onChange={e => editor.setQueue(e.target.value ? parseFloat(e.target.value) : null)}
        className={`Control Control-${Control.Text}`}
        elevated
      />
    </InlineEditorForm>
  );
}

export function FilterNumber(props: {
  data: number;
  onChange: ControlProps<number>['handleChange'];
  path: string;
  schema: JsonSchema;
}) {
  const { data, onChange, path, schema } = props;
  const [value, setValue] = React.useState(() => data || '');
  const queue = useDebounce(value, 500);

  React.useEffect(() => {
    onChange(path, queue);
  }, [onChange, path, queue]);

  return (
    <_TextField
      type='number'
      value={value}
      label={schema.title}
      helperText={schema.description}
      onChange={e => setValue(e.target.value ? parseFloat(e.target.value) : '')}
      className={`Control Control-${Control.Text}`}
      elevated
      InputProps={{
        endAdornment: (
          <ActionIconButton
            disabled={!queue}
            aria-label={`Clear ${schema.title}`}
            icon={faX}
            onClick={() => setValue('')}
          />
        )
      }}
    />
  );
}

function BulkActionNumber(props: { path: string; schema: JsonSchema; type: string }) {
  const { path, schema } = props;
  const editor = useBulkEditor(path);
  return (
    <BulkActionForm actions={SINGLE_VALUE_BULK_OPTIONS} editor={editor} title={schema.title}>
      <_TextField
        className={`Control Control-${Control.Text}`}
        elevated
        type='number'
        label='Value'
        onChange={e => editor.set(editor.operation, e.target.value ? parseFloat(e.target.value) : null)}
        value={editor.value}
      />
    </BulkActionForm>
  );
}

const _NumberControl = (props: ControlProps<number>) => {
  const { config, data, enabled, handleChange, path, schema } = props;

  if ((!config.asPreview && !isControlApplied(schema, config)) || (config.truncated && isMetaEmpty(data))) return null;
  if (config.asBulk) return <BulkActionNumber path={path} schema={schema} type={config.type} />;
  if (config.asInline)
    return <InlineNumber data={data} enabled={enabled} path={path} schema={schema} type={config.type} />;
  if (config.asFilter) return <FilterNumber data={data} onChange={handleChange} path={path} schema={schema} />;
  return (
    <_TextField
      type='number'
      value={data || ''}
      label={schema.title}
      helperText={schema.description}
      onChange={e => handleChange(path, e.target.value ? parseFloat(e.target.value) : null)}
      className={`Control Control-${Control.Text}`}
      elevated
      disabled={!enabled}
    />
  );
};
export const NumberControl = withJsonFormsControlProps(_NumberControl);
