import React from 'react';

import get from 'lodash/get';

import { useIDEState } from 'module/IDE';
import { BasicEditor } from 'module/Widgets/SyntaxEditor/BasicEditor';

interface AdvancedEditorProps {
  name?: string;
  onChange?(value: string): void;
  protocol?: (code: string) => string;
  readonly?: boolean;
}

export default function AdvancedEditor({
  name = 'raw',
  onChange,
  protocol,
  readonly
}: AdvancedEditorProps): React.ReactElement {
  const { dispatch, tagOptions, ideState } = useIDEState();
  const initialValue = get(ideState, name.split('.'));
  // debounce changes for render performance
  // we're not using useDebounce here because we don't want to fire the onChange on initial load, just with
  // BasicEditor's onChange
  const timer = React.useRef<NodeJS.Timeout>();
  const [_value, _setValue] = React.useState<string>(typeof initialValue === 'string' ? initialValue : '');

  function handleChange(value: string) {
    _setValue(value);
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      onChange ? onChange(value) : dispatch({ type: 'RawUpdateAction', raw: value, tagOptions });
    }, 250);
  }

  return (
    <BasicEditor
      className='AdvancedEditor'
      name={name}
      value={_value}
      highlight={protocol}
      onChange={handleChange}
      disabled={readonly}
    />
  );
}
