import React from 'react';

const paginate = (array, page_size, page_number) => {
  return array.slice(page_number * page_size, page_number * page_size + page_size);
};

export default function usePaginate<T>(data: Array<T>, pageSize: number) {
  const [pageData, setPageData] = React.useState(data);
  const [page, setPage] = React.useState(0);
  const [pageTotal, setPageTotal] = React.useState(Math.ceil(data.length / pageSize));

  const handleChangePage = React.useCallback(
    (newPage: number) => {
      if (newPage > pageTotal || newPage < 0) {
        return;
      }

      setPage(newPage);
    },
    [pageTotal]
  );

  React.useEffect(() => {
    setPageTotal(Math.ceil(data.length / pageSize));
    setPage(0);
  }, [data, pageSize]);

  React.useEffect(() => {
    setPageData(paginate([...data], pageSize, page));
  }, [page, data, pageSize]);

  return {
    page,
    pageData,
    pageTotal,
    handleChangePage
  };
}
