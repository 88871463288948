import React from 'react';

import { faBuildings, faCity, faHouse, faHouseBuilding } from '@fortawesome/pro-solid-svg-icons';

import Icon from 'snap-ui/Icon';

import { CheckableCardCheckboxFormik } from 'module/Form/CheckableCardFormik';

import ThreatProfileStep from '../ThreatProfileStep';

export default function Scale(): JSX.Element {
  return (
    <ThreatProfileStep
      title='At what scale do you operate?'
      description='The size of an organization significantly influences its security landscape, with larger entities often grappling with complex infrastructures, diverse endpoints, and heightened regulatory compliance requirements, necessitating extensive resources and strategic planning to effectively manage and mitigate potential cyber risks. Conversely, smaller organizations may face resource constraints but can benefit from more streamlined security operations, emphasizing the importance of scalable security measures tailored to the unique challenges of each organizational size.'
      content={
        <CheckableCardCheckboxFormik
          name='scale'
          options={[
            { value: '100', label: 'Small', detail: '1 - 100 Employees', icon: <Icon icon={faHouse} size='lg' /> },
            {
              value: '1000',
              label: 'Medium',
              detail: '101 - 1,000 Employees',
              icon: <Icon icon={faHouseBuilding} size='lg' />
            },
            {
              value: '10000',
              label: 'Large',
              detail: '1,001 - 10,000 Employees',
              icon: <Icon icon={faBuildings} size='lg' />
            },
            { value: '100000', label: 'Mega', detail: '10,000+ Employees', icon: <Icon icon={faCity} size='lg' /> }
          ]}
        />
      }
    />
  );
}
