import React from 'react';

import { RJSFSchema } from '@rjsf/utils';

import RjsfFormik from 'module/Form/RjsfFormik';

import { Guid } from 'types/common';

import SaveButton from './SaveButton';

type IOCProps = {
  integration_guid: Guid;
  integrationSchema: RJSFSchema;
};

export function IOCTab({ integrationSchema }: IOCProps) {
  return (
    <>
      <RjsfFormik name='extra_information' schema={integrationSchema} preserve />
      <SaveButton />
    </>
  );
}
