import React from 'react';

import { Order, SortDirection } from 'types/filter';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  sortFunction = null
): (a: { [key in Key] }, b: { [key in Key] }) => number {
  return order.direction === SortDirection.desc
    ? (a, b) => (sortFunction ? sortFunction(a, b) : descendingComparator(a, b, order.field as Key))
    : (a, b) => (sortFunction ? -sortFunction(a, b) : -descendingComparator(a, b, order.field as Key));
}

export type SortControls<T> = {
  createSortHandler(
    property: keyof T,
    sortFunction?: (a: T, b: T) => number
  ): (event: React.MouseEvent<unknown>) => void;
  order: Order;
  sortedData: T[];
};

export default function useSort<T>(data: Array<T>, initialSort: Order): SortControls<T> {
  const [sortedData, setSortedData] = React.useState(data);
  const [order, setOrder] = React.useState<Order>(initialSort);

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof T, sortFunction) => {
    const isAsc = order.field === property && order.direction === SortDirection.asc;
    const newOrder = {
      direction: isAsc ? SortDirection.desc : SortDirection.asc,
      field: property.toString()
    };
    setOrder(newOrder);

    setSortedData([...data].sort(getComparator(newOrder, sortFunction)));
  };

  const createSortHandler =
    (property: keyof T, sortFunction = null) =>
    (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property, sortFunction);
    };

  React.useEffect(() => {
    setSortedData([...data].sort(getComparator(order)));
    // putting 'order' here as a dependency prevents sorting by
    // a passed in sortFunction from working correctly
    // This also means we can only default sort on columns that use
    // the default string sort. These limitations should be addressed at
    // a later time if necessary.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    createSortHandler,
    order,
    sortedData
  };
}
