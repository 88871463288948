import React from 'react';

import { Link } from 'react-router-dom';

import { getAnalyticCollectionAssociations } from 'apis/resources/analytic';

import Path from 'constants/paths';

import { getAttackScriptCollectionAssociations } from 'module/AttackScript/AttackScript.api';
import { canI } from 'module/Can';
import { saveCollection, updateCollectionModifyItemByType } from 'module/Collection/Collection.api';
import { getCollectionList } from 'module/Collection/Collection.service';
import { AssociatedCollections, BulkCollectionField, Collection } from 'module/Collection/Collection.type';
import { useFilterRegistry } from 'module/GlobalFilter';
import { getIntelCollectionAssociations } from 'module/Intel/Intel.api';
import { getSessionCollectionAssociations } from 'module/Session/Session.api';
import AssociateArtifactsModal, { Message } from 'module/Widgets/AssociateArtifactsModal';

import { useAuth } from 'provider';

import { Status, useAsync } from 'storage';

import { ContentPermission } from 'types/auth';
import { ArtifactType, Guid, IconButtonRenderProps } from 'types/common';

import { buildCurationPayload } from '../Curation.service';
import { BUSY_MESSAGE, IDLE_MESSAGE, NO_COLLECTIONS_MESSAGE } from './AddToCollection.widgets';

export type AddArtifactToCollection = IconButtonRenderProps & {
  guid?: Guid;
  onClose: () => void;
  isOpen: boolean;
  type: ArtifactType.Intel | ArtifactType.Session | ArtifactType.Analytic | ArtifactType.AttackScript;
};

export function AddArtifactToCollection({ guid, onClose, isOpen, type }: AddArtifactToCollection) {
  const { user, defaultOrgId } = useAuth();
  const { generateQuery } = useFilterRegistry();
  const [isActing, setIsActing] = React.useState(false);
  const [action, setAction] = React.useState<boolean>();
  const [message, setMessage] = React.useState<Message>(IDLE_MESSAGE);
  const [selectedCollection, setSelectedCollection] = React.useState<Partial<Collection>>();

  const { data: allCollections, status, run: runAllCollections, reset } = useAsync<Collection[]>([]);
  const {
    data: associated,
    status: associatedStatus,
    run: associatedRun,
    setData: setAssociatedData,
    reset: resetAssociatedData
  } = useAsync<AssociatedCollections[]>([]);
  const isPending = status === Status.pending || associatedStatus === Status.pending;

  const refresh = React.useCallback(
    (searchString?: string) => {
      const query = generateQuery(ArtifactType.Collection, {
        query: searchString,
        organizations: [defaultOrgId.toString()]
      });
      if (guid && user.id) {
        runAllCollections(getCollectionList('Static', query), false);
        switch (type) {
          case ArtifactType.Intel:
            associatedRun(getIntelCollectionAssociations(guid));
            break;
          case ArtifactType.Session:
            associatedRun(getSessionCollectionAssociations(guid));
            break;
          case ArtifactType.Analytic:
            associatedRun(getAnalyticCollectionAssociations(guid));
            break;
          case ArtifactType.AttackScript:
            associatedRun(getAttackScriptCollectionAssociations(guid));
            break;
        }
      }
    },
    [associatedRun, generateQuery, guid, user.id, defaultOrgId, runAllCollections, type]
  );

  React.useEffect(() => {
    if (status === Status.resolved && allCollections.length === 0) setMessage(NO_COLLECTIONS_MESSAGE);
    else setMessage(IDLE_MESSAGE);
  }, [allCollections, status]);

  const handleToggleClick = React.useCallback(
    async (collection: Partial<Collection>, modifier: boolean) => {
      setAction(modifier);
      setSelectedCollection(collection);
      setIsActing(true);
      setMessage(BUSY_MESSAGE);
      let _message: Message = null;

      const updater = async () => {
        try {
          await updateCollectionModifyItemByType(
            collection.guid,
            modifier ? 'add' : 'remove',
            BulkCollectionField[type],
            guid
          );
          _message = {
            data: (
              <div key='success'>
                {`Your request to ${modifier ? 'add' : 'remove'} `}
                <strong>
                  <Link to={`${Path.Collection}/${collection.guid}`} target='_blank' rel='noopener noreferrer'>
                    {collection.name}
                  </Link>
                </strong>{' '}
                has been queued. Thanks!
              </div>
            ),
            severity: 'success'
          };
          if (modifier) {
            setAssociatedData([...associated, { guid: collection.guid, name: collection.name, id: collection.id }]);
          } else {
            setAssociatedData(associated.filter(a => a.guid !== collection.guid));
          }
        } catch (e) {
          _message = {
            data: (
              <div key='error'>
                {`Oh... Didn't expect that trying to ${modifier ? 'add' : 'remove'} to `}
                <strong>{collection.name}.</strong> {e.message}
              </div>
            ),
            severity: 'error'
          };
        }
      };

      await updater();
      setIsActing(false);
      setMessage(_message);
    },
    [associated, guid, setAssociatedData, type]
  );

  const onCreate = React.useCallback(
    async (name: string) => {
      setIsActing(true);
      setMessage(BUSY_MESSAGE);
      const payload = buildCurationPayload({
        name,
        description: `${user.name} collection`,
        organization: defaultOrgId.toString(),
        actor_names: [],
        software_names: [],
        vulnerability_names: [],
        attack_names: []
      });
      const tracked = (await saveCollection(payload)) as Partial<Collection>;
      tracked.name = name;
      handleToggleClick(tracked, true);
    },
    [defaultOrgId, handleToggleClick, user.name]
  );

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      reset();
      resetAssociatedData();
      setMessage(IDLE_MESSAGE);
      setSelectedCollection(null);
    }, 500);
  };

  return (
    <AssociateArtifactsModal
      onClose={handleClose}
      onCreate={onCreate}
      open={isOpen}
      message={message}
      title='Add to collection'
      refresh={refresh}
      isPending={isPending}
      options={allCollections.filter(col => canI(ContentPermission.Edit, col))}
      associated={associated}
      isActing={isActing}
      action={action}
      selectedItem={selectedCollection}
      handleToggleClick={handleToggleClick}
      path={Path.Collection}
      searchedArtifactType={ArtifactType.Collection}
    />
  );
}
