import React from 'react';

import upperCase from 'lodash/upperCase';

import Icon from 'snap-ui/Icon';
import MenuItem from 'snap-ui/MenuItem';
import { SelectMutator } from 'snap-ui/Select';
import { styled } from 'snap-ui/util';

import { AttackJobStateLabel } from 'module/Widgets/StateLabel';

import { Status, useAsync } from 'storage';

import { putBASJob } from '../BAS.api';
import { BASJobState } from '../BAS.type';
import { ColumnComponentProps } from './common';

const Label = styled(AttackJobStateLabel)`
  width: 100%;
`;

const Select = styled(SelectMutator)`
  width: 100%;

  .MuiInputBase-root {
    width: 100%;
  }
`;

function AttackStatusColumnMutator(props: ColumnComponentProps) {
  const { data, setData, status, task, errorProps } = useAsync<BASJobState>(props.job.state);
  const busy = status === Status.pending;

  const handleChange = (state: BASJobState) => {
    task(
      putBASJob(props.job.guid, {
        state
      }).then(job => setData(job.state))
    );
  };

  return (
    <Select
      value={data}
      errorProps={errorProps}
      onChange={handleChange}
      renderValue={selectedOption => <Label value={selectedOption as any} />}
      disabled={busy}
      busy={busy}
      IconComponent={busy ? Icon.SpinnerProgress : undefined}
    >
      {Object.entries(BASJobState).map(([key, value]) => (
        <MenuItem key={key} value={value}>
          <Label className='AttackStatusColumnMutator-label' value={value} />
        </MenuItem>
      ))}
    </Select>
  );
}

export default function AttackStatusColumn(props: ColumnComponentProps): JSX.Element {
  const { job, print, mutate } = props;
  if (print) return <span>{upperCase(job.state)}</span>;
  if (mutate) return <AttackStatusColumnMutator {...props} />;
  return <Label value={job.state} />;
}
