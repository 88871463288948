import { Ops, Query } from 'types/filter';

export const ValidationProductFilter = {
  field: 'product_name',
  op: Ops.in,
  value: ['SnapAttack', 'AtomicRedTeam']
};

export const DEFAULT_ATTACK_CODE = `name: New Attack Script
description: |
  a new attack script
supported_platforms:
  - windows
executor:
  name: command_prompt
  elevation_required: false
  command: |
    echo "hello world"`;

export const DEFAULT_VALIDATION_CODE = `exit_code: 0       # process exit status as an integer value
#output: success   # string to match in the process's stdout
#follow_up:        # optional command to run for further validation
#  command: whoami /groups   # command line string to execute
#  exit_code: 0              # process exit status as an integer value
#  output: BUILTIN\\Administrators   # string to match in the process's stdout`;

export function addValidationProductFilter(query: Query) {
  return {
    op: Ops.and,
    items: [query, ValidationProductFilter]
  };
}
