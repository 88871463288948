import React from 'react';

import { FabProps } from '@mui/material/Fab';
import MuiSpeedDial from '@mui/material/SpeedDial';
import MuiSpeedDialAction from '@mui/material/SpeedDialAction';
import MuiSpeedDialIcon from '@mui/material/SpeedDialIcon';

import { StrictReactNode } from 'types/core';

import { Base } from './type';

export type SpeedDialProps = Base & {
  children: StrictReactNode;
  direction?: 'up' | 'down' | 'left' | 'right';
  ariaLabel: string;
  icon: StrictReactNode;
  open?: boolean;
  onClick?(): void;
  FabProps?: FabProps;
};

function SpeedDial({ children, icon, ...others }: SpeedDialProps) {
  return (
    <MuiSpeedDial className='SpeedDial' icon={<MuiSpeedDialIcon icon={icon} />} {...others}>
      {children}
    </MuiSpeedDial>
  );
}

SpeedDial.Action = MuiSpeedDialAction;

export default SpeedDial;
