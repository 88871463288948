import React, { ReactElement } from 'react';

import { faPlay } from '@fortawesome/pro-solid-svg-icons';

import Icon from 'snap-ui/Icon';
import List, { ListItem, ListItemIcon, ListItemText } from 'snap-ui/List';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import useTitle from 'hooks/useTitle';

import { Engage, Fingerprint } from 'lib/Engagement';

import { MarkdownRead } from 'module/Widgets/Markdown';

import { convertSecondsToMMss } from 'utilities/TimeUtils';

import videos from './videos.json';

const Container = styled('div')`
  padding: ${p => p.theme.spacing(4, 9)};
  display: grid;
  grid-template-columns: 70% 30%;
  column-gap: ${p => p.theme.spacing(4)};

  .Tutorial-column-left {
    display: flex;
    gap: ${p => p.theme.spacing(4)};
    flex-direction: column;
    align-items: flex-start;
  }

  video {
    width: 100%;
    height: 100%;
  }

  li:hover {
    cursor: pointer;
    background-color: ${p => p.theme.palette.surface.hover};
  }

  .MuiListItemIcon-root:hover {
    cursor: default;
  }

  .MuiListItemText-primary {
    width: 100%;
    display: flex;
    gap: ${p => p.theme.spacing(4)};
    justify-content: space-between;
  }
`;

type Video = {
  id: number;
  src: string;
  title: string;
  description: string;
  time: number;
};

const Tutorial = (): ReactElement => {
  const [activeVideo, setActiveVideo] = React.useState<Video>(videos[0]);
  useTitle('Tutorials | SnapAttack');

  React.useEffect(() => {
    Engage.track(Fingerprint.load(Path.Tutorial).withData(activeVideo));
  }, [activeVideo]);

  return (
    <Container>
      <div className='Tutorial-column-left'>
        <Typography variant='h1'>SnapAttack Video Tutorials</Typography>
        <video key={activeVideo.src} controls autoPlay>
          <source src={activeVideo.src} type='video/mp4'></source>
        </video>
        <div>
          <Typography variant='h2'>{activeVideo.title}</Typography>
          <MarkdownRead value={activeVideo.description} />
        </div>
      </div>
      <div className='Tutorial-column-right'>
        <Typography variant='h4'>Playlist</Typography>
        <List>
          {videos.map((video, index) => (
            <ListItem key={video.id} onClick={(): void => setActiveVideo(video)}>
              <ListItemIcon>{video.id === activeVideo.id && <Icon icon={faPlay} />}</ListItemIcon>
              <ListItemText>
                {index + 1}. {video.title} <span>{convertSecondsToMMss(video.time)}</span>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </div>
    </Container>
  );
};

export default Tutorial;
