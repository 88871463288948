import React from 'react';

import startCase from 'lodash/startCase';

import { Autocomplete } from 'snap-ui/Autocomplete';
import { BarChart } from 'snap-ui/Charts';
import { BarChartProps as SUIBarChartProps } from 'snap-ui/Charts/BarChart';
import Placeholder from 'snap-ui/Placeholder';
import Typography from 'snap-ui/Typography';
import { useTheme } from 'snap-ui/util';

import useQueryString from 'hooks/useQueryString';

import { ATTACK_RECORD_TYPE_FILTER_OPTIONS } from '../const';
import { BarChartContainer, BarChartPanel, BarChartToolbar, BarChartPlacholders } from '../style';
import { CoverageStatName } from '../type';
import { mapAggregationToChartData } from '../util';
import { MitreCoverageInterface } from './useMitreCoverage';
import useRecordType from './useRecordType';

type BarChartProps = MitreCoverageInterface;

export default function MitreCoverageBarChart({
  aggregatedCoverage,
  isPending,
  isCoveragePending
}: BarChartProps): JSX.Element {
  const { palette } = useTheme();
  const { update: updateQueryString } = useQueryString(true);
  const [coverageMetricName, setCoverageMetricName] = React.useState<CoverageStatName>('coverage');
  const [recordType, setRecordType] = useRecordType();

  function handleBarClick(data: SUIBarChartProps['data'][number]) {
    updateQueryString({ priority: data?.label });
  }

  return (
    <BarChartPanel>
      <BarChartToolbar>
        <Typography variant='h2'>
          {startCase(recordType)} Percent {startCase(coverageMetricName)} by Priority
        </Typography>
        <div className='filters'>
          <Autocomplete
            className='metricSelect'
            disableClearable
            disableUserAdditions
            label='Type'
            name='recordType'
            onChange={setRecordType}
            options={ATTACK_RECORD_TYPE_FILTER_OPTIONS}
            value={recordType}
          />
          <Autocomplete
            className='metricSelect'
            disableClearable
            disableUserAdditions
            label='Coverage Metric'
            name='metric'
            onChange={v => setCoverageMetricName(v as CoverageStatName)}
            options={[
              { value: 'coverage', content: 'Coverage', label: 'Coverage' },
              { value: 'breadth', content: 'Breadth', label: 'Breadth' },
              { value: 'depth', content: 'Depth', label: 'Depth' }
            ]}
            value={coverageMetricName}
          />
        </div>
      </BarChartToolbar>
      {isPending || isCoveragePending ? (
        <BarChartPlacholders>
          <Placeholder variant='rectangular' height={250} />
          <Placeholder variant='rectangular' height={125} />
          <Placeholder variant='rectangular' height={200} />
          <Placeholder variant='rectangular' height={50} />
          <Placeholder variant='rectangular' height={100} />
        </BarChartPlacholders>
      ) : (
        <BarChartContainer>
          <BarChart
            height={280}
            data={mapAggregationToChartData(aggregatedCoverage?.[recordType], coverageMetricName, palette)}
            margin={{ left: -25 }}
            onBarClick={handleBarClick}
            useTooltip
          />
        </BarChartContainer>
      )}
    </BarChartPanel>
  );
}
