import React from 'react';

import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import { Autocomplete, getDisplayValue } from 'snap-ui/Autocomplete';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import Path from 'constants/paths';

import useTitle from 'hooks/useTitle';

import { CommonEvent, Engage, Fingerprint, Widget } from 'lib/Engagement';

import { getArtifactIcon } from 'module/Layout/Artifact.helper';
import { AutocompleteIconOption } from 'module/Layout/Styled';
import { LandingCollectionTag, Discriminator } from 'module/Tag';
import useTagCatalog, { DiscriminatorOrdinal } from 'module/Tag/useTagCatalog';

import { ArtifactType } from 'types/common';

import { formatQueryString, parseAnyQueryString } from 'utilities/SearchParam';

import { Container, SearchButton } from './Omnisearch.style';

type Props = {
  className?: string;
};

const sorter: (a: LandingCollectionTag, b: LandingCollectionTag) => number = (a, b) => a.name.localeCompare(b.name);

const suggestedTags: LandingCollectionTag[] = [
  {
    name: 'CISA Known Exploited Vulnerabilities',
    guid: '37738ff7-6dcd-40f1-b588-84cfdef42db4', // NOTE: the CISA Collection GUID will only work in production
    discriminator: ArtifactType.Collection
  },
  {
    name: 'APT29',
    discriminator: Discriminator.Actor
  },
  {
    name: 'FIN7',
    discriminator: Discriminator.Actor
  },
  {
    name: 'BlackByte',
    discriminator: Discriminator.Software
  },
  {
    name: 'Cobalt Strike',
    discriminator: Discriminator.Software
  },
  {
    name: 'CVE-2023-34362',
    discriminator: Discriminator.Vulnerability
  },
  {
    name: 'T1003: OS Credential Dumping',
    discriminator: Discriminator.Attack
  },
  {
    name: 'T1059.001: Command and Scripting Interpreter: PowerShell',
    discriminator: Discriminator.Attack
  }
];

export default function Omnisearch({ className }: Props) {
  useTitle(`Search | SnapAttack`);
  const { push, location } = useHistory();
  const { data: all } = useTagCatalog(DiscriminatorOrdinal.All, true);
  const urlParams = parseAnyQueryString(location?.search);
  const urlQuery = urlParams.query as string;
  const [query, setQuery] = React.useState<string>(urlQuery || '');

  const sortedTags = React.useMemo(() => {
    return all.sort(sorter);
  }, [all]);

  React.useEffect(() => {
    Engage.track(Fingerprint.of(Widget.Omnisearch).pageLoad().withData({ suggestions: suggestedTags }));
  }, []);

  const filteredSuggestions = React.useMemo(() => {
    const filtered =
      query.length === 0
        ? suggestedTags
        : sortedTags.filter(tag => tag.name.toLowerCase().includes(query.toLowerCase()));
    const clipped = filtered.slice(0, 8);
    return clipped.map(tag => ({
      value: `${tag.name}_${tag.discriminator}`,
      label: tag.name,
      discriminator: tag.discriminator,
      content: (
        <AutocompleteIconOption>
          <Tooltip arrow title={tag.discriminator.toUpperCase()} wrap>
            {getArtifactIcon(tag.discriminator, true)}
          </Tooltip>
          {tag.name}
        </AutocompleteIconOption>
      )
    }));
  }, [query, sortedTags]);

  function handleFilter(value) {
    setQuery(value || '');
  }

  function handleChange(value: string): void {
    const [name, discriminator] = value.split('_');
    if (value) handleSearch(name, discriminator as Discriminator);
  }

  function handleSearch(query: string, discriminator?: Discriminator) {
    const finder = (t: LandingCollectionTag) =>
      t.name.toLowerCase() === query.toLowerCase() && t.discriminator === discriminator;
    const tag = suggestedTags.find(finder) || all.find(finder);
    if (tag) {
      Engage.track(
        Fingerprint.of(Widget.Omnisearch)
          .withCommon(CommonEvent.Select)
          .withData({ query, name: tag.name, type: tag.discriminator })
      );
      if (tag.discriminator === ArtifactType.Collection) {
        push(`${Path.Collection}/${tag.guid}`);
      } else {
        push(`${Path.Collection}/${tag.discriminator}/${encodeURIComponent(tag.name)}`);
      }
    } else {
      Engage.track(Fingerprint.of(Widget.Omnisearch).withCommon(CommonEvent.Search).withData({ query }));
      push(
        Path.Feed +
          formatQueryString({
            topic: ArtifactType.Analytic,
            page: 1,
            query: query
          })
      );
    }
  }

  function handleSubmit() {
    if (query) handleSearch(query);
  }

  return (
    <Container className={classnames('Omnisearch', className)}>
      <img src={process.env.PUBLIC_URL + '/images/snapattack.png'} alt='SnapAttack Logo' />
      <form onSubmit={handleSubmit}>
        <Autocomplete
          disablePortal
          className='omni-search'
          name='omni-search'
          options={filteredSuggestions}
          onChange={handleChange}
          onInputChange={handleFilter}
          value={getDisplayValue(filteredSuggestions, query)}
          endAdornment={<SearchButton icon={faSearch} aria-label={'Search'} onClick={handleSubmit} />}
          customNewOptionContent={value => (
            <AutocompleteIconOption>
              <Icon icon={faSearch} />
              Search for &quot;{value}&quot;
            </AutocompleteIconOption>
          )}
          placeholder='Search for ATT&CK techniques, threat actors, malware/tools, and vulnerabilities'
        />
      </form>
    </Container>
  );
}
