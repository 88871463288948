import React from 'react';

import useScripts, { ScriptsAsyncInterface } from 'module/BAS/Launcher/useScripts';

import { ArtifactType, Guid } from 'types/common';

type EntityScriptsProviderProps = {
  children: React.ReactNode;
  guid: Guid;
  type: ArtifactType.Session | ArtifactType.Analytic;
};

const EntityScriptsContext = React.createContext<ScriptsAsyncInterface>(null);

export function EntityScriptsProvider({ children, guid, type }: EntityScriptsProviderProps): JSX.Element {
  const scriptsInterface = useScripts(guid, type);
  return <EntityScriptsContext.Provider value={scriptsInterface}>{children}</EntityScriptsContext.Provider>;
}

export function useEntityScripts(): ScriptsAsyncInterface {
  const context = React.useContext(EntityScriptsContext);
  if (!context) {
    throw new Error('useEntityScripts must be used within the EntityScriptsProvider');
  }
  return context;
}
