import React from 'react';

import useUsers, { UserCatalog } from './useUsers';

const UserCatalogContext = React.createContext<UserCatalog>(null);
UserCatalogContext.displayName = 'UserCatalogContext';

function useUserCatalog() {
  const context = React.useContext(UserCatalogContext);

  if (!context) {
    throw new Error('useUserCatalog must be used within a UserCatalogProvider');
  }

  return context;
}

function UserCatalogProvider({ children }: { children: React.ReactNode }) {
  const catalog = useUsers();

  return <UserCatalogContext.Provider value={catalog}>{children}</UserCatalogContext.Provider>;
}

export { UserCatalogProvider, useUserCatalog };
