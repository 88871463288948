import React from 'react';

import useFeed from 'aso/useFeed';

import { useFilterRegistry } from 'module/GlobalFilter';
import { getTagIDs } from 'module/Landing/Landing.util';
import { Discriminator, isTacticArtifact } from 'module/Tag';
import { ThreatProfileTag, useThreatProfile } from 'module/ThreatProfile';

import { Status } from 'storage';

import { Artifact, ArtifactType, Guid } from 'types/common';

import { useCoverage } from '../CoverageProvider';
import { AggregatedProfileCoverage, CoverageReport, TagCoverage } from '../type';
import { aggregateProfileCoverage } from '../util';

export type TagCoverageInterface = {
  items: Artifact[];
  status: Status;
};

export type ProfileCoverageInterface = {
  actors: TagCoverageInterface;
  attacks: TagCoverageInterface;
  software: TagCoverageInterface;
  vulnerabilities: TagCoverageInterface;
  aggregatedCoverage: AggregatedProfileCoverage;
  getProfile(tagType: Discriminator, guid: Guid): ThreatProfileTag;
  getStats(tagType: Discriminator, guid: Guid): TagCoverage;
  getTagTypeReport(tagType: Discriminator): CoverageReport;
  isProfilePending: boolean;
  isCoverageReportPending: boolean;
  isAggregationPending: boolean;
};

const PAGE_PARAMS = {
  page: 1,
  size: 3000 // software has over 2000 in profile?
};

const FILTER_BASE = { threatProfile: ['true'] };
const ACTOR_FILTER = { ...FILTER_BASE, contentType: 'actor' };
const SOFTWARE_FILTER = { ...FILTER_BASE, contentType: 'software' };
const VULNERABILITY_FILTER = { ...FILTER_BASE, contentType: 'vulnerability' };
const ATTACK_FILTER = { ...FILTER_BASE, contentType: 'attack' };

function useTagFeed(filter): TagCoverageInterface {
  const { generateQuery } = useFilterRegistry();
  const query = React.useMemo(() => generateQuery(ArtifactType.Collection, filter), [filter, generateQuery]);
  const feed = useFeed(ArtifactType.Collection, query, PAGE_PARAMS);

  return {
    items: filter.contentType === 'attack' ? feed.items.filter(i => !isTacticArtifact(i)) : feed.items,
    status: feed.status
  };
}

export default function useProfileCoverage(): ProfileCoverageInterface {
  const { loadCoverage, getTagCoverage, getTagTypeReport, coverageReportIsPending } = useCoverage();
  const { tagInProfile, tagsStatus } = useThreatProfile();

  const actors = useTagFeed(ACTOR_FILTER);
  const attacks = useTagFeed(ATTACK_FILTER);
  const software = useTagFeed(SOFTWARE_FILTER);
  const vulnerabilities = useTagFeed(VULNERABILITY_FILTER);

  React.useEffect(() => {
    loadCoverage();
  }, [loadCoverage]);

  const getFeedItems = React.useCallback(
    (tagType: Discriminator): Artifact[] => {
      switch (tagType) {
        case Discriminator.Actor:
          return actors.items;
        case Discriminator.Attack:
          return attacks.items;
        case Discriminator.Software:
          return software.items;
        case Discriminator.Vulnerability:
          return vulnerabilities.items;
        default:
          return [];
      }
    },
    [actors.items, attacks.items, software.items, vulnerabilities.items]
  );

  const getProfile = React.useCallback(
    (tagType: Discriminator, guid: Guid) => {
      const tag = getFeedItems(tagType).find(t => t.guid === guid);
      if (!tag) return;
      const tagIds = getTagIDs(tag, tagType);
      return tagInProfile(tagIds);
    },
    [getFeedItems, tagInProfile]
  );

  const getStats = React.useCallback(
    (tagType: Discriminator, guid: Guid) => {
      const tag = getFeedItems(tagType).find(t => t.guid === guid);
      if (!tag) return;
      const tagIds = getTagIDs(tag, tagType);
      return getTagCoverage(tagIds[0]);
    },
    [getFeedItems, getTagCoverage]
  );

  const aggregatedCoverage = React.useMemo(() => {
    if (
      [tagsStatus, actors.status, attacks.status, software.status, vulnerabilities.status].some(
        status => status !== Status.resolved
      )
    ) {
      return;
    }

    return aggregateProfileCoverage(
      actors.items,
      attacks.items,
      software.items,
      vulnerabilities.items,
      getStats,
      getProfile
    );
  }, [actors, attacks, software, vulnerabilities, tagsStatus, getStats, getProfile]);

  return {
    actors,
    attacks,
    software,
    vulnerabilities,
    aggregatedCoverage,
    getProfile,
    getStats,
    getTagTypeReport,
    isProfilePending: tagsStatus === Status.pending,
    isCoverageReportPending: coverageReportIsPending,
    isAggregationPending: !aggregatedCoverage
  };
}
