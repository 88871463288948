import React from 'react';

import { DetectionSummary } from 'module/Detection';

import { ArtifactType } from 'types/common';

import MarkerContent from './core/MarkerContent';
import Wrapper from './core/Wrapper';

type Props = {
  className?: string;
  count: DetectionSummary;
  error?: any;
  iconsInheritBackgroundColor?: boolean;
  headless?: boolean;
};

export default function AnalyticDefensivePosture({
  className,
  count,
  error,
  headless,
  iconsInheritBackgroundColor
}: Props) {
  const validated = count.validated || 0;
  const gap = count.validated_gaps || 0;
  const unvalidated = count.unvalidated || 0;
  const total = validated + gap + unvalidated;
  if (total === 0) return null;
  return (
    <Wrapper className={className} showError={error || !count}>
      <MarkerContent
        topic={ArtifactType.Analytic}
        count={total}
        unique={0}
        validated={validated}
        gap={gap}
        unvalidated={unvalidated}
        iconsInheritBackgroundColor={iconsInheritBackgroundColor}
        headless={headless}
      />
    </Wrapper>
  );
}
