import { MatrixDisplayOrdinal } from './Scaffold.type';

export const NAVIGATION_HEADER_HEIGHT = 64;
export const SESSION_VM_TOOLBAR_WIDTH = 60;
export const DRAWER_WIDTH = 350;

export const MATRIX_URL_DISPLAY_PARAM_SCHEMA = {
  display: Number,
  palette: String,
  heatMap: String,
  bounds: Number
};

export const DEFAULT_MATRIX_DISPLAY = MatrixDisplayOrdinal.Count + MatrixDisplayOrdinal.Empty;
export const MAX_DISPLAY_PARAM_VALUE = Object.values(MatrixDisplayOrdinal).reduce(
  (p, c) => p + (typeof c === 'string' ? 0 : c),
  0
);
