import React from 'react';

import { faBookmark as faBookmarkOutlined } from '@fortawesome/pro-regular-svg-icons';
import { faBookmark as faBookmarkSolid } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';

import Button from 'snap-ui/Button';
import Fade from 'snap-ui/Fade';
import Icon from 'snap-ui/Icon/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { useLandingCatalog } from 'module/Landing/LandingProvider';
import { useMayI } from 'module/May';
import { Discriminator, isTactic } from 'module/Tag';

import { useAuth } from 'provider/Account';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';

import { NOOP } from 'utilities/FunctionUtils';

import { ThreatProfileTag, useThreatProfile } from '.';
import TagJustification from './TagJustification';
import TagPriority from './TagPriority';
import { AddToProfileContainer } from './ThreatProfile.style';

type AddTagToThreatProfileProps = {
  className?: string;
};

export default function AddTagToThreatProfile({ className }: AddTagToThreatProfileProps) {
  const { isSubscriber } = useAuth();
  const canEdit = useMayI(FunctionalPermission.SetSecurityProfile);

  const { data, type } = useLandingCatalog();
  const { isTagInProfile, tagInProfile, addTag, removeTag, taskStatus, tagsStatus } = useThreatProfile();
  const isAttackTactic = type === Discriminator.Attack && isTactic({ name: data.combined.name, discriminator: type });

  if (isAttackTactic) return null;

  const tagName = data.combined.name;
  const tagIDs = data.items?.map(item => item.id);
  const isInProfile = isTagInProfile(tagIDs);
  const tag: ThreatProfileTag = tagInProfile(tagIDs);
  const isPending = [tagsStatus, taskStatus].includes(Status.pending);

  function handleAddTagToThreatProfile() {
    addTag(tagName);
  }
  function handleRemoveFromThreatProfile() {
    removeTag(tag.name);
  }

  return (
    <Fade in timeout={1000}>
      <AddToProfileContainer className={classnames('AddTagToThreatProfile', className)}>
        {canEdit ? (
          <>
            {isInProfile ? (
              <>
                <Tooltip arrow placement='left' title='Remove from Threat Profile' wrap>
                  <Button className='ThreatProfile-button' onClick={handleRemoveFromThreatProfile} disabled={isPending}>
                    <Icon icon={faBookmarkSolid} /> In Threat Profile
                  </Button>
                </Tooltip>
                <TagJustification components={tag.components} scoreLabel={tag.score_label}>
                  <TagPriority tag={tag} readonly={isPending} />
                </TagJustification>
              </>
            ) : (
              <Button
                className='ThreatProfile-button'
                variant='outlined'
                onClick={handleAddTagToThreatProfile}
                disabled={isPending}
              >
                <Icon icon={faBookmarkOutlined} />
                Add to Threat Profile
              </Button>
            )}
          </>
        ) : (
          <>
            {isInProfile ? (
              <>
                <Button className='ThreatProfile-button disabled' onClick={NOOP} disabled={isPending}>
                  <Icon icon={faBookmarkSolid} /> In Threat Profile
                </Button>
                <TagJustification components={tag.components} scoreLabel={tag.score_label}>
                  <TagPriority readonly tag={tag} />
                </TagJustification>
              </>
            ) : (
              <Tooltip
                arrow
                placement='left'
                title={
                  isSubscriber
                    ? 'Contact an Organization Administrator to make changes.'
                    : 'Threat Profiles are only available to subscribers.'
                }
                wrap
              >
                <Button
                  className='ThreatProfile-button disabled'
                  variant='outlined'
                  onClick={NOOP}
                  disabled={isPending}
                >
                  <Icon icon={faBookmarkOutlined} />
                  Not In Threat Profile
                </Button>
              </Tooltip>
            )}
          </>
        )}
      </AddToProfileContainer>
    </Fade>
  );
}
