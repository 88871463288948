import React from 'react';

import Link from 'snap-ui/Link';
import Placeholder from 'snap-ui/Placeholder';
import Typography from 'snap-ui/Typography';

import Path from 'constants/paths';

import { HyperTag } from 'module/Landing/Landing.type';

import { Status } from 'storage';

import { Discriminator } from './Tag.type';
import useLandingAliasPreview from './useLandingAliasPreview';

const VALID_DISCRIMINATORS = [Discriminator.Actor, Discriminator.Software, Discriminator.Vulnerability];

type TagAliasProps = {
  tag: Partial<HyperTag>;
  onTrigger: boolean;
};
export default function TagAlias({ tag, onTrigger }: TagAliasProps) {
  const { aliases, getAlias, status } = useLandingAliasPreview();
  const compositeAliases = aliases.filter(a => a.toLocaleLowerCase() !== tag.name.toLowerCase());
  const isAliasPresent = compositeAliases.length > 0;
  const isPending = status === Status.pending;
  const isTypeAliasBased = VALID_DISCRIMINATORS.some(discriminator => discriminator === tag.discriminator);

  const checkAlias = React.useCallback(() => {
    if (isTypeAliasBased) getAlias(tag.id, tag.discriminator);
  }, [getAlias, isTypeAliasBased, tag.discriminator, tag.id]);

  React.useEffect(() => {
    if (onTrigger) checkAlias();
  }, [checkAlias, onTrigger]);

  if (isPending) return <Placeholder variant='rectangular' height={20} animation='pulse' />;
  if (!isTypeAliasBased || !isAliasPresent) return null;

  return (
    <>
      <Typography variant='caption'>ALIASES: </Typography>
      {compositeAliases.map((alias, index) => (
        <Link key={alias} to={`${Path.Collection}/${tag.discriminator}/${encodeURIComponent(tag.name)}`}>
          <Typography variant='caption' color='primary'>
            {alias}
          </Typography>
          {index < compositeAliases.length - 1 && <span>, </span>}
        </Link>
      ))}
    </>
  );
}
