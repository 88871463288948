import React from 'react';

import { faSquareUp } from '@fortawesome/pro-solid-svg-icons';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';

import Icon from 'snap-ui/Icon';

import Path from 'constants/paths';

import { Collection } from 'module/Collection/Collection.type';
import {
  ArtifactWidgetMenuWrapper,
  Author,
  BurgerCancel,
  BurgerPublish,
  BurgerReset,
  Menu,
  Publish,
  Title
} from 'module/Layout/Artifact.widgets';

import { ArtifactType } from 'types/common';

import { CurationInput } from '../Curation.type';

type Props = {
  collection: Collection;
  onPublish(): void;
  onReset(): void;
};

export default function CurationEditHeader({ collection, onPublish, onReset }: Props) {
  const { replace } = useHistory();
  const { values, dirty } = useFormikContext<CurationInput>();

  const handleGoBack = () => {
    if (collection.guid) replace(`${Path.Collection}/${collection.guid}`, collection);
    else replace(`${Path.Feed}?topic=${ArtifactType.Collection}`);
  };

  return (
    <>
      <Title headerText={values.name || collection.name || 'New Collection'} />
      <Author resource={collection} type='collection' />
      <ArtifactWidgetMenuWrapper>
        <Publish onClick={onPublish} startIcon={<Icon icon={faSquareUp} />}>
          Publish
        </Publish>
        <Menu dirty={dirty}>
          <BurgerCancel onClick={handleGoBack} />
          <BurgerPublish onClick={onPublish} />
          <BurgerReset onClick={onReset} disabled={!dirty} />
        </Menu>
      </ArtifactWidgetMenuWrapper>
    </>
  );
}
