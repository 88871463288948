import React from 'react';

import moment from 'moment-timezone';
import ReactDOM from 'react-dom';

import { getOpenSettings } from 'apis/resources/auth';

import { Engage } from 'lib/Engagement';

import withSentry from 'module/Util/Sentry';

import { Monitoring } from 'types/auth';

import App from './App';

moment.tz.setDefault('UTC');

async function prepare() {
  if (process.env.REACT_APP_ENV === 'mock') {
    const { worker } = await import('./mock/browser');
    return worker.start();
  }
  return Promise.resolve();
}

prepare()
  .then(async () => {
    return await getOpenSettings();
  })
  .then(async (settings: Monitoring) => {
    Engage._init(settings?.tracking);
    const SnapAttackApp = await withSentry(() => <App />, settings);
    ReactDOM.render(<SnapAttackApp />, document.getElementById('project-purple'));
  })
  .catch(e => {
    console.error(e);
    ReactDOM.render(<App />, document.getElementById('project-purple'));
  });
