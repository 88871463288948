import React from 'react';

import omit from 'lodash/omit';

import { getGroups } from 'apis/resources/group';

import { ErrorProps, Status, useAsync } from 'storage';

import { Group } from 'types/auth';
import { Guid } from 'types/common';

import { deleteEntitlement, getEntitlements, postEntitlement, putEntitlement } from './Entitlements.api';
import { Entitlement, EntitlementPayload } from './Entitlements.type';

type EntitlementManagementState = {
  errorProps: ErrorProps;
  entitlements: Entitlement[];
  resetError(): void;
  refresh(organization: Guid): void;
  status: Status;
  create(payload: EntitlementPayload): Promise<void>;
  update(entitlementGuid: Guid, entitlement: EntitlementPayload): Promise<void>;
  delete(entitlementGuid: Guid): Promise<void>;
};

export function useEntitlements(): EntitlementManagementState {
  const { data: entitlements, errorProps, status, task, run } = useAsync<Entitlement[]>([]);

  const refresh = React.useCallback(() => {
    run(getEntitlements());
  }, [run]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  const resetError = React.useCallback(() => {
    task(Promise.resolve());
  }, [task]);

  const create = React.useCallback(
    async (payload: EntitlementPayload) => {
      await task(postEntitlement(payload));
      return refresh();
    },
    [task, refresh]
  );

  const update = React.useCallback(
    async (entitlementGuid: Guid, entitlement: EntitlementPayload) => {
      await task(putEntitlement(entitlementGuid, omit(entitlement, 'guid')));
      return refresh();
    },
    [task, refresh]
  );

  const remove = React.useCallback(
    async (entitlementGuid: Guid) => {
      await task(deleteEntitlement(entitlementGuid));
      return refresh();
    },
    [task, refresh]
  );

  return {
    errorProps,
    entitlements: entitlements,
    resetError,
    refresh,
    status,
    create,
    update,
    delete: remove
  };
}

type EntitlementGroupsState = {
  errorProps: ErrorProps;
  groups: Group[];
  resetError(): void;
  refresh(organization: Guid): void;
  status: Status;
};

export function useEntitlementGroups(): EntitlementGroupsState {
  const { data: groups, errorProps, status, task, run } = useAsync<Group[]>([]);

  const refresh = React.useCallback(() => {
    run(getGroups());
  }, [run]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  const resetError = React.useCallback(() => {
    task(Promise.resolve());
  }, [task]);

  const filteredGroups = React.useMemo(() => {
    const ROLES = ['Permission', 'ExternalContentUser'];
    const ORG_NAMES = ['Training'];
    return groups?.filter(group => ROLES.includes(group.role) || ORG_NAMES.includes(group.organization.name));
  }, [groups]);

  return {
    errorProps,
    groups: filteredGroups,
    resetError,
    refresh,
    status
  };
}
