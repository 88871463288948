import React, { useCallback, useMemo, useState } from 'react';

import { faFileAlt } from '@fortawesome/pro-solid-svg-icons';
import startCase from 'lodash/startCase';

import Icon from 'snap-ui/Icon';
import { List, ListItem } from 'snap-ui/List';
import { TooltipConstrained } from 'snap-ui/Tooltip';
import { styled, useTheme } from 'snap-ui/util';

import useEventLog from 'aso/useEventLog';

import * as SPLUNK from 'constants/splunk';

import useSplunkLink from 'hooks/useSplunkLink';

import { Session } from 'module/Session/Session.type';

import { isBlueMarker } from 'services/filterService/filterService';

import { Status } from 'storage';

import { SigmaLogsource } from 'types/analytic';
import { LogRecord } from 'types/ide';
import { MarkerEvent, MarkerExtended } from 'types/marker';

import { splunkDashboardUrl } from 'utilities/SplunkUtils';

const Preview = styled('div')`
  max-height: 300px;
  padding: ${p => p.theme.spacing(2)};
  overflow-y: auto;

  .list-item {
    padding: 0;
    flex-direction: column;
    margin-bottom: ${p => p.theme.spacing(1)};
  }

  .list-item-header {
    font-weight: bold;
    width: 100%;
    text-align: left;
    line-height: 1;
  }

  .list-item-contents {
    white-space: 'pre-wrap';
    overflow-wrap: 'break-word';
    width: 100%;
    line-height: 1rem;
  }
`;
interface HitLogProps {
  session: Session;
  hit: MarkerExtended;
}

interface LogPreviewProps {
  event: MarkerEvent;
  status: Status;
  hit: MarkerExtended;
}

function LogPreview({ event, status, hit }: LogPreviewProps): JSX.Element {
  const logItems: JSX.Element[] = useMemo(() => {
    if (status !== Status.resolved || !event.raw) return [];
    const log: LogRecord = JSON.parse(event.raw);
    const validColumns = SPLUNK.LOG_COLUMNS.filter(column => !!log[column]).slice(0, 5);
    return validColumns.map(column => (
      <ListItem key={column} className='list-item'>
        <div className='list-item-header'>{startCase(column)}</div>
        <pre className='list-item-contents'>{log[column]}</pre>
      </ListItem>
    ));
  }, [event, status]);

  if (status === Status.pending) return <>Loading Preview...</>;
  if (!event && status === Status.resolved) return <>View event logs near this attack</>;
  if (logItems.length === 0 && isBlueMarker(hit)) return <>View event logs for this detection</>;
  if (logItems.length === 0) return <>View event logs for this attack</>;

  return (
    <Preview>
      <List>{logItems}</List>
    </Preview>
  );
}

function HitLog({ session, hit }: HitLogProps) {
  const { palette } = useTheme();
  const row_id = hit?.event?.row_id;
  const eventIdentifier = isBlueMarker(hit) ? hit.row_id : '';
  const logsource: SigmaLogsource = {
    product: hit?.event?.sigma_product,
    service: hit?.event?.sigma_service,
    category: hit?.event?.sigma_category
  };

  const [hovered, setHovered] = useState(false);
  const { event, status } = useEventLog(hovered ? eventIdentifier : '');
  const { hostLogDashboard } = useSplunkLink();

  const onPopupOpen = useCallback(() => setHovered(true), []);

  return (
    <TooltipConstrained
      placement='top'
      arrow
      onOpen={onPopupOpen}
      title={<LogPreview event={event} status={status} hit={hit} />}
    >
      <a
        href={splunkDashboardUrl({
          dashboard: hostLogDashboard,
          session: session,
          neartime: hit?.event?.timestamp,
          row_id: row_id,
          logsource: logsource
        })}
        target='_blank'
        rel='noopener noreferrer'
      >
        <Icon icon={faFileAlt} color={palette.common.white} />
      </a>
    </TooltipConstrained>
  );
}

export default HitLog;
