import { z } from 'zod';

import { Discriminator } from 'module/Tag';

// BE Enum:
// Highest = 1.0
// High = 0.75
// Medium = 0.5
// Low = 0.25
// Lowest = 0.1
// Ignored = 0.0
export const TagWeight = {
  Highest: 'Highest',
  High: 'High',
  Medium: 'Medium',
  Low: 'Low',
  Lowest: 'Lowest',
  Ignored: 'Ignored'
} as const;
export type TagWeight = keyof typeof TagWeight;

export const ThreatProfileTagPayloadSchema = z.object({
  tag: z.string() || z.number(), // the BE lets you PUT/DELETE a tag by name or ID; in GET, tag is the ID
  score: z.number() || z.nativeEnum(TagWeight)
});
export type ThreatProfileTagPayload = Required<z.infer<typeof ThreatProfileTagPayloadSchema>>;

const TagScoreComponentSchema = z.object({
  description: z.string(),
  score: z.number(),
  weight: z.number(),
  weighted_score: z.number()
});

export type TagScoreComponent = Required<z.infer<typeof TagScoreComponentSchema>>;

export const ThreatProfileTagSchema = ThreatProfileTagPayloadSchema.extend({
  name: z.string(),
  source: z.string(),
  type: z.nativeEnum(Discriminator),
  override: z.boolean(),
  organization_id: z.number(),
  score_label: z.nativeEnum(TagWeight),
  score_projected: z.number(),
  score_raw: z.number(),
  extracted: z.boolean(),
  justification: z.array(z.string()).optional(),
  components: z.record(z.string(), TagScoreComponentSchema)
});

export type ThreatProfileTag = Required<z.infer<typeof ThreatProfileTagSchema>>;
