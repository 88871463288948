import React from 'react';

import Typography from 'snap-ui/Typography';

import { BASCampaign } from 'module/BAS/BAS.type';

import { CampaignReportSectionPlaceholder } from './CampaignReport.style';
import { ControlsPanel, OutcomesPanel, TestsExecutedPanel } from './Panels';

type AssessmentResultsSectionProps = {
  campaign: BASCampaign;
  isLoading: boolean;
};

function AssessmentResultsSection({ campaign, isLoading }: AssessmentResultsSectionProps) {
  return isLoading ? (
    <CampaignReportSectionPlaceholder />
  ) : (
    <div className='CampaignReport-section'>
      <Typography variant='h2'>Assessment Results</Typography>
      <div className='CampaignReport-charts'>
        <TestsExecutedPanel campaign={campaign} />
        <OutcomesPanel campaign={campaign} />
        <ControlsPanel campaign={campaign} />
      </div>
    </div>
  );
}

export default AssessmentResultsSection;
