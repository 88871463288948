import React from 'react';

import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import zod from 'zod';

import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { FilterAugment, FilterControl } from 'module/GlobalFilter';
import { getTagWeightLabel } from 'module/ThreatProfile';
import { ThreatPriorityBadge } from 'module/ThreatProfile/ThreatProfile.style';
import { TagWeight } from 'module/ThreatProfile/ThreatProfile.type';

import { useAuth } from 'provider';

import { ArtifactType } from 'types/common';
import { Ops, Query } from 'types/filter';

import { FilterConfig } from '../GlobalFilter.type';
import { addOrRemoveValue } from '../GlobalFilter.util';

type ThreatPriorityKeys = {
  priority: TagWeight[];
};

type ThreatProfilePriorityFilterProps = {
  onChange(values: ThreatPriorityKeys): void;
  values: ThreatPriorityKeys;
};

const StyledThreatPriorityBadge = styled(ThreatPriorityBadge)`
  min-width: 130px;
  font-size: 0.75rem;
`;

function ThreatProfilePriorityFilter({ onChange, values }: ThreatProfilePriorityFilterProps): React.ReactElement {
  const { isSubscriber } = useAuth();
  return (
    <FilterControl disabled={!isSubscriber}>
      <FormLabel id='threat-priority-toggle-button-group-label' className='title-tooltip'>
        By Threat Profile Priority
        <Tooltip
          title={
            !isSubscriber
              ? 'This filter is only available to subscribers'
              : "Prioritization of threats in your organization's threat profile"
          }
          placement='right'
          arrow
          wrap
        >
          <Icon.Info />
        </Tooltip>
      </FormLabel>
      <FormGroup aria-labelledby='threat-priority-toggle-button-group-label'>
        {Object.values(TagWeight).map(option => (
          <FormControlLabel
            key={option}
            control={<Checkbox onChange={handleChange} value={option} checked={values.priority.includes(option)} />}
            label={
              <StyledThreatPriorityBadge
                className={option}
                icon={<Icon icon={faTriangleExclamation} />}
                value={getTagWeightLabel(option, false)}
              />
            }
          />
        ))}
      </FormGroup>
    </FilterControl>
  );

  function handleChange(e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    const v = e.currentTarget.value as TagWeight;
    const priority = addOrRemoveValue(checked, values.priority, v);
    onChange({ priority });
  }
}

function checkForCompleteSet(values: TagWeight[]): boolean {
  return !values?.length || Object.values(TagWeight).every(weight => values?.includes(weight));
}

function getJsonPathValue(orgId: number, values: TagWeight[]) {
  return `$."${orgId}" ? (${values.map(val => `@ == "${val}"`).join(' || ')})`;
}

function toQuery(values: ThreatPriorityKeys, { orgId }: FilterAugment): Query {
  if (checkForCompleteSet(values.priority)) return;

  return {
    field: 'feed_profiles.profiles',
    op: Ops.json_path_exists,
    value: getJsonPathValue(orgId, values.priority)
  };
}

const fromQuery = zod
  .object({
    op: zod.literal(Ops.json_path_exists),
    field: zod.literal('feed_profiles.profiles'),
    value: zod.string()
  })
  .transform(query => {
    return { priority: Object.values(TagWeight).filter(tag => query.value.includes(`"${tag}"`)) };
  });

const ThreatProfilePriorityFilterConfig: FilterConfig<ThreatPriorityKeys> = {
  defaults: { default: () => ({ priority: Object.values(TagWeight) }) },
  supportedTopics: [ArtifactType.Collection, ArtifactType.AttackTag],
  component: ThreatProfilePriorityFilter,
  toQuery: { default: toQuery, [ArtifactType.AttackTag]: () => null },
  fromQuery: { default: fromQuery }
};
export default ThreatProfilePriorityFilterConfig;
