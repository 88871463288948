import React from 'react';

import { faHexagon } from '@fortawesome/pro-regular-svg-icons';
import classnames from 'classnames';

import Icon from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

type Props = {
  letterGrade: string;
  text?: string;
  percentageGrade?: number;
  className?: string;
};

const LetterGrade = styled('div')`
  font-size: 6rem;

  .grade {
    color: ${p => p.theme.palette.common.white};
    font-size: 2.2rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.grade-a {
    color: ${p => p.theme.palette.lime.main};
  }

  &.grade-b {
    color: ${p => p.theme.palette.lime.light};
  }

  &.grade-c {
    color: ${p => p.theme.palette.warning.main};
  }

  &.grade-d {
    color: ${p => p.theme.palette.warning.dark};
  }

  &.grade-f {
    color: ${p => p.theme.palette.error.dark};
  }
`;

function PreparednessGradeComponent({ className, letterGrade, percentageGrade, text }: Props) {
  const letter = letterGrade?.toLowerCase().split('')[0] || 'f';

  const displayContent = () => {
    if (!text && !percentageGrade) {
      return null;
    }

    if (text) {
      return <div className='text'>{text}</div>;
    }

    if (percentageGrade) {
      return (
        <div className='numeric-grade'>
          {Math.floor(percentageGrade)}
          <span className='divisor'>/100</span>
        </div>
      );
    }
  };

  return (
    <div className={classnames('PreparednessGrade', className)}>
      <LetterGrade className={classnames('LetterGrade fa-layers fa-fw', `grade-${letter}`)}>
        <Icon icon={faHexagon} />
        <span className='grade'>{letterGrade}</span>
      </LetterGrade>
      {displayContent()}
    </div>
  );
}

const PreparednessGrade = styled(PreparednessGradeComponent)<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${p => p.theme.spacing(5)};

  .text {
    font-size: 1.4rem;
  }

  .numeric-grade {
    font-size: 2.5rem;
  }

  .divisor {
    font-size: 1.5rem;
  }
`;

export default PreparednessGrade;
