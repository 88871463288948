import React from 'react';

import { faArrowLeft, faCopy, faSave, faTrash, faFlaskGear } from '@fortawesome/pro-solid-svg-icons';
import { Link, Prompt } from 'react-router-dom';

import Badge from 'snap-ui/Badge';
import { ActionIconButton } from 'snap-ui/Button';
import ConfirmDialog from 'snap-ui/Dialog/ConfirmDialog';
import { MenuTrigger } from 'snap-ui/Menu';
import MenuItem from 'snap-ui/MenuItem';
import SnapToolbar from 'snap-ui/Toolbar';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import useConfirmBeforeUnload from 'hooks/useConfirmBeforeUnload';

import { AlphaFeature, CanAlpha } from 'module/AlphaFeatures';
import { checkAnalyticPermission } from 'module/Analytic/Analytic.util';
import { getCloneState } from 'module/Analytic/core/EditorStateProvider';
import { IDEStatusState } from 'module/Analytic/core/IDE/IDEStatus';
import { IDEAction, IDEState } from 'module/IDE/reducer';
import { SupplementalArtifact } from 'module/Search';
import { useSyntaxCheckerContext } from 'module/SyntaxChecker';
import { RouterMessage } from 'module/Util/RouterPrompt';

import { useAuth, useIntegrationCatalog } from 'provider';

import { checkTaskPermission } from 'services/authService';

import { LabTestableCompilationTargets } from 'types/analytic';
import { ContentPermission, FunctionalPermission, Permitted } from 'types/auth';

const Toolbar = styled(SnapToolbar)`
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row-reverse;
  gap: ${p => p.theme.spacing(3)};
  margin-right: ${p => p.theme.spacing(3)};
`;

type BuilderToolbarProps = {
  emitChange(action: IDEAction): void;
  disableSave?: boolean;
  handleDelete(): void;
  handleHunt(): void;
  handleSave(): void;
  handlePreview(): void;
  ideState: IDEState;
  isModified: boolean;
  status: IDEStatusState;
  permissions: Permitted;
  supplemental: SupplementalArtifact;
};

export default function BuilderToolbar(props: BuilderToolbarProps): React.ReactElement {
  const { defaultOrgId, permission: orgs } = useAuth();
  const closePath = props.ideState.guid ? `${Path.Detection}/${props.ideState.guid}` : null;
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const [canDelete] = checkAnalyticPermission(props.permissions, props.supplemental, ContentPermission.Delete);
  const canClone = checkTaskPermission(orgs, FunctionalPermission.CreateAnalytic);
  const { integrations } = useIntegrationCatalog();
  const { syntaxCheckerParams } = useSyntaxCheckerContext();
  const integrationName = props.ideState.isNative
    ? integrations.all.find(i => i.id === syntaxCheckerParams?.integrationId)?.name
    : undefined;

  useConfirmBeforeUnload(props.isModified, [props.isModified]);

  let saveButtonLabel = 'Save detection';
  if (props.isModified) {
    saveButtonLabel += ' — there are unsaved changes';
  }
  if (props.disableSave) {
    saveButtonLabel = 'There are syntax errors that must be fixed before the detection can be saved.';
  }

  const deleteButtonLabel = 'Delete detection';

  const reallyDisableSave =
    props.disableSave || // container says so
    !!(!props.ideState.isModified && props.ideState.guid) || // existing detection is not modified
    props.status !== IDEStatusState.Ready; // some update is in progress

  const canTestInLab = LabTestableCompilationTargets.includes(props.ideState.analyticForm.languageId);
  const canTestInIntegration = props.ideState.isNative
    ? integrations.huntable.some(i => i.hunt_targets.some(t => t.id === props.ideState.analyticForm.languageId))
    : integrations.huntable.length;
  const testButtonTooltip =
    canTestInLab || canTestInIntegration
      ? 'Test your detection'
      : 'This detection cannot be tested because it is not compatible with your integrations or the SnapAttack threat library';
  const cloneEditor = getCloneState(props.ideState, defaultOrgId, props.ideState.guid);

  return (
    <Toolbar className='MuiToolBar-override'>
      <Prompt
        message={location =>
          location.pathname === Path.IDE ||
          !props.isModified ||
          JSON.stringify({
            title: 'Are you sure?',
            children: 'You have unsaved changes that will be lost if you navigate away',
            submitText: 'Go Back',
            secondaryText: 'Discard Changes'
          } as RouterMessage)
        }
      />
      <MenuTrigger
        trigger={({ ref, toggle, className }) => (
          <Tooltip title={testButtonTooltip} arrow wrap>
            <ActionIconButton
              aria-label={testButtonTooltip}
              className={className}
              disabled={!(canTestInLab || canTestInIntegration) || props.status !== IDEStatusState.Ready}
              icon={faFlaskGear}
              onClick={toggle}
              ref={ref}
            />
          </Tooltip>
        )}
      >
        {[
          <CanAlpha key='integration' feature={AlphaFeature.TestInIntegration}>
            <Tooltip
              arrow
              wrap
              placement='left'
              title={!canTestInIntegration ? 'This detection is not compatible with your integrations' : undefined}
            >
              <MenuItem disabled={!canTestInIntegration} onClick={props.handleHunt}>
                {integrationName ? `Test in ${integrationName}` : 'Select Integration'}
              </MenuItem>
            </Tooltip>
          </CanAlpha>,
          <Tooltip
            key='lab'
            arrow
            wrap
            placement='left'
            title={!canTestInLab ? 'This detection is not compatible with the SnapAttack threat library' : undefined}
          >
            <MenuItem disabled={!canTestInLab} onClick={props.handlePreview}>
              SnapAttack Threat Library
            </MenuItem>
          </Tooltip>
        ]}
      </MenuTrigger>
      <Tooltip title={saveButtonLabel} placement='bottom' arrow wrap>
        <Badge variant='dot' invisible={!props.isModified} color={props.disableSave ? 'secondary' : 'primary'}>
          <ActionIconButton
            disabled={reallyDisableSave}
            icon={faSave}
            aria-label={saveButtonLabel}
            onClick={props.handleSave}
          />
        </Badge>
      </Tooltip>
      {props.ideState.guid && canClone && (
        <Tooltip title='Clone detection' placement='bottom' arrow wrap>
          <ActionIconButton
            aria-label='Clone detection'
            component={Link}
            icon={faCopy}
            to={{
              pathname: Path.IDEReset,
              state: {
                editor: cloneEditor
              }
            }}
            disabled={!props.ideState.guid || props.status !== IDEStatusState.Ready}
          />
        </Tooltip>
      )}
      {canDelete && (
        <Tooltip title={deleteButtonLabel} placement='bottom' arrow wrap>
          <ActionIconButton
            aria-label={deleteButtonLabel}
            icon={faTrash}
            onClick={handleDeleteConfirm}
            disabled={!props.ideState.guid || props.status !== IDEStatusState.Ready}
          />
        </Tooltip>
      )}
      <ConfirmDialog
        DialogProps={{
          open: showDeleteConfirm,
          onClose: (): void => setShowDeleteConfirm(false)
        }}
        ConfirmProps={{
          children: 'Delete',
          onClick: handleDelete
        }}
        title='Delete Detection'
      >
        <div>Are you sure you want to delete the detection?</div>
        <div>This operation can not be undone.</div>
      </ConfirmDialog>
      {closePath && (
        <Tooltip title='Go back to detection' placement='bottom' arrow>
          <ActionIconButton icon={faArrowLeft} aria-label='Go back to detection' LinkComponent={Link} to={closePath} />
        </Tooltip>
      )}
    </Toolbar>
  );

  function handleDeleteConfirm(): void {
    if (!props.isModified) {
      setShowDeleteConfirm(true);
    } else props.handleDelete(); // let the unsaved changes checker handle confirmation
  }

  function handleDelete(): void {
    props.handleDelete();
    setShowDeleteConfirm(false);
  }
}
