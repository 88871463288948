import React from 'react';

import { faBars } from '@fortawesome/pro-solid-svg-icons';

import Accordion, { AccordionDetails, AccordionSummary } from 'snap-ui/Accordion';
import Icon from 'snap-ui/Icon';
import Paper from 'snap-ui/Paper';
import Popper from 'snap-ui/Popper';
import { ClickAwayListener, styled } from 'snap-ui/util';

import { useMayI } from 'module/May';

import { useAuth } from 'provider';

import { FunctionalPermission } from 'types/auth';

import { handleDebug, NOOP } from 'utilities/FunctionUtils';

import { Glossary, MenuOrdinal } from '../Scaffold.type';
import Copyright from './Copyright';
import ListItemsDetect from './ListItemsDetect';
import ListItemsHunt from './ListItemsHunt';
import ListItemsProfile from './ListItemsProfile';
import ListItemsReport from './ListItemsReport';
import ListItemsResearch from './ListItemsResearch';
import ListItemsSandbox from './ListItemsSandbox';
import ListItemsValidate from './ListItemsValidate';
import { List } from './ToolbarItem';
import { Profile } from './ToolbarItemProfile';

type Props = {
  glossary: Glossary;
  onClick(id: string, element: HTMLElement | HTMLDivElement): void;
  onClose(): void;
};

const Toolbox = styled(Paper)`
  display: flex;
  margin-top: ${p => p.theme.spacing(1)};
  padding: ${p => p.theme.spacing(2, 1)};
  border-top: 3px solid ${p => p.theme.palette.primary.main};

  .MuiList-root {
    margin: 0;
    padding: 0;
    border: 0;
  }

  .AccordionList {
    margin-left: -4px;
  }

  .AccordionMenu-accordionSummary {
    display: flex;
    align-items: center;
    min-height: unset;
    height: ${p => p.theme.spacing(8)};
    font-size: 1rem;
    background-color: ${p => p.theme.palette.grey[900]};
    color: ${p => p.theme.palette.primary.main};
    margin: ${p => p.theme.spacing(3, 0)};
    padding-left: 18px;
    border-left: ${p => p.theme.spacing(1)} solid ${p => p.theme.palette.primary.main};
  }

  /* Need to allow list items to grow as needed */
  .MuiListItem-root {
    height: unset;
    min-height: 3rem;
  }

  /* Bases on the feed items divider. Maybe this and that should be extracted for common use */
  .divider {
    margin: ${p => p.theme.spacing(6, 0)};
    background: linear-gradient(1turn, hsla(0, 0%, 20%, 0.1), hsla(0, 0%, 40%, 0.3), hsla(0, 0%, 20%, 0.1));
    width: 2px;
  }
`;

export default function ToolbarItemBurger({ glossary, onClick, onClose }: Props) {
  const { user, permission, defaultOrgId } = useAuth();
  const isBasUser = useMayI(FunctionalPermission.BASStableFeatures);
  const handleOnClick = (event: React.MouseEvent<HTMLLIElement | HTMLDivElement>) => {
    const id = event.currentTarget.getAttribute('data-id');
    onClick(id, event.currentTarget);
  };

  const [expanded, setExpanded] = React.useState<string | false>('research');
  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleMouseEvent = (e: React.MouseEvent<HTMLDivElement>) =>
    handleDebug(e, () => {
      console.groupCollapsed(user.guid);
      console.info('defaultOrgId', defaultOrgId);
      console.info('permission', permission);
      console.info('user', user);
      console.groupEnd();
    });

  return (
    <>
      <Profile
        aria-owns={`${MenuOrdinal.Burger}-menu-item`}
        data-id={MenuOrdinal.Burger}
        data-testid='menu-burger'
        tabIndex={0}
        role='menuitem'
        onClick={handleOnClick}
        onMouseEnter={handleMouseEvent}
      >
        <Icon icon={faBars} />
      </Profile>
      <Popper
        disablePortal
        anchorEl={glossary[MenuOrdinal.Burger]}
        open={Boolean(glossary[MenuOrdinal.Burger])}
        className='ToolbarItem-popper'
      >
        <ClickAwayListener onClickAway={open ? onClose : NOOP}>
          <Toolbox id={`${MenuOrdinal.Burger}-menu-item`} elevation={0} square>
            <List className='AccordionList' component={Paper} elevation={0} square>
              <Accordion
                className='AccordionMenu-accordion'
                expanded={expanded === 'research'}
                onChange={handleChange('research')}
                elevation={0}
              >
                <AccordionSummary className='AccordionMenu-accordionSummary'>Research</AccordionSummary>
                <AccordionDetails className='AccordionMenu-accordionDetails'>
                  <ListItemsResearch onClose={onClose} />
                </AccordionDetails>
              </Accordion>
              <Accordion
                className='AccordionMenu-accordion'
                expanded={expanded === 'detect'}
                onChange={handleChange('detect')}
                elevation={0}
              >
                <AccordionSummary className='AccordionMenu-accordionSummary'>Detect</AccordionSummary>
                <AccordionDetails className='AccordionMenu-accordionDetails'>
                  <ListItemsDetect onClose={onClose} />
                </AccordionDetails>
              </Accordion>
              <Accordion
                className='AccordionMenu-accordion'
                expanded={expanded === 'hunt'}
                onChange={handleChange('hunt')}
                elevation={0}
              >
                <AccordionSummary className='AccordionMenu-accordionSummary'>Hunt</AccordionSummary>
                <AccordionDetails className='AccordionMenu-accordionDetails'>
                  <ListItemsHunt onClose={onClose} />
                </AccordionDetails>
              </Accordion>
              {isBasUser && (
                <Accordion
                  className='AccordionMenu-accordion'
                  expanded={expanded === 'validate'}
                  onChange={handleChange('validate')}
                  elevation={0}
                >
                  <AccordionSummary className='AccordionMenu-accordionSummary'>Validate</AccordionSummary>
                  <AccordionDetails className='AccordionMenu-accordionDetails'>
                    <ListItemsValidate onClose={onClose} />
                  </AccordionDetails>
                </Accordion>
              )}
              <Accordion
                className='AccordionMenu-accordion'
                expanded={expanded === 'report'}
                onChange={handleChange('report')}
                elevation={0}
              >
                <AccordionSummary className='AccordionMenu-accordionSummary'>Report</AccordionSummary>
                <AccordionDetails className='AccordionMenu-accordionDetails'>
                  <ListItemsReport onClose={onClose} />
                </AccordionDetails>
              </Accordion>
              <Accordion
                className='AccordionMenu-accordion'
                expanded={expanded === 'sandbox'}
                onChange={handleChange('sandbox')}
                elevation={0}
              >
                <AccordionSummary className='AccordionMenu-accordionSummary'>Sandbox</AccordionSummary>
                <AccordionDetails className='AccordionMenu-accordionDetails'>
                  <ListItemsSandbox onClose={onClose} />
                </AccordionDetails>
              </Accordion>
            </List>
            <div className='divider' />
            <List component={Paper} elevation={0} square>
              <ListItemsProfile onClose={onClose} />
              <Copyright withStatus />
            </List>
          </Toolbox>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
