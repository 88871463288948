import React from 'react';

import { Link } from 'react-router-dom';

type ShouldLinkProps = {
  to?: string | { pathname: string; search?: string; state?: any };
  children: React.ReactNode;
  className?: string;
};

function ShouldLink({ to, children, className = '' }: ShouldLinkProps) {
  if (to) {
    return (
      <Link to={to} target='_blank' rel='noopener noreferrer' className={className}>
        {children}
      </Link>
    );
  }
  return <>{children}</>;
}

export default ShouldLink;
