import React from 'react';

import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons';
import * as Sentry from '@sentry/react';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import Button from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import { styled, useTheme } from 'snap-ui/util';

import Copyright from 'module/Scaffold/core/Copyright';
import OrButton from 'module/Widgets/OrButton';

import Fallback from './Fallback/Fallback';

/**
 
In development mode, React will rethrow errors caught within an error boundary.
This will result in errors being reported twice to Sentry with the above setup,
but this won’t occur in your production build.
 
@see https://docs.sentry.io/platforms/javascript/guides/react/components/errorboundary/
 

Also Error boundaries do not catch errors for:
  - Event handlers (learn more)
  - Asynchronous code (e.g. setTimeout or requestAnimationFrame callbacks)
  - Server side rendering
  - Errors thrown in the error boundary itself (rather than its children)
@see https://reactjs.org/docs/error-boundaries.html

If user data is needed, that can be obtained via `Sentry.getCurrentHub().getScope().getUser();`
 */
function SentryErrorBoundary({ children }: { children: React.ReactNode }) {
  return <Sentry.ErrorBoundary fallback={props => <FallbackWithLayout {...props} />}>{children}</Sentry.ErrorBoundary>;
}

const Container = styled('div')`
  header {
    display: flex;
    padding: ${p => p.theme.spacing(2)};
    background-color: ${p => p.theme.palette.background.paper};

    .logo-button {
      background-color: transparent;
      box-shadow: none;
    }

    img {
      height: 40px;
      width: auto;
    }
  }
`;
function FallbackWithLayout({ eventId }: { eventId: string | null }) {
  const { palette } = useTheme();
  const handleClick = () => {
    window.location.replace('/');
  };

  return (
    <Container>
      <header>
        <Button onClick={handleClick} className='logo-button'>
          <img className='logo' src={process.env.PUBLIC_URL + '/images/snapattack.png'} alt='SnapAttack' />
        </Button>
      </header>
      <Fallback
        message={`A non-recoverable error has occurred. We've notified the developers, but feel free to submit a bug ticket to help us reproduce the issue`}
        action={
          <OrButton color='secondary' onClick={handleClick}>
            <Icon icon={faSyncAlt} color='blue' />
            <span>Let&apos;s start over</span>
          </OrButton>
        }
      >
        <Alert severity='error'>
          <AlertTitle>Error Details</AlertTitle>
          <ul>
            <li>
              <span style={{ color: palette.grey[400] }}>event id:</span> {eventId}
            </li>
          </ul>
        </Alert>
      </Fallback>
      <footer style={{ textAlign: 'center', margin: '20px 0' }}>
        <Copyright />
      </footer>
    </Container>
  );
}

export default SentryErrorBoundary;
