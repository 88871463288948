import { DataGrid } from 'snap-ui/DataGrid';
import Card from 'snap-ui/Paper';
import { styled } from 'snap-ui/util';

export const Table = styled(DataGrid)`
  max-height: 550px;
`;

export const ErrorContainer = styled('div')`
  padding: ${p => p.theme.spacing(4, 8, 8)};
`;

export const StatGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${p => p.theme.spacing(4)};
  max-width: 2000px;

  &.three {
    @media (min-width: 600px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: 900px) {
      margin: 0 auto;
    }

    @media (min-width: 1200px) {
      gap: ${p => p.theme.spacing(6)};
    }
  }

  &.four {
    @media (min-width: 500px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1000px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media (min-width: 1800px) {
      margin: 0 auto;
      gap: ${p => p.theme.spacing(6)};
    }
  }
`;

export const StatPanel = styled(Card)`
  a {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: ${p => p.theme.spacing(5, 5, 8)};
    gap: ${p => p.theme.spacing(3)};
    align-items: center;
    border: 3px solid;

    &.healthy {
      color: ${p => p.theme.palette.success.light};
    }

    &.outdated {
      color: ${p => p.theme.palette.warning.light};
    }

    &.tuning {
      color: ${p => p.theme.palette.orange.light};
    }

    &.errors {
      color: ${p => p.theme.palette.error.light};
    }
  }

  .MuiCircularProgress-root {
    opacity: 0.75;
  }

  .title {
    align-items: center;
    display: flex;
    gap: ${p => p.theme.spacing(3)};

    .MuiTypography-body1 {
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 600;
    }
  }

  .description {
    font-weight: bold;
    font-size: 7rem;
    line-height: 5rem;
    margin: 0;
  }
`;
