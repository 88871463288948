import React from 'react';

import { useAuth } from 'provider';

import { ErrorProps, Status, useAsync } from 'storage';

import { putProfileBuild } from './ThreatProfileWizard.api';

type BuildThreatProfileInterface = {
  buildProfile(): Promise<void>;
  buildStatus: Status;
  buildErrorProps: ErrorProps;
};

export default function useBuildThreatProfile(): BuildThreatProfileInterface {
  const { user } = useAuth();
  const orgGuid = user.preferred_organization.guid;

  const { task: buildTask, status: buildStatus, errorProps: buildErrorProps } = useAsync();

  const buildProfile = React.useCallback(() => {
    return buildTask(putProfileBuild(orgGuid));
  }, [buildTask, orgGuid]);

  return {
    buildProfile,
    buildStatus,
    buildErrorProps
  };
}
