import React from 'react';

import classnames from 'classnames';

import { Autocomplete, Option } from 'snap-ui/Autocomplete';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Radio from 'snap-ui/Radio';
import RadioGroup from 'snap-ui/RadioGroup';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { StrictReactNode } from 'types/core';
import { Ops } from 'types/filter';

import { FilterControl } from '../GlobalFilter.style';

const FormControl = styled(FilterControl)`
  .form-label {
    display: flex;

    & span {
      align-items: center;
      margin-left: ${p => p.theme.spacing(2)};
    }

    & .sa-spin-faster {
      margin-left: auto;
      justify-self: end;
    }
  }
`;

type Props = {
  allowUserAdditions?: boolean;
  auxiliaryAction?: StrictReactNode;
  children?: StrictReactNode;
  className?: string;
  disabled?: boolean;
  disabledTooltip?: string;
  disableInclusionGroup?: boolean;
  name: string;
  onOperatorChange: (operator: Ops) => void;
  onSearch?: (v: string) => void;
  onValueChange: (option: Option[]) => void;
  operator: Ops;
  option: Option[];
  searching?: boolean;
  title: string;
  tooltip?: string;
  value: string[];
};

/**
 * This component will provide an auto completable list with radio options for specifying how selected options
 * should be used within the query filter.
 */
function InclusiveAutocomplete({ onSearch, ...props }: Props) {
  const handleOperatorChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    props.onOperatorChange(value as Ops);
  };

  function handleSearch(v: string) {
    if (onSearch) onSearch(v);
  }

  return (
    <FormControl
      className={classnames('InclusiveAutocomplete filter-widget', props.className)}
      disabled={props.disabled}
    >
      <FormLabel id={`${props.name}-label`} className='form-label'>
        {props.title}
        {(props.disabledTooltip || props.tooltip) && (
          <Tooltip title={props.disabled ? props.disabledTooltip : props.tooltip} placement='right' wrap arrow>
            <Icon.Info />
          </Tooltip>
        )}
        {props.searching && <Icon.SpinnerProgress color='primary' />}
      </FormLabel>
      <FormGroup aria-labelledby={`${props.name}-label`}>
        <Autocomplete
          multiple
          name={props.name}
          onChange={props.onValueChange}
          onInputChange={handleSearch}
          options={props.option}
          value={props.value}
          disabled={props.disabled}
          disableUserAdditions={!props.allowUserAdditions}
        />
        {props.children}
      </FormGroup>
      {!props.disableInclusionGroup && props.value?.length > 0 && (
        <RadioGroup
          className='radio-group'
          aria-labelledby={`${props.name}-op`}
          defaultValue={props.operator}
          name={`${props.name}-op-group`}
          value={props.operator}
          onChange={handleOperatorChange}
        >
          <FormControlLabel value={Ops.any} control={<Radio />} label='OR' />
          <FormControlLabel value={Ops.all} control={<Radio />} label='AND' />
          <FormControlLabel value={Ops.not} control={<Radio />} label='NOT' />
        </RadioGroup>
      )}
      {props.disableInclusionGroup && props.value?.length > 0 && (
        <RadioGroup
          className='radio-group'
          aria-labelledby={`${props.name}-op`}
          defaultValue={props.operator}
          name={`${props.name}-op-group`}
          value={props.operator}
        >
          <FormControlLabel value={Ops.any} control={<Radio disabled checked />} label='OR' />
        </RadioGroup>
      )}
      {props.auxiliaryAction}
    </FormControl>
  );
}

export default InclusiveAutocomplete;
