import React from 'react';

import isEqual from 'lodash/isEqual';

import {
  useIDETranslateControls,
  getLogicValues,
  hasLogsource,
  DetectionFields,
  IDETranslateControls
} from 'module/TranslateAnalytic';

import { useLanguageContext } from 'provider';

import { useIDEState } from './IDEStateProvider';

const IDETranslationStateContext = React.createContext<IDETranslateControls>(null);
IDETranslationStateContext.displayName = 'IDETranslationStateContext';

export function IDETranslationStateProvider({
  alwaysTranslateSigma,
  children
}: React.PropsWithChildren<{ alwaysTranslateSigma?: boolean }>): JSX.Element {
  const { allData: languages } = useLanguageContext();
  const { ideState } = useIDEState();

  const previousDetectionFields = React.useRef<DetectionFields>();
  const previousLanguageId = React.useRef<number>();

  const shouldTranslate = React.useCallback(
    (languageId: number) => {
      const language = languages.find(l => l.id === languageId);
      if (ideState.isNative) return false;
      if (alwaysTranslateSigma) return true;

      // don't try to translate if the logsource is missing
      if (!hasLogsource(ideState)) {
        return false;
      }

      // if we selected a new language, definitely translate
      if (languageId !== previousLanguageId.current) {
        return true;
      }

      // don't fetch a translation if we haven't changed logic fields
      if (
        isEqual(
          getLogicValues(ideState.analyticForm, language.backend_key),
          getLogicValues(previousDetectionFields.current, language.backend_key)
        )
      ) {
        return false;
      }

      return true;
    },
    [alwaysTranslateSigma, ideState, languages]
  );

  const value = useIDETranslateControls(ideState, shouldTranslate);

  React.useEffect(() => {
    previousLanguageId.current = value.selectedLanguage?.id;
  });

  React.useEffect(() => {
    previousDetectionFields.current = getLogicValues(ideState.analyticForm);
  });

  return <IDETranslationStateContext.Provider value={value}>{children}</IDETranslationStateContext.Provider>;
}

export function useIDETranslationState(): IDETranslateControls {
  const value = React.useContext(IDETranslationStateContext);
  if (!value) {
    throw new Error('useIDETranslationState must be used inside IDETranslationStateProvider');
  }
  return value;
}
