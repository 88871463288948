import React from 'react';

import { faRefresh } from '@fortawesome/pro-solid-svg-icons';

import Button from 'snap-ui/Button';
import { GridFooterContainer, useGridApiContext } from 'snap-ui/DataGrid';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';

export function BoardFooter({ isActing, onRefresh }: { isActing: boolean; onRefresh(): void }) {
  const apiRef = useGridApiContext();

  return (
    <GridFooterContainer>
      <Button
        disabled={isActing}
        color='inherit'
        variant='text'
        endIcon={<Icon icon={faRefresh} />}
        onClick={onRefresh}
      >
        REFRESH
      </Button>
      <Typography>
        {apiRef.current.getRowsCount()} {`player${apiRef.current.getRowsCount() !== 1 ? 's' : ''}`}
      </Typography>
    </GridFooterContainer>
  );
}
