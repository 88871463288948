import React from 'react';

import {
  Cell,
  Label,
  LabelProps,
  Legend,
  LegendProps as LegendProperties,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from 'recharts';
import { v4 as uuidv4 } from 'uuid';

import { theme } from 'snap-ui/theme';
import { styled, useTheme } from 'snap-ui/util';

import { PieChartData } from 'module/Dashboard/SocDashboard/SocDashboard.type';

import { TooltipDefaults } from './components/TooltipDefaults';

export type PieChartProps = {
  data: PieChartData;
  useLegend?: boolean;
  showValueInLegend?: boolean;
  legendProps?: Partial<Omit<LegendProperties, 'ref'>>;
  labelProps?: Partial<Omit<LabelProps, 'ref'>>;
  useTooltip?: boolean;
  height?: number | string;
  width?: number | string;
  outerRadius?: number | string;
  innerRadius?: number | string;
  colors?: Partial<{
    main: string;
    light: string;
  }>[];
  onPieClick?: any;
};

const HEIGHT = 240;
const WIDTH = '100%';
const OUT_RADIUS = 80;
const DEFAULT_COLORS = [
  theme.palette.lime,
  theme.palette.aqua,
  theme.palette.orange,
  theme.palette.mint,
  theme.palette.pink,
  theme.palette.blue,
  theme.palette.purple,
  theme.palette.yellow,
  theme.palette.red
];

const Container = styled(ResponsiveContainer)`
  .recharts-legend-wrapper {
    font-size: 1rem;
  }
  ul li.recharts-legend-item svg {
    margin-bottom: ${p => p.theme.spacing(1)};
  }

  & .recharts-text.recharts-label {
    font-size: 5em;
    fill: ${p => p.theme.palette.common.white};
    background-color: ${p => p.theme.palette.common.black};
    color: ${p => p.theme.palette.common.white};
    text-shadow: 2px 1px 2px ${p => p.theme.palette.common.black};
    background-clip: text;
    border: 1px solid ${p => p.theme.palette.common.black};
  }
`;

function CustomPieChart({
  data,
  colors = DEFAULT_COLORS,
  useLegend = false,
  showValueInLegend = false,
  legendProps = {
    layout: 'vertical',
    verticalAlign: 'middle',
    align: 'right'
  },
  labelProps,
  useTooltip = false,
  height = HEIGHT,
  width = WIDTH,
  innerRadius,
  outerRadius = OUT_RADIUS,
  onPieClick = null
}: PieChartProps) {
  const { palette } = useTheme();
  const uuid = uuidv4();

  const chartData = showValueInLegend
    ? data.map(({ name, value }) => ({
        name: `${name}: ${value}`,
        value
      }))
    : data;

  const valueInLegendProps = showValueInLegend
    ? {
        separator: '',
        formatter: () => null
      }
    : {};

  return (
    <Container width={width} height={height}>
      <PieChart>
        <defs>
          {chartData.map((_, index) => {
            return (
              <linearGradient id={`gradient-${index}-${uuid}`} key={index} gradientTransform='rotate(45)'>
                <stop offset='45%' stopColor={colors[index % colors.length].light} />
                <stop offset='75%' stopColor={colors[index % colors.length].main} />
              </linearGradient>
            );
          })}
        </defs>
        <Pie
          data={chartData}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          dataKey='value'
          stroke={palette.background.default}
          strokeWidth={1}
          onClick={onPieClick}
        >
          {data.map((_, index) => {
            return <Cell key={`cell-${index}`} fill={`url(#gradient-${index}-${uuid})`} />;
          })}
          {labelProps && <Label {...labelProps} />}
        </Pie>
        {useLegend && (
          <Legend
            {...legendProps}
            payload={data.map((item, index) => ({
              id: item.name,
              type: 'circle',
              value: `${item.name} (${item.value})`,
              color: colors[index % colors.length].main
            }))}
          />
        )}
        {useTooltip && <Tooltip {...TooltipDefaults} {...valueInLegendProps} />}
      </PieChart>
    </Container>
  );
}

export default CustomPieChart;
