import React from 'react';

import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';

import Accordion, { AccordionDetails, AccordionSummary } from 'snap-ui/Accordion';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';

import { Discriminator } from 'module/Tag';

import { TargetIndustryDetails } from 'types/mati';

import { CategoryContainer } from '../../Landing.style';
import { useLandingCatalog } from '../../LandingProvider';

const VALID_DISCRIMINATORS = [Discriminator.Software, Discriminator.Actor];
export default function TargetIndustries() {
  const { source, type } = useLandingCatalog();

  if (isEmpty(source.industries) || VALID_DISCRIMINATORS.every(discriminator => discriminator !== type)) return null;

  return (
    <Accordion className='LandingSection-accordion' defaultExpanded elevation={0}>
      <AccordionSummary className='LandingSection-accordionSummary' expandIcon={<Icon icon={faCaretUp} />}>
        <Typography variant='h4'>Target Industries</Typography>
      </AccordionSummary>
      <AccordionDetails className='category-title'>
        <CategoryContainer>
          <div className='category-detail'>
            {source.industries
              .sort((a, b) => a?.name.localeCompare(b.name))
              .map((industry, index) => {
                const industryDetail = TargetIndustryDetails[industry.name];
                return (
                  <div className='icon-title' key={index}>
                    <Icon icon={industryDetail?.icon} /> {industry.name}
                  </div>
                );
              })}
          </div>
        </CategoryContainer>
      </AccordionDetails>
    </Accordion>
  );
}
