import React from 'react';

import classnames from 'classnames';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import BackdropLoader from 'snap-ui/BackdropLoader';
import { styled } from 'snap-ui/util';

import { asValidationError, ValidationError } from 'apis';

import Path from 'constants/paths';

import useNoPermitCreateRedirect from 'hooks/useNoPermitCreateRedirect';
import useOrganizationOptions from 'hooks/useOrganizationOptions';
import useTitle from 'hooks/useTitle';

import { Engage, Fingerprint } from 'lib/Engagement';

import { putCollection, saveCollection } from 'module/Collection/Collection.api';
import { Collection } from 'module/Collection/Collection.type';
import { standardFormikBaseProps } from 'module/Form';
import Formik from 'module/Form/Formik';
import { Artifact } from 'module/Layout';

import { useAuth } from 'provider';

import { Status } from 'storage';

import { ContentPermission, FunctionalPermission } from 'types/auth';
import { ArtifactType } from 'types/common';

import useCollection from '../../Collection/useCollection';
import { buildCurationPayload } from '../Curation.service';
import { CurationInput, CurationSchema } from '../Curation.type';
import { ErrorAlert } from '../Curation.widgets';
import CurationEditHeader from './CurationEditHeader';
import CurationForm from './CurationForm';

const Container = styled(Artifact)`
  .ArtifactWidget-menuWrapper {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    gap: ${p => p.theme.spacing(3)};
  }
`;

type Props = RouteComponentProps<{ guid: string }> & {
  className?: string;
};

function CurationEdit({ className, match }: Props) {
  useNoPermitCreateRedirect(ArtifactType.Collection);
  const { push } = useHistory();
  const guid = match.params.guid;
  const [isActing, setIsActing] = React.useState(false);
  const { user, defaultOrgId } = useAuth();
  const options = useOrganizationOptions(FunctionalPermission.CreateCollection);
  const { collection, status } = useCollection(guid);
  const [error, setError] = React.useState<ValidationError>();
  const isEditMode = location.pathname.includes('edit');
  useTitle(isEditMode ? 'Edit Collection | SnapAttack' : 'Create Collection | SnapAttack');

  React.useEffect(() => {
    if (collection.guid && collection.name) {
      Engage.track(
        Fingerprint.load(Path.CollectionEdit).withData({
          guid: collection.guid,
          name: collection.name,
          artifact_organization_id: collection.organization?.id,
          artifact_organization_name: collection.organization?.name
        })
      );
      Engage.trackPersonIncrement(`view ${Path.CollectionEdit}`, 1);
    } else if (!guid) {
      Engage.track(Fingerprint.load(Path.CollectionCreate));
      Engage.trackPersonIncrement(`view ${Path.CollectionCreate}`, 1);
    }
  }, [collection.guid, collection.name, collection.organization?.id, collection.organization?.name, guid]);

  const initial: CurationInput = {
    organization: (guid ? collection?.organization?.id?.toString() : defaultOrgId?.toString()) || '',
    owner: (guid ? collection?.created_by?.name : user.name) || '',
    name: collection?.name || '',
    description: collection?.description || '',
    attack_names: collection?.attack_names || [],
    actor_names: collection?.actor_names || [],
    software_names: collection?.software_names || [],
    vulnerability_names: collection?.vulnerability_names || [],
    intel: [],
    session: [],
    analytic: []
  };

  return (
    <Formik {...standardFormikBaseProps} zodSchema={CurationSchema} initialValues={initial} onSubmit={handleSubmit}>
      {({ handleSubmit, values, resetForm }) => (
        <Container
          isPending={status === Status.pending}
          className={classnames('CurationEdit', className)}
          meta={
            <CurationEditHeader
              collection={
                guid
                  ? collection
                  : ({
                      created_by: { name: values.owner },
                      organization: { id: +values.organization }
                    } as Collection)
              }
              onPublish={handleSubmit}
              onReset={resetForm}
            />
          }
          type={ArtifactType.Collection}
        >
          <BackdropLoader open={isActing} title='Saving...' fixed />
          <ErrorAlert messages={error?.detail as string[]} />
          <CurationForm organization={options} />
        </Container>
      )}
    </Formik>
  );

  async function handleSubmit(values: CurationInput) {
    setIsActing(true);
    const payload = buildCurationPayload(values);

    if (guid) {
      Engage.track(Fingerprint.of(Path.CollectionEdit).withContent(ContentPermission.Edit).withData(values));
    } else {
      Engage.trackPersonIncrement(FunctionalPermission.CreateCollection, 1);
    }

    (guid ? putCollection(guid, payload) : saveCollection(payload)).then(
      data => push(`${Path.Collection}/${data.guid}`),
      (error: any) => {
        setIsActing(false);
        setError(asValidationError(error));
        Engage.track(Fingerprint.error(Path.CollectionEdit).withContent(ContentPermission.Edit).withData(error));
      }
    );
  }
}

export default CurationEdit;
