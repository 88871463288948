import React from 'react';

import { faCircle, faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

import Icon from 'snap-ui/Icon';

import { StackedIcon } from 'module/Widgets/MarkerIcons/StackedIcon';

type Props = {
  className?: string;
  inheritBackground?: boolean;
};

function UntestedAnalyticIcon({ inheritBackground, className }: Props): React.ReactElement {
  return (
    <StackedIcon className={classNames('non-domino-icon hollow-icon', className)}>
      <Icon icon={faCircle} color={inheritBackground ? 'inherit' : 'secondary'} />
      <Icon icon={faQuestionCircle} color='blue' />
    </StackedIcon>
  );
}

export default UntestedAnalyticIcon;
