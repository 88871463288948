import React from 'react';

import { Option } from 'snap-ui/Autocomplete';

import { getBASTools } from 'module/BAS/BAS.api';

import { Catalog, Scheme, useAsyncStorage } from 'storage';

import { BASTool } from 'types/bas';

export type BASCatalog = {
  toolOptions: Option[];
  tools: BASTool[];
  addTools(tools: BASTool[]): void;
};

const BASToolsContext = React.createContext<BASCatalog>(null);
BASToolsContext.displayName = 'BASToolsContext';

const useBASTools = (): BASCatalog => {
  const context = React.useContext(BASToolsContext);

  if (!context) {
    throw new Error('useBASTools must be used within the BASToolsContext');
  }

  return context;
};

function BASToolsProvider({ children }: { children: React.ReactNode }): React.ReactElement {
  const { data, setData, run } = useAsyncStorage<BASTool[]>(Scheme.sessionStorage, Catalog.bas_tool, []);

  React.useEffect(() => {
    run(() => getBASTools());
  }, [run]);

  const addTools = (newTools: BASTool[]) => setData([...data, ...newTools]);

  const catalog = { addTools, tools: data, toolOptions: data.map(tool => ({ content: tool, value: tool })) };

  return <BASToolsContext.Provider value={catalog}>{children}</BASToolsContext.Provider>;
}

export { BASToolsProvider, useBASTools };
