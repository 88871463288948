import React from 'react';

import { BASJobState } from 'module/BAS/BAS.type';

import { StateLabelBase, ValueMap } from './';

export const VALUE_MAP: ValueMap<BASJobState> = {
  [BASJobState.Canceled]: { color: 'greyish' },
  [BASJobState.Completed]: { color: 'success' },
  [BASJobState.Failed]: { color: 'error' },
  [BASJobState.InProgress]: { color: 'green', variant: 'outlined' },
  [BASJobState.MissingPrereq]: { color: 'warning' },
  [BASJobState.Pending]: { color: 'greyish', variant: 'outlined', label: 'Queued' },
  [BASJobState.TimedOut]: { color: 'orange' }
};

export function AttackJobStateLabel({ className, value }: { className?: string; value: BASJobState }): JSX.Element {
  return <StateLabelBase className={className} value={value} valueMap={VALUE_MAP} />;
}
