import React from 'react';

import { GridColumnHeaderParams, GridValidRowModel } from 'snap-ui/DataGrid';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

const Container = styled('span')`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export function ChallengeHeader({ p }: { p: GridColumnHeaderParams<GridValidRowModel, any, any> }) {
  return (
    <Tooltip title={p.colDef.headerName} placement='bottom'>
      <Container>{p.colDef.headerName}</Container>
    </Tooltip>
  );
}
