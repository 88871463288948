import React from 'react';

import { postRecommender } from 'module/Collection/Collection.api';
import {
  CollectionRecommenderType,
  RecommendationResponse,
  isCollectionRecommenderType
} from 'module/Curation/Curation.type';
import useMayI from 'module/May/useMayI';

import { useAsync } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { Guid } from 'types/common';

export function useRecommender() {
  const isRecommendationUser = useMayI(FunctionalPermission.CalculateRecommendation);
  const { data, run, status, reset } = useAsync<RecommendationResponse>();

  const getRecommendation = React.useCallback(
    (guid: Guid[], type: CollectionRecommenderType) => {
      const isRecommenderType = isCollectionRecommenderType(type);
      if (guid.length > 0 && isRecommenderType && isRecommendationUser) {
        run(postRecommender({ input_ids: guid }, type));
      }
    },
    [run, isRecommendationUser]
  );

  return {
    getRecommendation,
    data,
    status,
    recommendationReset: reset
  };
}
