import { Comparison, Condition, Modifier } from '../const';
import Token from './Token';

export default class Builder {
  private subject: Token = {
    property: null,
    section: null,
    inclusion: null,
    condition: null,
    comparison: null,
    modifier: null,
    criterion: null,
    satisfied: null,
    compiled: null
  };

  withProperty(property: string): Builder {
    this.subject.property = property;
    return this;
  }

  withSection(section: string): Builder {
    this.subject.section = section;
    return this;
  }

  withInclusion(inclusion: boolean): Builder {
    this.subject.inclusion = inclusion;
    return this;
  }

  withCondition(condition: Condition): Builder {
    this.subject.condition = condition;
    return this;
  }

  withComparison(comparison: Comparison): Builder {
    this.subject.comparison = comparison;
    return this;
  }

  withModifier(modifier: Modifier): Builder {
    this.subject.modifier = modifier;
    return this;
  }

  withCriterion(criterion: string[]): Builder {
    this.subject.criterion = criterion;
    return this;
  }

  get token(): Token {
    return this.subject;
  }
}
