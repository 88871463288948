import React from 'react';

import { ExternalLink } from 'snap-ui/Link';

import Path from 'constants/paths';

import Can from 'module/Can';

import { useAuth } from 'provider';

import { FunctionalPermission } from 'types/auth';
import { ArtifactType } from 'types/common';

import { formatQueryString } from 'utilities/SearchParam';

import ListItemButton from './ListItemButton';
import ListItemLink from './ListItemLink';

export default function ListItemsSandbox({ onClose }: { onClose(): void }) {
  const { isSubscriber } = useAuth();

  const viewPath =
    Path.FeedReset +
    formatQueryString({
      topic: ArtifactType.Session,
      page: 1
    });

  return (
    <>
      <ListItemLink to={viewPath} title='Threat Library' onClick={onClose} />
      <Can I={FunctionalPermission.CreateVMSession}>
        <Can.Grant>
          <ListItemLink
            aria-label='Go to start a new threat capture page'
            id='MenuItemSessionConfig'
            onClick={onClose}
            title='Capture a New Threat'
            to={Path.ThreatCreate}
          />
        </Can.Grant>
        <Can.Block>
          <ListItemButton
            disabled
            title='Capture a New Threat'
            tooltip={
              isSubscriber ? (
                'You do not have permission to capture a new threat.'
              ) : (
                <>
                  Adding new threats is currently restricted to vetted contributors.
                  <br />
                  <ExternalLink icon primary href='https://www.snapattack.com/community'>
                    Request contributor access.
                  </ExternalLink>
                </>
              )
            }
          />
        </Can.Block>
      </Can>
      <Can I={FunctionalPermission.CreateSession}>
        <Can.Grant>
          <ListItemLink
            aria-label='Go to upload a local threat capture page'
            id='MenuItemSessionUpload'
            onClick={onClose}
            title='Upload Existing Threat Capture'
            to={Path.ThreatUploadReset}
          />
        </Can.Grant>
        <Can.Block>
          <ListItemButton
            disabled
            title='Upload Existing Threat Capture'
            tooltip={
              isSubscriber ? (
                'You do not have permission to upload an existing threat capture.'
              ) : (
                <>
                  Adding new threats is currently restricted to vetted contributors.{' '}
                  <ExternalLink href='https://www.snapattack.com/community' target='_blank' rel='noopener noreferrer'>
                    Request contributor access.
                  </ExternalLink>
                </>
              )
            }
          />
        </Can.Block>
      </Can>
    </>
  );
}
