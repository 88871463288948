import React from 'react';

import { GridColDef } from 'snap-ui/DataGrid';

import { Artifact, ArtifactType } from 'types/common';

import { ConfidenceContainer, HitsContainer, NameContainer, SeverityContainer } from './DisplayGrid.helper';

export const GridColumnsThreat: (GridColDef<Artifact> & { hideToStart?: boolean })[] = [
  {
    field: 'name',
    headerName: 'Name',
    hideable: false,
    width: 500,
    renderCell: cell => <NameContainer artifact={cell.row} topic={ArtifactType.Session} value={cell.value} />
  },
  {
    field: 'severity',
    headerName: 'Severity',
    hideable: true,
    filterable: false,
    sortable: false,
    width: 125,
    renderCell: cell => <SeverityContainer guid={cell.row.guid} severity={cell.value} />
  },
  {
    field: 'rank',
    headerName: 'Confidence',
    hideable: true,
    sortable: false,
    filterable: false,
    width: 130,
    renderCell: cell => <ConfidenceContainer guid={cell.row.guid} rank={cell.value} itemIncludeRank />
  },
  {
    field: 'hits',
    headerName: 'Detection Hits',
    sortable: false,
    filterable: false,
    hideable: true,
    hideToStart: true,
    width: 350,
    renderCell: cell => <HitsContainer guid={cell.row.guid} topic={ArtifactType.Session} />
  }
];
