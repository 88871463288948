import { BackdropLoaderContainer } from 'snap-ui/BackdropLoader';
import { styled } from 'snap-ui/util';

export const Container = styled(BackdropLoaderContainer)`
  padding: ${p => p.theme.spacing(5)};

  .meta {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: ${p => p.theme.spacing(3)};
    margin-bottom: ${p => p.theme.spacing(7)};
  }

  .transfer-meta {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${p => p.theme.spacing(6)};
    margin-bottom: ${p => p.theme.spacing(6)};
  }

  .fetch-control {
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(6)};
    .MuiFormGroup-root {
      justify-content: center;
    }
  }

  .transfer-container {
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(5)};
    justify-content: space-between;
    align-items: flex-start;
  }

  .transfer-control {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: ${p => p.theme.spacing(3)};
  }

  .transfer-control .all {
    margin: ${p => p.theme.spacing(5, 0)};
  }

  .CurationGrid {
    min-width: 45%;
    height: 100%;
  }

  .MuiAccordion-root {
    padding: 10px;
  }

  .ApiError {
    margin: 48px auto;
    min-height: 2rem;
    width: 50%;
  }

  .PendingCount {
    display: flex;
    align-items: center;
    gap: ${p => p.theme.spacing(3)};
    color: ${p => p.theme.palette.common.white};
  }

  .MuiAccordionSummary-root.existing-summary {
    h3 {
      padding-right: ${p => p.theme.spacing(5)};
    }
  }

  .MuiAccordionSummary-root.existing-summary:hover {
    cursor: unset;
  }

  .existing-count {
    display: flex;
  }

  .Form-field {
    flex-grow: 1;
    max-width: 40%;
  }

  .pre-existing {
    width: fit-content;
  }

  .count {
    font-size: larger;
    color: ${p => p.theme.palette.common.white};
  }

  .attach-button {
    margin-left: -${p => p.theme.spacing(5)};
  }

  .artifact-select {
    margin-top: ${p => p.theme.spacing(2)};
    padding-right: ${p => p.theme.spacing(2)};
  }

  @media (min-width: 640px) {
    .meta {
      flex-direction: row;
      align-items: center;

      h1 {
        padding-right: ${p => p.theme.spacing(6)};
      }
    }

    .transfer-meta {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }

    .fetch-control {
      flex-direction: row;
      align-items: center;
    }

    .transfer-container {
      flex-direction: row;
    }

    .transfer-control {
      position: sticky;
      top: 35%;
      flex-direction: column;
      align-items: center;
    }
  }
`;
