import React from 'react';

import classnames from 'classnames';
import replace from 'lodash/replace';

import Placeholder from 'snap-ui/Placeholder';
import Typography, { TypographyProps } from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import CopyButton from 'module/Widgets/CopyButton';

import { ArtifactType } from 'types/common';

import { titleCase } from 'utilities/TextUtils/TextUtils';

const Container = styled(Typography)`
  &.MuiTypography-h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin: 0;
  }

  &.shadow {
    text-shadow: 3px 3px hsl(0, 0%, 9%);
  }

  overflow: hidden;
  text-overflow: ellipsis;
`;

export type ContentHeaderProps = TypographyProps & {
  headerText: string;
  contentType?: ArtifactType;
  shadow?: boolean;
  showPlaceholder?: boolean;
};
function ContentHeader({
  className,
  headerText,
  contentType,
  shadow = true,
  showPlaceholder,
  variant = 'h1',
  ...props
}: ContentHeaderProps) {
  const copyText =
    window.location.href.split(/[?#]/)[0] + ' - ' + (contentType ? titleCase(contentType) + ': ' : '') + headerText;

  return showPlaceholder ? (
    <Placeholder className='PlaceholderContentHeader' variant='text' height={66} width={250} />
  ) : (
    <Container className={classnames('ContentHeader', className, { shadow })} variant={variant} {...props}>
      <CopyButton value={copyText} placement='bottom' copyTooltip='Copied to clipboard!' messageTime={2000} arrow>
        <span>{replace(headerText || 'No Title', /[,_]/g, value => value + ' ')}</span>
      </CopyButton>
    </Container>
  );
}

export default ContentHeader;
