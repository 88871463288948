import React from 'react';

import { useLocation } from 'react-router-dom';

import { Path } from 'constants/paths';

import { getBrowser } from 'utilities/BrowserUtils';

import Banner from './Banner';

export default function SafariBrowserBanner() {
  const { pathname } = useLocation();

  const [showBanner, setShowBanner] = React.useState(false);

  React.useEffect(() => {
    const browser = getBrowser();
    const isSafari = browser.name === 'Safari';

    const pathsIncluded = [Path.ProcessGraph];

    if (isSafari) {
      setShowBanner(pathsIncluded.includes(pathname as Path));
    }
  }, [pathname]);

  if (!showBanner) return null;

  return <Banner>This page is best viewed with the latest Google Chrome or Mozilla Firefox.</Banner>;
}
