import { snapattack } from 'apis/snapattack';

import { Guid } from 'types/common';

import { ThreatProfileTag, ThreatProfileTagPayload } from './ThreatProfile.type';

const prefix = '/cyberai/profile';

export async function getProfileTags(orgGuid: Guid): Promise<ThreatProfileTag[]> {
  return snapattack.get(`${prefix}/${orgGuid}/tags/`).then(r => r.data);
}

export async function putProfileTags(orgGuid: Guid, payload: ThreatProfileTagPayload): Promise<void> {
  return snapattack.put(`${prefix}/${orgGuid}/tags/`, payload).then(r => r.data);
}
