import React from 'react';

import { faBars, faFileCsv } from '@fortawesome/pro-solid-svg-icons';

import { GridApi } from 'snap-ui/DataGrid';
import Menu from 'snap-ui/Menu';

import { BurgerClicker } from 'module/Layout/Artifact.widgets';
import { HamburgerFab } from 'module/Layout/Styled';

type TableMenuProps = {
  gridApiRef: GridApi;
  isPending: boolean;
};

export default function TableMenu({ isPending, gridApiRef }: TableMenuProps): JSX.Element {
  const burgerRef = React.useRef();
  const [showBurgerMenu, setShowBurgerMenu] = React.useState(false);

  return (
    <>
      <HamburgerFab
        disabled={isPending}
        aria-label='Actions'
        icon={faBars}
        onClick={() => setShowBurgerMenu(true)}
        ref={burgerRef}
      />
      <Menu
        anchorEl={burgerRef.current}
        onClose={() => setShowBurgerMenu(false)}
        onClick={() => setShowBurgerMenu(false)}
        open={showBurgerMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <BurgerClicker
          title='Export to CSV'
          aria-label='Export to CSV'
          icon={faFileCsv}
          onClick={gridApiRef?.exportDataAsCsv}
        />
      </Menu>
    </>
  );
}
