import React from 'react';

import { useLocation } from 'react-router-dom';

import { pingCaptureStatus } from 'apis/resources/harbor';

import Path from 'constants/paths';

import { getActiveSession } from 'module/Session/Session.api';
import { CommissionedHost } from 'module/Session/Session.type';

import { useMountedRef } from 'storage';

import { CeleryState, Url } from 'types/common';
import { CaptureStatus } from 'types/harbor';

import { useAuth } from './Account';

const PING_DELAY = 7500;

type CommissionedInterface = {
  activeSession: CommissionedHost[];
  setActiveSession(activeSession: CommissionedHost[]): void;

  // call to saveActiveSession should populate this information
  activeSave: Url[];
  setActiveSave(activeSave: Url[]): void;

  activeTask: Partial<CaptureStatus>;
  setActiveTask(activeTask: Partial<CaptureStatus>): void;
};

const CommissionedContext = React.createContext<CommissionedInterface>(null);
CommissionedContext.displayName = 'CommissionedContext';

function useCommissionedMachine(): CommissionedInterface {
  const context = React.useContext<CommissionedInterface>(CommissionedContext);

  if (!context) {
    throw new Error('useCommissionedMachine must be used within the CommissionedContext');
  }

  return context;
}

function CommissionedProvider({ children }: { children: React.ReactNode }): React.ReactElement {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const [activeSave, setActiveSave] = React.useState<Url[]>([]);
  const [activeSession, _setActiveSession] = React.useState<CommissionedHost[]>([]);
  const [pingSession, setPingSession] = React.useState(false);
  const [activeTask, _setActiveTask] = React.useState<Partial<CaptureStatus>>(null);
  const [pingTask, setPingTask] = React.useState(false);
  const mounted = useMountedRef();
  const timeoutRef = React.useRef<NodeJS.Timeout>();

  const setActiveSession = React.useCallback((value: CommissionedHost[]) => {
    if (mounted.current) {
      _setActiveSession(value);
      setPingSession(value?.length > 0);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setActiveTask = React.useCallback((value: Partial<CaptureStatus>) => {
    if (mounted.current) {
      _setActiveTask(value);
      setPingTask([CeleryState.pending, CeleryState.in_progress, CeleryState.retrying].includes(value?.status));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    clearTimeout(timeoutRef.current);

    async function fetchData() {
      const response = await getActiveSession();
      if (response && response.length === 0) setActiveSession([]);
    }

    if (pathname.includes(Path.ThreatExecute)) fetchData();
    else if (pingSession) timeoutRef.current = setTimeout(fetchData, PING_DELAY);

    return () => clearTimeout(timeoutRef.current);
  }, [pathname, pingSession, setActiveSession]);

  React.useEffect(() => {
    clearTimeout(timeoutRef.current);

    const task = () =>
      pingCaptureStatus(activeTask?.task_id).then(
        res => setActiveTask(res),
        err => setActiveTask(err)
      );

    if (pingTask) timeoutRef.current = setTimeout(task, PING_DELAY);
    return () => clearTimeout(timeoutRef.current);
  }, [activeTask, pingTask, setActiveTask]);

  /**
   * Someone logged in or out.
   * Lets keep our data fresh either way.
   */
  React.useEffect(() => {
    if (user.guid) {
      getActiveSession().then(setActiveSession);
      if (activeTask?.task_id) pingCaptureStatus(activeTask?.task_id).then(setActiveTask);
    } else {
      setActiveSession([]);
      setActiveTask(null);
    }
  }, [activeTask?.task_id, setActiveSession, setActiveTask, user.guid]);

  const value = React.useMemo<CommissionedInterface>(
    () => ({ activeSession, setActiveSession, activeSave, setActiveSave, activeTask, setActiveTask }),
    [activeSave, activeSession, activeTask, setActiveSave, setActiveSession, setActiveTask]
  );

  return <CommissionedContext.Provider value={value}>{children}</CommissionedContext.Provider>;
}

export { CommissionedProvider, useCommissionedMachine };
