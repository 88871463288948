import { z, ZodType } from 'zod';

import { ContentPermission } from './auth';

export const TrackedSchema = z.object({
  guid: z.string()
});

export type Tracked = z.infer<typeof TrackedSchema>;

export const IdentitySchema = TrackedSchema.extend({
  name: z.string(),
  id: z.number()
});

export type Identity = z.infer<typeof IdentitySchema>;

export const Permitted = z.union([
  z.array(z.literal(ContentPermission.All)),
  z.array(z.enum([ContentPermission.Delete, ContentPermission.Edit, ContentPermission.Read, ContentPermission.Share]))
]);

export type Permitted = z.infer<typeof Permitted>;

export const Version = TrackedSchema.extend({
  created_by: z.object({
    name: z.string()
  }),
  creation: z.string(),
  modified: z.string()
});

export type Version = z.infer<typeof Version>;

/**
 * TS strict mode makes this easier
 */
export function safelyParse<T>(data: unknown, type: ZodType): T {
  const result = type.safeParse(data);

  if (result.success === false) {
    if (process.env.NODE_ENV !== 'test') console.warn(result.error, result.error.issues);
    return data as T;
  }

  return result.data;
}
