import React from 'react';

import { faFilePdf } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import SimpleLineChart from 'snap-ui/Charts/SimpleLineChart';
import Grid from 'snap-ui/Grid';
import Typography from 'snap-ui/Typography';
import { styled, useTheme } from 'snap-ui/util';

import useDemoMode from 'hooks/useDemoMode';
import useTitle from 'hooks/useTitle';

import DashboardGrid from 'module/Dashboard/core/DashboardGrid';
import DateRangeSelector, { DateRange, getInitialDatesByPreset } from 'module/DateRangeSelector';
import { DetectionDeploymentStatus } from 'module/GlobalFilter/Filters/DeployedEnvironment';
import { getArtifactIcon } from 'module/Layout/Artifact.helper';
import { BurgerClicker, Menu } from 'module/Layout/Artifact.widgets';

import { useAuth, usePushSnack } from 'provider';

import { Status } from 'storage';

import { ArtifactType } from 'types/common';

import { formatQueryString, parseAnyQueryString } from 'utilities/SearchParam';
import { formatCustomNow } from 'utilities/TimeUtils';

import ChartPanel from '../core/ChartPanel';
import { exportToPDF } from '../core/Dashboard.helper';
import { Header, WithPrintStyle } from '../core/Dashboard.style';
import { EXPORT_FORMAT } from '../core/Dashboard.util';
import { DEMO_SNACK_MESSAGE } from '../core/Dashboard.widgets';
import { CoverPage } from '../core/DashboardCoverPage';
import { CREATED_DEMO_DATA, HUNTED_DEMO_DATA, VIEWED_DEMO_DATA } from './UserActivityDashboard.const';
import {
  FourColumnPlaceHolder,
  TOOLTIPS,
  ThreeColumnPlaceHolder,
  getCreateTotals,
  getHuntTotals,
  getViewedTotals
} from './UserActivityDashboard.widgets';
import {
  useUserActivityCreatedStats,
  useUserActivityHuntedStats,
  useUserActivityViewedStats
} from './useUserActivityStats';

const DASHBOARD_ID = 'UADashboard';

const Text = styled('div')`
  font-size: 5rem;
  margin-top: ${p => p.theme.spacing(4)};
`;

const ButtonContainer = styled('div')`
  display: flex;
  button {
    margin-left: ${p => p.theme.spacing(3)};
  }
`;

const INITIAL_PRESET = 30;
function UserActivityDashboard() {
  useTitle('User Activity Dashboard | SnapAttack');

  const [dates, setDates] = React.useState<DateRange>(() => getInitialDatesByPreset(INITIAL_PRESET));
  const { palette } = useTheme();
  const { search } = useLocation();
  const pushSnack = usePushSnack();
  const { isSubscriber, defaultOrgId } = useAuth();
  const { isDemoMode } = useDemoMode();
  const displayDemoData = isDemoMode || !isSubscriber;
  const { data: viewedStatsData, status: viewedStatus, getViewedStats } = useUserActivityViewedStats();
  const { data: huntedStatsData, status: huntedStatus, getHuntedStats } = useUserActivityHuntedStats();
  const { data: createdStatsData, status: getCreatedStatus, getCreatedStats } = useUserActivityCreatedStats();

  React.useEffect(() => {
    const { start, stop } = dates;
    const payload = {
      start: moment(start),
      stop: moment(stop),
      organization_id: defaultOrgId
    };
    getViewedStats(payload);
    getHuntedStats(payload);
    getCreatedStats(payload);
  }, [dates, defaultOrgId, getCreatedStats, getHuntedStats, getViewedStats]);

  const viewedData = displayDemoData ? VIEWED_DEMO_DATA : viewedStatsData;
  const huntedData = displayDemoData ? HUNTED_DEMO_DATA : huntedStatsData;
  const createdData = displayDemoData ? CREATED_DEMO_DATA : createdStatsData;

  const {
    createdIntelTotal,
    createdDeploymentTotal,
    createdDetectionTotal,
    createdSimulationsTotal,
    createdThreatsTotal
  } = getCreateTotals(createdData);

  const { viewedIntelTotal, viewedDetectionTotal, viewedThreatsTotal } = getViewedTotals(viewedData);
  const { huntsTotal, huntsIocTotal } = getHuntTotals(huntedData);

  const pdfExportRef = React.useRef<HTMLDivElement>(null);
  const todaysDate = formatCustomNow(EXPORT_FORMAT);
  const reportTitle = 'User Activity Manager Report';

  const isIdle = [getCreatedStatus, viewedStatus, huntedStatus].includes(Status.idle);
  const isPending = [getCreatedStatus, viewedStatus, huntedStatus].includes(Status.pending);

  React.useEffect(() => {
    if (!isSubscriber) {
      pushSnack(DEMO_SNACK_MESSAGE, 'info', 'center', 'bottom', 30000);
    }
  }, [isSubscriber, pushSnack]);

  return (
    <WithPrintStyle id={DASHBOARD_ID}>
      <div ref={pdfExportRef}>
        <CoverPage title={reportTitle} details={todaysDate} />
        <DashboardGrid>
          <Grid item xs={12} md={12} lg={12}>
            <Header>
              <Typography variant='h1' className='pdf_title'>
                User Activity
              </Typography>
              <ButtonContainer className='no-print'>
                <DateRangeSelector
                  dates={dates}
                  onDateRangeChange={setDates}
                  initialPreset={INITIAL_PRESET}
                  demoMode={displayDemoData}
                />
                <Menu className='no-print'>
                  <BurgerClicker
                    disabled={!isSubscriber}
                    icon={faFilePdf}
                    onClick={() =>
                      exportToPDF({
                        ref: pdfExportRef,
                        fileName: `UA_dashboard_${todaysDate}.pdf`
                      })
                    }
                    title='Export PDF'
                  />
                </Menu>
              </ButtonContainer>
            </Header>
          </Grid>
        </DashboardGrid>

        {!displayDemoData && (isIdle || isPending) ? (
          <>
            <ThreeColumnPlaceHolder />
            <ThreeColumnPlaceHolder />
            <FourColumnPlaceHolder />
          </>
        ) : (
          <>
            <DashboardGrid title={<>{getArtifactIcon(ArtifactType.Intel)} Intelligence</>}>
              <Grid item xs={6} md={4} lg={4}>
                <ChartPanel
                  title='Created'
                  panelTooltip={TOOLTIPS.createdIntel}
                  centerText={<Text>{createdIntelTotal}</Text>}
                  to={
                    displayDemoData
                      ? null
                      : {
                          pathname: '/feed',
                          search: formatQueryString({
                            ...parseAnyQueryString(search),
                            page: '1',
                            organizations: defaultOrgId,
                            topic: 'intelligence'
                          })
                        }
                  }
                >
                  <SimpleLineChart data={createdData} dataKey='intel' color={palette.yellow.main} />
                </ChartPanel>
              </Grid>
              <Grid item xs={6} md={4} lg={4}>
                <ChartPanel
                  panelTooltip={TOOLTIPS.viewedIntel}
                  title='Viewed'
                  centerText={<Text>{viewedIntelTotal}</Text>}
                >
                  <SimpleLineChart data={viewedData} dataKey='intel' color={palette.yellow.main} />
                </ChartPanel>
              </Grid>
              <Grid item xs={6} md={4} lg={4}>
                <ChartPanel
                  panelTooltip={TOOLTIPS.iocHunts}
                  title='IOC Hunts'
                  centerText={<Text>{huntsIocTotal}</Text>}
                >
                  <SimpleLineChart data={huntedData} dataKey='hunts_ioc' color={palette.yellow.main} />
                </ChartPanel>
              </Grid>
            </DashboardGrid>

            <DashboardGrid title={<>{getArtifactIcon(ArtifactType.Session)} Threats</>}>
              <Grid item xs={6} md={4} lg={4}>
                <ChartPanel
                  panelTooltip={TOOLTIPS.createdThreats}
                  title='Captured'
                  centerText={<Text>{createdThreatsTotal}</Text>}
                  to={
                    displayDemoData
                      ? null
                      : {
                          pathname: '/feed',
                          search: formatQueryString({
                            ...parseAnyQueryString(search),
                            page: '1',
                            organizations: defaultOrgId,
                            topic: 'threat'
                          })
                        }
                  }
                >
                  <SimpleLineChart data={createdData} dataKey='threats' color={palette.red.main} />
                </ChartPanel>
              </Grid>
              <Grid item xs={6} md={4} lg={4}>
                <ChartPanel
                  panelTooltip={TOOLTIPS.viewedThreats}
                  title='Viewed'
                  centerText={<Text>{viewedThreatsTotal}</Text>}
                >
                  <SimpleLineChart data={viewedData} dataKey='threats' color={palette.red.main} />
                </ChartPanel>
              </Grid>
              <Grid item xs={6} md={4} lg={4}>
                <ChartPanel
                  panelTooltip={TOOLTIPS.simulationsRun}
                  title='Simulations Launched'
                  centerText={<Text>{createdSimulationsTotal}</Text>}
                  to={{
                    pathname: '/attack_simulation'
                  }}
                >
                  <SimpleLineChart data={createdData} dataKey='simulations' color={palette.red.main} />
                </ChartPanel>
              </Grid>
            </DashboardGrid>

            <DashboardGrid title={<>{getArtifactIcon(ArtifactType.Analytic)} Detections</>}>
              <Grid item xs={6} md={3} lg={3}>
                <ChartPanel
                  panelTooltip={TOOLTIPS.createdDetections}
                  title='Created'
                  centerText={<Text>{createdDetectionTotal}</Text>}
                  to={
                    displayDemoData
                      ? null
                      : {
                          pathname: '/feed',
                          search: formatQueryString({
                            ...parseAnyQueryString(search),
                            page: '1',
                            organizations: defaultOrgId,
                            topic: ArtifactType.Analytic
                          })
                        }
                  }
                >
                  <SimpleLineChart data={createdData} dataKey='detections' color={palette.blue.main} />
                </ChartPanel>
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <ChartPanel
                  panelTooltip={TOOLTIPS.viewedDetections}
                  title='Viewed'
                  centerText={<Text>{viewedDetectionTotal}</Text>}
                >
                  <SimpleLineChart data={viewedData} dataKey='detections' color={palette.blue.main} />
                </ChartPanel>
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <ChartPanel
                  panelTooltip={TOOLTIPS.deployed}
                  title='Deployed'
                  centerText={<Text>{createdDeploymentTotal}</Text>}
                  to={
                    displayDemoData
                      ? null
                      : {
                          pathname: '/feed',
                          search: formatQueryString({
                            ...parseAnyQueryString(search),
                            page: '1',
                            organizations: defaultOrgId,
                            topic: ArtifactType.Analytic,
                            deployedStatus: DetectionDeploymentStatus.success
                          })
                        }
                  }
                >
                  <SimpleLineChart data={createdData} dataKey='deployments' color={palette.blue.main} />
                </ChartPanel>
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <ChartPanel panelTooltip={TOOLTIPS.threatHunts} title='Hunted' centerText={<Text>{huntsTotal}</Text>}>
                  <SimpleLineChart data={huntedData} dataKey='hunts' color={palette.blue.main} />
                </ChartPanel>
              </Grid>
            </DashboardGrid>
          </>
        )}
      </div>
    </WithPrintStyle>
  );
}

export default UserActivityDashboard;
