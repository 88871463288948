import yaml from 'js-yaml';

export function simpleParse<T>(yamlString: string): [T, yaml.YAMLException?] {
  let parsed;
  try {
    parsed = yaml.load(yamlString);
  } catch (yamlErr) {
    return [parsed, yamlErr as yaml.YAMLException];
  }
  if (typeof parsed === 'string') {
    return [{} as T, new yaml.YAMLException('YAML cannot be parsed')];
  }
  return [parsed, null];
}
