import React from 'react';

import ContentHeader from 'module/Widgets/ContentHeader';
import { HeroContainer } from 'module/Widgets/HeroImage';

import { Status } from 'storage';

import { ArtifactType } from 'types/common';

import { Head } from '../Analytic.style';
import { AnalyticVersion } from '../Analytic.type';
import AnalyticVersionMenu from './AnalyticVersionMenu';

type AnalyticHeroProps = {
  version: AnalyticVersion;
  versionStatus: Status;
};

export default function AnalyticVersionHero({ version, versionStatus }: AnalyticHeroProps): JSX.Element {
  const showPlaceholder = versionStatus === Status.pending;

  return (
    <HeroContainer type={ArtifactType.Analytic} chipLabel='DETECTION REVISION'>
      <Head>
        <ContentHeader headerText={version?.name} showPlaceholder={showPlaceholder} />
        <div className='actions'>
          <AnalyticVersionMenu version={version} />
        </div>
      </Head>
    </HeroContainer>
  );
}
