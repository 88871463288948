import React from 'react';

import { diffLines, diffWords } from 'diff';
import escapeRegExp from 'lodash/escapeRegExp';

import {
  getGrammar,
  getGrammarName,
  highlight,
  Highlighter,
  SyntaxEditor,
  SyntaxViewer
} from 'module/Widgets/SyntaxEditor';

import { NOOP } from 'utilities/FunctionUtils';

type DiffViewerProps = {
  grammar: string;
  original: string;
  value: string;
};

export type DiffEditorProps = DiffViewerProps & {
  onChange(value: string): void;
  disabled?: boolean;
};

enum DiffMethod {
  Lines,
  Words
}

const DIFFER = {
  [DiffMethod.Lines]: diffLines,
  [DiffMethod.Words]: diffWords
};

type Diff = {
  count: number;
  added?: boolean;
  removed?: boolean;
  value: string;
};

function getDiffMethod(grammar: string, original: string): DiffMethod {
  const prismGrammarName = getGrammarName(grammar);

  switch (prismGrammarName) {
    case 'yara-l':
    case 'yaml':
      return DiffMethod.Words;
    case 'splunk':
      return DiffMethod.Words;
  }

  const lineCount = original.split('\n').length;
  if (lineCount > 1) return DiffMethod.Words;
  return DiffMethod.Words;
}

const wordChars = `a-zA-Z0-9`;
const negativeLookBehind = `(?<![${wordChars}])`;
const negativeLookAhead = `(?!${wordChars})`;
function processPartForRegex(part: string, diffMethod: DiffMethod): string {
  let pattern = escapeRegExp(
    part.replace(/\n(\s+)?$/, '') // don't include trailing newline + whitespace
  );

  if (diffMethod === DiffMethod.Words) {
    // don't match if the part is contained inside another word (eg `or` should not match in `author`)
    pattern = `${negativeLookBehind}${pattern}${negativeLookAhead}`;
  }

  return pattern;
}

function useDiffGrammar(languageKey: string, original: string, value: string): Highlighter {
  original = original ?? '';
  value = value ?? '';

  return React.useMemo(() => {
    const grammarDef = getGrammar(languageKey);
    const diffMethod = getDiffMethod(languageKey, original);
    const diff: Diff[] = DIFFER[diffMethod](original, value);

    const additions = diff
      .filter(part => part.added)
      .filter(part => part.value?.trim())
      .map(part => processPartForRegex(part.value, diffMethod));

    const addedRegex = new RegExp(additions.join('|'));

    const diffGrammar = {
      inserted: {
        pattern: addedRegex,
        inside: grammarDef
      },
      ...grammarDef
    };

    return code => highlight(code, diffGrammar, `${languageKey}-diff`);
  }, [languageKey, original, value]);
}

export function DiffViewer({ grammar, original, value }: DiffViewerProps): JSX.Element {
  const highlight = useDiffGrammar(grammar, original, value);
  return <SyntaxViewer highlight={highlight} value={value} />;
}

export function DiffEditor({ grammar, original, value, onChange, disabled }: DiffEditorProps): JSX.Element {
  const highlight = useDiffGrammar(grammar, original, value);
  return <SyntaxEditor highlight={highlight} value={value} onChange={disabled ? NOOP : onChange} disabled={disabled} />;
}
