import React from 'react';

import { useFeed } from 'aso';

import Chip from 'snap-ui/Chip';
import Icon from 'snap-ui/Icon';
import Placeholder from 'snap-ui/Placeholder';
import { styled } from 'snap-ui/util';

import { useFeedCounts } from 'aso/useFeed';

import Path from 'constants/paths';

import { usePage } from 'hooks/useQueryString';
import useTitle from 'hooks/useTitle';

import { CommonEvent, Engage, Fingerprint, standardizeFilter } from 'lib/Engagement';

import Collection from 'module/Collection';
import useArtifactCollectionCounts from 'module/Curation/useCurationArtifactCounts';
import { useDetectionCount } from 'module/Detection';
import { useFilterRegistry } from 'module/GlobalFilter';
import { useMayI } from 'module/May';
import { useMetadataItems } from 'module/Metadata';
import { useFilterSidebar } from 'module/Scaffold/useStashSidebar';
import { useSupplementalItems } from 'module/Search';
import useSupplementalSessionDetectionItems from 'module/Search/useSessionSupplementalDetection';

import { FeedExtraProvider } from 'provider';
import { useUserConfig } from 'provider/UserConfig';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { Artifact, ArtifactScore, ArtifactType, Guid } from 'types/common';

import { DisplayKind } from './Feed.type';
import FeedItems from './FeedItems';
import FeedSubtitle from './FeedSubtitle';

const TabLabel = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(4)};
  align-items: center;
`;

const Container = styled(Collection, { name: 'Feed' })`
  margin: var(--Scaffold-spacing);

  &.DisplayKind-${DisplayKind.Card} {
    min-width: 900px;
    max-width: 1200px;
  }

  &.DisplayKind-${DisplayKind.Grid} {
    max-width: calc(100vw - var(--Scaffold-drawer) - (var(--Scaffold-spacing) * 2));

    .DisplayGrid {
      border: none;
    }
  }

  .Collection-content {
    border: 1px solid ${p => p.theme.palette.grey[700]};
    padding: ${p => p.theme.spacing(3)};
  }

  .CreateButton {
    white-space: nowrap;
  }

  /* The feed has a font color of #FFF that anchor tags inherit */
  .CreateButton:hover {
    color: ${p => p.theme.palette.background.default} !important;
  }

  .MuiTabs-flexContainer {
    gap: 10px;
  }

  .MuiTab-root {
    border-radius: 5px 5px 0 0;
    border: 1px solid ${p => p.theme.palette.grey[700]};
  }

  .MuiTab-root:not(.Mui-selected) {
    border-bottom-color: transparent;
  }

  .MuiTab-root:hover:not(.Mui-selected) {
    border-color: ${p => p.theme.palette.primary.main};
    background-color: ${p => p.theme.palette.background.paper};
    background-image: unset;
  }

  .MuiTab-root.Mui-selected:hover {
    border-color: ${p => p.theme.palette.primary.main};
    background-image: unset;
  }
`;

export default function Feed() {
  useTitle('Search Results | SnapAttack');
  useFilterSidebar(ArtifactType.Analytic, true);
  const { display, setDisplayKind } = useUserConfig();
  const { page, resetPage, setPage } = usePage(false);
  const isBasUser = useMayI(FunctionalPermission.BASStableFeatures);
  const { topic, toQuery, values: filter } = useFilterRegistry(ArtifactType.Session, resetPage, false);
  const size = display === DisplayKind.Card ? 20 : topic === ArtifactType.Collection ? 100 : 1000;
  const pageParams = React.useMemo(() => ({ page, size }), [page, size]);
  const [selectionModel, setSelectionModel] = React.useState<Guid[]>([]);
  const confidenceFilter = filter?.confidence;

  const query = React.useMemo(() => toQuery(), [toQuery]);
  const { reset, ...feedOrigin } = useFeed(topic, query, pageParams);
  const { data: sessionDetectionSupplemental } = useSupplementalSessionDetectionItems(topic, feedOrigin.items);
  const filterFeed = {
    ...feedOrigin,
    items: React.useMemo((): Artifact[] => {
      return feedOrigin.items.map(item => {
        const recommendedDetection = sessionDetectionSupplemental?.filter(detection => detection.guid === item.guid);
        return {
          ...item,
          rank:
            recommendedDetection?.length > 0 &&
            (recommendedDetection[0].recommended_detections.find(detection =>
              confidenceFilter.includes(detection.rank as ArtifactScore)
            )?.rank as ArtifactScore)
        };
      });
    }, [confidenceFilter, feedOrigin.items, sessionDetectionSupplemental])
  };
  const feed = topic !== ArtifactType.Session ? feedOrigin : filterFeed;
  const feedCounts = useFeedCounts();

  const { detection, status: detectStatus } = useDetectionCount(topic, feed.items);
  const { supplemental, status: supStatus } = useSupplementalItems(topic, feed.items);
  const { metadata, status: metadataStatus, refresh: refreshMetadata } = useMetadataItems(topic, feed.items);

  const { getCounts, getCountsStatus } = useArtifactCollectionCounts(feed.items);

  React.useEffect(() => {
    if (display !== DisplayKind.Grid) setSelectionModel([]);
  }, [display, topic]);

  React.useEffect(() => {
    Engage.trackPersonIncrement(`view ${Path.Feed} ${topic}`, 1);
  }, [topic]);

  React.useEffect(() => {
    if (filter) {
      Engage.track(Fingerprint.load(Path.Feed).withQualifier(topic).withData(standardizeFilter(filter)));
    }
  }, [filter, topic]);

  const getFeedCountChip = feedCount =>
    feedCount.status === Status.pending && !feedCounts.fetched ? (
      <Placeholder variant='rectangular' width={30} height={24} />
    ) : (
      <Chip size='small' label={feedCount?.total?.toLocaleString()} />
    );

  const onTabChange = tab => {
    Engage.track(
      Fingerprint.of(Path.Feed)
        .withCommon(CommonEvent.Click)
        .withQualifier(`tab ${tab}`)
        .withData(standardizeFilter(filter))
    );
    reset();
  };

  const handleDisplayKindChange = (kind: DisplayKind) => {
    reset();
    changePage(1);
    setDisplayKind(kind);
  };

  const tabs = [
    {
      value: ArtifactType.Intel,
      content: (
        <FeedItems
          items={feed.items}
          topic={ArtifactType.Intel}
          isPending={Status.pending === feed.status}
          hasActivity={Status.pending === detectStatus || Status.pending === supStatus}
          displayKind={display}
          selectionModel={selectionModel}
          onSelectionModelChange={setSelectionModel}
          page={pageParams.page}
          total={feed.pageTotal}
          onChangePage={changePage}
        />
      ),
      label: (
        <TabLabel>
          <Icon.Intel /> Intelligence {getFeedCountChip(feedCounts?.intelligence)}
        </TabLabel>
      )
    },
    {
      value: ArtifactType.Session,
      content: (
        <FeedItems
          items={feed.items}
          topic={ArtifactType.Session}
          isPending={Status.pending === feed.status}
          hasActivity={Status.pending === detectStatus || Status.pending === supStatus}
          displayKind={display}
          selectionModel={selectionModel}
          onSelectionModelChange={setSelectionModel}
          page={pageParams.page}
          total={feed.pageTotal}
          onChangePage={changePage}
        />
      ),
      label: (
        <TabLabel>
          <Icon.Session /> Threats {getFeedCountChip(feedCounts?.threat)}
        </TabLabel>
      )
    },
    {
      value: ArtifactType.Analytic,
      content: (
        <FeedItems
          items={feed.items}
          topic={ArtifactType.Analytic}
          isPending={Status.pending === feed.status}
          hasActivity={Status.pending === detectStatus || Status.pending === supStatus}
          displayKind={display}
          selectionModel={selectionModel}
          onSelectionModelChange={setSelectionModel}
          page={pageParams.page}
          total={feed.pageTotal}
          onChangePage={changePage}
        />
      ),
      label: (
        <TabLabel>
          <Icon.Analytic /> Detections {getFeedCountChip(feedCounts?.detection)}
        </TabLabel>
      )
    },
    {
      value: ArtifactType.AttackScript,
      content: (
        <FeedItems
          items={feed.items}
          topic={ArtifactType.AttackScript}
          isPending={Status.pending === feed.status}
          hasActivity={Status.pending === detectStatus || Status.pending === supStatus}
          displayKind={display}
          selectionModel={selectionModel}
          onSelectionModelChange={setSelectionModel}
          page={pageParams.page}
          total={feed.pageTotal}
          onChangePage={changePage}
        />
      ),
      label: (
        <TabLabel>
          <Icon.AttackScript /> Attack Scripts {getFeedCountChip(feedCounts?.attack_script)}
        </TabLabel>
      )
    },
    {
      value: ArtifactType.Collection,
      content: (
        <FeedItems
          items={feed.items}
          topic={ArtifactType.Collection}
          isPending={Status.pending === feed.status}
          hasActivity={Status.pending === detectStatus || Status.pending === supStatus}
          displayKind={display}
          hideCheckboxes
          page={pageParams.page}
          total={feed.pageTotal}
          onChangePage={changePage}
        />
      ),
      label: (
        <TabLabel>
          <Icon.Collection color='grey' /> Collections {getFeedCountChip(feedCounts?.collection)}
        </TabLabel>
      )
    }
  ].filter(tab => (tab.value === ArtifactType.AttackScript ? isBasUser : true));

  return (
    <FeedExtraProvider
      detection={detection}
      supplemental={supplemental}
      metadata={metadata}
      getCounts={getCounts}
      detectionPending={detectStatus === Status.pending}
      supplementalPending={supStatus === Status.pending}
      metadataPending={metadataStatus === Status.pending}
      getCountsStatus={getCountsStatus}
      refreshMetadata={refreshMetadata}
    >
      <Container
        className={`Feed DisplayKind-${display}`}
        onTabChange={onTabChange}
        tabs={tabs}
        tabsSubtitle={
          <FeedSubtitle
            display={display}
            feed={feed}
            pageParams={pageParams}
            topic={topic}
            handleDisplayKindChange={handleDisplayKindChange}
            selectionModel={selectionModel}
          />
        }
      />
    </FeedExtraProvider>
  );

  function changePage(newPage: number): void {
    if (newPage > feed.pageTotal || newPage < 1) {
      return;
    }
    setPage(newPage);
  }
}
