import React from 'react';

import { getProcessGraph } from 'apis/resources/cyberai';

import { useAsync } from 'storage';

import { Guid } from 'types/common';
import { NodeDataType } from 'types/progressGraph';

const graph: NodeDataType = {} as NodeDataType;

export default function useProcessGraph(hostGuid: Guid) {
  const { data, run, status } = useAsync<NodeDataType>(graph);

  React.useEffect(() => {
    if (hostGuid) run(getProcessGraph(hostGuid));
  }, [hostGuid, run]);

  return { data, status };
}
