import React from 'react';

import upperCase from 'lodash/upperCase';

import Chip, { ChipProps } from 'snap-ui/Chip';
import { styled } from 'snap-ui/util';

export type ValueMap<T extends string = string> = Record<T, ChipProps>;

export type StateLabelBaseProps<T extends string = string> = {
  className?: string;
  value: T;
  /* maps possible values to the configuration of the Chip */
  valueMap: ValueMap<T>;
};

const Label = styled(Chip)`
  height: 1.55rem;
  border-radius: 5px;
  align-items: center;
  text-transform: uppercase;

  .MuiChip-icon {
    margin-left: ${p => p.theme.spacing(3)};
  }
`;

/**
 * use this component as a base to create labels that change color (and other styles) based on the label's value
 *
 * color options are restricted to those that Chip is configured to use
 * https://mui.com/material-ui/customization/palette/#typescript
 */
export function StateLabelBase<T extends string = string>({
  className,
  value,
  valueMap
}: StateLabelBaseProps<T>): JSX.Element {
  return <Label className={className} label={upperCase(value)} {...valueMap[value]} />;
}
