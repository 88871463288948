import isEmpty from 'lodash/isEmpty';

import { DateRange } from 'module/DateRangeSelector';

import { isDefaultFPRDetection } from 'services/analyticService';

import { DetectionForm } from 'types/analytic';
import { Guid } from 'types/common';

import { FieldType, TuningFilter, TuningPayload } from './AnalyticTuning.types';

export function buildTuningPayload(
  dates: DateRange,
  integration: Guid,
  fields?: string[],
  fieldType?: FieldType,
  detectionForm?: DetectionForm
): TuningPayload {
  const { start: earliest, stop: latest } = dates;

  const filter: TuningFilter[][] =
    isEmpty(detectionForm?.sections) || isDefaultFPRDetection(detectionForm)
      ? []
      : detectionForm.sections.map(section =>
          section.rules.map(({ field, values }) => ({
            field: `${fieldType}.${field}`,
            values
          }))
        );

  return {
    earliest,
    latest,
    integration,
    filter,
    fields: isEmpty(fields) ? undefined : fields.map(field => `${fieldType}.${field}`)
  };
}
