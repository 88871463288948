import React from 'react';

import { faChevronCircleLeft } from '@fortawesome/pro-regular-svg-icons';
import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';
import { useLocation } from 'react-router-dom';

import { IconButton, RouterButton } from 'snap-ui/Button';
import IFrame from 'snap-ui/IFrame';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';

import { useCannotRedirect } from 'aso/useCannotRedirect';

import useQueryString from 'hooks/useQueryString';

import { IntegrationAutocomplete } from 'module/Integration';
import { IntegrationOption } from 'module/Integration/Integration.type';
import useJobGroupDetail, { JobGroupDetailInterface } from 'module/Job/useJobGroupDetail';
import { List } from 'module/Scaffold/Scaffold.style';
import ListItemLink from 'module/Scaffold/core/ListItemLink';
import ToolbarItemLogo from 'module/Scaffold/core/ToolbarItemLogo';

import { useIntegrationCatalog, useSidebar } from 'provider';
import { DrawerHeader } from 'provider/SidebarProvider/Sidebar.style';
import { SidebarState } from 'provider/SidebarProvider/Sidebar.type';

import { FunctionalPermission } from 'types/auth';

import { formatQueryString, getQueryParam } from 'utilities/SearchParam';

import {
  buildWorkbenchLink,
  getEndTime,
  getHitCount,
  getStartTime,
  isDetectionDisabled,
  removeDuplicateDetailItems
} from './';
import { WorkbenchSidebarContainer } from './Hunt.style';
import { DetectionHits, ExportButton } from './Hunt.widget';

export default function Workbench(): JSX.Element {
  useCannotRedirect(FunctionalPermission.OpenSearchUIAccess);
  const { search } = useLocation();
  const detectionGuid = getQueryParam(search, 'detection');
  const integrationGuid = getQueryParam(search, 'integrationGuid');
  const { jobGroup, jobs, details, exportDataAsCsv, isExportPending, hits, dispatchErrors } = useJobGroupDetail();

  const sidebarContent = React.useCallback(
    (state: SidebarState) => {
      return (
        <WorkbenchSidebar
          details={details}
          dispatchErrors={dispatchErrors}
          hits={hits}
          exportDataAsCsv={exportDataAsCsv}
          isExportPending={isExportPending}
          {...state}
        />
      );
    },
    [details, dispatchErrors, exportDataAsCsv, hits, isExportPending]
  );
  useSidebar(true, { content: sidebarContent });

  const startTime = getStartTime(jobGroup);
  const endTime = getEndTime(jobGroup);
  const huntGuids = jobs.map(h => h.analytic_job_guid);

  const url = buildWorkbenchLink(startTime, endTime, huntGuids, detectionGuid, integrationGuid, true);

  return <IFrame src={url} />;
}

const ALL_INTEGRATIONS = {
  label: 'All Integrations',
  value: '',
  type: 'all'
};

function WorkbenchSidebar({
  details,
  exportDataAsCsv,
  isExportPending,
  dispatchErrors,
  hits,
  open,
  setOpen
}: SidebarState &
  Pick<
    JobGroupDetailInterface,
    'details' | 'dispatchErrors' | 'hits' | 'exportDataAsCsv' | 'isExportPending'
  >): JSX.Element {
  const { pathname } = useLocation();
  const { search } = useLocation();
  const selectedDetectionGuid = getQueryParam(search, 'detection');
  const { getByKey, update: updateQueryString } = useQueryString(true);
  const { integrations: integrationCatalog } = useIntegrationCatalog();
  const uniqueDetection = removeDuplicateDetailItems(details);

  const isAllIntegrationsEnabled = getByKey('integrationGuid') === '';

  const integrationOptions: IntegrationOption[] = React.useMemo(() => {
    const options = integrationCatalog.all
      .filter(integration => details.some(jobDetail => jobDetail.integration_guid === integration.guid))
      .map<IntegrationOption>(integration => ({
        label: integration.name,
        value: integration.guid,
        type: integration.type
      }));
    return options.length > 1 ? [{ ...ALL_INTEGRATIONS }, ...options] : options;
  }, [details, integrationCatalog]);

  const selectedIntegrationOption = React.useMemo(() => {
    if (isAllIntegrationsEnabled) return ALL_INTEGRATIONS.value;
    return getByKey('integrationGuid');
  }, [getByKey, isAllIntegrationsEnabled]);

  function handleIntegrationChange(value: string) {
    updateQueryString({ integrationGuid: value });
  }

  return open ? (
    <>
      <DrawerHeader>
        <ToolbarItemLogo />
      </DrawerHeader>
      <WorkbenchSidebarContainer>
        <div className='header'>
          <RouterButton to={pathname.replace('/workbench', '')}>Back to Hunt Details</RouterButton>
          <IconButton onClick={() => setOpen(false)}>
            <Icon icon={faChevronCircleLeft} />
          </IconButton>
        </div>
        <ExportButton className='export' isExportPending={isExportPending} exportDataAsCsv={exportDataAsCsv}>
          Export Detection Hits
        </ExportButton>
        <div className='filters'>
          <div className='by-integration'>
            <Typography variant='h3'>Quick Filters</Typography>
            {integrationOptions.length !== 1 && (
              <>
                <div className='by-integration-header'>
                  <Typography variant='h4'>By Integration</Typography>
                  <RouterButton
                    to={{
                      pathname,
                      search: formatQueryString({ detection: selectedDetectionGuid, integrationGuid: '' })
                    }}
                    variant='outlined'
                    disabled={isAllIntegrationsEnabled}
                  >
                    Clear
                  </RouterButton>
                </div>
                <IntegrationAutocomplete
                  className='IntegrationSelection'
                  name='integration'
                  value={selectedIntegrationOption}
                  onChange={handleIntegrationChange}
                  options={integrationOptions}
                  disabled={isEmpty(integrationOptions)}
                  disableClearable
                />
              </>
            )}
          </div>
          <div className='by-detection'>
            <div className='by-detection-header'>
              <Typography variant='h4'>By Detection</Typography>
              <RouterButton
                to={{
                  pathname,
                  search: formatQueryString({ detection: '', integrationGuid: selectedIntegrationOption })
                }}
                variant='outlined'
                disabled={!selectedDetectionGuid}
              >
                Clear
              </RouterButton>
            </div>
            <div>
              <List>
                {uniqueDetection
                  .sort((a, b) => {
                    return (
                      getHitCount(b, isAllIntegrationsEnabled, hits, details, selectedIntegrationOption) -
                      getHitCount(a, isAllIntegrationsEnabled, hits, details, selectedIntegrationOption)
                    );
                  })
                  .map(detection => (
                    <ListItemLink
                      key={detection.guid}
                      selected={detection.guid === selectedDetectionGuid}
                      title={
                        <div className='detection'>
                          <Tooltip title={detection.name} wrap>
                            <span>{truncate(detection.name, { length: 30 })}</span>
                          </Tooltip>
                          <DetectionHits
                            detection={{
                              ...detection,
                              hit_count: isAllIntegrationsEnabled
                                ? hits[detection.guid]
                                : details.find(
                                    d => d.guid === detection.guid && d.integration_guid === selectedIntegrationOption
                                  )?.hit_count
                            }}
                            dispatchErrors={dispatchErrors}
                          />
                        </div>
                      }
                      to={formatQueryString({ detection: detection.guid, integrationGuid: selectedIntegrationOption })}
                      disabled={
                        isAllIntegrationsEnabled
                          ? hits[detection.guid] === 0
                          : isDetectionDisabled(details, detection.guid, selectedIntegrationOption)
                      }
                    />
                  ))}
              </List>
            </div>
          </div>
        </div>
      </WorkbenchSidebarContainer>
    </>
  ) : null;
}
