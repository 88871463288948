import React from 'react';

import { CancelToken } from 'apis';

import { Status } from 'storage';

import { buildForest } from './Matrix.service';
import { Matrix } from './Matrix.type';
import useForest from './useForest';
import useStatistic from './useStatistic';

export default function useMatrixFilter(): { matrix: Matrix; empty: boolean; loading: boolean } {
  const { forest, status: forestStatus } = useForest();
  const { summary, empty, fetch, statisticStatus } = useStatistic();

  React.useEffect(() => {
    const cancelSource = CancelToken.source();
    fetch(cancelSource);
    return () => cancelSource.cancel('matrix cleaning');
  }, [fetch]);

  const matrix = React.useMemo(() => {
    return buildForest(forest, summary);
  }, [forest, summary]);

  return {
    matrix,
    empty,
    loading: forestStatus === Status.pending || statisticStatus === Status.pending
  };
}
