import React from 'react';

import { Formik } from 'formik';

import Button from 'snap-ui/Button';

import { standardFormikBaseProps } from 'module/Form';
import TextFieldFormik from 'module/Form/TextFieldFormik';

import { changePwSchema, PasswordType, pwValidation } from 'services/pwValidationService';

import { Form } from '../Authentication.style';

type ChangePwFormData = {
  [PasswordType.PASSWORD]: string;
  [PasswordType.NEW_PASSWORD]: string;
  [PasswordType.CONFIRM_NEW_PASSWORD]: string;
};

type ChangePwFormProps = {
  submitForm: (formValue: ChangePwFormData) => void;
};

const ChangePwForm = ({ submitForm }: ChangePwFormProps): React.ReactElement => {
  const handleSubmit = (formValue): void => {
    submitForm(formValue);
  };

  return (
    <Formik
      {...standardFormikBaseProps}
      initialValues={{
        [PasswordType.PASSWORD]: '',
        [PasswordType.NEW_PASSWORD]: '',
        [PasswordType.CONFIRM_NEW_PASSWORD]: ''
      }}
      onSubmit={handleSubmit}
      validate={(values: { new_password: string; confirm_new_password: string }): object => {
        const errors = pwValidation(PasswordType.NEW_PASSWORD, values.new_password);
        if (values.confirm_new_password && values.confirm_new_password !== values.new_password) {
          errors.confirm_new_password = 'Passwords must match';
        }
        return errors;
      }}
      validationSchema={changePwSchema}
    >
      <Form className='ChangePasswordForm'>
        <TextFieldFormik label='Current Password' name={PasswordType.PASSWORD} type={PasswordType.PASSWORD} required />
        <TextFieldFormik label='New Password' name={PasswordType.NEW_PASSWORD} type={PasswordType.PASSWORD} required />
        <TextFieldFormik
          label='Confirm New Password'
          name={PasswordType.CONFIRM_NEW_PASSWORD}
          type={PasswordType.PASSWORD}
          required
        />
        <Button type='submit'>Change Password</Button>
      </Form>
    </Formik>
  );
};

export default ChangePwForm;
