import React from 'react';

import { Autocomplete } from 'snap-ui/Autocomplete';
import Typography from 'snap-ui/Typography';

import useQueryString from 'hooks/useQueryString';
import useTitle from 'hooks/useTitle';

import { Discriminator, isDiscriminator } from 'module/Tag';
import withFunctionalPermission from 'module/Util/withFunctionalPermission';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { Guid } from 'types/common';

import {
  CoverageTable,
  nameCol,
  mitreIdCol,
  mitreNameCol,
  priorityCol,
  deployedCol,
  recommendedCol,
  coverageCol,
  breadthCol,
  depthCol
} from '../Table';
import { ProfileDashboardPage } from '../style';
import Stats from './Stats';
import useProfileCoverage, { TagCoverageInterface } from './useProfileCoverage';

export const ProfileCoverageDashboard = withFunctionalPermission(function ProfileCoverageDashboard(): JSX.Element {
  useTitle('Threat Profile Coverage Report');
  const coverageInterface = useProfileCoverage();
  const { actors, attacks, software, vulnerabilities, getProfile, getStats, isProfilePending } = coverageInterface;

  const { getByKey, update: updateQueryString } = useQueryString(true);
  const tagTypeValue = getByKey('type');
  const tableTagType =
    isDiscriminator(tagTypeValue) &&
    (
      [
        Discriminator.Actor,
        Discriminator.Attack,
        Discriminator.Software,
        Discriminator.Vulnerability
      ] as Discriminator[]
    ).includes(tagTypeValue)
      ? tagTypeValue
      : Discriminator.Actor;

  function setTableTagType(value: string) {
    updateQueryString({ type: value });
  }

  const tci = getTci();

  return (
    <ProfileDashboardPage>
      <Typography variant='h1'>Threat Profile Coverage</Typography>
      <Stats {...coverageInterface} />
      <CoverageTable
        columns={[
          tableTagType === Discriminator.Attack ? mitreIdCol : undefined,
          tableTagType === Discriminator.Attack ? mitreNameCol : nameCol,
          priorityCol(false, (guid: Guid) => getProfile(tableTagType, guid)),
          deployedCol((guid: Guid) => getStats(tableTagType, guid)),
          recommendedCol((guid: Guid) => getStats(tableTagType, guid)),
          coverageCol((guid: Guid) => getStats(tableTagType, guid)),
          breadthCol((guid: Guid) => getStats(tableTagType, guid)),
          depthCol((guid: Guid) => getStats(tableTagType, guid))
        ].filter(Boolean)}
        isPending={tci?.status === Status.pending}
        isProfilePending={isProfilePending}
        rows={tci?.items}
        ToolbarContent={
          <Autocomplete
            className='TagTypeFilter'
            disableClearable
            label='Type'
            name='type_filter'
            options={[
              { value: Discriminator.Actor, content: 'Threat Actors', label: 'Threat Actors' },
              { value: Discriminator.Attack, content: 'MITRE ATT&CK', label: 'MITRE ATT&CK' },
              { value: Discriminator.Software, content: 'Software', label: 'Software' },
              { value: Discriminator.Vulnerability, content: 'Vulnerabilities', label: 'Vulnerabilities' }
            ]}
            onChange={v => setTableTagType(v as Discriminator)}
            value={tableTagType}
          />
        }
      />
    </ProfileDashboardPage>
  );

  function getTci(): TagCoverageInterface {
    switch (tableTagType) {
      case Discriminator.Actor:
        return actors;
      case Discriminator.Attack:
        return attacks;
      case Discriminator.Software:
        return software;
      case Discriminator.Vulnerability:
        return vulnerabilities;
    }
  }
}, FunctionalPermission.SecurityProfileDashboard);
