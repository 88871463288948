import React from 'react';

import useBulkAnalyticVersions, { BulkAnalyticVersionsInterface } from 'module/DeployedHealth/useBulkAnalyticVersions';

import { Guid } from 'types/common';

const BulkAnalyticVersionsContext = React.createContext<BulkAnalyticVersionsInterface>(null);
BulkAnalyticVersionsContext.displayName = 'BulkAnalyticVersionsContext';

function useBulkAnalyticVersionsContext(): BulkAnalyticVersionsInterface {
  const context = React.useContext<BulkAnalyticVersionsInterface>(BulkAnalyticVersionsContext);

  if (!context) {
    throw new Error('useBulkAnalyticVersionsContext must be used within the BulkAnalyticVersionsContext');
  }

  return context;
}

function BulkAnalyticVersionsProvider({ children, guids }: React.PropsWithChildren<{ guids: Guid[] }>): JSX.Element {
  const catalog: BulkAnalyticVersionsInterface = useBulkAnalyticVersions(guids);
  return <BulkAnalyticVersionsContext.Provider value={catalog}>{children}</BulkAnalyticVersionsContext.Provider>;
}

export { BulkAnalyticVersionsProvider, useBulkAnalyticVersionsContext };
