import React from 'react';

import MuiChip, { ChipProps } from '@mui/material/Chip';
import classNames from 'classnames';

import { styled } from 'snap-ui/util';

const Chip = styled(MuiChip)`
  height: 1.5rem;
  min-width: 115px;
  font-size: 0.75rem;
  font-weight: bold;
  border-radius: 4px;
`;

const StandardChip = React.forwardRef<HTMLDivElement, ChipProps & { className?: string }>(function StandardChip(
  { className, ...others },
  ref
) {
  return <Chip className={classNames('Chip', className)} {...others} ref={ref} />;
});

export default StandardChip;
