import { filterPublishedAnalyticCount, filterPublishedSessionCount, getFeaturedCount } from 'apis/resources/dashboard';

import { FeaturedQuery } from 'module/Dashboard/ContentDashboard/FeaturedPanel/Feature';
import { getStatSummary } from 'module/Detection';
import { AttackType, filterCoverage } from 'module/Tag';

import { ArtifactType } from 'types/common';
import { Coverage } from 'types/dashboard';
import { CompositeFilter, Ops } from 'types/filter';

type FeaturedSpec = {
  name: string;
  subscription: boolean;
  disabled?: boolean;
  query: CompositeFilter;
};

/*
 * NOTE: These will only work in app; we don't have custom tags and
 * Content Provider organization IDs might not get ported
 */
const SessionFeaturedSpec: FeaturedSpec[] = [
  {
    name: 'Atomic Red Team',
    subscription: false,
    query: {
      op: Ops.and,
      items: [
        {
          op: Ops.in,
          field: 'organization_id',
          value: ['189']
        },
        {
          op: Ops.in,
          field: 'visibility',
          value: ['Published']
        }
      ]
    }
  },
  {
    name: 'Metasploit',
    subscription: true,
    query: {
      op: Ops.and,
      items: [
        {
          op: Ops.contains,
          field: 'text',
          value: 'Metasploit'
        },
        {
          op: Ops.in,
          field: 'visibility',
          value: ['Published']
        }
      ]
    }
  },
  {
    name: 'PowerShell Empire',
    subscription: true,
    query: {
      op: Ops.and,
      items: [
        {
          op: Ops.contains,
          field: 'text',
          value: 'PowerShell Empire'
        },
        {
          op: Ops.in,
          field: 'visibility',
          value: ['Published']
        }
      ]
    }
  },
  {
    name: 'CVEs',
    subscription: true,
    query: {
      op: Ops.and,
      items: [
        {
          op: Ops.contains,
          field: 'text',
          value: 'CVE'
        },
        {
          op: Ops.in,
          field: 'visibility',
          value: ['Published']
        }
      ]
    }
  },
  {
    name: 'Mandiant Security Validation',
    subscription: true,
    disabled: true,
    query: {
      op: Ops.and,
      items: [
        {
          op: Ops.in,
          field: 'bas.product_name',
          value: ['MandiantSecurityValidation']
        },
        {
          op: Ops.in,
          field: 'visibility',
          value: ['Published']
        }
      ]
    }
  }
];

const AnalyticFeaturedSpec: FeaturedSpec[] = [
  {
    name: 'Sigma Community',
    subscription: false,
    query: {
      op: Ops.and,
      items: [
        {
          op: Ops.in,
          field: 'organization_id',
          value: ['188']
        },
        {
          op: Ops.in,
          field: 'visibility',
          value: ['Published']
        }
      ]
    }
  },
  {
    name: 'Chronicle Detection Rules',
    subscription: false,
    query: {
      op: Ops.and,
      items: [
        {
          op: Ops.in,
          field: 'organization_id',
          value: ['246']
        },
        {
          op: Ops.in,
          field: 'visibility',
          value: ['Published']
        }
      ]
    }
  },
  {
    name: 'Splunk ESCU Detections',
    subscription: false,
    query: {
      op: Ops.and,
      items: [
        {
          op: Ops.in,
          field: 'organization_id',
          value: ['129']
        },
        {
          op: Ops.in,
          field: 'visibility',
          value: ['Published']
        }
      ]
    }
  },
  {
    name: 'SnapAttack Premium Detections',
    subscription: true,
    disabled: true,
    query: {
      op: Ops.and,
      items: [
        {
          op: Ops.in,
          field: 'organization_id',
          value: ['1']
        },
        {
          op: Ops.in,
          field: 'visibility',
          value: ['Published']
        }
      ]
    }
  },
  {
    name: 'Testable via Attack Scripts',
    subscription: true,
    disabled: true,
    query: {
      op: Ops.and,
      items: [
        {
          op: Ops.in,
          field: 'bas.product_name',
          value: ['SnapAttack', 'AtomicRedTeam']
        },
        {
          op: Ops.in,
          field: 'visibility',
          value: ['Published']
        }
      ]
    }
  }
];

export function getDetectionSummary() {
  return getStatSummary().then(detectionSummary => ({
    detectionSummary
  }));
}

export function getSessionCount() {
  return filterPublishedSessionCount().then(sessionCount => ({
    sessionCount
  }));
}

export function getAnalyticCount() {
  return filterPublishedAnalyticCount().then(analyticCount => ({
    analyticCount
  }));
}

export function getMitreAttackCoverage() {
  const promise: [Promise<Coverage>, Promise<Coverage>] = [
    filterCoverage(AttackType.Technique),
    filterCoverage(AttackType.Subtechnique)
  ];

  return Promise.all(promise).then(([technique, subtechnique]) => ({
    technique,
    subtechnique
  }));
}

export function getFeaturedContent(
  contentType: ArtifactType.Analytic | ArtifactType.Session
): Promise<FeaturedQuery[]> {
  const spec = contentType === ArtifactType.Analytic ? AnalyticFeaturedSpec : SessionFeaturedSpec;
  const promise: Promise<FeaturedQuery>[] = Object.entries(spec).map(([, value]) => {
    return getFeaturedCount(contentType, value.query)
      .then(v => ({
        title: value.name,
        subscription: value.subscription,
        disabled: value?.disabled,
        count: v,
        payload: value.query
      }))
      .catch(() => ({
        title: value.name,
        subscription: value.subscription,
        disabled: value?.disabled,
        count: 0,
        payload: value.query
      }));
  });

  return Promise.all(promise);
}
