import * as React from 'react';

import { faGear, faSearch } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import escapeRegExp from 'lodash/escapeRegExp';
import startCase from 'lodash/startCase';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';

import { Autocomplete, getDisplayValue } from 'snap-ui/Autocomplete';
import Button, { ActionIconButton } from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import Paper from 'snap-ui/Paper';
import Table, { TablePlaceholder } from 'snap-ui/Table';
import TableSortLabel from 'snap-ui/TableSortLabel';
import TextField from 'snap-ui/TextField';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import useUserManagement from 'aso/useUserManagement';

import { DEFAULT_ROLES, GROUP_TYPE } from 'constants/organization';
import Path from 'constants/paths';

import usePaginate from 'hooks/usePaginate';
import useSort from 'hooks/useSort';
import useTitle from 'hooks/useTitle';

import TablePagination from 'module/Widgets/TablePagination';

import { useAuth, useManagedOrganizations, usePushSnack } from 'provider';

import { Status } from 'storage';

import { Organization, RegisterStatus, User } from 'types/auth';
import { Guid, Ident } from 'types/common';
import { SortDirection } from 'types/filter';

import { formatShortTimestamp, getDiff } from 'utilities/TimeUtils';

import { TableSearchInformationalMessage } from '../Core.style';
import UserFormDialog, { UserFormDialogPayload } from './UserFormDialog';
import {
  formatInvitedMessage,
  getAllUserGroupsDisplay,
  getUserGroupDisplayForOrg,
  reduceGroupPermissions,
  sortLastLoginColumn,
  sortUserByRoles
} from './UserManagement.util';

interface UserManagementProps extends RouteComponentProps<{ id: Guid }> {
  className?: string;
}

export type InviteUserPayload = {
  organization_id: Ident;
  email: string;
};

const Toolbar = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: ${p => p.theme.spacing(3)};
  padding: ${p => p.theme.spacing(2)};

  .organization-select {
    width: 300px;
  }

  .search-field {
    width: 200px;
  }

  .add-user-button {
    margin-left: auto;
  }
`;

const ALL = 'ALL';

function UserManagementComponent({ className, ...props }: UserManagementProps) {
  useTitle('User Management | SnapAttack');

  const PAGE_SIZE = 15;
  const orgGuidParam = props.match.params.id;

  const pushSnack = usePushSnack();
  const { replace } = useHistory();

  const { user } = useAuth();
  const { organizations } = useManagedOrganizations();
  const preferred = user.superuser ? ALL : user.preferred_organization?.guid || '';

  const [orgGuid, setOrgGuid] = React.useState<Guid>(orgGuidParam || preferred);
  const {
    users,
    status: usersStatus,
    inviteUser,
    resetError: resetUserError,
    update: updateUser,
    updateGroups: updateGroupMembership,
    taskError: userError,
    taskStatus,
    refresh
  } = useUserManagement(orgGuid);

  const selectedOrg = organizations.find(org => org.guid == orgGuid);

  const [filteredData, setFilteredData] = React.useState(users);

  const { createSortHandler, order, sortedData } = useSort<User>(filteredData, {
    direction: SortDirection.asc,
    field: 'last_name'
  });

  const { page, pageData, pageTotal, handleChangePage } = usePaginate<User>(sortedData, PAGE_SIZE);
  const [editorUser, setEditorUser] = React.useState<User>(null);
  const [showEditDialog, setShowEditDialog] = React.useState(false);

  const showAllOrgs = user.superuser && orgGuid == ALL;

  const orgOptions = React.useMemo(() => {
    if (organizations.length === 0) return [];
    const options = organizations.map(org => ({
      value: org.guid,
      content: org.name
    }));
    if (user.superuser) options.unshift({ value: ALL, content: 'All Organizations' });

    return options;
  }, [organizations, user.superuser]);

  React.useEffect(() => {
    // make sure API errors are reset when opening the editor
    if (showEditDialog) resetUserError();
  }, [resetUserError, showEditDialog]);

  // BEGIN filter table methods
  const [searchUser, setSearchUser] = React.useState('');
  function handleSearchUserChange(event) {
    setSearchUser(event.target.value);
  }

  React.useEffect(() => {
    const searchUserRE = new RegExp(escapeRegExp(searchUser), 'i');
    setFilteredData(
      users?.filter(
        user =>
          user?.email.match(searchUserRE) ||
          `${user?.first_name.toUpperCase()} ${user?.last_name.toUpperCase()}`.match(searchUserRE)
      )
    );
  }, [searchUser, users]);
  // END filter table methods

  // BEGIN add/edit modal methods
  function handleOpenNewUserForm() {
    setEditorUser(null);
    setShowEditDialog(true);
  }

  function handleEditUser(user: User) {
    return function () {
      setEditorUser(user);
      setShowEditDialog(true);
    };
  }

  function handleCloseEditor() {
    setEditorUser(null);
    setShowEditDialog(false);
    resetUserError();
  }

  function noGroupMembership(organization: Organization): { [key: string]: boolean } {
    if (!organization) return {};
    return reduceGroupPermissions(organization?.groups, null);
  }

  async function handleRemoveFromOrg(organization: Organization) {
    await updateGroupMembership(editorUser.guid, noGroupMembership(organization));
    refresh();
    handleCloseEditor();
  }

  async function handleSubmitEditor(values: UserFormDialogPayload) {
    const organization = organizations.find(org => org.guid == values.organizationGuid);

    if (editorUser) {
      let groupMembership = noGroupMembership(organization);

      if (values[DEFAULT_ROLES.Admin]) {
        const adminGroup = organization?.groups.find(group => group.role == DEFAULT_ROLES.Admin);
        groupMembership[adminGroup.guid] = true;
      } else if (values[DEFAULT_ROLES.ContentAdmin]) {
        const contentAdminGroup = organization?.groups.find(group => group.role == DEFAULT_ROLES.ContentAdmin);
        groupMembership[contentAdminGroup.guid] = true;
      } else {
        groupMembership = reduceGroupPermissions(organization?.groups, values);
      }

      groupMembership = {
        ...groupMembership,
        ...reduceGroupPermissions(
          organization?.groups.filter(group => group.type === GROUP_TYPE.Special),
          values
        )
      };

      await updateGroupMembership(editorUser.guid, groupMembership);
      if (user.superuser) {
        // update user will refresh, no need to call it here.
        await updateUser(editorUser.guid, { email: values.email[0] });
      } else {
        refresh();
      }

      handleCloseEditor();
      pushSnack('Permissions updated.', 'info', 'center', 'bottom', 5000);
    } else {
      await inviteUser(
        values.email.map(emailAddress => ({
          organization_id: organization.id,
          email: emailAddress
        }))
      );
      handleCloseEditor();
      pushSnack(formatInvitedMessage(values.email, organization.name), 'info', 'center', 'bottom', 5000);
    }
  }

  // END add/edit modal methods

  function handleOrgChange(org) {
    replace(`${Path.UserManagement}/${org}`);
    setOrgGuid(org);
  }

  function renderEmail(user: User): React.ReactNode {
    return <div className='email'>{user.email}</div>;
  }

  function renderGroups(user: User): React.ReactNode {
    return !showAllOrgs && user?.memberships?.[orgGuid]
      ? getUserGroupDisplayForOrg(user?.memberships[orgGuid], selectedOrg?.groups)
      : null;
  }

  function renderOrganizations(user: User, all = false): React.ReactNode {
    return Object.entries(user.memberships ?? [])
      .filter(membership => membership[0] !== orgGuid || all) // filter out selected organization unless all flag
      .map(([guid, groups]) => (
        <React.Fragment key={guid}>
          {/* display organization name with groups listed in tooltip */}
          <Tooltip arrow placement='left' title={getAllUserGroupsDisplay(groups)} wrap>
            <Link
              onClick={() => {
                setOrgGuid(guid);
              }}
              to={`${Path.UserManagement}/${guid}`}
            >
              {groups[0]?.organization_name}
            </Link>
          </Tooltip>
          <br />
        </React.Fragment>
      ));
  }

  function renderUserStatus(user: User): React.ReactNode {
    const isUserInvited = user.status === RegisterStatus.Invite;
    const isUserInvitationExpired = isUserInvited && getDiff(user.invites[0]?.expire_time || '0', Date()) < 0;
    return isUserInvitationExpired ? `Invite Expired ${user.invites[0]?.expire_time || ''} ` : startCase(user.status);
  }

  function renderLastActivity(user: User): React.ReactNode {
    return user.status === RegisterStatus.Active ? formatShortTimestamp(user.last_active) : '';
  }

  function renderSettings(user: User) {
    return (
      <div className='align-center'>
        <Tooltip arrow placement='right' title={`Settings for ${user.email}`}>
          <ActionIconButton aria-label={`Settings for ${user.email}`} icon={faGear} onClick={handleEditUser(user)} />
        </Tooltip>
      </div>
    );
  }

  return (
    <div className={classnames('UserManagement', className)}>
      <Typography variant='h1'>User Management</Typography>
      <Paper>
        <Toolbar>
          <Autocomplete
            className='organization-select'
            options={orgOptions}
            onChange={handleOrgChange}
            name='organization select'
            value={organizations.length === 0 ? 'Organizations' : getDisplayValue(orgOptions, orgGuid)}
            disabled={organizations.length <= 1}
            disableClearable
            disableUserAdditions
          />
          <TextField
            className='search-field'
            onChange={handleSearchUserChange}
            placeholder='Search Users'
            startAdornment={<Icon icon={faSearch} />}
          />
          <Button className='add-user-button' variant='outlined' onClick={handleOpenNewUserForm}>
            Add User
          </Button>
        </Toolbar>
        <Table
          aria-label='UserManagement'
          component='div'
          columns={[
            <TableSortLabel
              key='last_name'
              field='last_name'
              label='Last Name'
              sortHandler={createSortHandler('last_name')}
              order={order}
            />,
            <TableSortLabel
              key='first_name'
              field='first_name'
              label='First Name'
              sortHandler={createSortHandler('first_name')}
              order={order}
            />,
            <TableSortLabel
              key='email'
              field='email'
              label='Email'
              sortHandler={createSortHandler('email')}
              order={order}
            />,
            showAllOrgs ? 'Organizations' : 'Other Organizations',
            showAllOrgs ? null : (
              <TableSortLabel
                key='groups'
                field='memberships'
                label='Groups'
                sortHandler={createSortHandler('memberships', (a: User, b: User) => {
                  return sortUserByRoles(a, b, orgGuid, selectedOrg?.groups);
                })}
                order={order}
              />
            ),
            <TableSortLabel
              key='status'
              field='status'
              label='Status'
              sortHandler={createSortHandler('status')}
              order={order}
            />,
            <TableSortLabel
              key='last_active'
              field='last_active'
              label='Last Login'
              sortHandler={createSortHandler('last_active', sortLastLoginColumn)}
              order={order}
            />,
            <div key='settings' className='align-center'>
              Settings
            </div>
          ]}
          rows={pageData.map(user => [
            user.last_name,
            user.first_name,
            renderEmail(user),
            renderOrganizations(user, showAllOrgs),
            renderGroups(user),
            renderUserStatus(user),
            renderLastActivity(user),
            renderSettings(user)
          ])}
        />
        {usersStatus === Status.pending && pageData?.length < 1 ? (
          <TablePlaceholder count={10} height={40} />
        ) : (
          <>
            {pageData?.length < 1 && selectedOrg && (
              <TableSearchInformationalMessage>
                <>{searchUser ? `No user found matching ${searchUser}` : `No users in ${selectedOrg?.name}`}</>
              </TableSearchInformationalMessage>
            )}
          </>
        )}
        <TablePagination changePage={handleChangePage} numPages={pageTotal} page={page} zeroIndex />
      </Paper>
      <UserFormDialog
        inviteUser={inviteUser}
        isLoading={taskStatus === Status.pending}
        apiError={userError}
        editorUser={editorUser}
        onClose={handleCloseEditor}
        onSubmit={handleSubmitEditor}
        onRemoveFromOrg={handleRemoveFromOrg}
        open={showEditDialog}
        organizationGuid={orgGuid}
      />
    </div>
  );
}

const UserManagement = styled(UserManagementComponent)<UserManagementProps>`
  margin-bottom: ${p => p.theme.spacing(9)};
  min-width: fit-content;

  & table {
    white-space: nowrap;

    // special handling for long emails
    tr td div.email {
      min-width: 190px;
      overflow-wrap: anywhere;
      white-space: break-spaces;
    }
  }

  & .MuiTypography-h1 {
    margin-bottom: ${p => p.theme.spacing(4)};
  }

  & .align-center {
    text-align: center;
  }
`;

export default UserManagement;
