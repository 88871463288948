import React from 'react';

import ActorTags from './ActorTags';
import Aliases from './Aliases';
import AttackTags from './AttackTags';
import SoftwareTags from './SoftwareTags';
import VulnerabilityTags from './VulnerabilityTags';

export default function CommonTags() {
  return (
    <>
      <Aliases />
      <ActorTags />
      <SoftwareTags />
      <VulnerabilityTags />
      <AttackTags />
    </>
  );
}
