import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { AlertTitle, BuffAlert } from 'snap-ui/Alert';
import Button from 'snap-ui/Button';
import CircularProgress from 'snap-ui/CircularProgress';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import Can from 'module/Can';
import { useImpersonate } from 'module/Impersonate';
import May from 'module/May';

import { resendVerificationRequest, useAuth, useManagedOrganizations, usePushSnack } from 'provider';

import { FunctionalPermission, Organization, RegisterStatus, User } from 'types/auth';
import { Guid } from 'types/common';
import { StrictReactNode } from 'types/core';

import { InviteUserPayload } from './UserManagement';
import { formatInvitedMessage } from './UserManagement.util';

type UserFormActionsProps = {
  editorUser: User;
  selectedOrg?: Organization;
  isLoading: boolean;
  inviteUser(target: InviteUserPayload[]): Promise<void>;
  onSubmit: () => void;
};

const ActionsRow = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(3)};
`;

function UserFormActions({ editorUser, selectedOrg, isLoading, inviteUser, onSubmit }: UserFormActionsProps) {
  const { user } = useAuth();

  const pushSnack = usePushSnack();
  const { availableUsers, impersonate, isPending } = useImpersonate();
  const { organizations } = useManagedOrganizations();
  const isUserAwaitingInvite = editorUser?.status === RegisterStatus.Invite;
  const isUserAwaitingVerification = editorUser?.status === RegisterStatus.Verification;

  const [error, setError] = React.useState<string>();

  const impersonatableUserGuids = React.useMemo(() => availableUsers.map(u => u.guid), [availableUsers]);
  const isEditorImpersonatable = impersonatableUserGuids.includes(editorUser.guid);

  function handleResendUserInvite() {
    const organization = isEmpty(selectedOrg)
      ? organizations.find(organization => organization.guid === Object.keys(editorUser?.memberships)[0])
      : selectedOrg;

    inviteUser([
      {
        organization_id: organization.id,
        email: editorUser.email
      }
    ])
      .then(() => {
        pushSnack(formatInvitedMessage([editorUser.email], organization.name), 'info', 'center', 'bottom', 5000);
      })
      .catch(() => {
        pushSnack(
          `An error has occurred re-sending invitation email to ${editorUser.email}`,
          'error',
          'center',
          'bottom',
          5000
        );
      })
      .finally(onSubmit);
  }

  function handleResendUserVerification() {
    resendVerificationRequest(editorUser.email)
      .then(() => {
        pushSnack(`Verification email re-sent to ${editorUser.email}`, 'info', 'center', 'bottom', 5000);
      })
      .catch(() => {
        pushSnack(
          `An error has occurred re-sending verification email to ${editorUser.email}`,
          'error',
          'center',
          'bottom',
          5000
        );
      })
      .finally(onSubmit);
  }

  function handleImpersonate(guid: Guid) {
    return impersonate(guid, {
      catchCb: e => {
        setError(e?.response?.data?.detail || 'Oops! That was unexpected');
      },
      redirectPath: Path.Home
    });
  }

  if (!(isUserAwaitingInvite || isUserAwaitingVerification || isEditorImpersonatable)) return null;

  return (
    <>
      {error && (
        <BuffAlert severity='error'>
          <AlertTitle>Error</AlertTitle>
          {error}
        </BuffAlert>
      )}

      <Typography variant='h3'>Actions</Typography>

      <ActionsRow>
        <Can I={FunctionalPermission.ImpersonateUser}>
          <UserFormAction
            title={isEditorImpersonatable ? `Impersonate ${editorUser?.name}` : 'This user may not be impersonated'}
            buttonChildren={isPending ? <CircularProgress size={25} /> : 'Impersonate User'}
            onClickCB={() => handleImpersonate(editorUser?.guid)}
            disabled={isPending || !isEditorImpersonatable}
          />
        </Can>

        {isUserAwaitingInvite && user.superuser && (
          <May I={FunctionalPermission.InviteUser}>
            <UserFormAction
              title='Resend Invitation'
              buttonChildren={isLoading ? <CircularProgress size={25} /> : 'Resend Invitation'}
              onClickCB={handleResendUserInvite}
              disabled={isLoading}
            />
          </May>
        )}

        {isUserAwaitingVerification && user.superuser && (
          <May I={FunctionalPermission.InviteUser}>
            <UserFormAction
              title='Resend Verification'
              buttonChildren={isLoading ? <CircularProgress size={25} /> : 'Resend Verification'}
              onClickCB={handleResendUserVerification}
              disabled={isLoading}
            />
          </May>
        )}
      </ActionsRow>
    </>
  );
}

export default UserFormActions;

type UserFormActionProps = {
  title: string;
  buttonChildren: StrictReactNode;
  onClickCB: () => void;
  disabled: boolean;
};

function UserFormAction({ title, buttonChildren, onClickCB, disabled }: UserFormActionProps) {
  return (
    <Tooltip arrow placement='top' wrap title={title}>
      <Button variant='outlined' onClick={onClickCB} disabled={disabled}>
        {buttonChildren}
      </Button>
    </Tooltip>
  );
}
