import React from 'react';

type Props = React.ImgHTMLAttributes<HTMLImageElement> & {
  src: string;
  altSrc: string;
  component?: React.FunctionComponent<{ src: string; handleError(): void; component: 'img' }>;
};

export default function ExternalImage({ component, src, altSrc, ...others }: Props) {
  const [path, setPath] = React.useState(src);

  React.useEffect(() => {
    setPath(src);
  }, [src]);

  const handleError = () => {
    setPath(altSrc);
  };

  if (component) React.createElement(component, { component: 'img', src, handleError });
  return <img src={path} onError={handleError} {...others} />;
}
