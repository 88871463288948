import React from 'react';

import Placeholder from 'snap-ui/Placeholder';
import { styled } from 'snap-ui/util';

import AnalyticGapIcon from 'module/Icons/AnalyticGapIcon';
import UntestedAnalyticIcon from 'module/Icons/UntestedAnalyticIcon';
import UnvalidatedAnalyticIcon from 'module/Icons/UnvalidatedAnalyticIcon';
import ValidatedAnalyticIcon from 'module/Icons/ValidatedAnalyticIcon';

import { AnalyticLogState } from 'types/analytic';

import { Section, SectionTitle } from './util';

const Badge = styled('div')`
  font-size: 1.75rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: ${p => p.theme.spacing(3)};
  margin-top: ${p => p.theme.spacing(3)};
`;

type ValidationProps = {
  validated: number;
  unvalidated: number;
  validated_gaps: number;
};

export default function Validation(props: ValidationProps): JSX.Element {
  function getBadgeState() {
    if (props.validated > 0) return AnalyticLogState.VALIDATED;
    else if (props.validated_gaps > 0) return AnalyticLogState.VALIDATED_GAPS;
    else if (props.unvalidated > 0) return AnalyticLogState.UNVALIDATED;
    else if (props.validated === 0 && props.validated_gaps === 0 && props.unvalidated === 0)
      return AnalyticLogState.UNTESTED;
  }

  const makeBadge = (): React.ReactNode => {
    const state = getBadgeState();

    switch (state) {
      case AnalyticLogState.VALIDATED:
        return (
          <>
            <ValidatedAnalyticIcon />
            <span>Validated</span>
          </>
        );
      case AnalyticLogState.VALIDATED_GAPS:
        return (
          <>
            <AnalyticGapIcon />
            <span>Detection Gap</span>
          </>
        );
      case AnalyticLogState.UNVALIDATED:
        return (
          <>
            <UnvalidatedAnalyticIcon />
            <span>Unvalidated</span>
          </>
        );
      case AnalyticLogState.UNTESTED:
        return (
          <>
            <UntestedAnalyticIcon />
            <span>Untested</span>
          </>
        );
      default:
        return <Placeholder height={32} width={160} variant='rectangular' />;
    }
  };

  return (
    <Section>
      <SectionTitle>Validation</SectionTitle>
      <Badge>{makeBadge()}</Badge>
    </Section>
  );
}
