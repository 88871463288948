import React from 'react';

import classnames from 'classnames';
import _Editor from 'react-simple-code-editor';

import { styled } from 'snap-ui/util';

import { Highlight } from './';

const Editor = styled(_Editor)`
  font-family: monospace;
  border-radius: inherit;
  min-height: 100%;

  textarea:focus-visible {
    outline: unset;
  }

  pre,
  textarea {
    word-break: break-word !important;
  }

  &.lineNumbers {
    pre,
    textarea {
      padding-left: 60px !important;
    }

    .line {
      position: absolute;
      left: 0;
      color: ${p => p.theme.palette.grey[400]};
      text-align: right;
      width: 40px;
    }
  }
`;

export type BasicEditor = {
  className?: string;
  disabled?: boolean;
  disableLineNumbers?: boolean;
  disableCopyButton?: boolean;
  highlight?(value: string): React.ReactNode;
  name?: string;
  onChange(value: string): void;
  onCopy?(): void;
  value: string;
};

export function BasicEditor(props: BasicEditor) {
  const { className, disabled, disableLineNumbers, highlight, name, onChange, value } = props;

  const hl = typeof highlight === 'function' ? highlight : Highlight.none;
  return (
    <Editor
      className={classnames('BasicEditor', { lineNumbers: !disableLineNumbers }, className)}
      value={value ?? ''}
      onCopy={props.onCopy}
      onValueChange={onChange}
      highlight={code => (disableLineNumbers ? hl(code) : addLineNumbers(hl(code)))}
      name={name}
      padding={12}
      disabled={disabled}
    />
  );
}

function addLineNumbers(code: string | React.ReactNode): string | React.ReactNode {
  return typeof code === 'string'
    ? code
        .split('\n')
        .map((line, i) => `<span class='line'>${i + 1}</span>${line}`)
        .join('\n')
    : code;
}
