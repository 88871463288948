import React from 'react';

import Placeholder from 'snap-ui/Placeholder';
import { styled } from 'snap-ui/util';

type Props = {
  loading: boolean;
  children: React.ReactNode;
};

const Container = styled('div')`
  padding: ${p => p.theme.spacing(4)};
`;

export default function Loader(props: Props) {
  return props.loading ? (
    <Container>
      <Placeholder variant='text' />
      <Placeholder variant='text' width='50%' />
      <Placeholder variant='text' width='80%' />
      <Placeholder variant='text' width='60%' />
      <br />
      <Placeholder variant='text' />
      <Placeholder variant='text' width='50%' />
      <Placeholder variant='text' width='80%' />
      <Placeholder variant='text' width='60%' />
    </Container>
  ) : (
    <>{props.children}</>
  );
}
