import { Backfill, Host, Machine, Session } from 'module/Session/Session.type';

import { Visibility } from 'types/common';

export const SESSION_URL = '/session/';
export const ACTIVE_SESSION_URL = '/session/active/';
export const GET_AVAILABLE_HOSTS_URL = '/hosts/available/';
export const SESSION_ATTACK_URL = '/session/attack/';
export const PUBLISH_URL = '/publish';

export const SESSION_MODE = {
  EDIT: 'edit',
  PUBLISH: 'publish',
  SAVED: 'saved'
};

// These two can go into visibility when BE adds them.
// We don't have ordering for them yet.
export const ANY = 'any';
export const ACTIVE = 'active';

export const SESSION_STATES = [ANY, ACTIVE, Visibility[Visibility.Draft], Visibility[Visibility.Published]];

export const VOTE = {
  UP: 'up',
  DOWN: 'down'
};

export enum MarkerColor {
  Blue = 'blue',
  Red = 'red'
}

export const SessionShell: Session = {
  tags: [],
  creation: null,
  modified: null,
  created_by_id: 0,
  modified_by_id: 0,
  description: null,
  name: null,
  guid: null,
  short_id: null,
  visibility: null,
  hosts: [],
  references: [],
  severity: null,
  linked_threat_intelligence: [],
  linked_tags: [],
  linked_attacks: [],
  permission: [],
  organization_id: 0,
  small_image: null,
  large_image: null,
  owner_id: 0,
  logsource: '',
  preview: false,
  linked_actors: [],
  intel: [],
  actor_names: [],
  attack_names: [],
  software_names: [],
  vulnerability_names: []
};

export const HostShell: Host = {
  guid: null,
  id: 0,
  start: null,
  end: null,
  session_id: 0,
  machine_id: 0,
  is_attacker: false,
  is_victim: false,
  red_markers: [],
  machine: {} as Machine,
  graph: null,
  keystrokes: null,
  video: null
};

export const BACKFILL_SHELL: Backfill = {
  status: 'IDLE'
};

export enum HOST_TYPE {
  ATTACKER = 'attacker',
  VICTIM = 'victim'
}

export const HOST_COLORS = {
  [HOST_TYPE.ATTACKER]: 'red',
  [HOST_TYPE.VICTIM]: 'blue'
};

export const HOST_STATE = {
  AVAILABLE: 'AVAILABLE',
  CONFIGURING: 'CONFIGURING',
  CHECKED_OUT: 'CHECKED_OUT',
  DELETED: 'DELETED'
};

export const KALI_OS = 'kali';
export const COMMANDO_OS = 'commando';
export const WINDOWS_10 = 'win10';
export const UBUNTU_OS = 'ubuntu';
export const WINDOWS_DC_OS = 'windc';
