import React from 'react';

import { faUpload, faVideo } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import Button, { RouterButton } from 'snap-ui/Button';
import Card, { CardActions, CardContent } from 'snap-ui/Card';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import useNoPermitCreateRedirect from 'hooks/useNoPermitCreateRedirect';
import useTitle from 'hooks/useTitle';

import { Engage, Fingerprint, NotableEvent } from 'lib/Engagement';

import { ApiError } from 'module/ApiError';

import { useCommissionedMachine } from 'provider';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { ArtifactType } from 'types/common';

import { COMMANDO_OS, KALI_OS, UBUNTU_OS, WINDOWS_10, WINDOWS_DC_OS } from '../Session.const';
import useMachine from '../useMachine';
import Commando2Button from './HostCommandoButton';
import KaliButton from './HostKaliButton';
import UbuntuButton from './HostUbuntuButton';
import Windows10Button from './HostWindows10Button';
import WindowsDCButton from './WindowsDCButton';

const MachineCard = styled(Card)`
  width: fit-content;
  padding: ${p => p.theme.spacing(4)};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${p => p.theme.spacing(4)};

  .MachineCard-actions {
    gap: ${p => p.theme.spacing(2)};
  }
`;

const ErrorAlert = styled(ApiError)`
  margin: ${p => p.theme.spacing(3)} auto;
  justify-content: center;
  min-width: 80%;
`;

const SessionContainer = styled('div')`
  flex-grow: 1;

  padding: ${p => p.theme.spacing(5)};
  position: relative;

  .config {
    margin-top: ${p => p.theme.spacing(9)};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${p => p.theme.spacing(6)};
  }

  .toolbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${p => p.theme.spacing(6)};
  }

  .action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${p => p.theme.spacing(3)};
  }

  a,
  button,
  h1 {
    white-space: nowrap;
  }

  a svg {
    height: ${p => p.theme.palette.dimension.svg};
    color: ${p => p.theme.palette.grey[600]};
  }

  button svg {
    height: ${p => p.theme.palette.dimension.svg};
  }

  a:hover {
    color: ${p => p.theme.palette.common.white};
  }

  @media (min-width: 640px) {
    .action {
      flex-direction: row;
      align-items: center;
    }
  }

  @media (min-width: 801px) {
    padding: ${p => p.theme.spacing(9, '20%', 7)};

    .toolbar {
      flex-direction: row;
      justify-content: space-between;
    }

    .config {
      margin-top: 100px;
      flex-direction: row;
      justify-content: space-evenly;
    }
  }

  @media (min-width: 1001px) {
    .toolbar {
      flex-direction: row;
      margin-bottom: ${p => p.theme.spacing(7)};
    }
  }
`;

export default function SessionVMConfig() {
  useNoPermitCreateRedirect(ArtifactType.Session);
  useTitle('Capture Threat | SnapAttack');
  const { push } = useHistory();

  const { data: hosts, status, commission, errorProps, available } = useMachine();
  const { activeSession, setActiveSession } = useCommissionedMachine();
  const [attackers, setAttackers] = React.useState<string[]>([]);
  const [victims, setVictims] = React.useState<string[]>([]);
  const noAvailableHosts = hosts.length === 0;

  const handleCommission = () => {
    const payload = { hosts: [...victims.map(v => ({ host_key: v })), ...attackers.map(a => ({ host_key: a }))] };
    Engage.track(
      Fingerprint.load(Path.ThreatCreate).withFunctional(FunctionalPermission.CreateVMSession).withData(payload)
    );

    commission(payload).then(
      data => {
        Engage.track(
          Fingerprint.of(Path.ThreatCreate)
            .withNotable(NotableEvent.Success)
            .withFunctional(FunctionalPermission.CreateVMSession)
            .withQualifier('commission machine')
        );
        setActiveSession(data);
        push(Path.ThreatExecute);
      },
      () => {
        Engage.track(
          Fingerprint.error(Path.ThreatCreate).withFunctional(FunctionalPermission.CreateVMSession).withData(payload)
        );
      }
    );
  };

  React.useEffect(() => {
    available();
  }, [available]);

  React.useEffect(() => {
    Engage.track(Fingerprint.load(Path.ThreatCreate).withFunctional(FunctionalPermission.CreateVMSession));
    Engage.trackPersonIncrement(`view ${Path.ThreatCreate}`, 1);
  }, []);

  return (
    <SessionContainer className={classNames('SessionVM')}>
      <div className='toolbar'>
        <Typography variant='h1'>Capture Threat</Typography>
        <div className='action'>
          <RouterButton to={Path.ThreatUpload} variant='outlined' startIcon={<Icon icon={faUpload} />}>
            Upload existing local threat capture
          </RouterButton>
          <Button
            ariaLabel='Commission virtual machine'
            disabled={
              activeSession.length > 0 || status === Status.pending || (victims.length === 0 && attackers.length === 0)
            }
            endIcon={status === Status.pending ? <Icon.SpinnerProgress /> : <Icon icon={faVideo} />}
            onClick={handleCommission}
          >
            Start Capture
          </Button>
        </div>
      </div>
      <ErrorAlert {...errorProps} />
      <div className='config'>
        <div>
          <Typography className='sa-no-wrap' variant='caption'>
            1. Select your victim machine(s)
          </Typography>
          <MachineCard>
            <CardContent>
              <Typography className='sa-no-wrap' variant='h3'>
                Victim Machines
              </Typography>
            </CardContent>
            <CardActions disableSpacing className='MachineCard-actions'>
              <Windows10Button
                disabled={noAvailableHosts || hosts.length === 0 || !hosts.some(h => h.image_key === WINDOWS_10)}
                values={victims}
                onChange={setVictims}
              />
              <WindowsDCButton
                disabled={noAvailableHosts || hosts.length === 0 || !hosts.some(h => h.image_key === WINDOWS_DC_OS)}
                values={victims}
                onChange={setVictims}
              />
              <UbuntuButton
                disabled={noAvailableHosts || !hosts.some(h => h.image_key === UBUNTU_OS)}
                values={victims}
                onChange={setVictims}
              />
            </CardActions>
          </MachineCard>
        </div>
        <div>
          <Typography className='sa-no-wrap' variant='caption'>
            2. Optionally, select your attacker machine(s)
          </Typography>
          <MachineCard>
            <CardContent>
              <Typography className='sa-no-wrap' variant='h3'>
                Attacker Machines
              </Typography>
            </CardContent>
            <CardActions disableSpacing className='MachineCard-actions'>
              <Commando2Button
                disabled={noAvailableHosts || !hosts.some(h => h.image_key === COMMANDO_OS)}
                values={attackers}
                onChange={setAttackers}
              />
              <KaliButton
                disabled={noAvailableHosts || !hosts.some(h => h.image_key === KALI_OS)}
                values={attackers}
                onChange={setAttackers}
              />
            </CardActions>
          </MachineCard>
        </div>
      </div>
    </SessionContainer>
  );
}
