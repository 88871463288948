import React from 'react';

import { faCopy } from '@fortawesome/pro-solid-svg-icons';

import CopyButton from 'module/Widgets/CopyButton';

import { unescapeJson } from 'utilities/StringUtils';

import Button, { ActionIconButton } from './Button';
import CodeBlock from './CodeBlock';
import { DisplayDialog } from './Dialog';
import { styled } from './util';

const MessageContainer = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: ${p => p.theme.spacing(2)};
`;

const DialogTitle = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  gap: ${p => p.theme.spacing(2)};
`;

const MAX_LENGTH = 50;

export default function TableErrorMessage({ message }: { message: string }): JSX.Element {
  const [showDialog, setShowDialog] = React.useState(false);

  if (!message) return null;

  return (
    <>
      <MessageContainer>
        {message.length > MAX_LENGTH ? (
          <>
            <div>{message.slice(0, MAX_LENGTH - 3)}...</div>
            <Button variant='text' onClick={() => setShowDialog(true)}>
              More
            </Button>
          </>
        ) : (
          message
        )}
      </MessageContainer>
      <DisplayDialog
        DialogProps={{ open: showDialog, onClose: () => setShowDialog(false), maxWidth: 'md' }}
        title={
          <DialogTitle>
            Error Message
            <CopyButton arrow tooltip='Copy message to clipboard' value={message}>
              <ActionIconButton className='SyntaxViewer-copy' aria-label='Copy to clipboard' icon={faCopy} />
            </CopyButton>
          </DialogTitle>
        }
      >
        {<CodeBlock>{unescapeJson(message)}</CodeBlock>}
      </DisplayDialog>
    </>
  );
}
