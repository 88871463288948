import React from 'react';

import isEmpty from 'lodash/isEmpty';

import ToggleButton, { ToggleButtonGroup } from 'snap-ui/ToggleButton';

import { JobType, JobTypeTableMap } from './Job.type';

type JobTypeToggleProps = {
  jobTypeFilter: JobType | 'All';
  jobTypes: JobType[];
  onTypeChange: (type: JobType | 'All') => void;
};

export function JobTypeToggle({ jobTypeFilter, jobTypes, onTypeChange }: JobTypeToggleProps) {
  if (isEmpty(jobTypes)) return null;

  const handleJobTypeChange = (_event: React.MouseEvent<HTMLElement>, mode: JobType | 'All') => {
    if (mode) {
      onTypeChange(mode);
    }
  };

  return (
    <ToggleButtonGroup orientation='horizontal' value={jobTypeFilter} exclusive onChange={handleJobTypeChange}>
      <ToggleButton value='All'>All Tasks</ToggleButton>
      {jobTypes.map(jobType => (
        <ToggleButton key={jobType} value={jobType}>
          {JobTypeTableMap[jobType]}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
