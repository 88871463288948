import React from 'react';

import { useIDEValue } from 'module/IDE';

import { useLogsourceContext } from 'provider';

import { isWinEvent } from 'services/analyticService';

import { FieldOption } from 'types/analytic';

export default function useFieldOptions(): [FieldOption[], number[]] {
  const { data: sources } = useLogsourceContext();
  const { value: logsource } = useIDEValue('logsource');

  return React.useMemo(() => {
    let eventIds: number[] = [];
    const fields: FieldOption[] = [];
    const used: string[] = [];

    const logsourceSource = logsource?.product === 'windows' && !isWinEvent(logsource) ? 'sysmon' : logsource?.product;
    const logsourceCategory = logsource?.category || logsource?.service;
    sources.forEach(source => {
      if (source.source !== logsourceSource) return;
      source.categories.forEach(cat => {
        if (cat.category !== logsourceCategory) return;
        cat.fields.forEach(field => {
          if (field.field === 'EventID') {
            eventIds = field.event_ids.sort((a, b) => a - b);
          }

          if (!used.includes(field.field)) {
            fields.push(field);
            used.push(field.field);
          }
        });
      });
    });

    return [fields, eventIds];
  }, [logsource, sources]);
}
