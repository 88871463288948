import config from 'config/config';

/**
 * Downloading your standard file types, i.e. json csv
 * @param blob
 * @param fileName
 */
export function downloadFile(blob: Blob, fileName: string): void {
  const url = window.URL.createObjectURL(blob);
  download(url, fileName);
}

/**
 * Workaround for downloading those pesky zip files. A better solution should be taken.
 * @param uuid the resulting ID provided by the API
 * @param fileName
 */
export function downloadResultId(uuid: string, fileName: string) {
  const url = `${config.SNAPATTACK_API_URL}/signatures/export/${uuid}/result`;
  download(url, fileName);
}

function download(url: string, fileName: string): void {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
}
