import { Group } from 'types/auth';

import { DEFAULT_ROLES, GROUP_TYPE, SORTED_DEFAULT_ROLES } from './organization';

const MAX_INDEX = Object.keys(DEFAULT_ROLES).length;

function getIndex(group: Group): number {
  return group.type === GROUP_TYPE.RoleAssignment
    ? Object.keys(DEFAULT_ROLES).findIndex(key => DEFAULT_ROLES[key] === group.role)
    : MAX_INDEX;
}

export const sortGroupByRole = (groupA: Group, groupB: Group) => {
  return getIndex(groupA) - getIndex(groupB);
};

export function compareRolesDescending(roleA: string, roleB: string) {
  if ((roleA === '' && roleB === '') || (roleA === '' && roleB === null)) return 0;
  if (roleA === '' || roleA === null) return -1;
  if (roleB === '' || roleB === null) return 1;

  const rankA = SORTED_DEFAULT_ROLES[roleA];
  const rankB = SORTED_DEFAULT_ROLES[roleB];

  return rankA - rankB;
}
