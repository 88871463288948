import React from 'react';

import { deleteConfig, getConfigs, postConfig, putConfig } from 'apis/resources/analytic';

import { ErrorProps } from 'module/ApiError';

import { useAuth } from 'provider';

import { useAsync, Status } from 'storage';

import { ConfigType, SigmaConfig } from 'types/analytic';
import { Ident } from 'types/common';

export type SigmaConfigsState = {
  configs: SigmaConfig[];
  create(configYaml: string, organization_id: Ident): Promise<SigmaConfig>;
  error?: string;
  errorProps?: ErrorProps;
  refresh(): void;
  remove(sigma_identifier: string): Promise<void>;
  resetError(): void;
  status: Status;
  update(sigma_identifier: string, configYaml: string, organization_id: Ident): Promise<void>;
};

function useConfigType(configType: ConfigType): SigmaConfigsState {
  const { user } = useAuth();
  const { data: configs, error, errorProps, run, status, task } = useAsync([]);

  const refresh = React.useCallback(() => {
    if (user.id) {
      run(getConfigs(configType));
    }
  }, [configType, run, user.id]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  const create = React.useCallback(
    (configYaml: string, organization_id: Ident): Promise<SigmaConfig> => {
      return task(postConfig(configType, configYaml, organization_id)).then(data => {
        refresh();
        return data;
      });
    },
    [configType, refresh, task]
  );

  const update = React.useCallback(
    (sigma_identifier: string, configYaml: string, organization_id: Ident) => {
      return task(putConfig(configType, sigma_identifier, configYaml, organization_id)).then(refresh);
    },
    [configType, refresh, task]
  );

  const remove = React.useCallback(
    (sigma_identifier: string) => {
      return task(deleteConfig(configType, sigma_identifier)).then(refresh);
    },
    [configType, refresh, task]
  );

  const resetError = React.useCallback(() => {
    task(Promise.resolve());
  }, [task]);

  return {
    create,
    configs,
    error: error ? error.message || JSON.stringify(error.detail) || 'The server encountered an error' : undefined,
    errorProps,
    refresh,
    remove,
    resetError,
    status,
    update
  };
}

export function useLegacySigmaConfigs(): SigmaConfigsState {
  return useConfigType('field_mapping');
}

export function useSigmaPipelines(): SigmaConfigsState {
  return useConfigType('pipeline');
}

export function useSigmaValidators(): SigmaConfigsState {
  return useConfigType('validator');
}
