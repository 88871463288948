import React from 'react';

import { Form } from 'formik';

import Button from 'snap-ui/Button';
import CircularProgress from 'snap-ui/CircularProgress';

import AutocompleteFormik from 'module/Form/AutocompleteFormik';

import { User } from 'types/auth';
import { Guid } from 'types/common';

type Props = {
  isActive: boolean;
  impersonatedGuid: Guid;
  user: User;
  options: { id: number; content: string; value: string }[];
  onCancel(): void;
};

export default function ImpersonateForm({ isActive, impersonatedGuid, user, options, onCancel }: Props) {
  return (
    <Form>
      <AutocompleteFormik
        disabled={options.length <= 0}
        disableUserAdditions={true}
        options={options}
        name='user'
        label='Select user'
      />
      <div className='action'>
        {isActive && <CircularProgress size={20} />}
        <Button
          id='ImpersonateUserSubmit'
          ariaLabel='Impersonate user for testing'
          disabled={isActive || user.guid === impersonatedGuid}
          type='submit'
        >
          Impersonate User
        </Button>
        <Button
          id='ClearImpersonation'
          ariaLabel='Clear impersonate for user'
          variant='outlined'
          disabled={isActive || !user.impersonator}
          onClick={onCancel}
        >
          Clear Impersonation
        </Button>
      </div>
    </Form>
  );
}
