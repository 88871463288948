import React from 'react';

import rison from 'rison';

import { Option } from 'snap-ui/Autocomplete';

import { LogsourceType, SigmaLogsource } from 'types/analytic';

import { useLogsourceContext } from './';

export default function useLogsourceOptions(): Option[] {
  const { data: sources } = useLogsourceContext();

  const options: Option[] = React.useMemo(() => {
    if (!sources?.length) return [];

    const sysmonCategories =
      sources.find(source => source.source === LogsourceType.Sysmon)?.categories.map(cat => cat.category) || [];

    return sources.flatMap(source =>
      source.categories.map(cat => {
        const product = source.source === LogsourceType.Sysmon ? 'windows' : source.source;

        const value: SigmaLogsource = { product };
        if (sysmonCategories.includes(cat.category)) {
          value.category = cat.category;
        } else {
          value.service = cat.category;
        }

        return {
          group: product,
          value: rison.encode(value),
          content: `${product} ${cat.category}`
        };
      })
    );
  }, [sources]);

  return options;
}
