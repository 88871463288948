import React from 'react';

import { getProtectedSettings } from 'apis/resources/auth';

import { useAuth } from 'provider';

import { useAsync } from 'storage';

import { ProtectedSettings } from 'types/auth';

export default function useProtectedSettings(): ProtectedSettings {
  const { data, run } = useAsync({} as ProtectedSettings);
  const { user } = useAuth();

  React.useEffect(() => {
    if (user.id) {
      run(getProtectedSettings());
    }
  }, [run, user.id]);

  return data;
}
