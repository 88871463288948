import React from 'react';

import { z } from 'zod';

import { FormDialog } from 'snap-ui/Dialog';
import Placeholder from 'snap-ui/Placeholder';
import { styled } from 'snap-ui/util';

import { ApiError, ErrorProps } from 'module/ApiError';
import { standardFormikBaseProps } from 'module/Form';
import DataGridFormik from 'module/Form/DataGridFormik';

import { useIntegrationCatalog } from 'provider/Integration';

type DetermineOutcomesFormDialogProps = {
  open: boolean;
  onClose(): void;
  handleSubmit(selections: string[]): void;
  isLoading: boolean;
  apiError: ErrorProps;
};

const Container = styled('div')`
  min-height: 400px;

  .determine-outcomes-grid {
    margin-top: ${p => p.theme.spacing(5)};
    height: 525px;
    width: 100%;
  }
`;

const FormSchema = z.object({
  selections: z.array(z.string()).min(1, 'Expected at least 1 selection')
});

function DetermineOutcomesFormDialog({
  open,
  onClose,
  handleSubmit,
  isLoading,
  apiError
}: DetermineOutcomesFormDialogProps) {
  const {
    integrations: { huntable }
  } = useIntegrationCatalog();

  return (
    <FormDialog
      title='Determine Outcomes'
      DialogProps={{ open, onClose }}
      SubmitProps={{
        children: 'Submit',
        disabled: isLoading
      }}
      FormikConfig={{
        ...standardFormikBaseProps,
        initialValues: { selections: [] },
        zodSchema: FormSchema,
        onSubmit: ({ selections }) => {
          handleSubmit(selections);
        }
      }}
    >
      <Container>
        <ApiError {...apiError} />
        <DataGridFormik
          className='determine-outcomes-grid'
          name='selections'
          checkboxSelection={!isLoading}
          disableColumnSelector
          columns={[
            {
              field: 'name',
              headerName: 'Integration',
              hideable: false,
              flex: 1,
              renderCell: () => (isLoading ? <Placeholder variant='text' width={250} /> : undefined)
            }
          ]}
          rows={huntable}
          getRowId={row => row.guid}
        />
      </Container>
    </FormDialog>
  );
}

export default DetermineOutcomesFormDialog;
