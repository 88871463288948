import React from 'react';

import { getAllUser } from 'apis/resources/user';

import { useAsync } from 'storage';

import { User } from 'types/auth';

export default function useAllUser() {
  const { data, run, status } = useAsync<User[]>([]);

  const refresh = React.useCallback(() => {
    run(getAllUser());
  }, [run]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return { data, refresh, status };
}
