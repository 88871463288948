import React from 'react';

import { MultiProgress, MultiProgressBarProps } from 'snap-ui/LinearProgress';
import { styled } from 'snap-ui/util';

import { BASJobState } from 'types/bas';

import { CampaignResultStatus } from '../BAS.type';

type CampaignStatusProps = {
  result_status: CampaignResultStatus;
};

const Progress = styled(MultiProgress)`
  min-width: 100px;
`;

export default function CampaignStatus({ result_status }: CampaignStatusProps): JSX.Element {
  const total = Object.values(result_status).reduce((sum, count) => sum + count, 0);
  const bars: MultiProgressBarProps[] = [
    {
      color: 'success',
      label: `${result_status[BASJobState.Completed]} attack simulations executed without problems`,
      percent: Math.round((result_status[BASJobState.Completed] / total) * 100) || 0
    },
    {
      color: 'warning',
      label: `${result_status[BASJobState.MissingPrereq]} attack simulations had failing dependencies`,
      percent: Math.round((result_status[BASJobState.MissingPrereq] / total) * 100) || 0
    },
    {
      color: 'error',
      label: `${result_status[BASJobState.Failed]} attack simulations failed to execute`,
      percent: Math.round((result_status[BASJobState.Failed] / total) * 100) || 0
    },
    {
      color: 'secondary',
      label: `${result_status.incomplete} attack simulations waiting to execute`,
      percent: Math.round((result_status.incomplete / total) * 100) || 0
    }
  ];
  return <Progress bars={bars} />;
}
