import React from 'react';

import { getUser } from 'apis/resources/user';

import { useAuth } from 'provider';

import { useAsync } from 'storage';

import { UserResponse } from 'types/auth';

// Use this hook to ensure a user session is
// still active before performing some action
// This leverages the 401 error code handling
// in the interceptor to redirect to the login page.
export default function useIsAuthenticated() {
  const { user } = useAuth();
  const { run } = useAsync<UserResponse>();

  const refresh = React.useCallback(() => {
    run(getUser(user.guid));
  }, [user.guid, run]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);
}
