export function NOOP(...args): void {} // eslint-disable-line @typescript-eslint/no-unused-vars

export function debounce(dispatch: (...args) => void, delay = 250): (...args) => void {
  let timer: NodeJS.Timeout;

  return function (...args): void {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      dispatch(...args);
    }, delay);
  };
}

export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function handleDebug(event: React.MouseEvent<HTMLDivElement>, callback: () => void) {
  if (event.ctrlKey && event.shiftKey) callback();
}
