import React from 'react';

import { faStar } from '@fortawesome/pro-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

import Icon from 'snap-ui/Icon';

import { StackedIcon } from 'module/Widgets/MarkerIcons/StackedIcon';

type Props = {
  className?: string;
  inheritBackground?: boolean;
};

function UndetectedThreatIcon({ inheritBackground, className }: Props): React.ReactElement {
  return (
    <StackedIcon className={classNames('non-domino-icon hollow-icon', className)}>
      <Icon icon={faStarSolid} color={inheritBackground ? 'inherit' : 'secondary'} />
      <Icon icon={faStar} color='error' />
    </StackedIcon>
  );
}

export default UndetectedThreatIcon;
