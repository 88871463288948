import React from 'react';

import classnames from 'classnames';
import { Redirect, Route, Switch } from 'react-router-dom';

import BackdropLoader from 'snap-ui/BackdropLoader';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { useSettingsSidebar } from 'module/Scaffold/useStashSidebar';
import NotFound from 'module/Util/Fallback/NotFound';

import { useAuth, useManagedOrganizations } from 'provider';

import { Status } from 'storage';

import settingItems from './Setting.helper';

const Container = styled('div')`
  flex-grow: 1;
  width: 100%;
  position: relative;

  & .item-content {
    margin-top: ${p => p.theme.spacing(9)};
    margin-left: ${p => p.theme.spacing(9)};
    max-width: 85%;
  }

  & .item-content-task {
    max-width: 100%;
  }

  & .item-fullpage {
    max-height: 100vh;
    height: 100%;
  }

  & .sub-heading {
    color: ${p => p.theme.palette.grey[400]};
  }

  & .Filter {
    .toggle-header {
      padding-bottom: ${p => p.theme.spacing(2)};
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
    }
  }

  & .ApiKeys {
    & .item {
      display: flex;
      flex-direction: row;
      align-items: center;

      & > * {
        flex: 0 0;
      }

      & > :first-of-type {
        flex: 1 0;
      }
    }
  }

  & .ApiKeys-Modal {
    form .actions {
      // apply .ui.modal > .actions styles since the nesting of the form screws it up
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-start;
      align-items: center;
      padding: 1em;
      margin: 0 -1.5rem; // undo padding added by parent .modal > .content
    }
  }

  & .BASAgents {
    & .item {
      display: flex;
      flex-flow: row nowarp;
      justify-content: space-between;
      align-items: center;

      & > :first-of-type {
        flex: 1 0;
      }

      & .checkin {
        white-space: nowrap;
      }

      & .actions {
        flex: 0 0;
      }
    }
  }
`;

function Setting() {
  useSettingsSidebar();
  const { user, permission: userOrganizations } = useAuth();
  const { organizations: managedOrganizations, status } = useManagedOrganizations();
  const allowed = settingItems.filter(item =>
    item?.getAllowed ? item.getAllowed(user, userOrganizations, managedOrganizations) : true
  );
  const currentPage = settingItems.filter(item => window.location.pathname.includes(item.path))[0];

  return (
    <Container>
      <div
        className={classnames({
          'item-fullpage': currentPage?.fullPageStyle,
          'item-content': !currentPage?.fullPageStyle && currentPage?.name,
          'item-content-task': !currentPage?.fullPageStyle && currentPage?.name === 'Tasks'
        })}
      >
        <Switch>
          {allowed.map(menuItem => {
            const paths = [`${Path.Setting}/${menuItem.path}`];
            if (menuItem.enablePathIdVariable) paths.push(`${Path.Setting}/${menuItem.path}/:id`);
            return <Route key={menuItem.name} exact path={paths} component={menuItem.component} />;
          })}
          <Route exact path={Path.Setting}>
            <Redirect to={`${Path.Setting}/${allowed[0].path}`} />
          </Route>
          {status === Status.pending ? <BackdropLoader contained fixed /> : <Route component={NotFound} />}
        </Switch>
      </div>
    </Container>
  );
}

export default Setting;
