import React from 'react';

import { styledBox } from 'snap-ui/util';

import { StrictReactNode } from 'types/core';

const Stacked = styledBox(() => ({
  display: 'grid',

  '& > *': {
    gridColumn: 1,
    gridRow: 1
  }
}));

export function StackedIcon({ children, className }: { children: StrictReactNode; className?: string }) {
  return <Stacked className={className}>{children}</Stacked>;
}
