import React from 'react';

import Tabs, { TabItem } from 'snap-ui/Tabs';

import { AnalyticTabType } from 'types/analytic';

type BuilderPanelProps = {
  isFetching: boolean;
  activeTab: AnalyticTabType;
  disableDetection?: boolean;
  disableMetadata?: boolean;
  detectionComponent: React.ReactElement;
  metadataComponent: React.ReactElement;
  advancedComponent: React.ReactElement;
  onTabChange: (tab: AnalyticTabType) => boolean | void;
  toolbarComponent: React.ReactElement;
};

export default function BuilderPanel(props: BuilderPanelProps): React.ReactElement {
  const content: TabItem[] = [];
  if (props.detectionComponent) {
    content.push({
      value: AnalyticTabType.Detection,
      label: <>Detection</>,
      disabled: props.isFetching || props.disableDetection,
      content: props.detectionComponent
    });
  }

  if (props.metadataComponent) {
    content.push({
      value: AnalyticTabType.Metadata,
      label: <>Details</>,
      disabled: props.isFetching || props.disableMetadata,
      content: props.metadataComponent
    });
  }

  if (props.advancedComponent) {
    content.push({
      value: AnalyticTabType.Advanced,
      label: 'Advanced',
      disabled: props.isFetching,
      content: props.advancedComponent
    });
  }

  return (
    <Tabs
      nowrap
      tabs={content}
      auxiliary={props.toolbarComponent}
      onChange={(e, val) => props.onTabChange(val)}
      value={props.activeTab}
      variant='scrollable'
    />
  );
}
