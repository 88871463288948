import React from 'react';

import MuiAutocomplete, {
  AutocompleteChangeReason,
  AutocompleteRenderGroupParams,
  createFilterOptions
} from '@mui/material/Autocomplete';
import classnames from 'classnames';

import Chip from 'snap-ui/Chip';
import CircularProgress from 'snap-ui/CircularProgress';
import TextField from 'snap-ui/TextField';
import { styled } from 'snap-ui/util';

import { StrictReactNode } from 'types/core';

export type { AutocompleteChangeReason };

const filter = createFilterOptions();

const NoOptionsText = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: ${p => p.theme.spacing(2)};

  .MuiCircularProgress-circle {
    color: ${p => p.theme.palette.text.secondary};
  }
`;

const GroupList = styled('li')`
  padding: 0;
`;

const GroupHeader = styled('div')`
  line-height: 3;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  padding-left: ${p => p.theme.spacing(4)};
  padding-right: ${p => p.theme.spacing(4)};
  background-color: ${p => p.theme.palette.background.paper};
  cursor: pointer;
`;

const GroupOption = styled('ul')`
  padding-left: 0;
  .MuiAutocomplete-option {
    padding-left: ${p => p.theme.spacing(4)};
  }
`;

export const AutocompleteOptionContainer = styled('div')`
  padding: 0;
  display: flex;
  gap: ${p => p.theme.spacing(2)};
  align-items: center;

  .MuiListItemIcon-root {
    min-width: unset;
    width: 20px;
  }
`;

export type Option = {
  content: StrictReactNode;
  label?: string;
  userAdded?: boolean;
  value: string;
  group?: string;
  invalid?: boolean;
};

export type AutocompleteProps = {
  className?: string;
  error?: boolean;
  helperText?: React.ReactNode;
  label?: string;
  loading?: boolean;
  multiple?: boolean;
  name: string;
  onChange(value: string | Option[]): void;
  onBlur?(): void;
  onClick?(): void;
  options: Option[];
  value: string | Option | string[] | Option[];
  testId?: string;
  disabled?: boolean;
  disableClearable?: boolean;
  disableUserAdditions?: boolean;
  disableGroupSelect?: boolean;
  omitPopupIcon?: boolean;
  transformValue?(value: Option | string | (Option | string)[]): Option[];
  renderTags?(value, getTagProps): JSX.Element;
  disablePortal?: boolean;
  startAdornment?: StrictReactNode;
  endAdornment?: StrictReactNode;
  placeholder?: string;
  onInputChange?(value: string): void;
  inputValue?: string;
  isOptionEqualToValue?(option: unknown, value: unknown): boolean;
  searching?: boolean;
  filterByValue?: boolean;
  customNewOptionContent?(value: string): StrictReactNode;
  PaperComponent?: React.JSXElementConstructor<React.HTMLAttributes<HTMLElement>>;
  /** synthetic paper effect */
  elevated?: boolean | number;
};

export default function Autocomplete(props: AutocompleteProps): JSX.Element {
  const [open, setOpen] = React.useState<boolean>(false);

  function handleChange(e, newValue: Option | string | (Option | string)[]): void {
    const value = props.transformValue ? props.transformValue(newValue) : newValue;
    if (props.multiple) handleMultipleChange(value as (Option | string)[]);
    else handleSingleChange(value as Option | string);
  }

  function handleMultipleChange(values: (Option | string)[]): void {
    props.onChange(
      values.map(value => {
        if (typeof value === 'string') {
          return { content: value, userAdded: true, value };
        } else {
          return value;
        }
      })
    );
  }

  function handleSingleChange(newValue: Option | string): void {
    if (typeof newValue === 'string') {
      props.onChange(newValue);
    } else {
      props.onChange(newValue?.value || '');
    }
  }

  function filterByValue(value: string) {
    return function (option: Option) {
      return option.value.match(new RegExp(value, 'gi'));
    };
  }

  function handleFilterOptions(options: Option[], params): Option[] {
    const filtered = props.filterByValue
      ? options.filter(filterByValue(params.inputValue))
      : (filter(options, params) as Option[]);
    const { inputValue } = params;

    const inputValueExists = options.some(option => option.value === inputValue);

    if (inputValue && !props.disableUserAdditions && !inputValueExists) {
      return [
        ...filtered,
        {
          content: props.customNewOptionContent ? props.customNewOptionContent(inputValue) : `Add "${inputValue}"`,
          userAdded: true,
          value: inputValue
        }
      ];
    } else {
      return filtered;
    }
  }

  function getOptionLabel(option: string | Option): string {
    if (typeof option === 'string') {
      return option;
    }

    if (option.userAdded) {
      return option.value;
    }

    // regular option
    if (typeof option.content == 'string') {
      return option.content;
    }

    return option.label || '';
  }

  function renderInput(params): JSX.Element {
    const endAdornment = props.endAdornment || params.InputProps.endAdornment;
    const startAdornment = props.startAdornment || params.InputProps.startAdornment;
    return (
      <TextField
        {...params}
        className={classnames('AutocompleteInput', params.className)}
        onClick={props.onClick}
        onBlur={props.onBlur}
        error={props.error}
        helperText={props.helperText}
        label={props.label}
        InputProps={{
          ...params.InputProps,
          startAdornment,
          endAdornment,
          placeholder: props.placeholder
        }}
        elevated={props.elevated}
      />
    );
  }

  function renderOption(props, option: Option): JSX.Element {
    return (
      <li {...props} key={option.value}>
        {option.content}
      </li>
    );
  }

  function renderTags(value: (string | Option)[], getTagProps): JSX.Element[] {
    return value.map((option, index) => {
      if (typeof option === 'string') {
        option = props.options.find(opt => opt.value === option) || option;
      }
      return <Chip key={index} label={getOptionLabel(option)} {...getTagProps({ index })} />;
    });
  }

  function renderGroup(params: AutocompleteRenderGroupParams): JSX.Element {
    function handleClick(e) {
      if (props.disableGroupSelect) return;
      props.multiple ? handleMultipleChange(e) : handleSingleChange(e);
      setOpen(false);
    }

    function handleMultipleChange(e) {
      const existingValue = Array.isArray(props.value) ? props.value : [props.value];
      handleChange(e, [...existingValue, params.group]);
    }

    function handleSingleChange(e) {
      handleChange(e, params.group);
    }

    return (
      <GroupList key={params.key}>
        <GroupHeader onClick={handleClick}>{params.group}</GroupHeader>
        <GroupOption>{params.children}</GroupOption>
      </GroupList>
    );
  }

  const handleInputChange = props.onInputChange
    ? (_event, newInputValue, reason) => {
        props.onInputChange(reason === 'input' ? newInputValue : props.inputValue);
      }
    : undefined;

  return (
    <MuiAutocomplete
      data-testid={props.testId}
      className={props.className}
      clearOnBlur
      disabled={props.disabled}
      disableClearable={props.disableClearable}
      filterOptions={handleFilterOptions}
      forcePopupIcon={!props.omitPopupIcon}
      freeSolo={!props.disableUserAdditions}
      getOptionLabel={getOptionLabel}
      handleHomeEndKeys
      id={props.name}
      aria-label={props.name}
      loading={props.loading}
      multiple={props.multiple}
      onChange={handleChange}
      options={props.options}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderInput={renderInput}
      renderGroup={renderGroup}
      renderOption={renderOption}
      renderTags={props.renderTags ? props.renderTags : renderTags}
      selectOnFocus
      value={props.options.find(opt => opt.value === props.value)?.label || props.value}
      groupBy={(o: Option) => o.group}
      disablePortal={props.disablePortal}
      onInputChange={handleInputChange}
      inputValue={props.inputValue}
      isOptionEqualToValue={props.isOptionEqualToValue}
      noOptionsText={
        props.searching ? (
          <NoOptionsText>
            <CircularProgress size={15} /> Searching...
          </NoOptionsText>
        ) : undefined
      }
      PaperComponent={props.PaperComponent}
    />
  );
}
