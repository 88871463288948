import React from 'react';

import { useHistory } from 'react-router-dom';

import Path from 'constants/paths';

import { useAuth } from 'provider';

import { checkTaskPermission } from 'services/authService';

import { FunctionalPermission } from 'types/auth';
import { ArtifactType } from 'types/common';

import { formatQueryString } from 'utilities/SearchParam';

function permissionType(artifact: ArtifactType) {
  switch (artifact) {
    case ArtifactType.Analytic:
      return FunctionalPermission.CreateAnalytic;
    case ArtifactType.Intel:
      return FunctionalPermission.CreateIntel;
    case ArtifactType.Session:
      return FunctionalPermission.CreateSession;
    case ArtifactType.Collection:
      return FunctionalPermission.CreateCollection;
  }
}

/**
 * Direct the user to the feed typic page when there is no authorization to create
 */
export default function useNoPermitCreateRedirect(artifact: ArtifactType) {
  const { replace } = useHistory();
  const { user, permission } = useAuth();
  React.useEffect(() => {
    if (user?.email) {
      const canCreate = checkTaskPermission(permission, permissionType(artifact));
      if (!canCreate) {
        const feed =
          Path.Feed +
          formatQueryString({
            topic: artifact,
            page: 1
          });
        replace(feed);
      }
    }
  }, [artifact, permission, replace, user]);
}
