import React from 'react';

import { faApple } from '@fortawesome/free-brands-svg-icons';

import Icon, { saLinuxTux, saWindows } from 'snap-ui/Icon';

import { CheckableCardCheckboxFormik } from 'module/Form/CheckableCardFormik';

import ThreatProfileStep from '../ThreatProfileStep';

export default function OperatingSystems(): JSX.Element {
  return (
    <ThreatProfileStep
      title='What operating systems do you use?'
      description='Modern operating systems face an ongoing battle against a diverse array of cyber threats, including malware, vulnerabilities, and unauthorized access attempts, underscoring the necessity of regular security updates, robust access controls, and proactive monitoring to safeguard sensitive data and maintain system integrity. The dynamic landscape of cyber threats requires a comprehensive defense approach that adapts to evolving attack vectors across various operating environments.'
      content={
        <CheckableCardCheckboxFormik
          name='platforms'
          options={[
            { value: 'Windows', label: 'Windows', icon: <Icon icon={saWindows} size='xl' /> },
            { value: 'Linux', label: 'Linux', icon: <Icon icon={saLinuxTux} size='xl' /> },
            { value: 'macOS', label: 'MacOS', icon: <Icon icon={faApple} size='xl' /> }
          ]}
        />
      }
    />
  );
}
