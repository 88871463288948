import React from 'react';

import { UserbackProvider } from '@userback/react';

export default function UserBackProvider({ children }: { children: React.ReactNode }): React.ReactElement {
  return (
    <UserbackProvider
      token={'9425|17908|OT6tJZaz9pkm2a46TcoOIVvvKD53GlmYQ8APhiao4lXGPlK1LU'}
      options={{ native_screenshot: false }}
    >
      {children}
    </UserbackProvider>
  );
}
