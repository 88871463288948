import React from 'react';

import PieChart from 'snap-ui/Charts/PieChart';

import { BASCampaign } from 'module/BAS/BAS.type';

import { ArtifactScore } from 'types/common';

import { getFlatObjectCount } from '../../BAS.util';
import { ConfidenceSeverityChartColors, PieChartDefaultProperties } from '../CampaignReport.style';
import ReportPanel from './ReportPanel';

type SeverityPanelProps = {
  campaign: BASCampaign;
};

const DATA_EMPTY_MESSAGE = 'Assigning detections when adding/editing Outcomes below will populate this chart.';

function SeverityPanel({ campaign }: SeverityPanelProps) {
  const severityEmpty = getFlatObjectCount(campaign.severities) === 0;

  const severityChartData = React.useMemo(() => {
    if (!campaign.severities) return [];
    return [
      { name: ArtifactScore.HIGHEST, value: campaign.severities[ArtifactScore.HIGHEST] },
      { name: ArtifactScore.HIGH, value: campaign.severities[ArtifactScore.HIGH] },
      { name: ArtifactScore.MEDIUM, value: campaign.severities[ArtifactScore.MEDIUM] },
      { name: ArtifactScore.LOW, value: campaign.severities[ArtifactScore.LOW] },
      { name: ArtifactScore.LOWEST, value: campaign.severities[ArtifactScore.LOWEST] },
      { name: ArtifactScore.UNKNOWN, value: campaign.severities[ArtifactScore.UNKNOWN] }
    ];
  }, [campaign.severities]);

  return (
    <ReportPanel title='Severity' hasError={severityEmpty} error={DATA_EMPTY_MESSAGE}>
      <PieChart colors={ConfidenceSeverityChartColors} data={severityChartData} {...PieChartDefaultProperties} />
    </ReportPanel>
  );
}

export default SeverityPanel;
