import React from 'react';

import { Alert, AlertTitle } from 'snap-ui/Alert';

import May from 'module/May';

import { FunctionalPermission } from 'types/auth';

export default function withFunctionalPermission<T>(
  WrappedComponent: React.FunctionComponent<T>,
  permission: FunctionalPermission,
  asNull?: boolean
) {
  function Wrapper(props: T) {
    return (
      <May I={permission}>
        <May.Yes>
          <WrappedComponent {...props} />
        </May.Yes>
        <May.No>
          {asNull ? null : (
            <Alert severity='info' width='max-content'>
              <AlertTitle>This feature is not part of your SnapAttack subscription.</AlertTitle>
              Please contact{' '}
              <a href='mailto:sales@snapattack.com' target='_blank' rel='noreferrer' className='mailto'>
                sales@snapattack.com
              </a>{' '}
              to discuss subscription plans.
            </Alert>
          )}
        </May.No>
      </May>
    );
  }
  Wrapper.displayName = `${WrappedComponent.name}WithFunctionalPermission:${permission}`;
  return Wrapper;
}
