import React from 'react';

import MuiDivider, { DividerProps } from '@mui/material/Divider';
import classNames from 'classnames';

import { styled } from './util';

const ColoredDivider = styled(MuiDivider)(p => {
  if (p.color === 'primary') {
    return {
      '&:before, &:after': {
        borderColor: p.theme.palette.primary.main
      }
    };
  }
});

export default function Divider({ className, ...others }: DividerProps) {
  return <ColoredDivider className={classNames('Divider', className)} {...others} />;
}

const BuffDivider = styled(Divider)`
  margin: ${p => p.theme.spacing(4, 0)};
  border-top: 1px solid ${p => p.theme.palette.grey[700]};
  border-bottom: 1px solid ${p => p.theme.palette.grey[800]};
`;

export { BuffDivider };
