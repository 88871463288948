import React from 'react';

import Button from 'snap-ui/Button';
import ConfirmDialog from 'snap-ui/Dialog/ConfirmDialog';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { useMultifactor } from 'module/Authentication/Multifactor/Multifactor.service';

import { usePushSnack } from 'provider';

import { Status } from 'storage';

import { User } from 'types/auth';
import { Guid } from 'types/common';

export type ResetMFAProps = {
  editorUser?: User;
  organization_id: Guid;
};

const StyledConfirmDialog = styled(ConfirmDialog)`
  & .MuiDialog-container > div {
    width: fit-content;
  }
`;

const ConfirmDialogBody = styled('div')`
  margin-bottom: ${p => p.theme.spacing(2)};
`;

function ResetMFA({ editorUser, organization_id }: ResetMFAProps) {
  const { status, orgAdminResetMFA } = useMultifactor();
  const [openModal, setOpenModal] = React.useState(false);
  const pushSnack = usePushSnack();
  const { email, guid } = editorUser;

  async function handleConfirm() {
    await orgAdminResetMFA({ organization_id, user_id: guid })
      .then(() => {
        pushSnack(`Successfully reset ${email} MFA`, 'success', 'center', 'bottom', 5000);
        setOpenModal(false);
      })
      .catch(() => {
        pushSnack(`Failed to reset MFA for ${email}`, 'error', 'center', 'bottom', 5000);
      });
  }

  return (
    <div>
      <Typography variant='h3'>
        <strong>Multi-Factor Authentication</strong>
      </Typography>
      <Button
        variant='outlined'
        onClick={() => {
          setOpenModal(true);
        }}
      >
        Reset MFA for {email}
      </Button>
      <StyledConfirmDialog
        DialogProps={{
          open: openModal,
          onClose: () => setOpenModal(false)
        }}
        ConfirmProps={{
          children: 'Confirm',
          onClick: () => handleConfirm(),
          disabled: status === Status.pending
        }}
        title='Confirm Action'
      >
        <ConfirmDialogBody>
          Are you sure you want to reset MFA for <strong>{email}</strong>?
        </ConfirmDialogBody>
      </StyledConfirmDialog>
    </div>
  );
}

export default ResetMFA;
