import Path from 'constants/paths';

import { FilterValues } from 'module/GlobalFilter';
import { HeatMap } from 'module/Matrix/Matrix.type';

import { formatQueryString, parseSchemaOnlyQueryString } from 'utilities/SearchParam';

import { DEFAULT_MATRIX_DISPLAY, MATRIX_URL_DISPLAY_PARAM_SCHEMA, MAX_DISPLAY_PARAM_VALUE } from './Scaffold.const';
import { MatrixDisplay, MatrixPaletteOrdinal } from './Scaffold.type';

export function hydratedMatrixDisplayOptions(search: string): MatrixDisplay {
  const options = parseSchemaOnlyQueryString(search, MATRIX_URL_DISPLAY_PARAM_SCHEMA) as MatrixDisplay;

  // Provide defaults
  options.display = options.display || DEFAULT_MATRIX_DISPLAY;
  options.palette = options.palette || MatrixPaletteOrdinal.Traffic;
  options.heatMap = options.heatMap || HeatMap.Detection;
  options.bounds = options.bounds || 5;

  // Clamp incoming param values
  if (options.display < 0 || options.display > MAX_DISPLAY_PARAM_VALUE) options.display = DEFAULT_MATRIX_DISPLAY;
  if (!Object.values<string>(MatrixPaletteOrdinal).includes(options.palette))
    options.palette = MatrixPaletteOrdinal.Traffic;
  if (typeof options.bounds !== 'number') options.bounds = 5;
  else if (options.bounds < 0) options.bounds = 0;

  return options;
}

export function getNewMatrixParams(pathname: string, filter: FilterValues, options: object, replacements: object) {
  return {
    pathname,
    search: formatQueryString({
      ...filter,
      ...options,
      ...replacements
    }),
    state: undefined
  };
}

export function isFilterEnabled(pathname: string) {
  const path = ('/' + pathname.split('/')?.[1]) as Path;

  switch (path) {
    case Path.Feed:
    case Path.Matrix:
      return true;
    case Path.Collection:
      return !pathname.includes('create') && !pathname.includes('edit') && !pathname.includes('attach');
    case Path.Threat:
      return (
        !pathname.includes('upload') &&
        !pathname.includes('edit') &&
        !pathname.includes('create') &&
        !pathname.includes('execute')
      );
    default:
      return false;
  }
}

export function isPageFlexible(pathname: string) {
  const path = ('/' + pathname.split('/')?.[1]) as Path;

  switch (path) {
    case Path.Matrix:
      return false;
    default:
      return true;
  }
}
