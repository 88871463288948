import React from 'react';

import classNames from 'classnames';

import Alert from 'snap-ui/Alert';
import Button from 'snap-ui/Button';
import Checkbox from 'snap-ui/Checkbox';
import Radio from 'snap-ui/Radio';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { useAuth } from 'provider';

import { NOOP } from './FunctionUtils';

const Container = styled('div')`
  padding: ${p => p.theme.spacing(5)};
  flex-grow: 1;

  .grays {
    display: flex;
  }

  section {
    margin: ${p => p.theme.spacing(3)};
    border: 1px solid ${p => p.theme.palette.secondary.main};
    padding: ${p => p.theme.spacing(3)};

    p {
      margin: ${p => p.theme.spacing(2)} 0;
    }

    > div {
      display: flex;
      gap: ${p => p.theme.spacing(3)};

      div {
        width: fit-content;
      }
    }
  }

  .square {
    min-width: 25px;
    min-height: 25px;
    padding: ${p => p.theme.spacing(1)};
    border: 1px solid ${p => p.theme.palette.secondary.dark};
  }

  .a100 {
    background-color: ${p => p.theme.palette.grey[100]};
    color: ${p => p.theme.palette.getContrastText(p.theme.palette.grey[100])};
  }

  .a200 {
    background-color: ${p => p.theme.palette.grey[200]};
    color: ${p => p.theme.palette.getContrastText(p.theme.palette.grey[200])};
  }

  .a300 {
    background-color: ${p => p.theme.palette.grey[300]};
    color: ${p => p.theme.palette.getContrastText(p.theme.palette.grey[300])};
  }

  .a400 {
    background-color: ${p => p.theme.palette.grey[400]};
    color: ${p => p.theme.palette.getContrastText(p.theme.palette.grey[400])};
  }

  .a500 {
    background-color: ${p => p.theme.palette.grey[500]};
    color: ${p => p.theme.palette.getContrastText(p.theme.palette.grey[500])};
  }

  .a600 {
    background-color: ${p => p.theme.palette.grey[600]};
    color: ${p => p.theme.palette.getContrastText(p.theme.palette.grey[600])};
  }

  .a700 {
    background-color: ${p => p.theme.palette.grey[700]};
    color: ${p => p.theme.palette.getContrastText(p.theme.palette.grey[700])};
  }

  .a800 {
    background-color: ${p => p.theme.palette.grey[800]};
    color: ${p => p.theme.palette.getContrastText(p.theme.palette.grey[800])};
  }

  .a900 {
    background-color: ${p => p.theme.palette.grey[900]};
    color: ${p => p.theme.palette.getContrastText(p.theme.palette.grey[900])};
  }

  .orange {
    background-color: ${p => p.theme.palette.orange.main};
    color: ${p => p.theme.palette.orange.contrastText};

    &.dark {
      background-color: ${p => p.theme.palette.orange.dark};
    }
  }

  .lime {
    background-color: ${p => p.theme.palette.lime.main};
    color: ${p => p.theme.palette.lime.contrastText};
  }

  .aqua {
    background-color: ${p => p.theme.palette.aqua.main};
    color: ${p => p.theme.palette.aqua.contrastText};
  }

  .mint {
    background-color: ${p => p.theme.palette.mint.main};
    color: ${p => p.theme.palette.mint.contrastText};
  }

  .pink {
    background-color: ${p => p.theme.palette.pink.main};
    color: ${p => p.theme.palette.pink.contrastText};
  }

  .mitre {
    background-color: ${p => p.theme.palette.mitre.main};
    color: ${p => p.theme.palette.mitre.contrastText};
  }

  .cert {
    background-color: ${p => p.theme.palette.cert.main};
    color: ${p => p.theme.palette.cert.contrastText};
  }
`;

export default function Palette() {
  const { user } = useAuth();

  if (!user.superuser) return null;
  return (
    <Container>
      <Typography variant='h1'>Palette</Typography>
      <section>
        <Typography variant='body1'>Gray Shades</Typography>
        <div>
          {[...Array(9)].map((e, i) => (
            <div key={i} className={classNames('square', `a${(i + 1) * 100}`)}>
              {(i + 1) * 100}
            </div>
          ))}
        </div>
      </section>
      <section>
        <Typography variant='body1'>Color States</Typography>
        <div>
          <div>
            <Button color='primary'>Primary</Button>
            <Checkbox color='primary' onChange={NOOP} checked />
            <Radio color='primary' checked />
          </div>
          <div>
            <Button color='secondary'>Secondary</Button>
            <Checkbox color='secondary' onChange={NOOP} checked />
            <Radio color='secondary' checked />
          </div>
          <div>
            <Button color='warning'>Warning</Button>
            <Checkbox color='warning' onChange={NOOP} checked />
            <Radio color='warning' checked />
          </div>
          <div>
            <Button color='error'>Error</Button>
            <Checkbox color='error' onChange={NOOP} checked />
            <Radio color='error' checked />
          </div>
          <div>
            <Button color='orange'>Orange</Button>
            <Checkbox color='orange' onChange={NOOP} checked />
            <Radio color='orange' checked />
          </div>
          <div>
            <Button color='mint'>Mint</Button>
            <Checkbox color='mint' onChange={NOOP} checked />
            <Radio color='mint' checked />
          </div>
          <div>
            <Button color='cert'>Cert</Button>
            <Checkbox color='cert' onChange={NOOP} checked />
            <Radio color='cert' checked />
          </div>
          <div>
            <Button color='greyish'>Greyish</Button>
            <Checkbox color='greyish' onChange={NOOP} checked />
            <Radio color='greyish' checked />
          </div>
        </div>
      </section>
      <section style={{ display: 'flex', gap: '8px' }}>
        <div>
          <Alert variant='outlined' severity='success'>
            Success
          </Alert>
        </div>
        <div>
          <Alert variant='outlined' severity='info'>
            Info
          </Alert>
        </div>
        <div>
          <Alert variant='outlined' severity='warning'>
            Warning
          </Alert>
        </div>
        <div>
          <Alert variant='outlined' severity='error'>
            Error
          </Alert>
        </div>
        <div>
          <Alert variant='outlined' severity='info' color='orange'>
            Info Orange
          </Alert>
        </div>
        <div>
          <Alert variant='outlined' severity='info' color='mint'>
            Info Mint
          </Alert>
        </div>
        <div>
          <Alert variant='outlined' severity='info' color='cert'>
            Info Cert
          </Alert>
        </div>
        <div>
          <Alert variant='outlined' severity='info' color='greyish'>
            Info Greyish
          </Alert>
        </div>
      </section>
      <section>
        <Typography variant='body1'>Augmented Colors - (light? main dark?)</Typography>
        <div>
          <div className='square mint'>Mint</div>
          <div className='square lime'>Lime</div>
          <div className='square aqua'>Aqua</div>
          <div className='square orange'>Orange</div>
          <div className='square orange dark'>Orange</div>
          <div className='square pink'>Pink</div>
          <div className='square mitre'>Mitre Red</div>
          <div className='square cert'>Certified</div>
        </div>
      </section>
    </Container>
  );
}
