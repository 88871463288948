import React from 'react';

// import { useHistory } from 'react-router-dom';
import { useAsync } from 'storage';

import { Guid } from 'types/common';

import { getCollection } from './Collection.api';
import { COLLECTION_SHELL } from './Collection.const';
import { Collection, CollectionAsync } from './Collection.type';

export default function useCollection(guid: Guid): CollectionAsync {
  // const { location } = useHistory();
  const { data, run, status, error, setData } = useAsync<Collection>(COLLECTION_SHELL);

  // router state isn't working properly for collections. remove for now
  // const stateGuid = (location.state as { item: Collection })?.item?.guid;
  // const stateData = React.useMemo(() => {
  //   if (stateGuid) return (location.state as { item: Collection }).item;
  //   else return COLLECTION_SHELL;
  // }, [stateGuid]); // eslint-disable-line react-hooks/exhaustive-deps

  const refresh = React.useCallback(() => {
    if (guid) run(getCollection(guid));
  }, [guid, run]);

  React.useEffect(() => {
    // if (stateData?.guid) setData(stateData);
    if (guid && !data.guid) refresh();
  }, [data.guid, guid, refresh]);

  return {
    collection: error?.response?.status === 402 ? error.response.data : data,
    isPreview: error?.response?.status === 402,
    status,
    error,
    refresh,
    setData
  };
}
