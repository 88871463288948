import { AnalyticForm, KeyValueRule, SigmaModifier, SectionForm } from 'types/analytic';

import * as Token from '../Token';
import { Condition, Modifier } from '../const';

export default class Lexer {
  private token: Token.Model[];

  constructor(private structure: AnalyticForm) {
    this.token = [];
  }

  run(): Lexer {
    if (this.structure && this.structure.detection && this.structure.detection.sections) {
      this.structure.detection.sections.forEach(this.unpackSection.bind(this));
    }

    return this;
  }

  unpackSection({ kind, name, rules, values }: SectionForm): void {
    switch (kind) {
      case 'keyvalue':
        this.unpackNamedProperty(name, rules);
        break;
      case 'strings':
        this.unpackGlobal(name, values);
        break;
    }
  }

  unpackNamedProperty(name: string, rules: KeyValueRule[]): void {
    if (!name) return;

    rules.forEach(r => {
      const builder = new Token.Builder()
        .withSection(name)
        .withInclusion(this.structure.detection.condition.includes(name))
        .withProperty(r.field)
        .withComparison(r.modifier)
        .withModifier(r.values.length === 1 ? Modifier.exact : r.all === 'true' ? Modifier.all : Modifier.any)
        .withCondition(Condition._)
        .withCriterion(r.values);

      this.token.push(builder.token);
    });
  }

  unpackGlobal(name: string, values: string[]): void {
    if (values && values.length === 1 && !values[0]) return;

    const builder = new Token.Builder()
      .withSection(name)
      .withInclusion(this.structure.detection.condition.includes(name))
      .withComparison(SigmaModifier.Contains)
      .withModifier(Modifier.full)
      .withCriterion(values)
      .withCondition(Condition._);

    this.token.push(builder.token);
  }

  get result(): Token.Model[] {
    return this.token;
  }
}
