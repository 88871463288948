import React from 'react';

import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

import Icon from 'snap-ui/Icon';
import TextField from 'snap-ui/TextField';
import { styled } from 'snap-ui/util';

type TableToolbarProps = {
  children?: React.ReactChild;
  className?: string;
  onSearchChange(searchTerm: string): void;
  placeholder?: string;
};

export const Toolbar = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${p => p.theme.spacing(2)};
  gap: ${p => p.theme.spacing(2)};

  .search-field {
    width: 350px;
  }

  .buttons {
    display: flex;
    gap: ${p => p.theme.spacing(2)};
  }
`;

export default function TableToolbar({
  children,
  className,
  onSearchChange,
  placeholder
}: TableToolbarProps): JSX.Element {
  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>): void {
    onSearchChange(event.target.value);
  }

  return (
    <Toolbar className={classNames('TableToolBar', { className })}>
      <TextField
        className='search-field'
        onChange={handleSearchChange}
        placeholder={placeholder || 'Search'}
        startAdornment={<Icon icon={faSearch} />}
      />
      {children}
    </Toolbar>
  );
}
