import React from 'react';

import omit from 'lodash/omit';
import zod from 'zod';

import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { useAuth } from 'provider';

import { BASProductName, BASProductNameKey } from 'types/bas';
import { ArtifactType } from 'types/common';
import { Ops, Query } from 'types/filter';

import { CheckboxControl } from '../GlobalFilter.style';
import { FilterConfig } from '../GlobalFilter.type';
import { addOrRemoveValue } from '../GlobalFilter.util';

type BASCompatibilityKeys = {
  basProduct: BASProductNameKey[];
};

type BASCompatibilityFilterProps = {
  onChange(values: BASCompatibilityKeys): void;
  values: BASCompatibilityKeys;
};

function BASCompatibilityFilter({ onChange, values }: BASCompatibilityFilterProps): JSX.Element {
  const { isSubscriber } = useAuth();

  const options: { label: BASProductName; value: BASProductNameKey }[] = Object.entries(
    omit(BASProductName, 'AtomicRedTeam')
  ).map(([key, value]) => ({
    label: value,
    value: key as BASProductNameKey
  }));

  function handleChange(e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    const v = e.currentTarget.value as BASProductNameKey;
    const basProduct = addOrRemoveValue(checked, values.basProduct, v);
    return onChange({ basProduct });
  }

  return (
    <CheckboxControl disabled={!isSubscriber}>
      <FormLabel id='bas-toggle-button-group-label' className='title-tooltip'>
        By Attack Simulation Compatibility
        {!isSubscriber && (
          <Tooltip title='Attack Simulations are only available to subscribers' placement='right' wrap arrow>
            <Icon.Info />
          </Tooltip>
        )}
      </FormLabel>
      <FormGroup>
        {options.map(({ value, label }) => (
          <FormControlLabel
            key={value}
            control={
              <Checkbox
                onChange={handleChange}
                value={value}
                checked={values.basProduct.includes(value as BASProductNameKey)}
              />
            }
            label={label}
          />
        ))}
      </FormGroup>
    </CheckboxControl>
  );
}

function toQuery(values: BASCompatibilityKeys): Query {
  if (!values.basProduct?.length) return;

  const basProduct = [...values.basProduct];

  if (basProduct.includes('SnapAttack') && !basProduct.includes('AtomicRedTeam')) {
    basProduct.push('AtomicRedTeam');
  }

  return {
    field: 'bas.product_name',
    op: Ops.in,
    value: basProduct
  };
}

const fromQuery = zod.union([
  zod
    .object({
      field: zod.union([
        zod.literal('bas.product_name'),
        zod.literal('bas.session.bas_script.product_name'),
        zod.literal('bas_script.product_name')
      ]),
      op: zod.literal(Ops.in),
      value: zod.array(zod.string())
    })
    .transform(query => {
      const basProduct = (query.value as BASProductNameKey[]).map(b => (b === 'AtomicRedTeam' ? 'SnapAttack' : b));
      return { basProduct: [...new Set(basProduct)] };
    }),
  zod
    .object({
      field: zod.union([zod.literal('analytic.bas.has_bas'), zod.literal('bas.has_bas'), zod.literal('has_bas')])
    })
    .transform(() => ({
      basProduct: Object.keys(omit(BASProductName, 'AtomicRedTeam')) as BASProductNameKey[]
    }))
]);

const BASCompatibilityFilterConfig: FilterConfig<BASCompatibilityKeys> = {
  defaults: { default: () => ({ basProduct: [] }) },
  supportedTopics: [ArtifactType.Analytic, ArtifactType.Session],
  component: BASCompatibilityFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default BASCompatibilityFilterConfig;
