import React from 'react';

type ProviderProps = React.PropsWithChildren<Record<never, never>>;

export function createSimpleProvider<V>(
  displayName: string,
  useValueHook: () => V
): [React.ComponentType<ProviderProps>, () => V] {
  const Context = React.createContext<V>(undefined);
  Context.displayName = displayName;

  function Provider({ children }: ProviderProps): JSX.Element {
    const value = useValueHook();
    return <Context.Provider value={value}>{children}</Context.Provider>;
  }

  function useContext(): V {
    const value = React.useContext(Context);
    if (!value) throw new Error(`context hook used outside of ${Context.displayName}`);
    return value;
  }

  return [Provider, useContext];
}
