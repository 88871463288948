export enum Path {
  // Public
  Public = '/',
  Login = '/login',
  LoginSuccess = '/loginsuccess',
  Redirect = '/redirect',
  Logout = '/logout',
  Register = '/register',
  ForgotPassword = '/password/forgot',
  ResetPassword = '/password/reset',
  Multifactor = '/multifactor',
  MultifactorConfiguration = '/configure-multifactor',
  Tutorial = '/tutorial',
  // Dashboards
  Home = '/',
  ContentDashboard = '/dashboard/content',
  NistDashboard = '/dashboard/nist',
  DetectionDashboard = '/dashboard/detections',
  DetectionHealth = '/dashboard/detection-health',
  SocDashboard = '/dashboard/soc',
  UserActivityDashboard = '/dashboard/user-activity',
  MitreCoverageDashboard = '/dashboard/attack-coverage',
  ProfileCoverageDashboard = '/dashboard/profile-coverage',
  Leaderboard = '/training/leaderboard',
  Scoreboard = '/training/scoreboard',
  // Collections
  Collection = '/collection',
  CollectionAttach = '/collection/attach',
  CollectionCreate = '/collection/create',
  CollectionEdit = '/collection/edit',
  CollectionReset = '/collection/create_reset',
  // IDE
  IDE = '/ide',
  IDEReset = '/ide/new',
  IDELogPicker = '/ide/logs', // deprecated?
  // IOC Parser
  IOC = '/ioc-hunter',
  // Detections (formerly Analytics)
  Detection = '/detection',
  ConfidenceTailoring = '/detection/confidence-tailoring',
  AdHocQuery = '/ad-hoc-query',
  // Intelligence
  Intelligence = '/intelligence',
  IntelligenceCreate = '/intelligence/create',
  IntelligenceEdit = '/intelligence/edit',
  // Threats (formerly Attack Sessions)
  Threat = '/threat',
  ThreatEdit = '/threat/edit',
  ThreatCreate = '/threat/create',
  ThreatExecute = '/threat/execute',
  ThreatSave = '/threat/save',
  ThreatUpload = '/threat/upload',
  ThreatUploadReset = '/threat/upload/new',
  // Attack Simulations
  AttackScript = '/attack',
  AttackScriptCreate = '/attack/create',
  AttackScriptEdit = '/attack/edit',
  BASJob = '/attack_simulation',
  // Hunts
  Hunt = '/hunt',
  HuntScheduled = '/hunt/scheduled',
  HuntDetail = '/hunt/:guid',
  HuntWorkbench = '/hunt/:guid/workbench',
  // Settings
  Setting = '/settings',
  Agents = '/settings/agents',
  APIKeys = '/settings/apikeys',
  Authentication = '/settings/authentication',
  Impersonate = '/settings/impersonate',
  LanguageConfig = '/settings/language',
  Integrations = '/settings/integrations',
  Preferences = '/settings/preferences',
  OrganizationManagement = '/settings/organization-management',
  OrganizationProfile = '/settings/organization-profile',
  UserManagement = '/settings/user-management',
  Meta = '/settings/metadata',
  Tasks = '/settings/tasks',
  ThreatProfile = '/settings/threat-profile',
  // Misc
  Feed = '/feed',
  FeedReset = '/reset/feed',
  Matrix = '/matrix',
  ProcessGraph = '/processGraph',
  // Deprecated
  DeprecatedAnalytic = '/analytic',
  DeprecatedIntelligence = '/intel',
  DeprecatedSession = '/session',
  DeprecatedValidation = '/validation'
}

export default Path;
