import React, { ReactElement } from 'react';

import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useDrop } from 'react-dnd';

import { styled } from 'snap-ui/util';

const TargetRoot = styled('div')`
  .pp-delete-icon:after {
    font-family: 'Icons';
    content: '\f00d';
    margin-left: ${p => p.theme.spacing(2)};
  }
`;

type TargetProps = {
  accept: string[];
  onDrop: (item: any) => void;
  droppedItem: string;
  isOperand: boolean;
  onClick?: () => void;
  disabled?: boolean;
};

function Target({ accept, onDrop, droppedItem, isOperand, onClick, disabled }: TargetProps): ReactElement {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const hasValue = !isEmpty(droppedItem);
  const isActive = isOver && canDrop && !hasValue;

  let text = '';
  if (isActive) {
    text = 'Drop Here';
  } else if (hasValue) {
    text = droppedItem;
  }

  return (
    <TargetRoot
      ref={drop}
      className={classNames('drag-target', {
        'pp-delete-icon': hasValue && !disabled,
        'operand-box': hasValue && isOperand,
        'section-box': hasValue && !isOperand,
        'empty-target': !hasValue,
        'droppable-target': canDrop
      })}
      onClick={disabled ? null : onClick}
    >
      {text}
    </TargetRoot>
  );
}

export default Target;
