import React from 'react';

import Placeholder from 'snap-ui/Placeholder';
import { styled } from 'snap-ui/util';

const PlaceholderContainer = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(3)};
`;

export function TagsPlaceholder({ className }: { className?: string }) {
  return (
    <PlaceholderContainer className={className}>
      <Placeholder variant='text' width={40} />
      <Placeholder variant='text' width={40} />
      <Placeholder variant='text' width={40} />
      <Placeholder variant='text' width={40} />
      <Placeholder variant='text' width={40} />
      <Placeholder variant='text' width={40} />
    </PlaceholderContainer>
  );
}

export function PosturePlaceholder({ className, showTotal = true }: { className?: string; showTotal?: boolean }) {
  return (
    <PlaceholderContainer className={className}>
      {showTotal && <Placeholder variant='text' width={120} />}
      <Placeholder variant='text' width={25} />
      <Placeholder variant='text' width={25} />
      <Placeholder variant='text' width={25} />
      <Placeholder variant='text' width={25} />
    </PlaceholderContainer>
  );
}

export function IntelReferencePlaceholder({ className }: { className?: string }) {
  return (
    <PlaceholderContainer className={className}>
      <Placeholder variant='text' width={120} />
      <Placeholder variant='text' width={25} />
    </PlaceholderContainer>
  );
}
