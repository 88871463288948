import React from 'react';

import { faBuildingCircleArrowRight } from '@fortawesome/pro-solid-svg-icons';

import { styled } from 'snap-ui/util';

import { BurgerClicker } from 'module/Layout/Artifact.widgets';

import { MigrateSession } from './MigrateSession';

type Button = Partial<Omit<BurgerClicker, 'onClick'>>;
type Dialog = Omit<MigrateSession, 'isOpen' | 'onClose'>;

const StyledBurgerClicker = styled(BurgerClicker)`
  svg {
    padding-left: 2px;
  }
`;

export function useMigrateSessionInterface(): [(props: Button) => JSX.Element, (props: Dialog) => JSX.Element] {
  const [isOpen, setIsOpen] = React.useState(false);

  const button = (props: Button) => (
    <StyledBurgerClicker
      title='Move Threat'
      aria-label='Move Threat'
      icon={faBuildingCircleArrowRight}
      onClick={() => setIsOpen(true)}
      {...props}
    />
  );
  const dialog = (props: Dialog) => <MigrateSession isOpen={isOpen} onClose={() => setIsOpen(false)} {...props} />;

  return [button, dialog];
}
