import React from 'react';

import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';

import Accordion, { AccordionDetails, AccordionSummary } from 'snap-ui/Accordion';
import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';

import { Discriminator } from 'module/Tag';

import { CategoryContainer } from '../../Landing.style';
import { useLandingCatalog } from '../../LandingProvider';

export default function TargetCapabilities() {
  const { source, type } = useLandingCatalog();

  if (isEmpty(source.capabilities) || type !== Discriminator.Software) return null;
  return (
    <Accordion className='LandingSection-accordion' defaultExpanded={true} elevation={0}>
      <AccordionSummary className='LandingSection-accordionSummary' expandIcon={<Icon icon={faCaretUp} />}>
        <Typography variant='h4' className='category'>
          Capabilities
        </Typography>
      </AccordionSummary>
      <AccordionDetails className='category-title'>
        <CategoryContainer>
          <div className='category-detail'>
            {source.capabilities
              .sort((a, b) => a.localeCompare(b))
              .map(capabilities => (
                <div key={capabilities}>{capabilities}</div>
              ))}
          </div>
        </CategoryContainer>
      </AccordionDetails>
    </Accordion>
  );
}
