import React from 'react';

import { useAuth } from 'provider';

import { checkContentPermission, checkTaskPermission } from 'services/authService';

import { ContentPermission, FunctionalPermission, Permitted } from 'types/auth';

import Block from './Block';
import Grant from './Grant';
import { I } from './util';

export const CanContext = React.createContext({ _can: false });
CanContext.displayName = 'CanContext';

type Props = {
  /**
   * The ContentPermission type e.g. ContentPermission.Edit
   */
  I: I;

  /**
   * The artifact having Permitted type attached to it. e.g. Intel | Session | Analytic
   */
  this?: Permitted;

  children: React.ReactNode;
};

/**
 * <Can /> assumes you are passing in children as an array of <Grant />s and or <Block />s. Otherwise,
 * The child is wrapped in <Grant /> for convenience.
 */
function Can({ I: action, this: subject, children }: Props) {
  const { permission, user } = useAuth();
  const result = React.useMemo(() => {
    const isAllowed = subject
      ? checkContentPermission(subject, action as ContentPermission)
      : checkTaskPermission(permission, action as FunctionalPermission);

    return { _can: user.superuser || isAllowed };
  }, [user.superuser, action, subject, permission]);

  return (
    <CanContext.Provider value={result}>
      {/*TODO: need a better check for children being Can.Grant + Can.Block */}
      {Array.isArray(children) ? children : <Grant>{children}</Grant>}
    </CanContext.Provider>
  );
}

Can.Grant = Grant;
Can.Block = Block;

export default Can;
