import React, { ReactElement } from 'react';

import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { ScriptsAsyncInterface } from 'module/BAS/Launcher/useScripts';
import { MetadataView } from 'module/Metadata';
import PayWall from 'module/PayWall';
import PayWallTextBlur from 'module/PayWall/PayWallTextBlur';
import ReferenceIntel from 'module/Reference/ReferenceIntel';
import { Session } from 'module/Session/Session.type';
import TagList from 'module/Tag/TagList';
import { draftJSToMarkdownRawParser } from 'module/Widgets/Markdown/Markdown.util';
import MarkdownRead from 'module/Widgets/Markdown/MarkdownRead';

import { Status } from 'storage';

import { AnalyticRecommendation } from 'types/analytic';
import { ArtifactType } from 'types/common';

import SessionRecommendations from './SessionRecommendations';

const Container = styled('div')`
  margin-bottom: ${p => p.theme.spacing(7)};

  .row-justified-between {
    display: flex;
    justify-content: space-between;
  }

  .controls {
    display: flex;
    gap: ${p => p.theme.spacing(2)};
    align-items: center;
  }

  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: ${p => p.theme.spacing(6)};
    flex-wrap: wrap;

    .threat-details {
      margin-top: 40px;
      flex: 3 1 520px;
      flex-flow: row nowrap;

      .toolbar {
        justify-content: space-between;
      }

      .visibility {
        font-size: 1rem;
        padding: ${p => p.theme.spacing(2, 3)};
        height: auto;
        border-radius: ${p => p.theme.spacing(6)};
      }

      .header {
        justify-content: center;
      }

      .AuthorMeta {
        color: ${p => p.theme.palette.grey[400]};
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      @media (min-width: 640px) {
        & .header {
          justify-content: flex-start;
        }
      }
    }
  }
`;

type Props = {
  session: Session;
  preview: boolean;
  recommendations?: AnalyticRecommendation[];
  scripts: ScriptsAsyncInterface;
  status?: Status;
  isRedMarkerCreated?: boolean;
};

const SessionDetails = (props: Props): ReactElement => {
  const { session, preview } = props;
  const description = draftJSToMarkdownRawParser(session.description);

  return (
    <Container className='SessionDetails'>
      <div className='header-container'>
        <div className='threat-details'>
          <PayWallTextBlur preview={preview}>
            <Typography variant='h4' gutterBottom>
              Overview
            </Typography>
            <MarkdownRead value={description} restricted />
            <ReferenceIntel data={session} />
            <MetadataView type={ArtifactType.Session} guid={session.guid} />
            <TagList
              actor={session.actor_names}
              attack={session.attack_names}
              software={session.software_names}
              vulnerability={session.vulnerability_names}
            />
          </PayWallTextBlur>
        </div>
        <SessionRecommendations {...props} />
      </div>

      {preview && <PayWall fixed />}
    </Container>
  );
};

export default SessionDetails;
