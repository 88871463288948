import React from 'react';

import CircularProgress from 'snap-ui/CircularProgress';
import { ExternalLink } from 'snap-ui/Link';
import { styled } from 'snap-ui/util';

import { Status } from 'storage';

import { ArtifactType, PageParams } from 'types/common';

import { DisplayKind, FeedAsync } from './Feed.type';

const FeedMessageContainer = styled('div')`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(2)};
  padding: ${p => p.theme.spacing(2, 0)};
`;

type FeedMessageProps = {
  display: DisplayKind;
  feed: Pick<FeedAsync, 'status' | 'total' | 'message'>;
  pageParams: PageParams;
  topic: ArtifactType;
};

export default function FeedMessage({ display, feed, pageParams, topic }: FeedMessageProps): JSX.Element {
  let suffix = 'detections';
  switch (topic) {
    case ArtifactType.Session:
      suffix = 'threats';
      break;
    case ArtifactType.Intel:
      suffix = 'intelligence';
      break;
    case ArtifactType.Collection:
      suffix = 'collections';
      break;
    case ArtifactType.AttackScript:
      suffix = 'attack scripts';
      break;
  }

  if (feed.status === Status.pending || feed.status === Status.idle)
    return (
      <>
        Fetching page {pageParams.page}...
        {display !== DisplayKind.Grid && Status.pending === feed.status && <CircularProgress size={30} />}
      </>
    );

  const start = (pageParams.page - 1) * pageParams.size + 1;
  const end = Math.min(pageParams.page * pageParams.size, feed.total);
  return (
    <FeedMessageContainer>
      {feed.message && (
        <>
          {feed.message}
          <div>
            You may refine your search using the{' '}
            <ExternalLink
              icon
              primary
              href='https://docs.snapattack.com/guide/searching-and-filtering/#advanced-search'
            >
              advanced search options
            </ExternalLink>
            .
          </div>
        </>
      )}
      {feed.total === 0 ? (
        <>No matching {suffix}</>
      ) : (
        <>
          Showing {start.toLocaleString()}-{end.toLocaleString()} of {feed.total.toLocaleString()} matching {suffix}
        </>
      )}
    </FeedMessageContainer>
  );
}
