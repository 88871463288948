import { CollectionDiscriminator } from 'module/Collection/Collection.type';
import { BaseTag } from 'module/Tag';

import { Ident, Tracked } from 'types/common';

export const IOCCompatibleCollections = [CollectionDiscriminator.Actor, CollectionDiscriminator.Software];

export function isIOCCompatibleCollection(type: CollectionDiscriminator) {
  return IOCCompatibleCollections.some(discriminator => discriminator === type);
}

export const IOCType = {
  IPv4: 'IPv4',
  IPv6: 'IPv6',
  URL: 'URL',
  Domain: 'Domain',
  MD5: 'MD5',
  SHA1: 'SHA1',
  SHA256: 'SHA256',
  SHA512: 'SHA512'
} as const;

export type IOCType = keyof typeof IOCType;

export function isIOCType<T extends Tracked & { type?: string }>(item: T) {
  return Object.keys(IOCType).includes(item.type);
}

export const IPS: IOCType[] = [IOCType.IPv4, IOCType.IPv6];
export const HASHES: IOCType[] = [IOCType.MD5, IOCType.SHA1, IOCType.SHA256, IOCType.SHA512];

export type IOCBase = {
  type: IOCType;
  name: string; // fanged value
};

export type IOC = IOCBase & {
  organization_id: Ident;
  value: string; // defanged value
  misp_warning_lists: string[];
  tags?: BaseTag[];
};

export type SupportedIOC = IOC & {
  hasTemplate: boolean;
};

export type IOCArtifact = Tracked &
  Omit<IOC, 'tags'> & {
    score: number;
    first_seen: string;
    last_seen: string;
    external_source: string;
    external_source_id: string;
  };

export type ExtractIOCResponse = {
  text: string; // fully fanged text
  indicators: IOC[];
};

export type TranslateIOCResponse = {
  translations: IOCTranslation[];
  canceled?: boolean;
};

export type IndicatorSearchResponse = {
  indicator_name: string;
  indicator_type: IOCType;
  indicator_score: number;
  landing_name: string;
  landing_type: CollectionDiscriminator;
};

export const IOCTypeCategory = {
  Hash: 'Hash',
  IP_Address: 'IP',
  Domain: 'Domain',
  URL: 'URL'
} as const;

export const iocTypeCategory = Object.values(IOCTypeCategory);
export type IOCTypeCategory = (typeof iocTypeCategory)[number];
export type IOCTypeCategoryChecked = {
  [K in IOCTypeCategory]: boolean;
};

export type CategorizedIOCs = Record<IOCTypeCategory, IOC[]>;

export type IOCTranslation = {
  error?: string;
  raw: string;
  url: string;
};
