import React from 'react';

import Placeholder from 'snap-ui/Placeholder';
import Typography from 'snap-ui/Typography';

import { Guid } from 'types/common';

import CampaignJobList from '../CampaignJobList/CampaignJobList';

type DetailedResultsSectionProps = {
  campaignGuid: Guid;
  isLoading: boolean;
  refreshCampaign(): void;
};

function DetailedResultsSection({ campaignGuid, isLoading, refreshCampaign }: DetailedResultsSectionProps) {
  return isLoading ? (
    <div className='CampaignReport-section'>
      <Placeholder variant='text' height={66} width={250} />
      <Placeholder variant='rectangular' height={308} width='100%' />
    </div>
  ) : (
    <div className='CampaignReport-section no-print'>
      <Typography variant='h2'>Detailed Results</Typography>
      <CampaignJobList campaignGuid={campaignGuid} refreshCampaign={refreshCampaign} />
    </div>
  );
}

export default DetailedResultsSection;
