import React from 'react';

import zod from 'zod';

import { Option } from 'snap-ui/Autocomplete';

import { getCountryBy } from 'constants/countries';

import { useAuth } from 'provider';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';
import { SOURCE_REGIONS } from 'types/mati';

import { AutocompleteOptionContent } from '../GlobalFilter.style';
import { FilterConfig } from '../GlobalFilter.type';
import InclusiveAutocomplete from './InclusiveAutocomplete';

type ActorSourceRegionsKeys = {
  sourceRegion: string[];
  sourceRegionOp: Ops;
};

type ActorSourceRegionFilterProps = {
  onChange(values: Partial<ActorSourceRegionsKeys>): void;
  values: ActorSourceRegionsKeys;
};

const options = SOURCE_REGIONS.slice()
  .sort((a, b) => a.localeCompare(b))
  .map(region => {
    const country = getCountryBy('name', region);
    return {
      label: region,
      value: country?.alpha3,
      content: (
        <AutocompleteOptionContent>
          {country?.flag}
          {region}
        </AutocompleteOptionContent>
      )
    };
  });

function ActorSourceRegionFilter({ onChange, values }: ActorSourceRegionFilterProps): React.ReactElement {
  const { isSubscriber } = useAuth();
  const handleValueChange = (option: Option[]) => {
    const payload = { sourceRegion: option.map(o => o.value) };
    if (option.length === 0) payload['sourceRegionOp'] = undefined;
    onChange(payload);
  };

  const handleOperatorChange = (sourceRegionOp: Ops) => {
    onChange({ sourceRegionOp });
  };

  return (
    <InclusiveAutocomplete
      title='By Actor Source Region'
      name='actor_source_region_dropdown'
      option={options}
      value={values.sourceRegion}
      onValueChange={handleValueChange}
      onOperatorChange={handleOperatorChange}
      operator={values.sourceRegionOp}
      disableInclusionGroup
      disabled={!isSubscriber}
      disabledTooltip='This filter is only available to subscribers'
      tooltip='Filter by threat actor country of origin as attributed by Mandiant'
    />
  );
}
function toQuery(value: ActorSourceRegionsKeys) {
  if (!value.sourceRegion?.length) return;
  return {
    field: 'source_regions',
    op: Ops.any,
    value: value.sourceRegion
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('source_regions'),
    op: zod.literal(Ops.any),
    value: zod.array(zod.string())
  })
  .transform(query => ({
    sourceRegion: query.value,
    sourceRegionOp: query.op
  }));

const ActorSourceRegionFilterConfig: FilterConfig<ActorSourceRegionsKeys> = {
  defaults: { default: () => ({ sourceRegion: [], sourceRegionOp: Ops.any }) },
  supportedTopics: [ArtifactType.Collection],
  component: ActorSourceRegionFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default ActorSourceRegionFilterConfig;
