import React from 'react';

import { useHistory } from 'react-router-dom';

import Path from 'constants/paths';

export default function Reset() {
  const { replace, location } = useHistory();
  React.useLayoutEffect(() => {
    replace({ pathname: Path.IDE, state: location.state });
  }, [replace, location.state]);
  return null;
}
