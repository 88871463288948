import React from 'react';

import { RJSFSchema } from '@rjsf/utils';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';

import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import RjsfFormik from 'module/Form/RjsfFormik';
import TextFieldFormik from 'module/Form/TextFieldFormik';

import { IntegrationFormData, LanguageOption } from '../Integration.type';

type DeploymentFieldProps = {
  languageOptionLists: LanguageOption;
  integrationSchema: RJSFSchema;
};

function DeploymentField({ languageOptionLists, integrationSchema }: DeploymentFieldProps) {
  const { values } = useFormikContext<IntegrationFormData>();
  return (
    <>
      <TextFieldFormik label='Prefix' name='deployment_prefix_template' placeholder='' />
      <AutocompleteFormik
        label='Deployment Language'
        name='deployment_targets'
        options={languageOptionLists.deploy}
        disableUserAdditions
        disabled={isEmpty(languageOptionLists.deploy)}
        multiple
        helperText={isEmpty(languageOptionLists.deploy) ? 'No Deploy languages configured.' : ''}
      />
      <AutocompleteFormik
        label='Hunt Language'
        name='hunt_targets'
        options={languageOptionLists.hunt}
        disableUserAdditions
        disabled={isEmpty(languageOptionLists.hunt) && isEmpty(values.hunt_targets)}
        multiple
        helperText={isEmpty(languageOptionLists.hunt) ? 'No Hunt languages configured.' : ''}
      />
      <RjsfFormik name='extra_information' schema={integrationSchema} preserve />
    </>
  );
}

export default DeploymentField;
