import React from 'react';

import startCase from 'lodash/startCase';

import { GridColDef } from 'snap-ui/DataGrid';
import Typography from 'snap-ui/Typography';

import SnapScoreBadge from 'module/Analytic/core/SnapScoreBadge';
import { CollectionDiscriminator } from 'module/Collection/Collection.type';
import { isLandingType } from 'module/Collection/Collection.util';
import { getAliasesByType, getTagIDs } from 'module/Landing/Landing.util';
import { PlatformIcons } from 'module/Landing/Landing.widgets';
import { getArtifactIcon } from 'module/Layout/Artifact.helper';
import { Discriminator } from 'module/Tag';
import useThreatProfile from 'module/ThreatProfile/useThreatProfile';
import CollectionCounter from 'module/Widgets/CollectionCounter';
import Motivations from 'module/Widgets/MATI/Motivations';
import SourceRegion from 'module/Widgets/MATI/SourceRegion';
import TargetedCountries from 'module/Widgets/MATI/TargetedCountries';
import TargetedIndustries from 'module/Widgets/MATI/TargetedIndustries';
import ThreatPriority from 'module/Widgets/MATI/ThreatPriority';

import { Artifact, ArtifactType } from 'types/common';

import { formatFullTimestamp } from 'utilities/TimeUtils';

import { NameContainer, TagContainer } from './DisplayGrid.helper';
import { threatPriorityComparator } from './DisplayGrid.util';

export function useGridColumnsCollection() {
  const { tagInProfile } = useThreatProfile();
  const GridColumnsCollection: (GridColDef<Artifact> & { hideToStart?: boolean })[] = [
    {
      field: 'type',
      headerName: 'Content Type',
      hideable: true,
      width: 150,
      renderCell: cell => {
        const isLanding = isLandingType(cell.row);
        return (
          <Typography component='div' className='DisplayGrid-columnType'>
            {getArtifactIcon(isLanding ? (cell.value as Discriminator) : ArtifactType.Collection)}
            {isLanding
              ? cell.value === CollectionDiscriminator.Attack
                ? 'ATT&CK'
                : cell.value
              : ArtifactType.Collection}
          </Typography>
        );
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      hideable: false,
      width: 500,
      renderCell: cell => <NameContainer artifact={cell.row} topic={ArtifactType.Collection} value={cell.value} />
    },
    {
      field: 'aliases',
      headerName: 'Aliases',
      hideable: true,
      width: 500,
      valueGetter: params => getAliasesByType(params.row),
      renderCell: cell => {
        const props = { [cell.row.type]: cell.value };
        return <TagContainer name={cell.row.name} {...props} />;
      }
    },
    {
      field: 'reference',
      headerName: 'References',
      sortable: false,
      filterable: false,
      hideable: true,
      width: 325,
      renderCell: cell => <CollectionCounter item={cell.row} />
    },

    // Landing Columns
    {
      field: 'pseudo_profile_projected_score',
      headerName: 'Projected Score',
      sortable: true,
      filterable: true,
      hideable: true,
      width: 150,
      valueGetter: params => {
        const tagIDs = getTagIDs(params.row, params.row.type as Discriminator);
        const tag = tagInProfile(tagIDs);

        return tag?.score_projected;
      },
      renderCell: cell => <SnapScoreBadge score={cell.value} display='grid' />
    },
    {
      field: 'pseudo_threat_priority',
      headerName: 'Threat Priority',
      filterable: true,
      hideable: true,
      width: 160,
      valueGetter: params => {
        const tagIDs = getTagIDs(params.row, params.row.type as Discriminator);
        return tagInProfile(tagIDs);
      },
      sortingOrder: ['asc', 'desc', null],
      sortComparator: threatPriorityComparator,
      renderCell: cell => <ThreatPriority tag={cell.value} external_source={cell.row.external_source} />
    },
    {
      field: 'source_regions',
      headerName: 'Source Region',
      sortable: false,
      filterable: true,
      hideable: true,
      width: 100,
      renderCell: cell => <SourceRegion item={cell.row} />
    },
    {
      field: 'target_regions',
      headerName: 'Targeted Regions',
      sortable: false,
      filterable: true,
      hideable: true,
      width: 250,
      renderCell: cell => <TargetedCountries countries={cell.value} />
    },
    {
      field: 'industries',
      headerName: 'Targeted Industries',
      sortable: false,
      filterable: true,
      hideable: true,
      width: 200,
      renderCell: cell => <TargetedIndustries industries={cell.value} />
    },
    {
      field: 'motivations',
      headerName: 'Motivations',
      sortable: false,
      filterable: true,
      hideable: true,
      width: 175,
      renderCell: cell => <Motivations motivations={cell.value} />
    },
    {
      field: 'last_seen',
      headerName: 'Last Seen Date',
      sortable: true,
      filterable: true,
      hideable: true,
      width: 175,
      renderCell: cell => formatFullTimestamp(cell.value)
    },
    {
      field: 'platforms',
      headerName: 'Operating Systems',
      sortable: true,
      filterable: true,
      hideable: true,
      width: 150,
      renderCell: cell => <PlatformIcons platforms={cell.value} />
    },
    {
      field: 'role',
      headerName: 'Software Role',
      sortable: true,
      filterable: true,
      hideable: true,
      width: 200
    },
    {
      field: 'risk_rating',
      headerName: 'Risk Rating',
      sortable: true,
      filterable: true,
      hideable: true,
      width: 100
    },
    {
      field: 'exploitation_state',
      headerName: 'Exploitation State',
      sortable: true,
      filterable: true,
      hideable: true,
      width: 150,
      renderCell: cell => <>{startCase(cell.value)}</>
    },
    {
      field: 'was_zero_day',
      headerName: 'Exploited as Zero-day',
      sortable: true,
      filterable: true,
      hideable: true,
      width: 150,
      renderCell: cell => <>{cell.value ? 'Yes' : 'No'}</>
    },
    {
      field: 'observed_in_the_wild',
      headerName: 'Exploited in the Wild',
      sortable: true,
      filterable: true,
      hideable: true,
      width: 150,
      renderCell: cell => <>{cell.value ? 'Yes' : 'No'}</>
    },
    {
      field: 'epss_score',
      headerName: 'EPSS Score',
      sortable: true,
      filterable: true,
      hideable: true,
      width: 100,
      renderCell: cell => <>{cell.value}</>
    },
    {
      field: 'cvss_3_base_score',
      headerName: 'CVSS v3.1 Score',
      sortable: true,
      filterable: true,
      hideable: true,
      width: 150,
      renderCell: cell => <>{cell.value}</>
    },
    {
      field: 'cisa_known_exploited_date',
      headerName: 'CISA KEV',
      sortable: true,
      filterable: true,
      hideable: true,
      width: 150,
      renderCell: cell => <>{cell.value ? 'Member' : ''}</>
    }
  ];
  return GridColumnsCollection;
}
