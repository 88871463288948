import React, { ReactElement } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import BackdropLoader, { BackdropLoaderContainer } from 'snap-ui/BackdropLoader';
import Tooltip from 'snap-ui/Tooltip';

export type Props = {
  className?: string;
  content: React.ReactNode;
  footer?: ReactElement | string;
  linkTo?: string | object;
  loading?: boolean;
  title: ReactElement | string;
  tooltip?: ReactElement;
};

function Panel({ className, loading, content, footer, linkTo, title, tooltip }: Props): ReactElement {
  const Component = linkTo
    ? (p: { children: React.ReactNode }) => (
        <Tooltip title={tooltip} placement='top' arrow>
          <Link to={linkTo} {...p} />
        </Tooltip>
      )
    : (p: { children: React.ReactNode }) => <div className='features'>{p.children}</div>;

  return (
    <BackdropLoaderContainer className={classNames('pp-dashboard-panel', className)}>
      <BackdropLoader open={loading} />
      <Component>
        <div className='title'>{title}</div>
        {content}
        {footer ? <div className='footer'>{footer}</div> : ''}
      </Component>
    </BackdropLoaderContainer>
  );
}

export default Panel;
