import { DEPLOYED_DETECTION_TYPES } from 'module/Dashboard/SocDashboard/SocDashboard.type';

import { ArtifactScore } from 'types/common';

export const SEVERITY = [
  '',
  ArtifactScore.LOWEST,
  ArtifactScore.LOW,
  ArtifactScore.MEDIUM,
  ArtifactScore.HIGH,
  ArtifactScore.HIGHEST
];
export const CHIPS = [
  DEPLOYED_DETECTION_TYPES.ALERT,
  DEPLOYED_DETECTION_TYPES.HUNT,
  DEPLOYED_DETECTION_TYPES.MONITOR,
  DEPLOYED_DETECTION_TYPES.LOG
];
export const TITLES = [
  [
    { title: ArtifactScore.HIGHEST, tableCellProps: { colSpan: 1, rowSpan: 1 } },
    {
      title: DEPLOYED_DETECTION_TYPES.MONITOR,
      tableCellProps: { colSpan: 2, rowSpan: 3 },
      confidence: [ArtifactScore.HIGHEST, ArtifactScore.HIGH, ArtifactScore.MEDIUM],
      severity: [ArtifactScore.LOWEST, ArtifactScore.LOW]
    },
    { title: DEPLOYED_DETECTION_TYPES.MONITOR },
    {
      title: DEPLOYED_DETECTION_TYPES.ALERT,
      tableCellProps: { colSpan: 3, rowSpan: 3 },
      confidence: [ArtifactScore.HIGHEST, ArtifactScore.HIGH, ArtifactScore.MEDIUM],
      severity: [ArtifactScore.MEDIUM, ArtifactScore.HIGH, ArtifactScore.HIGHEST]
    },
    { title: DEPLOYED_DETECTION_TYPES.ALERT },
    { title: DEPLOYED_DETECTION_TYPES.ALERT }
  ],
  [
    { title: ArtifactScore.HIGH, tableCellProps: { colSpan: 1, rowSpan: 1 } },
    { title: DEPLOYED_DETECTION_TYPES.MONITOR },
    { title: DEPLOYED_DETECTION_TYPES.MONITOR },
    { title: DEPLOYED_DETECTION_TYPES.ALERT },
    { title: DEPLOYED_DETECTION_TYPES.ALERT },
    { title: DEPLOYED_DETECTION_TYPES.ALERT }
  ],
  [
    { title: ArtifactScore.MEDIUM, tableCellProps: { colSpan: 1, rowSpan: 1 } },
    { title: DEPLOYED_DETECTION_TYPES.MONITOR },
    { title: DEPLOYED_DETECTION_TYPES.MONITOR },
    { title: DEPLOYED_DETECTION_TYPES.ALERT },
    { title: DEPLOYED_DETECTION_TYPES.ALERT },
    { title: DEPLOYED_DETECTION_TYPES.ALERT }
  ],
  [
    { title: ArtifactScore.LOW, tableCellProps: { colSpan: 1, rowSpan: 1 } },
    {
      title: DEPLOYED_DETECTION_TYPES.LOG,
      tableCellProps: { colSpan: 2, rowSpan: 2 },
      confidence: [ArtifactScore.LOW, ArtifactScore.LOWEST],
      severity: [ArtifactScore.LOW, ArtifactScore.LOWEST]
    },
    { title: DEPLOYED_DETECTION_TYPES.LOG },
    {
      title: DEPLOYED_DETECTION_TYPES.HUNT,
      tableCellProps: { colSpan: 3, rowSpan: 2 },
      confidence: [ArtifactScore.LOWEST, ArtifactScore.LOW],
      severity: [ArtifactScore.HIGHEST, ArtifactScore.HIGH, ArtifactScore.MEDIUM]
    },
    { title: DEPLOYED_DETECTION_TYPES.HUNT },
    { title: DEPLOYED_DETECTION_TYPES.HUNT }
  ],
  [
    { title: ArtifactScore.LOWEST, tableCellProps: { colSpan: 1, rowSpan: 1 } },
    { title: DEPLOYED_DETECTION_TYPES.LOG },
    { title: DEPLOYED_DETECTION_TYPES.LOG },
    { title: DEPLOYED_DETECTION_TYPES.HUNT },
    { title: DEPLOYED_DETECTION_TYPES.HUNT },
    { title: DEPLOYED_DETECTION_TYPES.HUNT }
  ]
];
export const TOOL_TIP_TITLES = [
  'Detections at the mid-highest severity and confidence are suitable for alerting as they have few false positives. High or highest confidence detections may even be used for prevention or active response in supported tools.',
  'Detections at the mid-hightest severity but are low confidence are suitable for threat hunting, or present tuning opportunities to reduce false positives and be made into alerts.',
  'Detections that are lower severity but mid-highest confidence should be monitored. These are also suitable for correlating malicious or suspicious activity.',
  'Detections that are both low severity and confidence should be logged. These are also suitable for correlating malicious or suspicious activity.'
];
export const EMPTY_VIEW = [
  [0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0]
];
