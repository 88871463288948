import React from 'react';

import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import useKeyStrokes from './useKeyStrokes';

type Props = {
  url: string;
  hostname: string;
};

const KeystrokeLog = styled('div')`
  padding: 5px;
  overflow-y: scroll;
`;

const SessionKeystrokes = (props: Props) => {
  const { data } = useKeyStrokes(props.url);

  return (
    <KeystrokeLog>
      <Typography variant='h5' className='ui header'>
        {props.hostname}
      </Typography>
      <pre style={{ fontSize: 12, maxHeight: 1000 }}>{data}</pre>
    </KeystrokeLog>
  );
};

export default SessionKeystrokes;
