import { useCallback } from 'react';

import {
  deployAnalytic,
  removeAnalyticDeployment,
  updateWorkflow,
  UpdateWorkflowResponse
} from 'apis/resources/analytic';

import { useAuth } from 'provider';

import { ErrorProps, Status, useAsync } from 'storage';

import { AnalyticDeploymentDetail, AnalyticDeploymentPayload, Workflow, WorkflowState } from 'types/analytic';
import { Guid, Ident } from 'types/common';

export interface WorkflowResponse {
  deploy(payload: AnalyticDeploymentPayload): Promise<AnalyticDeploymentDetail>;
  removeDeployment(deploymentId: Ident, integrationGuid?: Guid): Promise<void>;
  reset(): void;
  status: Status;
  update(state: WorkflowState): Promise<UpdateWorkflowResponse>;
  error: any;
  errorProps: ErrorProps;
}

const ANALYTIC_SHELL = {} as Workflow;

export default function useWorkflow(guid: Guid): WorkflowResponse {
  const { defaultOrgId } = useAuth();
  const { task, reset, status, error, errorProps } = useAsync<Workflow>(ANALYTIC_SHELL);

  const update = useCallback(
    (state: WorkflowState) => {
      return task(updateWorkflow(guid, state, defaultOrgId));
    },
    [guid, task, defaultOrgId]
  );

  const deploy = useCallback(
    (payload: AnalyticDeploymentPayload) => {
      return task(deployAnalytic(guid, payload));
    },
    [guid, task]
  );

  const removeDeployment = useCallback(
    (deploymentId: Ident, integrationGuid?: Guid) => {
      return task(removeAnalyticDeployment(guid, deploymentId, integrationGuid));
    },
    [guid, task]
  );

  return {
    error,
    errorProps,
    reset,
    status,
    update,
    deploy,
    removeDeployment
  };
}
