import React from 'react';

import FormControlLabel from 'snap-ui/FormControlLabel';
import Switch from 'snap-ui/Switch';

import { AlphaFeature, useAlphaSetting } from './';

export function AlphaToggle() {
  const { enableAlpha, setEnableAlpha } = useAlphaSetting(AlphaFeature.AlphaSetting);

  return (
    <FormControlLabel
      control={<Switch checked={enableAlpha} onChange={(e, v) => setEnableAlpha(v)} />}
      label='Enable alpha features'
      labelPlacement='end'
    />
  );
}
