import React from 'react';

import Typography from 'snap-ui/Typography';

import { Discriminator } from 'module/Tag';

import { useLandingCatalog } from '../../LandingProvider';

export default function SoftwareSubheader() {
  const { type, data } = useLandingCatalog();
  if (type !== Discriminator.Software) return null;
  return (
    <div className='Landing-subtype'>
      <Typography component='span' variant='body2'>
        {data.combined.type}
      </Typography>
    </div>
  );
}
