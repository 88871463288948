import React from 'react';

import classnames from 'classnames';

import { StandardChip as Chip, ChipProps } from 'snap-ui/Chip';
import Icon from 'snap-ui/Icon';
import Placeholder from 'snap-ui/Placeholder';
import { styled } from 'snap-ui/util';

import AnalyticGapIcon from 'module/Icons/AnalyticGapIcon';
import UntestedAnalyticIcon from 'module/Icons/UntestedAnalyticIcon';
import UnvalidatedAnalyticIcon from 'module/Icons/UnvalidatedAnalyticIcon';
import ValidatedAnalyticIcon from 'module/Icons/ValidatedAnalyticIcon';

import { AnalyticLogState } from 'types/analytic';

const StandardChip = styled(Chip)`
  /* margin-top: 1px; */

  .MuiChip-label {
    display: flex;
    align-items: center;
    gap: 6px;

    margin-left: 0.25em;
    padding: 0 10px;
  }

  .rank-text {
    font-size: 12px;
    text-align: center;
  }
`;

type ValidationBadgeProps = {
  validated: number;
  unvalidated: number;
  validated_gaps: number;
  className?: string;
};

export default function ValidationBadge({
  validated,
  unvalidated,
  validated_gaps,
  className
}: ValidationBadgeProps): React.ReactElement {
  function getBadgeState() {
    if (validated > 0) return AnalyticLogState.VALIDATED;
    else if (validated_gaps > 0) return AnalyticLogState.VALIDATED_GAPS;
    else if (unvalidated > 0) return AnalyticLogState.UNVALIDATED;
    else if (validated === 0 && validated_gaps === 0 && unvalidated === 0) return AnalyticLogState.UNTESTED;
  }

  const makeBadge = (): React.ReactNode => {
    const state = getBadgeState();

    switch (state) {
      case AnalyticLogState.VALIDATED:
        return (
          <>
            <ValidatedAnalyticIcon />
            <span className='rank-text'>Validated</span>
          </>
        );
      case AnalyticLogState.VALIDATED_GAPS:
        return (
          <>
            <AnalyticGapIcon />
            <span className='rank-text'>Detection&nbsp;Gap</span>
          </>
        );
      case AnalyticLogState.UNVALIDATED:
        return (
          <>
            <UnvalidatedAnalyticIcon />
            <span className='rank-text'>Unvalidated</span>
          </>
        );
      case AnalyticLogState.UNTESTED:
        return (
          <>
            <UntestedAnalyticIcon />
            <span className='rank-text'>Untested</span>
          </>
        );
      default:
        return <Placeholder height={12} width={66} variant='rectangular' />;
    }
  };

  return <StandardChip label={makeBadge()} className={classnames('ValidationBadge', className)} />;
}

export function LockedValidationBadge({ className, ...others }: Omit<ChipProps, 'label'>) {
  return (
    <StandardChip
      label={
        <>
          <Icon.Lock />
          <span className='rank-text'>Locked</span>
        </>
      }
      className={classnames('ValidationBadge', className)}
      {...others}
    />
  );
}
