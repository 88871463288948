import React from 'react';

import MuiButtonGroup from '@mui/material/ButtonGroup';

type Props = {
  className?: string;
  children: React.ReactNode;
  color?: 'primary' | 'secondary' | 'warning';
  disableElevation?: boolean;
};

export default function ButtonGroup({ children, color, ...others }: Props) {
  return (
    <MuiButtonGroup className='ButtonGroup' aria-label='split button' color={color} {...others}>
      {children}
    </MuiButtonGroup>
  );
}
