import React from 'react';

import Button from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { usePushSnack } from 'provider';

import { CeleryState } from 'types/common';

import { Backfill } from '../Session.type';

const show: ('IDLE' | CeleryState)[] = [CeleryState.in_progress, CeleryState.retrying, CeleryState.pending];

export default function BackfillButton({ output, status }: Backfill) {
  const pushSnack = usePushSnack();

  if (!show.includes(status)) return null;

  return (
    <Tooltip title='Detection hits are currently being calculated.' placement='top' arrow>
      <Button
        className='no-wrap'
        color={'warning'}
        variant='outlined'
        startIcon={<Icon.SpinnerProgress />}
        onClick={() =>
          pushSnack(
            `Running ${output?.total || 0} detections. ${Math.floor(output?.percent_complete || 0)}% complete.`,
            'info'
          )
        }
      >
        Detecting {Math.floor(output?.percent_complete || 0)}%
      </Button>
    </Tooltip>
  );
}
