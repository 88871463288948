import React from 'react';

import { faEdit, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import Alert from 'snap-ui/Alert';
import { ConfirmDialog } from 'snap-ui/Dialog';
import { ListItemText } from 'snap-ui/List';

import Path from 'constants/paths';

import { LauncherButton, LauncherDialog, LauncherInterface } from 'module/BAS/Launcher/useLauncherInterface';
import { ScriptsAsyncInterface } from 'module/BAS/Launcher/useScripts';
import Can from 'module/Can';
import {
  AddArtifactToCollectionButton,
  AddArtifactToCollectionDialog,
  AddArtifactToCollectionInterface
} from 'module/Curation';
import { DefensivePosture } from 'module/DefensivePosture';
import { DetectionStatsState } from 'module/Detection/useDetectionStats';
import {
  ArtifactWidgetFooter,
  Author,
  BurgerClicker,
  BurgerLink,
  Menu,
  Title,
  Visibility
} from 'module/Layout/Artifact.widgets';
import { useMigrateSessionInterface } from 'module/MigrateSession';
import BackfillButton from 'module/Session/BackfillButton';

import { ContentPermission } from 'types/auth';
import { ArtifactType } from 'types/common';

import useBackfill from '../BackfillButton/useBackfill';
import { deleteSession } from '../Session.api';
import { decomposeSession } from '../Session.helper';
import { Session } from '../Session.type';
import {
  AddAttackScriptToSessionButton,
  AddAttackScriptToSessionDialog,
  AddAttackScriptToSessionInterface
} from './AddAttackScriptToSessionInterface';

type Props = {
  session?: Session;
  detectionStats: DetectionStatsState;
  preview: boolean;
  onRefresh(): void;
  scripts: ScriptsAsyncInterface;
};

export default function SessionHeader({ session, detectionStats, preview, onRefresh, scripts }: Props) {
  const { replace } = useHistory();
  const backfill = useBackfill(session.guid);
  const { error, summary } = detectionStats;

  const [isConfirming, setIsConfirming] = React.useState(false);

  const [MoveButton, MoveDialog] = useMigrateSessionInterface();

  return (
    <>
      <Visibility visibility={session.visibility} />
      <Title headerText={session.name || 'Captured Threat'} contentType={ArtifactType.Session} />
      <Author resource={session} type='session' />
      <ArtifactWidgetFooter>
        <AddArtifactToCollectionInterface>
          <AddAttackScriptToSessionInterface>
            <LauncherInterface>
              <DefensivePosture
                error={error}
                summary={summary}
                topic={ArtifactType.Session}
                iconsInheritBackgroundColor
              />
              {!preview && (
                <div className='ArtifactWidget-menuWrapper'>
                  <BackfillButton {...backfill} />
                  <Menu>
                    <Can key='edit' I={ContentPermission.Edit} this={session}>
                      <BurgerLink
                        icon={faEdit}
                        title={<ListItemText>Edit Threat</ListItemText>}
                        to={{
                          pathname: `${Path.ThreatEdit}/${session.guid}`,
                          state: decomposeSession(session)
                        }}
                      />
                    </Can>
                    <Can key='migrate' I={ContentPermission.Delete} this={session}>
                      <MoveButton />
                    </Can>
                    <LauncherButton key='launcher' scripts={scripts.data} />
                    <AddAttackScriptToSessionButton key='validation' />
                    <AddArtifactToCollectionButton key='collection' />
                    <Can key='delete' I={ContentPermission.Delete} this={session}>
                      <BurgerClicker title='Delete' onClick={() => setIsConfirming(true)} icon={faTrash} />
                    </Can>
                  </Menu>
                </div>
              )}
              <AddArtifactToCollectionDialog key='collection' type={ArtifactType.Session} guid={session.guid} />
              <AddAttackScriptToSessionDialog key='validation' guid={session.guid} scripts={scripts} />
              <LauncherDialog key='launcher' scripts={scripts.data} />
              <MoveDialog key='migrate' refreshSession={onRefresh} session={session} />
              <ConfirmDialog
                key='delete'
                DialogProps={{
                  open: isConfirming,
                  onClose: () => setIsConfirming(false)
                }}
                ConfirmProps={{
                  onClick: () =>
                    deleteSession(session.guid).then(() => replace(`${Path.Feed}/?topic=${ArtifactType.Session}`)),
                  children: 'Delete'
                }}
                title='Delete forever'
                SecondaryProps={{ children: 'Cancel' }}
              >
                <Alert severity='warning'>Are you sure you want to delete this threat?</Alert>
              </ConfirmDialog>
            </LauncherInterface>
          </AddAttackScriptToSessionInterface>
        </AddArtifactToCollectionInterface>
      </ArtifactWidgetFooter>
    </>
  );
}
