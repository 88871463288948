import React from 'react';

import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { getCountryBy } from 'constants/countries';

import { LandingArtifact } from 'module/Landing/Landing.type';
import { Discriminator } from 'module/Tag';

export const Container = styled('div', { label: 'SourceRegion' })`
  display: flex;
  gap: ${p => p.theme.spacing(2)};
  align-items: center;
  font-size: 1em;
`;

export default function SourceRegion({ item }: { item: LandingArtifact }) {
  if (item.type !== Discriminator.Actor) return null;

  return (
    <Container className='SourceRegion'>
      {(item.source_regions || []).map(source => {
        const country = getCountryBy('alpha3', source);
        return (
          <Tooltip key={country.alpha3} arrow title={country.name} wrap>
            <>{country.flag}</>
          </Tooltip>
        );
      })}
    </Container>
  );
}
