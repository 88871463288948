import React from 'react';

import classnames from 'classnames';

import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import useTitle from 'hooks/useTitle';

import { Engage, Fingerprint } from 'lib/Engagement';

import { useAuth } from 'provider';

import ChangePassword from '../ChangePassword';
import MultiFactorSettings from '../Multifactor/MultifactorSettings';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(5)};
`;

type Props = {
  className?: string;
};

const Authentication = (props: Props): React.ReactElement => {
  useTitle('Change Password | SnapAttack');
  const { user } = useAuth();

  React.useEffect(() => {
    Engage.track(Fingerprint.load(Path.Authentication).withData({ user: user.email, guid: user.guid }));
  }, [user.email, user.guid]);

  return (
    <Container className={classnames('Authentication', props.className)}>
      <ChangePassword />
      <MultiFactorSettings />
    </Container>
  );
};

export default Authentication;
