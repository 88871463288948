import { styled } from 'snap-ui/util';

export const Container = styled('div')`
  margin-top: ${p => p.theme.spacing(4)};
  margin-left: auto;
  margin-right: auto;
  height: 70vh;
  width: 80vw;

  h1 {
    margin-bottom: ${p => p.theme.spacing(4)};
    display: flex;
    align-items: center;
    gap: ${p => p.theme.spacing(5)};
  }

  .MuiAlert-root {
    margin: ${p => p.theme.spacing(3)} 0;
  }

  .MuiTypography-root.MuiTypography-h1 > svg {
    color: ${p => p.theme.palette.warning.main};
  }

  .MuiDataGrid-row {
    background-color: ${p => p.theme.palette.surface.odd};
  }

  .MuiDataGrid-row:nth-of-type(even) {
    background-color: ${p => p.theme.palette.surface.even};
  }

  .MuiDataGrid-row:nth-of-type(even):hover {
    background-color: ${p => p.theme.palette.surface.hover};
  }

  .MuiDataGrid-columnHeaderTitle,
  .MuiDataGrid-columnHeaderTitleContainer {
    font-weight: 700;
  }

  .MuiDataGrid-pinnedColumnHeaders {
    background-color: transparent;
    background-image: none;
  }

  .MuiDataGrid-pinnedColumns {
    background-image: none;
  }

  .MuiDataGrid-footerContainer {
    padding-left: ${p => p.theme.spacing(3)};
    padding-right: calc(${p => p.theme.spacing(3)} + 8px); /* add on the button padding  */
  }
`;
