import { snapattack } from 'apis';

import { Guid } from 'types/common';

import { ApiKey, CreateApiKeyPayload, CreatedApiKey } from './ApiKeys.type';

const prefix = '/auth';

export function getApiKeys(): Promise<ApiKey[]> {
  return snapattack.get(`${prefix}/api_key/`).then(r => r.data);
}

export function postApiKey(key: CreateApiKeyPayload): Promise<CreatedApiKey> {
  return snapattack.post(`${prefix}/api_key/`, key).then(r => r.data);
}

export function deleteApiKey(keyGuid: Guid): Promise<void> {
  return snapattack.delete(`${prefix}/api_key/${keyGuid}/`).then(r => r.data);
}
