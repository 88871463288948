import React from 'react';

import { useField } from 'formik';

import { CheckableCards, CardProps, Size } from 'snap-ui/Card';

type CheckableCardFormik = {
  className?: string;
  disabled?: boolean;
  name: string;
  options: CardProps[];
  size?: Size;
};

export function CheckableCardRadioFormik({
  className,
  disabled,
  name,
  options,
  size = 'md'
}: CheckableCardFormik): JSX.Element {
  const [field, , helpers] = useField(name);

  function handleChange(value: string): void {
    helpers.setValue(value);
    helpers.setTouched(true, false);
  }

  return (
    <CheckableCards
      className={className}
      disabled={disabled}
      name={name}
      options={options}
      size={size}
      onChange={handleChange}
      value={field.value}
    />
  );
}

export function CheckableCardCheckboxFormik({
  className,
  disabled,
  name,
  options,
  size = 'md'
}: CheckableCardFormik): JSX.Element {
  const [field, , helpers] = useField(name);

  function handleChange(value: string[]): void {
    helpers.setValue(value);
    helpers.setTouched(true, false);
  }

  return (
    <CheckableCards
      className={className}
      disabled={disabled}
      multiple
      name={name}
      options={options}
      size={size}
      onChange={handleChange}
      value={field.value}
    />
  );
}
