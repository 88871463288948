import React from 'react';

import { useHistory } from 'react-router-dom';

import { Engage } from 'lib/Engagement';

import { useAuth, useAuthInterface } from 'provider/Account';

import { useAsync, Status } from 'storage';

import { User } from 'types/auth';
import { Guid, Identity } from 'types/common';

import * as api from './Impersonate.api';

type ImpersonateOptions = {
  catchCb?: (e: any) => void;
  redirectPath?: string;
};

type ImpersonateInterface = {
  availableOrgs: Identity[];
  orgsStatus: Status;
  availableUsers: User[];
  usersStatus: Status;
  impersonate(guid: Guid, options?: ImpersonateOptions): void;
  impersonateOrg(guid: Guid, options?: ImpersonateOptions): void;
  unpersonate(options?: ImpersonateOptions): void;
  isPending: boolean;
};

const ImpersonateContext = React.createContext<ImpersonateInterface>(null);
ImpersonateContext.displayName = 'ImpersonateContext';

export function useImpersonate(): ImpersonateInterface {
  const context = React.useContext(ImpersonateContext);
  if (!context) throw new Error('useImpersonate used outside ImpersonateContext');
  return context;
}

export function ImpersonateProvider({ children }: React.PropsWithChildren<Record<never, never>>): JSX.Element {
  const { fetch } = useAuthInterface();
  const { user } = useAuth();
  const { push } = useHistory();
  const { data: availableOrgs, status: orgsStatus, run: orgsRun } = useAsync<Identity[]>([]);
  const { data: availableUsers, status: usersStatus, run: usersRun } = useAsync<User[]>([]);
  const [isPending, setIsPending] = React.useState(false);

  React.useEffect(() => {
    if (user.id) {
      orgsRun(api.getAvailableOrgs());
    }
  }, [orgsRun, user.id]);

  React.useEffect(() => {
    if (user.id) {
      usersRun(api.getAvailableUsers());
    }
  }, [usersRun, user.id]);

  const handler = (func: () => Promise<any>, options: ImpersonateOptions) => {
    const { catchCb, redirectPath } = options ?? {};

    setIsPending(true);
    func()
      .then(() => {
        localStorage?.clear();
        sessionStorage?.clear();
        Engage.unidentify();
        redirectPath && push(redirectPath);
        fetch();
      })
      .catch(e => {
        catchCb && catchCb(e);
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  const impersonate = async (guid: Guid, options?: ImpersonateOptions) => handler(() => api.impersonate(guid), options);

  const impersonateOrg = async (guid: Guid, options?: ImpersonateOptions) =>
    handler(() => api.impersonateOrg(guid), options);

  const unpersonate = async (options?: ImpersonateOptions) => handler(() => api.unpersonate(), options);

  return (
    <ImpersonateContext.Provider
      value={{
        availableOrgs,
        orgsStatus,
        availableUsers,
        usersStatus,
        impersonate,
        impersonateOrg,
        unpersonate,
        isPending
      }}
    >
      {children}
    </ImpersonateContext.Provider>
  );
}
