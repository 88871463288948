import React from 'react';

import compact from 'lodash/compact';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';

import Alert from 'snap-ui/Alert';
import { DialogProgressContainer, DisplayDialog, FormDialog } from 'snap-ui/Dialog';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import { FieldsLayout } from 'snap-ui/Layout';
import Placeholder from 'snap-ui/Placeholder';

import { CommonEvent, Engage, Fingerprint, Widget } from 'lib/Engagement';

import { postRecommender } from 'module/Collection/Collection.api';
import { CollectionRecommenderType } from 'module/Curation/Curation.type';
import { getSearchPage } from 'module/Feed/Feed.service';
import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import CheckboxFormik from 'module/Form/CheckboxFormik';
import { FormikProps } from 'module/Form/Formik';
import RadioGroupFormik from 'module/Form/RadioGroupFormik';

import { useLanguageContext } from 'provider';

import { Status, useAsync } from 'storage';

import { ArtifactType } from 'types/common';
import { StrictReactNode } from 'types/core';
import { Ops, Query } from 'types/filter';

import { renderValueWithLabel } from 'utilities/TextUtils';

import { DetectionExportForm, ExportEndpointPrefix, ExportForm, MimeType } from './Export.type';
import useExport from './useExport';

type Exporter = {
  isOpen: boolean;
  onClose(): void;
};

type ExporterInner<V extends ExportForm> = Exporter & {
  children?: StrictReactNode;
  count: number;
  countIsLoading?: boolean;
  FormikConfig?: Partial<FormikProps<V>>;
  startExport(values: V): void;
  status: Status;
};

function Exporter<V extends ExportForm>({
  children,
  count,
  countIsLoading,
  FormikConfig,
  isOpen,
  onClose,
  startExport,
  status
}: ExporterInner<V>): JSX.Element {
  const formConfig = {
    initialValues: {
      format: MimeType.csv,
      ...FormikConfig?.initialValues
    },
    onSubmit: startExport,
    ...FormikConfig
  };

  return (
    <>
      <FormDialog
        DialogProps={{ open: isOpen && status === Status.idle, onClose }}
        FormikConfig={formConfig}
        SubmitProps={{ children: 'Export', disabled: !count }}
        title={countIsLoading ? 'Calculating total...' : `Export ${renderValueWithLabel(count, 'item')}`}
      >
        <FieldsLayout>
          <RadioGroupFormik
            label='File Format'
            name='format'
            options={Object.values(MimeType).map(f => ({ label: f.toUpperCase(), value: f }))}
            row
          />
          {children}
        </FieldsLayout>
      </FormDialog>
      <DisplayDialog DialogProps={{ open: isOpen && status === Status.pending, onClose }} title='Export in progress'>
        <Alert severity='info'>We are exporting your data. Please keep this modal open until it&apos;s finished.</Alert>
        <DialogProgressContainer>
          <Placeholder variant='rectangular' height={10} animation='wave' />
        </DialogProgressContainer>
      </DisplayDialog>
      <DisplayDialog DialogProps={{ open: isOpen && status === Status.resolved, onClose }} title='Export complete'>
        <Alert severity='success'>The data export is complete.</Alert>
      </DisplayDialog>
    </>
  );
}

export type BASJobExporter = Exporter & {
  filter: Query;
};

export function BASJobExporter({ filter, ...others }: BASJobExporter): JSX.Element {
  const exportUtils = useExport(others.isOpen, ExportEndpointPrefix.BASJobs, filter);

  function handleStartExport(values: ExportForm) {
    exportUtils.startExport({ format: [values.format], filter });
  }

  return (
    <Exporter
      count={exportUtils.count}
      countIsLoading={exportUtils.countStatus === Status.pending}
      startExport={handleStartExport}
      status={exportUtils.status}
      {...others}
    />
  );
}

export type DetectionExporter = Exporter & {
  filter: Query;
  filterIsLoading?: boolean;
};

export function DetectionExporter({ filter, filterIsLoading, ...others }: DetectionExporter): JSX.Element {
  const { data: translateFormats } = useLanguageContext();
  const exportUtils = useExport(others.isOpen, ExportEndpointPrefix.Detections, filter);

  function handleStartExport(values: DetectionExportForm) {
    Engage.track(
      Fingerprint.of(Widget.Modal).withQualifier('export detections').withCommon(CommonEvent.Submit).withData({
        filter,
        languages: translateFormats
      })
    );
    exportUtils.startExport({
      filter,
      analytic_compilation_target_id: values.analytic_compilation_target_id,
      format: [values.format],
      include_confidence: values.include_additional_fields,
      include_metadata: values.include_additional_fields,
      include_organization: values.include_additional_fields,
      include_severity: values.include_additional_fields,
      include_sigma: false, // if a user wants to export the Sigma, they should select that as the language
      include_tags: values.include_additional_fields,
      include_validation: values.include_additional_fields
    });
  }

  if (!others.isOpen) return null;

  return (
    <Exporter
      count={exportUtils.count}
      countIsLoading={filterIsLoading || exportUtils.countStatus === Status.pending}
      FormikConfig={{
        initialValues: {
          format: MimeType.csv,
          analytic_compilation_target_id: translateFormats[0]?.id,
          include_additional_fields: false
        }
      }}
      startExport={handleStartExport}
      status={exportUtils.status}
      {...others}
    >
      <AutocompleteFormik
        aria-labelledby='language'
        label='Language'
        name='analytic_compilation_target_id'
        options={translateFormats?.map(({ id, name }) => ({
          key: id,
          value: id?.toString(),
          content: name
        }))}
        disableClearable
        disableUserAdditions
      />
      <FormGroup>
        <FormLabel>Options</FormLabel>
        <CheckboxFormik label='Include additional fields' name='include_additional_fields' />
      </FormGroup>
    </Exporter>
  );
}

export function RecommendedDetectionExporter({ filter, ...others }: DetectionExporter): JSX.Element {
  const { data: detectionRecs, run, status } = useAsync<string[]>([]);

  React.useEffect(() => {
    if (others.isOpen) {
      run(
        getSearchPage(ArtifactType.Session, 1, 5000, filter).then(async threatPage => {
          if (isEmpty(threatPage.items)) return [];
          const recommendation = await postRecommender(
            { input_ids: threatPage.items.map(i => i.guid) },
            CollectionRecommenderType.Threat
          );

          return compact(
            concat(recommendation.all_deployment_recommendations, recommendation.all_hunt_recommendations)
          ).map(r => r.guid);
        })
      );
    }
  }, [filter, others.isOpen, run]);

  return (
    <DetectionExporter
      filter={{ field: 'guid', op: Ops.in, value: detectionRecs }}
      filterIsLoading={status === Status.pending}
      {...others}
    />
  );
}
