import React from 'react';

import compact from 'lodash/compact';
import concat from 'lodash/concat';
import { Link } from 'react-router-dom';

import { CancelToken } from 'apis';

import { useFeedAsync } from 'aso/useFeed';
import { useRecommender } from 'aso/useRecommender';

import Path from 'constants/paths';

import useRecommendedAttackScripts from 'module/AttackScript/useRecommendedAttackScripts';
import { canI } from 'module/Can';
import { saveCollection, updateCollectionModifyItemByType } from 'module/Collection/Collection.api';
import { getCollectionList } from 'module/Collection/Collection.service';
import { BulkCollectionField, Collection } from 'module/Collection/Collection.type';
import { useFilterRegistry } from 'module/GlobalFilter';
import AssociateArtifactsModal, { Message } from 'module/Widgets/AssociateArtifactsModal';

import { useAuth } from 'provider';

import { Status, useAsync } from 'storage';

import { ContentPermission } from 'types/auth';
import { ArtifactType, Guid } from 'types/common';
import { Query } from 'types/filter';

import { buildCurationPayload } from '../Curation.service';
import { CollectionRecommenderType } from '../Curation.type';
import { AddToCollection } from './AddToCollection';
import { BUSY_MESSAGE, IDLE_MESSAGE, NO_SELECTION_MESSAGE } from './AddToCollection.widgets';

export type AddSelectedArtifactsToCollection = AddToCollection & {
  criteria?: Query;
  fromRecommendedDetection?: boolean;
  fromRelatedAttackScripts?: boolean;
  selectionModel: Guid[];
  title?: string;
};

export function AddSelectedArtifactsToCollection(props: AddSelectedArtifactsToCollection) {
  const { criteria, fromRecommendedDetection, fromRelatedAttackScripts, isOpen, type } = props;
  const { user, defaultOrgId } = useAuth();
  const { generateQuery } = useFilterRegistry();
  const [isActing, setIsActing] = React.useState(false);
  const [action, setAction] = React.useState<boolean>();
  const [message, setMessage] = React.useState<Message>(IDLE_MESSAGE);
  const [selectedCollection, setSelectedCollection] = React.useState<Partial<Collection>>();

  const { data: allCollections, status, run: runAllCollections, reset } = useAsync<Collection[]>([]);
  const {
    fetchFeed,
    status: feedStatus,
    items,
    reset: feedReset
  } = useFeedAsync(type, isOpen ? criteria : undefined, {
    page: 1,
    size: 2000
  });
  const {
    getRecommendation,
    data: recommendation,
    status: recommendationStatus,
    recommendationReset
  } = useRecommender();
  const { getRelated, data: related, status: relatedStatus, relatedReset } = useRecommendedAttackScripts();

  const isPending =
    status === Status.pending ||
    feedStatus === Status.pending ||
    recommendationStatus === Status.pending ||
    relatedStatus === Status.pending;

  const refresh = React.useCallback(
    (searchString?: string) => {
      const query = generateQuery(ArtifactType.Collection, {
        query: searchString,
        organizations: [defaultOrgId.toString()]
      });
      if (user.id) {
        runAllCollections(getCollectionList('Static', query), false);
      }
    },
    [generateQuery, user.id, defaultOrgId, runAllCollections]
  );

  React.useEffect(() => {
    const cancelSource = CancelToken.source();
    if (props.isOpen && props.criteria) fetchFeed(cancelSource);
    else cancelSource.cancel();
    return () => cancelSource.cancel();
  }, [fetchFeed, props.criteria, props.isOpen]);

  const baseGuidList = React.useMemo(() => {
    return props.selectionModel?.length > 0 ? props.selectionModel : items?.map(i => i.guid);
  }, [props.selectionModel, items]);

  React.useEffect(() => {
    if (fromRecommendedDetection && isOpen && baseGuidList && baseGuidList.length)
      getRecommendation(baseGuidList, CollectionRecommenderType.Threat);
  }, [baseGuidList, fromRecommendedDetection, getRecommendation, isOpen, props.criteria]);

  React.useEffect(() => {
    if (fromRelatedAttackScripts && isOpen && baseGuidList && baseGuidList.length) getRelated(baseGuidList);
  }, [baseGuidList, fromRelatedAttackScripts, getRelated, isOpen]);

  const guids = React.useMemo(
    () =>
      fromRecommendedDetection
        ? compact(concat(recommendation?.all_deployment_recommendations, recommendation?.all_hunt_recommendations)).map(
            r => r.guid
          )
        : fromRelatedAttackScripts
        ? related.map(r => r.guid)
        : baseGuidList,
    [baseGuidList, fromRecommendedDetection, fromRelatedAttackScripts, recommendation, related]
  );

  React.useEffect(() => {
    if (
      isOpen &&
      guids.length === 0 &&
      !isPending &&
      (recommendationStatus !== Status.idle || relatedStatus !== Status.idle)
    )
      setMessage(NO_SELECTION_MESSAGE);
    else setMessage(IDLE_MESSAGE);
  }, [guids, isOpen, isPending, recommendationStatus, relatedStatus]);

  const handleToggleClick = React.useCallback(
    async (collection: Partial<Collection>, modifier: boolean) => {
      setIsActing(true);
      setMessage(BUSY_MESSAGE);
      setAction(modifier);
      setSelectedCollection(collection);
      let _message: Message = null;

      const updater = async () => {
        try {
          await updateCollectionModifyItemByType(
            collection.guid,
            modifier ? 'add' : 'remove',
            fromRecommendedDetection
              ? 'analytics'
              : fromRelatedAttackScripts
              ? 'bas_scripts'
              : BulkCollectionField[props.type],
            guids
          );
          _message = {
            data: (
              <div key='success'>
                {`Your request to ${modifier ? 'add' : 'remove'} selected items ${modifier ? 'to' : 'from'} `}
                <strong>
                  <Link to={`${Path.Collection}/${collection.guid}`} target='_blank' rel='noopener noreferrer'>
                    {collection.name}
                  </Link>
                </strong>{' '}
                has been queued. Thanks!
              </div>
            ),
            severity: 'success'
          };
        } catch (e) {
          _message = {
            data: (
              <div key='error'>
                {`Oh... Didn't expect that trying to ${modifier ? 'add to' : 'remove from'} `}
                <strong>{collection.name}.</strong> {e.message}
              </div>
            ),
            severity: 'error'
          };
        }
      };

      await updater();
      setIsActing(false);
      setMessage(_message);
    },
    [fromRecommendedDetection, fromRelatedAttackScripts, guids, props.type]
  );

  const onCreate = React.useCallback(
    async (name: string) => {
      setIsActing(true);
      setMessage(BUSY_MESSAGE);
      const payload = buildCurationPayload({
        name,
        description: `${user.name} collection`,
        organization: defaultOrgId.toString(),
        actor_names: [],
        software_names: [],
        vulnerability_names: [],
        attack_names: []
      });
      const tracked = (await saveCollection(payload)) as Partial<Collection>;
      tracked.name = name;
      handleToggleClick(tracked, true);
    },
    [defaultOrgId, handleToggleClick, user.name]
  );

  const handleClose = () => {
    props.onClose();
    setTimeout(() => {
      reset();
      feedReset();
      recommendationReset();
      relatedReset();
      setMessage(IDLE_MESSAGE);
      setSelectedCollection(null);
    }, 500);
  };

  return (
    <AssociateArtifactsModal
      onClose={handleClose}
      onCreate={onCreate}
      open={props.isOpen}
      message={message}
      title={props.title || 'Add selected items to a collection'}
      refresh={refresh}
      isPending={isPending}
      options={allCollections.filter(col => canI(ContentPermission.Edit, col))}
      isActing={isActing}
      action={action}
      selectedItem={selectedCollection}
      handleToggleClick={handleToggleClick}
      path={Path.Collection}
      disabled={guids.length === 0}
      searchedArtifactType={ArtifactType.Collection}
    />
  );
}
