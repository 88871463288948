import React from 'react';

import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';

import Icon from 'snap-ui/Icon';
import { ExternalLink } from 'snap-ui/Link';
import Typography from 'snap-ui/Typography';

import { CategoryContainer, CategorySection } from '../../Landing.style';
import { useLandingCatalog } from '../../LandingProvider';

export default function CWE() {
  const { source } = useLandingCatalog();
  if (!source.cwe_id) return null;

  return (
    <CategorySection>
      <Typography variant='h4'>Common Weakness Enumeration (CWE)</Typography>
      <CategoryContainer className='row'>
        <ExternalLink
          className='cwe-link'
          href={`https://cwe.mitre.org/data/definitions/${source.cwe_id.replace('CWE-', '')}.html`}
          target='_blank'
        >
          <Icon icon={faArrowUpRightFromSquare} className='link' />
          {`${source?.cwe_title || ''} (${source.cwe_id})`}
        </ExternalLink>
      </CategoryContainer>
    </CategorySection>
  );
}
