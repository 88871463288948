import React from 'react';

import { artifactToEndpointFragment } from 'module/Landing/Landing.util';

import { useAsync } from 'storage';

import { getAliasesByDiscriminator } from './Tag.api';
import { Discriminator } from './Tag.type';

export default function useLandingAliasPreview() {
  const { data: aliases, run, status } = useAsync<string[]>([]);

  const getAlias = React.useCallback(
    (id: number, discriminator: Discriminator) => {
      run(getAliasesByDiscriminator(id, artifactToEndpointFragment(discriminator)));
    },
    [run]
  );
  return {
    aliases,
    getAlias,
    status
  };
}
