import { ActionIconButton } from 'snap-ui/Button';
import { GridFooterContainer } from 'snap-ui/DataGrid';
import Icon from 'snap-ui/Icon';
import Paper from 'snap-ui/Paper';
import { styled } from 'snap-ui/util';

export const CustomFooterContainer = styled(GridFooterContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledActionIconButton = styled(ActionIconButton)`
  svg {
    height: 12px;
  }
`;

export const GroupingColumnContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(3)};
  width: 100%;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }

  &.leaf-node {
    margin-left: ${p => p.theme.spacing(5)};
  }
`;

export const GroupingIcon = styled(Icon)`
  color: hsl(0, 0%, 39.2%);
  margin-right: ${p => p.theme.spacing(2)};
`;

export const GroupingListContainer = styled(Paper)`
  padding: ${p => p.theme.spacing(5)};
`;
