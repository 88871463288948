import React from 'react';

import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

import Icon from 'snap-ui/Icon';
import MuiList from 'snap-ui/List';
import MuiMenuItem from 'snap-ui/MenuItem';
import Paper from 'snap-ui/Paper';
import Popper, { PopperProps } from 'snap-ui/Popper';
import { ClickAwayListener, styled } from 'snap-ui/util';

import { StrictReactFragment } from 'types/core';

import { NOOP } from 'utilities/FunctionUtils';

import { MenuOrdinal } from '../Scaffold.type';

const MenuItem = styled(MuiMenuItem)`
  display: flex;
  gap: ${p => p.theme.spacing(2)};

  outline: none;

  &:focus {
    outline: 1px solid ${p => p.theme.palette.primary.main};
  }
`;

type Props = {
  ordinal: MenuOrdinal;
  anchorEl?: PopperProps['anchorEl'];
  children: StrictReactFragment;
  onClick(id: string, element: HTMLElement | HTMLDivElement): void;
  onClose(): void;
  open: boolean;
  title: string | React.ReactElement;
  testId: string;
};

export const List = styled(MuiList)`
  margin-top: ${p => p.theme.spacing(1)};
  padding: ${p => p.theme.spacing(2, 1)};
  border-top: 3px solid ${p => p.theme.palette.primary.main};

  .MuiListItem-root {
    margin: 0;
    padding: 0;
    height: 3rem;
  }
` as unknown as typeof MuiList;

export default function ToolbarItem(props: Props) {
  const handleOnClick = (event: React.MouseEvent<HTMLLIElement>) => {
    const id = event.currentTarget.getAttribute('data-id');
    props.onClick(id, event.currentTarget);
  };

  return (
    <>
      <MenuItem
        aria-owns={`${props.ordinal}-menu-item`}
        data-id={props.ordinal}
        data-testid={props.testId}
        onClick={handleOnClick}
        tabIndex={0}
      >
        {props.title}
        {typeof props.title === 'string' && <Icon icon={faCaretDown} />}
      </MenuItem>
      <Popper disablePortal anchorEl={props.anchorEl} open={props.open} className='ToolbarItem-popper'>
        <ClickAwayListener onClickAway={props.open ? props.onClose : NOOP}>
          <List id={`${props.ordinal}-menu-item`} component={Paper} elevation={0} square>
            {props.children}
          </List>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
