import React from 'react';

import { Autocomplete, Option } from 'snap-ui/Autocomplete';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { BulkOperation } from 'module/Metadata';
import { Editor } from 'module/Metadata';

import { StrictReactNode } from 'types/core';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(2)};

  .title {
    font-size: 1.125rem;
  }
`;

type BulkActionForm = {
  actions: Option[];
  children?: StrictReactNode;
  editor: Editor;
  title: string;
};

export function BulkActionForm(props: BulkActionForm) {
  const { actions, children, editor, title } = props;

  return (
    <Container>
      <Typography className='title' variant='h3'>
        {title}
      </Typography>

      <Autocomplete
        className={`Control Control-Operation`}
        disableClearable
        disableUserAdditions
        elevated
        name=''
        onChange={(operation: BulkOperation) => editor.set(operation, editor.value)}
        options={actions}
        value={actions.find(opt => opt.value === editor.operation) || editor.operation}
      />

      {[BulkOperation.Add, BulkOperation.Set, BulkOperation.Remove].some(op => op === editor.operation) && (
        <>{children}</>
      )}
    </Container>
  );
}
