import React from 'react';

import classnames from 'classnames';

import { Option } from 'snap-ui/Autocomplete';
import { CardContent } from 'snap-ui/Card';
import Tooltip from 'snap-ui/Tooltip';

import { useIDEDetectionValue } from 'module/IDE';

import { FieldOption, SectionForm as SectionFormValues, SectionKind } from 'types/analytic';

import { AbSection } from './Detection.style';
import KeyValueSectionForm from './KeyValueSectionForm';
import SectionHeader from './SectionHeader';
import StringsSectionForm from './StringsSectionForm';

type SectionFormProps = {
  detectionFieldName: 'analyticForm' | 'customization';
  disabled: boolean;
  disabledTitle?: string;
  eventIdOptions: Option[];
  fields: FieldOption[];
  isDupeName: boolean;
  sectionIndex: number;
  ignoreValidation: boolean;
};

const SectionForm = ({
  detectionFieldName,
  disabled,
  disabledTitle,
  eventIdOptions,
  fields,
  isDupeName,
  sectionIndex,
  ignoreValidation
}: SectionFormProps): React.ReactElement => {
  const { removeSection, updateSection, value: detectionValue } = useIDEDetectionValue(detectionFieldName);
  const conditionValue = detectionValue?.condition ?? '';
  const section = detectionValue?.sections?.[sectionIndex];

  function handleChange(newValues: Partial<SectionFormValues>) {
    updateSection(sectionIndex, newValues);
  }

  function handleDeleteSection() {
    removeSection(sectionIndex);
  }

  function getErrorMessage(): string | undefined {
    if (section.name && !conditionValue.includes(section.name)) {
      return `${section.name} is not included in the detection condition.`;
    }
  }

  return (
    <>
      <Tooltip
        arrow
        title={disabled ? disabledTitle ?? 'Select a Log Source and Event Type to get started.' : ''}
        placement='top-end'
        wrap
      >
        <AbSection
          className={classnames('SectionForm', {
            disabled: disabled
          })}
          elevation={6}
          data-testid={`section-${sectionIndex}`}
        >
          <CardContent>
            <SectionHeader
              disabled={disabled}
              isDupeName={isDupeName}
              onChange={handleChange}
              onRemove={handleDeleteSection}
              section={section}
            />
            {section.kind === SectionKind.KeyValue && (
              <KeyValueSectionForm
                disabled={disabled}
                eventIdOptions={eventIdOptions}
                fields={fields}
                onChange={rules => handleChange({ rules })}
                section={section}
                sectionIndex={sectionIndex}
                ignoreValidation={ignoreValidation}
              />
            )}
            {section.kind === SectionKind.Strings && (
              <StringsSectionForm
                disabled={disabled}
                onChange={values => handleChange({ values })}
                section={section}
                sectionIndex={sectionIndex}
              />
            )}
          </CardContent>
        </AbSection>
      </Tooltip>
      {!!getErrorMessage() && (
        <div className='field'>
          <span className='error'>{getErrorMessage()}</span>
        </div>
      )}
    </>
  );
};

export default SectionForm;
