import React from 'react';

import { useAsync } from 'storage';

import { getJobSchemaCatalog } from '../Job/Job.api';
import { JobIntegrationCatalogSchema } from './Integration.type';

export default function useJobSchema() {
  const { data, run, status } = useAsync<JobIntegrationCatalogSchema>({});

  React.useEffect(() => {
    run(getJobSchemaCatalog());
  }, [run]);

  return {
    data,
    status
  };
}
