import React from 'react';

import Button, { ActionIconButtonProps, ButtonProps } from 'snap-ui/Button';
import Tooltip, { TooltipProps } from 'snap-ui/Tooltip';

import { StrictReactNode } from 'types/core';

type Props = {
  children: StrictReactNode;
  className?: string;
  disabled: boolean;
  title?: StrictReactNode;
  disabledTitle?: StrictReactNode;
  buttonComponent?: React.ElementType;
  ButtonProps?:
    | (Omit<ButtonProps, 'children' | 'disabled'> & { ref?: React.ForwardedRef<HTMLButtonElement> })
    | ActionIconButtonProps;
  TooltipProps?: Omit<TooltipProps, 'children' | 'title'>;
};

/**
 * Handles disabled button state with tooltips.
 * Tooltip default with arrow and placement top.
 *
 * @param children - button children
 * @param className - classname
 * @param disabled - button disabled state
 * @param title - tooltip title when button is enabled. Not providing or passing empty string will not render a tip.
 * @param disabledTitle - tooltip title when button is disabled
 * @param ButtonProps - props passed to the Button component
 * @param TooltipProps - props passed to the Tooltip component
 */
export default function DisabledButton({
  children,
  className,
  disabled,
  title = '',
  disabledTitle,
  TooltipProps,
  buttonComponent = Button,
  ButtonProps = {}
}: Props) {
  const { sx, ...otherButton } = ButtonProps;
  return (
    <Tooltip title={disabled ? disabledTitle || '' : title} arrow placement='top' {...TooltipProps} wrap>
      {React.createElement(
        buttonComponent,
        {
          sx: buttonComponent === Button ? { height: '100%', minWidth: '48px', ...sx } : sx,
          className,
          disabled,
          ...otherButton
        },
        children
      )}
    </Tooltip>
  );
}
