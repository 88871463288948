import { z } from 'zod';

import { CollectionCount } from 'module/Curation/Curation.type';
import { Attack, AttackType, BaseTag } from 'module/Tag';
import { SoftwareType } from 'module/Tag/Tag.type';

import { Artifact, Guid, Ident } from 'types/common';
import { Query } from 'types/filter';
import { TargetIndustry } from 'types/mati';

import { artifactToEndpointFragment } from './Landing.util';

export interface LandingArtifact extends Artifact {
  // landing fields
  source_regions?: string[];
  target_regions?: string[];
  motivations?: string[];
  industries?: TargetIndustry[];
  last_seen?: string;
  risk_rating?: string;
  exploitation_state?: string;
  observed_in_the_wild?: boolean;
  was_zero_day?: boolean;

  role?: string;

  cisa_known_exploited_date?: string;
  cvss_3_base_score?: number;
  epss_score?: number;

  // tag ids
  actors?: Ident[];
  attack?: Ident[];
  software?: Ident[];
  vulnerabilities?: Ident[];
}

export interface HyperTag extends BaseTag {
  aliases?: string[];
  analysis?: string;
  actors?: Partial<HyperTag>[];
  attacks?: Partial<HyperTag>[];
  available_mitigation: string[];
  capabilities?: string[];
  cisa_known_exploited_date?: string;
  cisa_known_exploited_due_date?: string;
  cvss_3_base_score?: number;
  cvss_3_temporal_score?: number;
  cvss_3_vector?: string;
  cvss_3_vector_details?: LandingCVSS3Details;
  cvss_2_base_score?: number;
  cvss_2_temporal_score?: number;
  cvss_2_vector?: string;
  cvss_2_vector_details?: LandingCVSS2Details;
  cwe_id?: string;
  cwe_title?: string;
  creation?: string;
  children?: Attack[]; //applies only to MITRE ATT&CK tags
  date_of_disclosure?: string;
  days_to_patch?: number;
  deprecated: boolean;
  description?: string;
  epss_score?: number;
  epss_percentile?: number;
  exploits?: LandingVulnerabilityExploits[];
  exploitation_consequences?: string[];
  exploitation_state?: string;
  external_source?: string;
  external_source_id?: string;
  external_sources?: string[];
  exploitation_vectors?: string[];
  first_publish_date?: string;
  first_seen?: string;
  guid: Guid;
  id: Ident;
  industries?: TargetIndustry[];
  last_modified_date?: string;
  last_seen?: string;
  mitre_id: string;
  motivations?: string[];
  modified?: string;
  observed_in_the_wild?: boolean;
  parents?: Partial<HyperTag>[];
  platforms?: string[];
  redirect?: string;
  revoked: boolean;
  risk_rating?: string;
  role?: string;
  siblings?: Attack[]; //applies only to MITRE ATT&CK tags
  sigma_name: string;
  sigma_names?: string[];
  software?: Partial<HyperTag>[];
  source_regions?: string[];
  target_regions?: string[];
  type?: AttackType | SoftwareType;
  vendor?: string;
  vulnerabilities?: Partial<HyperTag>[];
  vulnerable_cpes?: LandingVendorVulnerability[];
  vendor_fix_references?: VendorFixReference[];
  was_zero_day?: boolean;
}

export type Landing = {
  combined: HyperTag /** consolidated view of all the tags encompassed under the name */;
  items: Partial<HyperTag>[] /** individual tags that make up the composite view */;
  analytic_filter: Query;
  session_filter: Query;
  threat_intelligence_filter: Query;
  collection_filter: Query;
  bas_script_filter: Query;
};

export type LandingStats = CollectionCount & {
  indicators: number;
};

export type LandingStatsCatalog = Record<string, LandingStats>;

export function isLandingStatsType(value: CollectionCount | LandingStats): value is LandingStats {
  return typeof value === 'object' && 'indicators' in value;
}

export type LandingCategoryDetail = {
  name?: string;
  value?: string;
};

export type LandingVendorVulnerability = {
  vendor_name?: string;
  technology_name?: string;
  cpe_title?: string;
};

export type LandingCVSS3Details = {
  attack_vector?: string;
  attack_complexity?: string;
  privileges_required?: string;
  user_interaction?: string;
  scope?: string;
  confidentiality_impact?: string;
  integrity_impact?: string;
  availability_impact?: string;
};
export type LandingCVSS2Details = {
  access_vector?: string;
  confidentiality_impact?: string;
  integrity_impact?: string;
  access_complexity?: string;
  authentication?: string;
  availability_impact?: string;
};

export type LandingVulnerabilityExploits = {
  description?: string;
  name?: string;
  exploit_url?: string;
  file_size?: number;
  md5?: string;
  release_date?: string;
  reliability?: string;
};

export type VendorFixReference = {
  name?: string;
  unique_id?: string;
  url?: string;
};

export type LandingHyperTags = Record<string, Partial<HyperTag>[]>;
export type LandingFragment = ReturnType<typeof artifactToEndpointFragment>;

export const LandingFormSchema = z.object({
  actor_names: z.array(z.string()).optional(),
  alias_names: z.array(z.string()).optional(),
  attack_names: z.array(z.string()).optional(),
  external_source: z.literal('snapattack'),
  name: z.string().nonempty('Name is required'),
  overview: z.string().nonempty('A description is required'),
  software_names: z.array(z.string()).optional(),
  vulnerabilities_names: z.array(z.string()).optional(),
  type: z.nativeEnum(SoftwareType).optional()
  // other tag specific things not enabled yet
});

export type LandingForm = Required<z.infer<typeof LandingFormSchema>>;

export type LandingRegions = { alpha_2: string; alpha_3: string; flag: string; name: string; numeric: string };

export const VulnerabilitiesScore = {
  NONE: 'None',
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High',
  HIGHEST: 'Highest',
  CRITICAL: 'Critical'
} as const;

export const MandiantSeverityStatus = {
  Yes: 'Yes',
  Wide: 'Wide',
  Confirmed: 'Confirmed',
  Available: 'Available',
  Anticipated: 'Anticipated',
  NoKnown: 'No Known'
} as const;

export type MandiantSeverityLevel = (typeof VulnerabilitiesScore)[keyof typeof VulnerabilitiesScore];

export type LandingCreationPayload = {
  aliases: string[];
  attacks: string[];
  description: string;
  external_source: 'snapattack';
  external_source_id: 'string';
  name: string;
  revoking: number;
  sigma_name: string;
  vulnerabilities: string[];
  type?: string;
};

export type ActorCreationPayload = LandingCreationPayload & {
  actors: string[];
  source_regions: string[];
  target_regions: string[];
};

export type SoftwareCreationPayload = LandingCreationPayload & {
  role: string;
  software: string[];
  type: SoftwareType;
};

export type Country = {
  flag: JSX.Element;
  name: string;
  status: string;
  alpha3: string;
  alpha2: string;
  countryCallingCodes: string[];
  currencies: any[] | string[];
  ioc: string;
  languages: any[] | string[];
};
