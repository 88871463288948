import React from 'react';

import { CancelToken } from 'apis';

import { FEED_SKELETON } from 'module/Feed/Feed.const';
import { getSearchPage } from 'module/Feed/Feed.service';
import { Feed } from 'module/Feed/Feed.type';
import { useFilterRegistry } from 'module/GlobalFilter';
import { useMayI } from 'module/May';

import { Status, useAsync } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { Artifact, ArtifactType } from 'types/common';

import { DuckedArtifact } from './Curation.type';

const SIZE = 50;
const SORT = '';

export default function useCompositeSearch(): {
  data: Artifact[];
  isPending: boolean;
  submitQuery(value: string): void;
} {
  const isBasUser = useMayI(FunctionalPermission.BASStableFeatures);
  const { generateQuery } = useFilterRegistry();
  const cancelTokenSourceRef = React.useRef(CancelToken.source());
  const { data: intel, run: intelRun, status: intelStatus, reset: intelReset } = useAsync<Feed>(FEED_SKELETON);
  const { data: session, run: sessionRun, reset: sessionReset, status: sessionStatus } = useAsync<Feed>(FEED_SKELETON);
  const {
    data: analytic,
    run: analyticRun,
    status: analyticStatus,
    reset: analyticReset
  } = useAsync<Feed>(FEED_SKELETON);
  const {
    data: attackScript,
    run: attackScriptRun,
    status: attackScriptStatus,
    reset: attackScriptReset
  } = useAsync<Feed>(FEED_SKELETON);

  const cancelQuery = React.useCallback(() => {
    cancelTokenSourceRef.current.cancel();
    intelReset();
    sessionReset();
    analyticReset();
    attackScriptReset();
  }, [intelReset, sessionReset, analyticReset, attackScriptReset]);

  const submitQuery = React.useCallback(
    (query: string) => {
      cancelQuery();
      const source = CancelToken.source();
      cancelTokenSourceRef.current = source;

      intelRun(
        getSearchPage(ArtifactType.Intel, 1, SIZE, generateQuery(ArtifactType.Intel, { query }), SORT, source),
        true
      );

      sessionRun(
        getSearchPage(ArtifactType.Session, 1, SIZE, generateQuery(ArtifactType.Session, { query }), SORT, source),
        true
      );

      analyticRun(
        getSearchPage(ArtifactType.Analytic, 1, SIZE, generateQuery(ArtifactType.Analytic, { query }), SORT, source),
        true
      );

      if (isBasUser) {
        attackScriptRun(
          getSearchPage(
            ArtifactType.AttackScript,
            1,
            SIZE,
            generateQuery(ArtifactType.AttackScript, { query }),
            SORT,
            source
          ),
          true
        );
      }
    },
    [cancelQuery, intelRun, generateQuery, sessionRun, analyticRun, isBasUser, attackScriptRun]
  );

  const data = React.useMemo(() => {
    return [
      ...(intel.message ? [] : intel.items.map(i => ({ ...i, duck: ArtifactType.Intel }))),
      ...(session.message ? [] : session.items.map(s => ({ ...s, duck: ArtifactType.Session }))),
      ...(analytic.message ? [] : analytic.items.map(a => ({ ...a, duck: ArtifactType.Analytic }))),
      ...(attackScript.message ? [] : attackScript.items.map(a => ({ ...a, duck: ArtifactType.AttackScript })))
    ] as unknown as DuckedArtifact[];
  }, [intel, session, analytic, attackScript]);

  return {
    data,
    isPending: [intelStatus, sessionStatus, analyticStatus, attackScriptStatus].includes(Status.pending),
    submitQuery
  };
}
