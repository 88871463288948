import React, { ReactElement } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSquare, faSquareUp } from '@fortawesome/pro-regular-svg-icons';
import { faBan, faBars, faEdit, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Form as FormikForm, FormikFormProps } from 'formik';
import { Link, LinkProps } from 'react-router-dom';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import Badge from 'snap-ui/Badge';
import Button, { FabProps } from 'snap-ui/Button';
import Chip from 'snap-ui/Chip';
import Divider from 'snap-ui/Divider';
import Icon, { CannedIconProps } from 'snap-ui/Icon';
import { ListItemIcon, ListItemText } from 'snap-ui/List';
import { MenuTrigger } from 'snap-ui/Menu';
import MenuItem, { MenuItemProps } from 'snap-ui/MenuItem';
import Placeholder from 'snap-ui/Placeholder';
import Tooltip, { TooltipProps } from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { STATE_LABEL_COLOR } from 'constants/feed';

import { HamburgerFab } from 'module/Layout/Styled';
import ContentHeader, { ContentHeaderProps } from 'module/Widgets/ContentHeader';
import { IntelReferenceCounts } from 'module/Widgets/ReferenceCounts';

import { ArtifactType, FieldError, Visibility as VisibilityType } from 'types/common';
import { StrictReactNode } from 'types/core';

import { NOOP } from 'utilities/FunctionUtils';

import AuthorMeta, { AuthorMetaProps } from './AuthorMeta';

const VisibilityChip = styled(Chip)`
  font-weight: 400;
  text-transform: uppercase;
`;

const ReferenceCountWrapper = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(5)};
`;

const StyledListItemIcon = styled(ListItemIcon)`
  svg {
    width: 20px;
  }
`;

export const FooterDetail = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: ${p => p.theme.spacing(6, 11)};
  p {
    margin-bottom: 0px;
  }

  .collection-header-detail-container {
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(1)};
    flex-wrap: wrap;

    a.mandiant-link {
      text-decoration: none;
    }
  }
`;

export function Visibility({ className, visibility: v }: { className?: string; visibility: VisibilityType }) {
  if (!v || v === VisibilityType.Published) return null;
  return (
    <VisibilityChip
      className={classNames('ArtifactWidget-visibility', className)}
      label={VisibilityType[v]}
      color={STATE_LABEL_COLOR[v]}
    />
  );
}

type BasicTitleProps = { children: StrictReactNode; className?: string };
export function Title(props: ContentHeaderProps): JSX.Element;
export function Title(props: BasicTitleProps): JSX.Element;
export function Title({ className, ...props }: ContentHeaderProps | BasicTitleProps): JSX.Element {
  const classes = classNames('ArtifactWidget-title', className);
  return 'headerText' in props ? (
    <ContentHeader className={classes} {...props} />
  ) : (
    <div className={classes}>{props.children}</div>
  );
}

export const Author = ({ className, ...props }: AuthorMetaProps) => (
  <AuthorMeta className={classNames('ArtifactWidget-author', className)} {...props} />
);

export function Count({
  className,
  iocs,
  references,
  isPending
}: {
  className?: string;
  iocs: number;
  references: number;
  isPending?: boolean;
}) {
  if (isPending) return <Placeholder variant='rectangular' height={26} width={360} />;
  return (
    <ReferenceCountWrapper className={classNames('ArtifactWidget-count', className)}>
      <IntelReferenceCounts references={references} iocs={iocs} />
    </ReferenceCountWrapper>
  );
}

export function AlertError({ error }: { error: FieldError }) {
  if (!error) return null;
  const message = Array.isArray(error.message) ? JSON.stringify(error.message) : error.message;

  return (
    <Alert severity='error' className='Intel-error'>
      <AlertTitle>Oops!</AlertTitle>
      {message}
    </Alert>
  );
}

export function ArtifactIcon({ type, ...iconProps }: { type: ArtifactType } & CannedIconProps): JSX.Element {
  if (type === ArtifactType.Intel) return <Icon.Intel {...iconProps} />;
  if (type === ArtifactType.Session) return <Icon.Session {...iconProps} />;
  if (type === ArtifactType.Analytic) return <Icon.Analytic {...iconProps} />;
  if (type === ArtifactType.AttackScript) return <Icon.AttackScript {...iconProps} />;
  if (type === ArtifactType.Collection) return <Icon.Collection {...iconProps} />;
  return null;
}

export function Menu({
  children,
  className,
  disabled,
  dirty,
  size
}: {
  children: StrictReactNode | ((params: { toggle(): void }) => React.ReactNode[]);
  className?: string;
  disabled?: boolean;
  dirty?: boolean;
  size?: FabProps['size'];
}): JSX.Element {
  return (
    <MenuTrigger
      trigger={({ ref, toggle }) => (
        <Badge
          variant='dot'
          color='primary'
          invisible={!dirty}
          className={classNames('ArtifactWidget-menu', className)}
        >
          <HamburgerFab
            ref={ref}
            aria-label='Menu opener'
            icon={faBars}
            onClick={toggle}
            disabled={disabled}
            size={size}
          />
        </Badge>
      )}
    >
      {children}
    </MenuTrigger>
  );
}

const MenuItemLinkContainer = styled(MenuItem)<Omit<MenuItemProps, 'children'>>`
  &:hover {
    color: ${p => p.theme.palette.common.white};
  }
` as unknown as typeof MenuItem;

export function BurgerLink({
  onClick = NOOP,
  to = '',
  icon,
  title,
  TooltipProps,
  ...others
}: {
  disabled?: boolean;
  icon: FontAwesomeIconProps['icon'];
  onClick?(): void;
  title: StrictReactNode;
  TooltipProps?: Omit<TooltipProps, 'children'>;
  to?: LinkProps['to'];
  target?: LinkProps['target'];
  rel?: LinkProps['rel'];
}) {
  const item = (
    <MenuItemLinkContainer component={Link} to={to} onClick={onClick} {...others}>
      <StyledListItemIcon>
        <Icon icon={icon} />
      </StyledListItemIcon>
      {title}
    </MenuItemLinkContainer>
  );

  return TooltipProps ? (
    <Tooltip placement='right' arrow wrap {...TooltipProps}>
      {item}
    </Tooltip>
  ) : (
    item
  );
}

export function BurgerCancel({
  disabled,
  onClick,
  to
}: {
  disabled?: boolean;
  onClick?(): void;
  to?: string | { pathname: string; state?: any };
}) {
  if (to) return <BurgerLink title='Cancel' icon={faBan} to={to} />;
  return (
    <MenuItem onClick={onClick} disabled={disabled}>
      <StyledListItemIcon>
        <Icon icon={faBan} />
      </StyledListItemIcon>
      <ListItemText>Cancel</ListItemText>
    </MenuItem>
  );
}

export function BurgerReset({ disabled, onClick }: { disabled?: boolean; onClick(): void }) {
  return (
    <MenuItem onClick={onClick} disabled={disabled}>
      <StyledListItemIcon>
        <Icon icon={faSquare} />
      </StyledListItemIcon>
      <ListItemText>Reset</ListItemText>
    </MenuItem>
  );
}

export function BurgerDelete({ onClick }: { onClick(): void }) {
  return (
    <MenuItem onClick={onClick}>
      <StyledListItemIcon>
        <Icon icon={faTrash} />
      </StyledListItemIcon>
      <ListItemText>Delete</ListItemText>
    </MenuItem>
  );
}

export function BurgerDraft({ disabled, onClick }: { disabled?: boolean; onClick(): void }) {
  return (
    <MenuItem onClick={onClick} disabled={disabled}>
      <StyledListItemIcon>
        <Icon icon={faEdit} />
      </StyledListItemIcon>
      <ListItemText>Save as Draft</ListItemText>
    </MenuItem>
  );
}

export function BurgerPublish({ disabled, onClick }: { disabled?: boolean; onClick(): void }) {
  return (
    <MenuItem key='publish' onClick={onClick} disabled={disabled}>
      <StyledListItemIcon>
        <Icon icon={faSquareUp} />
      </StyledListItemIcon>
      <ListItemText>Publish</ListItemText>
    </MenuItem>
  );
}

export type BurgerClicker = {
  className?: string;
  label?: string;
  disabled?: boolean;
  icon: IconProp | ReactElement;
  onClick?(): void;
  title: StrictReactNode;
  TooltipProps?: Omit<TooltipProps, 'children'>;
};

export function BurgerClicker({ className, label, disabled, icon, onClick, title, TooltipProps }: BurgerClicker) {
  const item = (
    <MenuItem aria-label={label} className={className} onClick={onClick} disabled={disabled}>
      <StyledListItemIcon>{React.isValidElement(icon) ? icon : <Icon icon={icon as IconProp} />}</StyledListItemIcon>
      {title}
    </MenuItem>
  );

  return TooltipProps ? (
    <Tooltip placement='right' arrow wrap {...TooltipProps}>
      {item}
    </Tooltip>
  ) : (
    item
  );
}

export function TagDivider() {
  return <Divider textAlign='left'>Tags</Divider>;
}

export function Form({ className, ...others }: FormikFormProps) {
  return <FormikForm className={classNames('ArtifactWidget-form', className)} {...others} />;
}

export const Publish = styled(Button)`
  // height: 24px;
  &:not(.Mui-disabled) {
    svg {
      color: ${p => p.theme.palette.common.black} !important;
    }
  }
`;

export const ArtifactWidgetFooter = styled(
  ({ children, className, separation }: { children: StrictReactNode; className?: string; separation?: boolean }) => (
    <div className={classNames('ArtifactWidget-footer', { separation }, className)}>{children}</div>
  )
)`
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &.separation {
    justify-content: space-between;
    margin: ${p => p.theme.spacing(3, 0, 3, 0)};
  }
`;

export const ArtifactWidgetMenuWrapper = styled((props: { children?: StrictReactNode; className?: string }) => (
  <div className={classNames('ArtifactWidget-menuWrapper', props.className)}>{props.children}</div>
))`
  display: flex;
  align-self: flex-end;
  gap: ${p => p.theme.spacing(3)};
`;
