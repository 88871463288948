import React from 'react';

import Icon, { saGoogleWorkspace, saMicrosoftOffice365 } from 'snap-ui/Icon';

import { CheckableCardCheckboxFormik } from 'module/Form/CheckableCardFormik';

import ThreatProfileStep from '../ThreatProfileStep';

export default function OfficeSoftware(): JSX.Element {
  return (
    <ThreatProfileStep
      title='What office software do you use?'
      description='Office software suites like Microsoft Office and Google Workspace, while essential for productivity, have also become prime targets for cyber threats due to their widespread usage and integration across organizations. Attackers exploit vulnerabilities in these platforms to deliver malware, execute phishing campaigns, and gain unauthorized access, highlighting the necessity for regular updates and vigilant security practices to mitigate potential risks.'
      content={
        <CheckableCardCheckboxFormik
          name='platforms'
          options={[
            { value: 'Google Workspace', label: 'Google Workspace', icon: <Icon icon={saGoogleWorkspace} size='xl' /> },
            { value: 'Office 365', label: 'Microsoft Office 365', icon: <Icon icon={saMicrosoftOffice365} size='xl' /> }
          ]}
        />
      }
    />
  );
}
