import React from 'react';

import { faHexagonCheck } from '@fortawesome/pro-regular-svg-icons';
import { faCodeCompare } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { Link, useHistory, useLocation } from 'react-router-dom';

import Icon from 'snap-ui/Icon';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from 'snap-ui/List';
import Tooltip from 'snap-ui/Tooltip';

import Path from 'constants/paths';

import { Guid } from 'types/common';

import { getQueryParam } from 'utilities/SearchParam';
import { STANDARD, formatCustomTimestamp } from 'utilities/TimeUtils';

import { VersionNumberTypography } from '../Analytic.style';
import { AnalyticVersion } from '../Analytic.type';
import { useAnalyticCatalog } from '../core/AnalyticProvider';

type AnalyticVersionListItems = {
  id: string;
  guid: Guid;
  title: string;
  versions: AnalyticVersion[];
};

export function AnalyticVersionListItems(props: AnalyticVersionListItems) {
  const { id, guid, title, versions } = props;
  const [{ supplemental }] = useAnalyticCatalog();
  const { push } = useHistory();
  const { pathname, search } = useLocation();
  const deployedEnvironment = getQueryParam(search, 'deployedEnvironment');
  const deployedVersion = supplemental?.deployments?.filter(d =>
    d.integrations.some(i => i.guid === deployedEnvironment)
  );

  function getPathname(versionId: number | string): string {
    return `${Path.Detection}/${guid}/version/${versionId}`;
  }

  function getDeployedPath() {
    if (deployedEnvironment) return `?deployedEnvironment=${deployedEnvironment}`;
    else return '';
  }

  const handleDiffSide = (event: React.MouseEvent<HTMLDivElement>) => {
    const id = event.currentTarget.getAttribute('data-id');
    if (pathname.split('/').pop() === 'diff') push(`${pathname}/${id}${getDeployedPath()}`);
    else push(`${getPathname(id)}/diff${getDeployedPath()}`);
  };

  if (versions?.length <= 0) return null;

  return (
    <>
      <ListSubheader component='div' className='VersionList-subheader' id={id} disableSticky={false} inset>
        {title}
      </ListSubheader>
      {versions.map((version, index) => (
        <ListItem key={version.id} disableGutters>
          <ListItemButton
            component={Link}
            selected={pathname === getPathname(version.id)}
            to={`${getPathname(version.id)}${getDeployedPath()}`}
          >
            <>
              <ListItemText
                secondary={`${version.created_by?.name} - ${formatCustomTimestamp(version.creation, STANDARD)}`}
                primary={
                  <VersionNumberTypography variant='subtitle2' component='span'>
                    Version {versions.length - index}
                  </VersionNumberTypography>
                }
              />
              {deployedVersion.some(d => d.analytic_version_id === version.id) && (
                <Tooltip arrow title='Deployed'>
                  <span>
                    <Icon className='icon' icon={faHexagonCheck} color='success' />
                  </span>
                </Tooltip>
              )}
            </>
          </ListItemButton>
          <Tooltip
            title={
              pathname.includes('diff/')
                ? 'Select left side for comparing'
                : pathname.includes('/diff')
                ? 'Select right side to compare with'
                : 'Select left side for comparing'
            }
            placement='right'
            arrow
          >
            <ListItemButton
              className={classNames('VersionsList-diff', {
                'VersionsList-diff--left': pathname.includes(`version/${version.id}/diff`),
                'VersionsList-diff--right': pathname.includes(`diff/${version.id}`)
              })}
              selected={pathname.includes(`version/${version.id}`) || pathname.includes(`diff/${version.id}`)}
              data-id={version.id}
              onClick={handleDiffSide}
            >
              <ListItemIcon>
                <Icon icon={faCodeCompare} />
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>
        </ListItem>
      ))}
    </>
  );
}
