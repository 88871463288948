import React from 'react';

import isEqual from 'lodash/isEqual';

import { BarChartProps } from 'snap-ui/Charts/BarChart';
import { useTheme } from 'snap-ui/util';

import useQueryString from 'hooks/useQueryString';

import { Discriminator } from 'module/Tag';

import { ChartPanel, StatPanel } from '../Widgets';
import { EXPLANATIONS } from '../const';
import { ProfileStatGrid } from '../style';
import { decimalToPercent, mapAggregationToChartData } from '../util';
import { ProfileCoverageInterface } from './useProfileCoverage';

const SINGULAR = {
  [Discriminator.Actor]: 'Threat Actor',
  [Discriminator.Attack]: 'MITRE ATT&CK',
  [Discriminator.Software]: 'Software',
  [Discriminator.Vulnerability]: 'Vulnerability'
};

const PLURAL = {
  [Discriminator.Actor]: 'Threat Actors',
  [Discriminator.Attack]: 'MITRE ATT&CK',
  [Discriminator.Software]: 'Software',
  [Discriminator.Vulnerability]: 'Vulnerabilities'
};

function Stats({
  aggregatedCoverage,
  isAggregationPending,
  getTagTypeReport,
  isCoverageReportPending
}: ProfileCoverageInterface): JSX.Element {
  const { palette } = useTheme();
  const { update: updateQueryString } = useQueryString(true);

  function handleBarClick(type: Discriminator) {
    return function (data: BarChartProps['data'][number]) {
      const priority = data?.label;
      updateQueryString({ priority, type });
    };
  }

  return (
    <ProfileStatGrid>
      {[Discriminator.Actor, Discriminator.Software, Discriminator.Vulnerability, Discriminator.Attack].map(tagType => {
        const coverage = decimalToPercent(getTagTypeReport(tagType)?.coverage_avg ?? 0);
        return (
          <React.Fragment key={tagType}>
            <StatPanel
              explanation={EXPLANATIONS.COVERAGE}
              isPending={isCoverageReportPending}
              title={PLURAL[tagType]}
              value={coverage}
            />
            <ChartPanel
              data={mapAggregationToChartData(aggregatedCoverage?.[tagType], 'coverage', palette)}
              isPending={isAggregationPending}
              onBarClick={handleBarClick(tagType)}
              title={`${SINGULAR[tagType]} Percent Coverage by Priority Rank`}
            />
          </React.Fragment>
        );
      })}
    </ProfileStatGrid>
  );
}

export default React.memo(Stats, isEqual);
