import React from 'react';

import Paper from 'snap-ui/Paper';
import { styled } from 'snap-ui/util';

import SliderFormik from 'module/Form/SliderFormik';

import ThreatProfileStep from '../ThreatProfileStep';

const Container = styled(Paper, { name: 'SliderContainer' })`
  padding: 60px;
`;

const marks = [
  { value: 0, label: 'New and Emerging' },
  { value: 50, label: 'Balanced' },
  { value: 100, label: 'Established' }
];

const lastMarkValue = marks[marks.length - 1].value;
export default function ThreatPriorities(): JSX.Element {
  return (
    <ThreatProfileStep
      title='What are your threat priorities?'
      description='Threat priorities within the cybersecurity landscape often hinge on distinguishing between new and emergent threats versus established ones. Established threats, with well-documented tactics, techniques, and procedures (TTPs), have a historical track record of attacks and are generally well-understood by the security community. Organizations typically allocate resources and develop defenses against these known threats based on their historical relevance and impact. In contrast, new and emergent threats represent a dynamic and rapidly evolving category. These threats are characterized by novel attack vectors, vulnerabilities, or tactics, and often lack a substantial history of exploitation. As such, they can be particularly challenging to predict and defend against. Prioritizing these threats requires a forward-looking approach, staying updated with the latest threat intelligence, and employing flexible, adaptive security strategies that can respond effectively to emerging risks. Balancing efforts between established and emergent threats is vital to maintain a robust cybersecurity posture.'
      content={
        <Container>
          <SliderFormik
            name='threat_recency_threshold'
            track={false}
            className='VulnerabilitySlider'
            marks={marks}
            min={0}
            max={lastMarkValue}
            valueLabelDisplay='off'
          />
        </Container>
      }
    />
  );
}
