import React from 'react';

import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';

import Icon from 'snap-ui/Icon';
import Link from 'snap-ui/Link';

import Path from 'constants/paths';

import { formatExactDayTimestamp } from 'utilities/TimeUtils';

import { CategoryDetail } from '../../Landing.widgets';
import { useLandingCatalog } from '../../LandingProvider';

/**
 * NOTE: the CISA Collection GUID will only work in production
 */
export default function KnownExploitedDate() {
  const { source } = useLandingCatalog();

  if (!source.cisa_known_exploited_date) return null;
  return (
    <CategoryDetail
      title={
        <Link className='cwe-link' to={`${Path.Collection}/37738ff7-6dcd-40f1-b588-84cfdef42db4`} target='_blank'>
          CISA Known Exploited Vulnerabilities <Icon icon={faArrowUpRightFromSquare} size='xs' className='link' />
        </Link>
      }
      detail={[
        {
          name: 'Date Added to Catalog',
          value: `${formatExactDayTimestamp(source.cisa_known_exploited_date)}`
        },
        {
          name: 'Required Remediation Date',
          value: `${formatExactDayTimestamp(source.cisa_known_exploited_due_date)}`
        }
      ]}
    />
  );
}
