import React from 'react';

import { Route } from 'react-router-dom';

import { AppLoader } from 'snap-ui/BackdropLoader';

import { useAuthInterface } from 'provider';

import { Status } from 'storage';

import ScaffoldApp from './ScaffoldApp';

/**
 * I will render a private route when authenticated
 */
export default function Scaffold({ component: Component, ...rest }: any) {
  const { authStatus } = useAuthInterface();

  switch (authStatus) {
    case Status.resolved:
      return (
        <Route
          {...rest}
          render={routeProps => (
            <ScaffoldApp>
              <Component {...routeProps} />
            </ScaffoldApp>
          )}
        />
      );
    default:
      return <AppLoader />;
  }
}
