import { ActionIconButton } from 'snap-ui/Button';
import { styled } from 'snap-ui/util';

export const SearchButton = styled(ActionIconButton)`
  color: ${p => p.theme.palette.getContrastText(p.theme.palette.grey[200])};
`;

export const Container = styled('div')`
  animation: 1s ease fadein;

  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(4)};

  padding: ${p => p.theme.spacing(0, 5)};

  img {
    width: 100%;
  }

  .omni-search {
    .AutocompleteInput > .MuiInputBase-root {
      border-radius: ${p => p.theme.spacing(3)};
      padding: ${p => p.theme.spacing(2)} !important; // because screw you, MUI.

      background-color: ${p => p.theme.palette.grey[200]};
      color: ${p => p.theme.palette.getContrastText(p.theme.palette.grey[200])};

      &:hover fieldset {
        border: 2px solid ${p => p.theme.palette.primary.main};
      }
    }

    input {
      font-size: 20px;

      &::placeholder {
        opacity: 1;
      }
    }

    fieldset {
      transition: border 1s ease;
    }
  }

  @keyframes fadein {
    from {
      opacity: 0%;
    }
    to {
      opacity: 100%;
    }
  }

  /* Styling autocomplete options */
  div.MuiAutocomplete-popper ul {
    background-color: ${p => p.theme.palette.background.paper};
    color: ${p => p.theme.palette.grey[200]};

    li:hover {
      background-color: ${p => p.theme.palette.grey[900]};
    }
  }
`;
