import React from 'react';

import { StrictReactNode } from 'types/core';

function MarkerIcon({ icon }: { icon: StrictReactNode }) {
  return (
    <div className='pp-marker-icon'>
      <div className='icon-container'>{icon}</div>
    </div>
  );
}

export default MarkerIcon;
