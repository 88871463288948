import { Option } from 'snap-ui/Autocomplete';

import { LogPreviewResult } from 'module/Detection';
import { Integration } from 'module/Integration/Integration.type';
import { IntegrationOption, IntegrationTarget } from 'module/Integration/Integration.type';
import { AdHocQueryPayload, JobGroup, PostJobsInput } from 'module/Job';

import { Status } from 'storage';

import { Language } from 'types/analytic';
import { Organization } from 'types/auth';
import { Guid } from 'types/common';
import { Ident } from 'types/common';

export const MAX_RESULTS_DEFAULT = 500;

export type AdHocQueryJobHistory = {
  jobGuid: Guid;
  payload: AdHocQueryPayload;
  response: JobGroup;
  hits?: LogPreviewResult['count'];
};

export type AdHocQueryStorage = Record<Organization['id'], Record<Integration['guid'], AdHocQueryJobHistory[]>>;

export type AdHocQueryHistoryAsyncBag = {
  getQueryHistory: () => AdHocQueryJobHistory[];
  updateQueryHistoryResponse: (
    jobGuid: AdHocQueryJobHistory['jobGuid'],
    newResponse: AdHocQueryJobHistory['response']
  ) => void;
  updateQueryHistoryHits: (
    jobGroupGuid: AdHocQueryJobHistory['response']['guid'],
    hits: AdHocQueryJobHistory['hits']
  ) => void;
  executeQuery(language: Language['backend_key'], query: string, parameters?: PostJobsInput['parameters']): void;
  status: Status;
};

export type AdHocQueryInterface = AdHocQueryHistoryAsyncBag & {
  handleHistoryClick(queryHistory: AdHocQueryJobHistory): void;
  integrationGuid: Guid;
  setIntegrationGuid(guid: Guid): void;
  selectedLanguage: Ident;
  setSelectedLanguage(id: Ident): void;
  query: string;
  setQuery(query: string): void;
  maxResults: number;
  setMaxResults(max: number): void;
  jobGuid: Guid;
  setJobGuid(guid: Guid): void;
  languageTargets: IntegrationTarget[];
  integrationOptions: IntegrationOption[];
  languageOptions: Option[];
};
