import React from 'react';

import { RouterButton } from 'snap-ui/Button';

import Path from 'constants/paths';

import { useMayI } from 'module/May';

import { FunctionalPermission } from 'types/auth';
import { ArtifactType } from 'types/common';

interface CreateButtonProps {
  type: ArtifactType;
}

export default function CreateButton({ type }: CreateButtonProps): React.ReactElement {
  const canCreateIntel = useMayI(FunctionalPermission.CreateIntel);
  const canCreateThreat = useMayI(FunctionalPermission.CreateSession);
  const canUploadThreat = useMayI(FunctionalPermission.CreateVMSession);
  const canCreateDetection = useMayI(FunctionalPermission.CreateAnalytic);
  const canCreateAttackScript = useMayI(FunctionalPermission.BASStableFeatures);
  const canCreateCollection = useMayI(FunctionalPermission.CreateCollection);

  if (type === ArtifactType.Intel && canCreateIntel) {
    return (
      <RouterButton variant='contained' className='CreateButton' to={Path.IntelligenceCreate}>
        Create Intelligence
      </RouterButton>
    );
  } else if (type === ArtifactType.Session) {
    return (
      <>
        {canCreateThreat && (
          <RouterButton variant='contained' className='CreateButton' to={Path.ThreatCreate}>
            Capture a New Threat
          </RouterButton>
        )}
        {canUploadThreat && (
          <RouterButton variant='contained' className='CreateButton' to={Path.ThreatUploadReset}>
            Upload Existing Threat Capture
          </RouterButton>
        )}
      </>
    );
  } else if (type === ArtifactType.Analytic && canCreateDetection) {
    return (
      <RouterButton variant='contained' className='CreateButton' to={Path.IDEReset}>
        Create a New Detection
      </RouterButton>
    );
  } else if (type === ArtifactType.AttackScript && canCreateAttackScript) {
    return (
      <RouterButton variant='contained' className='CreateButton' to={Path.AttackScriptCreate}>
        Create a New Attack Script
      </RouterButton>
    );
  } else if (type === ArtifactType.Collection && canCreateCollection) {
    return (
      <RouterButton variant='contained' className='CreateButton' to={Path.CollectionReset}>
        Create a New Collection
      </RouterButton>
    );
  }

  return null;
}
