import { styled } from 'snap-ui/util';

import Artifact from 'module/Layout/Artifact';

export const ArtifactStyle = styled(Artifact)`
  .HeroImage-window {
    min-width: fit-content;
    display: grid;
    align-items: center;
    gap: ${p => p.theme.spacing(3)};

    grid-template-columns: 1fr 100px;
    grid-template-rows: 4fr;
    grid-template-areas:
      'type visibility'
      'title title'
      'author author'
      'footer footer';

    .Artifact-type {
      grid-area: type;
      justify-self: start;
      height: 1.25rem;
    }

    .ArtifactWidget-visibility {
      grid-area: visibility;
    }

    .ArtifactWidget-action {
      grid-area: action;
      justify-self: end;
      display: flex;
      gap: ${p => p.theme.spacing(1)};
      align-items: center;
    }

    .ArtifactWidget-title {
      grid-area: title;
    }

    .ArtifactWidget-author {
      grid-area: author;

      .Divider {
        margin: 15px 0;
      }
    }

    .ArtifactWidget-footer {
      justify-content: space-between;
    }

    .ArtifactWidget-menuWrapper {
      display: flex;
      align-items: center;
      gap: ${p => p.theme.spacing(3)};
    }

    .DefensivePosture {
      color: ${p => p.theme.palette.grey[400]};
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .DefensivePosture {
      & > * + * {
        margin-left: ${p => p.theme.spacing(6)};
      }
    }
  }
`;

export const RecommendedHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: row nowrap;
  text-transform: uppercase;

  .MuiTypography-h3 {
    font-size: 1.2rem;
  }

  .MuiTypography-h4 {
    font-weight: normal;
    font-size: 1rem;
  }
`;
