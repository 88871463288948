import React from 'react';

import { faPlay, faStop } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';

import CronForm from 'snap-ui/CronForm';
import { DataGrid, GridColDef, GridFilterModel, GridLogicOperator } from 'snap-ui/DataGrid';
import { MenuTrigger } from 'snap-ui/Menu';
import Switch from 'snap-ui/Switch';
import Tooltip from 'snap-ui/Tooltip';

import Paths from 'constants/paths';

import useTableControls from 'hooks/useTableControls';

import { ApiError } from 'module/ApiError';
import { Integration, JobSchedule } from 'module/Integration/Integration.type';
import { getSpecificJobSchema } from 'module/Integration/Integration.util';
import DeleteTaskButton from 'module/Integration/IntegrationConfidenceTailoring/DeleteTaskButton';
import EditTaskButton from 'module/Integration/IntegrationConfidenceTailoring/EditTaskButton';
import useJobSchema from 'module/Integration/useJobSchema';
import { JobType } from 'module/Job';
import { TaskTableToolbar } from 'module/Job/Job.widgets';
import { BurgerClicker } from 'module/Layout/Artifact.widgets';
import { TimeAgoTimestamp } from 'module/Widgets/TimeAgoTimestamp';

import { useIntegrationCatalog } from 'provider';

import { Status } from 'storage';

import { Guid } from 'types/common';
import { Query, SortDirection } from 'types/filter';

import { NOOP } from 'utilities/FunctionUtils';
import { formatMiniTimestamp } from 'utilities/TimeUtils';

import TablePagination from '../Widgets/TablePagination';
import useScheduleEnableBulk from './useScheduleEnableBulk';

type IntegrationConfidenceJobsTablesProps = {
  jobs: JobSchedule[];
};

function filterFn(query: string, item: JobSchedule): boolean {
  return item?.name.toLowerCase().includes(query?.toLowerCase());
}

function getIntegrationNameByGuid(allIntegrations: Integration[], integrationGuid: Guid) {
  return allIntegrations?.find(i => i.guid === integrationGuid);
}

export default function HuntTaskTable({ jobs }: IntegrationConfidenceJobsTablesProps) {
  const [selectionModel, setSelectionModel] = React.useState<Guid[]>([]);
  const { data: jobSchemaCatalog } = useJobSchema();
  const {
    status: bulkScheduleEnableStatus,
    errorProps,
    optimisticJobs,
    pendingGuids,
    setScheduledHuntStatus
  } = useScheduleEnableBulk(jobs);

  const isBulkStatusLoading = bulkScheduleEnableStatus === Status.pending;

  const {
    integrations: { huntable: allHuntableIntegrations }
  } = useIntegrationCatalog();
  const {
    data: visibleRows,
    setPage,
    page,
    pageTotal
  } = useTableControls(
    optimisticJobs,
    filterFn,
    {
      field: 'name',
      direction: SortDirection.desc
    },
    20
  );

  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
    quickFilterValues: []
  });

  const columns: GridColDef<JobSchedule>[] = [
    {
      field: 'name',
      headerName: 'Hunt',
      flex: 20,
      renderCell: params => (
        <Link to={`${Paths.Hunt}?scheduledHuntId=${params.row.id}`}>{params.row.name || <em>No Name</em>}</Link>
      )
    },
    {
      field: 'cron_trigger',
      headerName: 'Frequency (UTC)',
      flex: 10,
      renderCell: params => <CronForm value={params.row.cron_trigger} readOnly setValue={NOOP} />
    },
    {
      field: 'last_dispatch',
      headerName: 'Last Run',
      renderCell: params =>
        params.row.last_dispatch ? <TimeAgoTimestamp timestamp={params.row.last_dispatch} /> : 'Never Run',
      flex: 8
    },
    {
      field: 'next_dispatch',
      headerName: 'Next Run',
      renderCell: params => formatMiniTimestamp(params.row.next_dispatch),
      flex: 10
    },
    {
      field: 'integration_name',
      headerName: 'Integration',
      renderCell: params => params.row.integration_name,
      flex: 10
    },
    {
      field: 'enabled',
      headerName: 'Enabled',
      flex: 4,
      renderCell: params => (
        <Tooltip arrow title={params.row.enabled ? 'Enabled' : 'Disabled'}>
          <Switch
            checked={params.row.enabled}
            disabled={pendingGuids.includes(params.row.guid) && isBulkStatusLoading}
            onChange={() => setScheduledHuntStatus([params.row.guid], !params.row.enabled)}
          />
        </Tooltip>
      )
    },
    {
      field: 'action',
      headerName: 'Actions',
      renderCell: p => {
        return (
          <>
            <EditTaskButton
              name={p.row.name}
              type={JobType.Hunt}
              title='Edit Scheduled Hunt'
              integration_guid={p.row.integration_guid}
              extra_parameters={p.row?.parameters}
              criteria={p.row?.criteria}
              analytic_filter={p.row.analytic_filter as Query}
              cron_trigger={p.row?.cron_trigger}
              schedule_guid={p.row.guid}
              jobSchema={getSpecificJobSchema(
                jobSchemaCatalog,
                getIntegrationNameByGuid(allHuntableIntegrations, p.row.integration_guid)?.type
              )}
            />
            <DeleteTaskButton name={p.row.name} integration_guid={p.row.integration_guid} schedule_guid={p.row.guid} />
          </>
        );
      },
      minWidth: 100,
      hideSortIcons: true
    }
  ];

  function onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFilterModel(model => ({
      ...model,
      items: [
        {
          id: 1,
          field: 'name',
          operator: 'contains',
          value: event.target.value
        }
      ],
      logicOperator: GridLogicOperator.Or
    }));
  }

  return (
    <>
      {!!errorProps && <ApiError {...errorProps} />}
      <DataGrid
        className='data-grid'
        columns={columns}
        rows={visibleRows}
        getRowId={row => row.guid}
        checkboxSelection
        disableRowSelectionOnClick
        filterModel={filterModel}
        onFilterModelChange={newModel => setFilterModel(newModel)}
        onRowSelectionModelChange={newModel => setSelectionModel(newModel as Guid[])}
        getRowClassName={p => (p.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        getCellClassName={() => 'dataGrid-row cell-space'}
        slots={{
          toolbar: TaskTableToolbar,
          footer: () => <TablePagination changePage={setPage} numPages={pageTotal} page={page} zeroIndex />
        }}
        slotProps={{
          toolbar: {
            onSearchChange: onSearchChange,
            includeExport: false,
            includeByOrg: false,
            menuSlot: (
              <MenuTrigger>
                <BurgerClicker
                  title='Disable Hunt'
                  icon={faStop}
                  disabled={selectionModel.length === 0}
                  onClick={() => setScheduledHuntStatus(selectionModel, false)}
                />
                <BurgerClicker
                  title='Enable Hunt'
                  icon={faPlay}
                  disabled={selectionModel.length === 0}
                  onClick={() => setScheduledHuntStatus(selectionModel, true)}
                />
              </MenuTrigger>
            )
          }
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }]
          },
          filter: {
            filterModel: {
              items: [{ field: 'error', operator: 'isEmpty' }]
            }
          }
        }}
      />
    </>
  );
}
