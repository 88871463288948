import React, { ReactElement } from 'react';

import Slider from 'snap-ui/Slider';
import { styled } from 'snap-ui/util';

import { convertMillisecondsToMMss } from 'utilities/TimeUtils';

type FilterSliderProps = {
  label: string;
  maxValue: number;
  minValue: number;
  value: number[];
  onChange: (event: Event, value: number[]) => void;
};

const FilterSliderContainer = styled('div', { name: 'FilterSlider' })`
  .slider-container {
    padding: ${p => p.theme.spacing(3)};
  }
`;

const FilterSlider = ({ label, maxValue, minValue, value, onChange }: FilterSliderProps): ReactElement => {
  const min = Math.max(minValue, 0);
  const max = Math.max(maxValue, 0);

  const formatLabel = (value: number) => convertMillisecondsToMMss(value - minValue);

  const marks = [
    {
      value: min,
      label: formatLabel(min)
    },
    {
      value: max,
      label: formatLabel(max)
    }
  ];

  return (
    <FilterSliderContainer>
      <label>{label}</label>
      <div className='slider-container'>
        <Slider
          step={1000}
          min={min}
          max={max}
          value={value}
          onChange={onChange}
          valueLabelDisplay='auto'
          getAriaValueText={formatLabel}
          valueLabelFormat={formatLabel}
          marks={marks}
        />
      </div>
    </FilterSliderContainer>
  );
};

export default FilterSlider;
