import React from 'react';

import { useHistory } from 'react-router-dom';
import { z } from 'zod';

import CircularProgress from 'snap-ui/CircularProgress';
import { FormDialog } from 'snap-ui/Dialog';
import { FieldsLayout } from 'snap-ui/Layout';

import { save as putDetection } from 'apis/resources/analytic';

import useOrganizationOptions from 'hooks/useOrganizationOptions';

import { putAttackScripts } from 'module/AttackScript/AttackScript.api';
import { standardFormikBaseProps } from 'module/Form';
import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import { putIntel } from 'module/Intel/Intel.api';
import { SupplementalArtifact } from 'module/Search';

import { useAuth, usePushSnack } from 'provider';

import { Status, useAsync } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { Artifact, ArtifactType, Guid, Ident } from 'types/common';

type MoveOrgProps = {
  isOpen: boolean;
  onClose: () => void;
  type: ArtifactType.Analytic | ArtifactType.Intel | ArtifactType.AttackScript;
  artifact?: Artifact & { raw?: string };
  supplemental?: SupplementalArtifact;
};

function MoveOrgModal({ isOpen, onClose, type, artifact, supplemental }: MoveOrgProps) {
  const options = useOrganizationOptions(FunctionalPermission.CreateSession);
  const { defaultOrgId } = useAuth();
  const { data, run, status } = useAsync<{ guid: Guid }>();
  const pushSnack = usePushSnack();
  const { push } = useHistory();

  const handleMoveOrg = React.useCallback(
    organization_id => {
      const guid = artifact.guid;

      switch (type) {
        case ArtifactType.Analytic: {
          let payload = {};
          if (artifact.is_native) {
            payload = {
              raw: artifact.raw,
              name: artifact.name,
              author: artifact.author,
              organization_id: Number(organization_id),
              source_analytic_compilation_target_id: supplemental.source_analytic_compilation_target_id
            };
          } else {
            payload = {
              raw: artifact.raw,
              organization_id: Number(organization_id)
            };
          }
          run(putDetection(guid, payload));
          break;
        }
        case ArtifactType.AttackScript: {
          const payload = {
            organization_id
          };
          run(putAttackScripts(guid, payload));
          break;
        }
        case ArtifactType.Intel: {
          const payload = {
            organization_id: Number(organization_id)
          };
          run(putIntel(guid, payload));
          break;
        }
      }
    },
    [artifact, run, supplemental, type]
  );

  React.useEffect(() => {
    if (status === Status.resolved) {
      onClose();
      pushSnack('Successfully moved', 'success', 'center', 'bottom', 5000);

      if (type === ArtifactType.AttackScript) {
        push(`/attack/${data?.guid}`);
      }
    }
  }, [status, onClose, pushSnack, push, type, data?.guid]);

  const moveOptions = options.filter(orgOption => orgOption.value !== artifact?.organization_id?.toString());
  const initialOrg = moveOptions.some(org => org.value === defaultOrgId.toString())
    ? defaultOrgId.toString()
    : moveOptions[0].value;

  return (
    <>
      <FormDialog
        DialogProps={{ open: isOpen, onClose }}
        FormikConfig={{
          ...standardFormikBaseProps,
          initialValues: { organization_id: initialOrg },
          onSubmit: handleSubmit,
          zodSchema: z.object({
            organization_id: z.string().min(1, 'Organization is required')
          })
        }}
        SubmitProps={{
          children: status === Status.pending ? <CircularProgress size={25} /> : type,
          disabled: status === Status.pending
        }}
        title='Select Organization'
      >
        <FieldsLayout>
          <AutocompleteFormik
            name='organization_id'
            label='Organization'
            options={moveOptions}
            disableClearable
            disableUserAdditions
            disabled={status === Status.pending}
          />
        </FieldsLayout>
      </FormDialog>
    </>
  );

  function handleSubmit({ organization_id }: { organization_id: Ident }): void {
    handleMoveOrg(organization_id);
  }
}

export default MoveOrgModal;
