import React from 'react';

import zod from 'zod';

import { Autocomplete, Option } from 'snap-ui/Autocomplete';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';

import { useAuth } from 'provider';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';

import { FilterControl } from '../GlobalFilter.style';
import { FilterConfig, FilterTopic } from '../GlobalFilter.type';

type LastSeenKeys = {
  lastSeen: string;
};

type LastSeenFilterProps = {
  topic: FilterTopic;
  onChange(values: Partial<LastSeenKeys>): void;
  values: LastSeenKeys;
};
const BackwardsCompatibleValues = {
  '-7d': 'P7D',
  '-30d': 'P30D',
  '-90d': 'P90D',
  '-183d': 'P6M',
  '-365dy': 'P1Y',
  '-730y': 'P2Y'
};

const options: Option[] = [
  { content: 'Last 7 Days', label: '7 Days', value: 'P7D' },
  { content: 'Last 30 Days', label: '30 Days', value: 'P30D' },
  { content: 'Last 90 Days', label: '90 Days', value: 'P90D' },
  { content: 'Last 6 Months', label: '6 Months', value: 'P6M' },
  { content: 'Last Year', label: '1 Year', value: 'P1Y' },
  { content: 'Last 2 Years', label: '2 Years', value: 'P2Y' }
];

function LastSeenFilter({ topic, onChange, values }: LastSeenFilterProps): React.ReactElement {
  const { isSubscriber } = useAuth();
  const handleValueChange = (option: string) => {
    const payload = { lastSeen: option };
    onChange(payload);
  };

  return (
    <FilterControl className='LastSeenFilter' disabled={!isSubscriber}>
      <FormLabel id='lastSeenDropdown' className='title-tooltip'>
        By Last Seen Date
        {topic === ArtifactType.Collection && (
          <Tooltip
            title={
              !isSubscriber
                ? 'This filter is only available to subscribers'
                : 'Measure of actor or software activity as tracked by Mandiant'
            }
            placement='right'
            arrow
            wrap
          >
            <Icon.Info />
          </Tooltip>
        )}
      </FormLabel>
      <Autocomplete
        name='last_seen_dropdown'
        options={options}
        value={values.lastSeen}
        onChange={handleValueChange}
        disabled={!isSubscriber}
      />
    </FilterControl>
  );
}

function toQuery(values: LastSeenKeys) {
  if (!values.lastSeen) return;

  return {
    field: 'last_seen',
    op: Ops.greater_than,
    value: values.lastSeen
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('last_seen'),
    op: zod.nativeEnum(Ops),
    value: zod.string()
  })
  .transform(query => ({ lastSeen: BackwardsCompatibleValues[query.value] ?? query.value }));

const LastSeenFilterConfig: FilterConfig<LastSeenKeys> = {
  defaults: { default: () => ({ lastSeen: null }) },
  supportedTopics: [ArtifactType.Collection, ArtifactType.Indicator],
  component: LastSeenFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default LastSeenFilterConfig;
