import React from 'react';

import { formatShortTimestamp } from 'utilities/TimeUtils/TimeUtils';

import { ColumnComponentProps } from './common';

export default function ExecutedAtColumn(props: ColumnComponentProps): JSX.Element {
  return (
    <span key='time' className='time'>
      {formatShortTimestamp(props.job?.start_time)}
    </span>
  );
}
