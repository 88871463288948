import React, { ReactElement } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import BackdropLoader from 'snap-ui/BackdropLoader';
import { ConfirmDialog } from 'snap-ui/Dialog';

import { asValidationError } from 'apis';

import Path from 'constants/paths';

import useLocationState from 'hooks/useLocationState';
import useTitle from 'hooks/useTitle';

import { Engage, Fingerprint } from 'lib/Engagement';

import { EditIntelForm } from 'module/Intel/IntelEdit/IntelEdit';
import { getTagListDiffs } from 'module/Tag';
import { TagListProps } from 'module/Tag/TagList';
import NotFound from 'module/Util/Fallback/NotFound';

import { useAuth, usePushSnack, useUserCatalog } from 'provider';

import { Status } from 'storage';

import { ContentPermission } from 'types/auth';
import { ArtifactType, Visibility } from 'types/common';

import { deleteIntel, quickAddTags, quickDeleteTags, tagRefresh } from '../Intel.api';
import { updateIntel } from '../Intel.service';
import { ArtifactStyle } from '../Intel.style';
import { Intel, IntelRouterState } from '../Intel.type';
import IntelDetail from '../IntelCore/IntelDetail';
import useIntel from '../useIntel';
import IntelHeader from './IntelViewHeader';

type IntelViewProps = {
  className?: string;
  history: History;
};

const IntelView = (props: IntelViewProps): ReactElement => {
  const { className } = props;
  const { push } = useHistory();
  const pushSnack = usePushSnack();
  const { users } = useUserCatalog();
  const { permission: organizations, user } = useAuth();
  const { item, references, permission } = useLocationState<IntelRouterState>() || {};
  const [isActing, setIsActing] = React.useState(false);
  const [action, setAction] = React.useState(null);
  const [isConfirming, setIsConfirming] = React.useState(false);

  const { id: guid } = useParams<{ id: string }>();

  const {
    intel,
    error,
    preview,
    setIntel,
    status: { intel: intelStatus }
  } = useIntel(guid, item, references, permission);

  useTitle(`${intel.name || 'Intelligence'} | SnapAttack`);

  const isPending = intelStatus === Status.pending;

  React.useEffect(() => {
    if (intel.guid && intel.name) {
      Engage.track(
        Fingerprint.load(Path.Intelligence).withData({
          content: ContentPermission.Read,
          guid: intel.guid,
          name: intel.name,
          artifact_organization_id: intel.organization_id,
          artifact_organization_name: organizations?.find(o => o.id === intel.organization_id)?.name
        })
      );
      Engage.trackPersonIncrement(`view ${Path.Intelligence}`, 1);
    }
  }, [intel.guid, intel.name, intel.organization_id, organizations]);

  async function handleDelete(): Promise<void> {
    setIsConfirming(false);
    setAction('Deleting...');
    setIsActing(true);
    await deleteIntel(guid);
    push(`${Path.Feed}?topic=${ArtifactType.Intel}`);
  }

  async function handlePublish(values: Intel) {
    setAction('Saving...');
    setIsActing(true);
    const payload: EditIntelForm = {
      organization_id: values?.organization_id.toString(),
      source: values?.url,
      image: values?.small_image,
      indicators: values?.indicators || [],
      large_image: values?.large_image,
      title: values?.name,
      description: values?.description,
      original_author: values?.original_author,
      visibility: Visibility.Published,
      reference: values?.references,
      url: values?.url,
      actor_names: values?.actor_names,
      attack_names: values?.attack_names,
      software_names: values?.software_names,
      vulnerability_names: values?.vulnerability_names,
      owner: users.find(user => user.id === intel.created_by_id)?.name || user.name,
      quick_add_attachment: [],
      attachment: []
    };

    try {
      const intel = await updateIntel(values.guid, payload);
      setIntel(intel);

      setIsActing(false);
    } catch (err) {
      const validationError = asValidationError(err)?.detail;
      let fieldError;

      if (validationError) fieldError = { message: validationError };

      pushSnack(fieldError.message, 'error', 'center');
      setIsActing(false);
    }
  }

  const handleConfirmDelete = () => {
    setIsConfirming(true);
  };

  const handleQuickAddTag: TagListProps['onSubmit'] = React.useCallback(
    async values => {
      const [addition, deletion] = getTagListDiffs(values, intel);
      const promised: Promise<unknown>[] = [];
      if (addition?.length > 0 || deletion?.length > 0) {
        if (addition?.length > 0) promised.push(quickAddTags(intel.guid, addition));
        if (deletion?.length > 0) promised.push(quickDeleteTags(intel.guid, deletion));
        await Promise.all(promised);
        const refreshedTags = await tagRefresh(intel.guid);

        setIntel({
          ...intel,
          ...refreshedTags
        });
      }
    },
    [intel, setIntel]
  );

  if (error.intel && !preview) {
    return <NotFound artifact={ArtifactType.Intel} error={{ response: 'cti not found', tag: guid }} />;
  }

  return (
    <ArtifactStyle
      isPending={isPending}
      className={className}
      type={ArtifactType.Intel}
      meta={
        <IntelHeader
          intel={intel}
          isPending={isPending}
          preview={preview}
          onDelete={handleConfirmDelete}
          onPublish={handlePublish}
        />
      }
    >
      {/* This is displayed only for publish and delete from hamburger menu, not page load */}
      <BackdropLoader title={action} open={isActing} fixed />
      <ConfirmDialog
        DialogProps={{ open: isConfirming, onClose: () => setIsConfirming(false) }}
        ConfirmProps={{ onClick: handleDelete, children: 'Delete' }}
        title='Delete forever'
        SecondaryProps={{ children: 'Cancel' }}
      >
        Are you sure you want to delete this intelligence?
      </ConfirmDialog>
      <IntelDetail intel={intel} preview={preview} onQuickAddTag={handleQuickAddTag} />
    </ArtifactStyle>
  );
};

export default IntelView;
