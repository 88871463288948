import React from 'react';

import moment from 'moment';

import { Autocomplete, Option } from 'snap-ui/Autocomplete';
import DatePicker from 'snap-ui/DatePicker';
import { styled } from 'snap-ui/util';

import { renderValueWithLabel } from 'utilities/TextUtils';

import { DateRange, MomentDateRange, PRESET_WINDOW, PRESET_WINDOWS } from './DateRangeSelector.type';

const Container = styled('div', { name: 'DateRangeSelector' })`
  display: flex;
  gap: ${p => p.theme.spacing(3)};
  align-items: center;
  justify-content: flex-end;

  .DatePicker-start,
  .DatePicker-stop {
    width: 140px;
  }

  .DateRange {
    width: 160px;

    .MuiInputBase-root.MuiAutocomplete-inputRoot input {
      padding-right: ${p => p.theme.spacing(1)} !important;
    }
  }
`;

export type DateRangeSelectorProps = {
  dates: DateRange;
  disabled?: boolean;
  onDateRangeChange: (dates: DateRange) => void;
  initialPreset: PRESET_WINDOW;
  demoMode?: boolean;
};

export default function DateRangeSelector({
  dates,
  disabled,
  onDateRangeChange,
  demoMode,
  initialPreset
}: DateRangeSelectorProps) {
  const { start: startDate, stop: stopDate } = dates;
  const [selection, setSelection] = React.useState<string>(initialPreset.toString());

  const minDate = new Date('11/01/2021');

  const momentStop = moment(new Date());

  const handleDateChange = (newDates: MomentDateRange) => {
    const { start, stop } = newDates;

    const startValid = start.isValid() && start.isAfter(minDate) && start.isBefore(moment());
    const stopValid = stop.isValid() && stop.isAfter(minDate) && stop.isBefore(moment()) && stop.isAfter(start);

    if (startValid && stopValid) {
      onDateRangeChange({
        start: start.toDate(),
        stop: stop.toDate()
      });
    }
  };

  const options = [
    ...PRESET_WINDOWS.map(preset => ({
      content: `Last ${renderValueWithLabel(preset, 'day')}`,
      label: `Last ${renderValueWithLabel(preset, 'day')}`,
      value: preset.toString()
    })),
    {
      content: 'Custom Range',
      label: 'Custom Range',
      value: 'custom'
    }
  ];

  function handleChange(value: string | Option[]): void {
    setSelection(value as string);

    if (value !== 'custom') {
      onDateRangeChange({
        stop: momentStop.toDate(),
        start: momentStop.subtract(Number(value), 'days').toDate()
      });
    }
  }

  return (
    <Container className='DateRangeSelector'>
      {selection === 'custom' && (
        <>
          <DatePicker
            className='DatePicker-start'
            label='Start Date'
            value={moment(startDate)}
            minDate={moment(minDate)}
            maxDate={moment(stopDate)}
            onChange={newDate => {
              handleDateChange({
                stop: moment(stopDate),
                start: newDate
              });
            }}
          />
          <DatePicker
            className='DatePicker-stop'
            label='Stop Date'
            value={moment(stopDate)}
            disableFuture
            minDate={moment(startDate)}
            onChange={newDate => {
              handleDateChange({
                stop: newDate,
                start: moment(startDate)
              });
            }}
          />
        </>
      )}
      <Autocomplete
        className='DateRange'
        value={selection}
        options={options}
        disabled={disabled || demoMode}
        disableClearable
        name='date-range'
        label='Date Range'
        onChange={handleChange}
        helperText={
          selection === 'custom' ? null : `${startDate.toLocaleDateString()} - ${stopDate.toLocaleDateString()}`
        }
      />
    </Container>
  );
}
