import React from 'react';

import LinearProgress from 'snap-ui/LinearProgress';
import { styled } from 'snap-ui/util';

type ProgressProps = {
  currentTime: number;
  duration: number;
};

export const LinearProgressStyled = styled(LinearProgress)`
  position: absolute;
  top: 15px;
  right: 12px;
  left: 12px;
  height: 4px;
  margin: 0;
  background-color: ${p => p.theme.palette.greyish.light};
`;

function Progress({ currentTime, duration }: ProgressProps) {
  const percent = (currentTime / duration) * 100;
  return <LinearProgressStyled variant='determinate' value={percent} color='error' />;
}

export default Progress;
