import React from 'react';

import isEmpty from 'lodash/isEmpty';

import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import TextFieldFormik from 'module/Form/TextFieldFormik';

function SearchFields({ languageOptionLists }: { languageOptionLists }) {
  return (
    <>
      <AutocompleteFormik
        label='Search Language'
        name='search_targets'
        options={languageOptionLists.search}
        disableUserAdditions
        disabled={isEmpty(languageOptionLists.search)}
        multiple
        helperText={isEmpty(languageOptionLists.search) ? 'No Search languages configured.' : ''}
      />
      <TextFieldFormik
        label='URL Template'
        name='url_template'
        placeholder='ex: https://server.com/search?q={{query}}'
        helperText={'Use "{{query}}" as a placeholder for detection code to create dynamic search links'}
      />
    </>
  );
}

export default SearchFields;
