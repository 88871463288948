import React from 'react';

import {
  deleteLanguageTarget,
  getLanguageTargetsExtended,
  postLanguageTarget,
  postLanguageTargetRecompile,
  putLanguageTarget,
  putHiddenLanguages
} from 'apis/resources/analytic';

import { ErrorProps } from 'module/ApiError';

import { useAuth, useLanguageContext } from 'provider';

import { Status, useAsync } from 'storage';

import { LanguageExtended, LanguageTargetPayload } from 'types/analytic';
import { Guid, Ident } from 'types/common';

type LanguageSettingsState = {
  create(target: LanguageTargetPayload): Promise<void>;
  errorProps?: ErrorProps;
  hiddenLanguages: Ident[];
  languages: LanguageExtended[];
  recompile(targetId: Ident): Promise<void>;
  remove(targetId: Ident): Promise<void>;
  resetError(): void;
  refresh: () => Promise<void>;
  selectedOrgId: Ident;
  setSelectedOrgId(id: Ident): void;
  status: Status;
  toggle(languageId: Ident, orgGuid: Guid): Promise<void>;
  update(targetId: Ident, target: LanguageTargetPayload): Promise<void>;
};

export default function useLanguageSettings(): LanguageSettingsState {
  const { user, defaultOrgId, permission } = useAuth();
  const [selectedOrgId, setSelectedOrgId] = React.useState(defaultOrgId);

  const org = React.useMemo(() => permission.find(p => p.id === selectedOrgId), [permission, selectedOrgId]);
  const [hiddenLanguages, setHiddenLanguages] = React.useState<Ident[]>([]);
  React.useEffect(() => {
    // set hidden languages on org load, but it will be tracked locally from then on so we don't have to do a heavy
    // org refresh
    setHiddenLanguages(org?.mandatory_preference?.hidden_analytic_compilation_targets || []);
  }, [org]);

  const { refresh: refreshCatalog } = useLanguageContext();
  const { data: languages, errorProps, run, setData: setLanguages, status, task } = useAsync([] as LanguageExtended[]);

  const refresh = React.useCallback(async () => {
    if (user.id) {
      refreshCatalog();
      run(getLanguageTargetsExtended(), true);
    }
  }, [refreshCatalog, run, user.id]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  const toggle = React.useCallback(
    (languageId: Ident, orgGuid: Guid): Promise<void> => {
      const oldHiddenLanguages = [...hiddenLanguages];
      const newHiddenLanguages = getNewHiddenLanguages(oldHiddenLanguages, languageId);
      const optimisticData = getOptimisticToggleData(languages, newHiddenLanguages);
      setLanguages(optimisticData);
      setHiddenLanguages(newHiddenLanguages);
      return task(putHiddenLanguages(orgGuid, newHiddenLanguages))
        .catch(() => {
          // undo optimistic update
          setLanguages(languages);
          setHiddenLanguages(oldHiddenLanguages);
        })
        .then(() => {
          refresh();
        });
    },
    [hiddenLanguages, languages, refresh, setLanguages, task]
  );

  const create = React.useCallback(
    (target: LanguageTargetPayload) => {
      return task(postLanguageTarget(target)).then(refresh);
    },
    [refresh, task]
  );

  const update = React.useCallback(
    (targetId: Ident, target: LanguageTargetPayload) => {
      return task(putLanguageTarget(targetId, target)).then(refresh);
    },
    [refresh, task]
  );

  const recompile = React.useCallback(
    (targetId: Ident) => {
      return task(postLanguageTargetRecompile(targetId)).then(refresh);
    },
    [refresh, task]
  );

  const remove = React.useCallback(
    (targetId: Ident) => {
      return task(deleteLanguageTarget(targetId)).then(refresh);
    },
    [refresh, task]
  );

  const resetError = React.useCallback(() => {
    task(Promise.resolve());
  }, [task]);

  return {
    create,
    errorProps,
    hiddenLanguages,
    languages,
    recompile,
    remove,
    resetError,
    selectedOrgId,
    setSelectedOrgId,
    status,
    toggle,
    update,
    refresh
  };
}

export function getOptimisticToggleData(
  languages: LanguageExtended[],
  newHiddenLanguages: Ident[]
): LanguageExtended[] {
  return languages.map(language => ({
    ...language,
    hidden: newHiddenLanguages.includes(language.id)
  }));
}

export function getNewHiddenLanguages(hiddenLanguages: Ident[], languageId: Ident): Ident[] {
  if (hiddenLanguages.includes(languageId)) {
    return hiddenLanguages.filter(langId => langId !== languageId);
  } else {
    return Array.from(new Set([...hiddenLanguages, languageId]));
  }
}
