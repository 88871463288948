import { styled } from 'snap-ui/util';

const HuntStyle = styled('div')`
  .ApiError,
  .Hunt-job {
    margin-bottom: ${p => p.theme.spacing(7)};
  }

  .ApiError {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .Hunt-grid {
    margin-top: ${p => p.theme.spacing(5)};
    height: 525px;
    width: 100%;
  }

  .Hunt-static-params {
    gap: ${p => p.theme.spacing(7)};
    margin: ${p => p.theme.spacing(3)} 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${p => p.theme.spacing(3)};
    grid-row-gap: ${p => p.theme.spacing(7)};

    .Form-field {
      flex: 1;
    }

    .Form-field.Hunt-name,
    .frequency,
    .advancedConfigurationToggle {
      grid-column: 1/-1;
      .MuiFormGroup-root {
        flex-direction: row;
      }
    }

    .MuiFormControlLabel-root {
      grid-column: 1/-1;
    }
  }

  .frequency {
    display: flex;
    align-items: center;
    gap: ${p => p.theme.spacing(3)};
  }

  .frequency,
  .cron,
  .advancedConfigurationToggle {
    margin-top: ${p => p.theme.spacing(4)};
  }

  .Hunt-actions {
    display: flex;
    gap: ${p => p.theme.spacing(3)};
    justify-content: flex-end;
    margin-bottom: ${p => p.theme.spacing(9)};
    margin-top: -${p => p.theme.spacing(5)};
  }

  .Hunt-createIntegration {
    margin-bottom: ${p => p.theme.spacing(8)};
  }
`;

export default HuntStyle;
