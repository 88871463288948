import React from 'react';

import { GridColDef } from 'snap-ui/DataGrid';

import { useMetadataSchema } from 'provider';

import { Artifact } from 'types/common';

import { MetadataCell } from './DisplayGrid.helper';

/**
 * builds columnn definitions based on metadata schema.
 * depends on global MetadataProvider and local FeedExtraProvider
 */
export function useGridColumnsMetadata(fieldPrefix?: string): GridColDef<Artifact>[] {
  const { schema } = useMetadataSchema();

  return React.useMemo(
    () =>
      Object.entries(schema?.properties || {}).map(([key, fieldSchema]) => ({
        field: fieldPrefix ? `${fieldPrefix}{${key}}` : key,
        headerName: `Metadata: ${fieldSchema.title}`,
        hideable: true,
        // sortable:
        //   value.type === 'boolean' ||
        //   (value.type === 'string' && value.properties.control.title !== 'textarea') ||
        //   value.type === 'number',
        sortable: false,
        filterable: false,
        width: 200,
        renderCell: ({ row, field }) => <MetadataCell field={field} artifact={row} />
      })),
    [fieldPrefix, schema]
  );
}
