import { deleteAgent, putAgent } from 'module/BAS/BAS.api';

import { Status, useAsync } from 'storage';

import { BASAgentEditableFields } from 'types/bas';

type UpdateAgentBag = {
  error?: string;
  updateAgent: (agentId: string, values: BASAgentEditableFields) => void;
  removeAgent: (agentId: string) => void;
  status: Status;
};

export default function useUpdateAgent(): UpdateAgentBag {
  const { error, run, status } = useAsync();

  const updateAgent: UpdateAgentBag['updateAgent'] = (agentId, values) => {
    return run(putAgent(agentId, values));
  };

  const removeAgent: UpdateAgentBag['removeAgent'] = agentId => {
    return run(deleteAgent(agentId));
  };

  return {
    error: error ? JSON.stringify(error.response?.data) : error,
    status,
    updateAgent,
    removeAgent
  };
}
