import React from 'react';

import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import z from 'zod';

import Button from 'snap-ui/Button';
import { BuffDivider } from 'snap-ui/Divider';
import Paper from 'snap-ui/Paper';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { ApiError } from 'module/ApiError';
import Formik from 'module/Form/Formik';
import TextFieldFormik from 'module/Form/TextFieldFormik';
import { Flex } from 'module/Layout/Styled';

import { useAuth, useAuthInterface } from 'provider';

import { PasswordType } from 'services/pwValidationService';

import { Form } from '../Authentication.style';
import { useMultifactor } from './Multifactor.service';

const Container = styled(Paper)`
  padding: ${p => p.theme.spacing(7)};

  form {
    padding-top: ${p => p.theme.spacing(2)};
  }
`;

type Props = {
  className?: string;
};

const MFAAction = {
  Disable: 'disable',
  Enable: 'enable',
  Reset: 'reset'
};

export default function MultiFactorSettings(props: Props) {
  const { user } = useAuth();
  const { fetch } = useAuthInterface();
  const { replace } = useHistory();

  const { remove, errorProps } = useMultifactor();

  const [mfaAction, setMfaAction] = React.useState<string>();
  const mfaEnabled = user.preference?.mfa_required;

  return (
    <Container className={classnames('MultifactorSettings', props.className)} elevation={16}>
      <Typography variant='h1'>Multi-Factor Authentication</Typography>
      <p>{getSubheading()}</p>

      <BuffDivider />
      {mfaEnabled ? (
        <Formik
          enableReinitialize
          initialValues={{
            [PasswordType.PASSWORD]: ''
          }}
          onSubmit={handleSubmit}
          zodSchema={z.object({
            [PasswordType.PASSWORD]: z.string().min(8, 'Existing password is required.')
          })}
        >
          <Form>
            <ApiError {...errorProps} />
            <TextFieldFormik
              label='Current Password'
              name={PasswordType.PASSWORD}
              type={PasswordType.PASSWORD}
              required
            />
            <Flex>
              <>
                {!user.mfa_required_org && (
                  <Button
                    type='submit'
                    onClick={() => {
                      setMfaAction(MFAAction.Disable);
                    }}
                  >
                    Disable MFA
                  </Button>
                )}
                <Button
                  type='submit'
                  onClick={() => {
                    setMfaAction(MFAAction.Reset);
                  }}
                >
                  Reset MFA
                </Button>
              </>
            </Flex>
          </Form>
        </Formik>
      ) : (
        <Button type='submit' onClick={handleEnable}>
          Enable MFA
        </Button>
      )}
    </Container>
  );

  async function handleSubmit({ password }: { password: string }) {
    const payload = {
      email: user.email,
      password
    };

    if (mfaAction === MFAAction.Disable) return handleDisable(payload);
    if (mfaAction === MFAAction.Reset) return handleReset(payload);
  }

  async function handleDisable(payload) {
    await remove(payload);
    await fetch();
  }

  async function handleReset(payload) {
    await remove(payload);
    await fetch();
    replace(Path.MultifactorConfiguration);
  }

  async function handleEnable() {
    replace(Path.MultifactorConfiguration);
  }

  function getSubheading(): string {
    if (mfaEnabled) {
      if (user.mfa_required_org) {
        return 'Turn on multi-factor authentication to provide additional security for your account.';
      } else {
        return 'Disable multi-factor authentication for your account, or reset your multi-factor authentication configuration to pair with a new device.';
      }
    } else {
      return 'Turn on multi-factor authentication to provide additional security for your account.';
    }
  }
}
