import { Artifact, ArtifactScore } from 'types/common';

export const ARTIFACT_SCORE_OPTIONS = Object.entries(ArtifactScore).map(([key, value]) => ({
  key,
  content: value,
  value
}));

export const TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSS';

export const MULTIPART_REQUEST_CONFIG = { headers: { 'Content-Type': 'multipart/form-data' } };

export const ArtifactShell: Artifact = {
  short_id: null,
  organization_id: 0,
  visibility: null,
  modified: null,
  creation: null,
  created_by_id: 0,
  modified_by_id: 0,
  owner_id: 0,
  small_image: null,
  large_image: null,
  logsource: null,
  preview: false,
  linked_actors: [],
  linked_attacks: [],
  linked_tags: [],
  guid: null,
  name: null,
  description: null,
  tags: [],
  actor_names: [],
  attack_names: [],
  software_names: [],
  vulnerability_names: []
};

export const ONES_TRUTH = [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31];
export const TWOS_TRUTH = [2, 3, 6, 7, 10, 11, 14, 15, 18, 19, 22, 23, 26, 27, 30, 31];
export const FOURS_TRUTH = [4, 5, 6, 7, 12, 13, 14, 15, 20, 21, 22, 23, 28, 29, 30, 31];
export const EIGHTS_TRUTH = [8, 9, 10, 11, 12, 13, 14, 15, 24, 25, 26, 27, 28, 29, 30, 31];
export const SIXTEENS_TRUTH = [16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
