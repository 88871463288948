import React from 'react';

import { ThemeProvider, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CssBaseline from '@mui/material/CssBaseline';
import { css, styled } from '@mui/material/styles';
import useMuiMediaQuery from '@mui/material/useMediaQuery';

import { StrictReactNode } from 'types/core';

export type { CSSObject, Palette, Theme } from '@mui/material';
export { ClickAwayListener, CssBaseline, ThemeProvider, css, styled, useTheme };

export function useMediaQuery(query: string) {
  return useMuiMediaQuery(query);
}

type StyledBoxProps = {
  className?: string;
  children: StrictReactNode;
};

export function styledBox(styleFn: () => { [index: string]: string | number | { [index: string]: string | number } }) {
  return styled((props: StyledBoxProps) => <Box className={props.className}>{props.children}</Box>)<StyledBoxProps>(
    styleFn
  );
}
