import React from 'react';

import useIsLocationTransition from 'hooks/useIsLocationTransition';
import useQueryString from 'hooks/useQueryString';

import { SortOrder, isValidSortOrder } from 'module/Feed/Feed.type';

import { useUserConfig } from './UserConfig';

/**
 * wrapper around useUserConfig that keeps url `sort_by` param in sync with sortOrder value from storage
 * if you need to access the active sortOrder value but not need to control the value (eg in a presentational
 * component like Cards), use useUserConfig directly
 */
export function useSortOrder(): { sortOrder: SortOrder; setSortOrder(order: SortOrder): void } {
  const isTransitioning = useIsLocationTransition();
  const [loaded, setLoaded] = React.useState(false);
  const { sortOrder, _setSortOrder } = useUserConfig();
  const { update: updateUrl, getByKey } = useQueryString();
  const urlSortBy = getByKey('sort_by');
  const validatedUrlSortBy = isValidSortOrder(urlSortBy) ? urlSortBy : undefined;

  React.useEffect(() => {
    // set the url param on mount
    if (!loaded && !validatedUrlSortBy && sortOrder && !isTransitioning) {
      updateUrl({ sort_by: sortOrder });
      setLoaded(true);
    }
  }, [sortOrder, loaded, updateUrl, validatedUrlSortBy, isTransitioning]);

  React.useEffect(() => {
    // respond to changes in the url by saving them to user settings
    if (validatedUrlSortBy && validatedUrlSortBy !== sortOrder) {
      _setSortOrder(validatedUrlSortBy);
    } else if (!sortOrder) {
      _setSortOrder(SortOrder.modified);
    }
  }, [sortOrder, _setSortOrder, validatedUrlSortBy]);

  const setSortOrder = React.useCallback(
    (sort_by: SortOrder) => {
      if (isValidSortOrder(sort_by) && sort_by !== sortOrder) {
        updateUrl({ sort_by });
      }
    },
    [sortOrder, updateUrl]
  );

  return { sortOrder, setSortOrder };
}
