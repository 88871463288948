import { Collection } from 'module/Collection/Collection.type';
import { BaseTag } from 'module/Tag/Tag.type';

import {
  BASAgent,
  BASJobOutcome,
  BASJobResult,
  BASJobState,
  BASScript,
  BASTool,
  ExecutionErrors,
  JobOutcome
} from 'types/bas';
import { Artifact, ArtifactScore, Guid, Ident } from 'types/common';
import { Query } from 'types/filter';

export { BASProductName } from 'types/bas';
export { BASJobState };

export type CampaignResultStatus = Record<BASJobState, number> & {
  incomplete: number;
};

export enum Discriminator {
  manual = 'Manual',
  bas = 'BAS'
}

export enum OSName {
  Linux = 'linux',
  macOS = 'macos',
  Windows = 'windows',
  Azure_Ad = 'azure-ad',
  Containers = 'containers',
  Google = 'google-workspace',
  Iaas = 'iaas',
  Iaas_Azure = 'iaas:azure',
  Iaas_Aws = 'iaas:aws',
  Iaas_gcp = 'iaas:gcp',
  Office = 'office-365',
  Saas = 'saas'
}

export type BASJob = {
  attacker_ip: string;
  victim_hostname: string;
  attacker_hostname: string;
  victim_ip: string;
  victim_operating_system: OSName;
  guid: Guid;
  id: number;
  description: string;
  creation: string;
  discriminator?: Discriminator;
  start_time?: string;
  end_time?: string;
  test_cases: string[];
  command_input?: string;
  command_output?: string;
  state: BASJobState;
  agent: BASAgent;
  result: BASJobResult;
  execution_errors?: ExecutionErrors;
  script: BASScript;
  outcomes: BASJobOutcome[];
  outcome: JobOutcome;
  logged: boolean;
  prevented: boolean;
  detected: boolean;
  attack_names: string[];
};

export type ManualAgent = {
  victim_hostname: string;
  victim_ip: string;
  victim_operating_system: OSName;
  attacker_hostname: string;
  attacker_ip: string;
};

export type BASManualTest = {
  name: string;
  description: string;
  severity: ArtifactScore;
  attack_names: string[];
  state: BASJobState;
  victim_hostname: string;
  victim_ip: string;
  victim_operating_system: string;
  attacker_hostname: string;
  attacker_ip: string;
  start_time: Date;
  end_time: Date;
  command_input: string;
  command_output: string;
};

export type BASCampaignFeed = {
  guid: Guid;
  name: string;
  outcomes: Record<JobOutcome, number>;
  last_execution: string;
  state: BASJobState;
  agents: BASCampaignFeedAgent[];
  result_status: CampaignResultStatus;
};

export type BASCampaign = Omit<BASCampaignFeed, 'agents'> & {
  agents: BASAgent[];
  id: Ident;
  created_by_id: Ident;
  creation: string;
  description?: string;
  detected: number;
  modified: string;
  organization_id: Ident;
  tool_outcomes: Record<BASTool, number>;
  ranks: Record<ArtifactScore, number>;
  severities: Record<ArtifactScore, number>;
  validations: Record<DetectionValidated, number>;
  tactic_coverage: Record<string, Record<JobOutcome, number>>;
  attack_coverage: Record<string, Record<JobOutcome, number>>;
  collection?: Artifact;
  tag?: Artifact;
  linked_artifact: Artifact;
};

export type BASCampaignFeedAgent = {
  guid: Guid;
  os_name: OSName;
  host_name: string;
  is_online: boolean;
};

export function isBASCampaignFeedAgent(agent: BASAgent | BASCampaignFeedAgent): agent is BASCampaignFeedAgent {
  return !!agent && 'host_name' in agent;
}

export type BASCampaignSupplemental = {
  guid: Guid;
  collection?: Artifact;
  tag?: Artifact;
};

export enum DetectionValidated {
  Validated = 'Validated',
  NotValidated = 'Not Validated',
  NotApplicable = 'N/A'
}

export enum OutcomesStatus {
  Pending = 'PENDING',
  InProgress = 'IN PROGRESS',
  Complete = 'COMPLETE',
  Failed = 'FAILED'
}

export type DetermineOutcomesStatus = {
  current_detail: string;
  current_task: string;
  status: OutcomesStatus;
  status_detail: Record<string, unknown>;
};

export type BASCampaignPreview = {
  count: number;
  platforms: Partial<Record<OSName, number>>;
};

export type BASCampaignPreviewResponse = {
  count: number;
  platforms: OSName[];
};

export type BASCampaignPreviewPayload = {
  name?: string;
  session_filter?: Query;
  analytic_filter?: Query;
  bas_script_filter?: Query;
  collection?: Collection['guid'];
  tag?: BaseTag['name'];
};

export type BASCampaignPost = BASCampaignPreviewPayload & {
  agents: Partial<Record<OSName, Guid>>;
};
