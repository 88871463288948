import React from 'react';

import { useField, useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';

import { AlertTitle } from 'snap-ui/Alert';
import { Option } from 'snap-ui/Autocomplete';
import Button from 'snap-ui/Button';
import CircularProgress from 'snap-ui/CircularProgress';
import { FormDialog } from 'snap-ui/Dialog';
import { FieldsLayout } from 'snap-ui/Layout';

import { ApiError, ErrorProps } from 'module/ApiError';
import { standardFormikBaseProps } from 'module/Form';
import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import { Highlight } from 'module/Widgets/SyntaxEditor';
import { BasicEditor } from 'module/Widgets/SyntaxEditor/BasicEditor';

import { useAuth } from 'provider';

import { SigmaConfigYamlSchema } from 'services/sigmaService';

import { Status } from 'storage';

import { EditorConfig } from 'types/analytic';
import { Ident } from 'types/common';

import { configName, getYamlShell } from '../LanguageConfig.util';
import { StickyAlert, YamlFieldContainer } from './ConfigFormDialog.style';

export type ConfigFormValues = {
  organization_id: Ident;
  raw: string;
};

type SigmaConfigFormDialogProps = {
  errorProps?: ErrorProps;
  initialConfig?: EditorConfig;
  onClose(): void;
  onSave(values: ConfigFormValues): Promise<any>;
  status: Status;
  orgOptions?: Option[];
};

export default function ConfigFormDialog({
  errorProps,
  initialConfig,
  onClose,
  onSave,
  status,
  orgOptions
}: SigmaConfigFormDialogProps): JSX.Element {
  const { user, defaultOrgId } = useAuth();

  function handleSubmit(values: ConfigFormValues) {
    onSave(values).then(onClose);
  }

  const isLoading = status === Status.pending;
  return (
    <FormDialog
      DialogProps={{
        open: !!initialConfig,
        onClose,
        maxWidth: 'md'
      }}
      FormikConfig={{
        ...standardFormikBaseProps,
        initialValues: {
          discriminator: initialConfig?.discriminator,
          raw: initialConfig?.raw || getYamlShell(initialConfig?.discriminator),
          organization_id: initialConfig?.organization_id || defaultOrgId
        },
        onSubmit: handleSubmit,
        zodSchema: SigmaConfigYamlSchema,
        flattenZodErrors: path => path.filter(part => part !== 'raw').join('.')
      }}
      SubmitProps={{
        children: isLoading ? <CircularProgress size={25} /> : 'Save and Close',
        disabled: isLoading
      }}
      SecondaryActionProps={{
        onClick: onSave,
        children: isLoading ? <CircularProgress size={25} /> : 'Save',
        disabled: isLoading,
        validate: true
      }}
      title={
        initialConfig?.id
          ? `Edit ${initialConfig?.name}`
          : `Create ${configName(initialConfig?.discriminator, { capital: true })}`
      }
    >
      <ApiError {...errorProps} />
      <FieldsLayout maxWidth='100%'>
        {orgOptions?.length > 1 && user.superuser && (
          <AutocompleteFormik
            className='sigma-organization-dropdown'
            disableClearable
            disableUserAdditions
            elevated
            label='Organization'
            name='organization_id'
            options={orgOptions}
          />
        )}
        <YamlField />
      </FieldsLayout>
    </FormDialog>
  );
}

function YamlField(): JSX.Element {
  const [{ value: rawValue }, , { setValue: setRawValue }] = useField('raw');
  const [showFullError, setShowFullError] = React.useState(false);
  const { errors } = useFormikContext();

  return (
    <>
      {!isEmpty(errors) && (
        <StickyAlert severity='error'>
          <AlertTitle>
            Oops! Looks like there&apos;s a problem with your config{' '}
            <Button color='error' size='small' variant='text' onClick={() => setShowFullError(v => !v)}>
              {showFullError ? 'Hide' : 'Show'}
            </Button>
            {showFullError && (
              <pre>
                {Object.entries(errors)
                  .map(([field, message]) => `${field}: ${message}`)
                  .join('\n')}
              </pre>
            )}
          </AlertTitle>
        </StickyAlert>
      )}
      <YamlFieldContainer>
        <BasicEditor value={rawValue} onChange={setRawValue} name='raw' highlight={Highlight.yaml} disableCopyButton />
      </YamlFieldContainer>
    </>
  );
}
