import React from 'react';

import upperCase from 'lodash/upperCase';

import Divider from 'snap-ui/Divider';
import Paper from 'snap-ui/Paper';
import Table from 'snap-ui/Table';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { BASToolsProvider } from 'provider/BASTools';

import { Guid } from 'types/common';

import { NOOP } from 'utilities/FunctionUtils';

import AgentMetadata from '../AgentMetadata';
import { BASJob, Discriminator, ManualAgent } from '../BAS.type';
import { getTestCaseName } from '../BAS.util';
import useCampaignJobs from '../CampaignJobList/useCampaignJobs';
import { ResultStatus, ValidationStatus } from '../JobList/ActionsColumn';
import AttackStatusColumn from '../JobList/AttackStatusColumn';
import ExecutedAtColumn from '../JobList/ExecutedAtColumn';
import MitreAttackColumn from '../JobList/MitreAttackColumn';
import OutcomeColumn from '../JobList/OutcomeColumn';
import TestCaseColumn from '../JobList/TestCaseColumn';
import { PaginateModalProvider } from '../JobOutcomes/PaginateModal';
import { BASReportType } from './CampaignReport';

type TestSummarySectionProps = {
  campaignGuid: Guid;
  isLoading: boolean;
  reportType: BASReportType;
};

const DEFAULT_PAGE_SIZE = 500;

function TestSummarySection({ campaignGuid, reportType }: TestSummarySectionProps) {
  const { getPage, page } = useCampaignJobs(campaignGuid, DEFAULT_PAGE_SIZE);

  const fetchData = React.useCallback(
    (next?: number) => {
      const page = next || 0;

      getPage({ page });
    },
    [getPage]
  );

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {reportType && (
        <div className='CampaignReport-section TestSummary print-only'>
          <Typography variant='h2'>Test Summary</Typography>
          <CampaignJobListPrint jobs={page.items} />
        </div>
      )}
      {reportType === BASReportType.DetailedReport && (
        <>
          <div className='page-break'></div>
          <div className='CampaignReport-section DetailedTestResults print-only'>
            <Typography variant='h2'>Detailed Test Results</Typography>
            <DetailedTestResultsPrint jobs={page.items} />
          </div>
        </>
      )}
    </>
  );
}

export default TestSummarySection;

const Container = styled(Paper)`
  .time {
    white-space: nowrap;
  }

  pre {
    word-break: break-all;
  }
`;

function getColumns() {
  const columns: string[] = ['Executed At', 'MITRE ATT&CK', 'Test Case', 'Attack Status', 'Outcome'];
  const row = [ExecutedAtColumn, MitreAttackColumn, TestCaseColumn, AttackStatusColumn, OutcomeColumn];

  return { columns, row };
}

type CampaignJobListPrintProps = {
  jobs: BASJob[];
};

function CampaignJobListPrint({ jobs }: CampaignJobListPrintProps): JSX.Element {
  const { columns, row } = getColumns();

  return (
    <BASToolsProvider>
      <PaginateModalProvider items={jobs} refreshJobs={NOOP} updateOutcomes={NOOP}>
        <Container elevation={5}>
          <Table
            columns={columns}
            component='div'
            rows={jobs?.map(job => row.map(component => React.createElement(component, { job, print: true })))}
          />
        </Container>
      </PaginateModalProvider>
    </BASToolsProvider>
  );
}

type DetailedTestResultsPrintProps = {
  jobs: BASJob[];
};

function DetailedTestResultsPrint({ jobs }: DetailedTestResultsPrintProps): JSX.Element {
  return (
    <>
      {jobs.map((job, index) => {
        const discriminator = job?.discriminator;
        const isManual = discriminator === Discriminator.manual;
        const title = getTestCaseName(job);
        const description = isManual ? job.description : job.script?.description;
        const command = isManual ? job?.command_input : job?.result?.command;
        const commandOutput = isManual ? job?.command_output : job?.result?.output;
        const manualAgent: ManualAgent = isManual
          ? {
              victim_hostname: job?.victim_hostname,
              victim_ip: job?.victim_ip,
              victim_operating_system: job?.victim_operating_system,
              attacker_hostname: job?.attacker_hostname,
              attacker_ip: job?.attacker_ip
            }
          : null;

        return (
          <React.Fragment key={job.guid}>
            {index !== 0 && <div className='page-break' />}
            <Container elevation={5}>
              <>
                <Typography variant='h2'>{title}</Typography>
                <p>{description}</p>
                {job.attack_names?.map(attack => (
                  <p key={attack}>{attack}</p>
                ))}
                <p>
                  Source: <TestCaseColumn job={job} />
                </p>
                <Divider />
                <div className='detail-section'>
                  <div className='job-meta'>
                    <Typography variant='h2'>Test Details</Typography>
                    <AgentMetadata agent={job.agent} isManual={isManual} manualAgent={manualAgent} />
                  </div>
                  {job?.script?.validation ? (
                    <ValidationStatus executionErrors={job?.execution_errors} />
                  ) : (
                    <ResultStatus className={job.state} label={upperCase(job.state)} />
                  )}
                </div>

                <SplitOutput title='Commands' result={command} />

                {!!job?.result?.error && (
                  <>
                    <SplitOutput title='Error' result={job?.result?.error} />
                  </>
                )}
                {commandOutput && (
                  <>
                    <SplitOutput title='Output' result={commandOutput} />
                  </>
                )}
              </>
            </Container>
          </React.Fragment>
        );
      })}
    </>
  );
}

function SplitOutput({ result, title }: { result: string; title: string }): JSX.Element {
  if (!result) return null;
  const parts = result?.split(/\n/g);

  const startOnNewPage = result?.length > 1000;

  const reduced = parts
    .reduce((accumulated, part, index) => {
      const row = Math.floor(index / 80);
      accumulated[row] ? accumulated[row].push(part) : (accumulated[row] = [part]);
      return accumulated;
    }, [])
    .map(part => part.join('\n'));

  return (
    <>
      {startOnNewPage && <div className='page-break'></div>}
      <Typography variant='h3'>{title}</Typography>

      {reduced.map((part, index, list) => {
        return (
          <React.Fragment key={`output-${index}`}>
            <div className='output'>
              <code>
                <pre>{part}</pre>
              </code>
            </div>
            {index !== list.length - 1 && <div className='page-break'></div>}
          </React.Fragment>
        );
      })}
    </>
  );
}
