import { DateTimePicker } from '@mui/x-date-pickers-pro';

import { styled } from 'snap-ui/util';

const StyledDateTimePicker = styled(DateTimePicker)`
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  background-color: hsl(0, 0%, 7.1%);
  background-image: linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12));
`;
export default StyledDateTimePicker;
