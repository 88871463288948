import React from 'react';

import { Popper as MuiPopper, PopperProps } from '@mui/material';
import classNames from 'classnames';

const Popper = ({ className, children, ...others }: PopperProps) => {
  return (
    <MuiPopper className={classNames('Popper', className)} {...others}>
      {children}
    </MuiPopper>
  );
};

export type { PopperProps };
export default Popper;
