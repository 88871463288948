import React from 'react';

import { useAuth, useUserCatalog } from 'provider';

import { Status, useAsync } from 'storage';

import { Guid } from 'types/common';

import { deleteApiKey, getApiKeys, postApiKey } from './ApiKeys.api';
import { ApiKey, CreateApiKeyPayload, CreatedApiKey } from './ApiKeys.type';

export type ApiKeyCatalog = {
  createdApiKey: CreatedApiKey;
  setCreatedApiKey(key: CreatedApiKey): void;
  create(target: CreateApiKeyPayload): Promise<void>;
  error?: string;
  apiKeys: ApiKey[];
  resetError(): void;
  status: Status;
  remove(targetGuid: Guid): Promise<void>;
  refresh(): void;
};

export default function useApiKeys(): ApiKeyCatalog {
  const { user } = useAuth();
  const { getUserById } = useUserCatalog();

  const [createdApiKey, setCreatedApiKey] = React.useState<CreatedApiKey>(null);
  const { data: _apiKeys, error, status, run, task } = useAsync<ApiKey[]>([]);

  const refresh = React.useCallback(() => {
    if (user?.guid) run(getApiKeys());
  }, [run, user?.guid]);

  const create = React.useCallback(
    (target: CreateApiKeyPayload): Promise<void> => {
      return task(postApiKey(target))
        .then(data => setCreatedApiKey(data))
        .then(refresh);
    },
    [refresh, task]
  );

  const remove = React.useCallback(
    (targetGuid: Guid) => {
      return task(deleteApiKey(targetGuid)).then(refresh);
    },
    [refresh, task]
  );

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  const resetError = React.useCallback(() => {
    if (status === Status.rejected) task(Promise.resolve());
  }, [task, status]);

  const apiKeys = React.useMemo(
    () => _apiKeys.map(k => ({ ...k, user: getUserById(k.app_user_id) })),
    [_apiKeys, getUserById]
  );

  return {
    createdApiKey,
    setCreatedApiKey,
    create,
    error: error ? error.message || JSON.stringify(error.detail) || 'The server encountered an error' : undefined,
    apiKeys,
    resetError,
    status,
    remove,
    refresh
  };
}
