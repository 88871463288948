import React from 'react';

import Typography from 'snap-ui/Typography';

import { CategoryContainer, CategorySection, SubTitle } from '../../Landing.style';
import { useLandingCatalog } from '../../LandingProvider';

export default function SeverityDetail() {
  const { source } = useLandingCatalog();
  const inTheWild = source.observed_in_the_wild ? 'Yes' : 'No';
  const wasZeroDay = source.was_zero_day ? 'Yes' : 'No';

  if (!source.risk_rating && !source.exploitation_state && !source.observed_in_the_wild && !source.was_zero_day)
    return null;
  return (
    <CategorySection>
      <Typography variant='h4'>Severity</Typography>
      <CategoryContainer className='row'>
        <div className='category-detail-item'>
          <SubTitle variant='subtitle2'>Risk Rating</SubTitle>
          <Typography variant='h4' className={`sa-cap ${source.risk_rating}`}>
            {source.risk_rating}
          </Typography>
        </div>
        <div className='category-detail-item'>
          <SubTitle variant='subtitle2'>Exploitation State</SubTitle>
          <Typography variant='h4' className={`sa-cap ${source.exploitation_state}`}>
            {source.exploitation_state}
          </Typography>
        </div>
        <div className='category-detail-item'>
          <SubTitle variant='subtitle2'>Exploited as Zero-Day</SubTitle>
          <Typography variant='h4' className={`sa-cap ${wasZeroDay}`}>
            {wasZeroDay}
          </Typography>
        </div>
        <div className='category-detail-item'>
          <SubTitle variant='subtitle2'>Exploited in the Wild</SubTitle>
          <Typography variant='h4' className={`sa-cap ${inTheWild}`}>
            {inTheWild}
          </Typography>
        </div>
      </CategoryContainer>
    </CategorySection>
  );
}
