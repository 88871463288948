import { CancelTokenSource } from 'axios';
import { z } from 'zod';

import { Guid } from 'types/common';
import { safelyParse } from 'types/common.zod';

import { getJobGroups, getJobOverview, getJobResult, getJobs, getSingleJob, postJobs } from './Job.api';
import {
  JobGroup,
  JobGroupOutputSchema,
  JobGroupSchema,
  JobOverviewFilters,
  JobResult,
  JobResultSchema,
  JobSchema,
  JobsOutputSchema,
  PostJobsInput
} from './Job.type';

export async function postAsyncJobs(body: PostJobsInput) {
  const data = await postJobs(body);
  return safelyParse<JobGroup>(data, JobGroupSchema);
}

export async function getAsyncJobs() {
  const data = await getJobs();
  return safelyParse<z.infer<typeof JobsOutputSchema>>(data, JobsOutputSchema);
}

export async function getSingleAsyncJob(guid: Guid) {
  const data = await getSingleJob(guid);
  return safelyParse<z.infer<typeof JobSchema>>(data, JobSchema);
}

export async function getAsyncJobOverview(params: JobOverviewFilters, cancelTokenSource?: CancelTokenSource) {
  return await getJobOverview(params, cancelTokenSource);
}

export async function getAsyncTaskGroupJobs() {
  const data = await getJobGroups();
  return safelyParse<z.infer<typeof JobGroupOutputSchema>>(data, JobGroupOutputSchema);
}

export async function getAsyncJobResult(guid: Guid) {
  const data = await getJobResult(guid);
  return safelyParse<JobResult>(data, JobResultSchema);
}
