import React from 'react';

import { faHeadset } from '@fortawesome/pro-solid-svg-icons';

import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

const Container = styled(Typography)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${p => p.theme.spacing(3)};

  svg {
    color: ${p => p.theme.palette.aqua.main};
  }
` as typeof Typography;

export function NoPlayer() {
  return (
    <Container variant='h3' component='div'>
      No Players <Icon icon={faHeadset} />
    </Container>
  );
}
