import React from 'react';

import _Card, { CardContent } from 'snap-ui/Card';
import Divider from 'snap-ui/Divider';
import Tooltip, { TooltipProps } from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { MarkdownRead } from 'module/Widgets/Markdown';

import { HyperTag } from '../Landing/Landing.type';
import TagAlias from '../Tag/TagAlias';

const MAX_CHARS = 500;

const Card = styled(_Card)`
  .uppercase {
    text-transform: uppercase;
  }
`;

export default function TagLandingPreview({
  tag,
  children
}: {
  tag: Partial<HyperTag>;
  children: TooltipProps['children'];
}) {
  const [isOnTagHover, setOnTagHover] = React.useState<boolean>(false);
  let trimmedDescription = 'No Description Available';
  if (tag?.description) {
    trimmedDescription = tag.description.substring(0, MAX_CHARS) + `${tag.description.length > MAX_CHARS ? '...' : ''}`;
  }

  return (
    <Tooltip
      onOpen={() => setOnTagHover(true)}
      title={
        <Card>
          <CardContent>
            <Typography variant='body2'>{tag.name}</Typography>
            {tag.external_source && (
              <Typography variant='caption' color='text.secondary' className='uppercase'>
                source: {tag.external_source}
              </Typography>
            )}
            <Divider sx={{ marginBottom: '8px' }} />
            <MarkdownRead
              value={trimmedDescription}
              restricted
              to={`${Path.Collection}/${tag.discriminator}/${encodeURIComponent(tag.name)}`}
            />
            <TagAlias tag={tag} onTrigger={isOnTagHover} />
          </CardContent>
        </Card>
      }
      placement='top'
      arrow
      enterDelay={300}
      enterNextDelay={300}
      wrap
    >
      {children}
    </Tooltip>
  );
}
