import React, { MutableRefObject } from 'react';

import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import Icon from 'snap-ui/Icon';
import { styled, useTheme } from 'snap-ui/util';

import { CompositeMarkerLoadState } from 'aso/useCompositeMarker';

import useQueryString from 'hooks/useQueryString';
import useTitle from 'hooks/useTitle';

import { DetectionStatsState } from 'module/Detection/useDetectionStats';
import * as SessionTypes from 'module/Session/Session.type';
import NotFound from 'module/Util/Fallback/NotFound';

import { usePushSnack } from 'provider';

import { Status } from 'storage';

import { ArtifactType, Guid } from 'types/common';
import { StrictReactNode } from 'types/core';

import { getQueryParam } from 'utilities/SearchParam';

import { ArtifactStyle } from '../Session.style';
import VideoPlayer from '../VideoPlayer';

const Container = styled(ArtifactStyle)`
  .Artifact-content > * {
    margin-bottom: ${p => p.theme.spacing(7)};
  }

  .SessionCore-fullscreen {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

type Props = {
  error: any;
  className?: string;
  data: SessionTypes.Session;
  children: React.ReactChildren | React.ReactChild | React.ReactNode;
  composite: CompositeMarkerLoadState;
  hasHostGraph: boolean;
  host: SessionTypes.Host;
  hostSelector?: React.ReactNode;
  detectionStats: DetectionStatsState;
  meta: StrictReactNode;
  sessionGuid: Guid;
  startTime: string;
  videoRef: MutableRefObject<HTMLVideoElement>;
  videoDurationChange?: (duration: number) => void;
  preview: boolean;
  tabs?: React.ReactNode;
};

export default function SessionCore(props: Props): React.ReactElement {
  const { palette } = useTheme();
  const pushSnack = usePushSnack();
  const { location } = useHistory();
  const { update: updateQuery } = useQueryString(true);
  const host = props.host;
  const hostSelectorRef = React.useRef(); // The video player top level div

  const topic = getQueryParam(location.search, 'topic');

  const session = props.data;
  useTitle(`${session.name || 'Threat'} | SnapAttack`);

  const handleRefreshMarker = () => {
    props.detectionStats.refresh();
    props.composite.refresh();
  };

  const redMarkerLength = props.composite.markers?.red?.length;
  React.useEffect(() => {
    if (props.composite.status === Status.resolved && redMarkerLength === 0) {
      pushSnack(
        <>
          This threat doesn&apos;t have any labeled attacks. Help users filter and validate true positive detection hits
          by labeling an attack. You can do this by clicking the <Icon icon={faStar} color={palette.common.white} />{' '}
          star icon next to an detection hit, or from the process graph or event log pages.
        </>,
        'info',
        'center',
        'bottom',
        30000
      );
    }
  }, [props.composite.status, redMarkerLength, pushSnack, palette]);

  React.useEffect(() => {
    if (topic !== ArtifactType.Marker) updateQuery({ topic: ArtifactType.Marker }, undefined);
  }, [topic, updateQuery]);

  if (props.error && !props.preview) {
    return (
      <NotFound artifact={ArtifactType.Session} error={{ response: 'threat not found', tag: props.sessionGuid }} />
    );
  } else
    return (
      <Container meta={props.meta} type={ArtifactType.Session}>
        <>
          {props.children}
          {!props.preview && (
            <>
              <div className='SessionCore-fullscreen' ref={hostSelectorRef}>
                {props.hostSelector}
                <VideoPlayer
                  activeHost={host}
                  onDurationChange={props.videoDurationChange}
                  videoRef={props.videoRef}
                  hostSelectorRef={hostSelectorRef}
                  startTime={props.startTime}
                  hasHostGraph={props.hasHostGraph}
                  session={session}
                  composite={props.composite}
                  onRefreshMarker={handleRefreshMarker}
                />
              </div>
              {props.tabs}
            </>
          )}
        </>
      </Container>
    );
}
