import React from 'react';

import Typography from 'snap-ui/Typography';

import { CategoryContainer } from '../Landing.style';
import { ReactComponent as MandiantLogo } from './MandiantLogo.svg';

function LandingMandiantLogo() {
  return (
    <>
      <Typography className='mandiantLogo-prefix' variant='h6'>
        POWERED BY
      </Typography>
      <CategoryContainer>
        <MandiantLogo width={125} />
      </CategoryContainer>
    </>
  );
}

export default LandingMandiantLogo;
