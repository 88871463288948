import React, { Ref } from 'react';

import { faEyeSlash, faGear } from '@fortawesome/pro-solid-svg-icons';
import { useHistory, useLocation } from 'react-router-dom';

import { ActionIconButton } from 'snap-ui/Button';
import PieChart, { PieChartProps } from 'snap-ui/Charts/PieChart';
import SimpleLineChart from 'snap-ui/Charts/SimpleLineChart';
import ShouldLink from 'snap-ui/Charts/components/ShouldLink';
import Grid from 'snap-ui/Grid';
import Placeholder from 'snap-ui/Placeholder';
import Tooltip from 'snap-ui/Tooltip';
import { styled, useTheme } from 'snap-ui/util';

import useHover from 'hooks/useHover';

import { DEMO_INTEGRATION } from 'module/Dashboard/SocDashboard/SocDashboard.const';
import { PieChartData, SimpleLineChartData } from 'module/Dashboard/SocDashboard/SocDashboard.type';
import ChartPanel from 'module/Dashboard/core/ChartPanel';
import DashboardGrid from 'module/Dashboard/core/DashboardGrid';
import PreparednessGrade from 'module/Dashboard/core/PreparednessGrade';
import { DetectionDeploymentStatus } from 'module/GlobalFilter/Filters/DeployedEnvironment';

import { ArtifactType, Guid } from 'types/common';

import { formatQueryString, parseAnyQueryString } from 'utilities/SearchParam';

import history from '../../../../history';

const Grades = {
  A_PLUS: { score: 0.9, value: 'A+' },
  A: { score: 0.6, value: 'A' },
  B: { score: 0.41, value: 'B' },
  C: { score: 0.21, value: 'C' },
  D: { score: 0.11, value: 'D' },
  F: { score: 0.1, value: 'F' }
} as const;

type Grade = (typeof Grades)[keyof typeof Grades]['value'];

export function grade(score: number): Grade {
  if (score >= Grades.A_PLUS.score) return Grades.A_PLUS.value;
  else if (score >= Grades.A.score) return Grades.A.value;
  else if (score >= Grades.B.score) return Grades.B.value;
  else if (score >= Grades.C.score) return Grades.C.value;
  else if (score >= Grades.D.score) return Grades.D.value;
  return Grades.F.value;
}

const EXAMPLE_DATA = [
  {
    value: 200
  },
  {
    value: 300
  },
  {
    value: 200
  },
  {
    value: 400
  },
  {
    value: 100
  },
  {
    value: 500
  },
  {
    value: 600
  }
];

const StyledGrid = styled(Grid, { shouldForwardProp: p => p !== 'height' })`
  opacity: 0.5;
  height: 404px;
  ${p => `height: ${p.height};`}
`;

export const Text = styled('div')`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
`;

const StyledLink = styled(ShouldLink)`
  color: ${p => p.theme.palette.common.white};
`;

const StyledTitle = styled('div')`
  padding-right: ${p => p.theme.spacing(7)};

  :hover {
    .remove-integration {
      display: inline-block;
    }
  }
  .remove-integration {
    display: none;
  }
`;

const PieChartDefaultProperties: Partial<PieChartProps> = {
  useLegend: true,
  showValueInLegend: true,
  useTooltip: true,
  legendProps: {
    layout: 'horizontal',
    verticalAlign: 'bottom',
    align: 'center'
  }
};

type IntegrationProps = {
  detectionData: SimpleLineChartData;
  detectionHits: number;
  detections: number;
  letterGrade: string;
  title: string;
  validatedDetections: PieChartData;
  index: number;
  guid: Guid;
  languageID: number[];
  confidenceData: PieChartData;
  demoMode?: boolean;
  handleDeleteIntegration?: (id: string) => void;
  id?: string;
};

export function Integration({
  detectionData,
  detectionHits,
  detections,
  letterGrade,
  title,
  validatedDetections,
  guid,
  languageID,
  confidenceData,
  demoMode,
  handleDeleteIntegration = () => {},
  id
}: IntegrationProps) {
  const { palette } = useTheme();
  const { search } = useLocation();

  const onPieClick = index => {
    const confidence = index.name.split(':')?.[0]; // name here is the label in the tooltip, eg 'Highest: 14'
    const to = {
      pathname: '/feed',
      search: formatQueryString({
        ...parseAnyQueryString(search),
        page: '1',
        confidence,
        deployedStatus: DetectionDeploymentStatus.success,
        topic: ArtifactType.Analytic,
        languages: languageID
      })
    };
    history.push(to);
  };

  return (
    <DashboardGrid
      title={
        <StyledTitle>
          {title}
          <Tooltip title='Remove integration from dashboard' placement='top' arrow wrap className='remove-integration'>
            <ActionIconButton
              aria-label='Remove Integration'
              icon={faEyeSlash}
              onClick={(): void => handleDeleteIntegration(id)}
            />
          </Tooltip>
        </StyledTitle>
      }
    >
      <Grid item xs={6} md={6} lg={3}>
        <ChartPanel
          centerText={
            <StyledLink
              to={
                !demoMode
                  ? {
                      pathname: '/feed',
                      search: formatQueryString({
                        ...parseAnyQueryString(search),
                        page: '1',
                        deployedEnvironment: guid,
                        topic: ArtifactType.Analytic
                      })
                    }
                  : null
              }
            >
              <PreparednessGrade letterGrade={letterGrade} text={`${detections} Detections`} />
            </StyledLink>
          }
          bottomText={
            <ShouldLink
              to={
                !demoMode
                  ? {
                      pathname: '/feed',
                      search: formatQueryString({
                        ...parseAnyQueryString(search),
                        page: '1',
                        posture: 'validatedDetections',
                        deployedStatus: DetectionDeploymentStatus.false,
                        topic: ArtifactType.Analytic,
                        languages: languageID
                      })
                    }
                  : null
              }
              className='no-print'
            >
              Review Compatible Detections
            </ShouldLink>
          }
        >
          <SimpleLineChart data={detectionData} color={palette.lime.main} />
        </ChartPanel>
      </Grid>
      <Grid item xs={6} md={6} lg={3}>
        <ChartPanel
          title='Validated Detections'
          centerText={
            <PieChart
              {...PieChartDefaultProperties}
              height={324}
              data={validatedDetections}
              colors={[palette.success, palette.red, palette.greyish]}
            />
          }
        />
      </Grid>
      <Grid item xs={6} md={6} lg={3}>
        <ChartPanel
          title='Detection Confidence'
          centerText={
            <PieChart
              data={confidenceData}
              {...PieChartDefaultProperties}
              onPieClick={onPieClick}
              height={324}
              colors={[palette.red, palette.orange, palette.warning, palette.success, palette.blue]}
            />
          }
        />
      </Grid>
      {detectionHits === 0 ? (
        <OnHoverWrapper
          onHoverComponent={
            <ChartPanel title='Detection Hits'>
              <Text>Configure bi-directional integration to see deployed detection hits in SnapAttack</Text>
            </ChartPanel>
          }
        >
          <ChartPanel title='Detection Hits' centerText={<>{detectionHits}</>} />
        </OnHoverWrapper>
      ) : (
        <Grid item xs={6} md={6} lg={3}>
          <ChartPanel title='Detection Hits' centerText={<>{detectionHits}</>} />
        </Grid>
      )}
    </DashboardGrid>
  );
}

type OnHoverWrapperProps = {
  children: React.ReactElement;
  onHoverComponent: React.ReactElement;
  xs?: number;
  md?: number;
  lg?: number;
  height?: string;
  minHeight?: number;
};

export function OnHoverWrapper({ children, onHoverComponent, xs = 6, md = 6, lg = 3, minHeight }: OnHoverWrapperProps) {
  const [hoverRef, isHovering] = useHover() as [Ref<HTMLDivElement>, boolean];

  return (
    <StyledGrid item xs={xs} md={md} lg={lg} ref={hoverRef} height='auto' minHeight={minHeight}>
      {isHovering === true ? onHoverComponent : children}
    </StyledGrid>
  );
}

export function IntegrationExample() {
  const { palette } = useTheme();
  const { push } = useHistory();

  const onHoverComponent = (
    <ChartPanel
      title='Configure Integration'
      centerText={
        <ActionIconButton
          aria-label={`Configure Integration`}
          icon={faGear}
          onClick={() => {
            push({
              pathname: '/settings/integrations'
            });
          }}
          size='large'
          IconProps={{ size: '4x' }}
        />
      }
    />
  );

  return (
    <DashboardGrid title='SIEM' className='no-print'>
      <OnHoverWrapper onHoverComponent={onHoverComponent}>
        <ChartPanel
          centerText={<PreparednessGrade letterGrade={'A'} text={`175 Detections`} />}
          bottomText='Review Compatible Detections'
        >
          <SimpleLineChart data={EXAMPLE_DATA} color={palette.lime.main} />
        </ChartPanel>
      </OnHoverWrapper>
      <OnHoverWrapper onHoverComponent={onHoverComponent}>
        <ChartPanel
          title='Validated Detections'
          centerText={
            <PieChart
              {...PieChartDefaultProperties}
              data={[
                { name: 'Tested', value: 125 },
                { name: 'Not Tested', value: 33 },
                { name: 'Not Applicable', value: 17 }
              ]}
              colors={[palette.success, palette.red, palette.greyish]}
            />
          }
        />
      </OnHoverWrapper>
      <OnHoverWrapper onHoverComponent={onHoverComponent}>
        <ChartPanel
          title='Confidence'
          centerText={
            <PieChart
              data={[
                { name: 'Highest', value: 500 },
                { name: 'High', value: 233 },
                { name: 'Medium', value: 355 },
                { name: 'Low', value: 134 },
                { name: 'Lowest', value: 45 }
              ]}
              {...PieChartDefaultProperties}
              colors={[palette.red, palette.orange, palette.warning, palette.success, palette.blue]}
            />
          }
        />
      </OnHoverWrapper>
      <OnHoverWrapper onHoverComponent={onHoverComponent}>
        <ChartPanel title='Detection Hits' centerText={492} />
      </OnHoverWrapper>
    </DashboardGrid>
  );
}

export function PlaceHolderIntegration() {
  return (
    <DashboardGrid>
      <Grid item xs={6} md={6} lg={3}>
        <Placeholder variant='rectangular' height={325} />
      </Grid>
      <Grid item xs={6} md={6} lg={3}>
        <Placeholder variant='rectangular' height={325} />
      </Grid>
      <Grid item xs={6} md={6} lg={3}>
        <Placeholder variant='rectangular' height={325} />
      </Grid>
      <Grid item xs={6} md={6} lg={3}>
        <Placeholder variant='rectangular' height={325} />
      </Grid>
    </DashboardGrid>
  );
}

export function FullWidthPlaceHolder() {
  return (
    <DashboardGrid>
      <Grid item xs={12} md={12} lg={12}>
        <Placeholder variant='rectangular' height={325} />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <Placeholder variant='rectangular' height={325} />
      </Grid>
    </DashboardGrid>
  );
}

export function IntegrationDemo() {
  return (
    <>
      {DEMO_INTEGRATION.map((integration, idx) => (
        <Integration
          demoMode
          key={idx}
          detectionData={integration.detectionData}
          detectionHits={integration.hits}
          detections={integration.deployments}
          letterGrade={grade(integration.score)}
          title={integration.name}
          guid={integration.guid}
          languageID={integration.languageIds}
          validatedDetections={integration.validatedDetections}
          index={idx}
          confidenceData={integration.confidenceData}
        />
      ))}
    </>
  );
}
