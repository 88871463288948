import { AutocompleteV2 } from 'snap-ui/Autocomplete/AutocompleteV2';
import { Select } from 'snap-ui/Select';
import { styled } from 'snap-ui/util';

export const AutocompleteCell = styled(AutocompleteV2)`
  width: 100%;
  margin-left: ${p => p.theme.spacing(4)};

  fieldset {
    border: none;
  }

  .MuiInputBase-input {
    margin-right: ${p => p.theme.spacing(4)};
  }
`;

export const SelectCell = styled(Select)`
  width: 100%;
  fieldset {
    border: none;
  }
`;

export const AutocompleteLi = styled('li')`
  gap: ${p => p.theme.spacing(3)};
`;
