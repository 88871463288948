import React from 'react';

import { useField } from 'formik';

import FormControl, { FormHelperText } from 'snap-ui/FormControl';

import { Discriminator } from '../Tag.type';
import TagAutocompleteFormik from '../TagAutocompleteFormik';

export function BulkCatalogFormik() {
  const [, meta] = useField('catalog');
  const hasError = !!(meta.touched && meta.error);
  return (
    <FormControl className='BulkTag-catalog' error={hasError} component='fieldset' variant='standard'>
      <TagAutocompleteFormik name='attack_names' discriminator={Discriminator.Attack} multiple />
      <TagAutocompleteFormik name='actor_names' discriminator={Discriminator.Actor} multiple />
      <TagAutocompleteFormik name='software_names' discriminator={Discriminator.Software} multiple />
      <TagAutocompleteFormik name='vulnerability_names' discriminator={Discriminator.Vulnerability} multiple />
      {hasError && <FormHelperText error>{meta.error}</FormHelperText>}
    </FormControl>
  );
}
