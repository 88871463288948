import React from 'react';

import { useLocation } from 'react-router-dom';

import CircularProgress from 'snap-ui/CircularProgress';
import Link from 'snap-ui/Link';

import { useImpersonate } from 'module/Impersonate';

import { useAuth } from 'provider';

import { correctIndefiniteArticle } from 'utilities/TextUtils/TextUtils';

import Banner from './Banner';

export default function ImpersonateBanner() {
  const location = useLocation();

  const { unpersonate, isPending } = useImpersonate();

  const { user, permission, defaultOrgId } = useAuth();
  if (!user || !user.impersonator) return null;

  const org = permission.find(o => o.id === defaultOrgId);
  return (
    <Banner>
      Viewing as {correctIndefiniteArticle(org?.name)} user.
      {isPending ? (
        <CircularProgress color='secondary' size={20} />
      ) : (
        <Link
          id='ClearImpersonationLink'
          ariaLabel='Clear Impersonation'
          disabled={isPending}
          to={location}
          sx={{
            marginLeft: '1rem'
          }}
          onClick={() => unpersonate()}
        >
          Clear
        </Link>
      )}
    </Banner>
  );
}
