import { styled } from 'snap-ui/util';

import { Artifact } from 'module/Layout';

export const StyledArtifact = styled(Artifact)`
  .LandingEdit-meta {
    display: flex;
    gap: ${p => p.theme.spacing(3)};
    align-items: baseline;
  }

  .AliasOf {
    margin-left: ${p => p.theme.spacing(4)};
    display: flex;
    gap: ${p => p.theme.spacing(4)};
    align-items: center;
  }

  .ArtifactWidget-title {
    text-transform: capitalize;
  }

  .ArtifactWidget-menuWrapper {
    display: flex;
    align-items: center;
    gap: ${p => p.theme.spacing(3)};
  }
`;
