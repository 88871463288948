import React from 'react';

import { faArrowRightFromArc } from '@fortawesome/pro-solid-svg-icons';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { RouteComponentProps } from 'react-router-dom';

import Chip from 'snap-ui/Chip';
import Icon from 'snap-ui/Icon';
import ToggleButton from 'snap-ui/ToggleButton';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import useCompositeMarker from 'aso/useCompositeMarker';

import Path from 'constants/paths';

import useLocationState from 'hooks/useLocationState';
import useSplunkLink from 'hooks/useSplunkLink';
import useTitle from 'hooks/useTitle';

import { Engage, Fingerprint } from 'lib/Engagement';

import { JobList, useJobList } from 'module/BAS/JobList';
import { EntityScriptsProvider, useEntityScripts } from 'module/BAS/ScriptList';
import { DetectionStatsResources } from 'module/Detection';
import useDetectionStats from 'module/Detection/useDetectionStats';
import { useFilterRegistry } from 'module/GlobalFilter';
import { useFilterSidebar } from 'module/Scaffold/useStashSidebar';
import * as SessionTypes from 'module/Session/Session.type';
import SessionCore from 'module/Session/SessionCore';
import SessionTabs from 'module/Session/SessionCore/SessionTabs';
import useSession from 'module/Session/useSession';
import useSessionHost from 'module/Session/useSessionHost';

import { useAuth } from 'provider';

import * as AuthTypes from 'types/auth';
import * as CommonTypes from 'types/common';

import { compositeSearchFilter } from 'utilities/CompositeMarker/CompositeMarket';
import { formatQueryString } from 'utilities/SearchParam';
import { splunkDashboardUrl } from 'utilities/SplunkUtils';

import HostSelector from '../HostSelector';
import { SessionDetailsPlaceholder } from '../Session.helper';
import SessionKeystrokes from '../SessionCore/SessionKeystrokes';
import SessionExport from '../SessionExport';
import Timeline from '../Timeline';
import useAnalyticRecommendations from '../useAnalyticRecommendations';
import SessionDetails from './SessionDetails';
import SessionViewHeader from './SessionViewHeader';

const StyledToggleButton = styled(ToggleButton)`
  margin-right: ${p => p.theme.spacing(2)};
`;

const StyledChip = styled(Chip)`
  line-height: 16px;
`;

interface SessionProps extends RouteComponentProps<{ guid: string }> {
  className?: string;
  currentVote?: CommonTypes.Vote;
  session?: SessionTypes.Session;
  voteOnSession?(sessionId: string | number, vote: CommonTypes.VoteName): Promise<void>;
}

function SessionView(props: SessionProps): React.ReactElement {
  useFilterSidebar(CommonTypes.ArtifactType.Marker, false, true);
  const { permission: organizations } = useAuth();
  const { item } = useLocationState<SessionTypes.SessionRouterState>() || {};

  const sessionGuid = props.match.params.guid;
  const { session, error, refresh: refreshSession, preview } = useSession(sessionGuid, item);
  const { data: recommendations, status } = useAnalyticRecommendations(session.guid);

  const { values: feedFilterValues } = useFilterRegistry();
  const { query } = feedFilterValues || {};

  useTitle(`${session.name || 'Threat'} | SnapAttack`);
  const host = useSessionHost(session.hosts);
  const scripts = useEntityScripts();
  const jobListAsync = useJobList({ session: sessionGuid });
  const detectionStats = useDetectionStats(DetectionStatsResources.session, sessionGuid);
  const compositeOptions = useCompositeMarker(
    sessionGuid,
    detectionStats.detection,
    host.start,
    host.is_victim ? host.machine?.name : '',
    true,
    recommendations
  );

  const { hostLogDashboard } = useSplunkLink();
  const [autoScroll, setAutoScroll] = React.useState(true);
  const [videoDuration, setVideoDuration] = React.useState(0);
  const hasHostGraph = host.graph && host.graph.length > 0;

  const composite = React.useMemo(() => {
    return compositeSearchFilter(compositeOptions, query as string);
  }, [compositeOptions, query]);

  const isRedMarkerCreated = !isEmpty(composite.markers.red);

  const videoRef = React.useRef<HTMLVideoElement>();

  const startTime = get<SessionTypes.Host, 'start', string>(
    host,
    'start',
    get<SessionTypes.Session, 'creation'>(session, 'creation')
  );

  React.useEffect(() => {
    if (session.guid && session.name) {
      Engage.track(
        Fingerprint.load(Path.Threat).withData({
          content: AuthTypes.ContentPermission.Read,
          guid: session.guid,
          name: session.name,
          artifact_organization_id: session.organization_id,
          artifact_organization_name: organizations?.find(o => o.id === session.organization_id)?.name
        })
      );
      Engage.trackPersonIncrement(`view ${CommonTypes.ArtifactType.Session}`, 1);
    }
  }, [session.guid, session.name, session.organization_id, organizations]);

  const handleRefreshMarker = () => {
    detectionStats.refresh();
    composite.refresh();
  };

  return (
    <SessionCore
      className={props.className}
      data={session}
      error={error.session || error.permissions || error.references || error.hosts}
      host={host}
      composite={composite}
      detectionStats={detectionStats}
      meta={
        <SessionViewHeader
          session={session}
          detectionStats={detectionStats}
          onRefresh={refreshSession}
          preview={preview}
          scripts={scripts}
        />
      }
      sessionGuid={sessionGuid}
      startTime={startTime}
      videoRef={videoRef}
      videoDurationChange={setVideoDuration}
      preview={preview}
      hasHostGraph={hasHostGraph}
      hostSelector={
        <HostSelector
          activeHostName={host.machine.name}
          hosts={session?.hosts}
          getCurrentTime={() => videoRef.current?.currentTime | 0 || 0}
        />
      }
      tabs={
        <SessionTabs
          exportComponent={<SessionExport sessionGuid={sessionGuid} sessionName={session.name} />}
          graphUrl={
            !host.is_attacker && hasHostGraph
              ? `${Path.ProcessGraph}${formatQueryString({
                  hostId: host.guid,
                  hostname: host.machine.name,
                  nodeId: host.machine.name,
                  threatId: session.guid
                })}`
              : null
          }
          keystrokesComponent={<SessionKeystrokes url={host.keystrokes} hostname={host.machine.name} />}
          keystrokesLabel='Keystrokes'
          jobListComponent={jobListAsync?.data?.total ? <JobList {...jobListAsync} /> : null}
          jobListLabel={
            jobListAsync?.data?.total ? (
              <>
                Simulations <StyledChip size='small' label={jobListAsync.data?.total} />
              </>
            ) : null
          }
          timelineComponent={
            <Timeline
              activeHost={host}
              duration={videoDuration}
              session={session}
              composite={composite}
              startTime={startTime}
              videoRef={videoRef}
              hasHostGraph={hasHostGraph}
              onRefreshMarker={handleRefreshMarker}
              autoScroll={autoScroll}
              recommendations={recommendations}
            />
          }
          timelineLabel={
            <>
              <Tooltip title='Toggle timeline autoscroll' placement='top' arrow>
                <StyledToggleButton
                  id='SessionAutoscrollToggle'
                  value='autoscroll'
                  aria-label='Toggle timeline autoscroll'
                  onClick={() => setAutoScroll(a => !a)}
                  color='primary'
                  selected={autoScroll}
                >
                  <Icon icon={faArrowRightFromArc} />
                </StyledToggleButton>
              </Tooltip>
              Timeline{' '}
              <StyledChip
                size='small'
                label={
                  composite.markers.unfilteredTotalByHost - composite.markers.all.length === 0
                    ? `showing ${composite.markers.unfilteredTotalByHost} for this host`
                    : ` showing ${composite.markers.all.length} / ${composite.markers.unfilteredTotalByHost} for this host`
                }
              />
            </>
          }
          splunkUrl={splunkDashboardUrl({
            dashboard: hostLogDashboard,
            session: session
          })}
        />
      }
    >
      {(session && session.guid) || preview ? (
        <SessionDetails
          session={session}
          preview={preview}
          recommendations={recommendations}
          status={status}
          scripts={scripts}
          isRedMarkerCreated={isRedMarkerCreated}
        />
      ) : (
        <SessionDetailsPlaceholder />
      )}
    </SessionCore>
  );
}

export default function SessionViewWrapper(props: SessionProps) {
  return (
    <EntityScriptsProvider guid={props.match.params.guid} type={CommonTypes.ArtifactType.Session}>
      <SessionView {...props} />
    </EntityScriptsProvider>
  );
}
