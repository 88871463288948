import Card from 'snap-ui/Card';
import { styled } from 'snap-ui/util';

export const AbSection = styled(Card)`
  &.disabled {
    .Mui-disabled {
      -webkit-text-fill-color: ${p => p.theme.palette.common.white};
      cursor: not-allowed;
    }

    opacity: 0.95;

    & .title {
      margin-bottom: ${p => p.theme.spacing(4)};
    }

    & .ab-section-condition {
      grid-template-columns: minmax(260px, auto) 150px 1fr;

      .modifier,
      .ab-all {
        .MuiAutocomplete-endAdornment {
          display: none;
        }
      }
    }
  }

  .ab-section-condition {
    display: grid;
    grid-template-columns: minmax(225px, auto) 150px 1fr ${p => p.theme.spacing(7)};
    grid-gap: ${p => p.theme.spacing(3)};
    align-items: center;
    justify-items: center;

    &:not(:last-child) {
      margin-bottom: ${p => p.theme.spacing(7)};
    }

    & > * {
      margin-bottom: 0;
    }

    .value {
      justify-self: stretch;
      grid-column-start: 3;

      &.custom-event-id fieldset {
        border: 1px solid ${p => p.theme.palette.warning.main};
      }
    }

    .field {
      grid-column-start: 1;
      justify-self: stretch;

      &.custom-field fieldset {
        border: 1px solid ${p => p.theme.palette.warning.main};
      }
    }
    .modifier {
      grid-column-start: 2;
      justify-self: stretch;

      & input.MuiAutocomplete-input {
        padding-right: ${p => p.theme.spacing(2)} !important;
      }

      & > .dropdown:not('.icon') {
        min-width: 150px;
      }
    }

    .ab-all {
      grid-column-start: 2;
      grid-row: 2;
      justify-self: stretch;
    }

    .add-field-rule {
      grid-column-start: 4;
      align-self: start;
    }

    &.strings {
      grid-template-columns: 1fr ${p => p.theme.spacing(7)};

      .value {
        grid-column-start: 1;
      }

      .add-field-rule {
        grid-column-start: 2;
      }
    }
  }

  .ab-section-actions {
    text-align: right;
  }

  .ButtonEndAdornment {
    position: absolute;
    right: ${p => p.theme.spacing(1)};
  }
`;
