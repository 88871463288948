import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { DisplayKind, SortOrder } from 'module/Feed/Feed.type';
import { SearchDisplayPreference } from 'module/SearchBar/SeachBar';

import { updateCurrentUser, useAuth } from 'provider/Account';

import { useAsync } from 'storage';

import { Config, Context } from './UserConfig.type';

const INITIAL_CONFIG: Config = {
  display: DisplayKind.Card,
  sortOrder: SortOrder.modified,
  searchDisplay: SearchDisplayPreference.Content,
  columnModel: {},
  sortModel: [],
  columnOrderModel: {},
  enableAlpha: false,
  showAiDescriptions: true,
  tuningFields: {}
};

const UserConfigContext = React.createContext<Context>(null);
UserConfigContext.displayName = 'UserConfigContext';

function useUserConfig(): Context {
  const context = React.useContext(UserConfigContext);

  if (!context) {
    throw new Error('useUserConfig must be used within the UserConfigContext');
  }

  return context;
}

function UserConfigProvider(props: { children: React.ReactNode; value?: Context }) {
  const { user } = useAuth();

  const { children } = props;

  const { data, setData, task } = useAsync<Config>(INITIAL_CONFIG);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!loaded && !isEmpty(user.preference)) {
      setData({ ...INITIAL_CONFIG, ...user.preference?.frontend });
      setLoaded(true);
    }
  }, [loaded, setData, user.preference]);

  const setWith = React.useCallback(
    (func: (d: Config) => Config) => {
      const result = func(data);
      task(updateCurrentUser({ preference: { frontend: result } }));
      // optimistic update
      setData(result);
    },
    [data, task, setData]
  );

  const updateStorage = React.useCallback(
    (update: Partial<Config>) => {
      setWith(d => ({ ...d, ...update }));
    },
    [setWith]
  );

  const setDisplayKind = React.useCallback((display: DisplayKind) => updateStorage({ display }), [updateStorage]);

  const setSearchDisplayPreference = React.useCallback(
    (searchDisplay: SearchDisplayPreference) => updateStorage({ searchDisplay }),
    [updateStorage]
  );

  const setColumnModel = React.useCallback(
    (columnModel: Config['columnModel']) => updateStorage({ columnModel }),
    [updateStorage]
  );

  const setSortModel = React.useCallback(
    (sortModel: Config['sortModel']) => updateStorage({ sortModel }),
    [updateStorage]
  );

  const setColumnOrderModel = React.useCallback(
    (columnOrderModel: Config['columnOrderModel']) => updateStorage({ columnOrderModel }),
    [updateStorage]
  );

  const setShowAiDescriptions = React.useCallback(
    (showAiDescriptions: Config['showAiDescriptions']) => updateStorage({ showAiDescriptions }),
    [updateStorage]
  );

  const setTuningFields = React.useCallback(
    (tuningFields: Config['tuningFields']) => updateStorage({ tuningFields }),
    [updateStorage]
  );

  const _setSortOrder = React.useCallback(
    (sortOrder: Config['sortOrder']) => updateStorage({ sortOrder }),
    [updateStorage]
  );

  const _setItem = React.useCallback(
    <K extends keyof Config = keyof Config>(key: K, value: Config[K]) => updateStorage({ [key]: value }),
    [updateStorage]
  );

  const value: Context = React.useMemo(() => {
    return {
      ...data,
      sorterOrder: data.sortOrder || SortOrder.modified,
      setDisplayKind,
      setSearchDisplayPreference,
      setColumnModel,
      setSortModel,
      setColumnOrderModel,
      setShowAiDescriptions,
      setTuningFields,
      _setSortOrder,
      _setItem
    };
  }, [
    data,
    setDisplayKind,
    setSearchDisplayPreference,
    setColumnModel,
    setSortModel,
    setColumnOrderModel,
    setShowAiDescriptions,
    setTuningFields,
    _setSortOrder,
    _setItem
  ]);

  return <UserConfigContext.Provider value={value}>{children}</UserConfigContext.Provider>;
}

export { UserConfigProvider, useUserConfig };
