import { Modifier } from '../const';
import Token from './Token';

function representRule(token: Token): string {
  switch (token.modifier) {
    case Modifier.exact:
      return token.comparison;
    case Modifier.full:
      return 'full text search';
    default:
      return `${token.comparison} ${Modifier[token.modifier]}`;
  }
}

/**
 * Simple custom print function to represent this token.
 * Used in Popovers
 * @param token
 */
export default function (token: Token): string {
  return `
        ${token.section}:
        ${
          !token.inclusion
            ? 'Missing from condition expression'
            : `
        (${representRule(token)})
        ${
          !token.satisfied
            ? 'Missing or unsatisfied condition'
            : token.criterion.join(token.modifier === Modifier.any ? ' | ' : ' & ')
        }`
        }
    `;
}
