import React from 'react';

import { faSearch } from '@fortawesome/pro-solid-svg-icons';

import BackdropLoader, { BackdropLoaderContainer } from 'snap-ui/BackdropLoader';
import Icon from 'snap-ui/Icon';
import Paper from 'snap-ui/Paper';
import Table from 'snap-ui/Table';
import TextField from 'snap-ui/TextField';
import { styled } from 'snap-ui/util';

import { ApiError, ErrorProps } from 'module/ApiError';
import { BASJob } from 'module/BAS/BAS.type';
import { Page } from 'module/Feed/Feed.type';
import EmptyState from 'module/Widgets/EmptyState';
import TablePagination from 'module/Widgets/TablePagination';

import { Status } from 'storage';

import ActionsColumn from './ActionsColumn';
import AgentColumn from './AgentColumn';
import AttackStatusColumn from './AttackStatusColumn';
import DateColumn from './DateColumn';
import TestCaseColumn from './TestCaseColumn';
import { ColumnComponentProps } from './common';

type JobsListProps = {
  errorProps?: ErrorProps;
  includeTestCaseColumn?: boolean;
  data: Page<BASJob>;
  refresh(): void;
  setPage(page: number): void;
  setSearchTerm(searchTerm: string): void;
  status: Status;
  className?: string;
};

function getColumns(includeTestCaseColumn = true): [string[], React.ComponentType<ColumnComponentProps>[]] {
  const headers = [];
  const components = [];

  headers.push('Date');
  components.push(DateColumn);

  if (includeTestCaseColumn) {
    headers.push('Test Case');
    components.push(TestCaseColumn);
  }

  headers.push('Result');
  components.push(AttackStatusColumn);

  headers.push('Agent');
  components.push(AgentColumn);

  headers.push('Actions');
  components.push(ActionsColumn);

  return [headers, components];
}

function JobList(props: JobsListProps): JSX.Element {
  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    props.setSearchTerm(event.target.value);
  }

  const [headers, components] = getColumns(props.includeTestCaseColumn);

  return (
    <div className={props.className}>
      {props.status === Status.rejected && (
        <div className='error-container'>
          <ApiError {...props.errorProps} />
        </div>
      )}
      <Paper>
        <div className='toolbar'>
          <TextField
            className='search-field'
            onChange={handleSearchChange}
            placeholder='Search by attack or agent name'
            startAdornment={<Icon icon={faSearch} />}
          />
        </div>
        <BackdropLoaderContainer>
          <BackdropLoader open={props.status === Status.pending} />
          {props.data.total ? (
            <Table
              columns={headers}
              component='div'
              rows={props.data.items.map((job, i) => {
                const cellProps = { job, refreshJobs: props.refresh };
                return components.map(component => React.createElement(component, { key: i, ...cellProps }));
              })}
            />
          ) : (
            <EmptyState title='No Results' />
          )}
        </BackdropLoaderContainer>
        <TablePagination
          page={props.data.page}
          changePage={props.setPage}
          numPages={Math.ceil(props.data.total / props.data.size)}
          zeroIndex
        />
      </Paper>
    </div>
  );
}

const StyledJobList = styled(JobList)`
  .error-container {
    padding: 0 24px 24px;
  }

  .toolbar {
    padding: 8px;
  }

  .search-field {
    width: 350px;
  }

  tbody td {
    height: 54px;
  }
`;

export default StyledJobList;
