import React from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Icon from 'snap-ui/Icon';
import { ListItem as MuiListItem, ListItemButton as MuiListItemButton, ListItemIcon, ListItemText } from 'snap-ui/List';
import Tooltip, { TooltipProps } from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { StrictReactNode } from 'types/core';

type Props = {
  className?: string;
  disabled?: boolean;
  icon?: IconDefinition;
  id?: string;
  onClick?(): void;
  title?: StrictReactNode;
  to: string | { pathname: string; state: any };
  tooltip?: TooltipProps['title'];
  testId?: string;
  selected?: boolean;
};

const ListItem = styled(MuiListItem)`
  .MuiListItemButton-root:hover {
    color: ${p => p.theme.palette.common.white};
  }
`;

export default function ListItemButton({ className, icon, title, tooltip, to, testId, selected, ...others }: Props) {
  return (
    <Tooltip title={tooltip ? tooltip : ''} arrow placement='right' wrap={others.disabled}>
      <ListItem disableGutters>
        <MuiListItemButton
          className={classNames('ScaffoldItem ListItemLinkIcon', className)}
          component={Link}
          to={to}
          data-testid={testId || others.id}
          selected={selected}
          {...others}
        >
          {icon && (
            <ListItemIcon>
              <Icon icon={icon} />
            </ListItemIcon>
          )}
          {title && <ListItemText>{title}</ListItemText>}
        </MuiListItemButton>
      </ListItem>
    </Tooltip>
  );
}
