import React from 'react';

import ApiFields from './ApiFields';
import MetadataFields from './MetadataFields';
import SaveButton from './SaveButton';
import { IntegrationFormFieldProps } from './TabFormFields';

function ConfigurationTab({
  integrationSchema,
  setType
}: Pick<IntegrationFormFieldProps, 'integrationSchema' | 'setType'>) {
  return (
    <>
      <MetadataFields />
      <ApiFields integrationSchema={integrationSchema} setType={setType} />
      <SaveButton />
    </>
  );
}

export default ConfigurationTab;
