import React from 'react';

import {
  Backdrop as MuiBackdrop,
  BackdropProps as MuiBackdropProps,
  CircularProgress,
  Typography
} from '@mui/material';
import { ZIndexOptions } from '@mui/material/styles/zIndex';
import classnames from 'classnames';

import { styled } from 'snap-ui/util';

import { StrictReactNode } from 'types/core';

type BackdropProps = {
  children?: StrictReactNode;
  className?: string;
  contained?: boolean;
  fixed?: boolean;
  title?: string;
  open?: boolean;
  transitionDuration?: MuiBackdropProps['transitionDuration'];
  zIndexOption?: keyof ZIndexOptions;
};

const StyledMuiBackdrop = styled(MuiBackdrop, {
  shouldForwardProp: p => p !== 'contained' && p !== 'fixed' && p !== 'zIndexOption'
})<BackdropProps>`
  color: ${p => p.theme.palette.common.white};
  background-image: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
  flex-direction: column;
  gap: ${p => p.theme.spacing(3)};
  z-index: ${p => (p.zIndexOption ? p.theme.zIndex[p.zIndexOption] : p.theme.zIndex.backdrop)};
  ${p => (p.contained ? 'position: absolute;' : 'position: relative;')};
`;

const MessageContainer = styled('div', {
  shouldForwardProp: p => p !== 'fixed'
})<BackdropProps>`
  ${p => (p.fixed ? 'position: fixed;' : 'position: absolute;')}
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
`;

export default function BackdropLoader({
  className,
  contained,
  fixed,
  title,
  open = true,
  transitionDuration,
  zIndexOption
}: BackdropProps) {
  return (
    <StyledMuiBackdrop
      className={classnames('BackdropLoader', className)}
      contained={contained}
      open={open}
      transitionDuration={transitionDuration}
      zIndexOption={zIndexOption}
    >
      <MessageContainer fixed={fixed}>
        {title && (
          <Typography variant='h3' component='div'>
            {title}
          </Typography>
        )}
        <CircularProgress className='CircularProgress' color='primary' />
      </MessageContainer>
    </StyledMuiBackdrop>
  );
}

type BackdropLoaderContainerProps = {
  className?: string;
  component?: React.ElementType;
  children: any;
};

const BackdropLoaderContainerComponent = ({ className, component = 'div', children }: BackdropLoaderContainerProps) =>
  React.createElement(component, { className: classnames('BackdropLoaderContainer', className) }, children);

export const BackdropLoaderContainer = styled(BackdropLoaderContainerComponent)`
  flex-grow: 1;
  position: relative;

  .BackdropLoader,
  .Backdrop {
    position: absolute;
    border-radius: inherit;
  }
`;

export const AppLoader = styled(({ className }: { className?: string }) => {
  return <BackdropLoader className={className} transitionDuration={1000} contained />;
})`
  background-image: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)),
    url(${process.env.PUBLIC_URL + '/images/darkpurple.jpg'});
  background-position: center;
  background-size: cover;
  z-index: ${p => p.theme.zIndex.backdropHigh};
`;

export function Backdrop({ open = true, ...props }: BackdropProps) {
  return (
    <MuiBackdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.backdrop, cursor: 'default' }}
      className={classnames('Backdrop', props.className)}
      open={open}
    >
      {props.children}
    </MuiBackdrop>
  );
}
