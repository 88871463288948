import React from 'react';

import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';
import z from 'zod';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import Button, { ActionIconButton } from 'snap-ui/Button';
import Divider from 'snap-ui/Divider';
import { FieldsLayout } from 'snap-ui/Layout';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';

import { BASJob } from 'module/BAS/BAS.type';
import AutocompleteFormik from 'module/Form/AutocompleteFormik';
import TextFieldFormik from 'module/Form/TextFieldFormik';

import { useBASTools } from 'provider/BASTools';

import { BASJobOutcome, BASJobOutcomePayload, JobOutcome, JOB_OUTCOME_ORDER } from 'types/bas';

import { getTestCaseName } from '../BAS.util';
import { OutcomeContainer, StyledFormDialog } from './JobOutcomes.style';
import { PaginateModal, usePaginateModal } from './PaginateModal';

export type JobOutcomeFormDialogPayload = {
  outcomes: BASJobOutcomePayload[];
};

type JobOutcomeFormDialogProps = {
  isLoading: boolean;
  apiError?: string;
  className?: string;
  onClose(boolean): void;
  onSubmit(values: JobOutcomeFormDialogPayload, helpers): void;
  open: boolean;
  job: BASJob;
};

const OutcomeShell = {
  detections: [],
  notes: '',
  outcome: JobOutcome.NotTested,
  tool: ''
};

const sortByOutcome = (jobA: BASJobOutcome, jobB: BASJobOutcome) =>
  JOB_OUTCOME_ORDER.indexOf(jobB.outcome) - JOB_OUTCOME_ORDER.indexOf(jobA.outcome);

function JobOutcomeFormDialog(props: JobOutcomeFormDialogProps): JSX.Element {
  return (
    <StyledFormDialog
      className={props.className}
      DialogProps={{ open: props.open, onClose: () => props.onClose(false) }}
      FormikConfig={{
        validateOnChange: true,
        enableReinitialize: true,
        initialValues: {
          outcomes: isEmpty(props.job?.outcomes)
            ? [OutcomeShell]
            : [...props.job.outcomes].sort(sortByOutcome).map(outcome => ({
                ...outcome,
                notes: outcome.notes ?? '',
                detections: outcome.detections?.map(detection => detection.guid)
              }))
        },
        onSubmit: (v: JobOutcomeFormDialogPayload) => props.onSubmit(v, true),
        zodSchema: z.object({
          outcomes: z.array(
            z.object({
              outcome: z.nativeEnum(JobOutcome)
            })
          )
        })
      }}
      SubmitProps={{
        children: props.isLoading ? 'Saving...' : 'Save',
        disabled: props.isLoading
      }}
      title={getTestCaseName(props?.job) || 'Record Outcomes'}
    >
      {!!props.apiError && (
        <Alert severity='error'>
          <AlertTitle>Oops! Something went wrong.</AlertTitle>
          {props.apiError}
        </Alert>
      )}

      <FieldsLayout>
        <Outcomes />
        <div className='auxiliary-actions'>
          <PaginateModal onSubmit={props.onSubmit} />
          <AddRecordButton />
        </div>
      </FieldsLayout>
    </StyledFormDialog>
  );
}

export default JobOutcomeFormDialog;

export function AddRecordButton(): JSX.Element {
  const { values, setValues } = useFormikContext<JobOutcomeFormDialogPayload>();

  const addOutcome = () => setValues({ ...values, outcomes: [...values.outcomes, OutcomeShell] });
  return (
    <Button variant='outlined' onClick={addOutcome}>
      Add Record
    </Button>
  );
}

export function Outcomes(): JSX.Element {
  const { toolOptions } = useBASTools();
  const { detectionOptions } = usePaginateModal();

  const { values, setValues } = useFormikContext<JobOutcomeFormDialogPayload>();

  const removeOutcome = atIndex => () => {
    const newOutcomes = values.outcomes.filter((_outcome, index) => index !== atIndex);
    setValues({ ...values, outcomes: newOutcomes });
  };

  return (
    <>
      {values.outcomes.map((outcome, index) => (
        <OutcomeContainer key={`${outcome.tool}-${index}`}>
          <div className='row outcome-header'>
            <Typography variant='h4'>
              {outcome.tool ? (
                <>
                  {outcome.outcome} by {outcome.tool}
                </>
              ) : (
                <>Add Record(s)</>
              )}
            </Typography>
            <Tooltip arrow placement='top' title='Remove Outcome' wrap>
              <ActionIconButton
                aria-label={`Remove record for ${outcome.tool}`}
                icon={faTrash}
                onClick={removeOutcome(index)}
                disabled={values.outcomes.length <= 1}
              />
            </Tooltip>
          </div>
          <div className='row'>
            <AutocompleteFormik
              required
              label='Outcome'
              name={`outcomes.${index}.outcome`}
              options={Object.values(JobOutcome).map(outcome => ({ content: outcome, value: outcome }))}
              disableClearable
              disableUserAdditions
            />
            <AutocompleteFormik label='Control' name={`outcomes.${index}.tool`} options={toolOptions} />
          </div>
          <AutocompleteFormik
            label='Detections'
            multiple
            name={`outcomes.${index}.detections`}
            options={detectionOptions}
            disableUserAdditions
          />
          <TextFieldFormik label='Notes' name={`outcomes.${index}.notes`} multiline minRows={1} />
          <Divider />
        </OutcomeContainer>
      ))}
    </>
  );
}
