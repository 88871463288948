import React from 'react';

import { basJobFeed } from 'module/BAS/BAS.api';
import { BASJob } from 'module/BAS/BAS.type';
import { Page } from 'module/Feed/Feed.type';

import { Status, useAsync } from 'storage';

import { BASJobOutcomePayload } from 'types/bas';
import { PageParams } from 'types/common';
import { Guid } from 'types/common';
import { CompositeFilter, Filter, Ops } from 'types/filter';

const DEFAULT_SIZE = 20;

const DATA_SHELL: Page<BASJob> = {
  items: [],
  page: 0,
  size: DEFAULT_SIZE,
  total: 0
};

type CampaignJobsData = {
  getPage(params?: Partial<PageParams>, filter?: Filter | CompositeFilter);
  updateOutcomes(jobId: Guid, outcomes: BASJobOutcomePayload[]): void;
  page: Page<BASJob>;
  pageSize?: number;
  status: Status;
  error: any;
};

export default function useCampaignJobs(guid: Guid, pageSize = DEFAULT_SIZE): CampaignJobsData {
  const { data: page, run, status, error, setData } = useAsync(DATA_SHELL);

  const getPage = React.useCallback(
    (params?: Partial<PageParams>, filter?: CompositeFilter) => {
      if (guid === undefined) return;
      const campaignFilter: CompositeFilter = {
        op: Ops.and,
        // if guid is null, use 'bas_campaign_id'
        items: [{ field: guid ? 'campaign.guid' : 'bas_campaign_id', op: Ops.equals, value: guid }]
      };
      if (filter) {
        campaignFilter.items.push(filter);
      }

      const pageParams = {
        page: 0,
        sort_by: `creation:${guid ? 'asc' : 'desc'}`,
        size: pageSize,
        ...params
      };

      run(basJobFeed(pageParams, campaignFilter));
    },
    [guid, pageSize, run]
  );

  const updateOutcomes = React.useCallback(
    (jobId: Guid, outcomes: BASJobOutcomePayload[]) => {
      const newItems = [...page.items];
      const newJob = newItems.find(item => item.guid === jobId);
      newJob.outcomes = outcomes.map(outcome => ({
        ...outcome,
        detections: outcome.detections?.map(detection => ({ guid: detection }))
      }));
      const newPage = { ...page, items: newItems };

      setData(newPage);
    },
    [page, setData]
  );

  return {
    getPage,
    updateOutcomes,
    page,
    status,
    error
  };
}

export function textFilter(query: string): Filter {
  return {
    field: 'text',
    op: Ops.contains,
    value: query
  };
}
