import Alert from 'snap-ui/Alert';
import Paper from 'snap-ui/Paper';
import { styled } from 'snap-ui/util';

export const StyledAlert = styled(Alert)`
  margin-bottom: ${p => p.theme.spacing(4)};
`;

export const StickyAlert = styled(StyledAlert)`
  position: sticky;
  top: 0;
  background: ${p => p.theme.palette.background.default};
  z-index: 10;
`;

export const YamlFieldContainer = styled(Paper)`
  margin-bottom: ${p => p.theme.spacing(3)};
`;
