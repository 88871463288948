import React from 'react';

import Dropzone, { DropzoneRef } from 'react-dropzone';

import Button from 'snap-ui/Button';
import { styled } from 'snap-ui/util';

import { Guid, SavedFileMetadata } from 'types/common';

import { handleBytes } from 'utilities/NumberUtil';

export type DropZoneProps = {
  id?: string;

  /**
   * The input element's aria label
   */
  label?: string;

  /**
   * Comma delimited string of file types to accept.
   * e.g. "image/png, image/jpeg"
   */
  accept?: string;

  /**
   * The open dialog component's name
   */
  dialogName?: string;
};

const UploadContainer = styled('div')`
  border: 1px dashed ${p => p.theme.palette.grey[700]};
  padding: ${p => p.theme.spacing(6)};

  .control {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin-left: ${p => p.theme.spacing(3)};
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: ${p => p.theme.spacing(3)};
    color: ${p => p.theme.palette.grey[400]};
    background: ${p => p.theme.palette.background.default};

    li {
      display: grid;
      grid-template-columns: 1fr max-content max-content;
      grid-gap: ${p => p.theme.spacing(3)};

      & + li {
        margin-top: ${p => p.theme.spacing(3)};
      }
    }

    .filename {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    button {
      padding: ${p => p.theme.spacing(1, 3)};
    }
  }

  & ~ .error-icon {
    display: none;
  }
`;

type UploadFieldProps = DropZoneProps & {
  existingFiles?: SavedFileMetadata[];
  maxFiles?: number;
  onChange: (files: File[]) => void;
  onRemoveExisting?: (guid: Guid) => void;
  toggleTable?: () => void;
};

function UploadField(props: UploadFieldProps) {
  const { accept, existingFiles = [], id, label, maxFiles, onChange, onRemoveExisting } = props;
  const [files, setFile] = React.useState<File[]>([]);
  const dropzoneRef = React.useRef<DropzoneRef>();

  React.useEffect(() => {
    onChange(files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  function handleDrop(uploadedFile: File[]): void {
    setFile(files => [...files, ...uploadedFile]);
  }

  function handleRemove(idxToRemove: number): (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void {
    return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.preventDefault();
      setFile(() => [...files.filter((_, idx) => idx !== idxToRemove)]);
    };
  }

  const openFileDialog = (): void => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  return (
    <Dropzone ref={dropzoneRef} noClick noKeyboard onDrop={handleDrop} accept={accept} maxFiles={maxFiles}>
      {({ getInputProps, getRootProps }): React.ReactElement => (
        <UploadContainer className='UploadContainer' {...getRootProps()}>
          <input {...getInputProps()} id={id} aria-label={label} />
          <p className='control'>
            Drag and Drop or
            <Button variant='outlined' onClick={openFileDialog}>
              {props.dialogName || 'Select Files'}
            </Button>
          </p>
          {!!files.length && (
            <ul>
              {files.map((file, idx) => (
                <li key={`${file.name}${idx}`}>
                  <span className='filename'>{file.name}</span>
                  <span>{handleBytes(file.size)}</span>
                  <Button color='secondary' onClick={handleRemove(idx)}>
                    Remove
                  </Button>
                </li>
              ))}
            </ul>
          )}
          {!!existingFiles.length && (
            <ul>
              {existingFiles.map((file, idx) => (
                <li key={`${file.file_name}${idx}`}>
                  <span className='filename'>{file.file_name}</span>
                  {!!onRemoveExisting && (
                    <Button color='secondary' onClick={() => onRemoveExisting(file.guid)}>
                      Remove
                    </Button>
                  )}
                </li>
              ))}
            </ul>
          )}
        </UploadContainer>
      )}
    </Dropzone>
  );
}

export default UploadField;
