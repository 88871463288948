import React from 'react';

import zod from 'zod';

import { Option } from 'snap-ui/Autocomplete';

import { Discriminator } from 'module/Tag';
import useTagOptions from 'module/Tag/useTagOptions';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';

import { FilterConfig } from '../GlobalFilter.type';
import InclusiveAutocomplete from './InclusiveAutocomplete';

type DataSourceKeys = {
  dataSource: string[];
  dataSourceOp: Ops;
};

type DataSourceFilterProps = {
  onChange(values: Partial<DataSourceKeys>): void;
  values: DataSourceKeys;
};

function DataSourceFilter({ onChange, values }: DataSourceFilterProps): React.ReactElement {
  const { options, search, searching } = useTagOptions(Discriminator.DataSource);

  const handleValueChange = (option: Option[]) => {
    const payload = { dataSource: option.map(o => o.value) };
    if (option.length === 0) payload['dataSourceOp'] = undefined;
    onChange(payload);
  };

  const handleOperatorChange = (dataSourceOp: Ops) => {
    onChange({ dataSourceOp });
  };

  return (
    <InclusiveAutocomplete
      title='By Data Source / Component'
      name='datasource_tags_dropdown'
      option={options}
      value={values.dataSource}
      onValueChange={handleValueChange}
      onOperatorChange={handleOperatorChange}
      operator={values.dataSourceOp}
      onSearch={search}
      searching={searching}
      disableInclusionGroup
    />
  );
}

function toQuery(values: DataSourceKeys) {
  if (!values.dataSource?.length) return;
  return {
    field: 'datasource_names',
    op: values.dataSourceOp,
    value: values.dataSource
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('datasource_names'),
    op: zod.nativeEnum(Ops),
    value: zod.array(zod.string())
  })
  .transform(query => ({
    dataSource: query.value,
    dataSourceOp: query.op
  }));

const DataSourceFilterConfig: FilterConfig<DataSourceKeys> = {
  defaults: { default: () => ({ dataSource: [], dataSourceOp: Ops.any }) },
  supportedTopics: [ArtifactType.Analytic],
  component: DataSourceFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default DataSourceFilterConfig;
