import React from 'react';

import { asValidationError } from 'apis/snapattack';

import { usePushSnack } from 'provider';

import { useAsync, Status } from 'storage';

import { BulkLaunchBASFormValues } from 'types/bas';
import { Guid } from 'types/common';

import { cancelCampaign, deleteCampaign, cloneCampaign } from '../BAS.api';
import { BASCampaign } from '../BAS.type';

export type CampaignInterface = {
  cancel(): Promise<boolean>;
  rerun(payload: BulkLaunchBASFormValues): Promise<BASCampaign>;
  deleteCampaign(): Promise<boolean>;
  status: Status;
};

export default function useCampaignUtils(guid: Guid): CampaignInterface {
  const { error, status, task } = useAsync(null);
  const pushSnack = usePushSnack();

  React.useEffect(() => {
    if (error) {
      const message = asValidationError(error).detail?.[0] || 'Something went wrong';
      pushSnack(message, 'error', 'center');
    }
  }, [error, pushSnack]);

  const cancel = React.useCallback(() => {
    return task(cancelCampaign(guid));
  }, [guid, task]);

  const rerun = React.useCallback(
    (payload: BulkLaunchBASFormValues) => {
      return task(cloneCampaign(guid, payload));
    },
    [guid, task]
  );

  const deleteCampaignCB = React.useCallback(() => {
    return task(deleteCampaign(guid));
  }, [guid, task]);

  return { cancel, rerun, deleteCampaign: deleteCampaignCB, status };
}
