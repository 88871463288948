import { snapattack } from 'apis/snapattack';

import { Guid } from 'types/common';

import { Entitlement, EntitlementPayload } from './Entitlements.type';

const prefix = '/org/entitlement';

/**
 * All entitlement interactions are super-admin only
 */

export function getEntitlements(): Promise<Entitlement[]> {
  return snapattack.get(`${prefix}/`).then(r => r.data);
}

export function postEntitlement(payload: EntitlementPayload): Promise<Entitlement> {
  return snapattack.post(`${prefix}/`, payload).then(r => r.data);
}

export function putEntitlement(guid: Guid, payload: EntitlementPayload): Promise<void> {
  return snapattack.put(`${prefix}/${guid}/`, payload);
}

export function deleteEntitlement(guid: Guid): Promise<void> {
  return snapattack.delete(`${prefix}/${guid}/`);
}
