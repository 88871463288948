import Link from 'snap-ui/Link';
import { styled } from 'snap-ui/util';

export const PrimaryLink = styled(Link)`
  color: ${p => p.theme.palette.primary.main};
`;

export const MarkdownReadWrapper = styled('div', { label: 'MarkdownRead-wrapper' })`
  background-color: unset;
  box-shadow: unset;
  overflow-wrap: break-word;

  /* https://stackoverflow.com/questions/19651293/how-to-use-word-wrapbreak-word-for-a-div-without-specifying-width */
  display: table;
  table-layout: fixed;
  width: 100%;

  /* The preview div */
  & div:first-of-type > div {
    background-color: unset;
    overflow: hidden;
  }

  &.restrict {
    /* margin-bottom: 8px; */

    /**
    * Calculating this in rems since we want sized based on 4 lines of text
    * plus a little extra for inner element spacing. 4.25rem seems like our
    * magical number so we don't see partial text sliced horizontally, cut
    * off by overflow hidden.
    */
    max-height: 4.25rem;
    overflow: hidden;
  }

  table {
    margin: 1em 0;
    border-collapse: collapse;
    width: 100% !important;
    table-layout: fixed;
    overflow-wrap: break-word;
  }

  thead {
    background-color: rgba(208, 141, 237, 0.1);
    color: ${p => p.theme.palette.text.primary};
  }

  th,
  td {
    border: 1px solid ${p => p.theme.palette.secondary.dark};
    padding: 15px;
    text-align: left;
  }

  tr:nth-of-type(even) td {
    background-color: ${p => p.theme.palette.secondary.main};
  }

  blockquote {
    border-left: 5px solid ${p => p.theme.palette.primary.main};
    margin-block: unset;
    margin-inline: unset;
    padding-left: 1.5em;
  }

  :not(pre) > code {
    color: #ccc;
  }

  pre {
    border-left: 2px solid ${p => p.theme.palette.primary.main};
    background-color: hsla(0, 0%, 0%, 0.1);
    padding: 1.5em;
    overflow: auto;
    margin: 1em 0;
  }

  img {
    max-width: 100%;
    display: block;
    margin: 0px auto;
  }
`;
