import { faFileCsv } from '@fortawesome/pro-solid-svg-icons';

import { CeleryTaskResponse, TaskStatus } from 'types/common';
import { Query } from 'types/filter';

export const icon = faFileCsv;

export enum ExportEndpointPrefix {
  BASJobs = '/harbor/bas/job/export',
  Detections = '/harbor/signatures/export'
}

export enum MimeType {
  json = 'json',
  csv = 'csv'
}

export type ExportRequestBase = {
  filter: Query;
  format: MimeType[];
};

export type BASJobExportRequest = ExportRequestBase;

export type DetectionExportRequest = ExportRequestBase & {
  analytic_compilation_target_id: number;
  include_confidence?: boolean;
  include_metadata?: boolean;
  include_organization?: boolean;
  include_severity?: boolean;
  include_sigma?: boolean;
  include_tags?: boolean;
  include_validation?: boolean;
};

export enum ExportJobState {
  idle = 'IDLE', // Non celery state
  pending = 'PENDING',
  success = 'SUCCESS',
  failed = 'FAILED',
  retrying = 'RETRYING',
  cancelled = 'CANCELED' // Non celery state
}

export type ExportResponse = CeleryTaskResponse;

export type ExportStatus = TaskStatus<
  {
    exported: number;
    [MimeType.csv]?: string;
    [MimeType.json]?: string;
  },
  ExportJobState
>;

export type ExportForm = {
  format: MimeType;
};

export type DetectionExportForm = ExportForm & {
  analytic_compilation_target_id: number;
  include_additional_fields: boolean;
};
