import React from 'react';

import { deployAnalytic } from 'apis/resources/analytic';

import { usePushSnack } from 'provider';

import { Status, useAsync } from 'storage';

import { Guid, Ident } from 'types/common';

import { DeployedFeeds } from './';

type DeploymentUpdateActions = {
  items: DeployedFeeds['outdatedDeployments']['items'];
  deployOne(deploymentId: Ident): Promise<void>;
  deployAll(): Promise<void>;
  isPending: boolean;
};

export default function useDeploymentUpdateActions(_items: DeploymentUpdateActions['items']): DeploymentUpdateActions {
  const pushSnack = usePushSnack();
  const { data: items, errorProps, setData, status, task } = useAsync(_items);

  React.useEffect(() => {
    setData(_items);
  }, [_items, setData]);

  React.useEffect(() => {
    const errorMessage = errorProps?.messages?.[0] || errorProps?.title;
    if (errorMessage) {
      pushSnack(errorMessage, 'error');
    }
  }, [errorProps, pushSnack]);

  const deployOne = React.useCallback(
    (deploymentId: Ident) => {
      const deployment = items.find(i => i.deploymentId === deploymentId);
      if (!deployment) return Promise.resolve();

      return task(
        deployAnalytic(deployment.detection.guid, {
          overwrite: false,
          organization_id: deployment.integration.organization_id,
          integrations: [deployment.integration.guid]
        })
      ).then(() => {
        setData(items.filter(i => i.deploymentId !== deploymentId));
      });
    },
    [task, setData, items]
  );

  const deployAll = React.useCallback(() => {
    // not using bulkDeploy here because we want to update specific detections at specific integrations
    // bulkDeploy would push all detections to all integrations
    const integrationsByDetection: Record<Guid, { detectionGuid: Guid; orgId: Ident; integrationGuids: Guid[] }> =
      items.reduce((ibd, item) => {
        return {
          ...ibd,
          [item.detection.guid]: {
            detectionGuid: item.detection.guid,
            orgId: item.integration.organization_id,
            integrationGuids: [...(ibd[item.detection.guid]?.integrationGuids || []), item.integration.guid]
          }
        };
      }, {});

    const batches = Promise.all(
      Object.values(integrationsByDetection).map(({ detectionGuid, orgId, integrationGuids }) =>
        deployAnalytic(detectionGuid, { overwrite: false, organization_id: orgId, integrations: integrationGuids })
      )
    );

    return task(batches).then(() => setData([]));
  }, [task, setData, items]);

  return {
    items,
    deployOne,
    deployAll,
    isPending: status === Status.pending
  };
}
