import React from 'react';

import { Status, useAsync, ErrorProps } from 'storage';

import {
  confirmTwoFactorCode,
  confirmTwoFactorSetup,
  removeTwoFactor,
  setupTwoFactor,
  resetMFA
} from './Multifactor.api';
import {
  TwoFactorCodePayload,
  TwoFactorRemovePayload,
  TwoFactorSetupResponse,
  OrgAdminResetUsersMFAParams
} from './Multifactor.type';

type MultifactorInterface = {
  errorProps?: ErrorProps;
  status: Status;
  // requestCode(): Promise<void>;
  setup(): Promise<TwoFactorSetupResponse>;
  confirmSetup(payload: TwoFactorCodePayload): Promise<void>;
  remove(payload: TwoFactorRemovePayload): Promise<void>;
  verify(login_identifier, payload: TwoFactorCodePayload): Promise<void>;
  orgAdminResetMFA(OrgAdminResetUsersMFAParams): Promise<void>;
};

export function useMultifactor(): MultifactorInterface {
  const { task, status, errorProps } = useAsync();

  /**
   * Resend code for type 'email'
   * Not currently in use
   */
  // const requestCode = React.useCallback(() => {
  //   return task(requestTwoFactorCode());
  // }, [task]);

  const setup = React.useCallback(() => {
    return task(setupTwoFactor());
  }, [task]);

  const confirmSetup = React.useCallback(
    (payload: TwoFactorCodePayload) => {
      return task(confirmTwoFactorSetup(payload));
    },
    [task]
  );

  const remove = React.useCallback(
    (payload: TwoFactorRemovePayload) => {
      return task(removeTwoFactor(payload));
    },
    [task]
  );

  const verify = React.useCallback(
    (login_identifier: string, payload: TwoFactorCodePayload) => {
      return task(confirmTwoFactorCode(login_identifier, payload));
    },
    [task]
  );

  const orgAdminResetMFA = React.useCallback(
    (resetMFAProps: OrgAdminResetUsersMFAParams) => {
      return task(resetMFA(resetMFAProps));
    },
    [task]
  );

  return {
    setup,
    confirmSetup,
    remove,
    verify,
    orgAdminResetMFA,
    errorProps,
    status
  };
}
