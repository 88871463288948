import { checkContentPermission } from 'services/authService';

import { ContentPermission, FunctionalPermission, Permitted } from 'types/auth';

export type I =
  | Exclude<ContentPermission, ContentPermission.All>
  | Exclude<FunctionalPermission, FunctionalPermission.All>;

export function canI(action: ContentPermission, object: Permitted): boolean {
  return checkContentPermission(object, action);
}
