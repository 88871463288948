import axios from 'axios';
import Guacamole from 'guacamole-common-js';

import { Catalog, Scheme, stowItem } from 'storage';

import { UploadResult, Url } from 'types/common';

const GUACAMOLE_BASE_URL = '/guacamole';

export function getHostName(url: Url): string {
  try {
    return new URL(url).host;
  } catch (e) {
    return '';
  }
}

function getUploadHostName(url: Url): string {
  if (process.env.REACT_APP_ENV === 'local') {
    return '';
  }

  return getHostName(url);
}

function getProtocol(url: Url): string {
  if (process.env.REACT_APP_ENV === 'local') {
    return '';
  }

  return new URL(url).protocol + '//';
}

export function syncLocalClipboardToRemote(client: Guacamole.Client): void {
  getLocalClipboard().then(textContent => {
    setRemoteClipboard(client, textContent);
  });
}

export function syncRemoteClipboardToLocal(stream: Guacamole.InputStream, mimetype: string): void {
  if (/^text\//.exec(mimetype)) {
    const reader = new Guacamole.StringReader(stream);

    let text = '';
    reader.ontext = (data: string) => {
      text += data;
    };

    reader.onend = () => {
      try {
        navigator.clipboard.writeText(text);
      } catch (e) {
        return;
      }
      stowItem(Scheme.sessionStorage, Catalog.clipboard, text);
    };
  } else {
    const reader = new Guacamole.BlobReader(stream, mimetype);
    reader.onend = () => {
      try {
        const data = [new ClipboardItem({ [mimetype]: reader.getBlob() })];
        navigator.clipboard.write(data);
      } catch (e) {
        return;
      }
    };
  }
}

export function setRemoteClipboard(client: Guacamole.Client, clipboardText: string): void {
  const stream = client.createClipboardStream('text/plain');
  const writer = new Guacamole.StringWriter(stream);
  writer.sendText(clipboardText);
  writer.sendEnd();
}

export function getLocalClipboard(): Promise<string> {
  // reading from clipboard will fail in every browser but chrome
  // just ignore errors
  try {
    return navigator.clipboard
      .readText()
      .then(text => {
        return text;
      })
      .catch(() => {
        return '';
      });
  } catch (e) {
    return Promise.resolve('');
  }
}

/**
 * TODO This function leverages an endpoint that doesn't exist
 */
export function uploadFiles(
  token: string,
  link: string,
  files: File[],
  client: Guacamole.Client,
  tunnel: Guacamole.Tunnel,
  onUploadProgress?: (file: File, progress: ProgressEvent) => void
): Promise<UploadResult[]> {
  return Promise.all(
    files.map(
      file =>
        new Promise<UploadResult>(resolve => {
          const stream = client.createFileStream(file.type, file.name);
          stream.onack = function (status: Guacamole.Status): void {
            // only upload once!
            stream.onack = null;

            if (status.isError()) {
              // TODO: map status codes to messages
              resolve({
                filename: file.name,
                success: false,
                message: 'Failed to open stream.'
              });
              return;
            }

            const url =
              getProtocol(link) +
              getUploadHostName(link) +
              GUACAMOLE_BASE_URL +
              '/api/session/tunnels/' +
              encodeURIComponent(tunnel.uuid) +
              '/streams/' +
              encodeURIComponent(stream.index) +
              '/' +
              encodeURIComponent(sanitizeFilename(file.name)) +
              '?token=' +
              token;

            // When Guacamole 1.6.0 releases, we can migrate to using a chunked file upload
            // guac server doesn't accept multipart/form-data. just set the file as the payload
            axios
              .post(url, file, {
                onUploadProgress(progress) {
                  if (typeof onUploadProgress === 'function') {
                    onUploadProgress(file, progress.event);
                  }
                }
              })
              .then(() => {
                resolve({ filename: file.name, success: true });
              })
              .catch(error => {
                let errorMessage = error?.message || 'Failed to upload file.';
                if (error?.response?.status === 413) {
                  errorMessage = 'File is larger than the maximum upload size.';
                }
                resolve({
                  filename: file.name,
                  success: false,
                  message: errorMessage
                });
              });
          };
        })
    )
  );
}

function sanitizeFilename(filename: string): string {
  // '\' in particular can screw up url encoding
  return filename.replace(/[\\/]+/g, '_');
}
