import React from 'react';

import { faCaretUp, faCopy } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';

import Accordion, { AccordionSummary } from 'snap-ui/Accordion';
import { ActionIconButton } from 'snap-ui/Button';
import { DataGrid, GridColDef, GridToolbarFilterButton, GridValidRowModel } from 'snap-ui/DataGrid';
import Icon from 'snap-ui/Icon';
import { ExternalLink } from 'snap-ui/Link';
import Typography from 'snap-ui/Typography';

import { Discriminator } from 'module/Tag';
import CopyButton from 'module/Widgets/CopyButton';

import { formatDayTimestamp } from 'utilities/TimeUtils';

import { CategoryContainer } from '../../Landing.style';
import { hashShortener } from '../../Landing.util';
import { useLandingCatalog } from '../../LandingProvider';

export default function Exploits() {
  const { source, type } = useLandingCatalog();

  if (type !== Discriminator.Vulnerability || isEmpty(source.exploits)) return null;
  const columns: GridColDef<GridValidRowModel>[] = [
    {
      field: 'description',
      headerName: 'Vendor',
      minWidth: 400,
      disableColumnMenu: true,
      flex: 1,
      renderCell: cell => (
        <div>
          <Typography variant='body2'>
            <ExternalLink primary target='_blank' href={cell.row.exploit_url}>
              {cell.row.name}
            </ExternalLink>
          </Typography>
          {/* Strip HTML tags since MATI data sometimes contains HTML */}
          <p>{cell.value?.replace(/<[^>]*>/g, '')}</p>
        </div>
      )
    },
    {
      field: 'md5',
      headerName: 'Hashes',
      disableColumnMenu: true,
      flex: 0.5,
      renderCell: cell =>
        !!cell.row.md5 && (
          <CopyButton
            value={cell.row.md5}
            placement='bottom'
            copyTooltip='Copied to clipboard!'
            messageTime={2000}
            arrow
          >
            <CategoryContainer className='row copy-button'>
              <span>{hashShortener(cell.value)}</span>
              <ActionIconButton aria-label='Copy to clipboard' icon={faCopy} />
            </CategoryContainer>
          </CopyButton>
        )
    },
    {
      field: 'reliability',
      headerName: 'Exploited Reliability',
      disableColumnMenu: true,
      flex: 0.3
    },
    {
      field: 'release_date',
      headerName: 'Release Date',
      disableColumnMenu: true,
      flex: 0.3,
      renderCell: cell => formatDayTimestamp(cell.value)
    }
  ];
  return (
    <Accordion className='LandingSection-accordion' defaultExpanded elevation={0}>
      <AccordionSummary className='LandingSection-accordionSummary' expandIcon={<Icon icon={faCaretUp} />}>
        <Typography variant='h4'>Exploits</Typography>
      </AccordionSummary>
      <DataGrid
        className='data-grid table-height'
        columns={columns}
        rows={source.exploits}
        disableRowSelectionOnClick
        getRowId={row => row.file_size + row.release_date}
        getRowClassName={p => (p.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        getRowHeight={() => 'auto'}
        getCellClassName={() => 'dataGrid-row cell-space'}
        slots={{ toolbar: () => <GridToolbarFilterButton /> }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'release_date', sort: 'desc' }]
          }
        }}
      />
    </Accordion>
  );
}
