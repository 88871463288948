import React from 'react';

import classnames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';

import Card, { CardContent } from 'snap-ui/Card';
import Chip from 'snap-ui/Chip';
import Divider from 'snap-ui/Divider';
import Placeholder from 'snap-ui/Placeholder';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { Path } from 'constants/paths';

import useLanding from 'module/Landing/useLanding';
import { getArtifactIcon } from 'module/Layout/Artifact.helper';
import { Discriminator } from 'module/Tag/Tag.type';
import { MarkdownRead } from 'module/Widgets/Markdown';

import { useMountedRef } from 'storage';
import { Status } from 'storage/Storage.type';

import { ArtifactType } from 'types/common';

import { BaseTag } from '.';
import { NistTag } from './Tag.type';

const MAX_CHARS = 400;

export function Tag({ tag, noLink }: { tag: BaseTag; noLink?: boolean }) {
  const link = getLandingLink(tag);
  const icon = getArtifactIcon(tag.discriminator, true);
  const isNist = tag.discriminator === Discriminator.Action;

  return (
    <TagTooltip tag={tag}>
      {isNist || noLink ? (
        <Chip className={classnames('Tag', tag.discriminator)} label={tag.name} icon={icon} />
      ) : (
        <Chip
          className={classnames('Tag', tag.discriminator)}
          label={tag.name}
          component={Link}
          to={link}
          clickable
          icon={icon}
        />
      )}
    </TagTooltip>
  );
}

function TagTooltip({ tag, children }: { tag: BaseTag; children: JSX.Element }) {
  const mounted = useMountedRef();
  const isAttack = tag.discriminator === Discriminator.Attack;
  const isNist = tag.discriminator === Discriminator.Action;
  const [rollup] = React.useState(() => isAttack);
  const {
    data: landingData,
    getData,
    setData,
    status: LandingStatus,
    task
  } = useLanding(tag?.discriminator, tag?.name);

  const tagSupplemental = isNist ? (tag as NistTag).detail : landingData.items[0];
  const compositeAliases = landingData?.items[0]?.aliases?.filter(
    alias => alias.toLocaleLowerCase() !== tag?.name.toLowerCase()
  );
  const isAliasPresent = compositeAliases?.length > 0;
  const isPending = LandingStatus === Status.pending;

  const getTagLandingData = React.useCallback(() => {
    if (!isNist) {
      task(getData(rollup)).then(d => {
        if (mounted.current) {
          setData(d, Status.resolved);
        }
      });
    }
  }, [rollup, getData, task, setData]); // eslint-disable-line react-hooks/exhaustive-deps

  let trimmedDescription = 'No Description Available';
  if (tagSupplemental?.description) {
    trimmedDescription =
      tagSupplemental?.description.substring(0, MAX_CHARS) +
      `${tagSupplemental?.description.length > MAX_CHARS ? '...' : ''}`;
  }

  return (
    <Tooltip
      onOpen={() => getTagLandingData()}
      title={
        <Card>
          {isPending ? (
            <TagTooltipPlaceholder />
          ) : (
            <CardContent>
              <Typography variant='body2'>
                {isNist ? tagSupplemental?.external_source_id + ': ' + tagSupplemental?.name : tagSupplemental?.name}
              </Typography>
              {tagSupplemental?.external_source && (
                <Typography variant='caption' color='text.secondary' className='sa-all-cap'>
                  source: {tagSupplemental?.external_source}
                </Typography>
              )}
              <Divider sx={{ marginBottom: '8px' }} />
              <MarkdownRead
                value={trimmedDescription}
                restricted
                to={`${Path.Collection}/${tag.discriminator}/${encodeURIComponent(tag.name)}`}
              />
              {isAliasPresent && (
                <>
                  <Typography variant='caption'>ALIASES: </Typography>
                  {compositeAliases?.map((alias, index) => (
                    <Link key={alias} to={`${Path.Collection}/${tag?.discriminator}/${encodeURIComponent(alias)}`}>
                      <Typography variant='caption' color='primary'>
                        {alias}
                      </Typography>
                      {index < compositeAliases.length - 1 && <span>, </span>}
                    </Link>
                  ))}
                </>
              )}
            </CardContent>
          )}
        </Card>
      }
      placement='top'
      arrow
      enterDelay={300}
      enterNextDelay={300}
      wrap
    >
      {children}
    </Tooltip>
  );
}

const TagTooltipContainer = styled('div')`
  display: flex;
  gap: ${p => p.theme.spacing(1)};
  flex-direction: column;
  margin-bottom: ${p => p.theme.spacing(3)};
`;

function TagTooltipPlaceholder() {
  return (
    <CardContent>
      <TagTooltipContainer>
        <Placeholder variant='text' height={20} width={250} animation='pulse' />
        <Placeholder variant='text' height={10} width={100} animation='pulse' />
      </TagTooltipContainer>
      <TagTooltipContainer>
        <Placeholder variant='text' height={10} width={200} animation='pulse' />
        <Placeholder variant='text' height={10} width={180} animation='pulse' />
        <Placeholder variant='text' height={10} width={220} animation='pulse' />
        <Placeholder variant='text' height={10} width={190} animation='pulse' />
      </TagTooltipContainer>
      <TagTooltipContainer>
        <Placeholder variant='text' height={10} width={220} animation='pulse' />
        <Placeholder variant='text' height={10} width={190} animation='pulse' />
      </TagTooltipContainer>
    </CardContent>
  );
}

export function getLandingLink(tag: BaseTag): LinkProps['to'] {
  switch (tag.discriminator) {
    case Discriminator.Attack:
    case Discriminator.Actor:
    case Discriminator.Software:
    case Discriminator.Vulnerability:
      return `${Path.Collection}/${tag.discriminator}/${encodeURIComponent(tag.name)}`;
    default: {
      return `${Path.Feed}?topic=${ArtifactType.Analytic}&query=${tag.name}`;
    }
  }
}
