import React from 'react';

import Panel, { Props as PanelProps } from './Panel';

export type Props = Partial<PanelProps> & {
  value: number;
  className?: 'red' | 'blue' | string;
};

function NumberPanel({ className, title, value, ...extraProps }: Props) {
  return (
    <Panel
      {...extraProps}
      className={className}
      content={<div className='stat'>{value ? value.toLocaleString() : 0}</div>}
      title={title}
    />
  );
}

export default NumberPanel;
