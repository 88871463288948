import React from 'react';

import { Engage, Fingerprint, Widget } from 'lib/Engagement';

import { useAnalyticVersionCatalog } from 'module/Analytic/core/AnalyticVersionProvider';
import { useIDETranslationState } from 'module/IDE';
import { IDEAction, IDEState } from 'module/IDE/reducer';

import { useLogsourceContext } from 'provider';

import { Status } from 'storage';

import TranslateAnalytic from './TranslateAnalytic';

interface IDEWrapperProps {
  emitChange?(action: IDEAction): void;
  ideState: IDEState;
  fetchStatus?: Status;
}

export default function IDEWrapper(props: IDEWrapperProps): JSX.Element {
  const { status: logSourceStatus } = useLogsourceContext();
  const { status: versionStatus } = useAnalyticVersionCatalog();
  const isLoading =
    props.fetchStatus === Status.pending || versionStatus === Status.pending || logSourceStatus === Status.pending;

  const {
    integrationOptions,
    languageOptions,
    languageStatus,
    selectedIntegrationOptionValue,
    selectedLanguage,
    setSelectedIntegrationOptionValue,
    setSelectedLanguageId,
    translation,
    translationError,
    translationStatus
  } = useIDETranslationState();
  const translationIsPending = translationStatus === Status.pending;

  const handleEditAsNative = props.ideState.guid
    ? undefined // TODO: implement IDE state via router. Until then, Edit As Native is only available for fresh, unsaved detections
    : (languageId: number, raw: string) => {
        Engage.track(
          Fingerprint.of(Widget.TranslateAnalytic).withQualifier('convert to native').withData({
            guid: props.ideState.guid,
            languageId: languageId
          })
        );
        props.emitChange({ type: 'EditAsNativeUpdate', languageId, raw });
      };

  if (props.ideState.isNative) {
    return null;
  }

  if (!isLoading)
    return (
      <TranslateAnalytic
        analyticGuid={props.ideState.guid}
        analyticName={props.ideState.analyticForm.title}
        integrationOptions={integrationOptions}
        languageOptionStatus={languageStatus}
        languageOptions={languageOptions}
        onEditAsNative={handleEditAsNative}
        onIntegrationChange={setSelectedIntegrationOptionValue}
        onLanguageChange={setSelectedLanguageId}
        selectedIntegrationValue={selectedIntegrationOptionValue}
        selectedLanguage={selectedLanguage}
        translation={translation}
        translationError={translationError}
        translationIsPending={translationIsPending}
      />
    );

  return null;
}
