import React from 'react';

import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';

import { formatExactDayTimestamp } from 'utilities/TimeUtils';

import { CategoryContainer, CategorySection, SubTitle } from '../../Landing.style';
import { useLandingCatalog } from '../../LandingProvider';

export default function Mitigation() {
  const { source } = useLandingCatalog();
  if (!source.available_mitigation) return null;

  return (
    <CategorySection>
      <Typography variant='h4'>Mitigation</Typography>
      <CategoryContainer className='row'>
        <div className='category-detail-item'>
          <SubTitle variant='subtitle2'>Available Mitigation</SubTitle>
          {source.available_mitigation?.map(mitigation => (
            <div key={mitigation} className='category-detail-item'>
              <Typography variant='h5' className='sa-cap'>
                {mitigation}
              </Typography>
            </div>
          ))}
        </div>
        {source.date_of_disclosure && (
          <div className='category-detail-item'>
            <SubTitle variant='subtitle2'>Date of Disclosure</SubTitle>
            <div className='category-detail-item'>
              <Typography variant='h5' className='sa-cap'>
                {formatExactDayTimestamp(source.date_of_disclosure)}
              </Typography>
            </div>
          </div>
        )}
        {isFinite(source.days_to_patch) && (
          <div className='category-detail-item'>
            <Tooltip
              arrow
              placement='top'
              title='The number of days between the date of disclosure and the first vendor fix.'
            >
              <SubTitle variant='subtitle2'>
                Days to Patch <Icon.Info />
              </SubTitle>
            </Tooltip>
            <div className='category-detail-item'>
              <Typography variant='h5' className='sa-cap'>
                {source.days_to_patch}
              </Typography>
            </div>
          </div>
        )}
      </CategoryContainer>
    </CategorySection>
  );
}
