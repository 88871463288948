import React from 'react';

import Alert from 'snap-ui/Alert';
import { FormDialog } from 'snap-ui/Dialog';
import { FieldsLayout } from 'snap-ui/Layout';
import { styled } from 'snap-ui/util';

import { Integration } from 'module/Integration/Integration.type';
import { IntegrationOption } from 'module/Integration/IntegrationAutocomplete';
import { TranslateAnalytic } from 'module/TranslateAnalytic';

import { Status } from 'storage';

import { AnalyticLanguage } from 'types/analytic';
import { Ident } from 'types/common';

import { useIDETranslationState } from '../IDETranslationStateProvider';

const Content = styled(FieldsLayout)`
  width: 100%;
  max-width: 100%;
  padding: 0;
`;

type EditAsNativeDialogProps = {
  disableSubmit?:
    | boolean
    | ((selectedLanguage: AnalyticLanguage, selectedIntegration?: Integration) => string | boolean);
  /* must be stable */
  filterIntegrationOptions?(option: IntegrationOption): boolean;
  isOpen: boolean;
  onSubmit(raw: string, languageId: Ident, integrationId: string): void;
  onClose(): void;
  submitTitle?: string | ((selectedLanguage: AnalyticLanguage, selectedIntegration?: Integration) => string);
  title?: string;
};

export default function EditAsNativeDialog({
  disableSubmit: _disableSubmit = false,
  filterIntegrationOptions,
  isOpen,
  onSubmit,
  onClose,
  submitTitle: _submitTitle,
  title = 'Convert to Native Detection'
}: EditAsNativeDialogProps): JSX.Element {
  const {
    integrationOptions: _integrationOptions,
    languageOptions,
    languageStatus,
    selectedLanguage,
    setSelectedLanguageId,
    setSelectedIntegrationOptionValue,
    selectedIntegrationOptionValue,
    selectedIntegration,
    translation,
    translationError,
    translationStatus
  } = useIDETranslationState();
  const translationIsPending = translationStatus === Status.pending;

  const integrationOptions = React.useMemo(() => {
    if (!filterIntegrationOptions) return _integrationOptions;
    return _integrationOptions.filter(filterIntegrationOptions);
  }, [filterIntegrationOptions, _integrationOptions]);

  React.useEffect(() => {
    if (isOpen && !integrationOptions.some(o => o.value === selectedIntegrationOptionValue)) {
      if (integrationOptions[0]) {
        setSelectedIntegrationOptionValue(integrationOptions[0].value);
      }
    }
  }, [isOpen, integrationOptions, selectedIntegrationOptionValue, setSelectedIntegrationOptionValue]);

  function handleSubmit() {
    onSubmit(translation, selectedLanguage.id, selectedIntegrationOptionValue);
    onClose();
  }

  function getSubmitTitle() {
    if (_submitTitle && typeof _submitTitle === 'function') return _submitTitle(selectedLanguage, selectedIntegration);
    else if (_submitTitle) return _submitTitle;
    return selectedLanguage?.name ? `Edit ${selectedLanguage.name} Detection` : 'Edit Native Detection';
  }

  function getDisableSubmit() {
    if (!translation) return true;
    if (_disableSubmit && typeof _disableSubmit === 'function')
      return _disableSubmit(selectedLanguage, selectedIntegration);
    return _disableSubmit;
  }

  const disableSubmit = getDisableSubmit();

  return (
    <FormDialog
      title={title}
      DialogProps={{ onClose, open: isOpen, maxWidth: 'md' }}
      FormikConfig={{
        enableReinitialize: true,
        initialValues: {}, // form dialog is just for styling; formik doesn't help here
        onSubmit: handleSubmit
      }}
      SubmitProps={{
        children: getSubmitTitle(),
        disabled: !!disableSubmit
      }}
    >
      <Content>
        {typeof disableSubmit === 'string' && <Alert severity='error'>{disableSubmit}</Alert>}
        <TranslateAnalytic
          analyticGuid={undefined}
          analyticName={undefined}
          disableCopy
          disableDownload
          disableSearch
          integrationOptions={integrationOptions}
          languageOptionStatus={languageStatus}
          languageOptions={languageOptions}
          onEditAsNative={undefined}
          onIntegrationChange={setSelectedIntegrationOptionValue}
          onLanguageChange={setSelectedLanguageId}
          selectedIntegrationValue={selectedIntegrationOptionValue}
          selectedLanguage={selectedLanguage}
          translation={translation}
          translationError={translationError}
          translationIsPending={translationIsPending}
        />
      </Content>
    </FormDialog>
  );
}
