import React from 'react';

import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import Alert from 'snap-ui/Alert';
import Icon from 'snap-ui/Icon';
import PanelFrame, { Props as PanelProps } from 'snap-ui/PanelFrame';
import { styled, useTheme } from 'snap-ui/util';

import { useDashboardStats } from 'module/Dashboard/DetectionDashboard';

import ChangePercentage from '../ChangePercentage';

function calculateTicks(deployedCount: number): (string | number)[] {
  // floor nearest 20 (prevents decimal ticks)
  const interval = deployedCount < 100 ? Math.round(deployedCount / 4) : (Math.floor(deployedCount / 20) * 20) / 4;
  return Array(5)
    .fill(0)
    .map((val, index) => index * interval);
}

export type Props = Partial<PanelProps> & {
  className?: string;
};
function DeployedPanel({ className, title, ...extraProps }: Props) {
  const { deployed, isPending, isResolved } = useDashboardStats();
  const { palette } = useTheme();
  const calculatedTicks = calculateTicks(deployed?.stopCount);

  return (
    <PanelFrame
      {...extraProps}
      className={className}
      loading={isPending}
      title={title}
      content={
        isResolved && (
          <>
            <div className='deployed-analytics'>
              <ChangePercentage changePercent={deployed?.increasePercent} />
              <div className='deployed-analytics-count'>
                {!!deployed?.increasePercent && (
                  <>
                    <div>{deployed?.startCount?.toLocaleString() || 0}</div>
                    <Icon className='arrow' icon={faArrowRight} />
                  </>
                )}
                {deployed.stopCount ? (
                  <div>{deployed?.stopCount?.toLocaleString()}</div>
                ) : (
                  <Alert severity='info'>No data available for this period.</Alert>
                )}
              </div>
            </div>
            {deployed.startCount && (
              <ResponsiveContainer width='100%' height={115}>
                <BarChart width={900} height={100} data={[deployed]} layout='vertical'>
                  <defs>
                    <linearGradient id='deployedColor' x1='0' y1='0' x2='1' y2='0'>
                      <stop offset='25%' stopColor={palette.lime.main} stopOpacity={0.9} />
                      <stop offset='75%' stopColor={palette.lime.light} stopOpacity={0.9} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    stroke={palette.grey[100]}
                    strokeWidth='2'
                    type='number'
                    ticks={calculatedTicks}
                    domain={[0, 'dataMax']}
                    textAnchor='middle'
                  />
                  <YAxis type='category' dataKey='name' interval={0} textAnchor='end' hide={true} />
                  <Bar
                    dataKey='startCount'
                    stackId='analytic'
                    fill='url(#deployedColor)'
                    maxBarSize={30}
                    radius={deployed?.difference ? [20, 0, 0, 20] : [20, 20, 20, 20]}
                  />
                  <Bar
                    dataKey='difference'
                    stackId='analytic'
                    fill={palette.lime.main}
                    mask='url(#deployed-mask-stripe)'
                    radius={[0, 20, 20, 0]}
                    maxBarSize={30}
                  />
                  <pattern
                    id='deployed-pattern-stripe'
                    width='20'
                    height='20'
                    patternUnits='userSpaceOnUse'
                    patternTransform='rotate(-45)'
                  >
                    <rect
                      width='20'
                      height='20'
                      transform='translate(0,0)'
                      fill={palette.common.white}
                      fillOpacity={0.5}
                      stroke={palette.common.white}
                      strokeWidth={'3px'}
                      strokeDasharray='20'
                    ></rect>
                  </pattern>
                  <mask id='deployed-mask-stripe'>
                    <rect x='0' y='0' width='100%' height='100%' fill='url(#deployed-pattern-stripe)' />
                  </mask>
                </BarChart>
              </ResponsiveContainer>
            )}
          </>
        )
      }
    />
  );
}

const StyledDeployedPanel = styled(DeployedPanel)<Props>`
  min-height: 200px;

  .panel-contents {
    padding-top: ${p => p.theme.spacing(2)};
  }

  .deployed-analytics {
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(4)};
  }

  & .deployed-analytics-count {
    font-size: 3.5rem;
    line-height: 1;
    background-color: ${p => p.theme.palette.common.black};
    color: ${p => p.theme.palette.common.white};
    background-clip: text;
    text-shadow: 2px 2px 4px ${p => p.theme.palette.common.black};
    display: flex;
    justify-content: center;
    gap: ${p => p.theme.spacing(6)};
    align-items: center;

    .arrow {
      font-size: 2.75rem;
    }
  }

  & .recharts-yAxis {
    font-size: 0.8em;
  }
`;

export default StyledDeployedPanel;
