import React from 'react';

import zod from 'zod';

import Checkbox from 'snap-ui/Checkbox';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';
import Icon from 'snap-ui/Icon';

import { OSName } from 'module/BAS/BAS.type';
import { addOrRemoveValue } from 'module/Filter/Filter.util';
import { FilterControl, IconFormControlLabel } from 'module/GlobalFilter';
import { AttackPlatform } from 'module/Tag';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';

import { FilterConfig } from '../GlobalFilter.type';

/**
 * the values here correspond to keys in the BAS::OSName and Tag::AttackPlatform enums for easy value lookups
 */
export const FilterablePlatform: Record<string, keyof typeof OSName | keyof typeof AttackPlatform | 'Cloud'> = {
  Windows: 'Windows',
  Linux: 'Linux',
  Mac: 'macOS',
  Cloud: 'Cloud',
  Network: 'Network',
  Containers: 'Containers'
} as const;

export type FilterablePlatform = (typeof FilterablePlatform)[keyof typeof FilterablePlatform];

const VALUE_MAPS = {
  [ArtifactType.AttackScript]: {
    [FilterablePlatform.Windows]: [OSName.Windows],
    [FilterablePlatform.Linux]: [OSName.Linux],
    [FilterablePlatform.Mac]: [OSName.macOS],
    [FilterablePlatform.Containers]: [OSName.Containers],
    [FilterablePlatform.Cloud]: [
      OSName.Azure_Ad,
      OSName.Google,
      OSName.Iaas,
      OSName.Iaas_Azure,
      OSName.Iaas_Aws,
      OSName.Iaas_gcp,
      OSName.Office,
      OSName.Saas
    ]
  },
  [ArtifactType.AttackTag]: {
    [FilterablePlatform.Windows]: [AttackPlatform.Windows],
    [FilterablePlatform.Linux]: [AttackPlatform.Linux],
    [FilterablePlatform.Mac]: [AttackPlatform.macOS],
    [FilterablePlatform.Network]: [AttackPlatform],
    [FilterablePlatform.Containers]: [AttackPlatform.Containers],
    [FilterablePlatform.Cloud]: [
      AttackPlatform.AzureAD,
      AttackPlatform.Office365,
      AttackPlatform.GoogleWorkspace,
      AttackPlatform.IaaS,
      AttackPlatform.SaaS
    ]
  }
} as const;

type PlatformKeys = {
  platforms: FilterablePlatform[];
};

type PlatformFilterProps = {
  disabled?: boolean;
  onChange(values: Partial<PlatformKeys>): void;
  topic: ArtifactType;
  values: PlatformKeys;
};

function PlatformFilter({ onChange, topic, values }: PlatformFilterProps): React.ReactElement {
  return (
    <FilterControl className='PlatformFilter'>
      <FormLabel id='visible-toggle-button-group-label'>By Platform</FormLabel>
      <FormGroup aria-labelledby='visible-toggle-button-group-label'>
        {Object.entries(FilterablePlatform).map(([platformKey, platformValue]) =>
          Object.keys(VALUE_MAPS[topic] || {})?.includes(platformValue) ? (
            <IconFormControlLabel
              key={platformKey}
              control={
                <Checkbox
                  onChange={handleChange}
                  value={platformValue}
                  checked={values.platforms.includes(platformValue)}
                />
              }
              label={
                <>
                  {Icon[platformKey] && React.createElement(Icon[platformKey], { width: 16 })} {platformValue}
                </>
              }
            />
          ) : null
        )}
      </FormGroup>
    </FilterControl>
  );

  function handleChange(e: React.FormEvent<HTMLInputElement>, checked: boolean): void {
    const v = e.currentTarget.value as FilterablePlatform;
    const platforms = addOrRemoveValue(checked, values.platforms, v) as FilterablePlatform[];
    onChange({ platforms });
  }
}

function genToQuery(topic: ArtifactType) {
  return function (values: PlatformKeys) {
    return {
      field: 'platforms',
      op: Ops.in,
      value: values.platforms.flatMap(platformValue => VALUE_MAPS[topic]?.[platformValue] || [])
    };
  };
}

function genFromQuery(topic: ArtifactType) {
  return zod
    .object({
      field: zod.literal('platforms'),
      op: zod.nativeEnum(Ops),
      value: zod.array(zod.string())
    })
    .transform(query => {
      const valueMapEntries = Object.entries(VALUE_MAPS[topic]);
      const platforms = query.value.reduce((accPlatforms, value) => {
        valueMapEntries.forEach(([platformValue, filterValues]: [FilterablePlatform, string[]]) => {
          if (filterValues.includes(value)) accPlatforms.add(platformValue);
        });
        return accPlatforms;
      }, new Set<FilterablePlatform>());
      return { platforms: Array.from(platforms) };
    });
}

const PlatformFilterConfig: FilterConfig<PlatformKeys> = {
  defaults: {
    default: () => ({
      platforms: [FilterablePlatform.Windows, FilterablePlatform.Linux, FilterablePlatform.Mac]
    }),
    [ArtifactType.AttackScript]: () =>
      ({ platforms: Object.keys(VALUE_MAPS[ArtifactType.AttackScript]) } as PlatformKeys)
  },
  supportedTopics: [ArtifactType.AttackTag, ArtifactType.AttackScript],
  component: PlatformFilter,
  toQuery: {
    [ArtifactType.AttackScript]: genToQuery(ArtifactType.AttackScript),
    [ArtifactType.AttackTag]: genToQuery(ArtifactType.AttackTag)
  },
  fromQuery: {
    [ArtifactType.AttackScript]: genFromQuery(ArtifactType.AttackScript),
    [ArtifactType.AttackTag]: genFromQuery(ArtifactType.AttackTag)
  }
};

export default PlatformFilterConfig;
