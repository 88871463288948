import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { BulkLauncherDialog, BulkLauncherDivButton, BulkLauncherInterface } from 'module/BAS/Launcher';
import { buildCuratedFilter } from 'module/Curation/Curation.service';
import {
  CollectionRecommenderType,
  isCollectionRecommenderType,
  RecommendationType
} from 'module/Curation/Curation.type';
import {
  DetectionDeploymentDialog,
  DetectionDeploymentDivButton,
  DetectionDeploymentInterface
} from 'module/DetectionDeployment';
import { JobType, huntInterfaceFactory } from 'module/Job';
import useMayI from 'module/May/useMayI';

import { useRecommenderCatalog } from 'provider/Recommender/RecommenderProvider';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { ArtifactType } from 'types/common';

import { useLandingCatalog } from '../LandingProvider';
import HuntRecommenderButton from './HuntButton';
import { RecommendationContainer } from './Recommender.style';
import { RecommendationPanel, SnapScore } from './Recommender.widgets';

type RecommenderProps = {
  name: string;
  type: CollectionRecommenderType;
};

const [HuntInterface, HuntButton, HuntDialog] = huntInterfaceFactory('HuntContext');

export default function Recommender({ name, type }: RecommenderProps) {
  const { data: recommendation, status: recommendationStatus, getRecommendation } = useRecommenderCatalog();

  const [deployCount, setDeployCount] = React.useState<number>(0);
  const [huntCount, setHuntCount] = React.useState<number>(0);
  const [validationCount, setValidationCount] = React.useState<number>(0);
  const isRecommenderPending = recommendationStatus === Status.pending;
  const snapScore = recommendation?.score;
  const deploymentGuids = recommendation?.deployment_recommendations?.map(d => d.guid);
  const huntGuids = recommendation?.hunt_recommendations?.map(d => d.guid);
  const validationGuids = recommendation?.validations?.map(d => d.guid);
  const deploymentQuery = deploymentGuids?.length > 0 ? buildCuratedFilter(deploymentGuids) : null;
  const huntQuery = huntGuids?.length > 0 ? buildCuratedFilter(huntGuids) : null;
  const basQuery = validationGuids?.length > 0 ? buildCuratedFilter(validationGuids) : null;

  const refresh = React.useCallback(() => {
    if (name && type) getRecommendation([name], type);
  }, [getRecommendation, name, type]);

  React.useEffect(() => {
    setDeployCount(recommendation?.deployment_recommendations?.length || 0);
  }, [recommendation?.deployment_recommendations?.length]);

  React.useEffect(() => {
    setHuntCount(recommendation?.hunt_recommendations?.length || 0);
  }, [recommendation?.hunt_recommendations?.length]);

  React.useEffect(() => {
    setValidationCount(recommendation?.validations?.length || 0);
  }, [recommendation?.validations?.length]);

  return (
    <RecommendationContainer>
      <SnapScore pending={isRecommenderPending} score={snapScore || 0} />

      {/* Detect */}
      <DetectionDeploymentInterface>
        <RecommendationPanel
          pending={isRecommenderPending}
          type={RecommendationType.Deployment}
          count={deployCount}
          action={<DetectionDeploymentDivButton count={deployCount} />}
        />
        <DetectionDeploymentDialog criteria={deploymentQuery} onDeployed={refresh} />
      </DetectionDeploymentInterface>

      {/* Hunt */}
      <HuntInterface jobType={JobType.Hunt}>
        <RecommendationPanel
          pending={isRecommenderPending}
          type={RecommendationType.Hunt}
          count={huntCount}
          action={
            <HuntButton>
              {({ onClick, disabled }) => (
                <HuntRecommenderButton count={huntCount} onClick={onClick} disabled={disabled} />
              )}
            </HuntButton>
          }
        />
        <HuntDialog query={huntQuery} selections={huntGuids} onHunted={refresh} />
      </HuntInterface>

      {/* Validate */}
      <BulkLauncherInterface topic={ArtifactType.AttackScript} criteria={basQuery} basProduct={[]}>
        <RecommendationPanel
          pending={isRecommenderPending}
          type={RecommendationType.Validation}
          count={validationCount}
          action={<BulkLauncherDivButton count={validationCount} />}
        />
        <BulkLauncherDialog onLaunched={refresh} />
      </BulkLauncherInterface>
    </RecommendationContainer>
  );
}

export function LandingRecommender() {
  const isRecommendationUser = useMayI(FunctionalPermission.CalculateRecommendation);
  const { name, type, source, aliases } = useLandingCatalog();
  const isRecommenderType = isCollectionRecommenderType(type);

  if (isEmpty(source) || !isRecommenderType || !isRecommendationUser) return null;
  return (
    <Recommender name={aliases?.length === 1 ? aliases[0] : name} type={type as unknown as CollectionRecommenderType} />
  );
}
