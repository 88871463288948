import React from 'react';

import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import useTitle from 'hooks/useTitle';

import { Engage, Fingerprint } from 'lib/Engagement';

import AnalyticBoard from './Board/AnalyticBoard';
import PostureBoard from './Board/PostureBoard';
import SessionBoard from './Board/SessionBoard';
import { Root } from './ContentDashboard.style';
import useCoverage from './useCoverage';

const ContentDashboardRoot = styled(Root, { label: 'ContentDashboardRoot' })`
  margin-top: ${p => p.theme.spacing(8)};
  margin-left: auto;
  margin-right: auto;
`;

function ContentDashboard(): React.ReactElement {
  useTitle('Content Dashboard | SnapAttack');
  const coverage = useCoverage();

  React.useEffect(() => {
    Engage.track(Fingerprint.load(Path.ContentDashboard));
  }, []);

  return (
    <ContentDashboardRoot>
      <PostureBoard loading={coverage?.loadingDetectionSummary} {...coverage?.detectionSummary} />
      <SessionBoard
        total={coverage?.sessionCount}
        technique={coverage?.technique}
        subtechnique={coverage?.subtechnique}
        featured={coverage?.sessionFeature}
        loadingStats={coverage?.loadingSessionCount}
        loadingFeatured={coverage?.loadingFeaturedSessions}
      />
      <AnalyticBoard
        total={coverage?.analyticCount}
        technique={coverage?.technique}
        subtechnique={coverage?.subtechnique}
        featured={coverage?.analyticFeature}
        loadingStats={coverage?.loadingAnalyticCount}
        loadingFeatured={coverage?.loadingFeaturedAnalytics}
      />
    </ContentDashboardRoot>
  );
}

export default ContentDashboard;
