import React from 'react';

import { useLocation } from 'react-router-dom';

import Alert from 'snap-ui/Alert';
import Snackbar from 'snap-ui/Snackbar';

import { StrictReactNode } from 'types/core';

import { SnackInterface } from './Snack.type';

export const SnackContext = React.createContext<SnackInterface['pushSnack']>(null);
SnackContext.displayName = 'SnackContext';
const anchorOrigin: { horizontal: 'center' | 'left' | 'right'; vertical: 'top' | 'bottom' } = {
  horizontal: 'left',
  vertical: 'bottom'
};

function usePushSnack() {
  const context = React.useContext(SnackContext);

  if (!context) {
    throw new Error('useSnack must be used within the SnackContext');
  }

  return context;
}

function SnackProvider({ children }: { children: React.ReactElement }) {
  const { pathname } = useLocation();
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState<StrictReactNode>();
  const [severity, setSeverity] = React.useState<Alert['severity']>();
  const [anchor, setAnchor] = React.useState(anchorOrigin);
  const [duration, setDuration] = React.useState(9000);

  const pushSnack: SnackInterface['pushSnack'] = React.useCallback(
    (value, severity, horizontal, vertical, autoHideDuration) => {
      const anchorHorizontal = horizontal || anchorOrigin.horizontal;
      const anchorVertical = vertical || anchorOrigin.vertical;
      const duration = autoHideDuration === -1 ? undefined : autoHideDuration || 9000;

      setContent(value);
      setSeverity(severity);
      setAnchor({
        horizontal: anchorHorizontal,
        vertical: anchorVertical
      });

      setDuration(duration);
      setOpen(true);
    },
    []
  );

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setContent(undefined);
    setSeverity(undefined);
    setAnchor(anchorOrigin);
    setDuration(9000);
  }, []);

  React.useLayoutEffect(() => {
    handleClose();
  }, [handleClose, pathname]);

  return (
    <>
      <SnackContext.Provider value={pushSnack}>{children}</SnackContext.Provider>
      <Snackbar open={open} anchorOrigin={anchor} autoHideDuration={duration} onClose={handleClose}>
        <Alert variant='standard' severity={severity} onClose={handleClose}>
          {content}
        </Alert>
      </Snackbar>
    </>
  );
}

export { SnackProvider, usePushSnack };
