import React from 'react';

import { faSearch } from '@fortawesome/pro-solid-svg-icons';

import Button from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import Paper from 'snap-ui/Paper';
import Table, { TablePlaceholder } from 'snap-ui/Table';
import TextField from 'snap-ui/TextField';
import { styled } from 'snap-ui/util';

import useDebounce from 'hooks/useDebounce';

import TablePagination from 'module/Widgets/TablePagination';

import { BASToolsProvider } from 'provider/BASTools';

import { Status } from 'storage';

import { Guid } from 'types/common';

import { AddTestCaseProvider, useAddTestCase } from '../AddTestCase/AddTestCase';
import DetermineOutcomesButton from '../DetermineOutcomes/DetermineOutcomesButton';
import ActionsColumn from '../JobList/ActionsColumn';
import AgentColumn from '../JobList/AgentColumn';
import AttackStatusColumn from '../JobList/AttackStatusColumn';
import ExecutedAtColumn from '../JobList/ExecutedAtColumn';
import MitreAttackColumn from '../JobList/MitreAttackColumn';
import OutcomeColumn from '../JobList/OutcomeColumn';
import TestCaseColumn from '../JobList/TestCaseColumn';
import { PaginateModalProvider } from '../JobOutcomes/PaginateModal';
import useCampaignJobs, { textFilter } from './useCampaignJobs';

const Container = styled(Paper)`
  .button-container {
    display: flex;
    gap: ${p => p.theme.spacing(2)};
    align-items: stretch;

    .MuiChip-root {
      height: 100%;
    }
  }
  .toolbar {
    padding: ${p => p.theme.spacing(2)};
    display: flex;
    justify-content: space-between;
  }

  .search-field {
    width: 350px;
  }

  .BackdropLoaderContainer {
    height: 300px;
  }

  .ActionsColumn,
  .time {
    white-space: nowrap;
    justify-content: flex-start;
    display: flex;
  }
`;

function getColumns(showAgent: boolean) {
  const columns: string[] = ['Executed At', 'MITRE ATT&CK', 'Attack', 'Attack Status', 'Outcome'];
  const row = [ExecutedAtColumn, MitreAttackColumn, TestCaseColumn, AttackStatusColumn, OutcomeColumn];

  if (showAgent) {
    columns.push('Agent');
    row.push(AgentColumn);
  }

  columns.push('Actions');
  row.push(ActionsColumn);

  return { columns, row };
}

type CampaignJobListProps = {
  campaignGuid: Guid;
  showAgent?: boolean;
  refreshCampaign(): void;
};

function AddManualTestButton() {
  const { openModal, setOpenModal } = useAddTestCase();
  return (
    <Button variant='outlined' onClick={() => setOpenModal(!openModal)}>
      Add Manual Test Case
    </Button>
  );
}

function CampaignJobListInner({ campaignGuid, showAgent, refreshCampaign }: CampaignJobListProps): JSX.Element {
  const { getPage, page, updateOutcomes, status } = useCampaignJobs(campaignGuid);

  const [query, setQuery] = React.useState<string>(null);
  const debouncedQuery = useDebounce(query, 500);

  const fetchData = React.useCallback(
    (next?: number) => {
      const page = next || 0;
      if (debouncedQuery) {
        getPage({ page }, textFilter(debouncedQuery));
      } else {
        getPage({ page });
      }
    },
    [debouncedQuery, getPage]
  );

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const refreshPage = () => fetchData(page.page);

  const { columns, row } = getColumns(showAgent);

  return (
    <PaginateModalProvider items={page.items} refreshJobs={refreshCampaign} updateOutcomes={updateOutcomes}>
      <AddTestCaseProvider refreshJobs={refreshPage} campaignGuid={campaignGuid}>
        <Container elevation={5}>
          <div className='toolbar'>
            <TextField
              className='search-field no-print'
              onChange={e => setQuery(e.target.value)}
              placeholder='Search by attack or agent name'
              startAdornment={<Icon icon={faSearch} />}
            />
            {campaignGuid && (
              <div className='button-container'>
                <AddManualTestButton />
                <DetermineOutcomesButton campaign_id={campaignGuid} refreshPage={refreshPage} />
              </div>
            )}
          </div>
          <Table
            columns={columns}
            component='div'
            rows={page.items?.map(job => {
              return row.map(component =>
                React.createElement(component, {
                  disableReplay: !!campaignGuid,
                  job,
                  refreshJobs: refreshPage,
                  mutate: true
                })
              );
            })}
          />
          {status === Status.pending && <TablePlaceholder count={10} height={30} />}
          <TablePagination
            page={page.page}
            changePage={fetchData}
            numPages={Math.ceil(page.total / page.size)}
            zeroIndex
          />
        </Container>
      </AddTestCaseProvider>
    </PaginateModalProvider>
  );
}

export default function CampaignJobList(props: CampaignJobListProps): JSX.Element {
  return (
    <BASToolsProvider>
      <CampaignJobListInner {...props} />
    </BASToolsProvider>
  );
}
