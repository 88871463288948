import React from 'react';

import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { Alert, AlertTitle } from 'snap-ui/Alert';
import BackdropLoader from 'snap-ui/BackdropLoader';
import Paper from 'snap-ui/Paper';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { ValidationResponse } from 'apis';

import useTitle from 'hooks/useTitle';

import { ApiError } from 'module/ApiError';

import { changePassword } from 'provider';

import { Status, useAsync } from 'storage';

import { Divider } from '../Authentication.style';
import ChangePasswordForm from '../ChangePassword/ChangePasswordForm';

type ChangeFormData = {
  password: string;
  new_password: string;
  confirm_new_password: string;
};

const Container = styled(Paper)`
  padding: ${p => p.theme.spacing(7)};

  .ApiError {
    margin: ${p => p.theme.spacing(6, 'auto')};
  }

  .ChangePasswordForm {
    padding-top: ${p => p.theme.spacing(3)};
  }
`;

type Props = {
  className?: string;
};

const ChangePassword = (props: Props): React.ReactElement => {
  useTitle('Change Password | SnapAttack');

  const { task, status, errorProps } = useAsync<ValidationResponse>(null);

  const callChange = React.useCallback(
    payload => {
      return task(changePassword(payload));
    },
    [task]
  );

  return (
    <Container className={classnames('ChangePassword', props.className)} elevation={16}>
      <Typography variant='h1'>Change Password</Typography>
      <p>Please enter your new password.</p>
      <Divider />
      {status === Status.pending && <BackdropLoader data-testid='loading-dimmer' fixed contained />}
      <ApiError {...errorProps} />
      {status === Status.resolved && isEmpty(errorProps) && (
        <Alert severity='success'>
          <AlertTitle>Password successfully changed.</AlertTitle>
        </Alert>
      )}
      <ChangePasswordForm submitForm={handleChange} />
    </Container>
  );

  function handleChange(formValue: ChangeFormData): void {
    const payload = {
      new_password: formValue.new_password,
      current_password: formValue.password
    };

    callChange(payload);
  }
};

export default ChangePassword;
