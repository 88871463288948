import React from 'react';

import { FieldsLayout } from 'snap-ui/Layout';

import TextFieldFormik from 'module/Form/TextFieldFormik';

export default function EditAgentForm(): JSX.Element {
  return (
    <FieldsLayout>
      <TextFieldFormik label='Name' name='name' />
      <TextFieldFormik multiline minRows={3} label='Description' name='description' />
    </FieldsLayout>
  );
}
