import { useCallback, useEffect, useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';

import { getAnalytic, getAnalyticPermissions, getAnalyticReferences } from 'apis/resources/analytic';

import { getAnalyticFpr } from 'module/Analytic/Analytic.api';
import { FPROutput, TranslationResult } from 'module/Analytic/Analytic.type';
import { augmentSigmaTags } from 'module/Analytic/Analytic.util';
import { useAnalyticVersionCatalog } from 'module/Analytic/core/AnalyticVersionProvider';
import { getSupplementalSearch } from 'module/Feed/Feed.service';
import { SupplementalArtifact } from 'module/Search';
import { SigmaTag } from 'module/Tag/Tag.type';

import { useAuth } from 'provider';

import { Status, useAsync } from 'storage';

import { AnalyticReferences } from 'types/analytic';
import { Permitted } from 'types/auth';
import { Artifact, ArtifactType, Guid, Ident } from 'types/common';

export interface AnalyticLoadState {
  analytic?: Artifact & { raw?: string };
  analyticStatus: Status;
  updateAnalytic(analytic: Artifact): void;
  analyticError: any;
  setWithPartialData(d: Partial<AnalyticLoadState['analytic']>): void;

  supplemental?: SupplementalArtifact;
  supplementalStatus: Status;
  updateSupplemental(supplemental: SupplementalArtifact): void;
  supplementalError: any;
  supplementalRefresh(): void;

  references?: AnalyticReferences;
  referencesStatus: Status;
  updateReferences(references: AnalyticReferences): void;
  referencesError: any;

  permissions?: Permitted;
  permissionsStatus: Status;
  updatePermissions(permissions: Permitted): void;
  permissionsError: any;

  getFpr(integrationGuid: Guid): FPROutput;
  fprRefresh(orgId: number): void;
  getFprCompilation(integrationGuid: Guid, languageId: Ident): TranslationResult;
  fprStatus: Status;
}

export default function useAnalytic(
  guid: Guid,
  artifact?: AnalyticLoadState['analytic'],
  supplementalArtifact?: SupplementalArtifact,
  referenceArtifact?: AnalyticReferences,
  permissionArtifact?: Permitted,
  tagCatalog?: SigmaTag[]
): AnalyticLoadState {
  const { defaultOrgId } = useAuth();

  const {
    data: analytic,
    run: analyticRun,
    status: analyticStatus,
    setData: _setAnalytic,
    error: analyticError
  } = useAsync<AnalyticLoadState['analytic']>();

  const {
    data: supplemental,
    run: supplementalRun,
    status: supplementalStatus,
    setData: setSupplemental,
    error: supplementalError
  } = useAsync<SupplementalArtifact>();

  const {
    data: references,
    run: referencesRun,
    status: referencesStatus,
    setData: setReferences,
    error: referencesError
  } = useAsync<AnalyticReferences>();

  const {
    data: permissions,
    run: permissionsRun,
    status: permissionsStatus,
    setData: setPermissions,
    error: permissionsError
  } = useAsync<Permitted>();

  const { data: fprs, run: fprRun, status: fprStatus } = useAsync<FPROutput[]>([]);
  const { versions, refresh: versionsRefresh } = useAnalyticVersionCatalog();
  const raw = versions.find(v => v.guid === guid)?.raw || versions[0]?.raw;

  const rawAnalytic = useMemo(
    () =>
      augmentSigmaTags(raw || analytic?.raw, supplemental?.source_analytic_compilation_target_id, analytic, tagCatalog),
    [raw, supplemental?.source_analytic_compilation_target_id, analytic, tagCatalog]
  );

  const setAnalytic = useCallback(
    async (value: Artifact) => {
      await versionsRefresh();
      _setAnalytic(value);
    },
    [_setAnalytic, versionsRefresh]
  );

  const setWithPartialData = useCallback(
    (updates: Partial<AnalyticLoadState['analytic']>) => {
      _setAnalytic(d => ({
        ...d,
        ...(updates || {})
      }));
    },
    [_setAnalytic]
  );

  useEffect(() => {
    if (!isEmpty(artifact)) {
      _setAnalytic(artifact);
    } else if (!guid) {
      _setAnalytic(null);
    } else if (analyticStatus === Status.pending) {
      // do nothing
    } else {
      analyticRun(getAnalytic(guid));
    }
    // eslint-disable-next-line
  }, [guid]);

  useEffect(() => {
    if (!isEmpty(supplementalArtifact)) {
      setSupplemental(supplementalArtifact);
    } else if (!guid) {
      setSupplemental(null);
    } else if (supplementalStatus === Status.pending) {
      //do nothing
    } else {
      supplementalRun(getSupplementalSearch(ArtifactType.Analytic, guid));
    }
    // eslint-disable-next-line
  }, [guid]);

  useEffect(() => {
    if (!isEmpty(referenceArtifact)) {
      setReferences(referenceArtifact);
    } else if (!guid) {
      setReferences(null);
    } else if (referencesStatus === Status.pending) {
      // do nothing
    } else {
      referencesRun(getAnalyticReferences(guid));
    }
    // eslint-disable-next-line
  }, [guid]);

  useEffect(() => {
    if (!isEmpty(permissionArtifact)) {
      setPermissions(permissionArtifact);
    } else if (!guid) {
      setPermissions(null);
    } else if (permissionsStatus === Status.pending) {
      //do nothing
    } else {
      permissionsRun(getAnalyticPermissions(guid));
    }
    // eslint-disable-next-line
  }, [guid]);

  useEffect(() => {
    if (fprStatus === Status.pending) {
      //do nothing
    } else if (guid) {
      fprRun(getAnalyticFpr(guid, defaultOrgId));
    }
    // eslint-disable-next-line
  }, [guid]);

  const supplementalRefresh = useCallback(() => {
    if (guid) {
      supplementalRun(getSupplementalSearch(ArtifactType.Analytic, guid), true);
    }
  }, [guid, supplementalRun]);

  const fprRefresh = useCallback(
    orgId => {
      if (guid) {
        fprRun(getAnalyticFpr(guid, orgId));
      }
    },
    [fprRun, guid]
  );

  const getFpr = useCallback(
    (integrationGuid: Guid) => {
      return fprs?.find(fpr => fpr.applied_to.find(a => a.integration === integrationGuid));
    },
    [fprs]
  );

  const getFprCompilation = useCallback(
    (integrationGuid: Guid, languageId: Ident) => {
      const application = getFpr(integrationGuid)?.applied_to.find(a => a.integration === integrationGuid);
      return application?.compilations.find(compilation => compilation.id === languageId);
    },
    [getFpr]
  );

  return {
    analytic: { ...analytic, raw: rawAnalytic },
    analyticStatus,
    updateAnalytic: setAnalytic,
    analyticError,
    setWithPartialData,

    supplemental,
    supplementalStatus,
    updateSupplemental: setSupplemental,
    supplementalError,
    supplementalRefresh,

    references,
    referencesStatus,
    updateReferences: setReferences,
    referencesError,

    permissions,
    permissionsStatus,
    updatePermissions: setPermissions,
    permissionsError,

    getFpr,
    getFprCompilation,
    fprRefresh,
    fprStatus
  };
}
