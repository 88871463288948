import React from 'react';

import { EIGHTS_TRUTH, FOURS_TRUTH, ONES_TRUTH, TWOS_TRUTH } from 'constants/common';

import { useAsync } from 'storage';

import { getSigmaTagsByAnyDiscriminators } from './Tag.service';
import { Discriminator, SigmaTag } from './Tag.type';

export const DiscriminatorOrdinal = {
  Attack: 1,
  Actor: 2,
  Software: 4,
  Vulnerability: 8,
  All: 15
} as const;

export type DiscriminatorOrdinal = (typeof DiscriminatorOrdinal)[keyof typeof DiscriminatorOrdinal];

/**
 * Use of this catalog should be limited. Consider useTag when possible
 */
export default function useTagCatalog(flags: DiscriminatorOrdinal, legacy: boolean) {
  const { data, run, status } = useAsync<SigmaTag[]>([]);

  React.useEffect(() => {
    const types = [];
    if (ONES_TRUTH.includes(flags)) types.push(Discriminator.Attack);
    if (TWOS_TRUTH.includes(flags)) types.push(Discriminator.Actor);
    if (FOURS_TRUTH.includes(flags)) types.push(Discriminator.Software);
    if (EIGHTS_TRUTH.includes(flags)) types.push(Discriminator.Vulnerability);
    if (types.length) run(getSigmaTagsByAnyDiscriminators(10_000, { partial: '', types, legacy }));
  }, [flags, legacy, run]);

  return {
    data,
    status
  };
}
