import React from 'react';

import zod from 'zod';

import { Option } from 'snap-ui/Autocomplete';

import { useLanguagePlatforms } from 'module/Integration';
import { AutoCompleteOptionContent } from 'module/Integration/IntegrationAutocomplete';

import { useLanguageContext } from 'provider';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';

import { FilterConfig } from '../GlobalFilter.type';
import InclusiveAutocomplete from './InclusiveAutocomplete';

type LanguageKeys = {
  languages: string[];
  languageOp: Ops;
};

type LanguageFilterProps = {
  onChange(values: Partial<LanguageKeys>): void;
  values: LanguageKeys;
};

function LanguageFilter({ onChange, values }: LanguageFilterProps): JSX.Element {
  const { getPlatformDetails } = useLanguagePlatforms();
  const { data: languages } = useLanguageContext();
  const options = React.useMemo(
    () =>
      languages.map(language => ({
        content: (
          <AutoCompleteOptionContent
            type={getPlatformDetails(language.id)?.platforms?.[0]?.integrationType}
            text={language.name}
          />
        ),
        label: language.name,
        value: language.id.toString() // values should be strings since they come from urls
      })),
    [getPlatformDetails, languages]
  );

  const handleValueChange = (option: Option[]) => {
    const payload: Partial<LanguageKeys> = { languages: option.map(o => o.value) };
    if (option.length === 0) payload['languageOp'] = undefined;
    onChange(payload);
  };

  const handleOperatorChange = (languageOp: Ops) => {
    onChange({ languageOp });
  };

  return (
    <InclusiveAutocomplete
      title='By Supported Language'
      name='language_tags_dropdown'
      option={options}
      value={values.languages.filter(l => languages.some(language => language.id.toString() === l))}
      onValueChange={handleValueChange}
      onOperatorChange={handleOperatorChange}
      operator={values.languageOp}
    />
  );
}

function toQuery(values: LanguageKeys) {
  if (!values.languages?.length) return;
  return {
    field: 'analytic_compilation_targets',
    op: values.languageOp,
    value: values.languages.map(Number)
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('analytic_compilation_targets'),
    op: zod.nativeEnum(Ops),
    value: zod.array(zod.number())
  })
  .transform(query => ({
    languageOp: query.op,
    languages: query.value.map(String)
  }));

const legacyFromQuery = zod
  .object({
    field: zod.literal('latest_version.analytic_compilations.analytic_compilation_targets.id'),
    op: zod.nativeEnum(Ops),
    value: zod.array(zod.union([zod.number(), zod.string()]))
  })
  .transform(query => ({
    languageOp: query.op,
    languages: query.value.map(String)
  }));

const LanguageFilterConfig: FilterConfig<LanguageKeys> = {
  defaults: { default: () => ({ languages: [], languageOp: Ops.any }) },
  supportedTopics: [ArtifactType.Analytic],
  component: LanguageFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: zod.union([fromQuery, legacyFromQuery]) }
};
export default LanguageFilterConfig;
