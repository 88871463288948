import React from 'react';

import CircularProgress from 'snap-ui/CircularProgress';
import { ConfirmDialog } from 'snap-ui/Dialog';

import useWorkflow from 'aso/useWorkflow';

import { useAuth, useIntegrationCatalog } from 'provider';

import { Status } from 'storage';

import { useAnalyticCatalog } from './AnalyticProvider';

type UndeployOnCloneProps = {
  isOpen: boolean;
  onClose(): void;
  onComplete(): void;
};

export default function UndeployOnClone({ isOpen, onClose, onComplete }: UndeployOnCloneProps): JSX.Element {
  const { defaultOrgId } = useAuth();
  const { integrations } = useIntegrationCatalog();
  const [{ analytic, supplemental }] = useAnalyticCatalog();
  const { removeDeployment, status } = useWorkflow(analytic.guid);
  const isPending = status === Status.pending;
  const deployments = React.useMemo(
    () => supplemental?.deployments?.filter(d => d.organization_id === defaultOrgId),
    [defaultOrgId, supplemental]
  );

  const handleDismiss = React.useCallback(() => {
    onClose();
    onComplete();
  }, [onClose, onComplete]);

  React.useEffect(() => {
    if (isOpen && !deployments.length) {
      handleDismiss();
    }
  }, [isOpen, deployments, handleDismiss]);

  function handleUndeploy() {
    Promise.all(deployments.map(deployment => removeDeployment(deployment.id))).then(handleDismiss);
  }

  return (
    <ConfirmDialog
      DialogProps={{ open: isOpen && !!deployments.length, onClose }}
      ConfirmProps={{
        children: isPending ? <CircularProgress size={25} /> : 'Undeploy',
        disabled: isPending,
        onClick: handleUndeploy
      }}
      SecondaryProps={{ children: 'Keep Deployed', onClick: handleDismiss }}
      title='Undeploy detection?'
    >
      This detection is currently deployed to
      <ul>
        {deployments?.flatMap(deployment =>
          deployment.integrations?.map(({ guid: integrationGuid }) => (
            <li key={integrationGuid}>
              {integrations.all.find(integration => integration.guid === integrationGuid)?.name ||
                'Unknown Environment'}
            </li>
          ))
        )}
      </ul>
      Would you like to undeploy it before you clone it?
    </ConfirmDialog>
  );
}
