import React from 'react';

export type FilterFn<T> = (query: string, item: T) => boolean;

type SearchState<T> = {
  filteredData: T[];
  setQuery(query: string): void;
};

/**
 * utility for filtering in-memory data by text search, such as for a table view
 */
export default function useSearch<T>(data: T[], filter: FilterFn<T>): SearchState<T> {
  const [query, setQuery] = React.useState<string>();
  const [filteredData, setFilteredData] = React.useState(data);

  const filterFn = React.useCallback((item: T) => (query ? filter(query, item) : true), [filter, query]);

  React.useEffect(() => {
    if (data) setFilteredData(data.filter(filterFn));
  }, [data, filterFn]);

  return { filteredData, setQuery };
}
