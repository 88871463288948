import React from 'react';

import Icon from 'snap-ui/Icon';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { IDEStatusState } from 'module/Analytic/core/IDE/IDEStatus';
import { LogListContainer, Pager, LogToolbar as _LogToolbar } from 'module/MatchingLog/MatchingLog.widgets';

import { Guid } from 'types/common';
import { LogRecord } from 'types/ide';

import { IDELogContent } from './LogContentWrapper';
import { ToggleLogPinButton } from './ToggleLogPinButton';

const LogToolbar = styled(_LogToolbar)`
  padding: ${p => p.theme.spacing(6)};
  align-items: center;
  justify-content: space-between;
`;

export function PreviewLogs(props: {
  data: LogRecord[];
  onPinLogChange?(guid: Guid): void;
  pins: Guid[];
  status: IDEStatusState;
}) {
  const [current, setCurrent] = React.useState(0);
  const log = props.data?.[current];

  return (
    <LogListContainer>
      <LogToolbar>
        <Pager count={props.data?.length} current={current} onChange={setCurrent} />
        <Typography variant='caption'>
          <Icon.Info /> only the first 100 test logs are returned
        </Typography>
        {!!props.onPinLogChange && log?.row_id && (
          <ToggleLogPinButton
            pinned={props.pins?.includes(log?.row_id)}
            guid={log?.row_id}
            onClick={props.onPinLogChange}
            disabled={!log}
          />
        )}
      </LogToolbar>
      <IDELogContent log={log} />
    </LogListContainer>
  );
}
