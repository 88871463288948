import React from 'react';

import { Option } from 'snap-ui/Autocomplete';

import useRawTranslation, { RawTranslationContext } from 'aso/useRawTranslation';

import { useAnalyticLanguageCatalog } from 'module/Analytic/AnalyticLanguages';
import { IDEState } from 'module/IDE/reducer';
import {
  getSyntheticSnapattackIntegration,
  useIntegrationOptions,
  useLanguageOptions,
  useLanguagePlatforms
} from 'module/Integration';
import { Integration } from 'module/Integration/Integration.type';
import { IntegrationOption } from 'module/Integration/IntegrationAutocomplete';

import { useAuth, useIntegrationCatalog, useLanguageContext } from 'provider';

import { Status } from 'storage';

import { AnalyticLanguage } from 'types/analytic';

import { getIntegrationValueFromLanguageId } from './';

export type IDETranslateControls = {
  defaultLanguageId: number;
  integrationOptions: IntegrationOption[];
  languageList: AnalyticLanguage[];
  languageStatus: Status;
  selectedLanguage: AnalyticLanguage;
  selectedLanguageId: number;
  setSelectedLanguageId(id: number): void;
  selectedIntegrationOptionValue: string;
  setSelectedIntegrationOptionValue(value: string): void;
  selectedIntegration: Integration | undefined;
  languageOptions: Option[];
  translation: RawTranslationContext['translation'];
  translationError: RawTranslationContext['error'];
  translationStatus: RawTranslationContext['status'];
};

export function useIDETranslateControls(
  ideState: IDEState,
  _shouldTranslate: boolean | ((languageId: number) => boolean)
): IDETranslateControls {
  const { defaultLanguageId } = useAuth();
  const { integrations } = useIntegrationCatalog();
  const { supportedPlatforms } = useLanguagePlatforms();
  const { allData: allLanguages } = useLanguageContext();

  const [selectedIntegrationOptionValue, setSelectedIntegrationOptionValue] = React.useState<string>('');
  const [selectedLanguageId, setSelectedLanguageId] = React.useState<number>(defaultLanguageId);
  const { integrationOptions } = useIntegrationOptions(true);
  const languageOptions = useLanguageOptions(selectedIntegrationOptionValue);

  React.useEffect(() => {
    if (languageOptions.length)
      setSelectedLanguageId(
        Number(languageOptions?.find(language => !language.warning)?.value ?? languageOptions[0]?.value)
      );
  }, [languageOptions]);

  const shouldTranslate = React.useMemo(() => {
    if (!selectedLanguageId) return false;
    if (typeof _shouldTranslate === 'function') return _shouldTranslate(selectedLanguageId);
    return _shouldTranslate;
  }, [_shouldTranslate, selectedLanguageId]);

  const { data: languageList, status: languageStatus } = useAnalyticLanguageCatalog();
  const {
    translation,
    error: translationError,
    status: translationStatus
  } = useRawTranslation(shouldTranslate, ideState.raw, ideState._customization_raw, selectedLanguageId);

  const selectedLanguage = React.useMemo(
    () => languageList.find(lang => lang.id === selectedLanguageId),
    [languageList, selectedLanguageId]
  );

  const selectedIntegration = React.useMemo(
    () => integrations.all.find(i => i.id.toString() === selectedIntegrationOptionValue),
    [integrations.all, selectedIntegrationOptionValue]
  );

  React.useEffect(() => {
    // set selected integration on load
    // use the first integration that supports the detection's source language
    if (selectedLanguageId && !selectedIntegrationOptionValue) {
      const integrationValue = getIntegrationValueFromLanguageId(
        selectedLanguageId,
        integrationOptions,
        [...integrations.all, getSyntheticSnapattackIntegration(allLanguages)],
        supportedPlatforms
      );
      if (integrationValue) setSelectedIntegrationOptionValue(integrationValue);
    }
  }, [
    allLanguages,
    integrationOptions,
    integrations.all,
    selectedIntegrationOptionValue,
    selectedLanguageId,
    supportedPlatforms
  ]);

  return {
    defaultLanguageId,
    languageList,
    languageOptions,
    integrationOptions,
    setSelectedLanguageId,
    setSelectedIntegrationOptionValue,
    selectedIntegrationOptionValue,
    selectedIntegration,
    languageStatus,
    selectedLanguage,
    selectedLanguageId,
    translation,
    translationError,
    translationStatus
  };
}
