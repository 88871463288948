import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { Area, AreaChart } from 'recharts';

import Alert from 'snap-ui/Alert';
import PanelFrame, { Props as PanelProps } from 'snap-ui/PanelFrame';
import { styled, useTheme } from 'snap-ui/util';

import { useDashboardStats } from 'module/Dashboard/DetectionDashboard';

import { daysBetween } from 'utilities/TimeUtils';

import PreparednessGrade from '../../core/PreparednessGrade';

export type Props = Partial<PanelProps> & {
  className?: string;
};

function OverallPanel({ className, title, ...extraProps }: Props) {
  const {
    overallPosture,
    postures,
    isPending,
    isResolved,
    DateRangeSelectorProps: { dates }
  } = useDashboardStats();
  const { start: startDate, stop: stopDate } = dates;

  const { palette } = useTheme();
  const duration = daysBetween(startDate, stopDate);

  const { 0: initialPosture, [postures.length - 1]: finalPosture } = postures;
  const postureChange = Math.floor(finalPosture?.value - initialPosture?.value);

  return (
    <PanelFrame
      {...extraProps}
      className={className}
      loading={isPending}
      title={title}
      content={
        isResolved && (
          <>
            {isEmpty(overallPosture) && <Alert severity='info'>No data available for this period.</Alert>}
            {!!overallPosture?.letterGrade && <PreparednessGrade {...overallPosture} />}
            {duration >= 30 && !isEmpty(postures) && (
              <>
                {!!postureChange && (
                  <p>
                    {postureChange >= 0 ? '+' : ''}
                    {postureChange} over the last {duration} days
                  </p>
                )}
                <AreaChart width={350} height={75} data={postures}>
                  <defs>
                    <linearGradient id='area-gradient' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor={palette.purple.main} stopOpacity={0.8} />
                      <stop offset='95%' stopColor={palette.purple.main} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <Area
                    type='monotone'
                    dataKey='value'
                    stroke={palette.purple.main}
                    fillOpacity={0.6}
                    fill='url(#area-gradient)'
                  />
                </AreaChart>
              </>
            )}
          </>
        )
      }
    />
  );
}

const StyledOverallPanel = styled(OverallPanel)<Props>`
  min-height: 200px;

  .panel-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${p => p.theme.spacing(3)};
    padding-top: ${p => p.theme.spacing(2)};

    p {
      margin: 0;
    }
  }
`;

export default StyledOverallPanel;
