import React from 'react';

import { faCircleMinus, faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { FieldArray, FieldArrayRenderProps, useField } from 'formik';

import { SlimAccordion } from 'snap-ui/Accordion';
import { ActionIconButton } from 'snap-ui/Button';
import { FormHelperText } from 'snap-ui/FormControl';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import TextFieldFormik from 'module/Form/TextFieldFormik';

import { LanguageAdvanceConfig } from 'types/analytic';

export const EMPTY_BACKEND_OPTION: LanguageAdvanceConfig[] = [
  {
    field: '',
    value: ''
  }
];

const Container = styled('div')`
  .advance-value-container {
    display: flex;
    flex-direction: column;
  }
  .valueRow {
    margin-bottom: ${p => p.theme.spacing(5)};
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr auto auto;
    grid-gap: ${p => p.theme.spacing(3)};

    .errorMessage {
      position: absolute;
      top: 40px;
      left: 5px;
    }
  }

  .addOne {
    align-self: flex-end;
    margin-right: 11px;
  }
`;

type StringsSectionFormProps = {
  disabled: boolean;
};

function SigmaAdvanceKeyValue(props: StringsSectionFormProps) {
  const [field, meta] = useField<LanguageAdvanceConfig[]>('backend_options');
  const hasError = !!(meta.touched && meta.error);
  const [advanceOpen] = React.useState<boolean>(
    !field.value?.every(option => option.field === '' && option.value === '')
  );
  const handleDeleteValue = (
    valueIndex: number,
    removeValue: FieldArrayRenderProps['remove'],
    pushValue: FieldArrayRenderProps['push']
  ): void => {
    removeValue(valueIndex);
    if (field.value.length <= 1) {
      pushValue({ field: '', value: '' });
    }
  };
  const renderValue = (
    valueIndex: number,
    removeValue: FieldArrayRenderProps['remove'],
    pushValue: FieldArrayRenderProps['push']
  ): React.ReactElement => (
    <div className='valueRow' key={valueIndex}>
      <TextFieldFormik
        label='Key'
        className='value'
        disabled={props.disabled}
        name={`backend_options[${valueIndex}].field`}
        error={meta?.value[valueIndex].field === '' && hasError}
        placeholder='Key'
      />
      <TextFieldFormik
        label='Value'
        className='value'
        disabled={props.disabled}
        name={`backend_options[${valueIndex}].value`}
        placeholder='Value'
      />
      <Tooltip arrow placement='left' title='Delete value'>
        <ActionIconButton
          aria-label='Delete value'
          disabled={props.disabled}
          icon={faCircleMinus}
          className='link'
          onClick={(): void => {
            handleDeleteValue(valueIndex, removeValue, pushValue);
          }}
        />
      </Tooltip>
      {meta?.value[valueIndex].field === '' && hasError && (
        <FormHelperText className='errorMessage' error>
          {hasError && meta.error}
        </FormHelperText>
      )}
    </div>
  );

  return (
    <Container>
      <SlimAccordion
        details={
          <FieldArray name={`backend_options`}>
            {({ remove: removeValue, push: pushValue }): React.ReactElement => (
              <div className='advance-value-container'>
                {field.value?.map((value, valueIndex) => renderValue(valueIndex, removeValue, pushValue))}
                <Tooltip className='addOne' title='Add a value' placement='left'>
                  <ActionIconButton
                    aria-label='Add a rule'
                    disabled={props.disabled}
                    className='link add-field-rule'
                    onClick={(): void => pushValue({ field: '', value: '' })}
                    icon={faCirclePlus}
                  />
                </Tooltip>
              </div>
            )}
          </FieldArray>
        }
        summary='Advanced Configuration'
        defaultExpanded={advanceOpen}
      />
    </Container>
  );
}

export default SigmaAdvanceKeyValue;
