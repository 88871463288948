import React from 'react';

import { isLandingType } from 'module/Collection/Collection.util';
import { DetectionStatsResources, DetectionSummaryCatalog, getStatCount } from 'module/Detection';

import { Status, useAsync } from 'storage';

import { Artifact, ArtifactType } from 'types/common';

export type DetectionCountInterface = {
  detection: DetectionSummaryCatalog;
  status: Status;
};

export default function useDetectionCount(topic: ArtifactType, feed: Artifact[]): DetectionCountInterface {
  const { data, run, status, reset } = useAsync<DetectionSummaryCatalog>({});
  const payload = React.useMemo(() => feed.filter(item => !isLandingType(item)).map(item => item.guid), [feed]);
  const stringPayload = JSON.stringify(payload);

  React.useEffect(() => {
    if (topic === ArtifactType.Session || topic === ArtifactType.Analytic) {
      if (payload.length > 0) run(getStatCount(DetectionStatsResources[topic], payload));
      else reset();
    }
  }, [topic, stringPayload, run, reset]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    detection: data,
    status
  };
}
