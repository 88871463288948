import React from 'react';

import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import Button from 'snap-ui/Button';
import { ConfirmDialog } from 'snap-ui/Dialog';
import Icon from 'snap-ui/Icon';

import { ValidationError, asValidationError } from 'apis';

import Path from 'constants/paths';

import { saveCollection, updateAllCollectionItemsByType } from 'module/Collection/Collection.api';
import { BulkCollectionField, Collection } from 'module/Collection/Collection.type';
import { guidsFromCompositeFilterFor } from 'module/Collection/Collection.util';

import { useAuth } from 'provider';

import { Guid, IconButtonRenderProps } from 'types/common';

import { buildCurationPayload } from './Curation.service';
import { CurationInput } from './Curation.type';

type Props = IconButtonRenderProps & {
  collection: Collection;
  onClone(): void;
  onCancel?(): void;
  onError?(error: ValidationError): void;
};

export default function CurationClone({ children, onClone, onCancel, onError, collection }: Props) {
  const { user, defaultOrgId } = useAuth();
  const { push } = useHistory();
  const [showConfirmation, setConfirmation] = React.useState(false);
  const cloneACollection = async () => {
    onClone();
    const createCollectionPayload = buildCurationPayload({
      ...collection,
      organization: defaultOrgId.toString()
    } as unknown as CurationInput);

    const analyticGuids = guidsFromCompositeFilterFor(collection.analytic_filter)?.value as Guid[];
    const intelGuids = guidsFromCompositeFilterFor(collection.threat_intelligence_filter)?.value as Guid[];
    const threatGuids = guidsFromCompositeFilterFor(collection.session_filter)?.value as Guid[];
    const attackScriptGuids = guidsFromCompositeFilterFor(collection.bas_script_filter)?.value as Guid[];
    const payload = {
      [BulkCollectionField.intelligence]: intelGuids || [],
      [BulkCollectionField.threat]: threatGuids || [],
      [BulkCollectionField.detection]: analyticGuids || [],
      [BulkCollectionField.attack_script]: attackScriptGuids || []
    };

    saveCollection(createCollectionPayload).then(newCollection => {
      return (
        updateAllCollectionItemsByType(newCollection.guid, 'add', payload).then(() => {
          push(`${Path.Collection}/${newCollection.guid}`);
          window.location.reload();
        }),
        (error: any) => {
          onError(asValidationError(error));
        }
      );
    });
  };
  return (
    <>
      {children && children({ onClick: () => setConfirmation(true), icon: faEdit })}
      {!children && (
        <Button
          variant='outlined'
          onClick={() => setConfirmation(true)}
          aria-label='Start Editing'
          startIcon={<Icon icon={faEdit} />}
        >
          Edit
        </Button>
      )}
      <ConfirmDialog
        DialogProps={{
          open: showConfirmation,
          onClose: () => {
            setConfirmation(false);
          }
        }}
        ConfirmProps={{ onClick: () => cloneACollection(), children: 'Okay' }}
        title='Edit Collection'
        SecondaryProps={{ children: 'Cancel', onClick: onCancel }}
      >
        {`You cannot edit this collection. Instead, we will create a copy and make it visible only to your organization, ${user.preferred_organization.name}`}
      </ConfirmDialog>
    </>
  );
}
