import { Option } from 'snap-ui/Autocomplete';

import Path from 'constants/paths';

import { CollectionDiscriminator } from 'module/Collection/Collection.type';
import { Discriminator } from 'module/Tag';

import { Artifact } from 'types/common';

import { HyperTag, Landing, LandingArtifact, MandiantSeverityLevel, VulnerabilitiesScore } from './Landing.type';

export function pathToDiscriminator(path: string): Discriminator {
  const values = path.split('/');
  return values?.[values.length - 2] as Discriminator;
}

export function getLandingPaths(path: Path) {
  return [
    `${path}/${Discriminator.Actor}/:name`,
    `${path}/${Discriminator.Attack}/:name`,
    `${path}/${Discriminator.Software}/:name`,
    `${path}/${Discriminator.Vulnerability}/:name`
  ];
}

export function getAllLandingPaths() {
  return [...getLandingPaths(Path.Collection), ...getLandingPaths(Path.CollectionEdit)];
}

export function getExpandedName(paramName: string, source: Partial<HyperTag>): [] | [string, boolean] {
  if (!source) return [];

  if (source.discriminator === Discriminator.Attack) {
    return [source.name, true];
  }

  const matches = source.name?.toLowerCase() === paramName?.toLowerCase();
  const name = matches ? source.name : paramName;

  return [name, matches];
}

export function getSource(sourceName: string, data: Landing): Partial<HyperTag> {
  return (sourceName === 'combined' ? data.combined : data.items.find(i => i.mitre_id === sourceName)) || {};
}

export function getAliasNames(name: string, items: Partial<HyperTag>[]): string[] {
  // NOTE: this is being made case insensitive since the user can type in a tag name
  // and aliases won't return if the casing is different
  // We also remove the tag names that are an alias of themselves, and alphabetize them
  const tagName = name.toLowerCase();
  const aliasNames = items
    .filter(i => i.aliases?.map(alias => alias.toLowerCase())?.includes(tagName))
    .map(i => i.name)
    .filter(i => tagName !== i.toLowerCase())
    .sort((a, b) => a.localeCompare(b));
  const unique = [...new Set(aliasNames)];
  return unique.length > 0 ? unique : null;
}

/** Can a thing be CUD */
export function isMutable(type: Discriminator): type is typeof Discriminator.Actor | typeof Discriminator.Software {
  return type === Discriminator.Actor || type === Discriminator.Software;
}

export function mapInitialSnapattackTagValuesByKey(
  key: 'actors' | 'attacks' | 'software' | 'vulnerabilities',
  data: Landing
) {
  const snap = data.items.find(i => i.external_source === 'snapattack');
  if (!snap || !(key in snap) || !Array.isArray(snap[key])) return [];
  return snap[key].map(v => v.name);
}

export function mapInitialSnapattackAliases(data: Landing) {
  const snap = data.items.find(i => i.external_source === 'snapattack');
  if (!snap || !('aliases' in snap) || !Array.isArray(snap.aliases)) return [];
  return snap.aliases;
}

export function mapInitialSnapattackName(data: Landing) {
  const mitre = data.items.find(i => i.external_source === 'snapattack');
  return mitre?.name || '';
}

export function mapInitialSnapattackOverview(data: Landing) {
  const snap = data.items.find(i => i.external_source === 'snapattack');
  return snap?.description || '';
}

export function mapAliasValues(hyper: HyperTag): Option[] {
  if (!Array.isArray(hyper.aliases)) return [];
  return hyper.aliases.map<Option>(a => ({
    content: a,
    value: a,
    userAdded: true
  }));
}

export function artifactToEndpointFragment(value: CollectionDiscriminator): string;
export function artifactToEndpointFragment(value: Artifact['type']): string;
export function artifactToEndpointFragment(value: Artifact['type'] | CollectionDiscriminator) {
  return pluralizeLandingType(value);
}

export function pluralizeLandingType(discriminator: Artifact['type'] | CollectionDiscriminator) {
  switch (discriminator) {
    case Discriminator.Actor:
      return 'actors';
    case Discriminator.Attack:
      return 'attacks';
    case Discriminator.DataSource:
      return 'datasource';
    case Discriminator.Software:
      return 'software';
    case Discriminator.Vulnerability:
      return 'vulnerabilities';
    case Discriminator.Action:
      return 'NIST 800-53 controls';
    default: {
      console.warn('discriminatorToEndpointFragment Unhandled type', discriminator);
      return null;
    }
  }
}

export function getLandingName(asId: boolean, data: Partial<HyperTag>) {
  return asId ? data.external_source_id : data.name;
}

export function getSeverityLevel(baseScore: number): MandiantSeverityLevel {
  if (baseScore === 0) {
    return 'None';
  } else if (baseScore < 4) {
    return VulnerabilitiesScore.LOW;
  } else if (baseScore < 7) {
    return VulnerabilitiesScore.MEDIUM;
  } else if (baseScore < 9) {
    return VulnerabilitiesScore.HIGH;
  } else if (baseScore <= 10) {
    return VulnerabilitiesScore.CRITICAL;
  }
}

export function hashShortener(hash: string): string {
  const maxLength = 5;

  if (hash?.length > maxLength) {
    const middle = Math.floor(hash.length / 2);
    const replaceLength = 15;
    const start = middle - Math.floor(replaceLength / 2);
    const end = start + replaceLength;
    const shortenedHash = hash.substring(0, start) + ' ...... ' + hash.substring(end);
    return shortenedHash;
  }

  return hash;
}

export function getAliasesByType(item: LandingArtifact): string[] {
  switch (item?.type) {
    case Discriminator.Actor:
      return item.actor_aliases?.slice();
    case Discriminator.Software:
      return item.software_aliases?.slice();
    case Discriminator.Vulnerability:
      return item.vulnerability_aliases?.slice();
  }

  return [];
}

export function getTagIDs(item: LandingArtifact, type: Discriminator) {
  switch (type) {
    case Discriminator.Actor:
      return item.actors;
    case Discriminator.Attack:
      return item.attacks;
    case Discriminator.Software:
      return item.software;
    case Discriminator.Vulnerability:
      return item.vulnerabilities;
    default:
      return [];
  }
}

export function filterOutSelf(self: string) {
  return function (item: string) {
    return !item?.toLowerCase().includes(self.toLowerCase());
  };
}
