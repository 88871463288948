import React from 'react';

import { faStar } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

import Icon from 'snap-ui/Icon';
import Popper from 'snap-ui/Popper';
import { ClickAwayListener, styled, useTheme } from 'snap-ui/util';

import UndetectedThreatIcon from 'module/Icons/UndetectedThreatIcon';
import { RedMarkerCreationPayload } from 'module/Session/Session.type';

import { Marker } from 'types/marker';

import { addDurationISOFormatted } from 'utilities/TimeUtils';

import MarkerForm, { MarkerPayload } from './MarkerForm';

const RedMarkerPopupContainer = styled('div')`
  &.RedMarkerPopup {
    > button {
      padding: 0;
      &:hover {
        background-color: transparent;
      }
    }
  }

  a {
    cursor: pointer;
  }
`;
const MarkerFormContainer = styled(MarkerForm)`
  & .MuiStack-root {
    padding: 10px;
    padding-top: 34px;
  }
  min-width: 400px;
  border: 1px solid hsl(282, 73%, 74%);
  background-color: hsl(0, 0%, 16.1%);
  border-radius: 4px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12));
  background-color: hsl(0, 0%, 7.1%);
  cursor: auto;
  box-shadow: 1px 1px 7px 0px hsl(0deg 0% 8%);
`;

type Props = {
  className?: string;
  hit?: Marker;
  offset?: number;
  startTime?: string;
  severity?: string;
  detectionName?: 'UNDETECTED' | 'DETECTED';
  onClose?: () => void;
  onDelete?(id: number): Promise<void>;
  onAdd(marker: RedMarkerCreationPayload): Promise<void>;
};

const PopperContainer = styled(Popper)`
  z-index: ${p => p.theme.zIndex.navBar};
`;
function RedMarkerPopup({ hit, ...props }: Props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const hitAttack = hit?.attack_names || [];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { palette } = useTheme();

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const safeHit = hit
    ? {
        ...('id' in hit
          ? {
              id: hit.id,
              text: hit.description || ''
            }
          : {}),
        offset: props.offset,
        attack_names: hitAttack.length > 0 ? hitAttack : [],
        severity: 'severity' in hit ? hit.severity : undefined
      }
    : {
        offset: props.offset,
        attack_names: []
      };

  return (
    <RedMarkerPopupContainer className={classNames('RedMarkerPopup', props.className)}>
      <a onClick={handleToggle}>{getIconByBadgeName(props.detectionName)}</a>
      <PopperContainer
        data-testid='RedMarkerPopup'
        placement='left-start'
        anchorEl={anchorEl}
        open={open}
        className='ToolbarItem-popper'
      >
        <ClickAwayListener onClickAway={handleClose}>
          <span>
            <MarkerFormContainer
              isLoading={isLoading}
              initialValues={safeHit}
              onDelete={handleDelete}
              onSubmit={handleAdd}
            />
          </span>
        </ClickAwayListener>
      </PopperContainer>
    </RedMarkerPopupContainer>
  );

  function handleAdd(marker: MarkerPayload) {
    setIsLoading(true);
    const payload: RedMarkerCreationPayload = {
      description: marker.text,
      timestamp: addDurationISOFormatted(props.startTime, marker.offset),
      severity: marker.severity,
      attack_names: marker.attack_names
    };

    if (hit?.event) payload.event_id = hit.event.id;

    props.onAdd(payload).finally(() => {
      setIsLoading(false);
      handleClose();
    });
  }

  async function handleDelete(id: number) {
    setIsLoading(true);
    await props.onDelete(id).catch(() => {
      setIsLoading(false);
    });
  }

  function getIconByBadgeName(detectionName: Props['detectionName']): JSX.Element {
    switch (detectionName) {
      case 'UNDETECTED':
        return <UndetectedThreatIcon />;
      case 'DETECTED':
        return <Icon icon={faStar} color='error' />;
      default:
        return <Icon icon={faStar} color={palette.common.white} />;
    }
  }
}

export default RedMarkerPopup;
