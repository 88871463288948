import React from 'react';

import { useLocation } from 'react-router-dom';

export default function useLocationState<T>(): T {
  const { state } = useLocation();

  React.useEffect(() => window.history.replaceState({}, document.title), [state]);

  return state as T;
}
