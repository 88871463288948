import React from 'react';

import { useFormikContext } from 'formik';

import { Autocomplete } from 'snap-ui/Autocomplete';
import PrimaryCircularProgress from 'snap-ui/CircularProgress';
import Typography from 'snap-ui/Typography';

import { ApiError } from 'module/ApiError';
import { ImportForm } from 'module/Import';
import { JobType } from 'module/Job';

import { Status } from 'storage';

import { CreateJobSchedulePayload, IntegrationFormData } from './Integration.type';
import { useScheduledJobs } from './useScheduledJobs';

const BASE_IMPORT_JOB: Omit<CreateJobSchedulePayload, 'name' | 'cron_trigger'> = {
  criteria: 'Filter',
  analytic_filter: {
    op: 'equals',
    field: 'id',
    value: null
  },
  type: JobType.Import
};

const API_SYNC_COMPATIBLE_INTEGRATION_TYPES = ['Chronicle', 'MicrosoftSentinel', 'MicrosoftDefender', 'Splunk'];

export default function IntegrationScheduledImport({ integration_id }: Pick<ImportForm, 'integration_id'>) {
  const { values } = useFormikContext<IntegrationFormData>();
  const {
    jobs,
    fetchStatus: jobsStatus,
    fetchErrorProps: jobsErrorProps,
    createJobSchedule,
    deleteJobSchedule,
    updateJobSchedule,
    taskStatus: scheduleStatus,
    taskErrorProps: scheduleErrorProps
  } = useScheduledJobs(integration_id);

  const { name, type } = values;

  const isLoading = [jobsStatus, scheduleStatus].includes(Status.pending);

  // using `find` as we're assuming only one "Import" job will be scheduled
  // since an "Import" job is all or nothing
  const importJob = React.useMemo(() => jobs.find(j => j.type === JobType.Import), [jobs]);

  if (!integration_id) return null;
  if (!API_SYNC_COMPATIBLE_INTEGRATION_TYPES.includes(type)) return null;

  function handleChange(value: string): void {
    if (importJob && value) {
      updateJobSchedule(integration_id, importJob.guid, { ...importJob, cron_trigger: value });
    } else if (importJob) {
      deleteJobSchedule(integration_id, importJob.guid);
    } else if (value) {
      createJobSchedule(integration_id, {
        ...BASE_IMPORT_JOB,
        name: `${name} - Scheduled Import`,
        cron_trigger: value
      });
    }
  }

  return (
    <div>
      <Typography variant='h4'>Automatic Import</Typography>
      <p>
        Periodically sync existing native detections for this integration into SnapAttack. Requires API integration.
      </p>
      <ApiError {...jobsErrorProps} />
      <ApiError {...scheduleErrorProps} />
      <div className='actions'>
        <Autocomplete
          className='DetectionImportControl'
          name='Schedule'
          label='Schedule'
          disableClearable
          disabled={isLoading}
          onChange={handleChange}
          options={[
            {
              content: 'None',
              label: 'None',
              value: ''
            },
            {
              content: '1 hour',
              label: '1 hour',
              value: '0 */1 * * *'
            },
            {
              content: '4 hours',
              label: '4 hours',
              value: '0 */4 * * *'
            },
            {
              content: '8 hours',
              label: '8 hours',
              value: '0 */8 * * *'
            },
            {
              content: '1 day',
              label: '1 day',
              value: '0 0 * * *'
            },
            {
              content: '1 week',
              label: '1 week',
              value: '0 0 * * 0'
            }
          ]}
          value={importJob?.cron_trigger || ''}
          endAdornment={isLoading ? <PrimaryCircularProgress size={16} /> : null}
        />
      </div>
    </div>
  );
}
