import { styled } from 'snap-ui/util';

export const style = {
  maxWidth: '50%',

  '& hr': {
    marginTop: '-6px'
  },

  '& form': {
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },

  '& .action': {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '8px'
  }
};

export const TableSearchInformationalMessage = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${p => p.theme.spacing(5, 0)};
`;
