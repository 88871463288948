import React from 'react';

import { faSignalBars } from '@fortawesome/pro-regular-svg-icons';
import { faBars } from '@fortawesome/pro-solid-svg-icons';

import Menu from 'snap-ui/Menu';
import { styled } from 'snap-ui/util';

import { addToCollectionInterfaceFactory } from 'module/Curation';
import { AddToCollection } from 'module/Curation/AddToCollection/AddToCollection';
import { buildCuratedFilter } from 'module/Curation/Curation.service';
import {
  DetectionDeploymentDialog,
  DetectionDeploymentInterface,
  DetectionDeploymentMenuButton
} from 'module/DetectionDeployment';
import { useDetectionExportInterface } from 'module/Export';
import { useFilterRegistry } from 'module/GlobalFilter';
import { JobType, huntInterfaceFactory } from 'module/Job';
import { HamburgerFab } from 'module/Layout/Styled';

import { ArtifactType, Guid } from 'types/common';

import { DisplayKind } from './Feed.type';

const [SelectedATCInterface, SelectedATCButton, SelectedATCDialog] =
  addToCollectionInterfaceFactory('SelectedATCContext');
const [RecommendedATCInterface, RecommendedATCButton, RecommendedATCDialog] = addToCollectionInterfaceFactory(
  'AddRecommendedToCollectionContext'
);

const [HuntInterface, HuntButton, HuntDialog] = huntInterfaceFactory('HuntContext');
const [BulkConfidenceInterface, BulkConfidenceButton, BulkConfidenceDialog] =
  huntInterfaceFactory('BulkConfidenceContext');

const MenuContainer = styled(Menu)`
  .MuiPaper-root {
    margin-top: ${p => p.theme.spacing(1)};
    padding: ${p => p.theme.spacing(2, 1)};
    border-top: 3px solid ${p => p.theme.palette.primary.main};
    border-radius: 0;
    background-color: ${p => p.theme.palette.background.paper};
  }
`;

export default function DetectionFeedMenu(props: {
  feedTotal: number;
  feedGuid?: Guid[];
  hasActivity: boolean;
  displayKind: DisplayKind;
  onDisplayKindChange(kind: DisplayKind): void;
  selectionModel: Guid[];
}) {
  const burgerRef = React.useRef();
  const { topic, toQuery } = useFilterRegistry(ArtifactType.Analytic);
  const [showBurgerMenu, setShowBurgerMenu] = React.useState(false);
  const queryWithSelectionsOrAll =
    props.selectionModel.length > 0 ? buildCuratedFilter(props.selectionModel) : toQuery();
  const [DetectionExportButton, DetectionExportDialog] = useDetectionExportInterface();

  return (
    <SelectedATCInterface>
      <RecommendedATCInterface>
        <HuntInterface jobType={JobType.Hunt}>
          <BulkConfidenceInterface jobType={JobType.Rank}>
            <DetectionDeploymentInterface>
              <HamburgerFab
                className='no-print'
                disabled={props.hasActivity || props.feedTotal === 0}
                aria-label='Bulk actions'
                icon={faBars}
                onClick={() => setShowBurgerMenu(true)}
                ref={burgerRef}
              />
              <MenuContainer
                className='no-print'
                anchorEl={burgerRef.current}
                onClose={() => setShowBurgerMenu(false)}
                onClick={() => setShowBurgerMenu(false)}
                open={showBurgerMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <DetectionExportButton />
                <DetectionDeploymentMenuButton />
                <DetectionDeploymentMenuButton title='Undeploy Detections' undeploy />
                <HuntButton />
                <BulkConfidenceButton icon={faSignalBars} />
                <SelectedATCButton />
                <RecommendedATCButton title='Add Recommended Detections to Collection' />
              </MenuContainer>
              <SelectedATCDialog
                type={topic as AddToCollection['type']}
                selectionModel={props.displayKind === DisplayKind.Grid ? props.selectionModel : undefined}
                criteria={props.displayKind === DisplayKind.Card ? queryWithSelectionsOrAll : undefined}
                title={props.displayKind === DisplayKind.Card ? 'Add filtered items to a collection' : undefined}
              />
              <RecommendedATCDialog
                type={topic as AddToCollection['type']}
                selectionModel={props.displayKind === DisplayKind.Grid ? props.selectionModel : undefined}
                criteria={props.displayKind === DisplayKind.Card ? queryWithSelectionsOrAll : undefined}
                fromRecommendedDetection
                title='Add Recommended Detections to Collection'
              />
              <HuntDialog query={queryWithSelectionsOrAll} selections={props.selectionModel} />
              <BulkConfidenceDialog query={queryWithSelectionsOrAll} selections={props.selectionModel} />
              <DetectionDeploymentDialog criteria={queryWithSelectionsOrAll} />
              <DetectionExportDialog filter={queryWithSelectionsOrAll} />
            </DetectionDeploymentInterface>
          </BulkConfidenceInterface>
        </HuntInterface>
      </RecommendedATCInterface>
    </SelectedATCInterface>
  );
}
