import React from 'react';

import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import Icon from 'snap-ui/Icon';

import Path from 'constants/paths';

import TagIcon from 'module/Landing/TagIcon';
import { Discriminator as TagDiscriminator } from 'module/Tag';

import { Tracked } from 'types/common';

import { BASCampaign, BASCampaignFeed, BASCampaignSupplemental, BASJob, Discriminator } from './BAS.type';

export function getFlatObjectCount(data: Record<string, number>, omitKeys?: string | string[]): number {
  if (!data || isEmpty(data)) return 0;
  const total = Object.values(omit(data, omitKeys)).reduce((total: number, value: number) => total + value, 0);
  return total as number;
}

export function getLinkPath(campaign: BASCampaign): string {
  if (campaign.collection) return `${Path.Collection}/${campaign.linked_artifact.guid}`;
  if (campaign.tag) return `${Path.Collection}/${campaign.tag.type}/${campaign.tag.name}`;
  return null;
}

export function getLinkedArtifactIcon(campaign: BASCampaign): JSX.Element {
  if (campaign.collection) return <Icon.Collection className='no-print' color='primary' />;
  if (campaign.tag) return <TagIcon discriminator={campaign.linked_artifact.type as TagDiscriminator} iconOnly />;

  return null;
}

export function composeCampaignWithSupplement<C extends Tracked = BASCampaignFeed>(
  campaign: C,
  supplementals: BASCampaignSupplemental[]
): C {
  const supplemental = supplementals.find(supplemental => supplemental.guid === campaign.guid);

  const newCampaign = {
    ...campaign,
    ...supplemental,
    linked_artifact: {}
  };
  newCampaign.linked_artifact = newCampaign.collection || newCampaign.tag || newCampaign.linked_artifact;

  return newCampaign;
}

export function getTestCaseName(job: BASJob): string {
  const { discriminator, test_cases = [], script } = job || {};
  return (discriminator === Discriminator.manual ? test_cases[0] : script?.name) || '';
}
