import React from 'react';

import useIntegrations, { IntegrationCatalog } from 'module/Integration/useIntegrations';

const IntegrationContext = React.createContext<IntegrationCatalog>(null);
IntegrationContext.displayName = 'IntegrationContext';

function useIntegrationCatalog(): IntegrationCatalog {
  const context = React.useContext<IntegrationCatalog>(IntegrationContext);

  if (!context) {
    throw new Error('useIntegrationCatalog must be used within the IntegrationContext');
  }

  return context;
}

function IntegrationProvider({ children }: { children: React.ReactNode }): React.ReactElement {
  const catalog: IntegrationCatalog = useIntegrations();

  return <IntegrationContext.Provider value={catalog}>{children}</IntegrationContext.Provider>;
}

export { IntegrationProvider, useIntegrationCatalog };
