import React from 'react';

import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';

import Icon from 'snap-ui/Icon';
import PanelFrame, { Props as PanelProps } from 'snap-ui/PanelFrame';
import { styled, useTheme } from 'snap-ui/util';

import { useDashboardStats } from 'module/Dashboard/DetectionDashboard';

import ChangePercentage from '../ChangePercentage';

const COLORS = ['url(#coveredGradient)', 'url(#attack-increase-pattern)', 'url(#availableGradient)'];

export type Props = Partial<PanelProps> & {
  className?: string;
};

function AttackPanel({ className, title, ...extraProps }: Props) {
  const { attackCoverage, isPending, isResolved } = useDashboardStats();
  const { palette } = useTheme();

  const increasedCoverage = attackCoverage.change >= 0;
  const initial = (attackCoverage.initial / attackCoverage.children) * 100;
  const change = (attackCoverage.change / attackCoverage.children) * 100;
  const available = 100 - (initial + Math.abs(change));

  const data = [
    { name: increasedCoverage ? 'Initial Coverage' : 'Coverage', percent: initial },
    { name: increasedCoverage ? 'Increase' : 'Decrease', percent: Math.abs(change) },
    { name: 'Available', percent: available }
  ];

  let coverageStart = increasedCoverage ? initial : initial + Math.abs(change);
  let coverageCurrent = !increasedCoverage ? initial : initial + Math.abs(change);

  coverageStart = Number(coverageStart.toFixed(0));
  coverageCurrent = Number(coverageCurrent.toFixed(0));
  const changePercent = coverageCurrent - coverageStart;

  return (
    <PanelFrame
      {...extraProps}
      className={className}
      loading={isPending}
      title={title}
      content={
        isResolved && (
          <>
            <div className='attack-change-percent'>
              <ChangePercentage changePercent={changePercent} />
            </div>
            <div className='attack-change'>
              <div className='attack-counts'>
                {!!changePercent && (
                  <>
                    <div>{coverageStart.toLocaleString() || 0}%</div>
                    <Icon className='arrow' icon={faArrowRight} />
                    <div>{coverageCurrent.toLocaleString() || 0}%</div>
                  </>
                )}
              </div>
            </div>
            <ResponsiveContainer height={350}>
              <PieChart height={400}>
                <defs>
                  <pattern
                    id='attack-increase-pattern'
                    width={10}
                    height={10}
                    patternUnits='userSpaceOnUse'
                    patternTransform={'rotate(-45)'}
                  >
                    <rect
                      width='20'
                      height='20'
                      transform='translate(0,0)'
                      fill={increasedCoverage ? palette.lime.dark : palette.error.dark}
                      fillOpacity={0.8}
                      stroke={palette.common.white}
                      strokeOpacity={0.4}
                      strokeWidth={'5px'}
                      strokeDasharray='20'
                    ></rect>
                  </pattern>
                  <linearGradient id='coveredGradient' x1='0' y1='0' x2='1' y2='0'>
                    <stop offset='25%' stopColor={palette.lime.main} stopOpacity={0.9} />
                    <stop offset='75%' stopColor={palette.lime.light} stopOpacity={0.9} />
                  </linearGradient>
                  <linearGradient id='availableGradient' x1='0' y1='0' x2='1' y2='0'>
                    <stop offset='5%' stopColor={palette.common.black} stopOpacity={0.8} />
                    <stop offset='95%' stopColor={palette.grey[700]} stopOpacity={1} />
                  </linearGradient>
                </defs>
                <Pie
                  data={data}
                  innerRadius={100}
                  outerRadius={130}
                  startAngle={90}
                  endAngle={450}
                  stroke={palette.common.black}
                  dataKey='percent'
                >
                  {data?.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                  <Label value={`${coverageCurrent.toFixed(0)}%`} position='center' />
                </Pie>
                <Legend iconType='circle' verticalAlign='top' align='center' />
              </PieChart>
            </ResponsiveContainer>
          </>
        )
      }
    />
  );
}

const StyledAttackPanel = styled(AttackPanel)<Props>`
  .panel-contents {
    position: relative;
  }

  .attack-change {
    position: absolute;
    bottom: ${p => p.theme.spacing(1)};

    width: 100%;
  }

  .attack-counts {
    font-size: 2.5rem;
    display: flex;
    flex-direction: row;
    gap: ${p => p.theme.spacing(3)};
    justify-content: center;
    align-items: center;
  }

  .arrow {
    font-size: 2rem;
  }

  & .recharts-text.recharts-label {
    font-size: 5em;
    fill: ${p => p.theme.palette.common.white};
    background-color: ${p => p.theme.palette.common.black};
    color: ${p => p.theme.palette.common.white};
    text-shadow: 2px 1px 2px ${p => p.theme.palette.common.black};
    background-clip: text;
    border: 1px solid ${p => p.theme.palette.common.black};
  }
`;

export default StyledAttackPanel;
