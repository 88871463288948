import React from 'react';

import { Pagination as MuiPagination } from '@mui/material';

export type PaginationProps = {
  className?: string;
  changePage: (next: number) => void;
  numPages: number;
  page: number;
  zeroIndex?: boolean;
};

export default function Pagination({
  className,
  changePage: changePageOrig,
  numPages,
  page: pageOrig,
  zeroIndex
}: PaginationProps) {
  const pageOffset = zeroIndex ? -1 : 0;
  const page = pageOrig - pageOffset;

  const changePage = React.useCallback<typeof changePageOrig>(
    page => {
      changePageOrig(page + pageOffset);
    },
    [changePageOrig, pageOffset]
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    changePage(value);
  };

  return (
    <MuiPagination
      className={className}
      boundaryCount={0}
      count={numPages}
      color='primary'
      defaultPage={1}
      shape='rounded'
      siblingCount={2}
      size='large'
      showFirstButton
      showLastButton
      onChange={handleChange}
      page={page}
      variant='outlined'
    />
  );
}
