import React from 'react';

import { styled } from './util';

type ImageProps = {
  src: string;
  width: string | number;
  height: string | number;
  asHero?: boolean;
  className?: string;
};

const shouldForwardProp = (prop: string) => prop === 'className';

const StyledImage = styled('div', { shouldForwardProp })<{
  src: string;
  width: string | number;
  height: string | number;
  asHero: boolean;
}>`
  background-image: url('${p => p.src}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${p => (p.asHero ? 'cover' : 'contain')};
  width: ${p => (typeof p.width === 'number' ? `${p.width}px` : p.width)};
  height: ${p => (typeof p.height === 'number' ? `${p.height}px` : p.height)};
`;

export default function Image({ src, width, height, asHero = false, className }: ImageProps): JSX.Element {
  return <StyledImage className={className} src={src} width={width} height={height} asHero={asHero} />;
}
