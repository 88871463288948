import React from 'react';

import { getLanguageTargets } from 'apis/resources/analytic';

import { useAuth } from 'provider';

import { Catalog, Status, Scheme, useAsyncStorage } from 'storage';

import { Language } from 'types/analytic';

type Context = {
  data: Language[];
  allData: Language[];
  refresh: () => void;
  status: Status;
  reset: () => void;
};

const LanguageContext = React.createContext<Context>(null);
LanguageContext.displayName = 'LanguageContext';

function useLanguageContext(): Context {
  const context = React.useContext(LanguageContext);

  if (!context) {
    throw new Error('useLanguageContext must be used within the LanguageContext');
  }

  return context;
}

function LanguageProvider({
  children,
  value = []
}: {
  children: React.ReactNode;
  value?: Language[];
}): React.ReactElement {
  const { user } = useAuth();
  const { data, run, status, reset } = useAsyncStorage<Language[]>(Scheme.sessionStorage, Catalog.language, value);

  const refresh = React.useCallback(() => {
    if (user.id) {
      reset();
      run(() => getLanguageTargets());
    }
  }, [reset, run, user.id]);

  const filteredData = React.useMemo(() => {
    return data.filter(lang => !lang.hidden);
  }, [data]);

  const payload = React.useMemo(() => {
    return { data: filteredData, allData: data, refresh, status, reset };
  }, [filteredData, data, refresh, status, reset]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return <LanguageContext.Provider value={payload}>{children}</LanguageContext.Provider>;
}

export { LanguageProvider, useLanguageContext };
