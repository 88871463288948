import { KeyValueRule, SectionForm, SectionKind, SpecialSigmaModifier } from 'types/analytic';
import { BooleanString } from 'types/common';

/* API URL */
export const ANALYTICS_URL = '/signatures';

export const EMPTY_KEY_VALUE_RULE: KeyValueRule = {
  all: BooleanString.False,
  field: '',
  modifier: SpecialSigmaModifier.Equal,
  values: ['']
};

export const EMPTY_SECTION: SectionForm = {
  kind: SectionKind.KeyValue,
  name: 'Section',
  rules: [EMPTY_KEY_VALUE_RULE],
  values: ['']
};
