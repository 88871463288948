import Menu from 'snap-ui/Menu';
import { styled } from 'snap-ui/util';

import { ArtifactScore } from 'types/common';

export const LevelMenuBadgeContainer = styled('div')`
  button {
    padding: ${p => p.theme.spacing(0, 2)};
    border-width: 2px;
    border-radius: 10px;
    align-items: center;
    gap: ${p => p.theme.spacing(2)};
    padding-top: 8px;
    padding-bottom: 8px;
    width: 131.91px;
    height: 47.91px;
    &.no-click {
      cursor: default;
    }
    &.no-click:hover {
      background: none;
    }
    &.${ArtifactScore.HIGHEST} {
      color: ${p => p.theme.palette.error.main};
    }
    &.${ArtifactScore.HIGH} {
      color: ${p => p.theme.palette.orange.main};
    }
    &.${ArtifactScore.MEDIUM} {
      color: ${p => p.theme.palette.warning.main};
    }
    &.${ArtifactScore.LOW} {
      color: ${p => p.theme.palette.success.main};
    }
    &.${ArtifactScore.LOWEST} {
      color: ${p => p.theme.palette.info.main};
    }
    &.${ArtifactScore.UNKNOWN} {
      color: ${p => p.theme.palette.grey[500]};
    }

    svg {
      font-size: 20px;
    }

    .dropdown-icon {
      font-size: 16px;
    }
  }

  .text {
    font-size: 12px;
    text-align: left;
    line-height: 14px;
  }
`;

export const StyledMenu = styled(Menu)`
  .MuiMenu-list {
    background: ${p => p.theme.palette.background.default};
    border: 1px solid ${p => p.theme.palette.secondary.dark};

    .level:hover {
      background-color: ${p => p.theme.palette.grey[900]};
    }

    .level {
      &.${ArtifactScore.HIGHEST} {
        color: ${p => p.theme.palette.error.main};
      }
      &.${ArtifactScore.HIGH} {
        color: ${p => p.theme.palette.orange.main};
      }
      &.${ArtifactScore.MEDIUM} {
        color: ${p => p.theme.palette.warning.main};
      }
      &.${ArtifactScore.LOW} {
        color: ${p => p.theme.palette.success.main};
      }
      &.${ArtifactScore.LOWEST} {
        color: ${p => p.theme.palette.info.main};
      }
      &.${ArtifactScore.UNKNOWN} {
        color: ${p => p.theme.palette.grey[500]};
      }
    }
  }
`;
