import mixpanel from 'mixpanel-browser';

import { NOOP } from 'utilities/FunctionUtils';

import { EngagementInterface, PropertyBatch } from './Engagement.type';
import { standardize } from './Engagement.util';
import Fingerprint from './Fingerprint';

const t = process.env.NODE_ENV === 'test';

export const Mixpanel: EngagementInterface = t
  ? {
      _projectToken: undefined,
      _init: NOOP,
      identify: NOOP,
      unidentify: NOOP,
      track: NOOP,
      trackPerson: NOOP,
      trackPersonBatch: NOOP,
      trackPersonOnce: NOOP,
      trackPersonOnceBatch: NOOP,
      trackPersonIncrement: NOOP,
      assignPreferredOrganization: NOOP,
      assignOrganizations: NOOP,
      register: NOOP
    }
  : {
      _projectToken: undefined,
      _init: function (tracking: string) {
        this._projectToken = tracking;
        if (tracking) {
          mixpanel.init(tracking, {
            api_host: `${window.location.origin}/engagement`,

            // events are printed to the console window for local development
            debug: process.env.NODE_ENV !== 'production',

            // If this is true, mixpanel cookies will be marked as
            // secure, meaning they will only be transmitted over https
            // Set to false for local debugging
            secure_cookie: true,

            // whether to ignore or respect the web browser's Do Not Track setting
            ignore_dnt: true
          });
        } else console.error('Engagement tracking not set. Open endpoint returned null tracking.');
      },
      assignPreferredOrganization: function (value: string) {
        if (this._projectToken) mixpanel.people.set('preferred_organization', value);
      },
      assignOrganizations: function (value: string | string[]) {
        if (this._projectToken) mixpanel.set_group('organizations', value);
      },
      identify: function (value: string) {
        if (this._projectToken) mixpanel.identify(value);
      },
      register: function (value: PropertyBatch) {
        if (this._projectToken) mixpanel.register(value);
      },
      track: function (event: Fingerprint) {
        if (this._projectToken) mixpanel.track(standardize(event.name), event.dict);
      },
      trackPerson: function (key: string, value: string | number) {
        if (this._projectToken) mixpanel.people.set(standardize(key), value);
      },
      trackPersonBatch: function (value: PropertyBatch) {
        if (this._projectToken) mixpanel.people.set(value);
      },
      trackPersonOnce: function (key: string, value: string | number) {
        if (this._projectToken) mixpanel.people.set_once(standardize(key), value);
      },
      trackPersonOnceBatch: function (value: PropertyBatch) {
        if (this._projectToken) mixpanel.people.set_once(value);
      },
      trackPersonIncrement: function (key: string, value: number) {
        if (this._projectToken) mixpanel.people.increment(standardize(key), value);
      },
      unidentify: function () {
        if (this._projectToken) mixpanel.reset();
      }
    };
