import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import { Path } from 'constants/paths';

import { usePushSnack } from 'provider';

import { ArtifactType, Guid } from 'types/common';
import { Version } from 'types/common.zod';

export function useIsLatestVersionSnack(guid: Guid, versions: Version[], type: ArtifactType) {
  const pushSnack = usePushSnack();

  let path = '';
  switch (type) {
    case ArtifactType.Analytic:
      path = Path.Detection;
      break;
    case ArtifactType.Session:
      path = Path.Threat;
      break;
    case ArtifactType.Intel:
      path = Path.Intelligence;
      break;
    case ArtifactType.Collection:
      path = Path.Collection;
      break;
    case ArtifactType.AttackScript:
      path = Path.AttackScript;
      break;
  }

  React.useEffect(() => {
    if (guid && !isEmpty(versions) && path) {
      const latest = versions.slice().shift();

      if (latest.guid !== guid) {
        pushSnack(
          <>
            This version is not current. <Link to={`${path}/${latest.guid}`}>Click here</Link> for the most recent
            version.
          </>,
          'info',
          'center',
          'bottom',
          30000
        );
      }
    }
  }, [guid, path, pushSnack, versions]);
}
