import { styled } from 'snap-ui/util';

export const DialogContainer = styled('div')`
  .Form-field.required {
    margin-top: ${p => p.theme.spacing(4)};
  }

  .Divider {
    font-weight: bold;
    ::before,
    ::after {
      border-width: 2px;
      border-color: ${p => p.theme.palette.primary.main};
    }
  }
`;

export const Container = styled('div')`
  .actions {
    display: flex;
    justify-content: flex-end;
    padding: ${p => p.theme.spacing(6)};

    .DetectionImportControl {
      width: 180px;
    }
  }

  .TabsContent {
    padding-top: ${p => p.theme.spacing(8)};
    padding: ${p => p.theme.spacing(6)};
    min-height: 250px;
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(4)};
  }

  .MuiTabs-flexContainer {
    margin-top: ${p => p.theme.spacing(6)};
  }

  .MuiTabs-flexContainer > button {
    min-width: 150px;
    color: ${p => p.theme.palette.common.white};
  }

  .Form-field.required {
    margin-top: ${p => p.theme.spacing(4)};
  }
`;
