import React from 'react';

import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';

import Accordion, { AccordionDetails as _AccordionDetails, AccordionSummary } from 'snap-ui/Accordion';
import Button from 'snap-ui/Button';
import Chip from 'snap-ui/Chip';
import PrimaryCircularProgress from 'snap-ui/CircularProgress';
import { DisplayDialog } from 'snap-ui/Dialog';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import TruncateContainer from 'module/Layout/TruncateContainer';
import { AttackNode } from 'module/Matrix/Matrix.type';
import useForest from 'module/Matrix/useForest';
import { AttackType } from 'module/Tag';
import AttackChip from 'module/Widgets/AttackChip';
import TagLandingPreview from 'module/Widgets/TagLandingPreview';

import { Status } from 'storage/Storage.type';

import { filterForestByMitreIDBasedOnArray } from '../Landing.service';
import { getLandingMatrixStyle, LandingMiniMatrix } from '../Landing.style';
import { useLandingCatalog } from '../LandingProvider';

const AccordionDetails = styled(_AccordionDetails)`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(5)};
`;

const AttackControlsContainer = styled('div')`
  display: flex;
  justify-content: space-between;

  .LandingAttack-toggles,
  .LandingAttack-view-matrix {
    display: flex;
    gap: ${p => p.theme.spacing(3)};
  }
`;

const BOTH_ATTACK_TYPES = [AttackType.Technique, AttackType.Subtechnique];
export default function AttackTags() {
  const { source, handleReset } = useLandingCatalog();

  const { forest, status: forestStatus } = useForest();
  const [showing, setShowing] = React.useState<AttackType[]>(BOTH_ATTACK_TYPES);
  const [matrixDialogIsOpen, setMatrixDialogIsOpen] = React.useState<boolean>(false);

  if (isEmpty(source.attacks)) return null;

  function handleClick(type: AttackType) {
    const removing = showing?.some(attackType => attackType === type);

    if (removing) {
      if (showing.length === 1) setShowing(BOTH_ATTACK_TYPES);
      else setShowing(showing => showing.filter(attackType => attackType !== type));
    } else {
      setShowing([type, ...showing]);
    }
  }

  function onClose() {
    setMatrixDialogIsOpen(false);
  }

  return (
    <Accordion className='LandingSection-accordion' defaultExpanded={source.attacks.length > 0} elevation={0}>
      <AccordionSummary className='LandingSection-accordionSummary' expandIcon={<Icon icon={faCaretUp} />}>
        <Typography variant='h4'>ATT&CK Techniques</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <AttackControlsContainer>
          <div className='LandingAttack-toggles'>
            <Tooltip arrow placement='top' title='Toggle display of Techniques'>
              <Chip
                className='LandingSection-chip attack technique'
                label={`${showing?.some(t => t === AttackType.Technique) ? 'Hide' : 'Show'} Techniques`}
                onClick={() => handleClick(AttackType.Technique)}
              />
            </Tooltip>
            <Tooltip arrow placement='top' title='Toggle display of Sub-techniques'>
              <Chip
                className='LandingSection-chip attack subtechnique'
                label={`${showing?.some(t => t === AttackType.Subtechnique) ? 'Hide' : 'Show'} Sub-techniques`}
                onClick={() => handleClick(AttackType.Subtechnique)}
              />
            </Tooltip>
          </div>
          <Tooltip arrow title='View on MITRE ATT&CK® Matrix' wrap>
            <Button
              className='LandingAttack-view-matrix'
              onClick={() => setMatrixDialogIsOpen(true)}
              aria-label='View on MITRE ATT&CK® Matrix'
              color='primary'
              variant='outlined'
              disabled={forestStatus === Status.pending || isEmpty(forest)}
            >
              {forestStatus === Status.pending && <PrimaryCircularProgress size={16} />}
              View as Matrix
            </Button>
          </Tooltip>
        </AttackControlsContainer>
        <TruncateContainer>
          {source.attacks
            ?.filter(attack => showing.some(type => attack.type === type))
            .sort((a, b) => a.mitre_id.localeCompare(b.mitre_id))
            .map(v => (
              <TagLandingPreview tag={v} key={v.id}>
                <AttackChip onClick={handleReset} tag={v} />
              </TagLandingPreview>
            ))}
        </TruncateContainer>
      </AccordionDetails>
      <DisplayDialog DialogProps={{ open: matrixDialogIsOpen, onClose, fullScreen: true }} title='MITRE ATT&CK® Matrix'>
        <LandingMiniMatrix
          forest={filterForestByMitreIDBasedOnArray(forest, source.attacks)}
          getNodeStyle={(node: AttackNode & { isIncluded: boolean }) => getLandingMatrixStyle(node, node.isIncluded)}
        />
      </DisplayDialog>
    </Accordion>
  );
}
