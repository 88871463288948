import Path from 'constants/paths';

import { AttackType, Discriminator } from 'module/Tag/Tag.type';

import { ArtifactType } from 'types/common';

import { formatShortTimestamp } from 'utilities/TimeUtils';

import { catalog, NUMBUCKETS } from './Matrix.const';
import {
  AttackNode,
  Display,
  Matrix,
  MitreNavigator,
  Palette,
  Statistic,
  StatisticNode,
  Summary,
  Tier
} from './Matrix.type';

export function scoreNode(node: StatisticNode) {
  return (node?.analytics ?? 0) + (node?.intelligence ?? 0) + (node?.sessions ?? 0);
}

/**
 * Drill into the leaf nodes first and match backwards to build the matrix from sub-techniques -> techniques -> tactics
 */
export function buildForest(node: AttackNode[], summary: Summary): Matrix {
  const result: StatisticNode[] = [];

  node.forEach(n => {
    const children: StatisticNode[] = n.attack_children?.length > 0 ? buildForest(n.attack_children, summary) : [];

    const statistic: Statistic = {
      intelligence: 0,
      sessions: 0,
      analytics: 0,
      ...summary[n.id]
    };

    const value: StatisticNode = {
      ...n,
      ...statistic,
      attack_children: children
    };
    result.push(value);
  });

  return result;
}

/**
 * Drill into the leaf nodes first and match backwards
 *
 * 1) Exclude any sub techniques that don't match expression
 * 2) Exclude any techniques that don't have sub techniques or match expression
 * 3) Exclude any tactics that don't have techniques or match expression
 */
export function filterForestByMitreID(nodes: AttackNode[] = [], summary) {
  return nodes
    .map(node => ({
      ...node,
      outcome: summary[node.mitre_id],
      attack_children: filterForestByMitreID(node.attack_children, summary)
    }))
    .filter(node => node.outcome || node.attack_children.length);
}

export const Sorter = mitreIDs => ({
  [AttackType.Tactic]: (a: AttackNode, b: AttackNode) => mitreIDs.indexOf(a.mitre_id) - mitreIDs.indexOf(b.mitre_id),
  [AttackType.Technique]: (a: AttackNode, b: AttackNode) => a.name.localeCompare(b.name),
  [AttackType.Subtechnique]: (a: AttackNode, b: AttackNode) => a.mitre_id.localeCompare(b.mitre_id)
});

/**
 * Sort leaf nodes first, working back to branch, and then trunk.
 *
 * 1) Sort Sub-techniques by mitre id `attack.txxxx.xxx`
 * 2) Sort Techniques alphabetically
 * 3) Sort tactics by https://attack.mitre.org/ showing left to right
 */
export function sortForest(nodeList: AttackNode[], sorter) {
  if (!nodeList || nodeList.length === 0) return nodeList;

  nodeList.forEach(n => {
    if (n.attack_children) sortForest(n.attack_children, sorter);
  });

  return nodeList.sort(sorter[nodeList[0].type]);
}

export function getNumberOfItemsPerBucket(max: number) {
  return Math.max(Math.floor(max / NUMBUCKETS), 1);
}

export function getPaletteNodeColorTier(
  theme: string,
  numBuckets: number,
  perBucket: number,
  count: number
): Tier | undefined {
  const palette = catalog[theme];
  if (!palette) return;
  let bucket = 0;

  if (count >= perBucket && count < perBucket * 2) bucket = 1;
  else if (count >= perBucket * 2 && count < perBucket * 3) bucket = 2;
  else if (count >= perBucket * 3 && count < perBucket * 4) bucket = 3;
  else if (count >= perBucket * 4 && count < perBucket * 5) bucket = 4;
  else if (count >= perBucket * 5) bucket = 5;

  return palette[Math.min(numBuckets, bucket)];
}

export function flatten(nodes: StatisticNode[], topic: ArtifactType): MitreNavigator['techniques'] {
  const techniques: MitreNavigator['techniques'] = [];

  nodes?.forEach(n => {
    if (n.type !== AttackType.Tactic) {
      const artifact =
        topic === ArtifactType.Analytic ? 'Detections' : topic === ArtifactType.Session ? 'Threats' : 'Intelligence';

      techniques.push({
        techniqueID: n.mitre_id,
        enabled: true,
        links: [
          {
            label: `View ${artifact} in SnapAttack: ${n.mitre_id}`,
            url: `${window?.location.origin}${Path.Collection}/${Discriminator.Attack}/${encodeURIComponent(n.name)}`
          }
        ],
        score: scoreNode(n),
        showSubtechniques: n.type === AttackType.Technique
      });
    }

    if (n.attack_children?.length) techniques.push(...flatten(n.attack_children, topic));
  });

  return techniques;
}

export function matrixToAttackNavigation(
  matrix: Matrix,
  meta: Omit<Display, 'showDrawer'>,
  topic: ArtifactType,
  palette?: Palette
): MitreNavigator {
  return {
    name: 'SnapAttack - Matrix',
    versions: {
      attack: '15',
      navigator: '5.0.1',
      layer: '4.5'
    },
    domain: 'enterprise-attack',
    description: `Exported from SnapAttack on ${formatShortTimestamp(new Date())}`,
    layout: {
      layout: 'flat',
      aggregateFunction: 'average',
      showID: meta.showMitreId,
      showName: true,
      showAggregateScores: meta.showCount,
      countUnscored: meta.showEmpty,
      expandedSubtechniques: 'all'
    },
    hideDisabled: false,
    links: [
      {
        label: 'View filtered Matrix in SnapAttack',
        url: window.location.href
      }
    ],
    techniques: flatten(matrix, topic),
    gradient: {
      colors: palette?.map(tier => tier.background) || ['#ff6666ff', '#ffe766ff', '#8ec843ff'],
      minValue: 0,
      maxValue: meta.maxRange
    }
  };
}
