import React from 'react';

import { useCanContext } from './useCanContext';

type Props = {
  children: React.ReactNode;
};

export default function Grant({ children }: Props) {
  const can = useCanContext();
  return can ? <>{children}</> : null;
}
