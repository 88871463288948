import React from 'react';

import { CancelToken } from 'apis';

import { Status, useAsync } from 'storage';

import { ArtifactType, Guid } from 'types/common';

import { EXISTING_COMPOSITE_SHELL } from './Curation.const';
import { getCollectionItems } from './Curation.service';
import { ExistingComposite, DuckedArtifact } from './Curation.type';

export default function useCompositeSearch(guid: Guid): {
  existing: DuckedArtifact[];
  existingIsPending: boolean;
  refresh(): void;
} {
  const { data, run, status, reset } = useAsync<ExistingComposite>(EXISTING_COMPOSITE_SHELL);
  const cancelTokenSourceRef = React.useRef(CancelToken.source());

  const cancelQuery = React.useCallback(() => {
    cancelTokenSourceRef.current.cancel();
    reset();
  }, [reset]);

  const refresh = React.useCallback(() => {
    if (guid) run(getCollectionItems(guid));
  }, [guid, run]);

  React.useEffect(() => {
    cancelQuery();
    const source = CancelToken.source();
    cancelTokenSourceRef.current = source;
    refresh();
  }, [cancelQuery, refresh]);

  const existing = React.useMemo(() => {
    return [
      ...data.threat_intelligences.map(t => ({
        ...t,
        duck: ArtifactType.Intel
      })),

      ...data.sessions.map(s => ({
        ...s,
        duck: ArtifactType.Session
      })),

      ...data.analytics.map(a => ({
        ...a,
        duck: ArtifactType.Analytic
      })),

      ...data.bas_scripts.map(a => ({
        ...a,
        duck: ArtifactType.AttackScript
      }))
    ] as DuckedArtifact[];
  }, [data]);

  return {
    existing,
    existingIsPending: status === Status.pending,
    refresh
  };
}
