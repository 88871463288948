import React from 'react';

import startCase from 'lodash/startCase';
import { Link } from 'react-router-dom';

import { CancelToken, CancelTokenSourceType } from 'apis';

import Path from 'constants/paths';

import AssociateArtifactsModal, { Message } from 'module/Widgets/AssociateArtifactsModal';

import { useAsync, Status } from 'storage';

import { ArtifactType, Named, Tracked } from 'types/common';

import { correctIndefiniteArticle } from 'utilities/TextUtils';

type Item = Named & Tracked;

const idleMessage = (associated: string, current: string): Message => ({
  data: (
    <div key='info'>
      Link {correctIndefiniteArticle(associated)} to this {current}.
    </div>
  ),
  severity: 'info'
});

const busyMessage = (): Message => ({
  data: <div key='warning'>Handling your request...</div>,
  severity: 'warning'
});

const emptyMessage = (associateds: string, current: string): Message => ({
  data: (
    <div key='warning'>
      There are no {associateds} you can link to this {current}.
    </div>
  ),
  severity: 'warning'
});

type AssociatorProps<A extends Item> = {
  associate(record: A): Promise<void>;
  associatedRecords: A[];
  associatedRecordsStatus: Status;
  disassociate(record: A): Promise<void>;
  fetchAllOptions(query: string, cancelToken: CancelTokenSourceType): Promise<A[]>;
  isOpen: boolean;
  onClose(): void;
  pathRoot: Path;
  types: {
    associated: ArtifactType;
    current: ArtifactType;
  };
};

export function Associator<A extends Item>({
  associate,
  associatedRecords,
  associatedRecordsStatus,
  disassociate,
  fetchAllOptions,
  isOpen,
  onClose,
  pathRoot,
  types
}: AssociatorProps<A>): JSX.Element {
  const labels = React.useMemo(
    () => ({
      associated: {
        singular: startCase(types.associated),
        plural: startCase(types.associated + 's')
      },
      current: startCase(types.current)
    }),
    //  (it is exhaustive but lint fails on objects)
    [types.associated, types.current] // eslint-disable-line react-hooks/exhaustive-deps
  );
  const IDLE_MESSAGE = React.useMemo(() => idleMessage(labels.associated.singular, labels.current), [labels]);
  const BUSY_MESSAGE = React.useMemo(() => busyMessage(), []);
  const EMPTY_MESSAGE = React.useMemo(() => emptyMessage(labels.associated.singular, labels.current), [labels]);

  const [isActing, setIsActing] = React.useState(false);
  const [action, setAction] = React.useState<boolean>();
  const [message, setMessage] = React.useState<Message>(IDLE_MESSAGE);
  const [selectedItem, setSelectedItem] = React.useState<A>();
  const [query, setQuery] = React.useState('');
  const { data: _allOptions, status: allOptionsStatus, run: allOptionsRun } = useAsync<A[]>([]);
  const allOptions = React.useMemo(() => {
    if (query) return _allOptions;
    const allGuids = _allOptions.map(o => o.guid);
    return [...(associatedRecords?.filter(ar => !allGuids.includes(ar.guid)) ?? []), ..._allOptions];
  }, [_allOptions, associatedRecords, query]);

  const isPending = [associatedRecordsStatus, allOptionsStatus].includes(Status.pending);

  React.useEffect(() => {
    const cancelToken = CancelToken.source();
    allOptionsRun(
      fetchAllOptions(query, cancelToken)
        .then(o => o || [])
        .catch(() => [])
    );

    return () => cancelToken.cancel();
  }, [allOptionsRun, fetchAllOptions, query]);

  React.useEffect(() => {
    if (allOptionsStatus === Status.resolved && !allOptions.length) setMessage(EMPTY_MESSAGE);
    else setMessage(IDLE_MESSAGE);
  }, [allOptions, allOptionsStatus, EMPTY_MESSAGE, IDLE_MESSAGE]);

  function startWorking(item: A, action: boolean) {
    setAction(action);
    setMessage(BUSY_MESSAGE);
    setIsActing(true);
    setSelectedItem(item);
  }

  function stopWorking() {
    setMessage(IDLE_MESSAGE);
    setIsActing(false);
    setSelectedItem(undefined);
  }

  function getUpdater(item, action): () => Promise<Message> {
    const actionText = action ? 'add' : 'remove';
    return async () => {
      try {
        await (action ? associate(item) : disassociate(item));
        return {
          data: (
            <div key='success'>
              {`Your request to ${actionText} `}
              <strong>
                <Link to={`${pathRoot}/${item.guid}`} target='_blank' rel='noopener noreferrer'>
                  {item.name}
                </Link>
              </strong>{' '}
              has been queued. Thanks!
            </div>
          ),
          severity: 'success'
        };
      } catch (e) {
        return {
          data: (
            <div key='error'>
              {`Oh... Didn't expect that trying to ${actionText} `}
              <strong>{item.name}.</strong> to this ${types.current}. {e.message}
            </div>
          ),
          severity: 'error'
        };
      }
    };
  }

  function handleToggleClick(item: A, action: boolean) {
    startWorking(item, action);
    return getUpdater(item, action)()
      .then(setMessage)
      .finally(() => {
        stopWorking();
      });
  }

  function handleClose() {
    onClose();
    stopWorking();
  }

  return (
    isOpen && (
      <AssociateArtifactsModal
        action={action}
        associated={associatedRecords}
        handleToggleClick={handleToggleClick}
        isActing={isActing}
        isPending={isPending}
        message={message}
        onClose={handleClose}
        open={isOpen}
        options={allOptions}
        path={pathRoot}
        refresh={setQuery}
        searchedArtifactType={types.associated}
        selectedItem={selectedItem}
        title={`Add ${labels.associated.singular}`}
      />
    )
  );
}
