import React from 'react';

import ReactDOM from 'react-dom';

import ConfirmDialog from 'snap-ui/Dialog/ConfirmDialog';

import { MaterialProvider } from 'provider/Material';

import { StrictReactNode } from 'types/core';

export type RouterMessage = {
  title: string;
  children: StrictReactNode;
  submitText: string;
  secondaryText: string;
};

function ConfirmRoutingDialog(props: RouterMessage & { onClose(result: boolean): void }) {
  return (
    <MaterialProvider>
      <ConfirmDialog
        DialogProps={{
          open: true,
          onClose: () => props.onClose(true)
        }}
        ConfirmProps={{
          children: props.submitText,
          onClick: () => props.onClose(false)
        }}
        SecondaryProps={{
          children: props.secondaryText
        }}
        title={props.title}
      >
        {props.children}
      </ConfirmDialog>
    </MaterialProvider>
  );
}

function RouterPrompt(message: string, onClose: (navigate: boolean) => void): void {
  const container = document.createElement('div');
  document.body.appendChild(container);

  try {
    var dialogProps = JSON.parse(message) as RouterMessage;
  } catch (e) {
    dialogProps = {
      title: 'Are you sure',
      children: message,
      submitText: 'Yes',
      secondaryText: 'Cancel'
    };
  }

  function close(navigate: boolean) {
    onClose(navigate);
    unmount();
  }

  function unmount(): void {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
  }

  ReactDOM.render(<ConfirmRoutingDialog {...dialogProps} onClose={close} />, container);
}

export default RouterPrompt;
