import React from 'react';

import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import DateRangeSelector from '../../DateRangeSelector/DateRangeSelector';
import { useDashboardStats } from './DetectionDashboardContext';

type Props = {
  title: string;
};

const Container = styled('div')`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  .dashboard-title {
    margin-left: ${p => p.theme.spacing(2)};
  }

  @media (min-width: 780px) {
    flex-direction: row;
  }
`;

export default function DetectionDashboardHeader({ title }: Props) {
  const { DateRangeSelectorProps } = useDashboardStats();
  return (
    <Container className='DetectionDashboardHeader'>
      <Typography variant='h1' className='dashboard-title'>
        {title}
      </Typography>
      <DateRangeSelector {...DateRangeSelectorProps} />
    </Container>
  );
}
