import React from 'react';

import zod from 'zod';

import TextField from 'snap-ui/TextField';

import useQueryString from 'hooks/useQueryString';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';

import { getQueryParam } from 'utilities/SearchParam';

import { FilterConfig } from '../GlobalFilter.type';

type SearchKeys = {
  query: string;
};

export function Search(): JSX.Element {
  const { search, update } = useQueryString();
  const query = getQueryParam(search, 'query');

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    update({ query: e.target.value });
  }

  return <TextField onChange={handleChange} value={query} />;
}

function toQuery({ query }: SearchKeys) {
  if (!query) return;
  return {
    field: 'text',
    op: Ops.contains,
    value: query,
    case_sensitive: false,
    highlight: true
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('text'),
    op: zod.literal(Ops.contains),
    value: zod.string()
  })
  .transform(query => ({
    query: query.value as string
  }));

const SearchConfig: FilterConfig<SearchKeys> = {
  defaults: { default: () => ({ query: '' }) },
  component: false,
  supportedTopics: Object.values(ArtifactType),
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default SearchConfig;
