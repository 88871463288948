import React from 'react';

import { useHistory } from 'react-router-dom';

import Path from 'constants/paths';

import { useAuth } from 'provider';

export default function useConfigureMFARedirect() {
  const { user } = useAuth();
  const { replace } = useHistory();

  React.useEffect(() => {
    if (user.mfa && !user.mfa_configured) replace(Path.MultifactorConfiguration);
  }, [user, replace]);
}
