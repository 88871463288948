import { FeedLink } from 'module/Feed/Feed.type';
import { FilterTopic } from 'module/GlobalFilter/GlobalFilter.type';

import { ArtifactType, DefensivePosture, Visibility } from 'types/common';

import Path from './paths';

export const STATE_LABEL_COLOR: Record<Visibility, 'primary' | 'warning' | 'error'> = {
  [Visibility.Draft]: 'warning',
  [Visibility.Published]: 'primary',
  [Visibility.Deleted]: 'error'
};

export const FEED_TYPES: Partial<Record<ArtifactType, FeedLink>> = {
  [ArtifactType.Session]: {
    text: 'Threat',
    link: Path.Threat
  },
  [ArtifactType.Analytic]: {
    text: 'Detection',
    link: Path.Detection
  },
  [ArtifactType.Intel]: {
    text: 'Intelligence',
    link: Path.Intelligence
  },
  [ArtifactType.AttackScript]: {
    text: 'Attack Script',
    link: Path.AttackScript
  },
  [ArtifactType.Collection]: {
    text: 'Collection',
    link: Path.Collection
  }
};

export type FilterType =
  | ArtifactType.Session
  | ArtifactType.Analytic
  | ArtifactType.Intel
  | ArtifactType.AttackScript
  | ArtifactType.Collection
  | ArtifactType.Marker
  | ArtifactType.AttackTag;

// Ordering matters on DefensivePosture records.
export const DEFENSIVE_POSTURES: Partial<Record<FilterTopic, Partial<Record<DefensivePosture, string>>>> = {
  [ArtifactType.Session]: {
    validatedDetections: 'validated',
    detectionGaps: 'gap',
    undetectedThreats: 'undetected',
    unvalidatedDetections: 'unvalidated'
  },
  [ArtifactType.Analytic]: {
    validatedDetections: 'validated',
    detectionGaps: 'gap',
    unvalidatedDetections: 'unvalidated',
    untestedDetections: 'untested'
  },
  [ArtifactType.Marker]: {
    validatedDetections: 'validated',
    detectionGaps: 'gap',
    unvalidatedDetections: 'unvalidated'
  },
  [ArtifactType.Intel]: {},
  '*': {
    validatedDetections: 'validated',
    detectionGaps: 'gap',
    undetectedThreats: 'undetected',
    unvalidatedDetections: 'unvalidated',
    untestedDetections: 'untested'
  }
};
