import React, { ReactElement } from 'react';

import classnames from 'classnames';

import { styled } from 'snap-ui/util';

const WidthContainerRoot = styled('div')`
  &.pp-center-vert {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }
`;

type ContainerProps = {
  children: ReactElement | ReactElement[];
  width?: number | string;
  maxWidth?: number | string;
  className?: string;
};

/**
 * @deprecated
 */
const WidthContainer = ({ children, width, maxWidth, className, ...otherProps }: ContainerProps): ReactElement => {
  return (
    <WidthContainerRoot
      className={classnames('pp-width-container', className)}
      style={{ width: width, maxWidth: maxWidth }}
      {...otherProps}
    >
      {children}
    </WidthContainerRoot>
  );
};

export default WidthContainer;
