import React from 'react';

import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import classnames from 'classnames';

import Icon from 'snap-ui/Icon';

import { LandingArtifact } from 'module/Landing/Landing.type';
import { getTagWeightLabel, TagJustification } from 'module/ThreatProfile';
import { ThreatPriorityBadge } from 'module/ThreatProfile/ThreatProfile.style';
import { TagScoreComponent, TagWeight, ThreatProfileTag } from 'module/ThreatProfile/ThreatProfile.type';

const MITRE_IGNORED_TAG: Pick<ThreatProfileTag, 'components' | 'score_label'> = {
  components: {
    mitre: {
      description:
        'This threat does not have enough information to be scored, and has been left out of your threat profile.',
      score: 1,
      weight: 1,
      weighted_score: 1
    }
  },
  score_label: TagWeight.Ignored
} as const;

const OVERRIDDEN_COMPONENT: TagScoreComponent = {
  description: 'Your organization has manually set the priority of this threat.',
  score: 1,
  weight: 1,
  weighted_score: 1
};

export default function ThreatPriority(props: {
  tag: ThreatProfileTag;
  external_source: LandingArtifact['external_source'];
}) {
  let tag = props.tag;
  if (tag?.override) {
    tag = {
      ...tag,
      components: {
        override: OVERRIDDEN_COMPONENT,
        ...tag.components
      }
    };
  }

  const isMitre = props.external_source === 'mitre';
  if (!tag && isMitre) {
    tag = MITRE_IGNORED_TAG as ThreatProfileTag;
  }

  const score = tag?.score_label ?? TagWeight.Ignored;

  return (
    <div className='ThreatPriority'>
      <TagJustification components={tag?.components} scoreLabel={score}>
        <ThreatPriorityBadge
          className={classnames('ThreatPriorityBadge', score)}
          icon={<Icon icon={faTriangleExclamation} />}
          value={getTagWeightLabel(score, false)}
        />
      </TagJustification>
    </div>
  );
}
