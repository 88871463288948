import React from 'react';

import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import {
  faBold,
  faCode,
  faH1,
  faImage,
  faItalic,
  faLink,
  faListCheck,
  faListOl,
  faListUl,
  faQuotes
} from '@fortawesome/pro-solid-svg-icons';
import '@github/markdown-toolbar-element';
import classNames from 'classnames';

import { ActionIconButton } from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import Tabs from 'snap-ui/Tabs';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import MarkdownRead from './MarkdownRead';

type CustomElement<T> = Partial<T & React.DOMAttributes<T> & { children: any }>;

// https://reactjs.org/docs/web-components.html
// https://coryrylan.com/blog/how-to-use-web-components-with-typescript-and-react
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      ['markdown-toolbar']: CustomElement<any>;
      ['md-bold']: CustomElement<any>;
      ['md-header']: CustomElement<any>;
      ['md-italic']: CustomElement<any>;
      ['md-quote']: CustomElement<any>;
      ['md-code']: CustomElement<any>;
      ['md-link']: CustomElement<any>;
      ['md-image']: CustomElement<any>;
      ['md-unordered-list']: CustomElement<any>;
      ['md-ordered-list']: CustomElement<any>;
      ['md-task-list']: CustomElement<any>;
      ['md-mention']: CustomElement<any>;
    }
  }
}

const Container = styled('div', { label: 'MarkdownEdit' })`
  .MuiTabs-flexContainer > button {
    background-color: ${p => p.theme.palette.secondary.main};
    color: ${p => p.theme.palette.common.white};
    border: none;
  }

  .MuiTabs-flexContainer > button.Mui-selected {
    background-image: unset;
  }

  .error {
    border: 1px solid ${p => p.theme.palette.error.main};
  }

  .MuiTabs-root {
    background-color: ${p => p.theme.palette.secondary.main};
    border: 1px solid ${p => p.theme.palette.grey[600]};
  }

  .Tab-auxiliary {
    flex-grow: 1;
    align-self: center;
    display: none;
    justify-content: flex-end;
    margin-right: ${p => p.theme.spacing(3)};
    margin-left: ${p => p.theme.spacing(3)};

    @media (min-width: 575px) {
      display: flex;
    }
  }

  markdown-toolbar {
    font-size: smaller;
    display: flex;
    gap: ${p => p.theme.spacing(4)};

    svg:hover {
      cursor: pointer;
      color: ${p => p.theme.palette.primary.main};
    }

    button {
      border: none;
      background-color: unset;
      padding: unset;
    }
  }

  textarea {
    padding: ${p => p.theme.spacing(4)};
    width: 100%;
    height: 300px;
    min-height: 300px;
    font-size: smaller;
    font-family: monospace;

    background-color: ${p => p.theme.palette.background.default};
    color: ${p => p.theme.palette.common.white};
    resize: vertical;
    vertical-align: top;
  }

  textarea:focus:not(.error) {
    border-color: ${p => p.theme.palette.primary.main};
  }

  textarea:focus.error {
    border-width: 2px;
  }

  textarea:focus-visible {
    outline: none;
  }

  textarea:hover:not(:focus):not(.disabled) {
    border-color: inherit;
  }

  textarea.disabled {
    color: ${p => p.theme.palette.text.disabled};
  }

  markdown-toolbar.disabled {
    color: ${p => p.theme.palette.grey[600]};

    svg:hover {
      cursor: unset;
      color: ${p => p.theme.palette.grey[600]};
    }
  }

  .markdown-preview {
    // Matching the textarea size
    border: 3px solid orange;
    height: 300px;
    padding: ${p => p.theme.spacing(4)};
    overflow: auto;
    resize: vertical;
    background-color: ${p => p.theme.palette.secondary.main};
    border: 1px solid ${p => p.theme.palette.grey[600]};
  }

  // Preview has the input background color (#3d3d3d) instead of the darker page background color (#171717),
  // so we need to display a different background color to show the zebra stripes.
  .markdown-preview tr:nth-of-type(even) td {
    background-color: ${p => p.theme.palette.secondary.dark} !important;
  }
`;

type Props = {
  id: string;
  value: string;
  onChange(value: string): void;
  className?: string;
  textareaClassName?: string;
  onBlur?(): void;
  disabled?: boolean;
};

const MarkdownToolbar = React.forwardRef<
  any,
  { id: string; disabled: boolean; onAddCustomMarkdownSyntax(value: string): void }
>(function MarkdownToolbar({ id, disabled, onAddCustomMarkdownSyntax }, ref) {
  return (
    <markdown-toolbar ref={ref} for={id} disabled={disabled} class={disabled && 'disabled'}>
      <Tooltip title={disabled ? '' : 'This field supports markdown'} wrap placement='top' arrow>
        <button
          type='button'
          data-md-button
          role='link'
          disabled={disabled}
          onClick={() => {
            window?.open('https://commonmark.org/help/', '_blank');
          }}
        >
          <Icon.Info color={disabled ? 'grey' : 'primary'} />
        </button>
      </Tooltip>
      <md-header>
        <Tooltip title={disabled ? '' : 'Heading'} wrap placement='top' arrow>
          <Icon icon={faH1} />
        </Tooltip>
      </md-header>
      <md-bold>
        <Tooltip title={disabled ? '' : 'Bold'} wrap placement='top' arrow>
          <Icon icon={faBold} />
        </Tooltip>
      </md-bold>
      <md-italic>
        <Tooltip title={disabled ? '' : 'Italic'} wrap placement='top' arrow>
          <Icon icon={faItalic} />
        </Tooltip>
      </md-italic>
      <md-quote>
        <Tooltip title={disabled ? '' : 'Quotes'} wrap placement='top' arrow>
          <Icon icon={faQuotes} />
        </Tooltip>
      </md-quote>
      <md-code>
        <Tooltip title={disabled ? '' : 'Code'} wrap placement='top' arrow>
          <Icon icon={faCode} />
        </Tooltip>
      </md-code>
      <md-link>
        <Tooltip title={disabled ? '' : 'Link'} wrap placement='top' arrow>
          <Icon icon={faLink} />
        </Tooltip>
      </md-link>
      <md-image>
        <Tooltip title={disabled ? '' : 'Image'} wrap placement='top' arrow>
          <Icon icon={faImage} />
        </Tooltip>
      </md-image>
      <Tooltip
        title={
          disabled ? (
            ''
          ) : (
            <>
              <Typography>Link syntax for YouTube videos</Typography>
              <code>https://youtube.com/embed/&lt;ID&gt;</code>
              <br />
              <code>https://youtube.com/watch?v=&lt;ID&gt;</code>
              <br />
              <code>https://youtu.be/&lt;ID&gt;</code>
            </>
          )
        }
        placement='top'
        arrow
      >
        <ActionIconButton
          disabled={disabled}
          aria-label='Add embedded YouTube markdown'
          icon={faYoutube}
          onClick={() => onAddCustomMarkdownSyntax('[](https://youtube.com/watch?v=VIDEO_ID)')}
        />
      </Tooltip>
      <md-unordered-list>
        <Tooltip title={disabled ? '' : 'Unordered List'} wrap placement='top' arrow>
          <Icon icon={faListUl} />
        </Tooltip>
      </md-unordered-list>
      <md-ordered-list>
        <Tooltip title={disabled ? '' : 'Ordered List'} wrap placement='top' arrow>
          <Icon icon={faListOl} />
        </Tooltip>
      </md-ordered-list>
      <md-task-list>
        <Tooltip title={disabled ? '' : 'Check List'} wrap placement='top' arrow>
          <Icon icon={faListCheck} />
        </Tooltip>
      </md-task-list>
    </markdown-toolbar>
  );
});

const MarkdownEdit = ({ id, className, textareaClassName, onChange, onBlur, value, disabled: isDisabled }: Props) => {
  const textareaRef = React.useRef<HTMLTextAreaElement>();
  const [disabled, setDisabled] = React.useState(false);

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.currentTarget.value);
  };

  const handleTabChange = (_event: React.SyntheticEvent, value: any) => {
    setDisabled(value === 'preview');
  };

  const handleAddCustomMarkdownSyntax = (syntax: string) => {
    if (textareaRef.current) {
      const selectionStart = textareaRef.current.selectionStart;
      const selectionEnd = textareaRef.current.selectionEnd;
      const newValue = value.substring(0, selectionStart) + syntax + value.substring(selectionEnd, value.length);
      onChange(newValue);
    }
  };
  return (
    <Container className={className}>
      <Tabs
        onChange={handleTabChange}
        className='markdownEditTabs'
        tabs={[
          {
            value: 'edit',
            label: 'Edit',
            content: (
              <textarea
                ref={textareaRef}
                aria-label='description with markdown enabled input'
                id={id}
                value={value}
                onChange={handleTextChange}
                onBlur={onBlur}
                placeholder='Markdown enabled input'
                className={classNames(textareaClassName, { disabled: isDisabled })}
                disabled={isDisabled}
                readOnly={isDisabled}
              />
            )
          },
          {
            value: 'preview',
            label: 'Preview',
            content: <MarkdownRead value={value} className='markdown-preview' />
          }
        ]}
        auxiliary={
          <MarkdownToolbar
            id={id}
            disabled={disabled || isDisabled}
            onAddCustomMarkdownSyntax={handleAddCustomMarkdownSyntax}
          />
        }
      />
    </Container>
  );
};

export default MarkdownEdit;
