import React from 'react';

import { ErrorProps } from 'module/ApiError';
import { getEndTime, getStartTime } from 'module/Hunt';
import {
  JobOverview,
  JobOverviewDetail,
  JobOverviewDetailItem,
  getJobOverviewResult,
  postClientHitsExport
} from 'module/Job';

import { usePushSnack } from 'provider';

import { Status, useAsync } from 'storage';

import { Guid } from 'types/common';

import { downloadFile } from 'utilities/FileUtils';

export type JobGroupDetailInterface = {
  jobGroup: JobOverview;
  jobs: JobOverviewDetail[];
  details: JobOverviewDetailItem[];
  hits: Record<string, number>;
  dispatchErrors: Record<Guid, JobOverviewDetailItem>;
  isPending: boolean;
  errorProps: ErrorProps;
  exportDataAsCsv: () => Promise<void>;
  isExportPending: boolean;
};

const AsyncJobGroupDetailContext = React.createContext<JobGroupDetailInterface>(null);
AsyncJobGroupDetailContext.displayName = 'AsyncJobGroupDetailContext';

function _useJobGroupDetail(groupGuid: Guid): JobGroupDetailInterface {
  const pushSnack = usePushSnack();
  const { data: jobs, run, status, errorProps } = useAsync<JobOverviewDetail[]>([]);
  const { task: exportTask, status: exportStatus, errorProps: exportErrorProps } = useAsync<string>();

  React.useEffect(() => {
    if (groupGuid) run(getJobOverviewResult(groupGuid));
  }, [run, groupGuid]);

  const jobGroup = React.useMemo(() => jobs.find(job => job.analytic_job_group_guid === groupGuid), [groupGuid, jobs]);

  React.useEffect(() => {
    if (exportErrorProps) pushSnack(exportErrorProps.title, 'error');
  }, [exportErrorProps, pushSnack]);

  const details: JobOverviewDetailItem[] = React.useMemo(() => {
    const detailCountsByGuid = jobs?.reduce((counts, job) => {
      job.item_detail?.forEach(detail => {
        counts[detail.guid] = (counts[detail.guid] || 0) + 1;
      });
      return counts;
    }, {});

    return jobs?.flatMap(job =>
      job.item_detail?.map(detail => ({
        ...detail,
        integration_name: job.integration_name,
        integration_guid: job.integration_guid,
        detailGuidForGrouping: detailCountsByGuid[detail.guid] > 1 ? [detail.guid] : null
      }))
    );
  }, [jobs]);

  const dispatchErrors = React.useMemo(() => {
    const errors = {};
    for (const integrationResult of jobs) {
      for (const detailResult of integrationResult.item_detail) {
        if (detailResult.item_error) {
          errors[detailResult.guid] = errors[detailResult.guid] ?? {};
          errors[detailResult.guid][integrationResult.integration_name] = detailResult.item_error;
        }
      }
    }
    return errors;
  }, [jobs]);

  const hits = React.useMemo(() => {
    const aggregateHits = {};
    for (const integrationResult of jobs) {
      for (const detailResult of integrationResult.item_detail) {
        aggregateHits[detailResult.guid] = aggregateHits[detailResult.guid] ?? 0;
        aggregateHits[detailResult.guid] += detailResult.hit_count || 0;
      }
    }
    return aggregateHits;
  }, [jobs]);

  const exportDataAsCsv = React.useCallback(() => {
    return exportTask(
      postClientHitsExport(groupGuid, { start: getStartTime(jobGroup), end: getEndTime(jobGroup) })
    ).then(result => downloadFile(new Blob([result]), `${jobGroup?.name || 'hunt_results'}.csv`));
  }, [exportTask, groupGuid, jobGroup]);

  return {
    jobGroup,
    jobs,
    details,
    exportDataAsCsv,
    isExportPending: exportStatus === Status.pending,
    dispatchErrors,
    hits,
    errorProps: errorProps,
    isPending: status === Status.pending
  };
}

export function JobGroupDetailProvider({ children, guid }: { children: React.ReactChild; guid: Guid }): JSX.Element {
  const jgd = _useJobGroupDetail(guid);
  return <AsyncJobGroupDetailContext.Provider value={jgd}>{children}</AsyncJobGroupDetailContext.Provider>;
}

export default function useJobGroupDetail(): JobGroupDetailInterface {
  const context = React.useContext(AsyncJobGroupDetailContext);
  if (!context) throw new Error('useJobGroupDetail must be used inside AsyncJobGroupDetailContext');
  return context;
}
