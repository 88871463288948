import React from 'react';

import { formatShortTimestamp } from 'utilities/TimeUtils';

import { ColumnComponentProps } from './common';

export default function DateColumn(props: ColumnComponentProps): JSX.Element {
  const dateToDisplay = props.job.end_time || props.job.start_time || props.job.creation;

  return <>{formatShortTimestamp(dateToDisplay)}</>;
}
