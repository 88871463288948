import React from 'react';

import { Route, Switch } from 'react-router-dom';

import Path from 'constants/paths';

import { SyntaxCheckerProvider } from 'module/SyntaxChecker';

import Analytic from './Analytic';
import { AnalyticRouterProps } from './Analytic.type';
import { AnalyticDiff } from './AnalyticDiff';
import { AnalyticLanguagesProvider } from './AnalyticLanguages';
import { AnalyticTuning, AnalyticTuningRaw } from './AnalyticTuning';
import AnalyticFineTuning from './AnalyticTuning/AnalyticFineTuning';
import { TuningProvider } from './AnalyticTuning/AnalyticTuningProvider';
import AnalyticVersion from './AnalyticVersion';
import { AnalyticProvider } from './core/AnalyticProvider';
import { AnalyticVersionProvider } from './core/AnalyticVersionProvider';
import { EditorStateProvider } from './core/EditorStateProvider';

export default function AnalyticRouter(props: AnalyticRouterProps): JSX.Element {
  const analyticGuid = props.match.params.analyticGuid;
  return (
    <SyntaxCheckerProvider>
      <AnalyticVersionProvider analyticGuid={analyticGuid}>
        <AnalyticProvider analyticGuid={analyticGuid}>
          <AnalyticLanguagesProvider guid={analyticGuid}>
            <EditorStateProvider>
              <TuningProvider>
                <Switch>
                  <Route
                    path={`${Path.Detection}/:analyticGuid/version/:versionId/diff/:otherId?`}
                    component={AnalyticDiff}
                  />
                  <Route path={`${Path.Detection}/:analyticGuid/version/:versionId`} component={AnalyticVersion} />
                  <Route path={`${Path.Detection}/:analyticGuid/tuning`} component={AnalyticTuning} />
                  <Route path={`${Path.Detection}/:analyticGuid/fine_tuning`} component={AnalyticFineTuning} />
                  <Route path={`${Path.Detection}/:analyticGuid/tuning_raw`} component={AnalyticTuningRaw} />
                  <Route component={Analytic} />
                </Switch>
              </TuningProvider>
            </EditorStateProvider>
          </AnalyticLanguagesProvider>
        </AnalyticProvider>
      </AnalyticVersionProvider>
    </SyntaxCheckerProvider>
  );
}
