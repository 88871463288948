import React from 'react';

import classNames from 'classnames';
import classnames from 'classnames';

import Icon from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

import { StrictReactNode } from 'types/core';

export const Counter = styled('span')`
  margin-top: 6px;
  border-right: 1px solid ${p => p.theme.palette.surface.fade};

  display: inline-grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  column-gap: 6px;
  padding-right: 6px;

  span {
    text-align: right;
  }

  .unavailable {
    color: ${p => p.theme.palette.secondary.dark};
  }

  strong.active {
    color: ${p => p.theme.palette.secondary.dark};
    animation: pending 2s linear infinite;
  }

  @keyframes pending {
    50% {
      opacity: 0;
    }
  }
`;

type Props = {
  className?: string;
  intelCount: number;
  intelAvailability: boolean;
  intelActivity: boolean;
  threatCount: number;
  threatAvailability: boolean;
  threatActivity: boolean;
  detectionCount: number;
  detectionActivity: boolean;
  detectionAvailability: boolean;
};

export default function CurationCount(props: Props) {
  return (
    <Counter className={classNames(props.className, 'CurationCount')}>
      <Icon.Intel />
      <span className={classnames({ unavailable: !props.intelAvailability })}>Intelligence:</span>
      <strong
        className={classnames({
          unavailable: !props.intelAvailability,
          active: props.intelAvailability && props.intelActivity
        })}
      >
        {props.intelCount}
      </strong>
      <Icon.Session />
      <span className={classnames({ unavailable: !props.threatAvailability })}>Threats:</span>
      <strong
        className={classnames({
          unavailable: !props.threatAvailability,
          active: props.threatAvailability && props.threatActivity
        })}
      >
        {props.threatCount}
      </strong>
      <Icon.Analytic />
      <span className={classnames({ unavailable: !props.detectionAvailability })}>Detections:</span>
      <strong
        className={classnames({
          unavailable: !props.detectionAvailability,
          active: props.detectionAvailability && props.detectionActivity
        })}
      >
        {props.detectionCount}
      </strong>
    </Counter>
  );
}

type PendingCountProps = {
  unavailable?: boolean;
  active?: boolean;
  children: StrictReactNode;
  className?: string;
};

const PendingCountContainer = styled('span')`
  padding: ${p => p.theme.spacing(0, 2)};

  &.unavailable {
    color: ${p => p.theme.palette.secondary.dark};
  }

  &.active {
    color: ${p => p.theme.palette.secondary.light};
    animation: pending 2s linear infinite;
  }

  @keyframes pending {
    50% {
      opacity: 0;
    }
  }
`;

export function PendingCount({ unavailable, active, className, children }: PendingCountProps) {
  return (
    <PendingCountContainer
      className={classnames('PendingCount', className, {
        unavailable,
        active
      })}
    >
      {children}
    </PendingCountContainer>
  );
}
