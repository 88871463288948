import pick from 'lodash/pick';

import { IntelReferencesShell } from './Intel.const';
import { Intel, IntelRouterState } from './Intel.type';

/**
 * @deprecated - Cards send { item, supplementalItem, count } but here with two different fields. Intel is also missing key fields as a result of that shell.
 */
export function decomposeIntel(intel: Intel): IntelRouterState {
  //const artifact = pick(intel, Object.keys(ArtifactShell) as Array<keyof typeof ArtifactShell>);
  const references = pick(intel, Object.keys(IntelReferencesShell) as Array<keyof typeof IntelReferencesShell>);

  return {
    item: intel,
    references,
    permission: { permission: intel.permission }
  };
}
