import React from 'react';

import { CancelToken, CancelTokenSourceType } from 'apis/snapattack';

import { FEED_SKELETON } from 'module/Feed/Feed.const';
import { getArtifactCounts, getSearchPage } from 'module/Feed/Feed.service';
import { Feed, FeedAsync, FeedCounts } from 'module/Feed/Feed.type';
import { useFilterRegistry } from 'module/GlobalFilter';
import { useMayI } from 'module/May';

import { useUserConfig } from 'provider';

import { Status, useAsync } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { ArtifactType, PageParams } from 'types/common';
import { Query } from 'types/filter';

export function useFeedAsync(
  topic: ArtifactType,
  query: Query,
  pageParams: PageParams
): FeedAsync & { fetchFeed(cancelSource: CancelTokenSourceType): void } {
  const { sortOrder } = useUserConfig();
  const { data, run, status, error, reset } = useAsync<Feed>(FEED_SKELETON);
  const isBasUser = useMayI(FunctionalPermission.BASStableFeatures);

  const stringifiedQuery = JSON.stringify(query);
  const fetchFeed = React.useCallback(
    (cancelSource: CancelTokenSourceType) => {
      if (query) {
        if (ArtifactType.AttackScript === topic && !isBasUser) {
          run(Promise.resolve(FEED_SKELETON));
          return;
        }
        run(getSearchPage(topic, pageParams.page, pageParams.size, query, sortOrder, cancelSource));
      }
    },
    [isBasUser, pageParams.page, pageParams.size, stringifiedQuery, run, sortOrder, topic] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    ...data,
    fetchFeed,
    size: pageParams.size,
    error,
    status,
    reset
  };
}

export default function useFeed(topic: ArtifactType, query: Query, pageParams: PageParams): FeedAsync {
  const { fetchFeed, ...feed } = useFeedAsync(topic, query, pageParams);

  React.useEffect(() => {
    const cancelSource = CancelToken.source();
    fetchFeed(cancelSource);
    return () => {
      cancelSource.cancel('User made new selection');
    };
  }, [fetchFeed]);

  return feed;
}

/**
 * @param filter - Our url filter params
 */
export function useFeedCounts(): FeedCounts {
  const { toQuery } = useFilterRegistry();
  const isBasUser = useMayI(FunctionalPermission.BASStableFeatures);

  const { run: intelRun, ...intel } = useAsync<Feed>(FEED_SKELETON);
  const intelQuery = toQuery(ArtifactType.Intel);
  const { run: sessionRun, ...session } = useAsync<Feed>(FEED_SKELETON);
  const sessionQuery = toQuery(ArtifactType.Session);
  const { run: analyticRun, ...analytic } = useAsync<Feed>(FEED_SKELETON);
  const analyticQuery = toQuery(ArtifactType.Analytic);
  const { run: validationRun, ...validation } = useAsync<Feed>(FEED_SKELETON);
  const validationQuery = toQuery(ArtifactType.AttackScript);
  const { run: collectionRun, ...collection } = useAsync<Feed>(FEED_SKELETON);
  const collectionQuery = toQuery(ArtifactType.Collection);

  const [fetched, setFetched] = React.useState<boolean>(false);

  const _intelQuery = JSON.stringify(intelQuery);
  React.useEffect(() => {
    const cancelSource = CancelToken.source();
    if (intelQuery) {
      intelRun(getArtifactCounts(intelQuery, ArtifactType.Intel, cancelSource));
    }
    return () => cancelSource.cancel();
  }, [intelRun, _intelQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  const _sessionQuery = JSON.stringify(sessionQuery);
  React.useEffect(() => {
    const cancelSource = CancelToken.source();
    if (sessionQuery) {
      sessionRun(getArtifactCounts(sessionQuery, ArtifactType.Session, cancelSource));
    }
    return () => cancelSource.cancel();
  }, [sessionRun, _sessionQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  const _analyticQuery = JSON.stringify(analyticQuery);
  React.useEffect(() => {
    const cancelSource = CancelToken.source();
    if (analyticQuery) {
      analyticRun(getArtifactCounts(analyticQuery, ArtifactType.Analytic, cancelSource));
    }
    return () => cancelSource.cancel();
  }, [analyticRun, _analyticQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  const _collectionQuery = JSON.stringify(collectionQuery);
  React.useEffect(() => {
    const cancelSource = CancelToken.source();
    if (collectionQuery) {
      collectionRun(getArtifactCounts(collectionQuery, ArtifactType.Collection, cancelSource));
    }
    return () => cancelSource.cancel();
  }, [collectionRun, _collectionQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  const _validationQuery = JSON.stringify(validationQuery);
  React.useEffect(() => {
    const cancelSource = CancelToken.source();
    if (isBasUser && validationQuery) {
      validationRun(getArtifactCounts(validationQuery, ArtifactType.AttackScript, cancelSource));
    }
    return () => cancelSource.cancel();
  }, [validationRun, _validationQuery, isBasUser]); // eslint-disable-line react-hooks/exhaustive-deps

  // the intent is for this to tell us when all have been fetched once
  // we do not want it to revert back to false on subsequent calls
  React.useEffect(() => {
    if (
      [intel.status, session.status, analytic.status, validation.status, collection.status].every(
        status => status === Status.resolved
      )
    ) {
      setFetched(true);
    }
  }, [intel.status, session.status, analytic.status, validation.status, collection.status]);

  return {
    [ArtifactType.Intel]: { ...intel.data, status: intel.status, error: intel.error, reset: intel.reset },
    [ArtifactType.Session]: { ...session.data, status: session.status, error: session.error, reset: session.reset },
    [ArtifactType.Analytic]: {
      ...analytic.data,
      status: analytic.status,
      error: analytic.error,
      reset: analytic.reset
    },
    [ArtifactType.AttackScript]: {
      ...validation.data,
      status: validation.status,
      error: validation.error,
      reset: validation.reset
    },
    [ArtifactType.Collection]: {
      ...collection.data,
      status: collection.status,
      error: collection.error,
      reset: collection.reset
    },
    fetched
  };
}
