import React from 'react';

import { TableSortLabel, TableSortLabelProps } from '@mui/material';

import { Order, SortDirection } from 'types/filter';

import { caseInsensitiveStringEqual } from 'utilities/StringUtils';

type Props = TableSortLabelProps & {
  label: string;
  field: string;
  sortHandler: (event: React.MouseEvent<unknown>) => void;
  order: Order;
  className?: string;
};

export default function SATableSortLabel({ order, label, field, sortHandler, ...props }: Props): JSX.Element {
  const sortingBy = caseInsensitiveStringEqual(order.field, field);

  return (
    <TableSortLabel
      active={sortingBy}
      direction={sortingBy ? order.direction : SortDirection.asc}
      onClick={sortHandler}
      aria-label={order.direction === SortDirection.asc ? 'sorted ascending' : 'sorted descending'}
      {...props}
    >
      {label}
    </TableSortLabel>
  );
}
