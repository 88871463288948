import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { publish as publishAnalytic, save as saveAnalytic } from 'apis/resources/analytic';

import useSnackOnError from 'hooks/useSnackOnError';

import { Status, useAsync } from 'storage';

import { Visibility } from 'types/common';

import { AnalyticVersion } from '../Analytic.type';
import { buildAnalyticPayload } from '../Analytic.util';
import { useAnalyticCatalog } from '../core/AnalyticProvider';
import { useAnalyticVersionCatalog } from '../core/AnalyticVersionProvider';
import useIDEStateFromVersion from './useIDEStateFromVersion';

type RevertAnalyticBag = {
  revert(): Promise<void>;
  status: Status;
};

export default function useRevert(version: AnalyticVersion): RevertAnalyticBag {
  const { error, setError, status, task } = useAsync();
  const [{ analytic, updateAnalytic }] = useAnalyticCatalog();
  const { refresh: refreshVersions } = useAnalyticVersionCatalog();
  const ideState = useIDEStateFromVersion(version);
  useSnackOnError(setError, error);

  const revert = React.useCallback(() => {
    if (isEmpty(version)) return Promise.resolve(null);
    const payload = buildAnalyticPayload(ideState, version?.created_by?.name);
    return task(
      saveAnalytic(analytic.guid, payload)
        .then(newAnalytic => {
          if (analytic.visibility === Visibility.Published) {
            // regenerate markers after the revert
            // the PUT to publish is what generates markers
            return publishAnalytic(analytic.guid).then(() => newAnalytic);
          }
          return newAnalytic;
        })
        .then(newAnalytic => {
          updateAnalytic(newAnalytic);
          refreshVersions();
        })
    );
  }, [analytic.guid, analytic.visibility, ideState, refreshVersions, task, updateAnalytic, version]);

  return {
    revert,
    status
  };
}
