import { TagWeight } from 'module/ThreatProfile';

export const EXPLANATIONS = {
  COVERAGE:
    'The coverage score is calculated by averaging the breadth and depth metrics, reflecting the percentage of all techniques covered by your defenses. This score matters because it provides a holistic view of your security coverage, indicating how effective your protection is against various attack vectors.',
  BREADTH:
    'Breadth measures the number of unique techniques covered by your security tools. It indicates how widely your defenses span across different stages of an attack. Calculated by the percentage of techniques with at least one detection or mitigation in place. High breadth means broader coverage against varied attack methods.',
  DEPTH:
    'Depth evaluates the number of detections deployed for techniques across the total recommended. It reflects how thoroughly each technique is defended from attack variations. Calculated as the percentage of recommended detections in place, averaged across all techniques. Greater depth ensures comprehensive protection against specific attack vectors.'
};

/**
 * make Highest the highest index since 'not present' is -1
 */
export const ORDERED_PRIORITY = Object.values(TagWeight).reverse();

export const PRIORITY_FILTER_OPTIONS = ORDERED_PRIORITY.map((priority, idx) => ({
  content: priority,
  label: priority,
  value: idx.toString()
}))
  .filter(option => option.label !== TagWeight.Ignored)
  .reverse();

export const ATTACK_RECORD_TYPE_FILTER_OPTIONS = [
  { value: 'tactics', content: 'Tactics', label: 'Tactics' },
  { value: 'techniques', content: 'Techniques', label: 'Techniques' },
  { value: 'subtechniques', content: 'Subtechniques', label: 'Subtechniques' }
];
