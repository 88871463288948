import React from 'react';

import classnames from 'classnames';

import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

const Container = styled('div')`
  &.hidden {
    display: none;
  }

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${p => p.theme.spacing(1)};
  .versionIcon {
    padding: ${p => p.theme.spacing(2)};
  }

  .versionAvailable {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: ${p => p.theme.spacing(2)};
    padding: 0;
  }

  .complexityTime {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: ${p => p.theme.spacing(2)};
  }
`;

type AnalyticFooterItemProps = {
  label: string;
  content: JSX.Element;
  actionTooltip?: string | JSX.Element;
  tooltip?: string | JSX.Element;
  action?: JSX.Element;
};

function AnalyticFooterItem({ content, label, action, actionTooltip, tooltip }: AnalyticFooterItemProps) {
  const contentRef = React.useRef<HTMLSpanElement>();
  const actionRef = React.useRef<HTMLSpanElement>();

  // hide the whole thing to avoid showing a label if the inner content doesn't render anything
  const hidden = !contentRef.current?.innerHTML || (action && !actionRef.current?.innerHTML);

  return (
    <Container className={classnames({ hidden })}>
      {action && (
        <Tooltip arrow placement='top' title={actionTooltip} enterDelay={300} enterNextDelay={300}>
          <span ref={actionRef}>{action}</span>
        </Tooltip>
      )}

      <div>
        <Typography variant='h6'>{label}</Typography>
        <Tooltip arrow placement='top' title={tooltip} enterDelay={300} enterNextDelay={300}>
          <span ref={contentRef}>{content}</span>
        </Tooltip>
      </div>
    </Container>
  );
}

export default AnalyticFooterItem;
