import React from 'react';

import BackdropLoader from 'snap-ui/BackdropLoader';
import Button from 'snap-ui/Button';
import { ConfirmDialog } from 'snap-ui/Dialog';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { FilterLayout, FilterValues, useSettingsRegistry } from 'module/GlobalFilter';

import { renderValueWithLabel } from 'utilities/TextUtils';

import PreferenceMessage from './PreferenceMessage';

const Container = styled('div')`
  .defaultFilterButton {
    width: 400px;
  }
  .formControl {
    padding: 10px;
    padding-right: 30px;
  }
`;

function PreferenceFilterDialog({
  initialValues,
  isEditOrg,
  isOpen,
  isPending,
  onClose,
  onSave
}: {
  isOpen?: boolean;
  initialValues?: FilterValues;
  isEditOrg?: boolean;
  isPending: boolean;
  onClose?: () => void;
  onSave(values?: FilterValues): void;
}) {
  const { render, values, appliedCount, reset, trimValues } = useSettingsRegistry(initialValues);

  function handleClose() {
    onClose();
  }

  function handleSave(values?: FilterValues) {
    onSave(trimValues(values));
  }

  return (
    <Container>
      <ConfirmDialog
        DialogProps={{
          disablePortal: true,
          open: isOpen,
          onClose: handleClose
        }}
        SecondaryProps={{
          children: 'Cancel',
          onClick: handleClose
        }}
        ConfirmProps={{
          children: 'Save',
          onClick: () => handleSave(values)
        }}
        title={<PreferenceMessage editOrg={isEditOrg} onSave={() => onSave({})} onDelete={() => onSave(null)} />}
        disableOnClose={true}
      >
        <FilterLayout className='formControl'>
          <Tooltip title='Number of filter options' arrow placement='top'>
            <Button variant='outlined' id='FilterNamed' ariaLabel='Filter named' onClick={() => reset()}>
              {`${renderValueWithLabel(appliedCount, 'Filter')} Applied`}
            </Button>
          </Tooltip>
        </FilterLayout>
        <BackdropLoader title='Updating...' open={isPending} contained fixed />
        <FilterLayout className='formControl'>{values && render()}</FilterLayout>
      </ConfirmDialog>
    </Container>
  );
}

export default PreferenceFilterDialog;
