export function draftJSToMarkdownRawParser(value: string): string {
  if (value?.includes(`"blocks"`)) {
    const json = JSON.parse(value);
    return json.blocks.reduce(
      (previousValue: string, currentValue: any) => previousValue + currentValue.text + '\n\n',
      ''
    );
  }

  return value;
}
