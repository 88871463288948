import React from 'react';

import RawChip from 'snap-ui/Chip/RawChip';
import Icon from 'snap-ui/Icon';

import { SupplementalArtifact } from 'module/Search';
import ContentHeader from 'module/Widgets/ContentHeader';
import { HeroContainer } from 'module/Widgets/HeroImage';

import { Status } from 'storage';

import { AnalyticReferences } from 'types/analytic';
import { Organization, Permitted } from 'types/auth';
import { Artifact, ArtifactType } from 'types/common';

import { ContentFooter, Head } from '../Analytic.style';
import AnalyticConfidence from './AnalyticConfidence';
import AnalyticMenu from './AnalyticMenu';
import AnalyticSeverity from './AnalyticSeverity';
import FooterDetail from './FooterDetail';

type AnalyticHeroProps = {
  analytic: Artifact;
  analyticStatus: Status;
  permissions: Permitted;
  references: AnalyticReferences;
  supplemental: SupplementalArtifact;
  supplementalStatus: Status;
  preview: boolean;
  preferredOrg?: Partial<Organization>;
  onShowVersionList(): void;
};

export default function AnalyticHero({
  analytic,
  analyticStatus,
  permissions,
  references,
  supplemental,
  supplementalStatus,
  preview,
  onShowVersionList
}: AnalyticHeroProps): JSX.Element {
  const analyticPending = analyticStatus === Status.pending;
  const supplementalPending = supplementalStatus === Status.pending;

  return (
    <HeroContainer type={ArtifactType.Analytic}>
      <RawChip className='Artifact-type' icon={<Icon.Analytic />} label={ArtifactType.Analytic} color='primary' />
      <Head>
        <ContentHeader
          headerText={analytic.name}
          contentType={ArtifactType.Analytic}
          showPlaceholder={analyticPending}
        />
        <ContentFooter>
          <FooterDetail onShowVersionList={onShowVersionList} />
          {!preview && (
            <div className='actions'>
              <AnalyticSeverity analytic={analytic} supplemental={supplemental} />
              <AnalyticConfidence analytic={analytic} supplemental={supplemental} />
              <AnalyticMenu
                analytic={analytic}
                supplemental={supplemental}
                references={references}
                permissions={permissions}
                disabled={analyticPending || supplementalPending}
              />
            </div>
          )}
        </ContentFooter>
      </Head>
    </HeroContainer>
  );
}
