import { AnalyticForm } from 'types/analytic';

import Condition from '../Condition';
import HighlightDetection from '../DetectionResult';
import Property from '../Property';

const empty = {
  token: [],
  error: false,
  message: null
};

/**
 * For more information: @see https://github.com/Neo23x0/sigma/wiki/Specification
 * @param sigmaYaml string
 */
export default class Processor {
  private structure: AnalyticForm;
  private subject: HighlightDetection;

  static fromAnalyticForm(form: AnalyticForm): Processor {
    return new Processor().withForm(form);
  }

  static empty(): Processor {
    return new Processor();
  }

  private constructor() {
    this.subject = empty;
  }

  /**
   * In the next version, this will be multi-asynchronous
   * operation that returns `Promise<HighlightDetection>`
   */
  run(): HighlightDetection {
    if (!this.subject.error) {
      this.unpackCondition(); // async 1
      this.unpackProperty(); // async 2
    }

    return this.subject;
  }

  private withForm(form: AnalyticForm): Processor {
    this.structure = form;
    this.subject.error = !this.structure || !this.structure.detection;
    return this;
  }

  private unpackCondition(): void {
    this.subject = {
      ...this.subject,
      ...new Condition(this.structure).run().result
    };
  }

  private unpackProperty(): void {
    this.subject = {
      ...this.subject,
      token: new Property(this.structure).run().result
    };
  }
}
