import React from 'react';

import { Attack, AttackType } from 'module/Tag';
import { getAttackByType } from 'module/Tag/Tag.api';

import { Catalog, Scheme, useAsyncStorage } from 'storage';

export default function useTactics(): { tactics: Attack[]; mitreIDs: Attack['mitre_id'][] } {
  const { run, data: tactics } = useAsyncStorage<Attack[]>(Scheme.localStorage, Catalog[AttackType.Tactic], []);

  React.useEffect(() => {
    run(() => getAttackByType(AttackType.Tactic));
  }, [run]);

  const mitreIDs = React.useMemo(() => tactics.map(tactic => tactic.mitre_id), [tactics]);

  return { tactics, mitreIDs };
}
