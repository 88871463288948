import React from 'react';

import { faBan, faPlay, faVideo } from '@fortawesome/pro-solid-svg-icons';
import { useLocation } from 'react-router-dom';

import Button, { RouterButton } from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';

import Path from 'constants/paths';

import useMachine from 'module/Session/useMachine';

import { useCommissionedMachine } from 'provider';

import Banner from './Banner';

export default function SessionResumeBanner() {
  const { pathname } = useLocation();
  const { kill } = useMachine();
  const { activeSession } = useCommissionedMachine();
  const [isPending, setIsPending] = React.useState(false);

  if (pathname.includes(Path.ThreatExecute) || pathname.includes(Path.ThreatSave) || activeSession.length === 0)
    return null;

  return (
    <Banner>
      <Icon icon={faVideo} className='banner-icon' />
      You have an active threat capture that can be resumed here
      <RouterButton startIcon={<Icon icon={faPlay} />} to={Path.ThreatExecute} color='secondary'>
        Access VMs
      </RouterButton>
      <Button
        startIcon={isPending ? <Icon.SpinnerProgress /> : <Icon icon={faBan} />}
        color='secondary'
        variant='text'
        onClick={handleKill}
      >
        Kill VMs
      </Button>
    </Banner>
  );

  function handleKill() {
    setIsPending(true);
    kill();
  }
}
