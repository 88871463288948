import React from 'react';

import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname, state } = useLocation();

  React.useEffect(() => {
    if (get(state, 'noScroll')) return;
    window.scrollTo(0, 0);
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}

export default ScrollToTop;
