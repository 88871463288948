import React from 'react';

import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridColumnOrderChangeParams,
  GridRowSelectionModel
} from 'snap-ui/DataGrid';
import { styled } from 'snap-ui/util';

import { FILTER_PREFIX } from 'module/Metadata/Metadata.const';

import { useAuth, useFeedExtra, useUserCatalog, useUserConfig } from 'provider';

import { Artifact, ArtifactType, Guid } from 'types/common';
import { StrictReactNode } from 'types/core';

import { move } from 'utilities/ArrayUtils';

import { getInitiallyHiddenColumns } from './DisplayGrid.util';
import { useGridColumnsCollection } from './DisplayGridColumnsCollection';
import { getGridColumnsCommon } from './DisplayGridColumnsCommon';
import useGridColumnsDetection from './DisplayGridColumnsDetection';
import { GridColumnsIOC } from './DisplayGridColumnsIOC';
import { getGridColumnsIntel } from './DisplayGridColumnsIntel';
import { useGridColumnsMetadata } from './DisplayGridColumnsMetadata';
import { GridColumnsThreat } from './DisplayGridColumnsThreat';
import { GridColumnsValidation } from './DisplayGridColumnsValidation';

type Props = {
  gridApiRef: DataGridProps['apiRef'];
  children?: StrictReactNode;
  className?: string;
  items: Artifact[];
  isPending: boolean;
  hideCheckboxes?: boolean;
  selectionModel?: GridRowSelectionModel;
  onSelectionModelChange?(model: Guid[]): void;
  topic: ArtifactType;
};

const Container = styled('div')`
  .DisplayGrid-sizer {
    height: calc(100vh - 364px);
  }

  .MuiDataGrid-root {
    border: 0;
  }

  .MuiDataGrid-footerContainer {
    padding-right: ${p => p.theme.spacing(9)};

    p {
      margin-bottom: unset;
    }
  }

  .MuiDataGrid-columnSeparator {
    color: ${p => p.theme.palette.primary.main};
  }

  .DisplayGrid-columnType {
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: ${p => p.theme.spacing(3)};
  }

  .CardCounter {
    display: grid;
    grid-template-columns: repeat(5, 60px);
    white-space: nowrap;
  }
`;

export default function DisplayGrid(props: Props) {
  const { columnModel, setColumnModel, sortModel, setSortModel, columnOrderModel, setColumnOrderModel } =
    useUserConfig();

  const base = useArtifactColumns(props.topic);
  const metadataColumns = useGridColumnsMetadata(FILTER_PREFIX);
  const [columns, hidden] = React.useMemo(() => {
    const columns: GridColDef<Artifact>[] = [...base.columns, ...metadataColumns];
    const hidden = { ...base.hidden };
    metadataColumns.forEach(col => {
      hidden[col.field] = false;
    });
    return [columns, hidden];
  }, [base.columns, base.hidden, metadataColumns]);

  const columnOrder = React.useMemo(
    () => columnOrderModel?.[props.topic] || columns.map(c => c.field),
    [columnOrderModel, columns, props.topic]
  );

  const handleSetColumnVisibilityModel = (value: Record<string, boolean>) =>
    setColumnModel({ ...columnModel, [props.topic]: value });

  const handleSetColumnOrderModel = ({ targetIndex, oldIndex }: GridColumnOrderChangeParams) =>
    setColumnOrderModel({
      ...columnOrderModel,
      [props.topic]: move(columnOrder, oldIndex - 1, targetIndex - 1)
    });

  const sortedColumns = React.useMemo(
    () => columns.slice().sort((a, b) => columnOrder.indexOf(a.field) - columnOrder.indexOf(b.field)),
    [columnOrder, columns]
  );

  return (
    <Container className={classNames('DisplayGrid', props.className)}>
      <div className='DisplayGrid-sizer'>
        <DataGrid
          apiRef={props.gridApiRef}
          columnVisibilityModel={isEmpty(columnModel[props?.topic]) ? undefined : columnModel[props?.topic]}
          onColumnVisibilityModelChange={handleSetColumnVisibilityModel}
          onColumnOrderChange={handleSetColumnOrderModel}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          checkboxSelection={!props.hideCheckboxes}
          rowSelectionModel={props.selectionModel}
          onRowSelectionModelChange={props.onSelectionModelChange}
          loading={props.isPending}
          key={props.topic}
          columns={sortedColumns}
          rows={props.items}
          getRowId={artifact => artifact.guid}
          hideFooter
          hideFooterPagination
          disableColumnFilter
          rowBuffer={10}
          initialState={{
            columns: {
              columnVisibilityModel: hidden
            }
          }}
        />
      </div>
      {props.children}
    </Container>
  );
}

function useArtifactColumns(topic: ArtifactType) {
  const { supplementalCatalog, supplementalPending, getSupplemental } = useFeedExtra();
  const { defaultOrgId, permission: organizations } = useAuth();
  const { users } = useUserCatalog();
  const gridColumnsDetection = useGridColumnsDetection();
  const gridColumnsCollection = useGridColumnsCollection();
  const result = React.useMemo(() => {
    const c: GridColDef<Artifact>[] =
      topic === ArtifactType.Indicator
        ? GridColumnsIOC
        : [
            ...(topic === ArtifactType.Intel
              ? getGridColumnsIntel(supplementalPending, getSupplemental)
              : topic === ArtifactType.Session
              ? GridColumnsThreat
              : topic === ArtifactType.Analytic
              ? gridColumnsDetection
              : topic === ArtifactType.AttackScript
              ? GridColumnsValidation
              : topic === ArtifactType.Collection
              ? gridColumnsCollection
              : []),
            ...getGridColumnsCommon(users, organizations)
          ];

    const hidden = getInitiallyHiddenColumns(c);
    return {
      columns: c,
      hidden
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic, supplementalCatalog, defaultOrgId, supplementalPending]);

  return result;
}
