import React from 'react';

import startCase from 'lodash/startCase';

import { styled } from 'snap-ui/util';

import { StrictReactNode } from 'types/core';

import { attemptToFormatDate } from 'utilities/TimeUtils';

export const DL = styled('dl')`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${p => p.theme.spacing(2)};
  dt {
    font-weight: bold;
  }

  dd {
    margin: 0;
  }
`;

type ListFormat = 'inline' | 'block';

type KeyValueListProps = {
  data: Record<string, unknown>;
  listFormat?: ListFormat;
  titleCase?: boolean;
};

function renderValue(value: unknown, listFormat: ListFormat): StrictReactNode {
  return typeof value === 'string' ? (
    attemptToFormatDate(value)
  ) : typeof value === 'number' ? (
    value.toLocaleString()
  ) : typeof value === 'boolean' ? (
    value.toString()
  ) : Array.isArray(value) ? (
    listFormat === 'inline' ? (
      value.join(', ')
    ) : (
      <ul>value.map(renderValue)</ul>
    )
  ) : React.isValidElement(value) ? (
    value
  ) : typeof value === 'object' ? (
    <DL>
      {Object.entries(value ?? {}).map(([key, value]) => (
        <React.Fragment key={key}>
          <dt>{key}</dt>
          <dd>{value}</dd>
        </React.Fragment>
      ))}
    </DL>
  ) : null;
}

export default function KeyValueList({
  data,
  listFormat = 'inline',
  titleCase = false
}: KeyValueListProps): JSX.Element {
  return (
    <DL>
      {Object.entries(data ?? {}).map(([key, value]) => (
        <React.Fragment key={key}>
          <dt>{titleCase ? startCase(key) : key}</dt>
          <dd>{renderValue(value, listFormat)}</dd>
        </React.Fragment>
      ))}
    </DL>
  );
}
