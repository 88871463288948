import React from 'react';

import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';

import Icon from 'snap-ui/Icon';
import { BaseLink } from 'snap-ui/Link';
import {
  ListItemIcon,
  ListItemProps,
  ListItemText,
  ListItem as MuiListItem,
  ListItemButton as MuiListItemButton
} from 'snap-ui/List';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

type Props = {
  className?: string;
  compact?: boolean;
  href: string;
  id?: string;
  onClick?(): void;
  title: string;
  tooltip?: string;
};

const ListItem = styled(MuiListItem, { shouldForwardProp: p => p !== 'compact' })<ListItemProps & { compact: boolean }>`
  .MuiListItemButton-root:hover {
    color: ${p => p.theme.palette.common.white};
  }

  .MuiListItemIcon-root {
    ${p =>
      p.compact
        ? `
      min-width: unset;
      padding-right: 8px;
    `
        : ''}
  }
`;

export default function ItemLinkExternal({ className, compact, title, tooltip, ...others }: Props) {
  return (
    <Tooltip title={tooltip ? tooltip : ''} arrow placement='right'>
      <ListItem disableGutters className={className} compact={compact}>
        <MuiListItemButton
          className='ScaffoldItem ItemLinkExternal'
          component={BaseLink}
          target='_blank'
          rel='noopener noreferrer'
          href=''
          {...others}
        >
          <ListItemIcon>
            <Icon icon={faExternalLink} size='xs' />
          </ListItemIcon>
          <ListItemText>{title}</ListItemText>
        </MuiListItemButton>
      </ListItem>
    </Tooltip>
  );
}
