import concat from 'lodash/concat';
import flattenDeep from 'lodash/flattenDeep';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import split from 'lodash/split';
import uniqBy from 'lodash/uniqBy';

import { SEARCH_FIELDS } from 'constants/processGraph';

import { Detection } from 'module/Detection/Detection.type';
import { GraphParam } from 'module/ProcessGraph/useGraphParam';

import { ArtifactScore, ArtifactScoreDetail } from 'types/common';
import { BlueMarker, BlueMarkerExtended, Marker, MarkerExtended, RedMarker } from 'types/marker';
import { NodeDataType } from 'types/progressGraph';

import { isBlueMarker } from './filterService/filterService';

export function nodeMatchesSomeMarkers<M extends Marker>(node: NodeDataType, marker: M[]) {
  return node?.row_id && marker.some(m => m.event?.row_id === node.row_id);
}

export function getMarkers<M extends Marker>(nodeData: NodeDataType, marker: M[]): M[] {
  let result = [];

  if (nodeData?.row_id && marker.length > 0) {
    result = isBlueMarker(marker[0])
      ? filterBlueMarker(nodeData, marker as BlueMarker[])
      : filterRedMarker(nodeData, marker as RedMarker[]);
  }

  return result;
}

function filterRedMarker(nodeData: NodeDataType, marker: RedMarker[]) {
  return marker.filter(m => nodeData.row_id === `${m.event?.row_id}`);
}

function filterBlueMarker(nodeData: NodeDataType, marker: BlueMarker[]) {
  return marker.filter(m => nodeData.row_id === `${m.event.row_id}`);
}

export function findNode(nodeId, nodeSet, hits): NodeDataType[] {
  if (!nodeId) return [];
  hits = hits.concat(nodeSet.filter(node => get(node, '_id') === nodeId));

  nodeSet.forEach(node => {
    if (node && node.children && node.children.length > 0) {
      hits = findNode(nodeId, node.children, hits);
    }
  });

  return hits;
}

export function findNodeByRowId(rowIdString: string, nodeSet: NodeDataType[]): NodeDataType {
  const data = Array.isArray(nodeSet) ? nodeSet : [nodeSet];
  let foundNode = null;
  data.forEach(node => {
    if (node && node.row_id === rowIdString) {
      foundNode = node;
    }
    if (!foundNode && node && node._children && node._children.length > 0) {
      foundNode = findNodeByRowId(rowIdString, node._children);
    }
  });
  return foundNode;
}

export function isAChildUnfiltered(node: NodeDataType): boolean {
  let result = false;
  if (node._children) {
    node._children.forEach(child => {
      if (!child.filterCollapse || result) {
        result = true;
      } else {
        result = isAChildUnfiltered(child);
      }
    });
  }
  return result;
}

export function findNodesWithRowIds(rowIds: string[], nodeSet: NodeDataType | NodeDataType[]): NodeDataType[] {
  const data = Array.isArray(nodeSet) ? nodeSet : [nodeSet];
  let foundNodes = [];
  data.forEach(node => {
    if (node && includes(rowIds, node.row_id)) {
      foundNodes = concat(foundNodes, node);
    }
    if (node && node._children) {
      foundNodes = concat(foundNodes, findNodesWithRowIds(rowIds, node._children));
    }
  });
  return foundNodes;
}

export function getStartTime(data: NodeDataType): number {
  const startTimestamp = get(data, 'timestamp_min');
  return new Date(startTimestamp).getTime();
}

export function getEndTime(data: NodeDataType): number {
  const endTimestamp = get(data, 'timestamp_max');
  return new Date(endTimestamp).getTime();
}

export function getNodeTime(data: NodeDataType): number {
  if (data.UtcTime) {
    return new Date(split(data.UtcTime, '.')[0]).getTime();
  }

  return null;
}

export function isBlueMarkerLonely(eventIdentifier: string, detection: Detection) {
  return (
    detection.unvalidated.some(e => e.row_id === eventIdentifier) ||
    detection.validated_gaps.some(e => e.row_id === eventIdentifier)
  );
}

export function isRedMarkerLonely(id: number, detection: Detection) {
  return detection.undetected.some(e => e.id === id) || detection.detection_gaps.some(e => e.id === id);
}

/**
 * TODO {
 *  1) check on red/blue labels for correctness
 *  2) Add unit tests. This function was pulled out of ProcessGraph for isolated testing
 * }
 *
 * Known Issues - BE doesn't return events on red markers unless they are created through process graph add red marker which is currently disabled.
 *
 * @param param
 * @param node
 * @param marked
 * @returns { label: string; value: number | string }[]
 */
function buildSearchData(param: GraphParam, node: NodeDataType, marked: MarkerExtended[]) {
  const currentSearchdata: { label: string; value: number | string }[] = [];
  if (isEmpty(marked)) return currentSearchdata;

  if (param.searchField === SEARCH_FIELDS.ATTACK_NODE.value) {
    marked.forEach(marker => {
      if (!isBlueMarker(marker)) {
        const redStarNode = findNodeByRowId(`${marker.event?.row_id}`, [node]);

        if (redStarNode) {
          currentSearchdata.push({
            label: marker.description,
            value: marker.event?.row_id
          });
        }
      }
    });
  }

  if (param.searchField === SEARCH_FIELDS.ANALYTIC_HITS.value) {
    marked.forEach(marker => {
      if (isBlueMarker(marker)) {
        const foundNode = findNodeByRowId(`${marker.event.row_id}`, [node]);
        if (foundNode) {
          currentSearchdata.push({
            label: marker?.analytics?.length > 0 ? `${foundNode.process_name}` : `${marker.event.id}`,
            value: marker.event?.row_id
          });
        }
      }
    });
  }

  if (!node) return currentSearchdata;

  if (!isEmpty(node._children)) {
    node._children.forEach(child => {
      currentSearchdata.push(...buildSearchData(param, child, marked));
    });
  }

  currentSearchdata.push({
    label: node[param.searchField],
    value: node[param.searchField]
  });

  return currentSearchdata.sort((a, b) => a?.label?.localeCompare(b.label));
}

export function mapSearchData(param: GraphParam, node: NodeDataType, marked: MarkerExtended[]) {
  const build = buildSearchData(param, node, marked);
  const flat = flattenDeep(build);
  return uniqBy(flat, 'value');
}

/**
 * A blue marker can have more than one analytic which can have multiple
 * organizational ranks.
 *
 * Collect all the rank details then sort by natural ordering of ArtifactScore
 */
export function getRankTextFromBlueMarker(marker: BlueMarkerExtended) {
  const ordering = Object.keys(ArtifactScore);
  const rankingPool: ArtifactScoreDetail[] = [];
  marker.analytics?.forEach(a => rankingPool.push(...a.ranks));
  rankingPool.sort((a, b) => ordering.indexOf(a.rank) - ordering.indexOf(b.rank));
  return rankingPool;
}

/**
 * Sorts and returns all markers that match this node log
 * @param selectedNode
 * @param blue
 * @returns
 */
export function sortSelectedBlueMarker(selectedNode: NodeDataType, blue: BlueMarkerExtended[]) {
  const ordering = Object.keys(ArtifactScore);
  const found = getMarkers<BlueMarkerExtended>(selectedNode, blue);
  return orderBy(
    found,
    blueMarker => {
      return ordering.indexOf(get(blueMarker, ['analytics', 0, 'rank', 0], ArtifactScore.UNKNOWN));
    },
    'asc'
  );
}
