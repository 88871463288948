import React from 'react';

import Grid from 'snap-ui/Grid';
import Placeholder from 'snap-ui/Placeholder';

import DashboardGrid from 'module/Dashboard/core/DashboardGrid';

import { ViewedStats, HuntedStats, CreatedStats } from '../SocDashboard/SocDashboard.type';

export function ThreeColumnPlaceHolder() {
  return (
    <DashboardGrid>
      <Grid item xs={6} md={4} lg={4}>
        <Placeholder variant='rectangular' height={276} />
      </Grid>
      <Grid item xs={6} md={4} lg={4}>
        <Placeholder variant='rectangular' height={276} />
      </Grid>
      <Grid item xs={6} md={4} lg={4}>
        <Placeholder variant='rectangular' height={276} />
      </Grid>
    </DashboardGrid>
  );
}

export function FourColumnPlaceHolder() {
  return (
    <DashboardGrid>
      <Grid item xs={6} md={3} lg={3}>
        <Placeholder variant='rectangular' height={276} />
      </Grid>
      <Grid item xs={6} md={3} lg={3}>
        <Placeholder variant='rectangular' height={276} />
      </Grid>
      <Grid item xs={6} md={3} lg={3}>
        <Placeholder variant='rectangular' height={276} />
      </Grid>
      <Grid item xs={6} md={3} lg={3}>
        <Placeholder variant='rectangular' height={276} />
      </Grid>
    </DashboardGrid>
  );
}

export function getCreateTotals(data: CreatedStats) {
  return data.reduce(
    (totals, current) => {
      const createdIntelTotal = totals?.createdIntelTotal + current?.intel;
      const createdDeploymentTotal = totals?.createdDeploymentTotal + current?.deployments;
      const createdDetectionTotal = totals?.createdDetectionTotal + current?.detections;
      const createdSimulationsTotal = totals?.createdSimulationsTotal + current?.simulations;
      const createdSimulationsFailedTotal = totals?.createdSimulationsFailedTotal + current?.simulations_failed;
      const createdThreatsTotal = totals?.createdThreatsTotal + current?.threats;

      return {
        createdIntelTotal,
        createdDeploymentTotal,
        createdDetectionTotal,
        createdSimulationsTotal,
        createdSimulationsFailedTotal,
        createdThreatsTotal
      };
    },
    {
      createdIntelTotal: 0,
      createdDeploymentTotal: 0,
      createdDetectionTotal: 0,
      createdSimulationsTotal: 0,
      createdSimulationsFailedTotal: 0,
      createdThreatsTotal: 0
    }
  );
}

export function getViewedTotals(data: ViewedStats) {
  return data.reduce(
    (totals, current) => {
      const viewedIntelTotal = totals?.viewedIntelTotal + current?.intel;
      const viewedThreatsTotal = totals?.viewedThreatsTotal + current?.threats;
      const viewedDetectionTotal = totals?.viewedDetectionTotal + current?.detections;

      return {
        viewedIntelTotal,
        viewedDetectionTotal,
        viewedThreatsTotal
      };
    },
    {
      viewedIntelTotal: 0,
      viewedDetectionTotal: 0,
      viewedThreatsTotal: 0
    }
  );
}

export function getHuntTotals(data: HuntedStats) {
  return data.reduce(
    (totals, current) => {
      const huntsTotal = totals?.huntsTotal + current?.hunts;
      const huntsIocTotal = totals?.huntsIocTotal + current?.hunts_ioc;

      return {
        huntsTotal,
        huntsIocTotal
      };
    },
    {
      huntsTotal: 0,
      huntsIocTotal: 0
    }
  );
}

export const TOOLTIPS = {
  createdIntel:
    "The number of intelligence objects created in SnapAttack. This shows advanced organizational maturity by creating custom security content based on your organization's observed or prioritized threats.",
  createdDetections:
    "The number of detections created in SnapAttack. This shows advanced organizational maturity by creating custom security content based on your organization's observed or prioritized threats.",
  createdThreats:
    "The number of threats captured in SnapAttack. This shows advanced organizational maturity by creating custom security content based on your organization's observed or prioritized threats.",
  viewedIntel:
    'The number of intelligence objects viewed in SnapAttack. This shows preparedness by reviewing available content in the platform',
  viewedDetections:
    'The number of detections viewed in SnapAttack. This shows preparedness by reviewing available content in the platform',
  viewedThreats:
    'The number of threats viewed in SnapAttack. This shows preparedness by reviewing available content in the platform',
  iocHunts:
    "The number of IOC hunts executed from intelligence reports in SnapAttack. IOC Hunts provide the ability to quickly identify known malicious domain names, IP addresses, or file hashes observed in your organization's network.",
  simulationsRun:
    "The number of attack simulations run in your organization's network. Attack simulations are a way to validate controls and deployed detections from SnapAttack.",
  deployed:
    'The number of deployed detections from SnapAttack. Detections alert on known adversary tactics, techniques, and procedures (TTPs) and provide additional coverage against known and emerging threats.',
  threatHunts:
    'The number of detections used for threat hunts from SnapAttack. Threat hunting is a proactive way to identify malicious or suspicious activity in your organization without creating "alert fatigue" for your analysts.'
};
