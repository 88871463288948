import React from 'react';

import { useLocation } from 'react-router-dom';

import MuiAppBar, { AppBarProps } from 'snap-ui/AppBar';
import Toolbar from 'snap-ui/Toolbar';
import { styled, Theme } from 'snap-ui/util';

import useConfigureMFARedirect from 'hooks/useConfigureMFARedirect';

import { useMayI } from 'module/May';
import SearchBar from 'module/SearchBar';
import RequestPreferredOrg from 'module/Widgets/RequestPreferredOrg/RequestPreferredOrg';

import { useSidebarState } from 'provider';

import { FunctionalPermission } from 'types/auth';
import { StrictReactNode } from 'types/core';

import { DRAWER_WIDTH, NAVIGATION_HEADER_HEIGHT } from './Scaffold.const';
import { isPageFlexible } from './Scaffold.service';
import { Glossary } from './Scaffold.type';
import ImpersonateBanner from './core/Banner/ImpersonateBanner';
import IsolatedBanner from './core/Banner/IsolatedBanner';
import MaintenanceBanner from './core/Banner/MaintenanceBanner';
import SafariBrowserBanner from './core/Banner/SafariBrowserBanner';
import SessionResumeBanner from './core/Banner/SessionResumeBanner';
import SessionTaskBanner from './core/Banner/SessionTaskBanner';
import ToolbarItemBurger from './core/ToolbarItemBurger';
import ToolbarItemDetect from './core/ToolbarItemDetect';
import ToolbarItemHunt from './core/ToolbarItemHunt';
import ToolbarItemLogo from './core/ToolbarItemLogo';
import ToolbarItemProfile from './core/ToolbarItemProfile';
import ToolbarItemReport from './core/ToolbarItemReport';
import ToolbarItemResearch from './core/ToolbarItemResearch';
import ToolbarItemSandbox from './core/ToolbarItemSandbox';
import ToolbarItemValidate from './core/ToolbarItemValidate';
import useWidthListener from './useWidthListener';

type MainProps = {
  theme?: Theme;
  open: boolean;
};
const Main = styled('div', { shouldForwardProp: p => p !== 'open' })<MainProps>`
  &.flexible {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .HeroImage {
      flex: 0 0 auto;
    }
  }

  &.rigid {
    margin-left: ${p => p.theme.spacing(8)};
  }

  --Scaffold-drawer: ${p => (p.open ? DRAWER_WIDTH : 41)}px;
  --Scaffold-cushion: 20%;
  --Scaffold-spacing: ${p => p.theme.spacing(6)};
  --Scaffold-flex: ${p => (p.open ? '0px' : '20%')};
  --Scaffold-flex-hero: ${p => (p.open ? '7%' : '12%')};
  --Scaffold-page: 20px var(--Scaffold-flex);
  --Scaffold-hero: 20px var(--Scaffold-flex-hero);
`;

const DrawerHeader = styled('div')<any>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 1),

  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const Banners = styled('div')`
  position: sticky;
  top: ${NAVIGATION_HEADER_HEIGHT}px;
  z-index: ${p => p.theme.zIndex.drawer - 1};
  > div:not(:last-of-type) {
    border-bottom: 1px solid hsla(0 0% 34% / 0.25);
  }
`;

const ToolBox = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
interface Props extends AppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})<Props>(({ theme, open }) => ({
  zIndex: theme.zIndex.navBar,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }),

  '& .MuiToolbar-root': {
    backgroundColor: theme.palette.common.black,
    gap: theme.spacing(4)
  },

  '&.MuiPaper-root': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  '& .MuiToolbar-root.grow': {
    flexGrow: 1
  }
}));

export default function ScaffoldApp({ children }: { children: StrictReactNode }) {
  useConfigureMFARedirect();
  const { pathname } = useLocation();
  const sidebarState = useSidebarState();
  const isBasUser = useMayI(FunctionalPermission.BASStableFeatures);
  const sidebarIsOpen = sidebarState.options.enabled && sidebarState.open;
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<Glossary>({});
  const flexible = isPageFlexible(pathname);
  const { width: availableWidthForAppBar, small, medium, large } = useWidthListener(sidebarIsOpen, 10);

  const handleMenuClose = () => setMenuAnchorEl({});

  const handleMenuOpen = (id: string, element: HTMLElement | HTMLDivElement): void => {
    if (menuAnchorEl[id]) return handleMenuClose();
    setMenuAnchorEl({ [id]: element });
  };

  React.useEffect(() => {
    // Close any open menus if width changes
    setMenuAnchorEl({});
  }, [availableWidthForAppBar]);

  const toolbarItemProps = {
    onClick: handleMenuOpen,
    onClose: handleMenuClose,
    glossary: menuAnchorEl
  };

  return (
    <>
      <AppBar position='fixed' open={sidebarIsOpen}>
        <Toolbar className='grow'>
          {!sidebarIsOpen && <ToolbarItemLogo />}
          {large && (
            <>
              <ToolbarItemResearch {...toolbarItemProps} />
              <ToolbarItemDetect {...toolbarItemProps} />
              <ToolbarItemHunt {...toolbarItemProps} />
              {isBasUser && <ToolbarItemValidate {...toolbarItemProps} />}
              <ToolbarItemReport {...toolbarItemProps} />
              <ToolbarItemSandbox {...toolbarItemProps} />
              <ToolBox>
                <SearchBar {...toolbarItemProps} />
                <ToolbarItemProfile {...toolbarItemProps} />
              </ToolBox>
            </>
          )}
          {medium && (
            <>
              <ToolbarItemResearch {...toolbarItemProps} />
              <ToolbarItemDetect {...toolbarItemProps} />
              <ToolbarItemHunt {...toolbarItemProps} />
              {isBasUser && <ToolbarItemValidate {...toolbarItemProps} />}
              <ToolbarItemReport {...toolbarItemProps} />
              <ToolbarItemSandbox {...toolbarItemProps} />
              <ToolBox>
                <ToolbarItemProfile {...toolbarItemProps} />
              </ToolBox>
            </>
          )}
          {small && (
            <ToolBox>
              <ToolbarItemBurger {...toolbarItemProps} />
            </ToolBox>
          )}
        </Toolbar>
      </AppBar>
      <Main open={sidebarIsOpen} className={`Main ${flexible ? 'flexible' : 'rigid'}`}>
        <DrawerHeader />
        <Banners className='Banners'>
          <ImpersonateBanner />
          <IsolatedBanner />
          <MaintenanceBanner />
          <SessionResumeBanner />
          <SessionTaskBanner />
          <SafariBrowserBanner />
          <RequestPreferredOrg />
        </Banners>
        {children}
      </Main>
    </>
  );
}
