import { AxiosError } from 'axios';

import { getAnalytic } from 'apis/resources/analytic';
import { snapattack } from 'apis/snapattack';

import { AssociatedCollections } from 'module/Collection/Collection.type';
import { SupplementalArtifact } from 'module/Search';
import { BACKFILL_SHELL } from 'module/Session/Session.const';

import { ArtifactAnalyticCatalog } from 'types/analytic';
import { Permitted } from 'types/auth';
import { SessionBASScriptResponse } from 'types/bas';
import { Artifact, CeleryTaskResponse, Guid, Ident, TaskStatus, Tracked } from 'types/common';
import { RedMarker } from 'types/marker';

import {
  Backfill,
  CommissionedHost,
  Host,
  MarkersBlue,
  MarkersEvents,
  MarkersRed,
  MarkersResponse,
  RedMarkerCreationPayload,
  RedMarkerUpdatePayload,
  SessionReferences,
  StartSessionRequest
} from './Session.type';

const prefix = '/sessions';
const prefixHost = '/host';

export function getAvailableHosts(): Promise<CommissionedHost[]> {
  return snapattack.get(`${prefixHost}/available/`).then(res => res.data);
}

export function startSession(payload: StartSessionRequest): Promise<CommissionedHost[]> {
  return snapattack.post(`${prefix}/`, payload).then(res => res.data);
}

export function killActiveSession(): Promise<string> {
  return snapattack.delete(`${prefix}/active/`).then(res => res.data);
}

export function saveActiveSession(): Promise<string[]> {
  return snapattack.post(`${prefix}/active/save/`).then(res => res.data);
}

export function getActiveSession(): Promise<CommissionedHost[]> {
  return snapattack.get(`${prefix}/active/`).then(res => res.data);
}

export function getSessionCollectionAssociations(guid: Guid): Promise<AssociatedCollections[]> {
  const url = `${prefix}/${guid}/collections/`;
  return snapattack.get(url).then(r => r.data);
}

export function getSession(sessionId: Guid): Promise<Artifact> {
  const url = `${prefix}/${sessionId}/`;
  return snapattack.get(url).then(r => r.data);
}

export function getSessionReferences(sessionId: Guid): Promise<SessionReferences> {
  const url = `${prefix}/${sessionId}/references/`;
  return snapattack.get(url).then(r => r.data);
}

export function getSessionPermissions(sessionId: Guid): Promise<Permitted> {
  const url = `${prefix}/${sessionId}/permissions/`;
  return snapattack.get(url).then(r => ({
    permission: r.data
  }));
}

export function getSessionHosts(sessionId: Guid): Promise<Host[]> {
  const url = `${prefix}/${sessionId}/hosts/`;
  return snapattack.get(url).then(r => r.data);
}

export function getBackfill(sessionId: Guid): Promise<Backfill> {
  return snapattack.get(`${prefix}/${sessionId}/backfill_status/`).then(r => r.data || BACKFILL_SHELL);
}

/**
 * Returns all markers for all hosts
 */
export function getCompositeMarker(sessionId: Guid): Promise<MarkersResponse> {
  const promise: [Promise<MarkersBlue>, Promise<MarkersRed>, Promise<MarkersEvents>] = [
    snapattack.get(`${prefix}/${sessionId}/markers/blue/`).then(r => r.data),
    snapattack.get(`${prefix}/${sessionId}/markers/red/`).then(r => r.data),
    snapattack.get(`${prefix}/${sessionId}/markers/events/`).then(r => r.data)
  ];

  return Promise.all(promise)
    .then(([blue, red, events]) => ({
      blue,
      red,
      events
    }))
    .catch(() => null);
}

export function getBASScript(sessionId: string): Promise<SessionBASScriptResponse> {
  return snapattack
    .get(`${prefix}/${sessionId}/bas_script/`)
    .then(r => r.data)
    .catch((err: AxiosError) => {
      if (err.response.status === 404) {
        // 404 just means the session doesn't have a script, so catch the error and return empty response
        return null;
      }
      throw err;
    });
}

export function addRedMarker(sessionId: Guid, hostId: Guid, payload: RedMarkerCreationPayload): Promise<RedMarker> {
  return snapattack.post(`${prefix}/${sessionId}/hosts/${hostId}/markers/red/`, payload).then(res => res.data);
}

export function updateRedMarker(
  sessionId: Guid,
  hostId: Guid,
  markerId: number,
  payload: RedMarkerUpdatePayload
): Promise<RedMarker> {
  return snapattack.put(`${prefix}/${sessionId}/hosts/${hostId}/markers/red/${markerId}/`, payload).then(res => {
    return res.data;
  });
}

export function removeRedMarker(sessionId: Guid, hostId: Guid, markerId: Ident): Promise<void> {
  return snapattack.delete(`${prefix}/${sessionId}/hosts/${hostId}/markers/red/${markerId}/`);
}

export function updateSessionMeta(guid: Guid, session: Partial<Artifact>): Promise<Tracked> {
  return snapattack.put(`${prefix}/${guid}/`, session).then(res => res.data);
}

export function updateMachineDisplayName(machineId: Ident, name: string): Promise<void> {
  return snapattack.put(`/machine/${machineId}/`, { name }).then(r => r.data);
}

export function deleteSession(guid: Guid): Promise<void> {
  return snapattack.delete(`${prefix}/${guid}/`).then(res => res.data);
}

/**
 * Move a session to another organization
 * returns a task guid that may be polled for status updates
 */
export function migrateSession(guid: Guid, organization_id: Ident): Promise<CeleryTaskResponse> {
  return snapattack.post(`${prefix}/${guid}/migrate/`, { organization_id }).then(r => r.data);
}

/**
 * Get the status of a migrate task
 */
export function getMigrationStatus(
  sessionGuid: Guid,
  taskGuid: Guid
): Promise<TaskStatus<{ session_identifier: Guid; message: string }>> {
  return snapattack.get(`${prefix}/${sessionGuid}/migrate/${taskGuid}/`).then(r => r.data);
}

export async function getAnalyticRecommendations(guid: Guid): Promise<SupplementalArtifact[]> {
  return await snapattack.get(`${prefix}/${guid}/analytic_recommendations/`).then(res => res.data);
}

export async function getBulkAnalyticRecommendations(guid: Guid) {
  const result: Tracked[] = await snapattack.get(`${prefix}/${guid}/analytic_recommendations/`).then(res => res.data);
  const analyticsPromises: Promise<ArtifactAnalyticCatalog>[] = [];

  result.forEach(async tracked => {
    analyticsPromises.push(
      new Promise(res => {
        getAnalytic(tracked.guid).then(data => res({ ...data, ...tracked }));
      })
    );
  });

  return Promise.all(analyticsPromises);
}

export function exportSessionFiles(sessionGuid: Guid): Promise<Blob> {
  return snapattack.get(`${prefix}/${sessionGuid}/files/`, { responseType: 'blob' }).then(r => r.data);
}
