import { snapattack } from 'apis/snapattack';

import { Guid } from 'types/common';

import { ImportEndpointPrefix, ImportForm, ImportResponse, ImportStatus } from './Import.type';

export function postSignatureCSVImport(prefix: ImportEndpointPrefix, payload: ImportForm): Promise<ImportResponse> {
  const fileBlob = payload.file[0];
  const formData = new FormData();
  formData.append(
    'options',
    JSON.stringify({
      organization_id: payload.organization_id,
      integration_id: payload.integration_id,
      reimport: payload.reimport,
      deployable: payload.deployable
    })
  );
  formData.append('file', fileBlob);
  return snapattack
    .post(`${prefix}/csv/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(r => r.data);
}

export function postSignatureJSONImport(prefix: ImportEndpointPrefix, payload): Promise<ImportResponse> {
  return snapattack.post(`${prefix}/json/`, payload).then(r => r.data);
}

export function getImportStatus(prefix: ImportEndpointPrefix, taskId: Guid): Promise<ImportStatus> {
  return snapattack.get(`${prefix}/${taskId}/`).then(r => r.data);
}
export function getDetectionImportTemplate(prefix: ImportEndpointPrefix): Promise<string> {
  return snapattack.get(`${prefix}/csv/`).then(r => r.data);
}

export function getImportResult(prefix: ImportEndpointPrefix, taskId: Guid): Promise<string> {
  return snapattack
    .get(`${prefix}/csv/${taskId}/result/`, { responseType: 'text' })
    .then(r => r.data)
    .then(content => {
      if (typeof content === 'string') return content;
      return JSON.stringify(content);
    });
}
