import React from 'react';

import { Engage, Fingerprint, Widget } from 'lib/Engagement';

import { BurgerClicker } from 'module/Layout/Artifact.widgets';

import { useAuth } from 'provider';

import { checkTaskPermission } from 'services/authService';

import { FunctionalPermission } from 'types/auth';

import { DetectionExporter, icon } from '.';

type Button = Partial<Omit<BurgerClicker, 'onClick'>>;
type Dialog = Omit<DetectionExporter, 'isOpen' | 'onClose'>;

export function useDetectionExportInterface(): [(props: Button) => JSX.Element, (props: Dialog) => JSX.Element] {
  const { permission } = useAuth();
  const [isOpen, setIsOpen] = React.useState(false);

  if (!checkTaskPermission(permission, FunctionalPermission.BulkExportAnalytic)) return [() => null, () => null];

  const button = (props: Button) => (
    <BurgerClicker
      title='Export Detections'
      aria-label='Export Detections'
      icon={icon}
      onClick={() => {
        Engage.track(Fingerprint.of(Widget.Modal).open().withQualifier('export detections'));
        setIsOpen(true);
      }}
      {...props}
    />
  );
  const dialog = (props: Dialog) => <DetectionExporter isOpen={isOpen} onClose={() => setIsOpen(false)} {...props} />;

  return [button, dialog];
}
