import moment from 'moment';

import { snapattack } from 'apis/snapattack';

import { EVERY, Stats, ViewedStats, CreatedStats, HuntedStats } from './SocDashboard.type';

const RANGES = {
  WEEK: 7,
  MONTH: 31,
  SIX_MONTHS: 180
};

export type Payload = {
  start: Date;
  stop: Date;
  organization_id: number;
};

function editPayload(payload: Payload) {
  const { start, stop } = payload;
  const startDate = moment(start);
  const endDate = moment(stop);
  const dateRange = Math.abs(startDate.diff(endDate, 'days'));
  const every = EVERY.DAY;

  if (dateRange <= RANGES.WEEK) {
    const newStop = moment(stop).subtract(7, 'd');
    return { ...payload, every, start: newStop };
  } else if (dateRange <= RANGES.MONTH) {
    return { ...payload, every };
  } else if (dateRange <= RANGES.SIX_MONTHS) {
    return { ...payload, every: EVERY.WEEK };
  } else if (dateRange > RANGES.SIX_MONTHS) {
    return { ...payload, every: EVERY.MONTH };
  }
}

export function getSocDashboardStats(payload: Payload): Promise<Stats> {
  const params = { page: 0, size: 5 };
  const newPayload = editPayload(payload);
  return snapattack.post('/stats/integrations/', newPayload, { params }).then(r => r.data);
}

export function getUserActivityViewedStats(payload: Payload): Promise<ViewedStats> {
  const newPayload = editPayload(payload);
  return snapattack.post('/stats/activity/viewed/', newPayload).then(r => r.data);
}

export function getUserActivityHuntedStats(payload: Payload): Promise<HuntedStats> {
  const newPayload = editPayload(payload);
  return snapattack.post('/stats/activity/hunted/', newPayload).then(r => r.data);
}

export function getUserActivityCreatedStats(payload: Payload): Promise<CreatedStats> {
  const newPayload = editPayload(payload);
  return snapattack.post('/stats/activity/created/', newPayload).then(r => r.data);
}
