import React from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faBuildingCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { IconButton } from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import { ListItem as MuiListItem } from 'snap-ui/List';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

type Props = {
  disabled?: boolean;
  icon: IconDefinition;
  id?: string;
  onClick?(): void;
  to: string;
  tooltip?: string;
};

const ListItem = styled(MuiListItem)`
  padding-left: 0;
  padding-right: 0;

  .MuiButtonBase-root {
    width: 100%;
    color: ${p => p.theme.palette.common.white};
  }

  .MuiButtonBase-root:hover {
    color: ${p => p.theme.palette.common.white};
    background-color: ${p => p.theme.palette.surface.hover};
  }

  .optically-center > svg {
    margin-right: -4px;
  }

  &.disabled .MuiButtonBase-root {
    color: ${p => p.theme.palette.action.disabled};
  }
`;

/**
 * For use with closed Drawer
 */
export default function ItemLinkIcon({ icon, to, tooltip, ...others }: Props) {
  return (
    <Tooltip title={tooltip ? tooltip : ''} arrow placement='right'>
      <ListItem className={classnames({ disabled: others.disabled })}>
        <IconButton
          className={classnames('ScaffoldItem ItemLinkIcon', {
            'optically-center': icon === faBuildingCircleCheck
          })}
          component={Link}
          to={to}
          {...others}
        >
          <Icon icon={icon} />
        </IconButton>
      </ListItem>
    </Tooltip>
  );
}
