import React from 'react';

import zod from 'zod';

import { Option } from 'snap-ui/Autocomplete';
import Autosearch from 'snap-ui/Autosearch';
import FormGroup from 'snap-ui/FormGroup';
import FormLabel from 'snap-ui/FormLabel';

import { NameMatchResponse } from 'module/Feed/Feed.type';
import usePartialNameMatch from 'module/Intel/IntelCore/usePartialNameMatch';

import { Status } from 'storage';

import { ArtifactType } from 'types/common';
import { Ops, Query } from 'types/filter';

import { AutosearchControl } from '../GlobalFilter.style';
import { FilterConfig } from '../GlobalFilter.type';

type SelectedAnalyticKeys = {
  selectedAnalytics: string[];
};

type SelectedAnalyticFilterProps = {
  onChange(values: SelectedAnalyticKeys): void;
  values: SelectedAnalyticKeys;
};

function SelectedAnalyticFilter({ onChange, values }: SelectedAnalyticFilterProps): JSX.Element {
  const { data, status, cancel, search } = usePartialNameMatch();
  const [selection, setSelection] = React.useState<Option[]>([]);
  const mappedOption = React.useMemo(() => mapSelectionAndData(selection, data), [data, selection]);

  function handleListChange(value: Option[]) {
    onChange({ selectedAnalytics: value.map(o => o.value) });
    setSelection(mapSelectionAndOption(selection, value));
  }

  function handleSearchChange(value: string) {
    cancel();
    search(value, [ArtifactType.DeprecatedAnalytic]);
  }

  return (
    <AutosearchControl>
      <FormLabel id='analytic-group-label' className='form-label'>
        By Detection
      </FormLabel>
      <FormGroup aria-labelledby='analytic-group-label'>
        <Autosearch
          className='autosearch'
          onChange={handleListChange}
          onSearchChange={handleSearchChange}
          value={values.selectedAnalytics}
          option={mappedOption}
          isSearching={status === Status.pending}
          noOptionText='Enter a value to search detections by name'
          disableUserAdditions
        />
      </FormGroup>
    </AutosearchControl>
  );
}

function toQuery(values: SelectedAnalyticKeys): Query {
  if (!values.selectedAnalytics?.length) return;
  return {
    field: 'analytics.guid',
    op: Ops.in,
    value: values.selectedAnalytics
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('analytics.guid'),
    op: zod.literal(Ops.in),
    value: zod.array(zod.string())
  })
  .transform(query => ({ selectedAnalytics: query.value }));

const SelectedAnalyticFilterConfig: FilterConfig<SelectedAnalyticKeys> = {
  defaults: { default: () => ({ selectedAnalytics: [] }) },
  supportedTopics: [ArtifactType.Session],
  component: SelectedAnalyticFilter,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default SelectedAnalyticFilterConfig;

export function mapSelectionAndData(selection: Option[], data: NameMatchResponse) {
  return [
    ...selection,
    ...data
      .filter(analytic => !selection.some(s => s.value === analytic.guid))
      .map(analytic => ({
        content: analytic.name,
        value: analytic.guid
      }))
  ];
}

export function mapSelectionAndOption(selection: Option[], data: Option[]) {
  return [...selection, ...data.filter(d => !selection.some(s => s.value === d.value))];
}
