import React from 'react';

import { faCompress, faExpand, faPause, faPlay, faRedo } from '@fortawesome/pro-solid-svg-icons';

import { ActionIconButton } from 'snap-ui/Button';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { convertSecondsToMMss } from 'utilities/TimeUtils';

interface ControlProps {
  className?: string;
  containerRef: HTMLElement;
  currentTime: number;
  duration: number;
  isFullscreen: boolean;
  playing: boolean;
  onFullscreenClick(e: React.MouseEvent<HTMLElement>): void;
  onPlayPauseClick(e: React.MouseEvent<HTMLElement>): void;
}

function Controls({
  className,
  containerRef,
  currentTime,
  duration,
  isFullscreen,
  playing,
  onFullscreenClick,
  onPlayPauseClick
}: ControlProps) {
  function getPlayIcon() {
    if (playing) return faPause;
    if (duration === currentTime) return faRedo;
    return faPlay;
  }

  function getPlayLabel() {
    if (playing) return 'pause';
    if (duration === currentTime) return 'replay';
    return 'play';
  }

  function getFullscreenLabel() {
    return isFullscreen ? 'Exit full screen' : 'Full screen';
  }

  return (
    <div className={className}>
      <div>
        <ActionIconButton
          aria-label={getPlayLabel()}
          className='play-button'
          icon={getPlayIcon()}
          onClick={onPlayPauseClick}
        />
        {convertSecondsToMMss(currentTime)} / {convertSecondsToMMss(duration)}
      </div>
      <div>
        <Tooltip PopperProps={{ container: containerRef }} placement='top' title={getFullscreenLabel()} wrap>
          <ActionIconButton
            aria-label={getFullscreenLabel()}
            icon={isFullscreen ? faCompress : faExpand}
            onClick={onFullscreenClick}
          />
        </Tooltip>
      </div>
    </div>
  );
}

export default styled(Controls)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${p => p.theme.spacing(3)};

  & .play-button {
    margin-right: ${p => p.theme.spacing(3)};
  }
`;
