import React from 'react';

import { Formik } from 'formik';
import { object, string } from 'yup';

import Button from 'snap-ui/Button';

import TextFieldFormik from 'module/Form/TextFieldFormik';

import { ForgotRequest } from 'types/auth';

import { LoginRegisterLinks } from '../Authentication.helper';
import { Form } from '../Authentication.style';

type ForgotPwFormProps = {
  submitForm: (formValue: ForgotRequest) => void;
};

const ForgotPwForm = ({ submitForm }: ForgotPwFormProps): React.ReactElement => {
  const handleSubmit = (formValue: ForgotRequest): void => {
    submitForm(formValue);
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{ email: '' }}
      onSubmit={handleSubmit}
      validationSchema={object().shape({
        email: string().email('Please enter a valid email').required('Email is required')
      })}
    >
      <Form>
        <TextFieldFormik label='Enter email' name='email' type='email' autoComplete='on' />
        <Button type='submit'>Send Reset Email</Button>
        <LoginRegisterLinks />
      </Form>
    </Formik>
  );
};

export default ForgotPwForm;
