import { styled } from 'snap-ui/util';

import Artifact from 'module/Layout/Artifact';

export const ArtifactStyle = styled(Artifact)`
  .HeroImage-window {
    min-width: fit-content;
    display: grid;
    align-items: center;
    gap: ${p => p.theme.spacing(3)};

    grid-template-columns: 1fr 100px;
    grid-template-rows: 4fr;
    grid-template-areas:
      'type visibility'
      'title title'
      'author author'
      'footer footer';

    .Artifact-type {
      grid-area: type;
      justify-self: start;
      height: 1.25rem;
    }

    .ArtifactWidget-visibility {
      grid-area: visibility;
    }

    .ArtifactWidget-action {
      grid-area: action;
      justify-self: end;
      display: flex;
      gap: ${p => p.theme.spacing(1)};
      align-items: center;
    }

    .ArtifactWidget-title {
      grid-area: title;
    }

    .ArtifactWidget-author {
      grid-area: author;

      .Divider {
        margin: 15px 0;
      }
    }

    .Unfurl {
      max-width: min(500px, 50%);
    }

    .ArtifactWidget-footer {
      justify-content: space-between;
    }

    .ArtifactWidget-menuWrapper {
      display: flex;
      align-items: center;
      gap: ${p => p.theme.spacing(3)};
    }
  }

  .Intel-error {
    margin-bottom: ${p => p.theme.spacing(7)};
  }

  .IntelFeed {
    margin-top: ${p => p.theme.spacing(7)};
  }

  .Alert {
    margin-top: ${p => p.theme.spacing(5)};
  }
`;
