import React from 'react';

import { useIDEValue } from 'module/IDE';
import DelayedTextField from 'module/Widgets/DelayedTextField';

interface LicenseFieldProps {
  readonly?: boolean;
}

function LicenseField({ readonly }: LicenseFieldProps): React.ReactElement {
  const { value, setValue } = useIDEValue('license_url');

  return (
    <DelayedTextField
      elevated
      label='License URL'
      name='license_url'
      placeholder='A link to the text of the license that governs this detection'
      aria-label='detection license url'
      disabled={readonly}
      onChange={v => setValue(v)}
      value={value}
      data-testid='license_url'
    />
  );
}

export default LicenseField;
