import React from 'react';

import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { Menu } from '@mui/material';

import { HamburgerFab } from 'module/Layout/Styled';

import { StrictReactNode } from 'types/core';

/**
 * Standard Burger component. Three ways to work with this component and one pitfall. See MenuTrigger.readme.md for examples.
 */
export default function MenuTrigger({
  trigger,
  children,
  className
}: {
  trigger?:
    | React.ReactElement
    | (({
        ref,
        toggle,
        className
      }: {
        className: string;
        ref: React.MutableRefObject<undefined>;
        toggle(): void;
      }) => JSX.Element);
  children: StrictReactNode | ((params: { toggle(): void }) => React.ReactNode[]);
  className?: string;
}): JSX.Element {
  const ref = React.useRef();
  const [open, setOpen] = React.useState(false);
  const toggle = React.useCallback(() => setOpen(!open), [open]);
  const isAutoClosing = typeof children !== 'function';

  return (
    <>
      {!trigger ? (
        <HamburgerFab aria-label='Menu opener' icon={faBars} ref={ref} onClick={toggle} className={className} />
      ) : typeof trigger === 'function' ? (
        trigger({ ref, toggle, className })
      ) : (
        React.cloneElement(trigger, { ref, className, onClick: toggle })
      )}
      <Menu
        anchorEl={ref.current}
        onClose={toggle}
        onClick={isAutoClosing ? toggle : undefined}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {isAutoClosing ? children : children({ toggle })}
      </Menu>
    </>
  );
}
