import React from 'react';

import { DateRange, getInitialDatesByPreset } from 'module/DateRangeSelector';

import DetectionDashboard from './DetectionDashboard';
import { DetectionDashboardStatsProvider } from './DetectionDashboardContext';

const INITIAL_PRESET = 30;
export default function WrappedDetectionDashboard() {
  const [dates, setDates] = React.useState<DateRange>(() => getInitialDatesByPreset(INITIAL_PRESET));
  return (
    <DetectionDashboardStatsProvider
      DateRangeSelectorProps={{ dates, onDateRangeChange: setDates, initialPreset: INITIAL_PRESET }}
    >
      <DetectionDashboard />
    </DetectionDashboardStatsProvider>
  );
}

export { DetectionDashboardStatsProvider, useDashboardStats } from './DetectionDashboardContext';
