import React from 'react';

import { faWindows } from '@fortawesome/free-brands-svg-icons';

import { OutlinedAccordion as Accordion } from 'snap-ui/Accordion';
import Icon, { saLinuxTux } from 'snap-ui/Icon';
import { styled } from 'snap-ui/util';

const Container = styled('div')`
  padding: ${p => p.theme.spacing(4)};
  margin: ${p => p.theme.spacing(4, 0)};

  max-width: 65%;
`;

const OSIcon = styled(Icon)`
  margin: ${p => p.theme.spacing(0, 1)};
`;

export default function Download(): JSX.Element {
  return (
    <Container>
      <Accordion
        summary='How do I run attack simulations?'
        details={
          <div>
            <p>
              Want to test attacks in your local environment? With the Attack Simulation Agent, you can run automated
              attacks on a local workstation, server, or virtual machine to test and validate your deployed detections!
            </p>

            <p>
              <a
                href='https://cdn.snapattack.com/resources/validate/snapattack_validation_agent_installation_guide.pdf'
                rel='noopener noreferrer'
                target='_blank'
              >
                Read the documentation
              </a>{' '}
              for installation instructions and launching your first attack simulation.
            </p>

            <p>
              <OSIcon icon={faWindows} />{' '}
              <a
                href='https://cdn.snapattack.com/resources/validate/Validate-Setup.exe'
                rel='noopener noreferrer'
                target='_blank'
              >
                Download the Windows Agent
              </a>
            </p>

            <p>
              <OSIcon icon={saLinuxTux} />{' '}
              <a
                href='https://cdn.snapattack.com/resources/validate/Validate-Linux.tar.gz'
                rel='noopener noreferrer'
                target='_blank'
              >
                Download the Linux Agent
              </a>
            </p>
          </div>
        }
      />
    </Container>
  );
}
