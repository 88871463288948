import React from 'react';

import { faDiamondExclamation, faHexagonCheck } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

import Button from 'snap-ui/Button';
import Icon from 'snap-ui/Icon';
import MenuItem from 'snap-ui/MenuItem';
import Tooltip from 'snap-ui/Tooltip';

import { LevelMenuBadgeContainer, StyledMenu } from 'module/Widgets/LevelMenuBadge/LevelMenuBadge.style';

import { ArtifactScore } from 'types/common';

type LevelMenuBadgeProps = {
  value: ArtifactScore;
  variant: 'Severity' | 'Confidence';
  permitted: boolean;
  loading?: boolean;
  tooltip?: React.ReactNode;
  handleLevelChange(score: ArtifactScore): Promise<void>;
};

export function LevelMenuBadge({
  value,
  variant,
  permitted,
  loading,
  tooltip,
  handleLevelChange
}: LevelMenuBadgeProps): JSX.Element {
  const severityEl = React.useRef<HTMLButtonElement>();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  function handleClose() {
    setIsMenuOpen(false);
  }

  function handleOpen() {
    setIsMenuOpen(true);
  }

  const valueText = value?.toLocaleUpperCase();

  function handleChange(event: React.MouseEvent<HTMLElement>) {
    const value = event.currentTarget.textContent;
    handleLevelChange(value as ArtifactScore);
    handleClose();
  }

  return (
    <LevelMenuBadgeContainer className={classNames(variant)}>
      {permitted ? (
        <>
          <Button
            ref={severityEl}
            className={value}
            id='basic-button'
            variant='outlined'
            color='inherit'
            aria-controls={isMenuOpen ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={isMenuOpen ? 'true' : undefined}
            onClick={handleOpen}
          >
            {loading ? (
              <Icon.SpinnerProgress />
            ) : (
              <>
                {variant === 'Severity' && <Icon icon={faDiamondExclamation} />}
                {variant === 'Confidence' && <Icon icon={faHexagonCheck} />}
                {variant === 'Severity' && (
                  <div className='text'>
                    {valueText}
                    <br /> {variant.toUpperCase()}
                  </div>
                )}
                {variant === 'Confidence' && tooltip && (
                  <Tooltip arrow placement='top' title={tooltip}>
                    <div className='text'>
                      {valueText}
                      <br /> {variant.toUpperCase()}
                    </div>
                  </Tooltip>
                )}
                <Icon className='dropdown-icon' icon={faCaretDown} />
              </>
            )}
          </Button>
          <StyledMenu
            anchorEl={severityEl.current}
            open={isMenuOpen}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <div className='dropdown-list'>
              {Object.values(ArtifactScore)
                .filter(score => !!score)
                .map(score => (
                  <MenuItem key={score} className={classNames('level', score)} onClick={handleChange}>
                    {score}
                  </MenuItem>
                ))}
            </div>
          </StyledMenu>
        </>
      ) : (
        <Button className={classNames(value, 'no-click')} id='basic-button' variant='outlined' color='inherit'>
          <Icon icon={faDiamondExclamation} />
          <div className='text'>
            {valueText}
            <br /> {variant?.toLocaleUpperCase()}
          </div>
        </Button>
      )}
    </LevelMenuBadgeContainer>
  );
}
