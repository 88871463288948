import React from 'react';

import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import isEmpty from 'lodash/isEmpty';

import Chip from 'snap-ui/Chip';
import Icon from 'snap-ui/Icon';
import Tooltip from 'snap-ui/Tooltip';
import { styled } from 'snap-ui/util';

import { LandingArtifact } from 'module/Landing/Landing.type';

import { ActorMotivationDetails } from 'types/mati';

export const Container = styled('div', { label: 'Motivations' })`
  display: flex;
  gap: ${p => p.theme.spacing(2)};
  align-items: center;

  .MuiChip-label {
    display: flex;
    gap: ${p => p.theme.spacing(2)};
    align-items: center;
    line-height: 1;
  }
`;

export default function Motivations({ motivations }: { motivations: LandingArtifact['motivations'] }) {
  motivations = isEmpty(motivations) ? [] : motivations;

  const display = motivations.slice(0, 5);
  const remaining = motivations.length - display.length;

  return (
    <Container>
      {isEmpty(display) && <Chip label='No Motivations Listed' />}
      {display.map(motivation => {
        const icon = ActorMotivationDetails[motivation]?.icon;
        if (!icon) return null;
        return (
          <Tooltip key={motivation} arrow title={motivation} wrap>
            <Icon icon={icon} />
          </Tooltip>
        );
      })}
      {remaining > 0 && (
        <Chip
          size='small'
          label={
            <>
              <Icon icon={faCirclePlus} /> <span>{remaining}</span>
            </>
          }
        />
      )}
    </Container>
  );
}
