import { CompositeMarkerLoadState } from 'aso/useCompositeMarker';

import { addRedMarker, removeRedMarker, updateRedMarker } from 'module/Session/Session.api';

import { Guid, Ident } from 'types/common';
import { CompositeMarker, RedMarker } from 'types/marker';

import { RedMarkerCreationPayload, RedMarkerUpdatePayload } from './Session.type';

/**
 * Addressing any mutually exclusive data
 */
function filterOutExclusiveData(data: RedMarkerCreationPayload): RedMarkerCreationPayload {
  const payload = {
    ...data
  };

  if (payload.event_id) {
    delete payload.event_row;
    delete payload.timestamp;
  } else if (payload.event_row) {
    delete payload.event_id;
    delete payload.timestamp;
  } else if (payload.timestamp) {
    delete payload.event_id;
    delete payload.event_row;
  }

  return payload;
}

function packMarkerWithData(marker: RedMarker, composite: CompositeMarker): CompositeMarker {
  return {
    blue: [...composite.blue],
    red: [...composite.red, marker]
  };
}

function repackMarkerInData(marker: RedMarker, composite: CompositeMarker): CompositeMarker {
  return {
    blue: [...composite.blue],
    red: [...composite.red.map(r => (r.id === marker.id ? { ...r, ...marker } : r))]
  };
}

function unpackMarkerFromData(id: number, composite: CompositeMarker): CompositeMarker {
  return {
    blue: [...composite.blue],
    red: [...composite.red.filter(r => r.id !== id)]
  };
}

/**
 * Handles filtering exclusive data, posting request, packing response, updating cache, and requesting refresh
 * @param session Guid
 * @param host Guid
 * @param payload RedMarkerCreationPayload
 * @param composite CompositeMarkerLoadState
 */
export const addMarker = async (
  session: Guid,
  host: Guid,
  payload: RedMarkerCreationPayload,
  composite: CompositeMarkerLoadState
) => {
  const filtered = filterOutExclusiveData(payload);
  const red = await addRedMarker(session, host, filtered);
  return packMarkerWithData(red, composite.markers);
};

/**
 * Handles putting request, repacking response, updating cache, and requesting refresh
 * @param session Guid
 * @param host Guid
 * @param updateMarkerId Ident
 * @param payload RedMarkerUpdatePayload
 * @param composite CompositeMarkerLoadState
 */
export const updateMarker = async (
  session: Guid,
  host: Guid,
  updateMarkerId: Ident,
  payload: RedMarkerUpdatePayload,
  composite: CompositeMarkerLoadState
) => {
  const red = await updateRedMarker(session, host, updateMarkerId, payload);
  return repackMarkerInData(red, composite.markers);
};

/**
 * Handles deleting request, unpacking Ident, updating cache, and requesting refresh
 * @param session Guid
 * @param host Guid
 * @param deleteMarkerId Ident
 */
export const deleteMarker = async (
  session: Guid,
  host: Guid,
  deleteMarkerId: Ident,
  composite: CompositeMarkerLoadState
) => {
  await removeRedMarker(session, host, deleteMarkerId);
  return unpackMarkerFromData(deleteMarkerId, composite.markers);
};
