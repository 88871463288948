import React from 'react';

import { CancelToken, CancelTokenSourceType } from 'apis';

import Path from 'constants/paths';

import { FEED_SKELETON } from 'module/Feed/Feed.const';
import { getSearchPage } from 'module/Feed/Feed.service';
import { Feed } from 'module/Feed/Feed.type';
import { useFilterRegistry } from 'module/GlobalFilter';
import { Associator } from 'module/Widgets/AssociateArtifactsModal';

import { useAsync } from 'storage';

import { Artifact, ArtifactType, IconButtonRenderProps } from 'types/common';
import { Identity } from 'types/common.zod';
import { Ops } from 'types/filter';

import { postAttackScriptAnalytics } from '../AttackScript.api';

export type AddAnalyticToAttackScriptProps = IconButtonRenderProps & {
  attackScript: Identity;
  onClose: () => void;
  isOpen: boolean;
};

const ITEMS_PER_PAGE = 50;

export default function AddAnalyticToAttackScript({ attackScript, onClose, isOpen }: AddAnalyticToAttackScriptProps) {
  const { generateQuery } = useFilterRegistry();
  const {
    data: associatedAnalytics,
    status: associatedAnalyticsStatus,
    run: associatedAnalyticsRun
  } = useAsync<Feed>(FEED_SKELETON);

  const fetchAssociatedAnalytics = React.useCallback(() => {
    if (!attackScript.id) return;
    associatedAnalyticsRun(
      getSearchPage(
        ArtifactType.Analytic,
        1,
        ITEMS_PER_PAGE,
        { field: 'analytic.bas_scripts.id', op: Ops.equals, value: attackScript.id },
        ''
      ),
      true
    );
  }, [associatedAnalyticsRun, attackScript.id]);

  React.useEffect(() => {
    fetchAssociatedAnalytics();
  }, [fetchAssociatedAnalytics]);

  const fetchAllAnalytics = React.useCallback(
    (searchTerm: string, cancelToken: CancelTokenSourceType) => {
      return getSearchPage(
        ArtifactType.Analytic,
        1,
        ITEMS_PER_PAGE,
        generateQuery(ArtifactType.Analytic, { query: searchTerm }),
        '',
        cancelToken
      ).then(data => data.items);
    },
    [generateQuery]
  );

  const associate = React.useCallback(
    (analytic: Artifact) => {
      return postAttackScriptAnalytics(attackScript.guid, 'add', [analytic.guid]).then(() => {
        fetchAssociatedAnalytics();
        fetchAllAnalytics('', CancelToken.source());
      });
    },
    [attackScript.guid, fetchAssociatedAnalytics, fetchAllAnalytics]
  );

  const disassociate = React.useCallback(
    (analytic: Artifact) => {
      return postAttackScriptAnalytics(attackScript.guid, 'delete', [analytic.guid]).then(() => {
        fetchAssociatedAnalytics();
        fetchAllAnalytics('', CancelToken.source());
      });
    },
    [attackScript.guid, fetchAssociatedAnalytics, fetchAllAnalytics]
  );

  return (
    <Associator
      associate={associate}
      associatedRecords={associatedAnalytics.items}
      associatedRecordsStatus={associatedAnalyticsStatus}
      disassociate={disassociate}
      fetchAllOptions={fetchAllAnalytics}
      isOpen={isOpen}
      onClose={onClose}
      pathRoot={Path.Detection}
      types={{ associated: ArtifactType.AttackScript, current: ArtifactType.Analytic }}
    />
  );
}
