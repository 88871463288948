import yaml from 'js-yaml';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import omit from 'lodash/omit';
import take from 'lodash/take';
import { mixed as yupMixed } from 'yup';

import { EMPTY_KEY_VALUE_RULE, EMPTY_SECTION } from 'constants/analytic';

import { Discriminator, SigmaTag } from 'module/Tag';

import * as AnalyticTypes from 'types/analytic';
import { Analytic } from 'types/analytic';
import { BooleanString } from 'types/common';

const INDENT = 4;

export class YamlParseError extends Error {}

export class UnsupportedModifierError extends YamlParseError {
  constructor(...args: string[]) {
    if (args.length === 1) {
      super(`Sigma modifier '${args[0]}' is not supported`);
    } else if (args.length > 1) {
      super(`Sigma modifiers '${args.join(', ')}' cannot be used together`);
    } else {
      super('Sigma modifier not supported');
    }
  }
}

export class UnsupportedDetectionError extends YamlParseError {
  constructor(sectionName: string) {
    super(`Section '${sectionName}' is not supported`);
  }
}

export class UnsupportedLanguageError extends YamlParseError {
  language: string;

  constructor(fieldName: string) {
    super(`Unable to convert ${fieldName} to Sigma detection`);
    this.language = fieldName.replace('detection_', '');
  }
}

export class DuplicateFieldError extends YamlParseError {
  constructor(fieldName: string, sectionName: string) {
    super(`Field '${fieldName}' cannot be used more than once in ${sectionName}`);
  }
}

export const KeyValueSectionSchema = yupMixed().test(
  'is-KeyValueSection',
  'Section path type is not supported',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (value: any) => {
    if (typeof value === 'string' || typeof value === 'number') return false;
    if (value instanceof Array) return false;
    if (typeof value !== 'object') return false;
    return Object.values(value).every(
      fieldValue =>
        typeof fieldValue === 'string' ||
        typeof fieldValue === 'number' ||
        (fieldValue instanceof Array &&
          fieldValue.every(subValue => typeof subValue === 'string' || typeof subValue === 'number'))
    );
  }
);

export const StringsSectionSchema = yupMixed().test(
  'is-StringsSection',
  'Section path is not supported',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (value: any) => {
    if (value instanceof Array) return value.every(val => typeof val === 'string');
    return false;
  }
);

/**
 * parse yaml sigma code and remove nulls
 * @param {string} analyticYaml - analytic sigma code
 * @returns {AnalyticTypes.AnalyticForm} parsed values
 */
export function parseAnalyticYaml(
  analyticYaml: string,
  sigmaTags: SigmaTag[],
  supportedSysmonCategories?: string[],
  analytic?: Analytic
): [AnalyticTypes.AnalyticForm, (YamlParseError | yaml.YAMLException)?] {
  let yamlParseError;
  let data;
  try {
    data = (yaml.load(analyticYaml) || {}) as AnalyticTypes.AnalyticForm;
  } catch (yamlErr) {
    return [{} as AnalyticTypes.AnalyticForm, yamlErr as yaml.YAMLException];
  }
  if (typeof data === 'string') {
    return [{} as AnalyticTypes.AnalyticForm, new yaml.YAMLException('Analytic YAML cannot be parsed')];
  }
  // FIXME Better double check this logsource.category
  const isNative =
    data[`detection_${data['analytic_language']}`] !== undefined || get(data, 'logsource.category') === 'Native';

  if (!isNative) {
    try {
      let detection: Record<string, any> = get(data, 'detection');
      if (!detection) {
        const alternateDetection = Object.keys(data).find(key => key.startsWith('detection_'));
        if (alternateDetection) {
          throw new UnsupportedLanguageError(alternateDetection);
        } else {
          detection = {
            condition: 'Section_1',
            Section_1: { '': '' }
          };
          if (isWinEvent(get(data, 'logsource'))) {
            detection.Section_1 = { EventID: '' };
          }
        }
      }

      var newDetection: AnalyticTypes.DetectionForm = {
        condition: get(detection, 'condition'),
        sections: []
      };

      newDetection.sections = Object.entries(detection)
        .filter(([key]) => key !== 'condition')
        .map(([sectionName, sectionValue]) => {
          if (KeyValueSectionSchema.isValidSync(sectionValue)) {
            return {
              ...EMPTY_SECTION,
              kind: AnalyticTypes.SectionKind.KeyValue,
              name: sectionName,
              rules: Object.entries(sectionValue).map(
                ([fieldNameWithMods, fieldValue], fieldIndex, allSectionValues) => {
                  const { all, fieldName, modifier } = stripModifiers(fieldNameWithMods);

                  if (allSectionValues.some(([f], i) => f.split('|')[0] === fieldName && i !== fieldIndex)) {
                    throw new DuplicateFieldError(fieldName, sectionName);
                  }

                  return {
                    all,
                    field: fieldName,
                    modifier,
                    values: fieldValue instanceof Array ? fieldValue.map(val => String(val)) : [String(fieldValue)]
                  };
                }
              )
            };
          } else if (StringsSectionSchema.isValidSync(sectionValue)) {
            return {
              ...EMPTY_SECTION,
              kind: AnalyticTypes.SectionKind.Strings,
              name: sectionName,
              values: sectionValue as string[] // safe to assume since validation passed
            };
          } else {
            throw new UnsupportedDetectionError(sectionName);
          }
        });
    } catch (e) {
      yamlParseError = e;
    }

    data['detection'] = { ...newDetection };
  }

  Object.keys(data).forEach(field => {
    if (!isValidValue(data[field])) delete data[field];

    // make sure array fields are arrays
    if (['tags', 'references'].includes(field)) {
      if (!(data[field] instanceof Array) && data[field]) {
        data[field] = [data[field]];
      }
    }

    if (data[field] instanceof Array) {
      data[field] = data[field].filter(isValidValue);
      if (!data[field].length) delete data[field];
    }

    // converting sigma tags back into autocomplete values
    if (field === 'tags' && data[field]) {
      const attacks = new Map();
      const actors = new Map();
      const software = new Map();
      const vulnerability = new Map();

      data[field].forEach(v => {
        const tag = analytic
          ? sigmaTags
              ?.filter(o => {
                switch (o.discriminator) {
                  case Discriminator.Software:
                    return analytic.software_names?.includes(o.name);
                  case Discriminator.Attack:
                    return analytic.attack_names?.includes(o.name);
                  case Discriminator.Actor:
                    return analytic.actor_names?.includes(o.name);
                  case Discriminator.Vulnerability:
                    return analytic.vulnerability_names?.includes(o.name);
                  default:
                    return o.sigma_names.includes(v);
                }
              })
              .find(a => a.sigma_names?.includes(v))
          : sigmaTags?.find(o => o.sigma_names.includes(v));
        switch (tag?.discriminator) {
          case Discriminator.Attack:
            attacks.set(tag.name, {
              value: tag.name,
              sigma_names: tag.sigma_names,
              content: tag.name,
              discriminator: Discriminator.Attack
            });
            break;
          case Discriminator.Actor:
            actors.set(tag.name, {
              value: tag.name,
              sigma_names: tag.sigma_names,
              content: tag.name,
              discriminator: Discriminator.Actor
            });
            break;
          case Discriminator.Software:
            software.set(tag.name, {
              value: tag.name,
              sigma_names: tag.sigma_names,
              content: tag.name,
              discriminator: Discriminator.Software
            });
            break;
          case Discriminator.Vulnerability:
            vulnerability.set(tag.name, {
              value: tag.name,
              sigma_names: tag.sigma_names,
              content: tag.name,
              discriminator: Discriminator.Vulnerability
            });
            break;
        }
      });

      data['attack_names'] = Array.from(attacks.values());
      data['actor_names'] = Array.from(actors.values());
      data['software_names'] = Array.from(software.values());
      data['vulnerability_names'] = Array.from(vulnerability.values());
      delete data[field];
    }
  });

  return [data, yamlParseError];
}

/**
 * interpolate sigma code template with field values
 *
 * @param {object} values - field values to be injected into the yaml template
 * @returns {string} yaml code template including values
 */
export function interpolateYamlTemplate(values: AnalyticTypes.AnalyticForm): string {
  const sortOrder = [
    'title',
    'description',
    'tags',
    'author',
    'references',
    'logsource',
    'detection',
    'condition',
    'category'
  ];
  const { getArrayValue, getMultiLineValue, getStringValue, getTagsValue } = createGetters(values);
  const transformed: any = omit(
    {
      ...values,
      title: getStringValue('title'),
      description: getMultiLineValue('description'),
      tags: getTagsValue(),
      author: getStringValue('author'),
      references: getArrayValue('references'),
      detection: transformDetection(values)
    },
    ['attack_names', 'actor_names', 'software_names', 'vulnerability_names', 'languageId', 'organization_id']
  );

  const dump = yaml.dump(transformed, {
    indent: INDENT,
    noArrayIndent: false,
    skipInvalid: true,
    sortKeys(a, b) {
      const aOrder = sortOrder.indexOf(a);
      const bOrder = sortOrder.indexOf(b);
      if (aOrder > -1 && bOrder > -1) {
        return aOrder - bOrder;
      } else if (aOrder > -1) {
        return -1;
      } else if (bOrder > -1) {
        return 1;
      } else {
        return 0;
      }
    }
  });

  return dump;
}

export function transformDetection(
  values: Pick<AnalyticTypes.AnalyticForm, 'detection'>
): string | Record<string, string | number | boolean | string[] | number[] | boolean[]> {
  const detection = values?.detection;
  const initialTransformedDetection = {
    condition: get(detection, 'condition', '') || ''
  };

  if (!detection) {
    return Object.keys(values || {}).some(key => key.startsWith('detection_')) ? undefined : '';
  }
  if (detection.sections) {
    const transformedDetection = detection.sections.reduce((sigma, section) => {
      return {
        ...sigma,
        [section.name]:
          section.kind === AnalyticTypes.SectionKind.KeyValue
            ? section.rules.reduce((sectionValue, rule) => {
                let fieldName = rule.field || '';
                let modifier = rule.modifier === AnalyticTypes.SpecialSigmaModifier.Equal ? '' : '|' + rule.modifier;
                if (rule.all === BooleanString.True && rule.values?.length > 1) {
                  modifier += '|' + AnalyticTypes.SpecialSigmaModifier.All;
                }
                fieldName = fieldName + modifier;
                return {
                  ...sectionValue,
                  [fieldName]: !rule.values.length ? '' : rule.values.length === 1 ? rule.values[0] : rule.values
                };
              }, {})
            : section.kind === AnalyticTypes.SectionKind.Strings
            ? section.values
            : { '': '' }
      };
    }, initialTransformedDetection);
    return transformedDetection;
  } else {
    return initialTransformedDetection;
  }
}

interface InterpolateYamlTemplateGetters {
  getStringValue: (field: string) => string;
  getArrayValue: (field: string) => string[];
  getMultiLineValue: (field: string) => string;
  getTagsValue: () => string[];
}

function createGetters(values: AnalyticTypes.AnalyticForm): InterpolateYamlTemplateGetters {
  return {
    getStringValue(field: string): string {
      return get(values, field) || '';
    },
    getArrayValue(field: string): string[] {
      return get(values, field);
    },
    getMultiLineValue(field: string): string {
      const val = get(values, field, '');
      if (val.endsWith('\n')) return val;
      return val + '\n';
    },
    getTagsValue(): string[] {
      const tags: string[] = [];
      if (values.attack_names?.length > 0) tags.push(...values.attack_names.flatMap(t => t['sigma_names']));
      if (values.actor_names?.length > 0) tags.push(...values.actor_names.flatMap(t => t['sigma_names']));
      if (values.software_names?.length > 0) tags.push(...values.software_names.flatMap(t => t['sigma_names']));
      if (values.vulnerability_names?.length > 0)
        tags.push(...values.vulnerability_names.flatMap(t => t['sigma_names']));
      return tags.length > 0 ? tags : undefined;
    }
  };
}

export function stripModifiers(fieldNameWithMods: string): {
  all: BooleanString;
  fieldName: string;
  modifier: AnalyticTypes.SigmaModifier | AnalyticTypes.SpecialSigmaModifier.Equal;
} {
  const modifierValues = Object.values(AnalyticTypes.SigmaModifier);
  const allValidValues = [...modifierValues, AnalyticTypes.SpecialSigmaModifier.All];

  const [fieldName, ...modifiers] = fieldNameWithMods.split('|');

  // modifier validation
  if (modifiers.length > 2) {
    throw new UnsupportedModifierError();
  }

  const lowerMods = modifiers.map(m => m.toLowerCase());

  lowerMods.forEach((mod: AnalyticTypes.SigmaModifier, idx) => {
    if (!allValidValues.includes(mod)) {
      throw new UnsupportedModifierError(mod);
    }

    if (modifierValues.includes(mod)) {
      const conflictingMod = modifiers.find(
        (m, i) => i !== idx && modifierValues.includes(m.toLowerCase() as AnalyticTypes.SigmaModifier)
      );
      if (conflictingMod) {
        throw new UnsupportedModifierError(mod, conflictingMod);
      }
    }
  });

  return {
    all: lowerMods.includes(AnalyticTypes.SpecialSigmaModifier.All) ? BooleanString.True : BooleanString.False,
    fieldName,
    modifier:
      (lowerMods.find(m => m !== AnalyticTypes.SpecialSigmaModifier.All) as AnalyticTypes.SigmaModifier) ||
      AnalyticTypes.SpecialSigmaModifier.Equal
  };
}

export function checkRequiredFieldsForTranslate(analytic): boolean {
  const sections = get(analytic, 'detection.sections') || [];
  const hasFields =
    sections.length &&
    sections.every(section => {
      const rules = section.rules || [];
      const values = section.values || [];
      return (rules.length && rules.every(rule => !!rule.field && rule.values.length)) || values.length;
    });
  return hasFields;
}

interface LanguageMeta {
  pattern: RegExp;
  filetype: string;
}

const TRANSLATION_LANGUAGE_RULES: LanguageMeta[] = [
  { pattern: /aws select/i, filetype: 'sql' },
  { pattern: /carbonblack/i, filetype: 'carbonblack' },
  { pattern: /elastic/i, filetype: 'elastic' },
  { pattern: /hx/i, filetype: 'hx' },
  { pattern: /sigma/i, filetype: 'sigma' },
  { pattern: /splunk/i, filetype: 'splunk' },
  { pattern: /pyhal/i, filetype: 'py' },
  { pattern: /snapattack/i, filetype: 'splunk' }
];

export function getFileType(translateTarget: AnalyticTypes.Language): string {
  if (!translateTarget) return 'txt';
  for (const { pattern, filetype } of TRANSLATION_LANGUAGE_RULES) {
    const match = pattern.exec(translateTarget.name);
    if (!match) continue;
    return filetype;
  }
  return 'txt';
}

export function emptySection(
  sectionNumber: number,
  logsource?: AnalyticTypes.AnalyticForm['logsource'],
  sectionName?: string
): AnalyticTypes.SectionForm {
  const hasEventID = isWinEvent(logsource);
  return {
    ...EMPTY_SECTION,
    name: sectionName ?? `Section_${sectionNumber}`,
    rules: hasEventID ? [{ ...EMPTY_SECTION.rules[0], field: 'EventID' }] : [{ ...EMPTY_SECTION.rules[0] }]
  };
}

export function defaultDetection(
  logsource?: AnalyticTypes.AnalyticForm['logsource'],
  sectionName?: string
): AnalyticTypes.DetectionForm {
  const section = emptySection(1, logsource, sectionName);
  return {
    condition: section.name,
    sections: [section]
  };
}

export function defaultFprDetection(logsource?: AnalyticTypes.AnalyticForm['logsource']): AnalyticTypes.DetectionForm {
  const detection = defaultDetection(logsource);
  detection.sections[0].name = `Custom_${detection.sections[0].name}`;
  detection.condition = `not ${detection.sections[0].name}`;
  return detection;
}

export function isDefaultFPRDetection(
  detection: AnalyticTypes.DetectionForm,
  logsource?: AnalyticTypes.AnalyticForm['logsource']
) {
  return isEqual(defaultFprDetection(logsource), { ...detection, condition: detection?.condition.trim() });
}

export function newRuleFromKeyValue(field: string, value: string): AnalyticTypes.KeyValueRule {
  return { ...EMPTY_KEY_VALUE_RULE, field, values: [value] };
}

export function appendValueToRule(rule: AnalyticTypes.KeyValueRule, value: string): void {
  /* MUTATION WARNING!: this modifies the rule in place */
  if (last(rule.values) === '') {
    rule.values = [...take(rule.values, rule.values.length - 1), value];
  } else {
    rule.values.push(value);
  }
}

function isValidValue(field: any): boolean {
  return field !== null && field !== undefined && field !== '';
}

const DEFAULT_RAW_DETECTION = yaml.dump({ detection: transformDetection({ detection: defaultDetection() }) });
const DEFAULT_FPR_DETECTION = yaml.dump({
  detection: transformDetection({ detection: defaultFprDetection() })
});

export function getFprPayloadValue(fprRaw: string): string | void {
  if (!fprRaw || fprRaw === DEFAULT_RAW_DETECTION || fprRaw === DEFAULT_FPR_DETECTION) return;
  return fprRaw;
}

export function isWinEvent(logsource?: AnalyticTypes.AnalyticForm['logsource']): boolean {
  // by convention: sysmon logsources use `category`, windows event logsources use `service`
  return logsource?.product === 'windows' && !!logsource?.service && logsource.service !== 'sysmon';
}
