import React from 'react';

import { faStar } from '@fortawesome/pro-solid-svg-icons';

import Icon from 'snap-ui/Icon';

function AttackIcon(): React.ReactElement {
  return <Icon icon={faStar} color='error' />;
}

export default AttackIcon;
