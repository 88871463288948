import { useEffect } from 'react';

import { getEvent } from 'module/Detection';

import { useAsync } from 'storage';

import { MarkerEvent } from 'types/marker';

const EVENT_SHELL: MarkerEvent = {
  id: 0,
  host_id: 0,
  raw: '',
  source: '',
  sigma_product: '',
  sigma_service: '',
  sigma_category: '',
  timestamp: '',
  process_id: '',
  host: {} as any,
  row_id: ''
};

export default function useEventLog(eventIdentifier: string) {
  const { data: event, run, status, setData, error } = useAsync<MarkerEvent>(EVENT_SHELL);
  useEffect(() => {
    if (!eventIdentifier) {
      setData(EVENT_SHELL);
    } else {
      run(getEvent(eventIdentifier));
    }
  }, [eventIdentifier, run, setData]);

  return {
    event,
    status,
    update: setData,
    error
  };
}
