import React from 'react';

import { FormDialog } from 'snap-ui/Dialog';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { CommonEvent, Engage, Fingerprint } from 'lib/Engagement';

import { standardFormikBaseProps } from 'module/Form';
import TextFieldFormik from 'module/Form/TextFieldFormik';

import { APPLIED_TO_DEFAULT } from './Metadata.const';
import { MetadataFieldInput, MetadataSchema, Operation } from './Metadata.type';
import { buildSchemasFromInput, convertNameToIdentifier } from './Metadata.util';
import { AppliedToFormik, OptionsFormik, TypeFormik } from './Metadata.widgets';
import { MetadataPreview } from './MetadataPreview';

const Content = styled('div')`
  max-width: 100%;
  padding: ${p => p.theme.spacing(3)};
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(6)};

  .Content-appliedPreview {
    margin-top: ${p => p.theme.spacing(5)};
    display: flex;
    gap: ${p => p.theme.spacing(4)};
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .Content-applied {
    flex: 0.45;
    margin-top: ${p => p.theme.spacing(3)};
    fieldset {
      width: 100%;
    }
  }

  .Control-openselect,
  .Control-select,
  .Control-multiselect {
    min-width: 200px;
  }

  .group-layout {
    padding: ${p => p.theme.spacing(5)};
    background-color: ${p => p.theme.palette.background.default};
    position: absolute;
    top: 0;
    left: 55%;
    height: 100%;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export function MetadataBuilder(props: {
  blacklist?: string[];
  onSubmit(payload: MetadataSchema): void;
  isOpen: boolean;
  onClose(): void;
}) {
  const handleSubmit = (values: MetadataFieldInput) => {
    const payload: MetadataSchema = {
      ...buildSchemasFromInput(values),
      migrations: [
        {
          operation: Operation.FieldAdd,
          field: convertNameToIdentifier(values.label)
        }
      ]
    };

    Engage.track(
      Fingerprint.of(Path.Meta).withCommon(CommonEvent.Submit).withQualifier('add field control').withData(payload)
    );
    props.onSubmit(payload);
  };

  React.useEffect(() => {
    if (props.isOpen) Engage.track(Fingerprint.of(Path.Meta).withQualifier('add field dialog').pageLoad());
  }, [props.isOpen]);

  if (!props.isOpen) return null;

  return (
    <FormDialog
      title='Metadata - Add Field'
      DialogProps={{
        open: true,
        onClose: props.onClose
      }}
      SubmitProps={{
        children: 'Save'
      }}
      FormikConfig={{
        ...standardFormikBaseProps,
        validateOnChange: true,
        initialValues: {
          label: '',
          description: '',
          type: '',
          applied: APPLIED_TO_DEFAULT,
          options: [],
          _blacklist: props.blacklist
        },
        zodSchema: MetadataFieldInput,
        onSubmit: handleSubmit
      }}
    >
      {({ values, resetForm }) => {
        const handleOnTypeChange = (type: string) => {
          resetForm({
            values: {
              type: type,
              label: values.label,
              description: '',
              applied: APPLIED_TO_DEFAULT,
              options: [],
              _blacklist: props.blacklist
            }
          });
        };
        return (
          <Content>
            <TypeFormik onChange={handleOnTypeChange} />
            <TextFieldFormik
              name='label'
              label='Field Name'
              required
              helperText='A name like FooBar or Foo Bar'
              disabled={!values.type}
            />
            <OptionsFormik />
            <div className='Content-appliedPreview'>
              <AppliedToFormik name='applied' />
              <MetadataPreview key={`${values.type}-${values.label}`} />
            </div>
          </Content>
        );
      }}
    </FormDialog>
  );
}
