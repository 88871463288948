import React from 'react';

import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';

import Alert from 'snap-ui/Alert';
import Icon from 'snap-ui/Icon';
import { ErrorTooltip } from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import Path from 'constants/paths';

import { ApiError, ErrorProps } from 'module/ApiError';

import { renderValueWithLabel } from 'utilities/TextUtils';

const Error = styled(ApiError)`
  border: none;
`;

const Trigger = styled(Alert)`
  align-items: center;
  height: 100%;

  & > .MuiAlert-message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

function ApiErrorPopper(props: ErrorProps): React.ReactElement {
  return (
    <ErrorTooltip title={<Error {...props} />} arrow>
      <Trigger className='ErrorList status-alert' severity='error'>
        {renderValueWithLabel(props.messages.length, 'error')}
      </Trigger>
    </ErrorTooltip>
  );
}

export function TargetSigmaMessage() {
  return (
    <Typography variant='body2'>
      You can update this language target or its Sigma configuration(s) from the{' '}
      <Link to={Path.LanguageConfig} target='blank' rel='noopener'>
        Detection Language <Icon icon={faArrowUpRightFromSquare} />
      </Link>{' '}
      settings page.
    </Typography>
  );
}

export default ApiErrorPopper;
