import { styled } from 'snap-ui/util';

export const Container = styled('div')`
  margin-bottom: ${p => p.theme.spacing(9)};

  & .MuiTypography-h1 {
    margin-bottom: ${p => p.theme.spacing(4)};
  }

  .contents {
    min-width: 800px;
    max-width: 1400px;
    margin-top: ${p => p.theme.spacing(4)};

    .table-container {
      .table-toolbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: ${p => p.theme.spacing(2)};

        & .search-field {
          width: 250px;
        }
      }

      .groups ul,
      .rights ul {
        list-style: none;
        margin: ${p => p.theme.spacing(2)};
        padding: 0;
      }

      .actions-column {
        display: flex;
        justify-content: center;
      }
    }
  }
`;
