import React from 'react';

import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import { ActionIconButton } from 'snap-ui/Button';
import Tooltip from 'snap-ui/Tooltip';

import JobOutcome from '../JobOutcomes';
import { LabelContainer } from '../JobOutcomes/JobOutcomes.style';
import { usePaginateModal } from '../JobOutcomes/PaginateModal';
import { ColumnComponentProps } from './common';

type OutcomeColumnProps = ColumnComponentProps;

export default function OutcomeColumn({ job, print }: OutcomeColumnProps): JSX.Element {
  const { setOpenId } = usePaginateModal();

  function handleClick() {
    setOpenId(job.guid);
  }

  return (
    <LabelContainer onClick={handleClick}>
      <JobOutcome job={job} print={print} />
      {!print && (
        <Tooltip arrow className='add-outcome-wrapper' placement='top' title='Add/Edit Outcomes' wrap>
          <ActionIconButton aria-label='Add or Edit Outcomes' icon={faPlus} onClick={handleClick} />
        </Tooltip>
      )}
    </LabelContainer>
  );
}
