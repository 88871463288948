import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { DetectionSummary, getAnalyticDetectionCount } from 'module/Detection';

import { Status, useAsync } from 'storage';

import { Guid } from 'types/common';

export interface AnalyticDetectionSummaryState {
  summary: DetectionSummary & { total: number };
  status: Status;
  error: any;
}

export default function useAnalyticDetectionSummary(
  analyticGuid: Guid,
  count?: DetectionSummary
): AnalyticDetectionSummaryState {
  const { data, run, status, error, setData } = useAsync<DetectionSummary>();

  React.useEffect(() => {
    if (analyticGuid && isEmpty(count)) {
      run(getAnalyticDetectionCount(analyticGuid));
    } else {
      setData(count);
    }
  }, [analyticGuid, count, run, setData]);

  return {
    summary: { ...data, total: data?.validated + data?.validated_gaps + data?.unvalidated || 0 },
    status,
    error
  };
}
