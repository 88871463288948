import React from 'react';

import zod from 'zod';

import { Option } from 'snap-ui/Autocomplete';

import useNist from 'module/Dashboard/NistDashboard/useNist';

import { ArtifactType } from 'types/common';
import { Ops } from 'types/filter';

import { compareAlphaNumericStrings } from 'utilities/SortUtils';

import { FilterConfig } from '../GlobalFilter.type';
import InclusiveAutocomplete from './InclusiveAutocomplete';

type NistActionKeys = {
  actions: string[];
  actionsOp: Ops;
};

type NistActionProps = {
  onChange(values: Partial<NistActionKeys>): void;
  values: NistActionKeys;
};

function NistAction({ onChange, values }: NistActionProps): React.ReactElement {
  const { nistTags } = useNist();

  const nistOptions = nistTags
    .map(nist => ({
      key: nist.tag_id,
      content: `${nist.tag_name}: ${nist.detail.name}`,
      value: nist.detail.name
    }))
    .sort((a, b) => compareAlphaNumericStrings(a.content, b.content));

  const handleValueChange = (option: Option[]) => {
    const payload = { actions: option.map(o => o.value) };
    if (option.length === 0) payload['actionsOp'] = undefined;
    onChange(payload);
  };

  const handleOperatorChange = (actionsOp: Ops) => {
    onChange({ actionsOp });
  };

  return (
    <InclusiveAutocomplete
      title='By NIST 800-53 Control'
      name='actions_tags_dropdown'
      option={nistOptions}
      value={values.actions}
      onValueChange={handleValueChange}
      onOperatorChange={handleOperatorChange}
      operator={values.actionsOp}
    />
  );
}

function toQuery(values: NistActionKeys) {
  if (!values.actions?.length) return;
  return {
    field: 'action_names',
    op: values.actionsOp,
    value: values.actions
  };
}

const fromQuery = zod
  .object({
    field: zod.literal('action_names'),
    op: zod.nativeEnum(Ops),
    value: zod.array(zod.string())
  })
  .transform(query => ({
    actions: query.value,
    actionsOp: query.op
  }));

const NistActionFilterConfig: FilterConfig<NistActionKeys> = {
  defaults: { default: () => ({ actions: [], actionsOp: Ops.any }) },
  supportedTopics: [ArtifactType.Analytic],
  component: NistAction,
  toQuery: { default: toQuery },
  fromQuery: { default: fromQuery }
};
export default NistActionFilterConfig;
