import React from 'react';

import { Glossary, MenuOrdinal } from '../Scaffold.type';
import ListItemsValidate from './ListItemsValidate';
import ToolbarItem from './ToolbarItem';

type Props = {
  onClick(id: string, element: HTMLElement): void;
  onClose(): void;
  glossary: Glossary;
};

export default function ToolbarItemValidate({ glossary, onClick, onClose }: Props) {
  return (
    <ToolbarItem
      anchorEl={glossary[MenuOrdinal.Validate]}
      onClick={onClick}
      onClose={onClose}
      open={Boolean(glossary[MenuOrdinal.Validate])}
      ordinal={MenuOrdinal.Validate}
      title='Validate'
      testId='menu-validate'
    >
      <ListItemsValidate onClose={onClose} />
    </ToolbarItem>
  );
}
