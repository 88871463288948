import React from 'react';

import classNames from 'classnames';

import Pagination, { PaginationProps } from 'snap-ui/Pagination/Pagination';
import Paper from 'snap-ui/Paper';
import { styled } from 'snap-ui/util';

type Props = PaginationProps & {
  className?: string;
};

const Container = styled(Paper)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${p => p.theme.spacing(4)};

  &.with-grid {
    border: 0;
    margin: 0;
    border-top: 1px solid ${p => p.theme.palette.divider};
    padding-right: ${p => p.theme.spacing(9)};
  }
`;

export default function TablePagination(props: Props) {
  return (
    <Container className={classNames('TablePagination', props.className)} elevation={8}>
      <Pagination {...props} />
    </Container>
  );
}
