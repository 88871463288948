import React from 'react';

import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';

import { ActionIconButton, ButtonProps } from 'snap-ui/Button';
import {
  GridRenderCellParams,
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridSelector
} from 'snap-ui/DataGrid';
import { styled } from 'snap-ui/util';

import { Attack } from 'module/Tag';

import { LandingLink, LandingRootName, LandingTarget } from '../Landing.widgets';

const IDCellContainer = styled('div')`
  display: flex;
  justify-content: center;
  gap: ${p => p.theme.spacing(2)};
  align-items: center;

  &.offset {
    margin-left: ${p => p.theme.spacing(2)};
  }
`;

/**
 * Yoink
 * https://mui.com/x/react-data-grid/tree-data/#custom-grouping-column
 */
export function MitreIDGroupingCell(
  props: GridRenderCellParams & { onClick: (e: React.MouseEvent) => void }
): JSX.Element {
  const { id, field, rowNode, row, onClick } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick: ButtonProps['onClick'] = (event: React.MouseEvent) => {
    if (rowNode.type !== 'group') {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <IDCellContainer className={rowNode.depth ? 'offset' : ''}>
      {filteredDescendantCount > 0 && rowNode.type === 'group' ? (
        <>
          <LandingTarget asId data={row} />
          <ActionIconButton
            aria-label='Toggle Subtechniques'
            icon={rowNode.childrenExpanded ? faCaretDown : faCaretUp}
            onClick={handleClick}
          />
        </>
      ) : (
        <LandingLink asId onClick={onClick} data={row} />
      )}
    </IDCellContainer>
  );
}

export function MitreNameColumn(source: Attack, onClick: (e: React.MouseEvent) => void) {
  return {
    field: 'name',
    headerName: 'Name',
    groupable: false,
    aggregable: false,
    renderCell: cell => <LandingRootName sourceId={source.external_source_id} onClick={onClick} data={cell.row} />,
    flex: 1
  };
}
