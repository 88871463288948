import React from 'react';

import { isLandingStatsType } from 'module/Landing/Landing.type';
import { useMayI } from 'module/May';
import ReferenceCounts from 'module/Widgets/ReferenceCounts';

import { useFeedExtra } from 'provider';
import { useAuth } from 'provider/Account';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { Artifact } from 'types/common';

import { PosturePlaceholder } from '../Layout/Placeholders';

export default function CollectionCounter({ item }: { item: Artifact }): JSX.Element {
  const { getCounts, getCountsStatus } = useFeedExtra();
  const counts = getCounts(item);
  const countsPending = getCountsStatus(item) === Status.pending;

  const isBasUser = useMayI(FunctionalPermission.BASStableFeatures);
  const { isSubscriber } = useAuth();

  if (countsPending) return <PosturePlaceholder className='CardCounter' showTotal={false} />;
  return (
    <div className='CardCounter'>
      <ReferenceCounts
        artifactType={item.type}
        analytics={counts?.analytics}
        attackScripts={isBasUser ? counts?.bas_scripts : undefined}
        indicators={isSubscriber && isLandingStatsType(counts) ? counts?.indicators : undefined}
        intels={counts?.threat_intelligences}
        sessions={counts?.sessions}
      />
    </div>
  );
}
