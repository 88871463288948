import React from 'react';

import { faGearComplexCode } from '@fortawesome/pro-solid-svg-icons';

import { BurgerClicker } from 'module/Layout/Artifact.widgets';

import { useAuth } from 'provider/Account';

import { checkTaskPermission } from 'services/authService';

import { FunctionalPermission } from 'types/auth';

import { AddAttackScriptToAnalytic } from './AddAttackScriptToAnalytic';

type Button = Partial<BurgerClicker>;
type Dialog = Omit<AddAttackScriptToAnalytic, 'isOpen' | 'onClose'>;

type AddAttackScriptToAnalyticContext = {
  isOpen: boolean;
  setIsOpen(open: boolean): void;
};

const AddAttackScriptToAnalyticContext = React.createContext<AddAttackScriptToAnalyticContext>(null);
AddAttackScriptToAnalyticContext.displayName = 'AddAttackScriptToAnalyticContext';

function AddAttackScriptToAnalyticProvider({ children }: React.PropsWithChildren<Record<never, never>>): JSX.Element {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <AddAttackScriptToAnalyticContext.Provider
      value={{
        isOpen,
        setIsOpen
      }}
    >
      {children}
    </AddAttackScriptToAnalyticContext.Provider>
  );
}

function useAddAttackScriptToAnalytic(): AddAttackScriptToAnalyticContext {
  const context = React.useContext(AddAttackScriptToAnalyticContext);
  if (!context) {
    throw new Error('useAddAttackScriptToAnalytic must be used within an AddAttackScriptToAnalyticContext');
  }
  return context;
}

function AddAttackScriptToAnalyticButton({ onClick, ...others }: Button): JSX.Element {
  const { setIsOpen } = useAddAttackScriptToAnalytic();
  const { permission } = useAuth();
  const notAllowed = !checkTaskPermission(permission, FunctionalPermission.CreateAnalytic);

  return (
    <BurgerClicker
      title='Link to Attack Script'
      icon={faGearComplexCode}
      onClick={() => {
        setIsOpen(true);
        if (onClick) onClick();
      }}
      {...others}
      disabled={notAllowed}
      TooltipProps={{
        title: notAllowed ? 'You do not have permission to add to a detection.' : ''
      }}
    />
  );
}

function AddAttackScriptToAnalyticDialog(props: Dialog) {
  const { isOpen, setIsOpen } = useAddAttackScriptToAnalytic();

  return <AddAttackScriptToAnalytic isOpen={isOpen} onClose={() => setIsOpen(false)} {...props} />;
}

export { AddAttackScriptToAnalyticButton, AddAttackScriptToAnalyticDialog, AddAttackScriptToAnalyticProvider };
