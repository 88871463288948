import React from 'react';

import Grid from 'snap-ui/Grid';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

type Props = {
  className?: string;
  children: React.ReactNode;
  actions?: React.ReactNode;
  title?: React.ReactNode;
};

const Container = styled('div')`
  flex-grow: 1;
  width: 100vw;
  max-width: 1400px;
  margin: 0 auto;
  padding: ${p => p.theme.spacing(4)} ${p => p.theme.spacing(7)} ${p => p.theme.spacing(4)} ${p => p.theme.spacing(7)};

  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: ${p => p.theme.spacing(2)};
  }
`;

function DashboardGrid({ className, title, children, actions }: Props) {
  return (
    <Container className={className}>
      <div className='header'>
        {title && (
          <Typography variant='h2' className='dashboard-title'>
            {title}
          </Typography>
        )}
        {actions && actions}
      </div>
      <Grid container spacing={3} className='dashboard-contents'>
        {children}
      </Grid>
    </Container>
  );
}

export default DashboardGrid;
