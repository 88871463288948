import React from 'react';

import { useHistory } from 'react-router-dom';

import Paper from 'snap-ui/Paper';
import Typography from 'snap-ui/Typography';

import Path from 'constants/paths';

import { CollectionRecommenderType } from 'module/Curation/Curation.type';
import CurationFeed from 'module/Curation/CurationFeed';
import { ArtifactWidgetFooter, FooterDetail } from 'module/Layout/Artifact.widgets';
import { MetadataView } from 'module/Metadata';
import PayWall from 'module/PayWall';
import { useFilterSidebar } from 'module/Scaffold/useStashSidebar';
import { AddTagToThreatProfile } from 'module/ThreatProfile';
import NotFound from 'module/Util/Fallback/NotFound';
import OverviewTab, { Subtitle } from 'module/Widgets/OverviewTab';

import { useAuth } from 'provider';
import { RecommenderProvider } from 'provider/Recommender/RecommenderProvider';

import { Status } from 'storage';

import { ArtifactType } from 'types/common';

import { AttackFamily } from './AttackFamily';
import Disambiguate from './Disambiguate';
import { StyledArtifact } from './Landing.style';
import { artifactToEndpointFragment } from './Landing.util';
import { AliasOf, LandingMenu, LandingTitle } from './Landing.widgets';
import { useLandingCatalog } from './LandingProvider';
import { CommonTags } from './LandingTags';
import { MandiantLogoLink } from './MandiantLogo';
import { LandingRecommender } from './RecommendationPanel/Recommender';
import { ActorDetail, TargetIndustries, TargetRegions } from './core/Actor';
import { AttackSubheader } from './core/Attack';
import { SoftwareDetail, SoftwareSubheader, TargetCapabilities } from './core/Software';
import {
  Exploits,
  VendorRemediationGuide,
  VulnerabilityContentDetails,
  VulnerabilityDetail,
  VulnerabilitySoftwareVersion
} from './core/Vulnerability';

export default function Landing() {
  useFilterSidebar(ArtifactType.Analytic, false);
  const { replace } = useHistory();

  const { name, type, data, status, collection } = useLandingCatalog();
  const newATTACKVersionName = data?.items?.[0]?.redirect;

  React.useEffect(() => {
    if (newATTACKVersionName) {
      replace(Path.Collection + '/attack/' + newATTACKVersionName);
    }
  }, [newATTACKVersionName, replace]);

  if (status === Status.rejected) {
    return <NotFound artifact={type} error={{ response: 'landing not found', tag: name }} />;
  }

  return (
    <RecommenderProvider guid={collection.name} type={type as CollectionRecommenderType}>
      <StyledArtifact
        isPending={[Status.pending, Status.idle].includes(status) && !data.combined.id}
        type={type}
        meta={<LandingHeader />}
      >
        <LandingContent />
      </StyledArtifact>
    </RecommenderProvider>
  );
}

function LandingHeader() {
  return (
    <>
      <LandingTitle />
      <AddTagToThreatProfile />

      <AliasOf className='Landing-alias' linkable />
      <AttackSubheader />
      <SoftwareSubheader />

      <ArtifactWidgetFooter separation>
        <FooterDetail>
          <MandiantLogoLink />
          <VulnerabilityDetail />
          <SoftwareDetail />
          <ActorDetail />
        </FooterDetail>
        <LandingMenu />
      </ArtifactWidgetFooter>
    </>
  );
}

function LandingContent() {
  const { isAmbiguous } = useLandingCatalog();
  if (isAmbiguous) return <Disambiguate />;

  return (
    <>
      <LandingRecommender />
      <LandingFeed />
    </>
  );
}

function LandingOverview() {
  const { isSubscriber } = useAuth();
  useFilterSidebar(ArtifactType.Analytic, false);

  const { name, type } = useLandingCatalog();

  return (
    <>
      {/* Common across all landing pages */}
      <CommonTags />

      {/* ATT&CK only */}
      <AttackFamily />

      {/* Vulnerabilities only */}
      <VulnerabilityContentDetails />
      <VulnerabilitySoftwareVersion />
      <VendorRemediationGuide />
      <Exploits />

      {/* Actor + Software */}
      <TargetIndustries />

      {/* Actor only */}
      <TargetRegions />

      {/* Software only */}
      <TargetCapabilities />

      {/* Common across all landing pages */}
      <MetadataView type={ArtifactType.Landing} guid={`${artifactToEndpointFragment(type)}/${name}`} />
      {!isSubscriber && <PayWall mandiantContent />}
    </>
  );
}

function LandingFeed() {
  useFilterSidebar(ArtifactType.Analytic, false);

  const { data, source, collection } = useLandingCatalog();

  return (
    <CurationFeed
      guid={collection.guid}
      collection={collection}
      hyperTag={data.combined}
      infoLabel='Details'
      infoContent={
        <Paper>
          <OverviewTab
            description={source.description}
            title={source.external_source}
            subtitle={
              <Subtitle>
                <Typography>{source.mitre_id}</Typography>
              </Subtitle>
            }
          >
            <LandingOverview />
          </OverviewTab>
        </Paper>
      }
    />
  );
}
