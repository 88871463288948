import React from 'react';

import { Cron, CronProps, CronType, PeriodType } from 'react-js-cron';

import { DEFAULT_PERIOD } from 'module/Job/Job.const';

import { CronFormContainer } from './CronForm.style';

type AdditionalProps = {
  selectedPeriod?: PeriodType;
};

type CronConfig = {
  allowedDropdowns: CronType[];
  monthsAllowedDropdowns: CronType[];
  allowedPeriods: PeriodType[];
};

const cronConfig: CronConfig = {
  allowedDropdowns: ['period', 'months', 'month-days', 'week-days', 'hours'],
  monthsAllowedDropdowns: ['period', 'months', 'month-days', 'hours'],
  allowedPeriods: ['month', 'week', 'day']
};

function CronForm({ className, selectedPeriod, ...props }: CronProps & AdditionalProps) {
  return (
    <CronFormContainer className={className}>
      <Cron
        // dropdownsConfig DOES NOT work with Formik can causes a re-render loop
        // https://github.com/xrutayisire/react-js-cron/issues/67
        allowedDropdowns={selectedPeriod === 'month' ? cronConfig.monthsAllowedDropdowns : cronConfig.allowedDropdowns}
        allowedPeriods={cronConfig.allowedPeriods}
        mode={'multiple'}
        periodicityOnDoubleClick={false}
        clockFormat='12-hour-clock'
        defaultPeriod={DEFAULT_PERIOD}
        className='cron-container'
        allowClear={false}
        clearButton={false}
        allowEmpty='never'
        {...props}
      />
    </CronFormContainer>
  );
}

export default CronForm;
