import Path from 'constants/paths';

import { ContentPermission, FunctionalPermission } from 'types/auth';
import { ArtifactType } from 'types/common';

import { CommonEvent, NotableEvent, Widget } from './Engagement.type';

export default class Fingerprint {
  private type: ArtifactType;
  private functional: FunctionalPermission;
  private content: ContentPermission;
  private load: boolean;
  private notable: NotableEvent;
  private common: CommonEvent;
  private qualifier: string;
  private error: boolean;
  private data: object;

  static of(path: Path | Widget | NotableEvent.NotFound | NotableEvent.Feedback | NotableEvent.ExternalLink) {
    return new Fingerprint(path);
  }

  static load(path: Path) {
    return new Fingerprint(path).pageLoad();
  }

  static error(path: Path | Widget) {
    return new Fingerprint(path).asError();
  }

  private constructor(
    private path: Path | Widget | NotableEvent.NotFound | NotableEvent.Feedback | NotableEvent.ExternalLink
  ) {}

  pageLoad() {
    this.load = true;
    return this;
  }

  click() {
    this.common = CommonEvent.Click;
    return this;
  }

  open() {
    this.common = CommonEvent.Open;
    return this;
  }

  asError() {
    this.error = true;
    return this;
  }

  withArtifact(type: ArtifactType) {
    this.type = type;
    return this;
  }

  withFunctional(functional: Exclude<FunctionalPermission, FunctionalPermission.All>) {
    this.functional = functional;
    return this;
  }

  withContent(content: Exclude<ContentPermission, ContentPermission.All>) {
    this.content = content;
    return this;
  }

  withNotable(notable: Exclude<NotableEvent, NotableEvent.Error | NotableEvent.NotFound | NotableEvent.PageLoad>) {
    this.notable = notable;
    return this;
  }

  withCommon(common: CommonEvent) {
    this.common = common;
    return this;
  }

  withQualifier(qualifier: string) {
    this.qualifier = qualifier;
    return this;
  }

  withData(data: object) {
    this.data = data;
    return this;
  }

  get name(): string {
    let result = this.path as string;
    if (this.load) result = result.concat(` ${NotableEvent.PageLoad}`);
    if (this.functional) result = result.concat(` ${this.functional}`);
    if (this.content) result = result.concat(` ${this.content}`);
    if (this.common) result = result.concat(` ${this.common}`);
    if (this.notable) result = result.concat(` ${this.notable}`);
    if (this.qualifier) result = result.concat(` ${this.qualifier}`);
    if (this.error) result = result.concat(` ${NotableEvent.Error}`);
    return result;
  }

  get dict(): object {
    /* We need to spread this.data because the mixpanel client modifies the dict.
     * So when we track the payload to POST /api/sessions/ for example,
     * it gets an extra field token added to it, causing a 422.
     */
    if (this.type && this.data) return { ...this.data, type: this.type };
    if (this.type) return { type: this.type };
    return { ...this.data };
  }
}
