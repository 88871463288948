import React, { ReactElement } from 'react';

import { faBars, faCloudArrowDown, faExternalLink } from '@fortawesome/pro-regular-svg-icons';

import BackdropLoader from 'snap-ui/BackdropLoader';
import Icon from 'snap-ui/Icon';
import SpeedDial from 'snap-ui/SpeedDial';
import { styled } from 'snap-ui/util';

import { ArtifactType } from 'types/common';

import { Matrix } from './Matrix.type';
import Tactic from './Tactic';
import Technique from './Technique';
import useMatrixOptions from './useMatrixOptions';

const Container = styled('div')`
  width: calc(100vw - 40px);
  height: calc(100vh - 65px);
  overflow: auto;
  overscroll-behavior-x: none;

  a {
    display: inline-block;
    width: 100%;
    color: ${p => p.theme.palette.common.white};
  }

  .brighten:hover {
    filter: brightness(1.25);
  }
`;

const TableWrapper = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: ${p => p.theme.spacing(4)};
`;

type Props = {
  loading: boolean;
  matrix: Matrix;
  onExport(): void;
};

const Content = ({ loading, matrix, onExport }: Props): ReactElement => {
  const topic = ArtifactType.Analytic;
  const { maxRange, palette, heatMap, perBucket, showCount, showMitreId, showSubtechniques, showEmpty, showPreAttack } =
    useMatrixOptions();
  const [isSpeedDialOpen, setIsSpeedDialOpen] = React.useState(true);

  return (
    <>
      <SpeedDial
        ariaLabel='Matrix quick actions'
        direction='right'
        open={isSpeedDialOpen}
        sx={{ position: 'fixed', bottom: 16, left: '60px' }}
        icon={<Icon icon={faBars} />}
        onClick={() => setIsSpeedDialOpen(v => !v)}
      >
        <SpeedDial.Action
          arrow
          icon={<Icon icon={faCloudArrowDown} />}
          tooltipTitle='Export ATT&CK Navigator Json'
          onClick={onExport}
        />
        <SpeedDial.Action
          arrow
          icon={<Icon icon={faExternalLink} />}
          tooltipTitle='ATT&CK® Navigator'
          onClick={() => {
            window?.open('https://mitre-attack.github.io/attack-navigator/', '_blank');
          }}
        />
      </SpeedDial>
      <Container>
        <TableWrapper>
          {matrix
            .filter(node => (showPreAttack ? true : !['Resource Development', 'Reconnaissance'].includes(node.name)))
            .map(node => (
              <div key={node.name}>
                <Tactic
                  node={node}
                  showMitreId={showMitreId}
                  showEmpty={showEmpty}
                  showCount={showCount}
                  topic={topic}
                  palette={palette}
                  maxRange={maxRange}
                  perBucket={perBucket}
                />
                {node.attack_children?.map(technique => (
                  <Technique
                    key={technique.id}
                    node={technique}
                    showSubtechniques={showSubtechniques}
                    showMitreId={showMitreId}
                    showCount={showCount}
                    showEmpty={showEmpty}
                    topic={topic}
                    palette={palette}
                    heatMap={heatMap}
                    maxRange={maxRange}
                    perBucket={perBucket}
                  />
                ))}
              </div>
            ))}
        </TableWrapper>
        <BackdropLoader title='ATT&CK Matrix loading...' open={loading} contained fixed zIndexOption='backdropLow' />
      </Container>
    </>
  );
};

export default Content;
