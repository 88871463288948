import React from 'react';

import { styled } from 'snap-ui/util';

import { StrictReactNode } from 'types/core';

const Container = styled('div')`
  background-color: ${p => p.theme.palette.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${p => p.theme.spacing(4)};
  color: ${p => p.theme.palette.getContrastText(p.theme.palette.primary.main)};
  position: relative;
  padding: ${p => p.theme.spacing(0, 4)};

  & .svg-inline--fa {
    height: 0.85rem;
  }

  .banner-icon {
    color: ${p => p.theme.palette.getContrastText(p.theme.palette.primary.main)};

    & .svg-inline--fa {
      height: 1rem;
    }
  }

  & a,
  & button {
    max-height: 1.5rem;
    text-decoration: underline;
    color: ${p => p.theme.palette.getContrastText(p.theme.palette.primary.main)};

    :hover {
      color: ${p => p.theme.palette.getContrastText(p.theme.palette.primary.main)};
      text-decoration: none;
    }
  }

  &::selection {
    color: ${p => p.theme.palette.getContrastText(p.theme.palette.primary.main)};
  }
`;

export default function Banner(props: { children: StrictReactNode }) {
  return <Container className='Banner' {...props} />;
}
