import React from 'react';

import { RouteComponentProps } from 'react-router-dom';

import IFrame from 'snap-ui/IFrame';

import useLocationState from 'hooks/useLocationState';

import { DateRange, getInitialDatesByPreset } from 'module/DateRangeSelector';
import { buildRawOSLink } from 'module/Hunt';

type Props = RouteComponentProps<{ analyticGuid: string }>;

function AnalyticTuningRaw(props: Props) {
  const analyticGuid = props.match.params.analyticGuid;
  const locationState = useLocationState<{ dates: DateRange }>();

  const { dates } = locationState || { dates: getInitialDatesByPreset(30) };

  const url = buildRawOSLink(dates.start.toISOString(), dates.stop.toISOString(), analyticGuid, true);

  return <IFrame src={url} />;
}

export default AnalyticTuningRaw;
