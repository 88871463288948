import React from 'react';

import { ArtifactType } from 'types/common';
import { Coverage } from 'types/dashboard';

import Panel from './Panel';
import { Props as PanelProps } from './Panel';

type Props = Partial<PanelProps> & {
  type: ArtifactType.Analytic | ArtifactType.Session;
  className?: 'red' | 'blue' | string;
  technique: Coverage;
  subtechnique: Coverage;
};

function MatrixPanel({ type, className, technique, subtechnique, ...others }: Props) {
  const [footerNoun, matrixImage] = getTypedContent();
  return (
    <Panel
      {...others}
      className={className}
      title='MITRE ATT&CK COVERAGE'
      content={
        <div className='pp-attack-panel'>
          <img src={matrixImage} alt='MITRE ATT&CK Matrix' />
        </div>
      }
      footer={
        <>
          {footerNoun} mapped to {type === ArtifactType.Analytic ? technique.analytics : technique.sessions}/
          {technique.attacks} techniques
          <br />
          {type === ArtifactType.Analytic ? subtechnique.analytics : subtechnique.sessions}/{subtechnique.attacks}{' '}
          subtechniques
        </>
      }
    />
  );

  function getTypedContent() {
    switch (type) {
      case ArtifactType.Analytic:
        return ['Detections', process.env.PUBLIC_URL + '/images/matrix-detections.png'];
      case ArtifactType.Session:
        return ['Threats', process.env.PUBLIC_URL + '/images/matrix-threats.png'];
    }
  }
}

export default MatrixPanel;
