import React from 'react';

import { useField } from 'formik';
import uniq from 'lodash/uniq';
import without from 'lodash/without';

import Checkbox from 'snap-ui/Checkbox';
import FormControlLabel from 'snap-ui/FormControlLabel';

import FormError from 'module/Form/FormError';

type MultiCheckboxFormikProps = {
  label?: string;
  name: string;
  options: {
    text: JSX.Element;
    value: string;
    disabled?: boolean;
  }[];
};

function MultiCheckboxFormik(props: MultiCheckboxFormikProps): React.ReactElement {
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [field, meta, helpers] = useField(props.name);

  React.useEffect(() => {
    if (firstLoad) {
      return setFirstLoad(false);
    }
    // don't do this in the click handler due to race condition in Formik
    helpers.setTouched(true);
  }, [field.value]); // eslint-disable-line

  return (
    <div className='MultiCheckbox'>
      {props.label && <label htmlFor={props.name}>{props.label}</label>}
      {props.options.map(option => (
        <FormControlLabel
          key={option.value}
          name={option.value}
          control={
            <Checkbox
              id={option.value}
              disabled={option.disabled}
              checked={field.value.includes(option.value)}
              onChange={handleChange(option.value)}
            />
          }
          label={option.text}
        />
      ))}
      <FormError error={meta.error} isVisible={!!(meta.touched && meta.error)} />
    </div>
  );

  function handleChange(name: string) {
    return function (_event: React.SyntheticEvent<Element, Event>, checked: boolean): void {
      if (checked) helpers.setValue(uniq([...field.value, name]), true);
      else helpers.setValue(without(field.value, name), true);
    };
  }
}

export default MultiCheckboxFormik;
