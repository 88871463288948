import { cyan, grey } from '@mui/material/colors';
import { createTheme, TypeBackground } from '@mui/material/styles';

import { getSpacing } from './theme.util';

/**
 * Not to be use outside theme
 */
enum Color {
  red = 'hsl(0, 100%, 75%)',
  mitreRed = 'hsl(10.2, 67.1%, 46.5%)', // #c64227, per https://attack.mitre.org/docs/ATTACK_Brand_Guide.pdf
  orange = 'hsl(28, 91%, 68%)',
  yellow = 'hsl(41, 100%, 75%)',
  green = 'hsl(102, 73%, 74%)',
  blue = 'hsl(195, 100%, 50%)',
  purple = 'hsl(282, 73%, 74%)', // from #D08DED (Primary)
  purpleHover = 'hsl(281, 15%, 21%)', // #392e3e
  mint = 'hsl(169, 98%, 68%)', // from #5DFDE0 (Secondary)
  mintHover = 'hsl(169, 98%, 45%)',
  pink = 'hsl(325, 100%, 75%)',
  black = 'hsl(0, 0%, 9%)', // from #171717
  darkest = 'hsl(0, 0%, 16.1%)', // from #282828 (Background)
  darker = 'hsl(0, 0%, 18.4%)', // from #2F2F2F
  dark = 'hsl(0, 0%, 24%)', // from #3D3D3D grey[800]
  gray = 'hsl(0, 0%, 34%)', // from #565656 grey[700] ?? off by 10rgb pts
  light = 'hsl(0, 0%, 44%)', // from #6F6F6F - grey[600]
  lighter = 'hsl(0, 0%, 75%)', // from #BEBEBE - grey[400]
  lightest = 'hsl(0, 0%, 90%)', // from #E6E6E6 - grey[300] is closest, case can be made for [200]
  white = 'hsl(0, 0%, 100%)'
}

const theme = createTheme({
  // Spacing Guide https://mui.com/material-ui/customization/spacing/
  spacing: getSpacing,

  // Color Guide https://mui.com/material-ui/customization/palette/
  palette: {
    action: {
      selected: 'hsla(0, 0%, 100%, 0.12)'
    },
    mode: 'dark',
    common: {
      black: Color.black,
      white: Color.white
    },

    aqua: {
      // For DashboardDetection only
      dark: 'hsl(187, 71%, 49%)',
      light: 'hsl(187, 71%, 69%)',
      main: 'hsl(187, 71%, 59%)',
      contrastText: Color.black
    },
    blue: {
      dark: 'hsl(195, 100%, 40%)',
      light: 'hsl(195, 100%, 69%)',
      main: 'hsl(195, 100%, 50%)',
      contrastText: Color.black
    },
    cyan: {
      dark: cyan[700],
      light: cyan[200],
      main: cyan[500],
      contrastText: Color.black
    },
    cert: {
      dark: '#aa8e00',
      main: '#d4b200',
      light: '#ddc133',
      contrastText: Color.black
    },
    error: {
      dark: 'hsl(0, 100%, 70%)',
      main: 'hsl(0, 100%, 75%)'
    },
    greyish: {
      dark: grey[600],
      main: grey[500],
      light: grey[400],
      contrastText: Color.white
    },
    stealth: {
      dark: grey[800],
      main: grey[700],
      light: grey[600],
      contrastText: Color.white
    },
    info: {
      light: 'hsl(200, 100%, 75%)',
      main: 'hsl(195, 100%, 50%)'
    },
    lime: {
      // For DashboardDetection only
      dark: 'hsl(88, 50%, 40%)',
      light: 'hsl(88, 50%, 70%)',
      main: 'hsl(88, 50%, 55%)',
      contrastText: Color.black
    },
    mint: {
      dark: 'hsl(169, 98%, 38%)',
      main: 'hsl(169, 98%, 68%)',
      light: 'hsl(169, 98%, 85%)',
      contrastText: Color.black
    },
    mitre: {
      dark: 'hsl(10.2, 67.1%, 36.5%)',
      main: Color.mitreRed,
      light: 'hsl(10.2, 67.1%, 66.5%)',
      contrastText: Color.white
    },
    orange: {
      dark: 'hsl(23, 100%, 65%)',
      light: 'hsl(23, 100%, 85%)',
      main: 'hsl(23, 100%, 75%)',
      contrastText: Color.black
    },
    pink: {
      // Charts only
      light: 'hsl(325, 100%, 87%)',
      main: 'hsl(325, 100%, 75%)',
      contrastText: Color.black
    },
    primary: {
      dark: 'hsl(282, 73%, 64%)',
      main: 'hsl(282, 73%, 74%)'
    },
    purple: {
      // DashboardDetection only
      main: 'hsl(243, 51.9%, 68.2%)',
      light: 'hsl(243, 51.9%, 76.2%)',
      contrastText: Color.black
    },
    darkPurple: {
      light: 'hsl(281, 15%, 27%)',
      main: 'hsl(281, 15%, 21%)',
      dark: 'hsl(281, 15%, 15%)'
    },
    red: {
      light: 'hsl(0, 100%, 85%)',
      main: 'hsl(0, 100%, 75%)',
      contrastText: Color.black
    },
    secondary: {
      dark: 'hsl(0, 0%, 34%)',
      main: 'hsl(0, 0%, 24%)'
    },
    success: {
      light: 'hsl(125, 100%, 85%)',
      main: 'hsl(125, 100%, 75%)'
    },
    green: {
      light: 'hsl(125, 100%, 85%)',
      main: 'hsl(125, 100%, 75%)',
      contrastText: Color.black
    },
    warning: {
      light: 'hsl(50, 100%, 85%)',
      main: 'hsl(50, 100%, 75%)'
    },
    yellow: {
      light: 'hsl(41, 100%, 87%)',
      main: 'hsl(41, 100%, 75%)',
      contrastText: Color.black
    },

    /**
     * Most things should use theme.palette.background.paper.
     * But if necessary, one-off surface colors can be added here.
     */
    surface: {
      fade: 'hsla(0, 0%, 34%, 0.25)',
      odd: 'hsl(0, 0%, 18.4%)',
      // Even should match MuiTableBody even override
      even: 'hsl(0, 0%, 16.1%)',
      gradient: 'linear-gradient(270deg, #975cab 0%, #5e2f72 50.66%, #5e2f72 100%)',
      cardHover: 'hsla(281, 15%, 21%, 0.9)',
      // Table hover used by MuiTableBody override
      hover: 'hsl(281, 15%, 21%)',
      auxiliaryTab: 'hsla(0, 0%, 18.4%, 0.5)',
      pre: 'hsla(282, 73%, 74%, 0.1)', // primary.main with 0.1 alpha
      fieldOutline: 'hsla(180, 100%, 100%, 0.23)'
    },

    dimension: {
      /**
       * Icon in button size
       */
      svg: '0.85rem'
    },

    background: {
      /**
       * Our base color and the equivalent of OG background
       */
      default: 'hsl(0, 0%, 16%)',

      /**
       * Used by our custom cards, profile avatar and other
       * things that should sit above the background. One
       * outlier is the filter and feed list which is using
       * theme.palette.secondary.main
       */
      paper: 'hsl(0, 0%, 7.1%)'
    }
  },

  // Base on assumed default of 16px font size which is standard browser setting
  typography: {
    fontFamily: `"Source Sans Pro", "Helvetica", "Arial", sans-serif`,
    h1: {
      fontSize: '2.25rem', // 36px
      lineHeight: '2.75rem', // 44px
      fontWeight: 400
    },
    h2: {
      fontSize: '1.75rem', // 28px
      lineHeight: '2.25rem', // 36px
      fontWeight: 400
    },
    h3: {
      fontSize: '1.5rem', // 24px
      lineHeight: '2rem', // 32px
      fontWeight: 600
    },
    h4: {
      fontSize: '1.25rem', // 20px
      lineHeight: '1.75rem', // 28px
      fontWeight: 600
    },
    h5: {
      fontSize: '0.875rem', // 14px
      lineHeight: '1.25rem', // 20px
      fontWeight: 700
    },
    h6: {
      fontSize: '0.75rem', // 12px
      lineHeight: '1rem', // 16px
      fontWeight: 700
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: themeParam => ({
        html: {
          fontSmoothing: 'auto'
        },
        body: {
          fontWeight: themeParam.typography.fontWeightMedium
        },
        a: {
          color: themeParam.palette.primary.main,
          textDecoration: 'none',
          '&.MuiButton-root.MuiButton-contained:hover': {
            color: 'initial'
          },
          '&.MuiButton-root.MuiButton-text:hover': {
            color: 'inherit'
          },
          '&.MuiButton-root.MuiButton-outlined:hover': {
            color: 'inherit'
          },
          '&.MuiButton-root.MuiButton-outlinedPrimary:hover': {
            color: theme.palette.primary.main
          }
        },
        p: {
          lineHeight: '1.5rem',
          margin: '0 0 1rem',
          ':first-of-type': {
            marginTop: 0
          }
        },
        pre: {
          whiteSpace: 'pre-wrap',
          margin: 0
        },
        'input:autofill': {
          color: theme.palette.text.primary,
          boxShadow: `0 0 0px 1000px ${theme.palette.secondary.main} inset !important`,
          transition: 'background-color 5000s ease-in-out 0s'
        },
        '#project-purple': {
          display: 'flex',
          flexDirection: 'column',
          height: '100vh'
        },
        '.Form-field': {
          position: 'relative',
          alignSelf: 'stretch'
        },
        '.Form-field.error textarea, .Form-field.error textarea:hover:not(:focus)': {
          borderColor: theme.palette.error.main
        },
        '.Form-field.required:: after': {
          position: 'absolute',
          content: '"required"',
          color: theme.palette.grey[600],
          right: 0,
          top: '-1.5rem',
          fontSize: 'small'
        },
        '.Form-info': {
          position: 'absolute',
          width: '20px',
          left: 'calc(100% - 4.5rem)',
          top: 'calc(-1rem - 3px)',
          color: theme.palette.primary.main
        },
        '.Control label': {
          textTransform: 'capitalize'
        },
        'fieldset.group-layout': {
          margin: 0,
          padding: 0,
          border: 'none'
        },
        /*
          react-js-cron Customizations
          These get mounted directly to <body> so they cannot go in CronForm.style.ts
        */
        '.ant-select-dropdown': {
          padding: '0 !important'
        },
        '.ant-select-dropdown .ant-select-item': {
          borderRadius: 'unset'
        },
        '.cron-container-select-dropdown .ant-select-item-option-selected': {
          backgroundColor: `hsla(282, 73%, 74%, 0.24) !important`,
          color: `${theme.palette.common.white} !important`,
          fontWeight: 'normal !important'
        },
        '.cron-container-select-dropdown .ant-select-item-option-active': {
          backgroundColor: `rgba(255, 255, 255, 0.08) !important`
        },
        '.cron-container-select-dropdown .ant-select-item-option': {
          color: `${theme.palette.common.white}`,
          padding: '6px 16px 6px 16px'
        },
        '.cron-container-select-dropdown': {
          backgroundColor: `${theme.palette.background.paper}`,
          boxShadow:
            '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))',
          color: `${theme.palette.common.white}`,
          borderRadius: `${theme.spacing(1)}`,
          zIndex: theme.zIndex.modal + 1
        },
        /* Other global utilities - consider moving these to a global styled component on App */
        '.sa-generic-spacer': {
          display: 'flex',
          gap: '6px'
        },
        '.sa-no-wrap': {
          whiteSpace: 'nowrap'
        },
        '.sa-shadow': {
          textShadow: '3px 3px hsl(0, 0%, 9%)'
        },
        '.sa-all-word-break': {
          wordBreak: 'normal',
          overflowWrap: 'anywhere'
        },
        '.sa-no-navigation': {
          overscrollBehaviorX: 'contain'
        },
        '.sa-cap': {
          textTransform: 'capitalize'
        },
        '.sa-all-cap': {
          textTransform: 'uppercase'
        },
        '.sa-spin-faster': {
          animation: 'fa-spin 1s infinite linear'
        }
        /* Nothing below here */
      })
    },
    /*
    Hides the divider when collapsed
    https://github.com/mui/material-ui/issues/37019
    */
    MuiAccordion: {
      defaultProps: {
        disableGutters: true
      },
      styleOverrides: {
        root: {
          '&.MuiAccordion-root:before': {
            content: 'none'
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          a: {
            textDecoration: 'underline',
            color: 'inherit',
            '&:hover': {
              textDecoration: 'underline',
              color: 'inherit',
              filter: 'brightness(80%)'
            }
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          maxWidth: '100%'
        },
        tag: {
          margin: '0 3px',
          height: '30px',
          maxWidth: '400px',
          overflow: 'hidden'
        },
        inputRoot: ({ theme }) => ({
          gap: theme.spacing(1),
          '&.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
            paddingRight: '28px !important'
          }
        })
      }
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: ({ theme }) => ({
          '&:hover': {
            background: theme.palette.surface.hover
          }
        })
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: 'none',
          '.MuiButton-startIcon svg': {
            color: theme.palette.secondary.light,
            height: theme.palette.dimension.svg
          }
        })
      }
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: 'sm'
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          margin: '16px',
          padding: 0
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          margin: '16px',
          padding: 0
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          // Taking these three from h4 above
          // MUI bug not applying as expected otherwise
          fontSize: '1.25rem',
          lineHeight: '1.75rem',
          fontWeight: 600
        }
      }
    },
    MuiFab: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'inherit',
          '&.Mui-focused': {
            color: 'inherit'
          }
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 8
      }
    },
    MuiTable: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.palette.surface.gradient
        })
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiTableRow-root:hover': {
            backgroundColor: theme.palette.surface.hover
          }
        })
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: theme.spacing(10),
          '&.Mui-selected': {
            backgroundImage: `linear-gradient(${theme.palette.action.selected}, ${theme.palette.action.selected})`
          },
          '&:nth-of-type(even)': {
            /** nth-of-type generates an error. ssr complaint */
            backgroundColor: theme.palette.surface.even
          }
        })
      }
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: ({ theme }) => ({
          flexDirection: 'row',
          gap: theme.spacing(3),
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.grey[700]}`,
          borderRight: 0,
          height: '48px',

          '&:last-of-type': {
            borderRight: `1px solid ${theme.palette.grey[700]}`
          },

          '&.Mui-selected': {
            backgroundImage: `linear-gradient(${theme.palette.action.selected}, ${theme.palette.action.selected})`
          },

          '&.Mui-disabled': {
            color: theme.palette.secondary.dark
          },

          '&:hover': {
            backgroundColor: theme.palette.action.hover,
            backgroundImage: `linear-gradient(${theme.palette.action.selected}, ${theme.palette.action.selected})`
          }
        })
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: () => ({
          '&.MuiToolBar-override': {
            // default toolbar styles include media queries that make it hard to override height
            // so it's easiest to do it here with this special class
            height: '48px',
            '@media (min-width: 600px)': {
              minHeight: 'revert'
            }
          }
        })
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        variant: 'outlined'
      }
    },
    MuiToggleButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          border: `1px solid ${Color.purple}`,
          backgroundColor: Color.darkest,
          padding: '10px',
          fontSize: '0.85rem'
        },
        arrow: {
          color: Color.purple
        }
      }
    },
    MuiModal: {
      defaultProps: {
        disableEnforceFocus: true
      }
    }
  },
  /*
   * These do not explicitly need to be re-declared and are here for documentation purposes.
   * See https://mui.com/material-ui/customization/z-index/
   */
  zIndex: {
    backdropLow: 990, // lowest backdrop; for use when it needs to go under most other elements
    mobileStepper: 1000,
    fab: 1050,
    speedDial: 1050,
    appBar: 1100, // this is a MUI default, and unused. Ours is navBar.
    backdrop: 1150, // default backdrop; does not cover drawer or navBar
    drawer: 1200,
    navBar: 1250, // Our custom navbar that covers drawers.
    backdropHigh: 1275, // highest backdrop; covers page but not things that may be higher
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500
  }
});

export default theme;

declare module '@mui/material/styles' {
  interface SABackground extends Partial<TypeBackground> {
    strip: string;
  }

  interface Palette {
    aqua: Palette['primary'];
    blue: Palette['primary'];
    cyan: Palette['primary'];
    cert: Palette['primary'];
    greyish: Palette['primary'];
    green: Palette['primary'];
    stealth: Palette['primary'];
    mint: Palette['primary'];
    lime: Palette['primary'];
    mitre: Palette['primary'];
    orange: Palette['primary'];
    pink: Palette['primary'];
    purple: Palette['primary'];
    darkPurple: Palette['primary'];
    red: Palette['primary'];
    yellow: Palette['primary'];

    surface: {
      fade: string;
      odd: string;
      even: string;
      gradient: string;
      cardHover: string;
      hover: string;
      auxiliaryTab: string;
      pre: string;
      fieldOutline: string;
    };

    dimension: {
      svg: string;
    };
  }
  interface PaletteOptions {
    aqua: PaletteOptions['primary'];
    blue: PaletteOptions['primary'];
    cyan: PaletteOptions['primary'];
    cert: PaletteOptions['primary'];
    greyish: PaletteOptions['primary'];
    green: PaletteOptions['primary'];
    stealth: PaletteOptions['primary'];
    lime: PaletteOptions['primary'];
    mint: PaletteOptions['primary'];
    mitre: PaletteOptions['primary'];
    orange: PaletteOptions['primary'];
    pink: PaletteOptions['primary'];
    purple: PaletteOptions['primary'];
    darkPurple: PaletteOptions['primary'];
    red: PaletteOptions['primary'];
    yellow: PaletteOptions['primary'];

    surface: {
      fade: string;
      odd: string;
      even: string;
      gradient: string;
      cardHover: string;
      hover: string;
      auxiliaryTab: string;
      pre: string;
      fieldOutline: string;
    };

    dimension: {
      svg: string;
    };
  }

  // This extends custom z-indexes not included by MUI
  interface ZIndex {
    navBar: number;
    backdrop: number;
    backdropLow: number;
    backdropHigh: number;
  }
}

declare module '@mui/material/Alert' {
  interface AlertPropsColorOverrides {
    mint: true;
    orange: true;
    cert: true;
    greyish: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    mint: true;
    orange: true;
    cert: true;
    greyish: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    mint: true;
    orange: true;
    cert: true;
    greyish: true;
    stealth: true;
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    mint: true;
    orange: true;
    cert: true;
    greyish: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    blue: true;
    cert: true;
    green: true;
    greyish: true;
    mint: true;
    orange: true;
    red: true;
  }
}

declare module '@mui/material/Radio' {
  interface RadioPropsColorOverrides {
    mint: true;
    orange: true;
    cert: true;
    greyish: true;
  }
}
