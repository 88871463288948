import React from 'react';

import { faArrowUpRightFromSquare, faTrash } from '@fortawesome/pro-solid-svg-icons';

import Alert from 'snap-ui/Alert';
import Button from 'snap-ui/Button';
import { DisplayDialog } from 'snap-ui/Dialog';
import Icon from 'snap-ui/Icon';
import LinearProgress from 'snap-ui/LinearProgress';
import { styled } from 'snap-ui/util';

import { ErrorProps } from 'module/ApiError';
import { Session } from 'module/Session/Session.type';
import ApiErrorPopper from 'module/Widgets/ApiErrorPopper';

const TestDialogContent = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(5)};
  button {
    align-self: flex-end;
  }
`;

enum State {
  Ready,
  Saving,
  Testing,
  Publishing,
  Deleting
}

export { State as IDEStatusState };

type IDEStatusProps = {
  errors?: ErrorProps;
  onCancelTest(): void;
  session?: Session;
  state: State;
};

function IDEStatus(props: IDEStatusProps): React.ReactElement {
  return (
    <div className='ide-status' data-testid='ide-status-message'>
      {props.state === State.Saving ? (
        <Alert className='status-alert' severity='info' icon={<Icon.Save />}>
          Saving...
        </Alert>
      ) : props.state === State.Publishing ? (
        <Alert className='status-alert' severity='info' icon={<Icon icon={faArrowUpRightFromSquare} />}>
          Publishing...
        </Alert>
      ) : props.state === State.Deleting ? (
        <Alert className='status-alert' severity='info' icon={<Icon icon={faTrash} />}>
          Deleting...
        </Alert>
      ) : props.state === State.Testing ? (
        <DisplayDialog
          disableClickaway
          actions={false}
          dividers={false}
          DialogProps={{ open: true }}
          title={`Testing Detections...`}
        >
          <TestDialogContent>
            <LinearProgress />
            <Button variant='outlined' onClick={props.onCancelTest}>
              Cancel
            </Button>
          </TestDialogContent>
        </DisplayDialog>
      ) : props.errors?.messages?.length > 0 ? (
        <ApiErrorPopper {...props.errors} />
      ) : null}
    </div>
  );
}

export default IDEStatus;
