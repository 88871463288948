import React from 'react';

import Path from 'constants/paths';

import { deleteAnalyticAttackScripts, postAnalyticAttackScripts } from 'module/Analytic/Analytic.api';
import { ScriptsAsyncInterface } from 'module/BAS/Launcher/useScripts';
import { useFilterRegistry } from 'module/GlobalFilter';
import { Associator } from 'module/Widgets/AssociateArtifactsModal';

import { ArtifactType, Guid } from 'types/common';

import { getAttackScriptList } from '../AttackScript.service';
import { AttackScript } from '../AttackScript.type';

export type AddAttackScriptToAnalytic = {
  scripts: ScriptsAsyncInterface;
  guid: Guid;
  onClose: () => void;
  isOpen: boolean;
};

export function AddAttackScriptToAnalytic({ guid, onClose, isOpen, scripts }: AddAttackScriptToAnalytic) {
  const { generateQuery } = useFilterRegistry();
  const { data: attackScripts, status, setScripts } = scripts;

  const fetchAllAttackScripts = React.useCallback(
    (searchTerm: string) => {
      const query = searchTerm ? generateQuery(ArtifactType.AttackScript, { query: searchTerm }) : undefined;
      return getAttackScriptList(query);
    },
    [generateQuery]
  );

  const associate = React.useCallback(
    (attackScript: AttackScript) => {
      return postAnalyticAttackScripts(guid, [attackScript.guid]).then(() => {
        setScripts(scripts => [...scripts, attackScript]);
      });
    },
    [guid, setScripts]
  );

  const disassociate = React.useCallback(
    (attackScript: AttackScript) => {
      return deleteAnalyticAttackScripts(guid, [attackScript.guid]).then(() => {
        setScripts(scripts => scripts.filter(script => script.guid !== attackScript.guid));
      });
    },
    [guid, setScripts]
  );

  return (
    <Associator
      associate={associate}
      associatedRecords={attackScripts}
      associatedRecordsStatus={status}
      disassociate={disassociate}
      fetchAllOptions={fetchAllAttackScripts}
      isOpen={isOpen}
      onClose={onClose}
      pathRoot={Path.AttackScript}
      types={{ associated: ArtifactType.Analytic, current: ArtifactType.AttackScript }}
    />
  );
}
