import React from 'react';

import Paper from 'snap-ui/Paper';
import Placeholder from 'snap-ui/Placeholder';

import OverviewTab, { CreatorSubtitle } from 'module/Widgets/OverviewTab';

import { Status } from 'storage';

import { AnalyticVersion } from '../Analytic.type';

type AnalyticVersionOverviewProps = {
  version: AnalyticVersion;
  versionStatus: Status;
};

export default function AnalyticVersionOverview({ version, versionStatus }: AnalyticVersionOverviewProps): JSX.Element {
  const showPlaceholder = versionStatus === Status.pending;

  return showPlaceholder ? (
    <Placeholder variant='rectangular' height={300} />
  ) : (
    <Paper>
      <OverviewTab
        title={version?.name}
        description={version?.description}
        subtitle={<CreatorSubtitle date={version?.modified} creator={version?.created_by?.name} />}
      />
    </Paper>
  );
}
