import { snapattack } from 'apis/snapattack';

import { Guid } from 'types/common';

import { ExportEndpointPrefix, ExportRequestBase, ExportResponse, ExportStatus } from './Export.type';

export function postExport(prefix: ExportEndpointPrefix, payload: ExportRequestBase): Promise<ExportResponse> {
  return snapattack.post(`${prefix}/`, payload).then(r => r.data);
}

export function getExportStatus(prefix: ExportEndpointPrefix, taskId: Guid): Promise<ExportStatus> {
  return snapattack.get(`${prefix}/${taskId}/`).then(r => r.data);
}

export function getExportResult(prefix: ExportEndpointPrefix, taskId: Guid): Promise<string> {
  return snapattack
    .get(`${prefix}/${taskId}/result/`, { responseType: 'text' })
    .then(r => r.data)
    .then(content => {
      if (typeof content === 'string') return content;
      return JSON.stringify(content);
    });
}
