import React from 'react';

import Path from 'constants/paths';

import Can from 'module/Can';

import { useAuth } from 'provider';

import { FunctionalPermission } from 'types/auth';
import { ArtifactType } from 'types/common';

import { formatQueryString } from 'utilities/SearchParam';

import ListItemButton from './ListItemButton';
import ListItemLink from './ListItemLink';

export default function ListItemsValidate({ onClose }: { onClose(): void }) {
  const { isSubscriber } = useAuth();
  const viewPath =
    Path.FeedReset +
    formatQueryString({
      topic: ArtifactType.AttackScript,
      page: 1
    });

  return (
    <>
      <ListItemLink to={viewPath} title='Attack Script Library' onClick={onClose} />
      <Can I={FunctionalPermission.TaskBASAgent}>
        <Can.Grant>
          <ListItemLink
            aria-label='Go to view attack simulation results page'
            id='MenuItemBASView'
            onClick={onClose}
            title='Attack Simulation Results'
            to={Path.BASJob}
          />
        </Can.Grant>
        <Can.Block>
          <ListItemButton
            disabled
            title='Attack Simulation Results'
            tooltip={
              !isSubscriber
                ? 'Attack simulations are only available to subscribers.'
                : 'You do not have permission to view attack simulation results.'
            }
          />
        </Can.Block>
      </Can>
    </>
  );
}
