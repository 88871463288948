import React from 'react';

import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';

import { Autocomplete } from 'snap-ui/Autocomplete';
import Button, { ActionIconButton } from 'snap-ui/Button';
import ConfirmDialog from 'snap-ui/Dialog/ConfirmDialog';
import Paper from 'snap-ui/Paper';
import Table, { TablePlaceholder } from 'snap-ui/Table';
import TableSortLabel from 'snap-ui/TableSortLabel';
import Tooltip from 'snap-ui/Tooltip';
import Typography from 'snap-ui/Typography';

import useOrganizationOptions from 'hooks/useOrganizationOptions';
import useSort from 'hooks/useSort';

import { useAuth } from 'provider';
import { useIntegrationCatalog } from 'provider';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { Guid } from 'types/common';
import { SortDirection } from 'types/filter';

import { formatShortTimestamp, timeAgo } from 'utilities/TimeUtils';

import { TableSearchInformationalMessage } from '../Core.style';
import ApiKeyFormDialog from './ApiKeyFormDialog';
import { ListContainer } from './ApiKeys.style';
import {
  ApiKey,
  OnCreateApiKey,
  OnDeleteApiKey,
  CreateApiKeyPayload,
  ApiKeyScope,
  CreatedApiKey
} from './ApiKeys.type';

const isPresent = (e: any) => e !== undefined;

type ApiKeysListProps = {
  className?: string;
  status: Status;
  keys: ApiKey[];
  onCreate: OnCreateApiKey;
  onDelete: OnDeleteApiKey;
  createdApiKey: CreatedApiKey;
  setCreatedApiKey(key: CreatedApiKey): void;
};

function ApiKeysList(props: ApiKeysListProps): React.ReactElement {
  const { user, defaultOrgId, permission: organizations } = useAuth();
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const [currentlyOpenedDeleteConfirm, setCurrentlyOpenedDeleteConfirm] = React.useState<Guid>();
  const { integrations } = useIntegrationCatalog();

  const _orgOptions = useOrganizationOptions(FunctionalPermission.TaskBASAgent, true);
  const orgOptions = React.useMemo(() => {
    return [{ content: 'All Organizations', label: 'All Organizations', value: '' }, ..._orgOptions];
  }, [_orgOptions]);
  const [selectedOrgId, setSelectedOrgId] = React.useState(defaultOrgId.toString());

  const filteredApiKeys = React.useMemo(() => {
    if (selectedOrgId) {
      return props.keys.filter(key => key.organization_id === +selectedOrgId);
    } else {
      return props.keys;
    }
  }, [selectedOrgId, props.keys]);

  const { createSortHandler, order, sortedData } = useSort<ApiKey>(filteredApiKeys, {
    direction: SortDirection.asc,
    field: 'name'
  });

  function getOrgName(orgId: number): string {
    const org = organizations.find(org => org.id === orgId);
    return org?.name || 'Unknown';
  }

  function renderActions(key: ApiKey): React.ReactNode {
    return (
      <div className='actions-column'>
        <Tooltip placement='left' arrow title={`Delete ${key.name}`}>
          <ActionIconButton
            aria-label={`Delete ${key.name}`}
            icon={faTrash}
            onClick={() => setCurrentlyOpenedDeleteConfirm(key.guid)}
          />
        </Tooltip>
        <ConfirmDialog
          DialogProps={{
            open: currentlyOpenedDeleteConfirm === key.guid,
            onClose: closeDeleteConfirm
          }}
          ConfirmProps={{
            children: 'Delete',
            onClick: handleDelete(key)
          }}
          title={`Delete ${key.name}`}
        >
          {getDeleteConfirmationMessage(key)}
        </ConfirmDialog>
      </div>
    );
  }

  return (
    <ListContainer className={classnames('ApiKeysList', props.className)}>
      <Typography variant='h1'>API Keys</Typography>
      <div className='contents'>
        <Paper className='table-container'>
          <div className='table-toolbar'>
            {user.superuser && (
              <Autocomplete
                className='organization-dropdown'
                disableClearable
                disableUserAdditions
                elevated
                label='Organization'
                name='organization_id'
                options={orgOptions}
                onChange={v => setSelectedOrgId(v as string)}
                value={selectedOrgId}
              />
            )}
            <Button className='add-apikey-button' variant='outlined' onClick={handleOpenCreateModal}>
              Create API Key
            </Button>
          </div>
          <Table
            aria-label='API Keys'
            component='div'
            columns={[
              <TableSortLabel
                key='name'
                field='name'
                label='Name'
                sortHandler={createSortHandler('name')}
                order={order}
              />,
              <TableSortLabel
                key='user'
                field='user'
                label='User'
                sortHandler={createSortHandler('user', (a, b) => a.user?.name?.localeCompare(b.user?.name))}
                order={order}
              />,
              user.superuser ? (
                <TableSortLabel
                  key='organization_id'
                  field='organization_id'
                  label='Organization'
                  sortHandler={createSortHandler('organization_id')}
                  order={order}
                />
              ) : undefined,
              <TableSortLabel
                key='scopes'
                field='scopes'
                label='Scope'
                sortHandler={createSortHandler('scopes')}
                order={order}
              />,
              <TableSortLabel
                key='integrations'
                field='integrations'
                label='Integration'
                sortHandler={createSortHandler('integrations')}
                order={order}
              />,
              <TableSortLabel
                key='creation'
                field='creation'
                label='Created'
                sortHandler={createSortHandler('creation')}
                order={order}
              />,
              <TableSortLabel
                key='last_used'
                field='last_used'
                label='Last&nbsp;Used'
                sortHandler={createSortHandler('last_used')}
                order={order}
              />,
              ''
            ].filter(isPresent)}
            rows={sortedData.map(key =>
              [
                key.name,
                key.user?.name || '',
                user.superuser ? getOrgName(key.organization_id) : undefined,
                renderScopes(key.scopes),
                renderEnvironments(key.integrations),
                renderRelativeTimestamp(key.creation),
                renderRelativeTimestamp(key.last_used),
                renderActions(key)
              ].filter(isPresent)
            )}
          />
          {props.status === Status.pending && <TablePlaceholder count={10} height={40} />}
          {props.status === Status.resolved && sortedData.length === 0 && (
            <TableSearchInformationalMessage>
              You don&apos;t have any API keys.{' '}
              <Button className='create-api-key' size='small' onClick={handleOpenCreateModal}>
                Create a New API Key
              </Button>
            </TableSearchInformationalMessage>
          )}
        </Paper>
      </div>
      <ApiKeyFormDialog
        isLoading={props.status === Status.pending}
        createdApiKey={props.createdApiKey}
        onClose={handleCloseCreateModal}
        onSubmit={handleSubmitNewKey}
        open={showCreateModal}
      />
    </ListContainer>
  );

  function renderScopes(scopes: string[]): string {
    return scopes.reduce((out, scope) => {
      if (!out) return scope;
      return `${out}, ${scope}`;
    }, '');
  }

  function renderEnvironments(integrations: ApiKey['integrations']): string {
    return integrations.reduce((out, integration) => {
      if (!out) return getIntegrationName(integration.guid);
      return `${out}, ${getIntegrationName(integration.guid)}`;
    }, '');
  }

  function getIntegrationName(guid: Guid): string {
    return integrations.preferred_org_aware.find(integration => integration.guid === guid)?.name ?? 'Unknown';
  }

  function renderRelativeTimestamp(timestamp: string): JSX.Element {
    return (
      <Tooltip arrow wrap placement='top' title={formatShortTimestamp(timestamp)}>
        <>{timeAgo(timestamp)}</>
      </Tooltip>
    );
  }

  function closeDeleteConfirm(): void {
    setCurrentlyOpenedDeleteConfirm(null);
  }

  function handleOpenCreateModal(): void {
    props.setCreatedApiKey(null);
    setShowCreateModal(true);
  }

  function handleCloseCreateModal(): void {
    setShowCreateModal(false);
    props.setCreatedApiKey(null);
  }

  function handleDelete(key: ApiKey): () => void {
    return function (): void {
      closeDeleteConfirm();
      props.onDelete(key.guid);
    };
  }

  function handleSubmitNewKey(key: CreateApiKeyPayload): void {
    props.onCreate(key);
  }

  function getDeleteConfirmationMessage(key: ApiKey): React.ReactNode {
    let message = 'Are you sure you want to delete this API Key?';
    const entities = [];
    if (key.scopes.includes(ApiKeyScope.AnalyticDeployment)) {
      entities.push('environments');
    }
    if (key.scopes.includes(ApiKeyScope.BASTestResults)) {
      entities.push('Attack Simulation Agents');
    }
    if (key.scopes.includes(ApiKeyScope.LocalSessionCapture)) {
      entities.push('CapAttack Agents');
    }
    if (key.scopes.includes(ApiKeyScope.REST)) {
      entities.push('API clients');
    }

    if (entities.length) {
      let entityList;
      switch (entities.length) {
        case 1:
          entityList = entities[0];
          break;
        case 2:
          entityList = `${entities[0]} or ${entities[1]}`;
          break;
        case 3:
          entityList = `${entities[0]}, ${entities[1]}, or ${entities[2]}`;
      }
      message += ` Any ${entityList} using this key will need to be reconfigured with a new key.`;
    }

    return (
      <>
        <p>{message}</p>
        <p>This action cannot be undone.</p>
      </>
    );
  }
}

export default ApiKeysList;
