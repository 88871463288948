import React from 'react';

import classNames from 'classnames';

import { BackdropLoaderContainer } from 'snap-ui/BackdropLoader';
import { RawChip } from 'snap-ui/Chip';
import Placeholder from 'snap-ui/Placeholder';
import { styled } from 'snap-ui/util';

import { Discriminator } from 'module/Tag';
import { HeroContainer } from 'module/Widgets/HeroImage';

import { ArtifactType } from 'types/common';
import { StrictReactNode } from 'types/core';

import { getArtifactIcon } from './Artifact.helper';

const Container = styled(BackdropLoaderContainer)`
  display: flex;
  flex-direction: column;

  .Artifact-content,
  .HeroImage {
    padding: var(--Scaffold-hero);
  }

  .Artifact-content {
    margin-bottom: calc(${p => p.theme.spacing(9)} * 2);

    &.Landing-content {
      display: flex;
      flex-direction: column;
      gap: ${p => p.theme.spacing(7)};
      padding-top: ${p => p.theme.spacing(7)};
    }
  }

  .Artifact-type {
    font-size: 1.125rem;
    svg {
      margin-left: 0;
    }
  }

  .ArtifactWidget-form {
    display: flex;
    flex-direction: column;
    gap: ${p => p.theme.spacing(7)};
  }

  .Artifact-content.Artifact-placeholder {
    display: grid;
    gap: ${p => p.theme.spacing(4)};
    column-gap: ${p => p.theme.spacing(4)};
  }
`;

export type ArtifactProps = {
  children: React.ReactChild | React.ReactChild[];
  className?: string;
  image?: string;
  isPending?: boolean;
  meta: StrictReactNode;
  type: ArtifactType | Discriminator;
  label?: string;
};

export default function Artifact(props: ArtifactProps) {
  const icon = getArtifactIcon(props.type);
  const label = props?.label ? props.label : props.type?.replaceAll('_', ' ');

  if (props.isPending)
    return (
      <Container className={classNames('Artifact', props.className)}>
        <HeroContainer>
          <RawChip className='Artifact-type' icon={icon} label={label} color='primary' />
          <Placeholder variant='text' height={66} width='35%' />
          <Placeholder variant='rectangular' height={20} width={100} />
        </HeroContainer>
        <div className='Artifact-content Artifact-placeholder'>
          <Placeholder className='ArtifactPlaceholder' variant='rectangular' height={150} />
          <Placeholder className='ArtifactPlaceholder' variant='rectangular' height={200} />
          <Placeholder className='ArtifactPlaceholder' variant='rectangular' height={200} />
          <Placeholder className='ArtifactPlaceholder' variant='rectangular' height={200} />
        </div>
      </Container>
    );

  return (
    <Container className={classNames('Artifact', props.className)}>
      <HeroContainer url={props.image} type={props.type}>
        <RawChip className='Artifact-type no-print' icon={icon} label={label} color='primary' />
        {props.meta}
      </HeroContainer>
      <div className='Artifact-content Landing-content '>{props.children}</div>
    </Container>
  );
}
