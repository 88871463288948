import React from 'react';

import Icon from 'snap-ui/Icon';
import Placeholder from 'snap-ui/Placeholder';
import { styled } from 'snap-ui/util';

import { Discriminator } from 'module/Tag/Tag.type';

const Container = styled('div')`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(2)};

  svg {
    font-size: 1em;
  }

  &.TagIcon-technique svg {
    font-size: 0.75rem;
    padding: 2px 3px;
    color: ${p => p.theme.palette.error.main};
    background-color: ${p => p.theme.palette.common.black};
    border: 1px solid transparent;
    border-radius: 50%;
  }

  &.TagIcon-actor svg {
    color: ${p => p.theme.palette.warning.main};
  }
`;

type TagIconProps = { discriminator: Discriminator; iconOnly?: boolean };

export default function TagIcon({ discriminator, iconOnly }: TagIconProps) {
  switch (discriminator) {
    case Discriminator.Actor:
      return (
        <Container className='TagIcon-actor'>
          <Icon.Actor />
          {iconOnly || 'Actor'}
        </Container>
      );
    case Discriminator.Attack:
      return (
        <Container className='TagIcon-technique'>
          <Icon.Attack />
          {iconOnly || 'Technique'}
        </Container>
      );
    case Discriminator.Software:
      return (
        <Container className='TagIcon-software'>
          <Icon.Software />
          {iconOnly || 'Tool'}
        </Container>
      );
    case Discriminator.Vulnerability:
      return (
        <Container className='TagIcon-vulnerability'>
          <Icon.Vulnerability />
          {iconOnly || 'Vulnerability'}
        </Container>
      );
    default:
      return (
        <Container className='TagIcon'>
          <Placeholder variant='text' width={50} />
        </Container>
      );
  }
}
