import { styled } from 'snap-ui/util';

export const CronFormContainer = styled('div')`
  // Styles readonly to look like inline text
  .cron-container-read-only.cron-container {
    display: flex;
    flex-wrap: wrap;

    .cron-container-field {
      padding: 0;
      span:first-of-type {
        padding-right: 0;
      }
    }

    .ant-select-single {
      cursor: inherit;
    }

    .ant-select-selector {
      border: none;
      min-width: unset;
      padding-inline-end: 0;
      padding: 0 0 0 4px;
      height: unset;
      transition: unset;
      background: transparent;
      color: ${p => p.theme.palette.common.white};
    }

    .ant-select-selection-search input {
      display: none;
    }

    .ant-select-selection-item {
      padding-inline-end: 4px;
      user-select: unset;
    }
  }

  // Regular CronForm
  &.cron-form .cron-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${p => p.theme.spacing(1)};

    .ant-select {
      margin: ${p => p.theme.spacing(0, 3)};
    }

    .ant-select,
    .ant-select-selection-item,
    .ant-select-arrow,
    .ant-select-selection-placeholder {
      font-family: ${p => p.theme.typography.fontFamily};
      color: ${p => p.theme.palette.common.white};
    }

    .ant-select-selector {
      border-radius: 4px;
      border: 1px solid rgba(255, 255, 255, 0.23) !important;
      color: ${p => p.theme.palette.common.white};
      background-color: hsl(0, 0%, 7.1%);
      background-image: linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12));
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12);
      padding-left: 11px;
      align-items: center;
      padding-inline-end: 0;

      &:hover {
        border: 1px solid ${p => p.theme.palette.common.white} !important;
      }

      &:focus-within {
        border: 2px solid ${p => p.theme.palette.primary.main} !important;
      }

      height: 40px;
    }

    .ant-select-selection-placeholder {
      color: ${p => p.theme.palette.grey[500]};
    }

    .ant-select-arrow {
      color: ${p => p.theme.palette.common.white};
    }

    .ant-select-arrow span svg path {
      d: path('M 151.251 331.625 L 512 692.374 L 872.748 331.625 L 151.251 331.625 Z');
    }

    .cron-container-field {
      padding-top: ${p => p.theme.spacing(2)};
      display: flex;
      align-items: center;
    }

    .cron-container-period {
      .ant-select-selector {
        min-width: 90px;
      }
    }

    .cron-container-month-days,
    .cron-container-week-days {
      .ant-select-selector {
        min-width: 180px;
      }
    }

    .cron-container-hours {
      .ant-select-selector {
        min-width: 150px;
      }
    }

    .cron-container-minutes {
      .ant-select-selector {
        min-width: 80px;
        padding-right: 25px;
      }
    }
  }
`;
