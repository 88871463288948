import React from 'react';

import { faSearch } from '@fortawesome/pro-solid-svg-icons';

import Alert from 'snap-ui/Alert';
import Button from 'snap-ui/Button';
import { CheckableCards } from 'snap-ui/Card';
import CircularProgress from 'snap-ui/CircularProgress';
import Divider from 'snap-ui/Divider';
import Icon from 'snap-ui/Icon';
import KeyValueList from 'snap-ui/KeyValueList';
import Paper from 'snap-ui/Paper';
import TextField from 'snap-ui/TextField';

import { ApiError } from 'module/ApiError';
import { useIDEState, useIDETranslationState } from 'module/IDE';
import { useReverseMap } from 'module/ReverseMap';
import EmptyState from 'module/Widgets/EmptyState';
import InlineHighlight from 'module/Widgets/InlineHighlight';

import { FieldOption } from 'types/analytic';

import { caseInsensitiveStringEqual } from 'utilities/StringUtils';

import { CardField, FieldPickerDialog } from './FieldPicker.style';

const SORT_SCORE = {
  BASE: 5,
  UNMAPPED_BASE: -15, // make sure these are sorted at the very bottom, even when they name match
  NAME_MATCH: 10,
  DESC_MATCH: 5,
  PREVALENCE: {
    High: 15, // needs to beat the score for search matches or else weird things happen with the separated lists
    Medium: 2,
    Low: 1
  }
};

type FieldPickerProps = {
  isOpen: boolean;
  isWinEvent: boolean;
  onClose(fieldName?: string): void;
  fields: FieldOption[];
};

export default function FieldPicker({ isOpen, isWinEvent, onClose, fields }: FieldPickerProps): JSX.Element {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [selectedFieldName, setSelectedFieldName] = React.useState<string>(null);
  const selectedField = fields.find(field => field.field === selectedFieldName);
  const fieldListRef = React.useRef<HTMLDivElement>();

  const { ideState } = useIDEState();
  const { selectedLanguage } = useIDETranslationState();
  const {
    errorProps,
    map: reverseFieldMap,
    isPending: isReverseMapPending
  } = useReverseMap(selectedLanguage?.id, ideState.analyticForm?.logsource);

  const resetLocalState = React.useCallback(() => {
    setSearchTerm('');
    setSelectedFieldName(null);
  }, [setSearchTerm, setSelectedFieldName]);

  function handleClose(fieldName?: string) {
    onClose(fieldName);
    resetLocalState();
  }

  function handleSelect(fieldName: string) {
    setSelectedFieldName(fieldName);
  }

  const allowAdditions = true;
  const fieldToUse = selectedFieldName || (allowAdditions ? searchTerm : null) || null;

  const filteredFields = React.useMemo(() => {
    return fields
      .map(field => {
        const reverseMap = Object.entries(reverseFieldMap).reduce((mapped, [sourceField, sigmaFields]) => {
          if (sigmaFields.some(sigmaField => caseInsensitiveStringEqual(sigmaField, field.field))) {
            return [...mapped, sourceField];
          }
          return mapped;
        }, []);

        // inherent value of the field
        let score_base = reverseMap?.length ? SORT_SCORE.BASE : SORT_SCORE.UNMAPPED_BASE;
        score_base += SORT_SCORE.PREVALENCE[field.prevalence] ?? 0;

        // search-based score
        let score = score_base;
        if (searchTerm && reverseMap.some(f => f.toLowerCase().includes(searchTerm.toLowerCase()))) {
          score += SORT_SCORE.NAME_MATCH;
        } else if (searchTerm && field.field?.toLowerCase().includes(searchTerm?.toLowerCase())) {
          score += SORT_SCORE.NAME_MATCH;
        } else if (searchTerm && field.description?.toLowerCase().includes(searchTerm?.toLowerCase())) {
          score += SORT_SCORE.DESC_MATCH;
        }

        return {
          ...field,
          _score: score,
          // if total score > non-search score, we know it was matched by search. this is a shortcut that lets us avoid
          // duplicating the search logic in the sort scoring and in the filter
          _score_base: score_base,
          _fields: reverseMap
        };
      })
      .filter(field => {
        return !searchTerm || field._score > field._score_base;
      })
      .sort((a, b) => b._score - a._score);
  }, [fields, reverseFieldMap, searchTerm]);

  React.useEffect(() => {
    if (selectedFieldName && !filteredFields.some(field => field.field === selectedFieldName)) setSelectedFieldName('');
  }, [filteredFields, selectedFieldName, setSelectedFieldName]);

  React.useEffect(() => {
    if (!isOpen) return;

    if (selectedFieldName === null) setSelectedFieldName(filteredFields[0]?.field ?? '');

    function selectWithKeyboard(event: KeyboardEvent) {
      const index = filteredFields.findIndex(f => f.field === selectedFieldName);
      let newSelectedName: string;
      switch (event.keyCode) {
        case 40: // down arrow
          if (index < filteredFields.length - 1) {
            newSelectedName = filteredFields[index + 1]?.field;
          }
          break;
        case 38: // up arrow
          if (index === -1) newSelectedName = filteredFields[0]?.field;
          else if (index > 0) newSelectedName = filteredFields[index - 1]?.field;
          break;
        default:
          break;
      }
      if (newSelectedName !== undefined) setSelectedFieldName(newSelectedName ?? '');
      const scrollTarget = fieldListRef.current?.querySelector(`[id="${newSelectedName}"]`)?.parentNode as HTMLElement;
      scrollTarget?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }

    document.addEventListener('keydown', selectWithKeyboard);

    return () => document.removeEventListener('keydown', selectWithKeyboard);
  }, [isOpen, filteredFields, selectedFieldName]);

  // order properties for display
  const fieldData = {};
  (['field', '_reverse', 'description', 'datatype', 'example', 'prevalence', 'event_ids'] as const).forEach(
    property => {
      if (property === 'event_ids' && !isWinEvent) return;
      if (property === '_reverse' && selectedLanguage) {
        const fields = filteredFields.find(f => f.field === selectedFieldName)?._fields;
        const propName = `${selectedLanguage.name} Fields`;
        if (fields?.length) {
          fieldData[propName] = <InlineHighlight query={searchTerm} value={fields.join(', ')} />;
        } else {
          fieldData[propName] = !isReverseMapPending && (
            <Alert severity='warning'>
              {selectedFieldName} is not mapped for {selectedLanguage.name}
            </Alert>
          );
        }
      }
      const val = selectedField?.[property];
      if (val) {
        if (['field', 'description'].includes(property)) {
          fieldData[property] = <InlineHighlight query={searchTerm} value={val} />;
        } else {
          fieldData[property] = val;
        }
      }
    }
  );

  type FilterFunc = (field: (typeof filteredFields)[number]) => boolean;
  const filteredSets: [string, FilterFunc][] = [
    ['Most Used', field => field.prevalence === 'High' && !!field._fields.length],
    ['Other Fields', field => field.prevalence !== 'High' && !!field._fields.length],
    ['Unmapped Fields', field => !field._fields.length]
  ];

  return (
    <FieldPickerDialog
      DialogProps={{ open: isOpen, onClose: () => handleClose(), maxWidth: 'md' }}
      FormikConfig={{
        initialValues: {},
        onSubmit() {
          handleClose(fieldToUse);
        }
      }}
      SubmitProps={{
        children: 'Use Field',
        disabled: !fieldToUse
      }}
      title={
        <>
          <p>Select a field {isReverseMapPending && <CircularProgress color='secondary' size={25} />}</p>
          <TextField
            autoFocus
            InputProps={{
              endAdornment: <Icon icon={faSearch} />
            }}
            fullWidth
            label='Search by field name or description'
            onChange={e => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
        </>
      }
    >
      <ApiError {...errorProps} />
      {filteredFields?.length ? (
        <CardField active>
          <div className='FieldList' ref={fieldListRef}>
            {filteredSets.map(([name, filterFn]) => (
              <React.Fragment key={name}>
                <Divider color='primary' textAlign='left'>
                  {name}
                </Divider>
                <CheckableCards
                  name='field'
                  options={filteredFields.filter(filterFn).map(field => ({
                    label: <InlineHighlight query={searchTerm} value={field.field} />,
                    value: field.field
                  }))}
                  size='xs'
                  value={selectedFieldName}
                  onChange={handleSelect}
                />
              </React.Fragment>
            ))}
          </div>
          {!!filteredFields?.length && (
            <Paper className='SelectedField' elevation={8}>
              {selectedField ? (
                <KeyValueList data={fieldData} titleCase />
              ) : (
                <EmptyState title='Select a field to show more information'>
                  <Button onClick={() => handleClose(searchTerm)} variant='outlined'>
                    Use &quot;{searchTerm}&quot;
                  </Button>
                </EmptyState>
              )}
            </Paper>
          )}
        </CardField>
      ) : (
        <EmptyState className='NoFields' title='No fields match your search'>
          <Button onClick={() => handleClose(searchTerm)} variant='outlined'>
            Use &quot;{searchTerm}&quot;
          </Button>
        </EmptyState>
      )}
    </FieldPickerDialog>
  );
}
