import React from 'react';

import { IconButton } from 'snap-ui/Button';
import { BuffDivider } from 'snap-ui/Divider';
import Placeholder from 'snap-ui/Placeholder';
import { styled } from 'snap-ui/util';

import { Session, SessionEditRouterState } from './Session.type';

const SessionDetailsPlaceholderContainer = styled('div')`
  display: grid;
  margin-top: 40px;
  grid-template-columns: 66% 33%;
  gap: ${p => p.theme.spacing(4)};
  .Placeholder {
    margin-bottom: ${p => p.theme.spacing(2)};
  }

  .action {
    align-self: flex-end;
    margin-bottom: ${p => p.theme.spacing(3)};
  }

  .tags {
    margin-top: ${p => p.theme.spacing(4)};
    display: flex;
    gap: 6px;
  }
`;

export function SessionDetailsPlaceholder(): JSX.Element {
  return (
    <SessionDetailsPlaceholderContainer>
      <div className='session-details'>
        <Placeholder variant='text' width={480} height={15} />
        <Placeholder variant='text' width={240} height={15} />
        <BuffDivider />
        <Placeholder variant='text' width={480} height={15} />
        <BuffDivider />
        <Placeholder variant='text' width={480} height={15} />
        <Placeholder variant='text' width={240} height={15} />
        <Placeholder variant='text' width={420} height={15} />
        <Placeholder variant='text' width={320} height={15} />

        <div className='tags'>
          <Placeholder variant='rectangular' width={48} height={24} />
          <Placeholder variant='rectangular' width={48} height={24} />
          <Placeholder variant='rectangular' width={48} height={24} />
          <Placeholder variant='rectangular' width={48} height={24} />
        </div>
      </div>
      <div className='detection-panel'>
        <Placeholder className='action' variant='rectangular' width={370} height={600} />
      </div>
    </SessionDetailsPlaceholderContainer>
  );
}

export const SessionIconButton = styled(IconButton)`
  margin: 0;
  padding: 0;

  :hover {
    background-color: transparent;
  }
  :active {
    color: ${p => p.theme.palette.grey[700]};
  }
`;

export function decomposeSession(session: Session): SessionEditRouterState {
  const { hosts, permission, linked_threat_intelligence, references, intel, ...artifact } = session;
  const refs = {
    linked_threat_intelligence,
    references,
    intel
  };

  return {
    item: artifact,
    references: refs,
    hosts,
    permissions: { permission }
  };
}
