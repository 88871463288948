import { snapattack } from 'apis/snapattack';

import { TwoFactorType } from 'types/auth';

import {
  TwoFactorSetupResponse,
  TwoFactorCodePayload,
  OrgAdminResetUsersMFAParams,
  TwoFactorRemovePayload
} from './Multifactor.type';

const prefix = '/auth';

const TwoFactorSetupPayload = { type: TwoFactorType.TOTP };

export function setupTwoFactor(): Promise<TwoFactorSetupResponse> {
  return snapattack.post(`${prefix}/setup_two_factor/`, TwoFactorSetupPayload).then(r => r.data);
}

export function confirmTwoFactorSetup(payload: TwoFactorCodePayload): Promise<void> {
  return snapattack.post(`${prefix}/setup_two_factor/confirm/`, payload).then(r => r.data);
}

export function removeTwoFactor(payload: TwoFactorRemovePayload): Promise<void> {
  return snapattack.post(`${prefix}/remove_two_factor/`, payload).then(r => r.data);
}

export function confirmTwoFactorCode(login_identifier: string, payload: TwoFactorCodePayload): Promise<void> {
  return snapattack.post(`${prefix}/login/${login_identifier}/verify/totp/`, payload).then(r => r.data);
}
/**
 * Resend code for type 'email'
 * Not currently in use
 */
export function requestTwoFactorCode(): Promise<void> {
  return snapattack.post(`${prefix}/request_code/`).then(r => r.data);
}

export function resetMFA({ organization_id, user_id }: OrgAdminResetUsersMFAParams): Promise<void> {
  return snapattack.post(`org/${organization_id}/users/${user_id}/reset_two_factor/`).then(r => r.data);
}
