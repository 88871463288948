import React from 'react';

import { IconDefinition, faDiamondExclamation, faHexagonCheck } from '@fortawesome/pro-regular-svg-icons';

import { ChipProps } from 'snap-ui/Chip';
import Icon from 'snap-ui/Icon';

import { ArtifactScore } from 'types/common';

import { StateLabelBase, ValueMap } from './';

const common: ValueMap['string'] = { variant: 'outlined' };
export const ScoreBaseMap: ValueMap<ArtifactScore> = {
  [ArtifactScore.HIGHEST]: { ...common, color: 'error' },
  [ArtifactScore.HIGH]: { ...common, color: 'orange' },
  [ArtifactScore.MEDIUM]: { ...common, color: 'warning' },
  [ArtifactScore.LOW]: { ...common, color: 'success' },
  [ArtifactScore.LOWEST]: { ...common, color: 'info' },
  [ArtifactScore.UNKNOWN]: { ...common, color: 'greyish' }
};

function getValueMap(icon: IconDefinition): ValueMap<ArtifactScore> {
  return Object.entries(ScoreBaseMap).reduce(
    (map, [score, props]: [ArtifactScore, ChipProps]) => ({
      ...map,
      [score]: { icon: <Icon icon={icon} />, ...props }
    }),
    {} as ValueMap<ArtifactScore>
  );
}

function ScoreLabel({ kind, value }: { kind: 'confidence' | 'severity'; value: ArtifactScore }): JSX.Element {
  let icon;
  switch (kind) {
    case 'confidence':
      icon = faHexagonCheck;
      break;
    case 'severity':
      icon = faDiamondExclamation;
      break;
  }

  return <StateLabelBase value={value} valueMap={getValueMap(icon)} />;
}

export function ConfidenceLabel({ value }: { value: ArtifactScore }): JSX.Element {
  return <ScoreLabel kind='confidence' value={value} />;
}

export function SeverityLabel({ value }: { value: ArtifactScore }): JSX.Element {
  return <ScoreLabel kind='severity' value={value} />;
}
