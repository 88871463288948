import React from 'react';

import omit from 'lodash/omit';

import { usePushSnack } from 'provider/Snack';

import { useAsync } from 'storage';

import { Guid } from 'types/common';

import {
  deleteIntegrationJobSchedule,
  getAllIntegrationJobSchedules,
  getIntegrationJobSchedules,
  postIntegrationJobSchedule,
  putIntegrationJobSchedule
} from './Integration.api';
import {
  CreateJobSchedulePayload,
  CreateJobSchedulePayloadExtra,
  CreateJobSchedulePayloadSchema,
  JobSchedule
} from './Integration.type';

function consolidateParameters(payloadExtra: CreateJobSchedulePayloadExtra): CreateJobSchedulePayload {
  if ('parameters' in payloadExtra) {
    return {
      ...omit(payloadExtra, 'extra_parameters'),
      parameters: {
        ...payloadExtra.extra_parameters,
        ...payloadExtra.parameters
      }
    };
  }
  return payloadExtra;
}
/**
 * @param integration_guid controls what is returned in `jobs`
 * @also {null | undefined} for when we're just doing create/update/delete and not interested in fetching
 * @also {Guid}  fetching scheduled jobs for a specific integration
 * @also {'ALL'} fetching scheduled jobs for all integrations
 */
export function useScheduledJobs(integration_guid?: Guid | 'ALL') {
  const pushSnack = usePushSnack();
  const { data, run, status, errorProps } = useAsync<JobSchedule[]>([]);
  const { task, status: taskStatus, errorProps: taskErrorProps } = useAsync();

  const refresh = React.useCallback(() => {
    if (integration_guid) {
      if (integration_guid === 'ALL') {
        run(getAllIntegrationJobSchedules());
      } else {
        run(getIntegrationJobSchedules(integration_guid));
      }
    }
  }, [integration_guid, run]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  const createJobSchedule = React.useCallback(
    (integration_guid: Guid, payloadExtra: CreateJobSchedulePayloadExtra) => {
      return task(
        postIntegrationJobSchedule(
          integration_guid,
          CreateJobSchedulePayloadSchema.parse(consolidateParameters(payloadExtra))
        )
      ).then(() => {
        pushSnack('Scheduled task created', 'info', 'center', 'bottom', 5000);
        refresh();
      });
    },
    [pushSnack, refresh, task]
  );

  const updateJobSchedule = React.useCallback(
    (integration_guid: Guid, schedule_guid: Guid, payloadExtra: CreateJobSchedulePayloadExtra) => {
      return task(
        putIntegrationJobSchedule(
          integration_guid,
          schedule_guid,
          CreateJobSchedulePayloadSchema.parse(consolidateParameters(payloadExtra))
        )
      ).then(() => {
        pushSnack('Scheduled task updated', 'info', 'center', 'bottom', 5000);
        refresh();
      });
    },
    [pushSnack, refresh, task]
  );

  const deleteJobSchedule = React.useCallback(
    (integration_guid: Guid, schedule_guid: Guid) => {
      return task(deleteIntegrationJobSchedule(integration_guid, schedule_guid)).then(() => {
        pushSnack('Scheduled task deleted', 'info', 'center', 'bottom', 5000);
        refresh();
      });
    },
    [task, pushSnack, refresh]
  );

  return {
    jobs: data,
    fetchStatus: status,
    fetchErrorProps: errorProps,
    createJobSchedule,
    updateJobSchedule,
    deleteJobSchedule,
    taskStatus,
    taskErrorProps,
    refresh
  };
}

type ScheduledJobsInterface = ReturnType<typeof useScheduledJobs>;

const ScheduledJobsContext = React.createContext<ScheduledJobsInterface>(null);
ScheduledJobsContext.displayName = 'ScheduledJobsContext';

export function useScheduledJobsContext() {
  const state = React.useContext(ScheduledJobsContext);
  if (!state) throw new Error('useScheduledJobsContext must be used in the ScheduledJobsProvider');
  return state;
}

export function ScheduledJobsProvider({
  children,
  integrationGuid
}: {
  children: React.ReactNode;
  integrationGuid: Guid;
}): JSX.Element {
  const value = useScheduledJobs(integrationGuid);
  return <ScheduledJobsContext.Provider value={value}>{children}</ScheduledJobsContext.Provider>;
}
