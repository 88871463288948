import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';

import Alert from 'snap-ui/Alert';
import Button from 'snap-ui/Button';
import { GridSortModel } from 'snap-ui/DataGrid';
import { DialogProgressContainer, DisplayDialog, FormDialog } from 'snap-ui/Dialog';
import Grid from 'snap-ui/Grid';
import Icon from 'snap-ui/Icon';
import { FieldsLayout } from 'snap-ui/Layout';
import LinearProgress from 'snap-ui/LinearProgress';
import Placeholder from 'snap-ui/Placeholder';
import Typography from 'snap-ui/Typography';
import { styled } from 'snap-ui/util';

import { useCannotRedirect } from 'aso/useCannotRedirect';

import Path from 'constants/paths';

import useTitle from 'hooks/useTitle';

import { ApiError } from 'module/ApiError';
import { standardFormikBaseProps } from 'module/Form';
import { DEFAULT_JOB_TYPE, JobGroup, JobGroupResult, JobOverview, JobStatus, JobType, useJobGroups } from 'module/Job';
import { convertSortModelToQueryString } from 'module/Job/Job.util';
import useTaskReducer from 'module/Job/Task/useTaskReducer';
import useJobOverview from 'module/Job/useJobOverview';

import { useAuth, useManagedOrganizations } from 'provider';

import { Status } from 'storage';

import { FunctionalPermission } from 'types/auth';
import { ArtifactScore, ArtifactType } from 'types/common';

import { pageTotal as getPageTotal } from 'utilities/NumberUtil';
import { formatQueryString, getQueryParam } from 'utilities/SearchParam';

import { Header } from '../core/Dashboard.style';
import DashboardGrid from '../core/DashboardGrid';
import { addNewRankToDuplicateGuid, getConfidenceDifference } from './BulkConfidence.helper';
import { BulkConfidencePayload, JobTaskResult } from './BulkConfidence.type';
import ConfidenceGrid from './BulkConfidenceGrid';
import BulkConfidenceTask from './BulkConfidenceTask';
import ConfidenceActivity from './ConfidenceActivity';
import ThresholdConfidence from './ThresholdConfidence';
import useBulkConfidence from './useBulkConfidence';

const Container = styled('div', { name: 'BulkConfidence' })`
  .job-title {
    margin: 0;
  }
  .action-button {
    font-size: 1rem;
    border-radius: ${p => p.theme.spacing(1)};
  }
  .dashboard-container {
    position: relative;
    width: 100%;
  }

  .dashboard-contents {
    flex-direction: column;
  }

  .dashboard-item {
    width: 100%;
  }
`;

const BULK_JOB_TYPES = [JobType.Rank, JobType.Hunt];

function BulkConfidence() {
  useTitle('Confidence Tailoring | SnapAttack');
  const { defaultOrgId } = useAuth();
  useCannotRedirect(FunctionalPermission.CreateAnalyticJob);
  const { organizations, refresh } = useManagedOrganizations();
  const preferredOrganization = organizations.find(org => org?.id === defaultOrgId);

  const { replace, location } = useHistory();
  const guid = getQueryParam(location.search, 'id');

  const { data: jobGroupTask, status: jobGroupTaskStatus, errorProps: jobGroupTaskErrorProps } = useJobGroups();
  const [state, dispatch] = useTaskReducer(DEFAULT_JOB_TYPE);
  const { typeFilter, orgFilter, nameFilter, sortModel, pageParams } = state;

  const jobTypesToFetch = React.useMemo(() => {
    return typeFilter === DEFAULT_JOB_TYPE ? BULK_JOB_TYPES : [typeFilter];
  }, [typeFilter]);

  const [jobGroup, setJobGroup] = React.useState<JobGroup>();
  const [bulkConfidence, setBulkConfidence] = React.useState<JobGroupResult>([]);
  const [percentage, setPercentage] = React.useState(0);

  const [isJobListOpen, setIsJobListOpen] = React.useState(false);
  const [isThresholdOpen, setIsThresholdOpen] = React.useState(false);
  const [saveSuccessful, setSaveSuccessful] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  const filters = React.useMemo(
    () => ({
      sort: convertSortModelToQueryString(sortModel),
      org_id: orgFilter,
      name: nameFilter
    }),
    [nameFilter, orgFilter, sortModel]
  );

  const {
    items: jobGroupsOverview,
    status: jobsGroupOverviewStatus,
    total
  } = useJobOverview(jobTypesToFetch, pageParams, filters);
  const isJobGroupResolved = [jobGroupTaskStatus, jobsGroupOverviewStatus].every(status => status === Status.resolved);
  const [recentJob, setRecentJob] = useState<JobOverview | undefined>(undefined);
  const { data, getResults, updateBulkConfidence, errorProps } = useBulkConfidence(guid || jobGroup?.guid);
  const isLoading =
    isEmpty(data) ||
    saving ||
    ([jobGroupTaskStatus, jobsGroupOverviewStatus].some(status => status !== Status.resolved) && !recentJob);

  useEffect(() => {
    if (jobGroupsOverview?.length > 0 && !recentJob) {
      const foundRecentJob = jobGroupsOverview?.find(job =>
        [JobStatus.Success, JobStatus.CompletedWithErrors].includes(job.job_status)
      );

      setRecentJob(foundRecentJob);
    }
  }, [jobGroupsOverview, recentJob]);

  const defaultJobGroupTask = jobGroupTask.find(jobGroup =>
    jobGroup.jobs.find(job => job.guid === recentJob?.analytic_job_guid)
  );
  const detectionPath =
    Path.FeedReset +
    formatQueryString({
      topic: ArtifactType.Analytic,
      page: 1
    });

  const jobResult = React.useMemo(() => {
    return data.map(detection => ({
      ...detection,
      newRank: isEmpty(detection.error)
        ? addNewRankToDuplicateGuid(data, preferredOrganization?.optional_preference, detection.guid)
        : ArtifactScore.UNKNOWN,
      newRankReadOnly: isEmpty(detection.error)
        ? addNewRankToDuplicateGuid(data, preferredOrganization?.optional_preference, detection.guid)
        : ArtifactScore.UNKNOWN,
      diff: getConfidenceDifference(
        detection.old_rank,
        isEmpty(detection.error)
          ? addNewRankToDuplicateGuid(data, preferredOrganization?.optional_preference, detection.guid)
          : ArtifactScore.UNKNOWN
      )
    }));
  }, [data, preferredOrganization?.optional_preference]);

  const setJobTask = React.useCallback(() => {
    if (guid) setJobGroup(jobGroupTask?.find(job => job.guid === guid));
    else setJobGroup(defaultJobGroupTask);
  }, [defaultJobGroupTask, guid, jobGroupTask]);

  React.useEffect(() => {
    setJobTask();
  }, [setJobTask]);

  React.useEffect(() => {
    setBulkConfidence(jobResult);
  }, [jobResult]);

  const getJob = (job: JobGroup) => {
    setIsJobListOpen(false);
    replace(
      Path.ConfidenceTailoring +
        formatQueryString({
          id: job.guid
        })
    );
  };

  const refreshJob = () => {
    getResults();
    setSaving(false);
    setSaveSuccessful(false);
    setPercentage(0);
  };

  const updateJob = (payload: BulkConfidencePayload[], overwriteManual?: boolean) => {
    setSaving(true);
    updateBulkConfidence(payload, overwriteManual).then(() => {
      let counter = 0;
      setSaveSuccessful(true);
      const intervalId = setInterval(() => {
        const percentage = (counter * 100) / 15;
        setPercentage(percentage);
        if (counter === 15) {
          clearInterval(intervalId);
        } else {
          counter++;
        }
      }, 1000);
    });
  };

  const updateDetection = (selectedDetection: JobTaskResult) => {
    setBulkConfidence([
      ...bulkConfidence.filter(oldDetection => oldDetection.guid !== selectedDetection.guid),
      ...bulkConfidence
        .filter(oldDetection => oldDetection.guid === selectedDetection.guid)
        .map(oldDetection => ({ ...oldDetection, diff: selectedDetection.diff, newRank: selectedDetection.newRank }))
    ]);
  };

  function handleChangePage(newPage: number): void {
    dispatch({ type: 'SET_PAGE', payload: newPage });
  }

  function handleSortModelChange(newModel: GridSortModel) {
    dispatch({ type: 'SET_SORT_MODEL', payload: newModel });
  }

  function onTypeChange(type: JobType | 'All') {
    dispatch({ type: 'SET_TYPE', payload: type });
  }

  function onOrgChange(org: number) {
    dispatch({ type: 'SET_ORG_FILTER', payload: org });
  }

  function onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch({ type: 'SET_NAME_FILTER', payload: event.target.value });
  }

  function handleCloseDialog() {
    dispatch({ type: 'RESET_FILTER', payload: DEFAULT_JOB_TYPE });
    setIsJobListOpen(false);
  }

  return (
    <Container>
      <DashboardGrid>
        <Header>
          <Typography variant='h1'>Confidence Tailoring Dashboard</Typography>
          <div className='Header-action'>
            <p className='job-title'>{jobGroup?.name}</p>
            <Button
              disabled={isLoading}
              className='action-button'
              variant='contained'
              onClick={() => setIsJobListOpen(!isJobListOpen)}
            >
              Change Task
            </Button>
            <Button
              className='action-button'
              variant='outlined'
              disabled={organizations?.length === 0}
              onClick={() => setIsThresholdOpen(!isThresholdOpen)}
            >
              Set Confidence Thresholds
            </Button>
          </div>
        </Header>
      </DashboardGrid>
      {(jobGroupTaskErrorProps || errorProps) && (
        <DashboardGrid>
          <ApiError {...jobGroupTaskErrorProps} />
          <ApiError {...errorProps} />
        </DashboardGrid>
      )}
      <ConfidenceActivity loading={isLoading} bulkConfidence={bulkConfidence as JobTaskResult[]} />
      <DashboardGrid>
        <Typography variant='h2'>Confidence Tailoring Results</Typography>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            {isLoading ? (
              <>
                <Placeholder variant='text' width={'100%'} height={100} />
                <Placeholder variant='text' width={'100%'} height={40} />
                <Placeholder variant='text' width={'100%'} height={40} />
                <Placeholder variant='text' width={'100%'} height={40} />
                <Placeholder variant='text' width={'100%'} height={40} />
                <Placeholder variant='text' width={'100%'} height={40} />
                <Placeholder variant='text' width={'100%'} height={40} />
              </>
            ) : (
              <ConfidenceGrid
                bulkConfidence={bulkConfidence}
                updateDetection={updateDetection}
                isLoading={isLoading}
                update={updateJob}
              />
            )}
          </Grid>
        </Grid>
      </DashboardGrid>
      <div className='task-dialog'>
        <DisplayDialog
          title={'Confidence Task'}
          DialogProps={{
            open: isJobListOpen,
            onClose: () => handleCloseDialog(),
            maxWidth: 'lg'
          }}
        >
          <BulkConfidenceTask
            jobs={jobGroupsOverview}
            jobGroupTask={jobGroupTask}
            getJob={getJob}
            isPending={jobsGroupOverviewStatus === Status.pending || jobsGroupOverviewStatus === Status.rejected}
            jobTypeFilter={typeFilter}
            onTypeChange={onTypeChange}
            page={pageParams.page}
            pageTotal={getPageTotal(total, pageParams.size)}
            handleChangePage={handleChangePage}
            sortModel={sortModel}
            handleSortModelChange={handleSortModelChange}
            orgFilter={orgFilter}
            onOrgChange={onOrgChange}
            onSearchChange={onSearchChange}
          />
        </DisplayDialog>
      </div>
      {isThresholdOpen && (
        <ThresholdConfidence
          open={isThresholdOpen}
          onClose={setIsThresholdOpen}
          optional_preference={preferredOrganization.optional_preference}
          refreshPreferredOrg={refresh}
        />
      )}

      <FormDialog
        title='No Detection Results'
        DialogProps={{
          open: isEmpty(jobGroup) && isJobGroupResolved,
          maxWidth: 'sm'
        }}
        SecondaryActionProps={{
          children: 'Configure Integration',
          onClick: () => replace(Path.Integrations),
          variant: 'outlined'
        }}
        SubmitProps={{
          children: 'Go to Detection Feed'
        }}
        FormikConfig={{
          ...standardFormikBaseProps,
          initialValues: {},
          onSubmit: () => replace(detectionPath)
        }}
      >
        <FieldsLayout>
          <span>
            In order to tailor detection confidence levels, you need to first configure an integration. Then, navigate
            to the detection feed, click the <Icon.Menu /> button and select &quot;Confidence Tailoring&quot; to launch
            a confidence tailoring task. When the task has completed, you can return here to view the results and tailor
            the detection confidence levels.
          </span>
        </FieldsLayout>
      </FormDialog>
      <FormDialog
        title='Updating Detection Confidence'
        FormikConfig={{
          ...standardFormikBaseProps,
          initialValues: {},
          onSubmit: () => replace(detectionPath)
        }}
        SubmitProps={{
          children: 'Done',
          onClick: () => refreshJob(),
          disabled: percentage !== 100
        }}
        SecondaryActionProps={{
          children: 'Go to Detection Feed',
          variant: 'outlined'
        }}
        DialogProps={{
          open: saveSuccessful
        }}
      >
        {saveSuccessful && (
          <Alert severity={percentage < 100 ? 'warning' : 'success'}>
            {percentage < 100
              ? 'Updating detection confidence to the new values. Please wait.'
              : 'Confidence values have finished updating.'}
          </Alert>
        )}
        <DialogProgressContainer>
          <LinearProgress color='primary' variant='determinate' value={percentage} />
        </DialogProgressContainer>
      </FormDialog>
    </Container>
  );
}

export default BulkConfidence;
