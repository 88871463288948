import { CancelTokenType, snapattack } from 'apis';

import { SupplementalArtifact } from 'module/Search';

import { ArtifactType, Guid, PageParams } from 'types/common';
import { Query } from 'types/filter';

import { pageTotal } from 'utilities/NumberUtil';

import { Feed, NameMatchResponse } from './Feed.type';

export const prefix = '/search';

export function partialNameMatch(partial: string, types?: ArtifactType[]): Promise<NameMatchResponse> {
  return snapattack.post(`${prefix}/autocomplete/`, { partial, types }).then(r => r.data);
}

export async function search(
  url: string,
  data: Query,
  config: { params: PageParams; cancelToken?: CancelTokenType }
): Promise<Feed> {
  try {
    const r = await snapattack.post(url, data, config);
    return { ...r.data, pageTotal: pageTotal(r.data.total, r.data.size), lastUsedQuery: data };
  } catch (err) {
    return {
      canceled: !!err.__CANCEL__,
      items: [],
      total: 0,
      page: config.params.page,
      size: config.params.size,
      pageTotal: 0,
      lastUsedQuery: data
    };
  }
}

/**
 *
 * @param topicEndpoint the supplemental url prefix up to the topic endpoint which includes the ending slash;
 * @param guid
 */
export async function supplemental(
  topicEndpoint: string,
  guid: Guid,
  config?: { cancelToken?: CancelTokenType }
): Promise<SupplementalArtifact> {
  return snapattack.get(`${topicEndpoint}cached/v2/${guid}/supplemental/`, config).then(r => r.data);
}
