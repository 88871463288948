import { snapattack } from 'apis';

import { BlackTag } from './Blacklist.type';

export function getBlacklist(): Promise<BlackTag[]> {
  return snapattack.get('/tags/blacklist/').then(r => r.data);
}

export function deleteBlacklistValue(value: string): Promise<void> {
  return snapattack.delete('/tags/blacklist/', {
    data: [value]
  });
}

export function deleteBlacklistValueWithAliases(value: string, aliases: string[]): Promise<void> {
  return snapattack.delete(`/tags/blacklist/${value}/`, {
    data: aliases
  });
}

export function addBlacklistValue(value: string): Promise<void> {
  return snapattack.put('/tags/blacklist/', [value]);
}

export function addBlacklistValueAndAlias(value: string, alias: string): Promise<void> {
  return snapattack.put(`/tags/blacklist/${value}/`, [alias]);
}
