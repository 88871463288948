import React from 'react';

import { List, ListItem } from 'snap-ui/List';
import Tooltip from 'snap-ui/Tooltip';

import { ColumnComponentProps } from './common';

export default function MitreAttackColumn(props: ColumnComponentProps): JSX.Element {
  const attack_names = props.job?.attack_names || [];

  if (!attack_names.length) return null;
  return (
    <>
      <div>{attack_names[0]}</div>
      {attack_names.length > 1 && (
        <Tooltip
          arrow
          title={
            <List>
              {[...attack_names].slice(1).map(mitre => (
                <ListItem key={mitre}>{mitre}</ListItem>
              ))}
            </List>
          }
        >
          <span>{attack_names.length - 1} More</span>
        </Tooltip>
      )}
    </>
  );
}
