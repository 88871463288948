import React from 'react';

import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { mixed, object } from 'yup';

import { Option } from 'snap-ui/Autocomplete';
import CircularProgress from 'snap-ui/CircularProgress';
import { FormDialog } from 'snap-ui/Dialog';
import { DialogProps } from 'snap-ui/Dialog/_MuiDialog';

import { Form, Formik, standardFormikBaseProps } from 'module/Form';

import { useLanguageContext } from 'provider';

import { Status } from 'storage';

import { Language } from 'types/analytic';

import { Integration, IntegrationFormData, IntegrationRJSFSchema, LanguageOption } from '../Integration.type';
import { getSpecificJobSchema } from '../Integration.util';
import { IntegrationHealthStatus } from '../IntegrationHealth';
import useIntegrationContext from '../IntegrationProvider';
import { useLanguagePlatforms } from '../LanguagePlatformProvider';
import InlineFormFields from './InlineFormFields';
import { Container, DialogContainer } from './Integration.style';
import TabFormFields from './TabFormFields';

type IntegrationsFormProps = {
  integration?: Integration;
  initialValuesProps?: {
    name: string;
    url_template: string;
    organization_id: string;
    deployment_prefix_template: string;
    deployment_targets: string[];
    hunt_targets: string[];
    search_targets: string[];
    extra_information?: Record<string, unknown>;
    type?: string;
  };
  submitForm?: (formData: IntegrationFormData) => void;
  DialogProps?: DialogProps;
  status?: Status;
};

function languageMapper(language: Language): Option {
  return { content: language.name, value: language.id.toString() };
}

const IntegrationsForm = (props: IntegrationsFormProps) => {
  const { integration, initialValuesProps, submitForm, DialogProps, status } = props;

  const { integrationSchemaCatalog, jobSchemaCatalog } = useIntegrationContext();

  const isDialog = !isEmpty(DialogProps);

  const { data: languageList } = useLanguageContext();
  const { supportedPlatforms } = useLanguagePlatforms();
  const jobSchema = getSpecificJobSchema(jobSchemaCatalog, integration?.type);
  if (!isEmpty(jobSchema))
    jobSchema.properties = omit(jobSchema?.properties, ['max_results_per_query', 'max_search_window_seconds']);

  const allLanguageOptions = React.useMemo(() => {
    return languageList.map<Option>(format => ({
      content: format.name,
      value: format?.id?.toString()
    }));
  }, [languageList]);

  function getLanguageOptions(type: string): LanguageOption {
    if (type === 'CustomerManaged')
      return {
        deploy: allLanguageOptions,
        search: allLanguageOptions,
        hunt: allLanguageOptions
      };
    else {
      const supportedPlatform = supportedPlatforms[type];

      const deployLanguageOption = languageList
        .filter(language => supportedPlatform?.deploy?.includes(language.id))
        .map(languageMapper);

      const searchLanguageOption = languageList
        .filter(language => supportedPlatform?.search?.includes(language.id))
        .map(languageMapper);

      const huntLanguageOption = languageList
        .filter(language => supportedPlatform?.hunt?.includes(language.id))
        .map(languageMapper);

      return {
        deploy: deployLanguageOption,
        search: searchLanguageOption,
        hunt: huntLanguageOption
      };
    }
  }

  const initValues = {
    ...initialValuesProps,
    extra_information: {
      ...initialValuesProps.extra_information
    }
  };

  function handleSubmit(formData: IntegrationFormData) {
    return submitForm(formData);
  }

  const [integrationType, setIntegrationType] = React.useState<string>();
  const integrationSchema: IntegrationRJSFSchema = integrationSchemaCatalog?.[integrationType];

  const formikConfig = {
    ...standardFormikBaseProps,
    initialValues: initValues,
    nestedRjsf: { extra_information: integrationSchema },
    validationSchema: object().shape({
      name: mixed().required('Name is required')
    }),
    onSubmit: handleSubmit
  };

  if (isDialog)
    return (
      <FormDialog
        DialogProps={DialogProps}
        SubmitProps={{
          children: status === Status.pending ? <CircularProgress size={25} /> : 'Copy Integration',
          disabled: status === Status.pending
        }}
        FormikConfig={formikConfig}
        title='Copy Integration'
      >
        <DialogContainer>
          <InlineFormFields
            integrationGuid={integration?.guid}
            getLanguageOptions={getLanguageOptions}
            integrationSchema={integrationSchema}
            jobSchema={jobSchema}
            setType={setIntegrationType}
          />
        </DialogContainer>
      </FormDialog>
    );

  return (
    <Container>
      <Formik {...formikConfig}>
        <Form>
          <IntegrationHealthStatus integration={integration} />

          <TabFormFields
            integrationGuid={integration?.guid}
            integrationSchema={integrationSchema}
            jobSchema={jobSchema}
            getLanguageOptions={getLanguageOptions}
            setType={setIntegrationType}
          />
        </Form>
      </Formik>
    </Container>
  );
};

export default IntegrationsForm;
