import React from 'react';

import Placeholder from 'snap-ui/Placeholder';
import Typography from 'snap-ui/Typography';

import CampaignRecommenderList from '../CampaignRecommenderList/CampaignRecommenderList';

function CampaignRecommendationSection({ campaignGuid, isLoading }: { campaignGuid: string; isLoading: boolean }) {
  return isLoading ? (
    <div className='CampaignReport-section'>
      <Placeholder variant='text' height={66} width={250} />
      <Placeholder variant='rectangular' height={308} width='100%' />
    </div>
  ) : (
    <div className='CampaignReport-section'>
      <Typography variant='h2'>Recommended Detections</Typography>
      <CampaignRecommenderList campaignGuid={campaignGuid} />
    </div>
  );
}

export default CampaignRecommendationSection;
