import React from 'react';

import { AuxiliaryTab } from 'snap-ui/Tabs';
import { styled } from 'snap-ui/util';

import { StrictReactNode } from 'types/core';

const Container = styled(AuxiliaryTab)`
  justify-content: flex-start;
  align-items: center;
  padding: ${p => p.theme.spacing(0, 3)};
  gap: ${p => p.theme.spacing(4)};
`;

type Props = {
  children: StrictReactNode;
};

const CollectionAuxiliaryAction = React.forwardRef<any, Props>(function CollectionAuxiliaryAction({ children }, ref) {
  return (
    <Container ref={ref} className='CollectionAuxiliaryAction'>
      {children}
    </Container>
  );
});

export default CollectionAuxiliaryAction;
