import React from 'react';

import Icon from 'snap-ui/Icon';

import { BurgerClicker } from 'module/Layout/Artifact.widgets';

import AddAnalyticToAttackScript, { AddAnalyticToAttackScriptProps } from './AddAnalyticToAttackScript';

type Button = Partial<BurgerClicker>;
type Dialog = Omit<AddAnalyticToAttackScriptProps, 'isOpen' | 'onClose'>;

type AddAnalyticToAttackScriptContextType = {
  isOpen: boolean;
  setIsOpen(open: boolean): void;
};

const AddAnalyticToAttackScriptContext = React.createContext<AddAnalyticToAttackScriptContextType>(null);
AddAnalyticToAttackScriptContext.displayName = 'AddAnalyticToAttackScriptContext';

function AddAnalyticToAttackScriptInterface({ children }: { children: React.ReactNode }): JSX.Element {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <AddAnalyticToAttackScriptContext.Provider
      value={{
        isOpen,
        setIsOpen
      }}
    >
      {children}
    </AddAnalyticToAttackScriptContext.Provider>
  );
}

const useAddAnalyticToAttackScript = (): AddAnalyticToAttackScriptContextType => {
  const context = React.useContext(AddAnalyticToAttackScriptContext);
  if (context === undefined) {
    throw new Error('useAddAnalyticToAttackScript must be used within an AddAnalyticToAttackScriptInterface');
  }
  return context;
};

function AddAnalyticToAttackScriptButton({ onClick, ...others }: Button) {
  const { setIsOpen } = useAddAnalyticToAttackScript();

  return (
    <BurgerClicker
      icon={<Icon.Analytic color='inherit' />}
      onClick={() => {
        setIsOpen(true);
        if (onClick) onClick();
      }}
      {...others}
      title='Link to Detection'
    />
  );
}

function AddAnalyticToAttackScriptDialog(props: Dialog) {
  const { isOpen, setIsOpen } = useAddAnalyticToAttackScript();

  return <AddAnalyticToAttackScript isOpen={isOpen} onClose={() => setIsOpen(false)} {...props} />;
}

export {
  AddAnalyticToAttackScriptButton,
  AddAnalyticToAttackScriptDialog,
  AddAnalyticToAttackScriptInterface,
  useAddAnalyticToAttackScript
};
